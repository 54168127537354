import React, { useContext, useState } from 'react';
import PushPinIcon from '@mui/icons-material/PushPin';
import { Box, CardContent, IconButton, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CommunityUser, JunoUser, SearchContentTypeEnum } from '@juno/client-api/model';
import { UserQuickView } from '@juno/ui';
import { getContentRoute, useSettings } from '@juno/utils';
import { FeedContext } from '../../Feed';
import ThreadPostOptions from './ThreadOptionsMenu';
import { FlexContainerCenter, ThreadPostAuthorAvatar, ThreadPostContentContainer } from './styles';

interface ThreadPostContentProps {
  body: JSX.Element;
  author: CommunityUser | undefined;
  currentUser: CommunityUser | undefined;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  navigateToProfile: () => void;
  onDeleteConfirm: () => void;
  onBlockUserConfirm: () => void;
  onReportUserConfirm: () => void;
  onReportContentConfirm: () => void;
  pinned: boolean;
  announcement: boolean;
  handlePinUnpin: () => void;
  onMakeAnnouncment: () => void;
  threadId: string;
  isReply: boolean;
}

const ThreadPostCardContent: React.FC<ThreadPostContentProps> = ({
  body,
  author,
  currentUser,
  setIsEditing,
  navigateToProfile,
  onDeleteConfirm,
  onBlockUserConfirm,
  onReportUserConfirm,
  onReportContentConfirm,
  pinned,
  announcement,
  handlePinUnpin,
  onMakeAnnouncment,
  threadId,
  isReply,
}) => {
  const { getImageSrc, isClient, site } = useSettings();
  const navigateRoute = useNavigate();
  const [userQuickViewAnchorEl, setUserQuickViewAnchorEl] = useState<null | HTMLElement>(null);
  // check to see if the author first and last name are both blank or null, if so, then display the author's email
  let userDisplayName = `${author?.first_name} ${author?.last_name}`;
  if (!userDisplayName || userDisplayName.trim() === '') {
    userDisplayName = author?.email || '';
  }

  const feedContext = useContext(FeedContext);
  return (
    <CardContent sx={{ pb: 0 }}>
      <ThreadPostContentContainer>
        <FlexContainerCenter
          aria-label='View user details'
          onClick={(e) => {
            setUserQuickViewAnchorEl(e.currentTarget);
          }}
          sx={{ cursor: 'pointer', position: 'relative' }}
        >
          <ThreadPostAuthorAvatar
            src={
              isClient
                ? author?.avatar || ''
                : getImageSrc(author?.icon || author?.avatar || 'UserIcon_CrowdHub.jpg')
            }
          />
          <Typography>{`${author?.first_name} ${author?.last_name}`}</Typography>
        </FlexContainerCenter>
        <FlexContainerCenter>
          {(pinned || feedContext?.iAmAdmin) && (
            <Box>
              <IconButton
                disabled={!feedContext?.iAmAdmin}
                onClick={(e) => {
                  handlePinUnpin();
                }}
              >
                {pinned && (
                  <Tooltip title='Unpin Post' placement='top'>
                    <PushPinIcon
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                      }}
                    />
                  </Tooltip>
                )}
                {!pinned && (
                  <Tooltip title='Pin Post' placement='top'>
                    <PushPinIcon
                      sx={{
                        color: '#bbb',
                      }}
                    />
                  </Tooltip>
                )}
              </IconButton>
            </Box>
          )}
          {/* // TODO - hiding options from non-author/non-admins until blocking/reporting users is ready - be sure to change ThreadPostOptions as well */}

          <ThreadPostOptions
            iAmAdmin={feedContext?.iAmAdmin}
            setIsEditing={setIsEditing}
            isAuthor={author?.id === currentUser?.id}
            onDeleteConfirm={onDeleteConfirm}
            onBlockUserConfirm={onBlockUserConfirm}
            onReportUserConfirm={onReportUserConfirm}
            onReportContentConfirm={onReportContentConfirm}
            onMakeAnnouncment={onMakeAnnouncment}
            announced={announcement}
            threadId={threadId}
            author={author}
            isReply={isReply}
          />
        </FlexContainerCenter>
      </ThreadPostContentContainer>
      {body}
      <UserQuickView
        anchorEl={userQuickViewAnchorEl}
        userId={author?.id || ''}
        open={Boolean(userQuickViewAnchorEl)}
        setAnchorEl={setUserQuickViewAnchorEl}
        onViewProfile={(userEmail: string): void => {
          navigateRoute(
            getContentRoute(site?.slug || '', author?.id || '', SearchContentTypeEnum.user),
          );
        }}
        onMessageUser={function (userId: string): void {
          throw new Error('Function not implemented.');
        }}
      />
    </CardContent>
  );
};

export default ThreadPostCardContent;
