import React, { useEffect, useState } from 'react';
import { AutorenewOutlined as AutorenewOutlinedIcon } from '@mui/icons-material';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import {
  createAccessPass,
  deleteContentKey,
  getAccessPasses,
  getGetAccessPassesQueryKey,
} from '@juno/client-api';
import { useGetUserRoleMap } from '@juno/client-api/helpers';
import { AccessPass, Site as SiteModel } from '@juno/client-api/model';
import { AdminDefaultViewHeader, JunoSpin, TabPanel } from '@juno/ui';
import { snackOptions } from '@juno/utils';
import { MutationAction, onMutation } from '@juno/utils';
import NewAccessPassDialog from './dialogs/NewAccessPassDialog';
import AccessPassList from './panelparts';
import AccessPassItem from './panelparts/AccessPassItem';
import { snackMessage } from './utils/utils';

interface AccessPassPanelProps {
  site: SiteModel;
}

const MODELS = 'Access Passes';

const AccessPassesPanel: React.FC<AccessPassPanelProps> = ({ site }) => {
  const navigate = useNavigate();
  const { id: siteId, platform_id: platformId } = site;
  const [open, setOpen] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [accessPasses, setAccessPasses] = useState<AccessPass[]>([]);
  const queryClient = useQueryClient();
  const roleMap = useGetUserRoleMap(siteId);
  const { isLoading: routesLoading } = roleMap;
  const { enqueueSnackbar } = useSnackbar();

  const refetch = () => queryClient.invalidateQueries(getGetAccessPassesQueryKey(siteId));

  const handleClose = (): void => {
    setOpen(false);
  };
  const handlePost = (accessPass: AccessPass): void => {
    setOpen(false);
    createAccessPass(siteId, accessPass)
      .then((resp) => {
        enqueueSnackbar(snackMessage.success, snackOptions('success'));
        refetch();
        refreshPasses();
      })
      .catch((err) => {
        enqueueSnackbar(snackMessage.error, snackOptions('error'));
      });
  };

  const handleDelete = (passId: string): void => {
    deleteContentKey(siteId, passId)
      .then((resp) => console.log(resp))
      .catch((err) => console.error(err));
    refreshPasses();
    navigate('./');
  };
  useEffect(() => {
    refreshPasses();
  }, []);

  const refreshPasses = () => {
    getAccessPasses(siteId, { include: 'products' })
      .then((resp) => {
        console.log(resp);
        setAccessPasses(resp);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  if (routesLoading) return <JunoSpin />;

  return (
    <Routes>
      <Route
        path='/'
        element={
          <Box>
            <AdminDefaultViewHeader title='Access Passes'>
              <Button
                onClick={() => setOpen(true)}
                startIcon={<BookmarkAddIcon />}
                sx={{ ml: 1, textTransform: 'none' }}
                variant='contained'
              >
                Create an Access Pass
              </Button>
            </AdminDefaultViewHeader>

            <TabPanel value={0} index={0}>
              <AccessPassList site={site} />
            </TabPanel>

            {open && (
              <NewAccessPassDialog
                siteId={site.id}
                open={open}
                handleClose={handleClose}
                handlePost={handlePost}
                allAccessPasses={accessPasses}
              />
            )}
          </Box>
        }
      />
      <Route
        path=':id'
        element={
          <AccessPassItem
            refreshPasses={refreshPasses}
            siteId={siteId}
            handleDelete={handleDelete}
            allAccessPasses={accessPasses}
            platformId={platformId}
          />
        }
      />
    </Routes>
  );
};

export default AccessPassesPanel;
