import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useMediaQuery } from 'usehooks-ts';
import {
  Component as ComponentModel,
  ComponentTypeEnum,
  FeatureConfig as FeatureConfigModel,
} from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import { AnnouncementsModule } from '../Announcements';
import { AnnouncementsDataModel } from '../Announcements/form';
import Blurb from '../Blurb';
import ContentGrid from '../ContentGrid';
import ContentRotator from '../ContentRotator';
import { DirectoryModule } from '../Directory';
import { DirectoryDataModel } from '../Directory/form';
import { GlobalFeed } from '../Feed';
import { GlobalForum } from '../Forum';
import Hero from '../Hero';
import { HeroDataModel } from '../Hero/form';
import { ScheduleModule } from '../Schedule';
import { ScheduleDataModel } from '../Schedule/form';
import SideBySide from '../SideBySide';
import { SideBySideDataModel } from '../SideBySide/form';
import ShadowWrapper from './ShadowWrapper';
import './styles.css';

interface JunoModuleProps {
  siteId: string;
  configs: FeatureConfigModel[];
  component: ComponentModel;
}

const JunoModule: React.FC<JunoModuleProps> = ({ siteId, component }) => {
  switch (component.type) {
    case ComponentTypeEnum.featured_rotator:
      return <ContentRotator siteId={siteId} settings={component.data} />;
    case ComponentTypeEnum.speaker_preview:
    case ComponentTypeEnum.grid:
      return <ContentGrid siteId={siteId} settings={component.data} />;
    case ComponentTypeEnum.blurb:
      return <Blurb html={component.data?.html} />;
    case ComponentTypeEnum.global_feed:
      return <GlobalFeed siteId={siteId} title={(component.data?.title as string) || ''} />;
    case ComponentTypeEnum.global_forum:
      return <GlobalForum siteId={siteId} title={(component.data?.title as string) || ''} />;
    case ComponentTypeEnum.hero:
      return <Hero component={component?.data as HeroDataModel} />;
    case ComponentTypeEnum.side_by_side:
      return <SideBySide component={component?.data as SideBySideDataModel} />;
    case ComponentTypeEnum.schedule:
      return <ScheduleModule component={component?.data as ScheduleDataModel} />;
    case ComponentTypeEnum.directory:
      return <DirectoryModule component={component?.data as DirectoryDataModel} />;
    case ComponentTypeEnum.announcements:
      return <AnnouncementsModule component={component?.data as AnnouncementsDataModel} />;
    default:
      return null;
  }
};

const JunoShadowModule: React.FC<JunoModuleProps> = ({ siteId, configs, component }) => {
  const { isClient } = useSettings();
  const isSmallScreen = useMediaQuery('(max-width: 1024px)');
  const margin = useMemo(() => {
    // TODO: look into a better way to allow full width than this hack
    const counterDefaultMargin = isSmallScreen ? '-20px' : '-32px';
    return component.data?.isFullWidth ? counterDefaultMargin : 'unset';
  }, [isSmallScreen, component]);
  if (isClient) {
    return (
      <Box sx={{ margin }}>
        <JunoModule siteId={siteId} configs={configs} component={component} />
      </Box>
    );
  }
  return (
    <ShadowWrapper configs={configs}>
      <Box sx={{ margin }}>
        <JunoModule siteId={siteId} configs={configs} component={component} />
      </Box>
    </ShadowWrapper>
  );
};

export default JunoShadowModule;
