import React from 'react';
import { Box, Card, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { JunoImage } from '@juno/client-api/fakeModel';
import { ASPECT_RATIOS, JunoImageUpload } from '@juno/ui';
import { optimizeImage, useSettings } from '@juno/utils';
import { About } from './About';
import { BasicInfo } from './BasicInfo';
import { Education } from './Education';
import { Experience } from './Experience';
import { Interests } from './Interests';
import { Skills } from './Skills';
import { useUserProfile, useUserProfileUpdate } from './UserProfileContext';

const UserProfile: React.FC = () => {
  const userProfile = useUserProfile();
  const updateUserProfile = useUserProfileUpdate();
  const { isSmallScreen } = useSettings();
  const theme = useTheme();
  return (
    <Box pb={3} key={userProfile.id}>
      <Card>
        <Box sx={{ position: 'relative' }}>
          <JunoImageUpload
            disabled={!userProfile.isMe}
            style={{ aspectRatio: '25/8' }}
            src={optimizeImage(1200, userProfile.banner)}
            srcUrl={userProfile.banner}
            onFileUploaded={(selected: JunoImage | null) => {
              if (selected?.url) {
                updateUserProfile({ ...userProfile, banner: selected?.url || '' });
              }
            }}
            aspectRatios={[ASPECT_RATIOS.TWENTY_FIVE_EIGHT]}
          />
        </Box>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            p: 2,
          }}
        >
          <JunoImageUpload
            disabled={!userProfile.isMe}
            style={{
              height: isSmallScreen ? 80 : 167,
              width: isSmallScreen ? 80 : 167,
              borderRadius: 150,
              overflow: 'hidden',
              position: 'absolute',
              top: isSmallScreen ? -60 : -102,
            }}
            src={optimizeImage(270, userProfile.photoURL)}
            srcUrl={userProfile.photoURL}
            onFileUploaded={(selected: JunoImage | null) => {
              if (selected?.url) {
                updateUserProfile({ ...userProfile, photoURL: selected?.url || '' });
              }
            }}
            aspectRatios={[ASPECT_RATIOS.ONE_ONE]}
          />
          <BasicInfo />
          {!userProfile.isMe && userProfile.settings.private_profile && (
            <Typography variant='h6' m={3} textAlign={'center'} color={theme.palette.grey[600]}>
              This profile is private.
            </Typography>
          )}
        </Box>
      </Card>
      {(userProfile.isMe || !userProfile.settings.private_profile) && (
        <>
          <About />
          <Experience />
          <Education />
          <Skills />
          <Interests />
        </>
      )}
    </Box>
  );
};

export default UserProfile;
