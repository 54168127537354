import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export interface ScormCourseForm {
  uploaded_file: string;
}

interface FormDialogProps {
  open: boolean;
  onClose: () => void;
}

const ScormLoadingDialog: React.FC<FormDialogProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Learning Package Loading in Progress</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your learning package is being loaded in the background. The course will reload when it is
          ready. Course edits cannot be saved until the learning package completes loading.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button onClick={onClose}>Close</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
export default ScormLoadingDialog;
