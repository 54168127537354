import React, { useEffect, useMemo, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { MAX_MP4_VIDEO_UPLOAD_SIZE } from '@juno/constants';
import { useS3Upload } from '@juno/modules';
import { cancelVideoFileUpload, formatBytes, snackOptions } from '@juno/utils';
import {
  UploadStatusWrapper,
  VideoUploadWrapper,
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from './styles';

type VideoUploadButtonProps = {
  uploadProgress?: number;
  setUploadProgress?: React.Dispatch<React.SetStateAction<number>>;
  isUploading?: boolean;
  setIsUploading?: React.Dispatch<React.SetStateAction<boolean>>;
  setFileName?: (value: string) => void;
  setLaunchCount?: React.Dispatch<React.SetStateAction<number>>;
  onVideoUploadStarted?: () => void;
  onVideoUploaded?: (videoUrl: any) => void;
  fileUploadPromise: any;
  setFileUploadPromise: React.Dispatch<React.SetStateAction<Promise<any> | null>>;
  videoFileIsCanceled: boolean;
  setVideoFileIsCanceled: React.Dispatch<React.SetStateAction<boolean>>;
};

const VideoUploadButton = ({
  uploadProgress,
  setUploadProgress,
  isUploading,
  setIsUploading,
  setFileName,
  setLaunchCount,
  onVideoUploadStarted,
  onVideoUploaded,
  fileUploadPromise,
  setFileUploadPromise,
  videoFileIsCanceled,
  setVideoFileIsCanceled,
}: VideoUploadButtonProps) => {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [isUploadErrorDialogOpen, setIsUploadErrorDialogOpen] = useState(false);
  const [fileUploadError, setFileUploadError] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { uploadVideoFileToS3 } = useS3Upload();
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      'video/mp4': ['.mp4', '.MP4'],
    },
    maxFiles: 1,
    maxSize: MAX_MP4_VIDEO_UPLOAD_SIZE,
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setIsConfirmationDialogOpen(true);
    }
  }, [acceptedFiles]);

  const onProgress = (percentage: number) => {
    if (setUploadProgress) {
      setUploadProgress(Math.floor(percentage * 100));
    }
  };

  const onCancelUploadBefore = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onCancelUploadDuring = async (videoPutPromise: Promise<any> | null) => {
    if (!videoPutPromise) {
      return;
    } else {
      try {
        enqueueSnackbar('The video upload was successfully canceled', snackOptions('success'));
        await cancelVideoFileUpload(videoPutPromise);
        return;
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    if (videoFileIsCanceled) {
      onCancelUploadDuring(fileUploadPromise);
      if (setIsUploading) {
        setIsUploading(false);
      }
      if (setUploadProgress) {
        setUploadProgress(0);
      }
      if (setFileName) {
        setFileName('');
      }
      setVideoFileIsCanceled(false);
      setFileUploadPromise(null);
    }
  }, [videoFileIsCanceled, setVideoFileIsCanceled]);

  const onUpload = async () => {
    if (onVideoUploadStarted) {
      onVideoUploadStarted();
    }
    if (setFileName) {
      setFileName(acceptedFiles[0].name);
    }
    if (setLaunchCount) {
      setLaunchCount((launchCount) => launchCount + 1);
    }
    if (setIsUploading) {
      setIsUploading(true);
    }

    setIsConfirmationDialogOpen(false);

    try {
      const { promise, getUrl } = uploadVideoFileToS3(acceptedFiles[0], onProgress);
      setFileUploadPromise(promise);
      const videoUploadUrl = await getUrl();

      if (onVideoUploaded) {
        onVideoUploaded(videoUploadUrl);
      }
    } catch (err) {
      setFileUploadError(err as string);
    }
  };

  useEffect(() => {
    if (fileRejections.length > 0) {
      setIsUploadErrorDialogOpen(true);
    }
  }, [fileRejections]);

  const onCloseUploadErrorDialog = () => {
    setFileUploadError(null);
    setIsUploadErrorDialogOpen(false);
  };

  return (
    <>
      <VideoUploadWrapper>
        <fieldset>
          <legend>
            <Typography className='legend-label' variant='caption'>
              MP4 Upload
            </Typography>
          </legend>
        </fieldset>
        <Typography className='label' variant='caption'>
          MP4 Upload
        </Typography>
        {isUploading ? (
          <UploadStatusWrapper>
            <Box sx={{ overflow: 'hidden' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <VideoFileIcon sx={{ fontSize: 12 }} />
                <Typography variant='caption' sx={{ lineHeight: 1 }}>
                  {uploadProgress === 100 ? 'Complete!' : 'In Progress'}
                </Typography>
              </Box>

              <Typography
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {acceptedFiles[0]?.name}
              </Typography>
            </Box>
            <Box sx={{ flexShrink: 0 }}>
              {uploadProgress === 100 && (
                <CheckCircleOutlineIcon color='primary' sx={{ fontSize: 20 }} />
              )}
            </Box>
          </UploadStatusWrapper>
        ) : (
          <Button variant='text' className='uploadBtn' {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <AddCircleOutlineIcon fontSize='small' />
            <span>Browse files (10GB)</span>
          </Button>
        )}
      </VideoUploadWrapper>
      <Dialog
        onClose={onCancelUploadBefore}
        open={isConfirmationDialogOpen}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle sx={{ fontWeight: 'bold' }}>File Confirmation</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 'bold' }}>File Name</Typography>
              <Typography>{acceptedFiles[0]?.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: 'bold' }}>File Size</Typography>
              <Typography>{formatBytes(acceptedFiles[0]?.size)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: 'bold' }}>File Type</Typography>
              <Typography>{acceptedFiles[0]?.type}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', padding: '8px 16px' }}>
          <Button sx={{ color: '#555' }} onClick={onCancelUploadBefore}>
            Cancel
          </Button>
          <Button onClick={onUpload} autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={onCloseUploadErrorDialog}
        open={isUploadErrorDialogOpen}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle>
          {fileRejections[0]?.errors[0]?.code === 'file-too-small'
            ? 'File Not Found'
            : 'File Upload Error'}
          {fileUploadError && 'File Upload Failed'}
        </DialogTitle>
        <DialogContent>
          {fileRejections[0]?.errors[0]?.code === 'file-too-small' && (
            <Typography>
              Something went wrong while attempting to load this file. <br />
              <br />
              Please try again with a different file.
            </Typography>
          )}
          {fileRejections[0]?.errors[0]?.code === 'file-invalid-type' && (
            <Typography>
              We currently only support MP4 files. Please try again with a different file.
            </Typography>
          )}
          {fileRejections[0]?.errors[0]?.code === 'file-too-large' && (
            <Typography>
              The max file size is 10GB. Please try again a different file.micmimmmmka
            </Typography>
          )}
          {fileRejections[0]?.errors[0]?.code === 'too-many-files' && (
            <Typography>
              We're sorry, we currently only support one file upload at a time.
            </Typography>
          )}
          {fileUploadError && (
            <Typography>
              We're sorry, it seems your upload failed due to a server error. <br />
              <br />
              Error Message: {fileUploadError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseUploadErrorDialog} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default VideoUploadButton;
