import React, { useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Editor } from '@tinymce/tinymce-react';
import { useLocation } from 'react-router-dom';
import { getAllUsers, useCreateFeedThread, useGetChildThreads } from '@juno/client-api';
import { CommunityUser, Thread, ThreadBody } from '@juno/client-api/model';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';
import { DialogAriaWrapper, customShadow } from '@juno/ui';
import { MutationAction, calculateUserSearchFilters, onMutation, useSettings } from '@juno/utils';
import { useBreakpoint } from '@juno/utils/hooks';
import { useAnalyticsContext } from '../../../Contexts/AnalyticsContext';
import { convertTinyMceUsers, createTinyMceMentionSpan } from '../../ThreadEditor/helpers';
import ThreadPostComments from '../ThreadPostComments';
import { ThreadLoadingSkeleton } from '../helpers';
import { ThreadPostAuthorAvatar } from '../styles';
import './styles.css';

interface PostDetailDialogProps {
  open: boolean;
  siteId: string;
  platformId: string;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  thread: Thread;
  handleCloseDialog: () => void;
  refetch: () => void;
  currentCommunityUser: CommunityUser;
  onNavigate?: (props: any) => void;
  userCanInteract?: boolean;
  isInForum?: boolean;
}
const PostDetailDialog: React.FC<PostDetailDialogProps> = ({
  open,
  siteId,
  platformId,
  thread,
  handleCloseDialog,
  refetch,
  setData,
  currentCommunityUser,
  onNavigate,
  userCanInteract = true,
  isInForum = false,
}) => {
  const { xs } = useBreakpoint();
  const theme = useTheme();
  const { getImageSrc } = useSettings();
  const dialogRef = useRef<HTMLDivElement>(null);
  const [dirty, setDirty] = useState(false);
  const [threadReplyingToId, setThreadReplyingToId] = useState<string>(thread.id);
  const [isReplying, setIsReplying] = useState(false);
  const { firehoseActivity } = useAnalyticsContext();
  const location = useLocation();

  const editorRef = useRef<any>(null);
  const userMentions = useRef<CommunityUser[]>(thread.tagged_users ?? []);
  const handleRefetch = () => {
    refetchChildren();
    refetch();
  };

  const threadCreate = useCreateFeedThread(onMutation(MutationAction.CREATE, '', handleRefetch));

  const focusInput = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  const {
    data: threadChildren,
    isLoading: threadChildrenLoading,
    refetch: refetchChildren,
  } = useGetChildThreads(siteId, thread.id, {}, { query: { enabled: thread.num_replies > 0 } });

  const mentions_fetch = async (query: any, success: any) => {
    // TODO - do we want this to only search group members
    const filter = calculateUserSearchFilters(query.term.toLowerCase());
    const allUsers = await getAllUsers(platformId, {
      ...filter,
      order: 'first_name',
      limit: 10,
    });
    const tinyUsers = convertTinyMceUsers(allUsers, getImageSrc ?? (() => ''));
    success(tinyUsers);
  };

  return (
    <DialogAriaWrapper
      id={`feed_detail_dialog`}
      open={open}
      maxWidth='sm'
      fullWidth={true}
      fullScreen={xs ? true : false}
      onClose={handleCloseDialog}
      disableEnforceFocus
    >
      <Box
        className='device_padding_topinset'
        sx={{
          position: 'relative',
        }}
      >
        <DialogTitle sx={{ pb: threadChildren && threadChildren?.length > 0 ? '0' : 2 }}>
          Comments
        </DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      {threadChildrenLoading && <ThreadLoadingSkeleton />}
      {!threadChildrenLoading && (!threadChildren ?? threadChildren?.length == 0) && (
        <Box
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'
          height='50px'
          className='device_padding_topinset'
          sx={{ mb: 2 }}
        >
          <Typography color='primary'>No comments yet</Typography>
        </Box>
      )}
      {!threadChildrenLoading && threadChildren && threadChildren?.length > 0 && (
        <DialogContent
          id='feed_detail_dialog-dialog-description'
          sx={{ px: '12px', py: xs ? '8px' : '0px' }}
          ref={dialogRef}
        >
          <Box>
            <ThreadPostComments
              userCanInteract={userCanInteract}
              setData={setData}
              platformId={platformId}
              siteId={siteId}
              threads={threadChildren ?? []}
              refetchParentThread={handleRefetch}
              onNavigate={onNavigate}
              handleCommentClick={(newParent: Thread) => {
                setThreadReplyingToId(newParent.id);
                editorRef.current?.setContent('');
                focusInput();
                const span = createTinyMceMentionSpan(
                  editorRef.current,
                  newParent.author ?? ({} as CommunityUser),
                );
                editorRef.current?.insertContent(span.outerHTML);
              }}
              dialogRef={dialogRef}
            />
          </Box>
        </DialogContent>
      )}
      {userCanInteract && (
        <DialogActions sx={{ boxShadow: customShadow }}>
          <Box p='2px 8px' width='100%' display={'flex'} justifyContent='space-around'>
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
              <IconButton onClick={() => focusInput()} aria-label='Start comment'>
                <ThreadPostAuthorAvatar
                  src={getImageSrc(
                    currentCommunityUser.icon ||
                      currentCommunityUser.avatar ||
                      'UserIcon_CrowdHub.jpg',
                  )}
                />
              </IconButton>
            </Box>
            <Editor
              apiKey={process.env.NX_TINY_MCE_API_KEY}
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={''}
              onDirty={() => setDirty(true)}
              init={{
                contextmenu: false,
                max_height: 10,
                auto_focus: true,
                highlight_on_focus: true,
                placeholder: 'Add a comment...',
                content_css: '/styles.css',
                content_style: `.user_mention{ color: ${theme.palette.primary.main};, cursor: pointer; }`,
                mentions_selector: 'span.user_mention',
                mentions_menu_complete: (editor: any, userInfo: any) => {
                  userMentions.current = [...userMentions.current, userInfo];
                  const span = createTinyMceMentionSpan(editor, userInfo);
                  return span;
                },
                mentions_fetch: mentions_fetch,
                mentions_item_type: 'profile',
                height: 60,
                width: '100%',
                setup: (editor) => {
                  editor.setProgressState(true);
                },
                toolbar: false,
                menubar: false,
                statusbar: false,
              }}
              plugins={'autolink emoticons link mentions'}
            />
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
              <LoadingButton
                sx={{
                  ml: 2,
                }}
                loading={isReplying}
                aria-label='Post comment'
                variant='contained'
                disabled={!dirty}
                onClick={async () => {
                  if (editorRef.current?.getContent() === '') {
                    focusInput();
                    return;
                  }
                  setIsReplying(true);
                  // double check that the user mentions are in the body
                  const taggedUsers = userMentions.current?.filter((m) =>
                    editorRef.current?.getContent().includes(m.id),
                  );
                  const pageSlug = location.pathname.split('/').pop() || '';
                  const data = {
                    body: editorRef.current?.getContent() as unknown as ThreadBody,
                    author_id: currentCommunityUser.id,

                    parent_thread_id: threadReplyingToId,
                    tagged_users: taggedUsers,
                  } as Thread;
                  const newThread = await threadCreate.mutateAsync({
                    siteId: siteId,
                    feedId: thread.id,
                    data: {
                      ...data,
                      metadata: {
                        page_slug: pageSlug,
                      },
                    },
                  });
                  firehoseActivity(
                    ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_COMMUNITY,
                    thread?.parent_id || '',
                    null,
                    `${currentCommunityUser?.id || ''}`,
                    isInForum
                      ? ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_FORUM
                      : ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_FEED,
                    threadReplyingToId === thread.id
                      ? ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_COMMENT.value
                      : ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_REPLY.value,
                    '',
                    null,
                  );
                  setData((old) => {
                    const updated = old.map((t) => {
                      if (t.id === newThread.parent_thread_id) {
                        return { ...t, num_replies: t.num_replies + 1 };
                      }
                      return t;
                    });
                    return updated;
                  });
                  setIsReplying(false);
                  setThreadReplyingToId(thread.id);
                  editorRef.current?.setContent('');
                }}
              >
                Post
              </LoadingButton>
            </Box>
          </Box>
        </DialogActions>
      )}
    </DialogAriaWrapper>
  );
};
export default PostDetailDialog;
