import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  ChevronRightOutlined,
  DynamicFeedOutlined,
  PeopleAltOutlined,
  PeopleOutline,
  QuestionAnswer,
} from '@mui/icons-material';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { Box, Card, Drawer, IconButton, Tab, Tabs, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { Session } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import { CHAT_ASIDE, PEOPLE_ASIDE, QA_ASIDE, SUBBREAKOUT_ASIDE } from '../../constants';
import { useCallState } from '../../contexts/CallProvider';
import { useUIState } from '../../contexts/UIStateProvider';

const drawerWidth = 300;

interface AsideProps {
  onClose: () => void;
  children?: ReactNode;
}

export const Aside: React.FC<AsideProps> = ({ onClose, children }) => {
  const { showAside, setShowAside } = useUIState();
  const [tabState, setTabState] = useState(0);
  const [open, setOpen] = useState(false);
  const { isAdmin, isSmallScreen, user: currentUser } = useSettings();
  const { roomInfo } = useCallState();
  const sessionData = useMemo(() => {
    if (roomInfo) {
      return (roomInfo as Record<string, unknown>)['session'] as Session;
    }
    return {} as Session;
  }, [roomInfo]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabState(newValue);
    switch (newValue) {
      case 0:
        setShowAside(QA_ASIDE);
        break;
      case 1:
        setShowAside(SUBBREAKOUT_ASIDE);
        break;
      default:
        setShowAside(QA_ASIDE);
        break;
    }
  };
  useEffect(() => {
    switch (showAside) {
      case QA_ASIDE:
        setTabState(0);
        break;
      case SUBBREAKOUT_ASIDE:
        setTabState(1);
        break;
      default:
        setTabState(0);
        break;
    }
    setOpen(showAside !== null);
  }, [showAside]);

  const isAdminOrMod = useMemo(() => {
    if (isAdmin) return true;
    return sessionData?.moderators?.some((mod) => mod.id === currentUser?.id);
  }, [sessionData, isAdmin, currentUser]);

  // TODO generate tabs array based on sessionData

  return (
    <Box position={'relative'} height={'100%'}>
      <Drawer
        className='call-aside'
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: '#2f2f2f',
          },
          '& .MuiDrawer-root': {
            position: 'absolute',
          },
          '& .MuiPaper-root:not(.MuiCard-root)': {
            position: isSmallScreen ? 'fixed' : 'absolute',
            zIndex: 11,
            overflow: 'visible',
          },
        }}
        variant={isSmallScreen ? 'temporary' : 'persistent'}
        anchor='right'
        open={open}
        onClose={() => {
          onClose();
          setOpen(false);
        }}
      >
        <Box
          height={'100%'}
          p={1}
          color={'white'}
          position={'relative'}
          width={'100%'}
          overflow={'visible'}
        >
          <Box
            sx={{
              top: 0,
              position: 'absolute',
              left: '-50px',
              height: 'fit-content',
              display: 'flex',
              justifyContent: 'center',
              width: '50px',
              bgcolor: '#2f2f2f',
              borderRadius: '10px 0px 00px 10px',
            }}
          >
            {showAside === CHAT_ASIDE || showAside === PEOPLE_ASIDE ? (
              <IconButton onClick={onClose}>
                <ChevronRightOutlined sx={{ color: 'white' }} />
              </IconButton>
            ) : (
              <Tabs
                value={tabState}
                onChange={handleChange}
                orientation='vertical'
                sx={{
                  color: 'white',
                  borderRight: 1,
                  borderColor: 'divider',
                  width: '50px',
                  height: '100%',
                  borderRadius: '10px 0px 0px 10px',

                  '& .MuiTab-root.Mui-selected': {
                    color: 'white',
                  },
                }}
                TabIndicatorProps={{
                  style: {
                    // TODO
                    backgroundColor: 'rgba(255,255,255,0.85)',
                  },
                }}
              >
                <Tab
                  icon={tabState === 0 ? <QuestionAnswer /> : <QuestionAnswerOutlinedIcon />}
                  sx={{ minWidth: 'unset', maxWidth: '50px' }}
                  {...a11yProps(0)}
                />
                {isAdminOrMod && (
                  <Tab
                    icon={<DynamicFeedOutlined />}
                    sx={{ minWidth: 'unset', maxWidth: '50px' }}
                    {...a11yProps(1)}
                  />
                )}
              </Tabs>
            )}
          </Box>
          {children}
        </Box>
      </Drawer>
    </Box>
  );
};

export default Aside;

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};
