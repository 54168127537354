import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SaveBarWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const SaveBarBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDirty',
})<{
  isDirty: boolean;
}>(({ isDirty, theme }) => ({
  position: 'fixed',
  top: isDirty ? theme.mixins.toolbar.minHeight : -50,
  right: 100,
  marginTop: 25,
  padding: 10,
  gap: 10,
  borderRadius: 8,
  height: 50,
  zIndex: 5,
  transition: '100ms all',
  display: 'flex',
  alignItems: 'center',
  minWidth: 250,
  backgroundColor: theme.palette.background.paper,
}));
