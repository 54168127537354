import * as Yup from 'yup';
import { Course } from '@juno/client-api/model';

interface CourseForm extends Course {
  certificate_template_id: string | null;
}

const dateError = 'Date is invalid';
const numberError = 'Number is invalid';
const videoTypesSupported = ['youtube', 'youtu.be', 'vimeo', 'wistia.com'];
const validationSchema = Yup.object().shape({
  id: Yup.string(),
  abstract: Yup.string().max(78, 'Preview must be less than 78 characters'),
  banner: Yup.string(),
  certificate: Yup.string().url('Certificate must be a valid URL'),
  credits: Yup.string()
    .nullable()
    .typeError(numberError)
    .matches(/^(\d{1,2}\.\d{1,2}|\d{1,2})$/, 'Must be an decimal between 0.00 and 99.99'),
  date_released: Yup.date().typeError(dateError).nullable(),
  date_start: Yup.date()
    .typeError(dateError)
    .nullable()
    // .max(Yup.ref('date_end'), 'must be before end date')
    .when('date_released', {
      is: (date_released: Date) => date_released,
      then: Yup.date()
        .required('Required')
        // .min(Yup.ref('date_released'), 'must be after release date')
        .test('start-isAfterOrEqual', 'must be on or after release date', function (date_start) {
          if (date_start && this.parent.date_released) {
            const start = date_start;
            const release: Date = this.parent.date_released;
            start.setSeconds(0);
            release.setSeconds(0);
            return start >= release;
          }
          return true;
        })
        .typeError(dateError)
        .nullable(),
    }),
  date_end: Yup.date()
    .nullable()
    .typeError(dateError)
    .when('date_released', {
      is: (date_released: Date) => date_released,
      then: Yup.date()
        .typeError(dateError)
        .required('Required')
        .min(Yup.ref('date_released'), 'must be after release date')
        .test('after_start', 'must be after start date', function (value) {
          if (this.parent.date_start && value) {
            const start = this.parent.date_start;
            const end = value;
            return end > start;
          }
          return true;
        })
        .nullable(),
    }),
  date_open_enrollment: Yup.date()
    .typeError(dateError)
    .nullable()
    .when('date_released', {
      is: (date_released: Date) => date_released,
      then: Yup.date()
        .typeError(dateError)
        .required('Required')
        // .min(Yup.ref('date_released'), 'must be after release date')
        .test(
          'open-isAfterOrEqual',
          'must be on or after release date',
          function (date_open_enrollment) {
            if (date_open_enrollment && this.parent.date_released) {
              const open = date_open_enrollment;
              const release: Date = this.parent.date_released;
              open.setSeconds(0);
              release.setSeconds(0);
              return open >= release;
            }
            return true;
          },
        )
        .nullable(),
    }),
  date_lock_enrollment: Yup.date()
    .typeError(dateError)
    .nullable()
    .when('date_released', {
      is: (date_released: Date) => date_released,
      then: Yup.date()
        .typeError(dateError)
        .required('Required')
        .min(Yup.ref('date_open_enrollment'), 'must be after open enrollment date')
        // .max(Yup.ref('date_end'), 'must be before end date')
        .test('before_end', 'must be on or before end date', function (value) {
          if (this.parent.date_end && value) {
            const end = this.parent.date_end;
            const lock = value;
            end.setSeconds(0);
            lock.setSeconds(0);
            return lock <= end;
          }
          return true;
        })
        .nullable(),
    }),
  description: Yup.string(),
  enrollment_required: Yup.boolean(),
  icon: Yup.string(),
  max_enrollments: Yup.number()
    .typeError(numberError)
    .test(
      'max_enrollment',
      `Max enrollments must be greater than current enrollments`,
      function (value) {
        if (value && this.parent.enrollments_count) {
          return value >= this.parent.enrollments_count;
        }
        return true;
      },
    ),
  metadata: Yup.object(),
  slug: Yup.string().required('Required'),
  title: Yup.string().required('Required').max(90, 'Title character limit: 90'),
  tags: Yup.array(),
  instructors: Yup.array(),
  prerequisites: Yup.array(),
  grants_requirements: Yup.array(),
  passing_percent: Yup.string()
    .nullable()
    .typeError(numberError)
    .matches(/^[0-9]+$/, 'Must be an integer')
    .test('passing_percent', 'must be between 0 and 100', function (value) {
      if (value) {
        return parseInt(value) >= 0 && parseInt(value) <= 100;
      }
      return true;
    }),
  banner_video: Yup.string()
    .url()
    .test('url', 'Supported video types: MP4 link, Vimeo, Wistia, and YouTube', function (value) {
      if (value) {
        const lowerValue = value.toLowerCase();
        return (
          lowerValue.substr(value.length - 4).includes('.mp4') ||
          videoTypesSupported.some((v) => lowerValue.includes(v))
        );
      }
      return true;
    }),
  education_credits: Yup.array(),
});

const unSteveofyData = (data: any) => {
  if (typeof data.prerequisites[0] == 'string') {
    data.prerequisites = data.prerequisites.map((id: string) => {
      return { id: id };
    });
  }
  if (typeof data.grants_requirements[0] == 'string') {
    data.grants_requirements = data.grants_requirements.map((id: string) => {
      return { id: id };
    });
  }
  return data as CourseForm;
};

export default validationSchema;
