import React, { useEffect, useState } from 'react';
import { ExpandMoreOutlined, FolderOpenOutlined } from '@mui/icons-material';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GroupResource, SearchContentTypeEnum } from '@juno/client-api/model';
import { BasicKebobMenu, FileTypeIcon, UserQuickView } from '@juno/ui';
import { getContentRoute, getTimeSinceCreated, handleFileDownload, useSettings } from '@juno/utils';

export interface FileTree {
  [folder: string]: FileTree | GroupResource;
}

interface FileTreeViewProps {
  fileTree: FileTree;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  itemToEdit: GroupResource;
  setItemToEdit: React.Dispatch<React.SetStateAction<GroupResource>>;
  onEditSubmit: () => void;
  onClickDelete: (file: GroupResource) => void;
  iAmAdmin: boolean;
  isAdminPage?: boolean;
}

const FileTreeView: React.FC<FileTreeViewProps> = ({
  fileTree,
  isEditing,
  setIsEditing,
  itemToEdit,
  setItemToEdit,
  onEditSubmit,
  onClickDelete,
  iAmAdmin,
  isAdminPage,
}) => {
  const { user: currentUser, site } = useSettings();
  const [userQuickViewAnchorEl, setUserQuickViewAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<null | string>(null);
  const showMenu = (resource: GroupResource) => currentUser?.id === resource.author_id;
  const navigateRoute = useNavigate();
  const showAdminMenu = () => iAmAdmin || isAdminPage;
  const showDownloadMenu = (resource: GroupResource) =>
    currentUser?.id !== resource.author_id && !isAdminPage && !iAmAdmin;

  const renderTree = (tree: FileTree, parentPath = '') => {
    const entries = Object.entries(tree);
    const files = entries
      .filter(([name, content]) => !(typeof content === 'object' && !('download' in content)))
      .sort(([nameA], [nameB]) => nameA.localeCompare(nameB));
    const folders = entries
      .filter(([name, content]) => typeof content === 'object' && !('download' in content))
      .sort(([nameA], [nameB]) => nameA.localeCompare(nameB));

    return (
      <>
        {files.map(([name, content]) => {
          const fullPath = parentPath ? `${parentPath}/${name}` : name;
          const file = content as GroupResource;
          return (
            <ListItem key={fullPath} sx={{ ml: isEditing ? 0 : 4 }}>
              {(!isEditing || (isEditing && itemToEdit?.id !== file.id)) && (
                <Grid container>
                  <Grid item xs={1}>
                    <FileTypeIcon fileType={file.download.filetype} />
                  </Grid>
                  <Grid item xs={8}>
                    <Box>
                      <Typography>{file.download.title}</Typography>
                      <Typography
                        variant='caption'
                        sx={{
                          maxHeight: '100px',
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {file.download.description}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2} justifyContent={'flex-end'} textAlign={'right'} pr={3}>
                    <Stack>
                      <Typography
                        variant='caption'
                        sx={{ cursor: 'pointer' }}
                        onClick={(e) => {
                          setUserQuickViewAnchorEl(e.currentTarget);
                          setSelectedUser(file.author?.id || null);
                        }}
                      >{`by ${file.author?.first_name} ${file.author?.last_name}`}</Typography>
                      <Typography variant='caption'>
                        {getTimeSinceCreated(false, file.download.date_created)}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>
                    {(showMenu(file) || showAdminMenu()) && (
                      <BasicKebobMenu>
                        <Box>
                          {showMenu(file) && (
                            <MenuItem
                              onClick={() => {
                                setItemToEdit(file);
                                setIsEditing(true);
                              }}
                              aria-label='Edit file'
                            >
                              <ListItemIcon>
                                <EditRoundedIcon color='primary' fontSize='small' />
                              </ListItemIcon>
                              <Typography>Edit</Typography>
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={() =>
                              handleFileDownload(file.download.url, file.download.title)
                            }
                            aria-label='Download file'
                          >
                            <ListItemIcon>
                              <FileDownloadRoundedIcon fontSize='small' color='primary' />
                            </ListItemIcon>
                            <Typography>Download</Typography>
                          </MenuItem>
                          <MenuItem onClick={() => onClickDelete(file)} aria-label='Delete file'>
                            <ListItemIcon>
                              <DeleteForeverRoundedIcon color='primary' fontSize='small' />
                            </ListItemIcon>
                            <Typography>Delete</Typography>
                          </MenuItem>
                        </Box>
                      </BasicKebobMenu>
                    )}
                    {showDownloadMenu(file) && (
                      <IconButton
                        sx={{ zIndex: 1 }}
                        onClick={() => handleFileDownload(file.download.url, file.download.title)}
                        aria-label='Download file'
                      >
                        <FileDownloadRoundedIcon fontSize='small' color='primary' />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              )}
              {isEditing && itemToEdit?.id === file.id && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder={file.download.title}
                      value={itemToEdit.download.title || ''}
                      label='Title'
                      onChange={(e) =>
                        setItemToEdit((prevItem) => ({
                          ...prevItem,
                          download: { ...prevItem.download, title: e.target.value },
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label='Description'
                      placeholder={'Enter a description'}
                      multiline
                      minRows={2}
                      value={itemToEdit.download.description}
                      onChange={(e) =>
                        setItemToEdit((prevItem) => ({
                          ...prevItem,
                          download: { ...prevItem.download, description: e.target.value },
                        }))
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box pt={2} display='flex' justifyContent='flex-end'>
                      <Button
                        size='small'
                        color='inherit'
                        onClick={() => {
                          setIsEditing(false);
                          setItemToEdit({} as GroupResource);
                        }}
                        aria-label='Cancel editing'
                        variant='text'
                        sx={{ textTransform: 'none' }}
                      >
                        Cancel
                      </Button>
                      <Button
                        size='small'
                        color='primary'
                        onClick={onEditSubmit}
                        aria-label='Save changes'
                        variant='contained'
                        sx={{ textTransform: 'none' }}
                      >
                        Save
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </ListItem>
          );
        })}
        <UserQuickView
          anchorEl={userQuickViewAnchorEl}
          userId={selectedUser || ''}
          open={Boolean(userQuickViewAnchorEl)}
          setAnchorEl={setUserQuickViewAnchorEl}
          onViewProfile={(userEmail: string): void => {
            navigateRoute(
              getContentRoute(site?.slug || '', selectedUser || '', SearchContentTypeEnum.user),
            );
          }}
          onMessageUser={function (userId: string): void {
            throw new Error('Function not implemented.');
          }}
        />
        {folders.map(([name, content]) => {
          const fullPath = parentPath ? `${parentPath}/${name}` : name;
          return (
            <Accordion key={fullPath} sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                sx={{ flexDirection: 'row-reverse', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}
              >
                <FolderOpenOutlined />
                <Typography variant='subtitle1' pl={1}>
                  {decodeURIComponent(name)}
                </Typography>
              </AccordionSummary>
              <List sx={{ ml: 4 }}>{renderTree(content as FileTree, fullPath)}</List>
            </Accordion>
          );
        })}
      </>
    );
  };

  return (
    <Card sx={{ mb: 2 }}>
      <List sx={{ pb: 0 }}>{renderTree(fileTree)}</List>
    </Card>
  );
};

export default FileTreeView;
