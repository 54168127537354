import StarIcon from '@mui/icons-material/Star';
import { Box, Card, CardActionArea, Chip, Paper, Typography } from '@mui/material';
import { CertificateTemplate } from '@juno/client-api/model';
import LivePreview from './LivePreview';

interface ListCertificatesProps {
  certificates?: CertificateTemplate[];
  onNav: (id: string) => void;
}

const ListCertificates: React.FC<ListCertificatesProps> = ({ certificates, onNav }) => {
  const handleNav = (id: string | undefined) => {
    if (!id) {
      return;
    }
    onNav(id);
  };

  if (!certificates) {
    return null;
  }

  const sortedCertificates = [...certificates].sort((a, b) => {
    if (!a?.name || !b?.name) return 0; // if name is null/undefined, return 0 to maintain original order
    return a.name.localeCompare(b.name);
  });

  return (
    <Box>
      {sortedCertificates.map((certificate) => (
        <CertificateCard key={certificate.id} onClick={handleNav} certificate={certificate} />
      ))}
    </Box>
  );
};

export default ListCertificates;

interface CertificateCardProps {
  certificate: CertificateTemplate;
  onClick: (id: string | undefined) => void;
}

const CertificateCard: React.FC<CertificateCardProps> = ({ certificate, onClick }) => {
  return (
    <Card sx={{ mt: 2, maxHeight: '147px', maxWidth: '100%' }}>
      <CardActionArea
        onClick={() => onClick(certificate.id)}
        sx={{
          p: 1,
          minHeight: '147px',
          maxHeight: '100%',
          maxWidth: '100%',
          position: 'relative',
        }}
      >
        <>
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              pl: 2,
              overflow: 'hidden',
              flexShrink: 0,
              transform: 'scale(0.15)',
              transformOrigin: 'top left',
              width: '1225px',
            }}
            elevation={0}
            id='certificate-thumbnail'
          >
            {certificate.html_content && (
              <LivePreview html={certificate.html_content} isThumbnail />
            )}
          </Paper>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              position: 'absolute',
              top: 50,
              left: 220,
            }}
          >
            <>
              {certificate.name && <Typography variant='h5'>{certificate.name}</Typography>}
              {certificate.metadata?.['description'] && (
                <Typography>{certificate.metadata?.['description'] as string}</Typography>
              )}
            </>
          </Box>
          {certificate.metadata?.['default'] && (
            <Chip
              label='Default'
              icon={<StarIcon style={{ color: 'white', fontSize: 16 }} />}
              sx={{ color: 'white', backgroundColor: '#636363', marginInlineStart: 'auto' }}
            />
          )}
        </>
      </CardActionArea>
    </Card>
  );
};
