import { Avatar, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CourseBannerContainer = styled(Box)(({ theme }) => ({
  height: '84px',
  backgroundColor: '#00000010',
  display: 'flex',
  alignItems: 'center',
  padding: '0 10px',
  cursor: 'pointer',
}));

export const CourseBannerImage = styled(Avatar)({
  width: '106px',
  height: '68px',
  marginRight: '10px',
});

export const CourseBannerContent = styled(Box)({
  display: 'flex',
  flex: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const CourseBannerLeftCol = styled(Box)({
  display: 'flex',
  flex: 1,
  alignItems: 'center',
});

export const CourseBannerSteps = styled(Typography)({
  lineHeight: '1',
  fontSize: '.75rem',
  color: '#595959',
  marginLeft: '2px',
});

export const CourseBannerRightCol = styled(Box)(({ theme }) => ({
  height: '26px',
  backgroundColor: theme.palette.primary.main,
  borderRadius: '18px',
  textAlign: 'center',
  padding: '0px 10px',
}));

export const CourseBannerPercent = styled(Typography)({
  lineHeight: '26px',
});
