import React from 'react';
import { Dayjs } from 'dayjs';

export type CourseFilterContextProps = {
  siteId: string;
  platformId: string;
  tempFilter: any;
  handleDateUpdate: (dateVal: Dayjs | null, key: string) => void;
  handleStringListUpdate: (vals: string[], key: string) => void;
  handleTagListUpdate: (vals: string[], key: string) => void;
  instructorsRemoved: string[];
  setInstructorsRemoved: React.Dispatch<React.SetStateAction<string[]>>;
  tagsRemoved: any;
  setTagsRemoved: React.Dispatch<React.SetStateAction<any>>;
};

const defaultCourseFilterContext: CourseFilterContextProps = {
  siteId: '',
  platformId: '',
  tempFilter: {},
  handleDateUpdate: () => {},
  handleStringListUpdate: () => {},
  handleTagListUpdate: () => {},
  instructorsRemoved: [],
  setInstructorsRemoved: () => {},
  tagsRemoved: {},
  setTagsRemoved: () => {},
};

export const CourseFilterContext = React.createContext<CourseFilterContextProps>(
  defaultCourseFilterContext,
);
