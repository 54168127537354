import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getGetPagesQueryKey, useCreatePage, useGetPages } from '@juno/client-api';
import { PageTypeEnum, SearchContentTypeEnum, Site } from '@juno/client-api/model';
import { GridV2 } from '@juno/modules';
import { Container, DefaultSearchSortFilter, DialogAriaWrapper } from '@juno/ui';
import { slugify, usePagesSearchFilter, useSettings } from '@juno/utils';

interface LibraryAdminProps {
  site?: Site;
}
const LibraryAdmin: React.FC<LibraryAdminProps> = ({ site }) => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newPageName, setNewPageName] = useState('');
  const [newPageSlug, setNewPageSlug] = useState('');
  const [, , searchFilter, setSearch] = usePagesSearchFilter();
  const [sort, setSort] = useState('title');
  const {
    data: pages,
    isFetching: isLoading,
    refetch,
  } = useGetPages(site?.id || '', {
    filter: { type: PageTypeEnum.library, ...searchFilter },
    order: sort,
  });
  const { configs } = useSettings();
  const navigate = useNavigate();
  const createPage = useCreatePage();
  const queryClient = useQueryClient();

  const handleNameChange = (name: string) => {
    setNewPageName(name);
    setNewPageSlug(slugify(name));
  };

  const handleSlugChange = (slug: string) => {
    setNewPageSlug(slugify(slug));
  };

  const handleOpenCreatePage = (e: any) => {
    e.stopPropagation();
    setCreateDialogOpen(true);
  };

  const handleCreatePage = async () => {
    setIsSaving(true);
    try {
      await createPage.mutateAsync({
        siteId: site?.id || '',
        data: {
          id: '',
          site: site?.id || '',
          title: newPageName,
          slug: newPageSlug,
          type: PageTypeEnum.library,
          components: [],
        },
      });
      await queryClient.invalidateQueries(
        getGetPagesQueryKey(site?.id || '', {
          filter: { type: PageTypeEnum.library },
        }),
      );
      setIsSaving(false);
      setNewPageName('');
      setNewPageSlug('');
      setCreateDialogOpen(false);
      navigate(`/${site?.slug}/admin/pages/${newPageSlug}`);
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  const cancelCreatePage = () => {
    setNewPageName('');
    setNewPageSlug('');
    setCreateDialogOpen(false);
  };

  const items = pages?.map((page) => ({
    title: page.title,
    slug: page.slug,
    abstract: `/library/${page.slug}`,
    components: page.components,
    id: page.id,
    icon: '',
  }));

  return (
    <Container>
      <Card sx={{ p: 2, mb: 4, boxShadow: 1 }}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='overline' sx={{ mb: 1 }}>
            Library
          </Typography>
        </Stack>
        <Typography variant='body2' sx={{ mb: 5, mt: 1, maxWidth: 900 }}>
          These are pages around your site that can be edited and linked to. Clicking on one will
          take you to the page builder where you can add, edit, and rearrange sections to create
          engaging landing pages.
        </Typography>
        <DefaultSearchSortFilter
          buttonDisabled={false}
          buttonText={'New Page'}
          onClickButton={() => setCreateDialogOpen(true)}
          setSearch={(value: string) => setSearch(value)}
          setSort={setSort}
          sort={sort}
          showFilter={false}
          sortOptions={[
            { value: 'title', label: 'Alphabetical' },
            { value: 'slug', label: 'Page Slug' },
          ]}
        />
        <GridV2
          items={items || []}
          type={SearchContentTypeEnum.page}
          isLoading={isLoading}
          isAdmin={true}
        />

        <DialogAriaWrapper
          open={createDialogOpen}
          onClose={() => cancelCreatePage()}
          id='create_new_page'
        >
          <Box>
            <Typography variant='h6' sx={{ mt: 3, ml: 3, mb: 2 }}>
              Create a new library item
            </Typography>
          </Box>
          <DialogContent>
            <Box>
              <TextField
                name='name'
                variant='filled'
                label='name'
                value={newPageName}
                onChange={(e) => handleNameChange(e.target.value)}
                InputProps={{ disableUnderline: true }}
                sx={{ mr: 1 }}
              />
              <TextField
                name='slug'
                variant='filled'
                label='slug'
                value={newPageSlug}
                onChange={(e) => handleSlugChange(e.target.value)}
                InputProps={{ disableUnderline: true }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelCreatePage()}>Cancel</Button>
            <LoadingButton
              loading={isSaving}
              onClick={() => handleCreatePage()}
              variant='contained'
            >
              Create
            </LoadingButton>
          </DialogActions>
        </DialogAriaWrapper>
      </Card>
    </Container>
  );
};

export default LibraryAdmin;

// import React, { useState } from 'react';
// import AddIcon from '@mui/icons-material/Add';
// import SearchIcon from '@mui/icons-material/Search';
// import { LoadingButton } from '@mui/lab';
// import {
//   Box,
//   Button,
//   Card,
//   DialogActions,
//   DialogContent,
//   Grid,
//   IconButton,
//   InputAdornment,
//   InputBase,
//   Skeleton,
//   Stack,
//   TextField,
//   Tooltip,
//   Typography,
// } from '@mui/material';
// import { useQueryClient } from 'react-query';
// import { useNavigate } from 'react-router-dom';
// import { getGetPagesQueryKey, useCreatePage, useGetPages } from '@juno/client-api';
// import { PageTypeEnum, Site } from '@juno/client-api/model';
// import { JunoModule } from '@juno/modules';
// import { Container, DialogAriaWrapper } from '@juno/ui';
// import { slugify, usePagesSearchFilter, useSettings } from '@juno/utils';

// const dummySkeletonArr1 = [1, 2, 3];
// interface LibraryAdminProps {
//   site?: Site;
// }
// const LibraryAdmin: React.FC<LibraryAdminProps> = ({ site }) => {
//   const [createDialogOpen, setCreateDialogOpen] = useState(false);
//   const [isSaving, setIsSaving] = useState(false);
//   const [newPageName, setNewPageName] = useState('');
//   const [newPageSlug, setNewPageSlug] = useState('');
//   const [newPageType, setNewPageType] = useState<PageTypeEnum>(PageTypeEnum.library);
//   const [debouncedSearch, search, searchFilter, setSearch] = usePagesSearchFilter();
//   const { data: pages, isFetching: isLoading } = useGetPages(site?.id || '', {
//     filter: { type: PageTypeEnum.library, ...searchFilter },
//   });
//   const { configs } = useSettings();
//   const navigate = useNavigate();
//   const createPage = useCreatePage();
//   const queryClient = useQueryClient();

//   const handleNameChange = (name: string) => {
//     setNewPageName(name);
//     setNewPageSlug(slugify(name));
//   };

//   const handleSlugChange = (slug: string) => {
//     setNewPageSlug(slugify(slug));
//   };

//   const handleOpenCreatePage = (e: any) => {
//     e.stopPropagation();
//     setCreateDialogOpen(true);
//   };

//   const handleCreatePage = async () => {
//     setIsSaving(true);
//     try {
//       await createPage.mutateAsync({
//         siteId: site?.id || '',
//         data: {
//           id: '',
//           site: site?.id || '',
//           title: newPageName,
//           slug: newPageSlug,
//           type: newPageType,
//           components: [],
//         },
//       });
//       await queryClient.invalidateQueries(
//         getGetPagesQueryKey(site?.id || '', { filter: { type: PageTypeEnum.library } }),
//       );
//       setIsSaving(false);
//       setNewPageName('');
//       setNewPageSlug('');
//       setCreateDialogOpen(false);
//     } catch (e) {
//       console.error(e);
//       setIsSaving(false);
//     }
//   };

//   const cancelCreatePage = () => {
//     setNewPageName('');
//     setNewPageSlug('');
//     setCreateDialogOpen(false);
//   };

//   return (
//     <Container>
//       <Card sx={{ mt: 2, p: 3, boxShadow: (theme) => theme.shadows[1] }}>
//         <Stack direction='row' justifyContent='space-between'>
//           <Typography variant='h6' sx={{ mb: 1, mt: 1 }}>
//             Library Content
//           </Typography>
//           <InputBase
//             sx={{ minWidth: 260, background: 'rgba(0,0,0,.05)', borderRadius: 8, pl: 2 }}
//             startAdornment={
//               <InputAdornment position='start'>
//                 <SearchIcon />
//               </InputAdornment>
//             }
//             onChange={(e) => setSearch(e.target.value)}
//             size='small'
//             placeholder='Search by title or slug'
//           />
//         </Stack>
//         <Typography variant='body2' sx={{ mb: 4, mt: 1 }}>
//           Library content works the exact same as creating a custom page, but will all be grouped
//           together for users on the site. Create articles, blog posts, or any other sort of
//           reference material here. Users will then be able to search and access the content through
//           the library landing page.
//         </Typography>
//         <Grid container spacing={3} p={3}>
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             md={3}
//             display={'flex'}
//             alignItems={'center'}
//             justifyContent={'center'}
//             sx={{ height: 270 }}
//           >
//             <Tooltip title='Create new Library content'>
//               <IconButton
//                 sx={{ padding: 3, background: 'rgba(0,0,0,.03)' }}
//                 onClick={handleOpenCreatePage}
//               >
//                 <AddIcon sx={{ fontSize: '1.8rem' }} />
//               </IconButton>
//             </Tooltip>
//           </Grid>
//           {isLoading && dummySkeletonArr1.map((_, i) => <SkeletonPagesTile key={i} />)}
//           {!isLoading &&
//             pages?.map((page) => (
//               <Grid item xs={12} sm={6} md={3} key={page.id}>
//                 <Card
//                   sx={{
//                     height: 270,
//                     cursor: 'pointer',
//                     transition: '200ms',
//                     '&:hover': {
//                       transform: 'translateY(-4px) scale(1.04)',
//                     },
//                   }}
//                   onClick={() => navigate(`/${site?.slug}/admin/pages/${page.slug}`)}
//                 >
//                   <Stack direction='column' p={2} pt={0} alignItems={'center'}>
//                     {page.components?.map((component, i) => {
//                       if (i > 1) {
//                         return <></>;
//                       }
//                       return (
//                         <Box
//                           key={i}
//                           sx={{
//                             transform: 'scale(.38)',
//                             transformOrigin: 'top',
//                             width: '300%',
//                             pointerEvents: 'none',
//                           }}
//                         >
//                           {page.components.map((component) => (
//                             <JunoModule
//                               key={component.id}
//                               component={component}
//                               siteId={site?.id || ''}
//                               configs={configs || []}
//                             />
//                           ))}
//                           {/* <ComponentDisplay type={component.type} /> */}
//                         </Box>
//                       );
//                     })}
//                   </Stack>
//                 </Card>{' '}
//                 <Typography sx={{ mt: 1, fontWeight: 600, fontSize: '.9rem' }}>
//                   {page.title}
//                 </Typography>
//               </Grid>
//             ))}
//           <DialogAriaWrapper
//             open={createDialogOpen}
//             onClose={() => cancelCreatePage()}
//             id='create_new_page'
//           >
//             <Box>
//               <Typography variant='h6' sx={{ mt: 3, ml: 3, mb: 2 }}>
//                 Create Library Content
//               </Typography>
//             </Box>
//             <DialogContent>
//               <Box>
//                 <TextField
//                   name='name'
//                   variant='filled'
//                   label='name'
//                   value={newPageName}
//                   onChange={(e) => handleNameChange(e.target.value)}
//                   InputProps={{ disableUnderline: true }}
//                   sx={{ mr: 1 }}
//                 />
//                 <TextField
//                   name='slug'
//                   variant='filled'
//                   label='slug'
//                   value={newPageSlug}
//                   onChange={(e) => handleSlugChange(e.target.value)}
//                   InputProps={{ disableUnderline: true }}
//                 />
//               </Box>
//             </DialogContent>
//             <DialogActions>
//               <Button onClick={() => cancelCreatePage()}>Cancel</Button>
//               <LoadingButton
//                 loading={isSaving}
//                 onClick={() => handleCreatePage()}
//                 variant='contained'
//               >
//                 Create
//               </LoadingButton>
//             </DialogActions>
//           </DialogAriaWrapper>
//         </Grid>
//       </Card>
//     </Container>
//   );
// };

// export default LibraryAdmin;

// export const SkeletonPagesTile: React.FC = () => {
//   return (
//     <Grid item xs={12} md={3}>
//       <Skeleton variant='rectangular' height={250} sx={{ borderRadius: 2 }} />
//       <Skeleton variant='text' height={20} sx={{ mt: 1, width: '50%' }} />
//     </Grid>
//   );
// };
