import * as React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Chip, Tooltip, Typography } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useQueryClient } from 'react-query';
import {
  getGetAllCourseRequirementsQueryKey,
  useCreateCourseRequirement,
  useGetAllCourseRequirements,
} from '@juno/client-api';
import { CourseRequirement } from '@juno/client-api/model';
import { ConfirmDialog } from '@juno/ui';
import { MutationAction, onMutation } from '@juno/utils';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
interface AutocompletePrereqsProps {
  formik: any;
  name: string;
  label: string;
  siteId: string;
  disabled?: boolean;
  tooltip?: string;
  chipIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
  allowCreateNew: boolean;
  grantNewEquivalent: boolean;
  startingPrereqs?: CourseRequirement[];
}

interface DialogState {
  open: boolean;
  equivalentName: string;
  prereqName: string;
}

interface CourseRequirementOption extends CourseRequirement {
  label?: string;
}

interface CachePrereq {
  prereq?: CourseRequirement;
  loading: boolean;
}

const AutocompletePrereqs: React.FC<AutocompletePrereqsProps> = ({
  formik,
  name,
  label,
  siteId,
  disabled,
  tooltip,
  chipIcon,
  grantNewEquivalent,
  allowCreateNew,
  startingPrereqs,
}) => {
  const cachedPrereq = React.useRef<CachePrereq>({ prereq: undefined, loading: false });
  const [open, setOpen] = React.useState(false);
  const [dialogState, setDialogState] = React.useState<DialogState>({
    open: false,
    equivalentName: '',
    prereqName: '',
  });
  const [options, setOptions] = React.useState<CourseRequirement[]>([]);
  const [selected, setSelected] = React.useState<CourseRequirement[] | undefined>(
    startingPrereqs || [],
  );
  const filter = createFilterOptions<CourseRequirementOption>();
  const [input, setInput] = React.useState<string>(formik.values[name]?.title || '');
  const loading = open && options.length === 0;

  const queryClient = useQueryClient();
  const { data: prereqs, isLoading } = useGetAllCourseRequirements(siteId);
  const refetch = () => {
    queryClient.invalidateQueries(getGetAllCourseRequirementsQueryKey(siteId));
  };
  const createCourseRequirement = useCreateCourseRequirement(
    onMutation(MutationAction.CREATE, 'CourseRequirement', refetch),
  );

  const handleSelect = (reason: string, value: CourseRequirement[] | undefined) => {
    if (value && selected && value.length > selected.length) {
      // Validate that new prereq is not an equivalent of one that was added already
      const selectedPrereq = value.find((v) => !selected.find((s) => s.id === v.id));
      if (selectedPrereq) {
        const isEquivalentOfSelected = selected.find((s) =>
          s.equivalents?.find((e) => e.id === selectedPrereq.id),
        );
        const hasEquivalentSelected = selected.find((s) =>
          selectedPrereq.equivalents?.find((e) => e.id === s.id),
        );
        if (isEquivalentOfSelected) {
          setDialogState({
            open: true,
            equivalentName: isEquivalentOfSelected.name,
            prereqName: selectedPrereq.name,
          });
        } else if (hasEquivalentSelected) {
          setDialogState({
            open: true,
            equivalentName: hasEquivalentSelected.name,
            prereqName: selectedPrereq.name,
          });
        }
      }

      // creating a new prereq from the dropdown, cache it and select in after its refetched
      if (selectedPrereq && (selectedPrereq as CourseRequirementOption).label) {
        const data = selectedPrereq;
        cachedPrereq.current = { prereq: selectedPrereq, loading: true };
        createCourseRequirement.mutate({ siteId, data });
        return;
      }
    }
    formik.setFieldValue(name, value);
    setSelected(value);
  };

  React.useEffect(() => {
    const repairedData = selected?.map((prereq) => {
      const found = prereqs?.find((p) => p.name === prereq.name);
      return found || prereq;
    });
    setSelected(repairedData);
  }, [options]);

  React.useEffect(() => {
    if (prereqs) {
      setOptions(prereqs);
      const cached = prereqs.find((p) => p.name === cachedPrereq.current?.prereq?.name);
      if (cached) {
        setSelected([...(selected || []), cached]);
        // have to do this after we mutate
        formik.setFieldValue(name, [cached.id]);
        formik.dirty = true;
      }
      cachedPrereq.current = { prereq: undefined, loading: false };
    }
  }, [isLoading, prereqs]);

  return (
    <>
      <Tooltip disableFocusListener title={tooltip ? tooltip : ''}>
        <Autocomplete
          id='asynchronous-demo'
          disabled={formik.values.is_active || disabled}
          disableCloseOnSelect
          open={open}
          multiple
          disablePortal
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          onInputChange={(event, newInputValue) => {
            setInput(newInputValue);
          }}
          onChange={(
            event: React.SyntheticEvent<Element, Event>,
            value: CourseRequirement[] | undefined,
            reason: string,
          ) => {
            handleSelect(reason, value);
          }}
          filterOptions={
            allowCreateNew
              ? (options, params) => {
                  const filtered = filter(options, params);

                  if (
                    params.inputValue !== '' &&
                    !options.find((o) => o.name === params.inputValue)
                  ) {
                    filtered.push({
                      id: '',
                      site_id: siteId,
                      num_course_grants: '0',
                      num_course_prerequisites: '0',
                      name: params.inputValue,
                      label: `Add "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }
              : undefined
          }
          getOptionLabel={(option) => `${option.name}`}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {(option as CourseRequirementOption).label ?? `${option.name}`}
            </li>
          )}
          options={options}
          loading={loading}
          value={selected ? selected : []}
          inputValue={input}
          // groupBy={(option) => (option.type ? option.type : 'Other')}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={<span>{option.name}</span>}
                icon={chipIcon ? chipIcon : undefined}
              ></Chip>
            ))
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              variant='filled'
              label={label.charAt(0).toUpperCase() + label.slice(1)}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: (
                  <React.Fragment>
                    {loading || cachedPrereq.current.loading ? (
                      <CircularProgress color='inherit' size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Tooltip>
      <ConfirmDialog
        open={dialogState.open}
        onSave={() => {
          setDialogState({ ...dialogState, open: false });
        }}
        title='Duplicate Prerequisite'
        children={
          <Typography>
            You have already selected <b>{dialogState.equivalentName}</b>, which is equivalent to{' '}
            <b>{dialogState.prereqName}</b>
          </Typography>
        }
      />
    </>
  );
};

export default AutocompletePrereqs;
