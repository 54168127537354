import React from 'react';
import { Box, Button, DialogContent, DialogTitle, FormControl } from '@mui/material';
import { Formik } from 'formik';
import { useQueryClient } from 'react-query';
import {
  getGetSiteUnsubscribeGroupsQueryKey,
  useCreateSiteUnsubscribeGroup,
  useUpdateSiteUnsubscribeGroup,
} from '@juno/client-api';
import { Site as SiteModel, UnsubscribeGroup } from '@juno/client-api/model';
import { DialogAriaWrapper, GenericFormikInput } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import { SubscriptionGroupSchema } from '../utils/validationSchema';

interface CreateEditUnsubscribeGroupProps {
  handleClose: () => void;
  handleGroupsUpdate?: () => void;
  handleGroupCreated?: React.Dispatch<React.SetStateAction<boolean>>;
  unsubscribeGroup?: UnsubscribeGroup;
}

interface FormikValues {
  name: string;
  description: string;
}

const CreateEditSubscriptionGroupDialog: React.FC<CreateEditUnsubscribeGroupProps> = ({
  handleClose,
  handleGroupCreated,
  handleGroupsUpdate,
  unsubscribeGroup,
}) => {
  // Variables
  const { site } = useSettings();
  const queryClient = useQueryClient();
  const { CREATE, UPDATE } = MutationAction;
  const refetchGroups = () =>
    queryClient.invalidateQueries(getGetSiteUnsubscribeGroupsQueryKey(site?.platform_id || ''));
  const unsubscribeGroupCreate = useCreateSiteUnsubscribeGroup(
    onMutation(CREATE, 'UnsubscribeGroup', refetchGroups),
  );
  const unsubscribeGroupUpdate = useUpdateSiteUnsubscribeGroup(
    onMutation(UPDATE, 'UnsubscribeGroup', refetchGroups),
  );

  // Queries and mutations
  const handleCreateGroup = (unsubscribeGroup: UnsubscribeGroup) => {
    unsubscribeGroupCreate
      .mutateAsync({ siteId: site?.id || '', data: unsubscribeGroup })
      .then(() => {
        handleGroupCreated && handleGroupCreated(true);
        handleClose();
      });
  };
  const handleUpdateGroup = (unsubscribeGroup: UnsubscribeGroup) => {
    unsubscribeGroupUpdate
      .mutateAsync({
        siteId: site?.id || '',
        unsubscribeGroupId: unsubscribeGroup.id,
        data: unsubscribeGroup,
      })
      .then(() => {
        if (handleGroupsUpdate) {
          handleGroupsUpdate();
        }
        handleClose();
      });
  };

  // Functions
  const handleSubmit = (values: FormikValues): void => {
    // Update
    if (unsubscribeGroup) {
      const payload = {
        id: unsubscribeGroup.id as string,
        site_id: site?.id || '',
        name: values.name,
        description: values.description,
      } as UnsubscribeGroup;
      handleUpdateGroup(payload);
      // Create
    } else {
      const payload = {
        id: '',
        site_id: site?.id || '',
        name: values.name,
        description: values.description,
      };
      handleCreateGroup(payload);
    }
  };

  return (
    <DialogAriaWrapper
      id={'createEditUnsubscribeGroupDialog'}
      open={true}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle
        id={'createEditUnsubscribeGroupDialog-dialog-title'}
        sx={{ textAlign: 'left', mb: 2 }}
        variant='h5'
      >
        {unsubscribeGroup ? '' : 'Create'} Site Unsubscribe Group
      </DialogTitle>
      <Formik
        initialValues={{
          name: (unsubscribeGroup?.name || '') as string,
          description: (unsubscribeGroup?.description || '') as string,
        }}
        enableReinitialize={true}
        validationSchema={SubscriptionGroupSchema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, submitForm, resetForm }) => (
          <DialogContent id={'createEditUnsubscribeGroupDialog-dialog-description'}>
            {unsubscribeGroup && (
              <FormControl fullWidth>
                <FormControl sx={{ border: '1px dashed', borderRadius: '10px', p: 3, mb: 5 }}>
                  <GenericFormikInput
                    label='Subscription Group Name'
                    type='text'
                    name='name'
                    value={values.name}
                  />
                </FormControl>
                <FormControl sx={{ border: '1px dashed', borderRadius: '10px', p: 3, mb: 5 }}>
                  <GenericFormikInput
                    label='Subscription Group Description'
                    type='text'
                    name='description'
                    value={values.description}
                  />
                </FormControl>
                <Box
                  sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 2 }}
                >
                  <Button
                    aria-label='Cancel'
                    variant='outlined'
                    onClick={() => {
                      handleClose();
                      resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    aria-label='Update'
                    onClick={() => submitForm()}
                    variant='contained'
                    disabled={!touched.name && !touched.description}
                  >
                    Update
                  </Button>
                </Box>
              </FormControl>
            )}
            {!unsubscribeGroup && (
              <FormControl fullWidth sx={{ p: 3, mb: 3 }}>
                <FormControl>
                  <GenericFormikInput
                    label='Subscription Group Name'
                    type='text'
                    name='name'
                    value={values.name}
                  />
                </FormControl>
                <FormControl>
                  <GenericFormikInput
                    label='Subscription Group Description'
                    type='text'
                    name='description'
                    value={values.description}
                  />
                </FormControl>
                <Box
                  sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 2 }}
                >
                  <FormControl>
                    <Button
                      aria-label='Cancel'
                      variant='outlined'
                      onClick={() => {
                        handleClose();
                        resetForm();
                      }}
                    >
                      Cancel
                    </Button>
                  </FormControl>
                  <FormControl>
                    <Button
                      aria-label='Create-subscription-group'
                      onClick={() => submitForm()}
                      type={'submit'}
                      variant='contained'
                      disabled={!touched.name && !touched.description}
                    >
                      Create Subscription Group
                    </Button>
                  </FormControl>
                </Box>
              </FormControl>
            )}
          </DialogContent>
        )}
      </Formik>
    </DialogAriaWrapper>
  );
};

export default CreateEditSubscriptionGroupDialog;
