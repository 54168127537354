import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
  Typography,
} from '@mui/material';
import { UTCtoLocalString } from '@juno/utils';
import { ImportArrayModel } from '../../../utils';
import ImportAutocomplete from '../ImportAutocomplete';

interface ImportWizardFieldProps {
  name: string;
  isValid?: (value: any) => boolean;
  importArrayModelItem: ImportArrayModel;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checkbox?: boolean) => void;
  getAutoCompleteOptions: () => any[];
  onChangeAutoComplete: (event: any, value: any) => void;
  onBlur: (event: any) => void;
  label?: string;
  type?: string;
  setFieldValue?: any;
  setFieldTouched?: any;
}

const ImportWizardField: React.FC<ImportWizardFieldProps> = ({ ...props }) => {
  const {
    label,
    name,
    type,
    importArrayModelItem,
    onChange,
    getAutoCompleteOptions,
    onChangeAutoComplete,
    onBlur,
  } = props;
  if (type === 'checkbox') {
    return (
      <FormControl sx={{ mb: 3 }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                key={name}
                id={name}
                name={name}
                checked={Boolean(importArrayModelItem.dom_value) ?? false}
                onChange={(e) => {
                  onChange(e, true);
                }}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
              />
            }
            labelPlacement='end'
            label={label}
          />
        </FormGroup>
        {importArrayModelItem.is_valid === false && (
          <FormHelperText error={true}>
            <Typography component={'span'} sx={{ fontSize: '12px' }}>
              {importArrayModelItem.errorMessage}
            </Typography>
          </FormHelperText>
        )}
      </FormControl>
    );
  } else if (type && type.search('autocomplete') > -1) {
    let mappedOptions: any[];
    if (Array.isArray(importArrayModelItem)) {
      mappedOptions = importArrayModelItem;
    }
    // check if we even have an importArrayModelItem
    else if (
      importArrayModelItem.dom_value !== undefined ||
      importArrayModelItem.errorMessage !== undefined ||
      importArrayModelItem.is_valid !== undefined
    ) {
      mappedOptions = [importArrayModelItem];
    } else {
      mappedOptions = [];
    }

    return (
      <ImportAutocomplete
        label={label ?? ''}
        fieldValue={name}
        options={getAutoCompleteOptions()}
        value={mappedOptions}
        onChangeAutoComplete={onChangeAutoComplete}
      />
    );
  } else {
    let fieldType = type;
    let val = importArrayModelItem.dom_value != null ? importArrayModelItem.dom_value : '';
    if (type === 'datetime-local') {
      if (val && String(val).trim() != '') {
        try {
          val = UTCtoLocalString(new Date(val as string));
        } catch (e) {
          val = '';
        }
      }
    } else if (type === 'percent') {
      fieldType = 'number';
      if (String(val).search('%') > -1) {
        val = String(val).replace('%', '');
      }
    }
    return (
      <TextField
        fullWidth
        key={name}
        multiline={type == 'textarea'}
        id={name}
        name={name}
        type={fieldType}
        value={val}
        onChange={onChange}
        error={importArrayModelItem.is_valid === false}
        helperText={importArrayModelItem.is_valid === false && importArrayModelItem.errorMessage}
        label={label}
        variant='outlined'
        inputProps={{
          onBlur: onBlur,
        }}
        InputLabelProps={{ shrink: true }}
        sx={{ mb: 2 }}
      />
    );
  }
};

export default ImportWizardField;
