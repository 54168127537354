/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type AllowRetakesEnum = typeof AllowRetakesEnum[keyof typeof AllowRetakesEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AllowRetakesEnum = {
  limited: 'limited',
  never: 'never',
  on_fail_limited: 'on_fail_limited',
  on_fail_unlimited: 'on_fail_unlimited',
  unlimited: 'unlimited',
} as const;
