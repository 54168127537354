import React, { Dispatch, SetStateAction, useMemo } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Button, Checkbox, Chip, Collapse, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import { Theme } from 'emoji-picker-react';
import { JunoUser, StatusD4cEnum } from '@juno/client-api/model';
import { getTimeSinceCreated, useBreakpoint } from '@juno/utils';
import { ContentReportWithContent, reasonMap } from '.';

interface ModerationItemTileProps {
  report: ContentReportWithContent;
  expandedContent: string;
  setExpandedContent: Dispatch<SetStateAction<string>>;
  handleChangeStatus: (report: ContentReportWithContent, status: StatusD4cEnum) => void;
  setSelectedUser: Dispatch<SetStateAction<JunoUser | null>>;
  setUserQuickViewAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  batch: boolean;
  selectedContent: string[];
  setSelectedContent: Dispatch<SetStateAction<string[]>>;
}

const ModerationItemTile: React.FC<ModerationItemTileProps> = ({
  report,
  expandedContent,
  setExpandedContent,
  handleChangeStatus,
  setSelectedUser,
  setUserQuickViewAnchorEl,
  batch,
  selectedContent,
  setSelectedContent,
}) => {
  const { xs, sm } = useBreakpoint();
  const isMobile = xs || sm;

  const contentTypeString = useMemo(() => {
    let contentTypeString = report.thread ? 'Post' : 'Discussion';
    if (report.thread && report.thread.parent_thread_id) {
      contentTypeString = 'Comment';
    }
    return contentTypeString;
  }, [report.thread, report.topic]);

  const handleBatchSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (selectedContent.includes(report.id)) {
      setSelectedContent(selectedContent.filter((id) => id !== report.id));
    } else {
      setSelectedContent([...selectedContent, report.id]);
    }
  };

  return (
    <Box key={report.id} borderBottom={'1px solid #cac4d0'} p={2} pb={0}>
      <Stack direction='row' spacing={2} pl={2} mb={2} alignItems='center'>
        {batch && (
          <Checkbox
            sx={{ height: 42 }}
            onChange={handleBatchSelect}
            checked={selectedContent.includes(report.id)}
            disabled={report.status !== StatusD4cEnum.Needs_Review}
          />
        )}
        <Avatar sx={{ height: 72, width: 72 }} src={report.reporting_user.avatar || ''} />
        <Box pt={1}>
          <Stack direction='row' spacing={0.4}>
            <Typography
              onClick={(e) => {
                setSelectedUser(report.reporting_user);
                setUserQuickViewAnchorEl(e?.target as HTMLElement);
              }}
              sx={{ fontSize: 12, cursor: 'pointer' }}
            >
              {report.reporting_user.first_name} {report.reporting_user.last_name}
            </Typography>
            <Typography sx={{ fontSize: 12 }}>
              reported <b>{reasonMap.find((r) => r.type === report.reason)?.string}</b> by
            </Typography>
            <Typography
              onClick={(e) => {
                setSelectedUser(report.reported_user);
                setUserQuickViewAnchorEl(e?.target as HTMLElement);
              }}
              sx={{ fontSize: 12, cursor: 'pointer', color: (theme) => theme.palette.primary.main }}
            >
              {report.reported_user.first_name} {report.reported_user.last_name}
            </Typography>
          </Stack>
          <Typography sx={{ fontSize: 12, mt: 1 }}>
            {contentTypeString} was reported{' '}
            {getTimeSinceCreated(isMobile, report.date_created || '')}
          </Typography>
          <Button
            sx={{
              fontSize: 12,
              mt: 0.5,
              fontWeight: 400,
              ml: -1,
            }}
            color='primary'
            size='small'
            onClick={() => setExpandedContent(expandedContent === report.id ? '' : report.id)}
          >
            <ChevronRightIcon
              sx={{
                fontSize: 18,
                transition: '200ms',
                mr: 0.5,
                transform: expandedContent === report.id ? 'rotate(90deg)' : 'rotate(0deg)',
              }}
            />
            Review and take action
          </Button>
        </Box>
      </Stack>
      <Collapse in={expandedContent === report.id} sx={{ ml: batch ? 3.5 : 0 }}>
        <>
          <Box mb={2} sx={{ backgroundColor: '#f2f2f2', borderRadius: 2, overflow: 'hidden' }}>
            {report.topic && (
              <>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  mb={2}
                  px={2}
                  py={1}
                  sx={{ backgroundColor: '#e3e3e3' }}
                >
                  <Stack direction='row' alignItems='center' spacing={2}>
                    <Avatar
                      src={report.reported_user.avatar || ''}
                      sx={{ height: 50, width: 50 }}
                    />
                    <Typography sx={{ fontWeight: 500 }}>
                      {report.reported_user.first_name} {report.reported_user.last_name}
                    </Typography>
                  </Stack>
                  <Typography fontSize={12}>
                    {format(
                      new Date(report.topic?.date_created || ''),
                      "'Date:' MMMM dd, yyyy 'at' h:mm a OO",
                    )}
                  </Typography>
                </Stack>
                <Typography variant='h6' sx={{ fontWeight: 500, px: 2 }}>
                  {report.topic.title}
                </Typography>
                <Box px={2}>
                  <div
                    style={{ fontSize: '14px' }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(report.topic?.description || ('' as string), {
                        ADD_TAGS: ['iframe'],
                        ADD_ATTR: [
                          'allow',
                          'allowfullscreen',
                          'frameborder',
                          'scrolling',
                          'target',
                        ],
                      }) as unknown as string,
                    }}
                  />
                </Box>
                <Box p={2}>
                  {report.topic.tags.map((t) => (
                    <Chip label={t.value} sx={{ mr: 1, borderRadius: 2 }} />
                  ))}
                </Box>
              </>
            )}
            {report.thread && (
              <>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  mb={2}
                  px={2}
                  py={1}
                  sx={{ backgroundColor: '#e3e3e3' }}
                >
                  <Stack direction='row' alignItems='center' spacing={2}>
                    <Avatar
                      src={report.reported_user.avatar || ''}
                      sx={{ height: 50, width: 50 }}
                    />
                    <Typography sx={{ fontWeight: 500 }}>
                      {report.reported_user.first_name} {report.reported_user.last_name}
                    </Typography>
                  </Stack>
                  <Typography fontSize={12}>
                    {format(
                      new Date(report.thread.date_created || ''),
                      "'Date:' MMMM dd, yyyy 'at' h:mm a OO",
                    )}
                  </Typography>
                </Stack>
                <Box px={2}>
                  <div
                    style={{ fontSize: '14px' }}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(report.thread.body as unknown as string, {
                        ADD_TAGS: ['iframe'],
                        ADD_ATTR: [
                          'allow',
                          'allowfullscreen',
                          'frameborder',
                          'scrolling',
                          'target',
                        ],
                      }) as unknown as string,
                    }}
                  />
                </Box>
                <Box p={2}>
                  {report.thread?.tags?.map((t) => (
                    <Chip label={t.value} sx={{ mr: 1, borderRadius: 2 }} />
                  ))}
                </Box>
              </>
            )}
          </Box>
        </>

        {report.description && (
          <Box
            mt={2}
            bgcolor='#f2f2f2'
            border='1px solid #cac4d0'
            minHeight='80px'
            borderRadius={2}
            mb={2}
            p={2}
          >
            <Typography fontSize='14px'>{report.description}</Typography>
          </Box>
        )}
        <Stack direction='row' mb={2} ml={2}>
          {report.status === StatusD4cEnum.Needs_Review && (
            <>
              <LoadingButton
                onClick={() => handleChangeStatus(report, StatusD4cEnum.Ignored)}
                variant='contained'
                sx={{ mr: 2, width: 105 }}
              >
                Keep
              </LoadingButton>
              <LoadingButton
                onClick={() => handleChangeStatus(report, StatusD4cEnum.Moderated)}
                variant='outlined'
                sx={{ width: 105 }}
              >
                <DeleteForeverOutlinedIcon sx={{ mr: 1 }} /> Delete
              </LoadingButton>
            </>
          )}
          {report.status === StatusD4cEnum.Moderated && (
            <LoadingButton
              onClick={() => handleChangeStatus(report, StatusD4cEnum.Restored)}
              variant='contained'
              sx={{ width: 105 }}
            >
              Restore
            </LoadingButton>
          )}
          {report.status === StatusD4cEnum.Restored && (
            <LoadingButton variant='contained' sx={{ width: 105 }} disabled>
              Restored
            </LoadingButton>
          )}
          {report.status === StatusD4cEnum.Ignored && (
            <>
              <LoadingButton variant='contained' sx={{ mr: 2, width: 105 }} disabled>
                Kept
              </LoadingButton>
              <LoadingButton
                onClick={() => handleChangeStatus(report, StatusD4cEnum.Moderated)}
                variant='outlined'
                sx={{ width: 105 }}
              >
                <DeleteForeverOutlinedIcon sx={{ mr: 1 }} /> Delete
              </LoadingButton>
            </>
          )}
        </Stack>
      </Collapse>
    </Box>
  );
};

export default ModerationItemTile;
