/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type GeneratedReportTypeEnum = typeof GeneratedReportTypeEnum[keyof typeof GeneratedReportTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GeneratedReportTypeEnum = {
  learningcourse_details: 'learning.course_details',
  learningenrollments: 'learning.enrollments',
  learningenrollments_by_group: 'learning.enrollments_by_group',
  learningstudent_courses: 'learning.student_courses',
  learningstudent_transcripts: 'learning.student_transcripts',
  learninguser_answers: 'learning.user_answers',
  learningwait_lists: 'learning.wait_lists',
  proxiesanalytics_activity: 'proxies.analytics_activity',
} as const;
