import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useDebounce } from 'usehooks-ts';
import { ComponentData, Component as ComponentModel } from '@juno/client-api/model';

/**
 * Upcoming session date range.
 * toggle for hide in person (or something like that? or in person only?)
 * filter by display label.
 * connected to groups/courses/content (depending on the page to which the schedule is added).
 * timezone for all scheduled/dated content in user preferences and works globally.
 * display ONLY past content (radio button for this and in person?)
 */
interface ScheduleFormProps {
  component?: ComponentModel;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
  formik: FormikProps<ScheduleComponentModel>;
}

interface ScheduleFormWrapperProps {
  component?: ComponentModel;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
}

export interface ScheduleDataModel extends ComponentData {
  hide_past_sessions: boolean;
  hide_upcoming_sessions: boolean;
  hide_in_person_sessions: boolean;
  display_label_filter: string;
  limit: number;
}

export interface ScheduleComponentModel extends ComponentModel {
  data: ScheduleDataModel;
}

const ScheduleForm: React.FC<ScheduleFormProps> = ({ component, onUpdate, formik }) => {
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const { handleChange, setValues, values } = formik;
  const debouncedValues = useDebounce(values, 700);
  useEffect(() => {
    if (component) {
      onUpdate({ ...component, ...debouncedValues });
    }
  }, [debouncedValues]);
  return (
    <>
      <Typography variant='body2' sx={{ opacity: 0.6 }}>
        Editing Section
      </Typography>
      <Typography variant='h5' sx={{ mb: 4 }}>
        {values.name || 'Schedule'}
      </Typography>
      <TextField
        value={values.name}
        onChange={handleChange}
        name='name'
        label='Name'
        variant='filled'
        fullWidth
        InputProps={{ disableUnderline: true }}
        sx={{ mb: 2 }}
      />
      <Stack spacing={1}>
        <Typography variant='body1'>Filters</Typography>
        <Tooltip
          title='This will hide any sessions with an end date less than now.'
          placement='top-start'
        >
          <FormControlLabel
            value={values?.data?.hide_past_sessions}
            control={
              <Checkbox
                checked={values?.data?.hide_past_sessions}
                onChange={(e) => {
                  handleChange({
                    target: {
                      name: 'data.hide_past_sessions',
                      value: e.target.checked,
                    },
                  });
                }}
              />
            }
            label='Hide past sessions'
          />
        </Tooltip>
        <Tooltip
          title='This will hide any sessions with a start date greater than today.'
          placement='top-start'
        >
          <FormControlLabel
            value={values?.data?.hide_upcoming_sessions}
            control={
              <Checkbox
                checked={values?.data?.hide_upcoming_sessions}
                onChange={(e) =>
                  setValues({
                    ...values,
                    data: {
                      ...values.data,
                      hide_upcoming_sessions: e.target.checked,
                    },
                  })
                }
              />
            }
            label='Hide upcoming sessions'
          />
        </Tooltip>
        <Tooltip title='This will hide all in person sessions.' placement='top-start'>
          <FormControlLabel
            value={values?.data?.hide_in_person_sessions}
            control={
              <Checkbox
                checked={values?.data?.hide_in_person_sessions}
                onChange={(e) =>
                  setValues({
                    ...values,
                    data: {
                      ...values.data,
                      hide_in_person_sessions: e.target.checked,
                    },
                  })
                }
              />
            }
            label='Hide in-person sessions'
          />
        </Tooltip>
        <Box pl={1} pr={1}>
          <TextField
            value={values?.data?.display_label_filter}
            onChange={(e) => {
              setValues({
                ...values,
                data: {
                  ...values.data,
                  display_label_filter: e.target.value,
                },
              });
            }}
            label='Filter by Display Label'
            name='display_label_filter'
            placeholder='Display label'
            size='small'
            variant='filled'
            fullWidth
            InputProps={{ disableUnderline: true }}
            sx={{ mb: 2 }}
          />
        </Box>
        <InputLabel id='limit'>Limit Results</InputLabel>
        <Select
          aria-labelledby='limit'
          value={values?.data?.limit || 20}
          onChange={(e) => {
            setValues({
              ...values,
              data: {
                ...values.data,
                limit: e.target.value as number,
              },
            });
          }}
          label='Limit'
          variant='filled'
          fullWidth
          sx={{ mb: 2 }}
        >
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={15}>15</MenuItem>
          <MenuItem value={20}>20</MenuItem>
        </Select>
      </Stack>
    </>
  );
};

const ScheduleFormWrapper: React.FC<ScheduleFormWrapperProps> = ({
  component,
  onSave,
  onUpdate,
}) => {
  return (
    <Formik
      initialValues={(component as ScheduleComponentModel) || ({} as ScheduleComponentModel)}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        if (component) {
          onSave({ ...component, ...values });
        }
      }}
      enableReinitialize
    >
      {(formik) => {
        return (
          <Form>
            <ScheduleForm
              component={component}
              onSave={onSave}
              onUpdate={onUpdate}
              formik={formik}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ScheduleFormWrapper;
