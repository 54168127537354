import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { SaveBarBox, SaveBarWrapper } from './styles';

interface SaveBarProps {
  isDirty: boolean;
  isValid?: boolean;
  isSaving: boolean;
  onDiscard: () => void;
  onSave: () => void;
}

const SaveBar: React.FC<SaveBarProps> = ({
  isDirty,
  isValid = true,
  isSaving,
  onDiscard,
  onSave,
}) => {
  return (
    <SaveBarWrapper>
      <SaveBarBox isDirty={isDirty} isSaving={isSaving}>
        <LoadingButton
          sx={{ width: '100%' }}
          loading={isSaving}
          disabled={!isValid}
          variant='contained'
          type='submit'
          onClick={onSave}
        >
          Save
        </LoadingButton>
        <Button sx={{ width: '100%' }} variant='outlined' onClick={onDiscard}>
          Discard
        </Button>
      </SaveBarBox>
    </SaveBarWrapper>
  );
};

export default SaveBar;
