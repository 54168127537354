import { Avatar, Box, Button, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFormImage = styled(Avatar)(() => ({
  width: '98px',
  height: '55px',
  objectFit: 'cover',
  overflow: 'hidden',
  boxShadow: '0px 0px 3px rgb(0 0 0 / 40%)',
}));

export const StyledUserAvatar = styled(Avatar)(() => ({
  width: '44px',
  height: '44px',
  objectFit: 'cover',
  overflow: 'hidden',
  boxShadow: '0px 0px 3px rgb(0 0 0 / 40%)',
}));

export const TileWrapper = styled(Box)(() => ({
  position: 'relative',
  userSelect: 'none',
  boxShadow: 'none',
  width: '100%',
}));

export const TileGridContainer = styled(Box)(() => ({
  width: 'auto',
  borderRadius: '4px',
}));

export const TileCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'idx',
})<{
  idx: number;
}>(({ idx }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: idx % 2 === 0 ? '#FFFFFF' : '#F2F2F2',
  border: 'none',
  boxShadow: 'none',
}));

export const TileGridBanner = styled(Box)(() => ({
  alignSelf: 'center',
}));

export const TileGridTextWrapper = styled(Box)(() => ({
  display: 'grid',
  alignContent: 'center',
}));

export const EllipsisTypography = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const ShowingLabel = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(1),
  color: '#595959',
  fontStyle: 'italic',
}));

export const PercentTypography = styled(Typography)(() => ({
  borderColor: 'primary.main',
  borderStyle: 'solid',
  borderWidth: '2px',
  borderRadius: '32px',
  padding: '2px 12px',
}));

export const EnrolledTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '32px',
  padding: '2px 12px',
}));

export const WaitlistTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: '#595959',
  borderRadius: '32px',
  padding: '2px 12px',
}));

export const CompletedBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSmallScreen',
})<{
  isSmallScreen: boolean;
}>(({ isSmallScreen }) => ({
  display: isSmallScreen ? 'inline' : 'flex',
  alignItems: 'center',
  gap: '10px',
}));

export const TranscriptDownloadButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{
  isMobile: boolean;
}>(({ isMobile }) => ({
  height: '48px',
  fontWeight: isMobile ? '400' : '700',
  order: isMobile ? 1 : 'unset',
  textTransform: isMobile ? 'uppercase' : 'none',
}));

export const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{
  width: number;
}>(({ theme, width }) => ({
  width: '100%',
  maxWidth: 1180,
  margin: 'auto',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

export const Controls = styled(Box)(({ theme }) => ({
  display: 'flex',
  margin: `20px auto 5px auto`,
  justifyContent: 'space-between',
  gap: `0px 24px`,
}));
