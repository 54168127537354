import React, { useEffect, useMemo, useState } from 'react';
import { InsertDriveFile as InsertDriveFileIcon } from '@mui/icons-material';
import { Link, Skeleton, Typography } from '@mui/material';
import { Reorder } from 'framer-motion';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getGetCourseQueryKey,
  getGetCourseResourcesQueryKey,
  useCreateCourseResource,
  useCreateDownload,
  useDeleteCourseResource,
  useGetCourse,
  useGetCourseResource,
  useGetCourseResources,
  useUpdateCourse,
  useUpdateCourseResource,
} from '@juno/client-api';
import {
  Course as CourseModel,
  CourseResource as CourseResourceModel,
  Site as SiteModel,
} from '@juno/client-api/model';
import { Download as DownloadUtils } from '@juno/client-api/utils';
import { useS3Upload } from '@juno/modules';
import { AlertDialog, JunoRowCreateTile as RowCreateTile } from '@juno/ui';
import { MutationAction, onMutation, uploadImagesToCloudinary } from '@juno/utils';
import RowTile from '../../components/RowTile';
import SaveBar from '../../components/SaveBar';
import FormDialog, { PayloadProps } from './FormDialog';
import { NoLessonsFound } from './styles';

const CourseResourcesLoading = () => (
  <>
    <RowCreateTile text='Create a resource' />
    {[...Array(5)].map((_, idx) => (
      <Skeleton
        key={`skel-${idx}`}
        variant='rectangular'
        width={'auto'}
        height={165}
        sx={{ mt: 2 }}
      />
    ))}
  </>
);

interface CourseResourcesProps {
  site: SiteModel;
}

const CourseResources: React.FC<CourseResourcesProps> = ({ site }) => {
  const { id: siteId } = site;
  const params = useParams() as { courseSlug?: string; tabSlug?: string };
  const courseSlug: string = params.courseSlug || '';
  const [updatedResources, setUpdatedResources] = useState<CourseResourceModel[] | undefined>();
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [resourceId, setResourceId] = useState<string | undefined>();
  const [isSaving, setIsSaving] = useState(false);
  const queryClient = useQueryClient();
  const { data: resourcesData, isLoading: resourcesLoading } = useGetCourseResources(
    siteId,
    courseSlug,
  );
  const { data: course, isLoading: courseLoading } = useGetCourse(siteId, courseSlug);
  const { data: courseResource, isLoading: resourceLoading } = useGetCourseResource(
    siteId,
    resourceId || '',
  );
  const isLoading = resourcesLoading || courseLoading;
  const { uploadFileToS3 } = useS3Upload();

  useEffect(() => {
    resourcesData &&
      setUpdatedResources(
        resourcesData.sort((a, b) => {
          if (a?.order === b?.order) {
            return 0;
          }
          if (!b?.order) {
            return 1;
          }
          return a?.order ? a.order - b.order : -1;
        }),
      );
  }, [resourcesData]);

  const isDirty = useMemo(() => {
    if (resourcesData && updatedResources) {
      return resourcesData.some((resource, idx) => resource.id !== updatedResources[idx]?.id);
    }
    return false;
  }, [resourcesData, updatedResources]);

  // Course mutations
  const resourcesRefetch = () =>
    queryClient.invalidateQueries(getGetCourseResourcesQueryKey(siteId, courseSlug));
  const courseRefetch = () =>
    queryClient.invalidateQueries(getGetCourseQueryKey(siteId, courseSlug));
  const refetch = () => {
    resourcesRefetch();
    courseRefetch();
  };
  const courseUpdate = useUpdateCourse(onMutation(MutationAction.UPDATE, 'Course', refetch));

  // CourseResource mutations
  const courseResourceCreate = useCreateCourseResource(
    onMutation(MutationAction.CREATE, 'CourseResource', refetch),
  );
  const courseResourceUpdate = useUpdateCourseResource(
    onMutation(MutationAction.UPDATE, 'CourseResource', refetch),
  );
  const courseResourceDelete = useDeleteCourseResource(
    onMutation(MutationAction.DELETE, 'CourseResource', refetch),
  );

  const downloadCreate = useCreateDownload(onMutation(MutationAction.CREATE, 'Download', () => {}));

  const handleCreateResource = () => {
    setResourceId(undefined);
    setFormDialogOpen(true);
  };

  const handleSettingsClick = (resourceId: string) => {
    if (!course?.id) return;
    setResourceId(resourceId);
    setFormDialogOpen(true);
  };

  const handleSaveCourse = (data: CourseModel | undefined) => {
    if (!data) return;
    const newData = { ...data, resources: updatedResources };
    courseUpdate.mutate({ siteId, courseId: data.id, data: newData });
  };

  const handleDialogClose = () => {
    setResourceId(undefined);
    setConfirmDeleteOpen(false);
    setFormDialogOpen(false);
    setIsSaving(false);
  };

  const getFiletype = (payloadFile: File) => {
    if (payloadFile.type) return payloadFile.type;
    if (payloadFile?.name) {
      const fileExtension = payloadFile?.name.split('.').pop();
      return `.${fileExtension}`;
    }
    return 'unknown filetype';
  };

  const handleDialogSave = async (payload: PayloadProps | undefined) => {
    if (!payload?.resource || !course) return;
    setIsSaving(true);
    const data = payload?.resource;
    // remove the download object from the resource
    delete data['download'];
    if (payload.iconFile) {
      // If updating the icon, upload to cloudinary and get the url
      const response = await uploadImagesToCloudinary([payload.iconFile]);
      data.icon = response.url;
    }
    if (payload.file) {
      // If updating the file, upload to s3, get the url,
      // create a Download and attach it to the CourseResource
      const downloadObj = DownloadUtils.fromCourseResource(data);
      downloadObj.filetype = getFiletype(payload.file);
      downloadObj.url = await uploadFileToS3(payload.file);
      const download = await downloadCreate.mutateAsync({ siteId, data: downloadObj });
      data.download = download.id;
    }
    if (resourceId) {
      // if existing resource update it
      await courseResourceUpdate.mutateAsync({ siteId, resourceId, data });
    } else {
      // if new resource create it
      await courseResourceCreate.mutateAsync({ siteId, courseId: course.id, data });
    }
    handleDialogClose();
  };

  const handleDeleteResource = () => {
    if (!course?.id || !resourceId) return;
    courseResourceDelete.mutate({ siteId, resourceId });
    handleDialogClose();
  };

  if (isLoading) return <CourseResourcesLoading />;

  return (
    <>
      <SaveBar
        isDirty={isDirty}
        onSave={() => handleSaveCourse(course)}
        isSaving={courseUpdate.isLoading || isSaving}
        onDiscard={() => setUpdatedResources(resourcesData || [])}
      />
      <RowCreateTile onClick={handleCreateResource} text='Create a resource' />
      <Reorder.Group
        axis='y'
        onReorder={setUpdatedResources}
        onClick={(e) => e.stopPropagation()}
        values={updatedResources || []}
        style={{ listStyle: 'none', padding: 0 }}
      >
        {updatedResources?.map((resource) => (
          <Reorder.Item id={resource.id} key={resource.id} value={resource}>
            <RowTile
              title={resource.title}
              subtitle={resource.description}
              imageUrl={resource.icon}
              onSettingsSelect={() => handleSettingsClick(resource.id)}
              sortable
              footer={
                <Link
                  href={resource.download?.url || ''}
                  target='_blank'
                  rel='noopener noreferrer'
                  underline='none'
                  color='inherit'
                >
                  <Typography color='primary' sx={{ display: 'flex', alignItems: 'center' }}>
                    <InsertDriveFileIcon fontSize='small' />
                    {resource.download?.filetype || ''}
                  </Typography>
                </Link>
              }
            />
          </Reorder.Item>
        ))}
      </Reorder.Group>
      {updatedResources?.length === 0 && (
        <NoLessonsFound sx={{ mt: 3 }}>
          <Typography variant='h6'>No resources found</Typography>
        </NoLessonsFound>
      )}
      <FormDialog
        isLoading={resourceLoading}
        item={courseResource}
        open={formDialogOpen}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onDelete={() => setConfirmDeleteOpen(true)}
        isSaving={isSaving}
      />
      <AlertDialog
        open={confirmDeleteOpen}
        description={'Are you sure you want to delete this resource?'}
        onCancel={() => setConfirmDeleteOpen(false)}
        onConfirm={handleDeleteResource}
      />
    </>
  );
};

export default CourseResources;
