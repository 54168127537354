import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BottomNavContent = styled(BottomNavigation)(({ theme }) => ({
  height: 64,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  width: '100%',
  position: 'fixed',
  bottom: 0,
  zIndex: theme.zIndex.appBar,
  justifyContent: 'space-evenly',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const BottomNavButton = styled(BottomNavigationAction, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected?: boolean;
}>(({ theme, isSelected }) => ({
  rowGap: '3px',
  padding: '0',
  minWidth: '50px',
  color: isSelected ? theme.palette.primary.main : theme.palette.primary.contrastText,
  '& .MuiBottomNavigationAction-label': {
    width: '100%',
    overflow: 'hidden',
    alignItems: 'start',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export const BottomNavIcon = styled('i')(() => ({
  width: '24px',
  height: '24px',
}));

export const FooterBlurb = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showSideMenu' && prop !== 'isBottomToolbarShowing',
})<{
  showSideMenu?: boolean;
  isBottomToolbarShowing?: boolean;
}>(({ theme, showSideMenu, isBottomToolbarShowing }) => ({
  position: 'fixed',
  width: '100%',
  bottom: isBottomToolbarShowing ? 64 : 0,
  left: showSideMenu ? 280 : 0,
  transition: 'left 0.3s ease-in-out',
  right: 0,
  zIndex: theme.zIndex.appBar,
}));
