import React, { useState } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from '@mui/material';
import { useSettings } from '@juno/utils';

interface AddModulePopperProps {
  onAdded: (type: string, name: string) => void;
}

const AddModulePopper = ({ onAdded }: AddModulePopperProps) => {
  const { restrictGZMVPFeatures } = useSettings();
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleSelectItem = (type: string, name: string) => {
    setOpen(false);
    onAdded(type, name);
  };

  return (
    <>
      <IconButton ref={anchorRef} onClick={handleToggle} size='small'>
        <Tooltip
          open={tooltipOpen && !open}
          onMouseOver={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
          title='Add Module'
        >
          <AddIcon />
        </Tooltip>
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-end'
        transition
        disablePortal
        style={{ zIndex: 5 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-end' ? 'right top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                  {/* // TODO commenting out for now since prod wants to iterate on the page builder stuff */}
                  {/* <MenuItem onClick={() => handleSelectItem('announcements', 'Announcements')}>
                    Announcements
                  </MenuItem> */}
                  <MenuItem onClick={() => handleSelectItem('blurb', 'Blurb')}>Blurb</MenuItem>
                  {!restrictGZMVPFeatures && (
                    <>
                      <MenuItem onClick={() => handleSelectItem('directory', 'Directory')}>
                        Directory
                      </MenuItem>
                      <MenuItem onClick={() => handleSelectItem('global_feed', 'Global Feed')}>
                        Global Feed
                      </MenuItem>
                      <MenuItem
                        onClick={() => handleSelectItem('global_forum', 'Global Discussions')}
                      >
                        Global Discussions
                      </MenuItem>
                    </>
                  )}
                  <MenuItem onClick={() => handleSelectItem('grid', 'Grid')}>Grid</MenuItem>
                  <MenuItem onClick={() => handleSelectItem('hero', 'Hero')}>Hero</MenuItem>
                  <MenuItem onClick={() => handleSelectItem('featured_rotator', 'Rotator')}>
                    Rotator
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectItem('schedule', 'Schedule')}>
                    Schedule
                  </MenuItem>
                  <MenuItem onClick={() => handleSelectItem('side_by_side', 'Side by Side')}>
                    Side by Side
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AddModulePopper;
