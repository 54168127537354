import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';

interface DefaultDeleteItemConfirmDialogProps {
  deleteTitle: string;
  deleteSubtitle?: string;
  buttonText?: string;
  handleClose: () => void;
  handleDelete: () => void;
}

/**
 * Use for all vnext delete item confirm dialogs to maintain consistency
 * @param deleteTitle string
 * @opt @param deleteSubtitle optional string
 * @opt @param buttonText optional string
 * @param handleClose () => void
 * @param handleDelete () => void
 * @example
 * <DefaultDeleteItemConfirmDialog
 * deleteTitle={'Education Credit'}
 * handleClose={handleClose}
 * handleDelete={handleDelete}
 * />
 *
 */
const DefaultDeleteItemConfirmDialog: React.FC<DefaultDeleteItemConfirmDialogProps> = ({
  deleteTitle,
  deleteSubtitle,
  buttonText,
  handleClose,
  handleDelete,
}) => {
  return (
    <Dialog open={true} onClose={handleClose} maxWidth='xs'>
      <DialogTitle sx={{ pt: 4, pb: 4, alignSelf: 'left' }}>Delete {deleteTitle}?</DialogTitle>
      <DialogContent>
        <DialogContentText align='left' component='div'>
          <Typography sx={{ mb: 3 }}>
            Are you sure you want to permanently delete this {deleteTitle}?
          </Typography>
          <Typography>
            {deleteSubtitle
              ? deleteSubtitle
              : `All of the ${deleteTitle} details will be lost. This action cannot be undone.`}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
      >
        <Stack sx={{ width: '100%', pr: 2, pl: 2, pb: 2 }}>
          <Button
            onClick={handleDelete}
            variant='contained'
            fullWidth
            sx={{ mb: 2, letterSpacing: '1px' }}
          >
            {buttonText ? buttonText : `Delete ${deleteTitle}`}
          </Button>
          <Button onClick={handleClose} variant='outlined' fullWidth>
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
export default DefaultDeleteItemConfirmDialog;
