import React, { useEffect, useState } from 'react';
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
import { Box, Chip, CircularProgress, FormControl, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Reorder } from 'framer-motion';
import { useQueryClient } from 'react-query';
import {
  getGetProductPricesQueryKey,
  useCreateProductPrice,
  useDeleteProductPrice,
  useGetPriceLevels,
  useGetProductPrices,
  useUpdateProductPrice,
} from '@juno/client-api';
import { Product, ProductPrice, Site as SiteModel } from '@juno/client-api/model';
import { AlertDialog } from '@juno/ui';
import { RowTile } from '@juno/ui';
import { MutationAction, onMutation } from '@juno/utils';
import FormDialog from './FormDialog';

const NoItemsFound = styled(Box)(() => ({
  textAlign: 'center',
  userSelect: 'none',
}));

interface PricingListProps {
  site: SiteModel;
  product?: Product;
}

const PricingList: React.FC<PricingListProps> = ({ site, product }) => {
  const siteId = site?.id || '';
  const productId = product?.id || '';
  const queryClient = useQueryClient();
  const [orderedItems, setOrderedItems] = useState<ProductPrice[] | undefined>();
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>();
  const [isSaving, setIsSaving] = useState(false);

  // Queries
  const { data: priceLevels, isLoading: levelsLoading } = useGetPriceLevels(site.id);
  const { data: productPrices, isLoading: pricesLoading } = useGetProductPrices(siteId, productId, {
    order: 'price',
  });
  const refetchProducts = () =>
    queryClient.invalidateQueries(getGetProductPricesQueryKey(siteId, productId));
  const isLoading = levelsLoading || pricesLoading;

  // Mutations
  const createProductPrice = useCreateProductPrice(
    onMutation(MutationAction.CREATE, 'ProductPrice', refetchProducts),
  );
  const updateProductPrice = useUpdateProductPrice(
    onMutation(MutationAction.UPDATE, 'ProductPrice', refetchProducts),
  );
  const deleteProductPrice = useDeleteProductPrice(
    onMutation(MutationAction.DELETE, 'ProductPrice', refetchProducts),
  );

  useEffect(() => {
    if (!productPrices) return;
    setOrderedItems(productPrices);
  }, [productPrices]);

  const handleCreate = () => {
    setSelectedItemId(undefined);
    setFormDialogOpen(true);
  };

  const handleUpdate = (itemId: string) => {
    setSelectedItemId(itemId);
    setFormDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedItemId(undefined);
    setConfirmDeleteOpen(false);
    setFormDialogOpen(false);
    setIsSaving(false);
  };

  const handleDialogSave = (data: ProductPrice) => {
    setFormDialogOpen(false);
    if (data.id) {
      updateProductPrice.mutate({ siteId, productId, priceId: data.id, data });
    } else {
      createProductPrice.mutate({
        siteId,
        productId,
        data: { ...data, currency: data.currency || 'usd' },
      });
    }
  };

  const handleDelete = () => {
    deleteProductPrice.mutate({ siteId, productId, priceId: selectedItemId || '' });
    handleDialogClose();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <FormControl
        margin='dense'
        fullWidth
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '20px',
        }}
      >
        <Typography sx={{ fontWeight: 700, display: 'flex' }}>Pricing Details</Typography>
        <IconButton onClick={handleCreate}>
          <AddCircleOutlineIcon />
        </IconButton>
      </FormControl>
      <Reorder.Group
        axis='y'
        onReorder={setOrderedItems}
        onClick={(e) => e.stopPropagation()}
        values={orderedItems || []}
        style={{ listStyle: 'none', padding: 0 }}
      >
        {orderedItems?.map((item) => (
          <Reorder.Item id={item.id} key={item.id} value={item} dragListener={false}>
            <RowTile
              title={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Chip
                    label={priceLevels?.find((level) => level.id === item.price_level.id)?.name}
                    sx={{ width: '50%', borderRadius: 1, border: 'solid 1px' }}
                  />
                  <Typography>${item.price}</Typography>
                  <Chip sx={{ textTransform: 'uppercase' }} label={item.currency || ''} />
                </Box>
              }
              onRowSelect={() => handleUpdate(item.id || '')}
              hideImage
            />
          </Reorder.Item>
        ))}
      </Reorder.Group>
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
          <CircularProgress />
        </Box>
      )}
      {(!orderedItems || orderedItems?.length === 0) && !isLoading && (
        <NoItemsFound sx={{ mt: 3 }}>
          <Typography variant='h6'>No items found</Typography>
        </NoItemsFound>
      )}
      <FormDialog
        item={productPrices?.find((item) => item.id === selectedItemId)}
        priceLevels={priceLevels || []}
        unusedPriceLevels={
          priceLevels?.filter(
            (price) => !productPrices?.find((item) => item.price_level.id === price.id),
          ) || []
        }
        isLoading={isLoading}
        open={formDialogOpen}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onDelete={() => setConfirmDeleteOpen(true)}
        isSaving={isSaving}
      />
      <AlertDialog
        open={confirmDeleteOpen}
        description={'Are you sure you want to delete this item?'}
        onCancel={() => setConfirmDeleteOpen(false)}
        onConfirm={handleDelete}
      />
    </Box>
  );
};

export default PricingList;
