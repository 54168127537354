/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type ShowCorrectAnswersEnum = typeof ShowCorrectAnswersEnum[keyof typeof ShowCorrectAnswersEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShowCorrectAnswersEnum = {
  never: 'never',
  after_complete: 'after_complete',
  after_final_retake: 'after_final_retake',
  after_passing: 'after_passing',
} as const;
