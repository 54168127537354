import { Typography } from '@mui/material';
import { useGetMe, useGetSite } from '@juno/client-api';
import { JunoSpin } from '@juno/ui';
import LearningDashboard from './dashboard';

export interface JunoLearningProps {
  siteId: string;
}

declare global {
  interface Window {
    App: any;
  }
}

const JunoLearningDashboard: React.FC<JunoLearningProps> = ({ siteId }) => {
  const { data: siteData, isLoading: siteLoading } = useGetSite(siteId);
  const { data: userData, isLoading: userLoading } = useGetMe(siteData?.platform_id || '');
  const isLoading = siteLoading || userLoading;

  if (isLoading) return <JunoSpin />;
  if (!userData || !siteData)
    return (
      <Typography variant='h6' color='text.primary' sx={{ textAlign: 'center' }}>
        {!userData && 'No user found'}
        <br />
        {!siteData && 'No site found'}
      </Typography>
    );

  return <LearningDashboard site={siteData} />;
};

export default JunoLearningDashboard;
