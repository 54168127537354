import React from 'react';
import { TextField } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormikProps } from 'formik';
import { FrequencyEnum } from '@juno/client-api/model';
import { CampaignScheduleSectionFormikValues } from './validationSchema';

interface TimePickProps {
  id: string;
  initialValue: string;
  formik: FormikProps<CampaignScheduleSectionFormikValues>;
  updateCampaignScheduleFormikValues: React.Dispatch<
    React.SetStateAction<CampaignScheduleSectionFormikValues>
  >;
  disabled?: boolean;
}
const TimePick: React.FC<TimePickProps> = ({
  id,
  initialValue,
  formik,
  updateCampaignScheduleFormikValues,
  disabled,
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        label='Time'
        value={initialValue}
        onChange={(value: any) => {
          try {
            const val = value && new Date(value).toISOString();
            formik.setFieldValue(id, val);
            // If start_date exists, and it is one-time, set start_date time to time. Prevents cross-timezone issues.
            const startDate = formik.values.schedule?.start_date;
            let extraData = {};
            if (
              formik.values.schedule?.frequency === FrequencyEnum.ONE_TIME &&
              value &&
              startDate
            ) {
              const startTimeDate = new Date(startDate);
              const timeDate = new Date(value);
              startTimeDate.setHours(
                timeDate.getHours(),
                timeDate.getMinutes(),
                timeDate.getSeconds(),
                timeDate.getMilliseconds(),
              );
              extraData = { start_date: startTimeDate.toISOString() };
            }
            updateCampaignScheduleFormikValues({
              ...formik.values,
              schedule: {
                ...formik.values.schedule,
                id: formik.values.schedule?.id || '',
                time: val,
                ...extraData,
              },
            });
          } catch (error) {
            formik.setFieldTouched(id, true);
            formik.setFieldError(id, 'Invalid time string');
          }
        }}
        disabled={disabled}
        renderInput={(params: any) => (
          <TextField
            {...params}
            id='schedule.time'
            variant='filled'
            {...formik.getFieldProps('schedule.time')}
            error={Boolean(formik.touched.schedule?.time && formik.errors.schedule?.time)}
            helperText={formik.touched.schedule?.time && formik.errors.schedule?.time}
            sx={{ maxWidth: '130px' }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default TimePick;
