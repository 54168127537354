import React from 'react';
import { EnrollmentModel } from './components/EnrollmentTile';
import { EnrolledTypography, PercentTypography } from './styles';

const CurrentCourseTileContent = (enrollment: EnrollmentModel) => {
  return (
    <>
      {!!enrollment.lessons_complete_percent && (
        <PercentTypography color='primary.main'>
          {enrollment.lessons_complete_percent}%
        </PercentTypography>
      )}
      {!enrollment.lessons_complete_percent && (
        <EnrolledTypography color='primary.contrastText'>Enrolled</EnrolledTypography>
      )}
    </>
  );
};

export default CurrentCourseTileContent;
