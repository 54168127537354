import React, { useState } from 'react';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Grid,
  IconButton,
  ListItemIcon,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GroupResource, SearchContentTypeEnum } from '@juno/client-api/model';
import { BasicKebobMenu, FileTypeIcon, UserQuickView } from '@juno/ui';
import { getContentRoute, getTimeSinceCreated, handleFileDownload, useSettings } from '@juno/utils';

interface FileGridViewProps {
  files: GroupResource[];
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  itemToEdit: GroupResource;
  setItemToEdit: React.Dispatch<React.SetStateAction<GroupResource>>;
  onEditSubmit: () => void;
  onClickDelete: (file: GroupResource) => void;
  iAmAdmin: boolean;
  isAdminPage?: boolean;
}
const FileGridView: React.FC<FileGridViewProps> = ({
  files,
  isEditing,
  setIsEditing,
  itemToEdit,
  setItemToEdit,
  onEditSubmit,
  onClickDelete,
  iAmAdmin,
  isAdminPage,
}) => {
  const { user: currentUser, site } = useSettings();
  const [userQuickViewAnchorEl, setUserQuickViewAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<null | string>(null);
  const showMenu = (resource: GroupResource) => {
    return currentUser?.id === resource.author_id;
  };
  const showAdminMenu = () => {
    return iAmAdmin || isAdminPage;
  };
  const navigateRoute = useNavigate();
  const showDownloadMenu = (resource: GroupResource) => {
    return currentUser?.id !== resource.author_id && !isAdminPage && !iAmAdmin;
  };
  return (
    <Grid container spacing={1}>
      {files?.map((file, index) => (
        <Grid item key={file.id} xs={12} sm={4}>
          <Card
            variant='outlined'
            key={file.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              position: 'relative',
            }}
          >
            <Box position='absolute' right={3} top={3} sx={{ bgcolor: 'white', borderRadius: 50 }}>
              {(showMenu(file) || showAdminMenu()) && (
                <BasicKebobMenu>
                  <Box>
                    {showMenu(file) && (
                      <MenuItem
                        onClick={() => {
                          setItemToEdit(file);
                          setIsEditing(true);
                        }}
                        aria-label='Edit file'
                      >
                        <ListItemIcon>
                          <EditRoundedIcon color='primary' fontSize='small' />
                        </ListItemIcon>
                        <Typography>Edit</Typography>
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        handleFileDownload(file.download.url, file.download.title);
                      }}
                      aria-label='Download file'
                    >
                      <ListItemIcon>
                        <FileDownloadRoundedIcon fontSize='small' color='primary' />
                      </ListItemIcon>
                      <Typography>Download</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => onClickDelete(file)} aria-label='Delete file'>
                      <ListItemIcon>
                        <DeleteForeverRoundedIcon color='primary' fontSize='small' />
                      </ListItemIcon>
                      <Typography>Delete</Typography>
                    </MenuItem>
                  </Box>
                </BasicKebobMenu>
              )}
              {showDownloadMenu(file) && (
                <IconButton
                  sx={{
                    zIndex: 1,
                    bgcolor: 'white',
                  }}
                  onClick={() => {
                    handleFileDownload(file.download.url, file.download.title);
                  }}
                  aria-label='Download file'
                >
                  <FileDownloadRoundedIcon fontSize='small' color='primary' />
                </IconButton>
              )}
            </Box>
            {file.download.filetype.startsWith('image/') ? (
              <CardMedia
                component='img'
                height='120'
                image={file.download.url}
                alt={file.download.title}
                sx={{ flex: '1 0 auto', maxWidth: '100%' }}
              />
            ) : (
              <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                height='120px'
                style={{ flex: '1 0 auto' }}
              >
                <FileTypeIcon fileType={file.download.filetype} />
              </Box>
            )}
            <Box p={2}>
              {(!isEditing || (isEditing && itemToEdit?.id !== file.id)) && (
                <Grid container alignItems='center'>
                  <Grid item xs={12}>
                    <Box>
                      <Typography variant='subtitle2'>{file.download.title}</Typography>
                      <Typography
                        variant='caption'
                        sx={{
                          maxHeight: '100px',
                          overflow: 'hidden',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {file.download.description}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack>
                      <Typography
                        variant='caption'
                        onClick={(e) => {
                          setUserQuickViewAnchorEl(e.currentTarget);
                          setSelectedUser(file.author?.id || null);
                        }}
                        sx={{ cursor: 'pointer' }}
                      >{`by ${file.author?.first_name} ${file.author?.last_name}`}</Typography>
                      <Typography variant='caption'>
                        {getTimeSinceCreated(false, file.download.date_created)}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              )}
              {isEditing && itemToEdit?.id === file.id && (
                <>
                  <Stack spacing={1}>
                    <TextField
                      fullWidth
                      placeholder={file.download.title}
                      value={itemToEdit.download.title || ''}
                      label='Title'
                      onChange={(e) => {
                        setItemToEdit((prevItem) => ({
                          ...prevItem,
                          download: {
                            ...prevItem.download,
                            title: e.target.value,
                          },
                        }));
                      }}
                    />

                    <TextField
                      fullWidth
                      label='Description'
                      placeholder={'Enter a description'}
                      multiline={true}
                      minRows={2}
                      value={itemToEdit.download.description}
                      onChange={(e) => {
                        setItemToEdit((prevItem) => ({
                          ...prevItem,
                          download: {
                            ...prevItem.download,
                            description: e.target.value,
                          },
                        }));
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Stack>
                  <Box pt={2} display='flex' justifyContent='flex-end'>
                    <Button
                      size='small'
                      color='inherit'
                      onClick={() => {
                        setIsEditing(false);
                        setItemToEdit({} as GroupResource);
                      }}
                      aria-label='Cancel editing'
                      variant='text'
                      sx={{ textTransform: 'none' }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size='small'
                      color='primary'
                      onClick={onEditSubmit}
                      aria-label='Save changes'
                      variant='contained'
                      sx={{ textTransform: 'none' }}
                    >
                      Save
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </Card>
        </Grid>
      ))}
      <UserQuickView
        anchorEl={userQuickViewAnchorEl}
        userId={selectedUser || ''}
        open={Boolean(userQuickViewAnchorEl)}
        setAnchorEl={setUserQuickViewAnchorEl}
        onViewProfile={(userEmail: string): void => {
          navigateRoute(
            getContentRoute(site?.slug || '', selectedUser || '', SearchContentTypeEnum.user),
          );
        }}
        onMessageUser={function (userId: string): void {
          throw new Error('Function not implemented.');
        }}
      />
    </Grid>
  );
};
export default FileGridView;
