import React, { useEffect, useMemo, useState } from 'react';
import {
  CheckCircle,
  CheckCircleOutline,
  DeleteOutlined,
  SendOutlined,
  ThumbUpAltOutlined,
} from '@mui/icons-material';
import {
  Badge,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  ListItem,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { JunoUser, SearchContentTypeEnum } from '@juno/client-api/model';
import { ConfirmDeleteDialog, UserQuickView } from '@juno/ui';
import { getContentRoute, getTimeSinceCreated, useSettings } from '@juno/utils';
import { QA_ACTIONS } from '../../constants';
import { QAMessage } from '../../types';

interface QuestionItemProps {
  sender: JunoUser;
  message: QAMessage;
  id: string;
  createdAt: string;
  localParticipant: any;
  sendMessage: any;
  isModerator: boolean;
}
const QuestionItem: React.FC<QuestionItemProps> = ({
  sender,
  id,
  createdAt,
  localParticipant,
  message,
  sendMessage,
  isModerator,
}) => {
  const formattedTimeSinceLastMessage = getTimeSinceCreated(false, createdAt);
  const { user: currentUser, site } = useSettings();
  const isLocalUser = (user: JunoUser) => user.id === currentUser?.id;
  const [isDeleted, setIsDeleted] = useState(false);
  const [showDeleteQDialog, setShowDeleteQDialog] = useState(false);
  const [showDeleteADialog, setShowDeleteADialog] = useState(false);
  const [userQuickViewAnchorEl, setUserQuickViewAnchorEl] = useState<HTMLElement | null>(null);

  const navigateRoute = useNavigate();

  useEffect(() => {}, [message.isAnswered, message.answer]);

  useEffect(() => {
    setIsDeleted(message.isDeleted || false);
  }, [message.isDeleted]);

  const upVote = () => {
    // TODO send the updated message to the server
    if (message.voters.includes(localParticipant.name)) {
      message.voters = message.voters.filter((voter: string) => voter !== localParticipant.name);
    } else {
      message.voters.push(localParticipant.name);
    }

    sendMessage(
      {
        messageId: id,
        message,
      },
      QA_ACTIONS.UPVOTE,
    );
  };

  const answerQuestion = () => {
    // TODO send the updated message to the server
    message.isAnswered = true;
    setIsAnswering(false);
    sendMessage(
      {
        messageId: id,
        message,
      },
      QA_ACTIONS.ANSWER_QUESTION,
    );
  };

  const markAsAnswered = () => {
    // TODO send the updated message to the server
    message.isAnswered = !message.isAnswered;
    sendMessage(
      {
        messageId: id,
        message,
      },
      QA_ACTIONS.MARK_AS_ANSWERED,
    );
  };

  const markAsDeleted = () => {
    // TODO send the updated message to the server
    message.isDeleted = true;
    setIsDeleted(true);
    sendMessage(
      {
        messageId: id,
        message,
      },
      QA_ACTIONS.DELETE_QUESTION,
    );
  };

  const deleteAnswer = () => {
    // TODO send the updated message to the server
    message.answer = undefined;
    message.isAnswered = false;
    sendMessage(
      {
        messageId: id,
        message,
      },
      QA_ACTIONS.DELETE_ANSWER,
    );
  };

  const [isAnswering, setIsAnswering] = useState(false);
  const [viewAnswer, setViewAnswer] = useState(
    message.answer
      ? !JSON.parse(sessionStorage.getItem('hiddenAnswers') || '[]').includes(id)
      : true,
  );

  const answerBtnText = useMemo(() => {
    if (message.answer) {
      return viewAnswer ? 'Hide Answer' : 'View Answer';
    } else if (isAnswering) {
      return 'Cancel';
    } else {
      return 'Answer';
    }
  }, [message.answer, message.isAnswered, isAnswering, viewAnswer]);

  const hideAnswer = () => {
    setViewAnswer((old) => !old);
    // set sessionStorage to remember which answers we've hidden
    // TODO do we want to clear this when the session ends or something?
    const hiddenAnswers = JSON.parse(sessionStorage.getItem('hiddenAnswers') || '[]');
    if (hiddenAnswers.includes(id)) {
      hiddenAnswers.splice(hiddenAnswers.indexOf(id), 1);
    } else {
      hiddenAnswers.push(id);
    }
    sessionStorage.setItem('hiddenAnswers', JSON.stringify(hiddenAnswers));
  };
  const normalUserActionSection = () => {
    return message.answer || message.isAnswered ? (
      <>
        <Grid item xs={9}>
          {message.answer && (
            <Button variant='text' color='primary' size='small' onClick={hideAnswer}>
              {answerBtnText}
            </Button>
          )}
        </Grid>
        <Grid item xs={3} justifyContent={'flex-end'} display={'flex'} flexDirection={'row'}>
          <Tooltip
            placement='top'
            title={message.answer || message.isAnswered ? 'Answered' : 'Unanswered'}
          >
            <IconButton>
              {message.answer || message.isAnswered ? (
                <CheckCircle fontSize='small' color='primary' />
              ) : (
                <CheckCircleOutline fontSize='small' />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
      </>
    ) : null;
  };
  const moderatorUserActionSection = () => {
    return (
      <>
        <Grid item xs={3}>
          <Tooltip placement='top' title={'Delete question'}>
            <IconButton onClick={() => setShowDeleteQDialog(true)}>
              <DeleteOutlined fontSize='small' />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={6} justifyContent={'center'} display={'flex'} flexDirection={'row'}>
          <Button
            variant='text'
            color='primary'
            size='small'
            onClick={() => {
              if (message.answer) {
                hideAnswer();
              } else {
                setIsAnswering((old) => !old);
              }
            }}
          >
            {answerBtnText}
          </Button>
        </Grid>
        <Grid item xs={3} justifyContent={'flex-end'} display={'flex'} flexDirection={'row'}>
          {isModerator ? (
            <Tooltip
              placement='top'
              title={message.answer || message.isAnswered ? 'Answered' : 'Mark as answered'}
            >
              <IconButton onClick={markAsAnswered}>
                {message.answer || message.isAnswered ? (
                  <CheckCircle fontSize='small' color='primary' />
                ) : (
                  <CheckCircleOutline fontSize='small' />
                )}
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip
              placement='top'
              title={message.answer || message.isAnswered ? 'Answered' : 'Unanswered'}
            >
              <IconButton>
                {message.answer || message.isAnswered ? (
                  <CheckCircle fontSize='small' color='primary' />
                ) : (
                  <CheckCircleOutline fontSize='small' />
                )}
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </>
    );
  };

  if (isDeleted) {
    return null;
  }

  return (
    <>
      <ListItem sx={{ p: 0, mt: 1 }}>
        <Stack spacing={0} mb={1} width={'100%'}>
          <Card sx={{ p: '5px 12px', width: '100%' }}>
            <Grid container spacing={1}>
              <Grid item xs={10}>
                <Stack>
                  <Typography
                    variant={'subtitle2'}
                    onClick={(e) => setUserQuickViewAnchorEl(e.currentTarget)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {/* // TODO fix this to be senderName */}
                    {isLocalUser(sender) ? 'You' : `${sender.first_name} ${sender.last_name}`}
                  </Typography>
                  <Typography variant={'caption'} fontSize={'0.7rem'}>
                    {formattedTimeSinceLastMessage}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Tooltip title={'Upvote'}>
                  <IconButton onClick={upVote}>
                    <Badge
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      badgeContent={message?.voters?.length || 0}
                      color='primary'
                      sx={{ '& .MuiBadge-badge': { fontSize: 9, height: 15, minWidth: 15 } }}
                      max={99}
                      invisible={
                        message.voters?.includes(localParticipant.name) &&
                        message.voters.length === 1
                      }
                    >
                      <ThumbUpAltOutlined
                        fontSize='small'
                        color={
                          message.voters?.includes(localParticipant.name) ? 'primary' : 'inherit'
                        }
                      />
                    </Badge>
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'body2'}>{message.question}</Typography>
              </Grid>
              <>
                {isModerator || isLocalUser(sender)
                  ? moderatorUserActionSection()
                  : normalUserActionSection()}
                {isAnswering && (
                  <Grid item xs={12}>
                    <FormControl fullWidth variant='outlined'>
                      <OutlinedInput
                        value={message.answer?.answerText}
                        onChange={(e) => {
                          if (message.answer) {
                            message.answer.answerText = e.target.value;
                          } else {
                            message.answer = {
                              userId: currentUser?.id || '',
                              answerText: e.target.value,
                              timestamp: new Date().getTime(),
                            };
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            answerQuestion();
                          }
                        }}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='Post answer'
                              onClick={answerQuestion}
                              edge='end'
                            >
                              <SendOutlined />
                            </IconButton>
                          </InputAdornment>
                        }
                        multiline
                        sx={{
                          background: 'white',
                          border: 'none',
                          borderRadius: '5px',
                          minHeight: '60px',
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
              </>
            </Grid>
          </Card>
          {message.answer && viewAnswer && (
            <Card sx={{ p: '5px 12px', width: '90%', mt: 1, alignSelf: 'flex-end' }}>
              <Stack
                direction={'row'}
                spacing={1}
                justifyContent={'space-between'}
                alignItems={'flex-start'}
              >
                <Typography variant={'body2'} sx={{ fontStyle: 'italic' }}>
                  {message.answer.answerText}
                </Typography>
                {(isModerator || isLocalUser(sender)) && (
                  <Tooltip placement='top' title={'Delete answer'}>
                    <IconButton onClick={() => setShowDeleteADialog(true)}>
                      <DeleteOutlined fontSize='small' />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
            </Card>
          )}
        </Stack>
      </ListItem>
      {showDeleteADialog && (
        <ConfirmDeleteDialog
          deleteButtonText='Confirm'
          handleClose={() => {
            setShowDeleteADialog(false);
          }}
          handleDelete={() => {
            setShowDeleteADialog(false);
            deleteAnswer();
          }}
          title={'Delete Answer'}
          message={
            <Typography sx={{ mt: 2 }} variant='body2'>
              Are you sure you want to permanently delete this answer?
            </Typography>
          }
        />
      )}
      {showDeleteQDialog && (
        <ConfirmDeleteDialog
          deleteButtonText='Confirm'
          handleClose={() => {
            setShowDeleteQDialog(false);
          }}
          handleDelete={() => {
            setShowDeleteQDialog(false);
            markAsDeleted();
          }}
          title={'Delete Question'}
          message={
            <Typography sx={{ mt: 2 }} variant='body2'>
              Are you sure you want to permanently delete this question? This action cannot be
              undone, however the question has been preserved for analytics.
            </Typography>
          }
        />
      )}
      <UserQuickView
        anchorEl={userQuickViewAnchorEl}
        userId={sender.id}
        open={Boolean(userQuickViewAnchorEl)}
        setAnchorEl={setUserQuickViewAnchorEl}
        onViewProfile={(userEmail) => {
          navigateRoute(getContentRoute(site?.slug || '', sender.id, SearchContentTypeEnum.user));
        }}
        onMessageUser={function (userId) {
          throw new Error('Function not implemented.');
        }}
      />
    </>
  );
};

export default QuestionItem;
