import { useEffect, useState } from 'react';
import { Box, Dialog } from '@mui/material';
import { useBreakpoint } from '@juno/utils';

const DialogAriaWrapper = ({ id, children, ...props }: any) => {
  //get initial body height
  const [bodyHeight, setBodyHeight] = useState(document.body.getBoundingClientRect().height);
  const { xs } = useBreakpoint();

  useEffect(() => {
    //listen for body height changes
    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const height = entry.borderBoxSize?.[0].blockSize;
        if (typeof height === 'number') {
          setBodyHeight(height);
        }
      }
    });

    observer.observe(document.body, { box: 'border-box' });

    //clean up
    return () => {
      observer.disconnect();
    };
  }, []);

  //NOTES: dialog has an interior wrapper now that will adjust height to match body (helps with soft keyboards)
  //This Box has no other styling, so if your contents expect the container to be flex, your contents should be wrapped thusly
  //PostDetailDialog uses this correctly now, including safe padding at the top
  //The special behavior is specific to mobile, so we will only fill the screen when breakpoint is xs
  return (
    <Dialog
      {...props}
      aria-labelledby={`${id}-dialog-title`}
      aria-describedby={`${id}-dialog-description`}
      fullScreen={xs ? true : false}
    >
      <Box
        sx={{
          position: 'relative',
          height: xs ? `calc(${bodyHeight}px)` : 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>
    </Dialog>
  );
};

export default DialogAriaWrapper;
