import * as React from 'react';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

interface DefaultSingleBasicAutocompleteProps {
  chosenOption: any;
  options: any[];
  valueKey: string;
  labelKey: string;
  handleChange: (event: any, option: any) => void;
  sortKey?: string;
  inputLabel?: string;
  sxProps?: any;
}

/**
 * Should be able to use this for any single-select autocomplete
 * - does not group items
 * - works with any model object using the `valueKey` and `labelKey` props
 * @param chosenOption object
 * @param options array
 * @param valueKey string
 * @param labelKey string
 * @param handleChange function
 * @param sortKey string
 * @param inputLabel string
 * @param sxProps object
 * @example
 * <DefaultSingleBasicAutocomplete
 * chosenOption={chosenTag}
 * options={allTags}
 * valueKey='id'
 * labelKey='name'
 * handleChange={handleChange}
 * sxProps={{ mb: 2 }}
 * />
 *
 */

const DefaultSingleBasicAutocomplete: React.FC<DefaultSingleBasicAutocompleteProps> = ({
  chosenOption,
  options,
  valueKey,
  labelKey,
  handleChange,
  sortKey,
  inputLabel,
  sxProps,
}) => {
  const optionSorter = () => {
    if (sortKey) {
      return options.sort((a, b) =>
        b[sortKey] && a[sortKey] ? -b[sortKey]?.localeCompare(a[sortKey]) : 0,
      );
    }
    return options;
  };
  return (
    <>
      <Autocomplete
        options={optionSorter()}
        getOptionLabel={(option) => option?.[labelKey] || ''}
        value={chosenOption || null}
        defaultValue={''}
        isOptionEqualToValue={(option, value) => option?.[valueKey] === value?.[valueKey]}
        onChange={(event, value) => {
          handleChange(event, value);
        }}
        renderInput={(params) => <TextField {...params} label={inputLabel} />}
        sx={{ ...sxProps }}
      />
    </>
  );
};

export default DefaultSingleBasicAutocomplete;
