import { JunoUser } from '@juno/client-api/model';
import { calculateDateFilterString } from '../ContentGrid/form/dateFilterUtils';

export const filterObjectBySubstring = (
  obj: { [key: string]: any },
  substrings: string[],
): { [key: string]: any } => {
  const filteredObj: { [key: string]: any } = {};

  for (const key in obj) {
    if (substrings.some((substring) => key.includes(substring))) {
      filteredObj[key] = obj[key];
    }
  }

  return filteredObj;
};

export const handleContentPreFilter = (settings: any, userData: JunoUser | undefined) => {
  // Load page builder filters, Date Filters, and handle bar interpolation
  const [updatedFilterString, updatedDateFilters] = calculateDateFilterString(
    settings,
    settings?.dateFilters || [],
    [],
    true,
  );
  if (settings) {
    settings.filter = updatedFilterString;
    settings.dateFilterString = updatedFilterString;
    settings.dateFilters = updatedDateFilters;
  }
  // Set dynamic date filters point of reference to today
  const now = new Date().toISOString();
  // TODO: Temporary fix for the filter
  let filterStr = settings?.filter || '{}';
  if (filterStr.includes('{{USER_ID}}') && userData?.id) {
    // TODO: Handles search replace for handlebar'ish syntax
    filterStr = filterStr.replaceAll('{{USER_ID}}', userData.id);
  }
  filterStr = filterStr.replaceAll('{{UTC_NOW}}', now);
  const filter = JSON.parse(filterStr);
  return {
    filter,
    filterStr,
  };
};
