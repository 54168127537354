import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { Editor } from '@tinymce/tinymce-react';
import { Reorder } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getGetCourseLessonsQueryKey,
  getGetLessonPartsQueryKey,
  getGetLessonQueryKey,
  useCreateDownload,
  useCreateLessonPart,
  useCreateLessonPartResource,
  useCreateLessonQuestion,
  useDeleteCourseResource,
  useDeleteLesson,
  useDeleteLessonPart,
  useGetCourse,
  useGetLesson,
  useGetLessonParts,
  useUpdateCourseResource,
  useUpdateLesson,
  useUpdateLessonPart,
  useUpdateLessonQuestion,
} from '@juno/client-api';
import { JunoImage as JunoImageModel } from '@juno/client-api/fakeModel';
import {
  AllowRetakesEnum,
  Lesson as LessonModel,
  LessonPart,
  LessonPart as LessonPartModel,
  Question as QuestionModel,
  Site as SiteModel,
  TypeD1bEnum,
} from '@juno/client-api/model';
import {
  Download as DownloadUtils,
  LessonPart as LessonPartUtils,
  Question as QuestionUtils,
} from '@juno/client-api/utils';
import { JUNO_ROUTE_MAP, TINY_MCE_DEFAULT_CONFIG } from '@juno/constants';
import { useS3Upload } from '@juno/modules';
import {
  AlertDialog,
  DialogAriaWrapper,
  JunoImageUpload,
  JunoSpin,
  JunoRowCreateTile as RowCreateTile,
  TimeDurationInput,
  VideoUploadPopup,
} from '@juno/ui';
import {
  MutationAction,
  cancelVideoFileUpload,
  onMutation,
  optimizeImage,
  secondsToHmsNumber,
  slugify,
  snackOptions,
  uploadImagesToCloudinary,
  uploadTinyMceImageCloudinary,
  useRouteNavigate,
  useSettings,
} from '@juno/utils';
import { PayloadProps } from '../CoursePanel/CourseResources/FormDialog';
import { CoursePanelContext } from '../CoursePanel/context';
import BackButton from '../components/BackButton';
import RowTile from '../components/LessonPartTile';
import SaveBar from '../components/SaveBar';
import VideoUploadCancellationModal from '../components/VideoUploadCancellationModal';
import { COURSE_STATUS } from '../constants';
import LessonPartForm from './LessonPartForm';
import LessonPartTypeDialog from './LessonPartTypeDialog';
import QuestionBuilder, { TEMP_ID_PREFIX } from './QuestionBuilder';
import ResourceBuilder from './ResourceBuilder';
import { PayloadProps as ResourcePayloadProps } from './ResourceBuilder/Form';
import { PartRowWrapper } from './styles';

const QUESTIONTYPE = LessonPartUtils.LESSON_PART_TYPES.QUESTION.value;
const VIDEOTYPE = LessonPartUtils.LESSON_PART_TYPES.VIDEO.value;

interface LessonPanelProps {
  site: SiteModel;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const DEFAULT_QUESTION = {
  id: '',
  name: '',
  question: '',
  type: QuestionUtils.QUESTION_TYPES.MULTIPLE_CHOICE.value,
  answer_changeable: false,
  answers: [],
  lesson_part: {
    id: '',
    type: LessonPartUtils.LESSON_PART_TYPES.QUESTION.value,
  },
};

const LessonPanel: React.FC<LessonPanelProps> = ({ site }) => {
  const theme = useTheme();
  const { id: siteId, slug: string } = site;
  const params = useParams() as { courseSlug?: string; tabSlug?: string; lessonSlug?: string };
  const siteSlug: string = site.slug || '';
  const courseSlug: string = params.courseSlug || '';
  const lessonSlug: string = params.lessonSlug || '';
  const [selectedPart, setSelectedPart] = useState<LessonPartModel | undefined>();
  const [error, setError] = useState<string>('');
  const [showQuestionDialog, setShowQuestionDialog] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleteLessonConfirmOpen, setIsDeleteLessonConfirmOpen] = useState(false);
  const [updatedLessonParts, setUpdatedLessonParts] = useState<LessonPartModel[]>([]);
  const [showTypeDialog, setShowTypeDialog] = useState(false);
  const [selectedPartTab, setSelectedPartTab] = useState<number>(
    LessonPartUtils.LESSON_PART_TYPES.QUESTION.value,
  );
  const navigate = useNavigate();
  // Video upload
  const coursePanelContext = useContext(CoursePanelContext);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [launchCount, incrementLaunchCount] = useState(0);
  const [fileName, setFileName] = useState('');
  const [videoUrlError, setVideoUrlError] = useState(false);
  const [saveVideoLessonPartIsReady, setSaveVideoLessonPartIsReady] = useState(false);
  const [videoFileIsCanceled, setVideoFileIsCanceled] = useState(false);
  const [fileUploadPromise, setFileUploadPromise] = useState<Promise<any> | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  // Resource Upload
  const [resourcePayloads, setResourcePayloads] = useState<ResourcePayloadProps[]>([]);

  const handleVideoUpdate = (key: string, val: unknown) => {
    setSelectedPart({ ...selectedPart, [key]: val } as any);
  };
  const onVideoUploaded = (videoUrl: string) => {
    setIsUploading(false);
    handleVideoUpdate('video_url', videoUrl);
    handlePartSave();
    setUploadProgress(0);
    coursePanelContext.setIsVideoUploading(false);
  };
  const onVideoUploadStarted = () => {
    setIsUploading(true);
    setShowQuestionDialog(false);
    coursePanelContext.setIsVideoUploading(true);
  };

  // Create a pause that allows the lesson part URL to be asynchronously saved
  useEffect(() => {
    if (saveVideoLessonPartIsReady) {
      saveVideoPart();
    }
    return () => {
      setSaveVideoLessonPartIsReady(false);
      setShowQuestionDialog(false);
    };
  }, [saveVideoLessonPartIsReady]);

  // Cancel the video upload
  const onCancelUploadDuring = async (videoPutPromise: Promise<any> | null) => {
    if (!videoPutPromise) {
      return;
    } else {
      try {
        enqueueSnackbar(
          'The video Lesson Part upload was successfully canceled',
          snackOptions('success'),
        );
        await cancelVideoFileUpload(videoPutPromise);
      } catch (err) {
        console.error(err);
      }
    }
  };
  useEffect(() => {
    if (fileUploadPromise && videoFileIsCanceled) {
      onCancelUploadDuring(fileUploadPromise);
      setIsUploading(false);
    }
  }, [fileUploadPromise, videoFileIsCanceled]);

  const [payload, setPayload] = useState<LessonModel | undefined | any>();
  const [questionState, setQuestionState] = useState<QuestionModel>(DEFAULT_QUESTION);
  const [openVideoUploadCancellationModal, setOpenVideoUploadCancellationModal] = useState(false);
  const { data: lessonData, isLoading: lessonLoading } = useGetLesson(
    siteId,
    courseSlug,
    lessonSlug,
  );
  const { data: partsData, isLoading: partsLoading } = useGetLessonParts(
    siteId,
    courseSlug,
    lessonSlug,
    { order: 'order' },
  );

  const isLoading = lessonLoading || partsLoading;
  const queryClient = useQueryClient();
  const learningNavigate = useRouteNavigate();
  const { isWidget } = useSettings();
  const { data: courseData } = useGetCourse(siteId, courseSlug);
  const [isTimed, setIsTimed] = useState(false);

  // Retake stuff
  const [allowRetakesIsChecked, setAllowRetakesIsChecked] = useState<boolean>(false);
  const [allowRetakesChoice, setAllowRetakesChoice] = useState<string>('');
  const [retakeLimit, setRetakeLimit] = useState<number | undefined>(undefined);
  // Inform the UI when the Allow Retakes checkbox needs to be set
  useEffect(() => {
    if (payload?.allow_retakes) {
      setAllowRetakesIsChecked(payload.allow_retakes !== AllowRetakesEnum.never);
    }
  }, [payload?.allow_retakes]);

  const courseStatus = useMemo(() => {
    const now = new Date().toISOString();
    const dateStart = courseData?.date_start && new Date(courseData?.date_start).toISOString();
    const disabled = dateStart && dateStart < now;
    const locked = courseData?.lesson_completions_count && courseData.lesson_completions_count > 0;
    if (locked) return COURSE_STATUS.LOCKED;
    if (disabled) return COURSE_STATUS.DISABLED;
    return COURSE_STATUS.ENABLED;
  }, [courseData]);
  const isLocked = courseStatus === COURSE_STATUS.LOCKED;
  const isDisabled = isLocked || courseStatus === COURSE_STATUS.DISABLED;

  const refetchParts = () =>
    queryClient.invalidateQueries(getGetLessonPartsQueryKey(siteId, courseSlug, lessonSlug));
  const refetchLesson = () =>
    queryClient.invalidateQueries(getGetLessonQueryKey(siteId, courseSlug, lessonSlug));
  const refetchCoursesLessons = () =>
    queryClient.invalidateQueries(getGetCourseLessonsQueryKey(siteId, courseSlug));

  const refetch = () => {
    refetchParts();
    refetchLesson();
  };
  const updateLesson = useUpdateLesson(onMutation(MutationAction.UPDATE, 'Lesson', refetch));
  const createPart = useCreateLessonPart(onMutation(MutationAction.CREATE, 'LessonPart', refetch));
  const updatePart = useUpdateLessonPart(onMutation(MutationAction.UPDATE, 'LessonPart', refetch));
  const deletePart = useDeleteLessonPart(onMutation(MutationAction.DELETE, 'LessonPart', refetch));
  const deleteLesson = useDeleteLesson(
    onMutation(MutationAction.DELETE, 'Lesson', refetchCoursesLessons),
  );
  const qCreate = useCreateLessonQuestion(onMutation(MutationAction.CREATE, 'Question', refetch));
  const qUpdate = useUpdateLessonQuestion(onMutation(MutationAction.UPDATE, 'Question', refetch));

  const downloadCreate = useCreateDownload(onMutation(MutationAction.CREATE, 'Download', () => {}));

  // CourseResource mutations
  const partResourceCreate = useCreateLessonPartResource(
    onMutation(MutationAction.CREATE, 'CourseResource', refetch),
  );
  const partResourceUpdate = useUpdateCourseResource(
    onMutation(MutationAction.UPDATE, 'CourseResource', refetch),
  );
  const partResourceDelete = useDeleteCourseResource(
    onMutation(MutationAction.DELETE, 'CourseResource', refetch),
  );
  const { uploadFileToS3 } = useS3Upload();
  const handleUpdate = (key: string, val: unknown) =>
    setPayload((p: typeof payload) => ({ ...p, [key]: val }));

  const handleMetadataUpdate = (key: string, val: unknown) =>
    setPayload((p: typeof payload) => ({
      ...p,
      metadata: { ...p.metadata, [key]: val },
    }));

  useEffect(() => {
    // Prevent MUI dialog from blocking focus to the TinyMCE code editor
    document.addEventListener('focusin', (e) => {
      if (
        (e.target as Element).closest(
          '.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root',
        ) !== null
      ) {
        e.stopImmediatePropagation();
      }
    });
  }, []);

  useEffect(() => {
    if (payload?.title && payload?.title?.length > 90) {
      setError('Title character limit: 90');
    } else {
      setError('');
    }
  }, [payload?.title]);

  const changeHandler = {
    checkbox: ({ target }: ChangeEvent<HTMLInputElement>) =>
      handleUpdate(target.name, target.checked),
    text: ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (target.name === 'slug') {
        // Require slug to follow slugify rules (alphanumeric, dashes, underscores, and uppercase here)
        handleUpdate(target.name, slugify(target.value, true));
        return;
      }
      handleUpdate(target.name, target.value);
    },
    textarea: ({ target }: ChangeEvent<HTMLTextAreaElement>) =>
      handleUpdate(target.name, target.value),
    tinymcetextarea: (key: string, value: string) => handleUpdate(key, value),
    number: ({ target }: ChangeEvent<HTMLInputElement>) =>
      handleUpdate(target.name, parseInt(target.value) || 0),
    image: (key: string, image: JunoImageModel | null) => handleUpdate(key, image?.url || ''),
    time: (key: string, value: number | null) => handleUpdate(key, value),
    select: (key: string, value: string) => handleUpdate(key, value),
    metadata: (key: string, value: string | number) => handleMetadataUpdate(key, value),
  };

  const navigateBack = () => {
    if (!isUploading) {
      navigate(`/${siteSlug}/admin/learning/${courseSlug}/lessons`);
    }

    setOpenVideoUploadCancellationModal(true);
  };

  const handleSave = async () => {
    setIsSaving(true);
    const courseId = courseData?.id || '';
    const lessonId = payload?.id || '';
    const data = { ...payload, parts: updatedLessonParts };
    try {
      const oldSlug = lessonData?.slug;
      const newLesson = await updateLesson.mutateAsync({ siteId, courseId, lessonId, data });
      const newSlug = newLesson.slug;
      if (oldSlug !== newSlug) {
        learningNavigate(JUNO_ROUTE_MAP.ADMIN_LESSON, {
          siteSlug,
          courseSlug,
          lessonSlug: newSlug,
        });
      }
      setIsSaving(false);
    } catch (e) {
      // error is captured and displayed on toast
      setIsSaving(false);
      return;
    }
    setIsSaving(false);
  };

  const handleSelectCreateType = (type: number) => {
    setShowTypeDialog(false);
    setShowQuestionDialog(true);
    const partObj = Object.values(LessonPartUtils.LESSON_PART_TYPES).find((t) => t.value === type);
    const questionCount = (partsData?.filter((part) => part.type === type).length || 0) + 1;
    const defaultTitle = `${lessonData?.title || ''}, ${partObj?.label || ''} #${questionCount}`;
    const partPayload = {
      id: '',
      lesson_id: '',
      type: type as TypeD1bEnum,
      title: defaultTitle,
      resources: [],
    };
    setSelectedPart(partPayload);
    setQuestionState({ ...DEFAULT_QUESTION, name: defaultTitle });
    setSelectedPartTab(type);
  };

  const handleSelectCreate = () => {
    setShowTypeDialog(true);
  };

  const handleSelectSettings = (part: LessonPartModel) => {
    setSelectedPartTab(part.type as number);
    if (part.type === VIDEOTYPE) {
      setSelectedPart(part);
    } else {
      setSelectedPart(part);
    }
    setQuestionState(part?.question?.[0] || DEFAULT_QUESTION);
    setShowQuestionDialog(true);
  };

  const handleClosePartDialog = () => {
    if (isUploading) {
      setSelectedPart(undefined);
      setShowQuestionDialog(false);
      return;
    }
    onCloseQuestionDialog();
  };

  const onCloseQuestionDialog = () => {
    setOpenVideoUploadCancellationModal(false);
    setSelectedPart(undefined);
    setShowQuestionDialog(false);
  };

  const onVideoUploadCancelConfirm = () => {
    setOpenVideoUploadCancellationModal(false);
    setSelectedPart(undefined);
    learningNavigate(JUNO_ROUTE_MAP.ADMIN_COURSE_TAB, {
      siteSlug,
      courseSlug,
      tabSlug: 'lessons',
    });
  };

  const handleDeletePart = () => {
    if (!selectedPart) return;
    const courseId = courseData?.id || '';
    const lessonId = lessonData?.id || '';
    deletePart.mutate({ siteId, courseId, lessonId, partId: selectedPart.id });
    setSelectedPart(undefined);
    setConfirmDeleteOpen(false);
  };

  const handleSelectDelete = (part: LessonPartModel) => {
    setConfirmDeleteOpen(true);
    setSelectedPart(part);
  };

  const handleCloseDeleteLessonConfirm = () => {
    setIsDeleteLessonConfirmOpen(false);
  };
  const handleOpenDeleteLessonConfirm = () => {
    setIsDeleteLessonConfirmOpen(true);
  };
  const handleDeleteLesson = async () => {
    if (!courseData?.id || !lessonData?.id) return;
    setIsSaving(true);
    await deleteLesson.mutateAsync({ siteId, courseId: courseData.id, lessonId: lessonData.id });
    navigateBack();
    setIsSaving(false);
  };

  useEffect(() => {
    setPayload(lessonData);
    setIsTimed(!!lessonData?.time_allowed);
  }, [lessonData]);

  useEffect(() => {
    setUpdatedLessonParts(partsData || []);
  }, [partsData]);

  useEffect(() => {
    // Update lesson part resource payloads
    if (selectedPart && selectedPart?.type === LessonPartUtils.LESSON_PART_TYPES.RESOURCE.value) {
      setResourcePayloads(
        selectedPart.resources.map((resource) => {
          return {
            resource: resource,
          };
        }),
      );
    }
  }, [selectedPart]);

  const handleLessonPartTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setSelectedPartTab(newTab);
  };

  const saveQuestion = async () => {
    const courseId = courseData?.id || '';
    const lessonId = payload?.id || '';
    // remove temp id from new answers
    const cleanedAnswers = questionState.answers.map((answer) => ({
      ...answer,
      id: answer.id.startsWith(TEMP_ID_PREFIX) ? '' : answer.id,
    }));
    const data = { ...questionState, answers: cleanedAnswers };
    if (QuestionUtils.QUESTION_TYPES.RATING.value !== questionState.type) {
      delete data.metadata?.min;
      delete data.metadata?.max;
      delete data.metadata?.increment;
    }
    if (QuestionUtils.QUESTION_TYPES.POLL.value !== questionState.type) {
      delete data.metadata?.showVoting;
    }
    if (!selectedPart || !(selectedPart?.id?.length > 0)) {
      const partData = {
        id: '',
        type: selectedPartTab as TypeD1bEnum,
        order: updatedLessonParts?.length || 0,
      };
      const newData = { ...data, lesson_part: partData };
      try {
        await qCreate.mutateAsync({ siteId, courseId, lessonId, data: newData });
      } catch (e) {
        console.error(e);
        return;
      }
    } else {
      try {
        await qUpdate.mutateAsync({ siteId, courseId, lessonId, questionId: data.id, data });
      } catch (e) {
        console.error(e);
        return;
      }
    }
    setShowQuestionDialog(false);
  };

  const getFiletype = (payloadFile: File) => {
    if (payloadFile.type) return payloadFile.type;
    if (payloadFile?.name) {
      const fileExtension = payloadFile?.name.split('.').pop();
      return `.${fileExtension}`;
    }
    return 'unknown filetype';
  };

  const handleResourcePayloadSave = async (payload: PayloadProps | undefined, partId: string) => {
    if (!payload?.resource || !courseData || !lessonData) return;
    const data = payload?.resource;
    // remove the download object from the resource
    delete data['download'];
    if (payload.iconFile) {
      // If updating the icon, upload to cloudinary and get the url
      const response = await uploadImagesToCloudinary([payload.iconFile]);
      data.icon = response.url;
    }
    if (payload.file) {
      // If updating the file, upload to s3, get the url,
      // create a Download and attach it to the CourseResource
      const downloadObj = DownloadUtils.fromCourseResource(data);
      downloadObj.filetype = getFiletype(payload.file);
      downloadObj.url = await uploadFileToS3(payload.file);
      const download = await downloadCreate.mutateAsync({ siteId, data: downloadObj });
      data.download = download.id;
    }
    if (payload?.resource?.id && payload?.resource?.id?.length > 0) {
      // if existing resource update it
      await partResourceUpdate.mutateAsync({ siteId, resourceId: payload?.resource?.id, data });
    } else {
      // if new resource create it
      await partResourceCreate.mutateAsync({
        siteId,
        courseId: courseData?.id,
        lessonId: lessonData?.id,
        partId: partId,
        data,
      });
    }
  };

  const saveResourcePart = async () => {
    setIsSaving(true);
    const courseId = courseData?.id || '';
    const lessonId = payload?.id || '';
    // remove temp id from new resources
    const cleanedResourcePayloads = resourcePayloads.map((resourcePayload) => ({
      ...resourcePayload,
      resource: {
        ...resourcePayload.resource,
        id: resourcePayload?.resource?.id.startsWith(TEMP_ID_PREFIX)
          ? ''
          : resourcePayload?.resource?.id,
      },
    }));
    const data = {
      ...selectedPart,
      id: selectedPart?.id || '',
      lesson_id: selectedPart?.lesson_id || '',
      type: selectedPartTab as TypeD1bEnum,
      order: selectedPart?.order || 0,
      question: [],
      resources: [],
    };
    let currentPart: LessonPart | undefined = undefined;
    if (!data?.id) {
      data.order = updatedLessonParts?.length || 0;
      try {
        currentPart = await createPart.mutateAsync({ siteId, courseId, lessonId, data });
      } catch (e) {
        console.error(e);
        setIsSaving(false);
        return;
      }
    } else {
      try {
        currentPart = await updatePart.mutateAsync({
          siteId,
          courseId,
          lessonId,
          partId: data.id,
          data,
        });
      } catch (e) {
        console.error(e);
        setIsSaving(false);
        return;
      }
    }
    // Update resources from the resource payloads
    for (let i = 0; i < cleanedResourcePayloads.length; i++) {
      const payload = cleanedResourcePayloads[i];
      await handleResourcePayloadSave(payload, currentPart.id);
    }
    setShowQuestionDialog(false);
    setIsSaving(false);
  };

  const savePart = async () => {
    const courseId = courseData?.id || '';
    const lessonId = payload?.id || '';
    const data = {
      ...selectedPart,
      id: selectedPart?.id || '',
      lesson_id: selectedPart?.lesson_id || '',
      type: selectedPartTab as TypeD1bEnum,
      order: selectedPart?.order || 0,
      question: [],
      resources: [],
    };
    if (!data?.id) {
      data.order = updatedLessonParts?.length || 0;
      try {
        await createPart.mutateAsync({ siteId, courseId, lessonId, data });
      } catch (e) {
        console.error(e);
        return;
      }
    } else {
      try {
        await updatePart.mutateAsync({ siteId, courseId, lessonId, partId: data.id, data });
      } catch (e) {
        console.error(e);
        return;
      }
    }
    setShowQuestionDialog(false);
  };

  const saveVideoPart = async () => {
    const courseId = courseData?.id || '';
    const lessonId = payload?.id || '';
    const data = {
      ...selectedPart,
      id: selectedPart?.id || '',
      lesson_id: selectedPart?.lesson_id || '',
      type: LessonPartUtils.LESSON_PART_TYPES.VIDEO.value as TypeD1bEnum,
      order: selectedPart?.order || 0,
      question: [],
      resources: [],
    };
    if (!data?.id) {
      data.order = updatedLessonParts?.length || 0;
      try {
        await createPart.mutateAsync({ siteId, courseId, lessonId, data });
      } catch (e) {
        console.error(e);
        return;
      }
    } else {
      try {
        await updatePart.mutateAsync({ siteId, courseId, lessonId, partId: data.id, data });
      } catch (e) {
        console.error(e);
        return;
      }
      setShowQuestionDialog(false);
    }
  };

  const handlePartSave = () => {
    switch (selectedPartTab) {
      case LessonPartUtils.LESSON_PART_TYPES.QUESTION.value:
        saveQuestion();
        break;

      case LessonPartUtils.LESSON_PART_TYPES.VIDEO.value:
        if (
          (selectedPart?.video_url &&
            !selectedPart?.video_url?.toLowerCase().includes('.mp4') &&
            !selectedPart?.video_url?.toLowerCase().includes('youtube') &&
            !selectedPart?.video_url?.toLowerCase().includes('vimeo') &&
            !selectedPart?.video_url?.toLowerCase().includes('wistia.com')) ||
          selectedPart?.video_url?.toLowerCase().includes(' ')
        ) {
          setVideoUrlError(true);
          return;
        } else {
          setVideoUrlError(false);
        }
        setSaveVideoLessonPartIsReady(true);
        break;

      case LessonPartUtils.LESSON_PART_TYPES.RESOURCE.value:
        saveResourcePart();
        break;
      default:
        savePart();
        break;
    }
  };

  const rowTypeDisplay = (part: LessonPartModel) => {
    const question = part?.question?.[0];
    const partType = LessonPartUtils.labelFromType(part?.type);
    const questionType = QuestionUtils.labelFromType(question?.type);
    return [partType, questionType].filter(Boolean).join(' - ');
  };

  const interpolateAllowRetakesValue = (alwaysOrOnFail: string, limit: number) => {
    let allowRetakesValue: AllowRetakesEnum = AllowRetakesEnum.unlimited;
    // Align with Learning Lesson Retake Types
    switch (alwaysOrOnFail) {
      case AllowRetakesEnum.unlimited:
      case 'Always':
        if (limit > 0) {
          allowRetakesValue = AllowRetakesEnum.limited;
        }
        break;
      case 'On Fail':
        if (limit === 0) {
          allowRetakesValue = AllowRetakesEnum.on_fail_unlimited;
        } else {
          allowRetakesValue = AllowRetakesEnum.on_fail_limited;
        }
        break;
      default:
        break;
    }
    changeHandler.select('allow_retakes', allowRetakesValue);
  };

  const interpolateAllowRetakesSelectValue = () => {
    // Default value when Allow Retakes is disabled
    if (!allowRetakesIsChecked) {
      return 'Always';
    }
    // First check if there's a dirty choice
    if (allowRetakesChoice.toLowerCase().includes('fail')) {
      return 'On Fail';
      // With no current user choice, default to what is saved
    } else if (payload.allow_retakes && payload.allow_retakes.toLowerCase().includes('fail')) {
      return 'On Fail';
    }
    return 'Always';
  };

  const interpolateRetakeLimit = () => {
    // Default value when Allow Retakes is disabled
    if (!allowRetakesIsChecked) {
      return AllowRetakesEnum.unlimited;
    }
    // First check if there's a dirty choice
    if (retakeLimit) {
      return retakeLimit > 0 ? retakeLimit : AllowRetakesEnum.unlimited;
      // With no current user choice, default to what is saved
    } else {
      return payload.metadata.retake_limit
        ? payload.metadata.retake_limit
        : AllowRetakesEnum.unlimited;
    }
  };

  // Per JL-10351 acceptance criteria, user needs to be able to recover chosen values upon toggling Allow Retakes checkbox
  const handleUncheckCheckAllowRetakes = () => {
    // Grab the DB retake_limit value
    let limit = payload?.metadata?.retake_limit ? payload.metadata.retake_limit : 0;
    // If there's a new retake_limit user choice, switch to it
    if (retakeLimit) {
      changeHandler.metadata('retake_limit', retakeLimit);
      limit = retakeLimit;
    }
    // When the Allow Retakes checkbox was previously unchecked and we have a user choice
    if (payload.allow_retakes === AllowRetakesEnum.never && allowRetakesChoice) {
      const hasFail = allowRetakesChoice.toLowerCase().includes('fail');
      if (hasFail) {
        return limit > 0 ? AllowRetakesEnum.on_fail_limited : AllowRetakesEnum.on_fail_unlimited;
      } else {
        return limit > 0 ? AllowRetakesEnum.limited : AllowRetakesEnum.unlimited;
      }
    }
    // Otherwise, if there was a previously saved allow_retakes value we defer to it
    if (payload.allow_retakes && payload.allow_retakes !== AllowRetakesEnum.never) {
      return payload.allow_retakes;
    }
    return AllowRetakesEnum.unlimited;
  };

  const isDirty = useMemo(() => {
    const dirty = [
      'title',
      'slug',
      'description',
      'abstract',
      'passing_percent',
      'banner',
      'include_in_course_grade',
      'time_allowed',
      'allow_retakes',
      'metadata',
      'progress_mode',
      'show_results',
      'show_correct_answers',
      'randomize_lesson_part_order',
    ].some((key) => payload?.[key] !== lessonData?.[key as keyof LessonModel]);
    if (dirty) return true;
    if (partsData && updatedLessonParts) {
      return partsData.some((part, idx) => part.id !== updatedLessonParts[idx]?.id);
    }
    return false;
  }, [payload, partsData, lessonData, updatedLessonParts]);

  const discardChanges = () => {
    setPayload(lessonData);
    partsData && setUpdatedLessonParts(partsData);
  };

  if (isLoading) return <JunoSpin />;
  return (
    <Box sx={{ m: 'auto', mt: 6, maxWidth: '980px' }}>
      <SaveBar
        isDirty={isDirty}
        isValid={!error}
        isSaving={isSaving}
        onSave={handleSave}
        onDiscard={discardChanges}
      />
      {isUploading && (
        <VideoUploadPopup
          launchcount={launchCount}
          filename={fileName}
          value={uploadProgress}
          setVideoFileIsCanceled={setVideoFileIsCanceled}
        />
      )}
      <>
        <Typography
          variant='body2'
          sx={{ color: 'text.secondary', opacity: 0.5, fontStyle: 'italic', mb: 1 }}
        >
          Editing
        </Typography>
        <BackButton onClick={navigateBack}>
          <Typography variant='h4' sx={{ color: 'text.secondary', letterSpacing: '3px' }}>
            {`${isWidget ? 'FRONT-END EDITOR: ' : ''}${payload?.title || ''}`}
          </Typography>
        </BackButton>
      </>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <FormControl margin='dense' fullWidth>
            <TextField
              fullWidth
              id='title'
              name='title'
              label='Title'
              value={payload?.title || ''}
              onChange={changeHandler.text}
              error={!!error}
              helperText={error}
            />
          </FormControl>
          <FormControl margin='dense' fullWidth>
            <TextField
              fullWidth
              id='slug'
              name='slug'
              label='Slug'
              value={payload?.slug || ''}
              onChange={changeHandler.text}
            />
          </FormControl>
          <FormControl margin='dense' fullWidth>
            <Box sx={{ display: 'flex', width: '100%', gap: 1 }}>
              <TextField
                id='abstract'
                name='abstract'
                label='Preview Text'
                fullWidth
                value={payload?.abstract || ''}
                onChange={changeHandler.text}
              />
            </Box>
          </FormControl>
          <FormControl margin='dense' fullWidth>
            <Typography sx={{ mb: 1 }}>Description</Typography>
            <Editor
              apiKey={process.env.NX_TINY_MCE_API_KEY}
              onEditorChange={(value: string) => {
                changeHandler.tinymcetextarea('description', value);
              }}
              value={payload?.description || ''}
              init={{
                ...TINY_MCE_DEFAULT_CONFIG,
                images_upload_handler: uploadTinyMceImageCloudinary,
                setup: (editor) => {
                  editor.setProgressState(true);
                },
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ mt: 1 }}>
          <Stack spacing={2} direction='column'>
            <Box>
              <JunoImageUpload
                style={{ aspectRatio: '16/9' }}
                src={optimizeImage(255, payload?.banner)}
                srcUrl={payload?.banner}
                onFileUploaded={(selected) => changeHandler.image('banner', selected)}
              />
            </Box>
            {/* Graded Lesson */}
            <Stack spacing={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!payload?.include_in_course_grade}
                    onChange={changeHandler.checkbox}
                    name='include_in_course_grade'
                  />
                }
                label={<Typography variant='body2'>Graded Lesson</Typography>}
              />
              <TextField
                id='passing_percent'
                name='passing_percent'
                label='Lesson Passing %'
                value={payload?.passing_percent || ''}
                onChange={changeHandler.text}
                sx={{ minWidth: '120px' }}
                disabled={!!courseData?.metadata?.scorm_course}
              />
            </Stack>
            <Stack spacing={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTimed}
                    onChange={() => {
                      setIsTimed(!isTimed);
                      changeHandler.time('time_allowed', null);
                    }}
                    name='is_timed_lesson'
                  />
                }
                label={<Typography variant='body2'>Timed Lesson</Typography>}
              />
              <TimeDurationInput
                label={'hh:mm:ss'}
                value={secondsToHmsNumber(payload?.time_allowed)}
                onChange={(result) => {
                  isTimed && changeHandler.time('time_allowed', result.totalSeconds);
                }}
              />
            </Stack>
            {/* Allow Retakes */}
            <Stack spacing={1}>
              <FormControlLabel
                control={<Checkbox />}
                checked={allowRetakesIsChecked}
                label={<Typography variant='body2'>Allow Retakes</Typography>}
                onChange={(e) => {
                  setAllowRetakesIsChecked((prevState) => !prevState);
                  const target = e.target as HTMLInputElement;
                  changeHandler.select(
                    'allow_retakes',
                    target.checked ? handleUncheckCheckAllowRetakes() : AllowRetakesEnum.never,
                  );
                  // Recalibrate the retake limit when the user unchecks the Allow Retakes option
                  if (!target.checked) {
                    changeHandler.metadata('retake_limit', 0);
                  }
                }}
              />
              <Stack spacing={2}>
                <Stack direction={'row'} spacing={1}>
                  <FormControl fullWidth sx={{ minWidth: '100px' }}>
                    <InputLabel id='allow-retake-label'>Allow Retakes</InputLabel>
                    <Select
                      labelId='allow-retake-label'
                      label='Allow Retakes'
                      value={interpolateAllowRetakesSelectValue()}
                      disabled={!allowRetakesIsChecked}
                      onChange={(e) => {
                        interpolateAllowRetakesValue(
                          e.target.value,
                          payload.metadata.retake_limit || 0,
                        );
                        setAllowRetakesChoice(e.target.value);
                      }}
                    >
                      <MenuItem value='Always'>Always</MenuItem>
                      <MenuItem value='On Fail'>On fail</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl fullWidth sx={{ minWidth: '150px' }}>
                    <InputLabel id='retake-limit-label'>Quantity</InputLabel>
                    <Select
                      labelId='retake-limit-label'
                      label='Quantity'
                      value={interpolateRetakeLimit()}
                      disabled={!allowRetakesIsChecked}
                      onChange={(e) => {
                        const target = e.target as HTMLInputElement;
                        const value =
                          target.value === AllowRetakesEnum.unlimited
                            ? 0
                            : Number(parseFloat(target.value).toFixed(0));
                        changeHandler.metadata('retake_limit', value);
                        const oldValueOrChoice = allowRetakesChoice
                          ? allowRetakesChoice
                          : payload.allow_retakes;
                        interpolateAllowRetakesValue(oldValueOrChoice, value);
                        setRetakeLimit(value);
                      }}
                    >
                      <MenuItem value={AllowRetakesEnum.unlimited}>Unlimited</MenuItem>
                      <MenuItem value='1'>1</MenuItem>
                      <MenuItem value='2'>2</MenuItem>
                      <MenuItem value='3'>3</MenuItem>
                      <MenuItem value='4'>4</MenuItem>
                      <MenuItem value='5'>5</MenuItem>
                      <MenuItem value='6'>6</MenuItem>
                      <MenuItem value='7'>7</MenuItem>
                      <MenuItem value='8'>8</MenuItem>
                      <MenuItem value='9'>9</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                {/* Hiding for now - taken out of MVP */}
                {/* <FormControl fullWidth>
                  <InputLabel id='lesson-progress-label'>Lesson Progress</InputLabel>
                  <Select
                    labelId='lesson-progress-label'
                    label='Lesson Progress'
                    value={payload?.progress_mode || ''}
                    disabled={payload?.allow_retakes === 'never'}
                    fullWidth
                    onChange={(e) => {
                      changeHandler.select('progress_mode', e.target.value);
                    }}
                  >
                    <MenuItem value='allow'>Allow next lesson</MenuItem>
                    <MenuItem value='prevent_with_retakes'>Prevent with retakes remaining</MenuItem>
                    <MenuItem value='prevent_on_fail'>Prevent on fail</MenuItem>
                  </Select>
                </FormControl> */}
              </Stack>
            </Stack>
            {/* Show Results - hide for now, not MVP */}
            {/* <Stack spacing={1}>
              <Tooltip placement='top-start' title="Display user's answers as correct or incorrect">
                <FormControlLabel
                  control={<Checkbox checked={payload?.show_results !== 'never'} />}
                  label={<Typography variant='body2'>Show Results</Typography>}
                  onChange={(e) => {
                    const target = e.target as HTMLInputElement;
                    changeHandler.select(
                      'show_results',
                      target.checked ? 'after_passing' : 'never',
                    );
                  }}
                />
              </Tooltip>
              <FormControl fullWidth>
                <InputLabel id='show-results-label'>Show Results</InputLabel>
                <Select
                  labelId='show-results-label'
                  value={payload?.show_results || ''}
                  label='Show Results'
                  disabled={payload?.show_results === 'never'}
                  fullWidth
                  onChange={(e) => {
                    changeHandler.select('show_results', e.target.value);
                  }}
                >
                  <MenuItem value='after_complete'>After complete</MenuItem>
                  <MenuItem value='after_passing'>After passing</MenuItem>
                  <MenuItem value='after_final_retake'>After final retake</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            {/* Show Answers
            <Stack spacing={1}>
              <Tooltip placement='top-start' title='Display the correct answers'>
                <FormControlLabel
                  control={<Checkbox checked={payload?.show_correct_answers !== 'never'} />}
                  label={<Typography variant='body2'>Show Answers</Typography>}
                  onChange={(e) => {
                    const target = e.target as HTMLInputElement;
                    changeHandler.select(
                      'show_correct_answers',
                      target.checked ? 'after_passing' : 'never',
                    );
                  }}
                />
              </Tooltip>
              <FormControl fullWidth>
                <InputLabel id='show-answers-label'>Show Answers</InputLabel>
                <Select
                  labelId='show-answers-label'
                  value={payload?.show_correct_answers || ''}
                  label='Show Answers'
                  disabled={payload?.show_correct_answers === 'never'}
                  fullWidth
                  onChange={(e) => {
                    changeHandler.select('show_correct_answers', e.target.value);
                  }}
                >
                  <MenuItem value='after_complete'>After complete</MenuItem>
                  <MenuItem value='after_passing'>After passing</MenuItem>
                  <MenuItem value='after_final_retake'>After final retake</MenuItem>
                </Select>
              </FormControl>
            </Stack> */}
          </Stack>
          <Stack spacing={1} sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!payload?.randomize_lesson_part_order}
                  onChange={changeHandler.checkbox}
                  name='randomize_lesson_part_order'
                />
              }
              label={<Typography variant='body2'>Randomize Lesson Part Order</Typography>}
            />
          </Stack>
        </Grid>
      </Grid>
      <FormControl margin='dense' fullWidth sx={{ mb: 2 }}>
        {!isDisabled && (
          <Grid item xs={12} mt={10}>
            <Button
              variant='contained'
              color='primary'
              fullWidth
              onClick={handleOpenDeleteLessonConfirm}
            >
              Delete Lesson
            </Button>
          </Grid>
        )}
        <DialogAriaWrapper
          id={'deleteLessonDialog'}
          open={isDeleteLessonConfirmOpen}
          onClose={handleCloseDeleteLessonConfirm}
        >
          <DialogTitle id={'deleteLessonDialog-dialog-title'}>Delete Lesson</DialogTitle>
          <DialogContent id={'deleteLessonDialog-dialog-description'}>
            <Typography>
              Are you sure you want to delete this lesson? This action cannot be undone.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteLessonConfirm}>Cancel</Button>
            <LoadingButton
              loading={isSaving}
              variant='contained'
              onClick={handleDeleteLesson}
              autoFocus
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </DialogAriaWrapper>
      </FormControl>
      <FormControl variant='filled' fullWidth margin='dense'>
        <Typography variant='h1' sx={{ mb: 1, mt: 4 }}>
          Lesson Parts
        </Typography>
        <Typography variant='body2' sx={{ color: 'text.secondary', opacity: 0.8 }}>
          Create, edit, delete, or drag to reorder
        </Typography>
        <Box sx={{ p: 3, boxShadow: 'none' }}>
          <Grid container>
            <Grid item sm={12} md={11} lg={9} xl={8}>
              <Box sx={{ mb: 3, position: 'relative' }}>
                <Reorder.Group
                  axis='y'
                  onReorder={setUpdatedLessonParts}
                  onClick={(e) => e.stopPropagation()}
                  values={updatedLessonParts || []}
                  style={{ listStyle: 'none', padding: 0 }}
                >
                  {updatedLessonParts?.map((part, index) => {
                    // TODO: This is broken in the backend, need to update when it's fixed
                    const questions = part?.question as unknown as QuestionModel[];
                    const question = questions[0];
                    return (
                      <Reorder.Item id={part.id} key={part.id} value={part}>
                        <PartRowWrapper>
                          <Typography variant='h1' sx={{ mr: 4 }}>
                            {index + 1}
                          </Typography>
                          <RowTile
                            key={part.id}
                            title={question?.name || part?.title || ''}
                            type={question ? 'question' : part.video_url ? 'video' : 'blurb'}
                            subtitle={question?.question || part?.body || ''}
                            onDelete={() => handleSelectDelete(part)}
                            hideImage
                            sortable={true}
                            disabled={isDisabled}
                            onSettingsSelect={() => handleSelectSettings(part)}
                            footer={
                              // <Typography
                              //   color='primary'
                              //   // sx={{
                              //   //   mt: 2,
                              //   //   borderRadius: 20,
                              //   //   fontSize: 14,
                              //   //   textTransform: 'uppercase',
                              //   //   background: 'rgba(122,122,122,.15)',
                              //   //   padding: '2px 10px',
                              //   // }}
                              // >

                              // </Typography>
                              <Chip label={rowTypeDisplay(part)} color='primary' />
                            }
                          />
                        </PartRowWrapper>
                      </Reorder.Item>
                    );
                  })}
                </Reorder.Group>
              </Box>

              {!isDisabled && (
                <RowCreateTile onClick={handleSelectCreate} text='Create a lesson part' />
              )}
            </Grid>
          </Grid>
        </Box>
      </FormControl>
      <DialogAriaWrapper
        id={'questionBuilderDialog'}
        open={showQuestionDialog}
        onClose={handleClosePartDialog}
        maxWidth='md'
        fullWidth
        sx={{ zIndex: theme.zIndex.drawer + 2 }}
      >
        <DialogTitle id={'questionBuilderDialog-dialog-title'}>
          {`${LessonPartUtils.labelFromType(selectedPartTab)} Details`}
          {selectedPartTab === 2 && (
            <Typography
              variant='caption'
              sx={{ display: 'block', fontStyle: 'italic', color: grey[600] }}
            >
              Upload an MP4 or add your video url below.
            </Typography>
          )}
        </DialogTitle>

        <DialogContent id={'questionBuilderDialog-dialog-description'}>
          <TabPanel
            value={selectedPartTab}
            index={LessonPartUtils.LESSON_PART_TYPES.QUESTION.value}
          >
            <QuestionBuilder
              siteId={siteId}
              courseId={courseData?.id || ''}
              lessonId={lessonData?.id || ''}
              questionState={questionState}
              setQuestionState={setQuestionState}
            />
          </TabPanel>
          <TabPanel value={selectedPartTab} index={LessonPartUtils.LESSON_PART_TYPES.BLURB.value}>
            <LessonPartForm
              type={LessonPartUtils.LESSON_PART_TYPES.BLURB.value}
              selectedPart={selectedPart}
              setSelectedPart={setSelectedPart}
            />
          </TabPanel>
          <TabPanel value={selectedPartTab} index={LessonPartUtils.LESSON_PART_TYPES.VIDEO.value}>
            <LessonPartForm
              type={LessonPartUtils.LESSON_PART_TYPES.VIDEO.value}
              selectedPart={selectedPart}
              setSelectedPart={setSelectedPart}
              videoUrlError={videoUrlError}
              onVideoUploadStarted={onVideoUploadStarted}
              onVideoUploaded={onVideoUploaded}
              uploadProgress={uploadProgress}
              setUploadProgress={setUploadProgress}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              setLaunchCount={incrementLaunchCount}
              setFileName={setFileName}
              fileUploadPromise={fileUploadPromise}
              setFileUploadPromise={setFileUploadPromise}
              videoFileIsCanceled={videoFileIsCanceled}
              setVideoFileIsCanceled={setVideoFileIsCanceled}
            />
          </TabPanel>
          <TabPanel
            value={selectedPartTab}
            index={LessonPartUtils.LESSON_PART_TYPES.RESOURCE.value}
          >
            {selectedPart && (
              <ResourceBuilder
                siteId={siteId}
                courseId={courseData?.id || ''}
                lessonId={lessonData?.id || ''}
                lessonPartState={selectedPart}
                setLessonPartState={setSelectedPart as Dispatch<SetStateAction<LessonPart>>}
                isSaving={isSaving}
                payloads={resourcePayloads}
                setPayloads={setResourcePayloads}
              />
            )}
          </TabPanel>
          <DialogActions>
            <Button onClick={handleClosePartDialog} color='primary'>
              Cancel
            </Button>
            <LoadingButton
              loading={isSaving}
              variant='contained'
              onClick={handlePartSave}
              color='primary'
            >
              Save
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </DialogAriaWrapper>
      <VideoUploadCancellationModal
        open={openVideoUploadCancellationModal}
        onClose={() => {
          setOpenVideoUploadCancellationModal(false);
        }}
        onCancelConfirm={onVideoUploadCancelConfirm}
      />
      <AlertDialog
        open={confirmDeleteOpen}
        description={'Are you sure you want to delete this Lesson Part?'}
        onCancel={() => setConfirmDeleteOpen(false)}
        onConfirm={handleDeletePart}
      />
      <LessonPartTypeDialog
        open={showTypeDialog}
        onClose={() => setShowTypeDialog(false)}
        onSelect={(type) => handleSelectCreateType(type)}
      />
    </Box>
  );
};

export default LessonPanel;
