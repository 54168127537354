import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import {
  useGetAllSessions,
  useGetMe,
  useGetPages,
  useGetPlatform,
  useGetSite,
} from '@juno/client-api';
import {
  CommunityGroup as CommunityGroupModel,
  Course,
  Page,
  Product,
  Session,
  Speaker as SpeakerModel,
} from '@juno/client-api/model';
import { EMPTY_COURSE_URL, EMPTY_GROUP_URL } from '@juno/constants';
import {
  CourseFilter,
  CourseFilterContext,
  DateFilter,
  DefaultSearchSortFilter,
  JunoGrid,
  PreviewItemProps,
} from '@juno/ui';
import type { CoursePreFilterOptions } from '@juno/ui';
import { useSettings } from '@juno/utils';
import { handleGroupFilter, setDefaultGroupFilters, useGroupsHook } from '../CommunityGroup/hooks';
import { handleContentPreFilter } from '../Content/utils';
import {
  CourseSort,
  SortOption,
  SortOptions,
  setDefaultCourseFilters,
  useCoursesHook,
} from '../Course/hooks';
import { Container } from '../JunoModule/styles';
import { useSpeakersHook } from '../Speaker/hooks';
import { interpolateSortOption, sortOptionToObjectField } from './form/sortFilterUtils';

export type TabItemObject = {
  id: string;
  label: string;
  filter: string;
  filter_and_or?: object;
  order: string;
  preSortName?: string;
  preSortOrder?: string;
  temporarySort?: string;
  dataModel?: string;
  preFilterUserActions?: boolean;
  selectedUserActions?: object;
  dateFilters?: DateFilter[];
  dateFilterString?: string;
  filterOnlyMyGroups?: boolean;
  filterByPrivacyLevel?: string;
} & CoursePreFilterOptions;

export interface CourseModel extends Course {
  course_complete_percentage?: string;
  products?: Product[];
  restrict_access?: boolean;
}

export interface DataSourceProps {
  speakers: SpeakerModel[] | undefined;
  courses: CourseModel[] | undefined;
  communityGroups: CommunityGroupModel[] | undefined;
  sessions: Session[] | undefined;
  library: Page[] | undefined;
  idValMap?: { [key: string]: string };
  fullWidth?: boolean;
}

interface ModelMapProps {
  [key: string]: {
    basePath: string;
    clientBasePath?: string;
    mapItems: (dataSource: DataSourceProps) => PreviewItemProps[];
  };
}

export const MODEL_MAP: ModelMapProps = {
  Speaker: {
    basePath: '/speakers',
    clientBasePath: '/speakers',
    mapItems: (dataSource: DataSourceProps) => {
      return (
        dataSource.speakers?.map(({ id, slug, name, preview_text, image }) => ({
          id,
          slug,
          name: name || '',
          description: preview_text || '',
          image: image || '',
          progressText: '0%',
        })) || []
      );
    },
  },
  Course: {
    basePath: '/learning/courses',
    clientBasePath: '/learning/courses',
    mapItems: (dataSource: DataSourceProps) => {
      return (
        dataSource.courses?.map(
          ({
            id,
            slug,
            title,
            abstract,
            icon,
            course_complete_percentage,
            products,
            restrict_preview_access,
            restrict_enrollment_access_passes,
            restrict_access,
          }) => {
            return {
              id,
              slug,
              name: title || '',
              description: abstract || '',
              image: icon || EMPTY_COURSE_URL,
              progressText: `${course_complete_percentage || 0}%`,
              paywall: products && products?.length > 0,
              restrict_preview: restrict_preview_access ?? false,
              restrict_enrollment_access_passes: restrict_enrollment_access_passes,
              restrict_access: !!restrict_access,
            };
          },
        ) || []
      );
    },
  },
  Group: {
    basePath: '/community/groups',
    clientBasePath: '/community/groups',
    mapItems: (dataSource: DataSourceProps) => {
      return (
        dataSource.communityGroups?.map(({ id, slug, title, preview_text, list_image }) => ({
          id,
          slug,
          name: title || '',
          description: preview_text || '',
          image: list_image || EMPTY_GROUP_URL,
        })) || []
      );
    },
  },
  Session: {
    basePath: '/sessions',
    clientBasePath: '/sessions',
    mapItems: (dataSource: DataSourceProps) => {
      return (
        dataSource.sessions?.map(({ id, slug, title, preview_text, icon }) => ({
          id,
          slug,
          name: title || '',
          description: preview_text || '',
          image: icon || '',
          progressText: '0%',
        })) || []
      );
    },
  },
  Library: {
    basePath: '/library',
    clientBasePath: '/library',
    mapItems: (dataSource: DataSourceProps) => {
      return (
        dataSource.library?.map(({ id, slug, title }) => ({
          id,
          slug,
          name: title || '',
          description: '',
          image: '',
          progressText: '0%',
        })) || []
      );
    },
  },
};

export const setDefaultFilters = (dataModel: string, filter: any) => {
  if (dataModel === 'Course') {
    setDefaultCourseFilters(filter);
  }
  if (dataModel === 'Group') {
    setDefaultGroupFilters(filter);
  }
};

export interface ContentGridProps {
  siteId: string;
  settings: any;
}

const ContentGrid: React.FC<ContentGridProps> = ({ siteId, settings }) => {
  const { isClient, user: userData, site: siteData } = useSettings();
  const [selectedTabId, setSelectedTabId] = useState<string | undefined>();
  const [search, setSearch] = useState('');
  const [tempFilter, setTempFilter] = useState<any>({});
  const [courseFilter, setCourseFilter] = useState<any>({});
  const debouncedSearch = useDebounce(search, 500);
  const currentTab = useMemo(() => {
    if (!selectedTabId) return settings?.tabs?.[0];
    return settings.tabs.find((tab: TabItemObject) => tab.id === selectedTabId);
  }, [settings, selectedTabId]);
  const [sortTemporary, setSortTemporary] = useState<string>('start_date');
  const [sortTemporaryOrder, setSortTemporaryOrder] = useState<string>('-date_created');
  const [courseSort, setCourseSort] = useState<SortOption>(
    currentTab?.preSortName
      ? SortOptions[interpolateSortOption(currentTab)]
      : SortOptions[sortTemporary],
  );

  const dataModel = settings?.dataModel || 'Group';
  const navigate = useNavigate();
  // Needed for being able to remove instructor and tag filter chips that appear below
  // the search bar when the user is filtering and the filter modal closes
  const [instructorsRemoved, setInstructorsRemoved] = useState<string[]>([]);
  const [tagsRemoved, setTagsRemoved] = useState({});

  // Need client name for restrict preview modal
  const { data: platformData, isLoading: platformLoading } = useGetPlatform(
    siteData?.platform_id || '',
  );
  // TODO this is erroring for no reason - will fix later
  // const { data: clientData, isLoading: clientLoading } = useGetClient(
  //   platformData?.client_id || '',
  // );
  // const clientName = clientData?.name || 'your company';
  const clientName = 'your company';

  const [filter, filterStr] = useMemo(() => {
    try {
      const genericPreFilter = handleContentPreFilter(currentTab, userData);
      let filter = genericPreFilter.filter;
      const filterStr = genericPreFilter.filterStr;

      if (dataModel === 'Group') {
        filter = handleGroupFilter(currentTab, filter, userData);
      }
      if (dataModel === 'Course') {
        // If the filter_and_or param is set, we need to replace the USER_ID's
        if (currentTab?.filter_and_or && userData?.id) {
          const serializedFilterAndOr = JSON.stringify(currentTab.filter_and_or);
          const replacedUserIds = serializedFilterAndOr.replace(/{{USER_ID}}/g, userData.id);
          currentTab.filter_and_or = JSON.parse(replacedUserIds);
        }
      }

      // TODO Handle sorting contents other than Course
      if (currentTab?.order) {
        // Set the CourseSort component
        setCourseSort(SortOptions[interpolateSortOption(currentTab)]);
      }
      // Make sure we don't allow unreleased or already ended content to show up
      setDefaultFilters(dataModel, filter);
      return [filter, filterStr];
    } catch (e) {
      console.debug(e);
      return [{}, currentTab?.filter || '{}'];
    }
  }, [currentTab, userData, courseSort, currentTab?.filter, currentTab?.preSortName]);

  // Register Temporary Sorting
  useEffect(() => {
    if (sortTemporary) {
      setCourseSort(SortOptions[interpolateSortOption(currentTab)]);
      const sortObject = sortOptionToObjectField(currentTab?.temporarySort || '');
      setSortTemporaryOrder(sortObject ? SortOptions[sortObject]?.order : '-date_created');
    }
  }, [sortTemporary]);

  const { speakers, speakersLoading } = useSpeakersHook(
    siteId,
    { ...currentTab, ...{ order: sortTemporaryOrder } },
    dataModel,
    filter,
    filterStr,
    debouncedSearch,
  );

  const { courses, coursesLoading } = useCoursesHook(
    siteId,
    { ...currentTab, ...{ order: sortTemporaryOrder } },
    dataModel,
    filter,
    filterStr,
    debouncedSearch,
    sortTemporaryOrder,
    courseSort,
    courseFilter,
  );

  const { groupsLoading, communityGroups } = useGroupsHook(
    siteId,
    { ...currentTab, ...{ order: sortTemporaryOrder || currentTab?.order || '-date_created' } },
    dataModel,
    filter,
    filterStr,
    debouncedSearch,
  );

  // SESSION QUERIES
  const { data: sessions, isLoading: sessionsLoading } = useGetAllSessions(
    siteId,
    {
      order: sortTemporaryOrder || currentTab?.order,
      filter: {
        title__icontains: debouncedSearch,
        subbreakout: false,
        active: true,
      },
    },
    { query: { enabled: dataModel === 'Session' && !filterStr.includes('{{USER_ID}}') } },
  );

  const { data: library, isLoading: libraryLoading } = useGetPages(
    siteId,
    {
      order: sortTemporaryOrder || currentTab?.order,
      filter: {
        type: 'library',
        title__icontains: debouncedSearch,
      },
    },
    { query: { enabled: dataModel === 'Library' } },
  );

  const isLoading =
    speakersLoading ||
    coursesLoading ||
    groupsLoading ||
    // siteLoading ||
    platformLoading ||
    // TODO see message above about useGetClient
    // clientLoading ||
    // userLoading ||
    libraryLoading ||
    sessionsLoading;

  const items =
    MODEL_MAP[dataModel]?.mapItems({ speakers, courses, communityGroups, sessions, library }) || [];
  const basePath = isClient
    ? MODEL_MAP[dataModel]?.clientBasePath || ''
    : MODEL_MAP[dataModel]?.basePath || '';

  const handleSelect = (item: PreviewItemProps) => {
    if (isClient) {
      navigate(`/${siteData?.slug}${basePath}/${item.slug}`);
    } else if (settings?.onSelect) {
      settings.onSelect(item);
    } else {
      navigate(`${basePath}/${item.slug}`);
    }
  };

  const handleTabSelected = (tabId: string) => {
    setSelectedTabId(tabId);
  };

  const filterComponent =
    dataModel === 'Course' ? (
      <CourseFilter
        site={siteData}
        onChange={setTempFilter}
        values={tempFilter}
        settings={settings}
        preFilterOptions={currentTab}
      />
    ) : null;

  const sortComponent =
    dataModel === 'Course' ? (
      <CourseSort
        settings={settings}
        values={courseSort?.value}
        currentTab={currentTab}
        setSortTemporary={setSortTemporary}
      />
    ) : null;

  // Give definition to the CourseFilterContext items, so we can manipulate them within JunoGrid
  const platformId = platformData?.id || '';
  const handleDateUpdate = (dateVal: Dayjs | null, key: string) => {
    if (!dateVal) {
      setTempFilter((current: any) => {
        const { [key]: _, ...rest } = current;
        return rest;
      });
      return;
    }
    const dateObj = dayjs(dateVal);
    if (!dateObj.isValid()) return;
    setTempFilter((current: any) => {
      return { ...current, [key]: dateObj.format() };
    });
  };
  const handleStringListUpdate = (vals: string[], key: string) => {
    setTempFilter((current: any) => {
      const { [key]: _, ...rest } = current;
      if (!vals?.length) return rest;
      return { ...rest, [key]: vals };
    });
  };
  const handleTagListUpdate = (vals: string[], key: string) => {
    if (!vals?.length) {
      setTempFilter((current: any) => {
        const { [key]: _, ...rest } = current;
        return rest;
      });
      return;
    }
    setTempFilter((current: any) => {
      return { ...current, [key]: vals };
    });
  };

  return (
    <CourseFilterContext.Provider
      value={{
        tempFilter,
        handleDateUpdate,
        handleStringListUpdate,
        handleTagListUpdate,
        siteId,
        platformId,
        instructorsRemoved,
        setInstructorsRemoved,
        tagsRemoved,
        setTagsRemoved,
      }}
    >
      <Box sx={{ display: 'none' }}>
        <CourseFilter
          site={siteData}
          onChange={setTempFilter}
          values={tempFilter}
          settings={settings}
        />
      </Box>
      <Container fullWidth={settings?.fullWidth || false}>
        <DefaultSearchSortFilter
          showButton={false}
          buttonDisabled={false}
          buttonText={''}
          onClickButton={() => {}}
          sort={sortTemporaryOrder || currentTab?.order || '-date_created'}
          setSort={(value: string) => {
            setSortTemporaryOrder(value);
          }}
          setSearch={setSearch}
          showSort={settings.showSortSelect || false}
          showFilter={false} // TODO
          sortOptions={[
            {
              label: 'Newest to Oldest',
              value: '-date_created',
            },
            {
              label: 'Oldest to Newest',
              value: 'date_created',
            },
            {
              label: 'Title - A to Z',
              value: 'title',
            },
            {
              label: 'Title - Z to A',
              value: '-title',
            },
          ]}
        />
        <JunoGrid
          settings={settings}
          clientName={clientName}
          items={items || []}
          tabs={settings?.tabs || []}
          selectedTabId={selectedTabId || settings?.tabs?.[0]?.id || ''}
          setSelectedTabSortTemporaryOrder={setSortTemporaryOrder}
          onTabSelected={handleTabSelected}
          isLoading={isLoading}
          onSelect={handleSelect}
          onFilter={() => setCourseFilter(tempFilter)}
          sortComponent={sortComponent}
          filterComponent={filterComponent}
        />
      </Container>
    </CourseFilterContext.Provider>
  );
};

export default ContentGrid;
