import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDebounce } from 'usehooks-ts';
import { useGetAccessPasses } from '@juno/client-api';
import { AccessPass, Site as SiteModel } from '@juno/client-api/model';
import { JunoSpin } from '@juno/ui';
import AccessPassListItem from './AccessPassListItem';

interface AccessPassListProps {
  site: SiteModel;
}

const NoItemsFound = styled(Box)(() => ({
  textAlign: 'center',
  userSelect: 'none',
}));

const AccessPassList: React.FC<AccessPassListProps> = ({ site }) => {
  const { id: siteId } = site;
  const [orderedItems, setOrderedItems] = useState<AccessPass[] | undefined>([]);
  const [search, setSearch] = useState<string>('');
  const { data: accessPasses, isLoading } = useGetAccessPasses(siteId);
  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    const ordered = accessPasses?.sort((a, b) => a.name.localeCompare(b.name));
    setOrderedItems(ordered);
  }, [accessPasses]);

  if (isLoading) return <JunoSpin />;

  return (
    <Box>
      <TextField
        fullWidth
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        size='small'
        placeholder='Search'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Grid container spacing={2} mt={2}>
        {orderedItems
          ?.filter((item) => item.name.toLowerCase().includes(debouncedSearch.toLowerCase()))
          .map((item) => (
            <Grid item xs={12} md={4} lg={3}>
              <AccessPassListItem key={item.id} accessPass={item} />
            </Grid>
          ))}
      </Grid>
      {(!orderedItems || orderedItems?.length === 0) && (
        <NoItemsFound sx={{ mt: 3 }}>
          <Typography variant='h6'>No items found</Typography>
        </NoItemsFound>
      )}
    </Box>
  );
};

export default AccessPassList;
