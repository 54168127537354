import React, { useEffect, useMemo, useState } from 'react';
import {
  AutorenewOutlined as AutorenewOutlinedIcon,
  DoneOutline as DoneOutlineIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getGetAccessPassesQueryKey,
  getGetFeatureConfigsPublicQueryKey,
  getGetFeatureConfigsQueryKey,
  useCreateAccessPass,
  useCreateFeatureConfig,
  useGetAccessPasses,
  useUpdateSiteFeatureConfig,
} from '@juno/client-api';
import { FeatureConfigTypeEnum, Site } from '@juno/client-api/model';
import { Container, DefaultSearchSortFilter, DialogAriaWrapper } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import AccessPassItem from './AccessPassItem';
import PricingList from './PricingList';

const CONFIG_TYPE = FeatureConfigTypeEnum.ecommerce;

const GroupsAdmin: React.FC = () => {
  const { id } = useParams();
  const { site, configs } = useSettings();
  const [search, setSearch] = useState('');
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [isCredsDialogOpen, setIsCredsDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [stripeKeysValid, setStripeKeysValid] = useState(false);
  const [hasValidatedStripe, setHasValidatedStripe] = useState(false);
  const [priceLevelsDialogOpen, setPriceLevelsDialogOpen] = useState(false);
  const [newPassName, setNewPassName] = useState('');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const refetchPages = () => {
    const queryKey = getGetFeatureConfigsQueryKey(site?.id || '');
    const publicQueryKey = getGetFeatureConfigsPublicQueryKey(site?.id || '');
    queryClient.invalidateQueries(queryKey);
    queryClient.invalidateQueries(publicQueryKey);
  };
  const { UPDATE, CREATE } = MutationAction;
  const createConfig = useCreateFeatureConfig(onMutation(CREATE, 'FeatureConfig', refetchPages));
  const updateConfig = useUpdateSiteFeatureConfig(
    onMutation(UPDATE, 'FeatureConfig', refetchPages),
  );
  const createPass = useCreateAccessPass(onMutation(CREATE, 'AccessPass', () => {}));

  const { data: accessPasses, isFetching: isLoading } = useGetAccessPasses(site?.id || '', {
    filter: {
      name__icontains: search,
    },
  });

  const [configStripe, configPubKey, configSecretKey, stripeWebhookId, stripeWebhookSecret] =
    useMemo(() => {
      const stripeConfig = configs?.find((config) => config.type === CONFIG_TYPE);
      const stripePubKey = stripeConfig?.config?.publishableKey || '';
      const stripeSecretKey = stripeConfig?.config?.secretKey || '';
      const stripeWebhookId = stripeConfig?.config?.webhookId || '';
      const stripeWebhookSecret = stripeConfig?.config?.webhookSecret || '';

      // TODO:
      // https://stripe.com/docs/keys#obtain-api-keys:~:text=and%20publishable%20keys-,Copied,-!
      // https://stripe.com/docs/keys#safe-keys:~:text=your%20keys%20safe-,Copied,-!
      // We need to remove the secret key from our config api call.
      //throw "We can't have stripeSecretKey on the client."

      return [
        stripeConfig,
        stripePubKey as string,
        stripeSecretKey as string,
        stripeWebhookId as string,
        stripeWebhookSecret as string,
      ];
    }, [configs]);

  async function handleStripeConnect() {
    setIsCredsDialogOpen(true);
  }

  const validateStripeKeys = async () => {
    setIsSaving(true);
    const isValid = await isStripeKeysValid(configPubKey, configSecretKey);
    setStripeKeysValid(isValid);
    setHasValidatedStripe(true);
    setIsSaving(false);
  };

  const isStripeKeysValid = async (publishableKey: string, secretKey: string) => {
    let stripe;
    try {
      stripe = await loadStripe(publishableKey);
      if (!stripe) return false;
    } catch (error) {
      return false;
    }
    const { token } = await stripe.createToken('pii', { personal_id_number: '000000000' });
    if (!token) return false;
    return true;
  };

  const handleCredsDialogSave = async (
    publishableKey: string,
    secretKey: string,
    webhookId: string,
    webhookSecret: string,
    validate = true,
  ) => {
    setIsSaving(true);
    if (validate) {
      const isValidKeys = await isStripeKeysValid(publishableKey, secretKey);
      if (!isValidKeys) {
        setIsSaving(false);
        alert('Failed to connect, check your API keys and please try again');
        return;
      }
    }

    const payload = {
      id: '',
      type: CONFIG_TYPE,
      config: { publishableKey, secretKey, webhookId, webhookSecret },
    };
    if (!configStripe) {
      await createConfig.mutateAsync({ siteId: site?.id || '', data: payload });
    } else {
      payload.id = configStripe.id;
      await updateConfig.mutateAsync({
        siteId: site?.id || '',
        configType: payload.type,
        data: payload,
      });
    }
    setIsSaving(false);
    setIsCredsDialogOpen(false);
  };

  const handleCredsDialogCancel = () => {
    setIsCredsDialogOpen(false);
  };

  useEffect(() => {
    configs && validateStripeKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs]);

  const cancelCreate = () => {
    setCreateDialogOpen(false);
    setNewPassName('');
  };

  const createNewPass = async () => {
    if (!newPassName) return;
    setIsCreating(true);
    try {
      await createPass.mutateAsync({ siteId: site?.id || '', data: { name: newPassName, id: '' } });
      await queryClient.invalidateQueries(getGetAccessPassesQueryKey(site?.id || ''));
      setIsCreating(false);
      setCreateDialogOpen(false);
      setNewPassName('');
    } catch (e) {
      console.error(e);
      setIsCreating(false);
    }
  };

  return (
    <Container>
      {id && <AccessPassItem stripeKeysValid={stripeKeysValid} />}
      {!id && (
        <>
          <Grid container spacing={3}>
            <Grid item md={6}>
              <Card sx={{ p: 2, mb: 4 }}>
                <Typography variant='h5'>Stripe Integration</Typography>
                <Divider sx={{ mt: 2, mb: 4 }} />

                <Typography variant='body2' sx={{ mb: 5, mt: 1, maxWidth: 900 }}>
                  Connect your Stripe account here. This is necessary to create and attach Products
                  to Access Passes. You will find your API keys in your Stripe dashboard.
                </Typography>
                {stripeKeysValid && (
                  <LoadingButton
                    sx={{
                      textTransform: 'none',
                    }}
                    loading={isLoading || isSaving}
                    onClick={handleStripeConnect}
                    startIcon={<DoneOutlineIcon />}
                    variant='contained'
                    color='success'
                  >
                    Connected to Stripe
                  </LoadingButton>
                )}
                {!stripeKeysValid && (
                  <LoadingButton
                    sx={{
                      textTransform: 'none',
                    }}
                    loading={isLoading || isSaving}
                    onClick={handleStripeConnect}
                    color='error'
                    startIcon={<AutorenewOutlinedIcon sx={{ transform: 'rotate(45deg)' }} />}
                    variant='contained'
                  >
                    Connect to Stripe
                  </LoadingButton>
                )}
              </Card>
            </Grid>
            <Grid item md={6}>
              <Card sx={{ p: 2, mb: 4 }}>
                <Typography variant='h5'>Price Levels</Typography>
                <Divider sx={{ mt: 2, mb: 4 }} />
                <Typography variant='body2' sx={{ mb: 5, mt: 1, maxWidth: 900 }}>
                  Pricing levels allow you to give different users different prices for the same
                  product. Configure your pricing levels here and then assign them on Products.
                </Typography>
                <Button
                  variant='contained'
                  onClick={() => setPriceLevelsDialogOpen(true)}
                  disabled={!stripeKeysValid}
                >
                  Manage Price Levels
                </Button>
              </Card>
            </Grid>
          </Grid>
          <Card sx={{ p: 2, mb: 4 }}>
            <Typography variant='h5'>Access Passes</Typography>
            <Divider sx={{ mt: 2, mb: 4 }} />
            <Typography variant='subtitle2' sx={{ mb: 5, mt: 1, maxWidth: 900 }}>
              Access passes restrict access to content around the site. They can either be granted
              by admins, or a product can be attached to them allowing users to purchase the access
              pass.
            </Typography>
            <DefaultSearchSortFilter
              buttonDisabled={false}
              buttonText={'New Access Pass'}
              onClickButton={() => setCreateDialogOpen(true)}
              setSearch={(value: string) => setSearch(value)}
              showFilter={false}
              showSort={false}
            />
            <Stack gap={0.25} mt={3}>
              {accessPasses?.map((ap, i) => (
                <Box
                  onClick={() => navigate(`/${site?.slug || ''}/admin/access/${ap.id}`)}
                  sx={{
                    cursor: 'pointer',
                    background: i % 2 !== 0 ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
                    p: 1,
                    borderRadius: 2,
                    '&:hover': {
                      background: 'rgba(0, 0, 0, 0.08)',
                    },
                  }}
                >
                  {ap.name}
                </Box>
              ))}
            </Stack>
          </Card>
          <CredsDialog
            pubKey={configPubKey}
            secretKey={configSecretKey}
            open={isCredsDialogOpen}
            loading={isSaving}
            onCancel={handleCredsDialogCancel}
            onSave={handleCredsDialogSave}
            webhookId={stripeWebhookId}
            webhookSecret={stripeWebhookSecret}
          />
          <DialogAriaWrapper
            id='create_access_pass'
            open={createDialogOpen}
            onClose={() => setCreateDialogOpen(false)}
            fullWidth
          >
            <DialogTitle>
              <Typography variant='subtitle1'>Create an Access Pass</Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                sx={{ mt: 3, minWidth: '50%' }}
                value={newPassName}
                onChange={(e) => setNewPassName(e.target.value)}
                size='small'
                disabled={isCreating}
                label={'Access Pass Name'}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => cancelCreate()}>Cancel</Button>
              <LoadingButton
                onClick={() => {
                  createNewPass();
                }}
                variant='contained'
                loading={isCreating}
                disabled={!newPassName}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </DialogAriaWrapper>
          <DialogAriaWrapper
            id='create_access_pass'
            open={priceLevelsDialogOpen}
            onClose={() => setPriceLevelsDialogOpen(false)}
            fullWidth
          >
            <DialogTitle>
              <Typography variant='subtitle1'>Manage Price Levels</Typography>
            </DialogTitle>
            <DialogContent>
              <PricingList stripeKeysValid={stripeKeysValid} site={site || ({} as Site)} />
            </DialogContent>
          </DialogAriaWrapper>
        </>
      )}
    </Container>
  );
};

export default GroupsAdmin;

interface CredsDialogProps {
  pubKey: string;
  secretKey: string;
  webhookId?: string;
  webhookSecret?: string;
  open: boolean;
  loading: boolean;
  onCancel: () => void;
  onSave: (
    pubKey: string,
    secretKey: string,
    webhookId: string,
    webhookSecret: string,
    validate?: boolean,
  ) => void;
}

const CredsDialog = (props: CredsDialogProps) => {
  const { open, loading, onCancel, onSave, pubKey, secretKey, webhookId, webhookSecret } = props;
  const [currentPubKey, setCurrentPubKey] = useState('');
  const [currentSecretKey, setCurrentSecretKey] = useState('');

  useEffect(() => {
    if (!open) return;
    setCurrentPubKey(pubKey);
    setCurrentSecretKey(secretKey);
  }, [pubKey, secretKey, open]);

  const handleDisconnect = () => {
    onSave('', '', '', '', false);
  };

  return (
    <DialogAriaWrapper open={open} onClose={onCancel} fullWidth id='connect_stripe_dialog'>
      <DialogTitle>
        <Typography variant='subtitle1'>Connect to Stripe</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Publishable Key'
              value={currentPubKey}
              onChange={(e) => setCurrentPubKey(e.target.value)}
              variant='outlined'
              sx={{ pr: 2 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label='Secret Key'
              type='password'
              value={currentSecretKey}
              onChange={(e) => setCurrentSecretKey(e.target.value)}
              variant='outlined'
              inputProps={{ autoComplete: 'new-password' }}
              sx={{ pr: 2 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Box>
          {!!pubKey && !!secretKey && <Button onClick={handleDisconnect}>Disconnect</Button>}
        </Box>
        <Box>
          <Button onClick={onCancel}>Cancel</Button>
          <LoadingButton
            onClick={() =>
              onSave(currentPubKey, currentSecretKey, webhookId || '', webhookSecret || '')
            }
            autoFocus
            loading={loading}
            disabled={!currentPubKey || !currentSecretKey}
          >
            Connect
          </LoadingButton>
        </Box>
      </DialogActions>
    </DialogAriaWrapper>
  );
};
