import React, { useEffect, useMemo, useState } from 'react';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteContentKey,
  getGetAccessPassQueryKey,
  getGetProductsQueryKey,
  updateAccessPass,
  useCreateProduct,
  useGetAccessPass,
  useGetAccessPasses,
  useGetProducts,
} from '@juno/client-api';
import { AccessPass, JunoUser, Product, Site } from '@juno/client-api/model';
import {
  AutoCompleteUsers,
  ConfirmDeleteDialog,
  Container,
  DefaultCircularProgress,
  GenericFormikInput,
  JunoSpin,
  SearchableUserList,
} from '@juno/ui';
import {
  MutationAction,
  UserListActionEnum,
  onMutation,
  useDynamicUserList,
  useSettings,
} from '@juno/utils';
import { accessPassValidationSchema } from './AccessPassValidationSchema';
import FormDialog from './FormDialog';
import ProductPanel from './ProductPanel';

interface AccessPassWithProduct extends AccessPass {
  products: Product[];
}

const PAGE_SIZE = 50;
interface AccessPassItemProps {
  stripeKeysValid: boolean;
}

const AccessPassItem: React.FC<AccessPassItemProps> = ({ stripeKeysValid }) => {
  const { site } = useSettings();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isFocusedUrlInput, setHelperText] = useState(false);
  const [userSearchValue, setUserSearchValue] = useState<string>('');
  const [userSearchValue2, setUserSearchValue2] = useState<string>('');
  const [userLoading, setUserLoading] = useState<string | undefined>(undefined);
  const [listUserLoading, setListUserLoading] = useState<string | undefined>(undefined);
  const [openDelete, setOpenDelete] = useState(false);
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const [createProductDialogOpen, setCreateProductDialogOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<string>('');
  const [upgradeType, setUpgradeType] = useState<'upgrade_url' | 'product' | 'none'>('none');
  const [initialProduct, setInitialProduct] = useState<null | Product>(null);

  const createProduct = useCreateProduct(
    onMutation(MutationAction.CREATE, 'Product', () =>
      queryClient.invalidateQueries(getGetProductsQueryKey(site?.id || '')),
    ),
  );

  const handleAddUser = async (user: JunoUser) => {
    setUserLoading(user.id);
    const newUser = {
      ...user,
      access_passes: [...user.access_passes, accessPass || ({} as AccessPass)],
    };
    setTimeout(() => {
      handleUpdateUser(newUser, UserListActionEnum.ADD);
      setUserLoading(undefined);
    }, 500);
  };

  const handleDeleteUser = async (user: JunoUser) => {
    setListUserLoading(user.id);
    const newUser = {
      ...user,
      access_passes: user.access_passes.filter((pass) => pass.id !== accessPass?.id),
    };
    setTimeout(() => {
      handleUpdateUser(newUser, UserListActionEnum.REMOVE);
      setListUserLoading(undefined);
    }, 500);
  };

  const { data: ap, isLoading } = useGetAccessPass(site?.id || '', id || '', {
    include: 'products',
  });
  const accessPass = useMemo(() => {
    return ap as AccessPassWithProduct;
  }, [ap]);
  const { data: accessPasses } = useGetAccessPasses(site?.id || '');
  const { data: products, isLoading: productsLoading } = useGetProducts(site?.id || '');
  const {
    list: { list: optimisticValue, isLoadingPage, isInitialLoad, ref },
    secondaryList: {
      list: allUsersData,
      isLoadingPage: isLoadingPageSecondary,
      isInitialLoad: isInitialLoadSecondary,
      ref: secondaryRef,
    },
    handleUpdateUser,
  } = useDynamicUserList(
    {
      filter: {
        access_passes: accessPass?.id || '',
      },
      order: 'last_name',
    },
    PAGE_SIZE,
    userSearchValue,
    userSearchValue2,
    site?.platform_id || '',
    {
      exclude: {
        access_passes: accessPass?.id || '',
      },
      order: 'last_name',
    },
    (a, b) => a.last_name.localeCompare(b.last_name),
    true,
    true,
  );

  const handleDelete = (passId: string): void => {
    deleteContentKey(site?.id || '', passId)
      .then((resp) => console.log(resp))
      .catch((err) => console.error(err));
    queryClient.invalidateQueries(getGetAccessPassQueryKey(site?.id || '', passId));
    navigate(`/${site?.slug}/admin/access`);
  };

  useEffect(() => {
    if (accessPass) {
      const products = accessPass?.products && accessPass?.products?.length > 0;
      const upgUrl = !!accessPass?.upgrade_enrollment_url;
      setUpgradeType(products ? 'product' : upgUrl ? 'upgrade_url' : 'none');
      setSelectedProductId(accessPass.products?.[0]?.id || '');
    }
  }, [accessPass]);

  const handleDialogSave = (data: Product) => {
    setCreateProductDialogOpen(false);
    createProduct.mutate({ siteId: site?.id || '', data });
  };

  return (
    <Formik
      key={'access-pass-form'}
      enableReinitialize={true}
      initialValues={accessPass}
      validationSchema={accessPassValidationSchema(
        accessPasses?.filter((ap) => ap.id !== accessPass?.id) || [],
      )}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const resp = await updateAccessPass(
            site?.id || '',
            accessPass?.id || '',
            values as AccessPass,
          );
          await queryClient.invalidateQueries(
            getGetAccessPassQueryKey(site?.id || '', accessPass?.id || ''),
          );
          await queryClient.invalidateQueries(getGetProductsQueryKey(site?.id || ''));
          setSubmitting(false);
        } catch (e) {
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleReset,
        setFieldValue,
        handleChange,
        setFieldTouched,
        handleSubmit,
        handleBlur,
        isSubmitting,
        dirty,
        isValid,
      }) => {
        return (
          <Container pb={4}>
            <Card sx={{ boxShadow: 1, p: 3 }}>
              <Stack direction='row' alignItems='center' mb={3}>
                <IconButton
                  color='primary'
                  onClick={() => navigate(`/${site?.slug || ''}/admin/access`)}
                  sx={{ mr: 1 }}
                >
                  <ArrowCircleLeftOutlinedIcon />
                </IconButton>
                <Typography variant='subtitle1'>Edit Access Pass</Typography>
              </Stack>

              {!isLoading && (
                <Form>
                  <Box>
                    <FormControl fullWidth>
                      <Box>
                        <Typography mb={2} mt={2} fontWeight='bold'>
                          Information
                        </Typography>
                        <GenericFormikInput
                          label='Access Pass Title'
                          name='name'
                          type='text'
                          placeholder='Access Pass Title'
                          value={values?.name}
                          size='small'
                          sx={{ maxWidth: 500 }}
                        />
                      </Box>
                      <Box mt={5}>
                        <Typography mb={2} fontWeight='bold'>
                          Upgrade Path
                        </Typography>
                        <Typography mb={2} variant='subtitle2'>
                          There are two ways for you to let users gain this access pass. <br />
                          <br />
                          <b>Upgrade URL</b>: you are giving an external link for them to follow to
                          purchase or sign up for this access pass. As an admin you then will come
                          in here and manually assign the pass to users who have completed
                          registration at your external destination.
                          <br />
                          <br />
                          <b>Product</b>: If you connect to your stripe account on the previous
                          page, you can create a product to attach to this access pass. Users who
                          land on content restricted by this access pass will be prompted to
                          purchase access through a secure Stripe checkout and are automatically
                          granted the access pass upon completion of the purchase. Select a product
                          to edit it. If you create a new product, it will be added to the list for
                          you to select and edit.
                        </Typography>
                        <FormControl sx={{ mb: 2 }}>
                          <RadioGroup
                            aria-labelledby='demo-controlled-radio-buttons-group'
                            name='controlled-radio-buttons-group'
                            value={upgradeType}
                            onChange={(e) => {
                              setUpgradeType(e.target.value as 'upgrade_url' | 'product' | 'none');
                              if (e.target.value === 'none') {
                                setFieldValue('upgrade_enrollment_url', '');
                                setFieldValue('products', []);
                                setSelectedProductId('');
                              }
                              if (e.target.value === 'upgrade_url') {
                                setFieldValue('products', []);
                                setSelectedProductId('');
                              }
                              if (e.target.value === 'product') {
                                setFieldValue('upgrade_enrollment_url', '');
                              }
                            }}
                            sx={{ flexDirection: 'row', mb: 1 }}
                          >
                            <FormControlLabel
                              value={'none'}
                              control={<Radio />}
                              label='None'
                              sx={{ mr: 2 }}
                            />
                            <FormControlLabel
                              value={'upgrade_url'}
                              control={<Radio />}
                              label='Upgrade URL'
                              sx={{ mr: 2 }}
                            />
                            {stripeKeysValid && (
                              <FormControlLabel
                                value={'product'}
                                control={<Radio />}
                                label='Product'
                              />
                            )}
                            {!stripeKeysValid && (
                              <Tooltip title='Configure Stripe Account to Enable'>
                                <FormControlLabel
                                  value={'product'}
                                  control={<Radio />}
                                  label='Product'
                                  disabled={true}
                                />
                              </Tooltip>
                            )}
                          </RadioGroup>
                        </FormControl>
                        {upgradeType === 'upgrade_url' && (
                          <GenericFormikInput
                            label='Upgrade URL'
                            name='upgrade_enrollment_url'
                            type='text'
                            placeholder='Upgrade URL'
                            value={values.upgrade_enrollment_url}
                            onFocus={() => setHelperText(true)}
                            onBlur={() => setHelperText(false)}
                            helperText={isFocusedUrlInput && ' https:// ...'}
                            sx={{ maxWidth: 500, display: 'flex' }}
                            size='small'
                          />
                        )}
                        {upgradeType === 'product' && products && (
                          <>
                            {!selectedProductId && !productsLoading && (
                              <Stack direction='row' alignItems={'center'} gap={3}>
                                <Autocomplete
                                  options={
                                    products.filter(
                                      (p) =>
                                        !p.access_pass || p.id === accessPass?.products?.[0]?.id,
                                    ) || []
                                  }
                                  value={
                                    selectedProductId
                                      ? products.find((p) => p.id === selectedProductId)
                                      : null
                                  }
                                  onChange={(e, value) => {
                                    setSelectedProductId(value?.id || '');
                                    setFieldValue('products', value ? [value] : []);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label='Choose an Existing Product'
                                      size='small'
                                    />
                                  )}
                                  sx={{ maxWidth: 400, minWidth: 300 }}
                                  getOptionLabel={(option) => `${option.name}`}
                                />
                                <Box>-OR-</Box>
                                <Button
                                  variant='contained'
                                  onClick={() => setCreateProductDialogOpen(true)}
                                >
                                  Create New Product
                                </Button>
                              </Stack>
                            )}
                            {selectedProductId && (
                              <Card
                                sx={{
                                  width: 400,
                                  mt: 0,
                                  p: 2,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography>
                                  {products.find((p) => p.id === selectedProductId)?.name}
                                </Typography>
                                <Box>
                                  <Tooltip title='Edit'>
                                    <IconButton onClick={() => setProductDialogOpen(true)}>
                                      <EditIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title='Remove'>
                                    <IconButton
                                      onClick={() => {
                                        setSelectedProductId('');
                                        setFieldValue('products', []);
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </Card>
                            )}
                          </>
                        )}
                      </Box>
                      <Box sx={{ textAlign: 'right', mt: 2 }}>
                        <LoadingButton
                          variant='contained'
                          loading={isSubmitting}
                          disabled={!dirty || !isValid}
                          onClick={() => handleSubmit()}
                        >
                          Save
                        </LoadingButton>
                      </Box>
                      <Divider sx={{ mt: 4 }} />
                      <Grid container mt={4} spacing={2} style={{ maxWidth: 800 }}>
                        <Grid item xs={12}>
                          <Typography fontWeight='bold'>Users with this Access Pass</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <AutoCompleteUsers
                            options={allUsersData || []}
                            selected={[]}
                            onSubmit={handleAddUser}
                            onInputChange={setUserSearchValue2}
                            isLoading={isInitialLoadSecondary}
                            isLoadingPage={isLoadingPageSecondary}
                            userLoading={userLoading}
                            locked={false}
                            label={'Grant Access Pass to Users'}
                            showClearButton={false}
                            paginateRef={secondaryRef}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <SearchableUserList
                              label={''}
                              users={optimisticValue || []}
                              locked={false}
                              userLoading={listUserLoading}
                              onDelete={handleDeleteUser}
                              searchValue={userSearchValue}
                              setSearchValue={setUserSearchValue}
                              loading={isInitialLoad}
                              showSubheader={false}
                            >
                              <Box
                                style={{
                                  position: 'relative',
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                  justifyContent: 'center',
                                }}
                                mt={1 / 2}
                              >
                                <DefaultCircularProgress
                                  props={{
                                    size: 18,
                                    variant: 'indeterminate',
                                    style: { visibility: isLoadingPage ? 'visible' : 'hidden' },
                                  }}
                                />
                                <Box ref={ref} sx={{ pb: 1 }} />
                              </Box>
                            </SearchableUserList>
                          </Box>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Box>
                  <Box>
                    <Button
                      variant='contained'
                      color='error'
                      sx={{ mt: 3, mb: 3 }}
                      startIcon={<DeleteForeverIcon />}
                      onClick={() => {
                        setOpenDelete((old) => !old);
                      }}
                    >
                      Delete Access Pass
                    </Button>
                  </Box>
                  {openDelete && (
                    <ConfirmDeleteDialog
                      handleClose={() => {
                        setOpenDelete(false);
                      }}
                      handleDelete={() => {
                        setOpenDelete(false);
                        handleDelete(accessPass?.id || '');
                      }}
                      title={'Delete Access Pass'}
                      message={
                        <>
                          <Typography sx={{ mb: 3 }}>
                            Are you sure you want to permanently delete this Access Pass?
                          </Typography>
                          <Typography>
                            All of the information will be lost. This action cannot be undone.
                          </Typography>
                        </>
                      }
                    />
                  )}
                </Form>
              )}
              {isLoading && <JunoSpin />}
            </Card>
            <Dialog open={productDialogOpen} onClose={() => setProductDialogOpen(false)} fullWidth>
              <DialogContent sx={{ minHeight: 300 }}>
                <Box>
                  <ProductPanel site={site || ({} as Site)} productId={selectedProductId} />
                </Box>
              </DialogContent>
            </Dialog>

            <FormDialog
              onSave={handleDialogSave}
              open={createProductDialogOpen}
              onDelete={() => {}}
              onClose={() => setCreateProductDialogOpen(false)}
              isLoading={false}
              item={null}
              isSaving={false}
            />
          </Container>
        );
      }}
    </Formik>
  );
};

export default AccessPassItem;
