import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';

export const FileTypeIcon = ({ fileType }: { fileType: string }) => {
  switch (fileType) {
    // we only care about pdfs, images, and videos, everything else defaults to attachment
    case 'application/pdf':
      return <PictureAsPdfOutlinedIcon sx={{ fontSize: '20px', mr: 1 }} />;
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
      return <PhotoOutlinedIcon sx={{ fontSize: '20px', mr: 1 }} />;
    case 'video/mp4':
    case 'video/quicktime':
      return <SmartDisplayOutlinedIcon sx={{ fontSize: '20px', mr: 1 }} />;
    default:
      return <AttachFileOutlinedIcon sx={{ fontSize: '20px', mr: 1 }} />;
  }
};
