import React from 'react';
import {
  Analytics as AnalyticsIcon,
  AnalyticsOutlined as AnalyticsOutlinedIcon,
} from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import FaceRetouchingNaturalOutlinedIcon from '@mui/icons-material/FaceRetouchingNaturalOutlined';
import FeedbackIcon from '@mui/icons-material/Feedback';
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import MailIcon from '@mui/icons-material/Mail';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PaletteIcon from '@mui/icons-material/Palette';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import SchoolIcon from '@mui/icons-material/School';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ShareIcon from '@mui/icons-material/Share';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import {
  Card,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useFeatureFlag } from 'configcat-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSettings } from '@juno/utils';

interface SideNavProps {
  siteSlug?: string;
}
const SideNav: React.FC<SideNavProps> = ({ siteSlug }) => {
  const { user, enableLearning, enableLibrary } = useSettings();
  const navigate = useNavigate();
  const location = useLocation();
  const { value: moderationValue } = useFeatureFlag('moderation', false);
  const isSelected = (match: string, noMatch?: string) => {
    if (noMatch) {
      return location.pathname.includes(match) && !location.pathname.includes(noMatch);
    }
    return location.pathname.includes(match);
  };

  return (
    <>
      <Card>
        <List>
          <ListItem>
            <Typography variant='subtitle1'>Layout</Typography>
          </ListItem>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin`)}
            selected={isSelected('/admin', '/admin/')}
          >
            <ListItemIcon>
              {isSelected('/admin', '/admin/') ? <PaletteIcon /> : <PaletteOutlinedIcon />}
            </ListItemIcon>
            <ListItemText>General</ListItemText>
          </ListItemButton>

          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/pages`)}
            selected={isSelected('/admin/pages')}
          >
            <ListItemIcon>
              {isSelected('/admin/pages') ? <FileCopyIcon /> : <FileCopyOutlinedIcon />}
            </ListItemIcon>
            <ListItemText>Pages</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/navigation`)}
            selected={isSelected('/admin/navigation')}
            sx={{ mb: 1 }}
          >
            <ListItemIcon>
              {isSelected('/admin/navigation') ? <ShareIcon /> : <ShareOutlinedIcon />}
            </ListItemIcon>
            <ListItemText>Navigation</ListItemText>
          </ListItemButton>
          <Divider />
          <ListItem>
            <Typography variant='subtitle1' sx={{ mt: 1.5 }}>
              Content
            </Typography>
          </ListItem>
          {enableLearning && (
            <ListItemButton
              onClick={() => navigate(`/${siteSlug}/admin/learning`)}
              selected={isSelected('/admin/learning')}
            >
              <ListItemIcon>
                {isSelected('/admin/learning') ? <SchoolIcon /> : <SchoolOutlinedIcon />}
              </ListItemIcon>
              <ListItemText>Learning</ListItemText>
            </ListItemButton>
          )}

          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/community/groups`)}
            selected={isSelected('/admin/community/groups')}
          >
            <ListItemIcon>
              {isSelected('/admin/community/groups') ? <GroupsIcon /> : <GroupsOutlinedIcon />}
            </ListItemIcon>
            <ListItemText>Community</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/sessions`)}
            selected={isSelected('/admin/sessions')}
          >
            <ListItemIcon>
              {isSelected('/admin/sessions') ? (
                <VideoCameraFrontIcon />
              ) : (
                <VideoCameraFrontOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>Sessions</ListItemText>
          </ListItemButton>
          {enableLibrary && (
            <ListItemButton
              onClick={() => navigate(`/${siteSlug}/admin/library`)}
              selected={isSelected('/admin/library')}
            >
              <ListItemIcon>
                {isSelected('/admin/library') ? <LocalLibraryIcon /> : <LocalLibraryOutlinedIcon />}
              </ListItemIcon>
              <ListItemText>Library</ListItemText>
            </ListItemButton>
          )}

          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/speakers`)}
            selected={isSelected('/admin/speakers')}
          >
            <ListItemIcon>
              {isSelected('/admin/library') ? (
                <FaceRetouchingNaturalOutlinedIcon />
              ) : (
                <FaceRetouchingNaturalIcon />
              )}
            </ListItemIcon>
            <ListItemText>Speakers</ListItemText>
          </ListItemButton>
          {moderationValue && (
            <ListItemButton
              onClick={() => navigate(`/${siteSlug}/admin/moderation`)}
              selected={isSelected('/admin/moderation')}
              sx={{ mb: 1 }}
            >
              <ListItemIcon>
                {isSelected('/admin/moderation') ? <FeedbackIcon /> : <FeedbackOutlinedIcon />}
              </ListItemIcon>
              <ListItemText>Moderation</ListItemText>
            </ListItemButton>
          )}
          <Divider />
          <ListItem>
            <Typography variant='subtitle1' sx={{ mt: 1.5 }}>
              Utilities
            </Typography>
          </ListItem>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/users`)}
            selected={isSelected('/admin/users')}
          >
            <ListItemIcon>
              {isSelected('/admin/users') ? (
                <SupervisedUserCircleIcon />
              ) : (
                <SupervisedUserCircleOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>Users</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/tags`)}
            selected={isSelected('/admin/tags')}
          >
            <ListItemIcon>
              {isSelected('/admin/tags') ? <LoyaltyIcon /> : <LoyaltyOutlinedIcon />}
            </ListItemIcon>
            <ListItemText>Tags</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/access`)}
            selected={isSelected('/admin/access')}
          >
            <ListItemIcon>
              {isSelected('/admin/access') ? <LockIcon /> : <LockOutlinedIcon />}
            </ListItemIcon>
            <ListItemText>Access</ListItemText>
          </ListItemButton>
          {/* <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/integrations`)}
            selected={isSelected('/admin/integrations')}
          >
            <ListItemIcon>
              {isSelected('/admin/integrations') ? (
                <WidgetsIcon />
              ) : (
                <WidgetsOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>Integrations</ListItemText>
          </ListItemButton> */}
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/automation_tool`)}
            selected={isSelected('/admin/automation_tool')}
          >
            <ListItemIcon>
              {isSelected('/admin/automation_tool') ? <MailIcon /> : <MailOutlinedIcon />}
            </ListItemIcon>
            <ListItemText>Automation</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/analytics`)}
            selected={isSelected('/admin/analytics')}
          >
            <ListItemIcon>
              {isSelected('/admin/analytics') ? <AnalyticsIcon /> : <AnalyticsOutlinedIcon />}
            </ListItemIcon>
            <ListItemText>Analytics</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/import-export`)}
            selected={isSelected('/admin/import-export')}
          >
            <ListItemIcon>
              {isSelected('/admin/import-export') ? <CachedIcon /> : <CachedOutlinedIcon />}
            </ListItemIcon>
            <ListItemText>Import</ListItemText>
          </ListItemButton>

          {user?.email.includes('junolive.co') && (
            <ListItemButton
              onClick={() => navigate(`/${siteSlug}/admin/super_admin`)}
              selected={isSelected('/admin/super_admin')}
            >
              <ListItemIcon>
                {isSelected('/admin/super_admin') ? <LockIcon /> : <LockOutlinedIcon />}
              </ListItemIcon>
              <ListItemText>Developers</ListItemText>
            </ListItemButton>
          )}
        </List>
      </Card>
    </>
  );
};

export default SideNav;
