import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { SimplePaletteColorOptions } from '@mui/material/styles';
import { useQueryClient } from 'react-query';
import { useDebounce, useEventListener } from 'usehooks-ts';
import {
  getGetFeatureConfigsPublicQueryKey,
  getGetFeatureConfigsQueryKey,
  useCreateFeatureConfig,
  useUpdateSiteFeatureConfig,
} from '@juno/client-api';
import { JunoImage as JunoImageModel } from '@juno/client-api/fakeModel';
import { FeatureConfig as FeatureConfigModel, Site as SiteModel } from '@juno/client-api/model';
import { FeatureConfig } from '@juno/client-api/utils';
import { EmailThemeConfigProps, THEME_FONT_FAMILIES } from '@juno/constants';
import { ASPECT_RATIOS, JunoImageUpload } from '@juno/ui';
import { MutationAction, onMutation, optimizeImage } from '@juno/utils';
import { StyledFormControl, StyledImageInputBox } from '../Header/styles';
import ColorPicker from './ColorPicker';
import { SiteDesignContent, SiteDesignContentPanel } from './styles';

interface SiteEmailDesignPanelProps {
  site: SiteModel;
  configs: FeatureConfigModel[] | undefined;
  setEmailConfig: (emailConfig: EmailThemeConfigProps) => void;
  emailConfig?: EmailThemeConfigProps;
}

const SiteEmailDesignPanel: React.FC<SiteEmailDesignPanelProps> = ({
  setEmailConfig,
  emailConfig,
  configs,
  site,
}) => {
  const queryClient = useQueryClient();
  const [payload, setPayload] = useState<EmailThemeConfigProps | undefined>(emailConfig);
  const [isSaving, setIsSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const debouncedPayload = useDebounce(payload, 300);
  const { CREATE, UPDATE } = MutationAction;
  const refetchConfigs = () => {
    const queryKey = getGetFeatureConfigsQueryKey(site.id);
    const publicQueryKey = getGetFeatureConfigsPublicQueryKey(site.id);
    queryClient.invalidateQueries(queryKey);
    queryClient.invalidateQueries(publicQueryKey);
  };

  const initialLoad = useRef(true);

  const configCreate = useCreateFeatureConfig(onMutation(CREATE, 'FeatureConfig', refetchConfigs));
  const configUpdate = useUpdateSiteFeatureConfig(
    onMutation(UPDATE, 'FeatureConfig', refetchConfigs),
  );

  // We need a default otherwise the theme will be undefined moving forward and new sites are unable to adjust themes.
  // Setting this in one place to avoid conditional logic scattered thru the theme code.
  const defaultTheme: EmailThemeConfigProps = {
    logo: '',
    banner_image: '',
    primary_color: '#000000',
    site_nickname: '',
  };

  useEffect(() => {
    if (!emailConfig) {
      setEmailConfig(defaultTheme);
    }
  }, [emailConfig, setEmailConfig]);

  useEffect(() => {
    if (!debouncedPayload) return;
    setEmailConfig(debouncedPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPayload]);

  useEffect(() => {
    setIsSaving(false);
    setIsDirty(false);
  }, [configs]);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
    } else {
      setIsDirty(true);
    }
  }, [emailConfig]);

  const handleSaveConfig = async () => {
    setIsSaving(true);
    const data = FeatureConfig.buildEmailConfigPayload(emailConfig, configs);
    try {
      if (data.id) {
        await configUpdate.mutateAsync({ siteId: site.id, configType: data.type, data });
      } else {
        await configCreate.mutateAsync({ siteId: site.id, data });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const handleSiteNicknameChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const value = target.value;
    if (!emailConfig) return;
    setPayload({ ...emailConfig, site_nickname: value });
  };

  const handleChangeColor = (color: string, colorName: string) => {
    if (!emailConfig) return;
    setPayload({ ...emailConfig, primary_color: color });
  };

  const updateMedia = (selected: JunoImageModel | null, type: string) => {
    if (!emailConfig) return;
    switch (type) {
      case 'banner_image':
        setPayload({ ...emailConfig, banner_image: selected?.url || '' });
        break;
      case 'logo':
        setPayload({ ...emailConfig, logo: selected?.url || '' });
        break;
      default:
        console.error(`${type} is not a valid media type`);
        break;
    }
  };

  return (
    <SiteDesignContent>
      <SiteDesignContentPanel>
        <Grid container>
          <Grid item xs={6} py={1}>
            <Typography variant='subtitle1'>Site Nickname</Typography>
            <Typography variant='body2'>The name used for branding site email campaigns</Typography>
          </Grid>
          <Grid item xs={6} py={1} justifyContent={'flex-end'} display={'flex'}>
            <TextField
              label='Site Nickname'
              value={payload?.site_nickname || ''}
              onChange={handleSiteNicknameChange}
              size='small'
              disabled={isSaving}
              fullWidth
              sx={{ width: 170 }}
            ></TextField>
          </Grid>
          <Divider />
          <Grid item xs={6} py={1}>
            <Typography variant='subtitle1'>Primary color</Typography>
            <Typography variant='body2'>
              The main color used for branding site email campaigns
            </Typography>
          </Grid>
          <Grid item xs={6} py={1} justifyContent={'flex-end'} display={'flex'}>
            <ColorPicker
              label='Primary'
              color={payload?.primary_color}
              colorName='primary.main'
              handleChange={handleChangeColor}
              disabled={isSaving}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant='subtitle1'>Logo</Typography>
            <Typography variant='body2'>
              Your logo. If it appears too small or large, try cropping the image first.
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign='right'>
            <StyledImageInputBox>
              <StyledFormControl>
                <Box sx={{ width: 140 }}>
                  <JunoImageUpload
                    style={{ aspectRatio: '16/9', height: 70 }}
                    src={optimizeImage(
                      270,
                      emailConfig?.logo ? (emailConfig?.logo as string) : undefined,
                    )}
                    srcUrl={emailConfig?.logo ? (emailConfig?.logo as string) : undefined}
                    onFileUploaded={(selected) => updateMedia(selected, 'logo')}
                    iconInsteadOfText={true}
                    hoverForControls={true}
                  />
                </Box>
              </StyledFormControl>
            </StyledImageInputBox>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='subtitle1'>Banner Image</Typography>
            <Typography variant='body2'>
              The banner image is usually displayed at the top of the header.
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign='right'>
            <StyledImageInputBox>
              <StyledFormControl>
                <Box sx={{ width: 140 }}>
                  <JunoImageUpload
                    style={{ aspectRatio: '25/8', height: 70 }}
                    src={optimizeImage(
                      270,
                      emailConfig?.banner_image ? (emailConfig?.banner_image as string) : undefined,
                    )}
                    srcUrl={
                      emailConfig?.banner_image ? (emailConfig?.banner_image as string) : undefined
                    }
                    onFileUploaded={(selected) => updateMedia(selected, 'banner_image')}
                    iconInsteadOfText={true}
                    hoverForControls={true}
                    aspectRatios={[ASPECT_RATIOS.TWENTY_FIVE_EIGHT]}
                  />
                </Box>
              </StyledFormControl>
            </StyledImageInputBox>
          </Grid>
        </Grid>
        <LoadingButton
          variant='contained'
          onClick={handleSaveConfig}
          loading={isSaving}
          disabled={!isDirty}
          sx={{ marginLeft: 'auto' }}
        >
          Save
        </LoadingButton>
      </SiteDesignContentPanel>
    </SiteDesignContent>
  );
};

export default SiteEmailDesignPanel;
