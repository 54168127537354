import React from 'react';
import { Box, Chip, Divider } from '@mui/material';
import { useGetAllTags } from '@juno/client-api';
import { useSettings } from '@juno/utils';

const chipSx = {
  fontWeight: 'bold',
  textTransform: 'capitalize',
  mr: 1,
  mb: 1,
  background: 'rgba(0,0,0,.06)',
  borderRadius: 2,
  cursor: 'pointer',
  transition: '300ms ease',
  '&:hover': { background: '#dadada' },
};

const TagsBar: React.FC = () => {
  const { site } = useSettings();
  const { data: tags } = useGetAllTags(site?.id || '');
  return (
    <Box p={1.5} pb={0.5} display={'flex'} flexWrap={'wrap'}>
      <Chip label={'Courses'} sx={chipSx} />
      <Chip label={'Groups'} sx={chipSx} />
      <Chip label={'Sessions'} sx={chipSx} />
      <Chip label={'Library'} sx={chipSx} />
      {tags?.map((tag) => (
        <Chip key={tag.id} label={tag.value} sx={chipSx} />
      ))}
    </Box>
  );
};

export default TagsBar;
