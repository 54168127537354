/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormikProps } from 'formik';
import {
  FrequencyEnum,
  TypeBd0Enum as campaignType,
  DayOfWeekEnum as dayOfWeekOptions,
  FrequencyEnum as frequencyOptions,
  MonthEnum as monthOptions,
} from '@juno/client-api/model';
import { JunoBigSwitch } from '@juno/ui';
import TimePick from '../../utils/TimePick';
import { FrequencyMap } from '../../utils/utils';
import {
  CampaignScheduleSectionFormikValues,
  NewCampaignSectionFormikValues,
} from '../../utils/validationSchema';
import ScheduleLogic from '../helpers/ScheduleLogic';

interface CampaignScheduleSectionProps {
  formik: FormikProps<CampaignScheduleSectionFormikValues>;
  updateCampaignScheduleFormikValues: React.Dispatch<
    React.SetStateAction<CampaignScheduleSectionFormikValues>
  >;
  isActive: boolean;
  newCampaignSectionValues: NewCampaignSectionFormikValues;
}

const CampaignScheduleSection: React.FC<CampaignScheduleSectionProps> = ({
  formik,
  updateCampaignScheduleFormikValues,
  isActive,
  newCampaignSectionValues,
}) => {
  // Variables
  const [endDateDisabled, setEndDateDisabled] = useState<boolean>(true);
  const oneTime = formik.values?.schedule?.frequency
    ? formik.values.schedule.frequency === frequencyOptions.ONE_TIME
    : false;
  const [disabledCampaignFrequency, setDisabledCampaignFrequency] = useState<boolean>(
    newCampaignSectionValues.type !== campaignType.CUSTOM,
  );

  // Functions
  const resetInitialVals = (frequencyOption: frequencyOptions) => {
    formik.setFieldValue('schedule.time', null);
    formik.setFieldValue('schedule.start_date', null);
    formik.setFieldValue('schedule.end_date', null);
    formik.setFieldValue('schedule.day_offset', null);
    formik.setFieldTouched('schedule.day_offset', false);
    formik.setFieldValue('schedule.date_part', null);
    formik.setFieldValue('schedule.week_offset', null);
    formik.setFieldValue('schedule.day_of_week', dayOfWeekOptions.Null);
    formik.setFieldValue('schedule.month', monthOptions.Null);
    updateCampaignScheduleFormikValues({
      ...formik.values,
      schedule: {
        ...formik.values.schedule,
        frequency: frequencyOption,
        time: null,
        start_date: null,
        end_date: null,
        day_offset: null,
        date_part: null,
        week_offset: null,
        day_of_week: dayOfWeekOptions.Null,
        month: monthOptions.Null,
      },
    });
  };

  useEffect(() => {
    if (newCampaignSectionValues.type === campaignType.CUSTOM) {
      setDisabledCampaignFrequency(false);
      formik.setFieldValue('schedule.frequency', frequencyOptions.ONE_TIME);
      updateCampaignScheduleFormikValues({
        ...formik.values,
        schedule: {
          ...formik.values.schedule,
          frequency: frequencyOptions.ONE_TIME,
        },
      });
    } else {
      setDisabledCampaignFrequency(true);
      formik.setFieldValue('schedule.frequency', frequencyOptions.AUTOMATIC);
      updateCampaignScheduleFormikValues({
        ...formik.values,
        schedule: {
          ...formik.values.schedule,
          frequency: frequencyOptions.AUTOMATIC,
        },
      });
    }
  }, [newCampaignSectionValues.type]);

  return (
    <Box sx={{ border: '1px dashed', borderRadius: '10px', p: 3, mt: 3 }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='h6' maxWidth={'50%'}>
            CAMPAIGN SCHEDULE
          </Typography>
          {!oneTime && (
            <FormControlLabel
              control={
                <JunoBigSwitch
                  id='endDateDisabled'
                  sx={{ m: 1, mb: 3 }}
                  checked={endDateDisabled}
                  disabled={isActive}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    formik.handleChange(event);
                    if (!event.target.checked) {
                      setEndDateDisabled(false);
                    } else {
                      if (formik.values?.schedule?.end_date) {
                        formik.values.schedule.end_date = null;
                        updateCampaignScheduleFormikValues({
                          ...formik.values,
                          schedule: {
                            ...formik.values.schedule,
                            end_date: null,
                          },
                        });
                      }
                      setEndDateDisabled(true);
                    }
                  }}
                />
              }
              label='Never expires'
            />
          )}
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <FormControl variant='filled' fullWidth disabled={true}>
              <InputLabel id='campaign-frequency-label'>Frequency</InputLabel>
              <Select
                id='schedule.frequency'
                disabled={disabledCampaignFrequency || isActive}
                error={Boolean(
                  formik.touched.schedule?.frequency && formik.errors.schedule?.frequency,
                )}
                {...formik.getFieldProps('schedule.frequency')}
                onChange={(event: SelectChangeEvent) => {
                  updateCampaignScheduleFormikValues({
                    ...formik.values,
                    schedule: {
                      ...formik.values.schedule,
                      id: formik.values.schedule?.id || '',
                      frequency: event.target.value as frequencyOptions,
                    },
                  });
                  formik.handleChange(event);
                  resetInitialVals(event.target.value as frequencyOptions);
                }}
                value={
                  newCampaignSectionValues.type === campaignType.CUSTOM
                    ? formik.values.schedule.frequency
                    : frequencyOptions.AUTOMATIC
                }
              >
                {Object.entries(FrequencyMap).map(([key, value], index: number) => {
                  if (
                    key === frequencyOptions.AUTOMATIC &&
                    (formik.values.type === campaignType.CUSTOM ||
                      newCampaignSectionValues.type === campaignType.CUSTOM)
                  ) {
                    return null;
                  } else {
                    return (
                      <MenuItem
                        key={index}
                        value={key}
                        disabled={newCampaignSectionValues.type !== campaignType.CUSTOM}
                      >
                        {value}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
              <FormHelperText error>
                {formik.touched.schedule?.frequency && formik.errors.schedule?.frequency}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <DesktopDatePicker
              label={oneTime ? 'Date' : 'Start Date'}
              inputFormat='MM/dd/yyyy'
              value={formik.values?.schedule?.start_date || null}
              onChange={(value) => {
                // If time exists, and it is one-time, set start_date time to time. Prevents cross-timezone issues.
                const time = formik.values.schedule?.time;
                let actualValue = value;
                if (formik.values.schedule?.frequency === FrequencyEnum.ONE_TIME && value && time) {
                  const startTimeDate = new Date(value);
                  const timeDate = new Date(time);
                  startTimeDate.setHours(
                    timeDate.getHours(),
                    timeDate.getMinutes(),
                    timeDate.getSeconds(),
                    timeDate.getMilliseconds(),
                  );
                  actualValue = startTimeDate.toISOString();
                }
                formik.setFieldValue('schedule.start_date', actualValue);
                updateCampaignScheduleFormikValues({
                  ...formik.values,
                  schedule: {
                    ...formik.values.schedule,
                    id: formik.values.schedule?.id || '',
                    start_date: actualValue,
                  },
                });
              }}
              disabled={isActive}
              disablePast={true}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  {...formik.getFieldProps('schedule.start_date')}
                  id='schedule.start_date'
                  fullWidth
                  variant='filled'
                  error={Boolean(
                    formik.touched.schedule?.start_date && formik.errors.schedule?.start_date,
                  )}
                  helperText={formik.errors.schedule?.start_date}
                />
              )}
            />
          </Grid>
          {formik.values?.schedule?.frequency &&
            formik.values.schedule.frequency === frequencyOptions.ONE_TIME && (
              <Grid item xs={4}>
                <TimePick
                  formik={formik}
                  updateCampaignScheduleFormikValues={updateCampaignScheduleFormikValues}
                  disabled={isActive}
                  id={'schedule.time'}
                  initialValue={formik.values.schedule.time || ''}
                />
              </Grid>
            )}
          {formik.values?.schedule?.frequency &&
            formik.values.schedule.frequency !== frequencyOptions.ONE_TIME && (
              <Grid item xs={4}>
                <DesktopDatePicker
                  label='End Date'
                  inputFormat='MM/dd/yyyy'
                  value={formik.values.schedule.end_date || null}
                  onChange={(value: any) => {
                    try {
                      const val = new Date(value || '')?.toISOString().slice(0, 16);
                      formik.setFieldValue('schedule.end_date', val);
                      formik.handleChange(val);
                      updateCampaignScheduleFormikValues({
                        ...formik.values,
                        schedule: {
                          ...formik.values.schedule,
                          id: formik.values.schedule?.id || '',
                          end_date: val,
                        },
                      });
                    } catch {
                      formik.setFieldTouched('schedule.end_date', true);
                      formik.setFieldError('schedule.end_date', 'Invalid end date value');
                    }
                  }}
                  disablePast={true}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      {...formik.getFieldProps('schedule.end_date')}
                      name='schedule.end_date'
                      id='schedule.end_date'
                      fullWidth
                      variant='filled'
                      error={Boolean(
                        formik.touched.schedule?.end_date && formik.errors.schedule?.end_date,
                      )}
                      helperText={formik.errors.schedule?.end_date}
                    />
                  )}
                  disabled={isActive || endDateDisabled}
                />
              </Grid>
            )}
        </Grid>
      </LocalizationProvider>
      {!disabledCampaignFrequency && !oneTime && (
        <ScheduleLogic
          formik={formik}
          updateCampaignScheduleFormikValues={updateCampaignScheduleFormikValues}
          is_active={isActive}
        />
      )}
      <Typography sx={{ mt: 2, mb: -2 }}>*all times in UTC</Typography>
    </Box>
  );
};

export default CampaignScheduleSection;
