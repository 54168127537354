import React, { useEffect, useMemo, useState } from 'react';
import { DailyCall } from '@daily-co/daily-js';
import { MicOutlined } from '@mui/icons-material';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import { Avatar, Box, Typography } from '@mui/material';
import Bowser from 'bowser';
import classNames from 'classnames';
import { useBreakpoint } from '@juno/utils';
import { DEFAULT_ASPECT_RATIO } from '../../constants';
import { useCallState } from '../../contexts/CallProvider';
import { useChat } from '../../contexts/ChatProvider';
import { useUIState } from '../../contexts/UIStateProvider';
import { useVideoTrack } from '../../hooks/useVideoTrack';

interface UserTileProps {
  participant: any;
  showName: boolean;
  hasVideoTrack: boolean;
  videoRef: React.MutableRefObject<null>;
  videoFit: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  aspectRatio?: string;
}
const UserTile: React.FC<UserTileProps> = ({
  participant,
  showName,
  hasVideoTrack,
  videoRef,
  videoFit = 'contain',
  aspectRatio = DEFAULT_ASPECT_RATIO,
}) => {
  const { callObject: daily, participantAudioLevels } = useCallState();
  const { isMobile } = useUIState();
  const isLocalCam = useMemo(() => {
    const localParticipant = (daily as DailyCall | null)?.participants()?.local;
    return (
      !participant.isScreenShare &&
      participant.userData?.userId === (localParticipant?.userData as any)?.userId
    );
  }, [daily, participant.isScreenShare, participant.id]);
  const [isMirrored, setIsMirrored] = useState(isLocalCam);
  const videoTrack = useVideoTrack(participant.id);
  const { xs } = useBreakpoint();
  const { userEmojiClicked } = useChat();
  const [userAudioLevel, setUserAudioLevel] = useState(0);

  const isChrome92 = useMemo(() => {
    const { browser, platform, os } = Bowser.parse(navigator.userAgent);
    return (
      browser.name === 'Chrome' &&
      parseInt(browser.version || '', 10) >= 92 &&
      (platform.type === 'desktop' || os.name === 'Android')
    );
  }, []);

  useEffect(() => {
    if (!participantAudioLevels) return;
    const audioLevel = participantAudioLevels[participant.id] || 0;
    setUserAudioLevel(audioLevel * 50);
  }, [participantAudioLevels]);

  /**
   * Determine if video needs to be mirrored.
   */
  useEffect(() => {
    if (!videoTrack) return;

    const videoTrackSettings = videoTrack.getSettings();
    const isUsersFrontCamera =
      'facingMode' in videoTrackSettings
        ? isLocalCam && videoTrackSettings.facingMode === 'user'
        : isLocalCam;
    // only apply mirror effect to user facing camera
    if (isMirrored !== isUsersFrontCamera) {
      setIsMirrored(isUsersFrontCamera);
    }
  }, [isMirrored, isLocalCam, videoTrack]);

  /**
   * Handle canplay & picture-in-picture events.
   */
  useEffect(() => {
    const video = videoRef.current as unknown as HTMLVideoElement;
    if (!video) return;
    const handleCanPlay = () => {
      if (!video.paused) return;
      video.play();
    };
    const handleEnterPIP = () => {
      video.style.transform = 'scale(1)';
    };
    const handleLeavePIP = () => {
      video.style.transform = '';
      setTimeout(() => {
        if (video.paused) video.play();
      }, 100);
    };
    video.addEventListener('canplay', handleCanPlay);
    video.addEventListener('enterpictureinpicture', handleEnterPIP);
    video.addEventListener('leavepictureinpicture', handleLeavePIP);
    return () => {
      video.removeEventListener('canplay', handleCanPlay);
      video.removeEventListener('enterpictureinpicture', handleEnterPIP);
      video.removeEventListener('leavepictureinpicture', handleLeavePIP);
    };
  }, [isChrome92, videoRef]);

  useEffect(() => {
    const video = videoRef.current as unknown as HTMLVideoElement;
    if (!video || !videoTrack) return;
    video.srcObject = new MediaStream([videoTrack]);
    if (isChrome92) video.load();
    return () => {
      // clean up when unmounted
      video.srcObject = null;
      if (isChrome92) video.load();
    };
  }, [isChrome92, participant.id, videoRef, videoTrack, videoTrack?.id]);
  return (
    <Box
      bgcolor={hasVideoTrack ? 'black' : 'lightgray'}
      maxWidth={'100%'}
      maxHeight={'100%'}
      position={'absolute'}
      margin={'auto'}
      left={0}
      top={0}
      right={0}
      bottom={0}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      borderRadius={2}
      style={{ aspectRatio: `${aspectRatio}` }}
    >
      {userEmojiClicked && userEmojiClicked.userId === participant?.userData?.userId && (
        <Typography
          variant='subtitle2'
          sx={{
            position: 'absolute',
            top: '12px',
            display: 'flex',
            alignItems: 'center',
            right: '12px',
            zIndex: 2,
            fontSize: '1.7rem',
          }}
        >
          <>{userEmojiClicked.emoji}</>
        </Typography>
      )}
      {participant.userData && participant.userData.handRaised && (
        <Typography
          variant='subtitle2'
          sx={{
            backgroundColor: 'white',
            borderRadius: '6px',
            position: 'absolute',
            top: '12px',
            display: 'flex',
            alignItems: 'center',
            left: '12px',
            zIndex: 2,
            lineHeight: 1,
            fontSize: '0.875rem',
            color: 'black',
            padding: '10px 18px',
            textShadow: '0px 1px 3px rgba(0, 0, 0, 0.45)',
            gap: '8px',
            width: 'fit-content',
            fontWeight: 'bold',
          }}
        >
          Raised Hand 🖐️
        </Typography>
      )}
      {hasVideoTrack ? (
        <video
          className={classNames(videoFit, {
            isMirrored,
            isMobile,
            playable: videoTrack?.enabled,
          })}
          autoPlay
          muted
          playsInline
          ref={videoRef}
          style={{
            objectFit: videoFit,
            width: '100%',
            borderRadius: 'inherit',
          }}
        />
      ) : (
        <>
          {participant?.userData?.userAvatar ? (
            <Avatar
              style={{ width: 'auto', height: '50%' }}
              alt={participant?.name}
              src={participant?.userData?.userAvatar}
            />
          ) : (
            <Avatar style={{ maxWidth: xs ? '90px' : '150px', maxHeight: xs ? '90px' : '150px' }} />
          )}
        </>
      )}
      {showName && (
        <Typography
          variant='subtitle2'
          sx={{
            position: 'absolute',
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            left: 0,
            zIndex: 2,
            lineHeight: '1rem',
            fontSize: '0.875rem',
            color: 'white',
            padding: xs ? '2px' : '10px',
            textShadow: '0px 1px 3px rgba(0, 0, 0, 0.45)',
            gap: '8px',
          }}
        >
          {!participant.isScreenShare &&
            (participant.isMicMuted ? (
              <MicOffOutlinedIcon fontSize='small' color='error' />
            ) : (
              <MicOutlined fontSize='small' color='primary' />
            ))}
          {participant.name}
        </Typography>
      )}

      <Box
        sx={{
          width: '100%',
          height: '100%',
          opacity: userAudioLevel,
          position: 'absolute',
          top: 0,
          left: 0,
          borderRadius: 2,
          boxShadow: 'inset 0 0 7px 3px limegreen',
          transitionDuration: '500ms',
          transitionProperty: 'opacity',
        }}
      />
    </Box>
  );
};
export default UserTile;
