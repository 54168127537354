import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TimeChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'statusColor',
})<{ statusColor: 'green' | 'red' }>(({ statusColor, theme }) => ({
  backgroundColor: statusColor === 'green' ? '#6FBB331A' : '#BC48481A',
  border: `2px solid ${statusColor === 'green' ? '#6FBB33' : '#BC4848'}`,
  color: theme.palette.text.primary,
  borderRadius: 6,
}));
