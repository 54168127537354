import React, { useMemo, useState } from 'react';
import { BookmarkBorderOutlined, BookmarkOutlined, ShareOutlined } from '@mui/icons-material';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import PushPinIcon from '@mui/icons-material/PushPin';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import {
  Box,
  Card,
  Chip,
  IconButton,
  ListItemIcon,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFeatureFlag } from 'configcat-react';
import DOMPurify from 'dompurify';
import { useSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { getContentUrl, useFollowUnfollowForumTopic, useUpdateForumTopic } from '@juno/client-api';
import { ForumTopic, JunoUser } from '@juno/client-api/model';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';
import { BasicKebobMenu } from '@juno/ui';
import { MutationAction, onMutation, snackOptions, useSettings } from '@juno/utils';
import { useAnalyticsContext } from '../Contexts/AnalyticsContext';

interface ForumTopicCardProps {
  topic: ForumTopic;
  me: JunoUser | undefined;
  refetchMe: () => void;
  refetchTopics: () => void;
  siteId: string;
  userCanInteract?: boolean;
  iAmAdmin?: boolean;
  handleEditTopic: (topic: ForumTopic) => void;
  setSelectedTopic: (value: React.SetStateAction<ForumTopic | null>) => void;
  setDeleteDialogOpen: (value: React.SetStateAction<boolean>) => void;
}
const ForumTopicCard: React.FC<ForumTopicCardProps> = ({
  topic,
  me,
  refetchMe,
  siteId,
  refetchTopics,
  userCanInteract = true,
  iAmAdmin = false,
  handleEditTopic,
  setSelectedTopic,
  setDeleteDialogOpen,
}) => {
  const { setReportContent, reportContent, myContentReports } = useSettings();
  const { value: moderationValue } = useFeatureFlag('moderation', false);
  const { firehoseActivity } = useAnalyticsContext();
  const numFollowers = topic.followers_count;
  const numPosts = topic.feed.post_count;
  const amFollowing = !!me?.followed_forum_topics.find((t: string) => t === topic.id);
  const refetch = () => {
    refetchMe();
    refetchTopics();
  };
  const followUnfollow = useFollowUnfollowForumTopic(
    onMutation(MutationAction.UPDATE, '', refetch),
  );
  const updateTopic = useUpdateForumTopic(onMutation(MutationAction.UPDATE, '', refetch));
  const handleFollowUnfollow = async () => {
    try {
      await followUnfollow.mutateAsync({
        siteId: siteId,
        forumId: topic.forum_id,
        topicId: topic.id,
        userId: me?.id || '',
      });
      const action = amFollowing
        ? ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_UNFOLLOW.value
        : ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_FOLLOW.value;
      firehoseActivity(
        ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_COMMUNITY,
        topic.id || '',
        topic.forum_id,
        `${me?.id || ''}`,
        ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_FORUM_TOPIC,
        action,
        topic.title,
        null,
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handlePinUnpin = async () => {
    try {
      await updateTopic.mutateAsync({
        siteId,
        forumId: topic.forum_id,
        topicId: topic.id,
        data: {
          ...topic,
          pinned: !topic.pinned,
          author: topic.author || me?.id,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const handleShareClick = async () => {
    if (!navigator.clipboard) {
      console.error('Clipboard API not supported');
      return;
    }

    const urlResponse = await getContentUrl(
      siteId,
      topic.id,
      location.pathname.split('/').pop() || '',
    );
    const locationSplit = window.location.href.split('/');
    locationSplit.pop();
    const locationHref = locationSplit.join('/');
    const contentUrl = `${locationHref}${urlResponse.url}`;

    navigator.clipboard
      .writeText(contentUrl)
      .then(() => {
        enqueueSnackbar('Copied!', snackOptions('success'));
      })
      .catch((error) => {
        enqueueSnackbar('Copy Failed!', snackOptions('error'));
        console.error('Failed to copy URL to clipboard:', error);
      });
  };

  const htmlValue = useMemo(() => {
    const sanitizedString = DOMPurify.sanitize(topic?.description || ('' as string), {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'],
    });
    // uncomment to debug what's getting removed
    // console.log('Dirty values', DOMPurify.removed);
    return sanitizedString;
  }, [topic.description]);

  return (
    <Card
      variant='outlined'
      sx={{ position: 'relative', boxShadow: 1, '&:hover': { boxShadow: 5 } }}
    >
      <Box p='8px 18px'>
        <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
          <Stack
            spacing={1}
            mb={1}
            onClick={() => setSelectedTopic(topic)}
            sx={{ cursor: 'pointer', flexGrow: 1 }}
          >
            <Typography
              variant='h6'
              sx={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {topic.title}
            </Typography>
            <div
              className='content'
              dangerouslySetInnerHTML={{
                __html: htmlValue as unknown as string,
              }}
            ></div>
          </Stack>
          <Box>
            <Box display={'flex'}>
              {(topic.pinned || iAmAdmin) && (
                <IconButton
                  disabled={!iAmAdmin}
                  onClick={(e) => {
                    handlePinUnpin();
                  }}
                >
                  {topic.pinned && (
                    <Tooltip title='Unpin Discussion' placement='top'>
                      <PushPinIcon
                        sx={{
                          color: (theme) => theme.palette.primary.main,
                        }}
                      />
                    </Tooltip>
                  )}
                  {!topic.pinned && (
                    <Tooltip title='Pin Discussion' placement='top'>
                      <PushPinIcon
                        sx={{
                          color: '#bbb',
                        }}
                      />
                    </Tooltip>
                  )}
                </IconButton>
              )}
              <BasicKebobMenu>
                {topic.author === me?.id && (
                  <Box>
                    <MenuItem
                      onClick={(e) => {
                        handleEditTopic(topic);
                      }}
                      aria-label='Edit Discussion'
                    >
                      <ListItemIcon>
                        <EditRoundedIcon color='primary' fontSize='small' />
                      </ListItemIcon>
                      <Typography>Edit</Typography>
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        setSelectedTopic(topic);
                        setDeleteDialogOpen(true);
                      }}
                      aria-label='Delete Discussion'
                    >
                      <ListItemIcon>
                        <DeleteForeverRoundedIcon color='primary' fontSize='small' />
                      </ListItemIcon>
                      <Typography>Delete</Typography>
                    </MenuItem>
                  </Box>
                )}
                {moderationValue && topic.author !== me?.id && !iAmAdmin && (
                  <MenuItem
                    onClick={(e) => {
                      setReportContent({
                        contentBeingReported: {
                          ...reportContent.contentBeingReported,
                          id: topic.id,
                          type: 'Discussion',
                          reporter: me?.id || '',
                          author: topic.author || '',
                        },
                        reportContentDialogOpen: true,
                      });
                    }}
                    aria-label='Report Discussion'
                    disabled={!!myContentReports.find((r) => r.object_id === topic.id)}
                  >
                    <ListItemIcon>
                      <ReportGmailerrorredIcon color='primary' fontSize='small' />
                    </ListItemIcon>
                    <Typography>
                      {myContentReports.find((r) => r.object_id === topic.id)
                        ? 'Reported'
                        : 'Report'}
                    </Typography>
                  </MenuItem>
                )}
                {topic.author != me?.id && iAmAdmin && (
                  <MenuItem
                    onClick={() => {
                      setSelectedTopic(topic);
                      setDeleteDialogOpen(true);
                    }}
                    aria-label='Delete discussion as admin'
                  >
                    <ListItemIcon>
                      <DeleteForeverRoundedIcon color='primary' fontSize='small' />
                    </ListItemIcon>
                    <Typography>Delete Discussion</Typography>
                  </MenuItem>
                )}
              </BasicKebobMenu>
            </Box>
            {/*
              TODO: sharing isn't implemented yet
              <BasicKebobMenu>
                <MenuItem onClick={() => {}} aria-label='Edit post'>
                  <ListItemIcon>
                    <ShareRoundedIcon color='primary' fontSize='small' />
                  </ListItemIcon>
                  <Typography>Share</Typography>
                </MenuItem>
              </BasicKebobMenu> */}
          </Box>
        </Stack>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Stack direction={'row'} spacing={1}>
            <IconButton
              size='small'
              disabled={!userCanInteract}
              onClick={(e) => {
                e.stopPropagation();
                handleFollowUnfollow();
              }}
            >
              {amFollowing && (
                <Tooltip title='Unfollow topic' placement='top'>
                  <BookmarkOutlined sx={{ color: (theme) => theme.palette.primary.main }} />
                </Tooltip>
              )}
              {!amFollowing && (
                <Tooltip title='Follow topic' placement='top'>
                  <BookmarkBorderOutlined />
                </Tooltip>
              )}
            </IconButton>
            <Tooltip title={'Share'} placement='top'>
              <IconButton
                aria-label={'share topic - copy to clipboard'}
                onClick={handleShareClick}
                disabled={!userCanInteract}
              >
                <ShareOutlined fontSize='small' />
              </IconButton>
            </Tooltip>
          </Stack>
          <Box maxWidth='60%' mt={2}>
            {topic.tags.map((tag) => (
              <Chip label={tag.value} sx={{ mr: 1, mb: 1 }} key={tag.id} />
            ))}
          </Box>
          <Stack direction='row' spacing={1}>
            <Typography variant='caption'>{`${numFollowers} Following`}</Typography>
            <Typography variant='caption'>{`${numPosts} Posts`}</Typography>
          </Stack>
        </Box>
      </Box>
    </Card>
  );
};
export default ForumTopicCard;
