import { Card } from '@mui/material';
import { ModerationAdmin } from '@juno/modules';
import { Container } from '@juno/ui';

const Moderation = () => {
  return (
    <Container>
      <Card>
        <ModerationAdmin />
      </Card>
    </Container>
  );
};

export default Moderation;
