import React, { useState } from 'react';
import { DeleteOutline, MarkEmailReadOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Notification } from '@juno/client-api/model';
import { getTimeSinceCreated, useSettings } from '@juno/utils';
import { useNotificationContext } from './NotificationContext';

interface NotificationsCardProps {
  handleClose: () => void;
}
const NotificationsCard: React.FC<NotificationsCardProps> = ({ handleClose }) => {
  const { sitePath } = useSettings();
  const navigate = useNavigate();
  const { notifications, unreadNotifications, readNotification, deleteNotification } =
    useNotificationContext();

  const [selectedNotifications, setSelectedNotifications] = useState<string[]>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSelect = (id: string) => {
    if (selectedNotifications.includes(id)) {
      setSelectedNotifications(selectedNotifications.filter((n) => n !== id));
    } else {
      setSelectedNotifications([...selectedNotifications, id]);
    }
  };

  const handleDelete = async () => {
    if (selectedNotifications.length === 0) return;
    setIsSubmitting(true);
    await deleteNotification(selectedNotifications);
    setSelectedNotifications([]);
    setIsSubmitting(false);
  };

  const handleRead = async (id?: string) => {
    if (id) {
      setIsSubmitting(true);
      await readNotification([id]);
      setSelectedNotifications([]);
      setIsSubmitting(false);
      return;
    }
    if (selectedNotifications.length === 0) return;
    setIsSubmitting(true);
    await readNotification(selectedNotifications);
    setSelectedNotifications([]);
    setIsSubmitting(false);
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const AllMessagesPanel = ({ ...props }) => {
    const { index } = props;
    return (
      <Box
        role='tabpanel'
        hidden={tabValue !== index}
        id={`all-tabpanel-${index}`}
        aria-labelledby={`all-tab-${index}`}
        display={'flex'}
        justifyContent={'center'}
        pt={1}
        sx={{
          pointerEvents: isSubmitting ? 'none' : 'auto',
          opacity: isSubmitting ? 0.5 : 1,
        }}
      >
        {tabValue === index && (
          <>
            {notifications?.length === 0 ? (
              <Typography variant={'subtitle2'} sx={{ pt: 2, pb: 2 }}>
                No Notifications
              </Typography>
            ) : (
              <List
                sx={{
                  maxHeight: '60vh',
                  height: '100%',
                  overflowY: 'scroll',
                  p: 0,
                }}
              >
                {notifications?.map((notification, index) => (
                  <NotificationItem
                    key={notification.id}
                    index={index}
                    handleSelect={handleSelect}
                    selectedNotifications={selectedNotifications}
                    notification={notification}
                    onClick={() => {
                      if (notification.content_slug) {
                        handleRead(notification.id);
                        handleClose();
                        navigate(`/${sitePath}${notification.content_slug}`);
                      }
                    }}
                  />
                ))}
              </List>
            )}
          </>
        )}
      </Box>
    );
  };

  const UnreadMessagesPanel = ({ ...props }) => {
    return (
      <Box
        role='tabpanel'
        hidden={tabValue !== 1}
        id={`unread-tabpanel-1`}
        aria-labelledby={`unread-tab-1`}
        display={'flex'}
        justifyContent={'center'}
        sx={{
          pointerEvents: isSubmitting ? 'none' : 'auto',
          opacity: isSubmitting ? 0.5 : 1,
        }}
      >
        {tabValue === 1 && (
          <>
            {unreadNotifications.length === 0 ? (
              <Typography variant={'subtitle2'} sx={{ pt: 2, pb: 2 }}>
                No Unread Notifications
              </Typography>
            ) : (
              <List
                sx={{
                  maxHeight: '60vh',
                  height: '100%',
                  overflowY: 'scroll',
                  p: 0,
                }}
              >
                {unreadNotifications?.map((notification, index) => (
                  <NotificationItem
                    index={index}
                    handleSelect={handleSelect}
                    selectedNotifications={selectedNotifications}
                    notification={notification}
                    onClick={() => {
                      if (notification.content_slug) {
                        handleClose();
                        navigate(`/${sitePath}${notification.content_slug}`);
                      }
                    }}
                  />
                ))}
              </List>
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography ml={1} variant={'subtitle1'}>
          Notifications
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} mt={2}>
        <FormControlLabel
          disabled={notifications?.length === 0}
          control={
            <Checkbox
              aria-label='Select all'
              checked={
                notifications?.length > 0 && selectedNotifications.length === notifications?.length
              }
              indeterminate={
                selectedNotifications.length > 0 &&
                selectedNotifications.length < notifications?.length
              }
              onClick={() => {
                if (selectedNotifications.length === notifications?.length) {
                  setSelectedNotifications([]);
                } else {
                  setSelectedNotifications(notifications?.map((n) => n.id) || []);
                }
              }}
              sx={{ pl: 2 }}
            />
          }
          label={
            <Typography variant={'subtitle2'}>
              {notifications?.length > 0 && selectedNotifications.length === notifications?.length
                ? 'Unselect All'
                : selectedNotifications.length === 0
                ? 'Select All'
                : `${selectedNotifications.length} selected`}
            </Typography>
          }
        />
        <Box>
          <Tooltip
            title={
              selectedNotifications.length === 0
                ? 'No notifications selected'
                : 'Mark selected as read'
            }
          >
            <IconButton onClick={() => handleRead()}>
              <MarkEmailReadOutlined
                sx={{
                  color: selectedNotifications.length === 0 ? 'disabled' : 'green',
                }}
              />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={
              selectedNotifications.length === 0 ? 'No notifications selected' : 'Delete selected'
            }
          >
            <IconButton onClick={handleDelete}>
              <DeleteOutline
                sx={{
                  color: selectedNotifications.length === 0 ? 'disabled' : 'red',
                }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Tabs
        sx={{ pl: 1 }}
        value={tabValue}
        onChange={handleChangeTab}
        aria-label='notifications tabs'
      >
        <Tab
          label={'All'}
          value={0}
          sx={{
            textTransform: 'none',
          }}
        />
        <Tab
          label={'Unread'}
          value={1}
          sx={{
            textTransform: 'none',
          }}
        />
      </Tabs>
      <AllMessagesPanel index={0} />
      <UnreadMessagesPanel index={1} />
    </>
  );
};
export default NotificationsCard;

const NotificationItem = ({
  notification,
  onClick,
  selectedNotifications,
  handleSelect,
  index,
}: {
  notification: Notification;
  onClick: () => void;
  selectedNotifications: string[];
  handleSelect: (id: string) => void;
  index: number;
}) => {
  return (
    <ListItem
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        pl: 1,
        pr: 1,
        pt: 0,
        width: '100%',
      }}
    >
      <Stack
        direction={'row'}
        spacing={1}
        sx={{
          width: '100%',
          borderBottom: (theme) => `2px solid ${theme.palette.divider}`,

          '&:hover': {
            borderRadius: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
          },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              aria-label={`Select notification ${index}`}
              checked={selectedNotifications.includes(notification.id)}
              sx={{
                '&.MuiCheckbox-root': {
                  paddingLeft: 0,
                },
              }}
            />
          }
          onClick={(e) => {
            e.stopPropagation();
            handleSelect(notification.id);
          }}
          label={
            <Avatar
              src={notification?.actor?.avatar || ''}
              sx={{
                width: 35,
                height: 35,
              }}
            />
          }
        />
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
          <Typography
            variant='subtitle2'
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'normal',
              fontWeight: notification.is_read ? 'normal' : 'bold',
              color: notification.is_read ? 'rgba(0, 0, 0, 0.7)' : 'default',
            }}
          >
            {notification.content_name}
          </Typography>

          <Typography
            textAlign='right'
            variant='caption'
            sx={{
              fontWeight: notification.is_read ? 'normal' : 'bold',
              color: notification.is_read ? 'rgba(0, 0, 0, 0.7)' : 'default',
              mb: 1,
            }}
          >
            {getTimeSinceCreated(false, notification.created_at)}
          </Typography>
        </Box>
      </Stack>
    </ListItem>
  );
};
