import { Avatar, Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{
  fullWidth: boolean;
}>(({ fullWidth }) => ({
  width: !fullWidth ? 1020 : '100%',
  maxWidth: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  '.slick-next::before, .slick-prev::before': {
    content: 'none',
    display: 'none',
  },
}));

export const Title = styled(Typography)(() => ({
  fontSize: 18,
  letterSpacing: 3,
  textTransform: 'uppercase',
  fontWeight: 700,
  margin: 0,
}));

export const PauseButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'right',
  minHeight: '20px',
});

export const PauseButton = styled(Button)(() => ({
  cursor: 'pointer',
  display: 'flex',
  lineHeight: '20px',
  fontSize: '16px',
  fontWeight: 300,
  margin: '0px 30px',
}));

export const SlideContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'slideWidth' && prop !== 'textAlign',
})<{ slideWidth: number; textAlign: 'center' | 'left' | 'right' }>(({ slideWidth, textAlign }) => ({
  position: 'relative',
  width: slideWidth,
  textAlign: textAlign,
  margin: 'auto',
  cursor: 'grab',
}));

export const SlideImage = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'slideWidth' && prop !== 'slideHeight',
})<{ slideWidth: number; slideHeight: number }>(({ slideWidth, slideHeight }) => ({
  margin: 'auto',
  width: slideWidth,
  height: slideHeight,
  img: {
    WebkitUserDrag: 'none',
    KhmlUserDrag: 'none',
    MozUserDrag: 'none',
    OUserDrag: 'none',
    userDrag: 'none',
  },
}));

export const ProgressIndicator = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'itemHeight',
})<{ itemHeight: number }>(({ itemHeight }) => ({
  zIndex: 1000,
  position: 'absolute',
  top: itemHeight - 40,
  right: 5,
  borderRadius: '45px',
  padding: '5px 10px',
}));

export const SlideTitle = styled(Typography)(() => ({
  marginTop: '10px',
  fontSize: '16px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const SlideDescription = styled(Typography)(() => ({
  marginTop: '0px', // 10px
  fontSize: '14px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  opacity: 0.5,
}));
