import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getGetProductQueryKey,
  getGetProductsQueryKey,
  useCreateProduct,
  useDeleteProduct,
  useGetAccessPasses,
  useGetProduct,
  useUpdateProduct,
} from '@juno/client-api';
import { JunoImage as JunoImageModel } from '@juno/client-api/fakeModel';
import {
  AccessPass,
  ProductMetadata,
  Product as ProductModel,
  ProductPrice,
  TypeD1bEnum as ProductTypeEnum,
  Site as SiteModel,
} from '@juno/client-api/model';
import {
  ASPECT_RATIOS,
  AdminEditPanelHeader,
  AutocompleteAccessPasses,
  JunoBackButton,
  JunoImageUpload,
  JunoSpin,
  SaveBar,
} from '@juno/ui';
import { MutationAction, onMutation, optimizeImage } from '@juno/utils';
import PricingList from './PricingList';

interface ProductPanelProps {
  site: SiteModel;
  productId: string;
}

export interface PayloadProps {
  id?: string;
  name: string;
  type?: ProductTypeEnum;
  description?: string;
  image?: string | null;
  product_prices?: ProductPrice[];
  metadata?: ProductMetadata;
  active?: boolean;
  access_pass?: string | null;
}

const ProductPanel: React.FC<ProductPanelProps> = ({ site, productId }) => {
  const { id: siteId } = site;
  const queryClient = useQueryClient();
  const [error, setError] = useState<string>('');
  const [isSaving, setIsSaving] = useState(false);
  const [payload, setPayload] = useState<PayloadProps | undefined>();
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const navigateBack = () => navigate(-1);
  const { data: productData, isLoading } = useGetProduct(siteId, productId);
  const refetchProduct = () =>
    queryClient.invalidateQueries(getGetProductQueryKey(siteId, productId));
  const updateProduct = useUpdateProduct(
    onMutation(MutationAction.UPDATE, 'Product', refetchProduct),
  );
  const createProduct = useCreateProduct(
    onMutation(MutationAction.CREATE, 'Product', refetchProduct),
  );
  const refetchProducts = () => queryClient.invalidateQueries(getGetProductsQueryKey(siteId));
  const refetchAfterDelete = () => {
    refetchProducts();
    navigateBack();
  };
  const deleteProduct = useDeleteProduct(
    onMutation(MutationAction.DELETE, 'Product', refetchAfterDelete),
  );
  const handleUpdate = (key: string, val: unknown) => setPayload((p) => ({ ...p, [key]: val }));

  useEffect(() => {
    // Prevent MUI dialog from blocking focus to the TinyMCE code editor
    document.addEventListener('focusin', (e) => {
      if (
        (e.target as Element).closest(
          '.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root',
        ) !== null
      ) {
        e.stopImmediatePropagation();
      }
    });
  }, []);

  useEffect(() => {
    if (!productData) return;
    setPayload(productData);
  }, [productData]);

  const changeHandler = {
    checkbox: ({ target: t }: ChangeEvent<HTMLInputElement>) => handleUpdate(t.name, t.checked),
    text: ({ target: t }: ChangeEvent<HTMLInputElement>) => handleUpdate(t.name, t.value),
    textarea: ({ target: t }: ChangeEvent<HTMLTextAreaElement>) => handleUpdate(t.name, t.value),
    tinymcetextarea: (key: string, value: string) => handleUpdate(key, value),
    number: ({ target: t }: ChangeEvent<HTMLInputElement>) =>
      handleUpdate(t.name, parseInt(t.value) || 0),
    image: (key: string, image: JunoImageModel | null) => handleUpdate(key, image?.url || ''),
    access_pass: (event: SelectChangeEvent<string[]>, accessPasses: AccessPass[]) =>
      handleContentSelectChange(event, accessPasses),
  };

  const handleSaveUpdate = async () => {
    if (!payload) return;
    setIsSaving(true);
    try {
      await updateProduct.mutateAsync({ siteId, productId, data: payload as ProductModel });
      setIsSaving(false);
    } catch (e) {
      // error is captured and displayed on toast
      setIsSaving(false);
      return;
    }
    setIsSaving(false);
  };

  const handleSaveCreate = async () => {
    if (!payload) return;
    setIsSaving(true);
    try {
      await createProduct.mutateAsync({
        siteId,
        data: {
          ...payload,
          active: true,
          product_prices: [],
          type: 1,
          site_id: site?.id || '',
          metadata: { foo: 'bar' },
        },
      });
      setIsSaving(false);
    } catch (e) {
      // error is captured and displayed on toast
      setIsSaving(false);
      return;
    }
    setIsSaving(false);
  };

  const handleSave = () => {
    if (productId) {
      handleSaveUpdate();
    } else {
      handleSaveCreate();
    }
  };

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = () => {
    deleteProduct.mutate({ siteId, productId });
  };

  const handleContentSelectChange = (
    e: SelectChangeEvent<string[]>,
    accessPasses: AccessPass[],
  ) => {
    if (accessPasses?.[1]?.id) {
      setPayload((old) => {
        return { ...old, access_pass: accessPasses[1].id };
      });
    } else if (accessPasses?.[0]?.id) {
      setPayload((old) => {
        return { ...old, access_pass: accessPasses[0].id };
      });
    } else {
      setPayload((old) => {
        return { ...old, access_pass: null };
      });
    }
  };

  const isDirty = useMemo(() => {
    let dirty = ['name', 'type', 'description', 'image', 'active'].some(
      (key) => payload?.[key as keyof PayloadProps] !== productData?.[key as keyof PayloadProps],
    );
    if (payload?.access_pass !== productData?.access_pass) {
      dirty = true;
    }
    if (dirty && payload) return true;
    return false;
  }, [payload, productData]);

  const discardChanges = () => {
    setPayload(productData);
  };

  if (isLoading) return <JunoSpin />;
  return (
    <Box sx={{ mt: 2 }}>
      <AdminEditPanelHeader
        shouldShow={isDirty}
        title={payload?.name || ''}
        onClickDiscard={discardChanges}
        onClickSave={handleSave}
        onClickGoBack={() => {
          navigate(-1);
        }}
        isSubmitting={isSaving}
      />

      <Button variant='text' onClick={openDeleteDialog}>
        Delete
      </Button>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={7}>
          <FormControl
            margin='dense'
            fullWidth
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '20px',
              mb: 1,
            }}
          >
            <Typography sx={{ fontWeight: 700, display: 'flex' }}>Product Information</Typography>
            <FormControlLabel
              sx={{ display: 'flex' }}
              control={
                <Switch
                  name='active'
                  checked={!!payload?.active}
                  onChange={changeHandler.checkbox}
                />
              }
              label='Active'
            />
          </FormControl>
          <FormControl margin='normal' fullWidth>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <TextField
                sx={{ flex: 2 }}
                fullWidth
                id='name'
                name='name'
                label='Product Title'
                value={payload?.name || ''}
                onChange={changeHandler.text}
                error={!!error}
                helperText={error}
                size='small'
              />
            </Box>
          </FormControl>
          <FormControl margin='normal' fullWidth>
            <Box sx={{ display: 'flex', width: '100%', gap: 1 }}>
              <TextField
                id='description'
                name='description'
                label='Product Description'
                fullWidth
                value={payload?.description || ''}
                size='small'
                onChange={changeHandler.text}
              />
            </Box>
          </FormControl>
          <FormControl margin='dense'>
            <Box sx={{ width: 310 }}>
              <fieldset style={{ borderColor: 'rgba(255,255,255,0.23)' }}>
                <legend>
                  <Typography variant='caption'>Product Image</Typography>
                </legend>
                <JunoImageUpload
                  style={{ aspectRatio: '1/1' }}
                  src={optimizeImage(200, payload?.image)}
                  onFileUploaded={(selected) => changeHandler.image('image', selected)}
                  aspectRatios={[ASPECT_RATIOS.ONE_ONE]}
                />
              </fieldset>
              <Box sx={{ ml: 2, mt: 1, lineHeight: 1 }}>
                <Typography variant='caption' sx={{ lineHeight: 0.5, fontStyle: 'italic' }}>
                  Upload a jpg or png image that is less than 2MB.
                </Typography>
              </Box>
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5}>
          <PricingList site={site} product={productData} />
        </Grid>
      </Grid>
      <Dialog open={deleteDialogOpen} onClose={closeDeleteDialog}>
        <DialogContent>Are you sure you want to delete this product?</DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog}>Cancel</Button>
          <Button onClick={handleDelete} variant='contained'>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProductPanel;
