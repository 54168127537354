import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Type0c0Enum } from '@juno/client-api/model';
import { LessonPart, Question } from '@juno/client-api/utils';
import { ImportAutocompleteModel } from '../ImportValidationDialog/wizard-parts/ImportAutocomplete';
import { headerKeys } from './headerKeys';
import { validateValue } from './validations';

interface ShapeProps<T> {
  columnKey: string;
  values: string | number | string[] | null;
  matchData: T[] | null | undefined;
  allowNull?: boolean;
  reverse?: boolean;
}

// TODO move this to a utils file
export interface ImportArrayModel {
  [k: string]: any;
  id?: string;
  dom_value: string | number | ImportArrayModel | ImportArrayModel[] | null | undefined | boolean;
  is_valid: boolean;
  errorMessage?: string;
  errorField?: string;
}

/**
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 * - This method is used to manipulate the incoming rows from a csv into an ImportArrayModel
 * - It shapes and validates the data based on the column key,  bucket and optional context
 * @param {string} bucket - the data type bucket
 * @param {array} rawValues - the raw row values from the csv
 * @param {object} props - the props passed in from the parent component contain any necessary matchData for validation such as courseList, siteTags, lessonList, questionList
 * @returns {ImportArrayModel[]} - an array of ImportArrayModel
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */
export const handleFormikValueManipulationByBucket = (
  bucket: string,
  rawValues: [],
  { ...props },
) => {
  const returnValues: ImportArrayModel[] = [];
  rawValues.forEach((row: any) => {
    const shapedRow: ImportArrayModel = {} as ImportArrayModel;
    const thisRow = row;
    Object.keys(row)?.map((key: string) => {
      const values = switchTypeAndReturnShapedValue(row[key], key, bucket, props);
      if (values.length > 1) {
        // if the value is an array, we need to map over it and validate each value
        const newRow = values.map((v) => validateValue(key, v, bucket, thisRow));
        shapedRow[key] = newRow;
      } else {
        const newRow = validateValue(key, values[0], bucket, thisRow);
        shapedRow[key] = newRow;
      }
    });
    returnValues.push(shapedRow);
  });
  return returnValues;
};

// TODO add more in depth comment about adding arrays
export const switchTypeAndReturnShapedValue = (
  values: any,
  key: string,
  bucket: string,
  { courseList, siteTags, lessonList, questionList }: any,
) => {
  const colDefs = getColumnDefinitionsForTable(bucket);
  const aCol = colDefs.find((col) => col.field === key);
  let shapeKey = key;
  let matchData: any = [];
  let reverse = false;
  let allowNull = true;

  // ! be sure to add String(val).toLowerCase() on any slug columns
  switch (aCol?.type) {
    case `autocomplete-${aCol?.field}`:
      if (aCol.type.search('tags') > -1) {
        shapeKey = 'value';
        matchData = siteTags;
        if (typeof values === 'string') {
          values = values.split('|');
        }
      } else if (aCol.type.search('course_id') > -1 || aCol.type.search('course_slug') > -1) {
        // Lessons course_id needs to be in the courseList, CourseResources course_slug needs to be in the courseList
        shapeKey = 'slug';
        matchData = courseList;
        allowNull = false;
      } else if (aCol.type.search('type') > -1) {
        // Questions must have a type in QuestionTypes.QUESTION_TYPES
        shapeKey = 'value';
        matchData = Object.keys(Question.QUESTION_TYPES).map((option) => {
          return { value: option, id: option ?? Date.now(), is_valid: true };
        });
        allowNull = false;
      } else if (aCol.type.search('question_id') > -1) {
        // Question Answers question_id needs to be in the questionList
        shapeKey = 'name';
        matchData = questionList;
        allowNull = false;
        // filter out any undefined or null values from the array
        if (Array.isArray(values)) {
          values = values.filter((v) => v !== undefined && v !== null);
        }
      } else if (aCol.type.search('lesson_slug') > -1) {
        // Lesson parts slug needs to be in the lessonList
        shapeKey = 'slug';
        matchData = lessonList;
        allowNull = false;
      }
      break;

    case 'percent':
      values = values ? Number(String(values).replace('%', '')) : values;
      break;

    case 'course_slug':
      shapeKey = 'slug';
      matchData = courseList;
      break;

    case 'course_slug_reverse':
      shapeKey = 'slug';
      matchData = courseList;
      reverse = true;
      break;

    case 'course_title':
      shapeKey = 'title';
      matchData = courseList;
      reverse = true;
      break;
  }

  return shapeIncomingImportColumn({
    columnKey: shapeKey,
    values,
    matchData,
    reverse,
    allowNull,
  });
};

/**
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 * Given a columnKey, array of values, and an array of matchData of type T,
 * return a new array of ImportArrayModel objects that match the given values.
 * If the value does not match, set is_valid to false.
 * If allowNull is true and the value is null or blank string, set is_valid to true.
 * If reverse is true, return all values that do not match.
 * If matchData is empty, return all values as valid.
 *
 * @param columnKey - string key to match against the T matchData
 * @param values - string or array of strings
 * @param matchData - array of objects of type T
 * @param allowNull - optional param to allow null or blank string values
 * @param reverse - optional param to reverse the match logic: reverse: true means is_valid will be true if the matchData does not contain the value; reverse: false means is_valid will be true if the matchData does contain the value
 * @returns { dom_value: string | number | ImportArrayModel |ImportArrayModel[] | null; is_valid: boolean; }[] An array of ImportArrayModel objects
 *
 * ? Note: dom_value is the arbitrary value of a key/value pair given a model type T
 *
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */
export const shapeIncomingImportColumn = <
  T extends { id?: string; title?: string; dom_value?: string; name?: string },
>({
  columnKey,
  values,
  matchData,
  allowNull,
  reverse,
}: ShapeProps<T>) => {
  const resp = [] as ImportArrayModel[];
  if ((values && Array.isArray(values)) || (typeof values === 'string' && values !== '')) {
    const local = typeof values === 'string' ? [values] : values;
    local?.forEach((value: string) => {
      // if we have no matchData values to match against, just return all values as valid, values are valid if allowNull is not false
      if (!matchData || matchData?.length === 0) {
        resp.push({
          [columnKey as keyof T]: values,
          dom_value: values,
          is_valid: allowNull !== false,
        } as ImportArrayModel);
        return;
      }

      const match =
        matchData?.filter((d: T) => (d[columnKey as keyof T] as unknown as string) === value) ?? [];

      // Here we handle both use cases, where I can say give me all items that do match OR give me all items that do not match
      if (reverse) {
        match.length > 0 &&
          resp.push({
            ...match[0],
            dom_value: match[0][columnKey as keyof T],
            is_valid: false,
            errorMessage: 'Field is not unique',
          } as ImportArrayModel);
        match.length === 0 &&
          resp.push({
            [columnKey as keyof T]: values,
            dom_value: value,
            is_valid: true,
          } as ImportArrayModel);
      } else {
        match.length > 0 &&
          resp.push({
            ...match[0],
            dom_value: match[0][columnKey as keyof T],
            is_valid: true,
          } as ImportArrayModel);
        match.length === 0 &&
          resp.push({
            dom_value: value,
            errorMessage: 'Field does not exist in the system',
            is_valid: false,
          } as ImportArrayModel);
      }
    });
  } else if (typeof values === 'number') {
    resp.push({
      [columnKey as keyof T]: values,
      dom_value: values,
      is_valid: true,
    } as ImportArrayModel);
  } else {
    if (allowNull) {
      resp.push({
        [columnKey as keyof T]: values,
        dom_value: values,
        is_valid: true,
      } as ImportArrayModel);
    } else {
      resp.push({
        [columnKey as keyof T]: values,
        dom_value: values,
        is_valid: false,
        errorField: columnKey,
        errorMessage: 'This is a required field',
      } as ImportArrayModel);
    }
  }
  return resp;
};

/**
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 * should take a bucket string and return the correct columns needed for the import for that bucket in a GridColDef array
 * @param bucket - string bucket name
 * @returns { GridColDef[] } - array of GridColDef objects
 * @example
 * const columns = getColumnDefinitionsForTable('Courses'); // returns an array of GridColDef objects
 *
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */
export const getColumnDefinitionsForTable = (bucket: string) => {
  return headerKeys[bucket]
    ? headerKeys[bucket]?.map((key) => {
        const extras = {
          ...(key.required && {
            headerClassName: 'required-header',
            headerAlign: 'left',
            renderHeader: () => <strong>{key.headerName}</strong>,
          }),
        };
        return {
          key: key.name,
          field: key.name,
          headerName: key.headerName,
          minWidth: 150,
          isResizable: true,
          type: key.type,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params: GridRenderCellParams<React.ReactNode>) => {
            return params.row[key.name]?.dom_value ?? '';
          },
          ...extras,
        } as GridColDef;
      })
    : [
        {
          field: 'no_headers',
          headerName: 'No Headers Supplied',
          width: 400,
          align: 'center',
        } as GridColDef,
      ];
};

/**
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 * @param tableRow - array of objects of type T
 * @returns { boolean } - true if all items in the array are valid, false if any are invalid
 * @example
 * const isValid = isTableRowValid<ImportArrayModel>(tableRow); // returns true or false
 *
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */
export const isTableRowValid = <T,>(tableRow: T[]): boolean => {
  let valid = true;
  tableRow.forEach((item: T) => {
    for (const key in item) {
      for (const newKey in item[key]) {
        if (valid === true && newKey === 'is_valid') {
          valid = item[key][newKey] as unknown as boolean;
        }
      }
    }
  });
  return valid;
};

/**
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 * @param tableRow - array of objects of type T
 * @returns { number } - number of invalid items in the array
 * @example
 * const count = countTableRowErrors<ImportArrayModel>(tableRow); // returns a number
 *
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */
export const countTableRowErrors = <T,>(tableRow: T[]): number => {
  let count = 0;
  tableRow.forEach((item: T) => {
    for (const key in item) {
      let itemKey = item[key];
      // handle when autocompletes are required but have no value
      if (Array.isArray(itemKey)) {
        const arrItem = { ...item[key] } as unknown as [any];
        itemKey = arrItem[0];
      }

      for (const newKey in itemKey) {
        if (newKey === 'is_valid') {
          const valid = itemKey[newKey] as unknown as boolean;
          !valid && count++;
        }
      }
    }
  });
  return count;
};

/**
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 * @param tableRow - array of objects of type T
 * @returns { ImportArrayModel[] } - array of objects of type ImportArrayModel
 * @example
 * const errors = getTableRowErrors<ImportArrayModel>(tableRow); // returns an array of objects of type ImportArrayModel
 *
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */
export const getTableRowErrors = <T,>(tableRow: T[]): ImportArrayModel[] => {
  const errors: ImportArrayModel[] = [];
  tableRow.forEach((item: T) => {
    for (const key in item) {
      // handle when autocompletes are required but have no value
      let itemKey = item[key];
      if (Array.isArray(itemKey)) {
        const arrItem = { ...item[key] } as unknown as [any];
        itemKey = arrItem[0];
      }

      for (const newKey in itemKey) {
        if (newKey === 'is_valid') {
          const valid = itemKey[newKey] as unknown as boolean;
          if (!valid) {
            errors.push({
              ...(itemKey as unknown as ImportArrayModel),
              rowId: item['rowId' as keyof T] as unknown as string,
            });
          }
        }
      }
    }
  });
  return errors;
};

/**
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 * @param valuesArray - array of objects of type T | undefined
 * @param relationalArray - array of objects of type U | undefined
 * @param bucket - string bucket name
 * @param matchKey - string key name to match on
 * @returns { T[] } - array of objects of type T
 * @example
 * const updatedValues = updateRelationalIds<Lesson, Course>(values, courses, 'Courses', 'course_name'); // returns an array of objects of type Lesson
 *
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */
export const updateRelationalIds = <T extends Record<string, any>, U extends Record<string, any>>(
  valuesArray: T[] | undefined,
  relationalArray: U[] | undefined,
  bucket: string,
  matchKey: string,
) => {
  if (!valuesArray) {
    return [];
  }
  // question answers do not have relational ids
  if (bucket === 'Question Answers') {
    return valuesArray;
  }
  const hKeys = headerKeys[bucket];
  return valuesArray?.map((item: T) => {
    let updatedItem = { ...item };
    Object.keys(item).forEach((key) => {
      const headerKey = hKeys?.find((hk) => hk.name === key);
      if (headerKey && headerKey.type?.startsWith('autocomplete-')) {
        const matchingItem = relationalArray?.find(
          (relationalItem) => relationalItem[matchKey] === item[key],
        );
        if (matchingItem) {
          updatedItem = { ...updatedItem, [key]: matchingItem['id'] };
        }
      }
    });
    return updatedItem;
  });
};

/**
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 * @param valuesArray - array of objects of type T | undefined
 * @param bucket - string bucket name
 * @returns { T[] } - array of objects of type T
 * @example
 *
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */
//  export const updateEnumValuesByBucket = <T extends Record<string, any>,>(valuesArray: T, bucket: string) =>{
export const updateEnumValuesByBucket = <T extends { type: string; question_type: string }>(
  valuesArray: T[],
  bucket: string,
) => {
  switch (bucket) {
    case 'Lesson Parts':
      valuesArray.map((item: T) => {
        const lessonPartTypes: any = LessonPart.LESSON_PART_TYPES;
        const typeForUpdate = Object.keys(lessonPartTypes)
          .map((lpt: string) => {
            return lessonPartTypes[lpt];
          })
          .filter((v) => v.label === item.type);
        item.type = typeForUpdate[0].value;

        if (String(item.type) === String(LessonPart.LESSON_PART_TYPES.QUESTION.value)) {
          const questionTypes: any = Question.QUESTION_TYPES;
          const questionTypeForUpdate = Object.keys(questionTypes)
            .map((qt: string) => {
              return questionTypes[qt];
            })
            .filter((v) => v.label === item.question_type);
          item.question_type = questionTypeForUpdate[0].value;
        }
      });
      return valuesArray;
    default:
      return valuesArray;
  }
};

export const fixDuplicateKeyCounts = (
  arr: ImportAutocompleteModel[],
): ImportAutocompleteModel[] => {
  const valueOccurrences: { [key: string]: number } = {};

  const result: ImportAutocompleteModel[] = arr.map((item) => {
    const { value } = item;
    const occurrence = valueOccurrences[value] || 0;
    valueOccurrences[value] = occurrence + 1;

    if (occurrence > 0) {
      // Append the incremental number to the value for duplicates
      item.value = `${value} (${occurrence})`;
    }

    return item;
  });

  return result;
};

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const stringToBoolean = (value: string): boolean | undefined => {
  switch (String(value).toLowerCase()) {
    case 'true':
    case 't':
    case '1':
    case 'yes':
    case 'y':
      return true;
    case 'false':
    case 'f':
    case '0':
    case 'no':
    case 'n':
      return false;
    default:
      return undefined;
  }
};

export interface QuestionProps {
  id: string;
  title: string;
  type: Type0c0Enum;
  is_valid: boolean;
}

export interface WizardValue {
  value: any;
  is_valid: boolean;
}
