/* eslint-disable no-prototype-builtins */
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
  Button,
  Chip,
  Divider,
  Drawer,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import {
  getGetEmailCampaignsQueryKey,
  useGetSiteFeatureConfig,
  useGetSiteUnsubscribeGroups,
  useSendSiteEmailCampaign,
} from '@juno/client-api';
import {
  FeatureConfigTypeEnum,
  SendingEmail as SendingEmailPayload,
  Site as SiteModel,
  Template as TemplateModel,
  TypeBd0Enum as campaignType,
  FrequencyEnum as frequencyOptions,
} from '@juno/client-api/model';
import { CAMPAIGN_EMAIL_FROM_SUFFIX } from '@juno/constants';
import { LinearProgressWithLabel } from '@juno/ui';
import {
  MutationAction,
  getScheduleString,
  onMutation,
  snackOptions,
  useSettings,
} from '@juno/utils';
import ActivateCampaignDialog from '../../Dialogs/ActivateCampaignDialog';
import ConfirmDeactivateCampaignDialog from '../../Dialogs/ConfirmDeactivateCampaignDialog';
import {
  CampaignTypeToTestEmailPayload,
  CampaignTypeToTestEmailSerializerFieldName,
  WelcomeEmailPayload,
  email_type_to_unsubscribe_group_mapping,
} from '../../utils/testEmailDefaultPayloads';
import {
  EmailCampaignTypeToTemplateMap,
  getFirstName,
  getUserPreferenceValue,
} from '../../utils/utils';
import {
  CampaignRecipientSectionFormikValues,
  CampaignScheduleSectionFormikValues,
  NewCampaignSectionFormikValues,
  SidebarSectionFormikValues,
} from '../../utils/validationSchema';
import { drawerWidth } from '../EmailCampaignBuilder';
import AutocompleteTemplate from '../helpers/AutocompleteTemplate';
import progress from '../helpers/ProgressLogic';
import CampaignTemplatePreview from './TemplatePreview';

interface WelcomeEmailPayloadType {
  magic_link: string;
  site_nickname: string;
  logo: string;
  banner_image: string;
  last_name: string;
}

interface CampaignSidebarSectionProps {
  formik: FormikProps<SidebarSectionFormikValues>;
  setSidebarSectionFormikValues: React.Dispatch<React.SetStateAction<SidebarSectionFormikValues>>;
  newCampaignSectionValues: NewCampaignSectionFormikValues;
  campaignScheduleSectionValues: CampaignScheduleSectionFormikValues;
  campaignRecipientsSectionValues: CampaignRecipientSectionFormikValues;
  handleSubmit: () => Promise<void>;
  emailCampaignTypeChanged?: campaignType | '';
  headCount: number;
  templateIsSaved: boolean;
}

const CampaignSidebarSection: React.FC<CampaignSidebarSectionProps> = ({
  formik,
  setSidebarSectionFormikValues,
  newCampaignSectionValues,
  campaignScheduleSectionValues,
  campaignRecipientsSectionValues,
  handleSubmit,
  emailCampaignTypeChanged,
  headCount,
  templateIsSaved,
}) => {
  // Variables
  const { site } = useSettings();
  const [openActivate, setOpenActivate] = useState(false);
  const [openDeactivate, setOpenDeactivate] = useState(false);
  const [chosenTemplate, setChosenTemplate] = useState<TemplateModel | undefined>(undefined);
  const [testRecipientInput, setTestRecipientInput] = useState<string>('');
  const [testEmailRecipients, setTestEmailRecipients] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const { CREATE } = MutationAction;
  const refetchEmailCampaigns = () =>
    queryClient.invalidateQueries(getGetEmailCampaignsQueryKey(site?.id || ''));
  const postTestEmail = useSendSiteEmailCampaign(
    onMutation(CREATE, 'Test Email', refetchEmailCampaigns),
  );
  const { enqueueSnackbar } = useSnackbar();
  const { values, isValid, validateForm } = formik;

  // Queries and mutations
  const { data: templateUnsubscribeGroup } = useGetSiteUnsubscribeGroups(
    site?.id || '',
    {
      filter: { name: email_type_to_unsubscribe_group_mapping[newCampaignSectionValues.type] },
    },
    { query: { enabled: !!site?.id } },
  );
  const { data: siteFeatureConfig } = useGetSiteFeatureConfig(
    site?.id || '',
    FeatureConfigTypeEnum.automation,
    { query: { enabled: !!site?.id } },
  );
  const sendTestEmail = (payload: SendingEmailPayload) => {
    postTestEmail
      .mutateAsync({ siteId: site?.id || '', data: payload })
      .then(() => {
        setTestEmailRecipients([]);
      })
      .catch((err) => {
        enqueueSnackbar(err.toString(), snackOptions('error'));
      });
  };

  // Functions
  const handleRecipientInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTestRecipientInput(event.target.value);
  };
  const handleRecipientInputKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      const newEmails = testRecipientInput.split(/[ ,]+/).filter((email) => email.length > 0);
      if (newEmails.length > 5) {
        // Let the user know a valid test recipient email address is required
        enqueueSnackbar('Please limit the test email to 5 recipients', snackOptions('error'));
        return;
      }
      if (!handleValidateRecipientEmail(newEmails[newEmails.length - 1])) return;
      setTestEmailRecipients((prevEmails) => [...prevEmails, ...newEmails]);
      setTestRecipientInput('');
    }
  };
  const handleDeleteRecipient = (emailToDelete: string) => {
    setTestEmailRecipients((prevEmails) => prevEmails.filter((email) => email !== emailToDelete));
  };
  const handleValidateRecipientEmail = (emailAddress: string): boolean => {
    // Enforce strict email address character length
    if (emailAddress.length > 254) {
      // Let the user know they've eclipsed the character limit
      enqueueSnackbar('Email address cannot be more than 254 characters', snackOptions('error'));
      return false;
    }
    // Regex to confirm that the user inputted a valid email address
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (emailRegex.test(emailAddress)) {
      // Confirm that we have a valid loner recipient email address
      return true;
    } else {
      // Let the user know a valid test recipient email address is required
      enqueueSnackbar('Please provide a valid email address', snackOptions('error'));
      return false;
    }
  };
  const handleSendTest = () => {
    const testCampaignType = newCampaignSectionValues.type;
    // If the user has added a single test recipient but hasn't pushed 'enter' or 'comma' we need to capture the recipient
    let foundSoloRecipient = false;
    if (!testEmailRecipients || testEmailRecipients.length < 1) {
      if (testRecipientInput.length > 0) {
        foundSoloRecipient = handleValidateRecipientEmail(testRecipientInput.trim());
      } else {
        // Let the user know an empty string does not qualify as a valid email address
        enqueueSnackbar(
          'A valid email address is required to send a test email',
          snackOptions('error'),
        );
        return;
      }

      if (!foundSoloRecipient) return;
    }
    // Bail if a template hasn't been saved
    if (!templateIsSaved) {
      enqueueSnackbar(
        'You must save a template to the campaign before you can send a test email',
        snackOptions('error'),
      );
      return;
    }
    // For templates that have unsubscribe group functionality, the site has to have the corresponding unsubscribe group setup
    if (
      testCampaignType !== campaignType.WELCOME &&
      testCampaignType !== campaignType.CUSTOM &&
      templateUnsubscribeGroup?.length === 0
    ) {
      enqueueSnackbar(
        'The template associated with this test email requires an unsubscribe group and this ' +
          'site has not yet configured the corresponding unsubscribe group',
        snackOptions('error'),
      );
      return;
    }
    // If the user hasn't selected an email template, we bail
    if (!chosenTemplate) {
      enqueueSnackbar(
        'An email template must be selected and saved before a test email can be sent',
        snackOptions('error'),
      );
      return;
    }
    // If for whatever reason, the chosen template doesn't align with the specific campaign type, we can't send the emails
    if (
      testCampaignType !== campaignType.CUSTOM &&
      EmailCampaignTypeToTemplateMap[testCampaignType] !== chosenTemplate?.version_name
    ) {
      enqueueSnackbar(
        'The selected email template must align with the EmailCampaign type in order to send a test email',
        snackOptions('error'),
      );
      return;
    }

    // We wanna override the banner_image and logo defaults for sites that have those MV's saved in their configs
    const isWelcomeTestSend = testCampaignType === campaignType.WELCOME;
    const welcomeEmailTestMVs = {
      last_name: WelcomeEmailPayload['last_name'],
      banner_image: WelcomeEmailPayload['banner_image'],
      logo: WelcomeEmailPayload['logo'],
      magic_link: WelcomeEmailPayload['magic_link'],
      site_nickname: WelcomeEmailPayload['site_nickname'],
    } as WelcomeEmailPayloadType;
    if (isWelcomeTestSend && siteFeatureConfig && siteFeatureConfig.config) {
      const config = siteFeatureConfig.config;
      if (config.banner_image && typeof config.banner_image === 'string') {
        welcomeEmailTestMVs['banner_image'] = config.banner_image;
      }
      if (config.logo && typeof config.logo === 'string') {
        welcomeEmailTestMVs['logo'] = config.logo;
      }
    }

    // Build payload
    const payload = {
      // Fields required for all email campaigns
      type: testCampaignType,
      email: foundSoloRecipient ? testRecipientInput.trim() : testEmailRecipients[0],
      first_name: foundSoloRecipient
        ? getFirstName(testRecipientInput.trim())
        : getFirstName(testEmailRecipients[0]),
      test_email_list: foundSoloRecipient ? [testRecipientInput.trim()] : testEmailRecipients,
      // EmailCampaign type-specific template merge variable fields - canned default values
      [CampaignTypeToTestEmailSerializerFieldName[testCampaignType]]: isWelcomeTestSend
        ? welcomeEmailTestMVs
        : CampaignTypeToTestEmailPayload[testCampaignType],
      // We have to have a way to distinguish between CUSTOM campaigns
      custom_test_id: newCampaignSectionValues.id,
    };

    // Send it
    sendTestEmail(payload);
  };

  const handleCloseActivate = () => {
    setOpenActivate(false);
  };

  const recipientValue = () => {
    if (headCount === 0) {
      return 'All eligible site users';
    }
    return `${headCount} site user${headCount > 1 ? 's' : ''}`;
  };

  const rows = [
    { label: 'Campaign Title', value: newCampaignSectionValues.name },
    { label: 'From (Name)', value: newCampaignSectionValues.from_name },
    {
      label: 'From (Email)',
      value: `${newCampaignSectionValues.from_email}${CAMPAIGN_EMAIL_FROM_SUFFIX}`,
    },
    { label: 'Subject', value: newCampaignSectionValues.subject },
    {
      label: 'Schedule',
      value: getScheduleString(campaignScheduleSectionValues.schedule),
    },
    {
      label: 'Subscription Group',
      value: getUserPreferenceValue(values.type || '', 'N/A'),
    },
  ];

  if (
    campaignScheduleSectionValues?.schedule?.frequency &&
    campaignScheduleSectionValues.schedule.frequency !== frequencyOptions.AUTOMATIC
  ) {
    rows.splice(4, 0, {
      label: 'Recipients',
      value: recipientValue(),
    });
  }

  useEffect(() => {
    if (chosenTemplate) {
      setSidebarSectionFormikValues({ ...values, template: chosenTemplate });
    }
  }, [chosenTemplate]);

  return (
    <Drawer
      anchor='right'
      hideBackdrop
      variant='permanent'
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Stack direction={'column'} sx={{ pl: 2, pr: 2, mt: 2 }}>
        <Stack direction='row' justifyContent='space-between'>
          {!values.is_active ? (
            <Button
              aria-label='Activate-campaign'
              fullWidth
              variant='contained'
              onClick={() => {
                isValid ? setOpenActivate(true) : validateForm();
              }}
              disabled={
                progress(
                  formik,
                  newCampaignSectionValues,
                  campaignScheduleSectionValues,
                  campaignRecipientsSectionValues,
                ) <= 99
              }
            >
              ACTIVATE CAMPAIGN
            </Button>
          ) : (
            <Button
              aria-label='Deactivate-campaign'
              fullWidth
              variant='contained'
              onClick={() => {
                setOpenDeactivate(true);
              }}
              color='error'
              endIcon={<LockOpenIcon />}
            >
              DEACTIVATE
            </Button>
          )}
        </Stack>
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel
            value={progress(
              formik,
              newCampaignSectionValues,
              campaignScheduleSectionValues,
              campaignRecipientsSectionValues,
            )}
          />
        </Box>
      </Stack>
      <TableContainer component={Paper}>
        <Table
          sx={{ maxWidth: drawerWidth, '& tr, & td': { borderBottom: 'none' } }}
          aria-label='simple table'
        >
          <TableBody>
            {rows.map((row, i) => (
              <TableRow key={i}>
                <TableCell sx={{ width: 120, paddingRight: 0, verticalAlign: 'top' }}>
                  <strong>{row.label}</strong>
                </TableCell>
                <TableCell>{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Divider />
        <Box sx={{ padding: '16px', fontSize: 14 }}>
          <strong>Email Preview</strong>
          <AutocompleteTemplate
            formik={formik}
            emailCampaignType={values?.type}
            emailCampaignTypeChanged={emailCampaignTypeChanged}
            setTemplate={setChosenTemplate}
          />
          <CampaignTemplatePreview
            template={chosenTemplate || { id: '', version_id: '', version_name: '', base_id: '' }}
          />
          <Typography variant='body2' sx={{ mt: 2, mb: 2 }}>
            <strong>Send Test Email</strong>
          </Typography>
          <TextField
            label='Enter Test Recipients'
            variant='outlined'
            value={testRecipientInput}
            onChange={handleRecipientInputChange}
            onKeyPress={handleRecipientInputKeyPress}
            helperText='Separate multiple email addresses with commas'
            fullWidth
            placeholder={'test_recipient_1@company.com'}
          />
          {testEmailRecipients.length > 0 && (
            <Typography sx={{ mt: 2 }}>Test Email Recipients</Typography>
          )}
          <Stack direction='column' spacing={1} style={{ marginTop: 16 }}>
            {testEmailRecipients.map((emailAddress, index) => (
              <Chip
                key={index}
                label={emailAddress}
                onDelete={() => handleDeleteRecipient(emailAddress)}
                color='primary'
              />
            ))}
          </Stack>
          <Button
            aria-label='Send-test-email'
            variant='outlined'
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleSendTest}
          >
            SEND TEST NOW
          </Button>
        </Box>
      </TableContainer>
      {openActivate && (
        <ActivateCampaignDialog
          formik={formik}
          setSidebarSectionFormikValues={setSidebarSectionFormikValues}
          open={openActivate}
          handleClose={handleCloseActivate}
          handleActivateCampaign={handleSubmit}
          unsavedSchedule={campaignScheduleSectionValues.schedule}
        />
      )}
      {openDeactivate && (
        <ConfirmDeactivateCampaignDialog
          formik={formik}
          setSidebarSectionFormikValues={setSidebarSectionFormikValues}
          handleClose={setOpenDeactivate}
          handleDeactivateCampaign={handleSubmit}
        />
      )}
    </Drawer>
  );
};

export default CampaignSidebarSection;
