import { ChangeEvent } from 'react';
import AppsIcon from '@mui/icons-material/Apps';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, Card, Divider, FormControlLabel, Switch, Tooltip, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { Reorder, useDragControls } from 'framer-motion';
import { CommunityGroup, Site as SiteModel, TabsOrderEnum } from '@juno/client-api/model';
import { TABS, TabProps } from '../../client/GroupPage/utils';

interface ForumFeedSettingsProps {
  site: SiteModel;
  group: CommunityGroup;
  formik: FormikProps<any>;
}

const ForumFeedSettings: React.FC<ForumFeedSettingsProps> = ({ site, group, formik }) => {
  const { handleChange, values, setFieldValue } = formik;

  const handleEnableForumChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    if (!e.target.checked) {
      setFieldValue('allow_member_forum_topics', false);
    }
  };

  const handleTabReorder = (newArray: any[]) => {
    // to make sure the about tab is always first, remove it from the array and add it back at the beginning
    newArray = newArray.filter((tab) => tab !== TabsOrderEnum.about);
    newArray.unshift(TabsOrderEnum.about);

    handleChange({ target: { name: 'tabs_order', value: newArray } });
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Card sx={{ background: '#fff', maxWidth: 600, width: '90%' }}>
        <Typography variant='h6' p={2}>
          Additional Group Settings
        </Typography>
        <Divider />
        <Box p={2}>
          <Box mt={1}>
            <FormControlLabel
              control={
                <Switch
                  checked={values.allow_member_forum_topics}
                  onChange={handleChange}
                  name='allow_member_forum_topics'
                />
              }
              label='Allow members to post new discussions'
            />
          </Box>
          <Box mt={1}>
            <Typography fontWeight='bold' mt={3}>
              Tabs
            </Typography>
            <Typography variant='body2' mb={1}>
              Reorder and show/hide sections of the group page. Whichever is first (and not hidden)
              will be the default landing page for the group.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 1.5,
                mb: 1,
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: 2,
                background: '#fff',
              }}
            >
              {'About'}
            </Box>
            <Reorder.Group
              axis='y'
              values={values.tabs_order}
              onReorder={handleTabReorder}
              style={{ listStyleType: 'none', paddingLeft: 0 }}
            >
              {values.tabs_order.map((tab: string, index: number) => {
                const item = TABS?.find((t) => t.slug === tab);
                if (item?.slug === TabsOrderEnum.about) return null;
                return <TabReorderItem item={item} key={item?.label} formik={formik} />;
              })}
            </Reorder.Group>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default ForumFeedSettings;

interface TabItemProps {
  item: TabProps | undefined;
  formik: FormikProps<any>;
}
const TabReorderItem: React.FC<TabItemProps> = ({ item, formik }) => {
  const controls = useDragControls();
  const { handleChange, values } = formik;
  const handleShowHideTab = (action: string) => {
    let newTabsShown = [];
    if (action === 'show') {
      newTabsShown = [...values.tabs_shown, item?.slug].sort((a, b) => a - b);
    } else {
      newTabsShown = values.tabs_shown.filter((tab: string) => tab !== item?.slug);
    }
    handleChange({
      target: {
        name: 'tabs_shown',
        value: newTabsShown,
      },
    });
  };
  return (
    <Reorder.Item key={item?.slug} value={item?.slug} dragListener={false} dragControls={controls}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1.5,
          mb: 1,
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: 2,
          background: '#fff',
          opacity: values.tabs_shown.includes(item?.slug) ? 1 : 0.5,
        }}
      >
        {item?.label}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {values.tabs_shown.includes(item?.slug) ? (
            <Tooltip title='Hide tab'>
              <VisibilityIcon
                onClick={() => handleShowHideTab('hide')}
                sx={{ cursor: 'pointer', opacity: 0.7, fontSize: 20 }}
              />
            </Tooltip>
          ) : (
            <Tooltip title='Show tab'>
              <VisibilityOffIcon
                onClick={() => handleShowHideTab('show')}
                sx={{ cursor: 'pointer', opacity: 0.7, fontSize: 20 }}
              />
            </Tooltip>
          )}
          <AppsIcon
            sx={{ cursor: 'grab', ml: 2, opacity: 0.7 }}
            onPointerDown={(e) => controls.start(e)}
          />
        </Box>
      </Box>
    </Reorder.Item>
  );
};
