import { ReoccurrenceEnum, Session } from '@juno/client-api/model';

export interface GenericKeyValueType {
  key?: string;
  value?: string;
}

// Takes an array and returns a dictionary object
export function arrayToDict(arr: GenericKeyValueType[]): Record<string, string> {
  const buildObj: Record<string, string> = {};
  arr.forEach((element) => {
    if (element.value) {
      const newObj = { [element.key as string]: element.value };
      Object.assign(buildObj, newObj);
    }
  });
  return buildObj;
}

// Takes a dictionary object and returns an array
export function objToArray(obj: GenericKeyValueType): GenericKeyValueType[] {
  const buildArr: GenericKeyValueType[] = [];
  Object.entries(obj).forEach(([key, value]) => {
    const tempObj = { key: value as string };
    buildArr.push(tempObj);
  });
  return buildArr;
}

export const determineSessionToShow = (session: Session): Session => {
  const currentDate = new Date();
  const startDate = new Date(session.date_start || '');
  const endDate = new Date(session.date_end || '');

  if (session.reoccurrence === ReoccurrenceEnum.once) {
    return session;
  }

  // Clone the session to avoid mutating the original object
  const sessionClone = { ...session };

  const duration = endDate.getTime() - startDate.getTime(); // Duration in milliseconds

  const newStart = new Date(startDate);

  switch (session.reoccurrence) {
    case ReoccurrenceEnum.daily:
      if (newStart < currentDate) {
        newStart.setDate(currentDate.getDate());
      }
      break;

    case ReoccurrenceEnum.weekly:
      {
        const dayDiff = (currentDate.getDay() - startDate.getDay() + 7) % 7;
        newStart.setDate(currentDate.getDate() - dayDiff);
      }
      break;

    case ReoccurrenceEnum.monthly:
      newStart.setMonth(currentDate.getMonth());
      break;

    case ReoccurrenceEnum.yearly:
      newStart.setFullYear(currentDate.getFullYear());
      break;
  }

  // Ensure the new start date is in the future
  if (newStart < currentDate) {
    switch (session.reoccurrence) {
      case ReoccurrenceEnum.daily:
        newStart.setDate(newStart.getDate() + 1);
        break;
      case ReoccurrenceEnum.weekly:
        newStart.setDate(newStart.getDate() + 7);
        break;
      case ReoccurrenceEnum.monthly:
        newStart.setMonth(newStart.getMonth() + 1);
        break;
      case ReoccurrenceEnum.yearly:
        newStart.setFullYear(newStart.getFullYear() + 1);
        break;
    }
  }

  // Adjust the end time based on the new start time and the original duration
  const newEnd = new Date(newStart.getTime() + duration);

  // Adjust the end time based on the original session time_end
  const originalTimeEnd = new Date(session.time_end || 0);
  newEnd.setHours(originalTimeEnd.getHours());
  newEnd.setMinutes(originalTimeEnd.getMinutes());
  newEnd.setSeconds(originalTimeEnd.getSeconds());

  sessionClone.date_start = newStart.toISOString();
  sessionClone.date_end = newEnd.toISOString();

  return sessionClone;
};
