/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type TypeA36Enum = typeof TypeA36Enum[keyof typeof TypeA36Enum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TypeA36Enum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
} as const;
