import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs, { Dayjs } from 'dayjs';
import { ANALYTICS_DASHBOARD_CATEGORIES } from '@juno/constants';
import { useSettings } from '@juno/utils';
import { LineChart } from './LineChart';
import { CATEGORIES } from './constants';
import { CategoryButton, CategoryTypography, Overlay } from './styles';

enum TimeFrame {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  LAST_7 = 'Last 7 Days',
  LAST_30 = 'Last 30 Days',
  LAST_90 = 'Last 90 Days',
  YTD = 'Year to Date',
  CUSTOM = 'Custom',
}

const defaultTimeFrame = [
  {
    title: TimeFrame.TODAY,
    dates: {
      start: dayjs().startOf('date'),
      end: dayjs().endOf('date'),
    },
  },
  {
    title: TimeFrame.YESTERDAY,
    dates: {
      start: dayjs().startOf('date').subtract(1, 'day'),
      end: dayjs().endOf('date'),
    },
  },
  {
    title: TimeFrame.LAST_7,
    dates: {
      start: dayjs().startOf('date').subtract(7, 'day'),
      end: dayjs().endOf('date'),
    },
  },
  {
    title: TimeFrame.LAST_30,
    dates: {
      start: dayjs().startOf('date').subtract(30, 'day'),
      end: dayjs().endOf('date'),
    },
  },
  {
    title: TimeFrame.LAST_90,
    dates: {
      start: dayjs().startOf('date').subtract(90, 'day'),
      end: dayjs().endOf('date'),
    },
  },
  {
    title: TimeFrame.YTD,
    dates: {
      start: dayjs().startOf('year'),
      end: dayjs().endOf('date'),
    },
  },
];

export interface AnalyticsExamineProps {
  excludedCategories: string[];
}
export const ExamineYourData: React.FC<AnalyticsExamineProps> = ({ excludedCategories }) => {
  const { enableLearning } = useSettings();
  const filteredCategories = CATEGORIES.filter((category) => {
    if (!enableLearning && category.id === ANALYTICS_DASHBOARD_CATEGORIES.LEARNING_ENGAGEMENT.id) {
      return false;
    }
    return !excludedCategories.includes(category.id);
  });
  const [selectedCategory, setSelectedCategory] = useState(filteredCategories[0]?.id || '');
  const [selectAll, setSelectAll] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().startOf('date').subtract(7, 'day'),
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs().endOf('day'));
  const [timeFrame, setTimeFrame] = useState('Last 7 Days');
  const [selectedWidget, setSelectedWidget] = useState<string | null>(null);
  const handleCategorySelect = (id: string) => {
    if (selectAll.length > 0) {
      setSelectAll([]);
    }
    if (selectedCategory === id) {
      setSelectedCategory('');
    } else {
      setSelectedCategory(id);
    }
  };
  const handleSelectAllCategories = () => {
    if (selectedCategory) {
      setSelectedCategory('');
    }
    if (selectAll.length > 0) {
      setSelectAll([]);
    } else {
      setSelectedCategory('');
      setSelectAll(CATEGORIES.map((cat) => cat.id));
    }
  };

  const handleSetStartDate = (date: any) => {
    try {
      setStartDate(dayjs(date).startOf('day'));
    } catch {
      setStartDate(null);
    }
  };

  const handleSetEndDate = (date: Dayjs | string | null) => {
    try {
      setEndDate(dayjs(date).endOf('day'));
    } catch {
      setEndDate(null);
    }
  };

  const handleSetTimeFrame = useCallback(() => {
    for (let i = 0; i < defaultTimeFrame.length; i++) {
      if (
        startDate?.valueOf() === defaultTimeFrame[i].dates.start.valueOf() &&
        endDate?.valueOf() === defaultTimeFrame[i].dates.end.valueOf()
      ) {
        setTimeFrame(defaultTimeFrame[i].title);
        return;
      }
      setTimeFrame('Custom');
    }
  }, [startDate, endDate]);

  useEffect(() => {
    handleSetTimeFrame();
  }, [startDate, endDate, handleSetTimeFrame]);

  const handleDateChange = (event: SelectChangeEvent) => {
    const timeFrameValue = event.target.value as string;
    switch (timeFrameValue) {
      case TimeFrame.TODAY: {
        // setTimeFrame(TimeFrame.TODAY);
        setStartDate(dayjs().startOf('day'));
        setEndDate(dayjs().endOf('day'));
        handleSetTimeFrame();
        break;
      }
      case TimeFrame.YESTERDAY: {
        // setTimeFrame(TimeFrame.YESTERDAY);
        setStartDate(dayjs().startOf('day').subtract(1, 'day'));
        setEndDate(dayjs().endOf('day'));
        handleSetTimeFrame();
        break;
      }
      case TimeFrame.LAST_7: {
        // setTimeFrame(TimeFrame.LAST_7);
        setStartDate(dayjs().startOf('day').subtract(7, 'day'));
        setEndDate(dayjs().endOf('day'));
        handleSetTimeFrame();
        break;
      }
      case TimeFrame.LAST_30: {
        // setTimeFrame(TimeFrame.LAST_30);
        setStartDate(dayjs().startOf('day').subtract(30, 'day'));
        setEndDate(dayjs().endOf('day'));
        handleSetTimeFrame();
        break;
      }
      case TimeFrame.LAST_90: {
        // setTimeFrame(TimeFrame.LAST_90);
        setStartDate(dayjs().startOf('day').subtract(90, 'day'));
        setEndDate(dayjs().endOf('day'));
        handleSetTimeFrame();
        break;
      }
      case TimeFrame.YTD: {
        // setTimeFrame(TimeFrame.YTD);
        setStartDate(dayjs().startOf('year'));
        setEndDate(dayjs().endOf('day'));
        handleSetTimeFrame();
        break;
      }
    }
  };

  return (
    <Box sx={{ mb: 1, mt: 2, ml: -1, mr: -1 }}>
      <Overlay isShowing={selectedWidget !== null} />
      <Grid container spacing={1}>
        {filteredCategories.map((cat) => (
          <Grid item key={cat.id}>
            <CategoryButton
              onClick={(e) => handleCategorySelect(cat.id)}
              variant={selectedCategory === cat.id ? 'contained' : 'outlined'}
            >
              {cat.title}
            </CategoryButton>
          </Grid>
        ))}
        <Grid item>
          <CategoryButton
            onClick={(e) => handleSelectAllCategories()}
            variant={selectAll.length > 0 ? 'contained' : 'outlined'}
          >
            View All
          </CategoryButton>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel id='timeFrame-select-label'>Time Frame</InputLabel>
              <Select
                fullWidth
                id='timeFrameSelect'
                value={timeFrame}
                label='Time Frame'
                onChange={handleDateChange}
              >
                <MenuItem value={TimeFrame.TODAY}>Today</MenuItem>
                <MenuItem value={TimeFrame.YESTERDAY}>Yesterday</MenuItem>
                <MenuItem value={TimeFrame.LAST_7}>Last 7 days</MenuItem>
                <MenuItem value={TimeFrame.LAST_30}>Last 30 Days</MenuItem>
                <MenuItem value={TimeFrame.LAST_90}>Last 90 Days</MenuItem>
                <MenuItem value={TimeFrame.YTD}>Year to Date</MenuItem>
                <MenuItem value={TimeFrame.CUSTOM} sx={{ display: 'none' }}>
                  Custom
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DesktopDatePicker
              // disabled
              label='Start Date'
              inputFormat='MM/dd/yyyy'
              value={startDate}
              PopperProps={{
                disablePortal: true,
              }}
              onChange={handleSetStartDate}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DesktopDatePicker
              // disabled
              label='End Date'
              inputFormat='MM/dd/yyyy'
              value={endDate}
              PopperProps={{
                disablePortal: true,
              }}
              onChange={handleSetEndDate}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
        </LocalizationProvider>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 4 }}>
        {filteredCategories
          .filter((c) => {
            if (selectAll.length > 0) {
              return selectAll.includes(c.id);
            } else if (selectedCategory) {
              return selectedCategory === c.id;
            } else {
              return '';
            }
          })
          .map((cat) => {
            return (
              <Grid item xs={12} key={cat.id}>
                <CategoryTypography variant={'h6'}>{cat.title}</CategoryTypography>
                <Grid container spacing={2}>
                  {cat.widgets.map((widget, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      key={widget.id}
                      sx={{ zIndex: selectedWidget === widget.id ? 2 : 0, minHeight: 238 }}
                    >
                      <LineChart
                        widget={widget}
                        startDate={startDate}
                        endDate={endDate}
                        index={index}
                        isSelected={selectedWidget === widget.id}
                        setSelectedWidget={setSelectedWidget}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
};
