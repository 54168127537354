import React, { useEffect, useRef, useState } from 'react';
import { mdiPlaylistEdit } from '@mdi/js';
import Icon from '@mdi/react';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import VisibilityOffTwoToneIcon from '@mui/icons-material/VisibilityOffTwoTone';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Divider, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import DOMPurify from 'dompurify';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getContentUrl,
  useGetCommunityGroupAdmins,
  useGetCommunityGroupMembers,
  useGetGroupAnnouncements,
} from '@juno/client-api';
import {
  CommunityGroup,
  CommunityUser,
  PrivacyEnum,
  SearchContentTypeEnum,
} from '@juno/client-api/model';
import {
  DefaultSearchSortFilter,
  GroupTree,
  JunoRotator,
  SkeletonUserTile,
  UserTile,
} from '@juno/ui';
import { getContentRoute, usePagination, useSearchFilter, useSettings } from '@juno/utils';
import AnnouncementTile from './AnnouncementTile';

interface AboutTabProps {
  siteId: string;
  groupId: string;
  iAmAdmin: boolean;
  iAmMember: boolean;
  thisGroup?: CommunityGroup;
  handleJoinLeave: () => void;
  joinLeaveLoading: boolean;
  buttonText: string;
  hlConfig?: boolean;
}
const AboutTab: React.FC<AboutTabProps> = ({
  siteId,
  groupId,
  iAmAdmin,
  iAmMember,
  thisGroup,
  handleJoinLeave,
  joinLeaveLoading,
  buttonText,
  hlConfig,
}) => {
  const [page, setPage] = useState(0);
  const [sortMembers, setSortMembers] = useState('last_name');
  const navigateRoute = useNavigate();
  const { site, isSmallScreen, user } = useSettings();

  const [debouncedSearch, search, filter, setSearch] = useSearchFilter();

  const {
    data: members,
    isLoading: isLoadingMembers,
    isFetching: isFetchingMembers,
  } = useGetCommunityGroupMembers(
    siteId,
    groupId,
    {
      ...filter,
      limit: 40,
      offset: page,
      order: sortMembers,
      include: 'settings',
    },
    { query: { enabled: !!groupId } },
  );

  const {
    data: admins,
    isLoading: isLoadingAdmins,
    isFetching: isFetchingAdmins,
  } = useGetCommunityGroupAdmins(
    siteId,
    groupId,
    {
      ...filter,
      order: 'last_name',
    },
    { query: { enabled: !!groupId } },
  );

  const { data: groupAnnouncements } = useGetGroupAnnouncements(siteId, groupId, {
    order: '-date_announced',
  });

  const [data, setData, ref, isLoading] = usePagination(
    members,
    isLoadingMembers,
    isFetchingMembers,
    search,
    debouncedSearch,
    setPage,
  );

  const [data2, setData2, ref2, isLoading2] = usePagination(
    admins,
    isLoadingAdmins,
    isFetchingAdmins,
    null,
    null,
    setPage,
  );
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded); // Toggle the expanded state
  };

  const location = useLocation();
  const navigate = useNavigate();
  const goToAnnouncement = async (threadId: string) => {
    const urlResponse = await getContentUrl(
      siteId,
      threadId,
      location.pathname.split('/').pop() || '',
    );
    navigate(`/${site?.slug}/community/groups${urlResponse.url}`);
  };

  const today = new Date();
  const formattedDate = format(today, 'EEEE, MMMM do, yyyy');

  const [isTruncated, setIsTruncated] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      // Check if the content is overflowing
      const isOverflowing =
        contentElement.scrollHeight > contentElement.clientHeight ||
        contentElement.scrollWidth > contentElement.clientWidth;

      setIsTruncated(isOverflowing);
    }
  }, [thisGroup?.body]);

  const theme = useTheme();

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card sx={{ p: 2, pt: 1, boxShadow: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {user?.first_name && user?.last_name && formattedDate && (
                <Typography
                  style={{
                    fontSize: '16px',
                    fontWeight: 600,
                    color: '#525252',
                  }}
                  textAlign={'left'}
                >
                  Hello, {user?.first_name} {user?.last_name}, today is {formattedDate}
                </Typography>
              )}

              {iAmAdmin && !isSmallScreen && (
                <IconButton onClick={() => navigateRoute('edit')}>
                  <Icon
                    path={mdiPlaylistEdit}
                    title='Edit'
                    size={1}
                    horizontal
                    vertical
                    rotate={180}
                    color={theme.palette.primary.main}
                  />
                </IconButton>
              )}
            </Box>
            <Box sx={{ position: 'relative', mb: 0 }}>
              <Typography
                ref={contentRef}
                variant='body1'
                sx={{
                  mb: 0,
                  maxHeight: expanded ? 'none' : '30vh',
                  overflow: 'hidden',
                  WebkitLineClamp: expanded ? 'none' : 6,
                  WebkitBoxOrient: 'vertical',
                  display: '-webkit-box',
                  textOverflow: 'ellipsis',
                  fontSize: '14.4px',
                  color: '#525252',
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(thisGroup?.body ?? '', {
                    ADD_TAGS: ['iframe'],
                    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
                  }),
                }}
              />

              {!expanded && isTruncated && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '60px',
                    background:
                      'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%)',
                  }}
                />
              )}

              {isTruncated && (
                <Box
                  sx={{
                    position: 'absolute',
                    bottom: expanded ? -15 : -8,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    background: 'transparent',
                    paddingRight: 0,
                    paddingBottom: 0,
                  }}
                >
                  <Button
                    variant='text'
                    onClick={() => setExpanded(!expanded)}
                    disableRipple
                    sx={{
                      '&.MuiButtonBase-root:hover': {
                        bgcolor: 'transparent',
                      },
                    }}
                  >
                    {expanded ? 'See Less' : 'See More'}
                  </Button>
                </Box>
              )}
            </Box>

            <Box display={'flex'} justifyContent={'space-between'} style={{ marginTop: 15 }}>
              {thisGroup && thisGroup.allow_join_leave && (
                <LoadingButton
                  variant={iAmMember ? 'outlined' : 'contained'}
                  size='large'
                  sx={{
                    maxHeight: 36,
                    width: '100%',
                    fontWeight: 500,
                    fontSize: '14px',
                    letterSpacing: '0.21px',
                  }}
                  onClick={handleJoinLeave}
                  loading={joinLeaveLoading}
                  disabled={
                    thisGroup === undefined ||
                    (thisGroup?.privacy === PrivacyEnum.secret && !iAmMember)
                  }
                >
                  {buttonText}
                </LoadingButton>
              )}
            </Box>
          </Card>
        </Grid>
        {groupAnnouncements && (
          <Grid item xs={12}>
            <Card sx={{ p: 2, pt: 1, boxShadow: 1 }}>
              <Typography variant={'body1'} fontWeight={'bold'} textAlign={'center'} mb={1}>
                Announcements
              </Typography>
              {groupAnnouncements.map((announcement) => (
                <Box
                  position={'relative'}
                  key={announcement.id}
                  sx={{ mb: 1, cursor: 'pointer' }}
                  onClick={() => goToAnnouncement(announcement.id)}
                >
                  <AnnouncementTile
                    announcement={announcement}
                    goToAnnouncement={goToAnnouncement}
                  />
                  <Divider />
                </Box>
              ))}
            </Card>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Card sx={{ boxShadow: 1, p: 2 }}>
                    <Stack direction='row' mb={1} mt={1}>
                      <PeopleAltTwoToneIcon />{' '}
                      <Typography ml={2}>{thisGroup?.members_count} Members</Typography>
                    </Stack>
                    <Stack direction='row' mb={1}>
                      {thisGroup?.privacy === PrivacyEnum.public && <PublicTwoToneIcon />}
                      {thisGroup?.privacy === PrivacyEnum.private && <LockOpenTwoToneIcon />}
                      {thisGroup?.privacy === PrivacyEnum.secret && <VisibilityOffTwoToneIcon />}
                      <Typography ml={2} sx={{ textTransform: 'capitalize' }}>
                        Privacy: {thisGroup?.privacy}
                      </Typography>
                    </Stack>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card sx={{ boxShadow: 1, p: 2, pb: 3 }}>
                    <Typography variant={'body1'} fontWeight={'bold'} textAlign={'center'}>
                      Meet Your Admins
                    </Typography>
                    <JunoRotator
                      onSelect={(item) =>
                        navigateRoute(
                          getContentRoute(
                            site?.slug || '',
                            item?.id || '',
                            SearchContentTypeEnum.user,
                          ),
                        )
                      }
                      settings={{
                        title: '',
                        slideHeight: 90,
                        slideWidth: 90,
                        arrows: false,
                        dots: true,
                        fullWidth: true,
                        slideVariant: 'circular',
                        showPauseButton: false,
                      }}
                      slides={
                        data2?.map((user: CommunityUser) => {
                          return {
                            id: user.id,
                            slug: user.id,
                            name: `${user.first_name} ${user.last_name}`,
                            description: user.email,
                            image: user.avatar || user.icon || '',
                          };
                        }) || []
                      }
                    />
                  </Card>
                </Grid>
                {hlConfig && (
                  <Grid item xs={12}>
                    <Card sx={{ boxShadow: 1, p: 2 }}>
                      <Typography variant={'body1'} fontWeight={'bold'} textAlign={'center'}>
                        Group Hierarchy
                      </Typography>
                      <GroupTree groupId={thisGroup?.id || ''} />
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ p: 2, pb: 1, boxShadow: 1 }}>
                <Typography variant={'body1'} fontWeight={'bold'} textAlign={'center'} mb={1}>
                  Member Directory
                </Typography>
                <DefaultSearchSortFilter
                  buttonDisabled={true}
                  showButton={false}
                  buttonText={''}
                  onClickButton={() => {
                    // TODO: Add new member from front end?
                  }}
                  setSearch={(value) => setSearch(value)}
                  showFilter={false}
                  showSort={true}
                  sort={sortMembers}
                  setSort={(value) => {
                    setPage(0);
                    setData([]);
                    setSortMembers(value);
                  }}
                  sortOptions={[
                    { value: 'first_name', label: 'First Name' },
                    { value: 'last_name', label: 'Last Name' },
                  ]}
                />
                <Box pt={2} height={450} overflow={'auto'}>
                  <Divider sx={{ mb: 1 }} />
                  <Box mb={2}>
                    {data?.map((member, index) => {
                      return (
                        <Box key={`usertile-${index}`}>
                          <UserTile user={member} company={true} />
                          {index === data.length - 1 && (
                            <Box ref={ref}>{isLoading && <SkeletonUserTile />}</Box>
                          )}
                        </Box>
                      );
                    })}
                    {!isLoading && data?.length === 0 && (
                      <Typography pl={1}>No members found</Typography>
                    )}
                    {isLoading && data?.length === 0 && <SkeletonUserTile />}
                  </Box>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default AboutTab;
