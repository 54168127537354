import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { JunoSkeleton } from '@juno/ui';
import { useSettings } from '@juno/utils';

const Callback: React.FC = () => {
  const navigate = useNavigate();
  const { site } = useSettings();

  useEffect(() => {
    if (site) {
      navigate(`/${site?.slug || 'main'}/home`);
    }
  }, [site, navigate]);

  return <JunoSkeleton />;
};

export default Callback;
