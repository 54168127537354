/**
 * Waits for time.
 * @param ms milliseconds to wait.  Defaults to 250 same as slowMo
 */
export async function timeout(ms = 250): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function secondsToHms(seconds: number) {
  seconds = Number(seconds);
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor((seconds % 3600) % 60);

  return {
    hours: h,
    minutes: m,
    seconds: s,
  };
}

export function formatHmsShort(seconds: number) {
  const d = secondsToHms(seconds);
  const h = String(d.hours).padStart(2, '0');
  const m = String(d.minutes).padStart(2, '0');
  const s = String(d.seconds).padStart(2, '0');
  return `${h}:${m}:${s}`;
}

export function formatHmsLong(seconds: number) {
  const d = secondsToHms(seconds);
  const h = String(d.hours);
  const m = String(d.minutes);
  const s = String(d.seconds);
  return `${h} hours ${m} minutes ${s} seconds`;
}

export function formatHmsLongAbbreviated(seconds: number) {
  const d = secondsToHms(seconds);
  const h = String(d.hours);
  const m = String(d.minutes);
  const s = String(d.seconds);
  return `${h}hr ${m}min ${s}sec`;
}

export function secondsToHmsNumber(seconds: number) {
  // Convert seconds to hhmmss (like you would enter into a microwave)
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  const hhmmss = [h, m, s]
    .map((v) => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join('');
  return Number(hhmmss) || 0;
}
