import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  getGetCommunityGroupsQueryKey,
  useCreateCommunityGroup,
  useGetCommunityGroups,
} from '@juno/client-api';
import { Site } from '@juno/client-api/model';
import { EMPTY_GROUP_URL } from '@juno/constants';
import { GridV2 } from '@juno/modules';
import { Container, DefaultSearchSortFilter, DialogAriaWrapper } from '@juno/ui';
import { slugify, usePagesSearchFilter } from '@juno/utils';

interface GroupsAdminProps {
  site?: Site;
}
const GroupsAdmin: React.FC<GroupsAdminProps> = ({ site }) => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupSlug, setNewGroupSlug] = useState('');
  const [sort, setSort] = useState('title');
  const [, , searchFilter, setSearch] = usePagesSearchFilter();
  const { data: groups, isFetching: isLoading } = useGetCommunityGroups(site?.id || '', {
    filter: { ...searchFilter },
    order: sort,
  });
  const createGroup = useCreateCommunityGroup();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleNameChange = (name: string) => {
    setNewGroupName(name);
    setNewGroupSlug(slugify(name));
  };

  const handleSlugChange = (slug: string) => {
    setNewGroupSlug(slugify(slug));
  };

  const handleOpenCreateGroup = (e: any) => {
    e.stopPropagation();
    setCreateDialogOpen(true);
  };

  const handleCreateGroup = async () => {
    setIsSaving(true);
    try {
      await createGroup.mutateAsync({
        siteId: site?.id || '',
        data: {
          title: newGroupName,
          slug: newGroupSlug,
          id: '',
          site_id: site?.id || '',
          tags: [],
        },
      });
      await queryClient.invalidateQueries(getGetCommunityGroupsQueryKey(site?.id || ''));
      setIsSaving(false);
      setNewGroupName('');
      setNewGroupSlug('');
      setCreateDialogOpen(false);
      navigate(`/${site?.slug}/admin/community/groups/${newGroupSlug}`);
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  const cancelCreatePage = () => {
    setNewGroupName('');
    setNewGroupSlug('');
    setCreateDialogOpen(false);
  };

  const gridData = groups?.map((group) => ({
    id: group.id,
    title: group.title || '',
    abstract: group.preview_text || '',
    icon: group.list_image || EMPTY_GROUP_URL,
    slug: group.slug || '',
  }));

  return (
    <Container>
      <Card sx={{ p: 2, mb: 4 }}>
        <Typography variant='h5'>Groups</Typography>
        <Divider sx={{ mt: 2, mb: 4 }} />
        <DefaultSearchSortFilter
          buttonDisabled={false}
          buttonText={'New Group'}
          onClickButton={() => setCreateDialogOpen(true)}
          setSearch={(value: string) => setSearch(value)}
          setSort={setSort}
          sort={sort}
          showFilter={false}
          sortOptions={[
            { value: 'title', label: 'Alphabetical' },
            { value: '-members_count', label: 'Number of Members' },
          ]}
        />
        <Stack direction='row' flexWrap={'wrap'} gap={2}>
          <GridV2 isLoading={isLoading} items={gridData || []} type='group' isAdmin={true} />
          <DialogAriaWrapper
            open={createDialogOpen}
            onClose={() => cancelCreatePage()}
            id='create_new_page'
          >
            <Box>
              <Typography variant='h6' sx={{ mt: 3, ml: 3, mb: 2 }}>
                Create a new group
              </Typography>
            </Box>
            <DialogContent>
              <Box>
                <TextField
                  name='name'
                  label='name'
                  value={newGroupName}
                  onChange={(e) => handleNameChange(e.target.value)}
                  sx={{ mr: 1 }}
                />
                <TextField
                  name='slug'
                  label='slug'
                  value={newGroupSlug}
                  onChange={(e) => handleSlugChange(e.target.value)}
                  sx={{ mr: 1 }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => cancelCreatePage()}>Cancel</Button>
              <LoadingButton
                loading={isSaving}
                onClick={() => handleCreateGroup()}
                variant='contained'
              >
                Create
              </LoadingButton>
            </DialogActions>
          </DialogAriaWrapper>
        </Stack>
      </Card>
    </Container>
  );
};

export default GroupsAdmin;

export const SkeletonPagesTile: React.FC = () => {
  return (
    <Grid item xs={12} md={3} lg={2}>
      <Skeleton variant='rectangular' height={160} width={235} sx={{ borderRadius: 2 }} />
      <Skeleton variant='text' height={20} sx={{ mt: 1, width: '50%' }} />
      <Skeleton variant='text' height={16} sx={{ width: '40%' }} />
    </Grid>
  );
};
