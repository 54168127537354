import React, { useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ConfirmLeaveDialog from '../ConfirmLeaveDialog';

interface BackLinkProps {
  text: string;
  hasChanged: boolean;
  backPath?: string;
}
const BackLink: React.FC<BackLinkProps> = ({ text, hasChanged, backPath }) => {
  // Variables
  const [openConfirmLeave, setOpenConfirmLeave] = useState<boolean>(false);
  const navigate = useNavigate();

  // Functions
  const handleConfirm = () => {
    setOpenConfirmLeave(false);
    if (backPath) {
      navigate(backPath);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <IconButton
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },
          fontSize: '18px',
          ml: 1,
        }}
        onClick={() => {
          if (hasChanged) {
            setOpenConfirmLeave(true);
          } else {
            navigate(-1);
          }
        }}
      >
        <ArrowBackIcon sx={{ mr: 1 }} /> {text}
      </IconButton>
      {openConfirmLeave && (
        <ConfirmLeaveDialog
          open={true}
          onCancel={() => setOpenConfirmLeave(false)}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};
export default BackLink;
