import React, { useEffect, useRef, useState } from 'react';
import { Button, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useInView } from 'react-intersection-observer';
import { FeatureConfigTypeEnum } from '@juno/client-api/model';
import { DialogAriaWrapper } from '@juno/ui';
import { useSettings } from '@juno/utils';

interface TermsOfServiceDialogProps {
  onAccept: () => void;
}
const TermsOfServiceDialog: React.FC<TermsOfServiceDialogProps> = ({ onAccept }) => {
  const { configs, platform } = useSettings();
  const dialogContentRef = useRef<HTMLDivElement>(null);
  const [finishedReading, setFinishedReading] = useState(false);
  const [declined, setDeclined] = useState(false);
  const [declinedCount, setDeclinedCount] = useState(0);
  const termsOfService = configs?.find((c) => c.type === FeatureConfigTypeEnum.terms_of_service);
  const { ref: inViewRef, inView } = useInView({
    threshold: 1.0,
  });

  useEffect(() => {
    if (inView) {
      setFinishedReading(true);
    }
  }, [inView]);

  return (
    <DialogAriaWrapper open={true} id='terms_of_service_dialog' maxWidth='sm'>
      <DialogTitle>{termsOfService?.config?.title as string}</DialogTitle>
      <DialogContent ref={dialogContentRef} sx={{ maxHeight: '80vh', overflowY: 'auto' }}>
        {declined ? (
          <Typography variant='h6'>You must accept the Terms of Use to access the site.</Typography>
        ) : (
          <>
            <Typography
              variant='body1'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize((termsOfService?.config?.body as string) || '', {
                  ADD_TAGS: ['iframe'],
                  ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
                }),
              }}
            />
            <Typography ref={inViewRef} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {!declined && (
              <Button disabled={!finishedReading} onClick={onAccept} variant='contained'>
                Accept
              </Button>
            )}
          </Grid>
          <Grid item xs={6} sx={{ justifyContent: 'flex-end' }} display={'flex'}>
            {declined ? (
              <Button onClick={() => setDeclined(false)} variant='outlined'>
                Go Back
              </Button>
            ) : (
              <Button
                variant='outlined'
                disabled={!finishedReading}
                onClick={() => {
                  setDeclinedCount(declinedCount + 1);
                  if (declinedCount >= 2) {
                    console.warn('User has declined 3 times, logging out');

                    localStorage.clear();
                    const current_location = window.location.origin + '/main/callback';
                    // Redirect to FusionAuth logout
                    window.location.href = `${
                      process.env.NX_FUSIONAUTH_URL || 'UNSET_NX_FUSIONAUTH_URL'
                    }/oauth2/logout?post_logout_redirect_uri=${current_location}&client_id=${
                      platform?.id
                    }`;
                  }
                  setDeclined(true);
                }}
              >
                Decline
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </DialogAriaWrapper>
  );
};
export default TermsOfServiceDialog;
