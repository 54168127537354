import * as Yup from 'yup';
import { CertificateTemplate } from '@juno/client-api/model';
import { CertificateType } from './enums';
import { html } from './html';

export const validationSchema = Yup.object().shape({
  name: Yup.string().max(67, 'Max 67 characters').required('Required'),
  html_content: Yup.string().required('Required'),
  metadata: Yup.object().shape({
    description: Yup.string().max(78, 'Max 78 characters'),
  }),
});

export const valueMaker = (siteId: string, certificate?: CertificateTemplate) => ({
  id: certificate?.id || '',
  name: certificate?.name || '',
  site_id: siteId || '',
  metadata: {
    type: certificate?.metadata?.['type'] || CertificateType.DEFAULT,
    description: (certificate?.metadata?.['description'] as string) || '',
    adminName: certificate?.metadata?.['adminName'] || '',
    adminRole: certificate?.metadata?.['adminRole'] || '',
    backgroundImage:
      certificate?.metadata?.['backgroundImage'] ||
      'https://res.cloudinary.com/dxx7aeiow/image/upload/v1675730575/ukk6wfidm9j1d0kcutgd.jpg',
    displayPlatformName:
      typeof certificate?.metadata?.['displayPlatformName'] === 'boolean'
        ? certificate?.metadata?.['displayPlatformName']
        : true,
    // displayLetterGrade:
    //   typeof certificate?.metadata?.['displayLetterGrade'] === 'boolean'
    //     ? certificate?.metadata?.['displayLetterGrade']
    //     : false,
    displayCreditsEarned:
      typeof certificate?.metadata?.['displayCreditsEarned'] === 'boolean'
        ? certificate?.metadata?.['displayCreditsEarned']
        : true,
    displayCertificateID:
      typeof certificate?.metadata?.['displayCertificateID'] === 'boolean'
        ? certificate?.metadata?.['displayCertificateID']
        : true,
    default:
      typeof certificate?.metadata?.['default'] === 'boolean'
        ? certificate?.metadata?.['default']
        : false,
    hideBackgroundImage:
      typeof certificate?.metadata?.['hideBackgroundImage'] === 'boolean'
        ? certificate?.metadata?.['hideBackgroundImage']
        : false,
    hasImageOnly:
      typeof certificate?.metadata?.['hasImageOnly'] === 'boolean'
        ? certificate?.metadata?.['hasImageOnly']
        : false,
    hideRibbon:
      typeof certificate?.metadata?.['hideRibbon'] === 'boolean'
        ? certificate?.metadata?.['hideRibbon']
        : false,
  },
  html_content: certificate?.html_content || html(certificate),
});
