import React, { Dispatch, SetStateAction, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Editor } from '@tinymce/tinymce-react';
import { Reorder } from 'framer-motion';
import { useGetQuestionAnswers } from '@juno/client-api';
import { Answer, Question } from '@juno/client-api/model';
import { Question as QuestionUtils } from '@juno/client-api/utils';
import { TINY_MCE_DEFAULT_CONFIG } from '@juno/constants';
import { uploadTinyMceImageCloudinary } from '@juno/utils';
import JunoQuestion from '../../../client/JunoQuestion';
import {
  AddAnswerButton,
  AnswerBox,
  Container,
  CorrectCheckbox,
  DeleteGridItem,
  DragIndicatorContainer,
  PreviewCard,
  SaveButton,
} from './styles';

export const TEMP_ID_PREFIX = 'TEMP-';
interface QuestionBuilderProps {
  courseId: string;
  lessonId: string;
  siteId: string;
  questionState: Question;
  setQuestionState: Dispatch<SetStateAction<Question>>;
}

interface QuestionMetaData {
  min: number;
  max: number;
  increment: number;
}

export default function QuestionBuilder({
  courseId,
  lessonId,
  siteId,
  questionState,
  setQuestionState,
}: QuestionBuilderProps): React.ReactElement {
  const QUESTION_TYPES = QuestionUtils.QUESTION_TYPES;
  const { data: answerData } = useGetQuestionAnswers(
    siteId,
    courseId,
    lessonId,
    questionState.id || '',
    { order: 'order' },
  );

  useEffect(() => {
    setQuestionState((old) => ({ ...old, answers: answerData || [] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answerData]);

  useEffect(() => {
    // jam some default values into the rating meta in case Q doesn't have it
    setQuestionState((old) => {
      return {
        ...old,
        metadata: {
          increment: 1,
          min: 0,
          max: 10,
          weight: 1,
          showVoting: 0,
          ...old.metadata,
        },
      };
    });
  }, []);

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>,
  ) => {
    const target = e.target as HTMLInputElement;

    setQuestionState((old) => {
      return {
        ...old,
        [target.name]: target.value,
      };
    });
  };

  const handleAnswerChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>,
    answerId: string,
  ) => {
    const target = e.target as HTMLInputElement;
    let value: string | number = target.value;
    if (target.name === 'points') {
      value = target.checked ? 1 : 0;
    } else if (value.length > 250) {
      return;
    }
    const newAnswers =
      questionState?.answers?.map((answer, index) => {
        if (answer.id === answerId) {
          return { ...answer, [target.name]: value };
        } else return { ...answer };
      }) || [];
    setQuestionState((old) => ({ ...old, answers: newAnswers }));
  };

  const handleScoreChange = (val: number) => {
    const score = isNaN(val) ? 0 : val;
    if (score >= 0) {
      setQuestionState((old) => ({ ...old, metadata: { ...old.metadata, weight: score } }));
    }
  };

  const handleMetaChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>,
  ) => {
    const target = e.target as HTMLInputElement;
    let value = parseInt(target.value);

    if (target.name === 'increment') {
      if (value < 1) {
        return;
      }
      if (
        typeof questionState.metadata?.min === 'number' &&
        typeof questionState.metadata?.max === 'number' &&
        (questionState.metadata?.max - questionState.metadata?.min) / value > 10
      ) {
        value = Math.ceil((questionState.metadata?.max - questionState.metadata?.min) / 10);
      }
    }
    if (target.name === 'min' && value < 1) {
      return;
    }
    if (target.name === 'max') {
      if (
        typeof questionState.metadata?.min === 'number' &&
        typeof questionState.metadata?.increment === 'number' &&
        value / questionState.metadata?.increment > 10
      ) {
        value = Math.floor(questionState.metadata.increment * 10);
      }
      if (
        value <= (typeof questionState.metadata?.min === 'number' ? questionState.metadata.min : 10)
      ) {
        return;
      }
    }
    if (target.name === 'showVoting') {
      value = target.checked ? 1 : 0;
    }

    setQuestionState((old) => {
      const newQ = {
        ...old,
        metadata: { ...old.metadata, [target.name]: value },
      };

      return newQ;
    });
  };

  const addAnswer = () => {
    setQuestionState((old) => {
      const newAnswers = old.answers || [];
      newAnswers.push({
        id: `${TEMP_ID_PREFIX}${Date.now()}`,
        title: '',
        points: 0,
        question_id: '',
        order: newAnswers.length,
      });
      return { ...old, answers: newAnswers };
    });
  };

  const deleteAnswer = (answerId: string) => {
    setQuestionState((old) => {
      const newAnswers = old.answers?.filter((answer) => answer.id !== answerId) || [];
      return { ...old, answers: newAnswers.map((answer, idx) => ({ ...answer, order: idx })) };
    });
  };

  const onReorder = (newOrder: Answer[]) => {
    const answers = newOrder.map((answer, order) => ({ ...answer, order })) || [];
    setQuestionState((old) => ({ ...old, answers }));
  };

  return (
    <Container container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id='title'
          label="Question Title (Learners won't see this)"
          variant='outlined'
          name='name'
          value={questionState.name}
          fullWidth
          onChange={handleChange}
          inputProps={{ maxLength: 90 }}
        />
      </Grid>
      <Grid item xs={12} sx={{ marginBottom: 3 }}>
        <Typography sx={{ mb: 1, fontSize: 14 }}>Description</Typography>
        <Editor
          apiKey={process.env.NX_TINY_MCE_API_KEY}
          init={{
            ...TINY_MCE_DEFAULT_CONFIG,
            images_upload_handler: uploadTinyMceImageCloudinary,
            setup: (editor) => {
              editor.setProgressState(true);
            },
          }}
          value={questionState.question}
          onEditorChange={(value: string) => {
            handleChange({ target: { name: 'question', value } } as any);
          }}
        />
      </Grid>
      <Grid
        item
        xs={questionState.type !== QUESTION_TYPES.POLL.value ? 9 : 7}
        md={questionState.type !== QUESTION_TYPES.POLL.value ? 10 : 8}
      >
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>Question Type</InputLabel>
          <Select
            id='question_type'
            name='type'
            value={questionState.type}
            label='Question Type'
            onChange={handleChange}
          >
            {Object.values(QUESTION_TYPES).map((entry, index) => (
              <MenuItem value={entry.value} key={`questiontype_${index}`}>
                {entry.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {questionState.type === QUESTION_TYPES.POLL.value && (
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Switch
                name='showVoting'
                checked={questionState.metadata?.showVoting ? true : false}
                onChange={handleMetaChange}
              />
            }
            label={<Typography sx={{ fontSize: 12 }}>Show Voting Results</Typography>}
          />
        </Grid>
      )}
      <Grid item xs={3} md={2}>
        <TextField
          id='weight'
          label='Score'
          variant='outlined'
          name='weight'
          type='number'
          value={
            typeof questionState?.metadata?.weight === 'number' ? questionState.metadata.weight : 1
          }
          fullWidth
          onChange={(e) => handleScoreChange(parseInt(e.target.value))}
        />
      </Grid>
      {(questionState.type === QUESTION_TYPES.MULTIPLE_CHOICE.value ||
        questionState.type === QUESTION_TYPES.POLL.value ||
        questionState.type === QUESTION_TYPES.RANKING.value) && (
        <Grid item xs={12}>
          <Reorder.Group
            axis='y'
            values={questionState?.answers || []}
            as='div'
            onReorder={(newOrder) => onReorder(newOrder)}
            style={{ listStyleType: 'none' }}
          >
            {questionState?.answers?.map((answer, index) => {
              return (
                <Reorder.Item key={`question_answer_form_${answer.id}_`} value={answer}>
                  <AnswerBox>
                    <Grid container>
                      <Grid item xs={1}>
                        <DragIndicatorContainer>
                          <DragIndicatorIcon />
                        </DragIndicatorContainer>
                      </Grid>
                      <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                          label='Answer Text'
                          variant='outlined'
                          name='title'
                          size='small'
                          value={answer.title}
                          fullWidth
                          onChange={(e) => handleAnswerChange(e, answer.id)}
                        />
                      </Grid>

                      <Grid item xs={2}>
                        {questionState.type === QUESTION_TYPES.MULTIPLE_CHOICE.value && (
                          <FormGroup>
                            <FormControlLabel
                              sx={{
                                [`	.MuiFormControlLabel-label`]: { fontSize: 14, fontWeight: 500 },
                              }}
                              control={
                                <CorrectCheckbox
                                  name='points'
                                  checked={answer.points === 0 ? false : true}
                                  onChange={(e) => handleAnswerChange(e, answer.id)}
                                  inputProps={{ 'aria-label': 'Correct' }}
                                />
                              }
                              label='Correct'
                              labelPlacement='top'
                            />
                          </FormGroup>
                        )}
                      </Grid>

                      <DeleteGridItem item xs={1}>
                        <IconButton aria-label='delete' onClick={() => deleteAnswer(answer.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </DeleteGridItem>
                    </Grid>
                  </AnswerBox>
                </Reorder.Item>
              );
            })}
          </Reorder.Group>
        </Grid>
      )}
      <Grid item xs={12}>
        {(questionState.type === QUESTION_TYPES.MULTIPLE_CHOICE.value ||
          questionState.type === QUESTION_TYPES.POLL.value ||
          questionState.type === QUESTION_TYPES.RANKING.value) && (
          <AddAnswerButton onClick={addAnswer} variant='contained' color='primary' fullWidth>
            Add Answer
          </AddAnswerButton>
        )}

        {questionState.type === QUESTION_TYPES.RATING.value && (
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <TextField
                label='Min'
                variant='outlined'
                name='min'
                placeholder={'0'}
                value={questionState?.metadata?.min}
                type='number'
                disabled
                fullWidth
                onChange={handleMetaChange}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label='Max'
                variant='outlined'
                name='max'
                placeholder={'10'}
                value={questionState?.metadata?.max}
                type='number'
                fullWidth
                onChange={handleMetaChange}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label='Increment'
                variant='outlined'
                name='increment'
                placeholder={'1'}
                value={questionState?.metadata?.increment}
                type='number'
                fullWidth
                onChange={handleMetaChange}
              />
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
        )}
      </Grid>

      <Typography variant='h6' gutterBottom sx={{ width: '100%', fontSize: '1.25rem', mt: 10 }}>
        Question Preview
      </Typography>
      <Grid item xs={12} sx={{ mb: 4 }}>
        <PreviewCard>
          <JunoQuestion question={questionState} displayOnly={true} />
        </PreviewCard>
      </Grid>
    </Container>
  );
}
