import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';
import PropTypes from 'prop-types';

export const MessageCard = ({ header, children, error = false, hideBack = false, onBack }) => (
  <Card className={error ? 'error' : ''}>
    {header && <CardHeader>{header}</CardHeader>}
    {children && <CardContent>{children}</CardContent>}
    {!hideBack && (
      <Box>
        {onBack ? (
          <Button onClick={() => onBack()}>Go back</Button>
        ) : (
          <Button onClick={() => window.location.reload()}>Go back</Button>
        )}
      </Box>
    )}
  </Card>
);

MessageCard.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.bool,
  onBack: PropTypes.func,
  hideBack: PropTypes.bool,
};

export default MessageCard;
