import React from 'react';
import { Box, Skeleton } from '@mui/material';

export const CreateNewConversationSkeleton = () => {
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      {/* Conversations list Skeleton */}
      <Box>
        {Array.from(new Array(10)).map((_, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', px: 2, py: 1 }}>
            {/* Avatar Skeleton */}
            <Skeleton variant='circular' width={35} height={35} sx={{ marginRight: 2 }} />

            {/* Text Skeleton */}
            <Box sx={{ flex: 1 }}>
              <Skeleton variant='text' width='95%' />
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CreateNewConversationSkeleton;
