import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Typography,
} from '@mui/material';
import { useGetAllEmailAnalyticsRecords } from '@juno/client-api';
import {
  EmailRecord as EmailRecordModel,
  Site as SiteModel,
  TypeBd0Enum as campaignType,
} from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import {
  EmailCampaignType,
  EmailCampaignTypeMap,
  initialAnalyticsTypeObject,
  organizeAnalyticsTypeRecords,
} from '../utils/utils';
import AnalyticsTable from './AnalyticsTable';

const EmailAnalytics: React.FC = () => {
  // Variables
  const { site } = useSettings();
  const [selectionMade, setSelectionMade] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<campaignType | null>(null);
  const [emailRecordsTypeGroups, setEmailRecordsTypeGroups] = useState<
    Record<campaignType, EmailRecordModel[]>
  >(initialAnalyticsTypeObject());

  // Queries and mutations
  const { data: rows, isLoading: recordsLoading } = useGetAllEmailAnalyticsRecords(
    site?.id || '',
    {
      order: '-date_sent',
    },
    { query: { enabled: !!site?.id } },
  );

  // Functions

  // Organize the EmailRecord objects into EmailCampaign type-specific groups
  useEffect(() => {
    if (rows && rows.length > 0) {
      setEmailRecordsTypeGroups(organizeAnalyticsTypeRecords(initialAnalyticsTypeObject(), rows));
    }
  }, [rows]);

  if (recordsLoading)
    return (
      <Card variant='elevation' sx={{ p: 4 }}>
        <Skeleton variant='rectangular' height={50} width={320} sx={{ ml: 2, mb: 4 }}></Skeleton>
        <CardContent>
          <Skeleton variant='rectangular' height={30} width={'80%'} sx={{ mb: 3 }}></Skeleton>
          <Skeleton variant='rectangular' height={60} width={250}></Skeleton>
        </CardContent>
      </Card>
    );

  return (
    <Card variant='elevation' sx={{ p: 4 }}>
      <Typography color='primary.main' variant='h5'>
        {site?.slug || ''} email campaign history
      </Typography>
      <Typography sx={{ mt: 4, mb: 3, ml: 1 }}>
        Choose a campaign type to view corresponding email records or "ALL" to see all site email
        records
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'left' }}>
        <FormControl sx={{ width: '300px' }}>
          <InputLabel>Campaign Type</InputLabel>
          <Select
            id='campaignType'
            label='Campaign Type'
            value={selectedType || undefined}
            onChange={(event: SelectChangeEvent) => {
              if (event.target.value === 'ALL') {
                setSelectedType(null);
                setSelectionMade(true);
              } else {
                setSelectionMade(true);
                setSelectedType(event.target.value as campaignType);
              }
            }}
          >
            <MenuItem disabled value=''>
              Select a campaign type
            </MenuItem>
            <MenuItem value='ALL' sx={{ height: '75px' }}>
              ALL
            </MenuItem>
            {Object.entries(EmailCampaignTypeMap).map(([key, value], index: number) => (
              <MenuItem key={index} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {selectionMade && rows && (
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 4 }}>
          <AnalyticsTable
            emailCampaignType={selectedType ? selectedType : undefined}
            tableRows={selectedType ? emailRecordsTypeGroups[selectedType] : rows}
          />
        </Box>
      )}
    </Card>
  );
};

export default EmailAnalytics;
