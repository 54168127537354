import React, { ChangeEvent, FocusEventHandler } from 'react';
import { DialogContent, DialogContentText, Typography } from '@mui/material';
import { Course, Lesson, LessonPart, Question as QuestionModel, Tag } from '@juno/client-api/model';
import { Question } from '@juno/client-api/utils';
import {
  ImportArrayModel,
  fixDuplicateKeyCounts,
  getColumnDefinitionsForTable,
  switchTypeAndReturnShapedValue,
} from '../../../utils';
import { ImporterTypes } from '../../../utils/enums';
import { validateValue } from '../../../utils/validations';
import { ImportAutocompleteModel } from '../ImportAutocomplete';
import ImportWizardField from '../ImportWizardField';

interface ImportDialogContentProps {
  rowCount: number;
  values: any;
  errors: any;
  bucket: string;
  courseList: Course[];
  siteTags: Tag[];
  lessonList: Lesson[];
  questionList: QuestionModel[];
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
}
const ImportDialogContent: React.FC<ImportDialogContentProps> = ({ ...props }) => {
  const {
    rowCount,
    values: inValues,
    bucket,
    courseList,
    siteTags,
    lessonList,
    questionList,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = props;

  if (!inValues?.rows) {
    return null;
  }

  // This method returns options to the autocomplete field in its expected format, depending on the bucket
  const getAutocompleteOptions = (bucket: string): ImportAutocompleteModel[] => {
    let sortedOptions: ImportAutocompleteModel[] = [];
    switch (bucket) {
      case ImporterTypes.QUESTIONS:
        sortedOptions = Object.keys(Question.QUESTION_TYPES).map((option) => {
          return { value: option, id: option ?? Date.now(), is_valid: true };
        });
        break;
      case ImporterTypes.QUESTION_ANSWERS:
        {
          // Get all question titles and ids out of lesson->parts->question
          const questions: ImportAutocompleteModel[] = [];
          lessonList?.forEach((lesson: Lesson) => {
            lesson.parts?.forEach((part: LessonPart) => {
              part?.question?.forEach((question: QuestionModel) => {
                questions.push({
                  id: question.id,
                  value: question.name,
                  is_valid: true,
                  name: question.name,
                });
              });
            });
          });
          sortedOptions = questions;
        }
        break;
      case ImporterTypes.LESSONS:
      case ImporterTypes.COURSE_RESOURCES:
        sortedOptions = courseList.map((option: Course) => {
          return { value: option.slug, id: option.id ?? Date.now(), is_valid: true };
        });
        break;
      case ImporterTypes.LESSON_PARTS:
        sortedOptions = lessonList.map((option: Lesson) => {
          return { value: option.slug, id: option.id ?? Date.now(), is_valid: true };
        });
        break;
      case ImporterTypes.COURSES:
        sortedOptions = siteTags.map((option: Tag) => {
          return { value: option.value, id: option.id ?? Date.now(), is_valid: true };
        });
        break;
      default:
        sortedOptions = [];
    }
    sortedOptions = sortedOptions.sort((a, b) => a.value.localeCompare(b.value));
    return fixDuplicateKeyCounts(sortedOptions);
  };

  return (
    <DialogContent>
      {rowCount === inValues.rows.length ? (
        <DialogContentText
          sx={{
            mt: 2,
            mb: 2,
            ml: 10,
            mr: 10,
            minHeight: '50vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant='caption'>
            You've successfully completed your data validation.
          </Typography>
          <Typography variant='caption'>
            After exiting the wizard, you can publish your data!
          </Typography>
        </DialogContentText>
      ) : (
        <>
          {inValues.rows[rowCount] &&
            Object.keys(inValues.rows[rowCount])?.map((key) => {
              const thisRow = inValues?.rows[rowCount] as ImportArrayModel;
              const thisItem = inValues?.rows[rowCount][key];
              const thisKeyDefinition = getColumnDefinitionsForTable(bucket).filter(
                (bKey) => bKey.field == key,
              )[0];
              if (!thisKeyDefinition) {
                return null;
              }
              return (
                <ImportWizardField
                  key={`rows.${rowCount}.${key}`}
                  label={thisKeyDefinition.headerName}
                  name={`rows.${rowCount}.${key}`}
                  type={thisKeyDefinition.type}
                  getAutoCompleteOptions={() => {
                    return getAutocompleteOptions(bucket);
                  }}
                  onChangeAutoComplete={(_event: ChangeEvent<HTMLInputElement>, values: any) => {
                    const toValidate =
                      values.length > 0
                        ? values
                            .filter((v: any) => v.value || v.dom_value)
                            .map((v: any) => v.value || v.dom_value)
                        : [];
                    const innerValues = switchTypeAndReturnShapedValue(toValidate, key, bucket, {
                      courseList,
                      siteTags,
                      lessonList,
                      questionList,
                    });
                    const mappedObj = Object.keys(thisRow).map((k) => {
                      if (Array.isArray(thisRow[k])) {
                        return { [k]: thisRow[k] };
                      } else {
                        return { [k]: thisRow[k].dom_value };
                      }
                    });
                    const reduced = mappedObj.reduce(
                      (obj, item) => Object.assign(obj, { ...item }),
                      {},
                    );
                    setFieldTouched(`rows.${rowCount}.${key}`, true);
                    const newValues: ImportArrayModel[] = [];
                    if (innerValues.length > 0) {
                      innerValues.map((val: ImportArrayModel) => {
                        const newRow = validateValue(key, val, bucket, reduced);
                        if (newRow.is_valid) {
                          newValues.push(newRow);
                        } else {
                          setFieldError(`rows.${rowCount}.${key}`, newRow.errorMessage);
                        }
                      });
                    } else {
                      const newRow = validateValue(key, innerValues[0], bucket, reduced);
                      newValues.push(newRow);
                      if (!newRow.is_valid) {
                        setFieldError(`rows.${rowCount}.${key}`, newRow.errorMessage);
                      }
                    }
                    setFieldValue(`rows.${rowCount}.${key}`, newValues);
                  }}
                  onBlur={(
                    _event: FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined,
                  ) => {
                    setFieldTouched(`rows.${rowCount}.${key}`, true);
                  }}
                  onChange={(event: ChangeEvent<HTMLInputElement>, checkbox?: boolean) => {
                    const val = checkbox ? event.target.checked : event.target.value;
                    const innerValues = switchTypeAndReturnShapedValue(val, key, bucket, {
                      courseList,
                      siteTags,
                      lessonList,
                      questionList,
                    });
                    // logic to get the row into its original shape for context
                    const mappedObj = Object.keys(thisRow).map((k) => {
                      if (Array.isArray(thisRow[k])) {
                        return { [k]: thisRow[k] };
                      } else {
                        return { [k]: thisRow[k].dom_value };
                      }
                    });
                    const reduced = mappedObj.reduce(
                      (obj, item) => Object.assign(obj, { ...item }),
                      {},
                    );

                    const newRow = validateValue(key, innerValues[0], bucket, reduced);
                    setFieldValue(`rows.${rowCount}.${key}`, newRow);
                    if (!newRow.is_valid) {
                      setFieldError(`rows.${rowCount}.${key}`, newRow.errorMessage);
                    }
                  }}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  importArrayModelItem={thisItem}
                />
              );
            })}
        </>
      )}
    </DialogContent>
  );
};
export default ImportDialogContent;
