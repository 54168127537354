/**
 * Generate a random string that can be used as a unique React component key value
 * @param {number} charCount - the length of the random character string to be returned
 * @return {string} a random mix of integers and letters in a string
 */
export const generateRandomString = (charCount: number): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const array = new Uint32Array(charCount);
  window.crypto.getRandomValues(array);
  let randomString = '';
  for (const element of array) {
    const randomIndex = element % characters.length;
    randomString += characters.charAt(randomIndex);
  }
  return randomString;
};
