import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useElementSize } from 'usehooks-ts';
import {
  Answer,
  Answer as AnswerModel,
  LessonPart,
  Question as QuestionModel,
} from '@juno/client-api/model';
import { ANALYTICS_CONFIGURATION, JUNO_ROUTE_MAP } from '@juno/constants';
import { useAnalyticsContext } from '@juno/modules';
import { Container } from '@juno/ui';
import { useRouteNavigate, useSettings } from '@juno/utils';
import { lessonPageRenderer } from './render/render';
import { useLessonHooks } from './utils/hooks';
import { CompletionMetadataModel, LessonProps, VideoCompletionModel } from './utils/lesson';
import { generateAnsweringQuestionActivityData, setVideoWatched } from './utils/methods';

const Lesson: React.FC<LessonProps> = ({ siteId }) => {
  const learningNavigate = useRouteNavigate();

  const params = useParams();
  const courseSlug: string = params.courseSlug || '';
  const lessonSlug: string = params.lessonSlug || '';

  const navigate = useNavigate();

  const { user, downloadUrl, junoUser, lessonScrollContainerRef, isClient, site } = useSettings();
  const { firehoseActivity } = useAnalyticsContext();
  const { FIREHOSE_ACTIONS, FIREHOSE_CATEGORIES, FIREHOSE_OBJECTS } = ANALYTICS_CONFIGURATION;
  const [imgRef, { width: imageWidth }] = useElementSize();

  const lessonHooks = useLessonHooks(siteId, courseSlug, lessonSlug);
  const {
    completeLesson,
    completionsData,
    course,
    currentCompletion,
    isEnrollmentRequired,
    isLastLesson,
    isLessonComplete,
    lesson,
    lessons,
    nextLesson,
    parts,
    retakeLesson,
    setShowIncompleteDialog,
    setVideoCompletions,
    startLesson,
    step,
    updateCompletion,
    setIsSaving,
    setShowIncompleteQuestions,
    setLessonCompleteDialogOpen,
    setLessonScore,
    userAnswers,
    setUserAnswers,
    setShouldPauseVideo,
    setRefresh,
    setOpenRetakeErrorModal,
    isRetake,
  } = lessonHooks;

  const handleTimesUp = async () => {
    // Complete lesson, even if not all questions are answered
    if (!isLessonComplete) {
      await handleNext();
    } else {
      handleLessonCompletedButtonClick();
    }
  };

  const handleGoBack = () => {
    if (isClient) {
      navigate(`/${site?.slug}/learning/courses/${course.slug}`);
      return;
    }
    learningNavigate(JUNO_ROUTE_MAP.CLIENT_COURSE, { courseSlug });
  };

  const handleUpdateAndCreateLessonCompletion = async () => {
    const courseId = course?.id;
    const lessonId = lesson?.id;
    const userId = user?.id;
    if (!courseId || !lessonId || !userId) return;
    const data: any = [];
    userAnswers.forEach((answer) => {
      const answerIds = answer?.answers?.map((a: Answer) => a.id) || [];
      data.push({ ...answer, answers: answerIds });
    });
    const lessonCompleteObj = await completeLesson.mutateAsync({
      siteId,
      courseId,
      lessonId,
      data: data || [],
    });
    setLessonScore(lessonCompleteObj?.grade);
    if (!nextLesson) return lessonCompleteObj;
    // create next lesson completion
    if (!completionsData?.find((completion) => completion.lesson_id === nextLesson.id)) {
      await startLesson.mutateAsync({ siteId, courseId, lessonId: nextLesson.id });
    }

    return lessonCompleteObj;
  };

  const retakeTheLesson = async () => {
    setRefresh(true);
    setUserAnswers([]);
    try {
      await retakeLesson.mutateAsync({
        siteId,
        courseId: course?.id || '',
        lessonId: lesson?.id || '',
      });
    } catch {
      setOpenRetakeErrorModal(true);
    }
    // give it an extra second so that the new completion state propagates
    setTimeout(() => {
      setRefresh(false);
    }, 1000);
  };

  const startTimedLesson = async () => {
    setRefresh(true);
    setUserAnswers([]);
    try {
      await startLesson.mutateAsync({
        siteId,
        courseId: course?.id || '',
        lessonId: lesson?.id || '',
      });
    } catch (error) {
      console.error('Error starting timed lesson:', error);
    }
    setRefresh(false);
  };

  const handleNext = async () => {
    let lessonCompleteObj;

    if (!isLessonComplete) {
      try {
        setIsSaving(true);
        lessonCompleteObj = await handleUpdateAndCreateLessonCompletion();

        // Report lesson completion of a lesson
        firehoseActivity(
          FIREHOSE_OBJECTS.OBJECT_CONTENT,
          lesson?.id || '',
          null,
          `${user?.id || ''}`,
          FIREHOSE_CATEGORIES.CATEGORY_LESSON,
          FIREHOSE_ACTIONS.ACTION_COMPLETE?.value,
          null,
          null,
        );
        // Report course completion
        if (isLastLesson) {
          firehoseActivity(
            FIREHOSE_OBJECTS.OBJECT_CONTENT,
            course?.id,
            null,
            `${user?.id || ''}`,
            FIREHOSE_CATEGORIES.CATEGORY_COURSE,
            FIREHOSE_ACTIONS.ACTION_COMPLETE?.value,
            null,
            null,
          );
        }
      } catch (e) {
        console.log(e);
        setIsSaving(false);
        return;
      }

      setIsSaving(false);
      setShouldPauseVideo(false);
      setShowIncompleteDialog(false);
      setShowIncompleteQuestions(false);

      // log the activity of answering a question
      generateAnsweringQuestionActivityData(userAnswers, parts ?? [], (questionId, type, label) => {
        firehoseActivity(
          FIREHOSE_OBJECTS.OBJECT_QUESTION,
          questionId,
          null,
          `${user?.id || ''}`,
          type,
          FIREHOSE_ACTIONS.ACTION_ANSWER?.value,
          label,
          null,
        );
      });

      setUserAnswers([]);

      if (lesson?.include_in_course_grade) {
        const grade = Number(lessonCompleteObj?.grade) || 0;
        setLessonCompleteDialogOpen(true);

        // Report grading of a lesson
        firehoseActivity(
          FIREHOSE_OBJECTS.OBJECT_QUESTION,
          lesson?.id || '',
          null,
          `${user?.id || ''}`,
          FIREHOSE_CATEGORIES.CATEGORY_ANSWER,
          FIREHOSE_ACTIONS.ACTION_EARN_GRADE?.value,
          lesson?.title || '',
          grade,
        );
      } else {
        handleLessonCompletedButtonClick();
      }
    }
  };

  const handleLessonCompletedButtonClick = () => {
    setLessonCompleteDialogOpen(false);
    if (isLastLesson) {
      return handleGoBack();
    }
    if (isClient) {
      navigate(
        `/${site?.slug}/learning/courses/${course.slug}${
          nextLesson?.slug ? `/${nextLesson?.slug}` : ''
        }`,
      );
    } else {
      learningNavigate(JUNO_ROUTE_MAP.CLIENT_LESSON, {
        courseSlug,
        lessonSlug: nextLesson?.slug || '',
      });
    }

    // Force the lesson page to load from the top of the screen
    if (lessonScrollContainerRef?.current) {
      lessonScrollContainerRef.current.scrollTop = 0;
    }
  };

  const handlePreviousLessonButtonClick = () => {
    const previousLesson = lessons && lessons[step - 2];
    if (isClient) {
      navigate(
        `/${site?.slug}/learning/courses/${course.slug}${
          previousLesson?.slug ? `/${previousLesson?.slug}` : ''
        }`,
      );
    } else {
      learningNavigate(JUNO_ROUTE_MAP.CLIENT_LESSON, {
        courseSlug,
        lessonSlug: previousLesson?.slug || '',
      });
    }
    // Force the lesson page to load from the top of the screen
    if (lessonScrollContainerRef?.current) {
      lessonScrollContainerRef.current.scrollTop = 0;
    }
  };

  const onAnswerMultipleChoiceQuestion = (q: QuestionModel | undefined, a: AnswerModel[]) => {
    const existingAnswer = userAnswers.find((answer) => answer.question_id === q?.id);

    if (existingAnswer) {
      setUserAnswers((old) =>
        old.map((answer) => {
          if (answer.question_id === q?.id) {
            return {
              ...answer,
              answers: a,
            };
          } else return answer;
        }),
      );
    } else {
      setUserAnswers((old) => {
        return [
          ...old,
          {
            id: '',
            question_id: q?.id || '',
            answers: a,
            lesson_completion_id: currentCompletion?.id || '',
          },
        ];
      });
    }
  };

  const onAnswerFITBQuestion = (q: QuestionModel | undefined, a: string) => {
    const existingAnswer = userAnswers.find((answer) => answer.question_id === q?.id);

    if (existingAnswer) {
      setUserAnswers((old) =>
        old.map((answer) => {
          if (answer.question_id === q?.id) {
            return {
              ...answer,
              metadata: {
                fillintheblank_answer: a,
              },
            };
          } else return answer;
        }),
      );
    } else {
      setUserAnswers((old) => {
        return [
          ...old,
          {
            id: '',
            question_id: q?.id || '',
            lesson_completion_id: currentCompletion?.id || '',
            metadata: { fillintheblank_answer: a },
          },
        ];
      });
    }
  };

  const onAnswerRatingQuestion = (q: QuestionModel | undefined, a: number) => {
    const existingAnswer = userAnswers.find((answer) => answer.question_id === q?.id);

    if (existingAnswer) {
      setUserAnswers((old) =>
        old.map((answer) => {
          if (answer.question_id === q?.id) {
            return {
              ...answer,
              metadata: {
                ratingAnswer: a,
              },
            };
          } else return answer;
        }),
      );
    } else {
      setUserAnswers((old) => {
        return [
          ...old,
          {
            id: '',
            question_id: q?.id || '',
            lesson_completion_id: currentCompletion?.id || '',
            metadata: { ratingAnswer: a },
          },
        ];
      });
    }
  };

  const onAnswerRankingQuestion = (q: QuestionModel | undefined, a: string[]) => {
    const existingAnswer = userAnswers.find((answer) => answer.question_id === q?.id);

    if (existingAnswer) {
      setUserAnswers((old) =>
        old.map((answer) => {
          if (answer.question_id === q?.id) {
            return {
              ...answer,
              metadata: {
                rankingAnswer: a,
              },
            };
          } else return answer;
        }),
      );
    } else {
      setUserAnswers((old) => {
        return [
          ...old,
          {
            id: '',
            question_id: q?.id || '',
            lesson_completion_id: currentCompletion?.id || '',
            metadata: { rankingAnswer: a },
          },
        ];
      });
    }
  };

  const handleVideoActivity = (partId: string, percent: number) => {
    setVideoWatched(partId, percent, currentCompletion, updateCompletion, siteId, course, lesson)
      .then((response: any) => {
        // Report watched a 10% increment of a video
        firehoseActivity(
          'Content',
          lesson?.id || '',
          null,
          `${user?.id || ''}`,
          'Content - [lesson]',
          'Watch',
          partId,
          percent / 100,
        );
        const responseMeta: CompletionMetadataModel | undefined = response.metadata;
        const responseCompletion: VideoCompletionModel = responseMeta?.video_completion || {};

        setVideoCompletions(responseCompletion);
      })
      .catch((error) => {
        console.error('Error updating video_completion on LessonCompletion:', error);
      });
  };

  if (isEnrollmentRequired) {
    return (
      <Typography variant='h6' color='text.primary' sx={{ textAlign: 'center' }}>
        You must be enrolled in this course to view this lesson.
      </Typography>
    );
  }

  const downloadPartResource = (part: LessonPart, selectionId: string) => {
    const resource = part?.resources?.find((r) => r.id === selectionId);
    const url = resource?.download?.url;
    if (url) {
      const filename = resource?.download?.url?.split('/').slice(-1)[0] || resource?.title;
      downloadUrl(url, filename, false);

      firehoseActivity(
        FIREHOSE_OBJECTS.OBJECT_DOWNLOAD,
        resource?.id || '',
        url || '',
        `${user?.id || ''}`,
        FIREHOSE_CATEGORIES.CATEGORY_DOWNLOAD,
        FIREHOSE_ACTIONS.ACTION_DOWNLOAD?.value,
        resource?.title || '',
        null,
      );
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: isClient ? 'none' : '760px', pt: 2 }}>
      {lessonPageRenderer({
        handleGoBack,
        handleLessonCompletedButtonClick,
        handleNext,
        handlePreviousLessonButtonClick,
        handleTimesUp,
        handleVideoActivity,
        onAnswerFITBQuestion,
        onAnswerMultipleChoiceQuestion,
        onAnswerRankingQuestion,
        onAnswerRatingQuestion,
        retakeTheLesson,
        startTimedLesson,
        siteId,
        imgRef,
        imageWidth,
        downloadPartResource,
        ...lessonHooks,
      })}
    </Box>
  );
};

export default Lesson;
