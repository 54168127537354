import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { SaveBar } from '@juno/ui';
import { useSettings } from '@juno/utils';
import { CertificateType } from '../utils/enums';
import CustomHTMLTemplate from './CustomHTMLTemplate';
import DefaultCertificateTemplate from './DefaultCertificateTemplate';

interface CertificateInfoProps {
  formik: any;
  onDelete: (id: string) => void;
  isSaving: boolean;
  onBack: () => void;
}

const subtitleStyles = {
  color: 'GrayText',
  fontSize: '16px',
  lineHeight: '16px',
  fontWeight: 600,
};

const CertificateInfo: React.FC<CertificateInfoProps> = ({
  formik,
  onDelete,
  isSaving,
  onBack,
}) => {
  const [openDelete, setOpenDelete] = useState(false);
  const { isWidget } = useSettings();

  const onOpenDelete = () => {
    setOpenDelete(true);
  };

  const onCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDelete = () => {
    onDelete(formik.values.id);
    onBack();
  };

  return (
    <Grid container>
      {!isWidget && (
        <SaveBar
          isDirty={formik.dirty}
          isSaving={isSaving}
          onDiscard={formik.handleReset}
          onSave={formik.handleSubmit}
        />
      )}
      {formik.values.metadata?.type === CertificateType.DEFAULT && (
        <DefaultCertificateTemplate
          formik={formik}
          subtitleStyles={subtitleStyles}
          onDelete={onOpenDelete}
        />
      )}
      {formik.values.metadata?.type === CertificateType.CUSTOM && (
        <CustomHTMLTemplate
          formik={formik}
          subtitleStyles={subtitleStyles}
          onDelete={onOpenDelete}
        />
      )}
      <Dialog open={openDelete} onClose={onCloseDelete}>
        <DialogTitle>Delete Certificate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this certificate? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseDelete}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default CertificateInfo;
