import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { JunoUser } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import DialogAriaWrapper from '../DialogAriaWrapper';

interface UserQuickViewModalProps {
  open: boolean;
  user: JunoUser;
  handleClose: () => void;
  onViewProfile: (email: string) => void;
}
const UserQuickViewModal: React.FC<UserQuickViewModalProps> = ({
  open,
  user,
  handleClose,
  onViewProfile,
}) => {
  const { getImageSrc } = useSettings();
  return (
    <DialogAriaWrapper
      id={`user_quick_view_modal`}
      open={open}
      fullWidth={true}
      maxWidth={'xs'}
      onClose={handleClose}
    >
      <DialogTitle></DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent id='user_quick_view_modal-dialog-description'>
        <Stack spacing={1}>
          <Box display='flex' alignItems='center' mb={2}>
            <Avatar
              src={getImageSrc(user.icon || user.avatar || 'UserIcon_CrowdHub.jpg')}
              alt='User Avatar'
              sx={{ width: '64px', height: '64px', mr: '32px' }}
            />
            <Stack spacing={0.25}>
              <Typography
                variant='subtitle2'
                fontSize={18}
              >{`${user.first_name} ${user.last_name}`}</Typography>
              <Typography variant='subtitle2'>{user.company}</Typography>
              <Typography variant='caption'>{user.email}</Typography>
            </Stack>
          </Box>
          <Button
            aria-label='View profile'
            variant='contained'
            color='primary'
            onClick={() => onViewProfile(user.email)}
          >
            View Profile
          </Button>
          <Button
            aria-label='Cancel'
            variant='outlined'
            color='primary'
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
        </Stack>
      </DialogContent>
    </DialogAriaWrapper>
  );
};
export default UserQuickViewModal;
