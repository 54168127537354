import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import _ from 'lodash';
import { JunoUser, PriceLevel } from '@juno/client-api/model';
import {
  AutoCompleteUsers,
  DefaultCircularProgress,
  DialogAriaWrapper,
  JunoSpin,
  SearchableUserList,
} from '@juno/ui';
import { UserListActionEnum, useDynamicUserList } from '@juno/utils';

const PAGE_SIZE = 50;
interface FormDialogProps {
  open: boolean;
  onSave: (data: PriceLevel) => void;
  onClose: () => void;
  onDelete: () => void;
  isLoading: boolean;
  isSaving: boolean;
  item?: any;
  platformId: string;
  siteId: string;
}

export interface PayloadProps {
  id?: string;
  name?: string;
}

const FormDialog: React.FC<FormDialogProps> = ({
  open,
  onClose,
  onSave,
  onDelete,
  isLoading,
  isSaving,
  item,
  siteId,
  platformId,
}) => {
  const [payload, setPayload] = useState<PayloadProps | undefined>();
  const [userSearchValue, setUserSearchValue] = useState<string>('');
  const [userSearchValue2, setUserSearchValue2] = useState<string>('');
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const selectedDeleteUser = useRef<JunoUser | undefined>(undefined);
  const [userLoading, setUserLoading] = useState<string | undefined>(undefined);
  const [listUserLoading, setListUserLoading] = useState<string | undefined>(undefined);
  const isValid = !!payload?.name;

  const handleAddUser = (user: JunoUser) => {
    setUserLoading(user.id);
    const newUser = {
      ...user,
      price_level: item?.id || '',
    };
    setTimeout(() => {
      handleUpdateUser(newUser, UserListActionEnum.ADD);
      setUserLoading(undefined);
    }, 500);
  };

  const handleRemoveUser = () => {
    if (selectedDeleteUser.current) {
      setListUserLoading(selectedDeleteUser.current.id);
      const newUser = {
        ...selectedDeleteUser.current,
        price_level: null,
      };

      setTimeout(() => {
        handleUpdateUser(newUser, UserListActionEnum.REMOVE);
        selectedDeleteUser.current = undefined;
        setListUserLoading(undefined);
      }, 500);
    }
    setRemoveDialogOpen(false);
  };

  const handleClose = () => {
    selectedDeleteUser.current = undefined;
    setRemoveDialogOpen(false);
  };

  const {
    list: { list: optimisticValue, isLoadingPage, isInitialLoad, ref },
    secondaryList: {
      list: allUsersData,
      isLoadingPage: isLoadingPageSecondary,
      isInitialLoad: isInitialLoadSecondary,
      ref: secondaryRef,
    },
    handleUpdateUser,
  } = useDynamicUserList(
    {
      filter: {
        price_level: item?.id || '',
      },
      order: 'last_name',
    },
    PAGE_SIZE,
    userSearchValue,
    userSearchValue2,
    platformId,
    {
      exclude: {
        price_level: item?.id || '',
      },
      order: 'last_name',
    },
    (a, b) => a.last_name.localeCompare(b.last_name),
    true,
    item?.id ? true : false,
  );

  const handleUpdate = (key: string, val: unknown) => setPayload({ ...payload, [key]: val });

  const changeHandler = {
    text: ({ target }: ChangeEvent<HTMLInputElement>) => handleUpdate(target.name, target.value),
    select: ({ target }: SelectChangeEvent<string[]>) => handleUpdate(target.name, target.value),
  };

  useEffect(() => {
    if (open) setPayload(undefined);
  }, [open]);

  useEffect(() => {
    if (item) {
      const data = { ...item };
      setPayload(data);
    }
  }, [item]);

  const handleSave = () => {
    const data = { ...payload, site_id: siteId };
    onSave(data as PriceLevel);
  };

  const title = !payload?.id ? 'New Pricing Level' : 'Edit Pricing Level';
  if (isLoading) return <JunoSpin />;
  return (
    <>
      <DialogAriaWrapper
        open={open}
        onClose={onClose}
        fullWidth={item?.id ? true : false}
        maxWidth={`md`}
        id={`create_edit_price_level`}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ height: item?.id ? 750 : 200, maxHeight: '70vh' }}>
          <TextField
            disabled={isSaving}
            id='name'
            name='name'
            label='Pricing Level Title'
            fullWidth
            margin='dense'
            value={payload?.name || ''}
            onChange={changeHandler.text}
          />
          {item?.id && (
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Typography fontWeight='bold'>Users with this Price Level</Typography>
              </Grid>
              <Grid item xs={6}>
                <AutoCompleteUsers
                  options={allUsersData || []}
                  selected={[]}
                  onSubmit={handleAddUser}
                  onInputChange={setUserSearchValue2}
                  isLoading={isInitialLoadSecondary}
                  isLoadingPage={isLoadingPageSecondary}
                  userLoading={userLoading}
                  locked={false}
                  label={'Grant Price Level to Users'}
                  showClearButton={false}
                  paginateRef={secondaryRef}
                />
              </Grid>
              <Grid item xs={6}>
                <SearchableUserList
                  label={''}
                  users={optimisticValue || []}
                  locked={false}
                  userLoading={listUserLoading}
                  onDelete={(user: JunoUser) => {
                    selectedDeleteUser.current = user;
                    setRemoveDialogOpen(true);
                  }}
                  searchValue={userSearchValue}
                  setSearchValue={setUserSearchValue}
                  loading={isInitialLoad}
                  showSubheader={false}
                >
                  <Box
                    style={{
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'center',
                    }}
                    mt={1 / 2}
                  >
                    <DefaultCircularProgress
                      props={{
                        size: 18,
                        variant: 'indeterminate',
                        style: { visibility: isLoadingPage ? 'visible' : 'hidden' },
                      }}
                    />
                    <Box ref={ref} sx={{ pb: 2 }} />
                  </Box>
                </SearchableUserList>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button
            sx={{ visibility: !item ? 'hidden' : 'visible' }}
            disabled={isSaving}
            variant='contained'
            color='error'
            onClick={onDelete}
          >
            Delete
          </Button>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Button disabled={isSaving} onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              disabled={!isValid}
              loading={isSaving}
              variant='contained'
              onClick={handleSave}
            >
              Save
            </LoadingButton>
          </Box>
        </DialogActions>
      </DialogAriaWrapper>
      <DialogAriaWrapper
        open={removeDialogOpen}
        onClose={handleClose}
        id={`remove_price_level_warning`}
      >
        <DialogTitle>Remove Price Level from user</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove this price level from{' '}
            {selectedDeleteUser.current?.first_name} {selectedDeleteUser.current?.last_name}?
          </Typography>
          <Typography mt={1}>
            Users without a price level will not be able to purchase products.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant='contained' onClick={() => handleRemoveUser()} autoFocus>
            Remove
          </Button>
        </DialogActions>
      </DialogAriaWrapper>
    </>
  );
};

export default FormDialog;
