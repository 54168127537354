import { nanoid } from 'nanoid';
import { createImportRecord } from '@juno/client-api';
import { ImportRecordTypeEnum, JunoUser, Site } from '@juno/client-api/model';
import { convertToCSV } from '@juno/utils';
import { ImportArrayModel, updateEnumValuesByBucket, updateRelationalIds } from '../utils';

interface UploadProps {
  rawValueRows: ImportArrayModel[];
  bucket: string;
  importType: ImportRecordTypeEnum;
  relationalArray: any[];
  matchKey: string;
  bucketKeys: any[];
  site: Site;
  user: JunoUser | undefined;
  uploadFileToS3: (
    file: File,
    progressCallback?: (percentage: number) => void,
    randomizeFileName?: boolean,
    folder?: string,
  ) => Promise<string>;
}
export const importUploadProcessor = ({
  rawValueRows,
  bucket,
  importType,
  relationalArray,
  matchKey,
  bucketKeys,
  site,
  user,
  uploadFileToS3,
}: UploadProps) => {
  return new Promise((resolve, reject) => {
    // go through and remove any keys that are not in the bucketKeys
    rawValueRows?.map((row: ImportArrayModel) => {
      Object.keys(row).forEach((key) => {
        const thisKeyHeaderName = bucketKeys.filter((bKey) => bKey.field == key)[0];
        if (!thisKeyHeaderName) {
          delete row[key];
        }
        return row;
      });
    });

    // map over rawValueRows and create an array of objects with the key being the header name and the value being the dom_value
    const modelValues = rawValueRows.map((row: ImportArrayModel) => {
      const values = Object.keys(row).map((key) => {
        // TODO not happy with this, but it works for now
        if (Array.isArray(row[key])) {
          return { [key]: row[key].map((k: ImportArrayModel) => k.id).join('|') };
        }
        return { [key]: row[key].id ?? row[key].dom_value ?? row[key]?.[0]?.id };
      });
      return values;
    });

    // reduce each object in the array into a single object that should match the model type T for the bucket
    // 'any' is used here because we don't know what the model type T is
    const uploadValues = modelValues.map((value: any[]) =>
      value.reduce((obj: any, item: any) => Object.assign(obj, { ...item }), {}),
    );

    // update any relational ids for the server
    const updatedRelationalValues = updateRelationalIds(
      uploadValues,
      relationalArray,
      bucket,
      matchKey,
    );

    // update any enum values for the server
    const updatedEnumValues = updateEnumValuesByBucket(updatedRelationalValues, bucket);

    const csvContent = convertToCSV(updatedEnumValues);

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv' });

    // Convert the Blob to a File
    const fileName = `${nanoid(5)}-${bucket}-${Date.now()}.csv`;
    const filePath = `/imports/${fileName}`;
    const file = new File([blob], `${fileName}`, {
      type: 'text/csv',
    });

    // Now, use the file with your uploadFileToS3 function
    uploadFileToS3(
      file,
      (percentage) => console.log(`Upload progress: ${percentage}`),
      false,
      'imports',
    )
      .then((uploadUrl) => {
        console.log('Uploaded CSV URL:', uploadUrl);
        // Additional logic after upload, if necessary
      })
      .then((res: any) => {
        console.log(res);
        resolve(res);
        createImportRecord(site.id, {
          csv_url: `${site.id}${filePath}`,
          site_id: site.id,
          type: importType,
          admin: { id: user?.id || '', email: user?.email || '', name: '' },
        })
          .then((record: any) => {
            console.log('Import Data S3 response:', record);
          })
          .catch((err: any) => {
            console.error(err);
            if (err.message) {
              reject(err.message);
            } else {
              reject(JSON.stringify(err));
            }
          });
      })
      .catch((err: any) => {
        console.error(err);
      });
  });
};
