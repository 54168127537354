import * as Yup from 'yup';
import { isAfter, isEqual } from 'date-fns';
import {
  EmailCampaign as EmailCampaignModel,
  Schedule,
  Tag as TagModel,
  Template,
  TypeBd0Enum as campaignType,
  DayOfWeekEnum as dayOfWeekOptions,
  FrequencyEnum as frequencyOptions,
  MonthEnum as monthOptions,
} from '@juno/client-api/model';
import { EmailCampaignTypeMap } from './utils';

const SubscriptionGroupSchema = Yup.object().shape({
  name: Yup.string().max(30, 'Subscription Group name cannot have more than 30 characters'),
  description: Yup.string().max(
    100,
    'Subscription Group description field cannot have more than 100 characters',
  ),
});

const SiteConfigSchema = () =>
  Yup.object().shape({
    siteNickname: Yup.string()
      .max(48, 'Site Nickname field cannot have more than 48 characters')
      .required('Site nickname is required'),
    primaryColor: Yup.string()
      .max(48, 'Primary Color field cannot have more than 48 characters')
      .required('Primary color field is required'),
    logo: Yup.mixed(),
    bannerImage: Yup.mixed(),
  });

const NewCampaignPostSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, 'Email campaign name cannot exceed 255 characters')
    .required('Name is required'),
  type: Yup.string().required('Email campaign type is required'),
});

const ScheduleSchema = Yup.object().shape({
  type: Yup.string().required(),
  schedule: Yup.object().shape({
    frequency: Yup.string().required(),
    start_date: Yup.date()
      .nullable()
      .required('Start date is required')
      .test('valid-start-date', 'Invalid start date value', function (value) {
        if (value === null || value === undefined) {
          return true; // Allow null values
        }

        try {
          const parsedDate = typeof value === 'string' ? new Date(value) : value;
          if (isNaN(parsedDate as any)) {
            return false; // Date parsing failed
          }
          // Additional checks can be added if needed
          return true;
        } catch (error) {
          return false; // Date parsing failed
        }
      }),
    end_date: Yup.date()
      .nullable(true)
      .test('end_date', 'End date must be after start date', function () {
        if (!this.parent.end_date) return true;
        const start_date = new Date(this.parent.start_date);
        const end_date = new Date(this.parent.end_date);
        return start_date && (isAfter(end_date, start_date) || isEqual(end_date, start_date));
      }),
    day_offset: Yup.mixed().when('frequency', {
      is: frequencyOptions.DAILY,
      then: Yup.mixed()
        .nullable(true)
        .test(
          'isNumberOrNull',
          'Value must be an integer from 1 to 365',
          (value) => value === null || (value >= 1 && value <= 365),
        ),
      otherwise: Yup.number().notRequired().nullable(true),
    }),
    time: Yup.date().typeError('Value must be a valid time string'),
    date_part: Yup.mixed()
      .when(['frequency'], {
        is: (frequency: string) => frequency === frequencyOptions.MONTHLY,
        then: Yup.number().required('Date is required'),
        otherwise: Yup.number().notRequired(),
      })
      .test('adjust_day', 'Date is invalid', async function (value: any) {
        const shouldValidate =
          this.parent.frequency === frequencyOptions.MONTHLY && typeof this.parent.date_part;
        if (shouldValidate) {
          return value >= -1 && value <= 28;
        }
        return true;
      }),
    day_of_week: Yup.string().when(['frequency', 'date_part'], {
      is: (frequency: string, date_part: number | null) =>
        frequency === 'Weekly' || (frequency === frequencyOptions.MONTHLY && typeof date_part),
      then: Yup.string().required('Day of week is required'),
      otherwise: Yup.string().notRequired(),
    }),
    week_offset: Yup.mixed().when('frequency', {
      is: frequencyOptions.MONTHLY,
      then: Yup.mixed()
        .nullable(true)
        .test(
          'isNumberOrNull',
          'Value must be an integer from 1 to 4',
          (value) => value === null || (typeof value === 'number' && value >= 1 && value <= 4),
        ),
      otherwise: Yup.number().notRequired().nullable(true),
    }),
    month: Yup.string().notRequired(),
  }),
});

const NewCampaignSchema = Yup.object().shape({
  name: Yup.string().max(256, 'Keep it under 256').required('Name is required'),
  from_name: Yup.string().max(256, 'Keep it under 256'),
  from_email: Yup.string()
    .matches(/^[^@]+$/, 'Invalid characters')
    .max(256, 'Keep it under 256'),
  type: Yup.mixed().oneOf(Object.entries(EmailCampaignTypeMap).map(([key, value]) => key)),
  subject: Yup.string().max(256, 'Keep it under 256'),
});

const selectDefaultValue = (type: string) => {
  switch (type) {
    case campaignType.CUSTOM:
      return frequencyOptions.ONE_TIME;
    default:
      return frequencyOptions.AUTOMATIC;
  }
};

interface NewCampaignSectionFormikValues {
  id: string;
  name: string;
  type: campaignType;
  from_name?: string;
  from_email?: string;
  subject?: string;
}

const NewCampaignSectionFormik = (emailCampaign: EmailCampaignModel | undefined) => {
  return {
    id: emailCampaign?.id || -1,
    name: emailCampaign?.name || '',
    type: emailCampaign?.type || '',
    from_name: emailCampaign?.from_name || '',
    from_email: emailCampaign?.from_email || '',
    subject: emailCampaign?.subject || '',
  } as NewCampaignSectionFormikValues;
};

interface CampaignScheduleSectionFormikValues {
  type: campaignType;
  schedule: Schedule;
}

const CampaignScheduleSectionFormik = (emailCampaign: EmailCampaignModel | undefined) => {
  return {
    type: emailCampaign?.type || '',
    schedule: {
      id: emailCampaign?.schedule?.id || '',
      frequency:
        emailCampaign?.schedule?.frequency || selectDefaultValue(emailCampaign?.type || ''),
      start_date: emailCampaign?.schedule?.start_date || null,
      end_date: emailCampaign?.schedule?.end_date || null,
      day_offset: emailCampaign?.schedule?.day_offset || null,
      date_part: emailCampaign?.schedule?.date_part || null,
      day_of_week: emailCampaign?.schedule?.day_of_week || dayOfWeekOptions.Null,
      week_offset: emailCampaign?.schedule?.week_offset || null,
      time: emailCampaign?.schedule?.time || null,
      month: emailCampaign?.schedule?.month || monthOptions.Null,
    },
  } as CampaignScheduleSectionFormikValues;
};

interface CampaignRecipientSectionFormikValues {
  metadata: {
    tags?: string[];
    has_all?: boolean;
    recipient_count?: number;
  };
  tags?: TagModel[];
}

const CampaignRecipientSectionFormik = (emailCampaign: EmailCampaignModel | undefined) => {
  return {
    metadata: {
      tags: emailCampaign?.metadata?.tags || undefined,
      has_all:
        emailCampaign?.metadata?.has_all !== undefined ? emailCampaign.metadata.has_all : undefined,
      recipient_count: emailCampaign?.metadata?.recipient_count || 0,
    },
    tags: undefined,
  } as CampaignRecipientSectionFormikValues;
};

interface SidebarSectionFormikValues
  extends NewCampaignSectionFormikValues,
    CampaignScheduleSectionFormikValues,
    CampaignRecipientSectionFormikValues {
  is_active?: boolean | null;
  template?: Template;
}

const SidebarSectionFormik = (emailCampaign: EmailCampaignModel | undefined) => {
  return {
    is_active: emailCampaign?.is_active || false,
    template: emailCampaign?.template || null,
    ...NewCampaignSectionFormik(emailCampaign),
    ...CampaignScheduleSectionFormik(emailCampaign),
    ...CampaignRecipientSectionFormik(emailCampaign),
  } as SidebarSectionFormikValues;
};

export {
  SubscriptionGroupSchema,
  SiteConfigSchema,
  ScheduleSchema,
  NewCampaignSchema,
  NewCampaignPostSchema,
  NewCampaignSectionFormikValues,
  NewCampaignSectionFormik,
  CampaignScheduleSectionFormikValues,
  CampaignScheduleSectionFormik,
  CampaignRecipientSectionFormikValues,
  CampaignRecipientSectionFormik,
  SidebarSectionFormikValues,
  SidebarSectionFormik,
};
