import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'usehooks-ts';
import {
  useGetCourses,
  useGetMyEnrollments,
  useGetMyWaitlistedEnrollments,
  useGetSite,
} from '@juno/client-api';
import { Course } from '@juno/client-api/model';
import { JUNO_ROUTE_MAP } from '@juno/constants';
import {
  CourseFilter,
  CourseFilterContext,
  CourseFilterDialog,
  FilterButton,
  FilterGridChip,
  SearchBar,
} from '@juno/ui';
import { useRouteNavigate } from '@juno/utils';
import { Breakpoints, useBreakpoint } from '@juno/utils/hooks';
import CourseTile, { CourseModel } from './components/CourseTile';
import EnrollmentTile, { EnrollmentModel } from './components/EnrollmentTile';
import LoadingTile from './components/LoadingTile';
import { TileType } from './constants';
import { Controls, ShowingLabel } from './styles';
import { getHandleDeleteFilter, getHandleUpdateDateChip } from './utils';

const PAGE_SIZE = 12;

type ResultModel = EnrollmentModel | CourseModel;

interface CourseListWrapperProps {
  siteId: string;
  tileType: TileType;
  searchField: string;
  fillTileContent: (model: EnrollmentModel | CourseModel, breakpoints: Breakpoints) => JSX.Element;
  tempFilter: { [key: string]: unknown };
  setTempFilter: (filter: any) => void;
  filter?: { [key: string]: unknown };
  include?: string;
  order?: string;
  markEndOfList?: boolean;
  roleType?: string;
  userId?: string;
}

interface CourseListProps extends CourseListWrapperProps {
  onFilter?: (filter: string) => void;
  filterComponent?: React.ReactNode;
}

const WrappedCourseList: React.FC<CourseListProps> = ({
  siteId,
  tileType,
  searchField,
  filter,
  include,
  order,
  fillTileContent,
  markEndOfList,
  roleType,
  userId,
  onFilter,
  filterComponent,
  tempFilter,
}) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1000,
  });
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [isEndOfList, setIsEndOfList] = useState(false);
  const [page, setPage] = useState(0);
  const breakpoints = useBreakpoint();
  const [dateStartAfterChipLabel, setDateStartAfterChipLabel] = useState('');
  const [dateStartBeforeChipLabel, setDateStartBeforeChipLabel] = useState('');
  const filterPrefix = useMemo(() => {
    return tileType === TileType.COURSE ? '' : 'course__';
  }, [tileType]);
  const courseFilterContext = useContext(CourseFilterContext);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 100);
  const learningNavigate = useRouteNavigate();
  const [allResults, setAllResults] = useState<ResultModel[]>([]);
  const prefixedTempFilter = useMemo(() => {
    return tempFilter
      ? Object.fromEntries(Object.entries(tempFilter).map(([k, v]) => [`${filterPrefix}${k}`, v]))
      : {};
  }, [tempFilter, filterPrefix]);
  const filterParams = { ...filter, ...prefixedTempFilter };
  filterParams[searchField] = debouncedSearch;
  const queryParams = {
    filter: filterParams,
    include: include,
    order: order,
    offset: page,
    limit: PAGE_SIZE,
  };

  // Only exactly one of these hooks is ever going to be used

  const { data: myEnrollments, isLoading: isLoadingMyEnrollments } = useGetMyEnrollments<
    ResultModel[]
  >(siteId, queryParams, {
    query: { enabled: tileType === TileType.ENROLLMENT },
  });

  const { data: myWaitlistEnrollments, isLoading: isLoadingMyWaitlistEnrollments } =
    useGetMyWaitlistedEnrollments<ResultModel[]>(siteId, queryParams, {
      query: { enabled: tileType === TileType.WAITLIST },
    });

  const { data: allCourses, isLoading: isLoadingAllCourses } = useGetCourses<ResultModel[]>(
    siteId,
    queryParams,
    {
      query: { enabled: tileType === TileType.COURSE },
    },
  );

  const results = useMemo(() => {
    if (tileType === TileType.ENROLLMENT) {
      return myEnrollments;
    }
    if (tileType === TileType.WAITLIST) {
      return myWaitlistEnrollments;
    }
    // tileType === TileType.COURSE
    return allCourses;
  }, [tileType, myEnrollments, myWaitlistEnrollments, allCourses]);

  const isLoading = useMemo(() => {
    if (tileType === TileType.ENROLLMENT) {
      return isLoadingMyEnrollments;
    }
    if (tileType === TileType.WAITLIST) {
      return isLoadingMyWaitlistEnrollments;
    }
    // tileType === TileType.COURSE
    return isLoadingAllCourses;
  }, [tileType, isLoadingMyEnrollments, isLoadingMyWaitlistEnrollments, isLoadingAllCourses]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleDeleteFilter = getHandleDeleteFilter(
    courseFilterContext,
    setDateStartAfterChipLabel,
    setDateStartBeforeChipLabel,
    onFilter,
  );
  const handleUpdateDateChip = getHandleUpdateDateChip(
    courseFilterContext,
    filterComponent,
    onFilter,
  );

  // Reload results when search changes
  useEffect(() => {
    setPage(0);
    setAllResults([]);
  }, [debouncedSearch, tempFilter]);

  // Load the next page when it comes into view
  useEffect(() => {
    if (inView && !isLoading && results?.length !== 0) {
      setPage(page + 1);
    }
  }, [inView, isLoading, results]);

  // Push newly loaded pages into the list of results.
  // Mark end of list if no results.
  useEffect(() => {
    if (!results) return;
    if (results?.length === 0) {
      setIsEndOfList(true);
      return;
    }
    setAllResults([...allResults, ...results]);
  }, [results]);

  useEffect(() => {
    if (filterComponent) {
      handleUpdateDateChip('date_start__gte', setDateStartAfterChipLabel, true);
      handleUpdateDateChip('date_start__lte', setDateStartBeforeChipLabel, false);
    } else {
      setDateStartAfterChipLabel('');
      setDateStartBeforeChipLabel('');
    }
  }, [filterComponent]);

  const handleCourseClick = (course: Course) => {
    learningNavigate(JUNO_ROUTE_MAP.CLIENT_COURSE, { courseSlug: course.slug });
  };

  const loadTile = (item: ResultModel, idx: number) => {
    if ((item as EnrollmentModel).course) {
      const enrollment = item as EnrollmentModel;
      return (
        <EnrollmentTile
          key={enrollment.id}
          enrollment={enrollment}
          onClick={handleCourseClick}
          idx={idx}
          ref={(idx === allResults.length - 1 && ref) || null}
        >
          {fillTileContent(enrollment, breakpoints)}
        </EnrollmentTile>
      );
    } else {
      const course = item as CourseModel;
      return (
        <CourseTile
          key={course.id}
          course={course}
          roleType={roleType || ''}
          userId={userId}
          imageUrl={course.icon}
          siteId={siteId}
          idx={idx}
          ref={(idx === allResults.length - 1 && ref) || null}
        />
      );
    }
  };

  return (
    <>
      <Controls>
        <SearchBar placeholder={'Search...'} onChange={handleSearchChange} />
        <FilterButton onClick={() => setFilterDialogOpen(true)} />
      </Controls>
      <Box sx={{ height: '100%', margin: `0px auto 30px auto` }}>
        <Grid container spacing={1}>
          <FilterGridChip
            label={dateStartAfterChipLabel}
            onDelete={() => handleDeleteFilter('date_start__gte')}
            enabled={!!dateStartAfterChipLabel}
          />
          <FilterGridChip
            label={dateStartBeforeChipLabel}
            onDelete={() => handleDeleteFilter('date_start__lte')}
            enabled={!!dateStartBeforeChipLabel}
          />
        </Grid>
      </Box>
      <ShowingLabel variant='body2'>{`Showing ${allResults?.length || 0} Courses`}</ShowingLabel>
      {allResults?.map(loadTile)}
      {isLoading && <LoadingTile />}
      {markEndOfList && isEndOfList && (
        <Typography variant='body1' sx={{ textAlign: 'center', mt: 2 }}>
          - No more courses found -
        </Typography>
      )}
      <CourseFilterDialog
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        onFilter={onFilter}
        filterComponent={filterComponent}
      />
    </>
  );
};

// Each course list needs its own context.
// Since the filter is built with a shared context, we will wrap the list and create unique context.
const CourseList: React.FC<CourseListWrapperProps> = (courseListProps) => {
  const { data: siteData, isLoading: siteLoading } = useGetSite(courseListProps.siteId);

  const handleDateUpdate = (dateVal: Dayjs | null, key: string) => {
    if (!dateVal) {
      courseListProps.setTempFilter((current: any) => {
        const { [key]: _, ...rest } = current;
        return rest;
      });
      return;
    }
    const dateObj = dayjs(dateVal);
    if (!dateObj.isValid()) return;
    courseListProps.setTempFilter((current: any) => {
      return { ...current, [key]: dateObj.format() };
    });
  };

  const filterComponent = (
    <CourseFilter
      site={siteData}
      onChange={courseListProps.setTempFilter}
      values={courseListProps.tempFilter}
      settings={{ showFilterStartDates: true }}
      preFilterOptions={{}}
    />
  );
  const wrappedProps: CourseListProps = {
    ...courseListProps,
    onFilter: () => {},
    filterComponent: filterComponent,
    tempFilter: courseListProps.tempFilter,
  };
  return (
    <CourseFilterContext.Provider
      value={{
        tempFilter: courseListProps.tempFilter,
        handleDateUpdate: handleDateUpdate,

        // Not yet used by Course List. Filler values.
        siteId: '',
        platformId: '',
        handleStringListUpdate: () => {},
        handleTagListUpdate: () => {},
        instructorsRemoved: [],
        setInstructorsRemoved: () => {},
        tagsRemoved: {},
        setTagsRemoved: () => {},
      }}
    >
      <WrappedCourseList {...wrappedProps}></WrappedCourseList>
    </CourseFilterContext.Provider>
  );
};

export default CourseList;
