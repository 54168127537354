import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useGetAnswerSummary } from '@juno/client-api';
import { Question } from '@juno/client-api/model';

interface PollProps {
  question: Question;
  handleSetAnswer: (answer: string[]) => void;
  answer: string[]; //string | string[] | number;
  courseId: string;
  lessonId: string;
  siteId: string;
}
const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
const AnswerContainer = styled(Grid)(({ theme }) => ({
  // padding: theme.spacing(1),
}));
const AnswerTypography = styled(Typography)(({ theme }) => ({
  flex: '100%',
  flexGrow: 1,
  marginRight: theme.spacing(3),
  padding: theme.spacing(2),
}));
const AnswerLetter = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.primary.light}`,
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const AnswerButton = styled(Button)(({ theme }) => ({
  padding: 0,
  width: '100%',
  borderRadius: '30px',
  textTransform: 'none',
  display: 'flex',
  position: 'relative',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
}));

export default function Poll({
  question,
  answer: theAnswers,
  handleSetAnswer,
  courseId,
  lessonId,
  siteId,
}: PollProps): React.ReactElement {
  const { answers, name, answer_changeable, metadata } = question;
  const answer = theAnswers?.[0];
  const [totalAnswerCount, setTotalAnswerCount] = useState<number>(1);

  // Grab this poll's historical answers
  const { data: pollAnswers } = useGetAnswerSummary(siteId, courseId, lessonId, question.id);
  // Increment the total_answers count for each poll answer object to account for the user's answer
  const adjustedPollAnswerTotals = pollAnswers?.map((pollAnswer: any) => ({
    ...pollAnswer,
    total_answers: pollAnswer.total_answers + 1,
  }));

  // Set the total amount of answers thus far for this poll question plus the answer that is about to happen
  useEffect(() => {
    setTotalAnswerCount((pollAnswers?.[0]?.total_answers || 0) + 1);
  }, [pollAnswers]);
  return (
    <Grid container columnSpacing={1} rowSpacing={'12px'}>
      {answers.map((a, index) => {
        const isSelected = answer === a.id;
        const historicalPollAnswerData = adjustedPollAnswerTotals?.find(
          (pollAnswerData: any) => pollAnswerData.answer_id === a.id,
        ) ?? { respondent_count: 0, total_answers: totalAnswerCount };

        const percentage = isSelected
          ? (100 * (historicalPollAnswerData?.respondent_count + 1)) /
            historicalPollAnswerData?.total_answers
          : (100 * historicalPollAnswerData?.respondent_count) /
            historicalPollAnswerData?.total_answers;
        const cleanedPercentage = percentage % 1 === 0 ? percentage : percentage.toFixed(2);
        const showVoting = metadata?.showVoting;
        const answerLetter = answer && showVoting ? `${cleanedPercentage}%` : alphabet[index];
        return (
          <AnswerContainer item xs={12} sm={6} md={6} key={`answer_${index}`}>
            <AnswerButton
              disabled={!!answer}
              variant={isSelected ? 'outlined' : 'contained'}
              onClick={(e) => handleSetAnswer([a.id])}
            >
              <AnswerLetter>{answerLetter}</AnswerLetter>
              <AnswerTypography>{a.title}</AnswerTypography>
            </AnswerButton>
          </AnswerContainer>
        );
      })}
    </Grid>
  );
}
