import React, { MutableRefObject } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, DialogTitle, Typography } from '@mui/material';

interface DialogHeaderProps {
  title: string;
  titlePrefix: string;
  rowCount: number;
  totalRows: number;
  currentErrorRow: number;
  totalErrorRows: number;
  errorsOnThisRow: number;
  totalErrors: number;
  handleStepForwardError: () => void;
  handleStepBackwardError: () => void;
  originalErrorRowRef: MutableRefObject<any>;
  handleClose: () => void;
}

const DialogHeader: React.FC<DialogHeaderProps> = ({ ...props }) => {
  const {
    title,
    titlePrefix,
    rowCount,
    totalRows,
    currentErrorRow,
    errorsOnThisRow,
    totalErrors,
    handleStepForwardError,
    handleStepBackwardError,
    originalErrorRowRef,
    handleClose,
  } = props;
  return (
    <Box>
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography fontSize={24}>Validation Wizard</Typography>
        <Button onClick={handleClose}>
          <ClearIcon />
        </Button>
      </DialogTitle>
      <Box
        component='div'
        justifyContent='space-between'
        display='flex'
        boxShadow='0px 2px 1px 0px #00000069'
        sx={{ backgroundColor: '#dadada', pt: 3, pb: 3, border: '1px solid black' }}
      >
        <Button disabled={rowCount == 0} onClick={handleStepBackwardError}>
          <ChevronLeftIcon />
        </Button>
        <Box
          component='div'
          sx={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
          width='-webkit-fill-available'
        >
          <Box justifyContent='space-between' display='flex'>
            {errorsOnThisRow > 0 ? (
              <>
                <Typography>
                  Item {currentErrorRow > 0 ? currentErrorRow + 1 : 1} of{' '}
                  {Object.keys(originalErrorRowRef.current ?? {}).length} with Errors
                </Typography>
                <Typography>
                  (1-{errorsOnThisRow} of {totalErrors} Errors)
                </Typography>
              </>
            ) : (
              <Typography>{totalErrors > 0 ? '(No errors)' : 'No Errors Remaining'}</Typography>
            )}
          </Box>
          <b>{titlePrefix}</b> {title}
        </Box>
        <Button disabled={rowCount >= totalRows - 1} onClick={handleStepForwardError}>
          <ChevronRightIcon />
        </Button>
      </Box>
    </Box>
  );
};
export default DialogHeader;
