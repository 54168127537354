import React, { Dispatch, SetStateAction, useContext } from 'react';
import { AuthContextProps } from 'react-oidc-context';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CommunityGroup,
  ContentReport,
  ContentReportWithUsers,
  Course,
  FeatureConfig,
  JunoUser,
  NavigationItem,
  Platform,
  QuickLink,
  SearchContentTypeEnum,
  SidebarWidget,
  Site as SiteModel,
} from '@juno/client-api/model';
import { JUNO_ROUTE_MAP } from '@juno/constants';

export interface RouteProps {
  key: string;
  route: string;
  params: string[];
}

export interface PreferredTag {
  tag_id: string;
  tag_name: string;
  tag_type: string;
}

export interface DefaultOnNavigateProps {
  key: string;
  params: { [key: string]: string };
  query?: { [key: string]: string };
}

export interface VNowUser {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  token: string;
  preferredTags: PreferredTag[];
  chatRoom?: string;
  chatKey?: string;
}

export interface FirehoseActionModel {
  title: string;
  value: string;
}

export interface FirehoseConfigModel {
  FIREHOSE_ACTIONS: {
    [key: string]: FirehoseActionModel;
  };
  FIREHOSE_CATEGORIES: {
    [key: string]: string;
  };
  FIREHOSE_OBJECTS: {
    [key: string]: string;
  };
}

interface ReportContentContext {
  reportContentDialogOpen: boolean;
  contentBeingReported?: {
    id?: string;
    type?: string;
    author?: string;
    reporter?: string;
    group?: string;
  };
}

export interface DefaultContextProps {
  isWidget: boolean;
  isClient: boolean;
  isAdmin?: boolean;
  siteId: string;
  pageSlug: string;
  routeKey?: string;
  junoUser?: VNowUser;
  user?: JunoUser;
  setCurrentUser: (user: JunoUser | undefined) => void;
  site?: SiteModel;
  setCurrentSite: (site: SiteModel | undefined) => void;
  sitePath?: string;
  groupSlug?: string;
  groupsSlug?: string;
  coursesSlug?: string;
  courseSlug?: string;
  openExternalUrl: (url: string) => void;
  downloadUrl: (url: string, fileName: string, shareRatherThanOpen: boolean) => void;
  getImageSrc: (url: string | null | undefined) => string;
  parseLinksFromDescription: (
    aDescription: string,
    aOnInternalLinkClicked: (url: string, target: string) => void,
    aOnExternalLinkClicked: (url: string, target: string) => void,
    aGenerateData: object,
    aParseForLinks: boolean,
  ) => void;
  onNavigate?: (props: DefaultOnNavigateProps) => void;
  preferredTags: PreferredTag[];
  isApp: boolean;
  configs?: FeatureConfig[];
  lessonScrollContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
  fullWidthLayout?: boolean;
  setFullWidthLayout?: Dispatch<SetStateAction<boolean>>;
  auth?: AuthContextProps | null;
  platform?: Platform | null;
  showMessenger?: boolean;
  setShowMessenger?: Dispatch<SetStateAction<boolean>>;
  showSideMenu?: boolean;
  setShowSideMenu?: Dispatch<SetStateAction<boolean>>;
  myGroups?: CommunityGroup[];
  myCurrentCourses?: Course[];
  isSmallScreen?: boolean;
  quickLinks?: QuickLink[];
  navItems?: NavigationItem[];
  navItemsLoading?: boolean;
  impliedAdminOfGroups?: string[];
  impliedMemberOfGroups?: string[];
  sidebarWidgets?: SidebarWidget[];
  enableLearning?: boolean;
  enableLibrary?: boolean;
  restrictGZMVPFeatures?: boolean;
  reportContent: ReportContentContext;
  setReportContent: Dispatch<SetStateAction<ReportContentContext>>;
  myContentReports: ContentReportWithUsers[];
}

//include defaults in case nothing is passed in
export const defaultContextObject: DefaultContextProps = {
  isWidget: false,
  isClient: false,
  isAdmin: false,
  siteId: '',
  pageSlug: '',
  routeKey: '',
  junoUser: undefined,
  preferredTags: [],
  user: undefined,
  site: undefined,
  sitePath: '',
  openExternalUrl: () => {},
  setCurrentUser: () => {},
  setCurrentSite: () => {},
  parseLinksFromDescription: () => {},
  downloadUrl: () => {},
  getImageSrc: () => '',
  isApp: false,
  impliedAdminOfGroups: [],
  impliedMemberOfGroups: [],
  enableLearning: false,
  enableLibrary: false,
  restrictGZMVPFeatures: false,
  reportContent: {
    reportContentDialogOpen: false,
    contentBeingReported: undefined,
  },
  setReportContent: () => {},
  myContentReports: [],
};

export const DefaultContext = React.createContext<DefaultContextProps>(defaultContextObject);

export const useSettings = () => {
  const settings = useContext(DefaultContext);
  return settings;
};

export const useRouteParams = () => {
  const params = useParams();
  const settings = useSettings();
  if (!settings.isWidget) return params;
  const { isWidget } = settings;
  return { isWidget };
};

export const useLegacyNavigate = () => {
  const { onNavigate } = useSettings();
  const navigate = (to: RouteProps, params: { [key: string]: string } = {}) => {
    let toRoute = to;
    const routeMap = Object.values(JUNO_ROUTE_MAP).filter((route) => route.key === to.key);
    if (routeMap.length > 0) {
      toRoute = routeMap[0];
    }
    onNavigate && onNavigate({ key: toRoute.key, params });
  };
  return navigate;
};

export const useRouteNavigate = () => {
  const navigateTo = useNavigate();
  const { onNavigate, isWidget } = useSettings();
  const navigate = (to: RouteProps, params: { [key: string]: string } = {}) => {
    let toRoute = to;
    const routeMap = Object.values(JUNO_ROUTE_MAP).filter((route) => route.key === to.key);
    if (routeMap.length > 0) {
      toRoute = routeMap[0];
    }
    if (!isWidget) {
      const route = toRoute.params.reduce((acc, param) => {
        return acc.replace(`:${param}`, params[param]);
      }, toRoute.route);
      navigateTo(route);
    } else {
      onNavigate && onNavigate({ key: toRoute.key, params });
    }
  };
  return navigate;
};

// VNEXT SHELL ROUTING ONLY

// These are static for now, but we can improve it to config these paths to give clients the option to change them.

export const ROUTES = {
  course: '/:siteSlug/learning/courses/:slug',
  group: '/:siteSlug/community/groups/:slug',
  page: '/:siteSlug/:slug',
  library: '/:siteSlug/library/:slug',
  session: '/:siteSlug/sessions/:slug',
  user: '/:siteSlug/user/:slug',
  speaker: '/:siteSlug/speaker/:slug',
};
export const ADMIN_ROUTES = {
  course: '/:siteSlug/admin/learning/:slug',
  group: '/:siteSlug/admin/community/groups/:slug',
  page: '/:siteSlug/admin/pages/:slug',
  library: '/:siteSlug/admin/library/:slug',
  session: '/:siteSlug/admin/sessions/:slug',
  speaker: '/:siteSlug/admin/speakers/:slug',
  user: '',
};
export const getContentRoute = (
  siteSlug: string,
  slug: string,
  contentType: SearchContentTypeEnum,
  admin = false,
) => {
  if (!siteSlug || !slug || !contentType) return '';
  if (admin) {
    return ADMIN_ROUTES[contentType].replace(':siteSlug', siteSlug).replace(':slug', slug);
  }
  return ROUTES[contentType].replace(':siteSlug', siteSlug).replace(':slug', slug);
};
