import React, { useContext, useEffect } from 'react';
import { Box, Button, Card, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Question } from '@juno/client-api/model';

interface MultipleChoiceProps {
  question: Question;
  handleSetAnswer: (answer: string[]) => void;
  answer: string[];
}
const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
const AnswerContainer = styled(Grid)(({ theme }) => ({
  // padding: theme.spacing(1),
}));
const AnswerTypography = styled(Typography)(({ theme }) => ({
  flex: '100%',
  flexGrow: 1,
  marginRight: theme.spacing(3),
  padding: theme.spacing(2),
}));
const AnswerLetter = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.primary.light}`,
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const AnswerButton = styled(Button)(({ theme }) => ({
  padding: 0,
  width: '100%',
  borderRadius: '30px',
  textTransform: 'none',
  display: 'flex',
  position: 'relative',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
}));

export default function MultipleChoice({
  question,
  answer,
  handleSetAnswer,
}: MultipleChoiceProps): React.ReactElement {
  const { answers, name, answer_changeable, metadata } = question;

  const onClickAnswer = (id: string) => {
    const isMultiSelect = answers.filter((a) => a?.points && a.points > 0).length > 1;
    if (isMultiSelect) {
      if (answer.includes(id)) {
        handleSetAnswer(answer.filter((singleAnswer) => singleAnswer !== id));
      } else {
        handleSetAnswer([...answer, id]);
      }
    } else {
      handleSetAnswer([id]);
    }
  };
  return (
    <Grid container columnSpacing={1} rowSpacing={'12px'}>
      {answers.map((a, index) => {
        const isSelected = answer.includes(a.id);
        return (
          <AnswerContainer item xs={12} sm={6} md={6} key={`answer_${index}`}>
            <AnswerButton
              variant={isSelected ? 'outlined' : 'contained'}
              onClick={(e) => onClickAnswer(a.id)}
            >
              <AnswerLetter>{alphabet[index]}</AnswerLetter>
              <AnswerTypography>{a.title}</AnswerTypography>
            </AnswerButton>
          </AnswerContainer>
        );
      })}
    </Grid>
  );
}
