import React, { useMemo } from 'react';
import {
  BookmarkBorder,
  BookmarkOutlined,
  ChatBubbleOutline,
  EditCalendarOutlined,
  GroupsOutlined,
} from '@mui/icons-material';
import { Avatar, Badge, Box, Card, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { JunoUser, UserSettings } from '@juno/client-api/model';
import { useBreakpoint, useSettings } from '@juno/utils';

interface ExtendedUser extends JunoUser {
  settings: UserSettings;
}
interface DirectoryTileProps {
  user: JunoUser;
  selectUser: (user: JunoUser, e: React.MouseEvent) => void;
  startMeeting: (user: JunoUser) => void;
  startChat: (user: JunoUser) => void;
  saveUser: (user: JunoUser) => void;
  isSaved?: boolean;
  messengerDisabled?: boolean;
}
const DirectoryTile: React.FC<DirectoryTileProps> = ({
  user,
  selectUser,
  startMeeting,
  startChat,
  saveUser,
  isSaved = false,
  messengerDisabled,
}) => {
  const { user: currentUser } = useSettings();
  const { xs } = useBreakpoint();
  const isCurrentUser = user.id === currentUser?.id;
  const userName = isCurrentUser
    ? `${user.first_name} ${user.last_name} (You)`
    : `${user.first_name} ${user.last_name}`;

  const numSharedGroups = useMemo(() => {
    if (isCurrentUser) return 0;
    const myGroups = currentUser?.community_groups_member || [];
    const theirGroups = user.community_groups_member || [];
    return myGroups.filter((g) => theirGroups.includes(g)).length;
  }, [user, currentUser]);
  return (
    <Card sx={{ p: 1, width: '100%' }}>
      <Grid container>
        <Grid item xs={xs ? 8 : 5}>
          <Box display={'flex'} onClick={(e) => selectUser(user, e)} sx={{ cursor: 'pointer' }}>
            <Avatar alt={userName} src={user.avatar || ''} sx={{ width: 56, height: 56, mr: 2 }} />
            <Box>
              <Typography>{userName}</Typography>
              <Typography variant='subtitle2'>{user.company}</Typography>
            </Box>
          </Box>
        </Grid>
        {!xs && (
          <Grid item xs={4} alignItems={'center'} display={'flex'}>
            {numSharedGroups > 0 && (
              <>
                <Typography>Shared Groups:</Typography>
                <GroupsOutlined sx={{ fontSize: '30px', ml: 1 }} />
                <Badge badgeContent={numSharedGroups} color='primary' sx={{ ml: 2 }} />
              </>
            )}
          </Grid>
        )}
        <Grid
          item
          xs={xs ? 4 : 3}
          alignItems={'center'}
          display={'flex'}
          justifyContent={'flex-end'}
        >
          <Tooltip title='Start a meeting'>
            <span>
              <IconButton disabled={isCurrentUser} onClick={() => startMeeting(user)}>
                <EditCalendarOutlined sx={{ fontSize: '24px' }} />
              </IconButton>
            </span>
          </Tooltip>
          {!messengerDisabled && (
            <Tooltip title='Start a chat'>
              <span>
                <IconButton
                  disabled={isCurrentUser || !(user as ExtendedUser).settings?.private_messaging}
                  onClick={() => startChat(user)}
                >
                  <ChatBubbleOutline sx={{ fontSize: '24px' }} />
                </IconButton>
              </span>
            </Tooltip>
          )}

          <Tooltip title={isSaved ? 'Remove contact' : 'Save contact'}>
            <span>
              <IconButton disabled={isCurrentUser} onClick={() => saveUser(user)}>
                {isSaved ? (
                  <BookmarkOutlined color='primary' sx={{ fontSize: '24px' }} />
                ) : (
                  <BookmarkBorder sx={{ fontSize: '24px' }} />
                )}
              </IconButton>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </Card>
  );
};
export default DirectoryTile;
