import CircleIcon from '@mui/icons-material/Circle';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';

interface HeaderModel {
  key: string;
  headerName: string;
}
interface HeaderConfirmationDialogProps {
  handleClose: () => void;
  missingHeaders?: HeaderModel[];
  extraHeaders?: HeaderModel[];
  handleClear: () => void;
}

const HeaderConfirmationDialog: React.FC<HeaderConfirmationDialogProps> = ({
  handleClose,
  missingHeaders,
  extraHeaders,
  handleClear,
}) => {
  const handleButton = () => {
    handleClear();
    handleClose();
  };

  const handleIgnore = () => {
    handleClose();
  };

  const hasMissingHeaders =
    missingHeaders && missingHeaders.length > 0 && missingHeaders[0].key != 'rowId';
  const hasExtraHeaders = extraHeaders && extraHeaders.length > 0 && extraHeaders[0].key != 'rowId';

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle sx={{ p: '20px', borderBottom: '1px solid #595959' }}>
        {hasMissingHeaders ? 'Required Header(s) Missing' : 'Extra Headers Found'}
      </DialogTitle>
      <DialogContent sx={{ background: '#EEEEEE' }}>
        <DialogContentText sx={{ p: '44px 64px' }} component='div'>
          {hasMissingHeaders && (
            <div>
              <Typography>Your csv is missing the following required headers:</Typography>
              <List dense={true}>
                {missingHeaders?.map(
                  (header: HeaderModel) =>
                    header.key != 'rowId' && (
                      <ListItem key={`${header.key}_key`}>
                        <CircleIcon sx={{ fontSize: 4, mr: 1 }} />
                        <ListItemText key={header.key} primary={header.headerName} />
                      </ListItem>
                    ),
                )}
              </List>
            </div>
          )}
          {hasExtraHeaders && (
            <div>
              <Typography sx={{ mb: 1 }}>
                The following headers from your csv are not supported. These columns will not be
                included in the data imported.
              </Typography>
              <List dense={true}>
                {extraHeaders?.map(
                  (header: HeaderModel) =>
                    header.key != 'rowId' && (
                      <ListItem key={`${header.key}_key`}>
                        <CircleIcon sx={{ fontSize: 4, mr: 1 }} />
                        <ListItemText key={header.key} primary={header.headerName} />
                      </ListItem>
                    ),
                )}
              </List>
            </div>
          )}

          <Typography sx={{ mt: 3 }}>
            {hasMissingHeaders && hasExtraHeaders
              ? 'Please update your csv to ensure all required columns exist and then try uploading again.'
              : hasMissingHeaders
              ? 'Please update your csv to ensure these columns exist and then try uploading again.'
              : 'If you see a mistake, please update your csv and try uploading again'}
          </Typography>
        </DialogContentText>
      </DialogContent>
      {hasMissingHeaders ? (
        <DialogActions sx={{ p: '16px', borderTop: '1px solid #595959' }}>
          <Button variant='contained' fullWidth={true} onClick={handleButton}>
            Ok
          </Button>
        </DialogActions>
      ) : (
        <DialogActions sx={{ p: '16px', borderTop: '1px solid #595959' }}>
          <Button variant='contained' fullWidth={true} onClick={handleIgnore}>
            Ok
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default HeaderConfirmationDialog;
