import axios from 'axios';
import { EMPTY_COURSE_URL, MAX_IMAGE_UPLOAD_SIZE } from '@juno/constants';
import { optimizeImage } from '../FormUtils';

const CLOUD_NAME = process.env.NX_CLOUDINARY_CLOUD_NAME || '';
const PRESET_NAME = process.env.NX_CLOUDINARY_UPLOAD_PRESET_NAME || '';
const CLOUDINARY_UPLOAD_API_URL = `https://api.cloudinary.com/v1_1/${CLOUD_NAME}/image/upload`;

type ProgressFn = (percent: number) => void;
interface BlobInfo {
  id: () => string;
  name: () => string;
  filename: () => string;
  blob: () => Blob;
  base64: () => string;
  blobUri: () => string;
  uri: () => string | undefined;
}

const JunoImage = {
  fake: (id: string, url: string | null | undefined) => ({
    id,
    created: '',
    name: '',
    alt: url || 'image',
    width: 200,
    height: 200,
    size: 1000,
    type: 'jpg',
    url: url || '',
    thumb_url: url || '',
  }),
};

export const uploadImagesToCloudinary = async (files: File[], progress?: ProgressFn) => {
  const data = new FormData();
  files.forEach((file) => data.append('file', file));
  data.append('upload_preset', PRESET_NAME);
  data.append('cloud_name', CLOUD_NAME);
  const response = await axios.post(CLOUDINARY_UPLOAD_API_URL, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: function ({ loaded, total }) {
      progress && progress(Math.floor((loaded / total) * 100));
    },
  });
  const responseJson = await response.data;
  return JunoImage.fake('some_image', responseJson.secure_url);
};

export const uploadTinyMceImageCloudinary = (blobInfo: BlobInfo, progress: ProgressFn) =>
  new Promise<string>((resolve, reject) => {
    const imageSize = blobInfo.blob().size;

    if (imageSize > MAX_IMAGE_UPLOAD_SIZE) {
      return reject({
        message: 'File is too big! Maximum upload file size is 10MB!',
        remove: true,
      });
    }

    const data = new FormData();
    data.append('file', blobInfo.blob(), blobInfo.filename());
    data.append('upload_preset', PRESET_NAME);
    data.append('cloud_name', CLOUD_NAME);

    axios
      .post(CLOUDINARY_UPLOAD_API_URL, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function ({ loaded, total }) {
          progress(Math.floor((loaded / total) * 100));
        },
      })
      .then((response) => {
        resolve(optimizeImage(450, response.data.secure_url || EMPTY_COURSE_URL));
      })
      .catch((err) => {
        reject({
          message: err.response.data.error.message,
          remove: true,
        });
      });
  });
