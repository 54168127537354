import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useDebounce } from 'usehooks-ts';
import { Component as ComponentModel, FeatureConfigTypeEnum } from '@juno/client-api/model';
import { RotatorSettings, buildRotatorSettings } from '@juno/ui';
import { useSettings } from '@juno/utils';
import FilterDialog from './FilterDialog';
import { FormControlRow } from './styles';

interface ModuleEditFormProps {
  siteId: string;
  component: ComponentModel;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
}

const SpeakersEditForm = ({ siteId, component, onUpdate, onSave }: ModuleEditFormProps) => {
  const [payload, setPayload] = useState<ComponentModel>(component);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const debouncedPayload = useDebounce(payload, 200);
  const { enableLearning } = useSettings();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => debouncedPayload && onUpdate({ ...debouncedPayload }), [debouncedPayload]);
  const settingsObj = useMemo(() => buildRotatorSettings(payload?.data), [payload]);

  const changeHandler = {
    checkbox: ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
      setPayload({ ...payload, data: { ...payload.data, [name]: checked } });
    },
    text: ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setPayload({ ...payload, data: { ...payload.data, [name]: value } });
    },
    number: ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setPayload({ ...payload, data: { ...payload.data, [name]: parseInt(value || '0') } });
    },
    componentText: ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      setPayload({ ...payload, [name]: value });
    },
    filterDialog: (settings: RotatorSettings) => {
      setPayload({ ...payload, data: { ...settings } });
      setIsFilterDialogOpen(false);
    },
  };

  return (
    <>
      <Typography variant={'body2'} sx={{ opacity: 0.6 }}>
        Editing Module
      </Typography>
      <Typography variant='h5' sx={{ textTransform: 'capitalize', mb: 2 }}>
        Rotator
      </Typography>
      <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
        <TextField
          fullWidth
          id='name'
          name='name'
          label='Name'
          value={payload?.name || ''}
          onChange={changeHandler.componentText}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        />
      </FormControl>
      <Typography sx={{ mt: 1, mb: 1 }}>Data</Typography>
      <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
        <TextField
          id='dataModel'
          name='dataModel'
          select
          label='Content Type'
          value={settingsObj.dataModel}
          onChange={changeHandler.text}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        >
          {enableLearning && <MenuItem value='Course'>Course</MenuItem>}
          <MenuItem value='Group'>Group</MenuItem>
          <MenuItem value='Library'>Library</MenuItem>
          <MenuItem value='Session'>Session</MenuItem>
          <MenuItem value='Speaker'>Speaker</MenuItem>
        </TextField>
      </FormControl>
      {settingsObj.dataModel !== 'Speaker' && (
        <Button variant='outlined' fullWidth onClick={() => setIsFilterDialogOpen(true)}>
          Filter results
        </Button>
      )}
      <Typography sx={{ mt: 2, mb: 1 }}>Design</Typography>
      <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
        <TextField
          fullWidth
          id='title'
          name='title'
          label='Title'
          defaultValue={settingsObj.title}
          onChange={changeHandler.text}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        />
      </FormControl>
      <FormGroup sx={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <FormControlLabel
          control={
            <Checkbox
              name='arrows'
              checked={settingsObj.arrows}
              onChange={changeHandler.checkbox}
            />
          }
          label='Arrows'
        />
        <FormControlLabel
          control={
            <Checkbox name='dots' checked={settingsObj.dots} onChange={changeHandler.checkbox} />
          }
          label='Dots'
        />
        <FormControlLabel
          control={
            <Checkbox
              name='showTitle'
              checked={settingsObj.showTitle}
              onChange={changeHandler.checkbox}
            />
          }
          label='Show Title'
        />
        <FormControlLabel
          control={
            <Checkbox
              name='showPauseButton'
              checked={settingsObj.showPauseButton}
              onChange={changeHandler.checkbox}
            />
          }
          label='Show Pause'
        />
      </FormGroup>
      <FormControlRow fullWidth>
        <TextField
          id='slideVariant'
          name='slideVariant'
          select
          label='Variant'
          value={settingsObj.slideVariant}
          onChange={changeHandler.text}
          sx={{ width: '100%' }}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        >
          <MenuItem value='circular'>Circular</MenuItem>
          <MenuItem value='rounded'>Rounded</MenuItem>
          <MenuItem value='square'>Square</MenuItem>
        </TextField>
        <TextField
          id='textAlign'
          name='textAlign'
          select
          label='Text Align'
          value={settingsObj.textAlign}
          onChange={changeHandler.text}
          sx={{ width: '100%' }}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        >
          <MenuItem value='center'>center</MenuItem>
          <MenuItem value='left'>left</MenuItem>
          <MenuItem value='right'>right</MenuItem>
        </TextField>
      </FormControlRow>
      <FormControlRow fullWidth>
        <TextField
          fullWidth
          id='slideWidth'
          name='slideWidth'
          label='Slide Width'
          type='number'
          value={settingsObj.slideWidth}
          onChange={changeHandler.number}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        />
        <TextField
          fullWidth
          id='slideHeight'
          name='slideHeight'
          label='Slide Height'
          type='number'
          value={settingsObj.slideHeight}
          onChange={changeHandler.number}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        />
        <TextField
          fullWidth
          id='minSpacing'
          name='minSpacing'
          label='Spacing'
          type='number'
          value={settingsObj.minSpacing}
          onChange={changeHandler.number}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        />
      </FormControlRow>
      <FormGroup sx={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <FormControlLabel
          control={
            <Checkbox
              name='showItemTitle'
              checked={settingsObj.showItemTitle}
              onChange={changeHandler.checkbox}
            />
          }
          label='Slide Title'
        />
        <FormControlLabel
          control={
            <Checkbox
              name='showItemDescription'
              checked={settingsObj.showItemDescription}
              onChange={changeHandler.checkbox}
            />
          }
          label='Slide Description'
        />
        <FormControlLabel
          control={
            <Checkbox
              name='showProgress'
              checked={settingsObj.showProgress}
              onChange={changeHandler.checkbox}
            />
          }
          label='Show Progress'
        />
      </FormGroup>
      <FilterDialog
        siteId={siteId}
        settings={settingsObj}
        open={isFilterDialogOpen}
        handleClose={() => setIsFilterDialogOpen(false)}
        onSubmit={(settings: RotatorSettings) => {
          onUpdate({ ...payload, data: { ...settings } });
          setIsFilterDialogOpen(false);
        }}
      />
    </>
  );
};

export default SpeakersEditForm;
