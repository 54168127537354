import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface ExporterContentDropdownProps {
  totalOptions: string[];
  selectedOptions: string[];
  setSelectedOptions: Dispatch<SetStateAction<string[]>>;
  optionsLabel: string | null;
}
export const ExporterContentDropdown: React.FC<ExporterContentDropdownProps> = ({
  totalOptions,
  selectedOptions,
  setSelectedOptions,
  optionsLabel,
}) => {
  return (
    <Grid container spacing={2} sx={{ mt: 4, borderColor: 'primary.main' }}>
      <Autocomplete
        multiple
        id='export-options'
        options={totalOptions}
        disableCloseOnSelect
        disablePortal
        getOptionLabel={(option) => option}
        value={selectedOptions}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          value: string[],
          option: string,
        ) => {
          if (value.length === 1 && value[0] === '') {
            setSelectedOptions([]);
          } else {
            setSelectedOptions(value);
          }
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        style={{ width: `100%` }}
        renderInput={(params) => <TextField {...params} label={optionsLabel} />}
      />
    </Grid>
  );
};
