import React from 'react';
import { Grid } from '@mui/material';
import FilterChip from '../FilterChip';

interface FilterChipProps {
  label: string;
  onDelete: () => void;
  enabled: boolean;
}

const FilterGridChip: React.FC<FilterChipProps> = ({ label, onDelete, enabled }) => {
  return enabled ? (
    <Grid item>
      <FilterChip label={label} onDelete={onDelete} />
    </Grid>
  ) : null;
};

export default FilterGridChip;
