import React, { useEffect, useState } from 'react';
import { Box, Card, List, ListItem, Pagination, Skeleton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  useGetAllUsers,
  useGetMyRecommendedUsers,
  useGetSavedUsers,
  useGetUserCount,
  useSaveUser,
} from '@juno/client-api';
import { JunoUser, SearchContentTypeEnum, UserRecommendation } from '@juno/client-api/model';
import { FeatureConfig as FeatureConfigUtils } from '@juno/client-api/utils';
import {
  Container,
  CreatePrivateMeetingDialog,
  DefaultSearchSortFilter,
  JunoRotator,
  UserQuickView,
} from '@juno/ui';
import {
  MutationAction,
  getContentRoute,
  onMutation,
  useMessengerContext,
  useSearchFilter,
  useSettings,
} from '@juno/utils';
import { messengerTabMap } from '../Messenger/index';
import DirectoryTile from './DirectoryTile';
import { DirectoryDataModel } from './form';

enum DirectorySort {
  FIRSTNAME = 'first_name',
  LASTNAME = 'last_name',
  EMAIL = 'email',
}
const SAVED_PAGE_SIZE = 5;
interface DirectoryProps {
  usersData: JunoUser[];
  totalUsersCount: number;
  usersPerPage: number;
  isFetching: boolean;
  isLoading: boolean;
  recommendedUsers?: UserRecommendation[];
  savedUsersData?: JunoUser[];
  refetchSavedUsers: () => void;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSort: React.Dispatch<React.SetStateAction<DirectorySort>>;
  sort: DirectorySort;
  search: string | null;
  setSearch: React.Dispatch<React.SetStateAction<string | null>>;
  showSavedUsers?: boolean;
  showRecommendedUsers?: boolean;
}
const Directory: React.FC<DirectoryProps> = ({
  isFetching,
  isLoading,
  usersData,
  totalUsersCount,
  recommendedUsers,
  savedUsersData,
  refetchSavedUsers,
  page,
  setPage,
  setSort,
  sort,
  search,
  setSearch,
  usersPerPage = 10,
  showSavedUsers = true,
  showRecommendedUsers = true,
}) => {
  const { site, user: currentUser, setShowMessenger, configs } = useSettings();
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState<JunoUser | null>(null);
  const [newMeetingOpen, setNewMeetingOpen] = useState(false);
  const [savedUsers, setSavedUsers] = useState<JunoUser[]>([]);
  const [savedUserPage, setSavedUserPage] = useState(1);
  const [userQuickViewAnchorEl, setUserQuickViewAnchorEl] = useState<null | HTMLElement>(null);
  const [quickViewUser, setQuickViewUser] = useState<string | null>(null);

  useEffect(() => {
    if (savedUsersData) {
      setSavedUsers(savedUsersData);
    }
  }, [savedUsersData]);

  const handleChangeSavedUserPage = (event: React.ChangeEvent<unknown>, value: number) => {
    setSavedUserPage(value);
  };

  // Calculate the range of items to display based on the current page and the number of items per page for saved users
  const startIndex = (savedUserPage - 1) * SAVED_PAGE_SIZE;
  const endIndex = startIndex + SAVED_PAGE_SIZE;
  const currentItems = savedUsers
    ?.sort((a, b) => a.first_name.localeCompare(b.first_name))
    .slice(startIndex, endIndex);

  const saveUser = useSaveUser(onMutation(MutationAction.CREATE, '', refetchSavedUsers));
  const messengerDisabled =
    (FeatureConfigUtils.getThemeConfig(configs)?.config?.hide_messenger as boolean) || false;
  const handleSaveUser = (user: JunoUser) => {
    if (isLoading || isFetching) return;
    if (!savedUsers?.some((u) => u.id === user.id)) {
      setSavedUsers([...savedUsers, user]);
    } else {
      setSavedUsers(savedUsers.filter((u) => u.id !== user.id));
    }
    try {
      saveUser.mutate({ siteId: site?.id || '', userId: user.id });
    } catch (e) {
      console.error(e);
      // if we error out, we should revert the change
      if (!savedUsers?.some((u) => u.id === user.id)) {
        setSavedUsers(savedUsers.filter((u) => u.id !== user.id));
      } else {
        setSavedUsers([...savedUsers, user]);
      }
    }
  };

  const { createChannelThenSubscribe, setMessengerTab, setIsConversation, setCurrentChannel } =
    useMessengerContext();

  const startChat = (user: JunoUser) => {
    if (!currentUser) return;
    setShowMessenger && setShowMessenger(true);
    createChannelThenSubscribe([user.id, currentUser?.id]).then((channelId) => {
      if (channelId) {
        setMessengerTab(messengerTabMap.Messenger);
        setCurrentChannel(channelId);
        setIsConversation(true);
      }
    });
  };
  return (
    <Container>
      <Box pt={1}>
        <Box mb={3}>
          {showSavedUsers && (
            <Card sx={{ p: 0, boxShadow: 0, bgcolor: 'transparent', mb: 3 }}>
              <Typography variant='overline'>Saved Contacts</Typography>
              {savedUsers?.length === 0 ? (
                <Typography variant='body2' textAlign={'center'}>
                  You have no saved contacts.
                </Typography>
              ) : (
                <>
                  <List>
                    {currentItems?.map((user, idx) => (
                      <ListItem key={idx} sx={{ pl: 0, pr: 0, pb: 0 }}>
                        <DirectoryTile
                          user={user as JunoUser}
                          selectUser={(user) => navigate(`/${site?.slug}/user/${user.id}`)}
                          startMeeting={(user) => {
                            setSelectedUser(user);
                            setNewMeetingOpen(true);
                          }}
                          startChat={(user) => startChat(user)}
                          saveUser={(user) => handleSaveUser(user)}
                          isSaved={true}
                          messengerDisabled={messengerDisabled}
                        />
                      </ListItem>
                    ))}
                  </List>
                  {savedUsers && savedUsers.length > SAVED_PAGE_SIZE && (
                    <Box display={'flex'} justifyContent={'center'}>
                      <Pagination
                        count={Math.ceil(savedUsers.length / SAVED_PAGE_SIZE)}
                        page={savedUserPage}
                        variant='outlined'
                        shape='rounded'
                        onChange={handleChangeSavedUserPage}
                      />
                    </Box>
                  )}
                </>
              )}
            </Card>
          )}
          {recommendedUsers && recommendedUsers.length > 0 && showRecommendedUsers && (
            <Card sx={{ boxShadow: 1, p: 2 }}>
              <JunoRotator
                onSelect={(item) =>
                  navigate(
                    getContentRoute(site?.slug || '', item?.id || '', SearchContentTypeEnum.user),
                  )
                }
                settings={{
                  title: 'Recommended Users',
                  slideHeight: 90,
                  slideWidth: 90,
                }}
                slides={
                  recommendedUsers?.map((user) => {
                    return {
                      id: user.user.id,
                      slug: user.user.id,
                      name: `${user.user.first_name} ${user.user.last_name}`,
                      description: user.user.email,
                      image: user.user.icon || '',
                    };
                  }) || []
                }
              />
            </Card>
          )}
        </Box>

        <Typography variant='overline' mb={2}>
          Full Directory
        </Typography>
        <DefaultSearchSortFilter
          showButton={false}
          buttonDisabled={true}
          buttonText={''}
          onClickButton={() => {}}
          setSearch={(value) => {
            setPage(0);
            setSearch(value);
          }}
          sort={sort}
          setSort={(value) => {
            setPage(0);
            setSort(value as DirectorySort);
          }}
          sortOptions={[
            { value: DirectorySort.FIRSTNAME, label: 'First Name' },
            { value: DirectorySort.LASTNAME, label: 'Last Name' },
            { value: DirectorySort.EMAIL, label: 'Email' },
          ]}
          showFilter={false}
        />
      </Box>
      <Box>
        <List>
          {usersData.map((user, idx) => (
            <ListItem sx={{ pl: 0, pr: 0, pb: 0 }} key={`user-${idx}`}>
              <DirectoryTile
                user={user as JunoUser}
                selectUser={(user, e) => {
                  setQuickViewUser(user.id);
                  setUserQuickViewAnchorEl(e.target as HTMLElement);
                }}
                startMeeting={(user) => {
                  setSelectedUser(user);
                  setNewMeetingOpen(true);
                }}
                startChat={(user) => {
                  startChat(user);
                }}
                saveUser={(user) => handleSaveUser(user)}
                isSaved={savedUsers?.some((u) => u.id === user.id)}
                messengerDisabled={messengerDisabled}
              />
            </ListItem>
          ))}
        </List>
        {isLoading && (
          <>
            <Skeleton sx={{ transform: 'scale(1, 0.90)' }} height={80} />
            <Skeleton sx={{ transform: 'scale(1, 0.90)' }} height={80} />
            <Skeleton sx={{ transform: 'scale(1, 0.90)' }} height={80} />
            <Skeleton sx={{ transform: 'scale(1, 0.90)' }} height={80} />
            <Skeleton sx={{ transform: 'scale(1, 0.90)' }} height={80} />
          </>
        )}
        {!isLoading && usersData?.length === 0 && search !== '' && (
          <Typography textAlign={'center'} pt={1} pb={3}>
            No results match your search
          </Typography>
        )}
        <Box display={'flex'} justifyContent={'center'}>
          <Pagination
            count={Math.ceil(totalUsersCount / usersPerPage)}
            page={page + 1}
            variant='outlined'
            shape='rounded'
            onChange={(event, value) => {
              setPage(value - 1 < 0 ? 0 : value - 1);
            }}
          />
        </Box>
      </Box>
      <CreatePrivateMeetingDialog
        open={newMeetingOpen}
        handleClose={() => setNewMeetingOpen(false)}
        selectedUser={selectedUser}
      />
      <UserQuickView
        anchorEl={userQuickViewAnchorEl}
        userId={quickViewUser || ''}
        open={Boolean(userQuickViewAnchorEl)}
        setAnchorEl={setUserQuickViewAnchorEl}
        onViewProfile={(userEmail: string): void => {
          navigate(
            getContentRoute(site?.slug || '', quickViewUser || '', SearchContentTypeEnum.user),
          );
        }}
        onMessageUser={function (userId: string): void {
          throw new Error('Function not implemented.');
        }}
      />
    </Container>
  );
};
export default Directory;

interface DirectoryModuleProps {
  component?: DirectoryDataModel;
}
export const DirectoryModule: React.FC<DirectoryModuleProps> = ({ component }) => {
  const { site, platform } = useSettings();
  const [debouncedSearch, search, filter, setSearch] = useSearchFilter();
  const [sort, setSort] = useState(DirectorySort.FIRSTNAME);
  const [page, setPage] = useState(0);
  const filterByInterests = component?.interestsFilter?.map((tag) => tag.id) || [];
  const filterBySkills = component?.skillsFilter?.map((tag) => tag.id) || [];
  let formFilter = {};
  if (filterByInterests.length > 0) {
    formFilter = {
      interests__id__in: filterByInterests,
    };
  }
  if (filterBySkills.length > 0) {
    formFilter = {
      ...formFilter,
      skills__id__in: filterBySkills,
    };
  }
  const {
    data: usersData,
    isLoading: isLoadingUsers,
    isFetching: fetchingUsers,
  } = useGetAllUsers(
    platform?.id || '',
    {
      filter_or: formFilter,
      ...filter,
      order:
        sort === DirectorySort.FIRSTNAME
          ? 'first_name'
          : sort === DirectorySort.LASTNAME
          ? 'last_name'
          : 'email',
      limit: component?.numPerPage || 10,
      offset: page,
      include: 'settings',
      exclude_or: {
        settings__private_profile: true,
      },
    },
    {
      query: { enabled: !!platform?.id && !!component },
    },
  );

  const {
    data: savedUsersData,
    isLoading: isLoadingSavedUsers,
    isFetching: isFetchingSavedUsers,
    refetch: refetchSavedUsers,
  } = useGetSavedUsers(
    site?.id || '',
    {
      order: '-first_name',
      include: 'settings',
    },
    { query: { enabled: !!site?.id && component?.showSavedUsers } },
  );

  const { data: recommendedUsers, isLoading: isLoadingRecommendations } = useGetMyRecommendedUsers(
    platform?.id || '',
    { include: 'settings' },
    { query: { enabled: !!platform?.id && component?.showRecommendedUsers } },
  );

  const { data: allUsersCount, isLoading: isLoadingAllUsersCount } = useGetUserCount(
    site?.id || '',
    { filter: formFilter, ...filter },
  );

  const isLoading =
    isLoadingUsers || isLoadingSavedUsers || isLoadingRecommendations || isLoadingAllUsersCount;

  return (
    <>
      {component?.title && (
        <Typography variant='h5' p={2}>
          {component.title}
        </Typography>
      )}
      <Directory
        usersPerPage={component?.numPerPage || 10}
        totalUsersCount={allUsersCount?.count || 1}
        usersData={usersData || []}
        isLoading={isLoading}
        recommendedUsers={recommendedUsers}
        savedUsersData={savedUsersData}
        refetchSavedUsers={refetchSavedUsers}
        isFetching={fetchingUsers}
        search={search}
        setSearch={setSearch}
        setPage={setPage}
        page={page}
        setSort={setSort}
        sort={sort}
        showSavedUsers={component?.showSavedUsers ?? true}
        showRecommendedUsers={component?.showRecommendedUsers ?? true}
      />
    </>
  );
};
