import { useGetCommunityGroups } from '@juno/client-api';
import { JunoUser, PrivacyEnum } from '@juno/client-api/model';
import { filterObjectBySubstring } from '../Content/utils';

const NOW = new Date().toISOString();

export const useGroupsHook = (
  siteId: string,
  settings: any,
  dataModel: string,
  filter: any,
  filterStr: string,
  debouncedSearch: string | undefined = undefined,
) => {
  const trimmedFilter = filterObjectBySubstring(filter, [
    'preview_text',
    'id',
    'list_image',
    'title',
    'site',
    'site_id',
    'slug',
    'tags',
    'user',
    'user_id',
    'members__id__in',
    'date_released__lte',
    'date_released__gte',
    'date_end__lte',
    'date_end__gte',
  ]);

  // Defaults
  // If the date end filter is not set, then exclude date end in the past (allows date_end=null)
  const excludeInvalidDateEnd = !(
    'date_end__gte' in trimmedFilter || 'date_end__lte' in trimmedFilter
  )
    ? { date_end__lte: NOW }
    : {};

  // we usually don't want to include secret groups UNLESS i'm a member or this grid is filtered to secret
  const excludeSecretGroup =
    !settings?.filterOnlyMyGroups &&
    dataModel === 'Group' &&
    settings.filterByPrivacyLevel !== PrivacyEnum.secret
      ? { privacy__iexact: 'secret' }
      : {};
  const excludeTagsGroup =
    settings?.excludeTags && settings.excludeTags.length > 0
      ? { tags__id__in: settings.excludeTags }
      : {};
  const excludeGroup = { ...excludeSecretGroup, ...excludeTagsGroup, ...excludeInvalidDateEnd };
  const privacyFilter = settings?.filterByPrivacyLevel
    ? { privacy__iexact: settings.filterByPrivacyLevel }
    : {};
  const filterOrTagsGroup =
    settings?.optionalTags && settings.optionalTags.length > 0
      ? { tags__id__in: settings.optionalTags }
      : {};
  const filterOrGroup = { ...filterOrTagsGroup };
  const filterAndTagsGroup =
    settings?.requireTags && settings.requireTags.length > 0
      ? { tags__id__in: settings.requireTags }
      : {};
  const filterAndGroup = { ...filterAndTagsGroup };
  const { data: communityGroups, isLoading: groupsLoading } = useGetCommunityGroups(
    siteId,
    {
      order: settings?.order,
      filter: {
        ...trimmedFilter,
        title__icontains: debouncedSearch,
        ...privacyFilter,
      },
      exclude_or: excludeGroup,
      filter_or: filterOrGroup,
      filter_and: filterAndGroup,
    },
    { query: { enabled: dataModel === 'Group' && !filterStr.includes('{{USER_ID}}') } },
  );
  return {
    groupsLoading,
    communityGroups,
  };
};

export const handleGroupFilter = (settings: any, filter: any, userData?: JunoUser) => {
  // Fill filter my groups with the user's id if set
  if (settings?.filterOnlyMyGroups) {
    return {
      ...filter,
      members__id__in: [userData?.id],
    };
  }
  return filter;
};

export const setDefaultGroupFilters = (existingFilter: { [key: string]: string | boolean }) => {
  if (!('date_released__lte' in existingFilter)) {
    existingFilter['date_released__lte'] = NOW;
  }
};
