import React, { useMemo } from 'react';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useFeatureFlag } from 'configcat-react';
import { Form, Formik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useDeleteCommunityGroup,
  useGetCommunityGroup,
  useUpdateCommunityGroup,
} from '@juno/client-api';
import {
  FeatureConfig,
  FeatureConfigConfig,
  FeatureConfigTypeEnum,
  RoleEnum,
  Site as SiteModel,
} from '@juno/client-api/model';
import { AdminEditPanelHeader, Container, DialogAriaWrapper, SaveBar } from '@juno/ui';
import { MutationAction, a11yProps, onMutation, useSettings } from '@juno/utils';
import GroupFilesTab from '../../shared/GroupFilesTab';
import ForumFeedSettings from './ForumFeedSettings';
import GroupInfo from './GroupInfo';
import GroupMembers from './GroupMembers';
import validationSchema from './ValidationSchema';

const MODEL = 'Group';
interface CommunityGroupPanelProps {
  site: SiteModel;
}

export interface GroupHierarchyFeatureConfigModel extends FeatureConfig {
  config: GroupHierarchyConfigModel;
}
export interface GroupHierarchyConfigModel extends FeatureConfigConfig {
  group_hierarchy?: boolean;
}

const headerSkeletons = (
  <Grid container spacing={2} mt={4}>
    <Grid item xs={1}>
      <Skeleton variant='circular' height={50} width={50} sx={{ mt: 2 }} />
    </Grid>
    <Grid item xs={6}>
      <Skeleton variant='rectangular' height={20} sx={{ mb: 1 }} width={80} />
      <Skeleton variant='rectangular' height={50} />
    </Grid>
  </Grid>
);

const skeletons = (
  <Container>
    <Grid container spacing={2} mt={4}>
      <Grid item xs={12}>
        <Skeleton variant='rectangular' height={60} />
      </Grid>
      <Grid item xs={9}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Skeleton variant='rectangular' height={40} />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant='rectangular' height={40} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant='rectangular' height={40} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant='rectangular' height={400} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant='rectangular' height={60} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant='rectangular' height={60} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Container>
);

const CommunityGroupPanel: React.FC<CommunityGroupPanelProps> = ({ site }) => {
  const { id: siteId } = site;
  const { groupSlug } = useParams();
  const { pathname } = useLocation();
  const { configs, user, impliedAdminOfGroups } = useSettings();
  const [tab, setTab] = React.useState(0);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useGetCommunityGroup(siteId, groupSlug || '');
  const groupUpdate = useUpdateCommunityGroup(onMutation(MutationAction.UPDATE, MODEL, refetch));
  const deleteGroup = useDeleteCommunityGroup(onMutation(MutationAction.DELETE, MODEL, () => {}));
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const { value: newGroupInfoAdminValue, loading: newGroupInfoAdminLoading } = useFeatureFlag(
    'newGroupInfoAdmin',
    false,
  );

  const iAmAdmin = useMemo(() => {
    if (user?.role === RoleEnum.NUMBER_1) return true;
    if (data?.admins?.find((admin) => admin.id === user?.id)) return true;
    if (impliedAdminOfGroups?.find((g) => data?.id === g)) return true;
    return false;
  }, [data?.admins, user, impliedAdminOfGroups]);

  const integrationConfig = configs?.find(
    (c) => c.type === FeatureConfigTypeEnum.integrations,
  ) as GroupHierarchyFeatureConfigModel;
  const hlConfig = integrationConfig?.config?.group_hierarchy;

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      await deleteGroup.mutateAsync({ siteId, groupId: data?.id || '' });
      setDeleteLoading(false);
      if (!pathname.includes('/admin/')) {
        navigate(`/${site.slug || ''}/home`);
      } else {
        navigate(-1);
      }
    } catch (e) {
      console.error(e);
      setDeleteLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Container>
        {headerSkeletons}
        {skeletons}
      </Container>
    );
  }

  if (!data) {
    return <>no data found</>;
  }

  if (!iAmAdmin) {
    return (
      <Container>
        <Typography variant='h6' textAlign={'center'} mt={4}>
          You do not have permission to view this page.
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <Formik
        initialValues={data}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const newValues = { ...values };
          delete newValues.members_count;
          try {
            const newData = await groupUpdate.mutateAsync({
              siteId,
              groupId: data?.id || '',
              data: newValues,
            });
            setSubmitting(false);
            resetForm({ values: newData });
          } catch (e) {
            setSubmitting(false);
            console.error(e);
          }
        }}
      >
        {(formik) => {
          const { handleSubmit, isSubmitting, dirty, handleReset, isValid, errors, values } =
            formik;

          return newGroupInfoAdminValue ? (
            <Form onSubmit={handleSubmit}>
              <Box>
                <Card sx={{ pt: 2, px: 2 }}>
                  <Box display='flex' justifyContent='flex-start' alignItems='center'>
                    <AdminEditPanelHeader
                      shouldShow={dirty && Object.values(errors).length === 0}
                      title={`${values.title}`}
                      onClickDiscard={handleReset}
                      onClickSave={handleSubmit}
                      onClickGoBack={() => {
                        navigate(-1);
                      }}
                      isSubmitting={isSubmitting}
                    />
                  </Box>

                  <Box>
                    <Tabs
                      value={tab}
                      onChange={handleTabChange}
                      aria-label='basic tabs example'
                      sx={{ '.MuiTab-root': { textTransform: 'none' } }}
                    >
                      <Tab label='Group Info' {...a11yProps(0)} />
                      <Tab label='Members' {...a11yProps(1)} />
                      <Tab label='Resources' {...a11yProps(2)} />
                      <Tab label='Additional Settings' {...a11yProps(3)} />
                      <Button
                        onClick={() => setDeleteDialogOpen(true)}
                        sx={{ marginLeft: 'auto', textTransform: 'none' }}
                        size='small'
                      >
                        <DeleteIcon sx={{ mr: 1, fontSize: 18 }} /> Delete Group
                      </Button>
                    </Tabs>
                  </Box>
                </Card>
                <CustomTabPanel value={tab} index={0}>
                  <GroupInfo site={site} group={data} formik={formik} hlConfig={!!hlConfig} />
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={1}>
                  <GroupMembers site={site} group={data} />
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={2}>
                  <GroupFilesTab
                    siteId={siteId}
                    group={data}
                    refetchGroup={refetch}
                    isAdminPage={true}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={3}>
                  <ForumFeedSettings group={data} formik={formik} site={site} />
                </CustomTabPanel>
              </Box>
            </Form>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Box>
                <AdminEditPanelHeader
                  shouldShow={dirty}
                  title={data?.title || ''}
                  onClickDiscard={handleReset}
                  onClickSave={handleSubmit}
                  onClickGoBack={() => {
                    navigate(-1);
                  }}
                  isSubmitting={isSubmitting}
                  bannerImageUrl={data?.banner_image}
                />
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    aria-label='basic tabs example'
                    sx={{ '.MuiTab-root': { textTransform: 'none' } }}
                  >
                    <Tab label='Group Info' {...a11yProps(0)} />
                    <Tab label='Members' {...a11yProps(1)} />
                    <Tab label='Resources' {...a11yProps(2)} />
                    <Tab label='Additional Settings' {...a11yProps(3)} />
                    <Button
                      onClick={() => setDeleteDialogOpen(true)}
                      sx={{ marginLeft: 'auto', textTransform: 'none' }}
                      size='small'
                    >
                      <DeleteIcon sx={{ mr: 1, fontSize: 18 }} /> Delete Group
                    </Button>
                  </Tabs>
                </Box>
                <CustomTabPanel value={tab} index={0}>
                  <GroupInfo site={site} group={data} formik={formik} hlConfig={!!hlConfig} />
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={1}>
                  <GroupMembers site={site} group={data} />
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={2}>
                  <GroupFilesTab
                    siteId={siteId}
                    group={data}
                    refetchGroup={refetch}
                    isAdminPage={true}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={3}>
                  <ForumFeedSettings group={data} formik={formik} site={site} />
                </CustomTabPanel>
              </Box>
            </Form>
          );
        }}
      </Formik>
      <DialogAriaWrapper
        id={'delete_group_confirm'}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Group</DialogTitle>
        <DialogContent>
          Are you sure you would like to delete the group {data?.title}?
        </DialogContent>
        <DialogActions>
          <Button aria-label='Cancel delete group' sx={{ textTransform: 'none' }}>
            Cancel
          </Button>
          <LoadingButton
            aria-label='Confirm delete group'
            sx={{ textTransform: 'none' }}
            variant={'contained'}
            loading={deleteLoading}
            onClick={handleDelete}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </DialogAriaWrapper>
    </Container>
  );
};

export default CommunityGroupPanel;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
