import React, { useEffect, useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { FeatureConfig } from '@juno/client-api/utils';
import { customShadow } from '@juno/ui';
import { getTheme, useSettings } from '@juno/utils';

interface ThemeWrapperProps {
  children: React.ReactNode;
}
const ThemeWrapper: React.FC<ThemeWrapperProps> = ({ children }) => {
  const { configs } = useSettings();
  const theme = useMemo(() => {
    const theme = getTheme(FeatureConfig.getThemeConfigTheme(configs));
    const customTheme = {
      ...theme,
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'capitalize',
              boxShadow: 'none',
              borderRadius: 7,
              fontWeight: 600,
              letterSpacing: '.21px',
              '&.MuiButton-text:hover': {
                backgroundColor: '#eee',
              },
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              boxShadow: customShadow,
              borderRadius: 8,
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              maxHeight: 40,
              '&.Mui-selected': {
                backgroundColor: '#eee',
                color: theme.palette.primary.main,
                '.MuiListItemIcon-root': {
                  color: theme.palette.primary.main,
                },
                ':hover': {
                  backgroundColor: '#eee',
                },
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#eee',
              },
              ':hover': {
                backgroundColor: '#eee',
              },
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              '> svg': {
                marginLeft: 3,
              },
            },
          },
        },
        MuiListItemText: {
          styleOverrides: {
            root: {
              '> .MuiTypography-root': {
                fontSize: '.9rem',
                lineHeight: '1.1rem',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            },
          },
        },
        // Commenting for now, not sure if we're ready to apply this to the whole theme
        // MuiTabs: {
        //   styleOverrides: {
        //     root: { minHeight: 0 },
        //   },
        // },
        // MuiTab: {
        //   styleOverrides: {
        //     root: {
        //       textTransform: 'capitalize',
        //       padding: 0,
        //       '&.MuiButtonBase-root': { padding: '5.5px 24.5px', minHeight: 0 },
        //     },
        //   },
        // },
        MuiSwitch: {
          styleOverrides: {
            root: {
              width: 44,
              height: 24,
              padding: 0,
              marginLeft: 8,
              marginRight: 8,
              display: 'flex',
              '&:active': {
                '& .MuiSwitch-thumb': {
                  width: 12,
                },
                '& .MuiSwitch-switchBase.Mui-checked': {
                  transform: 'translateX(20px)',
                },
              },
              '& .MuiSwitch-switchBase': {
                padding: 6,
                '&.Mui-checked': {
                  transform: 'translateX(20px)',
                  color: '#fff',
                  '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.secondary,
                  },
                },
              },
              '& .MuiSwitch-thumb': {
                boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
                width: 12,
                height: 12,
                borderRadius: 10,
                transition: theme.transitions.create(['width'], {
                  duration: 200,
                }),
              },
              '& .MuiSwitch-track': {
                borderRadius: 14,
                opacity: 1,
                backgroundColor: '#fff',
                boxSizing: 'border-box',
                border: '4px solid #000',
              },
              '.Mui-checked + .MuiSwitch-track': {
                border: '2px solid #fff',
              },
            },
            switchBase: {
              color: '#000',
            },
          },
        },
      },
      // some custom shadows - ideally we will eventually build this whole thing out with ours instead of MUI's
      shadows: theme.shadows.map((shadow, i) => {
        if (i === 1) {
          return '0px 3px 1px -1px rgba(0,0,0,0.06), 0px 1px 1px 0px rgba(0,0,0,0.04), 0px 1px 3px 0px rgba(0,0,0,0.04)';
        }
        if (i === 5) {
          return customShadow;
        }
        return shadow;
      }),
      typography: {
        ...theme.typography,
        body2: {
          fontSize: '.9rem',
          opacity: 0.6,
        },
        overline: {
          fontWeight: 600,
          background: 'rgba(0,0,0,.1)',
          paddingLeft: theme.spacing(1.5),
          paddingRight: theme.spacing(1.5),
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          borderRadius: theme.spacing(1),
          display: 'inline-flex',
          fontSize: '1rem',
          boxShadow: theme.shadows[1],
          letterSpacing: 1,
        },
        subtitle1: {
          fontSize: '1rem',
          fontWeight: 600,
          opacity: 0.6,
        },
      },
    };
    return customTheme;
  }, [configs]);

  useEffect(() => {
    // set branded favicon
    const themeConfig = FeatureConfig.getThemeConfig(configs);
    const link = document.getElementById('favicon') as HTMLLinkElement;
    if (link) {
      link.href = (themeConfig?.config?.favicon || '') as string;
    }
  }, [configs]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
export default ThemeWrapper;
