import React, { useEffect, useMemo, useRef, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Card, Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useElementSize } from 'usehooks-ts';
import { DownloadSingleCertificateDialog, certInterpolate } from '@juno/certificates';
import {
  generateCertificatePDF,
  getAllEducationCreditRequirements,
  getCertificateTemplate,
  getGetCoursesQueryKey,
  getPurchaseLink,
  useCheckCourseRequirements,
  useCompleteCourse,
  useCreateCourseEnrollment,
  useGetCourse,
  useGetCourseEducationCredits,
  useGetCourseEnrollments,
  useGetCourseFeed,
  useGetCourseLessons,
  useGetCourseResources,
  useGetMyEducationCreditProgress,
  useGetPlatform,
  useGetUserCourseLessonCompletions,
  useGetWaitListedEnrollments,
  useStartCourse,
  useStartLesson,
} from '@juno/client-api';
import {
  AccessPass,
  AllowRetakesEnum,
  CertificateTemplate,
  Course as CourseModel,
  EducationCredit,
  Enrollment as EnrollmentModel,
  Feed,
  JunoUser,
  LessonCompletion,
  Lesson as LessonModel,
  Product,
  ProductPrice,
  UserEducationCredit,
  UserPurchase,
} from '@juno/client-api/model';
import { Enrollment as EnrollmentUtils } from '@juno/client-api/utils';
import { ANALYTICS_CONFIGURATION, EMPTY_COURSE_URL, JUNO_ROUTE_MAP } from '@juno/constants';
import { Blurb, FeedComponent, useAnalyticsContext, usePubnubContext } from '@juno/modules';
import { Container, DefaultHeroBanner, JunoRotator, ProductCard, SimpleDialog } from '@juno/ui';
import {
  MutationAction,
  onMutation,
  truncateString,
  useAccessPassPurchaseState,
  useRouteNavigate,
  useSettings,
} from '@juno/utils';
import { useBreakpoint } from '@juno/utils/hooks';
import EnrollButton from '../components/EnrollButton';
import RestrictCourseEnrollDialog from '../components/RestrictCourseEnrollDialog';
import ScoreDialog from '../components/ScoreDialog';
import PrerequisiteBlurb from './PrerequisiteBlurb';
import { BUTTON_TYPES, COURSE_STATE_MAP } from './utils/constants';
import {
  LessonBannerContainer,
  LessonDoneIcon,
  LessonUnfinishedIcon,
  RetakeTypography,
} from './utils/styles';

export interface ExtendedCourseModel extends CourseModel {
  course_complete_percentage?: string;
  products?: Product[];
  restrict_access?: boolean;
}

interface LessonMetadataModel {
  [key: string]: unknown;
  retake_limit?: number;
}
export interface ProductStatus {
  product: Product | undefined;
  myPrice: ProductPrice | undefined;
  myPayment: UserPurchase | undefined;
  myAccessPass: AccessPass | undefined;
}

export interface CourseProps {
  siteId: string;
  settings?: any; // TODO: define this type after backend is finished
}

interface LessonCompletionMap {
  [key: string]: LessonCompletion[];
}

const Course: React.FC<CourseProps> = ({ siteId, settings }) => {
  const { pubnub } = usePubnubContext();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [courseCompleteDialogOpen, setCourseCompleteDialogOpen] = useState(false);
  const [courseScore, setCourseScore] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [openCertificate, setOpenCertificate] = useState(false);
  const [certificateUrl, setCertificateUrl] = useState('');
  const [isTimed, setIsTimed] = useState(false);
  const [openCannotPurchaseDialog, setOpenCannotPurchaseDialog] = useState(false);
  const [imgRef, { width: imageWidth }] = useElementSize();
  const queryClient = useQueryClient();
  const params = useParams();
  const courseSlug: string = params.courseSlug || '';

  const { user, coursesSlug, downloadUrl, openExternalUrl, isApp, onNavigate, isClient, site } =
    useSettings();
  const { firehoseActivity } = useAnalyticsContext();
  const learningNavigate = useRouteNavigate();
  const navigate = useNavigate();
  const { FIREHOSE_ACTIONS, FIREHOSE_CATEGORIES, FIREHOSE_OBJECTS } = ANALYTICS_CONFIGURATION;
  const timeStarted = useRef(0);

  const { xs, md } = useBreakpoint();
  const bannerHeight = xs ? '32vw' : md ? '246px' : '294px';
  // queries
  const courseObj = useGetCourse(siteId, courseSlug, {
    include: 'products,restrict_access',
  });
  const { data: course, isLoading: coursesLoading } = courseObj;
  const extendedCourse: ExtendedCourseModel | undefined = useMemo(() => course, [course]);

  // Restrict enroll modal vars
  const [restrictEnrollDialogOpen, setRestrictEnrollDialogOpen] = useState(false);
  const { data: platformData, isLoading: platformLoading } = useGetPlatform(
    site?.platform_id || '',
  );
  // TODO this is erroring for no reason - will fix later
  // const { data: clientData, isLoading: clientLoading } = useGetClient(
  //   platformData?.client_id || '',
  // );
  // const clientName = clientData?.name || 'your company';
  const clientName = 'your company';

  const resourcesObj = useGetCourseResources(siteId, courseSlug);
  const { data: resourcesData, isLoading: resourcesLoading } = resourcesObj;

  const lessonsObj = useGetCourseLessons(siteId, courseSlug, { order: 'order' });
  const { data: lessons, isLoading: lessonsLoading } = lessonsObj;

  const enrollmentsObj = useGetCourseEnrollments(siteId, course?.id || '');
  const { data: enrollmentsData, isLoading: enrollmentsLoading } = enrollmentsObj;

  const waitListedEnrollmentsObj = useGetWaitListedEnrollments(siteId, course?.id || '', {
    scope: 'mine',
  });
  const { data: waitlistedData, isLoading: waitlistedLoading } = waitListedEnrollmentsObj;

  const compObj = useGetUserCourseLessonCompletions(siteId, course?.id || '', user?.id || '', {
    order: '-date_started',
  });
  const { data: completionsData, isLoading: completionsLoading } = compObj;

  const prereqObj = useCheckCourseRequirements(siteId, course?.id || '');
  const { data: prereqData, isLoading: prereqLoading } = prereqObj;

  const { data: courseEducationCredits, isLoading: isLoadingCourseEducationCredits } =
    useGetCourseEducationCredits(siteId, course?.id || '');

  // Get education credit progress
  const { data: educationCreditProgress, isLoading: isLoadingEducationCreditProgress } =
    useGetMyEducationCreditProgress(siteId);

  const { data: courseFeed, isLoading: loadingFeed } = useGetCourseFeed(
    siteId,
    course?.id || '',
    {},
    { query: { enabled: !!course && course?.include_feed === true } },
  );

  const refetch = () => {
    enrollmentsObj.refetch();
    waitListedEnrollmentsObj.refetch();
    compObj.refetch();
  };

  const [hasMultipleCertificates, setHasMultipleCertificates] = useState(false);
  const [certificateToDownload, setCertificateToDownload] = useState<CertificateTemplate | null>(
    null,
  );
  const [singleEducationCredit, setSingleEducationCredit] = useState<EducationCredit | null>(null);

  useEffect(() => {
    if (courseEducationCredits && courseEducationCredits.length > 0) {
      // is this course attached to multiple EC's
      const creditCertificates = courseEducationCredits.filter(
        (credit: EducationCredit) => credit.certificate_template_id !== null,
      );

      const hasMany = creditCertificates.length > 1;
      setHasMultipleCertificates(hasMany);

      if (creditCertificates.length > 0 && !hasMany) {
        setSingleEducationCredit(creditCertificates[0]);
        getCertificateTemplate(siteId, creditCertificates[0].certificate_template_id || '').then(
          (cert: CertificateTemplate) => {
            setCertificateToDownload(cert);
          },
        );
      }

      // does the EC for this course have multiple courses attached
      if (courseEducationCredits.length > 0 && !hasMany) {
        getAllEducationCreditRequirements(siteId, courseEducationCredits[0].id || '').then(
          (reqs) => {
            if (reqs.length > 1) {
              setSingleEducationCredit(null);
              setHasMultipleCertificates(true);
              setCertificateToDownload(null);
            }
          },
        );
      }
    }
  }, [courseEducationCredits, educationCreditProgress]);

  const isLoading =
    coursesLoading ||
    lessonsLoading ||
    resourcesLoading ||
    // TODO see message above about useGetClient
    // clientLoading ||
    platformLoading;
  const saving =
    isSaving ||
    enrollmentsLoading ||
    completionsLoading ||
    waitlistedLoading ||
    enrollmentsObj.isRefetching ||
    waitListedEnrollmentsObj.isRefetching ||
    compObj.isRefetching ||
    prereqLoading;

  // mutations
  const startCourse = useStartCourse(onMutation(MutationAction.CREATE, 'Course', refetch, true));
  const createEnrollment = useCreateCourseEnrollment(
    onMutation(MutationAction.CREATE, 'Course Enrollment', refetch, true),
  );
  const startLesson = useStartLesson(onMutation(MutationAction.CREATE, 'Lesson', refetch, true));
  const completeCourse = useCompleteCourse(
    onMutation(MutationAction.UPDATE, 'Course', refetch, true),
  );

  // This is needed to determine the current lesson
  // TODO: I would like to move this logic to the backend, but it's not a priority right now
  const lessonCompletionMap = useMemo(() => {
    const completionDict: LessonCompletionMap = {};
    lessons?.map((lesson) => {
      completionDict[lesson.id] =
        completionsData?.filter((comp) => comp.lesson_id === lesson.id) || [];
    });
    return completionDict;
  }, [completionsData, lessons]);

  const currentLesson = useMemo(() => {
    if (!lessons) return undefined;
    if ((!completionsData || completionsData.length === 0) && lessons?.length) {
      return lessons[0];
    }
    return lessons?.find((lesson) => {
      return (
        lessonCompletionMap?.[lesson.id]?.find((comp) => !comp.date_completed) ||
        lessonCompletionMap?.[lesson.id]?.length === 0
      );
    });
  }, [lessonCompletionMap, completionsData, lessons]);

  // Product status - is this paywalled,
  const productStatus: ProductStatus = useAccessPassPurchaseState(
    siteId,
    user || ({} as JunoUser),
    course?.restrict_enrollment_access_passes || [],
    courseObj.refetch,
  );

  useEffect(() => {
    if (currentLesson) {
      setIsTimed(!!currentLesson.time_allowed);
    }
  }, [currentLesson]);

  const courseState = useMemo(() => {
    // Use business rules to determine the state of the course for the user
    // TODO: I would like to move this logic to the backend, but it's not a priority right now
    const {
      // date_released,
      date_open_enrollment,
      date_start,
      date_lock_enrollment,
      date_end,
      enrollment_required,
      max_enrollments,
    } = course || {};
    const now_dt = new Date();
    // calculate if we're between start/end dates
    const start_dt = date_start && new Date(date_start);
    const end_dt = date_end && new Date(date_end);
    const isCourseEarly = start_dt && now_dt < start_dt;
    const isCourseEnded = end_dt && now_dt > end_dt;
    // calculate if we're between enrollment dates
    const en_open_dt = date_open_enrollment && new Date(date_open_enrollment);
    const en_close_dt = date_lock_enrollment && new Date(date_lock_enrollment);
    const isTooEarlyToEnroll = en_open_dt && now_dt < en_open_dt;
    const isTooLateToEnroll = en_close_dt && now_dt > en_close_dt;
    const canEnroll = !isTooEarlyToEnroll && !isTooLateToEnroll;
    // get the enrollment if we have one
    const enrollment = enrollmentsData?.find((en: EnrollmentModel) => en.user_id === user?.id);
    // determine if currently waitlisted
    const isWaitlisted = waitlistedData?.find((wl) => wl.user?.id === user?.id) && !enrollment;
    // determine if all lessons are finished
    const lessonsCompleted = lessons?.filter(
      (lesson) =>
        completionsData?.filter((comp) => comp.lesson_id === lesson.id)?.[0]?.date_completed,
    );

    completionsData?.filter((comp) => comp && comp.date_completed);

    const hasPassedCourse =
      enrollment?.final_grade &&
      course?.passing_percent &&
      Number(enrollment.final_grade) >= course?.passing_percent;

    const isFinishedWithCourses: boolean = lessonsCompleted?.length === lessons?.length;
    // determine if the course is full
    const isCourseFull =
      max_enrollments === 0 ? false : (enrollmentsData?.length || 0) >= (max_enrollments || 0);

    // TODO: determine if prereqs are not met
    const prereqsRequired = prereqData && prereqData?.requirements_missing?.length > 0;

    // TODO peter to fix
    if (prereqsRequired) return COURSE_STATE_MAP.PREREQS_REQUIRED.key;
    if (hasPassedCourse && certificateToDownload !== null)
      return COURSE_STATE_MAP.COURSE_CERTIFIED.key;
    if (hasPassedCourse && hasMultipleCertificates)
      return COURSE_STATE_MAP.COURSE_CERTIFIED_MULTI.key;
    if (hasPassedCourse) return COURSE_STATE_MAP.COURSE_PASSED.key;
    if (!lessons?.length) return COURSE_STATE_MAP.NO_LESSONS.key;
    if (enrollment?.date_completed) return COURSE_STATE_MAP.COURSE_COMPLETED.key;
    if (isFinishedWithCourses) return COURSE_STATE_MAP.LESSONS_COMPLETED.key;
    if (isWaitlisted) return COURSE_STATE_MAP.WAITLISTED.key;
    if (completionsData?.length) return COURSE_STATE_MAP.LESSONS_STARTED.key;
    if (isCourseEnded) return COURSE_STATE_MAP.COURSE_CLOSED.key;
    if (isTooEarlyToEnroll) return COURSE_STATE_MAP.ENROLLMENT_TOO_EARLY.key;
    if (isTooLateToEnroll && !enrollment) return COURSE_STATE_MAP.ENROLLMENT_LOCKED.key;
    if (isTooLateToEnroll && enrollment) return COURSE_STATE_MAP.ENROLLED.key;
    if (isCourseFull && !enrollment) return COURSE_STATE_MAP.COURSE_FULL.key;
    if (productStatus.product && productStatus.myPayment && enrollment)
      return COURSE_STATE_MAP.ENROLLED.key;
    if (!extendedCourse?.restrict_access && !enrollment && canEnroll)
      return COURSE_STATE_MAP.CAN_SELF_ENROLL.key;
    if (productStatus.product && productStatus.myPayment) return COURSE_STATE_MAP.PURCHASED.key;
    if (
      productStatus.product &&
      productStatus.myPrice &&
      !productStatus.myPayment &&
      isApp &&
      !enrollment
    )
      return COURSE_STATE_MAP.PAYWALLED_APP.key;
    if (productStatus.product && productStatus.myPrice && !productStatus.myPayment && !enrollment)
      return COURSE_STATE_MAP.PAYWALLED.key;
    if (productStatus.product && !productStatus.myPrice && !productStatus.myPayment && !enrollment)
      return COURSE_STATE_MAP.CANT_PURCHASE.key;
    if (extendedCourse?.restrict_access && !enrollment) return COURSE_STATE_MAP.RESTRICT_ACCESS.key;
    if (enrollment_required && !enrollment) return COURSE_STATE_MAP.ENROLLMENT_REQUIRED.key;
    if (canEnroll && !enrollment) return COURSE_STATE_MAP.CAN_SELF_ENROLL.key;
    if (isCourseEarly) return COURSE_STATE_MAP.COURSE_INACTIVE.key;
    if (enrollment) return COURSE_STATE_MAP.ENROLLED.key;
    return COURSE_STATE_MAP.CAN_SELF_ENROLL.key;
  }, [course, enrollmentsData, user, completionsData, lessons, waitlistedData, productStatus]);

  const handleCourseEnroll = async () => {
    if (!user?.id || !course?.id) return;
    setIsSaving(true);
    try {
      await createEnrollment.mutateAsync({
        siteId,
        courseId: course.id,
        data: {
          status: EnrollmentUtils.ENROLLMENT_STATUSES.APPROVED.value,
          user_id: user?.id || '',
          course_id: course.id,
          id: '',
          credits_earned: 0,
          did_pass: false,
        },
      });
      queryClient.invalidateQueries(
        getGetCoursesQueryKey(siteId, {
          filter: {
            enrollments__user__id: user?.id || [],
            enrollments__date_completed__isnull: true,
          },
        }),
      );
      firehoseActivity(
        FIREHOSE_OBJECTS.OBJECT_CONTENT,
        course?.id,
        null,
        `${user?.id || ''}`,
        FIREHOSE_CATEGORIES.CATEGORY_COURSE,
        FIREHOSE_ACTIONS.ACTION_ENROLL.value,
        course?.title,
        null,
      );
    } catch (e) {
      console.error(e);
    }
    setIsSaving(false);
  };

  const handleSelectLesson = async (lesson: LessonModel | undefined) => {
    const lessonSlug = lesson?.slug || '';
    const lessonId = lesson?.id || '';
    const courseId = course?.id || '';

    const completeLessonSlugs =
      completionsData?.map((completion) => completion?.lesson?.slug) || [];
    if (currentLesson?.slug !== lessonSlug && !completeLessonSlugs.includes(lessonSlug)) {
      return;
    }
    const completion = completionsData?.find((comp) => comp?.lesson?.id === lessonId);
    const route = JUNO_ROUTE_MAP.CLIENT_LESSON.route
      .replace(':courseSlug', courseSlug)
      .replace(':lessonSlug', lessonSlug);
    const routeTo = { ...JUNO_ROUTE_MAP.CLIENT_LESSON, route };

    if (completion?.date_completed) {
      if (isClient) {
        navigate(`/${site?.slug}/learning/courses/${courseSlug}/${lessonSlug}`);
        return;
      }
      learningNavigate(routeTo, { courseSlug, lessonSlug });
      return;
    }
    if (!completeLessonSlugs.includes(lessonSlug)) {
      if (!lessonId || !courseId) return;
      setIsSaving(true);
      try {
        await startLesson.mutateAsync({ siteId, courseId, lessonId });
        firehoseActivity(
          FIREHOSE_OBJECTS.OBJECT_LESSON,
          lessonId,
          null,
          `${user?.id || ''}`,
          FIREHOSE_CATEGORIES.CATEGORY_LESSON,
          FIREHOSE_ACTIONS.ACTION_START?.value,
          null,
          null,
        );
      } catch (e) {
        console.error(e);
      }
      setIsSaving(false);
    }
    if (isClient) {
      navigate(`/${site?.slug}/learning/courses/${courseSlug}/${lessonSlug}`);
      return;
    }
    learningNavigate(routeTo, { courseSlug, lessonSlug });
  };

  const handleStartCourse = async () => {
    if (!course?.id) return;
    setIsSaving(true);
    try {
      await startCourse.mutateAsync({ siteId, courseId: course?.id });
      firehoseActivity(
        FIREHOSE_OBJECTS.OBJECT_CONTENT,
        course?.id,
        null,
        `${user?.id || ''}`,
        FIREHOSE_CATEGORIES.CATEGORY_COURSE,
        FIREHOSE_ACTIONS.ACTION_START?.value,
        null,
        null,
      );
      await handleSelectLesson(currentLesson);
    } catch (e) {
      console.error(e);
    }
    setIsSaving(false);
  };

  const handleCompleteCourse = async () => {
    if (!course?.id) return;
    setIsSaving(true);
    let savedCourse;
    try {
      savedCourse = await completeCourse.mutateAsync({ siteId, courseId: course.id });
      firehoseActivity(
        FIREHOSE_OBJECTS.OBJECT_CONTENT,
        course?.id,
        null,
        `${user?.id || ''}`,
        FIREHOSE_CATEGORIES.CATEGORY_COURSE,
        FIREHOSE_ACTIONS.ACTION_COMPLETE?.value,
        null,
        null,
      );
    } catch (e) {
      console.error(e);
    }
    setIsSaving(false);
    const grade = Number(savedCourse?.enrollment.final_grade) || 0;
    const credits = savedCourse?.enrollment.credits_earned || 0;
    const faAction = savedCourse?.enrollment.did_pass
      ? FIREHOSE_ACTIONS.ACTION_EARN_CREDIT.value
      : FIREHOSE_ACTIONS.ACTION_FAIL.value;
    firehoseActivity(
      FIREHOSE_OBJECTS.OBJECT_CONTENT,
      course?.id,
      null,
      `${user?.id || ''}`,
      FIREHOSE_CATEGORIES.CATEGORY_COURSE,
      FIREHOSE_ACTIONS.ACTION_EARN_GRADE?.value,
      course?.title,
      grade,
    );

    firehoseActivity(
      FIREHOSE_OBJECTS.OBJECT_CONTENT,
      course?.id,
      null,
      `${user?.id || ''}`,
      FIREHOSE_CATEGORIES.CATEGORY_COURSE,
      faAction,
      course?.title,
      credits,
    );
    setCourseScore(grade);
    setCourseCompleteDialogOpen(true);
  };

  const handleShowWarningDialog = () => {
    setDialogOpen(true);
  };

  const handleCourseCompletedButtonClick = () => {
    setCourseCompleteDialogOpen(false);
  };

  const getEducationCreditDateCompleted = (): string => {
    const a = educationCreditProgress?.find(
      (p: UserEducationCredit) => p.education_credit_id === singleEducationCredit?.id,
    )?.date_completed;
    if (a) {
      return new Date(a).toDateString();
    }
    // They just finished the course, so provide today as the completion date
    return new Date().toDateString();
  };

  // restricted enrollment functions
  const handleRestrictEnroll = () => {
    setRestrictEnrollDialogOpen(true);
  };

  const handleLessonRowClick = (lesson: LessonModel) => {
    switch (courseState) {
      case COURSE_STATE_MAP.LESSONS_STARTED.key:
      case COURSE_STATE_MAP.LESSONS_COMPLETED.key:
      case COURSE_STATE_MAP.COURSE_PASSED.key:
      case COURSE_STATE_MAP.COURSE_CERTIFIED.key:
      case COURSE_STATE_MAP.COURSE_COMPLETED.key:
        return handleSelectLesson(lesson);
      case COURSE_STATE_MAP.NO_LESSONS.key:
      case COURSE_STATE_MAP.ENROLLMENT_TOO_EARLY.key:
      case COURSE_STATE_MAP.ENROLLMENT_LOCKED.key:
      case COURSE_STATE_MAP.COURSE_INACTIVE.key:
      case COURSE_STATE_MAP.WAITLISTED.key:
      case COURSE_STATE_MAP.ENROLLMENT_REQUIRED.key:
        return handleShowWarningDialog();
    }
  };

  const handleButtonClick = () => {
    switch (courseState) {
      case COURSE_STATE_MAP.COURSE_CERTIFIED.key:
        return handleOpenCertificate();
      case COURSE_STATE_MAP.COURSE_CERTIFIED_MULTI.key:
        return;
      case COURSE_STATE_MAP.CAN_SELF_ENROLL.key:
      case COURSE_STATE_MAP.COURSE_FULL.key:
      case COURSE_STATE_MAP.PURCHASED.key:
        return handleCourseEnroll();
      /**
       * the order here is important
       * check if I have already completed first
       * then check if i've even started any lessons
       * then check if i'm enrolled
       */
      case COURSE_STATE_MAP.LESSONS_COMPLETED.key:
        return handleCompleteCourse();
      case COURSE_STATE_MAP.LESSONS_STARTED.key:
        return handleSelectLesson(currentLesson);
      case COURSE_STATE_MAP.ENROLLED.key:
        return handleStartCourse();
      case COURSE_STATE_MAP.NO_LESSONS.key:
      case COURSE_STATE_MAP.ENROLLMENT_TOO_EARLY.key:
      case COURSE_STATE_MAP.ENROLLMENT_LOCKED.key:
      case COURSE_STATE_MAP.COURSE_INACTIVE.key:
      case COURSE_STATE_MAP.WAITLISTED.key:
      case COURSE_STATE_MAP.ENROLLMENT_REQUIRED.key:
      case COURSE_STATE_MAP.PREREQS_REQUIRED.key:
        return handleShowWarningDialog();
      case COURSE_STATE_MAP.RESTRICT_ACCESS.key:
        return handleRestrictEnroll();
    }
  };

  const handleOpenCertificate = () => {
    if (!course?.id || !singleEducationCredit || hasMultipleCertificates) return;
    setIsSaving(true);
    generateCertificatePDF(
      siteId,
      singleEducationCredit.id || '',
      new Date().getTimezoneOffset(),
    ).then((pdfUrl: string) => {
      setIsSaving(false);
      if (pdfUrl === null) {
        return;
      }
      if (isApp) {
        // TODO check with prod to see if they want the name of the certificate in the file name
        // downloadUrl(pdfUrl, `${certificateToDownload?.name}certificate.pdf`, true);
        downloadUrl(pdfUrl, 'Cerficate.pdf', true);
      } else {
        setCertificateUrl(pdfUrl);
        setOpenCertificate(true);
      }
    });
  };

  const handleCloseCertificate = () => {
    if (!course?.id) return;
    setOpenCertificate(false);
  };

  const handleAdminSelect = () => {
    learningNavigate(JUNO_ROUTE_MAP.ADMIN_COURSE, {
      courseSlug: course?.slug || '',
    });
  };

  // fire analytics for course view
  const hasReported = useRef(false);
  useEffect(() => {
    if (
      !courseObj.isLoading &&
      !courseObj.isError &&
      !hasReported.current &&
      FIREHOSE_ACTIONS.ACTION_VIEW
    ) {
      hasReported.current = true;
      firehoseActivity(
        FIREHOSE_OBJECTS.OBJECT_CONTENT,
        course?.id || '',
        null,
        `${user?.id || ''}`,
        FIREHOSE_CATEGORIES.CATEGORY_COURSE,
        FIREHOSE_ACTIONS.ACTION_VIEW?.value,
        null,
        null,
      );
    }
  }, [courseObj]);

  useEffect(() => {
    timeStarted.current = Date.now();

    return () => {
      // Report time spent on course page
      if (FIREHOSE_ACTIONS.ACTION_LEAVE) {
        firehoseActivity(
          FIREHOSE_OBJECTS.OBJECT_CONTENT,
          course?.id || '',
          null,
          `${user?.id || ''}`,
          FIREHOSE_CATEGORIES.CATEGORY_COURSE,
          FIREHOSE_ACTIONS.ACTION_LEAVE?.value,
          null,
          Date.now() - timeStarted.current,
        );
      }
    };
  }, []);

  useEffect(() => {
    // Subscribe to the course channel to report presence
    if (course && user) {
      pubnub.subscribe({ channels: [course.id], withPresence: true });
    }
    return () => {
      // Unsubscribe from the course channel to stop reporting presence
      if (course) {
        pubnub.unsubscribe({ channels: [course.id] });
      }
    };
  }, [pubnub, course, user]);

  if (isLoading) return <></>;
  if (!course) {
    return (
      <Typography variant='h6' color='text.primary' sx={{ textAlign: 'center' }}>
        No Course Found
      </Typography>
    );
  }

  return (
    <Box>
      <Container>
        <Card>
          {/* // TODO: add back in when we have admin route */}
          {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {(user?.role === RoleEnum.NUMBER_1 ||
            user?.declared_tags.find((tag) => tag.value === 'Facilitator')) &&
            !isApp &&
            !xs &&
            !isClient && (
              <IconButton
                size='small'
                onClick={handleAdminSelect}
                sx={{
                  bgcolor: 'primary.main',
                  display: 'block',
                  width: '90px',
                  height: '90px',
                  marginLeft: '20px',
                  '&:hover': {
                    bgcolor: 'primary.main',
                    opacity: 0.8,
                  },
                }}
              >
                <Settings
                  fontSize='small'
                  sx={{ color: 'primary.contrastText', lineHeight: '20px' }}
                />
                <Typography sx={{ color: 'primary.contrastText' }}>Edit</Typography>
              </IconButton>
            )}
        </div> */}

          {course.banner_video && (
            <Box style={{ position: 'relative', paddingTop: '56.25%', background: '#000' }}>
              <ReactPlayer
                url={course.banner_video}
                width='100%'
                height='100%'
                controls={true}
                style={{ position: 'absolute', top: 0, left: 0 }}
              />
            </Box>
          )}
          {!course.banner_video && (
            <DefaultHeroBanner
              imgSrc={course.icon || EMPTY_COURSE_URL}
              imgAlt={course.title || ''}
              height={bannerHeight}
            />
            // TODO: add back in for lock access
            // <Box sx={{ position: 'relative' }}>
            //   {course.restrict_preview_access && (
            //     <LockedIndicator
            //       itemHeight={205}
            //       sx={{ color: 'primary.main', bottom: 0, top: 'unset' }}
            //     >
            //       <LockIcon />
            //     </LockedIndicator>
            //   )}
            // </Box>
          )}
          <Container pb={2} pt={1}>
            <Typography variant='h4' color='text.primary' sx={{ fontWeight: 'bold', pr: 1, pl: 2 }}>
              {course.title}
            </Typography>
            <PrerequisiteBlurb prereqIds={course.prerequisites} siteId={siteId} />
            <Blurb html={course.description} />
            {courseState === COURSE_STATE_MAP.PAYWALLED.key && (
              <ProductCard productStatus={productStatus} contentId={course.id} />
            )}
            <Box display={'flex'} justifyContent={'flex-end'} pr={1} alignItems={'center'}>
              {/* // TODO: commented this out to see if we still want or not, QA didn't like it and removed it from specs */}
              {/* <Typography sx={{ fontWeight: 'bold', opacity: 0.7, mr:1 }}>
              {COURSE_STATE_MAP[courseState].label}
            </Typography> */}

              <Box sx={{ display: 'flex' }} alignItems={'center'}>
                {COURSE_STATE_MAP[courseState].button.type === BUTTON_TYPES.DISABLED && (
                  <EnrollButton
                    title={COURSE_STATE_MAP[courseState].button.label}
                    onClick={handleButtonClick}
                  />
                )}
                {COURSE_STATE_MAP[courseState].button.type === BUTTON_TYPES.PAYWALLED_APP && (
                  <LoadingButton
                    loading={saving}
                    variant='contained'
                    onClick={() => setOpenCannotPurchaseDialog(true)}
                  >
                    {COURSE_STATE_MAP[courseState].button.label}
                  </LoadingButton>
                )}
                {COURSE_STATE_MAP[courseState].button.type === BUTTON_TYPES.POINTS && (
                  <LoadingButton loading={saving} variant='contained' onClick={handleButtonClick}>
                    {COURSE_STATE_MAP[courseState].button.label}
                  </LoadingButton>
                )}
                {COURSE_STATE_MAP[courseState].button.type === BUTTON_TYPES.BASIC && (
                  <LoadingButton loading={saving} variant='contained' onClick={handleButtonClick}>
                    {COURSE_STATE_MAP[courseState].button.label}
                  </LoadingButton>
                )}
              </Box>
            </Box>
          </Container>
        </Card>
      </Container>
      <Container>
        <Box>
          <Box mt={2}>
            {lessons?.map((lesson, idx) => {
              const completions = lessonCompletionMap[lesson.id];
              const didComplete = completions?.[0]?.date_completed;
              const lessonMeta: LessonMetadataModel = lesson?.metadata || {};
              let retakesAllowed = lessonMeta?.retake_limit || 1;
              const numCompletions = completions?.length || 0;
              // first take doesn't count towards our retakes
              const adjuster = numCompletions > 0 ? 1 : 0;

              let didPass = true;
              if (lesson.include_in_course_grade && completions?.[0]?.grade) {
                didPass = parseInt(completions?.[0]?.grade) >= (lesson?.passing_percent || 0);
              }

              let retakesLeft = 0;
              // if they've completed the lesson we check to see how many retakes they have left
              if (didComplete) {
                switch (lesson?.allow_retakes) {
                  case AllowRetakesEnum.never:
                    retakesAllowed = 0;
                    break;
                  case AllowRetakesEnum.unlimited:
                    retakesLeft = -1;
                    break;
                  case AllowRetakesEnum.on_fail_unlimited:
                    if (!didPass) {
                      retakesLeft = -1;
                    }
                    break;
                  case AllowRetakesEnum.limited:
                    retakesLeft = retakesAllowed - numCompletions + adjuster;
                    break;
                  case AllowRetakesEnum.on_fail_limited:
                    if (!didPass) {
                      retakesLeft = retakesAllowed - numCompletions + adjuster;
                    }
                    break;
                }
              }

              return (
                <LessonBannerContainer key={lesson.id} onClick={() => handleLessonRowClick(lesson)}>
                  <Box
                    sx={{
                      opacity: completions?.length ? 1 : 0.4,
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography>{`${idx + 1}. ${lesson.title}`}</Typography>
                    <Box sx={{ display: 'flex' }}>
                      {retakesLeft < 0 && (
                        <RetakeTypography>Unlimited Retakes Available</RetakeTypography>
                      )}
                      {retakesLeft > 0 && (
                        <RetakeTypography>
                          {retakesLeft} Retake{retakesLeft > 1 ? 's' : ''} Available
                        </RetakeTypography>
                      )}
                      {!didComplete && <LessonUnfinishedIcon />}
                      {didComplete && <LessonDoneIcon />}
                    </Box>
                  </Box>
                  {lesson?.abstract && (
                    <Box sx={{ mr: 2, mt: 1, opacity: completions?.length ? 1 : 0.4 }}>
                      <Typography variant='body2'>
                        {truncateString(lesson.abstract, 300)}
                      </Typography>
                    </Box>
                  )}
                </LessonBannerContainer>
              );
            })}
          </Box>
          <Box sx={{ pointerEvents: extendedCourse?.restrict_access ? 'none' : 'auto' }}>
            <JunoRotator
              isLoading={isLoading}
              slides={
                resourcesData?.map((r) => ({
                  id: r.id,
                  slug: r.id,
                  name: r.title,
                  description: r.description,
                  image: r.icon || '',
                })) || []
              }
              settings={{
                showPauseButton: false,
                title: 'Resources',
                slideVariant: 'rounded',
                slideWidth: 240,
                slideHeight: 150,
                minSpacing: 10,
                textAlign: 'left',
                showItemDescription: true,
              }}
              onSelect={(slide) => {
                if (extendedCourse?.restrict_access) return;
                const resource = resourcesData?.find((r) => r.id === slide.id);
                const url = resource?.download?.url;
                if (url) {
                  const filename =
                    resource?.download?.url?.split('/').slice(-1)[0] || resource?.title;
                  downloadUrl(url, filename, false);

                  firehoseActivity(
                    FIREHOSE_OBJECTS.OBJECT_DOWNLOAD,
                    resource?.id || '',
                    url || '',
                    `${user?.id || ''}`,
                    FIREHOSE_CATEGORIES.CATEGORY_DOWNLOAD,
                    FIREHOSE_ACTIONS.ACTION_DOWNLOAD.value,
                    resource?.title || '',
                    null,
                  );
                }
              }}
            />
          </Box>
          <Box mt={4}>
            {course?.include_feed && !loadingFeed && (
              <FeedComponent
                siteId={siteId}
                feed={courseFeed ?? ({} as Feed)}
                onNavigate={onNavigate}
              />
            )}
          </Box>
          {certificateToDownload && singleEducationCredit && openCertificate && (
            <DownloadSingleCertificateDialog
              certificateName={certificateToDownload.name ?? ''}
              downloadUrl={downloadUrl}
              certificateContent={certInterpolate(
                certificateToDownload.html_content ?? '',
                user ?? ({} as JunoUser),
                singleEducationCredit,
                getEducationCreditDateCompleted(),
                platformData?.name ?? '',
              )}
              certificateUrl={certificateUrl}
              onClose={handleCloseCertificate}
            />
          )}
        </Box>
        <SimpleDialog
          title={COURSE_STATE_MAP[courseState]?.alert?.title || ''}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        >
          {COURSE_STATE_MAP[courseState]?.alert?.message || ''}
        </SimpleDialog>
        <ScoreDialog
          score={courseScore}
          passingScore={course.passing_percent}
          open={courseCompleteDialogOpen}
          handleMoveOn={handleCourseCompletedButtonClick}
          buttonTitle='Ok'
          subject='course'
        />
        {/*Specific JL-9783 modal dialog when enrollment is restricted but preview isn't*/}
        {restrictEnrollDialogOpen && (
          <RestrictCourseEnrollDialog
            handleClose={() => {
              setRestrictEnrollDialogOpen(false);
            }}
            handleUpgrade={(url) => {
              openExternalUrl(url);
              setRestrictEnrollDialogOpen(false);
            }}
            courseName={course.title}
            clientName={clientName}
            upgradeUrl={
              course?.restrict_enrollment_access_passes?.find((pass) => pass.upgrade_enrollment_url)
                ?.upgrade_enrollment_url || ''
            }
          />
        )}
        <SimpleDialog
          open={openCannotPurchaseDialog}
          onClose={() => setOpenCannotPurchaseDialog(false)}
        >
          <Typography sx={{ fontWeight: 'bold', mb: 2 }}>Course Restriction</Typography>
          <Typography>
            This course is not accessible in the mobile app. Please enroll in this course using your
            desktop computer.
          </Typography>
        </SimpleDialog>
      </Container>
    </Box>
  );
};

export default Course;
