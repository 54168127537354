import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'usehooks-ts';
import { useGetAllUsers, useGetMyLearners } from '@juno/client-api';
import { JunoUser as JunoUserModel, Site as SiteModel } from '@juno/client-api/model';
import { VirtualScrollChild } from '@juno/modules';
import {
  CourseFilter,
  CourseFilterContext,
  CourseFilterDialog,
  FilterButton,
  FilterGridChip,
  SearchBar,
} from '@juno/ui';
import { calculateUserSearchFilters } from '@juno/utils';
import LearnerTile, { LearnersLoadingTile } from './components/LearnerTile';
import { ROLE_MAP } from './constants';
import { Controls, ShowingLabel } from './styles';
import { getHandleDeleteFilter, getHandleUpdateDateChip } from './utils';

interface LearnersListWrapperProps {
  site: SiteModel;
  roleType: string;
  tempFilter: { [key: string]: unknown };
  setTempFilter: (filter: any) => void;
}

interface LearnersListProps extends LearnersListWrapperProps {
  onFilter?: (filter: string) => void;
  filterComponent?: React.ReactNode;
}

const PAGE_SIZE = 25;

const WrappedLearnersList: React.FC<LearnersListProps> = ({
  site,
  roleType,
  onFilter,
  filterComponent,
  tempFilter,
}) => {
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const { ref: srollTopRef, inView: scrollTopInView } = useInView({
    threshold: 0,
  });
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [dateStartAfterChipLabel, setDateStartAfterChipLabel] = useState('');
  const [dateStartBeforeChipLabel, setDateStartBeforeChipLabel] = useState('');
  const courseFilterContext = useContext(CourseFilterContext);
  const prefixedTempFilter = tempFilter
    ? Object.fromEntries(
        Object.entries(tempFilter).map(([k, v]) => [`enrollments__course__${k}`, v]),
      )
    : {};

  const [search, setSearch] = useState('');
  const userIsSearchingRef = useRef(false);
  const debouncedSearch = useDebounce(search, 100);
  const [usersPageIndex, setUsersPageIndex] = useState(0);
  const [usersList, setUsersList] = useState<JunoUserModel[]>([]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // when a user is typing we need to have the server start at page 0 of the search
    setUsersPageIndex(0);
    setSearch(e.target.value);
  };

  const searchFilter = useMemo(() => {
    if (!debouncedSearch) return {};

    return calculateUserSearchFilters(debouncedSearch);
  }, [debouncedSearch]);

  const { data: learners, isLoading: learnersLoading } = useGetMyLearners(site.id, {
    query: { enabled: roleType === ROLE_MAP.MANAGER.key },
  });
  const {
    data: users,
    isLoading: usersLoading,
    isFetched: isUsersFetched,
  } = useGetAllUsers(
    site.platform_id,
    {
      limit: PAGE_SIZE,
      offset: usersPageIndex,
      filter: prefixedTempFilter,
      order: 'last_name',
      ...searchFilter,
    },
    {
      query: { enabled: roleType === ROLE_MAP.ADMIN.key },
    },
  );
  const isLoading = usersLoading || learnersLoading;

  const handleDeleteFilter = getHandleDeleteFilter(
    courseFilterContext,
    setDateStartAfterChipLabel,
    setDateStartBeforeChipLabel,
    onFilter,
  );
  const handleUpdateDateChip = getHandleUpdateDateChip(
    courseFilterContext,
    filterComponent,
    onFilter,
  );

  // Reload results when search changes
  useEffect(() => {
    setUsersPageIndex(0);
    setUsersList([]);
  }, [debouncedSearch, tempFilter]);

  useEffect(() => {
    if (!userIsSearchingRef.current && inView && isUsersFetched && users?.length !== 0) {
      setUsersPageIndex(usersPageIndex + 1);
    }
  }, [inView]);

  useEffect(() => {
    userIsSearchingRef.current = scrollTopInView;
  }, [scrollTopInView]);

  useEffect(() => {
    if (!users) return;
    const joined = _.union(usersList, users);
    const uniq = _.uniqWith(joined, _.isEqual);
    setUsersList(uniq);
  }, [users]);

  useEffect(() => {
    if (filterComponent) {
      handleUpdateDateChip('date_start__gte', setDateStartAfterChipLabel, true);
      handleUpdateDateChip('date_start__lte', setDateStartBeforeChipLabel, false);
    } else {
      setDateStartAfterChipLabel('');
      setDateStartBeforeChipLabel('');
    }
  }, [filterComponent]);

  const filteredUsers = useMemo(() => {
    let fUsers: JunoUserModel[] = [];
    if (roleType === ROLE_MAP.ADMIN.key) {
      fUsers = usersList || [];
    } else if (roleType === ROLE_MAP.MANAGER.key) {
      fUsers = learners || [];
    }
    if (!debouncedSearch) return fUsers;
    const searchWords = debouncedSearch
      .trim()
      .toLowerCase()
      .split(' ')
      .filter((word) => word !== '');
    return fUsers?.filter(
      (user) =>
        searchWords.some((word) => user.first_name.toLowerCase().includes(word)) ||
        searchWords.some((word) => user.last_name.toLowerCase().includes(word)) ||
        searchWords.some((word) => user.email.toLowerCase().includes(word)),
    );
  }, [learners, usersList]);

  return (
    <>
      <Controls>
        <SearchBar placeholder={'Search...'} onChange={handleSearchChange} />
        <FilterButton onClick={() => setFilterDialogOpen(true)} />
      </Controls>
      <Box sx={{ height: '100%', margin: `0px auto 30px auto` }}>
        <Grid container spacing={1}>
          <FilterGridChip
            label={dateStartAfterChipLabel}
            onDelete={() => handleDeleteFilter('date_start__gte')}
            enabled={!!dateStartAfterChipLabel}
          />
          <FilterGridChip
            label={dateStartBeforeChipLabel}
            onDelete={() => handleDeleteFilter('date_start__lte')}
            enabled={!!dateStartBeforeChipLabel}
          />
        </Grid>
      </Box>
      <ShowingLabel ref={srollTopRef} variant='body2'>{`Showing ${
        filteredUsers?.length ?? 0
      } Learners`}</ShowingLabel>
      {filteredUsers?.map((learner, idx) => (
        <VirtualScrollChild height={60} key={learner.id}>
          <LearnerTile
            learner={learner}
            siteId={site.id}
            imageUrl={learner.avatar || ''}
            idx={idx}
            ref={(idx === filteredUsers.length - 1 && ref) || null}
          />
        </VirtualScrollChild>
      ))}
      {isLoading && <LearnersLoadingTile />}
      <CourseFilterDialog
        open={filterDialogOpen}
        onClose={() => setFilterDialogOpen(false)}
        onFilter={onFilter}
        filterComponent={filterComponent}
      />
    </>
  );
};

// Each course list needs its own context.
// Since the filter is built with a shared context, we will wrap the list and create unique context.
const LearnersList: React.FC<LearnersListWrapperProps> = (learnersListProps) => {
  const handleDateUpdate = (dateVal: Dayjs | null, key: string) => {
    if (!dateVal) {
      learnersListProps.setTempFilter((current: any) => {
        const { [key]: _, ...rest } = current;
        return rest;
      });
      return;
    }
    const dateObj = dayjs(dateVal);
    if (!dateObj.isValid()) return;
    learnersListProps.setTempFilter((current: any) => {
      return { ...current, [key]: dateObj.format() };
    });
  };

  const filterComponent = (
    <CourseFilter
      site={learnersListProps.site}
      onChange={learnersListProps.setTempFilter}
      values={learnersListProps.tempFilter}
      settings={{ showFilterStartDates: true }}
      preFilterOptions={{}}
    />
  );
  const wrappedProps: LearnersListProps = {
    ...learnersListProps,
    onFilter: () => {},
    filterComponent: filterComponent,
    tempFilter: learnersListProps.tempFilter,
  };
  return (
    <CourseFilterContext.Provider
      value={{
        tempFilter: learnersListProps.tempFilter,
        handleDateUpdate: handleDateUpdate,

        // Not yet used by Course List. Filler values.
        siteId: '',
        platformId: '',
        handleStringListUpdate: () => {},
        handleTagListUpdate: () => {},
        instructorsRemoved: [],
        setInstructorsRemoved: () => {},
        tagsRemoved: {},
        setTagsRemoved: () => {},
      }}
    >
      <WrappedLearnersList {...wrappedProps}></WrappedLearnersList>
    </CourseFilterContext.Provider>
  );
};

export default LearnersList;
