/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type SessionMediaTypeEnum = typeof SessionMediaTypeEnum[keyof typeof SessionMediaTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SessionMediaTypeEnum = {
  resource: 'resource',
  playable: 'playable',
} as const;
