import React, { useState } from 'react';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
  DateTimePicker as MuiDateTimePicker,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useFeatureFlag } from 'configcat-react';
import { FormikErrors } from 'formik';
import { ReoccurrenceEnum } from '@juno/client-api/model';

interface DateTimePickerProps {
  onChange: (field: string, date: Date | null | string) => void;
  selectedStartDate: Date;
  selectedEndDate: Date;
  selectedEndTime?: Date;
  errors?: FormikErrors<any>;
  reoccurrence?: ReoccurrenceEnum;
  fullWidth?: boolean;
}

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  selectedStartDate,
  selectedEndDate,
  selectedEndTime,
  onChange,
  errors,
  reoccurrence = ReoccurrenceEnum.once,
  fullWidth = false,
}) => {
  const { value: recurringSessions } = useFeatureFlag('recurringSessions', false);

  const [allDay, setAllDay] = useState<boolean>(false);
  const [allDayDisabled, setAllDayDisabled] = useState<boolean>(
    reoccurrence !== ReoccurrenceEnum.once,
  );
  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={fullWidth ? 12 : 4}>
            <DatePicker
              label='Start Date'
              value={selectedStartDate}
              onAccept={(newValue) => {
                if (newValue) {
                  onChange('date_start', newValue.toISOString());
                }
              }}
              onChange={(newValue) => {
                if (newValue) {
                  onChange('date_start', newValue.toISOString());
                }
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
              componentsProps={{
                actionBar: {
                  actions: ['today'],
                },
              }}
            />
            {errors && errors.date_start && (
              <Typography variant='caption' color='error'>
                {errors.date_start}
              </Typography>
            )}
          </Grid>
          <Grid item xs={fullWidth ? 12 : 5}>
            <TimePicker
              label='Start Time'
              value={selectedStartDate}
              onChange={(newValue) => {
                if (newValue) {
                  onChange('date_start', newValue.toISOString());
                }
              }}
              onAccept={(newValue) => {
                if (newValue) {
                  onChange('date_start', newValue.toISOString());
                }
              }}
              renderInput={(params) => <TextField {...params} sx={{ width: '50%', pr: '2px' }} />}
              openTo='hours'
              views={['hours', 'minutes', 'seconds']}
              inputFormat='hh:mm:ss A'
              mask='__:__:__ _A'
            />
            {errors && errors.time_start && (
              <Typography variant='caption' color='error'>
                {errors.time_start}
              </Typography>
            )}
            {recurringSessions && (
              <>
                <TimePicker
                  disabled={allDay}
                  label='End Time'
                  value={selectedEndTime || selectedStartDate}
                  onChange={(newValue) => {
                    if (newValue) {
                      onChange('time_end', newValue.toISOString());
                    }
                  }}
                  onAccept={(newValue) => {
                    if (newValue) {
                      onChange('time_end', newValue.toISOString());
                    }
                  }}
                  renderInput={(params) => <TextField {...params} sx={{ width: '50%' }} />}
                  openTo='hours'
                  views={['hours', 'minutes', 'seconds']}
                  inputFormat='hh:mm:ss A'
                  mask='__:__:__ _A'
                />
                {errors && errors.time_end && (
                  <Typography variant='caption' color='error'>
                    {errors.time_end}
                  </Typography>
                )}
              </>
            )}
          </Grid>
          {recurringSessions && (
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allDay}
                    disabled={allDayDisabled}
                    onChange={(event) => setAllDay(event.target.checked)}
                  />
                }
                label='All day'
              />
            </Grid>
          )}
          {recurringSessions && (
            <Grid item xs={fullWidth ? 9 : 12}>
              <TextField
                fullWidth={fullWidth}
                value={reoccurrence}
                onChange={(e) => {
                  onChange('reoccurrence', e.target.value);
                  if (e.target.value === ReoccurrenceEnum.once) {
                    setAllDayDisabled(false);
                  } else {
                    setAllDay(false);
                    setAllDayDisabled(true);
                  }
                }}
                select
                label='Recurring'
              >
                <MenuItem sx={{ fontSize: 'small' }} value={ReoccurrenceEnum.once}>
                  Does not repeat
                </MenuItem>
                <MenuItem sx={{ fontSize: 'small' }} value={ReoccurrenceEnum.daily}>
                  Daily
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: 'small' }}
                  value={ReoccurrenceEnum.weekly}
                >{`Weekly on ${selectedStartDate.toLocaleDateString('en-US', {
                  weekday: 'long',
                })}`}</MenuItem>
                <MenuItem sx={{ fontSize: 'small' }} value={ReoccurrenceEnum.monthly}>
                  {calculateMonthlyItem(selectedStartDate)}
                </MenuItem>
                <MenuItem sx={{ fontSize: 'small' }} value={ReoccurrenceEnum.yearly}>
                  Yearly
                </MenuItem>
              </TextField>
            </Grid>
          )}
          <Grid item xs={fullWidth ? 12 : 5}>
            <Box display={'flex'} justifyContent={'left'} flexDirection={'column'}>
              <MuiDateTimePicker
                label='End Date'
                value={selectedEndDate}
                onChange={(newValue) => {
                  if (newValue) {
                    onChange('date_end', newValue.toISOString());
                  }
                }}
                onAccept={(newValue) => {
                  if (newValue) {
                    onChange('date_end', newValue.toISOString());
                  }
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
                componentsProps={{
                  actionBar: {
                    actions: ['today'],
                  },
                }}
                inputFormat='MM/DD/YYYY hh:mm:ss A'
                mask='____/__/__ __:__ _M'
              />
              {errors && errors.date_end && (
                <Typography variant='caption' color='error'>
                  {errors.date_end}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Box>
  );
};

export default DateTimePicker;

const calculateMonthlyItem = (date: Date) => {
  // return in the format "Monthly on the Nth Wednseday"
  let nth = Math.ceil(date.getDate() / 7);
  if (nth > 4) {
    nth = 4;
  }
  const day = date.toLocaleDateString('en-US', { weekday: 'long' });
  let nthSuffix = 'th';
  if (nth === 1) {
    nthSuffix = 'st';
  } else if (nth === 2) {
    nthSuffix = 'nd';
  } else if (nth === 3) {
    nthSuffix = 'rd';
  } else if (nth > 3) {
    nthSuffix = 'th';
  }
  return `Monthly on the ${nth}${nthSuffix} ${day}`;
};
