import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import { Card, Grid, List, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useFeatureFlag } from 'configcat-react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  useAddCommunityGroupMember,
  useAddCommunityGroupPendingMember,
  useCreateNewSession,
  useCreateSessionParent,
  useGetAllSessions,
  useGetCommunityGroup,
  useGetCommunityGroupFeed,
  useGetCommunityGroupForum,
  useGetMe,
  useRemoveCommunityGroupMember,
  useRemoveCommunityGroupPendingMember,
} from '@juno/client-api';
import {
  FeatureConfig,
  FeatureConfigConfig,
  FeatureConfigTypeEnum,
  Feed,
  Forum,
  PrivacyEnum,
  RoleEnum,
  Session,
  TabsOrderEnum,
  TypeA36Enum,
} from '@juno/client-api/model';
import { ANALYTICS_CONFIGURATION, EMPTY_GROUP_URL } from '@juno/constants';
import {
  FeedComponent,
  ForumComponent,
  ModerationAdmin,
  Schedule,
  useAnalyticsContext,
  usePubnubContext,
} from '@juno/modules';
import {
  Container,
  CreateNewSessionDialog,
  DefaultHeroBanner,
  DefaultSearchSortFilter,
  Juno404,
} from '@juno/ui';
import { MutationAction, a11yProps, onMutation, useSettings } from '@juno/utils';
import { useBreakpoint } from '@juno/utils/hooks';
import GroupFilesTab from '../../shared/GroupFilesTab';
import AboutTab from './AboutTab';
import GroupPageSkeleton from './GroupPageSkeleton';
import { TABS, TabProps } from './utils';

export interface GroupHierarchyFeatureConfigModel extends FeatureConfig {
  config: GroupHierarchyConfigModel;
}
export interface GroupHierarchyConfigModel extends FeatureConfigConfig {
  group_hierarchy?: boolean;
}

interface GroupPageProps {
  siteId: string;
  platformId: string;
}

const GroupPage: React.FC<GroupPageProps> = ({ siteId, platformId }) => {
  const settings = useSettings();
  const { xs, md } = useBreakpoint();
  const { pubnub } = usePubnubContext();
  const { firehoseActivity } = useAnalyticsContext();
  const [joinLeaveLoading, setJoinLeaveLoading] = useState(false);
  const { groupSlug } = useParams();
  const {
    onNavigate,
    user,
    configs,
    impliedAdminOfGroups,
    impliedMemberOfGroups,
    site,
    setReportContent,
  } = settings;
  const navigate = useNavigate();
  const location = useLocation();
  const { value: moderationValue } = useFeatureFlag('moderation', false);

  const { data: extendedUser, refetch: refetchMe } = useGetMe(platformId);
  const [createSessionOpen, setCreateSessionOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [search, setSearch] = useState('');
  const [sessionsForGrid, setSessionsForGrid] = useState<Session[]>([]);

  const integrationConfig = configs?.find(
    (c) => c.type === FeatureConfigTypeEnum.integrations,
  ) as GroupHierarchyFeatureConfigModel;
  const hlConfig = integrationConfig?.config?.group_hierarchy;

  const bannerHeight = xs ? '32vw' : md ? '246px' : '294px';
  const [sort, setSort] = useState('date_start');

  const addMember = useAddCommunityGroupMember(onMutation(MutationAction.UPDATE, '', () => {}));
  const removeMember = useRemoveCommunityGroupMember(
    onMutation(MutationAction.UPDATE, '', () => {}),
  );
  const addPendingMember = useAddCommunityGroupPendingMember(
    onMutation(MutationAction.UPDATE, '', () => {}),
  );
  const removePendingMember = useRemoveCommunityGroupPendingMember(
    onMutation(MutationAction.UPDATE, '', () => {}),
  );

  const {
    data: thisGroup,
    isLoading,
    refetch: refetchGroup,
  } = useGetCommunityGroup(siteId, groupSlug || '', { query: { enabled: !!groupSlug } });

  const { data: allSessions } = useGetAllSessions(
    siteId,
    {
      order: sort,
      filter: {
        active: true,
      },
    },
    {
      query: {
        enabled: !!siteId && createSessionOpen,
      },
    },
  );

  useEffect(() => {
    if (thisGroup?.sessions) {
      const filteredSessions = thisGroup.sessions.filter((session) => {
        return session.title.toLowerCase().includes(search.toLowerCase());
      });
      const sortedSessions = filteredSessions.sort((a, b) => {
        if (!a.date_start || !b.date_start) return 0;
        if (sort === 'date_start') {
          return new Date(a.date_start).getTime() - new Date(b.date_start).getTime();
        }
        return a.title.localeCompare(b.title);
      });
      setSessionsForGrid(sortedSessions);
    }
  }, [thisGroup, search, sort]);

  const tabsOrder = thisGroup?.tabs_order || [TabsOrderEnum.about];
  const tabsShown = thisGroup?.tabs_shown || [TabsOrderEnum.about];

  const [currentTab, setCurrentTab] = useState<TabsOrderEnum>(TabsOrderEnum.about);

  const [currentQueryParameters, setSearchParams] = useSearchParams();
  const handleTabChange = (event: React.SyntheticEvent, newTab: TabsOrderEnum) => {
    setSearchParams({});
    setCurrentTab(newTab);
  };

  useEffect(() => {
    if (!thisGroup) return;

    // grab the last string in the pathname as the tab
    const tab = (location.pathname.split('/').pop() as TabsOrderEnum) || TabsOrderEnum.about;
    //check to make sure we dont have the group slug or id (--> legacy notifications don't have a tab specified)
    if (!tab?.includes(thisGroup?.slug || thisGroup?.id)) {
      setCurrentTab(tab);
      return;
    }

    // Legacy code fallback to grab the correct tab from the query parameters for notifications
    const threadIdParam = currentQueryParameters.get('thread_id');
    const isForum = currentQueryParameters.get('forum');
    if (isForum === 'True') {
      setCurrentTab(TabsOrderEnum.discussions);
    } else if (threadIdParam) {
      setCurrentTab(TabsOrderEnum.feed);
    } else {
      setCurrentTab(TabsOrderEnum.about);
    }
  }, [thisGroup, currentQueryParameters]);

  const { data: groupFeed } = useGetCommunityGroupFeed(
    siteId,
    thisGroup?.id || '',
    {},
    { query: { enabled: !!thisGroup && currentTab === TabsOrderEnum.feed } },
  );

  const { data: groupForum } = useGetCommunityGroupForum(
    siteId,
    thisGroup?.id || '',
    {},
    { query: { enabled: !!thisGroup && currentTab === TabsOrderEnum.discussions } },
  );

  const iAmMember = useMemo(() => {
    return !!extendedUser?.community_groups_member?.find((group) => group === thisGroup?.id);
  }, [extendedUser, thisGroup?.id]);

  const iAmImpliedMember = useMemo(() => {
    return !!impliedMemberOfGroups?.find((g) => thisGroup?.id === g);
  }, [impliedMemberOfGroups, thisGroup?.id]);

  const iAmPendingMember = useMemo(() => {
    return !!extendedUser?.community_groups_pending_member?.find(
      (group) => group === thisGroup?.id,
    );
  }, [extendedUser, thisGroup?.id]);

  const iAmAdmin = useMemo(() => {
    if (extendedUser?.role === RoleEnum.NUMBER_1) return true;
    if (thisGroup?.admins?.find((admin) => admin.id === extendedUser?.id)) return true;
    if (impliedAdminOfGroups?.find((g) => thisGroup?.id === g)) return true;
    return false;
  }, [thisGroup?.admins, extendedUser, impliedAdminOfGroups]);

  useEffect(() => {
    // Subscribe to the group channel to report presence
    if (thisGroup && user) {
      pubnub.subscribe({ channels: [thisGroup.id], withPresence: true });
    }
    return () => {
      // Unsubscribe from the course channel to stop reporting presence
      if (thisGroup) {
        pubnub.unsubscribe({ channels: [thisGroup.id] });
      }
    };
  }, [pubnub, thisGroup, user]);

  const handleJoinLeave = async () => {
    setJoinLeaveLoading(true);
    try {
      if (iAmMember) {
        // leave group
        await removeMember.mutateAsync({
          siteId,
          groupId: thisGroup?.id || '',
          userId: extendedUser?.id || '',
        });
      } else if (iAmPendingMember) {
        // revoke join request
        await removePendingMember.mutateAsync({
          siteId,
          groupId: thisGroup?.id || '',
          userId: extendedUser?.id || '',
        });
      } else if (thisGroup?.privacy === PrivacyEnum.public) {
        // join group
        await addMember.mutateAsync({
          siteId,
          groupId: thisGroup?.id || '',
          data: {
            id: extendedUser?.id || '',
            first_name: extendedUser?.first_name || '',
            last_name: extendedUser?.last_name || '',
            email: extendedUser?.email || '',
          },
        });
      } else if (thisGroup?.privacy === PrivacyEnum.private) {
        // request to join group
        await addPendingMember.mutateAsync({
          siteId,
          groupId: thisGroup?.id || '',
          data: {
            id: extendedUser?.id || '',
            first_name: extendedUser?.first_name || '',
            last_name: extendedUser?.last_name || '',
            email: extendedUser?.email || '',
          },
        });
      }
      await refetchMe();
      await refetchGroup();
      setJoinLeaveLoading(false);
    } catch (e) {
      console.error(e);
      setJoinLeaveLoading(false);
    }
  };

  const buttonText = useMemo(() => {
    if (iAmMember) return `Leave ${thisGroup?.title}`;
    if (iAmPendingMember) return 'Cancel Join Request';
    if (thisGroup?.privacy === PrivacyEnum.public) return `Join ${thisGroup?.title}`;
    if (thisGroup?.privacy === PrivacyEnum.private) return `Request to Join ${thisGroup?.title}`;
    if (thisGroup?.privacy === PrivacyEnum.secret) return `Join ${thisGroup?.title}`;
    return '';
  }, [iAmMember, iAmPendingMember, thisGroup?.privacy]);

  const sessionCreate = useCreateNewSession(onMutation(MutationAction.CREATE, 'Session'));
  const sessionParentCreate = useCreateSessionParent(
    onMutation(MutationAction.CREATE, '', refetchGroup),
  );
  const handleSaveNewSession = async (session: Session) => {
    setIsSaving(true);
    try {
      if (session && thisGroup) {
        const newSession = await sessionCreate.mutateAsync({ siteId, data: session });
        // set session parent to this group
        await sessionParentCreate.mutateAsync({
          siteId,
          sessionId: newSession.id,
          parentId: thisGroup.id,
          contentType: 'community.communitygroup',
        });
        setIsSaving(false);
        setCreateSessionOpen(false);
        navigate(`/${site?.slug}/sessions/${newSession.slug}/edit`);
      }
    } catch (e) {
      console.error(e);
      setIsSaving(false);
      setCreateSessionOpen(false);
    }
  };

  const hasReportedView = useRef(false);
  useEffect(() => {
    // Record group view analytics only once
    if (thisGroup && user && !hasReportedView.current) {
      hasReportedView.current = true;
      firehoseActivity(
        ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_CONTENT,
        thisGroup?.id || '',
        null,
        `${user?.id || ''}`,
        ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_COMMUNITY,
        ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_VIEW.value,
        thisGroup.title,
        null,
      );
    }
  }, [firehoseActivity, thisGroup, user]);

  // get group if applicable for content being reported
  useEffect(() => {
    if (!thisGroup) return;
    setReportContent((old) => {
      return { ...old, contentBeingReported: { group: thisGroup.id } };
    });
  }, [thisGroup]);

  // reset when we leave page
  useEffect(() => {
    return () => {
      setReportContent((old) => {
        return { ...old, contentBeingReported: undefined };
      });
    };
  }, []);

  if (!iAmAdmin && !iAmMember && thisGroup?.privacy === PrivacyEnum.secret && !isLoading)
    return <Juno404 />;

  return (
    <Box pb={3}>
      {isLoading && <GroupPageSkeleton />}
      {!isLoading && (
        <>
          <Container>
            <Card>
              <DefaultHeroBanner
                imgSrc={thisGroup?.banner_image || EMPTY_GROUP_URL}
                imgAlt={`Banner image for ${thisGroup?.title} group`}
                height={bannerHeight}
              />

              <Grid container>
                <Grid item xs={12}>
                  <Typography
                    variant='h4'
                    color='text.primary'
                    sx={{ fontWeight: 'bold', pr: 1, pl: 3, pt: 2 }}
                  >
                    {thisGroup?.title}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box key={`${thisGroup?.id}`} role='tabpanel' hidden={false} pt={2} pr={1}>
                    <Tabs
                      value={currentTab}
                      onChange={handleTabChange}
                      variant='scrollable'
                      scrollButtons='auto'
                      role='navigation'
                    >
                      {tabsOrder
                        ?.filter((tab) => {
                          return tabsShown.includes(tab);
                        })
                        .map((tab) => {
                          if (!iAmAdmin && tab === TabsOrderEnum.moderation) return null;
                          const item = TABS?.find((t) => t.slug === tab) || ({} as TabProps);
                          return (
                            <Tab
                              key={item.slug}
                              label={item.label}
                              component={Link}
                              value={item.slug}
                              to={`${item.slug}`}
                              sx={{
                                textTransform: 'none',
                                letterSpacing: '1px',
                                display:
                                  thisGroup?.privacy === PrivacyEnum.private &&
                                  !iAmMember &&
                                  !iAmImpliedMember &&
                                  tab !== TabsOrderEnum.about
                                    ? 'none'
                                    : 'block',
                              }}
                              {...a11yProps(tab, item?.slug || '')}
                            />
                          );
                        })}
                    </Tabs>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </Container>

          <Container>
            <TabContext value={currentTab}>
              {/* Feed */}
              <TabPanel sx={{ p: 0, pt: 1 }} value={TabsOrderEnum.feed}>
                <FeedComponent
                  blockUserInteractionMessage='You must be a member of this group to create, like or view posts and comments.'
                  userCanInteract={iAmMember || iAmImpliedMember}
                  iAmAdmin={iAmAdmin}
                  siteId={siteId}
                  feed={groupFeed ?? ({} as Feed)}
                  showTitle={false}
                  onNavigate={onNavigate}
                />
              </TabPanel>
              {/* About */}
              <TabPanel sx={{ p: 0, pt: 1 }} value={TabsOrderEnum.about}>
                <AboutTab
                  siteId={siteId}
                  groupId={thisGroup?.id ?? ''}
                  thisGroup={thisGroup}
                  iAmAdmin={iAmAdmin}
                  iAmMember={iAmMember}
                  handleJoinLeave={handleJoinLeave}
                  joinLeaveLoading={joinLeaveLoading}
                  buttonText={buttonText}
                  hlConfig={hlConfig}
                />
              </TabPanel>
              {/* Discussions */}
              <TabPanel sx={{ p: 0, pt: 1 }} value={TabsOrderEnum.discussions}>
                <ForumComponent
                  siteId={siteId}
                  userCanInteract={iAmMember || iAmImpliedMember}
                  iAmAdmin={iAmAdmin}
                  forum={groupForum ?? ({} as Forum)}
                  feedBlockUserInteractionMessage='You must be a member of this group to create, like or view posts and comments.'
                  topicsEnabled={
                    iAmAdmin ||
                    ((iAmMember || iAmImpliedMember) && thisGroup?.allow_member_forum_topics)
                  }
                />
              </TabPanel>

              {/* Resources */}
              <TabPanel sx={{ p: 0, pt: 1 }} value={TabsOrderEnum.resources}>
                <Box>
                  <GroupFilesTab
                    iAmAdmin={iAmAdmin}
                    siteId={siteId}
                    group={thisGroup}
                    refetchGroup={refetchGroup}
                    userCanInteract={iAmMember || iAmImpliedMember}
                  />
                </Box>
              </TabPanel>
              {/* Meetings */}
              <TabPanel sx={{ p: 0, pt: 1 }} value={TabsOrderEnum.meetings}>
                <Box>
                  <DefaultSearchSortFilter
                    buttonDisabled={iAmAdmin || iAmMember || iAmImpliedMember ? false : true}
                    buttonText={'New Meeting'}
                    onClickButton={() => setCreateSessionOpen(true)}
                    setSearch={setSearch}
                    sort={sort}
                    setSort={setSort}
                    sortOptions={[
                      { value: 'date_start', label: 'Date' },
                      { value: 'title', label: 'Title' },
                    ]}
                    showFilter={false}
                  />
                  {sessionsForGrid && sessionsForGrid.length > 0 ? (
                    <List>
                      <Schedule
                        isLoading={isLoading}
                        sessions={sessionsForGrid}
                        refetchSessions={() => {
                          refetchGroup();
                        }}
                        page={0}
                        limit={0}
                        showSearchFilter={false}
                      />
                    </List>
                  ) : null}
                </Box>
              </TabPanel>
              {/* Moderation */}
              {iAmAdmin && moderationValue && (
                <TabPanel sx={{ p: 0, pt: 1 }} value={TabsOrderEnum.moderation}>
                  <ModerationAdmin groupId={thisGroup?.id || ''} />
                </TabPanel>
              )}
            </TabContext>
            {/* // TODO future build out */}
            {/* <Grid item xs={12} md={4}>
                <Box sx={{ pl: 2 }}>
                  <Card sx={{ mb: 4, p: 2 }}>
                    <Typography variant='h5'>Group Leaderboard</Typography>
                  </Card>
                  <Card sx={{ mb: 4, p: 2 }}>
                    <Typography variant='caption'>Top 5 Members</Typography>
                  </Card>
                </Box>
              </Grid> */}
          </Container>
          <CreateNewSessionDialog
            open={createSessionOpen}
            onClose={() => setCreateSessionOpen(false)}
            onSave={handleSaveNewSession}
            isSaving={isSaving}
            allSessions={allSessions || []}
            eventType={TypeA36Enum.NUMBER_2}
          />
        </>
      )}
    </Box>
  );
};
export default GroupPage;
