import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useDebounce } from 'usehooks-ts';
import { Component as ComponentModel, ComponentTypeEnum } from '@juno/client-api/model';

interface FeedForumFormProps {
  component?: ComponentModel;
  title: string;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
}

const DEFAULT_DATA = {
  id: '',
  name: 'Blurb',
  type: ComponentTypeEnum.global_feed,
  data: { html: '<h1 style="text-align: center;">Blurb Placeholder</h1>' },
};

const FeedForumForm: React.FC<FeedForumFormProps> = ({ component, onSave, onUpdate, title }) => {
  const [payload, setPayload] = useState<ComponentModel | undefined>();
  const debouncedPayload = useDebounce(payload, 200);
  useEffect(() => component && setPayload(component), [component]);
  useEffect(() => {
    debouncedPayload && onUpdate({ ...debouncedPayload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPayload]);
  const handleChange = ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
    payload && setPayload({ ...payload, data: { ...payload.data, [name]: value } });
  };

  return (
    <Stack direction='column'>
      <Typography variant='body2' sx={{ opacity: 0.6 }}>
        Editing Module
      </Typography>
      <Typography variant='h5' sx={{ mb: 4 }}>
        {title}
      </Typography>
      <TextField
        label='Title'
        onChange={handleChange}
        name='title'
        value={(payload?.data?.title as string) || ''}
        InputProps={{ disableUnderline: true }}
        variant='filled'
      />
    </Stack>
  );
};

export default FeedForumForm;
