import React, { useMemo, useRef, useState } from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import {
  Avatar,
  Box,
  Button,
  Card,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { getGetMeQueryKey, usePinGroup } from '@juno/client-api';
import {
  FeatureConfigTypeEnum,
  SearchContentTypeEnum,
  SidebarWidget,
  Site,
} from '@juno/client-api/model';
import { JunoIcon } from '@juno/ui';
import { MutationAction, getContentRoute, onMutation, useSettings } from '@juno/utils';
import SideNav from '../Admin/SideNav';

interface SideBarProps {
  site: Site;
}
const SideBar: React.FC<SideBarProps> = ({ site }) => {
  const { pathname } = useLocation();
  const { sidebarWidgets } = useSettings();
  if (pathname.includes('admin/pages/')) return <></>;

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: 300,
        ml: 'auto',
        boxShadow: 'none',
        '.MuiAvatar-root': {
          width: 30,
          height: 30,
        },
      }}
    >
      {pathname.includes('admin') && <SideNav siteSlug={site.slug} />}
      {!pathname.includes('admin') && (
        <UserSidebar hideEmptyWidgets={true} sidebarWidgets={sidebarWidgets || []} />
      )}
    </Box>
  );
};

export default SideBar;

interface UserSidebarProps {
  sidebarWidgets: SidebarWidget[];
  hideEmptyWidgets: boolean;
}

export const UserSidebar: React.FC<UserSidebarProps> = ({ sidebarWidgets, hideEmptyWidgets }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enableLearning } = useSettings();
  const [groupsExpanded, setGroupsExpanded] = useState(false);
  const groupsRef = useRef<HTMLElement | null>(null);
  const [coursesExpanded, setCoursesExpanded] = useState(false);
  const coursesRef = useRef<HTMLElement | null>(null);

  const showGroupsMore =
    !!groupsRef?.current?.scrollHeight && groupsRef?.current?.scrollHeight > 200;
  const showCoursesMore =
    !!coursesRef?.current?.scrollHeight && coursesRef?.current?.scrollHeight > 200;

  const { myGroups: groups, myCurrentCourses, quickLinks, site, user } = useSettings();
  const queryClient = useQueryClient();

  const pinGroup = usePinGroup(
    onMutation(MutationAction.UPDATE, '', () =>
      queryClient.invalidateQueries(getGetMeQueryKey(site?.platform_id || '')),
    ),
  );

  const myGroups = useMemo(
    () => groups?.sort((a, b) => (user?.pinned_groups?.includes(a.id) ? -1 : 1)),
    [groups, user?.pinned_groups],
  );

  const showGroups = (myGroups && myGroups.length > 0) || !hideEmptyWidgets;
  const showCourses =
    ((myCurrentCourses && myCurrentCourses.length > 0) || !hideEmptyWidgets) && enableLearning;

  return (
    <>
      {sidebarWidgets?.map((widget) => {
        // TODO add keys
        if (widget?.hidden) return <></>;
        switch (widget.type) {
          case 'quick_links':
            return (
              <motion.div key={widget.type}>
                <Card sx={{ boxShadow: 1, mb: 1.5 }}>
                  <List>
                    <ListItem>
                      <Typography variant='overline'>{widget.name}</Typography>
                    </ListItem>

                    {quickLinks
                      ?.sort((a, b) => (a.order || 0) - (b.order || 0))
                      .map((link) => (
                        <ListItemButton
                          key={link.id}
                          onClick={() => {
                            if (link?.internal) {
                              navigate(
                                getContentRoute(
                                  site?.slug || '',
                                  link?.internal_link_object?.slug || '',
                                  link?.search_content_type as SearchContentTypeEnum,
                                ),
                              );
                            } else {
                              window.open(link?.link || '', '_blank');
                            }
                          }}
                        >
                          <ListItemIcon>
                            {link.icon && <JunoIcon name={link.icon} />}
                            {!link.icon && link.internal && link.internal_link_object?.icon && (
                              <Avatar
                                sx={{ height: 30, width: 30 }}
                                src={link.internal_link_object.icon}
                                variant='rounded'
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText>{link.text}</ListItemText>
                        </ListItemButton>
                      ))}
                  </List>
                </Card>
              </motion.div>
            );

          case 'groups':
            return (
              <motion.div key={widget.type}>
                <Box>
                  {showGroups && (
                    <Card sx={{ mb: 1.5, boxShadow: 1 }}>
                      <List sx={{ pb: showGroupsMore ? 4 : 1 }}>
                        <ListItem>
                          <Typography variant='overline'>{widget.name}</Typography>
                        </ListItem>
                        {/* <ListItemButton
                              onClick={() => navigate(`${site.slug}/you/groups`)}
                              selected={pathname.includes('/you/groups')}
                            >
                              <ListItemIcon>
                                {pathname.includes('/you/groups') ? <GroupsIcon /> : <GroupsOutlinedIcon />}
                              </ListItemIcon>
                              <ListItemText>My Groups</ListItemText>
                            </ListItemButton> */}
                        <Box
                          sx={{
                            maxHeight: groupsExpanded ? 'none' : 200,
                            overflow: 'hidden',
                          }}
                        >
                          <Box ref={groupsRef}>
                            {myGroups?.map((group) => (
                              <ListItemButton
                                key={group.id}
                                onClick={() =>
                                  navigate(
                                    getContentRoute(
                                      site?.slug || '',
                                      group?.slug,
                                      SearchContentTypeEnum.group,
                                    ),
                                  )
                                }
                                sx={{ '&:hover .MuiIconButton-root': { display: 'flex' } }}
                              >
                                <ListItemIcon>
                                  <Avatar variant='rounded' src={group.list_image} />
                                </ListItemIcon>
                                <ListItemText>{group.title}</ListItemText>
                                <IconButton
                                  sx={{
                                    display: user?.pinned_groups?.includes(group.id)
                                      ? 'flex'
                                      : 'none',
                                    position: 'absolute',
                                    right: 10,
                                    height: 25,
                                    width: 25,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    pinGroup.mutateAsync({
                                      siteId: site?.id || '',
                                      groupId: group.id,
                                    });
                                  }}
                                >
                                  {user?.pinned_groups?.includes(group.id) ? (
                                    <Tooltip title='Unpin group'>
                                      <PushPinIcon
                                        sx={{
                                          transform: 'rotate(90deg)',
                                          fontSize: 14,
                                          color: (theme) => theme.palette.primary.main,
                                        }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title='Pin group'>
                                      <PushPinOutlinedIcon
                                        sx={{
                                          transform: 'rotate(90deg)',
                                          fontSize: 14,
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </IconButton>
                              </ListItemButton>
                            ))}
                            {showGroupsMore && (
                              <Button
                                size='small'
                                onClick={() => setGroupsExpanded(!groupsExpanded)}
                                sx={{
                                  position: 'absolute',
                                  bottom: 3,
                                  right: 3,
                                  textTransform: 'uppercase',
                                }}
                              >
                                {groupsExpanded ? 'See Less' : 'See All'}
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </List>
                    </Card>
                  )}
                </Box>
              </motion.div>
            );

          case 'courses':
            return (
              <motion.div key={widget.type}>
                <Box>
                  {showCourses && (
                    <Card sx={{ mb: 1.5, boxShadow: 1 }}>
                      <List sx={{ pb: showCoursesMore ? 4 : 1 }}>
                        <ListItem>
                          <Typography variant='overline'>{widget.name}</Typography>
                        </ListItem>
                        {/* <ListItemButton
              onClick={() => navigate(`${site.slug}/you/courses`)}
              selected={pathname.includes('/you/courses')}
            >
              <ListItemIcon>
                {pathname.includes('/you/courses') ? <SchoolIcon /> : <SchoolOutlinedIcon />}
              </ListItemIcon>
              <ListItemText>My Courses</ListItemText>
            </ListItemButton> */}
                        <Box
                          sx={{
                            maxHeight: coursesExpanded ? 'none' : 200,
                            overflow: 'hidden',
                          }}
                        >
                          <Box ref={coursesRef}>
                            {myCurrentCourses?.map((course) => (
                              <ListItemButton
                                key={course.id}
                                onClick={() =>
                                  navigate(
                                    getContentRoute(
                                      site?.slug || '',
                                      course?.slug,
                                      SearchContentTypeEnum.course,
                                    ),
                                  )
                                }
                                selected={pathname.includes(`/${course.slug}`)}
                              >
                                <ListItemIcon>
                                  <Avatar variant='rounded' src={course.icon} />
                                </ListItemIcon>
                                <ListItemText>{course.title}</ListItemText>
                              </ListItemButton>
                            ))}
                            {showCoursesMore && (
                              <Button
                                size='small'
                                onClick={() => setCoursesExpanded(!coursesExpanded)}
                                sx={{
                                  position: 'absolute',
                                  bottom: 8,
                                  right: 8,
                                  textTransform: 'uppercase',
                                }}
                              >
                                {coursesExpanded ? 'See Less' : 'See All'}
                              </Button>
                            )}
                          </Box>
                        </Box>
                      </List>
                    </Card>
                  )}
                </Box>
              </motion.div>
            );
          case 'sessions':
            return (
              <motion.div key={widget.type}>
                <Card sx={{ mb: 1.5, boxShadow: 1 }}>
                  <List>
                    <ListItem>
                      <Typography variant='overline'>{widget.name}</Typography>
                    </ListItem>
                    <ListItemButton
                      onClick={() => navigate(`${site?.slug}/you/schedule`)}
                      selected={pathname.includes('/you/schedule')}
                    >
                      <ListItemIcon>
                        {pathname.includes('/you/schedule') ? (
                          <CalendarMonthIcon />
                        ) : (
                          <CalendarMonthOutlinedIcon />
                        )}
                      </ListItemIcon>
                      <ListItemText>My Schedule</ListItemText>
                    </ListItemButton>
                  </List>
                </Card>
              </motion.div>
            );
          default:
            return null;
        }
      })}

      {/* Uncomment when we have time to build the tags landing page
  
  <Card sx={{ mt: 1.5 }}>
    <List>
      <ListItem sx={{ fontWeight: 'bold', fontSize: '.95rem' }}>Explore</ListItem>
      <TagsBar />
    </List>
  </Card> */}
    </>
  );
};
