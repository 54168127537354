import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, DialogActions, DialogContent, Stack, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import { useBlocker } from 'react-router-dom';
import {
  getGetFeatureConfigsPublicQueryKey,
  getGetFeatureConfigsQueryKey,
  getGetNavigationItemsQueryKey,
  getGetQuickLinksQueryKey,
  getGetSidebarWidgetsQueryKey,
  useCreateUpdateNavigationItems,
  useCreateUpdateQuickLinks,
  useUpdateSidebarWidgets,
  useUpdateSiteFeatureConfig,
} from '@juno/client-api';
import {
  FeatureConfig,
  FeatureConfigConfig,
  FeatureConfigTypeEnum,
  NavigationItem,
  QuickLink,
} from '@juno/client-api/model';
import { FeatureConfig as FeatureConfigUtils } from '@juno/client-api/utils';
import { NAV_ITEMS } from '@juno/constants';
import { Container, DialogAriaWrapper } from '@juno/ui';
import { MutationAction, onMutation, snackOptions, useSettings } from '@juno/utils';
import CustomizeSidebar from './Customize';
import HeaderLinks from './HeaderLinks';
import QuickLinks from './QuickLinks';

export interface ExtendedConfigModel extends FeatureConfigConfig {
  nav_hidden_icons?: string[];
}

interface InitialValuesModel {
  hide_messenger: boolean;
  nav_items: NavigationItem[];
}

interface ExtendedFeatureConfigModel extends FeatureConfig {
  config: ExtendedConfigModel;
}

interface ExtendedThemeConfigModel extends FeatureConfigConfig {
  hide_messenger: boolean;
}

interface ExtendedThemeConfig extends FeatureConfig {
  config: ExtendedThemeConfigModel;
}

const NavigationAdmin: React.FC = () => {
  const queryClient = useQueryClient();
  const { site, quickLinks, navItems, configs, sidebarWidgets } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  // top form is dirty
  const [pageDirty, setPageDirty] = useState(false);
  // bottom form is dirty
  const [pageDirty2, setPageDirty2] = useState(false);
  const [pageDirty3, setPageDirty3] = useState(false);

  const createUpdateQuickLinks = useCreateUpdateQuickLinks(
    onMutation(MutationAction.UPDATE, '', () => {}),
  );
  const createUpdateNavItems = useCreateUpdateNavigationItems(
    onMutation(MutationAction.UPDATE, '', () => {}),
  );
  const updateSidebarWidgets = useUpdateSidebarWidgets(
    onMutation(MutationAction.UPDATE, '', () => {}),
  );

  const configUpdate = useUpdateSiteFeatureConfig(onMutation(MutationAction.UPDATE, '', () => {}));
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      (pageDirty || pageDirty2 || pageDirty3) && currentLocation.pathname !== nextLocation.pathname,
  );

  const themeConfig = FeatureConfigUtils.getThemeConfig(configs) as ExtendedThemeConfig | undefined;

  const initialValues: InitialValuesModel = {
    nav_items: navItems || [],
    hide_messenger: themeConfig?.config?.hide_messenger || false,
  };

  return (
    <Container>
      <Formik
        initialValues={initialValues || { nav_items: [], hide_messenger: false }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const newValues = values.nav_items.map(
            ({ internal_link_object, ...keepAttrs }) => keepAttrs,
          );

          const updateNav = createUpdateNavItems.mutateAsync({
            siteId: site?.id || '',
            data: newValues,
          });

          const updateTheme = configUpdate.mutateAsync({
            siteId: site?.id || '',
            configType: FeatureConfigTypeEnum.theme,
            data: {
              id: themeConfig?.id || '',
              type: FeatureConfigTypeEnum.theme,
              config: {
                ...themeConfig?.config,
                hide_messenger: values.hide_messenger,
              },
            },
          });

          await Promise.all([updateNav, updateTheme]);

          enqueueSnackbar('Your changes were saved.', snackOptions('success'));
          await queryClient.invalidateQueries(getGetNavigationItemsQueryKey(site?.id || ''));
          await queryClient.invalidateQueries(getGetFeatureConfigsQueryKey(site?.id || ''));
          await queryClient.invalidateQueries(getGetFeatureConfigsPublicQueryKey(site?.id || ''));
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {(formik) => {
          return <HeaderLinks formik={formik} setPageDirty={setPageDirty} />;
        }}
      </Formik>

      <Formik
        initialValues={quickLinks || ([] as QuickLink[])}
        onSubmit={async (values, { setSubmitting }) => {
          const newValues = values.map(({ internal_link_object, ...keepAttrs }) => keepAttrs);

          await createUpdateQuickLinks.mutateAsync({
            siteId: site?.id || '',
            data: newValues,
          });
          // await
          enqueueSnackbar('Your changes were saved.', snackOptions('success'));
          await queryClient.invalidateQueries(getGetQuickLinksQueryKey(site?.id || ''));
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {(formik) => <QuickLinks formik={formik} setPageDirty2={setPageDirty2} />}
      </Formik>

      <Formik
        initialValues={sidebarWidgets || []}
        onSubmit={async (values, { setSubmitting }) => {
          await updateSidebarWidgets.mutateAsync({
            siteId: site?.id || '',
            data: values,
          });
          // await
          enqueueSnackbar('Your changes were saved.', snackOptions('success'));
          await queryClient.invalidateQueries(getGetSidebarWidgetsQueryKey(site?.id || ''));
          setSubmitting(false);
        }}
        enableReinitialize
      >
        {(formik) => <CustomizeSidebar formik={formik} setPageDirty3={setPageDirty3} />}
      </Formik>

      <DialogAriaWrapper open={blocker.state === 'blocked'}>
        <Box ml={2} mb={2} mt={2}>
          <Typography variant='h6'>Are you sure?</Typography>
        </Box>
        <DialogContent>
          <Typography>You have unsaved changes that will be lost if you proceed.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => blocker.reset && blocker.reset()}>Cancel</Button>
          <Button onClick={() => blocker.proceed && blocker.proceed()} variant='contained'>
            Proceed
          </Button>
        </DialogActions>
      </DialogAriaWrapper>
    </Container>
  );
};

export default NavigationAdmin;

interface NavIconsProps {
  formik: FormikProps<ExtendedFeatureConfigModel>;
  setPageDirty: Dispatch<SetStateAction<boolean>>;
}

const NavIcons: React.FC<NavIconsProps> = ({ formik, setPageDirty }) => {
  const { isSubmitting, dirty, setValues, values } = formik;

  useEffect(() => {
    setPageDirty(dirty);
  }, [dirty]);

  const handleChange = (title: string) => {
    if (!values?.config?.nav_hidden_icons) {
      setValues({ ...values, config: { ...values.config, nav_hidden_icons: [title] } });
      return;
    }

    if (values?.config?.nav_hidden_icons?.includes(title)) {
      setValues({
        ...values,
        config: {
          ...values.config,
          nav_hidden_icons: values.config.nav_hidden_icons.filter((item: string) => item !== title),
        },
      });
    } else {
      setValues({
        ...values,
        config: {
          ...values.config,
          nav_hidden_icons: [...values.config.nav_hidden_icons, title],
        },
      });
    }
  };
  return (
    <Form>
      <Card sx={{ p: 2, boxShadow: 1 }}>
        <Typography variant='overline'>Header Nav</Typography>
        <Typography variant='body2' sx={{ mt: 2 }}>
          Click on the icons to show or hide them in the header navigation. If you hide the icons,
          the pages will still exist if you want to link to them in other ways.
        </Typography>
        <Stack direction='row' mt={4}>
          {NAV_ITEMS.map((item, index) => (
            <Button
              onClick={() => {
                handleChange(item.title);
              }}
              sx={{
                pointerEvents: isSubmitting ? 'none' : 'auto',
                width: 90,
                mr: 2,
                boxShadow: (theme) =>
                  values.config.nav_hidden_icons?.includes(item.title) ? 'none' : theme.shadows[5],
                opacity: values.config.nav_hidden_icons?.includes(item.title) ? 0.4 : 1,
              }}
              key={index}
            >
              <Stack alignItems='center'>
                {item.icon}
                <Typography color={'#000'} variant='body2'>
                  {item.title}
                </Typography>
              </Stack>
            </Button>
          ))}
        </Stack>
        <Box textAlign='right'>
          <LoadingButton
            loading={isSubmitting}
            variant='contained'
            type='submit'
            disabled={!dirty}
            sx={{ mt: 3 }}
          >
            Save
          </LoadingButton>
        </Box>
      </Card>
    </Form>
  );
};
