import * as React from 'react';
import { useEffect, useMemo } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Avatar, Badge, Button, Stack, Typography } from '@mui/material';
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import { styled, useTheme } from '@mui/material/styles';
import { GridCloseIcon } from '@mui/x-data-grid';
import { useDebounce } from 'usehooks-ts';
import { useGetAllUsers } from '@juno/client-api';
import { JunoUser } from '@juno/client-api/model';
import { calculateUserSearchFilters } from '@juno/utils';
import { customShadow } from '../../index';

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'}`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: '100%',
  borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
  '& input': {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
    padding: 8,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    fontSize: 14,
    '&:focus': {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : 'rgb(12, 45, 107)'
      }`,
      borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd',
    },
  },
}));

const CustomUserBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '.5em 4px .5em 8px',
  fontWeight: 600,
  lineHeight: '15px',
  borderRadius: '6px',
  color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
  cursor: 'pointer',
  '&:hover,&:focus': {
    color:
      theme.palette.mode === 'light' ? theme.palette.primary.light : theme.palette.primary.dark,
  },
}));

interface AutocompleteJunoUsersProps {
  label?: string | React.ReactNode;
  title?: string;
  placeholder?: string;
  currentUsers: JunoUser[];
  platformId: string;
  handleChange: (newUsers: JunoUser[]) => void;
  getImageSrc: (icon: string) => string;
  accessPassIdsForUserBadge?: string[];
  disabled?: boolean;
}

const AutocompleteJunoUsers: React.FC<AutocompleteJunoUsersProps> = ({
  label,
  title,
  placeholder,
  currentUsers,
  platformId,
  handleChange,
  getImageSrc,
  accessPassIdsForUserBadge,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [value, setValue] = React.useState<JunoUser[]>(currentUsers || []);
  const [pendingValue, setPendingValue] = React.useState<JunoUser[]>([]);
  const theme = useTheme();

  const [inputValue, setInputValue] = React.useState<string>('');
  const [userSearchInput, setUserSearchInput] = React.useState<string>('');
  const debouncedSearchValue = useDebounce(userSearchInput, 200);

  const searchFilter = React.useMemo(() => {
    if (!debouncedSearchValue) return {};
    return calculateUserSearchFilters(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const { data: allUsers, isLoading } = useGetAllUsers(platformId, {
    ...searchFilter,
    order: 'last_name',
    limit: 10,
    offset: 0,
  });

  const handleInputChange = (e: React.SyntheticEvent, newInputValue: string, reason: string) => {
    if (['change'].includes(e?.type) || ['clear'].includes(reason)) {
      setInputValue(newInputValue);
      setUserSearchInput(newInputValue);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled) return;
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setValue(pendingValue);
    handleChange(pendingValue);
    setInputValue('');
    setUserSearchInput('');
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const sortedUsers = useMemo(() => {
    if (!allUsers) return [];
    return [...allUsers].sort((a, b) => {
      let ai = value.indexOf(a);
      ai = ai === -1 ? value.length + allUsers.indexOf(a) : ai;
      let bi = value.indexOf(b);
      bi = bi === -1 ? value.length + allUsers.indexOf(b) : bi;
      return ai - bi;
    });
  }, [allUsers]);

  useEffect(() => {
    setValue(currentUsers);
  }, [currentUsers]);

  const open = Boolean(anchorEl);
  const id = open ? 'autocomplete-juno-users' : undefined;

  return (
    <>
      <Box p={1}>
        <Button
          fullWidth
          aria-describedby={id}
          onClick={handleClick}
          size='large'
          sx={{ boxShadow: customShadow }}
        >
          {label || 'Users'}
        </Button>
        {value.length > 0 && (
          <Stack
            mt={2}
            spacing={1}
            sx={{
              maxHeight: 200,
              overflow: 'auto',
            }}
          >
            {value
              .sort((a, b) => a.first_name.localeCompare(b.first_name))
              .map((user, index) => (
                <CustomUserBox
                  key={user.id}
                  sx={{
                    backgroundColor: index % 2 === 0 ? 'inherit' : theme.palette.background.paper,
                  }}
                >
                  {/* if the user.access_passes contains all ids in accessPassIdsForUserBadge  */}
                  {accessPassIdsForUserBadge &&
                  accessPassIdsForUserBadge.length > 0 &&
                  accessPassIdsForUserBadge.every(
                    (passId) => user.access_passes.filter((ap) => ap.id === passId).length > 0,
                  ) ? (
                    <Badge
                      badgeContent={''}
                      color='primary'
                      variant='dot'
                      overlap='circular'
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <Avatar
                        src={getImageSrc(user.icon ?? user.avatar ?? '')}
                        alt={`${user.first_name} ${user.last_name}`}
                        sx={{
                          width: 24,
                          height: 24,
                          fontSize: 10,
                        }}
                      />
                    </Badge>
                  ) : (
                    <Avatar
                      src={getImageSrc(user.icon ?? user.avatar ?? '')}
                      alt={`${user.first_name} ${user.last_name}`}
                      sx={{
                        width: 24,
                        height: 24,
                        fontSize: 10,
                      }}
                    />
                  )}
                  <Typography ml={1}>{`${user.first_name} ${user.last_name}`}</Typography>
                  <GridCloseIcon
                    fontSize='small'
                    sx={{ ml: 'auto', cursor: 'pointer' }}
                    onClick={(e: any) => {
                      const newValue = value.filter((u) => u.id !== user.id);
                      setValue(newValue);
                      handleChange(newValue);
                    }}
                  />
                </CustomUserBox>
              ))}
          </Stack>
        )}
      </Box>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
        placeholder={''}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Box
              sx={{
                borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
                padding: '8px 10px',
                fontWeight: 600,
              }}
            >
              {title || 'Users'}
            </Box>
            <Autocomplete
              clearOnBlur={false}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              filterOptions={(x) => x}
              onInputChange={handleInputChange}
              loading={isLoading}
              open
              multiple
              disableClearable={false}
              onClose={(
                event: React.SyntheticEvent<Element, Event>,
                reason: AutocompleteCloseReason,
              ) => {
                if (reason === 'escape') {
                  handleClose();
                }
              }}
              inputValue={inputValue}
              value={pendingValue}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === 'keydown' &&
                  (event as React.KeyboardEvent).key === 'Backspace' &&
                  reason === 'removeOption'
                ) {
                  return;
                }
                setPendingValue(newValue);
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText='No users found'
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Box
                    component={DoneIcon}
                    sx={{ width: 17, height: 17, mr: '5px', ml: '-2px' }}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                  <Box
                    component='span'
                    sx={{
                      width: 14,
                      height: 14,
                      flexShrink: 0,
                      borderRadius: '3px',
                      mr: 1,
                      mt: '2px',
                    }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                      '& span': {
                        color: theme.palette.mode === 'light' ? '#586069' : '#8b949e',
                      },
                    }}
                  >
                    {`${option.first_name} ${option.last_name}`}
                    <br />
                    <span>{option.email}</span>
                  </Box>
                  <Box
                    component={CloseIcon}
                    sx={{ opacity: 0.6, width: 18, height: 18 }}
                    style={{
                      visibility: selected ? 'visible' : 'hidden',
                    }}
                  />
                </li>
              )}
              options={sortedUsers || []}
              getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={placeholder || 'Filter users'}
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export default AutocompleteJunoUsers;
