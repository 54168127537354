import { Avatar, Box, Card, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ThreadPostCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isReply',
})<{
  isReply: boolean;
}>(({ isReply, theme }) => ({
  marginTop: theme.spacing(2),
  marginRight: 'auto',
  marginLeft: 'auto',
  // TODO - backgroundColor: theme.palette.background.paper,
  border: isReply ? 'none' : 'default',
}));

export const FlexContainerCenter = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const ThreadPostAuthorAvatar = styled(Avatar)(({ theme }) => ({
  width: 32,
  height: 32,
  marginRight: theme.spacing(1),
}));

export const ThreadPostContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  // marginBottom: theme.spacing(1),
}));

export const TimeSinceCreatedText = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary,
  whiteSpace: 'pre-wrap',
}));

export const ThreadPostSkeleton = styled(Skeleton)(({ theme }) => ({
  width: 'inherit',
  height: 100,
  marginTop: theme.spacing(2),
  marginRight: 'auto',
  marginLeft: 'auto',
}));
