import { CommunityUser } from '@juno/client-api/model';

export const createTinyMceMentionSpan = (editor: any, user: CommunityUser): HTMLSpanElement => {
  // unfortunately raw html is the only way that you can add a mention to the editor
  const span = editor.getDoc().createElement('span');
  span.className = 'user_mention';
  span.setAttribute('aria-label', 'View user details');
  span.setAttribute('data-mention-id', user.id);
  span.setAttribute('data-mention-email', user.email);
  span.appendChild(
    editor
      .getDoc()
      .createTextNode(
        user.first_name && user.last_name ? `@${user.first_name} ${user.last_name}` : user.email,
      ),
  );
  return span;
};

export const convertTinyMceUsers = (users: CommunityUser[], imageSrcFn: any): CommunityUser[] => {
  return users.map((u) => ({
    id: u.id,
    first_name: u.first_name,
    last_name: u.last_name,
    name: u.first_name && u.last_name ? `${u.first_name} ${u.last_name}` : u.email,
    email: u.email,
    image: u.avatar || u.icon || 'UserIcon_CrowdHub.jpg',
  }));
};
