import { useEffect, useMemo, useRef, useState } from 'react';
import { SubdirectoryArrowLeft } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { Thread } from '@juno/client-api/model';
import ThreadPost from '..';

interface ThreadPostRepliesProps {
  siteId: string;
  platformId: string;
  threads: Thread[];
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  onNavigate: ((props: any) => void) | undefined;
  refetchParentThread: () => void;
  handleCommentClick: (thread: Thread) => void;
  userCanInteract?: boolean;
  dialogRef?: React.RefObject<HTMLDivElement>;
}

const ThreadPostComments: React.FC<ThreadPostRepliesProps> = ({
  siteId,
  platformId,
  setData,
  threads,
  onNavigate,
  refetchParentThread,
  handleCommentClick,
  dialogRef,
  userCanInteract = true,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showAll, setShowAll] = useState(false);
  const numThreadsToShow = 4;

  const displayedThreads = useMemo(() => {
    const sorted = threads.sort((a, b) => {
      return new Date(a?.date_created ?? '').getTime() - new Date(b?.date_created ?? '').getTime();
    });
    if (sorted.length > numThreadsToShow && !showAll) {
      return sorted.slice(threads.length - numThreadsToShow, threads.length);
    }
    return sorted;
  }, [threads, numThreadsToShow, showAll]);

  useEffect(() => {
    if (containerRef.current && !showAll) {
      containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [displayedThreads]);

  return (
    // TODO this ref for scroll to bottom can get funkyish
    <Box ref={containerRef} pb={2}>
      {threads.length > numThreadsToShow && (
        <Box sx={{ pt: 2 }}>
          <Button
            color='primary'
            size='small'
            startIcon={
              showAll ? undefined : <SubdirectoryArrowLeft sx={{ transform: 'rotate(90deg)' }} />
            }
            sx={{ textTransform: 'none', mb: -1 }}
            onClick={() => setShowAll((old) => !old)}
            aria-label={showAll ? 'Collapse older comments' : 'View older comments'}
          >
            {showAll ? 'Collapse older comments' : 'View older comments'}
          </Button>
        </Box>
      )}
      {displayedThreads.map((comment) => {
        return (
          <div key={comment.id}>
            <ThreadPost
              userCanInteract={userCanInteract}
              setData={setData}
              platformId={platformId}
              thread={comment}
              key={`reply-${comment.id}`}
              siteId={siteId}
              onNavigate={onNavigate}
              refetchParentThread={refetchParentThread}
              handleOpenPostDetails={(reply) => {
                handleCommentClick({
                  ...reply,
                  id: comment.id,
                });
              }}
              dialogRef={dialogRef}
            />
          </div>
        );
      })}
    </Box>
  );
};

export default ThreadPostComments;
