import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Card,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Editor } from '@tinymce/tinymce-react';
import { FormikProps } from 'formik';
import { useGetAccessPasses } from '@juno/client-api';
import { JunoImage } from '@juno/client-api/fakeModel';
import { AccessPass, Product, RoomTypeEnum, SessionMedia } from '@juno/client-api/model';
import { TINY_MCE_DEFAULT_CONFIG } from '@juno/constants';
import {
  DateTimePicker,
  DialogAriaWrapper,
  GenericFormikDateSection,
  GenericFormikInput,
  JunoImageUpload,
} from '@juno/ui';
import { AutocompleteAccessPasses } from '@juno/ui';
import { optimizeImage, uploadTinyMceImageCloudinary, useSettings } from '@juno/utils';
import GreenRoomSection from '../GreenRoomSection';
import IncludeSection from '../IncludeSection';
import MediaSection from '../MediaSection';
import ModerationSection from '../ModerationSection';

// import EducationCreditSection from '../../../../../learning/src/lib/admin/CoursePanel/CourseInfo/helpers/EducationCreditSection';
interface SessionInfoProps {
  setDeleteDialogOpen: (open: boolean) => void;
  formikProps: FormikProps<any>;
}
interface AccessPassForm extends AccessPass {
  products: Product[];
}

const SessionInfo: React.FC<SessionInfoProps> = ({ setDeleteDialogOpen, formikProps }) => {
  const { site } = useSettings();
  const { values, handleChange, setFieldValue, setFieldTouched } = formikProps;
  const { data: accessPasses, isLoading: isAccessPassesLoading } = useGetAccessPasses(
    site?.id || '',
    {
      include: 'products',
    },
  );
  const [accessPassDialogOpen, setAccessPassDialogOpen] = useState(false);

  // only allow one access pass with a product attached
  const handleAccessPassChange = (event: any, accessPasses: AccessPassForm[]) => {
    const withProducts =
      accessPasses?.filter((ap) => ap.products?.length > 0 || ap.upgrade_enrollment_url) || [];
    if (withProducts.length > 1) {
      setAccessPassDialogOpen(true);
    } else {
      setFieldValue('restrict_schedule_access_passes', accessPasses);
    }
  };

  return (
    <Box>
      <Grid container spacing={2} flex={3} sx={{ padding: 0 }}>
        <Grid item xs={8} sx={{ paddingLeft: 0 }}>
          <Card sx={{ mt: 2, p: 2, boxShadow: 1 }}>
            {/* Basic Info */}
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box display={'flex'} justifyContent={'space-between'}>
                  <Typography variant='overline' mb={1}>
                    Basic Information
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.active}
                        name={'active'}
                        onChange={(e) => {
                          setFieldValue('active', e.target.checked);
                        }}
                      />
                    }
                    label='Session is active'
                  />
                </Box>

                <Box justifyContent={'flex-end'} display={'flex'}>
                  <Typography variant='caption' sx={{ mt: 1, mb: 1, mr: 2 }}>
                    Session ID: {values.room_url}
                  </Typography>
                  <Button
                    onClick={() => setDeleteDialogOpen(true)}
                    sx={{ marginLeft: 'auto', textTransform: 'none' }}
                    size='small'
                  >
                    <DeleteIcon sx={{ mr: 1, fontSize: 18 }} /> Delete Session
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <GenericFormikInput
                  label='Title'
                  name='title'
                  type='text'
                  placeholder='Session Title'
                  value={values.title}
                  sx={{ mb: 0 }}
                  size='small'
                />
              </Grid>
              <Grid item xs={6}>
                <GenericFormikInput
                  label='Slug'
                  name='slug'
                  type='text'
                  placeholder='session_slug'
                  value={values.slug}
                  sx={{ mb: 0 }}
                  size='small'
                />
              </Grid>
              <Grid item xs={12}>
                <GenericFormikInput
                  label='Preview Text'
                  name='preview_text'
                  type='text'
                  placeholder='Preview text'
                  value={values.preview_text}
                  sx={{ mb: 0 }}
                  size='small'
                />
              </Grid>
            </Grid>
          </Card>
          {/* Description */}
          <Card sx={{ mt: 2, p: 2, boxShadow: 1 }}>
            <Typography variant='overline' sx={{ mb: 2 }}>
              Description
            </Typography>
            <Typography variant='body2' sx={{ mb: 5 }}>
              This shows on the session homepage - users browsing sessions will see this when they
              select the session.
            </Typography>
            <Editor
              apiKey={process.env.NX_TINY_MCE_API_KEY}
              onEditorChange={(value: string) =>
                handleChange({ target: { name: 'description', value } })
              }
              value={values.description || ''}
              init={{
                ...TINY_MCE_DEFAULT_CONFIG,
                images_upload_handler: uploadTinyMceImageCloudinary,
                setup: (editor) => {
                  editor.setProgressState(true);
                },
              }}
            />
          </Card>

          <Card></Card>
        </Grid>
        {/* RIGHT SIDE */}
        <Grid item xs={4}>
          {/* ACCESS */}

          {/* <AccessSection
                    dirty={dirty}
                    siteId={siteId}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    {...values}
                  /> */}
          {/* // TODO - education credits need disconnected from courses */}
          <Box mt={2}>
            {/* <Tooltip title='Attach education credits to this session' placement='top-start'>
                      <Typography variant={'subtitle2'} sx={{ mt: 2 }}>
                        Education Credit(s)
                      </Typography>
                    </Tooltip> */}
            {/* <EducationCreditSection
                      siteId={siteId}
                      course={
                        {
                          id: '',
                          title: values.title,
                          date_released: values.date_released,
                        } as Course
                      }
                      educationCredits={values.education_credits}
                      
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                      refetchEducationCredits={function (): void {
                        console.log('Function not implemented.');
                      }}
                      isFetContext={false}
                    /> */}
          </Box>

          {/* INCLUDE */}
          <Card sx={{ p: 2, mt: 2, boxShadow: 1 }}>
            <Tooltip
              title='Choose optional features to include in this session'
              placement='top-start'
            >
              <Typography variant={'overline'} sx={{ mb: 1 }}>
                Interaction Options
              </Typography>
            </Tooltip>
            <IncludeSection handleChange={handleChange} {...values} />
            <Box mt={2} />
            <AutocompleteAccessPasses
              multiple={true}
              label='Access Pass'
              fieldValue='restrict_schedule_access_passes'
              passArray={values.restrict_schedule_access_passes}
              accessPasses={accessPasses?.filter(
                (ap) =>
                  !values.restrict_schedule_access_passes.find((f: AccessPass) => f.id === ap.id),
              )}
              handleChange={handleAccessPassChange}
            />
          </Card>

          <Card sx={{ mt: 2, p: 2, boxShadow: 1 }}>
            <Grid item xs={12}>
              <Typography variant={'overline'} sx={{ mb: 2 }}>
                Scheduling
              </Typography>
              <DateTimePicker
                reoccurrence={values.reoccurrence}
                onChange={(field, date): void => {
                  handleChange({ target: { name: field, value: date } });
                  setFieldValue(field, date);
                  setFieldTouched(field, true);
                }}
                selectedStartDate={values.date_start ? new Date(values.date_start) : new Date()}
                selectedEndDate={values.date_end ? new Date(values.date_end) : new Date()}
                selectedEndTime={values.time_end ? new Date(values.time_end) : new Date()}
                fullWidth={true}
              />
            </Grid>
          </Card>
          {/* Activation, Type and Location */}
          <Card sx={{ mt: 2, p: 2, boxShadow: 1 }}>
            <Typography variant='overline' sx={{ mb: 2 }}>
              Activation, Type and Location
            </Typography>
            <Typography variant='body2' sx={{ mb: 2 }}>
              This is where you can choose a room type: mainstage or breakout. Set a display label
              to override the default label for the room type. You can also set the location of the
              session.
            </Typography>
            <Stack direction={'row'} spacing={2}>
              <Box>
                <Typography variant='subtitle1'>Room Type</Typography>
                <Select
                  value={values.room_type}
                  // sx={{ height: 40 }}
                  name={'room_type'}
                  onChange={handleChange}
                  size='small'
                >
                  <MenuItem value={RoomTypeEnum.NUMBER_1}>Mainstage</MenuItem>
                  <MenuItem value={RoomTypeEnum.NUMBER_2}>Breakout</MenuItem>
                </Select>
              </Box>
              <Box>
                <Typography variant='subtitle1'>Display Label</Typography>
                <GenericFormikInput
                  size='small'
                  name='chip_label'
                  type='text'
                  placeholder={
                    values.room_type === RoomTypeEnum.NUMBER_1 ? 'Mainstage' : 'Breakout'
                  }
                  value={values.chip_label}
                />
              </Box>
            </Stack>

            <Box>
              <Typography variant='subtitle1'>Location</Typography>
              <FormControlLabel
                sx={{ display: 'block' }}
                control={
                  <Checkbox
                    checked={values.inperson}
                    name={'inperson'}
                    onChange={(e) => {
                      setFieldValue('inperson', e.target.checked);
                    }}
                  />
                }
                label='In-Person only'
              />
              <GenericFormikInput
                size='small'
                name='location'
                type='text'
                //   sx={{ height: 40 }}
                placeholder='Enter a location'
                value={values.location}
                sx={{ mb: 0 }}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Card sx={{ p: 2, mt: 2, boxShadow: 1 }}>
        <Typography variant='overline' sx={{ mb: 2 }}>
          Banner and Icon Images
        </Typography>
        <Grid container spacing={3} sx={{ mb: 1 }}>
          <Grid item xs={6}>
            <Typography sx={{ mb: 1 }} variant='subtitle1'>
              Preview
            </Typography>
            <JunoImageUpload
              style={{ aspectRatio: '16/9' }}
              src={optimizeImage(270, values.icon)}
              srcUrl={values.icon}
              // disabled={isDisabled}
              onFileUploaded={(selected: JunoImage | null) =>
                setFieldValue('icon', selected?.url || '')
              }
            />
            <Typography variant='body2' mt={1}>
              Displays on Grids, Rotators and on the Side Navigation Bar.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ mb: 1 }} variant='subtitle1'>
              Banner
            </Typography>
            <JunoImageUpload
              style={{ aspectRatio: '16/9' }}
              src={optimizeImage(270, values.banner)}
              srcUrl={values.icon}
              // disabled={isDisabled}
              onFileUploaded={(selected: JunoImage | null) =>
                setFieldValue('banner', selected?.url || '')
              }
            />
            <Typography variant='body2' mt={1}>
              Displays across the top of the Landing page.
            </Typography>
          </Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Card>
      <Grid container spacing={2} flex={1} sx={{ mt: 1 }}>
        {/* <Grid item xs={12}>
            <Card sx={{ p: 2, boxShadow: 1 }}>

            <Grid item xs={12} mt={3}>
                  <Typography variant='subtitle1'>Images</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant='caption'>Icon</Typography>
                      <Box>
                        <JunoImageUpload
                          style={{ aspectRatio: '16/9' }}
                          src={optimizeImage(270, values.icon)}
                          srcUrl={values.icon}
                          // disabled={isDisabled}
                          onFileUploaded={(selected: JunoImage | null) =>
                            setFieldValue('icon', selected?.url || '')
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='caption'>Banner</Typography>
                      <Box>
                        <JunoImageUpload
                          style={{ aspectRatio: '16/9' }}
                          src={optimizeImage(270, values.banner)}
                          srcUrl={values.banner}
                          // disabled={isDisabled}
                          onFileUploaded={(selected: JunoImage | null) =>
                            setFieldValue('banner', selected?.url || '')
                          }
                          // aspectRatio={ASPECT_RATIOS.TWENTY_FIVE_EIGHT}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
            </Card>
                </Grid> */}
        <Grid item xs={12}>
          {/*  MODERATION */}
          <ModerationSection
            {...values}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            getImageSrc={(url) => {
              // TODO getImageSrc in VNEXT
              // console.warn('TODO getImageSrc in VNEXT', url);
              return url;
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={2}>
            {/* <Typography variant='h6'>Advanced</Typography> */}
            {/* GREENROOM */}
            <GreenRoomSection
              {...values}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
            {/*  MEDIA */}
            <MediaSection
              setFieldValue={setFieldValue}
              media={values.media || ([] as SessionMedia[])}
              handleChange={handleChange}
            />

            {/*  RECORDING */}
            {/* <RecordingSection
                      {...values}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    /> */}
          </Box>
        </Grid>
      </Grid>
      <DialogAriaWrapper
        id={'limit_products_attached'}
        open={accessPassDialogOpen}
        onClose={() => {
          setAccessPassDialogOpen(false);
        }}
      >
        <DialogTitle>Cannot Add Access Pass</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You may only attach one access pass with a product OR an upgrade url to a course.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAccessPassDialogOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </DialogAriaWrapper>
    </Box>
  );
};
export default SessionInfo;
