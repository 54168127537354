import React, { useEffect, useState } from 'react';
import { Download as DownloadIcon } from '@mui/icons-material';
import { Box, Button, Divider, Grid, Skeleton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useCSVDownloader } from 'react-papaparse';
import { Link } from 'react-router-dom';
import { useGetEmailCampaigns, useGetPlatform } from '@juno/client-api';
import {
  EmailCampaign as EmailCampaignModel,
  FrequencyEnum as frequencyOptions,
} from '@juno/client-api/model';
import { getCampaignListCsvData, useSettings } from '@juno/utils';
import NewEmailCampaignDialog from '../Dialogs/NewEmailCampaignDialog';
import EmailAnalytics from '../Settings/EmailAnalytics';
import SubscriptionSettings from '../Settings/SubscriptionSettings';
import { Search } from '../utils/Search';
import { campaignListTabProps } from '../utils/utils';
import EmailCampaignListItem from './EmailCampaignListItem';

const EmailCampaignList: React.FC = () => {
  // Variables
  const { site } = useSettings();
  const [campaigns, setCampaigns] = useState<EmailCampaignModel[]>([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState<EmailCampaignModel[]>([]);
  const [tab, setTab] = useState<number>(0);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const { CSVDownloader } = useCSVDownloader();

  // Queries and mutations
  const { data: emailCampaigns, isLoading: campaignsLoading } = useGetEmailCampaigns(
    site?.id || '',
    { query: { enabled: !!site?.id } },
  );
  const { data: platform } = useGetPlatform(
    site?.platform_id || '',
    {},
    { query: { enabled: !!site?.platform_id } },
  );

  // Functions
  useEffect(() => {
    if (emailCampaigns) {
      setCampaigns(emailCampaigns);
      setFilteredCampaigns(emailCampaigns);
    }
  }, [emailCampaigns]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const searchFilteredCampaigns: EmailCampaignModel[] =
    Array.isArray(filteredCampaigns) && filteredCampaigns.length > 0
      ? filteredCampaigns?.filter((emailCampaign) => {
          return emailCampaign?.name?.toLowerCase().includes(search?.toLowerCase());
        })
      : [];

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  useEffect(() => {
    const isCompleted = (emailCampaign: EmailCampaignModel) => {
      if (!emailCampaign.schedule) {
        return false;
      }
      if (
        emailCampaign.is_active &&
        emailCampaign.schedule.start_date &&
        !emailCampaign.schedule.end_date
      ) {
        const now = new Date();
        return (
          emailCampaign.schedule.frequency === frequencyOptions.ONE_TIME &&
          new Date(emailCampaign.schedule.start_date) < now
        );
      }
      if (!emailCampaign.schedule.end_date) {
        return false;
      }
      const campaignEndDate = new Date(emailCampaign.schedule.end_date);
      return campaignEndDate < new Date();
    };

    switch (tab) {
      // ALL campaigns
      case 0:
        setFilteredCampaigns(campaigns);
        break;
      // Active campaigns
      case 1:
        setFilteredCampaigns(
          campaigns.filter((campaign) => campaign.is_active && !isCompleted(campaign)),
        );
        break;
      // Draft campaigns
      case 2:
        setFilteredCampaigns(campaigns.filter((campaign) => !campaign.is_active));
        break;
      // Completed campaigns
      case 3:
        setFilteredCampaigns(
          campaigns.filter((campaign) => isCompleted(campaign) && campaign.is_active),
        );
        break;
      default:
        setFilteredCampaigns(campaigns);
    }
  }, [campaigns, tab]);

  return (
    <Box sx={{ padding: '20px' }}>
      <Stack
        direction={'row'}
        justifyContent='space-between'
        alignItems='center'
        sx={{ mb: 4, display: 'flex' }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} aria-label='campaign-status-tabs' onChange={handleTabChange}>
            <Tab label='all' {...campaignListTabProps(0)} />
            <Tab label='active' {...campaignListTabProps(1)} />
            <Tab label='draft' {...campaignListTabProps(2)} />
            <Tab
              label='completed'
              {...campaignListTabProps(3)}
              icon={
                <Divider
                  orientation='vertical'
                  style={{ height: 25, position: 'absolute', right: 0 }}
                  sx={{ backgroundColor: '#555', mt: 1 }}
                />
              }
            />
            <Tab
              label='subscription groups'
              {...campaignListTabProps(3)}
              icon={
                <Divider
                  orientation='vertical'
                  style={{ height: 25, position: 'absolute', right: 0 }}
                  sx={{ backgroundColor: '#555', mt: 1 }}
                />
              }
            />
            <Tab label={'email analytics'} />
          </Tabs>
        </Box>
      </Stack>
      {tab !== 4 && tab !== 5 && (
        <>
          <Search handleChange={handleSearchChange} />
          <Stack
            direction={'row'}
            justifyContent='space-between'
            alignItems='center'
            sx={{ mb: 2 }}
          >
            <Typography sx={{ color: 'text.primary', pl: '20px' }}>
              {searchFilteredCampaigns.length} Campaign(s) Found
            </Typography>
            {site && searchFilteredCampaigns.length > 0 && (
              <CSVDownloader
                filename={`${site?.slug}_CampaignDetails`}
                data={getCampaignListCsvData(searchFilteredCampaigns, platform?.name || '', site)}
              >
                <Typography
                  sx={{
                    color: 'text.primary',
                    pr: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  Download List <DownloadIcon sx={{ ml: 2 }} />
                </Typography>
              </CSVDownloader>
            )}
          </Stack>
          <Button
            aria-label='Create-new-campaign'
            variant='contained'
            fullWidth
            sx={{ mb: 2, p: 1.5, borderRadius: '10px' }}
            disableElevation
            onClick={() => setDialogOpen(true)}
          >
            + New Campaign
          </Button>
          {campaignsLoading && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Skeleton
                  variant='rectangular'
                  height={80}
                  width={'100%'}
                  sx={{ borderRadius: '10px' }}
                ></Skeleton>
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  variant='rectangular'
                  height={80}
                  width={'100%'}
                  sx={{ borderRadius: '10px' }}
                ></Skeleton>
              </Grid>
              <Grid item xs={12}>
                <Skeleton
                  variant='rectangular'
                  height={80}
                  width={'100%'}
                  sx={{ borderRadius: '10px' }}
                ></Skeleton>
              </Grid>
            </Grid>
          )}
          {!campaignsLoading &&
            searchFilteredCampaigns.map((emailCampaign, index) => (
              <Link to={`${emailCampaign.id || '/404'}`} key={index}>
                <Box>
                  <EmailCampaignListItem emailCampaign={emailCampaign} />
                </Box>
              </Link>
            ))}
        </>
      )}
      {tab === 4 && <SubscriptionSettings />}
      {tab === 5 && <EmailAnalytics />}
      {dialogOpen && (
        <NewEmailCampaignDialog
          open={true}
          handleClose={() => setDialogOpen(false)}
          campaigns={campaigns}
        />
      )}
    </Box>
  );
};

export default EmailCampaignList;
