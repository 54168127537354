import React, { useEffect, useRef, useState } from 'react';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, IconButton, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

interface AdminEditPanelHeaderProps {
  shouldShow: boolean;
  title: string;
  onClickDiscard: () => void;
  onClickSave: () => void;
  onClickGoBack: () => void;
  isSubmitting?: boolean;
  bannerImageUrl?: string;
}

/**
 * Use for all vnext edit panels to maintain consistency
 * @param shouldShow boolean
 * @param title string
 * @param onClickDiscard () => void
 * @param onClickSave () => void
 * @param onClickGoBack () => void
 * @param isSubmitting boolean
 * @param bannerImageUrl string
 * @example
 * <AdminEditPanelHeader
 * shouldShow={shouldShow}
 * title={title}
 * onClickDiscard={onClickDiscard}
 * onClickSave={onClickSave}
 * onClickGoBack={onClickGoBack}
 * />
 *
 */
const AdminEditPanelHeader: React.FC<AdminEditPanelHeaderProps> = ({
  shouldShow,
  title,
  onClickDiscard,
  onClickSave,
  onClickGoBack,
  isSubmitting,
  bannerImageUrl,
}) => {
  const [offset, setOffset] = useState(0);
  const ref = useRef<any>(null);
  const scroll = Math.abs(ref?.current?.getBoundingClientRect()?.top ?? 0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <Box sx={{ maxWidth: '100%', overflow: 'hidden' }}>
      {bannerImageUrl && (
        <Box
          sx={{
            backgroundImage: `url(${bannerImageUrl})`,
            backgroundSize: 'cover',
            height: 300,
            width: '100%',
          }}
        />
      )}

      <Box display='flex' justifyContent='flex-start' alignItems='center'>
        <IconButton onClick={onClickGoBack} sx={{ mr: 2 }} size='small'>
          <ArrowCircleLeftOutlinedIcon
            sx={{
              color: (theme) => theme.palette.primary.main,
              fontSize: '32px',
              cursor: 'pointer',
            }}
          />
        </IconButton>
        <Typography variant='h4' sx={{ mt: 1, mb: 1 }}>
          {title}
        </Typography>
      </Box>

      <AnimatePresence>
        {shouldShow && (
          <motion.div
            initial={{ x: 200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 200, opacity: 0 }}
            style={{
              position: 'fixed',
              zIndex: 1000,
              top: 76,
              right: 40,
            }}
          >
            <Card
              ref={ref}
              sx={{
                p: 1,
                display: 'flex',
                backgroundColor: '#d3d3d3',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 'fit-content',
              }}
            >
              <>
                <LoadingButton
                  variant='contained'
                  size='small'
                  sx={{
                    m: 0,
                    height: 'fit-content',
                    p: '6px 24px',
                    boxShadow: 'none',
                    mr: 1,
                    width: 120,
                  }}
                  onClick={onClickSave}
                  loading={isSubmitting}
                >
                  Save
                </LoadingButton>
                <Button
                  size='small'
                  sx={{
                    color: 'primary.main',
                    m: 0,
                    height: 'fit-content',
                    p: '6px 18px',
                    width: 120,
                  }}
                  onClick={onClickDiscard}
                  disabled={isSubmitting}
                >
                  Discard
                </Button>
              </>
            </Card>
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};
export default AdminEditPanelHeader;
