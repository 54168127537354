import { FormikProps } from 'formik';
import {
  TypeBd0Enum as campaignType,
  FrequencyEnum as frequencyOptions,
} from '@juno/client-api/model';
import {
  CampaignRecipientSectionFormikValues,
  CampaignScheduleSectionFormikValues,
  NewCampaignSectionFormikValues,
  SidebarSectionFormikValues,
} from '../../utils/validationSchema';

const progress = (
  formik: FormikProps<SidebarSectionFormikValues> | undefined,
  newCampaignSectionValues: NewCampaignSectionFormikValues,
  campaignScheduleSectionValues: CampaignScheduleSectionFormikValues,
  campaignRecipientsSectionValues: CampaignRecipientSectionFormikValues,
) => {
  // required fields
  const campaign_name = newCampaignSectionValues.name;
  const from_name = newCampaignSectionValues.from_name;
  const from_email = newCampaignSectionValues.from_email;
  const subject = newCampaignSectionValues.subject;
  const template = formik?.values.template;
  const start_date = campaignScheduleSectionValues.schedule.start_date;

  // CUSTOM campaign scheduling fields
  const time = campaignScheduleSectionValues.schedule.time;
  const day_offset = campaignScheduleSectionValues.schedule.day_offset;
  const day_of_week = campaignScheduleSectionValues.schedule.day_of_week;
  const week_offset = campaignScheduleSectionValues.schedule.week_offset;
  const date_part = campaignScheduleSectionValues.schedule.date_part;
  const tags = campaignRecipientsSectionValues.metadata.tags;

  let score = 0;
  let divisor = 0;
  const tally = (bool: boolean) => {
    if (bool) {
      score++;
    }
    divisor++;
  };

  tally(Boolean(campaign_name));
  tally(Boolean(from_name));
  tally(Boolean(from_email));
  tally(Boolean(subject));
  tally(Boolean(template));
  tally(Boolean(start_date));

  switch (newCampaignSectionValues.type) {
    case campaignType.CUSTOM:
      tally(Boolean(time));
      switch (campaignScheduleSectionValues.schedule.frequency) {
        case frequencyOptions.ONE_TIME:
          tally(Boolean(tags && tags.length > 0));
          break;
        case frequencyOptions.DAILY:
          tally(Boolean(day_offset));
          tally(Boolean(tags && tags.length > 0));
          break;
        case frequencyOptions.WEEKLY:
          tally(Boolean(day_of_week));
          tally(Boolean(tags && tags.length > 0));
          break;
        case frequencyOptions.MONTHLY:
          if (!date_part) {
            tally(Boolean(week_offset));
            tally(Boolean(day_of_week));
            tally(Boolean(tags && tags.length > 0));
          } else {
            tally(Boolean(tags && tags.length > 0));
          }
          break;
      }
      break;
    default:
      break;
  }

  const total = (score / divisor) * 100;
  return total || 0;
};

export default progress;
