import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { Container } from '../JunoModule/styles';

// TODO: this may be useful for getting further theming support into the module
// interface NestedRulesProps {
//   [key: string]: React.CSSProperties;
// }

// const getStyles = (theme: Theme) => {
//   const tags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
//   const nestedRules = {} as NestedRulesProps;
//   console.log('theme', theme);
//   tags.forEach((tag) => {
//     const rule = theme.typography?.[tag as keyof typeof Typography] as
//       | React.CSSProperties
//       | undefined;
//     if (rule) {
//       nestedRules[`& ${tag}`] = { ...rule };
//     }
//   });
//   return {
//     root: nestedRules,
//   } as { root: NestedRulesProps };
// };

export interface BlurbProps {
  html: string | undefined | unknown;
}
// TODO: this is super basic right now and only inherits basics of the theme.
const Blurb: React.FC<BlurbProps> = ({ html = '' }) => {
  const htmlValue = useMemo(() => {
    const sanitizedString = DOMPurify.sanitize(html as string, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'],
    });
    // uncomment to debug what's getting removed
    // console.log('Dirty values', DOMPurify.removed);
    return sanitizedString;
  }, [html]);

  return (
    <Container fullWidth={false}>
      <Box sx={{ minHeight: 10, p: 2 }}>
        <Typography color='text.primary' dangerouslySetInnerHTML={{ __html: htmlValue }} />
      </Box>
    </Container>
  );
};

export default Blurb;
