import React, { useEffect, useState } from 'react';
import { KeyboardVoiceOutlined, SpeakerOutlined, VideocamOutlined } from '@mui/icons-material';
import {
  Box,
  Card,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useGetUserSettings, useUpdateUserSettings } from '@juno/client-api';
import { UserSettings as UserSettingsModel } from '@juno/client-api/model';
import { Container } from '@juno/ui';
import {
  MutationAction,
  SelectMenuProps,
  StyledSelect,
  onMutation,
  useSettings,
} from '@juno/utils';
import PreferencesCard from './PreferencesCard';

const UserSettings: React.FC = () => {
  const { site, user } = useSettings();
  const [micSelectState, setMicSelectState] = useState('');
  const [cameraSelectState, setCameraSelectState] = useState('');
  const [speakerSelectState, setSpeakerSelectState] = useState('');
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
  const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([]);
  const [speakers, setSpeakers] = useState<MediaDeviceInfo[]>([]);
  const [settings, setSettings] = useState({} as UserSettingsModel);

  const { data: userSettings, refetch } = useGetUserSettings(
    site?.id || '',
    {
      filter: { user__id: user?.id },
    },
    { query: { enabled: !!site?.id && !!user?.id } },
  );

  const updateSettings = useUpdateUserSettings(onMutation(MutationAction.UPDATE, '', refetch));

  const handleChangeProfile = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSettings({ ...settings, private_profile: checked });
    updateSettings.mutate({
      siteId: site?.id || '',
      data: {
        ...settings,
        private_profile: checked,
      },
    });
  };
  const handleChangeMessaging = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setSettings({ ...settings, private_messaging: !checked });
    updateSettings.mutate({
      siteId: site?.id || '',
      data: {
        ...settings,
        private_messaging: !checked,
      },
    });
  };

  const updateMicrophone = (e: any) => {
    setMicSelectState(e.target.value);
    localStorage.setItem('micChoice', JSON.stringify(e.target.value));
  };

  const updateSpeakers = (e: any) => {
    setSpeakerSelectState(e.target.value);
    localStorage.setItem('speakerChoice', JSON.stringify(e.target.value));
  };

  const updateCamera = (e: any) => {
    setCameraSelectState(e.target.value);
    localStorage.setItem('cameraChoice', JSON.stringify(e.target.value));
  };

  useEffect(() => {
    if (userSettings) {
      setSettings(userSettings.find((us) => us.user === user?.id) || ({} as UserSettingsModel));
    }
  }, [userSettings]);

  useEffect(() => {
    const getDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const microphones = devices.filter((device) => device.kind === 'audioinput');
        const cameras = devices.filter((device) => device.kind === 'videoinput');
        const speakers = devices.filter((device) => device.kind === 'audiooutput');

        setMicrophones(microphones);
        setCameras(cameras);
        setSpeakers(speakers);

        const storedMicChoice = localStorage.getItem('micChoice');
        const storedCameraChoice = localStorage.getItem('cameraChoice');
        const storedSpeakerChoice = localStorage.getItem('speakerChoice');

        setMicSelectState(
          storedMicChoice ? JSON.parse(storedMicChoice) : microphones[0]?.deviceId || '',
        );
        setCameraSelectState(
          storedCameraChoice ? JSON.parse(storedCameraChoice) : cameras[0]?.deviceId || '',
        );
        setSpeakerSelectState(
          storedSpeakerChoice ? JSON.parse(storedSpeakerChoice) : speakers[0]?.deviceId || '',
        );
      } catch (err) {
        console.error('Error enumerating devices:', err);
      }
    };

    getDevices();
  }, []);

  return (
    <Container>
      <Card>
        <Typography variant='h5' fontWeight='600' sx={{ ml: 2, mt: 2 }}>
          Settings
        </Typography>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Box sx={{ p: 2 }}>
          <PreferencesCard
            handleGoBack={function (): void {
              throw new Error('Function not implemented.');
            }}
          />
          <Divider sx={{ mt: 3, mb: 3 }} />
          <Box>
            <Typography variant='h6' fontWeight='600'>
              Privacy
            </Typography>
            <FormControlLabel
              sx={{ ml: 0.5, mt: 2 }}
              control={
                <Switch
                  sx={{ mr: 2 }}
                  checked={settings.private_profile || false}
                  onChange={handleChangeProfile}
                  name='private_profile'
                />
              }
              label={<Typography variant='body1'>Make my profile private</Typography>}
            />
          </Box>
          <Divider sx={{ mt: 3, mb: 3 }} />
          <Box>
            <Typography variant='h6' fontWeight='600'>
              Messenger
            </Typography>
            <FormControlLabel
              sx={{ ml: 0.5, mt: 2 }}
              control={
                <Switch
                  sx={{ mr: 2 }}
                  checked={!settings.private_messaging}
                  onChange={handleChangeMessaging}
                  name='private_messaging'
                />
              }
              label={<Typography variant='body1'>Allow other users to send me messages</Typography>}
            />
          </Box>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box>
            <Typography variant='h6' fontWeight='600'>
              Camera & Microphone
            </Typography>
            <Stack spacing={0} mt={2} direction={'column'} flexWrap={'wrap'}>
              {/* Microphone choice */}
              <StyledSelect
                fullWidth
                name='micOptions'
                id='micSelect'
                onChange={updateMicrophone}
                value={micSelectState}
                size='small'
                startAdornment={<KeyboardVoiceOutlined sx={{ fontSize: '1rem', mr: 0.5 }} />}
                MenuProps={SelectMenuProps}
              >
                {microphones.map((mic) => (
                  <MenuItem key={`mic-${mic.deviceId}`} value={mic.deviceId}>
                    {mic.label || 'Unnamed Microphone'}
                  </MenuItem>
                ))}
              </StyledSelect>
              {/* Camera choice */}
              <StyledSelect
                fullWidth
                name='cameraOptions'
                id='cameraSelect'
                onChange={updateCamera}
                value={cameraSelectState}
                size='small'
                startAdornment={<VideocamOutlined sx={{ fontSize: '1rem', mr: 0.5 }} />}
                MenuProps={SelectMenuProps}
              >
                {cameras.map((camera) => (
                  <MenuItem key={`cam-${camera.deviceId}`} value={camera.deviceId}>
                    {camera.label || 'Unnamed Camera'}
                  </MenuItem>
                ))}
              </StyledSelect>
              {/* Speakers choice */}
              <StyledSelect
                fullWidth
                name='speakersOptions'
                id='speakersSelect'
                onChange={updateSpeakers}
                value={speakerSelectState}
                size='small'
                startAdornment={<SpeakerOutlined sx={{ fontSize: '1rem', mr: 0.5 }} />}
                MenuProps={SelectMenuProps}
              >
                {speakers.map((speaker) => (
                  <MenuItem key={`speaker-${speaker.deviceId}`} value={speaker.deviceId}>
                    {speaker.label || 'Unnamed Speaker'}
                  </MenuItem>
                ))}
              </StyledSelect>
            </Stack>
          </Box>
        </Box>
      </Card>
    </Container>
  );
};

export default UserSettings;
