/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type TimingEnum = typeof TimingEnum[keyof typeof TimingEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimingEnum = {
  before: 'before',
  after: 'after',
  download: 'download',
  controlled: 'controlled',
} as const;
