import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  useAddCommunityGroupMember,
  useApproveCommunityGroupPendingMember,
  useGetAllUsers,
  useGetCommunityGroupMembers,
  useGetCommunityGroupPendingMembers,
  useRemoveCommunityGroupMember,
  useRemoveCommunityGroupPendingMember,
} from '@juno/client-api';
import { CommunityGroup, CommunityUser, JunoUser, Site as SiteModel } from '@juno/client-api/model';
import { DialogAriaWrapper, SkeletonUserTile, UserTile } from '@juno/ui';
import { MutationAction, onMutation, usePagination, useSearchFilter } from '@juno/utils';

const MODEL = 'Group';
interface GroupMembersProps {
  site: SiteModel;
  group: CommunityGroup;
}

const USER_PAGE_SIZE = 20;
const MEMBER_PAGE_SIZE = 30;

const GroupMembers: React.FC<GroupMembersProps> = ({ site, group }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [debouncedSearch, search, filter, setSearch] = useSearchFilter();
  const [debouncedSearch2, search2, filter2, setSearch2] = useSearchFilter();
  const [page, setPage] = useState(0);
  const [page2, setPage2] = useState(0);
  const [usersLoading, setUsersLoading] = useState<string[]>([]);
  const [membersLoading, setMembersLoading] = useState<string[]>([]);
  const [userToDelete, setUserToDelete] = useState<CommunityUser | null>(null);
  const [handleRemoveDialogOpen, setHandleRemoveDialogOpen] = useState(false);
  const [pendingDialogOpen, setPendingDialogOpen] = useState(false);
  const [isLoadingApprove, setIsLoadingApprove] = useState('');
  const [isLoadingDeny, setIsLoadingDeny] = useState('');

  const {
    data: members,
    isLoading: isLoadingMembers,
    isFetching: isFetchingMembers,
    refetch: refetchMembers,
  } = useGetCommunityGroupMembers(site.id, group.id, {
    ...filter,
    limit: MEMBER_PAGE_SIZE,
    offset: page,
    order: 'last_name',
  });

  const {
    data: allUsersData,
    isLoading: isLoadingAllUsers,
    isFetching: isFetchingAllUsers,
  } = useGetAllUsers(
    site.platform_id,
    {
      ...filter2,
      limit: USER_PAGE_SIZE,
      offset: page2,
      order: 'last_name',
    },
    {
      query: {
        enabled: search2 !== ('' || null),
      },
    },
  );

  const { data: pendingMembers, refetch: refetchPendingMembers } =
    useGetCommunityGroupPendingMembers(site.id, group.id);

  const [data, setData, ref, isLoading] = usePagination(
    members,
    isLoadingMembers,
    isFetchingMembers,
    search,
    debouncedSearch,
    setPage,
  );

  const [data2, setData2, ref2, isLoading2] = usePagination(
    allUsersData,
    isLoadingAllUsers,
    isFetchingAllUsers,
    search2,
    debouncedSearch2,
    setPage2,
  );

  const refetchAfterApprove = async () => {
    try {
      await refetchPendingMembers();
      await refetchMembers();
    } catch (e) {
      console.error(e);
    }
    setIsLoadingApprove('');
  };

  const refetchAfterDeny = async () => {
    await refetchPendingMembers();
    setIsLoadingDeny('');
  };

  const addMember = useAddCommunityGroupMember(
    onMutation(MutationAction.UPDATE, 'Group', () => {}),
  );
  const removeMember = useRemoveCommunityGroupMember(
    onMutation(MutationAction.UPDATE, 'Group', () => {}),
  );
  const approveMember = useApproveCommunityGroupPendingMember(
    onMutation(MutationAction.UPDATE, 'Group', refetchAfterApprove),
  );
  const removePendingMember = useRemoveCommunityGroupPendingMember(
    onMutation(MutationAction.UPDATE, 'Group', refetchAfterDeny),
  );

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setPage2(0);
    setSearch2('');
    setData2([]);
  };

  const handleAddMember = async (user: JunoUser) => {
    setUsersLoading((old) => [...old, user.id]);
    try {
      await addMember.mutateAsync({
        siteId: site.id,
        groupId: group.id,
        data: {
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        },
      });
      setUsersLoading((old) => old.filter((id) => id !== user.id));
      setData((old) => [
        ...old,
        {
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          avatar: user.icon,
        },
      ]);
      setData2((old) =>
        old.map((u) => {
          if (u.id === user.id)
            return { ...u, community_groups_member: [...u.community_groups_member, group.id] };
          return u;
        }),
      );
    } catch (e) {
      console.error(e);
      setUsersLoading((old) => old.filter((id) => id !== user.id));
    }
  };

  const handleRemove = (user: CommunityUser) => {
    setHandleRemoveDialogOpen(true);
    setUserToDelete(user);
  };

  const handleApproveMember = async (userId: string) => {
    setIsLoadingApprove(userId);
    try {
      await approveMember.mutateAsync({
        siteId: site.id,
        groupId: group.id,
        userId,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemovePendingMember = async (userId: string) => {
    setIsLoadingDeny(userId);
    try {
      await removePendingMember.mutateAsync({
        siteId: site.id,
        groupId: group.id,
        userId,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleRemoveMember = async () => {
    if (!userToDelete) return;
    setHandleRemoveDialogOpen(false);
    try {
      setMembersLoading((old) => [...old, userToDelete.id]);
      await removeMember.mutateAsync({
        siteId: site.id,
        groupId: group.id,
        userId: userToDelete.id,
      });
      setData((old) => old.filter((u) => u.id !== userToDelete.id));
      setMembersLoading((old) => old.filter((id) => id !== userToDelete.id));
      setUserToDelete(null);
    } catch (e) {
      console.error(e);
      setMembersLoading((old) => old.filter((id) => id !== userToDelete.id));
      setUserToDelete(null);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Card sx={{ background: '#fff', p: 2, maxWidth: 600, width: '90%' }}>
        <Stack direction='row' justifyContent={'space-between'} alignItems={'center'} mb={2}>
          <TextField
            onChange={(e) => setSearch(e.target.value)}
            hiddenLabel
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder={'Search members'}
            variant={'filled'}
            size={'small'}
            sx={{
              '.MuiFilledInput-root': {
                borderRadius: 10,
              },
              minWidth: 'calc(70%)',
            }}
          />
          <Button
            onClick={() => setDialogOpen(true)}
            variant={'contained'}
            aria-label={'Add Members'}
          >
            Add Members
          </Button>
        </Stack>
        {(pendingMembers?.length || 0) > 0 && (
          <Box
            sx={{
              backgroundColor: '#F5F5F5',
              borderRadius: 10,
              p: 2,
              mb: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              cursor: 'pointer',
            }}
            onClick={() => setPendingDialogOpen(true)}
          >
            <Box fontWeight={600} sx={{ color: (theme) => theme.palette.primary.main }}>
              Pending members ({pendingMembers?.length})
            </Box>
          </Box>
        )}
        <Box ml={-1} mr={-1}>
          {data
            ?.sort((a, b) => -b?.last_name?.localeCompare(a?.last_name))
            .map((member, idx) => (
              <>
                <UserTile
                  user={member as JunoUser}
                  key={member.id}
                  icon={<CloseIcon sx={{ fontSize: 18 }} aria-label='Remove member' />}
                  handleIconClick={() => handleRemove(member)}
                  email={true}
                  loading={membersLoading.includes(member.id)}
                />
                {idx === data.length - 1 && !isLoading && <Box ref={ref} sx={{ height: 20 }}></Box>}
              </>
            ))}
          {isLoading && (
            <Box>
              <SkeletonUserTile />
            </Box>
          )}
          {!isLoading && data?.length === 0 && search !== '' && (
            <Typography textAlign={'center'} pt={1} pb={1}>
              No results match your search
            </Typography>
          )}
        </Box>
        <DialogAriaWrapper
          open={dialogOpen}
          onClose={handleCloseDialog}
          id={'add_users_dialog'}
          fullWidth
          sx={{ overflow: 'visible' }}
        >
          <DialogTitle
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              backgroundColor: '#fff',
            }}
          >
            Add group members
            <IconButton onClick={handleCloseDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider sx={{ width: '100%' }} />
          <DialogContent
            sx={{
              backgroundColor: '#fff',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              p: 0,
              pt: 2,
              maxHeight: '50vh',
            }}
          >
            <TextField
              onChange={(e) => setSearch2(e.target.value)}
              hiddenLabel
              InputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              placeholder={'Search for users to add'}
              variant={'filled'}
              size={'small'}
              sx={{
                '.MuiFilledInput-root': {
                  borderRadius: 10,
                },
                minWidth: 'calc(100% - 32px)',
                mb: 2,
              }}
            />
            <Box pl={1} pr={1} sx={{ width: '100%' }}>
              {isLoading2 && search2 && (
                <Box mb={2}>
                  <SkeletonUserTile />
                </Box>
              )}
              {data2?.length === 0 && search2 && !isLoading2 && (
                <Box textAlign={'center'} mb={2}>
                  No users found
                </Box>
              )}
              {!isLoading2 && search2 && (
                <Box mb={2}>
                  {data2.map((user) => (
                    <Box mb={1} key={user.id}>
                      <UserTile
                        user={user}
                        email={true}
                        rightAdornment={
                          <LoadingButton
                            variant={'contained'}
                            size={'small'}
                            sx={{ textTransform: 'none' }}
                            loading={usersLoading.includes(user.id)}
                            onClick={() => handleAddMember(user)}
                            disabled={user.community_groups_member.includes(group.id)}
                            aria-label={`Add ${user.first_name} ${user.last_name} to group`}
                          >
                            Add
                          </LoadingButton>
                        }
                      />
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </DialogContent>
        </DialogAriaWrapper>
        <DialogAriaWrapper
          open={handleRemoveDialogOpen}
          onClose={() => setHandleRemoveDialogOpen(false)}
          id={`remove_user_confirm_dialog`}
        >
          <Box sx={{ backgroundColor: '#fff' }}>
            <DialogTitle>Remove member from group</DialogTitle>
            <DialogContent>
              Are you sure you would like to remove {userToDelete?.first_name}{' '}
              {userToDelete?.last_name} from this group?
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setHandleRemoveDialogOpen(false)}
                sx={{ textTransform: 'none' }}
                aria-label={`Cancel removing ${userToDelete?.first_name} ${userToDelete?.last_name} from group`}
              >
                Cancel
              </Button>
              <LoadingButton
                onClick={() => handleRemoveMember()}
                sx={{ textTransform: 'none' }}
                variant={'contained'}
                aria-label={`Confirm removing ${userToDelete?.first_name} ${userToDelete?.last_name} from group`}
              >
                Remove
              </LoadingButton>
            </DialogActions>
          </Box>
        </DialogAriaWrapper>
        <DialogAriaWrapper
          open={pendingDialogOpen}
          onClose={() => setPendingDialogOpen(false)}
          id={'pending_members_dialog'}
        >
          <Box sx={{ background: '#fff', width: 600 }}>
            <DialogTitle>Pending Members</DialogTitle>
            <DialogContent>
              {pendingMembers?.map((member) => (
                <UserTile
                  key={member.id}
                  user={member as JunoUser}
                  rightAdornment={
                    <>
                      <LoadingButton
                        sx={{ textTransform: 'none' }}
                        onClick={(e) => handleRemovePendingMember(member.id)}
                        loading={isLoadingDeny === member.id}
                        disabled={isLoadingApprove === member.id}
                      >
                        Deny
                      </LoadingButton>
                      <LoadingButton
                        variant='contained'
                        sx={{ textTransform: 'none' }}
                        onClick={(e) => handleApproveMember(member.id)}
                        loading={isLoadingApprove === member.id}
                        disabled={isLoadingDeny === member.id}
                      >
                        Approve
                      </LoadingButton>
                    </>
                  }
                />
              ))}
            </DialogContent>
          </Box>
        </DialogAriaWrapper>
      </Card>
    </Box>
  );
};

export default GroupMembers;
