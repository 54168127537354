import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FormikProps } from 'formik';
import { useDebounce } from 'usehooks-ts';
import { useSearchContent } from '@juno/client-api';
import { AlignmentEnum } from '@juno/client-api/fakeModel';
import {
  ComponentData,
  Component as ComponentModel,
  SearchContent as SearchContentModel,
} from '@juno/client-api/model';
import { useSettings } from '@juno/utils';

export enum ColorEnum {
  primary = 'primary',
  secondary = 'secondary',
  accent = 'info',
}
interface ModuleButtonFormProps {
  component?: ComponentModel;
  onUpdate: (component: ComponentModel) => void;
  formik: FormikProps<any>;
  includeAlignment?: boolean;
}
export interface ModuleButtonModel {
  to: string | SearchContentModel;
  text: string;
  color: ColorEnum;
  type: 'internal' | 'external';
  alignment?: AlignmentEnum;
}
export interface ModuleButtonDataModel extends ComponentData {
  button: ModuleButtonModel;
}

const ModuleButtonForm: React.FC<ModuleButtonFormProps> = ({
  component,
  onUpdate,
  formik,
  includeAlignment,
}) => {
  const { setValues, values, handleChange } = formik;
  const { site } = useSettings();
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 300);
  const { data } = values;
  const { button } = data;
  const debouncedValues = useDebounce(values, 700);
  const { data: searchData } = useSearchContent(
    site?.id || '',
    { search_term: debouncedSearch || '' },
    { query: { enabled: !!search } },
  );
  useEffect(() => {
    if (component) {
      onUpdate({ ...component, data: debouncedValues });
    }
  }, [debouncedValues]);
  return (
    <Box sx={{ mt: 2 }}>
      <FormLabel id='button_form_label' sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>
        Button
      </FormLabel>
      <Box display='flex'>
        <TextField
          value={button?.text || ''}
          onChange={handleChange}
          name='data.button.text'
          label='text'
          variant='filled'
          fullWidth
          InputProps={{ disableUnderline: true }}
          sx={{ mr: 1 }}
        />
        <FormControl variant='filled' fullWidth sx={{ ml: 1 }}>
          <InputLabel id='alignment_label' sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>
            Color
          </InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            name='data.button.color'
            value={button?.color || ColorEnum.primary}
            label='Color'
            onChange={handleChange}
            disableUnderline
          >
            <MenuItem value={ColorEnum.primary}>{ColorEnum.primary}</MenuItem>
            <MenuItem value={ColorEnum.secondary}>{ColorEnum.secondary}</MenuItem>
            <MenuItem value={ColorEnum.accent}>Accent</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <FormControl sx={{ mt: 3 }}>
        <FormLabel id='alignment_label' sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>
          Button Destination
        </FormLabel>
        <RadioGroup
          aria-labelledby='alignment_label'
          defaultValue={'internal'}
          name='data.button.type'
          value={button?.type}
          onChange={(e) => {
            setValues({
              ...values,
              data: {
                ...values.data,
                button: {
                  ...button,
                  type: e.target.value,
                  to: e.target.value === 'internal' ? '' : null,
                },
              },
            });
          }}
          sx={{ flexDirection: 'row' }}
        >
          <FormControlLabel value={'internal'} control={<Radio />} label='Internal' />
          <FormControlLabel value={'external'} control={<Radio />} label='External' />
        </RadioGroup>
      </FormControl>
      {button?.type === 'internal' && (
        <Autocomplete
          options={searchData || []}
          getOptionLabel={(option) => option?.title}
          value={button?.to || null}
          noOptionsText={search ? 'No results found' : 'Search to find options'}
          renderOption={(props, option) => (
            <li {...props}>
              <Avatar src={option.icon} sx={{ mr: 2 }} />
              <Box>
                <Typography>{option.title}</Typography>
                <Typography variant='body2'>{option.type}</Typography>
              </Box>
            </li>
          )}
          onChange={(e, newValue) => {
            setValues({
              ...values,
              data: {
                ...values.data,
                button: { ...button, to: newValue },
              },
            });
          }}
          sx={{ mt: 2 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Search courses, pages, groups, or sessions'
              variant='filled'
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              onChange={(e) => setSearch(e.target.value)}
            />
          )}
        />
      )}
      {button?.type === 'external' && (
        <TextField
          value={button?.to || ''}
          onChange={handleChange}
          name='data.button.to'
          label='Destination url'
          variant='filled'
          fullWidth
          InputProps={{ disableUnderline: true }}
          sx={{ mt: 2 }}
        />
      )}
      {includeAlignment && (
        <FormControl sx={{ mt: 3 }}>
          <FormLabel id='alignment_label' sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>
            Button Alignment
          </FormLabel>
          <RadioGroup
            aria-labelledby='alignment_label'
            defaultValue={AlignmentEnum.left}
            value={values?.data?.button?.alignment}
            onChange={(e) => {
              setValues({
                ...values,
                data: {
                  ...values.data,
                  button: {
                    ...button,
                    alignment: e.target.value as AlignmentEnum,
                  },
                },
              });
            }}
          >
            <FormControlLabel value={AlignmentEnum.left} control={<Radio />} label='Left' />
            <FormControlLabel value={AlignmentEnum.right} control={<Radio />} label='Right' />
            <FormControlLabel value={AlignmentEnum.center} control={<Radio />} label='Center' />
          </RadioGroup>
        </FormControl>
      )}
    </Box>
  );
};

export default ModuleButtonForm;

const SkeletonContent: React.FC = () => {
  return (
    <Stack direction='row'>
      <Skeleton variant='circular' />
      <Skeleton variant='text' />
    </Stack>
  );
};
