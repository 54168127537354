import { RelativeTimeFrameOptions } from './utils';

export function dhm(t: number, padTime?: boolean): string {
  const cd = 24 * 60 * 60 * 1000,
    ch = 60 * 60 * 1000,
    pad = function (n: number) {
      return n < 10 ? '0' + n : n;
    };
  let d = Math.floor(t / cd),
    h = Math.floor((t - d * cd) / ch),
    m = Math.round((t - d * cd - h * ch) / 60000);
  if (m === 60) {
    h++;
    m = 0;
  }
  if (h === 24) {
    d++;
    h = 0;
  }
  if (padTime) {
    return `${d}d ${pad(h)}h ${pad(m)}m`;
  } else {
    return `${d}d ${h}h ${m}m`;
  }
}

// take the date and return the date string for our datetimepickers in the local timezone
export const UTCtoLocalString = (date: Date, dateOnly?: boolean) => {
  const offset = new Date().getTimezoneOffset() * 60000;
  const newDate = date.getTime() - offset;
  const sliceLength = dateOnly ? 10 : 16;
  return new Date(newDate).toISOString().slice(0, sliceLength);
};

/**
 *  Checks a new date value against an existing value to see which comes before/after
 *  @param {string} newDateString - The incoming date value as a string (e.g., '2023-07-25T12:34:56')
 *  @param {string} existingDateString - The old date value as a string (e.g., '2023-07-25T12:34:56')
 *  @param {boolean} greaterThan - If true, we are searching for which date comes after; if false we want which date comes before
 *  @return {boolean} True if the incoming date is greater/less than the existing date
 */
export const compareDateStrings = (
  newDateString: string,
  existingDateString: string,
  greaterThan: boolean,
) => {
  const newDate = new Date(newDateString);
  const existingDate = new Date(existingDateString);
  return greaterThan ? newDate > existingDate : newDate < existingDate;
};

export const getBeginningOfDay = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 1, 0);
};

export const getEndOfDay = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 0);
};

// For each Relative Time Frame option, we return toDate & fromDate values based on the time frame
export const interpolateRelativeTimeFrame = (timeMetric: string) => {
  const today = new Date();
  switch (timeMetric) {
    case RelativeTimeFrameOptions.Today:
      return [getBeginningOfDay(today).toISOString(), getEndOfDay(today).toISOString()];
    case RelativeTimeFrameOptions.LastWeek: {
      const firstDayLastWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - (today.getDay() + 7),
      );
      const lastDayLastWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - (today.getDay() + 1),
      );
      return [
        getBeginningOfDay(firstDayLastWeek).toISOString(),
        getEndOfDay(lastDayLastWeek).toISOString(),
      ];
    }
    case RelativeTimeFrameOptions.ThisWeek: {
      const firstDayThisWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - today.getDay(),
      );
      const lastDayThisWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - (today.getDay() - 6),
      );
      return [
        getBeginningOfDay(firstDayThisWeek).toISOString(),
        getEndOfDay(lastDayThisWeek).toISOString(),
      ];
    }
    case RelativeTimeFrameOptions.NextWeek: {
      const firstDayNextWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + (7 - today.getDay()),
      );
      const lastDayNextWeek = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + (13 - today.getDay()),
      );
      return [
        getBeginningOfDay(firstDayNextWeek).toISOString(),
        getEndOfDay(lastDayNextWeek).toISOString(),
      ];
    }
    case RelativeTimeFrameOptions.LastMonth: {
      const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
      return [
        getBeginningOfDay(firstDayLastMonth).toISOString(),
        getEndOfDay(lastDayLastMonth).toISOString(),
      ];
    }
    case RelativeTimeFrameOptions.ThisMonth: {
      const firstDayThisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      const lastDayThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      return [
        getBeginningOfDay(firstDayThisMonth).toISOString(),
        getEndOfDay(lastDayThisMonth).toISOString(),
      ];
    }
    case RelativeTimeFrameOptions.NextMonth: {
      const firstDayNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      const lastDayNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);
      return [
        getBeginningOfDay(firstDayNextMonth).toISOString(),
        getEndOfDay(lastDayNextMonth).toISOString(),
      ];
    }
    case RelativeTimeFrameOptions.LastYear: {
      const firstDayLastYear = new Date(today.getFullYear() - 1, 0, 1);
      const lastDayLastYear = new Date(today.getFullYear() - 1, 11, 31);
      return [
        getBeginningOfDay(firstDayLastYear).toISOString(),
        getEndOfDay(lastDayLastYear).toISOString(),
      ];
    }
    case RelativeTimeFrameOptions.ThisYear: {
      const firstDayThisYear = new Date(today.getFullYear(), 0, 1);
      const lastDayThisYear = new Date(today.getFullYear(), 11, 31);
      return [
        getBeginningOfDay(firstDayThisYear).toISOString(),
        getEndOfDay(lastDayThisYear).toISOString(),
      ];
    }
    case RelativeTimeFrameOptions.NextYear: {
      const firstDayNextYear = new Date(today.getFullYear() + 1, 0, 1);
      const lastDayNextYear = new Date(today.getFullYear() + 1, 11, 31);
      return [
        getBeginningOfDay(firstDayNextYear).toISOString(),
        getEndOfDay(lastDayNextYear).toISOString(),
      ];
    }
    default:
      return ['', ''];
  }
};

/**
 *  We take in an integer value representing an amount of days, and we calculate to and from date values based
 *  on the date range and whether the calculation is the previous amount of days or the upcoming amount of days
 *  NOTE: all return ranges INCLUDE today
 * @param {number} dayRange - The amount of days for which to calculate a rolling date range
 * @param {string} previousOrUpcoming - Determines whether we calculate into the past or into the future
 * @returns {[string, string]} - toDate and fromDate values cast to ISO strings
 */
export const interpolatePreviousUpcomingTimeFrame = (
  dayRange: number,
  previousOrUpcoming: string,
) => {
  const now = new Date();
  const fromDate = new Date();
  const toDate = new Date();
  switch (previousOrUpcoming) {
    case 'Previous': {
      fromDate.setDate(now.getDate() - dayRange);
      return [getBeginningOfDay(fromDate).toISOString(), getEndOfDay(toDate).toISOString()];
    }
    case 'Upcoming': {
      toDate.setDate(now.getDate() + dayRange);
      return [getBeginningOfDay(fromDate).toISOString(), getEndOfDay(toDate).toISOString()];
    }
    default:
      return ['', ''];
  }
};

/**
 * Sets the time of the given date to the very beginning of the day for from dates and very end of the day for to dates
 * @param {string} newValue - The date to be searched as a string (e.g., '2023-07-25T12:34:56')
 * @param {string} dateField - The date field name corresponding with the date value ('beforeAfterDate', 'fromDate', or 'toDate')
 * @param {string} beforeAfterBetween - The value representing 'Before', 'After', or 'Between'
 * @returns {string} The date value with the time set to the beginning or end of the day in ISO format (e.g., '2023-07-25T00:01:00' or '2023-07-25T23:59:00')
 */
export const findBeginningOrEndOfDay = (
  newValue: string,
  dateField: string,
  beforeAfterBetween: string,
) => {
  // For date fields, set them either to 12:01am or 11:59pm
  const newDateValue = newValue === '' ? '' : new Date(newValue);
  if (dateField === 'beforeAfterDate' || dateField === 'fromDate' || dateField === 'toDate') {
    if (newDateValue) {
      if (
        dateField === 'fromDate' ||
        (dateField === 'beforeAfterDate' && beforeAfterBetween === 'After')
      ) {
        // Set fromDate's or After dates to 12:01AM
        newDateValue.setHours(0, 1, 0);
      } else {
        // Set toDate's or Before dates to 11:59PM
        newDateValue.setHours(23, 59, 0);
      }
      return newDateValue.toISOString();
    }
  }
  return '';
};

export const getTimeSinceCreated = (isMobile: boolean, date_created?: string | null) => {
  if (date_created) {
    const dateCreated = new Date(date_created);
    const timeSincePosted = new Date().getTime() - dateCreated.getTime();
    const yearsSincePosted = Math.floor(timeSincePosted / (1000 * 60 * 60 * 24 * 7 * 4 * 12));
    const monthsSincePosted = Math.floor(timeSincePosted / (1000 * 60 * 60 * 24 * 7 * 4));
    const weeksSincePosted = Math.floor(timeSincePosted / (1000 * 60 * 60 * 24 * 7));
    const daysSincePosted = Math.floor(timeSincePosted / (1000 * 60 * 60 * 24));
    const hoursSincePosted = Math.floor(timeSincePosted / (1000 * 60 * 60));
    const minutesSincePosted = Math.floor(timeSincePosted / (1000 * 60));
    const secondsSincePosted = Math.floor(timeSincePosted / 1000);

    if (yearsSincePosted > 0) {
      if (isMobile) {
        return `${yearsSincePosted}y`;
      }
      return yearsSincePosted === 1 ? '1 year ago' : `${yearsSincePosted} years ago`;
    } else if (monthsSincePosted > 0) {
      if (isMobile) {
        return `${monthsSincePosted}m`;
      }
      return monthsSincePosted === 1 ? '1 month ago' : `${monthsSincePosted} months ago`;
    } else if (weeksSincePosted > 0) {
      if (isMobile) {
        return `${weeksSincePosted}w`;
      }
      return weeksSincePosted === 1 ? '1 week ago' : `${weeksSincePosted} weeks ago`;
    } else if (daysSincePosted > 0) {
      if (isMobile) {
        return `${daysSincePosted}d`;
      }
      return daysSincePosted === 1 ? '1 day ago' : `${daysSincePosted} days ago`;
    } else if (hoursSincePosted > 0) {
      if (isMobile) {
        return `${hoursSincePosted}h`;
      }
      return hoursSincePosted === 1 ? '1 hour ago' : `${hoursSincePosted} hours ago`;
    } else if (minutesSincePosted > 0) {
      if (isMobile) {
        return `${minutesSincePosted}m`;
      }
      return minutesSincePosted === 1 ? '1 minute ago' : `${minutesSincePosted} minutes ago`;
    } else if (secondsSincePosted > 0) {
      if (isMobile) {
        return `${secondsSincePosted}s`;
      }
      return secondsSincePosted === 1 ? '1 second ago' : `${secondsSincePosted} seconds ago`;
    } else {
      if (isMobile) {
        return 'now';
      }
      return 'just now';
    }
  } else {
    return '';
  }
};
