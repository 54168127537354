import { useEffect, useState } from 'react';

enum TimerState {
  idle = 'idle',
  running = 'running',
  stopped = 'stopped',
}
interface TimerProps {
  startingTime: number;
  timeAllowed: number;
  handleTimesUp?: () => void;
}
interface TimerHookResults {
  remainingTime: number;
  progress: number;
  startTimer: () => void;
  stopTimer: () => void;
  resetTimer: () => void;
  state: TimerState;
}

export const useTimer = ({
  startingTime,
  timeAllowed,
  handleTimesUp,
}: TimerProps): TimerHookResults => {
  const [remainingTime, setRemainingTime] = useState(
    startingTime < timeAllowed ? startingTime : timeAllowed,
  );
  const [progress, setProgress] = useState(0);
  const [state, setState] = useState<TimerState>(TimerState.idle);
  let timer: NodeJS.Timeout | null = null;

  const startTimer = () => {
    if (state === TimerState.idle) {
      setState(TimerState.running);
      timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(timer!);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
  };

  const stopTimer = () => {
    if (state === TimerState.running) {
      setState(TimerState.stopped);
      clearInterval(timer!);
    }
  };

  const resetTimer = () => {
    setState(TimerState.idle);
    clearInterval(timer!);
    setRemainingTime(timeAllowed);
  };

  useEffect(() => {
    if (remainingTime <= 0) {
      stopTimer();
      handleTimesUp && handleTimesUp();
    }
  }, [remainingTime, handleTimesUp]);

  useEffect(() => {
    const calculatedProgress = ((timeAllowed - remainingTime) / timeAllowed) * 100;
    setProgress(calculatedProgress);
  }, [remainingTime, timeAllowed]);

  useEffect(() => {
    return () => {
      clearInterval(timer!);
    };
  }, []);

  return { remainingTime, progress, startTimer, stopTimer, resetTimer, state };
};
