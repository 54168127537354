import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSettings } from '@juno/utils';
import * as APP_MESSAGE_ACTIONS from '../constants';
import FlyingEmoji from '../components/Room/FlyingEmoji';
import { useSharedState } from '../hooks/useSharedState';
import { useCallState } from './CallProvider';

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const { callObject } = useCallState();
  const navigate = useNavigate();
  const { user: currentUser, sitePath } = useSettings();
  const { sharedState, setSharedState } = useSharedState({
    initialValues: {
      chatHistory: [],
      qaHistory: [],
    },
    broadcast: false,
  });
  const [hasNewChatMessage, setHasNewChatMessage] = useState(false);
  const [hasNewQAMessage, setHasNewQAMessage] = useState(false);
  const [endBreakoutMessage, setEndBreakoutMessage] = useState({});
  const [flyingEmojis, setFlyingEmojis] = useState([]);
  const removeFlyingEmoji = (emojiComponent) => {
    setFlyingEmojis((prevEmojis) => prevEmojis.filter((emoji) => emoji !== emojiComponent));
  };
  const [userEmojiClicked, setUserEmojiClicked] = useState(null);
  const [showRoomDialog, setShowRoomDialog] = useState(false);
  const [roomDialogTitle, setRoomDialogTitle] = useState('');
  const [roomDialogMessage, setRoomDialogMessage] = useState('');
  const [navigateUrl, setNavigateUrl] = useState('');

  const handleNewMessage = useCallback(
    (e) => {
      if (e?.data?.message?.type) return;
      const participants = callObject.participants();
      const senderName = participants[e.fromId]?.user_name
        ? participants[e.fromId]?.user_name
        : 'Guest';

      const sender = {
        senderName,
        ...e.data.sender,
      };
      // Update shared state history
      switch (e?.data?.type) {
        /** START CHAT ACTIONS */
        case APP_MESSAGE_ACTIONS.CHAT_ACTIONS.SEND_MESSAGE:
          setSharedState((values) => ({
            ...values,
            chatHistory: [
              ...values.chatHistory,
              {
                sender,
                senderID: e.fromId,
                message: e.data.message,
                id: e.data.id,
                createdAt: e.data.createdAt,
              },
            ],
          }));
          setHasNewChatMessage(true);
          break;
        /** END CHAT ACTIONS */
        /** START QA ACTIONS */
        case APP_MESSAGE_ACTIONS.QA_ACTIONS.ASK_QUESTION:
          setSharedState((values) => ({
            ...values,
            qaHistory: [
              ...values.qaHistory,
              {
                sender,
                senderID: e.fromId,
                message: e.data.message,
                id: e.data.id,
                createdAt: e.data.createdAt,
              },
            ],
          }));
          setHasNewQAMessage(true);
          break;
        case APP_MESSAGE_ACTIONS.QA_ACTIONS.ANSWER_QUESTION:
          setSharedState((values) => {
            const updatedQaHistory = values.qaHistory.map((question) => {
              if (question.id === e.data.message?.messageId) {
                return {
                  ...question,
                  message: {
                    ...question.message,
                    answer: e.data.message.message?.answer,
                  },
                };
              }
              return question;
            });
            return {
              ...values,
              qaHistory: [...updatedQaHistory],
            };
          });
          break;
        case APP_MESSAGE_ACTIONS.QA_ACTIONS.UPVOTE:
          setSharedState((values) => {
            const updatedQaHistory = values.qaHistory.map((question) => {
              if (question.id === e.data.message?.messageId) {
                return {
                  ...question,
                  message: {
                    ...question.message,
                    voters: e.data.message.message?.voters,
                  },
                };
              }
              return question;
            });
            return {
              ...values,
              qaHistory: [...updatedQaHistory],
            };
          });
          break;
        case APP_MESSAGE_ACTIONS.QA_ACTIONS.MARK_AS_ANSWERED:
          setSharedState((values) => {
            const updatedQaHistory = values.qaHistory.map((question) => {
              if (question.id === e.data.message?.messageId) {
                return {
                  ...question,
                  message: {
                    ...question.message,
                    isAnswered: e.data.message.message?.isAnswered,
                  },
                };
              }
              return question;
            });
            return {
              ...values,
              qaHistory: [...updatedQaHistory],
            };
          });
          break;
        case APP_MESSAGE_ACTIONS.QA_ACTIONS.DELETE_QUESTION:
          setSharedState((values) => {
            const updatedQaHistory = values.qaHistory.map((question) => {
              if (question.id === e.data.message?.messageId) {
                return {
                  ...question,
                  message: {
                    ...question.message,
                    isDeleted: true,
                  },
                };
              }
              return question;
            });
            return {
              ...values,
              qaHistory: [...updatedQaHistory],
            };
          });
          break;
        case APP_MESSAGE_ACTIONS.QA_ACTIONS.DELETE_ANSWER:
          setSharedState((values) => {
            const updatedQaHistory = values.qaHistory.map((question) => {
              if (question.id === e.data.message?.messageId) {
                return {
                  ...question,
                  message: {
                    ...question.message,
                    answer: undefined,
                    isAnswered: false,
                  },
                };
              }
              return question;
            });
            return {
              ...values,
              qaHistory: [...updatedQaHistory],
            };
          });

          break;
        /** END QA ACTIONS */
        /** START BREAKOUT ACTIONS */
        case APP_MESSAGE_ACTIONS.BREAKOUT_ACTIONS.SEND_TO_ROOM:
          {
            const rooms = e.data.message.rooms;
            for (let room of rooms) {
              const assignedUsers = room.assigned_users;
              const isInThisRoom =
                assignedUsers.filter((assignedUserId) => assignedUserId === currentUser.id).length >
                0;
              if (isInThisRoom) {
                localStorage.setItem('parentSession', room.parent_session);
                // if room.auto_join is true, navigate to the room
                // otherwise, prompt the user to join the room
                if (room.auto_join) {
                  switchRooms(room.session);
                } else {
                  setRoomDialogTitle('Join Breakout Room');
                  setRoomDialogMessage(
                    `You are being sent to a breakout room. Would you like to join?`,
                  );
                  setNavigateUrl(room.session);
                  setShowRoomDialog(true);
                }
                break;
              }
            }
          }
          break;
        case APP_MESSAGE_ACTIONS.BREAKOUT_ACTIONS.RETURN_TO_MAIN:
          {
            localStorage.removeItem('parentSession');
            const waitTime = e.data.message.wait_time;
            if (waitTime) {
              // wait for the specified time before returning to main
              const timeInMs = () => {
                switch (waitTime) {
                  case APP_MESSAGE_ACTIONS.EndRoomOptions.EndImmediately:
                    return 0;
                  case APP_MESSAGE_ACTIONS.EndRoomOptions.EndIn10Seconds:
                    return 10000;
                  case APP_MESSAGE_ACTIONS.EndRoomOptions.EndIn30Seconds:
                    return 30000;
                  case APP_MESSAGE_ACTIONS.EndRoomOptions.EndIn1Minute:
                    return 60000;
                }
              };
              setEndBreakoutMessage(e.data.message);
              setTimeout(() => {
                switchRooms(e.data.message.main_url);
              }, timeInMs());
            } else {
              switchRooms(e.data.message.main_url);
            }
          }
          break;
        /** END BREAKOUT ACTIONS */
        /** BEGIN EMOJIS */
        case APP_MESSAGE_ACTIONS.EMOJI_ACTIONS.SEND_EMOJI:
          {
            const newFlyingEmoji = (
              <FlyingEmoji
                key={Date.now()}
                emoji={e.data.message.emoji}
                onExited={() => removeFlyingEmoji(newFlyingEmoji)}
              />
            );
            setFlyingEmojis((prevEmojis) => [...prevEmojis, newFlyingEmoji]);
            setUserEmojiClicked({
              userId: sender.id,
              emoji: e.data.message.emoji,
            });
            setTimeout(() => {
              setUserEmojiClicked(null);
            }, 5000);
          }
          break;
        /** END EMOJIS */
        default:
          console.log('Unknown newMessage type', e.data.type);
          break;
      }
    },
    [callObject, setSharedState],
  );

  const switchRooms = async (roomUrl) => {
    await callObject?.leave();
    callObject?.destroy();
    navigate(`/${sitePath}/sessions/${roomUrl}`);
  };

  const sendMessage = useCallback(
    (message, type) => {
      if (!callObject) return;
      const createdAt = new Date();
      const newId = nanoid();
      // keep the sender data as small as possible to avoid the "data too large - over 4096mb" error
      const sender = {
        id: currentUser.id,
        first_name: currentUser.first_name,
        last_name: currentUser.last_name,
        avatar: currentUser.avatar,
      };
      callObject.sendAppMessage({ id: newId, message, sender, type, createdAt }, '*');

      const participants = callObject.participants();
      const senderDailyId = participants.local.user_id;

      // Update shared state history
      switch (type) {
        /** START CHAT ACTIONS */
        case APP_MESSAGE_ACTIONS.CHAT_ACTIONS.SEND_MESSAGE:
          return setSharedState((values) => ({
            ...values,
            chatHistory: [
              ...values.chatHistory,
              { sender, senderID: senderDailyId, message, id: newId, createdAt },
            ],
          }));
        /** END CHAT ACTIONS */
        /** START QA ACTIONS */
        case APP_MESSAGE_ACTIONS.QA_ACTIONS.ASK_QUESTION:
          return setSharedState((values) => ({
            ...values,
            qaHistory: [
              ...values.qaHistory,
              { sender, senderID: senderDailyId, message, id: newId, createdAt },
            ],
          }));
        case APP_MESSAGE_ACTIONS.QA_ACTIONS.UPVOTE:
          return setSharedState((values) => {
            const updatedQaHistory = values.qaHistory.map((question) => {
              if (question.id === message?.messageId) {
                return {
                  ...question,
                  message: {
                    ...question.message,
                    voters: message.message?.voters,
                  },
                };
              }
              return question;
            });

            return {
              ...values,
              qaHistory: [...updatedQaHistory],
            };
          });
        case APP_MESSAGE_ACTIONS.QA_ACTIONS.MARK_AS_ANSWERED:
          return setSharedState((values) => {
            const updatedQaHistory = values.qaHistory.map((question) => {
              if (question.id === message?.messageId) {
                return {
                  ...question,
                  message: {
                    ...question.message,
                    isAnswered: message.message?.isAnswered,
                  },
                };
              }
              return question;
            });

            return {
              ...values,
              qaHistory: [...updatedQaHistory],
            };
          });
        case APP_MESSAGE_ACTIONS.QA_ACTIONS.DELETE_QUESTION:
          return setSharedState((values) => {
            const updatedQaHistory = values.qaHistory.map((question) => {
              if (question.id === message?.messageId) {
                return {
                  ...question,
                  message: {
                    ...question.message,
                    isDeleted: message.message?.isDeleted,
                  },
                };
              }
              return question;
            });

            return {
              ...values,
              qaHistory: [...updatedQaHistory],
            };
          });
        case APP_MESSAGE_ACTIONS.QA_ACTIONS.DELETE_ANSWER:
          return setSharedState((values) => {
            const updatedQaHistory = values.qaHistory.map((question) => {
              if (question.id === message?.messageId) {
                return {
                  ...question,
                  message: {
                    ...question.message,
                    answer: undefined,
                    isAnswered: false,
                  },
                };
              }
              return question;
            });

            return {
              ...values,
              qaHistory: [...updatedQaHistory],
            };
          });
        /** END QA ACTIONS */
        /** START BREAKOUT ACTIONS */
        case APP_MESSAGE_ACTIONS.BREAKOUT_ACTIONS.SEND_TO_ROOM:
          // TODO
          break;
        case APP_MESSAGE_ACTIONS.BREAKOUT_ACTIONS.RETURN_TO_MAIN:
          // TODO
          break;
        /** END BREAKOUT ACTIONS */
        default:
          console.log('Unknown sendMessage type', type);
          break;
      }
    },
    [callObject, setSharedState],
  );

  useEffect(() => {
    if (!callObject) return;

    callObject.on('app-message', handleNewMessage);

    return () => callObject.off('app-message', handleNewMessage);
  }, [callObject, handleNewMessage]);

  return (
    <ChatContext.Provider
      value={{
        sendMessage,
        chatHistory: sharedState.chatHistory,
        qaHistory: sharedState.qaHistory,
        hasNewChatMessage,
        setHasNewChatMessage,
        hasNewQAMessage,
        setHasNewQAMessage,
        endBreakoutMessage,
        setEndBreakoutMessage,
        flyingEmojis,
        setFlyingEmojis,
        showRoomDialog,
        setShowRoomDialog,
        roomDialogTitle,
        setRoomDialogTitle,
        roomDialogMessage,
        setRoomDialogMessage,
        navigateUrl,
        switchRooms,
        userEmojiClicked,
        setUserEmojiClicked,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

ChatProvider.propTypes = {
  children: PropTypes.node,
};

export const useChat = () => useContext(ChatContext);
