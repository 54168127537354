import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  TextField,
} from '@mui/material';
import { Page as PageModel } from '@juno/client-api/model';
import { slugify } from '@juno/utils';

export interface CreateUpdatePageDialogProps {
  page: PageModel | undefined;
  open: boolean;
  handleClose: () => void;
  onSubmit: (values: PageModel | undefined) => void;
}

const CreateUpdatePageDialog = ({
  page,
  open,
  onSubmit,
  handleClose,
}: CreateUpdatePageDialogProps) => {
  const [payload, setPayload] = useState(page);
  const title = page ? `Page settings` : `New Page`;
  const buttonLabel = page ? `Update Page` : `Create Page`;

  useEffect(() => {
    if (!open) setPayload(page);
  }, [open, page]);

  const onChange = (key: string, val: unknown) => {
    const newPayload = { ...payload, [key]: val } as PageModel;
    setPayload(newPayload);
  };

  const onChangeMetadata = (key: string, val: unknown) => {
    const metadata = { ...payload?.metadata };
    const newPayload = { ...payload, metadata: { ...metadata, [key]: val } } as PageModel;
    setPayload(newPayload);
  };

  const onTextChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (target.name === 'title' && !page) {
      // Update slug value based on title update for new pages
      setPayload({ ...payload, title: target.value, slug: slugify(target.value) } as PageModel);
      return;
    }
    if (target.name === 'slug') {
      // Require slug to follow slugify rules (alphanumeric, dashes, underscores, and uppercase here)
      onChange(target.name, slugify(target.value, true));
      return;
    }
    onChange(target.name, target.value);
  };

  const onCheckboxChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    onChangeMetadata(target.name, target.checked);
  };

  useEffect(() => {
    setPayload(page);
  }, [page]);

  const getProp = (prop: string, preset: unknown): unknown => {
    const data = payload?.metadata || {};
    return prop in data ? data[prop] : preset;
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth='md'
      open={open}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
    >
      <DialogTitle style={{ cursor: 'move' }}>{title}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <TextField
            fullWidth
            id='title'
            name='title'
            label='Title'
            value={payload?.title || ''}
            onChange={onTextChange}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <TextField
            fullWidth
            id='slug'
            name='slug'
            label='Slug'
            value={payload?.slug || ''}
            onChange={onTextChange}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Checkbox
                name='hideLayout'
                checked={getProp('hideLayout', false) as boolean}
                onChange={onCheckboxChange}
              />
            }
            label='Hide Layout'
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          Cancel
        </Button>
        <Button
          color='primary'
          type='submit'
          form='dialogForm'
          aria-label='save'
          onClick={() => onSubmit(payload)}
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUpdatePageDialog;
