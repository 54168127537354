import React, { useEffect, useState } from 'react';
import {
  Add as AddIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  ArrowLeft as ArrowLeftIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  InputLabel,
  ListItemButton,
  ListItemIcon,
  Tooltip,
  Typography,
} from '@mui/material';
import { Reorder } from 'framer-motion';
import { useQueryClient } from 'react-query';
import {
  getGetFeatureConfigsPublicQueryKey,
  getGetFeatureConfigsQueryKey,
  useUpdateSiteFeatureConfig,
} from '@juno/client-api';
import { JunoImage as JunoImageModel } from '@juno/client-api/fakeModel';
import {
  FeatureConfig as FeatureConfigModel,
  NavigationItem as NavigationItemModel,
  Page as PageModel,
} from '@juno/client-api/model';
import { FeatureConfig as FeatureConfigUtils } from '@juno/client-api/utils';
import { AlertDialog, JunoImageUpload } from '@juno/ui';
import { MutationAction, notification, onMutation, optimizeImage } from '@juno/utils';
import NavigationItemForm from './NavigationItemForm';
import { StyledFormControl, StyledImageInputBox } from './styles';

interface ModuleEditFormProps {
  siteId: string;
  configs: FeatureConfigModel[];
  navigationItems: NavigationItemModel[];
  pages: PageModel[];
  onSave: (payload: NavigationItemModel) => void;
  onUpdate: (payload: NavigationItemModel) => void;
  onDelete: (linkId: string) => void;
}

const HeaderEditForm: React.FC<ModuleEditFormProps> = ({
  siteId,
  configs,
  onUpdate,
  onSave,
  navigationItems,
  pages,
  onDelete,
}) => {
  const queryClient = useQueryClient();
  const [navItems, setNavItems] = useState<NavigationItemModel[]>([]);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isNavDialogOpen, setIsNavDialogOpen] = useState(false);
  const [editingNavItem, setEditingNavItem] = useState<NavigationItemModel | undefined>(undefined);
  const [elementsDragging, setElementsDragging] = useState(new Set());
  const [themeConfig, setThemeConfig] = useState<FeatureConfigModel | undefined>(undefined);
  // const debouncedPayload = useDebounce(payload, 200);

  const refetchFeatures = () => {
    queryClient.invalidateQueries(getGetFeatureConfigsPublicQueryKey(siteId));
    queryClient.invalidateQueries(getGetFeatureConfigsQueryKey(siteId));
  };
  const updateConfig = useUpdateSiteFeatureConfig(
    onMutation(MutationAction.UPDATE, 'FeatureConfig', refetchFeatures),
  );

  useEffect(() => {
    const allNavItems: NavigationItemModel[] = [];

    setNavItems(allNavItems);
  }, [navigationItems, pages]);

  useEffect(() => {
    setThemeConfig(FeatureConfigUtils.getThemeConfig(configs));
  }, [configs]);

  const handleOpenEditingNavItem = (key: string) => {
    if (elementsDragging.size > 0) return;
    const navItem = navigationItems.find((item) => item.id === key);
    if (!navItem) {
      notification.error('Navigation item not found');
      return;
    }
    setEditingNavItem(navItem);
    setIsNavDialogOpen(true);
  };
  const handleCloseEditingNavItem = () => {
    setIsNavDialogOpen(false);
    setEditingNavItem(undefined);
  };
  const handleSaveNavItem = (payload: NavigationItemModel) => {
    onSave({ ...payload, id: payload?.id || '' });
    handleCloseEditingNavItem();
  };

  const handleUpdateNavItem = (payload: NavigationItemModel) => {
    onUpdate(payload);
  };

  const handleCreateNavItem = () => {
    //onSave({ id: '', text: 'New Navigation Item', type: 2, parent_id: null, page_id: null });
  };

  const handleDeleteNavItemClicked = (navItemId: string) => {
    const navItem = navigationItems.find((item) => item.id === navItemId);
    if (!navItem) {
      notification.error('Navigation item not found');
      return;
    }
    setEditingNavItem(navItem);
    setConfirmDeleteOpen(true);
  };

  const handleConfirmDeleteLink = () => {
    if (!editingNavItem?.id) {
      notification.error('Navigation item not found');
      return;
    }
    onDelete(editingNavItem.id);
    setConfirmDeleteOpen(false);
    setEditingNavItem(undefined);
  };

  const handleDragStart = (id: string) => {
    setElementsDragging((currentElements) => currentElements.add(id));
  };

  const handleDragEnd = (id: string) => {
    setElementsDragging((currentElements) => {
      currentElements.delete(id);
      return currentElements;
    });
    // handleReorderNavItem();
  };

  const updateMedia = (selected: JunoImageModel | null, type: string) => {
    if (!themeConfig) return;
    switch (type) {
      case 'favicon':
        themeConfig.config = { ...themeConfig.config, favicon: selected?.url };
        updateConfig.mutate({ siteId, configType: themeConfig.type, data: themeConfig });
        break;
      case 'logo':
        themeConfig.config = { ...themeConfig.config, logo: selected?.url };
        updateConfig.mutate({ siteId, configType: themeConfig.type, data: themeConfig });
        break;
      default:
        break;
    }
  };

  return (
    <Box minWidth={280}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant='subtitle1'>Logo</Typography>
          <Typography variant='body2'>
            Your logo will be displayed in the top left side of the header. If it appears too small
            or large, try cropping the image first.
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign='right'>
          <StyledImageInputBox>
            <StyledFormControl>
              <Box sx={{ width: 140 }}>
                <JunoImageUpload
                  style={{ aspectRatio: '2/1', height: 70 }}
                  src={optimizeImage(
                    140,
                    themeConfig?.config?.logo ? (themeConfig?.config?.logo as string) : undefined,
                  )}
                  srcUrl={
                    themeConfig?.config?.logo ? (themeConfig?.config?.logo as string) : undefined
                  }
                  onFileUploaded={(selected) => updateMedia(selected, 'logo')}
                  iconInsteadOfText={true}
                  hoverForControls={true}
                />
              </Box>
            </StyledFormControl>
          </StyledImageInputBox>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='subtitle1'>Favicon</Typography>
          <Typography variant='body2'>
            The favicon displays on the browser tab and is usually a small square image.
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign='right'>
          <StyledImageInputBox>
            <StyledFormControl>
              <Box>
                <JunoImageUpload
                  style={{ aspectRatio: '1/1', height: 70, width: 70 }}
                  src={optimizeImage(
                    140,
                    themeConfig?.config?.favicon
                      ? (themeConfig?.config?.favicon as string)
                      : undefined,
                  )}
                  srcUrl={
                    themeConfig?.config?.favicon
                      ? (themeConfig?.config?.favicon as string)
                      : undefined
                  }
                  onFileUploaded={(selected) => updateMedia(selected, 'favicon')}
                  iconInsteadOfText={true}
                  hoverForControls={true}
                />
              </Box>
            </StyledFormControl>
          </StyledImageInputBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeaderEditForm;
