import React, { useCallback, useState } from 'react';
import { Add as AddIcon, Description as DescriptionIcon } from '@mui/icons-material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { Box, Grid, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { PreviewFile } from '@juno/client-api/fakeModel';
import { prettyFilesize } from '@juno/utils';
import ImageEditorDialog from '../ImageEditorDialog';
import { AspectRatioType } from '../constants';
import {
  EditButton,
  PreviewImage,
  PreviewMediaBox,
  PreviewVideo,
  UploadWrapperBox,
} from './styles';

const ACCEPTED_FILE_TYPES = ['image/*', 'video/*'];

interface UploadPreviewProps {
  setPreviewFiles: (files: PreviewFile[]) => void;
  previewFiles: PreviewFile[];
  dropzoneText: string;
  allowEdit: boolean;
  aspectRatios?: AspectRatioType[];
  makeInvisible?: boolean;
  replacementComponent?: JSX.Element;
}

const UploadPreview: React.FC<UploadPreviewProps> = ({
  setPreviewFiles,
  previewFiles,
  dropzoneText,
  allowEdit,
  aspectRatios,
  makeInvisible,
  replacementComponent,
}) => {
  const [editorOpen, setEditorOpen] = useState<boolean>(false);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setPreviewFiles(
        acceptedFiles.map((file) => {
          const preview_file = file as PreviewFile;
          if (file && ACCEPTED_FILE_TYPES.includes(file.type)) {
            preview_file.preview = URL.createObjectURL(file);
          }
          return preview_file;
        }),
      );
    },
    [setPreviewFiles],
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const editClick = () => setEditorOpen(true);
  const cancelCallback = () => setEditorOpen(false);
  const editCallback = (editedImage: PreviewFile) => {
    setEditorOpen(false);
    setPreviewFiles([editedImage]);
  };

  return (
    <Box>
      {previewFiles.length === 0 && !makeInvisible && (
        <UploadWrapperBox {...getRootProps()}>
          <input {...getInputProps()} />
          <AddIcon sx={{ fontSize: 60 }} />
          <Typography sx={{ fontSize: 20, fontWeight: 800 }}>Drag and Drop</Typography>
          <Typography>{dropzoneText}</Typography>
        </UploadWrapperBox>
      )}
      {previewFiles.length === 0 &&
        makeInvisible &&
        replacementComponent && { ...replacementComponent }}
      <Box>
        {previewFiles.map((file: PreviewFile, index) => (
          <Box key={index}>
            <Grid container spacing={2}>
              <Grid item>
                <Box sx={{ position: 'absolute', left: '100%' }}>
                  <CloseOutlinedIcon />
                </Box>
                <PreviewMediaBox>
                  {file.preview && file.type.search('image') >= 0 ? (
                    <PreviewImage src={file.preview} alt='' />
                  ) : (
                    <PreviewVideo src={file.preview} />
                  )}

                  {!file.preview && <DescriptionIcon fontSize='large' />}
                </PreviewMediaBox>
              </Grid>
              <Grid item xs sx={{ position: 'relative' }}>
                {!makeInvisible && (
                  <>
                    <Typography variant='body2'>{file.name}</Typography>
                    {file.size && (
                      <Typography variant='body2' color='textSecondary'>
                        {prettyFilesize(file.size)}
                      </Typography>
                    )}
                    <Typography variant='body2' color='textSecondary'>
                      {file.type}
                    </Typography>
                  </>
                )}
                {allowEdit && (
                  <EditButton onClick={editClick} variant='contained' color='primary'>
                    Edit
                  </EditButton>
                )}
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
      <ImageEditorDialog
        onCancel={cancelCallback}
        file={previewFiles[0] || null}
        open={editorOpen}
        onEdit={editCallback}
        aspectRatios={aspectRatios}
      />
    </Box>
  );
};

export default UploadPreview;
