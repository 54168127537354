import React from 'react';
import { FilterAlt } from '@mui/icons-material';
import { FilterButton as StyledFilterButton } from './styles';

interface FilterButtonProps {
  onClick: () => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({ onClick }) => (
  <StyledFilterButton variant='outlined' onClick={onClick}>
    <FilterAlt fontSize='small' color={'primary'} />
  </StyledFilterButton>
);

export default FilterButton;
