import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Avatar, Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{
  width: number;
}>(({ theme, width }) => ({
  width: '100%',
  maxWidth: 1180,
  margin: 'auto',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  // margin: `${width < 768 ? 0 : 55}px auto`,
}));

export const Title = styled(Typography)(() => ({
  marginBottom: 32,
  fontSize: 18,
  letterSpacing: 3,
  textTransform: 'uppercase',
  fontWeight: 700,
}));

export const Controls = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{
  width: number;
}>(({ width }) => ({
  display: 'flex',
  margin: `20px auto ${width < 768 ? 5 : 20}px auto`,
  gap: `0px 24px`,
}));

export const GridItemsContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'itemWidth' && prop !== 'spacing',
})<{
  itemWidth: number;
  spacing: number;
}>(({ itemWidth, spacing }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fill, ${itemWidth}px)`,
  alignItems: 'center',
  justifyContent: 'center',
  gap: `37px ${spacing}px`,
  // margin: '60px',
}));

export const GridItemContainer = styled(Box)(() => ({
  cursor: 'pointer',
  position: 'relative',
}));

export const GridItemImage = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'itemHeight' && prop !== 'itemWidth',
})<{ itemHeight: number; itemWidth: number }>(({ itemHeight, itemWidth }) => ({
  width: itemWidth,
  height: itemHeight,
  img: {
    WebkitUserDrag: 'none',
    KhmlUserDrag: 'none',
    MozUserDrag: 'none',
    OUserDrag: 'none',
    userDrag: 'none',
  },
}));

export const LockedIndicator = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'itemHeight',
})<{ itemHeight: number }>(({ itemHeight }) => ({
  position: 'absolute',
  top: itemHeight - 40,
  right: 5,
  borderRadius: '45px',
  padding: '5px 10px',
}));

export const ProgressIndicator = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'itemHeight',
})<{ itemHeight: number }>(({ itemHeight }) => ({
  position: 'absolute',
  top: itemHeight - 40,
  right: 5,
  borderRadius: '45px',
  padding: '5px 10px',
}));

export const GridItemTitle = styled(Typography)(() => ({
  marginTop: '12px',
  fontSize: '16px',
  wordBreak: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
}));

export const GridItemDescription = styled(Typography)(() => ({
  marginTop: '0px',
  fontSize: '14px',
  wordBreak: 'break-word',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  opacity: 0.5,
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
}));

export const PaymentIcon = styled(AttachMoneyIcon)(({ theme }) => ({
  background: theme.palette.primary.main,
  padding: 3,
  position: 'absolute',
  top: 7,
  left: 7,
  color: '#fff',
  borderRadius: theme.spacing(0.5),
}));
