import React, { forwardRef, useState } from 'react';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import {
  Box,
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import { Site as SiteModel, UnsubscribeGroup } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import CreateEditSubscriptionGroupDialog from '../Dialogs/CreateEditSubscriptionGroupDialog';
import DeleteSubscriptionGroupDialog from '../Dialogs/DeleteSubscriptionGroupDialog';
import GroupUnsubscribesDialog from '../Dialogs/GroupUnsubscribesDialog';
import {
  EllipsisTypography,
  TileCard,
  TileGridContainer,
  TileGridTextWrapper,
  TileWrapper,
} from '../utils/styles';

interface SubscriptionGroupTileProps {
  idx: number;
  subscriptionGroup: UnsubscribeGroup;
  handleGroupsUpdate: () => void;
}

const SubscriptionGroupTile = forwardRef<HTMLDivElement, SubscriptionGroupTileProps>(
  ({ idx, subscriptionGroup, handleGroupsUpdate }, ref) => {
    // Variables
    const { site } = useSettings();
    const [popperOpen, setPopperOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
    const [groupUnsubscribeDialogOpen, setGroupUnsubscribeDialogOpen] = useState<boolean>(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    // Functions
    const handleOpenPopper = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setPopperOpen(true);
    };
    const handleClosePopper = (event: Event | React.SyntheticEvent) => {
      setPopperOpen(false);
    };
    const handleEditGroup = () => {
      setEditDialogOpen(true);
    };
    const handleGroupUnsubscribes = () => {
      setGroupUnsubscribeDialogOpen(true);
    };
    const handleDeleteGroup = () => {
      setDeleteDialogOpen(true);
    };

    return (
      <TileWrapper ref={ref}>
        <TileGridContainer>
          <TileCard sx={{ backgroundColor: undefined }} idx={idx}>
            <TileGridTextWrapper>
              <EllipsisTypography sx={{ ml: 6, pt: 2, pb: 2 }} variant='body1'>
                {subscriptionGroup.name}
              </EllipsisTypography>
              <EllipsisTypography sx={{ ml: 3, pb: 2 }} variant='body2'>
                {subscriptionGroup.description}
              </EllipsisTypography>
            </TileGridTextWrapper>
            <Box sx={{ display: 'flex', flex: 1 }}></Box>
            <TileGridTextWrapper m={1}>
              <Box sx={{ display: 'flex' }}>
                <IconButton sx={{ mr: 3 }} onClick={handleOpenPopper}>
                  <MoreVertIcon />
                </IconButton>
                <Popper
                  open={popperOpen}
                  placement='bottom-end'
                  sx={{ zIndex: 5 }}
                  anchorEl={anchorEl}
                >
                  <Paper
                    sx={{ border: '1px solid', borderColor: 'primary.main', borderRadius: '8px' }}
                  >
                    <ClickAwayListener onClickAway={handleClosePopper}>
                      <MenuList autoFocusItem={popperOpen} onKeyDown={() => {}}>
                        {/* We only support predefined groups at this time, so let's not edit them */}
                        {/*<MenuItem sx={{ fontSize: '14px' }} onClick={handleEditGroup}>*/}
                        {/*  <EditIcon*/}
                        {/*    style={{ fontSize: '16px' }}*/}
                        {/*    sx={{ color: 'primary.main', mr: 1 }}*/}
                        {/*  />*/}
                        {/*  Edit Group*/}
                        {/*</MenuItem>*/}
                        <MenuItem sx={{ fontSize: '14px' }} onClick={handleGroupUnsubscribes}>
                          <UnsubscribeIcon
                            style={{ fontSize: '16px' }}
                            sx={{ color: 'primary.main', mr: 1 }}
                          />
                          Group Unsubscribes
                        </MenuItem>
                        {/* We only support predefined groups at this time, so let's not delete them */}
                        {/*<MenuItem sx={{ fontSize: '14px' }} onClick={handleDeleteGroup}>*/}
                        {/*  <DeleteIcon*/}
                        {/*    style={{ fontSize: '16px' }}*/}
                        {/*    sx={{ color: 'primary.main', mr: 1 }}*/}
                        {/*  />*/}
                        {/*  Delete Group*/}
                        {/*</MenuItem>*/}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Popper>
              </Box>
            </TileGridTextWrapper>
          </TileCard>
        </TileGridContainer>
        {editDialogOpen && (
          <CreateEditSubscriptionGroupDialog
            handleClose={() => {
              setEditDialogOpen(false);
            }}
            handleGroupsUpdate={handleGroupsUpdate}
            unsubscribeGroup={subscriptionGroup}
          />
        )}
        {groupUnsubscribeDialogOpen && (
          <GroupUnsubscribesDialog
            groupId={subscriptionGroup.id}
            groupName={subscriptionGroup.name}
            handleClose={() => {
              setGroupUnsubscribeDialogOpen(false);
            }}
          />
        )}
        {deleteDialogOpen && (
          <DeleteSubscriptionGroupDialog
            handleClose={() => {
              setDeleteDialogOpen(false);
            }}
            unsubcribeGroup={subscriptionGroup}
            handleDeleteGroup={handleGroupsUpdate}
          />
        )}
      </TileWrapper>
    );
  },
);

export default SubscriptionGroupTile;
