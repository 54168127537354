/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type StatusD4cEnum = typeof StatusD4cEnum[keyof typeof StatusD4cEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusD4cEnum = {
  Needs_Review: 'Needs Review',
  Moderated: 'Moderated',
  Ignored: 'Ignored',
  Restored: 'Restored',
} as const;
