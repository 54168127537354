/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type ProgressModeEnum = typeof ProgressModeEnum[keyof typeof ProgressModeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProgressModeEnum = {
  allow: 'allow',
  prevent_on_fail: 'prevent_on_fail',
  prevent_with_retakes: 'prevent_with_retakes',
} as const;
