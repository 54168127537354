import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  DialogActions,
  DialogContent,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getGetAllSpeakersQueryKey, useCreateSpeaker, useGetAllSpeakers } from '@juno/client-api';
import { GridV2 } from '@juno/modules';
import { Container, DefaultSearchSortFilter, DialogAriaWrapper } from '@juno/ui';
import { slugify, useSessionsSearchFilter, useSettings } from '@juno/utils';

const SpeakersAdmin: React.FC = () => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { site } = useSettings();
  const [, , searchFilter, setSearch] = useSessionsSearchFilter();
  const [slugError, setSlugError] = useState(false);
  const [sort, setSort] = useState('-date_created');
  const navigate = useNavigate();
  const { data: speakers, isLoading } = useGetAllSpeakers(
    site?.id || '',
    {},
    {
      query: {
        enabled: !!site?.id,
      },
    },
  );
  const createSpeaker = useCreateSpeaker();
  const queryClient = useQueryClient();

  const handleSaveNewSpeaker = async (name: string, slug: string) => {
    setIsSaving(true);
    try {
      await createSpeaker.mutateAsync({
        siteId: site?.id || '',
        data: { name: name, id: '', slug: slug, tags: [], image: '' },
      });
      setIsSaving(false);
      setCreateDialogOpen(false);
      await queryClient.invalidateQueries(getGetAllSpeakersQueryKey(site?.id || ''));
      navigate(`/${site?.slug}/admin/speakers/${slug}`);
    } catch (e) {
      console.error(e);
      setIsSaving(false);
      setCreateDialogOpen(false);
    }
  };

  const gridData = speakers?.map(({ id, name, preview_text, image, slug }) => ({
    id: id || '',
    title: name || '',
    abstract: preview_text || '',
    icon: image || '',
    slug: slug || '',
  }));

  return (
    <Container>
      <Card sx={{ p: 2, mb: 4, boxShadow: (theme) => theme.shadows[1] }}>
        <Stack direction='row' justifyContent='space-between'>
          <Typography variant='overline' sx={{ mb: 1 }}>
            Speakers
          </Typography>
        </Stack>
        <Typography variant='body2' sx={{ mb: 5, mt: 1, maxWidth: 900 }}>
          Manage the speakers on your platform.
        </Typography>
        <DefaultSearchSortFilter
          buttonDisabled={false}
          buttonText={'New Speaker'}
          onClickButton={() => setCreateDialogOpen(true)}
          setSearch={(value: string) => setSearch(value)}
          setSort={setSort}
          sort={sort}
          showFilter={false}
          sortOptions={[
            { value: 'title', label: 'Alphabetical' },
            { value: '-date_created', label: 'Date Created' },
          ]}
        />
        <Stack direction='row' flexWrap={'wrap'} gap={2}>
          <GridV2 isLoading={isLoading} items={gridData || []} type='speaker' isAdmin={true} />
        </Stack>
      </Card>
      <DialogAriaWrapper open={createDialogOpen} onClose={() => setCreateDialogOpen(false)}>
        <Formik
          key={'access-pass-form'}
          enableReinitialize={true}
          initialValues={{ name: '', slug: '' }}
          onSubmit={async (values, { setSubmitting }) => {
            await handleSaveNewSpeaker(values.name, values.slug);
            setSubmitting(false);
          }}
        >
          {({ setValues, values }) => {
            return (
              <Form>
                <DialogContent>
                  <Typography variant='h6' gutterBottom>
                    Create New Speaker
                  </Typography>
                  <TextField
                    size='small'
                    label='Speaker Name'
                    fullWidth
                    sx={{ mt: 2 }}
                    value={values.name}
                    onChange={(e) => {
                      setValues({ name: e.target.value, slug: slugify(e.target.value) });
                      if (speakers?.find((s) => s.slug === slugify(e.target.value))) {
                        setSlugError(true);
                      } else {
                        setSlugError(false);
                      }
                    }}
                  />
                  <TextField
                    value={values.slug}
                    size='small'
                    label='Speaker Slug'
                    fullWidth
                    error={slugError}
                    helperText={slugError ? 'Slug already exists' : ''}
                    onChange={(e) => {
                      setValues({ ...values, slug: slugify(e.target.value) });
                      if (speakers?.find((s) => s.slug === slugify(e.target.value))) {
                        setSlugError(true);
                      } else {
                        setSlugError(false);
                      }
                    }}
                    sx={{ mt: 2 }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setCreateDialogOpen(false)} color='primary'>
                    Cancel
                  </Button>
                  <LoadingButton
                    variant='contained'
                    loading={isSaving}
                    type={'submit'}
                    disabled={slugError || !values.name || !values.slug}
                  >
                    Create
                  </LoadingButton>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </DialogAriaWrapper>
    </Container>
  );
};

export default SpeakersAdmin;

export const SkeletonPagesTile: React.FC = () => {
  return (
    <Grid item xs={12} md={3} lg={2}>
      <Skeleton variant='rectangular' height={160} width={235} sx={{ borderRadius: 2 }} />
      <Skeleton variant='text' height={20} sx={{ mt: 1, width: '50%' }} />
      <Skeleton variant='text' height={16} sx={{ width: '40%' }} />
    </Grid>
  );
};
