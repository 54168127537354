import Pubnub from 'pubnub';
import PubNub, { PublishResponse } from 'pubnub';
import { v4 as uuidv4 } from 'uuid';
import { Message } from '@juno/utils';

export const getPubNubChannel = async (user_ids: string[], pubnub: Pubnub, platformId: string) => {
  let channelExists = false;
  let commonChannels = [];

  // 1. Get the channels for each of the users from PubNub
  try {
    const allChannels = await Promise.all(
      user_ids.map((user_id) =>
        pubnub.objects.getMemberships({
          uuid: user_id,
          include: { channelFields: true, customFields: true, customChannelFields: true },
        }),
      ),
    );

    interface CustomChannelMetadataObject {
      channel: {
        id: string;
        custom?: {
          platformId?: string;
          // Include other custom properties here as needed
        };
      };
    }

    // Extracting channel IDs from memberships
    const userChannels = allChannels.map((channels) =>
      channels.data
        .filter(
          (channel) =>
            (channel as unknown as CustomChannelMetadataObject).channel.custom?.platformId ===
            platformId, // Use the interface to access custom properties
        )
        .map((channel) => (channel as unknown as CustomChannelMetadataObject).channel.id),
    );

    // 2. Find the intersection of the channels
    commonChannels = userChannels.reduce((a, b) => a.filter((c) => b.includes(c)));

    // 3. Iterate through the channels and find the one that has the same number of members as the number of users we're looking for
    for (const channel of commonChannels) {
      const members = await pubnub.objects.getChannelMembers({ channel: channel });
      if (members.data.length === user_ids.length) {
        members.data.forEach((member) => {
          setChannelVisible({ pubnub, channelId: channel, userId: member.uuid.id, hide: false });
        });
        channelExists = true;
        return channel; // Found the suitable channel
      }
    }
  } catch (error) {
    console.error('Error in getting channels:', error);
    return null;
  }

  // 4. If we don't find a channel, create a new one using the previously updated createChannelAddMembers function
  if (!channelExists) {
    // Ensure createChannelAddMembers is correctly updated to accept platformId and handle it
    return createChannelAddMembers(user_ids, pubnub, platformId); // Assuming createChannelAddMembers accepts platformId as the last argument
  }
};

const createChannelAddMembers = async (
  user_ids: string[],
  pubnub: Pubnub,
  platformId: string,
  channelName?: string,
) => {
  // Generate a unique channel name
  const newChannelName = channelName ? channelName : uuidv4();

  try {
    // Create a new channel with platformId in the metadata if provided
    const channelMetadata = {
      channel: newChannelName,
      data: {
        name: channelName ? channelName : newChannelName, // Fallback to newChannelName if channelName is not provided
        description: 'Juno Messenger: Conversation Channel',
        custom: {
          platformId: platformId,
        },
        // Include other necessary metadata properties here
      },
    };
    console.log('Creating channel with metadata:', channelMetadata);
    const res = await pubnub.objects.setChannelMetadata(channelMetadata);
    console.log('Channel created:', res);
    // Add users as members
    await pubnub.objects.setChannelMembers({
      channel: newChannelName,
      uuids: user_ids, // Directly pass the array of user_ids (strings)
    });

    return newChannelName;
  } catch (error) {
    console.error('Error creating channel with members:', error);
    return null;
  }
};

export const getPubNubChannelMembers = async (channel: string, pubnub: Pubnub) => {
  try {
    const members = await pubnub.objects.getChannelMembers({ channel: channel });
    return members.data;
  } catch (error) {
    console.error('Error getting channel members:', error);
    return null;
  }
};

export type PubnubUUID = {
  uuid: string;
};

export type ChannelMembersMap = {
  [channel: string]: PubnubUUID[];
};

export const getPubNubChannelMembersForMultipleChannels = async (
  channels: string[],
  pubnub: Pubnub,
): Promise<ChannelMembersMap | null> => {
  try {
    const promises = channels.map((channel) =>
      pubnub.objects
        .getChannelMembers({ channel: channel })
        .then((response) => ({
          channel,
          members: response.data,
        }))
        .catch((error) => {
          console.error(`Error getting members for channel ${channel}:`, error);
          return { channel, members: null };
        }),
    );

    const results = await Promise.all(promises);

    const membersByChannel = results.reduce<ChannelMembersMap>((acc, { channel, members }) => {
      // Correctly mapping members to the structure of PubnubUUID
      const uuids: PubnubUUID[] = members
        ? members.map((member: any): PubnubUUID => ({ uuid: member.uuid.id }))
        : [];

      acc[channel] = uuids;
      return acc;
    }, {} as ChannelMembersMap);

    return membersByChannel;
  } catch (error) {
    console.error('Error getting channel members for multiple channels:', error);
    return null; // or throw error; depending on how you want to handle the error
  }
};

export interface SendMessageParams {
  pubnub: PubNub;
  channel: string;
  message: string | object; // Allow both string and object types
}

export const sendMessage = async ({
  pubnub,
  channel,
  message,
}: SendMessageParams): Promise<PublishResponse> => {
  try {
    if (!channel) {
      throw new Error('Channel name is required');
    }

    // Ensure that the message is in the correct format
    const messageToSend = typeof message === 'string' ? { text: message, id: uuidv4() } : message;

    const response = await pubnub.publish({
      channel,
      message: messageToSend,
    });

    return response;
  } catch (error) {
    console.error('Failed to send message:', error);
    throw error; // Re-throw the error to handle it in the calling context
  }
};

export const filterOutNonUUIDs = (user_ids: string[]) => {
  return user_ids.filter((user_id) => user_id && user_id.length === 36);
};

export const getMessagesFromChannel = async (
  channel: string,
  pubnub: Pubnub,
  numberOfMessages = 1,
  userId: string,
  chat: any,
): Promise<Message[] | null> => {
  // Change to Promise<Message[] | null>
  try {
    const response = await pubnub.fetchMessages({
      channels: [channel],
      includeMessageActions: true,
      includeMeta: true,
      count: numberOfMessages,
    });

    // Check the channel membership and use the last message's timetoken to mark messages as read or unread
    const user = await chat.getUser(userId);

    // Get the list of all user memberships and filter out the right channel
    const membership = await user.getMemberships({
      filter: `channel.id == '${channel}'`,
    });

    const filteredMembership = membership.memberships.filter((m: any) => m.channel.id === channel);

    if (response.channels[channel] && response.channels[channel].length > 0) {
      const fetchedMessages = response.channels[channel];
      return fetchedMessages.map((m) => {
        const message: Message = {
          text: m.message.text,
          createdAt: m.timetoken.toString(),
          userId: m.uuid || '',
          isNewMessage: filteredMembership[0].lastReadMessageTimetoken < m.timetoken,
        };
        return message;
      });
    } else {
      return null; // Return null if no messages are found
    }
  } catch (error) {
    console.error('error', error);
    return null; // Return null in case of an error
  }
};

export interface ChannelToUnreadMessageCountMap {
  [channel: string]: number;
}

export interface MarkAsReadParams {
  pubnub: PubNub;
  channel: string;
  userId: string;
  chat: any; // Replace with the actual type of chat if available
  timetoken?: string; // Make timetoken optional
}

export const markAllMessagesAsRead = async ({
  pubnub,
  channel,
  userId,
  chat,
  timetoken,
}: MarkAsReadParams): Promise<void> => {
  let finalTimetoken: string | undefined | number = timetoken;

  // If timetoken is not provided, fetch the latest message's timetoken
  if (!finalTimetoken) {
    const response = await pubnub.fetchMessages({
      channels: [channel],
      count: 1,
    });
    const latestMessage = response.channels[channel]?.[0];
    finalTimetoken = latestMessage?.timetoken;
  }

  const user = await chat.getUser(userId);

  // Get the list of all user memberships and filter out the right channel
  const membership = await user.getMemberships({
    filter: `channel.id == '${channel}'`,
    include: { channelFields: true, customFields: true, customChannelFields: true },
  });

  const filteredMembership = membership.memberships.filter((m: any) => m.channel.id === channel);

  try {
    // Assuming a single membership which matches the filter
    filteredMembership.forEach((m: any) => {
      m.setLastReadMessageTimetoken(finalTimetoken).then((res: any) => {});
    });
  } catch (error) {
    console.error('Error setting last read message:', error);
    throw error; // Re-throw to allow the caller to handle the error
  }
};

export interface ChannelMeta {
  channelId: string;
  channelName: string;
  channelDescription?: string;
  channelUpdated: string;
  isHidden?: boolean;
}

interface FetchMembershipsOptions {
  pubnub: PubNub;
  uuid: string;
  limit?: number;
}

export const fetchAllMemberships = async (
  options: FetchMembershipsOptions,
): Promise<ChannelMeta[]> => {
  const { pubnub, uuid, limit = 100 } = options;
  let memberships: ChannelMeta[] = [];
  let pageToken: string | undefined;
  try {
    const response = await pubnub.objects.getMemberships({
      uuid,
      limit,
      sort: { updated: 'desc' },
      include: {
        totalCount: true,
        customFields: true,
        channelFields: true,
      },
      page: pageToken ? { next: pageToken } : undefined,
    });

    const fetchedMemberships = response.data;

    memberships = [
      ...memberships,
      ...fetchedMemberships.map((m: any) => ({
        channelId: m.channel.id,
        channelName: m.channel.name,
        channelDescription: m.channel.description,
        channelUpdated: m.channel.updated,
        isHidden: m.custom?.isHidden,
      })),
    ];

    return memberships;
  } catch (error) {
    console.error('Error fetching memberships:', error);
    throw error;
  }
};

export const UNKNOWN_TIME = 'Unknown time';

export const getTimeSince = (epoch: string | null, unknownTimeDefault = ''): string => {
  if (epoch === null) {
    return 'Unknown';
  }

  if (epoch === UNKNOWN_TIME) {
    return unknownTimeDefault;
  }

  const seconds = Math.floor(
    (new Date().getTime() - new Date((parseInt(epoch, 10) / 10000000) * 1000).getTime()) / 1000,
  );

  // Ensure seconds is never negative
  if (seconds < 0) {
    return 'now'; // Or any other default message you'd like to show for future dates
  }

  let interval = seconds / 31536000;
  if (interval > 1) {
    return Math.floor(interval) + 'y';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + 'm';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + 'd';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + 'h';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + 'm';
  }
  return Math.floor(seconds) + 's';
};

// Define a type for the system message regarding channel updates
export type SystemChannelMessage = {
  type: 'ADD_TO_CHANNEL' | 'REMOVE_FROM_CHANNEL';
  userIds: string[];
  channelId: string;
};

// Define a type for the action to be performed based on the system message
export type ChannelAction = {
  actionType: 'SUBSCRIBE_TO_CHANNEL' | 'UNSUBSCRIBE_FROM_CHANNEL';
  channelId: string;
};

// Define the type for the function that will process the system message
export type ProcessSystemMessage = (
  message: SystemChannelMessage,
  myUserId: string,
) => ChannelAction | null;

export const processSystemMessage: ProcessSystemMessage = (message, myUserId) => {
  switch (message.type) {
    case 'ADD_TO_CHANNEL':
      if (message.userIds.includes(myUserId)) {
        // The current user is added to a new channel
        return {
          actionType: 'SUBSCRIBE_TO_CHANNEL',
          channelId: message.channelId,
        };
      }
      break;
    case 'REMOVE_FROM_CHANNEL':
      if (message.userIds.includes(myUserId)) {
        // The current user is removed from a channel
        return {
          actionType: 'UNSUBSCRIBE_FROM_CHANNEL',
          channelId: message.channelId,
        };
      }
      break;
    default:
      console.log('Unknown system message type.');
  }
  return null;
};

export interface SetChannelVisibilityParams {
  pubnub: Pubnub;
  channelId: string;
  userId: string;
  hide?: boolean;
}

export const setChannelVisible = async ({
  pubnub,
  channelId,
  userId,
  hide = true, // By default, we hide the channel; set to false to unhide
}: SetChannelVisibilityParams): Promise<boolean> => {
  try {
    // Fetch current memberships to preserve other metadata
    const memberships = await pubnub.objects.getMemberships({
      uuid: userId,
      include: { channelFields: true, customFields: true, customChannelFields: true },
    });
    const membership = memberships.data.find((m) => m.channel.id === channelId);

    if (!membership) {
      console.error(`Membership not found for user ${userId} in channel ${channelId}`);
      return false;
    }

    // Set isHidden based on the hide parameter in the membership's custom data
    await pubnub.objects.setMemberships({
      uuid: userId,
      channels: [
        {
          id: channelId,
          custom: {
            ...membership.custom, // Preserve other custom data
            isHidden: hide, // Set isHidden based on the hide parameter
          },
        },
      ],
    });

    return true; // Return true to indicate the operation was successful
  } catch (error) {
    console.error(
      `Error ${hide ? 'hiding' : 'unhiding'} channel ${channelId} for user ${userId}:`,
      error,
    );
    return false; // Return false to indicate the operation failed
  }
};
