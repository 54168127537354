import React, { useCallback, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs, { Dayjs } from 'dayjs';

enum TimeFrame {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  LAST_7 = 'Last 7 Days',
  LAST_30 = 'Last 30 Days',
  LAST_90 = 'Last 90 Days',
  YTD = 'Year to Date',
  CUSTOM = 'Custom',
}

const defaultTimeFrame = [
  {
    title: TimeFrame.TODAY,
    dates: {
      start: dayjs().startOf('date'),
      end: dayjs().endOf('date'),
    },
  },
  {
    title: TimeFrame.YESTERDAY,
    dates: {
      start: dayjs().startOf('date').subtract(1, 'day'),
      end: dayjs().endOf('date'),
    },
  },
  {
    title: TimeFrame.LAST_7,
    dates: {
      start: dayjs().startOf('date').subtract(7, 'day'),
      end: dayjs().endOf('date'),
    },
  },
  {
    title: TimeFrame.LAST_30,
    dates: {
      start: dayjs().startOf('date').subtract(30, 'day'),
      end: dayjs().endOf('date'),
    },
  },
  {
    title: TimeFrame.LAST_90,
    dates: {
      start: dayjs().startOf('date').subtract(90, 'day'),
      end: dayjs().endOf('date'),
    },
  },
  {
    title: TimeFrame.YTD,
    dates: {
      start: dayjs().startOf('year'),
      end: dayjs().endOf('date'),
    },
  },
];

export interface DefaultDatePickerProps {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  timeFrame: string;
  setStartDate: (setter: Dayjs | null) => void;
  setEndDate: (setter: Dayjs | null) => void;
  setTimeFrame: (setter: string) => void;
}

export const DefaultDatePicker: React.FC<DefaultDatePickerProps> = ({
  startDate,
  endDate,
  timeFrame,
  setStartDate,
  setEndDate,
  setTimeFrame,
}) => {
  const handleDateChange = (event: SelectChangeEvent) => {
    const timeFrameValue = event.target.value as string;
    switch (timeFrameValue) {
      case TimeFrame.TODAY: {
        setStartDate(dayjs().startOf('day'));
        setEndDate(dayjs().endOf('day'));
        handleSetTimeFrame();
        break;
      }
      case TimeFrame.YESTERDAY: {
        setStartDate(dayjs().startOf('day').subtract(1, 'day'));
        setEndDate(dayjs().endOf('day'));
        handleSetTimeFrame();
        break;
      }
      case TimeFrame.LAST_7: {
        setStartDate(dayjs().startOf('day').subtract(7, 'day'));
        setEndDate(dayjs().endOf('day'));
        handleSetTimeFrame();
        break;
      }
      case TimeFrame.LAST_30: {
        setStartDate(dayjs().startOf('day').subtract(30, 'day'));
        setEndDate(dayjs().endOf('day'));
        handleSetTimeFrame();
        break;
      }
      case TimeFrame.LAST_90: {
        setStartDate(dayjs().startOf('day').subtract(90, 'day'));
        setEndDate(dayjs().endOf('day'));
        handleSetTimeFrame();
        break;
      }
      case TimeFrame.YTD: {
        setStartDate(dayjs().startOf('year'));
        setEndDate(dayjs().endOf('day'));
        handleSetTimeFrame();
        break;
      }
    }
  };

  const handleSetTimeFrame = useCallback(() => {
    for (let i = 0; i < defaultTimeFrame.length; i++) {
      if (
        startDate?.valueOf() === defaultTimeFrame[i].dates.start.valueOf() &&
        endDate?.valueOf() === defaultTimeFrame[i].dates.end.valueOf()
      ) {
        setTimeFrame(defaultTimeFrame[i].title);
        return;
      }
      setTimeFrame('Custom');
    }
  }, [startDate, endDate, setTimeFrame]);

  useEffect(() => {
    handleSetTimeFrame();
  }, [startDate, endDate, handleSetTimeFrame]);

  return (
    <Grid container spacing={2}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id='timeFrame-select-label'>Time Frame</InputLabel>
            <Select
              fullWidth
              id='timeFrameSelect'
              value={timeFrame}
              label='Time Frame'
              onChange={handleDateChange}
            >
              <MenuItem value={TimeFrame.TODAY}>Today</MenuItem>
              <MenuItem value={TimeFrame.YESTERDAY}>Yesterday</MenuItem>
              <MenuItem value={TimeFrame.LAST_7}>Last 7 days</MenuItem>
              <MenuItem value={TimeFrame.LAST_30}>Last 30 Days</MenuItem>
              <MenuItem value={TimeFrame.LAST_90}>Last 90 Days</MenuItem>
              <MenuItem value={TimeFrame.YTD}>Year to Date</MenuItem>
              <MenuItem value={TimeFrame.CUSTOM} sx={{ display: 'none' }}>
                Custom
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DesktopDatePicker
            // disabled
            label='Start Date'
            inputFormat='MM/dd/yyyy'
            value={startDate}
            PopperProps={{
              disablePortal: true,
            }}
            onChange={setStartDate}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DesktopDatePicker
            // disabled
            label='End Date'
            inputFormat='MM/dd/yyyy'
            value={endDate}
            PopperProps={{
              disablePortal: true,
            }}
            onChange={setEndDate}
            renderInput={(params) => <TextField fullWidth {...params} />}
          />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};
