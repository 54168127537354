import React from 'react';
import { fireEvent, render } from '@testing-library/react';
import userEvent from '@testing-library/user-event';
import { BrowserRouter as Router } from 'react-router-dom';

interface RouterProps {
  children?: React.ReactNode;
}

const BrowserRouter = ({ children }: RouterProps) => <Router>{children}</Router>;

export const renderWithRouter = (
  ui: React.ReactElement,
  { route = '/' } = {},
): ReturnType<typeof render> => {
  window.history.pushState({}, 'Test page', route);

  return render(ui, { wrapper: BrowserRouter });
};

export function editMuiDatePicker(element: HTMLElement, date: string): void {
  fireEvent.focus(element);
  userEvent.type(element, date);
  fireEvent.change(element, {
    target: { value: date },
  });
  fireEvent.blur(element);
}
