import { Box, Card, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GradedIndicator = styled(Typography)(() => ({
  display: 'flex',
  border: 'solid 1px #000',
  borderRadius: '16px',
  padding: '4px 8px',
}));

export const NoLessonsFound = styled(Box)(() => ({
  textAlign: 'center',
  userSelect: 'none',
}));
