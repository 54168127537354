import React, { useState } from 'react';
import MicIcon from '@mui/icons-material/Mic';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import { Box, Card, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { SearchContentTypeEnum } from '@juno/client-api/model';
import { UserQuickView } from '@juno/ui';
import { getContentRoute, useSettings } from '@juno/utils';
import { Aside } from '../../components/Aside';
import { PEOPLE_ASIDE } from '../../constants';
import { useCallState } from '../../contexts/CallProvider';
import { useParticipants } from '../../contexts/ParticipantsProvider';
import { useUIState } from '../../contexts/UIStateProvider';

const PersonRow = ({ participant, isOwner = false, index, onClick }) => (
  <Box
    display={'flex'}
    justifyContent={'space-between'}
    mb={2}
    bgcolor={index % 2 === 0 ? 'rgba(0, 0, 0, 0.07)' : 'transparent'}
    borderRadius={2}
    p={'4px'}
  >
    <Typography
      variant='subtitle2'
      onClick={(e) => onClick(e, participant)}
      sx={{ cursor: 'pointer' }}
    >
      {participant.name} {participant.isLocal && '(You)'}
    </Typography>
    <Box display={'flex'} gap={1}>
      {participant.isMicMuted ? <MicOffOutlinedIcon /> : <MicIcon />}
      {participant.isCamMuted ? <VideocamOffOutlinedIcon /> : <VideocamIcon />}
    </Box>
  </Box>
);
PersonRow.propTypes = {
  participant: PropTypes.object,
  isOwner: PropTypes.bool,
};

export const PeopleAside = () => {
  const { callObject } = useCallState();
  const { showAside, setShowAside } = useUIState();
  const { participants, isOwner } = useParticipants();
  const [userQuickViewAnchorEl, setUserQuickViewAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigateRoute = useNavigate();
  const { site } = useSettings();

  const onClick = async (e, participant) => {
    setSelectedUser(participant.userData.userId);
    setUserQuickViewAnchorEl(e.target);
  };

  if (!showAside || showAside !== PEOPLE_ASIDE) {
    return null;
  }

  return (
    <Aside onClose={() => setShowAside(false)}>
      <Box position={'relative'} height={'100%'}>
        <Card sx={{ background: '#5e5a63', color: 'white', textAlign: 'center' }}>
          <Typography variant='subtitle2' sx={{ fontSize: '10px', p: 1 }}>
            Currently in the room
          </Typography>
        </Card>
        {/* {isOwner && (
          //TODO
          <div className="owner-actions">
            <Button
              fullWidth
              size="tiny"
              variant="outline-gray"
              onClick={() =>
                callObject.updateParticipants({ '*': { setAudio: false } })
              }
            >
              Mute all mics
            </Button>
            <Button
              fullWidth
              size="tiny"
              variant="outline-gray"
              onClick={() =>
                callObject.updateParticipants({ '*': { setVideo: false } })
              }
            >
              Mute all cams
            </Button>
          </div>
        )} */}
        <Box pt={2}>
          {participants.map((p, i) => (
            <PersonRow participant={p} key={p.id} isOwner={isOwner} index={i} onClick={onClick} />
          ))}
        </Box>
        <UserQuickView
          anchorEl={userQuickViewAnchorEl}
          userId={selectedUser || ''}
          open={Boolean(userQuickViewAnchorEl)}
          setAnchorEl={setUserQuickViewAnchorEl}
          onViewProfile={(userEmail) => {
            navigateRoute(
              getContentRoute(site?.slug || '', selectedUser || '', SearchContentTypeEnum.user),
            );
          }}
          onMessageUser={function (userId) {
            throw new Error('Function not implemented.');
          }}
        />
      </Box>
    </Aside>
  );
};

export default PeopleAside;
