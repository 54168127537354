import {
  DayOfWeekEnum as DOW,
  EmailCampaign as EmailCampaignModel,
  Schedule,
  Site as SiteModel,
  Tag as TagModel,
  FrequencyEnum as frequencyOptions,
} from '@juno/client-api/model';
import { CAMPAIGN_EMAIL_FROM_SUFFIX } from '@juno/constants';

const ordinal_suffix_of = (num: number) => {
  const j = num % 10,
    k = num % 100;
  if (j === 1 && k !== 11) {
    return num + 'st';
  }
  if (j === 2 && k !== 12) {
    return num + 'nd';
  }
  if (j === 3 && k !== 13) {
    return num + 'rd';
  }
  return num + 'th';
};

const ordinalDatePartValues = () => {
  const values = [];
  for (let i = 1; i <= 28; i++) {
    values.push({ value: i, label: ordinal_suffix_of(i) });
  }
  return values;
};

const getDailySchedule = (time: string, schedule: Schedule, untilString: string) => {
  // No point in displaying a DAILY schedule string until a start date and day offset are selected
  const noString = !schedule.start_date || !schedule.day_offset;
  if (noString) {
    return 'No schedule defined';
  }
  // Every day schedule is singular, else plural
  const dayOrDays = schedule.day_offset == 1 ? 'day' : `${schedule.day_offset} days`;
  const formattedStartDate = getScheduleStartDate(schedule.start_date);
  // Base return string
  const returnString = `Recurring - Every ${dayOrDays}`;
  // If a full schedule has been selected, include the time else return the base schedule string
  return time
    ? `${returnString} at ${time} starting ${formattedStartDate} ${untilString}`
    : `${returnString} starting ${formattedStartDate} ${untilString}`;
};

const getWeeklySchedule = (time: string, schedule: Schedule, untilString: string) => {
  // No point in displaying a WEEKLY schedule string until a start date has been selected
  const noString = !schedule.start_date;
  if (noString) {
    return 'No schedule defined';
  }
  // Return string when a day of week hasn't been selected
  const formattedStartDate = getScheduleStartDate(schedule.start_date);
  if (schedule.day_of_week === DOW.Null || !schedule.day_of_week) {
    return `Recurring - Weekly starting ${formattedStartDate} ${untilString}`;
  }
  const dayOfWeek = schedule.day_of_week.charAt(0).toUpperCase() + schedule.day_of_week.slice(1);
  // Base return string
  const returnString = `Recurring - Weekly every ${dayOfWeek}`;
  return time
    ? `${returnString} at ${time} starting ${formattedStartDate} ${untilString}`
    : `${returnString} starting ${formattedStartDate} ${untilString}`;
};

const getMonthlySchedule = (time: string, schedule: Schedule, untilString: string) => {
  // No point in displaying a MONTHLY schedule string until a start date has been selected
  if (!schedule.start_date) {
    return 'No schedule defined';
  }
  const formattedStartDate = getScheduleStartDate(schedule.start_date);
  // Handle MONTHLY case # 1
  if (!schedule.date_part) {
    if (schedule.week_offset && schedule.day_of_week !== DOW.Null) {
      const returnString = `Recurring - The ${ordinal_suffix_of(schedule.week_offset)} ${
        schedule.day_of_week
      } of every month `;
      return time
        ? `${returnString} at ${time} starting ${formattedStartDate} ${untilString}`
        : `${returnString} starting ${formattedStartDate} ${untilString}`;
    }
    // Handle MONTHLY case # 2
  } else if (schedule.date_part) {
    const returnString = `Recurring - The ${ordinal_suffix_of(schedule.date_part)} of every month `;
    return time
      ? `${returnString} at ${time} starting ${formattedStartDate} ${untilString}`
      : `${returnString} starting ${formattedStartDate} ${untilString}`;
  } else {
    return 'No schedule defined';
  }
};

const getSchedule = (
  start_date: string,
  time: string,
  schedule: Schedule,
  automaticString: string,
  customString: string,
) => {
  if (!schedule) {
    return `No schedule defined`;
  }
  switch (schedule.frequency) {
    case frequencyOptions.DAILY:
      return getDailySchedule(time, schedule, customString);
    case frequencyOptions.WEEKLY:
      return getWeeklySchedule(time, schedule, customString);
    case frequencyOptions.MONTHLY:
      return getMonthlySchedule(time, schedule, customString);
    case frequencyOptions.AUTOMATIC:
      return automaticString;
    case frequencyOptions.ONE_TIME:
      if (!schedule.start_date) {
        return 'No schedule defined';
      }
      return `One time - ${start_date}${time ? ` at ${time}` : ''}`;
    default:
      return `No schedule defined`;
  }
};

const getScheduleStartDate = (start_date?: Date | null | string) => {
  if (start_date) {
    return new Date(start_date).toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    });
  } else {
    return 'MM/DD/YYY';
  }
};

const getScheduleEndDate = (end_date?: Date | null | string) => {
  if (end_date) {
    return new Date(end_date).toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    });
  } else {
    return null;
  }
};

const getScheduleTime = (time?: Date | null | string) => {
  if (time) {
    return new Date(time).toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  } else {
    return 'HH:MM am/pm';
  }
};

const getScheduleString = (schedule: Schedule | undefined) => {
  if (schedule) {
    const { time, start_date, end_date } = schedule;

    // No schedule defined
    if (!time && !start_date && !end_date) {
      return `No schedule defined`;
    }

    // Check date and time info
    const startDate = getScheduleStartDate(start_date);
    const endDate = getScheduleEndDate(end_date);
    const timeToDisplay = time ? getScheduleTime(time) : '';

    // AUTOMATIC frequency campaign schedule strings are very straightforward
    const automaticString = endDate
      ? `Automatic - Starts ${startDate} and Expires ${endDate}`
      : `Automatic - Starts ${startDate} and never expires`;

    // CUSTOM frequency campaign schedule strings
    const customString = endDate ? `until ${endDate}` : `and never expires`;

    // Every campaign requires a start_date
    if (!startDate) {
      return `No schedule defined`;
    }

    // Get the frequency-specific schedule string
    return getSchedule(startDate, timeToDisplay, schedule, automaticString, customString);
  }
  return 'No schedule defined';
};

const tag_logic = (has_all: boolean | undefined) => {
  if (has_all) {
    return 'All';
  } else {
    return 'Any';
  }
};

const getStatus = (is_active?: boolean | null | undefined, end_date?: Date | null | string) => {
  if (is_active && end_date && end_date < new Date()) {
    return 'Completed';
  }
  if (is_active) {
    return 'Active';
  } else {
    return 'Draft';
  }
};

const getCampaignListCsvData = (
  list: EmailCampaignModel[],
  platformName: string | null,
  site: SiteModel,
) => {
  return list.map((emailCampaign: EmailCampaignModel) => {
    return {
      'Platform Name': platformName,
      'Site Name': site.slug || 'Custom',
      'Campaign Title': emailCampaign.name,
      'Campaign Status': getStatus(emailCampaign.is_active, emailCampaign.schedule?.end_date),
      'Campaign Description': emailCampaign.schedule
        ? getScheduleString(emailCampaign.schedule)
        : 'No schedule defined',
      'From Name': emailCampaign.from_name,
      'From Email': `${emailCampaign.from_email}${CAMPAIGN_EMAIL_FROM_SUFFIX}`,
      'Subject Line': emailCampaign.subject,
      Tags: emailCampaign.metadata?.tags
        ? (emailCampaign.metadata.tags as TagModel[]).map((tag: TagModel) => tag.value).join('|')
        : undefined,
      'Tag Logic': tag_logic(
        emailCampaign.metadata?.has_all ? (emailCampaign.metadata.has_all as boolean) : undefined,
      ),
      'Template Name': emailCampaign.template?.version_name ?? '',
      Frequency: emailCampaign.schedule?.frequency ?? '',
      'Start Date': emailCampaign.schedule?.start_date
        ? new Date(emailCampaign.schedule?.start_date).toLocaleString()
        : '',
      'Never Expires': !emailCampaign.schedule?.end_date,
      'End Date': emailCampaign.schedule?.end_date
        ? new Date(emailCampaign.schedule?.end_date).toLocaleString()
        : 'None',
    };
  });
};

export { ordinalDatePartValues, getScheduleString, getCampaignListCsvData };
