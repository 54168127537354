import React, { useState } from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { AccessPass } from '@juno/client-api/model';
import { GenericFormikInput } from '@juno/ui';
import { accessPassValidationSchema } from '../utils/utils';

interface NewAccessPassDialogProps {
  siteId: string;
  open: boolean;
  handleClose: () => void;
  handlePost: (accessPass: AccessPass) => void;
  allAccessPasses: AccessPass[] | undefined;
}

const NewAccessPassDialog: React.FC<NewAccessPassDialogProps> = ({
  siteId,
  open,
  handleClose,
  handlePost,
  allAccessPasses,
}) => {
  const [isFocusedUrlInput, setHelperText] = useState(false);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xs'>
      <DialogTitle sx={{ textAlign: 'left', mb: 0 }} variant='h5'>
        Create A New Access Pass
      </DialogTitle>
      <DialogContent>
        <Formik
          key={'info-form'}
          enableReinitialize={true}
          initialValues={{ name: '', tags: [], upgrade_enrollment_url: '' }}
          validationSchema={accessPassValidationSchema(allAccessPasses ?? [])}
          onSubmit={(values) => {
            const ap = { id: '', ...values };
            handlePost(ap);
          }}
        >
          {({
            values,
            errors,
            handleSubmit,
            setFieldValue,
            handleChange,
            setFieldTouched,
            handleBlur,
            dirty,
          }) => {
            return (
              <Form>
                <Box>
                  <GenericFormikInput
                    id={`name`}
                    label='Access Pass Name'
                    name={`name`}
                    value={values.name}
                    sx={{ mt: 1, mb: 2 }}
                  />

                  <div style={{ position: 'relative' }}>
                    <GenericFormikInput
                      id={`upgrade_enrollment_url`}
                      label='Upgrade URL'
                      name={`upgrade_enrollment_url`}
                      value={values.upgrade_enrollment_url}
                      sx={{ mt: 0, mb: 1 }}
                      onFocus={() => setHelperText(true)}
                      onBlur={() => setHelperText(false)}
                      helperText={isFocusedUrlInput && ' https:// ...'}
                    />
                  </div>
                  {(values.name === '' || values.tags.length === 0) && (
                    <Typography sx={{ pt: 0.5, color: 'gray', align: 'center' }}>
                      *Name and tag(s) are required
                    </Typography>
                  )}
                  <Box
                    sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mt: 2 }}
                  >
                    <Button variant='outlined' onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        values.name?.length === 0 ||
                        Boolean(errors.name) ||
                        Boolean(errors.upgrade_enrollment_url)
                      }
                      onClick={(e) => handleSubmit()}
                      variant='contained'
                    >
                      Create Access Pass
                    </Button>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default NewAccessPassDialog;
