import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DailyCall, DailyInputSettings } from '@daily-co/daily-js';
import {
  useAudioTrack,
  useDailyEvent,
  useDevices,
  useLocalParticipant,
  useVideoTrack,
} from '@daily-co/daily-react-hooks';
import { KeyboardVoiceOutlined, SpeakerOutlined, VideocamOutlined } from '@mui/icons-material';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import MicIcon from '@mui/icons-material/Mic';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  MenuProps,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
import { useCreateSessionParticipant, useGetRoomPresence } from '@juno/client-api';
import { Session } from '@juno/client-api/model';
import { useS3Upload } from '@juno/modules';
import { Container, SimpleDialog, VisualEffectsDialog } from '@juno/ui';
import { MutationAction, onMutation, useBreakpoint, useSettings } from '@juno/utils';
import { useCallState } from '../contexts/CallProvider';
import UserMediaError from '../helpers/errors';
import { TrayIcon } from '../styles/tray';

interface PresenceUser {
  userName: string;
  id: string;
}
interface PrejoinPageProps {
  sessionData: Session;
  joinCall: () => void;
  cancelCall: () => void;
}
const PrejoinPage: React.FC<PrejoinPageProps> = ({ sessionData, joinCall, cancelCall }) => {
  const { user, site } = useSettings();
  const { uploadFileToS3 } = useS3Upload();
  const { xs, sm, md } = useBreakpoint();
  const iconFontSize = xs ? '0.8rem' : sm ? '1rem' : md ? '1.5rem' : 'inherit';
  const localParticipant = useLocalParticipant();
  const videoTrack = useVideoTrack(localParticipant?.session_id || '');
  const { microphones, speakers, cameras, setMicrophone, setCamera, setSpeaker } = useDevices();
  const { callObject } = useCallState() as unknown as { callObject: DailyCall };
  const videoElement = useRef<HTMLVideoElement | null>(null);
  const videoElementEffects = useRef<HTMLVideoElement | null>(null);
  const [visualEffectsOpen, setVisualEffectsOpen] = useState(false);

  const [getUserMediaError, setGetUserMediaError] = useState(false);
  const localVideo = useVideoTrack(localParticipant?.session_id || '');
  const localAudio = useAudioTrack(localParticipant?.session_id || '');
  const mutedVideo = localVideo.isOff;
  const mutedAudio = localAudio.isOff;

  const [micSelectState, setMicSelectState] = useState('');
  const [cameraSelectState, setCameraSelectState] = useState('');
  const [speakerSelectState, setSpeakerSelectState] = useState('');
  const [watchOnly, setWatchOnly] = useState(false);

  const [displayName, setDisplayName] = useState(() => {
    const storedDisplayName = localStorage.getItem('sessionDisplayName');
    return (
      storedDisplayName || localParticipant?.user_name || `${user?.first_name} ${user?.last_name}`
    );
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogErrorMessage, setDialogErrorMessage] = useState('');
  const [presenceString, setPresenceString] = useState('You are the first person here.');

  const { data: presence } = useGetRoomPresence(site?.id || '', sessionData.id);

  const startLeavingCall = useCallback(() => {
    if (!callObject) return;
    callObject.leave();
    cancelCall();
  }, [callObject]);

  const toggleVideo = useCallback(() => {
    callObject?.setLocalVideo(mutedVideo);
    setWatchOnly(false);
    localStorage.setItem('videoMutedChoice', JSON.stringify(mutedVideo));
  }, [callObject, mutedVideo]);

  const toggleAudio = useCallback(() => {
    callObject?.setLocalAudio(mutedAudio);
    setWatchOnly(false);
    localStorage.setItem('audioMutedChoice', JSON.stringify(mutedAudio));
  }, [callObject, mutedAudio]);

  useDailyEvent(
    'camera-error',
    useCallback(() => {
      setGetUserMediaError(true);
    }, []),
  );

  useEffect(() => {
    if (presence && presence['data'] && localParticipant) {
      const newPresenceString = generatePresenceString(
        presence['data'] as PresenceUser[],
        localParticipant.user_name,
      );
      setPresenceString(newPresenceString);
    }
  }, [presence, localParticipant]);

  useEffect(() => {
    if (!videoTrack.persistentTrack) return;

    if (videoElement?.current) {
      videoElement.current.srcObject = new MediaStream([videoTrack.persistentTrack]);
    }

    if (videoElementEffects?.current) {
      videoElementEffects.current.srcObject = new MediaStream([videoTrack.persistentTrack]);
    }
  }, [videoTrack.persistentTrack, visualEffectsOpen]);

  // useEffect on microphones to set the initial microphone
  useEffect(() => {
    if (micSelectState !== '') return;

    const storedMicChoice = localStorage.getItem('micChoice');
    if (storedMicChoice) {
      setMicrophone(JSON.parse(storedMicChoice));
      setMicSelectState(JSON.parse(storedMicChoice));
      return;
    }

    if (microphones.length > 0) {
      const selectedMic = microphones.find((mic) => mic.selected === true);
      if (selectedMic) {
        setMicSelectState(selectedMic.device.deviceId);
      } else {
        setMicSelectState(microphones[0].device.deviceId);
      }
    }
  }, [microphones, micSelectState]);

  // useEffect on cameras to set the initial camera
  useEffect(() => {
    if (cameraSelectState !== '') return;

    const storedCameraChoice = localStorage.getItem('cameraChoice');
    if (storedCameraChoice) {
      setCamera(JSON.parse(storedCameraChoice));
      setCameraSelectState(JSON.parse(storedCameraChoice));
      return;
    }

    if (cameras.length > 0) {
      const selectedCamera = cameras.find((camera) => camera.selected === true);
      if (selectedCamera) {
        setCameraSelectState(selectedCamera.device.deviceId);
      } else {
        setCameraSelectState(cameras[0].device.deviceId);
      }
    }
  }, [cameras, cameraSelectState]);

  // useEffect on speakers to set the initial speaker
  useEffect(() => {
    if (speakerSelectState !== '') return;

    const storedSpeakerChoice = localStorage.getItem('speakerChoice');
    if (storedSpeakerChoice) {
      setSpeakerSelectState(JSON.parse(storedSpeakerChoice));
      return;
    }

    if (speakers.length > 0) {
      const selectedSpeaker = speakers.find((speaker) => speaker.selected === true);
      if (selectedSpeaker) {
        setSpeakerSelectState(selectedSpeaker.device.deviceId);
      } else {
        setSpeakerSelectState(speakers[0].device.deviceId);
      }
    }
  }, [speakers, speakerSelectState]);

  const updateMicrophone = (e: any) => {
    setMicrophone(e.target.value);
    setMicSelectState(e.target.value);
    localStorage.setItem('micChoice', JSON.stringify(e.target.value));
  };

  const updateSpeakers = (e: any) => {
    setSpeaker(e.target.value);
    setSpeakerSelectState(e.target.value);
    localStorage.setItem('speakerChoice', JSON.stringify(e.target.value));
  };

  const updateCamera = (e: any) => {
    setCamera(e.target.value);
    setCameraSelectState(e.target.value);
    localStorage.setItem('cameraChoice', JSON.stringify(e.target.value));
  };

  const createParticipant = useCreateSessionParticipant(onMutation(MutationAction.CREATE, ''));

  const onClickJoin = async () => {
    try {
      localStorage.setItem('sessionDisplayName', displayName);
      await callObject?.setUserName(displayName);
      const userData = (callObject?.participants()['local'].userData as Record<
        string,
        unknown
      >) ?? {
        userId: user?.id,
        userAvatar: user?.icon || user?.avatar || '',
      };
      await callObject?.setUserData({ ...userData, onlyWatching: watchOnly });
      await callObject?.join();
      await callObject.startRemoteParticipantsAudioLevelObserver(500);

      // TODO set is_mod, is_pres etc in the particpiant model
      createParticipant.mutateAsync({
        siteId: site?.id || '',
        sessionId: sessionData?.id || '',
      });
      joinCall();
    } catch (e: any) {
      console.error('Error joining call', e);
      setDialogErrorMessage(e.errorMsg);
      setDialogOpen(true);
    }
  };

  return (
    <>
      {getUserMediaError ? (
        <UserMediaError />
      ) : (
        <Container>
          <Box
            bgcolor={'white'}
            minHeight={'calc(100vh - 60px)'}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Grid container p={2}>
              <Grid item xs={xs ? 12 : 6}>
                <Box
                  position='relative'
                  bgcolor={'black'}
                  borderRadius={(theme) => theme.spacing(1)}
                  overflow={'hidden'}
                >
                  <Typography
                    variant='subtitle2'
                    position={'absolute'}
                    top={0}
                    p={1}
                    color='white'
                    sx={{ zIndex: 1 }}
                  >
                    {displayName}
                  </Typography>
                  {/* {videoTrack?.persistentTrack && (
                  )} */}
                  <Box
                    position={'relative'}
                    pt={'56.25%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    {localVideo.isOff ? (
                      <Box
                        className='audio-video-off'
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        height={'100%'}
                        bgcolor={'#535353'}
                        p={3}
                        position={'absolute'}
                        top={0}
                        left={0}
                      >
                        <Avatar
                          style={{ width: '100px', height: '100px' }}
                          src={user?.icon || user?.avatar || ''}
                          alt={localParticipant?.user_name}
                        />
                      </Box>
                    ) : (
                      <video
                        autoPlay
                        muted
                        playsInline
                        ref={videoElement}
                        style={{
                          objectFit: 'contain',
                          width: '100%',
                          position: 'absolute',
                          height: '100%',
                          top: 0,
                          left: 0,
                          transform: 'scaleX(-1)',
                        }}
                      />
                    )}
                    <Box
                      position={'absolute'}
                      bottom={8}
                      width={'100%'}
                      display={'flex'}
                      justifyContent={'center'}
                    >
                      <Tooltip placement='top' title={mutedAudio ? 'Unmute mic' : 'Mute mic'}>
                        <TrayIcon enabled={!mutedAudio} onClick={toggleAudio}>
                          {mutedAudio ? (
                            <MicOffOutlinedIcon sx={{ fontSize: iconFontSize }} />
                          ) : (
                            <MicIcon sx={{ fontSize: iconFontSize }} />
                          )}
                        </TrayIcon>
                      </Tooltip>

                      <Tooltip
                        placement='top'
                        title={mutedVideo ? 'Turn on camera' : 'Turn off camera'}
                      >
                        <TrayIcon enabled={!mutedVideo} onClick={toggleVideo}>
                          {mutedVideo ? (
                            <VideocamOffOutlinedIcon sx={{ fontSize: iconFontSize }} />
                          ) : (
                            <VideocamIcon sx={{ fontSize: iconFontSize }} />
                          )}
                        </TrayIcon>
                      </Tooltip>

                      <Tooltip placement='top' title={'Visual Effects'}>
                        <TrayIcon
                          enabled={true}
                          onClick={() => {
                            callObject?.setLocalVideo(true);
                            setVisualEffectsOpen(true);
                          }}
                        >
                          <AutoAwesomeOutlinedIcon sx={{ fontSize: iconFontSize }} />
                        </TrayIcon>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
                <Stack spacing={0} mt={2} direction={'row'} flexWrap={'wrap'}>
                  {/* Microphone choice */}
                  <StyledSelect
                    fullWidth
                    name='micOptions'
                    id='micSelect'
                    onChange={updateMicrophone}
                    value={micSelectState}
                    size='small'
                    startAdornment={<KeyboardVoiceOutlined sx={{ fontSize: '1rem', mr: 0.5 }} />}
                    MenuProps={SelectMenuProps}
                  >
                    {microphones?.map((mic) => (
                      <MenuItem key={`mic-${mic.device.deviceId}`} value={mic.device.deviceId}>
                        {mic.device.label.replace('Default - ', '')}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                  {/* Camera choice */}
                  <StyledSelect
                    fullWidth
                    name='cameraOptions'
                    id='cameraSelect'
                    onChange={updateCamera}
                    value={cameraSelectState}
                    size='small'
                    startAdornment={<VideocamOutlined sx={{ fontSize: '1rem', mr: 0.5 }} />}
                    MenuProps={SelectMenuProps}
                  >
                    {cameras?.map((camera) => (
                      <MenuItem
                        key={`cam-${camera.device.deviceId}`}
                        value={camera.device.deviceId}
                      >
                        {camera.device.label.replace('Default - ', '')}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                  {/* Speakers choice */}
                  <StyledSelect
                    fullWidth
                    name='speakersOptions'
                    id='speakersSelect'
                    onChange={updateSpeakers}
                    value={speakerSelectState}
                    size='small'
                    startAdornment={<SpeakerOutlined sx={{ fontSize: '1rem', mr: 0.5 }} />}
                    MenuProps={SelectMenuProps}
                  >
                    {speakers?.map((speaker) => (
                      <MenuItem
                        key={`speaker-${speaker.device.deviceId}`}
                        value={speaker.device.deviceId}
                      >
                        {speaker.device.label.replace('Default - ', '')}
                      </MenuItem>
                    ))}
                  </StyledSelect>
                </Stack>
              </Grid>
              <Grid
                item
                xs={xs ? 12 : 6}
                pl={1}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h5' mb={1}>
                  {sessionData.title}
                </Typography>
                {sessionData.preview_text && (
                  <Typography variant='body2' mb={3} pl={4} pr={4}>
                    {sessionData.preview_text}
                  </Typography>
                )}
                <Typography mb={1} variant='caption'>
                  {presenceString}
                </Typography>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  width={'100%'}
                  pl={4}
                  pr={4}
                >
                  <TextField
                    size='small'
                    name='display_name'
                    label='Display name'
                    InputProps={{
                      disableUnderline: true,
                    }}
                    placeholder='Enter a display name'
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                    variant='filled'
                  />
                  {sessionData.allow_watch_only && (
                    <FormControlLabel
                      labelPlacement='start'
                      control={
                        <Checkbox
                          name='showFiltersButton'
                          checked={watchOnly}
                          onChange={() => {
                            setWatchOnly(!watchOnly);
                            callObject?.setLocalAudio(false);
                            callObject?.setLocalVideo(false);
                          }}
                        />
                      }
                      label={<Typography variant='body2'>Watch Only</Typography>}
                    />
                  )}
                </Box>
                <Stack direction={'row'} mt={4} justifyContent={'center'} width={'100%'}>
                  <Button sx={{ mr: 2 }} variant='text' onClick={startLeavingCall}>
                    Go back
                  </Button>
                  <Button variant='contained' onClick={onClickJoin}>
                    Enter session
                  </Button>
                </Stack>
              </Grid>
            </Grid>

            <SimpleDialog
              open={dialogOpen}
              title={'Session is not available'}
              buttonTitle={'Go back'}
              onClose={() => {
                setDialogOpen(false);
                startLeavingCall();
              }}
            >
              <Typography pt={2} variant='subtitle1'>
                Unfortunately, this session is no longer available.
              </Typography>
              <Typography variant='body2'>Error message: {dialogErrorMessage}</Typography>
            </SimpleDialog>
            <VisualEffectsDialog
              uploadFileToS3={uploadFileToS3}
              open={visualEffectsOpen}
              setOpen={setVisualEffectsOpen}
              videoElementEffects={videoElementEffects}
              callObject={callObject}
            />
          </Box>
        </Container>
      )}
    </>
  );
};
export default PrejoinPage;

const StyledSelect = styled(Select)(() => ({
  borderColor: 'transparent',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.1)',
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
    },
  },
  borderRadius: '100px',
  maxWidth: '160px',
  fontSize: '12px',
}));

const SelectMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  sx: {
    '.MuiMenuItem-root': {
      fontSize: '12px',
    },
  },
  elevation: 5,
};

const generatePresenceString = (data: PresenceUser[], userName: string) => {
  if (data.length === 0) {
    return 'You are the first person here.';
  }

  // make sure we dont have any duplicates
  const namesSet = new Set(
    data.filter((user) => user.userName !== userName).map((user) => user.userName),
  );
  const names = Array.from(namesSet);

  if (names.length === 0) {
    return 'You are the first person here.';
  }

  if (names.length === 1) {
    return `${names[0]} is in the room.`;
  }

  if (names.length === 2) {
    return `${names[0]} and ${names[1]} are in the room.`;
  }

  if (names.length === 3) {
    return `${names[0]}, ${names[1]}, and ${names[2]} are in the room.`;
  }

  if (names.length > 3) {
    return `${names[0]}, ${names[1]}, ${names[2]}, and ${names.length - 3} others are in the room.`;
  }

  return 'You are the first person here.';
};
