/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type RoleEnum = typeof RoleEnum[keyof typeof RoleEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoleEnum = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;
