import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { grey } from '@mui/material/colors';
import { Editor } from '@tinymce/tinymce-react';
import { useFormik } from 'formik';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getAllEducationCreditRequirements,
  getGetAllEducationCreditsQueryKey,
  getGetCourseQueryKey,
  getGetCoursesQueryKey,
  updateCourseFeed,
  useCreateEducationCreditRequirement,
  useDeleteCourse,
  useDeleteEducationCreditRequirement,
  useGetAccessPasses,
  useGetAllCourseRequirements,
  useGetAllEducationCredits,
  useGetCourseFeed,
  useGradeAllCourseEnrollments,
  useUpdateCourse,
  useUpdateCourseFeed,
  useUpdateEducationCredit,
  useUploadScormCourse,
} from '@juno/client-api';
import { JunoImage } from '@juno/client-api/fakeModel';
import { useGetUserRoleMap } from '@juno/client-api/helpers';
import {
  AccessPass,
  Course,
  CourseRequirement,
  EducationCredit,
  EducationCreditRequirement,
  Feed,
  Product,
  Site,
  Site as SiteModel,
} from '@juno/client-api/model';
import { JUNO_ROUTE_MAP, TINY_MCE_DEFAULT_CONFIG } from '@juno/constants';
import {
  AutocompleteAccessPasses,
  AutocompleteTags,
  ConfirmDialog,
  DialogAriaWrapper,
  GenericFormikDateSection,
  JunoImageUpload,
  JunoSpin,
  VideoUploadPopup,
} from '@juno/ui';
import {
  MutationAction,
  onMutation,
  optimizeImage,
  uploadTinyMceImageCloudinary,
  useSettings,
} from '@juno/utils';
import { useRouteNavigate } from '@juno/utils';
import SaveBar from '../../components/SaveBar';
import VideoUploadButton from '../../components/VideoUploadButton';
import { CourseStatus } from '../../constants';
import { CoursePanelContext } from '../context';
import AutocompleteInstructors from './AutocompleteInstructors';
import AutocompletePrereqs from './AutocompletePrereqs';
import ScormFormDialog, { ScormCourseForm } from './ScormFormDialog';
import ScormLoadingDialog from './ScormLoadingDialog';
import validationSchema from './ValidationSchema';
import CloneCourseDialog from './helpers/CloneCourseDialog';
import CourseDateSection from './helpers/CourseDateSection';
import EducationCreditSection from './helpers/EducationCreditSection';

interface CourseEditProps<T> {
  site: SiteModel;
  course: Course;
  onDelete: () => void;
  saving: boolean;
  formik: any;
  handleScormOpen: () => void;
  refetchEducationCredits: () => void;
  setCloneCourseDialogOpen: Dispatch<SetStateAction<boolean>>;
  setRecalcDialogOpen: Dispatch<SetStateAction<boolean>>;
}
interface AccessPassForm extends AccessPass {
  products: Product[];
}

interface ScormCourseMetadata {
  job_status: string;
}

const CourseInfo = <T extends Record<string, unknown>>({
  site,
  course,
  onDelete,
  saving,
  formik,
  handleScormOpen,
  refetchEducationCredits,
  setCloneCourseDialogOpen,
  setRecalcDialogOpen,
}: CourseEditProps<T>): React.ReactElement => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [launchCount, incrementLaunchCount] = useState(0);
  const [fileName, setFileName] = useState('');
  const [videoFileIsCanceled, setVideoFileIsCanceled] = useState(false);
  const [fileUploadPromise, setFileUploadPromise] = useState<Promise<any> | null>(null);
  const [accessPassDialogOpen, setAccessPassDialogOpen] = useState(false);

  const isScorm = useMemo(() => {
    return !!course?.metadata?.scorm_course;
  }, [course?.metadata?.scorm_course]);
  const isScormComplete = useMemo(() => {
    return (course?.metadata?.scorm_course as ScormCourseMetadata)?.job_status === 'COMPLETED';
  }, [course?.metadata?.scorm_course]);
  const isScormLoading = useMemo(() => {
    return isScorm && !isScormComplete;
  }, [isScorm, isScormComplete]);

  const handleFileName = (value: string) => {
    setFileName(value);
  };

  const coursePanelContext = useContext(CoursePanelContext);
  const [open, setOpen] = React.useState(false);
  const { id: siteId, platform_id: platformId } = site;
  const roleMap = useGetUserRoleMap(siteId);

  const isLocked = isScormLoading; //courseStatus === COURSE_STATUS.LOCKED;
  const isDisabled = false; //isLocked || courseStatus === COURSE_STATUS.DISABLED;
  const { isWidget } = useSettings();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDelete = () => onDelete();

  const [restrictEnroll, setRestrictEnroll] = useState(
    formik.values.restrict_enrollment_access_passes?.length > 0 ? true : false,
  );
  const [includeFeed, setIncludeFeed] = useState<boolean>(formik.values.include_feed ?? false);

  // TODO arent these the same thing?
  const { data: prereqs, isLoading: isPrereqsLoading } = useGetAllCourseRequirements(siteId);
  const { data: grants, isLoading: isGrantsLoading } = useGetAllCourseRequirements(siteId);

  // If the restrict enrollment toggle isn't true, we don't show the restrict preview toggle
  useEffect(() => {
    if (!restrictEnroll) {
      formik.setFieldValue('restrict_enrollment_access_passes', []);
      formik.setFieldTouched('restrict_enrollment_access_pass', true);
      formik.setFieldValue('restrict_preview_access', false);
    }
  }, [restrictEnroll]);

  const onVideoUploaded = (videoUrl: string) => {
    coursePanelContext.setIsVideoUploading(false);
    formik.setFieldValue('banner_video', videoUrl);
    setIsUploading(false);
    setUploadProgress(0);
  };

  const onVideoUploadStarted = () => {
    coursePanelContext.setIsVideoUploading(true);
  };

  useEffect(() => {
    if (videoFileIsCanceled) {
      coursePanelContext.setIsVideoUploading(false);
    }
  }, [videoFileIsCanceled]);

  const { data: accessPasses, isLoading: isAccessPassesLoading } = useGetAccessPasses(siteId, {
    include: 'products',
  });
  const accessPassesData = useMemo(() => {
    return accessPasses as AccessPassForm[] | undefined;
  }, [accessPasses]);

  // only allow one access pass with a product attached
  const handleAccessPassChange = (event: any, accessPasses: AccessPassForm[]) => {
    const withProducts =
      accessPasses?.filter((ap) => ap.products?.length > 0 || ap.upgrade_enrollment_url) || [];
    if (withProducts.length > 1) {
      setAccessPassDialogOpen(true);
    } else {
      formik.setFieldValue('restrict_enrollment_access_passes', accessPasses);
    }
  };

  if (roleMap.isLoading || isPrereqsLoading || isGrantsLoading || isAccessPassesLoading)
    return <JunoSpin />;

  return (
    <form onSubmit={formik.handleSubmit}>
      {!isLocked && (
        <SaveBar
          isDirty={formik.dirty}
          isSaving={saving}
          onDiscard={formik.handleReset}
          onSave={formik.handleSubmit}
        />
      )}
      {isUploading && (
        <VideoUploadPopup
          launchcount={launchCount}
          filename={fileName}
          value={uploadProgress}
          setVideoFileIsCanceled={setVideoFileIsCanceled}
        />
      )}
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Card sx={{ p: 2, mt: 2, boxShadow: 1 }}>
              <Grid container spacing={3} flex={3}>
                <Grid item xs={12}>
                  <Typography variant='overline' sx={{ mb: 3 }}>
                    Basic Information
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id='title'
                    name='title'
                    label='Title'
                    size='small'
                    value={formik.values.title || ''}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    onBlur={formik.handleBlur}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id='slug'
                    name='slug'
                    label='Slug'
                    size='small'
                    value={formik.values.slug || ''}
                    onChange={formik.handleChange}
                    error={formik.touched.slug && Boolean(formik.errors.slug)}
                    helperText={formik.touched.slug && formik.errors.slug}
                    onBlur={formik.handleBlur}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id='abstract'
                    name='abstract'
                    label='Preview Text'
                    size='small'
                    value={formik.values.abstract || ''}
                    onChange={formik.handleChange}
                    error={formik.touched.abstract && Boolean(formik.errors.abstract)}
                    helperText={formik.touched.abstract && formik.errors.abstract}
                    onBlur={formik.handleBlur}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompleteTags formik={formik} label='Tag(s)' disabled={isDisabled} />
                </Grid>
                <Grid item xs={12}></Grid>
              </Grid>
            </Card>
            <Card sx={{ mt: 2, p: 2, boxShadow: 1 }}>
              <Typography variant='overline' sx={{ mb: 2 }}>
                Description
              </Typography>
              <Typography variant='body2' sx={{ mb: 5 }}>
                This shows on the course homepage - users browsing courses will see this when they
                select the course.
              </Typography>
              <Editor
                apiKey={process.env.NX_TINY_MCE_API_KEY}
                onEditorChange={(value: string) =>
                  formik.handleChange({ target: { name: 'description', value } })
                }
                value={formik.values.description || ''}
                init={{
                  ...TINY_MCE_DEFAULT_CONFIG,
                  images_upload_handler: uploadTinyMceImageCloudinary,
                  setup: (editor) => {
                    editor.setProgressState(true);
                  },
                }}
                disabled={isDisabled}
              />
              <FormHelperText error={true}>
                {formik.touched.description && formik.errors.description}
              </FormHelperText>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card sx={{ p: 2, mt: 2, boxShadow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='overline' sx={{ mb: 3 }}>
                    Actions
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={isDisabled}
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={handleScormOpen}
                  >
                    {isScorm ? 'Change Learning Package' : 'Convert to Learning Package'}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant='contained'
                    fullWidth
                    onClick={() => setCloneCourseDialogOpen(true)}
                    sx={{ mr: 1 }}
                  >
                    Clone Course
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button variant='contained' fullWidth onClick={() => setRecalcDialogOpen(true)}>
                    Recalculate Grades
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    disabled={isDisabled}
                    variant='contained'
                    color='primary'
                    fullWidth
                    onClick={handleOpen}
                  >
                    Delete Course
                  </Button>
                </Grid>
              </Grid>
            </Card>
            <Card sx={{ p: 2, mt: 2, boxShadow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='overline' sx={{ mb: 3 }}>
                    Dates
                  </Typography>
                </Grid>
                <CourseDateSection
                  dateFields={[
                    'date_released',
                    'date_start',
                    'date_end',
                    'date_open_enrollment',
                    'date_lock_enrollment',
                  ]}
                  formik={formik}
                />
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Card sx={{ p: 2, mt: 2, boxShadow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='overline' sx={{ mb: 2 }}>
                Image and Video
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ mb: 1 }} variant='subtitle1'>
                Course Image
              </Typography>
              <Typography variant='body2'>
                This is the primary image for your course. It will show on the course as well as in
                grids and rotators.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <JunoImageUpload
                style={{ aspectRatio: '16/9', width: 200, marginLeft: 'auto' }}
                src={optimizeImage(270, formik.values['icon'])}
                srcUrl={formik.values['icon']}
                disabled={isDisabled}
                onFileUploaded={(selected: JunoImage | null) =>
                  formik.setFieldValue('icon', selected?.url || '')
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='subtitle1' sx={{ mb: 1 }}>
                Video
              </Typography>
              <Typography variant='body2'>
                Upload an MP4 or add your video. The course video replaces the “Course List Image”
                on the course home page.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {' '}
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12}>
                  <VideoUploadButton
                    uploadProgress={uploadProgress}
                    setUploadProgress={setUploadProgress}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    setFileName={handleFileName}
                    setLaunchCount={incrementLaunchCount}
                    onVideoUploaded={onVideoUploaded}
                    onVideoUploadStarted={onVideoUploadStarted}
                    fileUploadPromise={fileUploadPromise}
                    setFileUploadPromise={setFileUploadPromise}
                    videoFileIsCanceled={videoFileIsCanceled}
                    setVideoFileIsCanceled={setVideoFileIsCanceled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id='banner_video'
                    name='banner_video'
                    label='Video URL'
                    placeholder=''
                    InputProps={{
                      disableUnderline: true,
                    }}
                    value={formik.values.banner_video || ''}
                    onChange={formik.handleChange}
                    error={formik.touched.banner_video && Boolean(formik.errors.banner_video)}
                    helperText={
                      formik.touched.banner_video && formik.errors.banner_video
                        ? formik.errors.banner_video
                        : //: '*Supported video types: AmazonIVS, Brightcove, MP4 Upload, Vimeo, Wistia, and YouTube.'
                          '*Supported video types: MP4 link, Vimeo, Wistia, and YouTube.'
                    }
                    onBlur={formik.handleBlur}
                    size={'small'}
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ p: 2, mt: 2, boxShadow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='overline' sx={{ mb: 3 }}>
                Instructors, Capacity and Grading
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <AutocompleteInstructors
                formik={formik}
                name='instructors'
                label='Instructors'
                siteId={siteId}
                platformId={platformId}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id='max_enrollments'
                name='max_enrollments'
                label='Course Learner Capacity'
                value={formik.values.max_enrollments}
                onChange={formik.handleChange}
                error={formik.touched.max_enrollments && Boolean(formik.errors.max_enrollments)}
                helperText={formik.touched.max_enrollments && formik.errors.max_enrollments}
                onBlur={formik.handleBlur}
                InputProps={{
                  disableUnderline: true,
                }}
                size={'small'}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id='credits'
                name='credits'
                label='Course Credits'
                value={formik.values.credits}
                onChange={formik.handleChange}
                error={formik.touched.credits && Boolean(formik.errors.credits)}
                helperText={formik.touched.credits && formik.errors.credits}
                onBlur={formik.handleBlur}
                size={'small'}
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id='passing_percent'
                name='passing_percent'
                label='Course Passing %'
                value={formik.values.passing_percent}
                onChange={formik.handleChange}
                error={formik.touched.passing_percent && Boolean(formik.errors.passing_percent)}
                helperText={formik.touched.passing_percent && formik.errors.passing_percent}
                onBlur={formik.handleBlur}
                size={'small'}
                disabled={isDisabled || isScorm}
              />
            </Grid>
          </Grid>
        </Card>

        <Card sx={{ p: 2, mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='overline'>Credits and Prerequisites</Typography>
            </Grid>
            <Grid item xs={12}>
              <EducationCreditSection
                siteId={siteId}
                course={course}
                educationCredits={formik.values.education_credits}
                handleChange={formik.handleChange}
                setFieldValue={formik.setFieldValue}
                refetchEducationCredits={refetchEducationCredits}
                isFetContext={isWidget}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='caption'>
                <i>Courses that must be completed to enroll</i>
              </Typography>
              <AutocompletePrereqs
                formik={formik}
                name={'prerequisites'}
                siteId={siteId}
                label='Prerequisite(s) Required'
                disabled={isDisabled}
                allowCreateNew={true}
                grantNewEquivalent={false}
                startingPrereqs={prereqs?.filter((f) =>
                  formik.values.prerequisites?.find((p: CourseRequirement) => f.id === p.id),
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant='caption'>
                <i>Prerequisites that this course will grant once passed</i>
              </Typography>
              <AutocompletePrereqs
                formik={formik}
                name={'grants_requirements'}
                siteId={siteId}
                label='Prerequisite(s) Granted'
                disabled={isDisabled}
                allowCreateNew={true}
                startingPrereqs={grants?.filter((f) =>
                  formik.values.grants_requirements?.find((p: CourseRequirement) => f.id === p.id),
                )}
                grantNewEquivalent={true}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ p: 2, mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='overline'>Rules</Typography>
            </Grid>{' '}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.enrollment_required}
                    onChange={formik.handleChange}
                    name='enrollment_required'
                    disabled={isDisabled || restrictEnroll}
                  />
                }
                label='Require enrollment requests'
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={restrictEnroll}
                    onChange={(e) => {
                      setRestrictEnroll((old) => !old);
                      if (!restrictEnroll) {
                        formik.setFieldValue('restrict_preview_access', false);
                      }
                    }}
                    name='restrict_enroll_toggle'
                  />
                }
                label='Restrict enrolling by Access Level'
              />
              {restrictEnroll && (
                <>
                  <AutocompleteAccessPasses
                    multiple={true}
                    label='Access Pass'
                    fieldValue='restrict_enrollment_access_passes'
                    passArray={formik.values.restrict_enrollment_access_passes}
                    accessPasses={accessPasses?.filter(
                      (ap) =>
                        !formik.values.restrict_enrollment_access_passes.find(
                          (f: AccessPass) => f.id === ap.id,
                        ),
                    )}
                    handleChange={handleAccessPassChange}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.restrict_preview_access}
                        onChange={(e) => {
                          formik.setFieldValue(
                            'restrict_preview_access',
                            !formik.values.restrict_preview_access,
                          );
                        }}
                        name='restrict_preview_access'
                      />
                    }
                    label={
                      <Tooltip
                        title='This will restrict the user from entering the course preview rather than restricting on the enrollment button.'
                        placement='top-start'
                      >
                        <Typography>Restrict access to preview course</Typography>
                      </Tooltip>
                    }
                  />
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={includeFeed}
                    onChange={(e) => {
                      setIncludeFeed((old) => !old);
                      formik.setFieldValue('include_feed', !formik.values.include_feed);
                    }}
                    name='include_feed'
                  />
                }
                label='Add feed to course'
              />
              {includeFeed && (
                <TextField
                  fullWidth
                  id='feed_title'
                  name='feed_title'
                  label='Feed Title'
                  placeholder='Add Title'
                  value={formik.values.feed_title}
                  onChange={formik.handleChange}
                  error={formik.touched.feed_title && Boolean(formik.errors.feed_title)}
                  helperText={formik.touched.feed_title && formik.errors.feed_title}
                  onBlur={formik.handleBlur}
                  variant='outlined'
                  sx={{ mt: 1 }}
                />
              )}
            </Grid>
          </Grid>
        </Card>

        <Grid container spacing={2}>
          {/* Course.banner isn't used currently, which is causing confusion. Uncomment when this is used. */}
          {/*{!isWidget && (*/}
          {/*  <Grid item xs={6}>*/}
          {/*    <Typography sx={{ mb: 2 }}>Banner</Typography>*/}
          {/*    <Box>*/}
          {/*      <JunoImageUpload*/}
          {/*        style={{ aspectRatio: '16/9' }}*/}
          {/*        src={optimizeImage(270, formik.values['banner'])}*/}
          {/*        srcUrl={formik.values['banner']}*/}
          {/*        disabled={isDisabled}*/}
          {/*        onFileUploaded={(selected: JunoImage | null) =>*/}
          {/*          formik.setFieldValue('banner', selected?.url || '')*/}
          {/*        }*/}
          {/*        aspectRatio={ASPECT_RATIOS.TWENTY_FIVE_EIGHT}*/}
          {/*      />*/}
          {/*    </Box>*/}
          {/*  </Grid>*/}
          {/*)}*/}
        </Grid>
        <Grid container spacing={2} flex={2} sx={{ padding: 1, alignContent: 'flex-start' }}>
          <></>
        </Grid>
      </Box>

      <DialogAriaWrapper open={open} onClose={handleClose} id='delete_course'>
        <DialogTitle>Delete Course</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this course? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton loading={saving} variant='contained' onClick={handleDelete} autoFocus>
            Delete
          </LoadingButton>
        </DialogActions>
      </DialogAriaWrapper>
      <DialogAriaWrapper
        id={'limit_products_attached'}
        open={accessPassDialogOpen}
        onClose={() => {
          setAccessPassDialogOpen(false);
        }}
      >
        <DialogTitle>Cannot Add Access Pass</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You may only attach one access pass with a product OR an upgrade url to a course.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAccessPassDialogOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </DialogAriaWrapper>
    </form>
  );
};

const unSteveofyData = (data: any) => {
  if (
    data.prerequisites &&
    data.prerequisites.length > 0 &&
    typeof data.prerequisites[0] == 'string'
  ) {
    data.prerequisites = data.prerequisites.map((id: string) => {
      return { id: id };
    });
  }
  if (
    data.grants_requirements &&
    data.grants_requirements.length > 0 &&
    typeof data.grants_requirements[0] == 'string'
  ) {
    data.grants_requirements = data.grants_requirements.map((id: string) => {
      return { id: id };
    });
  }
  return data;
};
interface CourseInfoWrapperProps {
  site: Site;
  courseStatus: CourseStatus;
  courseData: Course;
}

const CourseInfoWrapper: React.FC<CourseInfoWrapperProps> = ({
  site,
  courseStatus,
  courseData,
}) => {
  const { id: siteId, slug } = site;
  const [isSaving, setIsSaving] = useState(false);
  const [recalcDialogOpen, setRecalcDialogOpen] = useState(false);
  const [cloneCourseDialogOpen, setCloneCourseDialogOpen] = useState(false);
  const [isRecalculating, setIsRecalculating] = useState(false);
  const params = useParams() as { courseSlug?: string; tabSlug?: string };
  const siteSlug: string = slug || '';
  const courseSlug: string = params.courseSlug || '';
  const learningNavigate = useRouteNavigate();
  const qClient = useQueryClient();
  const [scormOpen, setScormOpen] = React.useState(false);
  const [scormLoadingOpen, setScormLoadingOpen] = React.useState(false);
  const [scormLoadingHasShown, setScormLoadingHasShown] = React.useState(false);
  const navigate = useNavigate();
  const [scormRefreshTimer, setScormRefreshTimer] = useState<NodeJS.Timer | null>(null);

  // queries
  const { data: courseEducationCredits, isLoading: isLoadingEC } = useGetAllEducationCredits(
    siteId,
    {
      filter: { requirements__course_id: courseData?.id || '' },
    },
    { query: { enabled: !!courseData.id } },
  );

  const { data: courseFeed, refetch: refetchCourseFeed } = useGetCourseFeed(
    siteId,
    courseData?.id || '',
    {},
    { query: { enabled: !!courseData.id } },
  );
  // mutations
  const refetchCourse = () => qClient.invalidateQueries(getGetCourseQueryKey(siteId, courseSlug));
  const courseUpdate = useUpdateCourse(onMutation(MutationAction.UPDATE, 'Course', refetchCourse));
  const refetchCourses = () => qClient.invalidateQueries(getGetCoursesQueryKey(siteId));
  const courseDelete = useDeleteCourse(onMutation(MutationAction.DELETE, 'Course', refetchCourses));
  const courseRecalculate = useGradeAllCourseEnrollments(
    onMutation(MutationAction.UPDATE, 'Grades', refetchCourse),
  );
  const updateCredit = useUpdateEducationCredit(
    onMutation(MutationAction.UPDATE, 'Education Credit', () => {}),
  );

  const refetchEducationCredits = () =>
    qClient.invalidateQueries(
      getGetAllEducationCreditsQueryKey(siteId, {
        filter: { requirements__course_id: courseData?.id || '' },
      }),
    );

  const formik = useFormik({
    initialValues: unSteveofyData({
      ...courseData,
      education_credits: courseEducationCredits,
      feed_title: courseFeed?.title || '',
    }),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.credits = values.credits ? values.credits : '0';
      values.passing_percent = values.passing_percent ? Number(values.passing_percent) : 0;
      values.max_enrollments = values.max_enrollments ? Number(values.max_enrollments) : 0;
      handleSubmit(values);
    },
    enableReinitialize: true,
  });
  const scormCreate = useUploadScormCourse();
  const handleScormOpen = () => setScormOpen(true);
  const handleScormClose = () => setScormOpen(false);
  const handleScormLoadingClose = () => setScormLoadingOpen(false);

  const requirementCreate = useCreateEducationCreditRequirement(
    onMutation(MutationAction.CREATE, 'Education Credit Requirement', refetchEducationCredits),
  );
  const requirementDelete = useDeleteEducationCreditRequirement(
    onMutation(MutationAction.DELETE, 'Education Credit Requirement', refetchEducationCredits),
  );

  const feedUpdate = useUpdateCourseFeed(onMutation(MutationAction.UPDATE, '', refetchCourseFeed));

  // Handle case where Scorm is loading in the background. Notify user and poll course.
  useEffect(() => {
    if (
      !scormLoadingHasShown &&
      courseData?.metadata?.scorm_course &&
      (courseData?.metadata?.scorm_course as ScormCourseMetadata)?.job_status !== 'COMPLETED'
    ) {
      setScormLoadingOpen(true);
      setScormLoadingHasShown(true);
    }
  }, [courseData]);

  useEffect(() => {
    if (
      courseData?.metadata?.scorm_course &&
      (courseData?.metadata?.scorm_course as ScormCourseMetadata)?.job_status !== 'COMPLETED' &&
      !scormRefreshTimer
    ) {
      // Register interval to poll scorm
      const interval = setInterval(refetchCourse, 10 * 1000);
      setScormRefreshTimer(interval);
    } else if (
      (courseData?.metadata?.scorm_course as ScormCourseMetadata)?.job_status === 'COMPLETED' &&
      scormRefreshTimer
    ) {
      // Interval to poll scorm exists but is no longer needed
      clearInterval(scormRefreshTimer);
      setScormRefreshTimer(null);
      setScormLoadingOpen(false);
    }
    return () => {
      if (scormRefreshTimer) {
        clearInterval(scormRefreshTimer);
        setScormRefreshTimer(null);
      }
    };
  }, [courseData]);

  // if (isLoadingCourse || isLoadingEC || !courseData) return <JunoSpin />;

  const handleSubmitEC = async (data: any) => {
    const selectedEducationCredits = data.education_credits as EducationCredit[];
    if (courseEducationCredits && courseEducationCredits.length > 0) {
      // I had pre-existing education credits
      const removedEducationCredits = courseEducationCredits.filter(
        (ec1) => !selectedEducationCredits.includes(ec1),
      );
      if (removedEducationCredits && removedEducationCredits.length > 0) {
        // if I have removed any of the pre-existing education credits, delete the requirements
        removedEducationCredits.forEach((removedEC: EducationCredit) => {
          getAllEducationCreditRequirements(siteId, removedEC.id ?? '').then(
            (requirements: EducationCreditRequirement[]) => {
              requirements.forEach((requirement: EducationCreditRequirement) => {
                // if the requirement is for this course, delete it
                if (courseData.id === requirement.course_id) {
                  requirementDelete.mutateAsync({
                    siteId,
                    creditId: removedEC.id ?? '',
                    requirementId: requirement.id ?? '',
                  });
                }
              });
            },
          );
        });
      }
    }

    if (selectedEducationCredits && selectedEducationCredits.length > 0) {
      // I have chosen education credits, make sure they weren't pre-existing
      const addedEducationCredits = selectedEducationCredits.filter((ec1: EducationCredit) => {
        return !courseEducationCredits?.includes(ec1);
      });
      if (addedEducationCredits && addedEducationCredits.length > 0) {
        // create new requirements for the added education credits
        addedEducationCredits.forEach((addedEC: EducationCredit) => {
          const newRequirement = {
            education_credit_id: addedEC.id,
            course_id: courseData.id,
            earned_hours: courseData.credits,
            completion_threshold: courseData.passing_percent,
          } as EducationCreditRequirement;
          requirementCreate.mutateAsync({
            siteId,
            creditId: addedEC.id ?? '',
            data: newRequirement,
          });
        });
      }
    }
  };

  const handleSubmit = async (data: any) => {
    setIsSaving(true);
    try {
      const oldSlug = courseData?.slug;
      await handleSubmitEC(data);
      const newCourse = await courseUpdate.mutateAsync({ siteId, courseId: courseData.id, data });
      const newSlug = newCourse.slug;
      if (oldSlug !== newSlug) {
        learningNavigate(JUNO_ROUTE_MAP.ADMIN_COURSE_TAB, {
          siteSlug,
          courseSlug: newSlug,
          tabSlug: 'info',
        });
      }
      if (newCourse.include_feed && data.feed_title && data.feed_title !== '') {
        const newFeed = {
          title: data.feed_title,
        } as Feed;
        feedUpdate.mutateAsync({ siteId, courseId: courseData.id, data: newFeed });
      }

      formik.resetForm({ values: { ...newCourse, feed_title: data.feed_title ?? '' } });
    } catch (error) {
      console.error(error);
    }
    setIsSaving(false);
  };

  const handleDelete = async () => {
    setIsSaving(true);
    await courseDelete.mutateAsync({ siteId, courseId: courseData.id });
    navigate(-1);
    setIsSaving(false);
  };

  const handleRecalculate = async () => {
    setIsRecalculating(true);
    await courseRecalculate.mutateAsync({ siteId, courseId: courseData.id });
    setRecalcDialogOpen(false);
    setIsRecalculating(false);
  };

  const handleSaveScormCourse = async (course: ScormCourseForm | undefined) => {
    if (!course) return;
    setIsSaving(true);
    try {
      if (course.uploaded_file) {
        await scormCreate
          .mutateAsync({
            siteId,
            courseId: courseData.id,
            data: { file_path: course.uploaded_file },
          })
          .then(() => {
            refetchCourse();
          });
      }
    } catch (e) {
      console.error(e);
    }
    handleScormClose();
    setIsSaving(false);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}></Box>
      <CourseInfo
        site={site}
        course={courseData}
        onDelete={handleDelete}
        saving={isSaving}
        formik={formik}
        handleScormOpen={handleScormOpen}
        refetchEducationCredits={refetchEducationCredits}
        setCloneCourseDialogOpen={setCloneCourseDialogOpen}
        setRecalcDialogOpen={setRecalcDialogOpen}
      />
      <ConfirmDialog
        title='Overwrite Grades?'
        open={recalcDialogOpen}
        saving={isRecalculating}
        onSave={handleRecalculate}
        onCancel={() => setRecalcDialogOpen(false)}
        buttonTitle='Recalculate'
      >
        Recalculating grades will apply to all enrolled Learners and overwrite submitted course
        grades for those who have already completed the course.
      </ConfirmDialog>
      <ScormFormDialog
        open={scormOpen}
        onClose={handleScormClose}
        onSave={(course: ScormCourseForm | undefined) => handleSaveScormCourse(course)}
        isSaving={isSaving}
      />
      <ScormLoadingDialog open={scormLoadingOpen} onClose={handleScormLoadingClose} />
      {cloneCourseDialogOpen && (
        <CloneCourseDialog
          siteId={siteId}
          onClose={() => setCloneCourseDialogOpen(false)}
          cloneTitle={courseData?.title}
          courseId={courseData?.id}
        />
      )}
    </>
  );
};

export default CourseInfoWrapper;
