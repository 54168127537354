import React from 'react';
import {
  Analytics as AnalyticsIcon,
  AnalyticsOutlined as AnalyticsOutlinedIcon,
} from '@mui/icons-material';
import CachedIcon from '@mui/icons-material/Cached';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import FaceRetouchingNaturalIcon from '@mui/icons-material/FaceRetouchingNatural';
import FaceRetouchingNaturalOutlinedIcon from '@mui/icons-material/FaceRetouchingNaturalOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import LockIcon from '@mui/icons-material/Lock';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import MailIcon from '@mui/icons-material/Mail';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PaletteIcon from '@mui/icons-material/Palette';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import SchoolIcon from '@mui/icons-material/School';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ShareIcon from '@mui/icons-material/Share';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import {
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSettings } from '@juno/utils';

interface SideNavProps {
  siteSlug?: string;
}
const SideNav: React.FC<SideNavProps> = ({ siteSlug }) => {
  const { user, enableLearning, enableLibrary } = useSettings();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <Card sx={{ boxShadow: (theme) => theme.shadows[1] }}>
        <List>
          <ListItem>
            <Typography variant='overline'>Layout</Typography>
          </ListItem>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin`)}
            selected={
              location.pathname.includes('/admin') && !location.pathname.includes('/admin/')
            }
          >
            <ListItemIcon>
              {location.pathname.includes('/admin') && !location.pathname.includes('/admin/') ? (
                <PaletteIcon />
              ) : (
                <PaletteOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>General</ListItemText>
          </ListItemButton>

          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/pages`)}
            selected={location.pathname.includes('/admin/pages')}
          >
            <ListItemIcon>
              {location.pathname.includes('/admin/pages') ? (
                <FileCopyIcon />
              ) : (
                <FileCopyOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>Pages</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/navigation`)}
            selected={location.pathname.includes('/admin/navigation')}
            sx={{ mb: 1 }}
          >
            <ListItemIcon>
              {location.pathname.includes('/admin/navigation') ? (
                <ShareIcon />
              ) : (
                <ShareOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>Navigation</ListItemText>
          </ListItemButton>
        </List>
      </Card>
      <Card sx={{ mt: 1.5, boxShadow: (theme) => theme.shadows[1] }}>
        <List>
          <ListItem>
            <Typography variant='overline'>Content</Typography>
          </ListItem>
          {enableLearning && (
            <ListItemButton
              onClick={() => navigate(`/${siteSlug}/admin/learning`)}
              selected={location.pathname.includes('/admin/learning')}
            >
              <ListItemIcon>
                {location.pathname.includes('/admin/learning') ? (
                  <SchoolIcon />
                ) : (
                  <SchoolOutlinedIcon />
                )}
              </ListItemIcon>
              <ListItemText>Learning</ListItemText>
            </ListItemButton>
          )}

          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/community/groups`)}
            selected={location.pathname.includes('/admin/community/groups')}
          >
            <ListItemIcon>
              {location.pathname.includes('/admin/community/groups') ? (
                <GroupsIcon />
              ) : (
                <GroupsOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>Community</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/sessions`)}
            selected={location.pathname.includes('/admin/sessions')}
          >
            <ListItemIcon>
              {location.pathname.includes('/admin/sessions') ? (
                <VideoCameraFrontIcon />
              ) : (
                <VideoCameraFrontOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>Sessions</ListItemText>
          </ListItemButton>
          {enableLibrary && (
            <ListItemButton
              onClick={() => navigate(`/${siteSlug}/admin/library`)}
              selected={location.pathname.includes('/admin/library')}
            >
              <ListItemIcon>
                {location.pathname.includes('/admin/library') ? (
                  <LocalLibraryIcon />
                ) : (
                  <LocalLibraryOutlinedIcon />
                )}
              </ListItemIcon>
              <ListItemText>Library</ListItemText>
            </ListItemButton>
          )}

          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/speakers`)}
            selected={location.pathname.includes('/admin/speakers')}
            sx={{ mb: 1 }}
          >
            <ListItemIcon>
              {location.pathname.includes('/admin/library') ? (
                <FaceRetouchingNaturalOutlinedIcon />
              ) : (
                <FaceRetouchingNaturalIcon />
              )}
            </ListItemIcon>
            <ListItemText>Speakers</ListItemText>
          </ListItemButton>
        </List>
      </Card>
      <Card sx={{ mt: 1.5, boxShadow: (theme) => theme.shadows[1] }}>
        <List>
          <ListItem>
            <Typography variant='overline'>Utilities</Typography>
          </ListItem>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/users`)}
            selected={location.pathname.includes('/admin/users')}
          >
            <ListItemIcon>
              {location.pathname.includes('/admin/users') ? (
                <SupervisedUserCircleIcon />
              ) : (
                <SupervisedUserCircleOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>Users</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/tags`)}
            selected={location.pathname.includes('/admin/tags')}
          >
            <ListItemIcon>
              {location.pathname.includes('/admin/tags') ? (
                <LoyaltyIcon />
              ) : (
                <LoyaltyOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>Tags</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/access`)}
            selected={location.pathname.includes('/admin/access')}
          >
            <ListItemIcon>
              {location.pathname.includes('/admin/access') ? <LockIcon /> : <LockOutlinedIcon />}
            </ListItemIcon>
            <ListItemText>Access</ListItemText>
          </ListItemButton>
          {/* <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/integrations`)}
            selected={location.pathname.includes('/admin/integrations')}
          >
            <ListItemIcon>
              {location.pathname.includes('/admin/integrations') ? (
                <WidgetsIcon />
              ) : (
                <WidgetsOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>Integrations</ListItemText>
          </ListItemButton> */}
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/automation_tool`)}
            selected={location.pathname.includes('/admin/automation_tool')}
          >
            <ListItemIcon>
              {location.pathname.includes('/admin/automation_tool') ? (
                <MailIcon />
              ) : (
                <MailOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>Automation</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/analytics`)}
            selected={location.pathname.includes('/admin/analytics')}
          >
            <ListItemIcon>
              {location.pathname.includes('/admin/analytics') ? (
                <AnalyticsIcon />
              ) : (
                <AnalyticsOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>Analytics</ListItemText>
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate(`/${siteSlug}/admin/import-export`)}
            selected={location.pathname.includes('/admin/import-export')}
          >
            <ListItemIcon>
              {location.pathname.includes('/admin/import-export') ? (
                <CachedIcon />
              ) : (
                <CachedOutlinedIcon />
              )}
            </ListItemIcon>
            <ListItemText>Import</ListItemText>
          </ListItemButton>

          {user?.email.includes('junolive.co') && (
            <ListItemButton
              onClick={() => navigate(`/${siteSlug}/admin/super_admin`)}
              selected={location.pathname.includes('/admin/super_admin')}
            >
              <ListItemIcon>
                {location.pathname.includes('/admin/super_admin') ? (
                  <LockIcon />
                ) : (
                  <LockOutlinedIcon />
                )}
              </ListItemIcon>
              <ListItemText>Developers</ListItemText>
            </ListItemButton>
          )}
        </List>
      </Card>
    </>
  );
};

export default SideNav;
