import React from 'react';
import * as Yup from 'yup';
import { Groups } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { string } from 'yup';
import { CommunityGroup } from '@juno/client-api/model';
import { slugify } from '@juno/utils';

export interface GroupsForm {
  title: string;
  slug: string;
}
interface FormDialogProps {
  open: boolean;
  onSave: (group: GroupsForm | undefined) => void;
  onClose: () => void;
  isSaving: boolean;
  allGroups: CommunityGroup[] | undefined;
}

const FormDialog: React.FC<FormDialogProps> = ({ open, onClose, onSave, isSaving, allGroups }) => {
  const INITIAL_FORM_STATE = {
    title: '',
    slug: '',
  };

  const changeHandler =
    (formik: FormikProps<GroupsForm>) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target;
      if (target.name === 'title') {
        // Update slug value based on title update for new pages
        formik.setFieldValue('slug', slugify(target.value));
        formik.handleChange(e);
      }
      if (target.name === 'slug') {
        // Require slug to follow slugify rules (alphanumeric, dashes, underscores, and uppercase here)
        formik.setFieldValue('slug', slugify(target.value, true));
      }
    };

  const validationSchema = Yup.object().shape({
    title: string().max(90, 'Title character limit: 90'),
    slug: string()
      .max(90, 'Slug character limit: 90')
      .test('title-unique-validation', 'This field must be unique', function test(value) {
        if (value && value.length > 0) {
          const filteredGroups = allGroups?.filter((c) => c.slug === value) ?? [];
          return filteredGroups.length <= 0;
        } else {
          return true;
        }
      }),
  });

  return (
    <Formik
      initialValues={{ ...INITIAL_FORM_STATE }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        onSave(values);
        actions.resetForm();
      }}
      enableReinitialize={true}
    >
      {(props) => (
        <Form>
          <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create Group</DialogTitle>
            <DialogContent>
              <TextField
                name='title'
                label='Title'
                value={props.values.title || ''}
                onChange={changeHandler(props)}
                fullWidth
                sx={{ mb: 2, mt: 1 }}
                error={!!props.errors.title}
                helperText={props.errors.title}
              />
              <TextField
                name='slug'
                label='Slug'
                value={props.values.slug || ''}
                onChange={changeHandler(props)}
                error={!!props.errors.slug}
                helperText={props.errors.slug}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <Button
                  disabled={isSaving}
                  onClick={() => {
                    props.resetForm();
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  disabled={!(props.dirty && props.isValid)}
                  loading={isSaving}
                  variant='contained'
                  onClick={() => props.handleSubmit()}
                >
                  Save
                </LoadingButton>
              </Box>
            </DialogActions>
          </Dialog>
        </Form>
      )}
    </Formik>
  );
};

export default FormDialog;
