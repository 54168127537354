import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { Tag, Thread } from '@juno/client-api/model';
import { DialogAriaWrapper } from '@juno/ui';
import ThreadEditor from '../../Thread/ThreadEditor';

interface CreateNewPostDialogProps {
  handleSubmit: any;
  handleCancel: any;
  siteId: string;
  platformId: string;
  open: boolean;
}
const CreateNewPostDialog: React.FC<CreateNewPostDialogProps> = ({
  handleSubmit,
  handleCancel,
  siteId,
  platformId,
  open,
}) => {
  return (
    <DialogAriaWrapper
      id={`create_new_post_dialog`}
      open={open}
      fullWidth={true}
      maxWidth={'sm'}
      disableEnforceFocus
    >
      <DialogTitle sx={{ pb: 0 }}>Create Post</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent id='create_new_post_dialog-dialog-description'>
        <ThreadEditor
          platformId={platformId}
          siteId={siteId}
          threadToEdit={{} as Thread}
          handleUpdate={handleSubmit}
          saveBtnText='Post'
          handleCancel={handleCancel}
        />
      </DialogContent>
    </DialogAriaWrapper>
  );
};
export default CreateNewPostDialog;
