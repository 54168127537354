export const ACCESS_STATE_UNKNOWN = 'unknown';
export const ACCESS_STATE_LOBBY = 'lobby';
export const ACCESS_STATE_FULL = 'full';
export const ACCESS_STATE_NONE = 'none';

export const MEETING_STATE_JOINED = 'joined-meeting';

export const VIDEO_QUALITY_AUTO = 'auto';
export const VIDEO_QUALITY_HIGH = 'high';
export const VIDEO_QUALITY_LOW = 'low';
export const VIDEO_QUALITY_VERY_LOW = 'very-low';
export const VIDEO_QUALITY_BANDWIDTH_SAVER = 'bandwidth-saver';

export const DEFAULT_ASPECT_RATIO = 16 / 9;

/** START APP_MESSAGE_ACTIONS */
export enum QA_ACTIONS {
  ASK_QUESTION = 'ask-question',
  ANSWER_QUESTION = 'answer-question',
  DELETE_QUESTION = 'delete-question',
  MARK_AS_ANSWERED = 'mark-as-answered',
  DELETE_ANSWER = 'delete-answer',
  UPVOTE = 'upvote',
}

export enum CHAT_ACTIONS {
  SEND_MESSAGE = 'send-message',
  DELETE_MESSAGE = 'delete-message',
}

export enum BREAKOUT_ACTIONS {
  SEND_TO_ROOM = 'send-to-room',
  RETURN_TO_MAIN = 'return-to-main',
}
export enum EMOJI_ACTIONS {
  SEND_EMOJI = 'send-emoji',
}
/** END APP_MESSAGE_ACTIONS */

// Aside types
export const CHAT_ASIDE = 'chat';
export const PEOPLE_ASIDE = 'people';
export const QA_ASIDE = 'qanda';
export const SUBBREAKOUT_ASIDE = 'subbreakout';

export enum EndRoomOptions {
  EndImmediately = 0,
  EndIn10Seconds = 1,
  EndIn30Seconds = 2,
  EndIn1Minute = 3,
}

export enum EndRoomOptionLabels {
  EndImmediately = 'End Immediately',
  EndIn10Seconds = 'End in 10 seconds',
  EndIn30Seconds = 'End in 30 seconds',
  EndIn1Minute = 'End in 1 minute',
}
