import React from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { isAfter, isBefore } from 'date-fns';
import { Form, Formik } from 'formik';
import { string } from 'yup';
import { ReoccurrenceEnum, Session, TypeA36Enum } from '@juno/client-api/model';
import { slugify } from '@juno/utils';
import DateTimePicker from '../CustomDateTimePicker';
import DialogAriaWrapper from '../DialogAriaWrapper';
import { GenericFormikInput } from '../GenericInputComponents';

interface CreateNewSessionDialogProps {
  open: boolean;
  onSave: (session: Session) => void;
  onClose: () => void;
  isSaving: boolean;
  allSessions: Session[];
  eventType: TypeA36Enum;
}

const CreateNewSessionDialog: React.FC<CreateNewSessionDialogProps> = ({
  open,
  onClose,
  onSave,
  isSaving,
  allSessions,
}) => {
  const currentDate = new Date();
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);
  const INITIAL_FORM_STATE = {
    date_start: currentDate.toISOString(),
    date_end: new Date(currentDate.setHours(currentDate.getHours() + 1)).toISOString(),
    reoccurrence: ReoccurrenceEnum.once,
    time_end: new Date(currentDate.setHours(currentDate.getHours() + 1)).toISOString(),
  } as Session;

  const validationSchema = Yup.object().shape({
    title: string().max(90, 'Title character limit is 90').required('Title is required'),
    slug: string()
      .max(90, 'Slug character limit is 90')
      .required('Slug is required')
      .test(
        'slug_no_space_or_special',
        'Slug cannot have spaces or special characters',
        function test(value) {
          if (value && value.length > 0) {
            return !/[^a-zA-Z0-9-_]/.test(value);
          } else {
            return true;
          }
        },
      )
      .test('title-unique-validation', 'This field must be unique', function test(value) {
        if (value && value.length > 0) {
          const filteredSessions = allSessions?.filter((c) => c.slug === value) ?? [];
          return filteredSessions.length <= 0;
        } else {
          return true;
        }
      }),
    date_start: Yup.string()
      .required('Start date is required')
      .test('date_start', 'Start date must be before end date', function test(this, value) {
        if (!this?.parent?.date_end) return true;
        if (value) {
          return isBefore(new Date(value), new Date(this?.parent?.date_end));
        } else {
          return false;
        }
      }),
    date_end: Yup.string().test(
      'date_end',
      'End date must be after the start date',
      function test(this, value) {
        if (value) {
          return isAfter(new Date(value), new Date(this?.parent?.date_start));
        } else {
          return true;
        }
      },
    ),
  });

  return (
    <Formik
      initialValues={{ ...INITIAL_FORM_STATE }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        onSave(values as Session);
        actions.resetForm();
      }}
      enableReinitialize={true}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          dirty,
          isValid,
          resetForm,
          setFieldTouched,
        } = props;
        return (
          <Form>
            <DialogAriaWrapper
              id='create-new-session-dialog'
              open={open}
              onClose={() => {
                resetForm();
                onClose();
              }}
            >
              <DialogTitle id='create-new-session-dialog-title'>Create a Session</DialogTitle>
              <DialogContent id='create-new-session-dialog-description'>
                <GenericFormikInput
                  label='Title'
                  name='title'
                  value={values.title || ''}
                  onChange={(e: any) => {
                    setFieldValue('slug', slugify(e.target.value));
                    handleChange(e);
                  }}
                  fullWidth
                  sx={{ mb: 2, mt: 1 }}
                />
                <GenericFormikInput
                  name='slug'
                  label='Slug'
                  value={values.slug || ''}
                  onChange={handleChange}
                  fullWidth
                />
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12}>
                    <DateTimePicker
                      reoccurrence={values.reoccurrence}
                      onChange={(field, date): void => {
                        handleChange({ target: { name: field, value: date } });
                        setFieldValue(field, date);
                        setFieldTouched(field, true);
                      }}
                      selectedStartDate={new Date(values.date_start || 0)}
                      selectedEndDate={new Date(values.date_end || 0)}
                      selectedEndTime={new Date(values.time_end || 0)}
                      errors={errors}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                  <Button
                    disabled={isSaving}
                    onClick={() => {
                      resetForm();
                      onClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    disabled={!(dirty && isValid)}
                    loading={isSaving}
                    variant='contained'
                    onClick={() => handleSubmit()}
                  >
                    Save
                  </LoadingButton>
                </Box>
              </DialogActions>
            </DialogAriaWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateNewSessionDialog;
