import React, { useEffect, useRef, useState } from 'react';
import { SubdirectoryArrowRight } from '@mui/icons-material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  getChildThreads,
  getContentUrl,
  useBlockUser,
  useDeleteThread,
  useLikeUnlikeThread,
  useUpdateThread,
} from '@juno/client-api';
import { useGetUserRoleMap } from '@juno/client-api/helpers';
import {
  CommunityUser,
  JunoUser,
  SearchContentTypeEnum,
  Tag,
  Thread,
} from '@juno/client-api/model';
import { FileTypeIcon, UserQuickView } from '@juno/ui';
import {
  MutationAction,
  getContentRoute,
  getTimeSinceCreated,
  onMutation,
  snackOptions,
  truncateFileName,
} from '@juno/utils';
import { useBreakpoint } from '@juno/utils/hooks';
import ThreadEditor from './ThreadEditor';
import ThreadPostCardActions from './ThreadPost/ThreadPostCardActions';
import ThreadPostCardContent from './ThreadPost/ThreadPostCardContent';
import { ThreadPostCard } from './ThreadPost/styles';

interface ThreadPostProps {
  siteId: string;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  platformId: string;
  thread: Thread;
  handleOpenPostDetails: (thread: Thread) => void;
  replyClickCallback?: (replyTag: JunoUser) => void;
  onNavigate?: (props: any) => void;
  refetchParentThread?: () => void;
  handleTagFilter?: (tag: Tag) => void;
  userCanInteract?: boolean;
  dialogRef?: React.RefObject<HTMLDivElement>;
  showingRepliesAsSingleThread?: boolean;
}

const MAX_CONTENT_HEIGHT = 200;

const ThreadPost: React.FC<ThreadPostProps> = ({
  siteId,
  setData,
  platformId,
  thread,
  handleOpenPostDetails,
  onNavigate,
  refetchParentThread,
  handleTagFilter,
  dialogRef,
  userCanInteract = true,
  showingRepliesAsSingleThread = false,
}) => {
  const { xs, sm } = useBreakpoint();
  const navigateRoute = useNavigate();

  const { user: currentUser } = useGetUserRoleMap(siteId);
  const {
    author,
    parent_thread_id,
    body,
    image,
    date_created,
    liked_users,
    num_likes,
    num_replies,
    downloads,
    tags,
    pinned,
  } = thread;
  const isReply = !!parent_thread_id;
  const currentCommunityUser: CommunityUser = {
    id: currentUser?.id || '',
    first_name: currentUser?.first_name || '',
    last_name: currentUser?.last_name || '',
    email: currentUser?.email || '',
    icon: currentUser?.icon,
    avatar: currentUser?.avatar,
    role: currentUser?.role,
  };

  const [isEditing, setIsEditing] = useState(false);
  const [replyList, setReplyList] = useState<Thread[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [loadingReplies, setLoadingReplies] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [postHeight, setPostHeight] = useState<string | number>(MAX_CONTENT_HEIGHT);
  const { enqueueSnackbar } = useSnackbar();
  const contentRef = useRef<HTMLElement | null>(null);

  const viewReplies = (thread: Thread) => {
    setLoadingReplies(true);
    getChildThreads(siteId, thread.id).then((res) => {
      const sorted = res.sort((a, b) => {
        return (
          new Date(a?.date_created ?? '').getTime() - new Date(b?.date_created ?? '').getTime()
        );
      });
      setReplyList(sorted);
      setLoadingReplies(false);
    });
  };

  const userLiked = liked_users
    ? liked_users?.filter((user) => {
        return user.id === currentUser?.id;
      })
    : [];
  const isMobile = xs || sm;

  const refetch = () => {
    refetchParentThread && refetchParentThread();
    if (showAll) {
      viewReplies(thread);
    }
  };

  useEffect(() => {
    const container = contentRef.current;
    const content = container?.querySelector('.content') as HTMLElement | null;

    if (container && content) {
      if (content.scrollHeight > container.clientHeight) {
        content.style.webkitBoxOrient = 'vertical';
        content.style.display = '-webkit-box';
        content.style.webkitLineClamp = isExpanded ? 'unset' : '7'; // 7 lines is just around 180px tall
      }
      setPostHeight(content.scrollHeight);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (showAll) {
      viewReplies(thread);
    }
  }, [num_replies, showAll]);

  const likeUnlike = useLikeUnlikeThread(onMutation(MutationAction.UPDATE, '', refetch));
  const threadUpdate = useUpdateThread(onMutation(MutationAction.UPDATE, '', refetch));
  const threadDelete = useDeleteThread(onMutation(MutationAction.DELETE, 'Thread', refetch));

  // TODO future build out
  const handleBlockUser = useBlockUser();
  // onMutation(MutationAction.UPDATE, '', () => {
  //   enqueueSnackbar(
  //     'User has been blocked. You can manage your blocked user list from your profile page.',
  //     snackOptions('success'),
  //   );
  //   refetch();
  // }),

  const handleLikeButtonClick = async () => {
    if (userCanInteract) {
      // the server handles wether the user has liked/disliked
      const pageSlug = location.pathname.split('/').pop() || '';
      const newThread = await likeUnlike.mutateAsync({
        siteId: siteId,
        threadId: thread.id,
        data: {
          ...thread,
          metadata: {
            page_slug: pageSlug,
          },
        },
      });
      setData((old) => {
        const updated = old.map((t) => {
          if (t.id === newThread.id) {
            return newThread;
          }
          return t;
        });
        return updated;
      });
    }
  };

  const onBlockUserConfirm = () => {
    if (author?.id) {
      handleBlockUser.mutate({ siteId: siteId, userId: author.id });
    }
  };

  const onReportContentConfirm = () => {
    if (author?.id) {
      // handleBlockUser.mutate({ siteId: siteId, userId: author.id });
    }
  };

  const onReportUserConfirm = () => {
    if (author?.id) {
      // handleBlockUser.mutate({ siteId: siteId, userId: author.id });
    }
  };

  const onDeleteConfirm = async () => {
    await threadDelete.mutateAsync({ siteId: siteId, threadId: thread.id });
    setData((old) => {
      return old.filter((t) => t.id !== thread.id);
    });
  };

  const navigateToProfile = () => {
    navigateRoute(getContentRoute(siteId, author?.id || '', SearchContentTypeEnum.user));
  };

  const handleFileDownload = (url: string, fileName: string) => {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileName || 'download';
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const [userQuickViewAnchorEl, setUserQuickViewAnchorEl] = useState<null | HTMLElement>(null);
  const [userQuickViewUserId, setUserQuickViewUserId] = useState<string>('');

  const clickHandler = (e: any) => {
    const target = e.target as HTMLElement;
    const userId = target.getAttribute('data-mention-id');
    if (userId) {
      setUserQuickViewAnchorEl(target);
      setUserQuickViewUserId(userId);
    }
  };
  const [currentQueryParameters] = useSearchParams();
  const threadIdParam = currentQueryParameters.get('thread_id');
  const isHighlighted = threadIdParam === thread.id;

  const handlePinUnpin = async () => {
    try {
      const newThread = await threadUpdate.mutateAsync({
        siteId,
        threadId: thread.id,
        data: {
          ...thread,
          pinned: !thread.pinned,
        },
      });
      setData((old) => {
        return old
          .map((t) => {
            if (t.id === newThread.id) {
              return newThread;
            }
            return t;
          })
          .sort((a, b) => {
            // sort by pinned first, then by date created
            if (a.pinned && !b.pinned) {
              return -1;
            }
            if (!a.pinned && b.pinned) {
              return 1;
            }
            return new Date(b.date_created).getTime() - new Date(a.date_created).getTime();
          });
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onMakeAnnouncment = async () => {
    try {
      const newThread = await threadUpdate.mutateAsync({
        siteId,
        threadId: thread.id,
        data: {
          ...thread,
          announcement: !thread.announcement,
          date_announced: new Date().toISOString(),
        },
      });
      setData((old) => {
        return old.map((t) => {
          if (t.id === newThread.id) {
            return newThread;
          }
          return t;
        });
      });
    } catch (e) {
      console.error(e);
    }
  };

  const location = useLocation();
  const handleShareClick = async () => {
    if (!navigator.clipboard) {
      console.error('Clipboard API not supported');
      return;
    }

    const urlResponse = await getContentUrl(
      siteId,
      thread.id,
      location.pathname.split('/').pop() || '',
    );
    const locationSplit = window.location.href.split('/');
    locationSplit.pop();
    const locationHref = locationSplit.join('/');
    const contentUrl = `${locationHref}${urlResponse.url}`;

    navigator.clipboard
      .writeText(contentUrl)
      .then(() => {
        enqueueSnackbar('Copied!', snackOptions('success'));
      })
      .catch((error) => {
        enqueueSnackbar('Copy Failed!', snackOptions('error'));
        console.error('Failed to copy URL to clipboard:', error);
      });
  };

  return (
    <Box>
      {!isEditing && (
        <motion.div
          animate={{
            scale: isHighlighted ? [1, 1.1, 1] : 1,
          }}
        >
          <ThreadPostCard
            isReply={isReply}
            variant={'outlined'}
            sx={{
              position: 'relative',
              overflow: 'visible',
              boxShadow: (theme) => theme.shadows[1],
            }}
          >
            <ThreadPostCardContent
              pinned={pinned || false}
              announcement={thread.announcement || false}
              handlePinUnpin={handlePinUnpin}
              onMakeAnnouncment={onMakeAnnouncment}
              threadId={thread.id}
              body={
                <>
                  {image && (
                    <Box
                      display={'flex'}
                      justifyContent={'center'}
                      mt={2}
                      sx={{ borderRadius: 2, overflow: 'hidden' }}
                    >
                      {/* // ! Can I check if the url has 'cloudinary' in it to determine if this is an image vs a video? Images get uploaded to cloudinary, videos go to S3...*/}
                      {image.search('cloudinary') >= 0 ? (
                        <img
                          style={{ width: '100%' }}
                          src={image}
                          loading='lazy'
                          // TODO srcSet="https://images.unsplash.com/photo-1527549993586-dff825b37782?auto=format&fit=crop&w=286&dpr=2 2x"
                          // TODO alt={metadata?.image_metadata?.alt_text || 'Image'}
                        />
                      ) : (
                        <video
                          preload='metadata'
                          muted={true}
                          style={{ maxHeight: '400px', maxWidth: '100%' }}
                          src={image}
                          controls
                          // TODO alt={metadata?.video_metadata?.alt_text || 'Video'}
                        />
                      )}
                    </Box>
                  )}

                  <Box position={'relative'}>
                    <Box
                      maxHeight={isExpanded ? 'auto' : MAX_CONTENT_HEIGHT}
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}
                      ref={contentRef}
                    >
                      <div
                        className='content'
                        onClick={clickHandler}
                        dangerouslySetInnerHTML={{
                          __html: body as unknown as string,
                        }}
                      ></div>
                      {Boolean(userQuickViewAnchorEl) && (
                        <ClickAwayListener onClickAway={() => setUserQuickViewAnchorEl(null)}>
                          <UserQuickView
                            platformId={platformId}
                            anchorEl={userQuickViewAnchorEl}
                            userId={userQuickViewUserId}
                            open={Boolean(userQuickViewAnchorEl)}
                            onViewProfile={(userId: string): void => {
                              navigateRoute(
                                getContentRoute(siteId, userId, SearchContentTypeEnum.user),
                              );
                            }}
                            onMessageUser={function (userId: string): void {
                              throw new Error('Function not implemented.');
                            }}
                            dialogRef={dialogRef}
                          />
                        </ClickAwayListener>
                      )}
                    </Box>
                    {(typeof postHeight === 'string' || postHeight >= MAX_CONTENT_HEIGHT) && (
                      <Box>
                        <Button
                          size='small'
                          variant='text'
                          aria-label='See more'
                          color='primary'
                          sx={{ mt: 1 }}
                          onClick={() => {
                            setIsExpanded((prevExpanded) => !prevExpanded);
                          }}
                        >
                          {isExpanded ? 'See Less' : 'See More'}
                        </Button>
                      </Box>
                    )}
                  </Box>
                  {downloads && downloads.length > 0 && (
                    <Grid container spacing={1} p={1}>
                      {downloads.map((file) => {
                        return (
                          <Grid item key={file.title}>
                            <Card
                              variant='outlined'
                              sx={{
                                justifyContent: 'space-between',
                                display: 'flex',
                                alignItems: 'center',
                                pl: 1,
                                width: '200px',
                              }}
                            >
                              <FileTypeIcon fileType={file.filetype} />
                              <Tooltip title={file.title} placement='top'>
                                <Typography component='span' sx={{ fontSize: '12px' }}>
                                  {truncateFileName(file.title)}
                                </Typography>
                              </Tooltip>
                              <IconButton
                                disabled={!userCanInteract}
                                component='span'
                                onClick={() => {
                                  handleFileDownload(file.url, file.title);
                                }}
                                aria-label='Download file'
                              >
                                <FileDownloadOutlinedIcon
                                  sx={{ fontSize: '16px' }}
                                  color={userCanInteract ? 'primary' : 'inherit'}
                                />
                              </IconButton>
                            </Card>
                          </Grid>
                        );
                      })}
                    </Grid>
                  )}
                </>
              }
              setIsEditing={setIsEditing}
              author={author}
              currentUser={currentCommunityUser}
              navigateToProfile={navigateToProfile}
              onDeleteConfirm={onDeleteConfirm}
              onBlockUserConfirm={onBlockUserConfirm}
              onReportContentConfirm={onReportContentConfirm}
              onReportUserConfirm={onReportUserConfirm}
              isReply={isReply}
            />
            {tags && tags?.length > 0 && (
              <Box>
                {tags.map((t) => (
                  <Chip
                    key={t.id}
                    label={t.value}
                    sx={{ ml: 1 }}
                    onClick={() => handleTagFilter && handleTagFilter(t)}
                  />
                ))}
              </Box>
            )}
            <ThreadPostCardActions
              handleShareClick={handleShareClick}
              timeSinceCreatedText={getTimeSinceCreated(isMobile, date_created)}
              userCanInteract={userCanInteract}
              onReplyButtonClick={() => handleOpenPostDetails(thread)}
              handleLikeButtonClick={handleLikeButtonClick}
              isLiked={userLiked.length > 0}
              numLikes={num_likes}
              replyCount={num_replies}
              isAuthor={author?.id === currentUser?.id}
              isReply={isReply}
            />
          </ThreadPostCard>
        </motion.div>
      )}
      {isEditing && (
        <ThreadPostCard isReply={isReply}>
          <ThreadEditor
            platformId={platformId}
            siteId={siteId}
            threadToEdit={thread}
            handleUpdate={async (updatedThread: Thread) => {
              const data = { ...thread, ...updatedThread };
              const newThread = await threadUpdate.mutateAsync({
                siteId: siteId,
                data: data,
                threadId: thread.id,
              });
              setIsEditing(false);
              setData((old) => {
                return old.map((thread) => {
                  if (thread.id === newThread.id) {
                    return newThread;
                  }
                  return thread;
                });
              });
            }}
            handleCancel={() => setIsEditing(false)}
            showActionMenu={isReply ? false : true}
            showToolbar={isReply ? false : true}
          />
        </ThreadPostCard>
      )}
      {num_replies > 0 && isReply && !showingRepliesAsSingleThread && (
        <Box>
          {loadingReplies ? (
            <Box sx={{ ml: 6 }}>
              <CircularProgress size={20} />
            </Box>
          ) : (
            <Button
              aria-label={showAll ? 'Hide replies' : `View replies`}
              color='primary'
              size='small'
              startIcon={showAll ? null : <SubdirectoryArrowRight />}
              sx={{ textTransform: 'none', mb: -1, ml: 4 }}
              onClick={() => {
                if (showAll) {
                  setReplyList([]);
                  setShowAll(false);
                } else {
                  viewReplies(thread);
                  setShowAll(true);
                }
              }}
            >
              {showAll ? 'Hide replies' : `View replies`}
            </Button>
          )}
        </Box>
      )}
      {replyList &&
        showAll &&
        replyList.map((reply) => {
          return (
            <Box component='div' key={reply.id} sx={{ ml: 4, display: 'flex' }}>
              <Box sx={{ width: '-webkit-fill-available' }}>
                <ThreadPost
                  setData={setData}
                  platformId={platformId}
                  thread={reply}
                  key={`reply-${reply.id}`}
                  siteId={siteId}
                  onNavigate={onNavigate}
                  refetchParentThread={refetch}
                  handleOpenPostDetails={() => handleOpenPostDetails(reply)}
                  dialogRef={dialogRef}
                />
              </Box>
            </Box>
          );
        })}
    </Box>
  );
};

export default ThreadPost;
