import React, { createContext, useEffect, useMemo, useState } from 'react';
import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProvider } from 'react-oidc-context';
import {
  useGetFeatureConfigsPublic,
  useGetPlatformBySubdomain,
  useGetSiteBySlug,
} from '@juno/client-api';
import { FeatureConfigConfig, FeatureConfigTypeEnum, Platform, Site } from '@juno/client-api/model';

export type PlatformContextType = {
  platform: Platform | null;
  site: Site | null;
};

export interface AuthenticationFeatureConfig extends FeatureConfigConfig {
  idp_hint?: string;
}

export const PlatformContext = createContext<PlatformContextType | null>(null);

type PlatformProviderProps = {
  children: React.ReactNode;
};

export const PlatformProvider: React.FC<PlatformProviderProps> = ({ children }) => {
  const [platformSubdomain, setPlatformSubdomain] = useState('');
  const [siteSlug, setSiteSlug] = useState('');

  useEffect(() => {
    const { origin, hostname, host, pathname, protocol, port } = window.location;
    const subdomain = host.split('.')[0];
    setPlatformSubdomain(process.env.NX_URL === origin ? '' : subdomain);

    // Set Site Slug
    const pathSiteSlug = pathname.split('/')[1];
    setSiteSlug(pathSiteSlug);
  }, []);

  const { data: platform, isLoading: isPlatformLoading } = useGetPlatformBySubdomain(
    platformSubdomain,
    {},
    { query: { enabled: !!platformSubdomain } },
  );

  useEffect(() => {
    if (platform && !siteSlug) {
      const defaultSiteSlug = platform.default_site?.slug;
      setSiteSlug(defaultSiteSlug || '');
    }
  }, [platform, siteSlug]);

  const { data: site } = useGetSiteBySlug(
    platform?.subdomain || '',
    siteSlug,
    {},
    { query: { enabled: !!platform && !!siteSlug } },
  );

  const { data: featureConfigs, isLoading: isLoadingConfig } = useGetFeatureConfigsPublic(
    site?.id || '',
    {
      filter: {
        type: FeatureConfigTypeEnum.authentication,
      },
    },
    { query: { enabled: !!site?.id } },
  );

  const authConfig = useMemo(() => {
    if (isLoadingConfig) {
      return undefined;
    }
    return (featureConfigs?.[0]?.config || {}) as AuthenticationFeatureConfig;
  }, [featureConfigs, isLoadingConfig]);

  const oidcSettings = useMemo(() => {
    if (!platform || !site || !authConfig) return null;

    const { protocol, hostname, port } = window.location;
    const baseUri = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    const redirectUri = `${baseUri}/${site?.slug}/callback`;
    const idp_hint = authConfig?.idp_hint;

    return {
      authority: process.env.NX_FUSIONAUTH_URL || 'UNSET_NX_FUSIONAUTH_URL',
      client_id: platform.id || 'UNSET_CLIENT_ID',
      redirect_uri: redirectUri,
      response_type: 'code',
      scope: 'openid profile',
      logout_url: baseUri,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      ...(idp_hint ? { extraQueryParams: { idp_hint: idp_hint } } : {}),
    };
  }, [platform, site, authConfig]);

  if (isPlatformLoading || !platform || !oidcSettings || !site) {
    return <div></div>;
  }

  return (
    <PlatformContext.Provider value={{ platform, site }}>
      <AuthProvider {...oidcSettings}>{children}</AuthProvider>
    </PlatformContext.Provider>
  );
};

export default PlatformProvider;
