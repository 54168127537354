import React from 'react';
import { FormControl } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { Form, Formik } from 'formik';
import { Course, Lesson, Question, Tag } from '@juno/client-api/model';
import { ConfirmDialog, DefaultDeleteDialog } from '@juno/ui';
import ImportValidationDialog from '../ImportValidationDialog';
import { ImportArrayModel } from '../utils';
import ImportDataGrid from './ImportDataGrid';

interface ImportFormikProps {
  validate: (values: any) => void;
  importFormRef: React.MutableRefObject<any>;
  selectionModel: GridSelectionModel;
  setSelectionModel: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  tableRows: ImportArrayModel[];
  columns: any[];
  validationOpen: boolean;
  setValidationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deleteOpen: boolean;
  setDeleteOpen: React.Dispatch<React.SetStateAction<boolean>>;
  confirmationOpen: boolean;
  setConfirmationOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handlePublishImport: () => void;
  deleteItem: (rowIndex: number, setFieldValue: any) => void;
  deleteAll: (remainingValues: any, setFieldValue: any) => void;
  rows: any[];
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  setTableRows: React.Dispatch<React.SetStateAction<ImportArrayModel[]>>;
  totalErrors: number;
  originalErrorRowRef: React.MutableRefObject<never[]>;
  courses: Course[];
  siteTags: Tag[];
  lessonList: Lesson[];
  questionList: Question[];
  bucket: string;
}
const ImportFormik: React.FC<ImportFormikProps> = ({
  validate,
  importFormRef,
  selectionModel,
  setSelectionModel,
  tableRows,
  columns,
  validationOpen,
  setValidationOpen,
  deleteOpen,
  setDeleteOpen,
  confirmationOpen,
  setConfirmationOpen,
  handlePublishImport,
  deleteItem,
  deleteAll,
  rows,
  setRows,
  setTableRows,
  totalErrors,
  originalErrorRowRef,
  courses,
  siteTags,
  lessonList,
  questionList,
  bucket,
}) => {
  return (
    <Formik
      validateOnMount={true}
      validate={validate}
      enableReinitialize={true}
      innerRef={importFormRef}
      initialValues={{
        rows: rows,
      }}
      onSubmit={(_values) => {}}
    >
      {/* {({ values, errors, setFieldValue, handleChange, dirty, ... full Formik bag }) => { */}
      {({ ...props }) => {
        return (
          <Form>
            <FormControl fullWidth>
              <ImportDataGrid
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                tableRows={tableRows}
                columns={columns}
              />
              {validationOpen && (
                <ImportValidationDialog
                  originalErrorRowRef={originalErrorRowRef}
                  courseList={courses ?? []}
                  siteTags={siteTags ?? []}
                  lessonList={lessonList ?? []}
                  questionList={questionList ?? []}
                  open={true}
                  bucket={bucket}
                  handleClose={setValidationOpen}
                  totalErrors={totalErrors}
                  handleDelete={(rowIndex: number) => {
                    deleteItem(rowIndex, props.setFieldValue);
                    props.handleReset();
                  }}
                  handleDeleteAll={(remainingValues: any) => {
                    deleteAll(remainingValues, props.setFieldValue);
                    props.handleReset();
                  }}
                  {...props}
                />
              )}

              {deleteOpen && (
                <DefaultDeleteDialog
                  deleteTitle={`${
                    selectionModel.length > 1 ? 'these items' : 'this item'
                  } from your csv?`}
                  deleteSubtitle='To recover this data, you will need to start over and re-upload your csv.'
                  buttonText={selectionModel.length > 1 ? 'Delete All' : 'Delete Item'}
                  handleClose={() => {
                    setDeleteOpen(false);
                  }}
                  handleDelete={() => {
                    const newRows = rows.filter((row, index) => {
                      return selectionModel.indexOf(index) == -1;
                    });
                    // reset our rowIds
                    const resetIds = newRows.map((row: any, i: number) => ({
                      ...row,
                      rowId: i,
                    }));
                    originalErrorRowRef.current = [];
                    setRows(resetIds);
                    setTableRows(resetIds);
                    props.setFieldValue('rows', resetIds);
                    props.handleReset();

                    setDeleteOpen(false);
                    setSelectionModel([]);
                  }}
                />
              )}
              {confirmationOpen && (
                <ConfirmDialog
                  open={confirmationOpen}
                  title={`Import ${bucket} CSV`}
                  children={`Are you sure you want to import the selected data?`}
                  onSave={handlePublishImport}
                  onCancel={() => setConfirmationOpen(false)}
                />
              )}
            </FormControl>
          </Form>
        );
      }}
    </Formik>
  );
};
export default ImportFormik;
