import { bool, mixed, number, string } from 'yup';
import { LessonPart, Question } from '@juno/client-api/utils';
import { validateImportDateString } from './validations';

//** VVVVVVVVVVVVVVVVVVV ** START YUP VALIDATIONS ** VVVVVVVVVVVVVVVVVVV */

//*** START COURSE MODEL */
const v_Courses_title = string()
  .required('Course Title is required')
  .ensure()
  .max(90, 'Field must not exceed 90 characters');
const v_Courses_abstract = string().ensure().max(78, 'Field must not exceed 78 characters');
const v_Courses_enrollment_required = bool()
  .typeError('Enter either true or false; if nothing is selected, it will default to false')
  .nullable()
  .default(false);
const v_Courses_max_enrollments = number().typeError('Must be a numerical value').min(0).nullable();
const v_Courses_banner_video = string().url('Must be a valid URL').nullable();
const v_Courses_icon = string().url('Must be a valid URL').nullable();
const v_Courses_certificate = string()
  .url('Must be a valid URL')
  .nullable()
  .when('reward_certificate_upon_completion', {
    is: (reward_certificate_upon_completion: boolean) =>
      reward_certificate_upon_completion === true,
    then: string()
      .url()
      .typeError('Must be a valid URL')
      .nullable()
      .required('This is a required field when the reward_certificate_upon_completion is set'),
  });
const v_Courses_credits = number()
  .typeError('Must be a numerical value between 0.00-99.99')
  .min(0.0, 'Must be a numerical value between 0.00-99.99')
  .max(99.99, 'Must be a numerical value between 0.00-99.99')
  .nullable();
const v_Courses_passing_percent = mixed()
  .nullable()
  .test(
    'passing_percent',
    'Must be a numerical value between 0-100 with no decimals',
    function (passing_percent) {
      // check if the item has a % sign, if so, strip it out and check for number, otherwise just check for number
      let val = 0;
      if (passing_percent) {
        const stripped = String(passing_percent).replace('%', '');
        val = Number(stripped);
      }
      return val >= 0 && val <= 100;
    },
  );
const v_Courses_date_start = string()
  .nullable()
  .test('date_valid', 'Value must be in correct standard date format', function (value) {
    if (!value) return true;
    return validateImportDateString(value);
  })
  .test(
    'required',
    'This is a required field when the release date is set',
    function (this, value) {
      if (!this?.options?.context?.date_released) {
        return true;
      }
      if (!value) {
        return false;
      }
      return true;
    },
  )
  .test('on-or-after-release', 'Date must be on or after the release date', function (this, value) {
    const date_released = this?.options?.context?.date_released;
    if (!value || !date_released) {
      return true;
    }
    return new Date(value).getTime() >= new Date(date_released).getTime();
  });

const v_Courses_date_end = string()
  .nullable()
  .test('date_valid', 'Value must be in correct standard date format', function (value) {
    if (!value) return true;
    return validateImportDateString(value);
  })
  .test(
    'required',
    'This is a required field when the release date is set',
    function (this, value) {
      if (!this?.options?.context?.date_released) {
        return true;
      }
      if (!value) {
        return false;
      }
      return true;
    },
  )
  .test('after-start', 'Date must be after the start date', function (this, value) {
    const date_start = this?.options?.context?.date_start;
    if (!value || !date_start) {
      return true;
    }
    return new Date(value).getTime() > new Date(date_start).getTime();
  })
  .test('after-release', 'Date must be after the release date', function (this, value) {
    const date_released = this?.options?.context?.date_released;
    if (!value || !date_released) {
      return true;
    }
    return new Date(value).getTime() > new Date(date_released).getTime();
  });

const v_Courses_date_released = string()
  .nullable()
  .test('date_valid', 'Value must be in correct standard date format', function (value) {
    if (!value) return true;
    return validateImportDateString(value);
  })
  .test('on-or-before-start', 'Date must be on or before the start date', function (this, value) {
    const date_start = this?.options?.context?.date_start;
    if (!value || !date_start) {
      return true;
    }
    return new Date(value).getTime() <= new Date(date_start).getTime();
  })
  .test('before-end', 'Date must be before the end date', function (this, value) {
    const date_end = this?.options?.context?.date_end;
    if (!value || !date_end) {
      return true;
    }
    return new Date(value).getTime() < new Date(date_end).getTime();
  });

const v_Courses_date_open_enrollment = string()
  .nullable()
  .test('date_valid', 'Value must be in correct standard date format', function (value) {
    if (!value) return true;
    return validateImportDateString(value);
  })
  .test(
    'required',
    'This is a required field when the release date is set',
    function (this, value) {
      if (!this?.options?.context?.date_released) {
        return true;
      }
      if (!value) {
        return false;
      }
      return true;
    },
  )
  .test('on-or-after-release', 'Date must be on or after the release date', function (this, value) {
    const date_released = this?.options?.context?.date_released;
    if (!value || !date_released) {
      return true;
    }
    return new Date(value).getTime() >= new Date(date_released).getTime();
  })
  .test('before-end', 'Date must be before the end date', function (this, value) {
    const date_end = this?.options?.context?.date_end;
    if (!value || !date_end) {
      return true;
    }
    return new Date(value).getTime() < new Date(date_end).getTime();
  });

const v_Courses_date_lock_enrollment = string()
  .nullable()
  .test('date_valid', 'Value must be in correct standard date format', function (value) {
    if (!value) return true;
    return validateImportDateString(value);
  })
  .test(
    'required',
    'This is a required field when the release date is set',
    function (this, value) {
      if (!this?.options?.context?.date_released) {
        return true;
      }
      if (!value) {
        return false;
      }
      return true;
    },
  )
  .test('after-open', 'Date must be after the open enrollment date', function (this, value) {
    const date_open_enrollment = this?.options?.context?.date_open_enrollment;
    if (!value || !date_open_enrollment) {
      return true;
    }
    return new Date(value).getTime() > new Date(date_open_enrollment).getTime();
  })
  .test('on-or-before-end', 'Date must be on or before the end date', function (this, value) {
    const date_end = this?.options?.context?.date_end;
    if (!value || !date_end) {
      return true;
    }
    return new Date(value).getTime() <= new Date(date_end).getTime();
  });

const v_Courses_slug = string()
  .nullable()
  .required('This is a required field')
  .test(
    'course-slug-char-validation',
    'Special characters are not permitted, including spaces.',
    function (value) {
      const reg = new RegExp(/^\w*$/);
      if (!reg.test(value || '')) {
        return false;
      }
      return true;
    },
  );

const v_Courses_tags = mixed().nullable();
const v_Courses_scorm = string()
  .url()
  .nullable()
  .test('url', 'Must point to a .zip file', function (value) {
    if (value) {
      return value?.substr(value.length - 4).includes('.zip');
    }
    return true;
  });
//*** END COURSE MODEL */

//*** START COURSE RESOURCES MODEL */
const v_CourseResources_course_slug = string().required('This is a required field').nullable();
const v_CourseResources_title = string()
  .required('This is a required field')
  .nullable()
  .ensure()
  .max(67, 'Field must not exceed 67 characters');
const v_CourseResources_icon = string()
  .url('Must be a valid URL')
  .required('This is a required field')
  .nullable();
const v_CourseResources_description = string()
  .nullable()
  .ensure()
  .max(78, 'Field must not exceed 78 characters');
const v_CourseResources_download = string()
  .required('This is a required field')
  .url('Must be a valid URL')
  .nullable();
//*** END COURSE RESOURCE MODEL */

//*** START LESSONS MODEL */
const v_Lessons_title = string()
  .required('This is a required field')
  .ensure()
  .max(90, 'Field must not exceed 90 characters');
const v_Lessons_slug = string()
  .required('This is a required field')
  .ensure()
  .max(90, 'Field must not exceed 90 characters')
  .matches(/^\w+$/, 'Special characters are not permitted, including spaces.');
const v_Lessons_abstract = string()
  .nullable()
  .ensure()
  .max(300, 'Field must not exceed 300 characters');
const v_Lessons_passing_percent = number()
  .typeError('Must be a numerical value between 0-100 with no decimals')
  .integer('Must be a numerical value between 0-100 with no decimals')
  .min(0, 'Must be a numerical value between 0-100 with no decimals')
  .max(100, 'Must be a numerical value between 0-100 with no decimals')
  .nullable();
const v_Lessons_include_in_course_grade = bool()
  .typeError('Text must be either "true" or "false"')
  .nullable();
const v_Lessons_banner = string().url('Must be a valid URL').nullable();
const v_Lessons_course_id = mixed().required('This is a required field');
//*** END LESSONS MODEL */

//*** START LESSON PARTS MODEL */
const v_LessonParts_lesson_slug = mixed()
  .required('This is a required field')
  .test('slug', 'required', function test(value) {
    if (!value || value === '') {
      return false;
    }
    return true;
  });
const v_LessonParts_type = string()
  .required('This is a required field')
  .nullable()
  .test(
    'type',
    'Must be one of the following options: Question, Video, or Blurb',
    function test(value) {
      if (!value || value === '') {
        return false;
      }
      const types = Object.values(LessonPart.LESSON_PART_TYPES).map((option) => {
        return { value: option.value, label: option.label };
      });
      const foundType = types?.filter((type) => type.label === value);
      return foundType?.length > 0;
    },
  );
const v_LessonParts_title = string()
  .nullable()
  .ensure()
  .max(90, 'The title must not surpass 90 characters')
  .test('lesson-parts-title-validation', 'Code markup is not permitted.', function (value) {
    const reg = new RegExp(/^(?!.*[`<>]).*$/);
    if (!reg.test(value || '')) {
      return false;
    }
    return true;
  });
const v_LessonParts_question_type = string()
  .nullable()
  .test(
    'question-type',
    'Must be one of the following options: Multiple Choice, Poll, Fill in the Blank, Ranking, Rating',
    function (this, value) {
      if (this?.options?.context?.type !== 'Question') {
        return true;
      }
      if (!value || value === '') {
        return false;
      }
      const types = Object.values(Question.QUESTION_TYPES).map((option) => {
        return { value: option.value, label: option.label };
      });
      const foundType = types?.filter((type) => type.label === value);
      return foundType?.length > 0;
    },
  );
const v_LessonParts_score = number()
  .integer('Must be an integer greater than or equal to 0')
  .min(0, 'Must be an integer greater than or equal to 0')
  .nullable(true)
  .transform((_, val) => (val ? Number(val) : null));
const v_LessonParts_rating_min = number()
  .integer('Must be an integer greater than or equal to 0')
  .min(0, 'Must be an integer greater than or equal to 0')
  .nullable(true)
  .transform((_, val) => (val ? Number(val) : null));
const v_LessonParts_rating_max = number()
  .integer('Must be an integer greater than or equal to 0')
  .min(0, 'Must be an integer greater than or equal to 0')
  .nullable(true)
  .transform((_, val) => (val ? Number(val) : null));
const v_LessonParts_rating_increment = number()
  .min(0, 'Must be greater than 0')
  .integer('Must be an integer')
  .nullable(true)
  .transform((_, val) => (val ? Number(val) : null));
const v_LessonParts_video_url = string()
  .nullable()
  .url('Must be a valid URL')
  .typeError('Must be a valid URL')
  .when('type', {
    is: (type: string) => type === LessonPart.LESSON_PART_TYPES.VIDEO.label,
    then: string()
      .url('Must be a valid URL')
      .typeError('Must be a valid URL')
      .required(`This is a required field when type 'Video' is set`),
  });
const v_LessonParts_video_required_completion_percent = mixed()
  .default(80)
  .nullable()
  .test('check completion percent', 'Must be an integer between 0 and 100', function test(value) {
    // check if the item has a % sign, if so, strip it out and check for number, otherwise just check for number
    let val = 0;
    if (value) {
      const stripped = String(value).replace('%', '');
      val = Number(stripped);
    }

    // check to see if our number is an integer
    if (!Number.isInteger(val)) {
      return false;
    }

    return val >= 0 && val <= 100;
  });
//*** END LESSON PARTS MODEL */

//*** START QUESTIONS MODEL */
const v_Questions_name = string()
  .required('This is a required field')
  .ensure()
  .max(90, 'Field must not exceed 90 characters');
const v_Questions_type = string().required('This is a required field');
const v_Questions_show_voting_results = bool()
  .typeError('Text must be either "true" or "false"')
  .nullable();
//*** END QUESTIONS MODEL */

//*** START QUESTION ANSWERS MODEL */
const v_QuestionAnswers_question_id = mixed()
  .required('This is a required field')
  .test('slug', 'required', function test(value) {
    if (!value || value === '') {
      return false;
    }
    return true;
  });
const v_QuestionAnswers_answer_text = string()
  .nullable()
  .max(250, 'Field must not exceed 250 characters');
const v_QuestionAnswers_is_correct = bool()
  .typeError('Text must be either "true" or "false"')
  .nullable();

const v_Users_email = string()
  .required('User email is required')
  .ensure()
  .max(128, 'Field must not exceed 128 characters');
const v_Users_firstname = string()
  .required('User first name is required')
  .ensure()
  .max(128, 'Field must not exceed 128 characters');
const v_Users_lastname = string()
  .required('User last name is required')
  .ensure()
  .max(128, 'Field must not exceed 128 characters');
const v_Users_company = string()
  .nullable()
  .ensure()
  .max(128, 'Field must not exceed 128 characters');
const v_Users_title = string().nullable().ensure().max(128, 'Field must not exceed 128 characters');
const v_Users_phone = string().nullable().ensure().max(32, 'Field must not exceed 32 characters');
const v_Users_social = string()
  .nullable()
  .ensure()
  .max(256, 'Field must not exceed 256 characters');
const v_Users_icon = string()
  .url('Must be a valid URL')
  .nullable()
  .max(1024, 'Field must not exceed 1024 characters');
const v_Users_avatar = string()
  .url('Must be a valid URL')
  .nullable()
  .max(256, 'Field must not exceed 256 characters');
const v_Users_banner = string()
  .url('Must be a valid URL')
  .nullable()
  .max(256, 'Field must not exceed 256 characters');
const v_Users_role = number()
  .typeError('Must be a numerical value between 0-1 with no decimals')
  .integer('Must be a numerical value between 0-1 with no decimals')
  .min(0, 'Must be a numerical value between 0-1 with no decimals')
  .max(1, 'Must be a numerical value between 0-1 with no decimals')
  .nullable(true);
const v_Users_accesspasses = mixed().nullable();
const v_Users_pricelevel = mixed().nullable();
const v_Users_declaredtags = mixed().nullable();

//*** END QUESTION ANSWERS MODEL */

//** ^^^^^^^^^^^^^^^^^^^ ** END YUP VALIDATIONS ** ^^^^^^^^^^^^^^^^^^^ */

/** START Test validations */
const v__TESTS__title = string().nullable().required('Title is required');
const v__TESTS__slug = string().nullable().ensure().max(5, 'Field must not exceed 5 characters');
const v__TESTS__context = bool()
  .nullable()
  .test('context-test', 'Context must be true when slug is "slug"', function (this, value) {
    // making up a use case: let's say the context field must be true if the slug is 'slug'
    if (this?.options?.context?.slug === 'slug') {
      if (!value) {
        return false;
      }
    }
    return true;
  });
/** END Test validations */

export {
  v__TESTS__title,
  v__TESTS__slug,
  v__TESTS__context,
  v_Courses_title,
  v_Courses_abstract,
  v_Courses_enrollment_required,
  v_Courses_max_enrollments,
  v_Courses_banner_video,
  v_Courses_icon,
  v_Courses_certificate,
  v_Courses_passing_percent,
  v_Courses_credits,
  v_Courses_date_released,
  v_Courses_date_start,
  v_Courses_date_end,
  v_Courses_date_open_enrollment,
  v_Courses_date_lock_enrollment,
  v_Courses_slug,
  v_Courses_tags,
  v_Courses_scorm,
  v_CourseResources_course_slug,
  v_CourseResources_title,
  v_CourseResources_icon,
  v_CourseResources_description,
  v_CourseResources_download,
  v_Lessons_title,
  v_Lessons_slug,
  v_Lessons_abstract,
  v_Lessons_passing_percent,
  v_Lessons_include_in_course_grade,
  v_Lessons_banner,
  v_Lessons_course_id,
  v_LessonParts_lesson_slug,
  v_LessonParts_type,
  v_LessonParts_title,
  v_LessonParts_question_type,
  v_LessonParts_score,
  v_LessonParts_rating_min,
  v_LessonParts_rating_max,
  v_LessonParts_rating_increment,
  v_LessonParts_video_url,
  v_LessonParts_video_required_completion_percent,
  v_Questions_name,
  v_Questions_type,
  v_Questions_show_voting_results,
  v_QuestionAnswers_question_id,
  v_QuestionAnswers_answer_text,
  v_QuestionAnswers_is_correct,
  v_Users_email,
  v_Users_firstname,
  v_Users_lastname,
  v_Users_company,
  v_Users_title,
  v_Users_phone,
  v_Users_social,
  v_Users_icon,
  v_Users_avatar,
  v_Users_banner,
  v_Users_role,
  v_Users_accesspasses,
  v_Users_pricelevel,
  v_Users_declaredtags,
};
