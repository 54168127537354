import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs, { Dayjs } from 'dayjs';
import { useGetAllTagTypes } from '@juno/client-api';
import { Site, TagType } from '@juno/client-api/model';
import { getBeginningOfDay, getEndOfDay } from '@juno/utils';
import AutocompleteInstructors from './AutocompleteInstructors';
import AutocompleteTags from './AutocompleteTags';
import { CourseFilterContext } from './context';

export type CoursePreFilterOptions = {
  preFilterTags?: boolean;
  requireTags?: string[];
  optionalTags?: string[];
  excludeTags?: string[];
};

interface CourseFilterProps {
  site?: Site;
  onChange: (filter: any) => void;
  values: any;
  settings: any;
  preFilterOptions?: CoursePreFilterOptions;
}
const CourseFilter: React.FC<CourseFilterProps> = ({
  site,
  onChange,
  values,
  settings,
  preFilterOptions,
}) => {
  const { platform_id: platformId = '', id: siteId = '' } = site || {};
  const [tagIdMap, setTagIdMap] = useState<{ [key: string]: string[] }>({});
  const [tagFilterIsSet, setTagFilterIsSet] = useState(false);
  const courseFilterContext = useContext(CourseFilterContext);

  useEffect(() => {
    if (settings) {
      setTagFilterIsSet(
        settings?.filterTagTypes?.length ? true : settings?.filterTagTypes?.length > 0,
      );
    }
  }, []);

  const { data: tagTypes } = useGetAllTagTypes(
    siteId,
    {
      filter: { id__in: settings?.filterTagTypes },
    },
    { query: { enabled: settings?.filterTagTypes !== '["-1"]' } },
  );

  const handleTagListUpdate = (vals: string[], key: string, typeId: string) => {
    const tempMap = { ...tagIdMap };
    tempMap[typeId] = vals;
    setTagIdMap(tempMap);
    let tempVals: string[] = [];
    Object.values(tempMap).forEach((t: string[]) => {
      tempVals = tempVals.concat(t);
    });
    if (!tempVals?.length) {
      onChange((current: any) => {
        const { [key]: _, ...rest } = current;
        return rest;
      });
      return;
    }
    onChange((current: any) => {
      return { ...current, [key]: tempVals };
    });
  };

  // Communicate preFiltered tags to the site's filter modal
  const handlePreFilterTagIds = () => {
    if (!preFilterOptions || !preFilterOptions.preFilterTags) {
      return [];
    } else {
      let preFilterTagIds: string[] = [];
      if (preFilterOptions?.requireTags && preFilterOptions.requireTags.length > 0) {
        preFilterTagIds = preFilterOptions.requireTags;
      }
      if (preFilterOptions?.optionalTags && preFilterOptions.optionalTags.length > 0) {
        const optionalTagIds = preFilterOptions.optionalTags;
        preFilterTagIds = [...preFilterTagIds, ...optionalTagIds];
      }

      if (preFilterOptions?.excludeTags && preFilterOptions.excludeTags.length > 0) {
        const excludeTagIds = preFilterOptions.excludeTags;
        preFilterTagIds = [...preFilterTagIds, ...excludeTagIds];
      }
      return preFilterTagIds;
    }
  };

  return (
    <Box sx={{ minHeight: 400 }}>
      {settings?.showFilterStartDates && (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Typography>Course Start Date</Typography>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <DesktopDatePicker
                label='From'
                inputFormat='MM/dd/yyyy'
                value={values?.date_start__gte || null}
                onChange={(dateVal: Dayjs | null) =>
                  courseFilterContext.handleDateUpdate(
                    dateVal ? dayjs(getBeginningOfDay(dayjs(dateVal).toDate())) : null,
                    'date_start__gte',
                  )
                }
                renderInput={(params: any) => <TextField fullWidth size='small' {...params} />}
                PopperProps={{ disablePortal: true }}
              />
            </Grid>
            <Grid item sm={6}>
              <DesktopDatePicker
                label='To'
                inputFormat='MM/dd/yyyy'
                value={values?.date_start__lte || null}
                onChange={(dateVal: Dayjs | null) =>
                  courseFilterContext.handleDateUpdate(
                    dateVal ? dayjs(getEndOfDay(dayjs(dateVal).toDate())) : null,
                    'date_start__lte',
                  )
                }
                renderInput={(params: any) => <TextField fullWidth size='small' {...params} />}
                PopperProps={{ disablePortal: true }}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      )}
      {settings?.showFilterInstructors && (
        <AutocompleteInstructors
          label='Instructor'
          platformId={platformId}
          onSelect={(itemIds) =>
            courseFilterContext.handleStringListUpdate(itemIds, 'instructors__id__in')
          }
          instructorsRemoved={courseFilterContext.instructorsRemoved}
        />
      )}
      {tagFilterIsSet &&
        tagTypes?.map((tagType: TagType) => (
          <AutocompleteTags
            key={tagType.id}
            label={tagType.value}
            tagType={tagType.value}
            siteId={siteId}
            onSelect={(itemIds) => handleTagListUpdate(itemIds, 'tags__id__in', tagType.id)}
            tagsRemoved={
              courseFilterContext.tagsRemoved?.[tagType?.id]?.length > 0
                ? courseFilterContext.tagsRemoved?.[tagType?.id]
                : undefined
            }
            preFilterTagIds={handlePreFilterTagIds()}
          />
        ))}
    </Box>
  );
};

export default CourseFilter;
