import { Typography } from '@mui/material';

export const BUTTON_TYPES = {
  DISABLED: 'disabled',
  POINTS: 'points',
  BASIC: 'basic',
  HIDDEN: 'hidden',
  PAYWALL: 'paywall',
  PAYWALLED_APP: 'paywall_app',
};

interface CourseStateMapProps {
  [key: string]: {
    key: string;
    label: string;
    button: {
      label: string;
      type: string;
      points?: number;
    };
    alert?: {
      title: string;
      message: React.ReactNode;
    };
  };
}

export const COURSE_STATE_MAP: CourseStateMapProps = {
  NO_LESSONS: {
    key: 'NO_LESSONS',
    label: 'No Lessons',
    button: { label: 'Course Locked', type: BUTTON_TYPES.DISABLED },
    alert: {
      title: 'No Lessons',
      message: <Typography>This course doesn't have any lessons yet.</Typography>,
    },
  },
  CAN_SELF_ENROLL: {
    key: 'CAN_SELF_ENROLL',
    label: 'Enroll in Course',
    button: { label: 'Enroll', type: BUTTON_TYPES.BASIC },
  },
  ENROLLMENT_REQUIRED: {
    key: 'ENROLLMENT_REQUIRED',
    label: 'Permission Required',
    button: { label: 'Enroll to Start', type: BUTTON_TYPES.DISABLED },
    alert: {
      title: 'Course Enrollment',
      message: (
        <>
          <Typography>
            Please reach out to your teacher if you’re interested in this course.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Your teacher will provide assistance with your course schedule, enroll you in courses,
            and add you to the waitlist for courses currently at capacity.
          </Typography>
        </>
      ),
    },
  },
  PREREQS_REQUIRED: {
    key: 'PREREQS_REQUIRED',
    label: 'Prerequisites Required',
    button: { label: 'Start Course', type: BUTTON_TYPES.DISABLED },
    alert: {
      title: 'Prerequisites Required',
      message: (
        <>
          <Typography>
            You haven’t completed the required prerequisites for this course yet.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Please enroll in those courses first and return once they’ve been completed.
          </Typography>
        </>
      ),
    },
  },
  ENROLLED: {
    key: 'ENROLLED',
    label: 'Ready to Begin!',
    button: { label: 'Start Course', type: BUTTON_TYPES.POINTS, points: 10 },
  },
  ENROLLMENT_TOO_EARLY: {
    key: 'ENROLLMENT_TOO_EARLY',
    label: 'Check Back Later!',
    button: { label: 'Enroll Soon', type: BUTTON_TYPES.DISABLED },
    alert: {
      title: 'Course Enrollment',
      message: (
        <>
          <Typography>
            Please reach out to your teacher if you’re interested in this course.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Your teacher will provide assistance with your course schedule, enroll you in courses,
            and add you to the waitlist for courses currently at capacity.
          </Typography>
        </>
      ),
    },
  },
  ENROLLMENT_LOCKED: {
    key: 'ENROLLMENT_LOCKED',
    label: 'Check Back Soon!',
    button: { label: 'Course Closed', type: BUTTON_TYPES.DISABLED },
    alert: {
      title: 'Enrollment Closed',
      message: (
        <Typography>
          The enrollment period for this course has ended. You may no longer add or drop this course
          from your schedule.
        </Typography>
      ),
    },
  },
  COURSE_INACTIVE: {
    key: 'COURSE_INACTIVE',
    label: 'Check Back Soon!',
    button: { label: 'Course Inactive', type: BUTTON_TYPES.DISABLED },
    alert: {
      title: 'Course Inactive',
      message: (
        <Typography>
          This course has not yet started. Please check back later for more information.
        </Typography>
      ),
    },
  },
  COURSE_CLOSED: {
    key: 'COURSE_CLOSED',
    label: 'Course Ended',
    button: { label: 'Past', type: BUTTON_TYPES.DISABLED },
    alert: {
      title: 'Course Ended',
      message: <Typography>This course has Ended.</Typography>,
    },
  },
  COURSE_FULL: {
    key: 'COURSE_FULL',
    label: 'Capacity Full',
    button: { label: 'Join Waitlist', type: BUTTON_TYPES.BASIC },
  },
  PAYWALLED: {
    key: 'PAYWALLED',
    label: '',
    button: { label: 'Enroll', type: BUTTON_TYPES.DISABLED },
  },
  PAYWALLED_APP: {
    key: 'PAYWALLED_APP',
    label: 'Access via Desktop',
    button: { label: 'Enroll', type: BUTTON_TYPES.PAYWALLED_APP },
  },
  PURCHASED: {
    key: 'PURCHASED',
    label: '',
    button: { label: 'Enroll', type: BUTTON_TYPES.BASIC },
  },
  CANT_PURCHASE: {
    key: 'CANT_PURCHASE',
    label: '',
    button: { label: 'Purchase', type: BUTTON_TYPES.DISABLED },
  },
  WAITLISTED: {
    key: 'WAITLISTED',
    label: 'Capacity Full',
    button: { label: 'On Waitlist', type: BUTTON_TYPES.DISABLED },
    alert: {
      title: 'Capacity is Full',
      message: (
        <>
          <Typography>You’re currently on the waitlist for this course.</Typography>
          <Typography sx={{ mt: 2 }}>
            This course has reached its enrollment capacity. If any enrolled learners drop this
            course, waitlisted students will be automatically enrolled on a first-come, first-serve
            basis.
          </Typography>
        </>
      ),
    },
  },
  LESSONS_STARTED: {
    key: 'LESSONS_STARTED',
    label: 'Course in Progress',
    button: { label: 'Continue Course', type: BUTTON_TYPES.BASIC },
  },
  LESSONS_COMPLETED: {
    key: 'LESSONS_COMPLETED',
    label: 'All Lessons Complete',
    button: { label: 'Complete Course', type: BUTTON_TYPES.POINTS, points: 10 },
  },
  COURSE_COMPLETED: {
    key: 'COURSE_COMPLETED',
    label: 'Course Complete',
    button: { label: '', type: BUTTON_TYPES.HIDDEN },
  },
  COURSE_PASSED: {
    key: 'COURSE_PASSED',
    label: 'Course Passed',
    button: { label: '', type: BUTTON_TYPES.HIDDEN },
  },
  COURSE_CERTIFIED: {
    key: 'COURSE_CERTIFIED',
    label: 'Course Passed',
    button: { label: 'View Certificate', type: BUTTON_TYPES.BASIC },
  },
  COURSE_CERTIFIED_MULTI: {
    key: 'COURSE_CERTIFIED_MULTI',
    label: 'Course Passed',
    button: { label: 'Go to profile to claim', type: BUTTON_TYPES.DISABLED },
  },
  RESTRICT_ACCESS: {
    key: 'RESTRICT_ACCESS',
    label: 'Premium Content: Click Access Course to Upgrade',
    button: { label: 'Access Course', type: BUTTON_TYPES.BASIC },
  },
};
