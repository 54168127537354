import React, { useEffect, useState } from 'react';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
} from '@mui/icons-material';
import { Box, IconButton, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import { Reorder } from 'framer-motion';
import { nanoid } from 'nanoid';
import { TabItemObject } from '../index';
import { TabsListItem, TabsListWrapper } from './styles';

interface TabItemProps {
  content: TabItemObject;
  onTabEdit: (tabId: string) => void;
  onTabDelete?: (tabId: string) => void;
}

const TabItem: React.FC<TabItemProps> = ({ content, onTabEdit, onTabDelete }) => {
  const { id, label } = content;
  return (
    <TabsListItem
      key={id}
      onClick={(evt: React.MouseEvent) => {
        evt.stopPropagation();
        onTabEdit(id);
      }}
    >
      <ListItemIcon>
        <DragIndicatorIcon />
      </ListItemIcon>
      <ListItemText primary={label} />
      {onTabDelete && (
        <IconButton
          onClick={(evt: React.MouseEvent) => {
            evt.stopPropagation();
            onTabDelete(id);
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </TabsListItem>
  );
};

interface TabListProps {
  tabs: TabItemObject[];
  onTabSelected: (tabId: string) => void;
  onTabUpdate: (newTabs: TabItemObject[]) => void;
  onTabDelete?: (tabId: string) => void;
}

const TabList: React.FC<TabListProps> = ({ tabs, onTabUpdate, onTabSelected, onTabDelete }) => {
  const [elementsDragging, setElementsDragging] = useState(new Set());
  const [elements, setElements] = useState<TabItemObject[]>([]);

  useEffect(() => {
    setElements(tabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  const handleAddTab = () => {
    const tabId = nanoid(5);
    onTabUpdate([...tabs, { id: tabId, label: `New Tab ${tabId}`, filter: '', order: '' }]);
  };

  const handleTabEdit = (tabId: string) => {
    if (elementsDragging.size > 0) return;
    // onConfigureModule(moduleId);
    onTabSelected(tabId);
  };

  const handleTabDelete = (tabId: string) => {
    if (elementsDragging.size > 0) return;
    onTabUpdate([...tabs.filter((tab) => tab.id !== tabId)]);
  };

  const handleReorderModules = () => {
    onTabUpdate(elements);
  };

  const handleDragStart = (id: string) => {
    setElementsDragging((currentElements) => currentElements.add(id));
  };

  const handleDragEnd = (id: string) => {
    setElementsDragging((currentElements) => {
      currentElements.delete(id);
      return currentElements;
    });
    handleReorderModules();
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>Tabs - Data Filtering</Typography>
        <IconButton onClick={handleAddTab} size='small'>
          <Tooltip title='Add Tab'>
            <AddIcon />
          </Tooltip>
        </IconButton>
      </Box>
      <TabsListWrapper>
        <Reorder.Group
          axis='y'
          onReorder={setElements}
          onClick={(e) => e.stopPropagation()}
          values={elements}
        >
          {elements.map((content) => (
            <Reorder.Item
              id={content.id}
              key={content.id}
              value={content}
              onDragStart={() => handleDragStart(content.id)}
              onDragTransitionEnd={() => handleDragEnd(content.id)}
            >
              <TabItem content={content} onTabEdit={handleTabEdit} onTabDelete={handleTabDelete} />
            </Reorder.Item>
          ))}
        </Reorder.Group>
      </TabsListWrapper>
    </>
  );
};

export default TabList;
