import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { AvatarWrapper } from './styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface BackButtonProps {
  onClick?: () => void;
  children?: React.ReactNode;
}

const BackButton: React.FC<BackButtonProps> = ({ onClick, children }) => {
  return (
    <Stack direction='row' alignItems='center' sx={{ width: '100%', p: 2 }}>
      <IconButton sx={{ pr: 2, pl: 0 }} disableRipple onClick={onClick}>
        <AvatarWrapper sx={{ width: 24, height: 24 }}>
          <ArrowBackIcon sx={{ fontSize: 18 }} />
        </AvatarWrapper>
      </IconButton>
      {children}
    </Stack>
  );
};

export default BackButton;
