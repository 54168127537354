import React from 'react';
import { Avatar, Box, Grid, ListItem, ListItemAvatar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Linkify from 'linkify-react';
import { useNavigate } from 'react-router-dom';
import { JunoUser } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';

export interface ConversationRowProps {
  junoUser: JunoUser;
  messageText: string;
  messageCreatedAt: string;
  isNewMessage?: boolean;
  isCurrentUser: boolean;
}

export const ConversationRow: React.FC<ConversationRowProps> = ({
  junoUser,
  messageText,
  messageCreatedAt,
  isNewMessage,
  isCurrentUser,
}) => {
  const theme = useTheme(); // Use the theme to access the primary and grey color
  const navigate = useNavigate();
  const { sitePath } = useSettings();

  const messageStyle = {
    backgroundColor: !isCurrentUser ? theme.palette.grey[300] : theme.palette.primary.main,
    color: !isCurrentUser ? 'black' : 'white',
    px: 1,
    py: 1,
    borderRadius: '10px',
    width: '100%',
    wordBreak: 'break-word',
  };

  // Decide the alignment based on the sender
  const justifyContent = isCurrentUser ? 'flex-end' : 'flex-start';

  const renderMessageContent = (message: string) => {
    // Example regex for detecting a URL ending in .gif
    const gifRegex = /(http(s?):)([/|.|\w|\s|-])*\.(?:gif)/g;
    const isGif = gifRegex.test(message);

    if (isGif) {
      return <img src={message} alt='gif' style={{ maxWidth: '100%' }} />;
    }

    // Updated method to handle emojis and text differently
    const emojiRegex = /([\u{1F600}-\u{1F64F}])/gu;
    const parts = message.split(emojiRegex);

    return (
      <span key={messageCreatedAt}>
        {parts.map((part, index) => {
          if (emojiRegex.test(part)) {
            // This part is an emoji, so we apply the larger font size
            return (
              <span key={index} style={{ fontSize: '2.5em' }}>
                {part}
              </span>
            );
          } else {
            // This part is not an emoji, so we render it without altering the font size
            return (
              <Linkify
                options={{
                  target: {
                    url: '_blank',
                  },
                }}
              >
                <span key={index}>{part}</span>
              </Linkify>
            );
          }
        })}
      </span>
    );
  };

  return isCurrentUser ? (
    <ListItem sx={{ justifyContent, display: 'flex', width: '100%' }}>
      <Grid container>
        <Grid item xs={3}></Grid>

        <Grid item xs={9}>
          {!isCurrentUser && (
            <Typography
              variant='subtitle2'
              component='h5'
              sx={{
                textAlign: 'left',
                fontSize: '.95rem',
              }}
            >
              {junoUser.first_name} {junoUser.last_name}
            </Typography>
          )}
          <Box sx={{ ...messageStyle }}>
            <Typography
              variant='body2'
              sx={{
                color: 'inherit',
                fontSize: '0.875rem',
                opacity: '1 !important', // Apply opacity directly to the text
                '& *': {
                  // This targets all child elements, ensuring the opacity is applied
                  opacity: '1 !important',
                },
              }}
            >
              {renderMessageContent(messageText)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  ) : (
    <ListItem sx={{ justifyContent, display: 'flex', width: '100%' }}>
      <Grid container>
        <Grid item xs={2}>
          {!isCurrentUser && (
            <ListItemAvatar
              sx={{ marginRight: 1, cursor: 'pointer' }}
              onClick={() => navigate(`/${sitePath}/user/${junoUser.id}`)}
            >
              <Avatar
                alt={`${junoUser.first_name} ${junoUser.last_name}`}
                src={junoUser.avatar ?? undefined}
                sx={{ width: 34, height: 34 }}
              />
            </ListItemAvatar>
          )}
        </Grid>

        <Grid item xs={9}>
          {!isCurrentUser && (
            <Typography
              variant='body1'
              component='h5'
              sx={{
                textAlign: 'left',
                fontSize: '.90rem',
              }}
            >
              {junoUser.first_name} {junoUser.last_name}
            </Typography>
          )}
          <Box sx={{ ...messageStyle }}>
            <Typography
              variant='body2'
              sx={{
                color: 'inherit',
                fontSize: '0.875rem',
                opacity: '1 !important', // Apply opacity directly to the text
                '& *': {
                  // This targets all child elements, ensuring the opacity is applied
                  opacity: '1 !important',
                },
              }}
            >
              {renderMessageContent(messageText)}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
    </ListItem>
  );
};
