import { SvgIconComponent } from '@mui/icons-material';
import GroupsIcon from '@mui/icons-material/Groups';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import SchoolIcon from '@mui/icons-material/School';
import VideocamIcon from '@mui/icons-material/Videocam';
import { blue, pink, purple, teal } from '@mui/material/colors';
import { ANALYTICS_DASHBOARD_CATEGORIES } from '@juno/constants';

export enum WidgetContentType {
  Course = 'course',
  Community = 'community',
  Session = 'session',
  Library = 'library',
}
export interface WidgetModel {
  id: string;
  title: string;
  description: string;
  content_type: string;
  icon: SvgIconComponent;
  color: string;
  show_pie: boolean;
  count_content?: boolean;
}

export const widgets: WidgetModel[] = [
  {
    id: ANALYTICS_DASHBOARD_CATEGORIES.USERS_ON_COMMUNITY_PAGE.id,
    title: ANALYTICS_DASHBOARD_CATEGORIES.USERS_ON_COMMUNITY_PAGE.title,
    description: 'Below is a list of your Communities with active users.',
    content_type: WidgetContentType.Community,
    icon: GroupsIcon,
    color: teal[300],
    show_pie: true,
  },
  {
    id: ANALYTICS_DASHBOARD_CATEGORIES.USERS_ON_COURSE_PAGE.id,
    title: ANALYTICS_DASHBOARD_CATEGORIES.USERS_ON_COURSE_PAGE.title,
    description: 'Below is a list of your Courses with active users.',
    content_type: WidgetContentType.Course,
    icon: SchoolIcon,
    color: pink[200],
    show_pie: true,
  },
  {
    id: ANALYTICS_DASHBOARD_CATEGORIES.USERS_ON_LIBRARY_PAGE.id,
    title: ANALYTICS_DASHBOARD_CATEGORIES.USERS_ON_LIBRARY_PAGE.title,
    description: 'Below is a list of your Library pages with active users.',
    content_type: WidgetContentType.Library,
    icon: LocalLibraryIcon,
    color: purple[600],
    show_pie: true,
  },
  {
    id: ANALYTICS_DASHBOARD_CATEGORIES.USERS_IN_LIVE_SESSIONS.id,
    title: ANALYTICS_DASHBOARD_CATEGORIES.USERS_IN_LIVE_SESSIONS.title,
    description:
      'Below is a list of active sessions and the total active users viewing them. \n' +
      '\n' +
      'Note: only sessions with active users will be displayed.',
    content_type: WidgetContentType.Session,
    icon: VideocamIcon,
    color: blue[600],
    show_pie: true,
  },
];
