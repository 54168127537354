// eslint-disable-next-line @typescript-eslint/no-empty-interface
import React, { useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  ListSubheader,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { completeCourseAdmin } from '@juno/client-api';
import { ItemProps } from './';
import LearnerTile from './LearnerTile';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ListLearnersProps {
  label: string;
  items: ItemProps[];
  locked: boolean;
  onDelete: (learner: any) => void;
  siteId: string;
  refetchEnrollments: () => void;
  isGraded: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  totalItems: number;
}

const ListLearners: React.FC<ListLearnersProps> = ({
  label,
  items,
  locked,
  onDelete,
  siteId,
  refetchEnrollments,
  isGraded,
  searchValue,
  setSearchValue,
  totalItems,
}) => {
  const [open, setOpen] = useState(false);
  const [completeOpen, setCompleteOpen] = useState(false);
  const [selected, setSelected] = useState<any>(null);
  const [isLoadingComplete, setIsLoadingComplete] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleDelete = (learner: any) => {
    onDelete(learner);
    handleClose();
  };

  const handleRemove = (item: any) => {
    setSelected(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelected(null);
  };

  const handleClearInput = () => {
    setSearchValue('');
  };

  const openComplete = (item: any) => {
    setCompleteOpen(true);
    setSelected(item);
  };

  const handleComplete = async () => {
    setIsLoadingComplete(selected.user.id);
    try {
      setCompleteOpen(false);
      await completeCourseAdmin(siteId, selected.course.id, { user_id: selected.user.id });
      refetchEnrollments();
      setIsLoadingComplete('');
      setSelected(null);
    } catch (e) {
      setCompleteOpen(false);
      setIsLoadingComplete('');
      console.log(e);
      setSelected(null);
    }
  };

  const renderName = (first: string, last: string): string => {
    if (first && last) {
      return `${last}, ${first}`;
    } else if (last) {
      return last;
    } else if (first) {
      return first;
    } else {
      return '';
    }
  };

  return (
    <Card>
      <CardHeader
        // title={label}
        title={
          <TextField
            fullWidth
            value={searchValue}
            onChange={handleChange}
            placeholder='Search'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchValue !== '' && (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='clear input'
                    onClick={handleClearInput}
                    edge='end'
                    size='small'
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        }
      />
      <Divider />
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          height: '510px',
          overflow: 'auto',
        }}
        className='dragon'
        subheader={
          <ListSubheader component='div' id='nested-list-subheader'>
            <Stack direction='row' justifyContent='space-between'>
              <Typography>{label}</Typography>
              <Typography>{`showing: ${items.length}/${totalItems}`}</Typography>{' '}
            </Stack>
          </ListSubheader>
        }
      >
        {items.map((item, index) => (
          <LearnerTile
            key={item.id}
            item={item}
            locked={locked}
            handleClose={handleClose}
            handleRemove={handleRemove}
            renderName={renderName}
            handleComplete={openComplete}
            isLoadingComplete={isLoadingComplete}
            isGraded={isGraded}
          />
        ))}
      </List>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Remove Learner</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove {selected?.user?.first_name} {selected?.user?.last_name}{' '}
            from the course?
          </Typography>
          <Typography>Any course progress will be lost.</Typography>
          {label === 'Enrolled' && (
            <Typography sx={{ mt: 1 }}>
              The next learner on the waitlist will be enrolled.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant='contained' onClick={() => handleDelete(selected)} autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={completeOpen} onClose={() => setCompleteOpen(false)}>
        <DialogTitle>Mark course as completed</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to complete the course for {selected?.user?.first_name}{' '}
            {selected?.user?.last_name}?
          </Typography>
          {label === 'Enrolled' && (
            <Typography sx={{ mt: 1 }}>
              They will be marked as having completed each of the lessons and receive a passing
              grade.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCompleteOpen(false)}>Cancel</Button>
          <Button variant='contained' onClick={() => handleComplete()} autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ListLearners;
