import React from 'react';
import { Box } from '@mui/material';

interface DefaultHeroBannerProps {
  imgSrc: string;
  imgAlt: string;
  title?: string;
  subtitle?: string;
  sx?: any;
  width?: string;
  height?: string;
}

const DefaultHeroBanner: React.FC<DefaultHeroBannerProps> = ({ ...props }) => {
  const width = props?.width ? props.width : '100%';
  const height = props?.height ? props.height : '32vw';
  return (
    <Box
      sx={{
        m: 'auto',
        p: 0,
        backgroundImage: `url(${props.imgSrc})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      width={width}
      height={`${props.imgSrc !== '' ? height : '0px'}`}
    ></Box>
  );
};
export default DefaultHeroBanner;
