import React, { useState } from 'react';
import { Session } from '@juno/client-api/model';
import { ProductStatus } from '../';
import App from '../components/App';
import { CHAT_ASIDE, PEOPLE_ASIDE, QA_ASIDE } from '../constants';
import { CallProvider } from '../contexts/CallProvider';
import { ChatProvider } from '../contexts/ChatProvider';
import { MediaDeviceProvider } from '../contexts/MediaDeviceProvider';
import { ParticipantsProvider } from '../contexts/ParticipantsProvider';
import { ScreenShareProvider } from '../contexts/ScreenShareProvider';
import { TracksProvider } from '../contexts/TracksProvider';
import { UIStateProvider } from '../contexts/UIStateProvider';
import PrejoinPage from './PrejoinPage';
import RoomInfo from './RoomInfo';

interface MainPageProps {
  sessionData: Session;
  cancelPrejoin: () => void;
  isPaywalled: boolean;
  productStatus: ProductStatus;
}
const MainPage: React.FC<MainPageProps> = ({ sessionData, cancelPrejoin, isPaywalled }) => {
  const { room_url } = sessionData;
  // Add asides and modals here
  const asides: any[] = [CHAT_ASIDE, PEOPLE_ASIDE, QA_ASIDE];
  const modals: any[] = [];
  const customTrayComponent = null;
  const [hasJoined, setHasJoined] = useState(false);

  return (
    <UIStateProvider asides={asides} modals={modals} customTrayComponent={customTrayComponent}>
      {/* @ts-expect-error: Dom said so */}
      <CallProvider roomUrl={room_url}>
        {/* @ts-expect-error: Dom said so */}
        <ParticipantsProvider>
          <TracksProvider>
            <MediaDeviceProvider>
              {/* @ts-expect-error: Dom said so */}
              <ScreenShareProvider>
                {/* @ts-expect-error: Dom said so */}
                <ChatProvider>
                  {/* @ts-expect-error: Dom said so */}
                  {hasJoined ? (
                    <>
                      <App
                        leaveCall={() => {
                          // TODO this throws an error in the console about trying to render main and app at the same time
                          setHasJoined(false);
                          cancelPrejoin();
                        }}
                        sessionData={sessionData}
                      />
                      <RoomInfo isPaywalled={isPaywalled} />
                    </>
                  ) : (
                    <PrejoinPage
                      sessionData={sessionData}
                      joinCall={() => setHasJoined(true)}
                      cancelCall={cancelPrejoin}
                    />
                  )}
                </ChatProvider>
              </ScreenShareProvider>
            </MediaDeviceProvider>
          </TracksProvider>
        </ParticipantsProvider>
      </CallProvider>
    </UIStateProvider>
  );
};
export default MainPage;
