import React, { useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Editor } from '@tinymce/tinymce-react';
import { FormikProps } from 'formik';
import { useDebounce } from 'usehooks-ts';
import {
  useGetAllUsers,
  useGetCommunityGroups,
  useGetCommunityGroupsHierarchy,
} from '@juno/client-api';
import { JunoImage } from '@juno/client-api/fakeModel';
import {
  CommunityGroup,
  CommunityUser,
  JunoUser,
  PrivacyEnum,
  Site as SiteModel,
} from '@juno/client-api/model';
import { TINY_MCE_DEFAULT_CONFIG } from '@juno/constants';
import {
  ASPECT_RATIOS,
  AutoCompleteUsers,
  AutocompleteTags,
  GenericDateInput,
  GroupTree,
  JunoImageUpload,
  UserTile,
} from '@juno/ui';
import {
  UTCtoLocalString,
  calculateUserSearchFilters,
  optimizeImage,
  slugify,
  uploadTinyMceImageCloudinary,
} from '@juno/utils';

interface GroupInfoProps {
  site: SiteModel;
  group: CommunityGroup;
  formik: FormikProps<any>;
  hlConfig?: boolean;
}

const GroupInfo: React.FC<GroupInfoProps> = ({ site, group, formik, hlConfig }) => {
  const [copyData, setCopyData] = useState('');
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 400);
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } =
    formik;

  const searchFilter = useMemo(() => {
    if (!debouncedSearch) return {};
    return calculateUserSearchFilters(debouncedSearch);
  }, [debouncedSearch]);

  const { data: groups } = useGetCommunityGroups(site?.id || '');

  const { data, isFetching: usersFetching } = useGetAllUsers(
    site.platform_id,
    {
      ...searchFilter,
    },
    {
      query: {
        enabled: !!debouncedSearch,
      },
    },
  );

  const handleAddAdmin = (user: JunoUser) => {
    if (values.admins) {
      setFieldValue('admins', [...values.admins, user]);
    } else {
      setFieldValue('admins', [user]);
    }
  };

  const handleSlugChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const slug = slugify(event.target.value);
    handleChange({ target: { name: 'slug', value: slug } });
  };

  const handleDeleteAdmin = (id: string) => {
    const newAdmins = values.admins?.filter((admin: JunoUser) => admin.id !== id);
    setFieldValue('admins', newAdmins);
  };

  const { data: groupsHierarchyChildren } = useGetCommunityGroupsHierarchy(
    site.id,
    group.id,
    'children',
  );

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={8}>
        <Typography mb={2} mt={1} fontWeight='bold'>
          Information
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              value={values.title}
              onChange={handleChange}
              label='Title'
              name='title'
              error={!!errors['title']}
              helperText={<>{errors?.['title'] || ''}</>}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={values.slug}
              onChange={handleSlugChange}
              label='Slug'
              name='slug'
              error={!!errors['slug']}
              helperText={<>{errors?.['slug'] || ''}</>}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values.preview_text}
              onChange={handleChange}
              label='Preview text'
              name='preview_text'
              error={!!errors['preview_text']}
              helperText={<>{errors?.['preview_text'] || ''}</>}
              fullWidth
            />
          </Grid>
          {hlConfig && (
            <Grid item xs={12}>
              <Autocomplete
                disabled={!groupsHierarchyChildren}
                value={groups?.find((g) => g.id === values.parent) || null}
                options={
                  groups?.filter(
                    (g) =>
                      g.id !== values.id && !groupsHierarchyChildren?.find((c) => c.id === g.id),
                  ) || []
                }
                fullWidth
                onChange={(event: any, newValue: CommunityGroup | null) => {
                  setFieldValue('parent', newValue?.id || null);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={'Parent'}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>{`${option.title}`}</li>
                )}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                getOptionLabel={(option) => option.title}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography mb={2} mt={2} fontWeight='bold'>
              Body
            </Typography>

            <Editor
              apiKey={process.env.NX_TINY_MCE_API_KEY}
              onEditorChange={(value: string) => handleChange({ target: { name: 'body', value } })}
              value={values.body || ''}
              init={{
                ...TINY_MCE_DEFAULT_CONFIG,
                images_upload_handler: uploadTinyMceImageCloudinary,
                setup: (editor) => {
                  editor.setProgressState(true);
                },
              }}
              disabled={false}
            />
            <FormHelperText error={true}>{/*touched.body && errors.body*/}</FormHelperText>
          </Grid>
          <Grid item xs={12}>
            <Typography mb={2} mt={2} fontWeight='bold'>
              Dates
            </Typography>
            <Grid container spacing={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item xs={6}>
                  <GenericDateInput
                    label={'Release Date'}
                    name={'date_released'}
                    value={
                      values.date_released ? UTCtoLocalString(new Date(values.date_released)) : ''
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(
                        'date_released',
                        !event.target.value ? null : new Date(event.target.value)?.toISOString(),
                      );
                      setFieldTouched('date_released', true);
                    }}
                    onBlur={handleBlur}
                    error={touched['date_released'] && Boolean(errors['date_released'])}
                    helperText={touched['date_released'] && errors['date_released']}
                    sx={{ minWidth: '225px' }}
                    onCopy={(_event: React.ClipboardEvent<HTMLInputElement>) => {
                      const textValue = values['date_released'];
                      setCopyData(textValue);
                    }}
                    onPaste={(_event: React.ClipboardEvent<HTMLInputElement>) => {
                      const textValue = copyData;
                      setFieldValue(
                        'date_released',
                        !textValue ? null : new Date(textValue)?.toISOString(),
                      );
                      setFieldTouched('date_released', true);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <GenericDateInput
                    label={'End Date'}
                    name={'date_end'}
                    value={values.date_end ? UTCtoLocalString(new Date(values.date_end)) : ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue(
                        'date_end',
                        !event.target.value ? null : new Date(event.target.value)?.toISOString(),
                      );
                      setFieldTouched('date_end', true);
                    }}
                    onBlur={handleBlur}
                    error={touched['date_end'] && Boolean(errors['date_end'])}
                    helperText={touched['date_end'] && errors['date_end']}
                    sx={{ minWidth: '225px' }}
                    onCopy={(_event: React.ClipboardEvent<HTMLInputElement>) => {
                      const textValue = values['date_end'];
                      setCopyData(textValue);
                    }}
                    onPaste={(_event: React.ClipboardEvent<HTMLInputElement>) => {
                      const textValue = copyData;
                      setFieldValue(
                        'date_end',
                        !textValue ? null : new Date(textValue)?.toISOString(),
                      );
                      setFieldTouched('date_end', true);
                    }}
                  />
                  <FormHelperText error={true}>
                    {touched['date_end'] && errors['date_end'] && <></>}
                  </FormHelperText>
                </Grid>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Typography mb={2} mt={4} fontWeight='bold'>
                Images
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography sx={{ mb: 2 }}>List</Typography>
                  <Box>
                    <JunoImageUpload
                      style={{ aspectRatio: '16/9' }}
                      src={optimizeImage(270, values['list_image'])}
                      srcUrl={values['list_image']}
                      disabled={false}
                      onFileUploaded={(selected: JunoImage | null) =>
                        setFieldValue('list_image', selected?.url || '')
                      }
                    />
                    <Typography variant='subtitle2' sx={{ opacity: 0.7, mt: 0.5 }}>
                      The group icon image appears on module grids and rotators around the site.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ mb: 2 }}>Banner</Typography>
                  <Box>
                    <JunoImageUpload
                      style={{ aspectRatio: '16/9' }}
                      src={optimizeImage(270, values['banner_image'])}
                      srcUrl={values['banner_image']}
                      disabled={false}
                      onFileUploaded={(selected: JunoImage | null) =>
                        setFieldValue('banner_image', selected?.url || '')
                      }
                      aspectRatios={[ASPECT_RATIOS['TWENTY_FIVE_EIGHT']]}
                    />
                    <Typography variant='subtitle2' sx={{ opacity: 0.7, mt: 0.5 }}>
                      The group banner image appears on the group home page and in featured
                      rotators.
                    </Typography>
                  </Box>
                </Grid>{' '}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography mb={2} mt={1} fontWeight='bold'>
          Privacy
        </Typography>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            name='privacy'
            value={values.privacy}
            onChange={(e) => {
              handleChange(e);
              if (e.target.value === PrivacyEnum.public) {
                setFieldValue('allow_join_leave', true);
              } else {
                setFieldValue('allow_join_leave', false);
              }
            }}
          >
            <MenuItem value={PrivacyEnum.public}>Public</MenuItem>
            <MenuItem value={PrivacyEnum.private}>Private</MenuItem>
            <MenuItem value={PrivacyEnum.secret}>Secret</MenuItem>
          </Select>
        </FormControl>
        <Tooltip
          title={
            values.privacy === PrivacyEnum.secret
              ? "Set the group's privacy to Private or Public to allow join requests"
              : ''
          }
          arrow
          placement='top-start'
          enterDelay={300}
        >
          <FormControlLabel
            control={
              <Switch
                checked={values.allow_join_leave}
                onChange={handleChange}
                id='allow-join-leave'
                name='allow_join_leave'
              />
            }
            label={
              values.privacy === PrivacyEnum.public
                ? 'Allow Join/Leave Group'
                : 'Allow Request To Join/Leave Group'
            }
            disabled={values.privacy === PrivacyEnum.secret}
          />
        </Tooltip>
        <Typography mb={2} mt={1} fontWeight='bold'>
          Tags
        </Typography>
        <AutocompleteTags formik={formik} label='Tag(s)' disabled={false} />

        <Typography mb={2} mt={4} fontWeight='bold'>
          Admins
        </Typography>
        <AutoCompleteUsers
          options={data || []}
          selected={values.admins}
          onSubmit={handleAddAdmin}
          onInputChange={setSearch}
          isLoading={usersFetching}
          isLoadingPage={false}
          label={'Search users to add'}
          locked={false}
          noOptionsText={debouncedSearch === '' ? 'Search to find users' : ''}
          closeOnSelect={true}
          filteredUsers={values.admins.map((admin: CommunityUser | JunoUser) => admin.id)}
        />
        <Box mt={2}>
          {values.admins
            ?.sort(
              (a: CommunityUser, b: CommunityUser) => -b?.last_name?.localeCompare(a?.last_name),
            )
            .map((admin: JunoUser) => (
              <UserTile
                key={admin.id}
                user={admin}
                email={false}
                loading={false}
                handleIconClick={() => handleDeleteAdmin(admin.id)}
                icon={<CloseIcon sx={{ fontSize: 16 }} />}
                virtualized={false}
              />
            ))}
        </Box>
        {hlConfig && (
          <Card sx={{ p: 2, boxShadow: 1, mt: 2 }}>
            <Typography mb={2} fontWeight='bold'>
              Group Hierarchy
            </Typography>
            <GroupTree groupId={group.id} />
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default GroupInfo;
