import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';

interface DefaultSearchBoxProps {
  searchTerm: string;
  onChange: (e: any) => void;
  placeholder?: string;
  sx?: any;
  fieldProps?: any;
}

/**
 * Use for all vnext search boxes to maintain consistency
 * @param {string} searchTerm
 * @param {(event: any) => void} onChange
 * @param {string} placeholder
 * @param {any} sx
 * @param {any} fieldProps
 * @example
 * <DefaultSearchBox searchTerm={searchTerm} handleChange={handleChange} placeholder={'Search users'}/>
 *
 */
const DefaultSearchBox: React.FC<DefaultSearchBoxProps> = ({
  searchTerm,
  onChange,
  placeholder,
  sx,
  fieldProps,
}) => {
  return (
    <TextField
      placeholder={placeholder ?? 'Search...'}
      fullWidth
      value={searchTerm}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      {...sx}
      {...fieldProps}
    />
  );
};
export default DefaultSearchBox;
