import { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useCSVReader } from 'react-papaparse';
import { csvReaderStyles } from '@juno/utils';

interface ImportCsvReaderProps {
  handleState: (newState: any) => void;
  bucket: string;
  transformHeader?: (header: string) => string;
}

const ImportCsvReader: React.FC<ImportCsvReaderProps> = ({
  handleState,
  bucket,
  transformHeader,
}) => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);

  return (
    <CSVReader
      onUploadAccepted={(results: any) => {
        setZoneHover(false);
        handleState(results.data);
      }}
      onDragOver={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(true);
      }}
      onDragLeave={(event: DragEvent) => {
        event.preventDefault();
        setZoneHover(false);
      }}
      config={{
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: transformHeader,
      }}
    >
      {({ getRootProps, acceptedFile }: any) => (
        <Box
          {...getRootProps()}
          style={Object.assign({}, csvReaderStyles.zone, zoneHover && csvReaderStyles.zoneHover)}
        >
          {acceptedFile ? (
            <>
              Loading...
              <CircularProgress style={csvReaderStyles.progressBar} />
            </>
          ) : (
            <Box>
              <Stack sx={{ textAlign: 'center', color: '#595959' }}>
                <AddCircleOutlineIcon sx={{ margin: 'auto', fontSize: '42px', opacity: '0.7' }} />
                <Typography variant='h5' mt={2} sx={{ fontWeight: '400' }}>
                  Directions
                </Typography>
                <Stack sx={{ textAlign: 'left', maxWidth: '60%', margin: 'auto' }}>
                  <Typography mt={1} mb={1} variant='inherit'>
                    1. Drag &#38; drop your {bucket} CSV here or click to upload.
                  </Typography>
                  <Typography mt={1} mb={1} variant='inherit'>
                    2. Review the column titles above. If needed, update your CSV file to match.
                    Make sure to spell headers correctly.
                  </Typography>
                  <Typography mt={1} mb={1} variant='inherit'>
                    3. Required columns are identified above; make sure your CSV contains these
                    columns. All other fields are optional.
                  </Typography>
                  <Typography mt={1} mb={1} variant='inherit'>
                    4. The order of columns does not need to match; the importer will update to
                    match the order of your CSV.
                  </Typography>
                  <Typography mt={1} mb={1} variant='inherit'>
                    5. Additional columns in your CSV will be ignored. The importer will only pull
                    data from the columns listed here.
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          )}
        </Box>
      )}
    </CSVReader>
  );
};

export default ImportCsvReader;
