import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { customShadow } from '@juno/ui';
import { ItemProps } from './';

interface LearnerTileProps {
  item: ItemProps;
  handleClose: () => void;
  locked: boolean;
  handleRemove: (item: ItemProps) => void;
  renderName: (firstName: string, lastName: string) => string;
  handleComplete: (item: ItemProps) => void;
  isLoadingComplete: string;
  isGraded: boolean;
}

const LearnerTile: React.FC<LearnerTileProps> = ({
  item,
  handleClose,
  locked,
  handleRemove,
  renderName,
  handleComplete,
  isLoadingComplete,
  isGraded,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <ListItem
      secondaryAction={
        !locked && (
          <>
            <IconButton edge='end' aria-label='delete' onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id='basic-menu'
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              sx={{ '.MuiPaper-root': { boxShadow: customShadow } }}
            >
              <MenuItem onClick={() => handleRemove(item)}>
                <ListItemIcon>
                  <ClearIcon fontSize='small' />
                </ListItemIcon>
                Remove
              </MenuItem>
              <MenuItem
                onClick={() => handleComplete(item)}
                disabled={
                  !!item.date_completed || isLoadingComplete == (item.user?.id || '') || isGraded
                }
              >
                <ListItemIcon>
                  {isLoadingComplete === item.user?.id ? (
                    <CircularProgress size={14} />
                  ) : (
                    <HowToRegIcon fontSize='small' />
                  )}
                </ListItemIcon>
                Mark Course Completed
              </MenuItem>
            </Menu>
          </>
        )
      }
    >
      <ListItemText
        primary={renderName(item.user?.first_name || '', item.user?.last_name || '')}
        secondary={item.user?.email || ''}
      />
    </ListItem>
  );
};

export default LearnerTile;
