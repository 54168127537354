import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, Box, Button, Dialog, IconButton, Toolbar, Typography } from '@mui/material';
import { PreviewFile } from '@juno/client-api/fakeModel';
import { AspectRatioType } from '../constants';
import ImageEditor from './ImageEditor';

interface ImageEditorDialogProps {
  open: boolean;
  file: PreviewFile;
  aspectRatios?: AspectRatioType[];
  onEdit: (editedImage: PreviewFile) => void;
  onCancel: () => void;
}

const ImageEditorDialog: React.FC<ImageEditorDialogProps> = ({
  open,
  file,
  aspectRatios,
  onEdit,
  onCancel,
}) => {
  const [saveImage, setSaveImage] = useState<boolean>(false);

  const cancelEdit = () => onCancel();
  const saveClick = () => setSaveImage(true);
  const finishEdit = (editedImage: PreviewFile) => {
    setSaveImage(false);
    onEdit(editedImage);
  };

  return (
    <Dialog open={open} onClose={cancelEdit}>
      <AppBar style={{ position: 'sticky' }}>
        <Toolbar>
          <IconButton onClick={cancelEdit} edge='start' color='inherit' aria-label='close'>
            <CloseIcon />
          </IconButton>
          <Typography variant='h6' sx={{ ml: 2, flex: 1 }}>
            Edit Image
          </Typography>
          <Button color='inherit' onClick={saveClick}>
            save
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ width: 600 }}>
        <ImageEditor
          imageFile={file}
          aspectRatios={aspectRatios}
          editCallback={finishEdit}
          saveImage={saveImage}
        />
      </Box>
    </Dialog>
  );
};

export default ImageEditorDialog;
