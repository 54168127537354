import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, MenuItem, Slider, Typography } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import AvatarEditor from 'react-avatar-editor';
import { useElementSize } from 'usehooks-ts';
import { PreviewFile } from '@juno/client-api/fakeModel';
import { ASPECT_RATIOS, ASPECT_RATIO_MAP, AspectRatioType } from '../constants';
import { AspectSelect, ControlContainer, LabelWrapper } from './styles';

export interface ImageEditorProps {
  imageFile: PreviewFile;
  saveImage: boolean;
  editCallback: (editedImage: PreviewFile) => void;
  aspectRatios?: AspectRatioType[];
}

const MASK_BORDER_SIZE = 30;
const AVATAR_COLOR = [0, 0, 0, 0.1];
const ZOOM_PROPS = { min: 0.25, max: 3.0, step: 0.01, default: 1 };
const ROTATE_PROPS = { min: -180, max: 180, step: 1, default: 0 };

const ImageEditor: React.FC<ImageEditorProps> = ({
  imageFile,
  saveImage,
  aspectRatios,
  editCallback,
}) => {
  const [selectedAspectRatio, setSelectedAspectRatio] = useState<string>(
    aspectRatios?.[0] || '1_1',
  );
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [containerRef, { width }] = useElementSize();
  const imgRef = useRef(null);

  const [imageWidth, imageHeight] = useMemo(() => {
    const adjustedWidth = width > MASK_BORDER_SIZE * 2 ? width - MASK_BORDER_SIZE * 2 : 1;
    const adjustedHeight = adjustedWidth / ASPECT_RATIO_MAP[selectedAspectRatio]?.ratio;
    return [adjustedWidth, adjustedHeight];
  }, [width, selectedAspectRatio]);

  useEffect(() => {
    const handleBlobUpdate = (imageBlob: Blob | null) => {
      if (!imageBlob) throw new Error('Failed to create image');
      const objectUrl = URL.createObjectURL(imageBlob);
      const editedFile = imageBlob as PreviewFile;
      editedFile.preview = objectUrl;
      editCallback(editedFile);
    };
    const runEditCallback = async () => {
      if (saveImage && imgRef?.current) {
        try {
          const editor = imgRef.current as unknown as AvatarEditor;
          const imageCanvas = editor.getImage();
          imageCanvas.toBlob(handleBlobUpdate, 'image/png', 1);
        } catch (ex) {
          console.error(ex);
          editCallback(imageFile);
        }
      }
    };
    runEditCallback();
  }, [saveImage, editCallback, imageFile]);

  const handleRatioChange = (e: SelectChangeEvent<unknown>) =>
    setSelectedAspectRatio(e.target.value as string);
  const handleZoomChange = (e: Event, val: number | number[]) => setScale(val as number);
  const handleRotateChange = (e: Event, val: number | number[]) => setRotate(val as number);

  return (
    <Box ref={containerRef}>
      <Box display='flex'>
        <ControlContainer>
          <LabelWrapper sx={{ justifyContent: 'center' }}>
            <Typography>Aspect Ratio: </Typography>
          </LabelWrapper>
          {/*Provide aspect ratio dropdown if the aspect ratio isn't preset*/}
          {(!aspectRatios || aspectRatios.length !== 1) && (
            <AspectSelect
              aria-label='Aspect ratio'
              value={selectedAspectRatio}
              onChange={handleRatioChange}
              disabled={!!aspectRatios}
            >
              {Object.values(ASPECT_RATIO_MAP)
                .filter((dimObj) => !aspectRatios || dimObj.value in aspectRatios)
                .map((dimObj) => (
                  <MenuItem value={dimObj.value} key={dimObj.value}>
                    {dimObj.label}
                  </MenuItem>
                ))}
            </AspectSelect>
          )}
          {/*Hard Code the aspect ratio when it is preset so the user knows it isn't editable*/}
          {aspectRatios && aspectRatios.length === 1 && (
            <Typography sx={{ textAlign: 'center' }}>
              {ASPECT_RATIO_MAP[aspectRatios[0]].width}x{ASPECT_RATIO_MAP[aspectRatios[0]].height}
            </Typography>
          )}
        </ControlContainer>
        <ControlContainer>
          <LabelWrapper>
            <Typography>Zoom: </Typography>
            <Typography>{scale}</Typography>
          </LabelWrapper>
          <Slider
            aria-label='Zoom slider'
            min={ZOOM_PROPS.min}
            max={ZOOM_PROPS.max}
            step={ZOOM_PROPS.step}
            defaultValue={ZOOM_PROPS.default}
            onChange={handleZoomChange}
          />
        </ControlContainer>
        <ControlContainer>
          <LabelWrapper>
            <Typography>Straighten: </Typography>
            <Typography>{rotate}</Typography>
          </LabelWrapper>
          <Slider
            aria-label='Straighten slider'
            min={ROTATE_PROPS.min}
            max={ROTATE_PROPS.max}
            step={ROTATE_PROPS.step}
            defaultValue={ROTATE_PROPS.default}
            onChange={handleRotateChange}
          />
        </ControlContainer>
      </Box>
      <AvatarEditor
        ref={imgRef}
        image={imageFile?.preview}
        width={imageWidth}
        height={imageHeight}
        border={MASK_BORDER_SIZE}
        color={AVATAR_COLOR}
        scale={scale}
        rotate={rotate}
      />
    </Box>
  );
};

export default ImageEditor;
