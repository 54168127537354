import React from 'react';
import { NotificationsActive as NotificationsActiveIcon } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { StatusIconItem } from './styles';

export interface StatusIconProps {
  status?: string;
}

const StatusIcon: React.FC<StatusIconProps> = ({ status }) => {
  let color: 'info' | 'disabled' | 'warning' | 'danger' | 'success' = 'info';
  switch (status) {
    case 'Active':
      color = 'success';
      break;
    case 'Draft':
      color = 'disabled';
      break;
    case 'Completed':
      color = 'info';
      break;
  }

  return (
    <Stack direction='row' justifyContent='space-between' alignItems='center'>
      <StatusIconItem
        sx={{
          fontSize: '16px',
          padding: '0 5px',
          boxShadow: 'none',
          backgroundColor: 'transparent',
        }}
      >
        {status}
      </StatusIconItem>
      <NotificationsActiveIcon color={color} />
    </Stack>
  );
};
export default StatusIcon;
