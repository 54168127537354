import React, { useRef, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Editor } from '@tinymce/tinymce-react';
import { useLocation } from 'react-router-dom';
import { getAllUsers, useCreateFeedThread, useGetChildThreads } from '@juno/client-api';
import { CommunityUser, Thread, ThreadBody } from '@juno/client-api/model';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';
import { customShadow } from '@juno/ui';
import { MutationAction, calculateUserSearchFilters, onMutation, useSettings } from '@juno/utils';
import ThreadPost from '..';
import { useAnalyticsContext } from '../../Contexts/AnalyticsContext';
import { convertTinyMceUsers, createTinyMceMentionSpan } from '../ThreadEditor/helpers';
import ThreadPostComments from '../ThreadPost/ThreadPostComments';
import { ThreadPostAuthorAvatar, ThreadPostSkeleton } from '../ThreadPost/styles';

interface SinglePostViewProps {
  siteId: string;
  singleThread: Thread | undefined;
  onGoBack: () => void;
  platformId: string;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  currentCommunityUser: CommunityUser;
  onNavigate?: (props: any) => void;
  refetch: () => void;
  userCanInteract?: boolean;
  isInForum?: boolean;
}
const SinglePostView: React.FC<SinglePostViewProps> = ({
  siteId,
  singleThread,
  onGoBack,
  platformId,
  setData,
  currentCommunityUser,
  onNavigate,
  refetch,
  userCanInteract = true,
  isInForum = false,
}) => {
  const theme = useTheme();
  const { getImageSrc } = useSettings();
  const dialogRef = useRef<HTMLDivElement>(null);
  const { firehoseActivity } = useAnalyticsContext();
  const location = useLocation();

  const [commentParent, setCommentParent] = useState<Thread>(singleThread || ({} as Thread));
  const [dirty, setDirty] = useState(false);

  const editorRef = useRef<any>(null);
  const userMentions = useRef<CommunityUser[]>(singleThread?.tagged_users || []);
  const {
    data: threadChildren,
    isLoading: threadChildrenLoading,
    refetch: refetchChildren,
  } = useGetChildThreads(
    siteId,
    singleThread?.id || '',
    {},
    { query: { enabled: singleThread && singleThread?.num_replies > 0 } },
  );

  const handleRefetch = () => {
    refetchChildren();
    refetch();
  };

  const threadCreate = useCreateFeedThread(onMutation(MutationAction.CREATE, '', handleRefetch));

  const focusInput = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  const mentions_fetch = async (query: any, success: any) => {
    // TODO - do we want this to only search group members
    const filter = calculateUserSearchFilters(query.term.toLowerCase());
    const allUsers = await getAllUsers(platformId, {
      ...filter,
      order: 'first_name',
      limit: 10,
    });
    const tinyUsers = convertTinyMceUsers(allUsers, getImageSrc ?? (() => ''));
    success(tinyUsers);
  };

  return (
    <>
      <Box mr={2}>
        <Tooltip title='Back to feed'>
          <IconButton onClick={onGoBack}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {singleThread === undefined || singleThread === null ? (
        <Box>
          <ThreadPostSkeleton />
        </Box>
      ) : (
        <>
          <ThreadPost
            showingRepliesAsSingleThread={true}
            userCanInteract={userCanInteract}
            setData={(newData) => {
              setData(newData);
              handleRefetch();
            }}
            platformId={platformId}
            onNavigate={onNavigate}
            key={singleThread.id}
            siteId={siteId}
            thread={singleThread}
            refetchParentThread={handleRefetch}
            handleOpenPostDetails={(thread: Thread) => {
              setCommentParent(thread);
              editorRef.current?.setContent('');
              focusInput();
            }}
          />
          {!threadChildrenLoading && threadChildren && threadChildren?.length > 0 && (
            <Box ml={3}>
              <ThreadPostComments
                userCanInteract={userCanInteract}
                setData={(newData) => {
                  setData(newData);
                  handleRefetch();
                }}
                platformId={platformId}
                siteId={siteId}
                threads={threadChildren ?? []}
                refetchParentThread={handleRefetch}
                onNavigate={onNavigate}
                handleCommentClick={(newParent: Thread) => {
                  setCommentParent(newParent);
                  editorRef.current?.setContent('');
                  focusInput();
                  const span = createTinyMceMentionSpan(
                    editorRef.current,
                    newParent.author ?? ({} as CommunityUser),
                  );
                  editorRef.current?.insertContent(span.outerHTML);
                }}
                dialogRef={dialogRef}
              />
            </Box>
          )}
          {userCanInteract && (
            <Box sx={{ boxShadow: customShadow }}>
              <Box p='2px 8px' width='100%' display={'flex'} justifyContent='space-around'>
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  flexDirection='column'
                >
                  <IconButton onClick={() => focusInput()} aria-label='Start comment'>
                    <ThreadPostAuthorAvatar
                      src={getImageSrc(
                        currentCommunityUser.icon ||
                          currentCommunityUser.avatar ||
                          'UserIcon_CrowdHub.jpg',
                      )}
                    />
                  </IconButton>
                </Box>
                <Editor
                  apiKey={process.env.NX_TINY_MCE_API_KEY}
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={''}
                  onDirty={() => setDirty(true)}
                  init={{
                    contextmenu: false,
                    max_height: 10,
                    auto_focus: true,
                    highlight_on_focus: true,
                    placeholder: 'Add a comment...',
                    content_css: '/styles.css',
                    content_style: `.user_mention{ color: ${theme.palette.primary.main};, cursor: pointer; }`,
                    mentions_selector: 'span.user_mention',
                    mentions_menu_complete: (editor: any, userInfo: any) => {
                      userMentions.current = [...userMentions.current, userInfo];
                      const span = createTinyMceMentionSpan(editor, userInfo);
                      return span;
                    },
                    mentions_fetch: mentions_fetch,
                    mentions_item_type: 'profile',
                    height: 60,
                    width: '100%',
                    setup: (editor) => {
                      editor.setProgressState(true);
                    },
                    toolbar: false,
                    menubar: false,
                    statusbar: false,
                  }}
                  plugins={'autolink emoticons link mentions'}
                />
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  flexDirection='column'
                >
                  <Button
                    sx={{
                      ml: 2,
                    }}
                    aria-label='Post comment'
                    variant='contained'
                    disabled={!dirty}
                    onClick={async () => {
                      if (editorRef.current?.getContent() === '') {
                        focusInput();
                        return;
                      }
                      // double check that the user mentions are in the body
                      const taggedUsers = userMentions.current?.filter((m) =>
                        editorRef.current?.getContent().includes(m.id),
                      );
                      const pageSlug = location.pathname.split('/').pop() || '';
                      const data = {
                        body: editorRef.current?.getContent() as unknown as ThreadBody,
                        author_id: currentCommunityUser.id,
                        parent_thread_id: commentParent.id,
                        tagged_users: taggedUsers,
                      } as Thread;
                      const newThread = await threadCreate.mutateAsync({
                        siteId: siteId,
                        feedId: singleThread.id,
                        data: {
                          ...data,
                          metadata: {
                            page_slug: pageSlug,
                          },
                        },
                      });
                      firehoseActivity(
                        ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_COMMUNITY,
                        singleThread?.parent_id || '',
                        null,
                        `${currentCommunityUser?.id || ''}`,
                        isInForum
                          ? ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_FORUM
                          : ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_FEED,
                        commentParent?.parent_thread_id
                          ? ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_REPLY.value
                          : ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_COMMENT.value,
                        '',
                        null,
                      );
                      setData((old) => {
                        const updated = old.map((t) => {
                          if (t.id === newThread.parent_thread_id) {
                            return { ...t, num_replies: t.num_replies + 1 };
                          }
                          return t;
                        });
                        return updated;
                      });
                      setCommentParent(singleThread);
                      editorRef.current?.setContent('');
                    }}
                  >
                    Post
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
};
export default SinglePostView;
