import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useGetPlatform, useGetSite } from '@juno/client-api';
import { useGetUserRoleMap } from '@juno/client-api/helpers';
import { FeatureConfig as FeatureConfigModel } from '@juno/client-api/model';
import { Juno404, JunoSpin } from '@juno/ui';
import { useSettings } from '@juno/utils';
import { useBreakpoint } from '@juno/utils/hooks';
import { PrereqEditPage } from '../index';
import AdminCoursePanel from './admin/CoursePanel';
import AdminCoursesPanel from './admin/CoursesPanel';
import AdminLessonPanel from './admin/LessonPanel';
import ClientCoursePage from './client/CoursePage';
import ClientCoursesPanel from './client/CoursesPanel';
import ClientLessonPage from './client/LessonPage';

declare global {
  interface Window {
    JunoWidgetEventBus: any;
  }
}

export interface PreferredTag {
  tag_id: string;
  tag_name: string;
  tag_type: string;
}
export interface JunoLearningProps {
  siteId: string;
  configs: FeatureConfigModel[];
}

const JunoLearning: React.FC<JunoLearningProps> = ({ siteId, configs }) => {
  const roleMap = useGetUserRoleMap(siteId);
  const settings = useSettings();
  const { setCurrentUser, setCurrentSite } = settings;
  const { data: siteData, isLoading: siteLoading } = useGetSite(siteId);
  const { user: userData, isSiteAdmin, isGroupAdmin, isInstructor } = roleMap;
  const isAdmin = isSiteAdmin || isInstructor || isGroupAdmin;
  const isLoading = siteLoading || roleMap.isLoading;
  const { data: platformData, isLoading: platformLoading } = useGetPlatform(siteId);

  useEffect(() => {
    setCurrentUser(userData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    setCurrentSite(siteData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteData]);

  if (isLoading || platformLoading) return <JunoSpin />;
  if (!userData || !siteData || !platformData)
    return (
      <Typography variant='h6' color='text.primary' sx={{ textAlign: 'center' }}>
        {!userData && 'No user found'}
        <br />
        {!siteData && 'No site found'}
      </Typography>
    );

  /*
  // Admin routes
  if (isAdmin) {
    switch (settings.routeKey) {
      case JUNO_ROUTE_MAP.ADMIN_COURSES.key:
        return <AdminCoursesPanel site={siteData} />;
      case JUNO_ROUTE_MAP.ADMIN_COURSE.key:
      case JUNO_ROUTE_MAP.ADMIN_COURSE_TAB.key:
        return <AdminCoursePanel site={siteData} />;
      case JUNO_ROUTE_MAP.ADMIN_LESSON.key:
        return <AdminLessonPanel site={siteData} />;
    }
  }

  // Client routes
  switch (settings.routeKey) {
    case JUNO_ROUTE_MAP.CLIENT_COURSE.key:
      return <ClientCoursePage siteId={siteId} settings={{ courseSlug, lessonSlug }} />;
    case JUNO_ROUTE_MAP.CLIENT_LESSON.key:
      return <ClientLessonPage siteId={siteId} settings={{ courseSlug, lessonSlug }} />;
    default:
      return (
        <ClientCoursesPanel
          isAdmin={isAdmin}
          routeKey={settings.routeKey}
          siteId={siteId}
          siteSubdomain={siteData.subdomain}
          configs={configs}
        />
      );
  }
  */

  /*
export const JUNO_ROUTE_MAP = {
  CLIENT_COURSES: {
    key: 'navigate.learning.page.courses',
    route: '/courses',
    params: [],
  },
  CLIENT_COURSE: {
    key: 'navigate.learning.course.slug',
    route: '/courses/:courseSlug',
    params: ['courseSlug', 'coursesSlug'],
  },
  CLIENT_LESSON: {
    key: 'navigate.learning.lesson.slug',
    route: '/courses/:courseSlug/:lessonSlug',
    params: ['courseSlug', 'lessonSlug'],
  },
  ADMIN_COURSES: {
    key: 'navigate.learning.admin.courses',
    route: '/sites/:siteSubdomain/courses',
    params: ['siteSubdomain'],
  },
  ADMIN_COURSE: {
    key: 'navigate.learning.admin.courses.slug',
    route: '/sites/:siteSubdomain/courses/:courseSlug/info',
    params: ['siteSubdomain', 'courseSlug'],
  },
  ADMIN_COURSE_TAB: {
    key: 'navigate.learning.admin.courses.slug.tab',
    route: '/sites/:siteSubdomain/courses/:courseSlug/:tabSlug',
    params: ['siteSubdomain', 'courseSlug', 'tabSlug'],
  },
  ADMIN_LESSON: {
    key: 'navigate.learning.admin.courses.slug.lessons.slug',
    route: '/sites/:siteSubdomain/courses/:courseSlug/lessons/:lessonSlug',
    params: ['siteSubdomain', 'courseSlug', 'lessonSlug'],
  },
};

  */

  const pathPrefix =
    (settings.isApp ? '/app.html' : '') +
    (settings.sitePath && settings.sitePath.length > 0 ? `/${settings.sitePath}` : '');
  return (
    <Routes>
      <Route
        path={`${pathPrefix}/learning-admin/courses`}
        element={<AdminCoursesPanel site={siteData} />}
      />
      <Route
        path={`${pathPrefix}/learning-admin/courses/:courseSlug/:tabSlug`}
        element={<AdminCoursePanel site={siteData} />}
      />
      <Route
        path={`${pathPrefix}/learning-admin/courses/prereqs/:prereqId`}
        element={<PrereqEditPage site={siteData} />}
      />
      <Route
        path={`${pathPrefix}/learning-admin/courses/:courseSlug/lessons/:lessonSlug`}
        element={<AdminLessonPanel site={siteData} />}
      />
      <Route path={'*'} element={<Juno404 />} />
      <Route
        path={`${pathPrefix}/learning/:coursesSlug`}
        element={
          <ClientCoursesPanel
            isAdmin={isAdmin}
            routeKey={settings.routeKey}
            siteId={siteId}
            configs={configs}
          />
        }
      />
      <Route
        path={`${pathPrefix}/courses/:courseSlug`}
        element={<ClientCoursePage siteId={siteId} />}
      />
      <Route
        path={`${pathPrefix}/courses/:courseSlug/:lessonSlug`}
        element={<ClientLessonPage siteId={siteId} />}
      />
    </Routes>
  );
};
//window.PLATFORM_DATA.Platform.Sites[0].Path;
const WrappedJunoLearning: React.FC<JunoLearningProps> = (props) => {
  const { siteId, configs } = props;
  const { xs } = useBreakpoint();

  return (
    <Box
      sx={{ p: xs ? '20px 20px 50px 20px' : '32px 32px 50px 32px' }}
      display={'flex'}
      justifyContent={'center'}
    >
      <JunoLearning siteId={siteId} configs={configs} />
    </Box>
  );
};

export default WrappedJunoLearning;
