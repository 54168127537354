import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useGetPages, useGetSite } from '@juno/client-api';
import { Course as CourseModel, FeatureConfig as FeatureConfigModel } from '@juno/client-api/model';
import { Page } from '@juno/client-api/utils';
import { JUNO_ROUTE_MAP } from '@juno/constants';
import { JunoModule } from '@juno/modules';
import { Juno404, JunoSpin, PreviewItemProps } from '@juno/ui';
import { useRouteNavigate, useSettings } from '@juno/utils';
import { useBreakpoint } from '@juno/utils/hooks';
import FETButton from '../components/FETButton';
import { CoursesWrapper } from './styles';

export interface DataSourceProps {
  courses: CourseModel[] | undefined;
}

export type PanelType = 'inperson' | 'virtual' | 'ondemand';

export const PANEL_TYPE = {
  INPERSON: 'inperson' as PanelType,
  VIRTUAL: 'virtual' as PanelType,
  ONDEMAND: 'ondemand' as PanelType,
};

interface CoursesPanelProps {
  isAdmin?: boolean;
  routeKey: string | undefined;
  siteId: string;
  configs: FeatureConfigModel[];
}

const CoursesPanel: React.FC<CoursesPanelProps> = ({ isAdmin, routeKey, siteId, configs }) => {
  const learningNavigate = useRouteNavigate();
  const { openExternalUrl, isApp } = useSettings();
  const { data: pages, isLoading } = useGetPages(siteId);

  const { data: site, isLoading: siteLoading } = useGetSite(siteId);

  const { pathname } = useLocation();
  const params = useParams();
  const coursesSlug: string = params.coursesSlug || '';
  //const coursesSlug = //pathname.split('/').reverse()?.[0] || '';

  //const coursesSlug = routeKey.split('.').reverse()?.[0] || '';
  const page = Page.getPageBySlug(pages, coursesSlug);
  const { xs } = useBreakpoint();

  if (isLoading) return <JunoSpin />;
  if (!siteId || !page) return <Juno404 />;

  const handleSelectCourse = (item: PreviewItemProps) => {
    learningNavigate(JUNO_ROUTE_MAP.CLIENT_COURSE, { courseSlug: item.slug });
  };

  const handleSelectFET = () => {
    learningNavigate(JUNO_ROUTE_MAP.ADMIN_COURSES, { siteSlug: site?.slug || '' });
  };

  return (
    <CoursesWrapper>
      {page.components.map((component) => (
        <JunoModule
          key={component.id}
          siteId={siteId}
          configs={configs}
          component={{
            ...component,
            data: {
              ...component.data,
              onSelect: handleSelectCourse,
              openExternalUrl: openExternalUrl,
            },
          }}
        />
      ))}
      {isAdmin && !isApp && !xs && <FETButton onClick={handleSelectFET} />}
    </CoursesWrapper>
  );
};

export default CoursesPanel;
