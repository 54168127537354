import React, { useEffect, useRef, useState } from 'react';
import { Box, Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDebounce } from 'usehooks-ts';
import { Question } from '@juno/client-api/model';

interface RatingProps {
  question: Question;
  handleSetAnswer: (answer: string[]) => void;
  answer: string[];
}

const RatingContainer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} 0`,
}));

function valuetext(value: number) {
  return `${value}`;
}

export default function Rating({
  question,
  answer,
  handleSetAnswer,
}: RatingProps): React.ReactElement {
  const { answers, name, answer_changeable, metadata } = question;
  const [selectedAnswer, setSelectedAnswer] = useState<null | number>(null);
  const debouncedAnswer = useDebounce(selectedAnswer, 500);
  const initialLoad = useRef(true);

  useEffect(() => {
    // Set the initial selected value if an answer is provided
    if (answer && answer.length > 0) {
      setSelectedAnswer(parseInt(answer[0]));
    }
  }, [answer]);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
    } else {
      handleSetAnswer([`${debouncedAnswer}`]);
    }
  }, [debouncedAnswer]);

  const marks = [];

  if (
    typeof metadata?.increment == 'number' &&
    typeof metadata?.min === 'number' &&
    typeof metadata?.max === 'number'
  ) {
    for (let i = 0; i <= (metadata.max - metadata.min) / metadata.increment; i++) {
      marks.push({
        value: metadata.min + i * metadata.increment,
        label: `${metadata.min + i * metadata.increment}`,
      });
    }
    return (
      <RatingContainer>
        <Slider
          aria-label='Temperature'
          value={selectedAnswer === null ? 3 : selectedAnswer}
          valueLabelDisplay='auto'
          step={metadata?.increment || 1}
          marks={marks}
          min={metadata?.min || 0}
          getAriaValueText={valuetext}
          max={metadata?.max || 10}
          onChange={(e, value) => setSelectedAnswer(Array.isArray(value) ? value[0] : value)}
        />
      </RatingContainer>
    );
  }

  return <></>;
}
