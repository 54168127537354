import * as React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useGetAllTagTypes, useGetAllTags } from '@juno/client-api';
import { Tag } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
interface AutocompleteTagsProps {
  formik: any;
  label: string;
  disabled?: boolean;
}

const AutocompleteTags: React.FC<AutocompleteTagsProps> = ({ formik, label, disabled }) => {
  const { site } = useSettings();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Tag[]>([]);
  const [selected, setSelected] = React.useState<Tag[] | undefined>(formik.values.tags);
  const [input, setInput] = React.useState<string>(formik.values.tag?.name || '');
  const loading = open && options.length === 0;

  const { data: tags, isLoading } = useGetAllTags(
    site?.id || '',
    {},
    { query: { enabled: !!site?.id } },
  );

  const handleSelect = (reason: string, value: Tag[] | undefined) => {
    formik.setFieldValue('tags', value);
    setSelected(value);
  };

  React.useEffect(() => {
    if (tags) {
      setOptions(tags);
    }
  }, [isLoading, tags]);

  const sortedOptions = options && [...options];
  sortedOptions?.sort((a, b) => -b?.value?.localeCompare(a?.value));

  return (
    <Autocomplete
      id='autocomplete-tags'
      disabled={formik.values.is_active || disabled}
      disableCloseOnSelect
      open={open}
      multiple
      disablePortal
      groupBy={(option) => option.type.value}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(event, newInputValue) => {
        setInput(newInputValue);
      }}
      onChange={(
        event: React.SyntheticEvent<Element, Event>,
        value: Tag[] | undefined,
        reason: string,
      ) => {
        handleSelect(reason, value);
      }}
      getOptionLabel={(option) => `${option.value}`}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option.value}`}
        </li>
      )}
      options={sortedOptions}
      loading={loading}
      value={selected ? selected : []}
      inputValue={input}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip {...getTagProps({ index })} label={<span>{option.value}</span>}></Chip>
        ))
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          size='small'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteTags;

{
  /* <Autocomplete
id='tags-autocomplete'
multiple
options={allTags}
getOptionLabel={(tag) => tag.value}
isOptionEqualToValue={(option, value) => option.id === value.id}
onChange={(_, value) => formik.handleChange({ target: { name: 'tags', value } })}
value={formik.values.tags}
renderInput={(params) => <TextField {...params} variant='standard' />}
/> */
}
function SiteContext(SiteContext: any) {
  throw new Error('Function not implemented.');
}

interface AutocompleteTagsGenericProps {
  tags: Tag[];
  setTags: (tags: Tag[]) => void; // New prop to manage tags state
  label: string;
  siteId: string;
  disabled?: boolean;
}

export const AutocompleteTagsGeneric: React.FC<AutocompleteTagsGenericProps> = ({
  tags,
  setTags,
  label,
  siteId,
  disabled,
}) => {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Tag[]>([]);
  const [selected, setSelected] = React.useState<Tag[]>(tags);
  const [input, setInput] = React.useState<string>('');
  const loading = open && options.length === 0;

  const { data: tagTypes, isLoading: isLoadingTagTypes } = useGetAllTagTypes(siteId);

  const { data: allTags, isLoading } = useGetAllTags(
    siteId,
    {
      filter: {
        type_id: tagTypes?.find((tagType) => tagType.value === 'Global Declared')?.id,
      },
    },
    { query: { enabled: !!tagTypes } },
  );

  React.useEffect(() => {
    if (allTags) {
      setOptions(allTags);
    }
  }, [isLoading, allTags]);

  const sortedOptions = options && [...options];
  sortedOptions?.sort((a, b) => -b?.value?.localeCompare(a?.value));

  const handleSelect = (value: Tag[] | undefined) => {
    setTags(value || []);
    setSelected(value || []);
  };

  return (
    <Autocomplete
      id='autocomplete-tags'
      disabled={disabled}
      disableCloseOnSelect
      open={open}
      multiple
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(event, newInputValue) => {
        setInput(newInputValue);
      }}
      onChange={(event: React.SyntheticEvent<Element, Event>, value: Tag[] | undefined) => {
        handleSelect(value);
      }}
      getOptionLabel={(option) => `${option.value}`}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option.value}`}
        </li>
      )}
      options={sortedOptions}
      loading={loading}
      value={selected}
      inputValue={input}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip {...getTagProps({ index })} label={<span>{option.value}</span>}></Chip>
        ))
      }
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant='filled'
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
