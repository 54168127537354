import React from 'react';
import { Box, Button, Typography } from '@mui/material';

interface ImporterHeaderButtonsProps {
  currentTab: number;
  totalErrors: number;
  shouldShow: boolean;
  onClickDiscard: () => void;
  onClickValidate: () => void;
}
const ImporterHeaderButtons: React.FC<ImporterHeaderButtonsProps> = ({
  currentTab,
  totalErrors,
  shouldShow,
  onClickDiscard,
  onClickValidate,
}) => {
  if ((!shouldShow && currentTab === 0) || currentTab > 0) {
    return <></>;
  }
  return (
    <Box>
      <Button
        variant='outlined'
        size='small'
        sx={{
          color: 'primary.main',
          letterSpacing: '3px',
          m: 0,
          mr: 2,
          backgroundColor: 'white',
          height: 'fit-content',
          p: '12px 45px',
        }}
        onClick={onClickDiscard}
      >
        Discard
      </Button>
      <Button
        variant='contained'
        size='small'
        sx={{ letterSpacing: '3px', m: 0, height: 'fit-content', p: '12px 45px' }}
        onClick={onClickValidate}
      >
        {totalErrors === 0 ? (
          <>Publish</>
        ) : (
          <>
            <Typography
              variant='caption'
              minWidth='24px'
              color='primary'
              pr='4px'
              pl='4px'
              mr='5px'
              sx={{ backgroundColor: 'white', borderRadius: '10px' }}
            >
              {totalErrors}
            </Typography>
            Validate
          </>
        )}
      </Button>
    </Box>
  );
};
export default ImporterHeaderButtons;
