export * from './junoImage';
export * from './previewFile';

export enum AlignmentEnum {
    left = 'left',
    center = 'center',
    right = 'right',
  }
  export enum SizeEnum {
      small = 'small',
      medium = 'medium',
      large = 'large',
    }