import notification from '../notification';

export const cancelVideoFileUpload = async (promise: Promise<any>) => {
  // TODO: Hard coding this to true for now, as it's not necessary for our current stage and we're crunching to launch the full shell.
  // Storage.cancel(promise, 'The video upload was successfully canceled');
  try {
    await promise;
  } catch (err) {
    return { success: true, message: (err as Error).message };
  }
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const convertToCSV = (arr: any) => {
  const array = [Object.keys(arr[0])].concat(arr);

  return array
    .map((it) => {
      /**
       * Each value may have both double quotes, single quotes and/or commas:
       * 1. Replace double quotes with two double quotes
       * 2. Wrap the value in double quotes
       * 3. This should handle commas being inside values when converted to CSV
       */
      return Object.values(it)
        .map((value) => {
          let val = value === null || value === undefined ? '' : value.toString();
          val = val.replace(/"/g, '""');
          if (val.search(/[",\n]/g) >= 0) val = `"${val}"`;
          return val;
        })
        .join(',');
    })
    .join('\n');
};

export const validateSelectedFileSize = (file: File | undefined) => {
  const MAX_FILE_SIZE = 10240; // 10MB

  if (!file) {
    notification.error('Please choose a file');
    return false;
  }

  const fileSizeKiloBytes = file.size / 1024;

  if (fileSizeKiloBytes > MAX_FILE_SIZE) {
    notification.error('File size is greater than 10MB');
    return false;
  }

  return true;
};

export const truncateFileName = (fileName: string) => {
  if (fileName.length <= 15) {
    return fileName;
  }

  const extension = fileName.slice(-10);
  const visiblePart = fileName.substring(0, 7);

  return `${visiblePart}...${extension}`;
};

export const handleFileDownload = async (url: string, fileName: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const downloadUrl = URL.createObjectURL(blob);

  const anchor = document.createElement('a');
  anchor.href = downloadUrl;
  anchor.download = fileName || 'download';
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(downloadUrl);
};
