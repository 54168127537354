import React, { useEffect, useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Switch,
  Tab,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  useBatchUpdateContentReports,
  useGetAllForumTopics,
  useGetAllThreads,
  useGetContentReports,
  useGetContentReportsCount,
  useGetGroupContentReports,
  useUpdateContentReport,
} from '@juno/client-api';
import {
  ContentReport,
  ContentReportWithUsers,
  ForumTopic,
  GetContentReportsCountParams,
  JunoUser,
  ReasonEnum,
  SearchContentTypeEnum,
  StatusD4cEnum,
  Thread,
} from '@juno/client-api/model';
import { CustomTabPanel, UserQuickView, a11yProps } from '@juno/ui';
import {
  MutationAction,
  getContentRoute,
  onMutation,
  useContentReportsSearchFilter,
  useSettings,
} from '@juno/utils';
import ModerationItemTile from './ModerationItemTile';
import { ModerationTabs, ReportsBadge, ReportsPagination } from './styles';

interface ModerationAdminProps {
  groupId?: string;
}

export interface ContentReportWithContent extends ContentReportWithUsers {
  topic?: ForumTopic;
  thread?: Thread;
}

// the strings for the reasons dont match with the strings on the report content dialog
export const reasonMap = [
  { type: ReasonEnum.Illegal__Copywrited, string: 'Illegal / Copywrited Content' },
  { type: ReasonEnum.Harassment, string: 'Harassment' },
  { type: ReasonEnum.Inappropriate, string: 'Inappropriate Content' },
  { type: ReasonEnum.False_Information, string: 'False Information' },
  { type: ReasonEnum.Other, string: 'Other' },
];

const PAGE_SIZE = 8;

const ModerationAdmin: React.FC<ModerationAdminProps> = ({ groupId }) => {
  const { site } = useSettings();
  const [userQuickViewAnchorEl, setUserQuickViewAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<JunoUser | null>(null);
  const [tab, setTab] = useState(0);
  const [batch, setBatch] = useState(false);
  const [sort, setSort] = useState('-date_created');
  const [expandedContent, setExpandedContent] = useState<string>('');
  const [selectedContent, setSelectedContent] = useState<string[]>([]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
    setExpandedContent('');
    setNeedsReviewPage(0);
    setPreviouslyReviewedPage(0);
  };
  const [needsReviewPage, setNeedsReviewPage] = useState(0);
  const [previouslyReviewedPage, setPreviouslyReviewedPage] = useState(0);
  const [, , searchFilter, setSearch] = useContentReportsSearchFilter();
  const navigateRoute = useNavigate();

  /*******************************************************************
   * GET NEEDS REVIEW REPORTS AND PREVIOUSLY REVIEWED REPORTS        *
   * 2/4 QUERIES ARE ENABLED DEPENDING ON WHETHER A GROUP IS SCOPED  *
   *******************************************************************/
  const {
    data: needsReviewGroupReports,
    isFetching: needsReviewGroupReportsFetching,
    refetch: refetchNeedsReviewGroupReports,
  } = useGetGroupContentReports(
    site?.id || '',
    groupId || '',
    {
      filter_or: {
        ...searchFilter,
      },
      filter_and: {
        group: groupId,
        status__in: [StatusD4cEnum.Needs_Review],
      },
      order: sort,
      offset: needsReviewPage,
      limit: PAGE_SIZE,
    },
    { query: { enabled: !!groupId } },
  );

  const {
    data: previouslyReviewedGroupReports,
    isFetching: previouslyReviewedGroupReportsFetching,
    refetch: refetchPreviouslyReviewedGroupReports,
  } = useGetGroupContentReports(
    site?.id || '',
    groupId || '',
    {
      filter_or: {
        ...searchFilter,
      },
      filter_and: {
        group: groupId,
        status__in: [StatusD4cEnum.Ignored, StatusD4cEnum.Moderated, StatusD4cEnum.Restored],
      },
      order: sort,
      offset: previouslyReviewedPage,
      limit: PAGE_SIZE,
    },
    { query: { enabled: !!groupId } },
  );

  const {
    data: needsReviewReports,
    isFetching: needsReviewReportsFetching,
    refetch: refetchNeedsReviewReports,
  } = useGetContentReports(
    site?.id || '',
    'all',
    {
      filter_or: {
        ...searchFilter,
      },
      filter_and: {
        group: groupId,
        status__in: [StatusD4cEnum.Needs_Review],
      },
      order: sort,
      offset: needsReviewPage,
      limit: PAGE_SIZE,
    },
    {
      query: { enabled: !groupId },
    },
  );

  const {
    data: previouslyReviewedReports,
    isFetching: previouslyReviewedReportsFetching,
    refetch: refetchPreviouslyReviewedReports,
  } = useGetContentReports(
    site?.id || '',
    'all',
    {
      filter_or: {
        ...searchFilter,
      },
      filter_and: {
        group: groupId,
        status__in: [StatusD4cEnum.Ignored, StatusD4cEnum.Moderated, StatusD4cEnum.Restored],
      },
      order: sort,
      offset: needsReviewPage,
      limit: PAGE_SIZE,
    },
    {
      query: { enabled: !groupId },
    },
  );

  /*******************************************************************
   * GET ATTACHED DISCUSSIONS AND THREADS                             *
   *******************************************************************/

  const contentIds = useMemo(() => {
    if (groupId) {
      return [
        ...(needsReviewGroupReports || []).map((r) => r.object_id),
        ...(previouslyReviewedGroupReports || []).map((r) => r.object_id),
      ];
    } else {
      return [
        ...(needsReviewReports || []).map((r) => r.object_id),
        ...(previouslyReviewedReports || []).map((r) => r.object_id),
      ];
    }
  }, [
    needsReviewGroupReports,
    previouslyReviewedGroupReports,
    needsReviewReports,
    previouslyReviewedReports,
  ]);

  const { data: discussions, isFetching: discussionsLoading } = useGetAllForumTopics(
    site?.id || '',
    {
      filter: {
        id__in: contentIds,
      },
    },
    { query: { enabled: contentIds.length > 0 } },
  );

  const { data: threads, isFetching: threadsLoading } = useGetAllThreads(
    site?.id || '',
    {
      filter: {
        id__in: contentIds,
      },
    },
    { query: { enabled: contentIds.length > 0 } },
  );

  /*******************************************************************
   * GET TOTAL COUNTS OF NEEDS REVIEW / PREV REVIEWED FOR PAGINATION *
   *******************************************************************/

  const needsReviewCountParams = {
    statuses: [StatusD4cEnum.Needs_Review],
  } as GetContentReportsCountParams;
  if (groupId) {
    needsReviewCountParams.group_id = groupId;
  }

  const previouslyReviewedCountParams = {
    statuses: [StatusD4cEnum.Moderated, StatusD4cEnum.Ignored, StatusD4cEnum.Restored],
  } as GetContentReportsCountParams;
  if (groupId) {
    previouslyReviewedCountParams.group_id = groupId;
  }

  const { data: needsReviewCount, refetch: refetchNeedsReviewCount } = useGetContentReportsCount(
    site?.id || '',
    needsReviewCountParams,
    {
      query: { enabled: true },
    },
  );

  const { data: previouslyReviewedCount, refetch: refetchPreviouslyReviewedCount } =
    useGetContentReportsCount(site?.id || '', previouslyReviewedCountParams, {
      query: { enabled: true },
    });

  /*******************************************************************
   * REFETCH AND HANDLE UPDATE REPORT
   *******************************************************************/

  const refetch = async () => {
    refetchNeedsReviewGroupReports();
    refetchNeedsReviewReports();
    refetchPreviouslyReviewedGroupReports();
    refetchPreviouslyReviewedReports();
    refetchNeedsReviewCount();
    refetchPreviouslyReviewedCount();
  };

  const updateReport = useUpdateContentReport(onMutation(MutationAction.UPDATE, '', refetch));
  const updateReports = useBatchUpdateContentReports(
    onMutation(MutationAction.UPDATE, '', refetch),
  );

  const handleChangeStatus = async (report: ContentReportWithContent, status: StatusD4cEnum) => {
    delete report.topic;
    delete report.thread;
    const newReport = {
      ...report,
      reporting_user: report.reporting_user.id,
      reported_user: report.reported_user.id,
    } as ContentReport;
    try {
      await updateReport.mutateAsync({
        siteId: site?.id || '',
        contentReportId: newReport.id,
        data: {
          ...newReport,
          status: status,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const handleBatchChangeStatus = async (reports: string[], status: StatusD4cEnum) => {
    try {
      await updateReports.mutateAsync({
        siteId: site?.id || '',
        params: {
          status: status,
          content_report_ids: reports,
        },
      });
      setSelectedContent([]);
    } catch (e) {
      console.error(e);
    }
  };

  /*******************************************************************
   * ADD REPORTED DISCUSSION / THREAD TO EACH REPORT
   *******************************************************************/

  const mapContentToReport = (r: ContentReportWithUsers): ContentReportWithContent => {
    // discussion content type
    if (r.content_type === 32) {
      return {
        ...r,
        topic: discussions?.find((d) => d.id === r.object_id),
      };
    }
    // thread content type
    else {
      return {
        ...r,
        thread: threads?.find((t) => t.id === r.object_id),
      };
    }
  };

  const needsReview: ContentReportWithContent[] = useMemo(() => {
    if (!threads || !discussions) return [];
    return groupId
      ? needsReviewGroupReports?.map(mapContentToReport) || []
      : needsReviewReports?.map(mapContentToReport) || [];
  }, [groupId, needsReviewReports, needsReviewGroupReports, threads, discussions, needsReviewPage]);

  const previouslyReviewed: ContentReportWithContent[] = useMemo(() => {
    if (!threads || !discussions) return [];
    return groupId
      ? previouslyReviewedGroupReports?.map(mapContentToReport) || []
      : previouslyReviewedReports?.map(mapContentToReport) || [];
  }, [
    groupId,
    previouslyReviewedReports,
    previouslyReviewedGroupReports,
    threads,
    discussions,
    previouslyReviewedPage,
  ]);

  useEffect(() => {
    setSelectedContent([]);
    setExpandedContent('');
  }, [needsReviewPage, previouslyReviewedPage, tab]);

  useEffect(() => {
    setSelectedContent([]);
  }, [batch, sort]);

  const needsReviewCountString =
    typeof needsReviewCount?.count === 'number' && needsReviewCount.count > 9
      ? '9+'
      : needsReviewCount?.count;

  const isLoading =
    needsReviewGroupReportsFetching ||
    needsReviewReportsFetching ||
    previouslyReviewedReportsFetching ||
    previouslyReviewedGroupReportsFetching ||
    discussionsLoading ||
    threadsLoading;

  return (
    <Card>
      <Stack direction={'row'} justifyContent='space-between'>
        <Typography variant='h5' sx={{ ml: 4, mt: 4, mb: 2, fontWeight: 500 }}>
          Moderation
        </Typography>
        <FormControlLabel
          control={
            <Switch checked={batch} onChange={(e) => setBatch(e.target.checked)} size='small' />
          }
          label='Batch Review'
          sx={{ fontSize: 12 }}
        />
      </Stack>
      <ModerationTabs value={tab} onChange={handleChange} aria-label='Moderation tabs'>
        <Tab
          label={
            <Stack direction='row' alignItems='center'>
              <Typography>Needs Review</Typography>
              <ReportsBadge>{needsReviewCountString}</ReportsBadge>
            </Stack>
          }
          sx={{ overflow: 'visible' }}
          {...a11yProps(0)}
        />
        <Tab label='Previously Reviewed' {...a11yProps(1)} />
      </ModerationTabs>
      <Stack px={4} spacing={2} direction='row'>
        {batch && (
          <>
            <Checkbox
              checked={selectedContent.length > 0}
              disabled={tab === 1}
              onChange={(e) =>
                selectedContent.length > 0
                  ? setSelectedContent([])
                  : setSelectedContent(
                      tab === 0
                        ? needsReview?.map((r) => r.id) || []
                        : previouslyReviewed?.map((r) => r.id) || [],
                    )
              }
            />
            <Select
              id='sort-reports'
              value={'1'}
              onChange={(e) => {
                handleBatchChangeStatus(selectedContent, e.target.value as StatusD4cEnum);
              }}
              size='small'
              disabled={tab === 1}
              sx={{ minWidth: 170, borderRadius: 2, fontSize: 14 }}
            >
              <MenuItem disabled value='1'>
                Batch Options ({selectedContent.length})
              </MenuItem>
              <MenuItem value={StatusD4cEnum.Ignored}>Keep</MenuItem>
              <MenuItem value={StatusD4cEnum.Moderated}>Delete</MenuItem>
            </Select>
          </>
        )}
        <TextField
          onChange={(e) => setSearch(e.target.value)}
          hiddenLabel
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            sx: { fontSize: 14, height: 42 },
          }}
          placeholder='Search...' // The placeholder is not a param so that all search fields are consistent
          fullWidth
          size='small'
          sx={searchStyles}
        />
        <Select
          id='sort-reports'
          value={sort}
          onChange={(e) => setSort(e.target.value)}
          size='small'
          sx={{ minWidth: 140, borderRadius: 2, fontSize: 14 }}
        >
          <MenuItem value='-date_created'>Newest</MenuItem>
          <MenuItem value='date_created'>Oldest</MenuItem>
        </Select>
      </Stack>
      <CustomTabPanel value={tab} index={0}>
        {isLoading && Array.from({ length: 8 }).map((_, i) => <SkeletonContentReportRow key={i} />)}
        {!isLoading &&
          needsReview?.map((report) => {
            return (
              <ModerationItemTile
                key={report.id}
                handleChangeStatus={handleChangeStatus}
                report={report}
                expandedContent={expandedContent}
                setExpandedContent={setExpandedContent}
                setSelectedUser={setSelectedUser}
                setUserQuickViewAnchorEl={setUserQuickViewAnchorEl}
                batch={batch}
                selectedContent={selectedContent}
                setSelectedContent={setSelectedContent}
              />
            );
          })}
        <Stack alignItems={'center'} justifyContent={'center'} paddingTop={2}>
          <ReportsPagination
            count={Math.ceil((needsReviewCount?.count || 0) / PAGE_SIZE)}
            page={needsReviewPage + 1}
            onChange={(e, page) => setNeedsReviewPage(page - 1)}
            size='small'
            color='primary'
          />
        </Stack>
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={1}>
        {isLoading && Array.from({ length: 8 }).map((_, i) => <SkeletonContentReportRow key={i} />)}
        {!isLoading &&
          previouslyReviewed?.map((report) => {
            return (
              <ModerationItemTile
                key={report.id}
                handleChangeStatus={handleChangeStatus}
                report={report}
                expandedContent={expandedContent}
                setExpandedContent={setExpandedContent}
                setSelectedUser={setSelectedUser}
                setUserQuickViewAnchorEl={setUserQuickViewAnchorEl}
                batch={batch}
                selectedContent={selectedContent}
                setSelectedContent={setSelectedContent}
              />
            );
          })}
        <Stack alignItems={'center'} justifyContent={'center'} paddingTop={2}>
          <ReportsPagination
            count={Math.ceil((previouslyReviewedCount?.count || 0) / PAGE_SIZE)}
            page={previouslyReviewedPage + 1}
            onChange={(e, page) => setPreviouslyReviewedPage(page - 1)}
            size='small'
            color='primary'
          />
        </Stack>
      </CustomTabPanel>
      <UserQuickView
        anchorEl={userQuickViewAnchorEl}
        userId={selectedUser?.id || ''}
        open={Boolean(userQuickViewAnchorEl)}
        setAnchorEl={setUserQuickViewAnchorEl}
        onViewProfile={(userEmail: string): void => {
          navigateRoute(
            getContentRoute(site?.slug || '', selectedUser?.id || '', SearchContentTypeEnum.user),
          );
        }}
        onMessageUser={function (userId: string): void {
          throw new Error('Function not implemented.');
        }}
      />
    </Card>
  );
};

export default ModerationAdmin;

const searchStyles = {
  bgcolor: 'white',
  borderRadius: 2,
  '.MuiInputBase-root': {
    borderRadius: 2,
  },
  '.MuiFilledInput-root': {
    backgroundColor: 'white',
    borderRadius: 2,
  },
  '.MuiOutlinedInput-notchedOutline': {
    '&:hover': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
  },
  '&:hover': {
    borderColor: 'rgba(0,0,0,.1)',
    backgroundColor: 'rgba(0,0,0,.01)',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0,0,0,.2)',
      backgroundColor: 'rgba(0,0,0,.01)',
    },
  },
};

const SkeletonContentReportRow = () => {
  return (
    <Stack direction='row' spacing={2} px={4} py={2} borderBottom={'1px solid #cac4d0'}>
      <Skeleton variant='circular' width={72} height={72} />
      <Box>
        <Skeleton variant='text' width={400} />
        <Skeleton variant='text' width={220} />
        <Skeleton variant='text' width={160} />
      </Box>
    </Stack>
  );
};
