import React, { useEffect, useRef, useState } from 'react';
import { ChatOutlined, NotificationsActiveOutlined } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  ClickAwayListener,
  Fade,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  Tab,
  TabProps,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce, useDebounceValue } from 'usehooks-ts';
import { useSearchContent } from '@juno/client-api';
import {
  FeatureConfigConfig,
  NavigationItem as NavigationItemModel,
  NavigationItemTypeEnum,
  SearchContentTypeEnum,
} from '@juno/client-api/model';
import { FeatureConfig as FeatureConfigUtils, NavigationMap } from '@juno/client-api/utils';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';
import { JunoIcon, customShadow } from '@juno/ui';
import {
  getContentRoute,
  notification,
  useBreakpoint,
  useMessengerContext,
  useSettings,
} from '@juno/utils';
import { useAnalyticsContext } from '../Contexts/AnalyticsContext';
import { useNotificationContext } from '../Notifications/NotificationContext';
import NotificationsPopover from '../Notifications/NotificationsPopover';
import {
  AppBarWrapper,
  HeaderLinkRowWrapper,
  HeaderLinkWrapper,
  HeaderSubNavLinkButton,
  HeaderSubNavLinkRow,
  HeaderSubnavLinkWrapper,
  HeaderWrapper,
  Logo,
  LogoLink,
  MainNavContent,
  SearchContainer,
  SearchField,
  SubNavContent,
} from './styles';

export interface ExtendedConfigModel extends FeatureConfigConfig {
  nav_hidden_icons?: string[];
}

interface MainNavProps {
  logoUrl: string;
  homepage: string;
  handleMoreButtonClick?: () => void;
  onOpenPopper?: () => void;
  anchorRef?: React.RefObject<HTMLButtonElement>;
  mainNavItems: NavigationItemModel[];
  selectedMainNavIds: string[];
  siteSlug: string;
}

const variants = {
  open: {
    x: -60,
    display: 'none',
  },
  closed: {
    x: 0,
    display: 'flex',
    alignItems: 'center',
  },
};

const MainNav: React.FC<MainNavProps> = ({
  logoUrl,
  homepage,
  handleMoreButtonClick,
  onOpenPopper,
  anchorRef,
  mainNavItems,
  selectedMainNavIds,
  siteSlug,
}) => {
  const {
    user,
    isAdmin,
    setShowMessenger,
    showMessenger,
    showSideMenu,
    setShowSideMenu,
    isSmallScreen,
    site,
    navItems,
    configs,
  } = useSettings();
  const navigate = useNavigate();
  const { xs } = useBreakpoint();
  const [notisOpen, setNotisOpen] = useState(false);
  const notiIconRef = useRef<HTMLButtonElement>(null);
  const [searchOpen, setSearchOpen] = useState(false);
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 300);
  const { firehoseActivity } = useAnalyticsContext();
  // need a long debounced search for analytics
  const [debouncedValue] = useDebounceValue(search, 1500); // 1.5s
  const { data: searchData, isFetching } = useSearchContent(
    site?.id || '',
    { search_term: debouncedSearch || '' },
    { query: { enabled: !!search } },
  );
  const location = useLocation();

  useEffect(() => {
    return () => {
      notification.destroy();
    };
  }, []);

  useEffect(() => {
    if (debouncedValue) {
      firehoseActivity(
        ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_CONTENT,
        '',
        '',
        user?.id || '',
        ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_SEARCH,
        ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_SEARCH.value,
        debouncedValue,
        null,
      );
    }
  }, [debouncedValue]);

  const { messengerMap } = useMessengerContext();
  const { unreadNotifications, setNewNotifications } = useNotificationContext();

  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const messengerDisabled = FeatureConfigUtils.getThemeConfig(configs)?.config?.hide_messenger;

  useEffect(() => {
    const count = messengerMap.channels.reduce((acc, channel) => {
      if (!channel.isHidden && !channel.id.includes('system-')) {
        const newMessages = channel.messages.filter(
          (message) => message.isNewMessage && user?.id !== message.userId,
        ).length;
        return acc + newMessages;
      }
      return acc;
    }, 0);
    setNewMessagesCount(count);
  }, [messengerMap]);

  const pathName = location.pathname;
  const currentSlug = pathName.split('/')[pathName.split('/').length - 1];
  const currentTabIndex = navItems?.findIndex(
    (item) => item.internal_link_object?.slug === currentSlug,
  );
  const [tabValue, setTabValue] = useState<number | boolean>(
    !currentTabIndex || currentTabIndex < 0 ? false : currentTabIndex,
  );
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  useEffect(() => {
    if (!navItems) return;
    const index = navItems?.findIndex((item) => item.internal_link_object?.slug === currentSlug);
    if (index < 0) {
      setTabValue(false);
    } else {
      setTabValue(index);
    }
  }, [location.pathname, navItems]);

  useEffect(() => {
    if (notisOpen) {
      setNewNotifications([]);
    }
  }, [notisOpen]);

  return (
    <MainNavContent>
      <HeaderLinkWrapper>
        <HeaderLinkRowWrapper>
          <ClickAwayListener onClickAway={() => setSearchOpen(false)}>
            <SearchContainer searchOpen={searchOpen}>
              <Stack direction='row' alignItems='center' minHeight={'60px'}>
                <motion.div
                  variants={variants}
                  animate={searchOpen ? 'open' : 'closed'}
                  style={{ alignItems: 'center', height: '100%' }}
                >
                  <Tooltip title='Toggle Menu' placement='bottom'>
                    <IconButton
                      onClick={() => setShowSideMenu && setShowSideMenu(!showSideMenu)}
                      sx={{
                        background: showSideMenu ? 'rgba(0,0,0,.05)' : 'transparent',
                        width: 36,
                        height: 36,
                      }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Tooltip>
                  {logoUrl && (
                    <LogoLink
                      searchOpen={searchOpen}
                      onClick={() => navigate(`/${site?.slug}/home`)}
                    >
                      {logoUrl && <Logo src={logoUrl} alt='logo' />}
                    </LogoLink>
                  )}
                </motion.div>

                {searchOpen && (
                  <motion.div initial={{ marginLeft: 40 }} animate={{ marginLeft: 0 }}>
                    <Tooltip title='Close Search' placement='bottom'>
                      <IconButton onClick={() => setSearchOpen(false)}>
                        <ArrowBackIosIcon />
                      </IconButton>
                    </Tooltip>
                  </motion.div>
                )}

                {!isSmallScreen && (
                  <SearchField
                    size='small'
                    fullWidth
                    hiddenLabel
                    onFocus={() => setSearchOpen(true)}
                    placeholder='Search...'
                    autoComplete='off'
                    onChange={(e) => {
                      setSearchOpen(true);
                      setSearch(e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{ ml: 1 }}
                  />
                )}
              </Stack>
              <Box maxWidth={'100%'}>
                {searchOpen && (
                  <>
                    <List>
                      {searchData?.map((item) => (
                        <ListItemButton
                          key={item.id}
                          sx={{ mt: 1, maxHeight: 55 }}
                          onClick={() => {
                            navigate(getContentRoute(site?.slug || '', item.slug, item.type));
                            setSearchOpen(false);
                          }}
                        >
                          {item.type !== 'page' && (
                            <Avatar src={item.icon} sx={{ mr: 2, height: 30, width: 30 }} />
                          )}
                          <Box>
                            <Typography fontWeight='bold' variant='body2'>
                              {item.title}
                            </Typography>
                            <Typography variant='body2' sx={{ textTransform: 'capitalize' }}>
                              {item.type}
                            </Typography>
                          </Box>
                        </ListItemButton>
                      ))}
                    </List>
                    {isFetching && (
                      <Stack direction='row' justifyContent='center'>
                        <CircularProgress size={26} />
                      </Stack>
                    )}
                    {!search && !isFetching && (
                      <Stack
                        direction='row'
                        justifyContent='center'
                        sx={{ whiteSpace: 'normal', textAlign: 'center' }}
                      >
                        <Typography variant='body2'>Search content and users.</Typography>
                      </Stack>
                    )}
                    {search && !isFetching && searchData?.length === 0 && (
                      <Stack
                        direction='row'
                        justifyContent='center'
                        sx={{ whiteSpace: 'normal', textAlign: 'center' }}
                      >
                        <Typography variant='body2'>
                          No results found for "{debouncedSearch}"
                        </Typography>
                      </Stack>
                    )}
                  </>
                )}
              </Box>
            </SearchContainer>
          </ClickAwayListener>
          <Tabs
            onChange={handleChange}
            value={tabValue}
            variant='scrollable'
            scrollButtons={isSmallScreen ? 'auto' : false} // TODO - this needs tweaks to show on desktop
            allowScrollButtonsMobile
            style={{ height: '60px' }}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              background: '#fff',
              position: isSmallScreen ? 'fixed' : 'relative',
              bottom: 0,
              boxShadow: isSmallScreen ? customShadow : 'none',
              left: 0,
              width: isSmallScreen ? '100%' : 'auto',
              '& button': {
                padding: isSmallScreen ? '0 6px' : 'auto',
                minWidth: isSmallScreen ? '70px' : '90px',
              },
              '.MuiTabs-flexContainer': {
                maxHeight: 60,
                width: 'fit-content',
                margin: '0 auto',
              },
            }}
          >
            {navItems
              ?.sort((a, b) => (a.order || 0) - (b.order || 0))
              .map((item) => {
                return (
                  <StyledTab
                    key={item.id}
                    icon={
                      item.type === NavigationItemTypeEnum.icon ? (
                        <JunoIcon
                          name={item.icon || ''}
                          sx={{
                            fontSize:
                              item.type === NavigationItemTypeEnum.icon
                                ? isSmallScreen
                                  ? 32
                                  : 26
                                : isSmallScreen
                                ? 26
                                : 20,
                          }}
                        />
                      ) : undefined
                    }
                    label={
                      item.type === NavigationItemTypeEnum.icon_with_text ||
                      item.type === NavigationItemTypeEnum.text ? (
                        <Typography
                          textTransform={'none'}
                          fontSize={'16px'}
                          display={'flex'}
                          alignItems={'center'}
                        >
                          {item.icon && item.type === NavigationItemTypeEnum.icon_with_text && (
                            <JunoIcon name={item.icon || ''} sx={{ fontSize: 22, mr: 1.25 }} />
                          )}{' '}
                          {item.text}
                        </Typography>
                      ) : undefined
                    }
                    onClick={() => {
                      if (item.internal) {
                        navigate(
                          getContentRoute(
                            siteSlug,
                            item.internal_link_object?.slug || '',
                            (item.search_content_type as SearchContentTypeEnum) ||
                              SearchContentTypeEnum.course,
                          ),
                        );
                      } else {
                        window.open(item.link, '_blank');
                      }
                    }}
                  />
                );
              })}
          </Tabs>
          <Stack
            direction='row'
            alignItems='center'
            width={350}
            textAlign='right'
            pr={2}
            height={60}
            justifyContent='flex-end'
          >
            {isAdmin && !xs && (
              <IconButton
                onClick={() => navigate(`/${siteSlug}/admin`)}
                sx={{
                  mr: 1,
                  color: pathName.includes(`/${siteSlug}/admin`) ? 'primary.main' : 'default',
                }}
              >
                <SettingsOutlinedIcon />
              </IconButton>
            )}
            {!messengerDisabled && (
              <IconButton
                onClick={() => {
                  setShowMessenger && setShowMessenger((old) => !old);
                }}
                sx={{
                  mr: 1,
                  color: showMessenger ? 'primary.main' : 'default',
                }}
              >
                <Badge badgeContent={newMessagesCount} color='primary'>
                  <ChatOutlined />
                </Badge>
              </IconButton>
            )}

            <IconButton
              ref={notiIconRef}
              onClick={() => setNotisOpen((old) => !old)}
              sx={{ mr: 1, color: notisOpen ? 'primary.main' : 'default' }}
            >
              <Badge badgeContent={unreadNotifications.length} color='primary'>
                <NotificationsActiveOutlined />
              </Badge>
            </IconButton>
            <IconButton
              onClick={onOpenPopper}
              ref={anchorRef}
              sx={{ backgroundColor: '#eaeaea', p: 0, mr: 1 }}
            >
              <Avatar
                sx={{ width: 40, height: 40, backgroundColor: 'transparent' }}
                alt='Anonymous'
                src={user?.avatar || ''}
              />
            </IconButton>
            <NotificationsPopover
              open={notisOpen}
              anchorRef={notiIconRef}
              handleClose={() => setNotisOpen(false)}
            />
          </Stack>
        </HeaderLinkRowWrapper>
      </HeaderLinkWrapper>
    </MainNavContent>
  );
};

interface SubNavProps {
  subNavItems: NavigationItemModel[];
  selectedSubNavIds: string[];
}

const SubNav: React.FC<SubNavProps> = ({ subNavItems, selectedSubNavIds }) => {
  return (
    <SubNavContent>
      <HeaderSubnavLinkWrapper>
        <HeaderSubNavLinkRow>
          {subNavItems?.map((item) => {
            return (
              <HeaderSubNavLinkButton
                key={item.id}
                selected={selectedSubNavIds.includes(item.id)}
                disableFocusRipple={true}
                disableRipple={true}
              >
                {item.text}
              </HeaderSubNavLinkButton>
            );
          })}
        </HeaderSubNavLinkRow>
      </HeaderSubnavLinkWrapper>
    </SubNavContent>
  );
};

interface HeaderContentProps {
  logoUrl: string;
  homepage: string;
  handleMoreButtonClick?: () => void;
  navigationMap: NavigationMap | undefined;
}

const HeaderContent: React.FC<HeaderContentProps> = ({
  logoUrl,
  homepage,
  handleMoreButtonClick,
  navigationMap,
}) => {
  const anchorRef = useRef<HTMLButtonElement>(null);

  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const { user, auth, site, platform } = useSettings();

  const handleOpen = () => setMenuOpen(true);
  const handleClose = () => setMenuOpen(false);
  const handleTBD = () => notification.info('To be implemented...');
  const handleLogout = () => {
    // Clear out all local storage
    localStorage.clear();

    const current_location = window.location.origin + '/main/callback';

    // Redirect to FusionAuth logout
    window.location.href = `${
      process.env.NX_FUSIONAUTH_URL || 'UNSET_NX_FUSIONAUTH_URL'
    }/oauth2/logout?post_logout_redirect_uri=${current_location}&client_id=${platform?.id}`;
  };
  const handleLogin = () => auth?.removeUser();

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      handleClose();
    } else if (event.key === 'Escape') {
      handleClose();
    }
  };

  const subNavItemLength = navigationMap?.subNavItems?.length || 0;

  return (
    <Box>
      <MainNav
        logoUrl={logoUrl}
        homepage={homepage}
        handleMoreButtonClick={handleMoreButtonClick}
        onOpenPopper={handleOpen}
        anchorRef={anchorRef}
        mainNavItems={navigationMap?.mainNavItems || []}
        selectedMainNavIds={navigationMap?.selectedMainNavIds || []}
        siteSlug='main'
      />
      {subNavItemLength > 0 && (
        <SubNav
          subNavItems={navigationMap?.subNavItems || []}
          selectedSubNavIds={navigationMap?.selectedSubNavIds || []}
        />
      )}
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        placement='bottom-end'
        transition
        disablePortal
        sx={{
          borderRadius: '8px',
          boxShadow: customShadow,
          zIndex: (theme) => theme.zIndex.modal,
        }}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-10, 0],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={100}>
            <Paper sx={{ borderRadius: '8px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={menuOpen}
                  onKeyDown={handleListKeyDown}
                  onClick={handleClose}
                  sx={{ width: 187 }}
                >
                  <MenuItem onClick={() => navigate(`/${site?.slug}/user/${user?.id || ''}`)}>
                    Profile
                  </MenuItem>
                  <MenuItem
                    onClick={() => navigate(`/${site?.slug}/user/${user?.id || ''}/settings`)}
                  >
                    Settings
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Log out</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
};

interface HeaderProps {
  navigationMap: NavigationMap | undefined;
  homepage: string;
  onMoreButtonClick?: () => void;
  showSideMenu?: boolean;
  display?: boolean;
  style?: React.CSSProperties;
}

const Header: React.FC<HeaderProps> = ({
  navigationMap,
  homepage,
  onMoreButtonClick,
  showSideMenu,
  style,
}) => {
  const { configs } = useSettings();
  const themeConfig = FeatureConfigUtils.getThemeConfig(configs);
  const logoUrl = (themeConfig?.config?.logo as string) || '';
  const showSubnav = (navigationMap?.subNavItems?.length || 0) > 0;
  const styleOverride = style?.position === 'relative' ? { paddingTop: 0 } : {};

  return (
    <HeaderWrapper showSubnav={showSubnav} style={{ ...styleOverride }} sx={{ zIndex: 1200 }}>
      <AppBarWrapper showSideMenu={showSideMenu} style={{ ...style }}>
        <Box sx={{ borderBottom: '1px solid #c3c3c3' }}>
          <HeaderContent
            logoUrl={logoUrl}
            homepage={homepage}
            handleMoreButtonClick={onMoreButtonClick}
            navigationMap={navigationMap}
          />
        </Box>
      </AppBarWrapper>
    </HeaderWrapper>
  );
};

export default Header;

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'none',
  color: '#000',
  maxHeight: 60,
  minHeight: 60,
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
  '&:hover': {
    backgroundColor: '#eaeaea',
  },
}));
