import React, { useEffect } from 'react';
import { Card } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetPage } from '@juno/client-api';
import {
  FeatureConfig as FeatureConfigModel,
  PageTypeEnum,
  Site as SiteModel,
} from '@juno/client-api/model';
import { JunoModule, usePubnubContext } from '@juno/modules';
import { Juno404, JunoSpin } from '@juno/ui';
import { useSettings } from '@juno/utils';

interface DynamicPageProps {
  site: SiteModel;
  configs: FeatureConfigModel[];
}

const DynamicPage: React.FC<DynamicPageProps> = ({ site, configs }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { pubnub } = usePubnubContext();

  const { user } = useSettings();

  // Extracting the siteSlug and pageSlug from the pathname
  const pathParts = pathname.split('/').filter(Boolean);
  const pageSlug = pathParts[pathParts.length - 1];

  const { data: page, isLoading: pageLoading } = useGetPage(site.id, pageSlug);

  useEffect(() => {
    // Subscribe to the library channel to report presence
    if (page && page.type === PageTypeEnum.library && user) {
      pubnub.subscribe({ channels: [page.id], withPresence: true });
    }
    return () => {
      // Unsubscribe from the library channel to stop reporting presence
      if (page && page.type === PageTypeEnum.library) {
        pubnub.unsubscribe({ channels: [page.id] });
      }
    };
  }, [pubnub, page, user]);

  if (pageLoading) return <JunoSpin />;
  if (!site || !page) return <Juno404 />;

  return (
    <Card sx={{ boxShadow: 'none', borderRadius: 0, background: '#f6f6f6' }}>
      {page.components.map((component) => (
        <JunoModule key={component.id} component={component} siteId={site.id} configs={configs} />
      ))}
    </Card>
  );
};

export default DynamicPage;
