import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useGetAllTags } from '@juno/client-api';
import { PrivacyEnum, Tag } from '@juno/client-api/model';
import { DateFilter, DialogAriaWrapper, JunoSpin, RotatorSettings } from '@juno/ui';
import { RelativeTimeFrameOptions, getBeginningOfDay, getEndOfDay } from '@juno/utils';
import AutocompleteTags from '../ContentGrid/form/AutocompleteTags';
import DatePreFilter from '../ContentGrid/form/DatePreFilter';
import {
  calculateDateFilterString,
  handleAddDateFilter,
  handleRemoveDateFilter,
} from '../ContentGrid/form/dateFilterUtils';
import { sortContent } from '../ContentGrid/form/sortFilterUtils';
import {
  BeforeAfterBetweenOptions,
  DateTypeOptions,
  DateTypeToFilterString,
  SortByOptions,
  SortOrderOptions,
  UserActions,
  checkJsonWorthiness,
} from '../ContentGrid/form/utils';
import {
  handleOpenOrderOptions,
  handleOpenSortOptions,
  handlePrivacyLevelChange,
  handleSortByChange,
  onFilterByDates,
  onFilterByPrivacyLevel,
  onFilterByTags,
  onFilterByUserActions,
  onFilterOnlyMyGroups,
  onTagChange,
  onUserActionChange,
} from './utils';

export interface CreateUpdatePageDialogProps {
  siteId: string;
  open: boolean;
  handleClose: () => void;
  settings: RotatorSettings;
  onSubmit: (values: RotatorSettings) => void;
}

export const defaultDateFilter: DateFilter = {
  idx: 0,
  dateType: '',
  timeRange: '',
  previousUpcomingDateRange: 0,
  relativeTimeFrame: RelativeTimeFrameOptions.Today,
  beforeAfterBetween: BeforeAfterBetweenOptions.Before,
  beforeAfterDate: '',
  fromDate: getBeginningOfDay(new Date()).toISOString(),
  toDate: getEndOfDay(new Date()).toISOString(),
  uniqueKey: '12345ABCDEF',
};

const CreateUpdatePageDialog = ({
  siteId,
  open,
  handleClose,
  settings,
  onSubmit,
}: CreateUpdatePageDialogProps) => {
  const [payload, setPayload] = useState<RotatorSettings>(settings);
  const [filterByTags, setFilterByTags] = useState<boolean>(settings.preFilterTags || false);
  const [filterByUserActions, setFilterByUserActions] = useState(false);
  const [selectedUserActions, setSelectedUserActions] = useState<string[]>([]);
  const [filterByDates, setFilterByDates] = useState<boolean>(false);
  const [dateFilters, setDateFilters] = useState<DateFilter[]>([defaultDateFilter]);
  const [dateFilterStringIsChanged, setDateFilterStringIsChanged] = useState<boolean>(false);
  const [dateFiltersRemoved, setDateFiltersRemoved] = useState<DateFilter[]>([]);
  const [selectedSortByOption, setSelectedSortByOption] = useState<string>(
    settings.preSortName || '',
  );
  const [selectedSortOrder, setSelectedSortOrder] = useState<string>(settings.preSortOrder || '');
  const theme = useTheme();

  // Queries
  const { data: siteTags, isLoading: isLoadingTags } = useGetAllTags(siteId);

  const mapTags = (tagIds: string[]) => {
    if (siteTags && tagIds) {
      return tagIds
        .map((tagId) => siteTags.find((tag) => tag.id === tagId))
        .filter((tag) => tag !== undefined) as Tag[];
    }
    return [];
  };

  // Asynchronously save the new payload and then save the full tab settings to preserve changes
  const handleSave = async () => {
    if (payload) {
      if (
        (!payload.requireTags || payload.requireTags.length === 0) &&
        (!payload.optionalTags || payload.optionalTags.length === 0) &&
        (!payload.excludeTags || payload.excludeTags.length === 0)
      ) {
        payload.preFilterTags = false;
        setFilterByTags(false);
      }
      if (!filterByDates) {
        // Zero out the filter string if we don't have any date filters or pre sorting set
        const jsonifiedFilterString = checkJsonWorthiness(payload.filter);
        for (const value of DateTypeToFilterString.values()) {
          if (value + '__lte' in jsonifiedFilterString) {
            delete jsonifiedFilterString[value + '__lte'];
          }
          if (value + '__gte' in jsonifiedFilterString) {
            delete jsonifiedFilterString[value + '__gte'];
          }
        }
        payload.filter = JSON.stringify(jsonifiedFilterString);
        payload.dateFilters = [];
      } else {
        // Set a new filter param based on the chosen date filters
        if (dateFilterStringIsChanged) {
          const [updatedFilterString, updatedDateFilters] = calculateDateFilterString(
            payload,
            dateFilters,
            dateFiltersRemoved,
            false,
          );
          payload.filter = updatedFilterString;
          payload.dateFilterString = updatedFilterString;
          payload.dateFilters = updatedDateFilters;
        }
      }

      // Set order param based on preSort
      payload.preSortOrder = selectedSortOrder;
      payload.preSortName = selectedSortByOption;
      const newOrder = sortContent(selectedSortByOption, selectedSortOrder);
      payload.order = newOrder ? newOrder : '';

      if (!selectedUserActions || selectedUserActions.length < 1) {
        payload.preFilterUserActions = false;
        setFilterByUserActions(false);
        setSelectedUserActions([]);
        onSubmit(payload);
        setDateFiltersRemoved([]);
      } else {
        onSubmit(payload);
        setDateFiltersRemoved([]);
      }
    }
  };

  // Make sure the modal preloads tab-specific filters
  useEffect(() => {
    setSelectedSortByOption(settings?.preSortName || '');
    setSelectedSortOrder(settings?.preSortOrder ? settings.preSortOrder : '');
    setPayload(settings);
    // Do we have any tag filters set?
    if (settings?.preFilterTags) {
      setFilterByTags(settings.preFilterTags);
    } else {
      setFilterByTags(false);
    }
    // Do we have any User Action filters set?
    if (settings?.preFilterUserActions) {
      setSelectedUserActions(
        Object.entries(settings.selectedUserActions || {})
          .filter(([, value]) => value === true)
          .map(([key]) => key) || [],
      );
      setFilterByUserActions(settings.preFilterUserActions);
    } else {
      setFilterByUserActions(false);
      setSelectedUserActions([]);
    }
    // Do we have any Date filters set?
    if (
      settings?.dateFilters &&
      settings.dateFilters.length > 0 &&
      settings.dateFilters[0].dateType !== ''
    ) {
      setFilterByDates(true);
      setDateFilters(settings.dateFilters);
    }
  }, [settings]);

  // Register dateFilter updates
  useEffect(() => {
    let newPayload;
    if (dateFilters) {
      newPayload = { ...payload, ['dateFilters']: dateFilters } as RotatorSettings;
      setPayload(newPayload);
    }
  }, [dateFilters]);

  if (isLoadingTags) {
    return <JunoSpin />;
  }

  return (
    <DialogAriaWrapper
      id={'rotatorSettingsDialog'}
      fullWidth={true}
      maxWidth='sm'
      open={open}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
      PaperProps={{ sx: { borderRadius: '8px' } }}
    >
      <Box
        sx={{
          background: '#FFFFFF',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: 3,
          pr: 2,
        }}
      >
        <Box>
          <Typography variant='body2' sx={{ opacity: 0.6, mt: 2 }}>
            Editing
          </Typography>
          <Typography variant='h5' sx={{ mb: 2 }}>
            Rotator Filters
          </Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent id={'rotatorSettingsDialog-dialog-description'}>
        <Typography sx={{ mt: 1, mb: 1 }}>Filter Content</Typography>
        <Box>
          <Grid container direction='column'>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    name='preFilterTags'
                    checked={payload?.preFilterTags === true}
                    onChange={(event) =>
                      onFilterByTags(event, payload, setFilterByTags, setPayload)
                    }
                  />
                }
                label='Tags'
                sx={{ pl: 2 }}
              />
            </Grid>
            {filterByTags && (
              <Grid item>
                <FormControl fullWidth sx={{ mt: 1, mb: 1, pr: 2, pb: 2, pl: 6 }}>
                  <AutocompleteTags
                    label={'Require Tag(s)'}
                    requireTags={mapTags(payload?.requireTags || [])}
                    optionalTags={mapTags(payload?.optionalTags || [])}
                    excludeTags={mapTags(payload?.excludeTags || [])}
                    siteTags={siteTags}
                    handleChange={(event: SyntheticEvent, newValue: Tag[]) => {
                      onTagChange(event, payload, setPayload, newValue, 'requireTags');
                    }}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1, mb: 1, pr: 2, pb: 2, pl: 6 }}>
                  <AutocompleteTags
                    label={'Optional Tag(s)'}
                    requireTags={mapTags(payload?.requireTags || [])}
                    optionalTags={mapTags(payload?.optionalTags || [])}
                    excludeTags={mapTags(payload?.excludeTags || [])}
                    siteTags={siteTags}
                    handleChange={(event: SyntheticEvent, newValue: Tag[]) => {
                      onTagChange(event, payload, setPayload, newValue, 'optionalTags');
                    }}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1, mb: 1, pr: 2, pb: 2, pl: 6 }}>
                  <AutocompleteTags
                    label={'Exclude Tag(s)'}
                    requireTags={mapTags(payload?.requireTags || [])}
                    optionalTags={mapTags(payload?.optionalTags || [])}
                    excludeTags={mapTags(payload?.excludeTags || [])}
                    siteTags={siteTags}
                    handleChange={(event: SyntheticEvent, newValue: Tag[]) => {
                      onTagChange(event, payload, setPayload, newValue, 'excludeTags');
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            {settings.dataModel === 'Course' && (
              <FormControlLabel
                control={
                  <Checkbox
                    name='preFilterUserActions'
                    checked={payload?.preFilterUserActions === true}
                    onChange={(event) =>
                      onFilterByUserActions(event, setFilterByUserActions, setPayload)
                    }
                  />
                }
                label='User Actions'
                sx={{ pl: 2 }}
              />
            )}
            {filterByUserActions && (
              <FormControl fullWidth sx={{ mt: 1, pr: 2, pl: 6 }}>
                <Autocomplete
                  multiple
                  options={[
                    UserActions.Complete,
                    UserActions.Enroll,
                    UserActions.Start,
                    UserActions.Waitlist,
                  ]}
                  value={selectedUserActions}
                  onChange={(event, value) =>
                    onUserActionChange(event, payload, setPayload, setSelectedUserActions, value)
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <FormControlLabel control={<Checkbox checked={selected} />} label={option} />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Optional User Action(s)'}
                      InputLabelProps={{ shrink: selectedUserActions?.length > 0 }}
                      InputProps={{ ...params.InputProps, disableUnderline: true }}
                      variant='filled'
                    />
                  )}
                  sx={{ pb: 3 }}
                />
              </FormControl>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name='preFilterByDate'
                    checked={filterByDates}
                    onChange={(event) =>
                      onFilterByDates(
                        event,
                        payload,
                        setPayload,
                        filterByDates,
                        setFilterByDates,
                        setDateFilters,
                        setDateFilterStringIsChanged,
                      )
                    }
                  />
                }
                label='Dates'
                sx={{ pl: 2 }}
              />
              {filterByDates && (
                <AddIcon
                  style={{ color: 'grey' }}
                  sx={{
                    marginLeft: 'auto',
                    mr: 2,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  onClick={() => {
                    handleAddDateFilter(dateFilters.length, setDateFilters);
                  }}
                />
              )}
            </Box>
            {filterByDates && (
              <Typography
                sx={{ ml: 6, fontSize: 12, fontStyle: 'italic' }}
                style={{ color: 'grey' }}
              >
                All dates/times are inclusive
              </Typography>
            )}
            {filterByDates && (
              <>
                {dateFilters.map((filter, idx) => (
                  <DatePreFilter
                    dateFilter={filter}
                    dateFilters={dateFilters}
                    setDateFilters={setDateFilters}
                    removeDateFilter={() =>
                      handleRemoveDateFilter(
                        filter.idx,
                        dateFilters,
                        dateFiltersRemoved,
                        setDateFiltersRemoved,
                        setDateFilterStringIsChanged,
                        setDateFilters,
                      )
                    }
                    key={filter.uniqueKey}
                    setDateFilterStringIsChanged={setDateFilterStringIsChanged}
                    dateTypeOptions={
                      settings.dataModel === 'Group'
                        ? [DateTypeOptions.ReleaseDate, DateTypeOptions.EndDate]
                        : Object.values(DateTypeOptions)
                    }
                  />
                ))}
              </>
            )}

            {settings.dataModel === 'Group' && (
              <FormControlLabel
                control={
                  <Checkbox
                    name='filterOnlyMyGroups'
                    checked={payload?.filterOnlyMyGroups === true}
                    onChange={(event) => onFilterOnlyMyGroups(event, setPayload)}
                  />
                }
                label='Groups I am a member of'
                sx={{ pl: 2 }}
              />
            )}
            {settings.dataModel === 'Group' && (
              <FormControlLabel
                control={
                  <Checkbox
                    name='filterByPrivacyLevel'
                    checked={!!payload?.filterByPrivacyLevel}
                    onChange={(event) => onFilterByPrivacyLevel(event, setPayload)}
                  />
                }
                label='Privacy Level'
                sx={{ pl: 2 }}
              />
            )}
            {payload?.filterByPrivacyLevel && (
              <Box pl={6} pt={1} pb={1} pr={2}>
                <Select
                  sx={{ width: 200 }}
                  onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
                    handlePrivacyLevelChange(event, setPayload);
                  }}
                  value={payload.filterByPrivacyLevel}
                >
                  <MenuItem value={PrivacyEnum.public}>Public</MenuItem>
                  <MenuItem value={PrivacyEnum.private}>Private</MenuItem>
                </Select>
              </Box>
            )}
          </Grid>
        </Box>
        <Typography sx={{ mt: 2, mb: 2 }}>Sort Content</Typography>
        <Typography sx={{ fontSize: 12, fontStyle: 'italic', mb: 2 }} style={{ color: 'grey' }}>
          Set default order for content displayed in grid on page load
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl fullWidth sx={{ mb: 2, mr: 1 }} variant='filled'>
            <InputLabel htmlFor='sort-option'>Sort By</InputLabel>
            <Select
              label='Sort By'
              value={selectedSortByOption}
              onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
                handleSortByChange(event, 'sortBy', setSelectedSortByOption, undefined);
              }}
              displayEmpty
              renderValue={() => selectedSortByOption}
              onOpen={() => handleOpenSortOptions(selectedSortByOption, setSelectedSortByOption)}
              disableUnderline
            >
              <MenuItem value={SortByOptions.Alphabetical}>
                <FormControlLabel
                  control={<Radio size='small' />}
                  label={SortByOptions.Alphabetical}
                />
              </MenuItem>
              <MenuItem value={SortByOptions.Newest}>
                <FormControlLabel control={<Radio size='small' />} label={SortByOptions.Newest} />
              </MenuItem>
              <MenuItem value={SortByOptions.StartDate}>
                <FormControlLabel
                  control={<Radio size='small' />}
                  label={SortByOptions.StartDate}
                />
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2, ml: 1 }} variant='filled'>
            <InputLabel htmlFor='sort-order'>Sort Order</InputLabel>
            <Select
              label='Sort Order'
              value={selectedSortOrder}
              onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
                handleSortByChange(event, 'sortOrder', undefined, setSelectedSortOrder);
              }}
              displayEmpty
              disableUnderline
              renderValue={() => selectedSortOrder}
              onOpen={() => handleOpenOrderOptions(selectedSortOrder, setSelectedSortOrder)}
            >
              <MenuItem value={SortOrderOptions.Ascending}>
                <FormControlLabel
                  control={<Radio size='small' />}
                  label={SortOrderOptions.Ascending}
                />
              </MenuItem>
              <MenuItem value={SortOrderOptions.Descending}>
                <FormControlLabel
                  control={<Radio size='small' />}
                  label={SortOrderOptions.Descending}
                />
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions sx={{ pl: 1 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color='primary'
          type='submit'
          form='dialogForm'
          aria-label='save'
          variant='contained'
          onClick={handleSave}
        >
          Save
        </Button>
      </DialogActions>
    </DialogAriaWrapper>
  );
};

export default CreateUpdatePageDialog;
