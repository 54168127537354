import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { DeleteOutline } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useQueryClient } from 'react-query';
import { useDebounce } from 'usehooks-ts';
import {
  getGetAllTagTypesQueryKey,
  getGetAllTagsQueryKey,
  useCreateTag,
  useDeleteTag,
  useGetAllTagTypes,
  useGetAllTags,
  useUpdateTag,
} from '@juno/client-api';
import { Site, Tag, TagType } from '@juno/client-api/model';
import {
  ConfirmDeleteDialog,
  Container,
  DefaultSearchSortFilter,
  DialogAriaWrapper,
} from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';

const dummyArr = [0, 1, 2, 3, 4, 5, 6, 7];
interface TagsPanelProps {
  site: Site;
}

const validationSchema = (allTags: Tag[]) => {
  return Yup.object().shape({
    value: Yup.string()
      .max(90, 'Tag value must not exceed 90 characters')
      .required('Tag value is required')
      .test('title-unique-validation', 'Tag value must be unique', function test(value) {
        if (value && value.length > 0) {
          if (
            allTags &&
            allTags.filter((t) => t.value.toLowerCase() === value.toLowerCase()).length > 0
          ) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      }),
  });
};

const TagsPanel: React.FC<TagsPanelProps> = ({ site }) => {
  const { id: siteId } = site;
  const [isSaving, setIsSaving] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState<Tag | null>(null);

  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);
  const queryClient = useQueryClient();
  const { data: tags, isLoading: isLoadingTags } = useGetAllTags(siteId);
  const { data: tagTypes, isLoading: isLoadingTagTypes } = useGetAllTagTypes(siteId);
  const refetch = () => {
    queryClient.invalidateQueries(getGetAllTagsQueryKey(siteId));
    queryClient.invalidateQueries(getGetAllTagTypesQueryKey(siteId));
  };
  const createTag = useCreateTag(onMutation(MutationAction.CREATE, 'Tag', refetch));
  const updateTag = useUpdateTag(onMutation(MutationAction.UPDATE, 'Tag', refetch));

  const isLoading = isLoadingTags || isLoadingTagTypes;

  const handleCreateUpdate = async (tag: Tag) => {
    setIsSaving(true);
    if (tag.id) {
      try {
        await updateTag.mutateAsync({
          siteId,
          tagId: tag.id,
          data: {
            ...tag,
          },
        });

        setCreateOpen(false);
        refetch();
        setSelectedTag(null);
      } catch (e) {
        console.error(e);
      } finally {
        setIsSaving(false);
      }
    } else {
      try {
        await createTag.mutateAsync({
          siteId,
          data: {
            id: '',
            value: tag.value,
            type: tagTypes?.find((tt) => tt.value === 'Global Declared') || ({} as TagType),
          },
        });

        setCreateOpen(false);
        refetch();
        setSelectedTag(null);
      } catch (e) {
        console.error(e);
      } finally {
        setIsSaving(false);
      }
    }
  };

  const handleCancelEdit = async () => {
    await setCreateOpen(false);

    // Delay clearing the selected tag to prevent text flicker between create/update
    setTimeout(() => {
      setSelectedTag(null);
    }, 300);
  };

  const filteredTags = useMemo(
    () =>
      tags?.filter((tag) => {
        if (debouncedSearch === '') return true;
        return tag.value.toLowerCase().includes(debouncedSearch.toLowerCase());
      }),
    [debouncedSearch, tags],
  );

  return (
    <Container>
      <Card sx={{ p: 2, mt: 2, mb: 4 }}>
        <Typography variant='h5'>Tags</Typography>
        <Divider sx={{ mt: 2, mb: 4 }} />
        <Typography variant='body2' sx={{ mt: 2, mb: 4 }}>
          Tags organize the content on your platform. They are essential for filtering and
          searching.
        </Typography>
        <DefaultSearchSortFilter
          buttonDisabled={false}
          buttonText={'New Tag'}
          onClickButton={() => setCreateOpen(true)}
          setSearch={(value: string) => setSearch(value)}
          setSort={() => {}}
          showFilter={false}
          showSort={false}
        />

        <Stack direction='row' mb={1} ml={1} mt={4}>
          <Typography sx={{ width: 300, fontWeight: 'bold' }}>Tag Value</Typography>
          <Typography sx={{ fontWeight: 'bold' }}>Type</Typography>
        </Stack>
        <Divider />
        {isLoading && dummyArr.map((item, i) => <SkeletonTile i={i} key={i} />)}
        {!isLoading &&
          filteredTags?.map((tag, i) => (
            <TagRow
              tag={tag}
              key={tag.id}
              i={i}
              handleEdit={async () => {
                await setSelectedTag(tag);
                setCreateOpen(true);
              }}
              refetchTags={() => refetch()}
            />
          ))}

        <DialogAriaWrapper open={createOpen} onClose={() => handleCancelEdit()}>
          <Formik
            initialValues={selectedTag || ({} as Tag)}
            onSubmit={async (formValues) => {
              handleCreateUpdate(formValues);
            }}
            validationSchema={validationSchema(tags || [])}
            enableReinitialize
          >
            {({ values, setFieldValue, isValid, errors, touched, setTouched }) => {
              return (
                <Form>
                  <Box p={3}>
                    <Typography variant='h6'>{values.id ? 'Update' : 'Create'}</Typography>
                  </Box>
                  <DialogContent>
                    <Box mb={2}>
                      <TextField
                        size='small'
                        value={values.value}
                        name='value'
                        label='Value'
                        error={Boolean(touched.value && errors.value)}
                        helperText={touched.value && errors.value}
                        onChange={(e) => {
                          setTouched({ value: true });
                          setFieldValue('value', e.target.value);
                        }}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => handleCancelEdit()} disabled={isSaving}>
                      Cancel
                    </Button>
                    <LoadingButton
                      disabled={!isValid || !values.value}
                      loading={isSaving}
                      variant='contained'
                      type='submit'
                    >
                      {values.id ? 'Update' : 'Create'}
                    </LoadingButton>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogAriaWrapper>
      </Card>
    </Container>
  );
};

export default TagsPanel;
interface TagRowProps {
  tag: Tag;
  i: number;
  handleEdit: (tag: Tag) => void;
  refetchTags: () => void;
}
export const TagRow: React.FC<TagRowProps> = ({ tag, i, handleEdit, refetchTags }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteTag = useDeleteTag(onMutation(MutationAction.DELETE, 'Tag', () => {}));
  const { site } = useSettings();

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteTag.mutateAsync({ siteId: site?.id || '', tagId: tag.id });
      refetchTags();
    } catch (e) {
      setIsDeleting(false);
      console.error(e);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <>
      <Stack
        direction='row'
        p={1}
        sx={{
          cursor: 'pointer',
          borderRadius: 1,
          background: i % 2 !== 0 ? 'rgba(0,0,0,.05)' : 'transparent',
          alignItems: 'center',
          '&:hover': {
            background: 'rgba(0,0,0,.1)',
          },
        }}
        onClick={() => handleEdit(tag)}
      >
        <Typography sx={{ width: 300 }}>{tag.value}</Typography>
        <Typography>{tag.type.value}</Typography>
        <IconButton
          sx={{ ml: 'auto' }}
          size='small'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDeleteOpen(true);
          }}
        >
          <DeleteOutline />
        </IconButton>
      </Stack>
      {deleteOpen && (
        <ConfirmDeleteDialog
          handleClose={() => {
            setDeleteOpen(false);
          }}
          handleDelete={() => {
            setDeleteOpen(false);
            handleDelete();
          }}
          title={'Delete Tag'}
          message={<Typography>Are you sure you want to permanently delete this Tag?</Typography>}
        />
      )}
    </>
  );
};

const SkeletonTile = ({ i }: { i: number }) => {
  return (
    <Stack
      direction='row'
      height={36}
      sx={{ background: i % 2 !== 0 ? 'rgba(0,0,0,.05)' : 'transparent' }}
      alignItems='center'
    >
      <Box width={300}>
        <Skeleton variant='text' width={190} />
      </Box>
      <Box>
        <Skeleton variant='text' width={100} />
      </Box>
    </Stack>
  );
};
