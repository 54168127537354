import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import { addMinutes } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useGetSession } from '@juno/client-api';
import {
  AccessPass,
  Product,
  ProductPrice,
  ReoccurrenceEnum,
  RoleEnum,
  Session,
  UserPurchase,
} from '@juno/client-api/model';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';
import { useAnalyticsContext, usePubnubContext } from '@juno/modules';
import { determineSessionToShow, useAccessPassPurchaseState, useSettings } from '@juno/utils';
import { getRelativeStartsIn } from './helpers';
import MainPage from './pages/Main';
import RoomInfo from './pages/RoomInfo';
import './styles/styles.scss';

const STATE_IDLE = 'STATE_IDLE';
const STATE_JOINED = 'STATE_JOINED';
const STATE_PREJOIN = 'STATE_PREJOIN';

export interface ProductStatus {
  product: Product | undefined;
  myPrice: ProductPrice | undefined;
  myPayment: UserPurchase | undefined;
  myAccessPass: AccessPass | undefined;
}

const SessionPage: React.FC = () => {
  const { site, user } = useSettings();
  const { firehoseActivity } = useAnalyticsContext();

  const siteId = site?.id || '';
  const { sessionSlug } = useParams();
  const { pubnub } = usePubnubContext();
  const [appState, setAppState] = useState(STATE_PREJOIN);
  const [sessionData, setSessionData] = useState<Session | null>(null);

  const {
    data: session,
    isLoading,
    refetch: refetchSession,
  } = useGetSession(
    siteId,
    sessionSlug || '',
    {},
    {
      query: {
        enabled: !!site?.id,
      },
    },
  );

  useEffect(() => {
    // Subscribe to the session channel to report presence
    if (sessionData && user) {
      pubnub.subscribe({ channels: [sessionData.id], withPresence: true });
    }
    return () => {
      // Unsubscribe from the session channel to stop reporting presence
      if (sessionData) {
        pubnub.unsubscribe({ channels: [sessionData.id] });
      }
    };
  }, [pubnub, sessionData, user]);

  useEffect(() => {
    if (!session) return;
    setSessionData(determineSessionToShow(session));
  }, [session]);

  const [hasStarted, setHasStarted] = useState(false);
  const timer = useRef<null | NodeJS.Timeout>(null);

  const userIsModeratorOrPresenter = useMemo(() => {
    if (user?.role === RoleEnum.NUMBER_1) return true;
    if (sessionData?.presenters?.find((presenter) => presenter.id === user?.id)) return true;
    if (sessionData?.moderators?.find((moderator) => moderator.id === user?.id)) return true;
    return false;
  }, [user, sessionData]);

  useEffect(() => {
    if (!sessionData) return;

    if (hasStarted && timer.current) {
      return;
    }

    if (timer.current) return;

    // TODO greenroom/waiting room
    const greenroomOpenMinutes = sessionData?.greenroom_open_minutes || 0;

    timer.current = setInterval(() => {
      const sessionStartMinusGreenroom = addMinutes(
        new Date(sessionData?.date_start || 0),
        userIsModeratorOrPresenter ? -greenroomOpenMinutes : 0,
      );

      const relativeStatus = getRelativeStartsIn(
        sessionStartMinusGreenroom.toISOString(),
        sessionData?.date_end || '',
        sessionData?.reoccurrence || ReoccurrenceEnum.once,
        sessionData?.time_end,
      );

      if (relativeStatus === 'Live') {
        console.log(
          `Bringing user into session, user is ${
            userIsModeratorOrPresenter ? 'moderator or presenter' : 'attendee'
          }`,
        );
        if (timer.current) clearInterval(timer.current);
        timer.current = null;
        setHasStarted(true);
        setAppState(STATE_JOINED);
      }
    }, 1000);
  }, [sessionData]);

  useEffect(() => {
    return () => {
      timer.current && clearInterval(timer.current);
    };
  }, []);

  const hasReportedView = useRef(false);
  useEffect(() => {
    // Record session view analytics
    if (sessionData && user && !hasReportedView.current) {
      hasReportedView.current = true;
      firehoseActivity(
        ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_CONTENT,
        sessionData?.id || '',
        null,
        `${user?.id || ''}`,
        ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_LIVE_SESSION,
        ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_VIEW.value,
        sessionData.title,
        null,
      );
    }
  }, [firehoseActivity, sessionData, user, session]);

  useEffect(() => {
    // Subscribe to the session channel to report presence
    if (sessionData && user) {
      pubnub.subscribe({ channels: [sessionData.id], withPresence: true });
    }
    return () => {
      // Unsubscribe from the session channel to stop reporting presence
      if (sessionData) {
        pubnub.unsubscribe({ channels: [sessionData.id] });
      }
    };
  }, [pubnub, sessionData, user]);

  // Product status - is this paywalled,
  const productStatus: ProductStatus = useAccessPassPurchaseState(
    siteId,
    user,
    sessionData?.restrict_schedule_access_passes || [],
    refetchSession,
  );

  const isPaywalled = useMemo(() => {
    return !!(productStatus.product && !productStatus.myAccessPass && !productStatus.myPayment);
  }, [productStatus]);

  return (
    <>
      {!sessionData ||
        (isLoading && (
          <>
            <Skeleton variant='rectangular' width={'80%'} height={60} sx={{ m: '10px auto' }} />
            <Skeleton variant='rectangular' width={'80%'} height={300} sx={{ m: '10px auto' }} />
            <Skeleton variant='rectangular' width={'80%'} height={80} sx={{ m: '10px auto' }} />
          </>
        ))}
      {sessionData &&
        (hasStarted && !isPaywalled && (appState === STATE_PREJOIN || appState === STATE_JOINED) ? (
          <Box className='session-page'>
            <MainPage
              sessionData={sessionData}
              cancelPrejoin={() => setAppState(STATE_IDLE)}
              isPaywalled={isPaywalled}
              productStatus={productStatus}
            />
          </Box>
        ) : (
          <RoomInfo
            hasStarted={hasStarted}
            refetchSession={refetchSession}
            productStatus={productStatus}
            isPaywalled={isPaywalled}
            splashPageTimerCallback={{
              number: sessionData?.greenroom_open_minutes
                ? sessionData?.greenroom_open_minutes * 60
                : 0,
              callback: () => {
                setAppState(STATE_PREJOIN);
              },
            }}
          />
        ))}
    </>
  );
};
export default SessionPage;
