import React from 'react';
import { Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSettings } from '@juno/utils';
import Layout from '../layout';
import EmailCampaignList from './EmailCampaignList/EmailCampaignList';

const Emailer: React.FC = () => {
  // Variables
  const { site } = useSettings();
  const navigate = useNavigate();

  // Functions
  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Layout title={`${site?.slug} Emailer Tool`} onBack={handleGoBack}>
      <Box sx={{ width: '100%' }}>
        <EmailCampaignList />
      </Box>
    </Layout>
  );
};

export default Emailer;
