import React, { useEffect, useMemo, useState } from 'react';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import { Box, Button, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { usePapaParse } from 'react-papaparse';
import { useLocation } from 'react-router-dom';

const ReportPreviewContainer = styled(Box)(({ theme }) => ({
  maxWidth: '100%',

  margin: '50px auto',
  border: `1px solid ${theme.palette.divider}`,
  padding: 20,
  borderRadius: 8,
  background: '#f8f8f8',
}));
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
export const ReportPreview: React.FC = () => {
  const query = useQuery();
  const { readRemoteFile } = usePapaParse();
  const [csvColumns, setCsvColumns] = useState<GridColDef[]>([]);
  const [csvRows, setCsvRows] = useState<any[]>([]);

  const handleDownload = () => {
    const reportUrl = query?.get('reportUrl');
    if (reportUrl) {
      window.open(reportUrl);
    }
  };
  useEffect(() => {
    const reportUrl = query?.get('reportUrl');
    if (reportUrl) {
      readRemoteFile(reportUrl, {
        download: true,
        header: true,
        complete: (results) => {
          const resultData = results.data;
          // Add an index header
          const columnCount = results.meta.fields?.length || 0;
          const columnRow = ['row'].concat(results.meta.fields || []);
          const columnHeaders = columnRow.map((key) => {
            return {
              key: key,
              field: key,
              headerName: key,
              minWidth: 200,
              flex: 1,
              isResizable: true,
              editable: false,
              headerAlign: 'left',
            } as GridColDef;
          });

          const dataRows = resultData
            .filter((row) => Object.keys(row as object).length === columnCount)
            .map((row, index) => {
              return { row: (index + 1).toString(), ...(row as object) };
            });
          setCsvColumns(columnHeaders);
          setCsvRows(dataRows);
        },
      });
    }
  }, [query, readRemoteFile]);

  return (
    <ReportPreviewContainer>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        p={'12px 0 16px 0'}
        sx={{ width: '100%' }}
      >
        <Box></Box>
        <Button
          onClick={handleDownload}
          variant='contained'
          startIcon={<SystemUpdateAltIcon />}
          sx={{ boxShadow: 'none', textTransform: 'none' }}
        >
          Download Report
        </Button>
      </Stack>
      <DataGrid
        rows={csvRows}
        columns={csvColumns}
        pageSize={20}
        rowsPerPageOptions={[20, 50, 100]}
        getRowId={(row) => row['row']}
        autoHeight
        disableColumnFilter
        disableColumnMenu
        disableSelectionOnClick
        getRowClassName={(params) => {
          return '';
        }}
      />
    </ReportPreviewContainer>
  );
};
