import { useEffect, useMemo, useRef, useState } from 'react';
import {
  getScormRegistrationLink,
  useCompleteLesson,
  useGetCourse,
  useGetCourseEnrollments,
  useGetCourseLessons,
  useGetLesson,
  useGetLessonParts,
  useGetUserCourseLessonCompletions,
  useGetUserLessonCompletions,
  useStartLesson,
  useUpdateLessonCompletion,
} from '@juno/client-api';
import {
  AllowRetakesEnum,
  Enrollment,
  ScormRegistrationLinkResponse,
  UserAnswer,
} from '@juno/client-api/model';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';
import { useAnalyticsContext, usePubnubContext } from '@juno/modules';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import {
  CompletionMetadataModel,
  CompletionScormModel,
  ExtendedLessonCompletion,
  LessonHooks,
  VideoCompletionModel,
} from './lesson';

export const useLessonHooks = (
  siteId: string,
  courseSlug: string,
  lessonSlug: string,
): LessonHooks => {
  // * REFS
  const timeStarted = useRef<Date>(new Date());
  const hasReported = useRef(false);

  // * STATES
  const [videoCompletions, setVideoCompletions] = useState<VideoCompletionModel | undefined>(
    undefined,
  );
  const [showIncompleteDialog, setShowIncompleteDialog] = useState(false);
  const [scormLoading, setScormLoading] = useState(false);
  const [scormUrl, setScormUrl] = useState<string | null>(null);
  const [scormRefreshTimer, setScormRefreshTimer] = useState<NodeJS.Timer | null>(null);
  const [showTimesUpDialog, setShowTimesUpDialog] = useState(false);
  const [showTimedLessonPrompt, setShowTimedLessonPrompt] = useState(false);
  const [openRetakeModal, setOpenRetakeModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showIncompleteQuestions, setShowIncompleteQuestions] = useState(false);
  const [lessonCompleteDialogOpen, setLessonCompleteDialogOpen] = useState(false);
  const [lessonScore, setLessonScore] = useState(0);
  const [userAnswers, setUserAnswers] = useState<UserAnswer[]>([]);
  const [shouldPauseVideo, setShouldPauseVideo] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openRetakeErrorModal, setOpenRetakeErrorModal] = useState(false);
  const [showUnwatchedVideos, setShowUnwatchedVideos] = useState(false);
  const [showUnwatchedDialog, setShowUnwatchedDialog] = useState(false);
  const { pubnub } = usePubnubContext();

  // * QUERY HOOKS
  const { user, junoUser } = useSettings();
  const { firehoseActivity } = useAnalyticsContext();
  const { FIREHOSE_ACTIONS, FIREHOSE_CATEGORIES, FIREHOSE_OBJECTS } = ANALYTICS_CONFIGURATION;

  const courseObj = useGetCourse(siteId, courseSlug);
  const { data: course, isLoading: courseLoading, isError: courseLoadError } = courseObj;

  const lessonsObj = useGetCourseLessons(siteId, courseSlug, { order: 'order' });
  const { data: lessons, isLoading: lessonsLoading, isError: lessonLoadError } = lessonsObj;

  const lessonObj = useGetLesson(siteId, courseSlug, lessonSlug);
  const { data: lesson, isLoading: lessonLoading } = lessonObj;

  const userCourseLessonCompletions = useGetUserCourseLessonCompletions(
    siteId,
    course?.id || '',
    user?.id || '',
    {
      order: '-date_started',
    },
  );
  const {
    data: completionsData,
    isLoading: completionsLoading,
    refetch: refetchCompletions,
  } = userCourseLessonCompletions;

  const partsObj = useGetLessonParts(siteId, courseSlug, lessonSlug, { order: 'order' });
  const { data: parts, isLoading: partsLoading } = partsObj;

  const enrollmentsObj = useGetCourseEnrollments(siteId, course?.id || '');
  const { data: enrollments, isLoading: enrollmentsLoading } = enrollmentsObj;

  const {
    data: completionData,
    isLoading: completionLoading,
    refetch: refetchCompletion,
  } = useGetUserLessonCompletions(siteId, course?.id || '', lesson?.id || '', user?.id || '', {
    order: '-date_started',
    include: 'user_answers',
  });

  const currentCompletion = useMemo(() => {
    const incomplete = completionData?.find((completion) => completion?.date_completed === null);
    if (incomplete) {
      return incomplete as ExtendedLessonCompletion;
    }
    return completionData?.[0] as ExtendedLessonCompletion;
  }, [completionData]);

  const isRetake = useMemo(() => {
    const isRetakingLesson = !!(
      completionData &&
      completionData.length > 1 &&
      !!completionData.find((c) => c.date_completed !== null)
    );
    return isRetakingLesson;
  }, [currentCompletion, completionData]);

  // * MUTATIONS
  const refetchAllCompletions = () => {
    refetchCompletion();
    refetchCompletions();
  };

  const updateCompletion = useUpdateLessonCompletion(
    onMutation(MutationAction.UPDATE, 'LessonCompletion', refetchAllCompletions, true),
  );

  const startLesson = useStartLesson(
    onMutation(MutationAction.UPDATE, 'LessonCompletion', refetchAllCompletions, true),
  );

  const retakeLesson = useStartLesson(
    onMutation(MutationAction.UPDATE, 'LessonCompletion', refetchAllCompletions, true),
  );

  const completeLesson = useCompleteLesson(
    onMutation(MutationAction.UPDATE, 'LessonCompletion', refetchAllCompletions, true),
  );

  // * EFFECTS
  useEffect(() => {
    const meta: CompletionMetadataModel = currentCompletion?.metadata || {};
    const completion: VideoCompletionModel | undefined = meta.video_completion || undefined;
    if (completion) {
      setVideoCompletions(completion);
    }

    if (
      (currentCompletion?.date_completed ||
        !currentCompletion ||
        !course ||
        !lesson ||
        !updateCompletion) &&
      !isRetake
    ) {
      // Load user's answers
      if (currentCompletion?.user_answers) {
        setUserAnswers(currentCompletion.user_answers);
      }
      return;
    }
    if (!currentCompletion?.date_started && !showTimedLessonPrompt) {
      timeStarted.current = new Date();
      const data = {
        ...currentCompletion,
        date_started: timeStarted.current.toISOString(),
      };
      const mutationParams = {
        siteId,
        courseId: course?.id || '',
        lessonId: lesson?.id || '',
        userId: user?.id || '',
        completionId: currentCompletion?.id || '',
        data,
      };
      updateCompletion.mutate(mutationParams);
    }
  }, [currentCompletion, showTimedLessonPrompt]);

  useEffect(() => {
    // Check for and handle scorm course
    if (!courseLoading && !courseLoadError && !scormLoading && !scormUrl) {
      setScormLoading(true);
      const isScorm = !!course?.metadata?.scorm_course;
      if (isScorm) {
        getScormRegistrationLink(siteId, course?.id || '', { redirect_on_exit_url: '' }).then(
          (response: ScormRegistrationLinkResponse) => {
            setScormUrl(response.link);
            setScormLoading(false);
          },
        );
      }
    }
  }, [course]);

  // fire analytics for lesson view
  useEffect(() => {
    if (!lessonLoading && !lessonLoadError && !hasReported.current) {
      hasReported.current = true;

      // Report viewing of a lesson
      firehoseActivity(
        FIREHOSE_OBJECTS.OBJECT_CONTENT,
        course?.id || '',
        null,
        `${user?.id || ''}`,
        FIREHOSE_CATEGORIES.CATEGORY_LESSON,
        FIREHOSE_ACTIONS.ACTION_VIEW?.value,
        null,
        null,
      );
    }
  }, [lesson]);

  useEffect(() => {
    if (!lessonLoading && !lessonLoadError) {
      setShowTimedLessonPrompt(isTimed && !isLessonComplete && !currentCompletion);
      setRefresh(showTimedLessonPrompt);
    }
  }, [lesson]);

  useEffect(() => {
    if (course?.metadata?.scorm_course && !scormRefreshTimer) {
      // Register interval to poll scorm
      const interval = setInterval(refetchAllCompletions, 10 * 1000);
      setScormRefreshTimer(interval);
    } else if (!course?.metadata?.scorm_course && scormRefreshTimer) {
      // Internal to poll scorm exists but is no longer needed
      clearInterval(scormRefreshTimer);
      setScormRefreshTimer(null);
    }
    return () => {
      if (scormRefreshTimer) {
        clearInterval(scormRefreshTimer);
        setScormRefreshTimer(null);
      }
    };
  }, [course?.metadata?.scorm_course]);

  // * MEMOS
  const isTimed = useMemo(() => {
    return typeof lesson?.time_allowed === 'number' && lesson?.time_allowed > 0;
  }, [lesson?.time_allowed]);

  const isLessonComplete = useMemo(() => {
    if (currentCompletion?.date_completed) return true;
    return false;
  }, [completionData]);

  const isEnrollmentRequired = useMemo(() => {
    const enrollment = enrollments?.find(
      (enrollment: Enrollment) => enrollment.user_id === user?.id,
    );
    return course?.enrollment_required && !enrollment;
  }, [course, enrollments, user]);

  const percentComplete = useMemo(() => {
    if (completionsData) {
      const totalLessons = lessons?.length || 0;
      const completedLessons =
        lessons?.filter((lesson) => {
          const latestCompletion = completionsData?.filter(
            (completion) => completion.lesson_id === lesson.id,
          )?.[0];
          return latestCompletion?.date_completed;
        }).length || 0;
      return Math.round((completedLessons / totalLessons) * 100);
    }
    return 0;
  }, [completionsData, lessons]);

  const [step, totalSteps, nextLesson, isLastLesson] = useMemo(() => {
    if (!lessons || !lesson) return [0, 0];
    const stepIndex = lessons.findIndex((l) => l.id === lesson.id);
    const isLast = stepIndex + 1 === lessons.length;
    return [stepIndex + 1, lessons.length, lessons[stepIndex + 1] || '', isLast];
  }, [lessons, lesson]);

  const [canIRetake, numRetakesLeft, hasPassed, retakesEnabled] = useMemo(() => {
    const completionMeta: CompletionMetadataModel = lesson?.metadata || {};
    const underCompletionLimit =
      (completionData?.length || 0) - 1 < (completionMeta?.retake_limit || 1);
    const lessonIncludedInCourseGrade = lesson?.include_in_course_grade;
    const currentGrade = currentCompletion?.grade || 0;
    const lessonPassingPercent = lesson?.passing_percent || 0;
    const hasPassedLesson = lessonIncludedInCourseGrade
      ? currentGrade >= lessonPassingPercent
      : true;
    let numRetakesLeft = (completionMeta?.retake_limit || 1) - (completionData?.length || 0) + 1;
    let canIRetake = false;
    let retakesEnabled = true;

    switch (lesson?.allow_retakes) {
      case AllowRetakesEnum.never:
        retakesEnabled = false;
        numRetakesLeft = 0;
        break;
      case AllowRetakesEnum.unlimited:
        canIRetake = true;
        numRetakesLeft = -1;
        break;
      case AllowRetakesEnum.limited:
        if (underCompletionLimit) {
          canIRetake = true;
        }
        break;
      case AllowRetakesEnum.on_fail_limited:
        if (underCompletionLimit && !hasPassedLesson) {
          canIRetake = true;
        }
        break;
      case AllowRetakesEnum.on_fail_unlimited:
        if (!hasPassedLesson) {
          canIRetake = true;
          numRetakesLeft = -1;
        }
        break;
    }
    return [canIRetake, numRetakesLeft, hasPassedLesson, retakesEnabled];
  }, [lesson, completionData, currentCompletion]);

  const canCompleteLesson = useMemo(() => {
    // This is currently just to handle disabling completion on SCORM course lesson until lesson completion has
    //  been set to COMPLETED in registrationCompletion
    return (
      !course?.metadata?.scorm_course ||
      (currentCompletion?.metadata?.scorm as CompletionScormModel)?.registrationCompletion ===
        'COMPLETED'
    );
  }, [course, currentCompletion]);

  useEffect(() => {
    // Subscribe to the course channel to report presence
    if (course && user) {
      pubnub.subscribe({ channels: [course.id], withPresence: true });
    }
    return () => {
      // Unsubscribe from the course channel to stop reporting presence
      if (course) {
        pubnub.unsubscribe({ channels: [course.id] });
      }
    };
  }, [pubnub, course, user]);

  return {
    isSaving,
    setIsSaving,
    showIncompleteQuestions,
    setShowIncompleteQuestions,
    lessonCompleteDialogOpen,
    setLessonCompleteDialogOpen,
    lessonScore,
    setLessonScore,
    userAnswers,
    setUserAnswers,
    shouldPauseVideo,
    setShouldPauseVideo,
    refresh,
    setRefresh,
    openRetakeErrorModal,
    setOpenRetakeErrorModal,
    showUnwatchedVideos,
    setShowUnwatchedVideos,
    showUnwatchedDialog,
    setShowUnwatchedDialog,
    canIRetake,
    completionData,
    completionLoading,
    completeLesson,
    completionsData,
    completionsLoading,
    course,
    courseLoading,
    currentCompletion,
    enrollments,
    enrollmentsLoading,
    hasPassed,
    hasReported,
    isEnrollmentRequired,
    isLastLesson,
    isLessonComplete,
    isTimed,
    lesson,
    lessonLoading,
    lessons,
    lessonsLoading,
    nextLesson,
    numRetakesLeft,
    openRetakeModal,
    parts,
    partsLoading,
    percentComplete,
    refetchCompletion,
    refetchCompletions,
    retakeLesson,
    retakesEnabled,
    scormLoading,
    scormUrl,
    setOpenRetakeModal,
    setScormLoading,
    setScormUrl,
    setShowIncompleteDialog,
    setShowTimedLessonPrompt,
    setShowTimesUpDialog,
    setVideoCompletions,
    showIncompleteDialog,
    showTimedLessonPrompt,
    showTimesUpDialog,
    startLesson,
    step,
    timeStarted,
    totalSteps,
    updateCompletion,
    videoCompletions,
    canCompleteLesson,
    isRetake,
  };
};
