import React from 'react';
import { Avatar, Grid, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { JunoUser } from '@juno/client-api/model';
import { useMessengerContext, useSettings } from '@juno/utils';
import { messengerTabMap } from '../index';

export interface SearchResultProps {
  searchResultUser?: JunoUser;
  channelName?: string;
  channelId?: string;
}

export const SearchResult: React.FC<SearchResultProps> = ({
  searchResultUser,
  channelName,
  channelId,
}) => {
  const {
    createChannelThenSubscribe,
    subscribeToChannels,
    setMessengerTab,
    setIsConversation,
    setCurrentChannel,
  } = useMessengerContext();
  const { platform, user } = useSettings();

  // Check that exactly one of user or channelName is provided
  if (!(searchResultUser ? !channelName : channelName)) {
    console.error('SearchResult: Either user or channelName must be provided, but not both.');
    return null; // Return null for an empty render
  }

  if (searchResultUser) {
    return (
      <ListItem
        alignItems='flex-start'
        onMouseDown={(e) => {
          if (!searchResultUser.id || !platform?.id || !user?.id) return;
          createChannelThenSubscribe([searchResultUser.id, user.id]).then((channelId) => {
            if (channelId) {
              setMessengerTab(messengerTabMap.Messenger);
              setCurrentChannel(channelId);
              setIsConversation(true);
            }
          });
        }}
      >
        <ListItemAvatar sx={{ marginRight: 1, minWidth: 0 }}>
          <Avatar
            alt={searchResultUser.email}
            src={searchResultUser.avatar ?? undefined}
            sx={{ width: 34, height: 34 }}
          />
        </ListItemAvatar>
        <Grid container justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <ListItemText
              primary={
                <Typography
                  sx={{
                    display: 'block',
                    fontSize: '.9rem',
                    marginTop: 1.7,
                    cursor: 'pointer',
                  }}
                  component='span'
                  variant='body2'
                  color='text.primary'
                  fontWeight={200}
                >
                  {`${searchResultUser.first_name} ${searchResultUser.last_name}`}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </ListItem>
    );
  } else if (channelName) {
    return (
      <ListItem
        alignItems='flex-start'
        onMouseDown={(e) => {
          if (channelId) {
            setMessengerTab(messengerTabMap.Messenger);
            setCurrentChannel(channelId);
            setIsConversation(true);
            subscribeToChannels([channelId]);
          }
        }}
      >
        <Grid container justifyContent='space-between' alignItems='center' sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <ListItemText
              primary={
                <Typography
                  sx={{
                    display: 'block',
                    fontSize: '1.1rem',
                    marginTop: 1.7,
                    cursor: 'pointer',
                  }}
                  component='span'
                  variant='body1'
                  color='text.primary'
                  fontWeight={400}
                >
                  {channelName}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </ListItem>
    );
  }

  return null; // Fallback return (though it should never reach here)
};
