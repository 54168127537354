import React from 'react';
import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';

interface SearchBarProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ onChange, placeholder }) => (
  <TextField
    sx={{ input: { height: '14px' } }}
    onChange={onChange}
    placeholder={placeholder}
    fullWidth
    InputProps={{
      startAdornment: (
        <InputAdornment position='start'>
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />
);

export default SearchBar;
