import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import DialogAriaWrapper from '../DialogAriaWrapper';

interface CourseFilterDialogProps {
  open: boolean;
  onClose?: () => void;
  onFilter?: (filter: string) => void;
  filterComponent?: React.ReactNode;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const CourseFilterDialog: React.FC<CourseFilterDialogProps> = ({
  open,
  onClose,
  onFilter,
  filterComponent,
}) => {
  const handleSaveFilter = () => {
    onFilter && onFilter('filter');
    onClose && onClose();
  };
  return (
    <DialogAriaWrapper
      id={'courseFilterDialog'}
      open={open}
      keepMounted
      onClose={onClose}
      TransitionComponent={Transition}
      aria-describedby='alert-dialog-slide-description'
      fullWidth
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>Filter</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>{filterComponent}</DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleSaveFilter}>
          Apply
        </Button>
      </DialogActions>
    </DialogAriaWrapper>
  );
};

export default CourseFilterDialog;
