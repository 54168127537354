import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Input,
  TextField,
} from '@mui/material';
import { JunoImage as JunoImageModel } from '@juno/client-api/fakeModel';
import { CourseResource as CourseResourceModel } from '@juno/client-api/model';
import { FILE_UPLOAD_TYPES } from '@juno/constants';
import { ASPECT_RATIOS, JunoImageUpload, JunoSpin } from '@juno/ui';
import { optimizeImage, readImageFile, validateSelectedFileSize } from '@juno/utils';

interface FormDialogProps {
  isLoading: boolean;
  item: CourseResourceModel | undefined;
  open: boolean;
  onSave: (resource: PayloadProps | undefined) => void;
  onClose: () => void;
  onDelete: () => void;
  isSaving: boolean;
}

export interface PayloadProps {
  resource?: any; // TODO: find a more typescripty way to key/value pair by string
  file?: File | null;
  iconFile?: File;
  iconUrl?: string;
}

const FormDialog: React.FC<FormDialogProps> = ({
  isLoading,
  item,
  open,
  onClose,
  onSave,
  onDelete,
  isSaving,
}) => {
  const [payload, setPayload] = useState<PayloadProps | undefined>();
  const [isTitleTouched, setIsTitleTouched] = useState<boolean>(false);
  const [isDescriptionTouched, setIsDescriptionTouched] = useState<boolean>(false);

  useEffect(() => {
    setPayload({ resource: item });
    return () => {
      setPayload(undefined);
    };
  }, [item]);

  useEffect(() => {
    if (!open) {
      setPayload(undefined);
    }
  }, [open]);

  const isTitleValid = () => {
    return payload?.resource?.title && payload?.resource?.title?.length <= 67;
  };

  const isDescriptionValid = () => {
    return payload?.resource?.description && payload?.resource?.description?.length <= 78;
  };

  const isValid = useMemo(() => {
    const requiredFields: (keyof PayloadProps)[] = item
      ? ['resource']
      : ['resource', 'file', 'iconFile', 'iconUrl'];
    const hasRequiredFields = !requiredFields.some((fieldId) => !payload?.[fieldId]);
    return (
      hasRequiredFields &&
      isTitleTouched &&
      isTitleValid() &&
      isDescriptionTouched &&
      isDescriptionValid()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const handleUpdate = (key: string, val: unknown) => {
    const newResource = { ...payload?.resource, [key]: val };
    setPayload({ ...payload, resource: newResource });
  };

  const changeHandler = {
    checkbox: ({ target: t }: ChangeEvent<HTMLInputElement>) => handleUpdate(t.name, t.checked),
    text: ({ target: t }: ChangeEvent<HTMLInputElement>) => handleUpdate(t.name, t.value),
    textarea: ({ target: t }: ChangeEvent<HTMLTextAreaElement>) => handleUpdate(t.name, t.value),
    tinymcetextarea: (key: string, value: string) => handleUpdate(key, value),
    image: (key: string, image: JunoImageModel) => handleUpdate(key, image.url),
    number: ({ target: t }: ChangeEvent<HTMLInputElement>) =>
      handleUpdate(t.name, parseInt(t.value) || 0),
    file: ({ target: t }: ChangeEvent<HTMLInputElement>) => {
      const file = t.files?.[0];
      if (validateSelectedFileSize(file)) {
        setPayload({ ...payload, file });
      } else {
        setPayload({ ...payload, file: null });
      }
    },
    iconFile: (iconFile: File, iconUrl: string) => {
      setPayload((oldPayload) => ({ ...oldPayload, iconFile, iconUrl }));
    },
  };

  const onDrop = useCallback((file: File | null) => {
    if (!file) return;
    readImageFile(file, (b64Str) => changeHandler.iconFile(file, b64Str));
  }, []);

  if (isLoading) return <JunoSpin />;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Resource Details</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={7}>
            <TextField
              disabled={isSaving}
              id='title'
              name='title'
              label='Title'
              fullWidth
              margin='dense'
              value={payload?.resource?.title || ''}
              onChange={changeHandler.text}
              error={isTitleTouched && !isTitleValid()}
              onFocus={(e: any) => {
                setIsTitleTouched(true);
              }}
            />
            <FormHelperText error>
              {isTitleTouched &&
                !isTitleValid() &&
                (payload?.resource?.title
                  ? 'Title must be less than 67 characters'
                  : 'Title is required')}
            </FormHelperText>
            <TextField
              disabled={isSaving}
              id='description'
              name='description'
              label='Description'
              fullWidth
              margin='dense'
              value={payload?.resource?.description || ''}
              onChange={changeHandler.text}
              error={isDescriptionTouched && !isDescriptionValid()}
              onFocus={(e: any) => {
                setIsDescriptionTouched(true);
              }}
            />
            <FormHelperText error>
              {isDescriptionTouched &&
                !isDescriptionValid() &&
                (payload?.resource?.description
                  ? 'Description must be less than 78 characters'
                  : 'Description is required')}
            </FormHelperText>
          </Grid>
          <Grid item xs={6} sm={5}>
            <Box sx={{ mt: 1 }}>
              <JunoImageUpload
                style={{ aspectRatio: '16/9' }}
                src={optimizeImage(200, payload?.resource?.iconUrl || payload?.resource?.icon)}
                srcUrl={payload?.resource?.iconUrl || payload?.resource?.icon || payload?.iconUrl}
                onFileSelected={onDrop}
                aspectRatios={[ASPECT_RATIOS['SIXTEEN_NINE']]}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex' }}>
          <TextField
            disabled
            id='url'
            name='url'
            label='File'
            fullWidth
            margin='dense'
            variant='standard'
            value={payload?.file?.name || payload?.resource?.download?.url || ''}
          />
          <TextField
            disabled
            id='filetype'
            name='filetype'
            label='Type'
            margin='dense'
            sx={{ ml: 1 }}
            variant='standard'
            value={payload?.file?.type || payload?.resource?.download?.filetype || ''}
          />
          <Button
            variant='contained'
            sx={{ width: '270px', ml: 1, mt: 1, mb: 0.5 }}
            component='label'
            disabled={isSaving}
          >
            Upload File
            <Input
              type='file'
              inputProps={{
                accept: FILE_UPLOAD_TYPES.join(','),
                multiple: true,
              }}
              onChange={changeHandler.file}
              style={{ display: 'none' }}
            />
          </Button>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ display: 'flex', justifyContent: item ? 'space-between' : 'flex-end', m: 2 }}
      >
        {!!item && (
          <Button disabled={isSaving} variant='contained' color='error' onClick={onDelete}>
            Delete
          </Button>
        )}
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button disabled={isSaving} onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            disabled={!isValid}
            loading={isSaving}
            variant='contained'
            onClick={() => onSave(payload)}
          >
            Save
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
