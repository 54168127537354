import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';
import {
  getGetEmailCampaignsQueryKey,
  useCreateEmailCampaign,
  useGetSiteFeatureConfig,
} from '@juno/client-api';
import { EmailCampaign as EmailCampaignModel, FeatureConfigTypeEnum } from '@juno/client-api/model';
import { DialogAriaWrapper, GenericFormikInput } from '@juno/ui';
import { MutationAction, onMutation, snackOptions, useSettings } from '@juno/utils';
import { EmailCampaignType, EmailCampaignTypeMap, getUserPreferenceValue } from '../utils/utils';
import { NewCampaignPostSchema } from '../utils/validationSchema';

interface NewEmailCampaignDialogProps {
  open: boolean;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  campaigns: EmailCampaignModel[];
}

interface FormikValues {
  name: string;
  type?: EmailCampaignType;
  user_preference: string;
  is_active: boolean;
}

const NewEmailCampaignDialog: React.FC<NewEmailCampaignDialogProps> = ({
  open,
  handleClose,
  campaigns,
}) => {
  // Variables
  const { site } = useSettings();
  const [welcomeEmailIsReady, setWelcomeEmailIsReady] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { CREATE } = MutationAction;
  const refetchEmailCampaigns = () =>
    queryClient.invalidateQueries(getGetEmailCampaignsQueryKey(site?.id || ''));
  const emailCampaignCreate = useCreateEmailCampaign(
    onMutation(CREATE, 'EmailCampaign', refetchEmailCampaigns),
  );
  const { enqueueSnackbar } = useSnackbar();

  // Queries and mutations
  const { data: siteFeatureConfig, isLoading: configLoading } = useGetSiteFeatureConfig(
    site?.id || '',
    FeatureConfigTypeEnum.automation,
    {
      query: {
        retry: 0,
        enabled: !!site?.id,
      },
    },
  );
  const handleCreateEmailCampaign = (payload: EmailCampaignModel) => {
    emailCampaignCreate
      .mutateAsync({ siteId: site?.id || '', data: payload })
      .then(() => {
        handleClose(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.toString(), snackOptions('error'));
      });
  };

  const handleSubmit = (values: FormikValues): void => {
    values.user_preference = getUserPreferenceValue(values.type || '', values.user_preference);
    if (values.type) {
      const payload: EmailCampaignModel = {
        id: '',
        type: values.type,
        name: values.name,
        is_active: values.is_active,
      };
      handleCreateEmailCampaign(payload);
    }
  };

  // Functions
  useEffect(() => {
    if (!siteFeatureConfig?.config) {
      setWelcomeEmailIsReady(false);
      return;
    } else {
      if (
        !siteFeatureConfig?.config.site_nickname ||
        !siteFeatureConfig?.config.primary_color ||
        !siteFeatureConfig?.config.banner_image ||
        !siteFeatureConfig?.config.logo
      ) {
        setWelcomeEmailIsReady(false);
        return;
      }
    }
    setWelcomeEmailIsReady(true);
  }, [siteFeatureConfig]);

  const existingCampaignTypes = useMemo(() => {
    return campaigns.map((c) => c.type.toString()).filter((t) => t !== EmailCampaignType.Custom);
  }, [campaigns]);

  return (
    <DialogAriaWrapper
      id={'newEmailCampaignDialog'}
      open={open}
      onClose={handleClose}
      fullWidth={true}
      maxWidth='sm'
    >
      <Formik
        enableReinitialize={true}
        validationSchema={NewCampaignPostSchema}
        initialValues={{
          name: '',
          user_preference: 'N/A',
          is_active: false,
        }}
        onSubmit={handleSubmit}
      >
        {({ resetForm, values, handleChange, handleBlur, touched, errors, handleSubmit }) => (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <DialogTitle id={'newEmailCampaignDialog-dialog-title'} sx={{ ml: 3, mt: 3 }}>
                New Campaign
              </DialogTitle>
              <CloseIcon
                onClick={() => {
                  resetForm();
                  handleClose(false);
                }}
                sx={{ mr: 3, mt: 3, cursor: 'pointer' }}
              />
            </Box>
            <DialogContent id={'newEmailCampaignDialog-dialog-description'}>
              <Box
                sx={{
                  width: '400px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                  p: 2,
                  margin: 'auto',
                }}
              >
                <GenericFormikInput
                  label='Campaign Title'
                  type='text'
                  name='name'
                  value={values.name}
                  error={touched.name && errors.name}
                />
                <FormControl fullWidth>
                  <InputLabel id='Campaign-type'>Campaign Type</InputLabel>
                  <Select
                    name='type'
                    value={values.type}
                    label='Campaign Type'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.type && Boolean(errors.type)}
                    sx={{ mb: 2 }}
                  >
                    {Object.entries(EmailCampaignTypeMap)
                      .filter(([key, value]) => !existingCampaignTypes.includes(key))
                      .map(([key, value], index) => (
                        <MenuItem key={index} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText error={touched.type && Boolean(errors.type)}>
                    {touched.type && errors.type}
                  </FormHelperText>
                </FormControl>
                <GenericFormikInput
                  label='Subscription Group'
                  type='text'
                  name='user_preference'
                  value={
                    values.type
                      ? getUserPreferenceValue(values.type, values.user_preference)
                      : 'N/A'
                  }
                  disabled={true}
                />
                <Button
                  aria-label='Create-new-campaign'
                  disabled={!values.type || !values.name}
                  onClick={() => handleSubmit()}
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3 }}
                >
                  Create New Campaign
                </Button>
              </Box>
            </DialogContent>
          </>
        )}
      </Formik>
    </DialogAriaWrapper>
  );
};

export default NewEmailCampaignDialog;
