import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, Chip, InputLabelProps } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

interface DefaultMultiChipAutocompleteProps {
  label: string;
  fieldValue: string;
  allContentOptions: any | undefined;
  filteredOptions: any | undefined;
  handleChange: any;
  setFieldValue?: any;
  setFieldTouched?: any;
  filterSelectedOptions?: boolean;
  inputLabelProps?: InputLabelProps;
}

/**
 * Should be able to use this for any multi-select autocomplete
 * - does not group items
 * - only works with model objects that have an `id`, `name` or `title` property, in that order
 * @param label string
 * @param fieldValue string
 * @param allContentOptions array
 * @param filteredOptions array sorted and filtered
 * @param handleChange function
 * @param setFieldValue function
 * @param setFieldTouched function
 * @param filterSelectedOptions boolean
 * @param inputLabelProps object
 * @example
 * <DefaultMultiChipAutocomplete
 *  label='Tags'
 *  fieldValue='tags'
 *  allContentOptions={allTags}
 *  filteredOptions={SomeSortedandFilterdTagArray}
 *  handleChange={handleChange}
 *  setFieldValue={setFieldValue}
 *  setFieldTouched={setFieldTouched}
 * />
 *
 */
const DefaultMultiChipAutocomplete: React.FC<DefaultMultiChipAutocompleteProps> = ({
  label,
  fieldValue,
  allContentOptions,
  filteredOptions,
  handleChange,
  setFieldValue,
  setFieldTouched,
  filterSelectedOptions,
  inputLabelProps,
}) => {
  return (
    <Autocomplete
      id={fieldValue}
      multiple
      disableCloseOnSelect
      getOptionLabel={(option) => {
        return typeof option === 'string' ? option : option.name || option.title;
      }}
      sx={{ mb: 2 }}
      value={filteredOptions}
      options={allContentOptions}
      filterSelectedOptions={filterSelectedOptions ?? true}
      onChange={(e, v) => {
        handleChange(e, v);
        setFieldValue && setFieldValue(fieldValue, v);
        setFieldTouched && setFieldTouched(fieldValue, true);
      }}
      renderTags={(tagList: any[], getTagProps: any) => {
        return tagList.map((option: any, index) => {
          return (
            <Chip
              {...getTagProps({ index })}
              color={option.is_valid === false ? 'error' : 'default'}
              label={<span>{option.name || option.title}</span>}
            ></Chip>
          );
        });
      }}
      renderOption={(
        props: JSX.IntrinsicAttributes &
          React.ClassAttributes<HTMLLIElement> &
          React.LiHTMLAttributes<HTMLLIElement>,
        option,
        { selected }: any,
      ) => (
        <li {...props} key={option.id ?? Date.now()}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option.name || option.title}`}
        </li>
      )}
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
        <TextField
          {...params}
          InputLabelProps={inputLabelProps}
          label={label}
          error={params.error}
          helperText={params.error && params.helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps?.endAdornment}</>,
            disableUnderline: true,
          }}
          variant='filled'
        />
      )}
      isOptionEqualToValue={(option, value) => {
        if (option.id) {
          return option.id === value.id;
        }
        if (option.name) {
          return option.name === value.name;
        }
        return option.title === value.title;
      }}
    />
  );
};

export default DefaultMultiChipAutocomplete;
