// eslint-disable-next-line @typescript-eslint/no-empty-interface
import * as React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, TextField } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { JunoUser, Status0c0Enum as StatusEnum } from '@juno/client-api/model';
import { Enrollment } from '@juno/client-api/utils';

interface OnSubmitProps {
  user: JunoUser;
  status: StatusEnum;
}

interface AutocompleteLearnerProps {
  options: JunoUser[];
  selected: JunoUser[];
  onSubmit: (data: OnSubmitProps) => void;
  onInputChange: (value: string) => void;
  isLoading: boolean;
  locked: boolean;
  handleOptimisticValue: (data: JunoUser) => void;
}

const AutocompleteLearners: React.FC<AutocompleteLearnerProps> = ({
  options,
  selected,
  onSubmit,
  onInputChange,
  isLoading,
  locked,
  handleOptimisticValue,
}) => {
  const [inputValue, setInputValue] = React.useState('');

  const handleChange = (e: React.SyntheticEvent, newValue: JunoUser[], reason: string) => {
    const newLearner = newValue[newValue.length - 1];
    if (reason === 'selectOption') {
      handleOptimisticValue(newLearner);
      onSubmit({
        user: newLearner,
        status: Enrollment.ENROLLMENT_STATUSES.PENDING_APPROVAL?.value,
      });
    }
  };

  const handleInputChange = (e: React.SyntheticEvent, newInputValue: string) => {
    if (['change', 'blur', 'click'].includes(e?.type)) {
      setInputValue(newInputValue);
      onInputChange(newInputValue);
    }
  };

  return (
    <Autocomplete
      fullWidth
      multiple
      disableCloseOnSelect
      value={selected}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      filterOptions={(x) => x}
      loading={isLoading}
      getOptionLabel={(option) => `${option.first_name} ${option.last_name} ${option.id}`}
      options={options}
      renderInput={(params) => <TextField {...params} label='Add Learners' />}
      disabled={locked}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disablePortal
      renderTags={() => null}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
            checkedIcon={<CheckBoxIcon fontSize='small' />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              {option.first_name} {option.last_name}
            </div>
            <div style={{ color: 'gray' }}> {option.email}</div>
          </div>
        </li>
      )}
    />
  );
};

export default AutocompleteLearners;
