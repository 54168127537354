import React, { ChangeEvent, Dispatch, SetStateAction, useContext, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { LessonPart as LessonPartModel, TypeD1bEnum } from '@juno/client-api/model';
import { LessonPart as LessonPartUtils } from '@juno/client-api/utils';
import { TINY_MCE_DEFAULT_CONFIG } from '@juno/constants';
import { uploadTinyMceImageCloudinary } from '@juno/utils';
import VideoUploadButton from '../components/VideoUploadButton';

interface LessonPartFormProps {
  selectedPart: LessonPartModel | undefined;
  setSelectedPart: Dispatch<SetStateAction<LessonPartModel | undefined>>;
  type: TypeD1bEnum;
  isSaving?: boolean;
  videoUrlError?: boolean;
  onVideoUploadStarted?: () => void;
  onVideoUploaded?: (videoUrl: string) => void;
  uploadProgress?: number;
  setUploadProgress?: React.Dispatch<React.SetStateAction<number>>;
  isUploading?: boolean;
  setIsUploading?: React.Dispatch<React.SetStateAction<boolean>>;
  fileName?: string;
  setFileName?: (value: string) => void;
  setLaunchCount?: React.Dispatch<React.SetStateAction<number>>;
  fileUploadPromise?: any;
  setFileUploadPromise?: any;
  videoFileIsCanceled?: any;
  setVideoFileIsCanceled?: any;
}

export interface PayloadProps {
  title?: string;
  body?: string;
  video_url?: string;
  video_required_completion_percent?: number;
}

const LessonPartForm: React.FC<LessonPartFormProps> = ({
  selectedPart,
  setSelectedPart,
  type,
  isSaving,
  videoUrlError = false,
  onVideoUploadStarted,
  onVideoUploaded,
  uploadProgress,
  setUploadProgress,
  isUploading,
  setIsUploading,
  setLaunchCount,
  setFileName,
  fileUploadPromise,
  setFileUploadPromise,
  videoFileIsCanceled,
  setVideoFileIsCanceled,
}) => {
  const handleUpdate = (key: string, val: unknown) => {
    setSelectedPart({ ...selectedPart, [key]: val } as any);
  };
  const changeHandler = {
    tinymcetextarea: (key: string, value: string) => handleUpdate(key, value),
    text: ({ target }: ChangeEvent<HTMLInputElement>) => {
      handleUpdate(target.name, target.value);
    },
    select: ({ target }: SelectChangeEvent) => {
      handleUpdate(target.name, target.value);
    },
  };

  return (
    <Box>
      <TextField
        disabled={isSaving}
        id='title'
        name='title'
        label='Title'
        fullWidth
        margin='dense'
        value={selectedPart?.title || ''}
        onChange={changeHandler.text}
      />
      {LessonPartUtils.isVideoType(type) && (
        <>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={4}>
              <VideoUploadButton
                uploadProgress={uploadProgress}
                setUploadProgress={setUploadProgress}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                setLaunchCount={setLaunchCount}
                setFileName={setFileName}
                onVideoUploaded={onVideoUploaded}
                onVideoUploadStarted={onVideoUploadStarted}
                fileUploadPromise={fileUploadPromise}
                setFileUploadPromise={setFileUploadPromise}
                videoFileIsCanceled={videoFileIsCanceled}
                setVideoFileIsCanceled={setVideoFileIsCanceled}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                disabled={isSaving}
                id='video_url'
                name='video_url'
                label='Video URL'
                fullWidth
                value={selectedPart?.video_url || ''}
                onChange={changeHandler.text}
                error={videoUrlError}
                helperText='*Supported video types: MP4 link, Vimeo, Wistia, and YouTube'
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>% Required to watch</InputLabel>
                <Select
                  disabled={isSaving}
                  id='video_required_completion_percent'
                  name='video_required_completion_percent'
                  labelId='video_required_completion_percent-label'
                  value={
                    selectedPart?.video_required_completion_percent === undefined ||
                    selectedPart?.video_required_completion_percent === null
                      ? '80'
                      : `${selectedPart?.video_required_completion_percent}`
                  }
                  label='% Required to watch'
                  onChange={changeHandler.select}
                >
                  <MenuItem value={'0'}>0%</MenuItem>
                  <MenuItem value={'10'}>10%</MenuItem>
                  <MenuItem value={'20'}>20%</MenuItem>
                  <MenuItem value={'30'}>30%</MenuItem>
                  <MenuItem value={'40'}>40%</MenuItem>
                  <MenuItem value={'50'}>50%</MenuItem>
                  <MenuItem value={'60'}>60%</MenuItem>
                  <MenuItem value={'70'}>70%</MenuItem>
                  <MenuItem value={'80'}>80%</MenuItem>
                  <MenuItem value={'90'}>90%</MenuItem>
                  <MenuItem value={'100'}>100%</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      {LessonPartUtils.isBlurbType(type) && (
        <FormControl margin='dense' fullWidth sx={{ mb: 2 }}>
          <Typography sx={{ mb: 1 }}>Description</Typography>
          <Editor
            apiKey={process.env.NX_TINY_MCE_API_KEY}
            onEditorChange={(value: string) => changeHandler.tinymcetextarea('body', value)}
            value={selectedPart?.body || ''}
            init={{
              ...TINY_MCE_DEFAULT_CONFIG,
              images_upload_handler: uploadTinyMceImageCloudinary,
              setup: (editor) => {
                editor.setProgressState(true);
              },
            }}
          />
        </FormControl>
      )}
    </Box>
  );
};

export default LessonPartForm;
