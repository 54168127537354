import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { CourseRequirement, Site } from '@juno/client-api/model';
import { JunoSpin } from '@juno/ui';
import AutocompletePrereqs from '../../CourseInfo/AutocompletePrereqs';
import AutoCompletePrereqContent, { PrereqContent } from './AutoCompletePrereqContent';

interface FormDialogProps {
  site: Site;
  open: boolean;
  onSave: (
    data: CourseRequirement,
    grantingContent: PrereqContent[],
    requiringContent: PrereqContent[],
  ) => void;
  onClose: () => void;
  onDelete: () => void;
  isLoading: boolean;
  isSaving: boolean;
  isNew?: boolean;
}

const FormDialog: React.FC<FormDialogProps> = ({
  site,
  open,
  onClose,
  onSave,
  onDelete,
  isSaving,
  isLoading,
}) => {
  const [input, setInput] = React.useState<string>('');

  const handleDialogSave = () => {
    formik.submitForm();
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      equivalents: [],
      grandingContent: [],
      requiringContent: [],
    },
    onSubmit: (values) => {
      console.log('values', values);

      const newPrereq: CourseRequirement = {
        id: '',
        site_id: site.id,
        name: values.name,
        equivalents: values.equivalents,
        num_course_grants: '0',
        num_course_prerequisites: '0',
      };
      formik.resetForm({
        values: { equivalents: [], name: '', grandingContent: [], requiringContent: [] },
      });
      setInput('');
      onSave(newPrereq, values.grandingContent, values.requiringContent);
    },
  });

  const onFormClose = () => {
    formik.resetForm({
      values: { equivalents: [], name: '', grandingContent: [], requiringContent: [] },
    });
    setInput('');
    onClose();
  };

  const isValid = !!formik.values.name;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    formik.setFieldValue('name', event.target.value);
  };

  if (isLoading) return <JunoSpin />;
  return (
    <Dialog open={open} onClose={onFormClose} maxWidth={'sm'} fullWidth>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        Create a Prerequisite
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ minHeight: 'fit-content' }}>
        <TextField
          disabled={isSaving}
          id='name'
          name='name'
          label='Prerequisite Title'
          fullWidth
          margin='dense'
          value={input}
          onChange={handleChange}
        />
        <AutocompletePrereqs
          siteId={site.id}
          label={'Equivalent(s)'}
          name={'equivalents'}
          formik={formik}
          chipIcon={<SyncAltRoundedIcon />}
          allowCreateNew={false}
          grantNewEquivalent={true}
        />
        {/* TODO: Implement this when steveo allows assigning prereqs to content from the create dialog */}
        {/* <Typography variant='body1' sx={{ mt: 2 }}>
          Assign prereq to content:
        </Typography> */}
        {/* <AutoCompletePrereqContent
          siteId={site.id}
          label={'As requirement'}
          name={'requiringContent'}
          formik={formik}
        />
        <AutoCompletePrereqContent
          siteId={site.id}
          label={'As grant'}
          name={'grantingContent'}
          formik={formik}
        /> */}
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', gap: '10px', mt: 3 }}>
          <Button disabled={isSaving} onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            disabled={!isValid}
            loading={isSaving}
            variant='contained'
            onClick={handleDialogSave}
          >
            Save
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
