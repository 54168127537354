// eslint-disable-next-line @typescript-eslint/no-empty-interface
import * as React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  ListSubheader,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Reorder } from 'framer-motion';

interface DragDropWaitlistProps {
  label: string;
  data: any[];
  locked: boolean;
  onDelete: (learnerId: string) => Promise<void>;
  isSiteAdmin: boolean;
  isWidget: boolean;
  handleUpdate: (waitList: any[]) => void;
  searchValue: string;
  setSearchValue: (value: string) => void;
  totalItems: number;
}

const DragDropWaitlist: React.FC<DragDropWaitlistProps> = ({
  label,
  data,
  locked,
  onDelete,
  isSiteAdmin,
  isWidget,
  handleUpdate,
  searchValue,
  setSearchValue,
  totalItems,
}) => {
  const [items, setItems] = React.useState<any[]>(data);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<any>(null);
  const [isDeleting, setIsDeleting] = React.useState(false);

  React.useEffect(() => {
    setItems(data);
  }, [data]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleDelete = async (learner: any) => {
    setIsDeleting(true);
    setOpen(false);
    await onDelete(learner.id);
    setIsDeleting(false);
    setSelected(null);
  };

  const handleRemove = (item: any) => {
    setSelected(item);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelected(null);
  };

  const handleClearInput = () => {
    setSearchValue('');
  };

  return (
    <Card>
      <CardHeader
        title={
          <TextField
            fullWidth
            value={searchValue}
            onChange={handleChange}
            placeholder='Search'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchValue !== '' && (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='clear input'
                    onClick={handleClearInput}
                    edge='end'
                    size='small'
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        }
      />
      <Divider />
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          height: '510px',
          overflowY: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
        subheader={
          <ListSubheader component='div' id='nested-list-subheader'>
            <Stack direction='row' justifyContent='space-between'>
              <Typography>{label}</Typography>
              <Typography>{`showing: ${items.length}/${totalItems}`}</Typography>{' '}
            </Stack>
          </ListSubheader>
        }
      >
        <Reorder.Group
          axis='y'
          values={items}
          onReorder={handleUpdate}
          layoutScroll
          style={{
            listStyleType: 'none',
            paddingLeft: 0,
            overflowY: 'auto',
            display: 'grid',
            gridTemplateRows: 'auto 1fr',
            gridTemplateColumns: 'minmax(0, 1fr)',
          }}
        >
          {items.map((item, index) => (
            <Reorder.Item
              key={item.id}
              value={item}
              style={{ position: 'relative' }}
              dragListener={(!locked && isSiteAdmin) || !isWidget}
            >
              <ListItem
                component='div'
                secondaryAction={
                  !locked && (
                    <IconButton
                      edge='end'
                      aria-label='delete'
                      onClick={(event) => handleRemove(item)}
                    >
                      {(!isDeleting || selected?.id !== item.id) && <ClearIcon />}
                      {isDeleting && selected?.id === item.id && (
                        <CircularProgress size={20} sx={{ mr: 0.5 }} />
                      )}
                    </IconButton>
                  )
                }
              >
                <ListItemText
                  primary={`${item.user.last_name}, ${item.user.first_name}`}
                  secondary={item.user.email}
                />
              </ListItem>
            </Reorder.Item>
          ))}
        </Reorder.Group>
      </List>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Remove Learner</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove {selected?.user?.first_name} {selected?.user?.last_name}{' '}
            from the course?
          </Typography>
          <Typography>Any course progress will be lost.</Typography>
          {label === 'Enrolled' && (
            <Typography sx={{ mt: 1 }}>
              The next learner on the waitlist will be enrolled.
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant='contained' onClick={() => handleDelete(selected)} autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default DragDropWaitlist;
