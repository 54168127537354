import React from 'react';
import styled from '@emotion/styled';
import { DeleteTwoTone } from '@mui/icons-material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import {
  Box,
  Card,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import { PreviewFile } from '@juno/client-api/fakeModel';
import { Download } from '@juno/client-api/model';
import { DeleteButton, VisuallyHiddenInput } from '@juno/ui';
import { truncateFileName, useSettings, validateSelectedFileSize } from '@juno/utils';
import { useBreakpoint } from '@juno/utils/hooks';

interface EditorActionMenuProps {
  previewMedia: PreviewFile[];
  setPreviewMedia: React.Dispatch<React.SetStateAction<PreviewFile[]>>;
  existingFiles: Download[] | undefined;
  newFiles: File[];
  setNewFiles: React.Dispatch<React.SetStateAction<File[]>>;
  setRemovedFiles: (file: Download) => void;
}
const EditorActionMenu: React.FC<EditorActionMenuProps> = ({
  previewMedia,
  setPreviewMedia,
  existingFiles,
  newFiles,
  setNewFiles,
  setRemovedFiles,
}) => {
  const [filesToShow, setFilesToShow] = React.useState<Download[]>(existingFiles ?? []);
  const { xs } = useBreakpoint();
  const { isApp } = useSettings();
  const attachImageRef = React.useRef<HTMLLIElement>(null);

  //TODO: lang?
  const BUTTON_MEDIATYPE_TAKEPICTURE = 'Take Picture';
  const BUTTON_MEDIATYPE_TAKEVIDEO = 'Take Video';
  const BUTTON_MEDIATYPE_CHOOSEPICTURE = 'Choose Picture from Library';
  const BUTTON_MEDIATYPE_CHOOSEVIDEO = 'Choose Video from Library';

  const processImageURI = async (imageData: string) => {
    fetch('data:image/jpeg;base64,' + imageData).then((res) => {
      res.blob().then((fileBlob) => {
        const filePreview = fileBlob as PreviewFile;
        filePreview.preview = URL.createObjectURL(fileBlob);
        filePreview.forceAppUpload = true;
        setPreviewMedia([filePreview]);
      });
    });
  };

  const takePicture = () => {
    const choosePicture_success = (imageData: string) => {
      processImageURI(imageData);
    };

    const choosePicture_error = (error: string) => {
      console.error(error);
    };

    const nav = navigator as CordovaNavigator;
    nav.camera.getPicture(choosePicture_success, choosePicture_error, {
      destinationType: Camera.DestinationType.DATA_URL,

      sourceType: Camera.PictureSourceType.CAMERA,
      saveToPhotoAlbum: false,
      correctOrientation: true,
      quality: 50,

      popoverOptions: {
        x: 300,
        y: 300,
        width: 100,
        height: 100,
        arrowDir: Camera.PopoverArrowDirection.ARROW_ANY,
        popoverWidth: 300,
        popoverHeight: 300,
      },
    });
  };

  const choosePicture = () => {
    const choosePicture_success = (imageData: string) => {
      processImageURI(imageData);
    };

    const choosePicture_error = (error: string) => {
      console.error(error);
    };

    const nav = navigator as CordovaNavigator;
    nav.camera.getPicture(choosePicture_success, choosePicture_error, {
      destinationType: Camera.DestinationType.DATA_URL,

      sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
      quality: 50,

      popoverOptions: {
        x: 300,
        y: 300,
        width: 100,
        height: 100,
        arrowDir: Camera.PopoverArrowDirection.ARROW_ANY,
        popoverWidth: 300,
        popoverHeight: 300,
      },
    });
  };

  const chooseOrTakeAppImage = () => {
    const aButtons: string[] = [];

    //chooseVideo
    aButtons.push(BUTTON_MEDIATYPE_TAKEPICTURE);
    aButtons.push(BUTTON_MEDIATYPE_CHOOSEPICTURE);

    const options = {
      androidTheme: 5, // default is THEME_TRADITIONAL
      title: 'Upload a photo',
      subtitle: '',
      buttonLabels: aButtons,
      androidEnableCancelButton: true,
      winphoneEnableCancelButton: true,
      addCancelButtonWithLabel: 'Cancel',
      destructiveButtonLast: true,
      position: [
        attachImageRef?.current ? attachImageRef.current.offsetLeft : 0,
        attachImageRef?.current ? attachImageRef.current.offsetTop : 0,
      ], // for iPad pass in the [x, y] position of the popover
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error: Window isn't typed
    window?.plugins?.actionsheet?.show(options, (buttonIndex: number) => {
      // remember button index starts at 1!
      const aValue = aButtons[buttonIndex - 1];
      if (aValue) {
        switch (aValue) {
          case BUTTON_MEDIATYPE_TAKEPICTURE:
            //TODO: App.INSTANCE.trackEvent('Write Post', 'Take Pic Attachment', '');
            takePicture();
            break;

          case BUTTON_MEDIATYPE_CHOOSEPICTURE:
            //TODO: App.INSTANCE.trackEvent('Write Post', 'Choose Pic Attachment', '');
            choosePicture();
            break;

          case BUTTON_MEDIATYPE_CHOOSEVIDEO:
            //TODO: App.INSTANCE.trackEvent('Write Post', 'Choose Video Attachment', '');
            //TODO: self.chooseVideo();
            break;

          case BUTTON_MEDIATYPE_TAKEVIDEO:
            //TODO: App.INSTANCE.trackEvent('Write Post', 'Take Video Attachment', '');
            //TODO: self.takeVideo();
            break;
        }
      }
    });
  };

  return (
    <>
      {previewMedia && previewMedia.length > 0 && previewMedia[0]?.preview && (
        <ListItem>
          <Box position={'relative'} sx={{ width: '128px' }}>
            <Tooltip title={'Remove media'}>
              <DeleteButton
                onClick={() => setPreviewMedia([])}
                sx={{ position: 'absolute', top: '5px', cursor: 'pointer' }}
                aria-label='remove media'
              >
                <DeleteTwoTone color='primary' />
              </DeleteButton>
            </Tooltip>
            {previewMedia[0]?.type.search('image') >= 0 ? (
              <PreviewImage src={previewMedia[0]?.preview} alt='' />
            ) : (
              <PreviewVideo src={previewMedia[0]?.preview} />
            )}
          </Box>
        </ListItem>
      )}
      {filesToShow && filesToShow.length > 0 && (
        <ListItem>
          <Grid container spacing={1}>
            {filesToShow.map((file) => {
              return (
                <Grid item key={file.title}>
                  <Card
                    variant='outlined'
                    sx={{
                      justifyContent: 'space-between',
                      display: 'flex',
                      alignItems: 'center',
                      pl: 1,
                    }}
                  >
                    <Tooltip title={file.title} placement='top'>
                      <Typography component='span' sx={{ fontSize: '12px' }}>
                        {truncateFileName(file.title)}
                      </Typography>
                    </Tooltip>
                    <IconButton
                      component='span'
                      onClick={() => {
                        setFilesToShow(
                          filesToShow.filter((f) => f.id !== file.id || f.title !== file.title),
                        );
                        setNewFiles(newFiles.filter((f) => f.name !== file.title));
                        setRemovedFiles(file);
                      }}
                    >
                      <DeleteTwoTone sx={{ fontSize: '16px' }} color='primary' />
                    </IconButton>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </ListItem>
      )}
      <Box>
        <Grid container pt={2}>
          <Grid item xs={2} md={12}>
            <ListItem
              ref={attachImageRef}
              disablePadding
              onClick={() => {
                if (isApp) {
                  chooseOrTakeAppImage();
                }
              }}
            >
              <ListItemButton component='label' role={undefined} tabIndex={-1}>
                <ListItemIcon>
                  <ImageIcon />
                </ListItemIcon>
                {!xs && (
                  <ListItemText aria-label='Attach media' primary='Attach Media'></ListItemText>
                )}
                {!isApp && (
                  <VisuallyHiddenInput
                    type='file'
                    // TODO - need to remove this once we get videos working on the app
                    accept={isApp ? 'image/*,application/pdf' : '*'}
                    onChange={(event: any) => {
                      const file = event.target.files[0] as PreviewFile;
                      if (file && ACCEPTED_MEDIA_TYPES.includes(file.type)) {
                        if (validateSelectedFileSize(file)) {
                          file.preview = URL.createObjectURL(file);
                          setPreviewMedia([file]);
                        }
                      } else {
                        alert('File type not supported');
                      }
                    }}
                  />
                )}
              </ListItemButton>
            </ListItem>
          </Grid>
          {!isApp && (
            <Grid item xs={2} md={12}>
              <ListItem disablePadding>
                <ListItemButton component='label' role={undefined} tabIndex={-1}>
                  <ListItemIcon>
                    <AttachFileIcon />
                  </ListItemIcon>
                  {!xs && (
                    <ListItemText aria-label='Attach files' primary='Attach File'></ListItemText>
                  )}
                  <VisuallyHiddenInput
                    multiple
                    type='file'
                    // TODO - need to remove this once we get videos working on the app
                    accept={isApp ? 'image/*,application/pdf' : '*'}
                    onChange={(event: any) => {
                      const selectedFiles = event.target.files as File[];

                      // Check if any of the selected files are already in newFiles
                      const newFilesToAdd = Array.from(selectedFiles).filter((selectedFile) => {
                        return (
                          !newFiles.some(
                            (existingFile) => existingFile.name === selectedFile.name,
                          ) &&
                          !existingFiles?.some(
                            (existingFile) => existingFile.title === selectedFile.name,
                          )
                        );
                      });

                      // Create Download objects for the new files
                      const convertedFiles = newFilesToAdd.map((selectedFile) => {
                        return {
                          url: '',
                          title: selectedFile.name,
                          filetype: selectedFile.type,
                        };
                      }) as Download[];

                      // Update newFiles and filesToShow with the new files
                      setNewFiles([...newFiles, ...newFilesToAdd]);
                      setFilesToShow([...filesToShow, ...convertedFiles]);
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};
export default EditorActionMenu;

const ACCEPTED_MEDIA_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/tiff',
  'image/tif',
  'image/webp',
  'image/bmp',
  'image/svg',
  'video/mp4',
  'video/png',
  'video/quicktime',
  'video/mov',
];

export const PreviewMediaBox = styled(Box)(() => ({
  width: 'auto',
  height: 128,
  display: 'flex',
  backgroundColor: 'rgb(155,155,155,0.15)',
  borderRadius: 1,
  alignItems: 'center',
  justifyContent: 'center',
}));

export const PreviewImage = styled('img')(() => ({
  margin: '0',
  display: 'block',
  maxWidth: '128px',
  maxHeight: '100%',
}));

export const PreviewVideo = styled('video')(() => ({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
}));

interface CordovaNavigator extends Navigator {
  /**
   * This plugin provides an API for taking pictures and for choosing images from the system's image library.
   */
  camera: Camera;
}

/**
 * This plugin provides an API for taking pictures and for choosing images from the system's image library.
 */
interface Camera {
  /**
   * Removes intermediate photos taken by the camera from temporary storage.
   * @param onSuccess Success callback, that called when cleanup succeeds.
   * @param onError Error callback, that get an error message.
   */
  cleanup(onSuccess: () => void, onError: (message: string) => void): void;
  /**
   * Takes a photo using the camera, or retrieves a photo from the device's image gallery.
   * @param cameraSuccess Success callback, that get the image
   * as a base64-encoded String, or as the URI for the image file.
   * @param cameraError Error callback, that get an error message.
   * @param cameraOptions Optional parameters to customize the camera settings.
   */
  getPicture(
    cameraSuccess: (data: string) => void,
    cameraError: (message: string) => void,
    cameraOptions?: CameraOptions,
  ): void;
  // Next will work only on iOS
  //getPicture(
  //    cameraSuccess: (data: string) => void,
  //    cameraError: (message: string) => void,
  //    cameraOptions?: CameraOptions): CameraPopoverHandle;
}

interface CameraOptions {
  /** Picture quality in range 0-100. Default is 50 */
  quality?: number;
  /**
   * Choose the format of the return value.
   * Defined in navigator.camera.DestinationType. Default is FILE_URI.
   *      DATA_URL : 0,   Return image as base64-encoded string
   *      FILE_URI : 1,   Return image file URI
   */
  destinationType?: number;
  /**
   * Set the source of the picture.
   * Defined in navigator.camera.PictureSourceType. Default is CAMERA.
   *      PHOTOLIBRARY : 0,
   *      CAMERA : 1,
   *      SAVEDPHOTOALBUM : 2
   */
  sourceType?: number;
  /** Allow simple editing of image before selection. */
  allowEdit?: boolean;
  /**
   * Choose the returned image file's encoding.
   * Defined in navigator.camera.EncodingType. Default is JPEG
   *      JPEG : 0    Return JPEG encoded image
   *      PNG : 1     Return PNG encoded image
   */
  encodingType?: number;
  /**
   * Width in pixels to scale image. Must be used with targetHeight.
   * Aspect ratio remains constant.
   */
  targetWidth?: number;
  /**
   * Height in pixels to scale image. Must be used with targetWidth.
   * Aspect ratio remains constant.
   */
  targetHeight?: number;
  /**
   * Set the type of media to select from. Only works when PictureSourceType
   * is PHOTOLIBRARY or SAVEDPHOTOALBUM. Defined in nagivator.camera.MediaType
   *      PICTURE: 0      allow selection of still pictures only. DEFAULT.
   *          Will return format specified via DestinationType
   *      VIDEO: 1        allow selection of video only, WILL ALWAYS RETURN FILE_URI
   *      ALLMEDIA : 2    allow selection from all media types
   */
  mediaType?: number;
  /** Rotate the image to correct for the orientation of the device during capture. */
  correctOrientation?: boolean;
  /** Save the image to the photo album on the device after capture. */
  saveToPhotoAlbum?: boolean;
  /**
   * Choose the camera to use (front- or back-facing).
   * Defined in navigator.camera.Direction. Default is BACK.
   *      FRONT: 0
   *      BACK: 1
   */
  cameraDirection?: number;
  /** iOS-only options that specify popover location in iPad. Defined in CameraPopoverOptions. */
  popoverOptions?: CameraPopoverOptions;
}

/**
 * A handle to the popover dialog created by navigator.camera.getPicture. Used on iOS only.
 */
interface CameraPopoverHandle {
  /**
   * Set the position of the popover.
   * @param popoverOptions the CameraPopoverOptions that specify the new position.
   */
  setPosition(popoverOptions: CameraPopoverOptions): void;
}

/**
 * iOS-only parameters that specify the anchor element location and arrow direction
 * of the popover when selecting images from an iPad's library or album.
 */
interface CameraPopoverOptions {
  x: number;
  y: number;
  width: number;
  height: number;
  /**
   * Direction the arrow on the popover should point. Defined in Camera.PopoverArrowDirection
   * Matches iOS UIPopoverArrowDirection constants.
   *      ARROW_UP : 1,
   *      ARROW_DOWN : 2,
   *      ARROW_LEFT : 4,
   *      ARROW_RIGHT : 8,
   *      ARROW_ANY : 15
   */
  arrowDir: number;
  popoverWidth: number;
  popoverHeight: number;
}

declare const Camera: {
  // Camera constants, defined in Camera plugin
  DestinationType: {
    DATA_URL: number;
    FILE_URI: number;
  };
  Direction: {
    BACK: number;
    FRONT: number;
  };
  EncodingType: {
    JPEG: number;
    PNG: number;
  };
  MediaType: {
    PICTURE: number;
    VIDEO: number;
    ALLMEDIA: number;
  };
  PictureSourceType: {
    PHOTOLIBRARY: number;
    CAMERA: number;
    SAVEDPHOTOALBUM: number;
  };
  // Used only on iOS
  PopoverArrowDirection: {
    ARROW_UP: number;
    ARROW_DOWN: number;
    ARROW_LEFT: number;
    ARROW_RIGHT: number;
    ARROW_ANY: number;
  };
};
