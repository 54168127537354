import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { SaveBarWrapper } from './styles';

interface SaveBarProps {
  isDirty: boolean;
  isValid?: boolean;
  isSaving: boolean;
  onDiscard: () => void;
  onSave: () => void;
}

const SaveBar: React.FC<SaveBarProps> = ({
  isDirty,
  isValid = true,
  isSaving,
  onDiscard,
  onSave,
}) => {
  return (
    <SaveBarWrapper isDirty={isDirty}>
      <Button sx={{ width: '100%' }} variant='outlined' onClick={onDiscard}>
        Discard
      </Button>
      <LoadingButton
        sx={{ width: '100%' }}
        loading={isSaving}
        disabled={!isValid}
        variant='contained'
        type='submit'
        onClick={onSave}
      >
        Save
      </LoadingButton>
    </SaveBarWrapper>
  );
};

export default SaveBar;
