import React from 'react';
import { Box, Typography } from '@mui/material';
import { EMPTY_COURSE_URL } from '@juno/constants';
import { optimizeImage } from '@juno/utils';
import {
  CourseBannerContainer,
  CourseBannerContent,
  CourseBannerImage,
  CourseBannerLeftCol,
  CourseBannerPercent,
  CourseBannerRightCol,
  CourseBannerSteps,
} from './styles';

interface CourseProgressBannerProps {
  title: string;
  icon: string | undefined;
  step: number;
  totalSteps: number;
  percentComplete: number;
  onBack: () => void;
}

const CourseProgressBanner: React.FC<CourseProgressBannerProps> = ({
  title,
  icon,
  step,
  totalSteps,
  percentComplete,
  onBack,
}) => {
  return (
    <CourseBannerContainer onClick={onBack}>
      <CourseBannerContent>
        <CourseBannerLeftCol>
          <CourseBannerImage src={optimizeImage(150, icon || EMPTY_COURSE_URL)} variant='rounded' />
          <Box>
            <Typography>{title}</Typography>
            <CourseBannerSteps>{`Lesson ${step} of ${totalSteps}`}</CourseBannerSteps>
          </Box>
        </CourseBannerLeftCol>
        <CourseBannerRightCol>
          <CourseBannerPercent color='primary.contrastText'>{percentComplete}%</CourseBannerPercent>
        </CourseBannerRightCol>
      </CourseBannerContent>
    </CourseBannerContainer>
  );
};

export default CourseProgressBanner;
