import React from 'react';
import {
  DragIndicator as DragIndicatorIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import { useDebounce, useElementSize } from 'usehooks-ts';
import { EMPTY_COURSE_URL } from '@juno/constants';
import { optimizeImage } from '@juno/utils';
import {
  BottomIndicatorsWrapper,
  EllipsisTypography,
  SettingsButton,
  StyledFormImage,
  TileGridBanner,
  TileGridContainer,
  TileGridDragArea,
  TileGridTextWrapper,
  TileWrapper,
} from './styles';

interface ResourceTileProps {
  title?: string;
  subtitle?: string;
  footer?: React.ReactNode;
  footerAction?: React.ReactNode;
  imageUrl?: string;
  hideImage?: boolean;
  onSettingsSelect?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  sortable?: boolean;
  disabled?: boolean;
}
const ResourceTile: React.FC<ResourceTileProps> = ({
  title,
  subtitle,
  footer,
  footerAction,
  imageUrl,
  hideImage,
  onSettingsSelect,
  sortable = false,
  disabled = false,
}) => {
  const [imgRef, { width: textWidth }] = useElementSize();
  const debouncedTextWidth = useDebounce(textWidth, 200);

  return (
    <TileWrapper>
      <TileGridContainer container sx={{ mt: 2 }}>
        <Card sx={{ display: 'flex', width: '100%', boxShadow: 1 }}>
          {sortable && (
            <TileGridDragArea item>
              <DragIndicatorIcon />
            </TileGridDragArea>
          )}
          {!hideImage && (
            <TileGridBanner item xs={3} sx={{ m: 2 }}>
              <StyledFormImage
                variant='rounded'
                src={optimizeImage(450, imageUrl || EMPTY_COURSE_URL)}
                alt='Lesson Banner'
              />
            </TileGridBanner>
          )}
          <TileGridTextWrapper item xs={8} sx={{ m: 2 }} ref={imgRef}>
            <Box>
              <EllipsisTypography variant='h6' width={debouncedTextWidth}>
                {title}
              </EllipsisTypography>
              <EllipsisTypography variant='body2' width={debouncedTextWidth}>
                {subtitle}
              </EllipsisTypography>
            </Box>
            <BottomIndicatorsWrapper>{footer}</BottomIndicatorsWrapper>
          </TileGridTextWrapper>
        </Card>
      </TileGridContainer>
      <SettingsButton onClick={(e) => onSettingsSelect && onSettingsSelect(e)} disabled={disabled}>
        <SettingsIcon />
      </SettingsButton>
      <Box sx={{ position: 'absolute', right: 0, bottom: 0, m: 1 }}>{footerAction}</Box>
    </TileWrapper>
  );
};

export default ResourceTile;
