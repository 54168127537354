import * as React from 'react';
import {
  Code as CodeIcon,
  OndemandVideo as OndemandVideoIcon,
  Quiz as QuizIcon,
} from '@mui/icons-material';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import {
  Avatar,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import { LessonPart as LessonPartUtils } from '@juno/client-api/utils';

export interface LessonPartTypeDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (value: number) => void;
}

const TYPE_LIST = [
  { ...LessonPartUtils.LESSON_PART_TYPES.QUESTION, icon: <QuizIcon /> },
  { ...LessonPartUtils.LESSON_PART_TYPES.VIDEO, icon: <OndemandVideoIcon /> },
  { ...LessonPartUtils.LESSON_PART_TYPES.BLURB, icon: <CodeIcon /> },
  { ...LessonPartUtils.LESSON_PART_TYPES.RESOURCE, icon: <BrowserUpdatedIcon /> },
];

const LessonPartTypeDialog: React.FC<LessonPartTypeDialogProps> = ({ onSelect, onClose, open }) => {
  const handleListItemClick = (value: number) => {
    onSelect(value);
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Select a lesson part type</DialogTitle>
      <List sx={{ pt: 0 }}>
        {TYPE_LIST.map(({ icon, value, label }) => (
          <ListItem button onClick={() => handleListItemClick(value)} key={value}>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}>
                {icon}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default LessonPartTypeDialog;
