import React from 'react';
import { Button, Color } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getContentRoute, useSettings } from '@juno/utils';
import type { ModuleButtonModel } from './form';
import { ColorEnum } from './form';

const JunoModuleButton: React.FC<ModuleButtonModel> = ({ to, text, color }) => {
  const navigate = useNavigate();
  const { site } = useSettings();
  const colorString =
    color === ColorEnum.primary ? 'primary' : color === ColorEnum.secondary ? 'secondary' : 'info';

  const route =
    typeof to === 'string' ? to : getContentRoute(site?.slug || '', to?.slug || '', to?.type || '');

  if (!route || !text) {
    return <></>;
  }

  const handleClick = () => {
    if (typeof to === 'string') {
      window.open(route, '_blank');
    } else {
      navigate(route);
    }
  };

  return (
    <Button onClick={handleClick} color={colorString} variant='contained' sx={{ mt: 2 }}>
      {text || 'dsadf'}
    </Button>
  );
};

export default JunoModuleButton;
