import { TabItemObject } from '../index';
import {
  SortByOptions,
  SortOrderOptionToAscendingParam,
  SortOrderOptionToDescendingParam,
  SortOrderOptions,
} from './utils';

/**
 *  Sort Content Logic
 */

export const sortOptionToObjectField = (option: string) => {
  switch (option) {
    case SortByOptions.Alphabetical:
      return 'course_name';
    case SortByOptions.Newest:
      return 'newest';
    case SortByOptions.StartDate:
      return 'start_date';
    default:
      return '';
  }
};

export const interpolateSortOption = (tab: TabItemObject | undefined) => {
  if (!tab || !tab.order) return sortOptionToObjectField(SortByOptions.StartDate);
  if (tab.temporarySort) return sortOptionToObjectField(tab.temporarySort);
  return sortOptionToObjectField(tab.preSortName || SortByOptions.StartDate);
};

export const sortContent = (
  selectedSortByOption: string,
  selectedSortOrder: string,
  isTemporary?: boolean,
) => {
  // Set order param based on selectedSortOrder
  if (!isTemporary) {
    return selectedSortOrder === SortOrderOptions.Ascending
      ? SortOrderOptionToAscendingParam.get(selectedSortByOption) ?? ''
      : SortOrderOptionToDescendingParam.get(selectedSortByOption) ?? '';
  }
};
