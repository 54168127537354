import React from 'react';
import { Box, Skeleton } from '@mui/material';
import { Container } from '@juno/ui';

export const ProfilePageSkeleton = () => {
  return (
    <Box sx={{ width: '100%', height: '100vh', mt: 2 }}>
      <Container>
        {/* Profile Header Skeleton */}
        <Skeleton variant='rectangular' width='100%' height='20vh' />

        {/* About Section Skeleton */}
        <Skeleton
          variant='rectangular'
          width='100%'
          height='10vh'
          sx={{ mt: 2, borderRadius: '10px' }}
        />

        {/* Social Links Skeleton */}
        <Skeleton
          variant='rectangular'
          width='100%'
          height='5vh'
          sx={{ mt: 2, borderRadius: '10px' }}
        />

        {/* Experience Section Skeleton */}
        <Skeleton
          variant='rectangular'
          width='100%'
          height='20vh'
          sx={{ mt: 2, borderRadius: '10px' }}
        />

        {/* Education Section Skeleton */}
        <Skeleton
          variant='rectangular'
          width='100%'
          height='10vh'
          sx={{ mt: 2, borderRadius: '10px' }}
        />

        {/* Skills and Interests Section */}
        <Box sx={{ display: 'flex', mt: 2 }}>
          <Skeleton
            variant='rectangular'
            width='48%'
            height='10vh'
            sx={{ borderRadius: '10px', mr: 2 }}
          />
          <Skeleton variant='rectangular' width='50%' height='10vh' sx={{ borderRadius: '10px' }} />
        </Box>
      </Container>
    </Box>
  );
};
