import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { useGetComponent } from '@juno/client-api';
import {
  FeatureConfig as FeatureConfigModel,
  NavigationItem as NavigationItemModel,
} from '@juno/client-api/model';
import { FeatureConfig as FeatureConfigUtils, NavigationMap } from '@juno/client-api/utils';
import { JunoSpin } from '@juno/ui';
import Blurb from '../Blurb';
import { BottomNavButton, BottomNavContent, BottomNavIcon, FooterBlurb } from './styles';

const getLink = (url: string | undefined) => {
  if (!url) return '';
  if (url.startsWith('http')) {
    return url;
  }
  return `http://${url}`;
};

const getLocalPath = (url: string | undefined) => {
  return `/${url?.replace(/^\/+/g, '')}`;
};

interface BottomToolbarContentProps {
  handleMoreButtonClick?: () => void;
  bottomNavItems: NavigationItemModel[];
  selectedBottomNavIds: string[];
  style?: React.CSSProperties;
}

const getIconClass = (iconUrl: string | undefined) => {
  if (!iconUrl) return '';
  // If copied directly from fontawesome.com get the class names from the tag
  return iconUrl.match(/"(.*?)"/)?.[1] || iconUrl;
};

const BottomToolbarContent: React.FC<BottomToolbarContentProps> = ({
  handleMoreButtonClick,
  bottomNavItems,
  selectedBottomNavIds,
  style,
}) => {
  const navigate = useNavigate();
  return (
    <BottomNavContent showLabels style={{ ...style }}>
      <BottomNavButton
        onClick={handleMoreButtonClick}
        disableRipple={true}
        label='More'
        icon={<BottomNavIcon className='fa-solid fa-bars-staggered' />}
      />
      {bottomNavItems.map((item) => {
        return (
          <BottomNavButton
            key={item.id}
            disableRipple={true}
            label={item.text || ''}
            isSelected={selectedBottomNavIds.includes(item.id)}
          />
        );
      })}
    </BottomNavContent>
  );
};

export interface FooterProps {
  siteId: string;
  configs: FeatureConfigModel[];
  onMoreButtonClick?: () => void;
  showSideMenu?: boolean;
  style?: React.CSSProperties;
}

const Footer: React.FC<FooterProps> = ({
  siteId,
  configs,
  style,
  onMoreButtonClick,
  showSideMenu,
}) => {
  const footerComponentId = useMemo(() => {
    const themeConfig = FeatureConfigUtils.getThemeConfig(configs);
    return (themeConfig?.config?.footerComponentId || '') as string;
  }, [configs]);
  const { data: component, isLoading } = useGetComponent(siteId, footerComponentId);
  const isSmallScreen = useMediaQuery('(max-width: 1024px)');
  const isRelative = style?.position === 'relative';

  if (isLoading) return <JunoSpin />;

  return (
    <>
      <FooterBlurb
        showSideMenu={showSideMenu}
        isBottomToolbarShowing={isSmallScreen && !isRelative}
        style={{ ...style }}
      >
        {component && <Blurb html={component?.data?.html} />}
      </FooterBlurb>
    </>
  );
};

export default Footer;
