import Axios, { AxiosRequestConfig } from 'axios';

// TODO: Parameterize!
export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.NX_API_URL,
});

export const localInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error: Property 'cancel' does not exist on type 'Promise<T>'
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};
