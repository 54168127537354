import React, { ChangeEvent, ReactNode, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { GenericDateInput } from '@juno/ui';
import { DateFilter } from '@juno/ui';
import {
  RelativeTimeFrameOptions,
  UTCtoLocalString,
  findBeginningOrEndOfDay,
  getBeginningOfDay,
  getEndOfDay,
  interpolateRelativeTimeFrame,
} from '@juno/utils';
import { BeforeAfterBetweenOptions, DateTypeOptions, TimeRange } from './utils';

interface DatePreFilterProps {
  dateFilter: DateFilter;
  removeDateFilter: (idx: number) => void;
  dateFilters?: DateFilter[];
  setDateFilters?: React.Dispatch<React.SetStateAction<DateFilter[]>>;
  setDateFilterStringIsChanged: React.Dispatch<React.SetStateAction<boolean>>;
  dateTypeOptions: DateTypeOptions[];
}

const DatePreFilter: React.FC<DatePreFilterProps> = ({
  dateFilter,
  removeDateFilter,
  dateFilters,
  setDateFilters,
  setDateFilterStringIsChanged,
  dateTypeOptions,
}) => {
  const [dateType, setDateType] = useState(dateFilter.dateType);
  const [timeRange, setTimeRange] = useState(dateFilter.timeRange);
  const [specific, setSpecific] = useState(dateFilter.timeRange === TimeRange.Specific);
  const [previous, setPrevious] = useState(dateFilter.timeRange === TimeRange.Previous);
  const [previousDateRange, setPreviousDateRange] = useState<number>(
    dateFilter.previousUpcomingDateRange,
  );
  const [relative, setRelative] = useState(dateFilter.timeRange === TimeRange.Relative);
  const [relativeTimeFrame, setRelativeTimeFrame] = useState(dateFilter.relativeTimeFrame);
  const [upcoming, setUpcoming] = useState(dateFilter.timeRange === TimeRange.Upcoming);
  const [upcomingDateRange, setUpcomingDateRange] = useState<number>(
    dateFilter.previousUpcomingDateRange,
  );
  const [beforeAfterBetween, setBeforeAfterBetween] = useState(
    dateFilter.beforeAfterBetween || BeforeAfterBetweenOptions.Before,
  );
  const [beforeAfterDate, setBeforeAfterDate] = useState<string>(dateFilter.beforeAfterDate);
  const [fromDate, setFromDate] = useState<string>(dateFilter.fromDate);
  const [toDate, setToDate] = useState<string>(dateFilter.toDate);

  // Update the array of Date Pre Filters
  const updateDateFilter = (idx: number, key?: string, newValue?: string) => {
    if (!dateFilters || dateFilters.length === 0) return;
    // Find this dateFilter in the full array of dateFilters
    const dateFilterIndex = dateFilters?.findIndex((df) => df.idx === idx);
    // Create a copy of the dateFilter array
    const updatedDateFilterArray = [...dateFilters];
    if (dateFilterIndex === -1) return;
    // And a copy of the dateFilter
    const updatedDateFilter = { ...updatedDateFilterArray[dateFilterIndex] };
    // Update a specific key, or both toDate and fromDate keys
    if (key && newValue) {
      updatedDateFilter[key] = newValue;
      // Fix impossible defaults
      // This addresses the edge cases when the user chooses End Date or Release Date with Relative and the default time frame
      if (
        (updatedDateFilter['dateType'] === DateTypeOptions.EndDate ||
          updatedDateFilter['dateType'] === DateTypeOptions.ReleaseDate) &&
        updatedDateFilter['timeRange'] === TimeRange.Relative &&
        updatedDateFilter['relativeTimeFrame'] === RelativeTimeFrameOptions.Today
      ) {
        updatedDateFilter['relativeTimeFrame'] =
          updatedDateFilter['dateType'] === DateTypeOptions.EndDate
            ? RelativeTimeFrameOptions.NextMonth
            : RelativeTimeFrameOptions.LastMonth;
        const [fromDate, toDate] = interpolateRelativeTimeFrame(
          updatedDateFilter['relativeTimeFrame'],
        );
        updatedDateFilter['fromDate'] = fromDate;
        updatedDateFilter['toDate'] = toDate;
      }
    } else {
      updatedDateFilter['fromDate'] = fromDate;
      updatedDateFilter['toDate'] = toDate;
    }
    // Replace
    updatedDateFilterArray[dateFilterIndex] = updatedDateFilter;
    // Send it
    return updatedDateFilterArray;
  };

  const updateInputState = (newValue: string, inputName: string) => {
    switch (inputName) {
      case 'dateType':
        setDateType(newValue);
        // Switch RelativeTimeFrame if dateType is 'End Date'
        if (
          newValue === DateTypeOptions.EndDate &&
          ![
            RelativeTimeFrameOptions.NextWeek,
            RelativeTimeFrameOptions.NextMonth,
            RelativeTimeFrameOptions.NextYear,
          ].includes(relativeTimeFrame as RelativeTimeFrameOptions)
        ) {
          setRelativeTimeFrame(RelativeTimeFrameOptions.NextMonth);
        }
        // Switch RelativeTimeFrame if dateType is 'Release Date'
        if (
          newValue === DateTypeOptions.ReleaseDate &&
          ![
            RelativeTimeFrameOptions.LastWeek,
            RelativeTimeFrameOptions.LastMonth,
            RelativeTimeFrameOptions.LastYear,
          ].includes(relativeTimeFrame as RelativeTimeFrameOptions)
        ) {
          setRelativeTimeFrame(RelativeTimeFrameOptions.LastMonth);
        }
        break;
      case 'timeRange':
        setTimeRange(newValue);
        if (newValue === TimeRange.Specific) {
          setSpecific(true);
          setPrevious(false);
          setRelative(false);
          setUpcoming(false);
          setBeforeAfterBetween(BeforeAfterBetweenOptions.Before);
        } else if (newValue === TimeRange.Previous) {
          setPrevious(true);
          setSpecific(false);
          setRelative(false);
          setUpcoming(false);
          setBeforeAfterBetween('');
        } else if (newValue === TimeRange.Relative) {
          setRelative(true);
          setSpecific(false);
          setPrevious(false);
          setUpcoming(false);
          setBeforeAfterBetween('');
        } else {
          setUpcoming(true);
          setSpecific(false);
          setPrevious(false);
          setRelative(false);
          setBeforeAfterBetween('');
        }
        break;
      case 'relativeTimeFrame':
        setRelativeTimeFrame(newValue);
        break;
      case 'beforeAfterBetween':
        setBeforeAfterBetween(newValue);
        break;
      case 'beforeAfterDate':
        setBeforeAfterDate(findBeginningOrEndOfDay(newValue, inputName, beforeAfterBetween));
        break;
      case 'fromDate':
        setFromDate(findBeginningOrEndOfDay(newValue, inputName, beforeAfterBetween));
        break;
      case 'toDate':
        setToDate(findBeginningOrEndOfDay(newValue, inputName, beforeAfterBetween));
        break;
      default:
        break;
    }
  };

  // Record individual UI Date filter field updates
  const handleChange = (event: SelectChangeEvent<string>, name: string) => {
    // Tell the modal that a change has been made
    setDateFilterStringIsChanged(true);
    // Record the change
    updateInputState(event.target.value, name);
    // Update the full array of dateFilters
    if (dateFilter && setDateFilters) {
      const newValue =
        (name === 'beforeAfterDate' || name === 'fromDate' || name === 'toDate') &&
        event.target.value !== ''
          ? findBeginningOrEndOfDay(event.target.value, name, beforeAfterBetween)
          : event.target.value;
      setDateFilters(() => updateDateFilter(dateFilter.idx, name, newValue) || []);
    }
  };

  const handlePreviousUpcomingInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    previousOrUpcoming: string,
  ) => {
    const newValue = Number(event.target.value);
    dateFilter['previousUpcomingDateRange'] = newValue;
    switch (previousOrUpcoming) {
      case TimeRange.Previous:
        setPreviousDateRange(newValue);
        break;
      case TimeRange.Upcoming:
        setUpcomingDateRange(newValue);
        break;
      default:
        break;
    }
  };

  const getRelativeTimeFrameRenderOptions = (dateType: string) => {
    switch (dateType) {
      case DateTypeOptions.EndDate:
        return [
          RelativeTimeFrameOptions.NextWeek,
          RelativeTimeFrameOptions.NextMonth,
          RelativeTimeFrameOptions.NextYear,
        ];
      case DateTypeOptions.ReleaseDate:
        return [
          RelativeTimeFrameOptions.LastWeek,
          RelativeTimeFrameOptions.LastMonth,
          RelativeTimeFrameOptions.LastYear,
        ];
      default:
        return Object.values(RelativeTimeFrameOptions).map((option) => option as string);
    }
  };

  const handleEndDateMinMax = (minOrMax: string, beforeOrAfter: string, dateType: string) => {
    if (dateType === DateTypeOptions.EndDate && minOrMax === 'min') {
      return getBeginningOfDay(new Date()).toISOString().slice(0, 16);
    } else if (dateType === DateTypeOptions.ReleaseDate && minOrMax === 'max') {
      return getEndOfDay(new Date()).toISOString().slice(0, 16);
    }
    return undefined;
  };

  return (
    <FormControl sx={{ mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControl fullWidth sx={{ ml: 6, mb: 2, mt: 3 }} variant='filled'>
          <InputLabel>Date Type</InputLabel>
          <Select
            fullWidth
            value={dateType}
            onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
              handleChange(event, 'dateType');
            }}
            disableUnderline={true}
          >
            <MenuItem value='' disabled></MenuItem>
            {dateTypeOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton
          sx={{
            mr: 2,
            ml: 4,
          }}
          onClick={() => {
            removeDateFilter(dateFilter.idx);
          }}
        >
          <ClearIcon />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', pr: 11 }}>
        <FormControl sx={{ ml: 6 }} variant='filled'>
          <InputLabel>Time Range</InputLabel>
          <Select
            value={timeRange}
            onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
              handleChange(event, 'timeRange');
            }}
            displayEmpty
            sx={{ width: 170 }}
            disableUnderline={true}
          >
            <MenuItem value='' disabled></MenuItem>
            <MenuItem value={TimeRange.Specific}>Specific</MenuItem>
            {dateType !== DateTypeOptions.EndDate && (
              <MenuItem value={TimeRange.Previous}>Previous</MenuItem>
            )}
            <MenuItem value={TimeRange.Relative}>Relative</MenuItem>
            {dateType !== DateTypeOptions.ReleaseDate && (
              <MenuItem value={TimeRange.Upcoming}>Upcoming</MenuItem>
            )}
          </Select>
        </FormControl>
        {specific && (
          <FormControl variant='filled' sx={{ ml: 2 }}>
            <InputLabel>Range</InputLabel>
            <Select
              value={beforeAfterBetween}
              onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
                handleChange(event, 'beforeAfterBetween');
              }}
              sx={{ width: 120 }}
              displayEmpty
              disableUnderline
            >
              {Object.values(BeforeAfterBetweenOptions).map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {previous && (
          <FormControl fullWidth={false} sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
            <TextField
              type='number'
              id='previousDateRange'
              name='previousDateRange'
              value={previousDateRange >= 1 ? previousDateRange : ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setDateFilterStringIsChanged(true);
                handlePreviousUpcomingInputChange(event, TimeRange.Previous);
              }}
              label={previousDateRange === 1 ? 'Day' : 'Days'}
              sx={{ width: 90 }}
              disabled={false}
              inputProps={{ min: 1 }}
              InputProps={{ disableUnderline: true }}
              variant='filled'
            />
          </FormControl>
        )}
        {relative && (
          <FormControl variant='filled' sx={{ ml: 2 }}>
            <InputLabel>Value</InputLabel>
            <Select
              value={relativeTimeFrame}
              onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
                handleChange(event, 'relativeTimeFrame');
              }}
              sx={{ width: 150 }}
              displayEmpty
              disableUnderline
            >
              {Object.values(RelativeTimeFrameOptions)
                .filter((option) => {
                  const optionsToRender = getRelativeTimeFrameRenderOptions(dateType);
                  return optionsToRender.includes(option);
                })
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
        {upcoming && (
          <FormControl fullWidth={false} sx={{ ml: 2 }}>
            <TextField
              type='number'
              id='upcomingDateRange'
              name='upcomingDateRange'
              label={upcomingDateRange === 1 ? 'Day' : 'Days'}
              value={upcomingDateRange >= 1 ? upcomingDateRange : ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setDateFilterStringIsChanged(true);
                handlePreviousUpcomingInputChange(event, TimeRange.Upcoming);
              }}
              disabled={false}
              variant='filled'
              inputProps={{ min: 1 }}
              InputProps={{ disableUnderline: true }}
              sx={{ width: 90 }}
            />
          </FormControl>
        )}
        {(beforeAfterBetween === BeforeAfterBetweenOptions.Before ||
          beforeAfterBetween === BeforeAfterBetweenOptions.After) &&
          specific && (
            <FormControl sx={{ ml: 2 }}>
              <GenericDateInput
                label={'Date'}
                name={'beforeAfterDate'}
                value={beforeAfterDate ? UTCtoLocalString(new Date(beforeAfterDate)) : ''}
                onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
                  handleChange(event, 'beforeAfterDate');
                }}
                sx={{
                  width: 240,
                }}
                fullWidth={false}
                inputProps={{
                  min: handleEndDateMinMax('min', beforeAfterBetween, dateType),
                  max: handleEndDateMinMax('max', beforeAfterBetween, dateType),
                }}
                variant='filled'
                InputProps={{ disableUnderline: true }}
              />
            </FormControl>
          )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', pl: 6 }}>
        {beforeAfterBetween === BeforeAfterBetweenOptions.Between && (
          <>
            <FormControl>
              <GenericDateInput
                label={'From'}
                name={'fromDate'}
                value={fromDate ? UTCtoLocalString(new Date(fromDate)) : ''}
                onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
                  handleChange(event, 'fromDate');
                }}
                sx={{ mb: 2, mt: 2, mr: dateFilters?.length === 1 ? 2 : 1, width: 240 }}
                fullWidth={false}
                inputProps={{
                  min: handleEndDateMinMax('min', beforeAfterBetween, dateType),
                  max: handleEndDateMinMax('max', beforeAfterBetween, dateType),
                }}
                InputProps={{ disableUnderline: true }}
                variant='filled'
              />
            </FormControl>
            <FormControl>
              <GenericDateInput
                label={'To'}
                name={'toDate'}
                value={toDate ? UTCtoLocalString(new Date(toDate)) : ''}
                onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
                  handleChange(event, 'toDate');
                }}
                sx={{
                  mb: 2,
                  mt: 2,
                  width: 240,
                  mr: dateFilters && dateFilters.length > 1 ? undefined : 7,
                }}
                fullWidth={false}
                inputProps={{
                  min: handleEndDateMinMax('min', beforeAfterBetween, dateType),
                  max: handleEndDateMinMax('max', beforeAfterBetween, dateType),
                }}
                InputProps={{ disableUnderline: true }}
                variant='filled'
              />
            </FormControl>
          </>
        )}
      </Box>
    </FormControl>
  );
};

export default DatePreFilter;
