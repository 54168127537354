/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type SidebarWidgetTypeEnum = typeof SidebarWidgetTypeEnum[keyof typeof SidebarWidgetTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SidebarWidgetTypeEnum = {
  quick_links: 'quick_links',
  groups: 'groups',
  courses: 'courses',
  sessions: 'sessions',
  tags: 'tags',
} as const;
