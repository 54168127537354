import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, ClickAwayListener } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SearchContentTypeEnum, Thread } from '@juno/client-api/model';
import { UserQuickView } from '@juno/ui';
import { getContentRoute, useSettings } from '@juno/utils';

const MAX_CONTENT_HEIGHT = 200;
interface AnnouncementTileProps {
  announcement: Thread;
  goToAnnouncement: (threadId: string) => Promise<void>;
}
const AnnouncementTile: React.FC<AnnouncementTileProps> = ({ announcement, goToAnnouncement }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef<HTMLElement | null>(null);
  const [userQuickViewAnchorEl, setUserQuickViewAnchorEl] = useState<null | HTMLElement>(null);
  const [userQuickViewUserId, setUserQuickViewUserId] = useState<string>('');
  const [postHeight, setPostHeight] = useState<string | number>(MAX_CONTENT_HEIGHT);
  const { platform, site } = useSettings();
  const dialogRef = useRef<HTMLDivElement>(null);
  const navigateRoute = useNavigate();

  const clickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement;
    const userId = target.getAttribute('data-mention-id');
    if (userId) {
      e.stopPropagation();
      setUserQuickViewAnchorEl(target);
      setUserQuickViewUserId(userId);
    }
  };
  useEffect(() => {
    const container = contentRef.current;
    const content = container?.querySelector('.content') as HTMLElement | null;

    if (container && content) {
      if (content.scrollHeight > container.clientHeight) {
        content.style.webkitBoxOrient = 'vertical';
        content.style.display = '-webkit-box';
        content.style.webkitLineClamp = isExpanded ? 'unset' : '7'; // 7 lines is just around 180px tall
      }
      setPostHeight(content.scrollHeight);
    }
  }, [isExpanded]);
  return (
    <>
      <Box
        maxHeight={isExpanded ? 'auto' : MAX_CONTENT_HEIGHT}
        overflow={'hidden'}
        textOverflow={'ellipsis'}
        ref={contentRef}
      >
        <div
          className='content'
          onClick={clickHandler}
          dangerouslySetInnerHTML={{
            __html: announcement.body as unknown as string,
          }}
        ></div>
        {Boolean(userQuickViewAnchorEl) && (
          <ClickAwayListener onClickAway={() => setUserQuickViewAnchorEl(null)}>
            <UserQuickView
              platformId={platform?.id || ''}
              anchorEl={userQuickViewAnchorEl}
              userId={userQuickViewUserId}
              open={Boolean(userQuickViewAnchorEl)}
              onViewProfile={(userId: string): void => {
                navigateRoute(getContentRoute(site?.id || '', userId, SearchContentTypeEnum.user));
              }}
              onMessageUser={function (userId: string): void {
                throw new Error('Function not implemented.');
              }}
              dialogRef={dialogRef}
            />
          </ClickAwayListener>
        )}
      </Box>
      {(typeof postHeight === 'string' || postHeight >= MAX_CONTENT_HEIGHT) && (
        <Box>
          <Button
            size='small'
            variant='text'
            aria-label='See more'
            color='primary'
            sx={{ mt: 1 }}
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded((prevExpanded) => !prevExpanded);
            }}
          >
            {isExpanded ? 'See Less' : 'See More'}
          </Button>
        </Box>
      )}
    </>
  );
};
export default AnnouncementTile;
