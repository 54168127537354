import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { FormikProps } from 'formik';
import { TypeBd0Enum as campaignType } from '@juno/client-api/model';
import { CAMPAIGN_EMAIL_FROM_SUFFIX } from '@juno/constants';
import { GenericFormikInput } from '@juno/ui';
import { EmailCampaignType, EmailCampaignTypeMap, getUserPreferenceValue } from '../../utils/utils';
import { NewCampaignSectionFormikValues } from '../../utils/validationSchema';

interface NewCampaignSectionProps {
  formik: FormikProps<NewCampaignSectionFormikValues>;
  updateNewCampaignSectionValues: React.Dispatch<
    React.SetStateAction<NewCampaignSectionFormikValues>
  >;
  isActive: boolean;
}

const NewCampaignSection: React.FC<NewCampaignSectionProps> = ({
  formik,
  updateNewCampaignSectionValues,
  isActive,
}) => {
  return (
    <Box sx={{ border: '1px dashed', borderRadius: '10px', p: 3 }}>
      <Typography variant='h6' sx={{ mb: 2 }}>
        NEW CAMPAIGN
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <GenericFormikInput
              label='Campaign Title'
              type='text'
              name='name'
              variant='filled'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldTouched('name', true);
                formik.handleChange(event);
                updateNewCampaignSectionValues({ ...formik.values, name: event.target.value });
              }}
              error={formik.touched.name && formik.errors.name}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>Campaign Type</InputLabel>
            <Select
              id='type'
              disabled={isActive}
              label='Campaign Type'
              {...formik.getFieldProps('type')}
              onChange={(event: SelectChangeEvent) => {
                const newCampaignType = event.target.value as keyof typeof campaignType;
                updateNewCampaignSectionValues({ ...formik.values, type: newCampaignType });
                formik.handleChange(event);
              }}
            >
              {Object.entries(EmailCampaignTypeMap).map(([key, value], index: number) => (
                <MenuItem key={index} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth={false}>
            <GenericFormikInput
              label='Subscription Group'
              type='text'
              name='subscriptionGroup'
              value={getUserPreferenceValue(formik.values.type, 'N/A')}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth={false}>
            <GenericFormikInput
              label='From (Name)'
              type='text'
              name='from_name'
              variant='filled'
              disabled={isActive}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldTouched('from_name', true);
                formik.handleChange(event);
                updateNewCampaignSectionValues({ ...formik.values, from_name: event.target.value });
              }}
              error={formik.touched.from_name && formik.errors.from_name}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth={false}>
            <GenericFormikInput
              label='From (Email)'
              type='text'
              name='from_email'
              variant='filled'
              disabled={isActive}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldTouched('from_email', true);
                formik.handleChange(event);
                updateNewCampaignSectionValues({
                  ...formik.values,
                  from_email: event.target.value,
                });
              }}
              InputProps={
                formik.values.from_email
                  ? {
                      endAdornment: (
                        <InputAdornment position='end'>{CAMPAIGN_EMAIL_FROM_SUFFIX}</InputAdornment>
                      ),
                    }
                  : undefined
              }
              sx={{ '& .MuiInputAdornment-root p': { mt: '18px' } }}
              error={formik.touched.from_email && formik.errors.from_email}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth={false}>
            <GenericFormikInput
              label='Subject'
              type='text'
              name='subject'
              variant='filled'
              disabled={isActive}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldTouched('from_email', true);
                formik.handleChange(event);
                updateNewCampaignSectionValues({ ...formik.values, subject: event.target.value });
              }}
              error={formik.touched.subject && formik.errors.subject}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewCampaignSection;
