import { LoadingButton } from '@mui/lab';
import { Avatar, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Breakpoints } from '@juno/utils/hooks';

export const DescriptionText = styled(Typography)({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '1.43',
});

export const LessonButtonSectionBox = styled(Box)(({ theme }) => ({
  'body.cordova &': { paddingBottom: '400px' },
}));

export const ButtonContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'sm' && prop !== 'md' && prop !== 'lg' && prop !== 'isPrevButton',
})<{
  xs: boolean;
  isPrevButton?: boolean;
}>(({ xs, isPrevButton }) => ({
  display: 'flex',
  flexDirection: xs ? 'column' : 'row',
  justifyContent: isPrevButton ? 'left' : 'right',
  flexFlow: 'row-reverse',
  alignItems: 'center',
  marginTop: xs ? '30px' : '45px',
  marginBottom: xs ? '48px' : '64px',
  marginLeft: isPrevButton ? '0px' : '10px',
  marginRight: isPrevButton ? '10px' : '0px',
}));

export const LessonNavigationButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  '&:hover': { backgroundColor: theme.palette.primary.main },
}));

export const DetailImage = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'imageWidth',
})<{
  imageWidth: number;
}>(({ imageWidth }) => ({
  width: '100%',
  height: `${imageWidth * (9 / 16)}px`,
  objectFit: 'cover',
}));
