import React, { useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { FormikProps } from 'formik';
import { useGetSendGridTemplates } from '@juno/client-api';
import { Template as TemplateModel, TypeBd0Enum as campaignType } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import { EmailCampaignTypeToTemplateMap } from '../../utils/utils';
import { SidebarSectionFormikValues } from '../../utils/validationSchema';

interface AutocompleteTemplateProps {
  formik: FormikProps<SidebarSectionFormikValues>;
  emailCampaignType?: campaignType;
  emailCampaignTypeChanged?: campaignType | '';
  setTemplate: React.Dispatch<React.SetStateAction<TemplateModel | undefined>>;
}

const AutocompleteTemplate: React.FC<AutocompleteTemplateProps> = ({
  formik,
  emailCampaignType,
  emailCampaignTypeChanged,
  setTemplate,
}) => {
  // Variables
  const { site } = useSettings();
  const [open, setOpen] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<TemplateModel[]>([]);
  const [selected, setSelected] = React.useState<TemplateModel>(
    formik.values.template || { id: '', base_id: '', version_id: '', version_name: '' },
  );
  const [input, setInput] = React.useState<string>('');

  // Queries and mutations
  const { data: templates, isLoading: templatesLoading } = useGetSendGridTemplates(site?.id || '', {
    query: { enabled: !!site?.id },
  });

  // Functions

  // Only show the users templates that can be used for their chosen campaign type
  useEffect(() => {
    if (!templatesLoading && templates) {
      if (!emailCampaignType) {
        setOptions(templates);
      } else {
        const campaignSpecificTemplates = templates.filter((template) =>
          emailCampaignType !== campaignType.CUSTOM
            ? template.version_name === EmailCampaignTypeToTemplateMap[emailCampaignType]
            : template.version_name.startsWith('custom_template'),
        );

        setOptions(campaignSpecificTemplates);
        if (campaignSpecificTemplates.length === 1 && setTemplate) {
          setSelected(campaignSpecificTemplates[0]);
          setTemplate(campaignSpecificTemplates[0]);
        }
      }
    }
  }, [templates]);

  // Notice when the user changes the campaign type
  useEffect(() => {
    let campaignSpecificTemplates: TemplateModel[] = [];
    const filterTemplates = (type: (typeof campaignType)[keyof typeof campaignType]) => {
      if (!templates) return [];
      return templates.filter(
        type === campaignType.CUSTOM
          ? (template) => template.version_name.startsWith('custom_template')
          : (template) => template.version_name === EmailCampaignTypeToTemplateMap[type],
      );
    };

    if (emailCampaignType && templates) {
      if (emailCampaignTypeChanged) {
        campaignSpecificTemplates = filterTemplates(emailCampaignTypeChanged);
      } else if (!emailCampaignTypeChanged) {
        campaignSpecificTemplates = filterTemplates(emailCampaignType);
      }

      // If user switched the campaign type, update the options
      if (emailCampaignTypeChanged) {
        setOptions(campaignSpecificTemplates);
      }

      // Point the drop-down selection to the first template in the list
      setSelected(campaignSpecificTemplates[0]);
      setTemplate(campaignSpecificTemplates[0]);
    }
  }, [emailCampaignTypeChanged, emailCampaignType, templates]);

  const handleSetSelected = (template: TemplateModel) => {
    formik.setFieldValue('template', template);
    setSelected(template);
    setTemplate && setTemplate(template);
  };

  useEffect(() => {
    // if options change we need to update our template vals that matched
    const template = formik.values.template;
    const option = options.filter((option) => option.id === formik.values?.template?.id)[0];
    const combined = { ...template, ...option };
    formik.setFieldValue('template', combined);
  }, [options]);

  if (!options) {
    return null;
  }

  return (
    <Autocomplete
      id='asynchronous-demo'
      sx={{ mt: 2 }}
      disabled={formik.values.is_active || false}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(event, newInputValue) => {
        setInput(newInputValue);
      }}
      onChange={(event: React.SyntheticEvent<Element, Event>, value: TemplateModel | null) => {
        const template = options?.find((template) => {
          return template.version_name === value?.version_name;
        });
        if (template) {
          handleSetSelected(template);
        }
        return;
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => (option.version_name ? option.version_name : '')}
      options={options || []}
      loading={templatesLoading}
      value={selected}
      inputValue={input}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Templates'
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {templatesLoading && <CircularProgress color='inherit' size={20} />}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteTemplate;
