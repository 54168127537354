import React, { useState } from 'react';

type CoursesPanelContextProps = {
  isFileUploading: boolean;
  setIsFileUploading: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultCoursesPanelContext: CoursesPanelContextProps = {
  isFileUploading: false,
  setIsFileUploading: () => {},
};

export const CoursesPanelContext = React.createContext<CoursesPanelContextProps>(
  defaultCoursesPanelContext,
);
