import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { Reorder, animate, motion, useMotionValue } from 'framer-motion';
import { useDebounce } from 'usehooks-ts';
import { useSearchContent } from '@juno/client-api';
import { InternalLinkObject, QuickLink, SearchContentTypeEnum } from '@juno/client-api/model';
import { DialogAriaWrapper, IconPicker, JunoIcon } from '@juno/ui';
import { useSettings } from '@juno/utils';

const NavItemSchema = Yup.object().shape({
  text: Yup.string().required('Text is required'),
  link: Yup.string().when('internal', {
    is: false,
    then: Yup.string()
      .max(128, 'URL must not exceed 128 characters')
      .test('title-valid-url', 'URL must begin with "http://" or "https://"', function test(value) {
        if (value && value.length > 0) {
          return value.startsWith('http://') || value.startsWith('https://');
        } else {
          return true;
        }
      })
      .required('Destination is required'),
  }),
  object_id: Yup.string()
    .nullable()
    .when('internal', {
      is: true,
      then: Yup.string().required('Destination is required'),
    }),
});

interface QuickLinksProps {
  formik: FormikProps<QuickLink[]>;
  setPageDirty2: Dispatch<SetStateAction<boolean>>;
}

const DUMMY_QUICK_LINK = {
  id: '',
  text: '',
  internal: true,
  link: '',
  order: 0,
  object_id: '',
} as QuickLink;

const QuickLinks: React.FC<QuickLinksProps> = ({ formik, setPageDirty2 }) => {
  const { site } = useSettings();
  const [currentLink, setCurrentLink] = useState<QuickLink>(DUMMY_QUICK_LINK);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { isSubmitting, dirty, setValues, values } = formik;
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 300);
  const { data: searchData, isFetching } = useSearchContent(
    site?.id || '',
    { search_term: debouncedSearch || '' },
    { query: { enabled: !!search } },
  );

  useEffect(() => {
    setPageDirty2(dirty);
  }, [dirty]);

  const handleRemove = (order: number) => {
    setValues(
      values.filter((item) => item.order !== order).map((item, i) => ({ ...item, order: i + 1 })),
    );
  };
  const handleReorder = (newOrder: QuickLink[]) => {
    setValues(newOrder.map((item, i) => ({ ...item, order: i + 1 })));
  };

  const height = useMotionValue(64 * values.length);

  useEffect(() => {
    animate(height, 64 * values.length, { type: 'tween', duration: 0.3 });
  }, [values.length]);

  const mapContentType = (type: SearchContentTypeEnum) => {
    switch (type) {
      case SearchContentTypeEnum.page:
        return 'content | page';
      case SearchContentTypeEnum.course:
        return 'learning | course';
      case SearchContentTypeEnum.group:
        return 'community | communitygroup';
      case SearchContentTypeEnum.user:
        return 'user | user';
      case SearchContentTypeEnum.library:
        return 'content | page';
      default:
        return 'content | page';
    }
  };

  return (
    <Form>
      <Card sx={{ p: 2, mt: 2, mb: 4 }}>
        <Typography variant='h5'>Quick Links</Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant='body2' sx={{ mb: 4 }}>
          These are links that will appear at the top of the left sidebar for every user. Add /
          remove / edit / reorder them as you see fit.
        </Typography>
        <Stack direction='row' justifyContent={'space-between'}>
          <Box minWidth='50%'>
            <Button onClick={() => setDialogOpen(true)} variant='contained' sx={{ mb: 2 }}>
              Add Link
            </Button>
            <motion.div style={{ height }}>
              <Reorder.Group
                axis='y'
                values={values}
                onReorder={handleReorder}
                as='div'
                style={{ listStyleType: 'none' }}
              >
                {values
                  .sort((a, b) => (a.order || 0) - (b.order || 0))
                  .map((item, index) => (
                    <Reorder.Item value={item} key={`${item.id || item.text}`}>
                      <Stack
                        direction='row'
                        alignItems='center'
                        sx={{
                          border: '1px solid rgba(0,0,0,.1)',
                          mt: 1.25,
                          mb: 1.25,
                          paddingX: 2,
                          paddingY: 1,
                          background: 'rgba(0,0,0,.02)',
                          borderRadius: 2,
                          cursor: 'grab',
                          '&:hover': {
                            background: 'rgba(0,0,0,.04)',
                          },
                        }}
                        justifyContent='space-between'
                      >
                        <Stack direction='row' alignItems='center'>
                          <Box width={50}>
                            {item.icon && <JunoIcon name={item.icon} />}
                            {!item.icon && item.internal && item.internal_link_object?.icon && (
                              <Avatar
                                sx={{ height: 30, width: 30, mr: 1 }}
                                src={item.internal_link_object?.icon || ''}
                                variant='rounded'
                              />
                            )}{' '}
                          </Box>
                          <Stack>
                            <Typography variant='subtitle1' sx={{ ml: 1 }}>
                              {item.text}
                            </Typography>
                            <Typography variant='subtitle2' sx={{ ml: 1 }}>
                              {item.internal_link_object?.slug
                                ? `/${item.internal_link_object?.slug}`
                                : item.link}
                            </Typography>
                          </Stack>
                        </Stack>

                        <Stack direction='row'>
                          <IconButton
                            onClick={() => {
                              setCurrentLink(item);
                              setDialogOpen(true);
                            }}
                            size='small'
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleRemove(item.order || -1)} size='small'>
                            <CloseIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </Reorder.Item>
                  ))}
              </Reorder.Group>
            </motion.div>
          </Box>
          <Box>
            <Typography variant='subtitle1' sx={{ mt: 2.5, mb: 1 }}>
              What users will see:
            </Typography>
            <Card sx={{ width: 274 }}>
              <List>
                <ListItem sx={{ fontWeight: 'bold', fontSize: '.95rem' }}>Quick Links</ListItem>
                {values
                  ?.sort((a, b) => (a.order || 0) - (b.order || 0))
                  .map((link) => (
                    <ListItemButton key={link.id} onClick={() => {}}>
                      <ListItemIcon>
                        {link.icon && <JunoIcon name={link.icon} />}
                        {!link.icon && link.internal && link.internal_link_object?.icon && (
                          <Avatar
                            sx={{ height: 30, width: 30 }}
                            src={link.internal_link_object?.icon || ''}
                            variant='rounded'
                          />
                        )}
                      </ListItemIcon>
                      <ListItemText>{link.text}</ListItemText>
                    </ListItemButton>
                  ))}
              </List>
            </Card>
          </Box>
        </Stack>
        <Box textAlign='right'>
          <LoadingButton
            loading={isSubmitting}
            variant='contained'
            type='submit'
            disabled={!dirty}
            sx={{ mt: 3 }}
          >
            Save
          </LoadingButton>
        </Box>
        <Formik
          initialValues={currentLink}
          validationSchema={NavItemSchema}
          onSubmit={async (formValues, { setSubmitting, resetForm }) => {
            // we're editing
            if (currentLink.text) {
              setValues((old) =>
                old.map((item) => (item.text === currentLink.text ? formValues : item)),
              );
            }
            // we're creating
            else {
              setValues((old) => [...old, { ...formValues, order: old.length + 1 }]);
            }
            await setCurrentLink(DUMMY_QUICK_LINK);
            setDialogOpen(false);
            resetForm();
          }}
          enableReinitialize
        >
          {({
            dirty: editFormDirty,
            values: formValues,
            setValues: setFormValues,
            handleSubmit: handleSubmitFormValues,
            errors,
            touched,
            setTouched,
            resetForm,
            isValid,
          }) => {
            return (
              <DialogAriaWrapper open={dialogOpen} fullWidth>
                <Box p={3}>
                  <Typography variant='subtitle1'>
                    {currentLink?.text ? 'Edit' : 'Create'} Quick Link
                  </Typography>
                </Box>
                <DialogContent>
                  <Grid container spacing={4}>
                    <Grid item sm={6}>
                      <Typography variant='subtitle1'>Link Text</Typography>
                      <Typography variant='subtitle2'>
                        This is the text that will show in the button (required).
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        value={formValues?.text}
                        size='small'
                        label='Link Text'
                        name={'text'}
                        fullWidth
                        onChange={(e) => {
                          setTouched({ ...touched, text: true });
                          setFormValues((old) => ({ ...old, text: e.target.value }));
                        }}
                        error={touched.text && !!errors.text}
                        helperText={touched.text && errors.text}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant='subtitle1'>Destination</Typography>
                      <Typography variant='subtitle2'>
                        The destination of the link. To create an internal link, search for Pages,
                        Courses, Groups, Users, or Library content. To create an external link that
                        will open in a new tab, uncheck internal and enter a url.
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Stack direction='row' width='100%' alignItems='flex-start'>
                        <Stack sx={{ flex: 1, flexGrow: 1 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formValues.internal || false}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setFormValues((old) => ({
                                      ...old,
                                      internal: e.target.checked,
                                      link: '',
                                      search_content_type: undefined,
                                    }));
                                  } else {
                                    setFormValues((old) => ({
                                      ...old,
                                      internal: e.target.checked,
                                      content_type: '',
                                      object_id: null,
                                      search_content_type: undefined,
                                    }));
                                  }
                                }}
                              />
                            }
                            label='Internal'
                            sx={{ width: 160, mb: 1 }}
                          />
                          {formValues.internal && formValues?.object_id && (
                            <Card
                              sx={{
                                p: 2,
                                boxShadow: 1,
                                display: 'flex',
                                background: 'rgba(0,0,0,.03)',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Stack direction='row' alignItems='center'>
                                {formValues?.internal_link_object?.icon && (
                                  <Avatar
                                    src={formValues?.internal_link_object?.icon}
                                    variant='rounded'
                                    sx={{ mr: 2, height: 30, width: 30 }}
                                  />
                                )}
                                <Typography variant='subtitle1'>
                                  {formValues?.internal_link_object?.title}
                                </Typography>
                              </Stack>
                              <IconButton
                                size='small'
                                onClick={() =>
                                  setFormValues({
                                    ...formValues,
                                    object_id: null,
                                    internal_link_object: {} as InternalLinkObject,
                                  })
                                }
                              >
                                <CloseIcon />
                              </IconButton>
                            </Card>
                          )}
                          {formValues.internal && !formValues?.object_id && (
                            <Autocomplete
                              options={searchData || []}
                              getOptionLabel={(option) => option?.title}
                              size='small'
                              loading={isFetching}
                              value={
                                searchData?.find((item) => item.id === formValues.object_id) || null
                              }
                              noOptionsText={search ? 'No results found' : 'Search to find options'}
                              renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                  <Avatar src={option.icon} variant='rounded' sx={{ mr: 2 }} />
                                  <Box>
                                    <Typography>{option.title}</Typography>
                                    <Typography variant='subtitle2'>{option.type}</Typography>
                                  </Box>
                                </li>
                              )}
                              onChange={(e, newValue) => {
                                setTouched({ ...touched, content_type: true });
                                if (!newValue) {
                                  setFormValues((old) => ({
                                    ...old,
                                    object_id: null,
                                    content_type: '',
                                    internal_link_object: undefined,
                                    search_content_type: undefined,
                                  }));
                                } else {
                                  setFormValues((old) => ({
                                    ...old,
                                    object_id: newValue?.id || '',
                                    content_type: mapContentType(newValue?.type || ''),
                                    internal_link_object: {
                                      slug: newValue?.slug || '',
                                      title: newValue?.title || '',
                                      icon: newValue?.icon || '',
                                    },
                                    search_content_type: newValue.type,
                                  }));
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label='Search destinations'
                                  InputProps={{ ...params.InputProps }}
                                  onChange={(e) => setSearch(e.target.value)}
                                  error={touched.content_type && !!errors.content_type}
                                  helperText={touched.content_type && errors.content_type}
                                />
                              )}
                            />
                          )}
                          {!formValues.internal && (
                            <TextField
                              value={formValues?.link}
                              size='small'
                              label='Link URL'
                              onChange={(e) => {
                                setTouched({ ...touched, link: true });
                                setFormValues((old) => ({ ...old, link: e.target.value }));
                              }}
                              error={touched.link && !!errors.link}
                              helperText={touched.link && errors.link}
                            />
                          )}
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant='subtitle1'>Icon</Typography>
                      <Typography variant='subtitle2'>
                        Choose an icon to accompany the link. If you set an icon for an internal
                        link that has a photo already, the icon will show instead of the photo.
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      {!formValues.icon && (
                        <IconPicker
                          onSelectIcon={(icon) => {
                            setTouched({ ...touched, icon: true });
                            setFormValues((old) => ({ ...old, icon: icon }));
                          }}
                        />
                      )}

                      {formValues.icon && (
                        <Card
                          sx={{
                            p: 2,
                            boxShadow: 1,
                            display: 'flex',
                            background: 'rgba(0,0,0,.03)',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <JunoIcon name={formValues.icon} />
                          <IconButton
                            size='small'
                            onClick={() =>
                              setFormValues({
                                ...formValues,
                                icon: '',
                              })
                            }
                          >
                            <CloseIcon />
                          </IconButton>
                        </Card>
                      )}
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      resetForm();
                      setDialogOpen(false);
                      setCurrentLink(DUMMY_QUICK_LINK);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleSubmitFormValues()}
                    variant={'contained'}
                    disabled={!editFormDirty || !isValid}
                  >
                    Save
                  </Button>
                </DialogActions>
              </DialogAriaWrapper>
            );
          }}
        </Formik>
      </Card>
    </Form>
  );
};

export default QuickLinks;
