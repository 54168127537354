import { Card, CardContent, Grid, Skeleton, Stack, Typography } from '@mui/material';

export const convertDurationToTime = (duration: number): string => {
  const hrs = Math.floor(duration / 3600);
  const mins = Math.floor((duration % 3600) / 60);
  const seconds = Math.floor(duration % 60);
  return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
};

export const ArchiveSkeleton = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Card sx={{ overflow: 'visible', boxShadow: 0 }}>
          <Typography variant={'overline'}>Participants</Typography>
          <CardContent>
            <Stack spacing={1}>
              <Skeleton variant='rectangular' height={55} />
              <Skeleton variant='rectangular' height={55} />
              <Skeleton variant='rectangular' height={55} />
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card sx={{ overflow: 'visible', boxShadow: 0 }}>
          <Skeleton variant='rectangular' height={280} />
        </Card>
      </Grid>
    </Grid>
  );
};
