import { Box, Select } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LabelWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const ControlContainer = styled(Box)(() => ({
  width: '100%',
  margin: '10px',
}));

export const AspectSelect = styled(Select)(() => ({
  width: '100%',
  height: 35,
}));
