import React, { useEffect, useState } from 'react';
import { EditNoteOutlined } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Card, Chip, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Tag } from '@juno/client-api/model';
import { AutocompleteTagsGeneric } from '@juno/ui';
import { useSettings } from '@juno/utils';
import { AnimatedIconButton } from './AnimatedIconButton';
import { useUserProfile, useUserProfileUpdate } from './UserProfileContext';

export const Skills = () => {
  const userProfile = useUserProfile();
  const updateUserProfile = useUserProfileUpdate();
  const [isEditing, setIsEditing] = useState(false);
  const [skills, setSkills] = useState(userProfile?.skills || []);
  const [initialSkills, setInitialSkills] = useState<Tag[]>([]);
  const [isEdited, setIsEdited] = useState(false);
  const settings = useSettings();
  const theme = useTheme();

  useEffect(() => {
    // Set the initial skills state when the component mounts
    setInitialSkills(userProfile?.skills || []);
    setSkills(userProfile?.skills || []);
    setIsEditing(false);
  }, [userProfile?.skills]);

  const handleIconClick = () => {
    if (isEditing && isEdited) {
      updateUserProfile({ ...userProfile, skills: skills });
      setInitialSkills(skills); // Update the initial state to the new saved state
    }
    setIsEditing(!isEditing);
    // Reset isEdited state when exiting editing mode
    if (!isEditing) {
      setIsEdited(false);
    }
  };

  const handleCancelEdit = () => {
    // Reset skills to initialSkills
    setSkills(initialSkills);

    // Exit edit mode
    setIsEditing(false);
    setIsEdited(false); // Reset isEdited state
  };

  if (skills.length === 0 && !userProfile.isMe) {
    return <div></div>;
  }

  return (
    <Box>
      {userProfile && (
        <Card sx={{ p: 3, mt: 2, position: 'relative' }}>
          {userProfile.isMe && (
            <Box sx={{ position: 'absolute', top: 0, right: 0, display: 'flex' }}>
              <Box>
                {!isEditing && (
                  <AnimatedIconButton onClick={handleIconClick} size='medium' animate={isEdited}>
                    <EditNoteOutlined color='disabled' />
                  </AnimatedIconButton>
                )}
              </Box>
              {isEditing && (
                <>
                  <IconButton onClick={handleCancelEdit} size='medium'>
                    <CancelIcon sx={{ color: theme.palette.error.main }} />
                  </IconButton>
                  {isEditing && userProfile.isMe && (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <IconButton onClick={handleIconClick} size='medium'>
                        <SaveIcon color={isEdited ? 'primary' : 'inherit'} />
                      </IconButton>
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}
          <Box mb={2}>
            <Typography variant='h6'>Skills</Typography>
          </Box>
          {isEditing ? (
            <AutocompleteTagsGeneric
              tags={skills}
              setTags={(tags) => {
                setSkills(tags);
                setIsEdited(JSON.stringify(tags) !== JSON.stringify(initialSkills));
              }}
              label=''
              siteId={settings.siteId}
            />
          ) : (
            <Stack direction='row' sx={{ my: 2, flexWrap: 'wrap', gap: '10px' }}>
              {skills.map((skill, index) => (
                <Chip
                  key={index}
                  label={skill.value}
                  sx={{ bgcolor: 'primary.main', color: 'primary.contrastText' }}
                />
              ))}
            </Stack>
          )}
        </Card>
      )}
    </Box>
  );
};
