import { useEffect } from 'react';
import { useParticipants } from '../../contexts/ParticipantsProvider';
import { VIEW_MODE_SPEAKER, useUIState } from '../../contexts/UIStateProvider';
import { GridView } from './GridView';
import { SpeakerView } from './SpeakerView';

export const VideoView: React.FC = () => {
  const { viewMode, setIsShowingScreenshare } = useUIState();
  const { participants, screens } = useParticipants();

  useEffect(() => {
    const hasScreens = screens.length > 0;
    setIsShowingScreenshare(hasScreens);
  }, [screens, setIsShowingScreenshare]);

  if (!participants.length) return null;
  return viewMode === VIEW_MODE_SPEAKER ? <SpeakerView /> : <GridView />;
};

export default VideoView;
