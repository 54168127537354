import React, { useEffect, useState } from 'react';

interface TextFieldProps {
  key: string;
  value: string;
}

type ValueInputGeneric = string | string[] | Date | boolean | TextFieldProps;

interface BindProps {
  value: ValueInputGeneric;
  onBlur: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | ValueInputGeneric,
  ) => void;
}

interface BindCheckboxProps {
  checked: boolean;
  onBlur: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface UseInputProps {
  value: ValueInputGeneric;
  setValue: (value: ValueInputGeneric) => void;
  reset: (value: ValueInputGeneric) => void;
  initialValue: ValueInputGeneric;
  isRequired: boolean;
  isDirty: boolean;
  isValid: boolean;
  touched: boolean;
  validate: () => boolean;
  bind: BindProps;
  bindCheckbox: BindCheckboxProps;
}

export interface UseInputParams {
  initialValue: ValueInputGeneric;
  isRequired?: boolean;
  validator?: ((value: ValueInputGeneric) => boolean) | undefined;
  formatter?: ((value: ValueInputGeneric) => ValueInputGeneric) | undefined;
}

export const useInput = ({
  initialValue,
  isRequired = false,
  validator = (val) => true,
  formatter = (val) => val,
}: UseInputParams): UseInputProps => {
  const [prevInitialValue, setPrevInitialValue] = useState(initialValue);
  const [prevValue, setPrevValue] = useState(initialValue);
  const [value, setValue] = useState(initialValue);
  const [isDirty, setIsDirty] = useState(false);
  // debounce setIsValid???
  const [isValid, setIsValid] = useState(true);
  const [touched, setTouched] = useState(false);

  const checkValidation = (val: ValueInputGeneric, justTouched: boolean) => {
    let valid = true;
    if (isRequired) {
      if (Array.isArray(val)) {
        valid = valid && val.length > 0;
      } else {
        valid = valid && !!val;
      }
    }
    valid = valid && validator(val);
    if (!touched && justTouched) {
      setTouched(true);
    }
    if (touched || justTouched) {
      setIsValid(valid);
    }
    return valid;
  };

  const validate = () => {
    return checkValidation(value, true);
  };

  const onReset = (resetValue: ValueInputGeneric) => {
    setValue(resetValue);
    setPrevValue(resetValue);
    setPrevInitialValue(resetValue);
    setIsDirty(false);
    setIsValid(true);
    setTouched(false);
  };

  useEffect(() => {
    if (initialValue !== prevInitialValue) {
      setPrevInitialValue(initialValue);
      setValue(formatter(initialValue));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const handleChange = (
    param: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | ValueInputGeneric,
  ) => {
    const paramVal = param as ValueInputGeneric;
    const evt = param as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
    const val = evt?.target?.value || paramVal;
    checkValidation(val, true);
    setValue(formatter(val));
    setIsDirty(prevValue !== val && val !== prevInitialValue);
  };

  return {
    value,
    setValue,
    isDirty,
    isValid,
    touched,
    reset: onReset,
    validate,
    initialValue,
    isRequired,
    bind: {
      value,
      onBlur: handleChange,
      onChange: handleChange,
    },
    bindCheckbox: {
      checked: value as boolean,
      onBlur: handleChange,
      onChange: handleChange,
    },
  };
};
