import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useDebounce } from 'usehooks-ts';
import { Component as ComponentModel } from '@juno/client-api/model';
import { buildGridSettings } from '@juno/ui';
import { TabItemObject } from '../index';
import AutocompleteTagTypes from './AutocompleteTagTypes';
import TabFilterDialog from './TabFilterDialog';
import TabList from './TabList';
import { FormControlRow } from './styles';

interface ModuleEditFormProps {
  component: ComponentModel;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
  siteId: string;
}

const SpeakersEditForm = ({ component, onSave, onUpdate, siteId }: ModuleEditFormProps) => {
  const [updatedComponent, setUpdatedComponent] = useState(component);
  const [payload, setPayload] = useState(component.data);
  const [isTabEditOpen, setIsTabEditOpen] = useState(false);
  const [tabEditing, setTabEditing] = useState<TabItemObject | undefined>();
  const debouncedPayload = useDebounce(payload, 200);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onUpdate({ ...component, data: debouncedPayload }), [debouncedPayload]);
  useEffect(() => {
    setPayload(component.data);
    setUpdatedComponent(component);
  }, [component]);

  const settingsObj = useMemo(() => buildGridSettings(payload), [payload]);

  const onChange = (key: string, val: unknown) => setPayload({ ...payload, [key]: val });
  const onComponentChange = (key: string, val: unknown) =>
    setUpdatedComponent({ ...updatedComponent, [key]: val });

  const changeHandler = {
    checkbox: ({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.name, target.checked),
    text: ({ target }: ChangeEvent<HTMLInputElement>) => onChange(target.name, target.value),
    number: ({ target }: ChangeEvent<HTMLInputElement>) =>
      onChange(target.name, parseInt(target.value) || 0),
    select: (type: string, val: string[]) => onChange(type, val),
  };

  const componentChangeHandler = {
    text: ({ target }: ChangeEvent<HTMLInputElement>) =>
      onComponentChange(target.name, target.value),
  };

  const handleUpdateTabCancel = async () => {
    setIsTabEditOpen(false);
    setTabEditing(undefined);
  };

  const handleTabSelected = (tabId: string) => {
    const tab = settingsObj.tabs.find((t: TabItemObject) => t.id === tabId);
    if (!tab) return;
    setIsTabEditOpen(true);
    setTabEditing(tab);
  };

  return (
    <>
      <Typography variant={'body2'} sx={{ opacity: 0.6 }}>
        Editing Module
      </Typography>
      <Typography variant='h5' sx={{ textTransform: 'capitalize', mb: 2 }}>
        {component?.type}
      </Typography>
      <FormControl fullWidth sx={{ mt: 1, mb: 2 }}>
        <TextField
          fullWidth
          id='name'
          name='name'
          label='Name'
          defaultValue={updatedComponent.name}
          onChange={componentChangeHandler.text}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        />
      </FormControl>
      <TabList
        tabs={settingsObj.tabs}
        onTabSelected={handleTabSelected}
        onTabUpdate={(tabs) => onChange('tabs', tabs)}
      />
      <Typography sx={{ mt: 2 }}>Design</Typography>
      <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              name='showTitle'
              checked={settingsObj.showTitle}
              onChange={changeHandler.checkbox}
            />
          }
          label='Show Title'
        />
        {settingsObj.showTitle && (
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            id='title'
            name='title'
            label='Title'
            defaultValue={settingsObj.title}
            onChange={changeHandler.text}
            InputProps={{ disableUnderline: true }}
            variant='filled'
          />
        )}
        <FormControlLabel
          control={
            <Checkbox
              name='showFiltersButton'
              checked={settingsObj.showFiltersButton}
              onChange={changeHandler.checkbox}
            />
          }
          label='Show Filter Options'
        />
        {settingsObj.dataModel === 'Course' && settingsObj.showFiltersButton && (
          <fieldset
            style={{
              marginBottom: '10px',
              borderColor: 'rgba(255,255,255,0.23)',
              borderRadius: '5px',
              width: '100%',
            }}
          >
            <FormControlLabel
              sx={{ display: 'block' }}
              control={
                <Checkbox
                  name='showFilterInstructors'
                  checked={settingsObj.showFilterInstructors}
                  onChange={changeHandler.checkbox}
                />
              }
              label='Instructors'
            />
            <FormControlLabel
              sx={{ display: 'block' }}
              control={
                <Checkbox
                  name='showFilterStartDates'
                  checked={settingsObj.showFilterStartDates}
                  onChange={changeHandler.checkbox}
                />
              }
              label='Start Date'
            />
            <Box sx={{ mt: 2 }}>
              <AutocompleteTagTypes
                label='Tag Types'
                siteId={siteId}
                values={settingsObj.filterTagTypes}
                onSelect={(itemIds) => changeHandler.select('filterTagTypes', itemIds)}
              />
            </Box>
          </fieldset>
        )}
        <FormControlLabel
          control={
            <Checkbox
              name='showSortSelect'
              checked={settingsObj.showSortSelect}
              onChange={changeHandler.checkbox}
            />
          }
          label='Show Sort Options'
        />
        {settingsObj.dataModel === 'Course' && settingsObj.showSortSelect && (
          <fieldset
            style={{
              marginBottom: '10px',
              borderColor: 'rgba(255,255,255,0.23)',
              borderRadius: '5px',
            }}
          >
            <FormControlLabel
              sx={{ width: '100%' }}
              control={
                <Checkbox
                  name='showSortCourseName'
                  checked={settingsObj.showSortCourseName}
                  onChange={changeHandler.checkbox}
                />
              }
              label='Alphabetical'
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={
                <Checkbox
                  name='showSortStartDate'
                  checked={settingsObj.showSortStartDate}
                  onChange={changeHandler.checkbox}
                />
              }
              label='Start Date'
            />
            <FormControlLabel
              sx={{ width: '100%' }}
              control={
                <Checkbox
                  name='showSortNewest'
                  checked={settingsObj.showSortNewest}
                  onChange={changeHandler.checkbox}
                />
              }
              label='Newest'
            />
            {/* Commenting out Most Popular option until it gets implemented */}
            {/*<FormControlLabel*/}
            {/*  sx={{ width: '100%' }}*/}
            {/*  control={*/}
            {/*    <Checkbox*/}
            {/*      name='showSortMostPopular'*/}
            {/*      checked={settingsObj.showSortMostPopular}*/}
            {/*      onChange={changeHandler.checkbox}*/}
            {/*    />*/}
            {/*  }*/}
            {/*  label='Most Popular'*/}
            {/*/>*/}
          </fieldset>
        )}
      </FormControl>
      <FormControlRow fullWidth>
        <TextField
          id='variant'
          name='variant'
          select
          label='Variant'
          value={settingsObj.variant}
          onChange={changeHandler.text}
          sx={{ width: '100%' }}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        >
          <MenuItem value='circular'>Circular</MenuItem>
          <MenuItem value='rounded'>Rounded</MenuItem>
          <MenuItem value='square'>Square</MenuItem>
        </TextField>
        <TextField
          id='textAlign'
          name='textAlign'
          select
          label='Text Align'
          value={settingsObj.textAlign}
          onChange={changeHandler.text}
          sx={{ width: '100%' }}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        >
          <MenuItem value='center'>center</MenuItem>
          <MenuItem value='left'>left</MenuItem>
          <MenuItem value='right'>right</MenuItem>
        </TextField>
      </FormControlRow>
      <FormControlRow fullWidth>
        <TextField
          fullWidth
          id='itemWidth'
          name='itemWidth'
          label='Item Width'
          type='number'
          value={settingsObj.itemWidth}
          onChange={changeHandler.number}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        />
        <TextField
          fullWidth
          id='itemHeight'
          name='itemHeight'
          label='Item Height'
          type='number'
          value={settingsObj.itemHeight}
          onChange={changeHandler.number}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        />
        <TextField
          fullWidth
          id='spacing'
          name='spacing'
          label='Spacing'
          type='number'
          value={settingsObj.spacing}
          onChange={changeHandler.number}
          InputProps={{ disableUnderline: true }}
          variant='filled'
        />
      </FormControlRow>
      <FormGroup sx={{ flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <FormControlLabel
          control={
            <Checkbox
              name='showItemTitle'
              checked={settingsObj.showItemTitle}
              onChange={changeHandler.checkbox}
            />
          }
          label='Item Title'
        />
        <FormControlLabel
          control={
            <Checkbox
              name='showItemDescription'
              checked={settingsObj.showItemDescription}
              onChange={changeHandler.checkbox}
            />
          }
          label='Item Description'
        />
        <FormControlLabel
          control={
            <Checkbox
              name='showProgress'
              checked={settingsObj.showProgress}
              onChange={changeHandler.checkbox}
            />
          }
          label='Show Progress'
        />
      </FormGroup>

      <TabFilterDialog
        siteId={siteId}
        tab={tabEditing}
        open={isTabEditOpen}
        handleClose={handleUpdateTabCancel}
        onChange={(tab: TabItemObject) => {
          const newTabs: TabItemObject[] = [];
          settingsObj?.tabs.forEach((t: TabItemObject) => {
            const selectedTab = t.id === tab.id ? tab : t;
            newTabs.push(selectedTab);
          });
          onChange('tabs', newTabs);
          handleUpdateTabCancel();
        }}
        onContentChosen={changeHandler.text}
        settings={settingsObj}
      />
    </>
  );
};

export default SpeakersEditForm;
