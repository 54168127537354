import React, { useEffect, useState } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { Form, Formik, FormikProps } from 'formik';
import { useDebounce } from 'usehooks-ts';
import { AlignmentEnum, JunoImage } from '@juno/client-api/fakeModel';
import { ComponentData, Component as ComponentModel } from '@juno/client-api/model';
import { DialogAriaWrapper, JunoImageUpload } from '@juno/ui';
import { optimizeImage } from '@juno/utils';
import ModuleButtonForm from '../ModuleButton/form';
import type { ModuleButtonModel } from '../ModuleButton/form';

interface SideBySideFormProps {
  component?: ComponentModel;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
  formik: FormikProps<SideBySideComponentModel>;
}

interface SideBySideFormWrapperProps {
  component?: ComponentModel;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
}

export interface SideBySideDataModel extends ComponentData {
  image: string;
  title: string;
  subtitle: string;
  parallax: boolean;
  alignment: AlignmentEnum;
  button: ModuleButtonModel;
  fullWidth: boolean;
}

export interface SideBySideComponentModel extends ComponentModel {
  data: SideBySideDataModel;
}

const SideBySideForm: React.FC<SideBySideFormProps> = ({ component, onUpdate, formik }) => {
  const { setValues, values } = formik;
  const [blurbModalOpen, setBlurbModalOpen] = useState(false);
  const debouncedValues = useDebounce(values, 700);
  useEffect(() => {
    if (values) {
      onUpdate({ ...component, ...debouncedValues });
    }
  }, [debouncedValues]);
  return (
    <Box pb={5}>
      <Typography variant='body2' sx={{ opacity: 0.6 }}>
        Editing Section
      </Typography>
      <Typography variant='h5' sx={{ mb: 4 }}>
        {values?.name}
      </Typography>
      <TextField
        value={values?.name}
        onChange={formik.handleChange}
        name='name'
        label='Name'
        variant='filled'
        fullWidth
        InputProps={{ disableUnderline: true }}
        sx={{ mb: 2 }}
      />
      <JunoImageUpload
        style={{
          aspectRatio: '16/9',
          height: 150,
          width: 250,
        }}
        src={optimizeImage(270, values?.data?.image || '')}
        srcUrl={values?.data?.image || ''}
        onFileUploaded={(selected: JunoImage | null) =>
          formik.setFieldValue('data.image', selected?.url || '')
        }
      />
      <Button onClick={() => setBlurbModalOpen(true)} sx={{ mt: 4 }}>
        <LaunchIcon sx={{ mr: 2 }} /> Large Editor
      </Button>
      <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
        <Editor
          apiKey={process.env.NX_TINY_MCE_API_KEY}
          value={formik.values.data.subtitle as string}
          onDirty={() => {}}
          init={{
            contextmenu: false,
            auto_focus: true,
            highlight_on_focus: true,
            placeholder: 'Add content here',
            height: 380,
            width: '100%',
            setup: (editor) => {
              editor.setProgressState(true);
            },
          }}
          plugins={'autolink emoticons link mentions media'}
          onEditorChange={(string) => formik.setFieldValue('data.subtitle', string)}
        />
      </FormControl>
      <Box display='flex'>
        <FormControl sx={{ mt: 3 }}>
          <FormLabel id='alignment_label' sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>
            Image Alignment
          </FormLabel>
          <RadioGroup
            aria-labelledby='alignment_label'
            defaultValue={AlignmentEnum.left}
            name='data.alignment'
            value={values?.data?.alignment}
            onChange={(e) => {
              setValues({
                ...values,
                data: { ...values.data, alignment: e.target.value as AlignmentEnum },
              });
            }}
          >
            <FormControlLabel value={AlignmentEnum.left} control={<Radio />} label='Left' />
            <FormControlLabel value={AlignmentEnum.right} control={<Radio />} label='Right' />
          </RadioGroup>
        </FormControl>
      </Box>
      <ModuleButtonForm
        component={component}
        formik={formik}
        onUpdate={onUpdate}
        includeAlignment={true}
      />
      <DialogAriaWrapper
        open={blurbModalOpen}
        fullWidth
        onClose={() => setBlurbModalOpen(false)}
        sx={{ p: 2 }}
        disableEnforceFocus={true}
      >
        <Editor
          apiKey={process.env.NX_TINY_MCE_API_KEY}
          value={formik.values.data.subtitle as string}
          onDirty={() => {}}
          init={{
            contextmenu: false,
            auto_focus: true,
            highlight_on_focus: true,
            placeholder: 'Add content here',
            height: 380,
            width: '100%',
            setup: (editor) => {
              editor.setProgressState(true);
            },
          }}
          plugins={'autolink emoticons link mentions media'}
          onEditorChange={(string) => formik.setFieldValue('data.subtitle', string)}
        />
      </DialogAriaWrapper>
    </Box>
  );
};

const SideBySideFormWrapper: React.FC<SideBySideFormWrapperProps> = ({
  component,
  onSave,
  onUpdate,
}) => {
  return (
    <Formik
      initialValues={(component as SideBySideComponentModel) || ({} as SideBySideComponentModel)}
      onSubmit={async (values, { setSubmitting, resetForm }) => {}}
      enableReinitialize
    >
      {(formik) => {
        return (
          <Form>
            <SideBySideForm
              component={component}
              onSave={onSave}
              onUpdate={onUpdate}
              formik={formik}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default SideBySideFormWrapper;
