import { useEffect, useState } from 'react';
import { TextField } from '@mui/material';

interface TimeDurationObject {
  hours: number;
  minutes: number;
  seconds: number;
  totalSeconds: number;
}
interface TimeDurationInputProps {
  label: string;
  value: number | null | undefined;
  onChange: (result: TimeDurationObject) => void;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

const TimeDurationInput: React.FC<TimeDurationInputProps> = ({
  label,
  value,
  onChange,
  ...inputProps
}) => {
  const [time, setTime] = useState<number | null | undefined>(value);

  useEffect(() => {
    setTime(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const value = Number(event.target.value.split(':').join(''));
      setTime(value);
      const [hours, minutes, seconds] = formatTime(value).split(':');
      const totalSeconds = Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
      onChange({
        hours: Number(hours),
        minutes: Number(minutes),
        seconds: Number(seconds),
        totalSeconds,
      });
    } catch (error) {
      return;
    }
  };

  const formatTime = (time: number | null | undefined): string => {
    // We use the subString method to get the second, minute, and hour values
    // from the time string. We then pad the values with 0s to ensure that
    // the time string is always in the format HH:MM:SS.
    if (!time) return '00:00:00';
    const timeString = time.toString();
    const seconds = timeString.substring(timeString.length - 2).padStart(2, '0');
    const minutes = timeString
      .substring(timeString.length - 4, timeString.length - 2)
      .padStart(2, '0');
    const hours = timeString.substring(0, timeString.length - 4).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  return (
    <TextField
      label={label}
      value={time}
      onChange={handleChange}
      inputProps={{ min: 0 }}
      variant='outlined'
      fullWidth
      InputProps={{
        value: formatTime(time),
        ...inputProps,
      }}
    />
  );
};

export default TimeDurationInput;
