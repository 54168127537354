import CertificateContextWrapper from './admin';

interface CertificateWidgetProps {
  siteId: string;
}

const CertificateWidget: React.FC<CertificateWidgetProps> = ({ siteId }) => {
  return <CertificateContextWrapper siteId={siteId} />;
};

export default CertificateWidget;
