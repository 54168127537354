import React, { useState } from 'react';
import {
  Avatar,
  Checkbox,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { JunoUser, SearchContentTypeEnum } from '@juno/client-api/model';
import { UserQuickView } from '@juno/ui';
import { getContentRoute, useSettings } from '@juno/utils';

interface CreateNewConversationRowProps {
  junoUser: JunoUser;
  setSelectedUsers: React.Dispatch<React.SetStateAction<JunoUser[]>>;
  selectedUsers: JunoUser[];
  handleSelectUser: () => void;
}

export const CreateNewConversationRow: React.FC<CreateNewConversationRowProps> = ({
  junoUser,
  setSelectedUsers,
  selectedUsers,
  handleSelectUser,
}) => {
  const [userQuickViewAnchorEl, setUserQuickViewAnchorEl] = useState<null | HTMLElement>(null);
  const { site } = useSettings();
  const navigateRoute = useNavigate();
  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    // Clear the user search input
    handleSelectUser();

    setSelectedUsers((prevUsers) => {
      // Check if the user is already selected
      const isUserSelected = prevUsers.some((user) => user.id === junoUser.id);

      if (checked && !isUserSelected) {
        // If the checkbox is checked and the user isn't already selected, add the user
        return [...prevUsers, junoUser];
      } else if (!checked && isUserSelected) {
        // If the checkbox is unchecked and the user is currently selected, remove the user
        return prevUsers.filter((user) => user.id !== junoUser.id);
      }

      // If no changes are needed, return the previous state
      return prevUsers;
    });
  };

  // Check if the user is currently selected
  const isChecked = selectedUsers.some((user) => user.id === junoUser.id);

  return (
    <ListItem alignItems='flex-start'>
      <Grid container alignItems='center' sx={{ width: '100%' }}>
        <Grid
          item
          xs={9}
          display='flex'
          alignItems='center'
          onClick={(e) => {
            setUserQuickViewAnchorEl(e.currentTarget);
          }}
        >
          <ListItemAvatar sx={{ marginRight: 1, minWidth: 0, marginBottom: 0.7 }}>
            <Avatar
              alt={`${junoUser.first_name} ${junoUser.last_name}`}
              src={junoUser.avatar ?? undefined}
              sx={{ width: 34, height: 34 }}
            />
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography
                sx={{ display: 'block', pl: 1 }}
                component='span'
                variant='body1'
                color='text.primary'
                fontWeight={400}
                fontSize='1.1rem'
              >
                {`${junoUser.first_name} ${junoUser.last_name}`}
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'right' }}>
          <Checkbox checked={isChecked} onChange={handleSelectChange} color='primary' />
        </Grid>
      </Grid>
      <UserQuickView
        anchorEl={userQuickViewAnchorEl}
        userId={junoUser.id}
        open={Boolean(userQuickViewAnchorEl)}
        setAnchorEl={setUserQuickViewAnchorEl}
        onViewProfile={(userEmail: string): void => {
          navigateRoute(
            getContentRoute(site?.slug || '', junoUser?.id || '', SearchContentTypeEnum.user),
          );
        }}
        onMessageUser={function (userId: string): void {
          throw new Error('Function not implemented.');
        }}
      />
    </ListItem>
  );
};
