import React, { useState } from 'react';
import * as Yup from 'yup';
import ArrowCircleLeftOutlinedIcon from '@mui/icons-material/ArrowCircleLeftOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { Form, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { string } from 'yup';
import { useDeleteSpeaker, useGetSpeaker, useUpdateSpeaker } from '@juno/client-api';
import { JunoImage } from '@juno/client-api/fakeModel';
import { Speaker } from '@juno/client-api/model';
import { TINY_MCE_DEFAULT_CONFIG } from '@juno/constants';
import {
  AdminEditPanelHeader,
  AutocompleteTags,
  ConfirmDeleteDialog,
  Container,
  GenericFormikInput,
  JunoImageUpload,
} from '@juno/ui';
import {
  MutationAction,
  onMutation,
  optimizeImage,
  slugify,
  uploadTinyMceImageCloudinary,
  useSettings,
} from '@juno/utils';

const validationSchema = Yup.object().shape({
  name: string().max(90, 'Name character limit: 90'),
  slug: string().max(90, 'Slug character limit: 90'),
  preview_text: string().max(78, 'Preview text character limit: 78').nullable(),
});

const SpeakerAdmin: React.FC = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const { site } = useSettings();
  const { slug } = useParams();
  const { data: speaker, refetch } = useGetSpeaker(site?.id || '', slug || '');
  const updateSpeaker = useUpdateSpeaker(
    onMutation(MutationAction.UPDATE, speaker?.name || '', refetch),
  );
  const deleteSpeaker = useDeleteSpeaker(
    onMutation(MutationAction.DELETE, speaker?.name || '', () => {
      navigate(`/${site?.slug || ''}/admin/speakers`);
    }),
  );

  const save = async (values: Speaker) => {
    setIsSaving(true);
    try {
      await updateSpeaker.mutateAsync({
        siteId: site?.id || '',
        speakerId: speaker?.id || '',
        data: values,
      });
      setIsSaving(false);
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteSpeaker.mutateAsync({ siteId: site?.id || '', speakerId: speaker?.id || '' });
      setIsDeleting(false);
    } catch (e) {
      console.error(e);
      setIsDeleting(false);
    }
  };

  return (
    <Formik
      key={'access-pass-form'}
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={speaker || ({} as Speaker)}
      onSubmit={async (values, { setSubmitting }) => {
        save(values);
      }}
    >
      {(formik) => {
        const {
          errors,
          values,
          setFieldValue,
          dirty,
          isValid,
          handleReset,
          handleSubmit,
          isSubmitting,
        } = formik;
        return (
          <Container>
            <Card sx={{ p: 2, mb: 3 }}>
              <AdminEditPanelHeader
                shouldShow={dirty && Object.values(errors).length === 0}
                title={`${values.name}`}
                onClickDiscard={handleReset}
                onClickSave={handleSubmit}
                onClickGoBack={() => {
                  navigate(-1);
                }}
                isSubmitting={isSubmitting}
              />
            </Card>
            <Card sx={{ p: 2 }}>
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <GenericFormikInput
                      name='name'
                      label='Name'
                      size='small'
                      fullWidth
                      value={values.name || ''}
                      sx={{ mt: 2 }}
                      error={!!errors['name']}
                      helperText={<>{errors?.['name'] || ''}</>}
                    />
                    <GenericFormikInput
                      name='slug'
                      label='Slug'
                      size='small'
                      fullWidth
                      onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                        setFieldValue('slug', slugify(e.target.value))
                      }
                      value={values.slug || ''}
                      sx={{ mt: 2 }}
                      error={!!errors['slug']}
                      helperText={<>{errors?.['slug'] || ''}</>}
                    />
                    <GenericFormikInput
                      name='preview_text'
                      label='Preview Text'
                      size='small'
                      fullWidth
                      value={values.preview_text || ''}
                      multiline
                      rows={3}
                      sx={{ mt: 2 }}
                      error={!!errors['preview_text']}
                      helperText={<>{errors?.['preview_text'] || ''}</>}
                    />
                    <Box sx={{ mt: 2 }}>
                      <AutocompleteTags formik={formik} label='tags' />
                    </Box>
                  </Grid>
                  <Grid item xs={6} display={'flex'} justifyContent={'center'}>
                    <JunoImageUpload
                      style={{
                        aspectRatio: '1/1',
                        width: 200,
                        height: 200,
                        marginTop: 2,
                        borderRadius: '50%',
                        overflow: 'hidden',
                      }}
                      backgroundSize={'cover'}
                      src={optimizeImage(270, values?.image || '')}
                      srcUrl={values?.image || ''}
                      disabled={false}
                      onFileUploaded={(selected: JunoImage | null) =>
                        setFieldValue('image', selected?.url || '')
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Editor
                      apiKey={process.env.NX_TINY_MCE_API_KEY}
                      onEditorChange={(value: string) => setFieldValue('description', value)}
                      value={values.description || ''}
                      init={{
                        ...TINY_MCE_DEFAULT_CONFIG,
                        images_upload_handler: uploadTinyMceImageCloudinary,
                        setup: (editor) => {
                          editor.setProgressState(true);
                        },
                      }}
                      disabled={false}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
                      <Button sx={{ mr: 'auto' }} onClick={() => setDeleteOpen(true)}>
                        <DeleteOutlineOutlinedIcon sx={{ mr: 1 }} />
                        Delete
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
              {!!deleteOpen && (
                <ConfirmDeleteDialog
                  handleClose={() => {
                    setDeleteOpen(false);
                  }}
                  handleDelete={() => {
                    setDeleteOpen(false);
                    handleDelete();
                  }}
                  title={'Delete Speaker'}
                  message={
                    <>
                      <Typography sx={{ mb: 3 }}>
                        Are you sure you want to permanently delete this Speaker?
                      </Typography>
                      <Typography>
                        All of the information will be lost. This action cannot be undone.
                      </Typography>
                    </>
                  }
                />
              )}
            </Card>
          </Container>
        );
      }}
    </Formik>
  );
};

export default SpeakerAdmin;
