import React from 'react';
import { Card, CardActionArea, CardContent, Chip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CourseRequirement } from '@juno/client-api/model';

interface PrereqListItemProps {
  prereq: CourseRequirement;
  siteId: string;
  handleClick: (id: string) => void;
}
const PrereqListItem: React.FC<PrereqListItemProps> = ({ prereq, siteId, handleClick }) => {
  return (
    <Card
      sx={{
        borderRadius: '10px',
        width: '100%',
        mb: 1,
        backgroundColor: 'white',
        border: '1px solid gray',
      }}
    >
      <CardActionArea
        onClick={(e) => {
          if (prereq.id) {
            handleClick(prereq.id);
          }
        }}
      >
        <CardContent
          sx={{
            padding: '20px',
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ width: '90%' }}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{prereq.name}</Typography>
            <Typography variant='caption' sx={{ fontSize: '16px' }}>
              {prereq.equivalents && prereq.equivalents?.length > 0
                ? `= ${prereq.equivalents?.map((e) => e.name).join(' , ')}`
                : '= No Equivalents'}
            </Typography>
          </Box>
          <Box sx={{ m: 2 }}>
            <Chip
              key={prereq.id}
              label={
                parseInt(prereq.num_course_grants) === 1
                  ? `${prereq.num_course_grants} Course`
                  : `${prereq.num_course_grants} Courses`
              }
              sx={{ ml: 1 }}
            />
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
export default PrereqListItem;
