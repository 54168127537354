import React, { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { getPurchaseLink } from '@juno/client-api';
import { AccessPass, Product, ProductPrice, UserPurchase } from '@juno/client-api/model';
import { useBreakpoint, useSettings } from '@juno/utils';
import DialogAriaWrapper from '../DialogAriaWrapper';

interface ProductCardProps {
  productStatus: ProductStatus;
  showQuantity?: boolean;
  contentId: string;
}

export interface ProductStatus {
  product: Product | undefined;
  myPrice: ProductPrice | undefined;
  myPayment: UserPurchase | undefined;
  myAccessPass: AccessPass | undefined;
}

interface ExtraUser {
  name: string;
  email: string;
}

const ProductCard: React.FC<ProductCardProps> = ({
  productStatus,
  showQuantity = false,
  contentId,
}) => {
  const { xs, sm } = useBreakpoint();
  const { isApp, user, site } = useSettings();
  const [isSaving, setIsSaving] = useState(false);
  const [quantity, setQuantity] = useState<string | number>(1);
  const [extraUsers, setExtraUsers] = useState<ExtraUser[]>([]);
  const [extraUsersDialogOpen, setExtraUsersDialogOpen] = useState(false);

  const handleClickPurchase = async (): Promise<void> => {
    setIsSaving(true);
    try {
      const link = await getPurchaseLink(site?.id || '', user?.id || '', {
        redirect_url: window.location.href,
        price_ids: [productStatus.myPrice?.id || ''],
        quantity: Number(quantity),
        content_id: contentId,
        extra_users: extraUsers,
      });
      window.location.href = link;
    } catch (error) {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    const newArr = [];
    for (let i = 1; i < Number(quantity); i++) {
      newArr.push({ name: '', email: '' });
    }
    setExtraUsers(newArr);
  }, [quantity]);

  return (
    <Card sx={{ p: 3, mb: 3 }}>
      <Stack
        direction={xs || sm ? 'column' : 'row'}
        justifyContent='space-between'
        alignItems='center'
      >
        <Box
          sx={{
            height: 100,
            width: 100,
            borderRadius: 60,
            mr: xs || sm ? 0 : 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: (theme) => theme.palette.primary.main,
            background: '#eee',
            mb: xs || sm ? 2 : 0,
          }}
        >
          <ShoppingCartCheckoutIcon sx={{ fontSize: 35 }} />
        </Box>
        <Typography sx={{ width: xs || sm ? '80%' : '50%', mb: xs || sm ? 4 : 0 }} variant='body2'>
          This content requires an Access Pass. If you wish to continue, click the Purchase option
          below and fill out your payment information.{' '}
          {isApp &&
            'This may not be done through the app. Visit this page in a browser if you wish to continue.'}
        </Typography>
        <Grid container width={300} spacing={2}>
          {!showQuantity && <Grid item xs={4}></Grid>}
          <Grid item xs={4}>
            <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
              Price
            </Typography>
          </Grid>
          {showQuantity && (
            <Grid item xs={4}>
              <Typography
                variant='body2'
                sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}
              >
                Quantity
                <Tooltip title='You may purchase seats for other people as well. Be prepared to enter a name and email for each additional attendant if you select a quantity greater than 1.'>
                  <InfoOutlinedIcon sx={{ fontSize: 14, ml: 1 }} />
                </Tooltip>
              </Typography>
            </Grid>
          )}
          <Grid item xs={4}></Grid>
          {!showQuantity && <Grid item xs={4}></Grid>}
          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 'bold' }}>
              {productStatus.myPayment && 'Purchased'}
              {productStatus.myPrice &&
                !productStatus.myPayment &&
                `$${productStatus.myPrice.price}`}
              {!productStatus.myPrice &&
                !productStatus.myPayment &&
                'You do not have access to purchase this course'}
            </Typography>
          </Grid>
          {showQuantity && (
            <Grid item xs={4}>
              <TextField
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
                type='number'
                size='small'
                sx={{ width: 80 }}
              />
            </Grid>
          )}

          <Grid item xs={4}>
            <LoadingButton
              loading={isSaving}
              onClick={() => {
                Number(quantity) > 1 ? setExtraUsersDialogOpen(true) : handleClickPurchase();
              }}
              variant='contained'
              disabled={isApp}
            >
              Purchase
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
      <DialogAriaWrapper
        id='extra_users'
        open={extraUsersDialogOpen}
        onClose={() => setExtraUsersDialogOpen(false)}
        fullWidth
      >
        <DialogTitle>
          <Typography variant='subtitle1'>Who else are you purchasing access for?</Typography>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} sx={{ pt: 2 }}>
            {extraUsers.map((_, index) => (
              <Stack direction='row' gap={2}>
                <TextField
                  label='Name'
                  variant='outlined'
                  size='small'
                  value={extraUsers[index].name}
                  onChange={(e) =>
                    setExtraUsers(
                      extraUsers.map((user, i) =>
                        i === index ? { ...user, name: e.target.value } : user,
                      ),
                    )
                  }
                />
                <TextField
                  label='Email'
                  variant='outlined'
                  value={extraUsers[index].email}
                  size='small'
                  onChange={(e) =>
                    setExtraUsers(
                      extraUsers.map((user, i) =>
                        i === index ? { ...user, email: e.target.value } : user,
                      ),
                    )
                  }
                />
              </Stack>
            ))}
          </Stack>
          <DialogActions>
            <LoadingButton
              loading={isSaving}
              variant='contained'
              onClick={() => handleClickPurchase()}
            >
              Purchase
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </DialogAriaWrapper>
    </Card>
  );
};

export default ProductCard;
