import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
} from '@mui/material';
import { DialogAriaWrapper } from '@juno/ui';
import { default as JunoCertificatePreview } from '../LivePreview';

interface DownloadSingleCertificateDialogProps {
  certificateName: string;
  certificateContent: any;
  certificateUrl: string;
  downloadUrl: (url: string, fileName: string, shareRatherThanOpen: boolean) => void;
  onClose: () => void;
}
export const DownloadSingleCertificateDialog: React.FC<DownloadSingleCertificateDialogProps> = ({
  certificateName,
  certificateContent,
  certificateUrl,
  downloadUrl,
  onClose,
}) => {
  return (
    <DialogAriaWrapper id='downloadSingleCertificateDialog' onClose={onClose} open={true}>
      <DialogTitle id='downloadSingleCertificateDialog-dialog-title'>
        {certificateName}
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent id='downloadSingleCertificateDialog-dialog-description'>
        {/* // TODO this looks like crap on mobile */}
        <Box
          sx={{ height: 416, width: 564, maxHeight: '80%', maxWidth: '90%', overflow: 'hidden' }}
        >
          <Paper
            sx={{
              m: 2,
              transformOrigin: 'top left',
              width: 'fit-content',
              height: 'fit-content',
              transform: 'scale(0.4)',
              overflow: 'hidden',
              paddingBottom: '1px',
              border: 'none',
            }}
            elevation={2}
          >
            <JunoCertificatePreview
              html={certificateContent ?? ''}
              style={{ width: '1225px', height: '925px' }}
            />
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          download
          href={certificateUrl}
          fullWidth
          color='primary'
          onClick={(e) => {
            downloadUrl(certificateUrl, 'Certificate.pdf', false);
            e.preventDefault();
          }}
        >
          Download Certificate
        </Button>
      </DialogActions>
    </DialogAriaWrapper>
  );
};

export default DownloadSingleCertificateDialog;
