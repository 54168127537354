import React, { useEffect, useMemo, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useDebounce } from 'usehooks-ts';
import { useGetAllTags } from '@juno/client-api';
import { Tag } from '@juno/client-api/model';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
interface AutocompleteTagsProps {
  label: string;
  instructions?: string;
  siteId: string;
  disabled?: boolean;
  onSelect?: (value: string[]) => void;
  tagType: string;
  tagsRemoved?: string;
  preFilterTagIds?: string[];
}

const AutocompleteTags: React.FC<AutocompleteTagsProps> = ({
  label,
  instructions,
  siteId,
  disabled,
  onSelect,
  tagType,
  tagsRemoved,
  preFilterTagIds,
}) => {
  const [selected, setSelected] = useState<Tag[] | undefined>();
  const [input, setInput] = useState<string>('');
  const debouncedSearchValue = useDebounce(input, 200);

  const searchFilter = useMemo(() => {
    if (!debouncedSearchValue) return { type__value: tagType };
    return {
      type__value: tagType,
      value__icontains: debouncedSearchValue,
    };
  }, [debouncedSearchValue]);

  const { data: usersData, isLoading } = useGetAllTags(siteId, {
    filter: searchFilter,
    order: 'value',
    limit: 250,
    offset: 0,
  });

  // TODO: Replace w/ Dom's async lazy load component w/ it's ready
  if (usersData && usersData.length > 250) {
    console.warn(
      'Too many tags returned, please narrow your search. We should refactor UX to allow for pagination.',
    );
  }

  const handleSelect = (reason: string, value: Tag[] | undefined) => {
    setSelected(value);
    onSelect?.(value?.map((v) => v.id) || []);
  };

  const handleInputChange = (e: React.SyntheticEvent, newInputValue: string) => {
    if (!e) return;
    setInput(newInputValue);
  };

  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: Tag[] | undefined,
    reason: string,
  ) => {
    // Don't let the user select disabled tag options
    if (preFilterTagIds && value && preFilterTagIds.includes(value[0].id)) return;
    handleSelect(reason, value);
  };

  // Make the AutoCompleteTags element on the filters modal recognize when the user removes a tag/s from the JunoGrid page
  useEffect(() => {
    if (tagsRemoved) {
      const newTagSet = selected?.filter((obj) => !tagsRemoved.includes(obj.id));
      handleSelect('remove-option', newTagSet);
    }
  }, [tagsRemoved]);

  return (
    <>
      <Typography variant='body2'>{instructions}</Typography>
      <Autocomplete
        sx={{ mb: 2, mt: 1 }}
        disabled={disabled}
        disableCloseOnSelect
        multiple
        limitTags={2}
        size='small'
        loading={isLoading}
        loadingText={<CircularProgress size={20} />}
        onChange={handleChange}
        getOptionLabel={(option) => option.value}
        filterOptions={(x) => x}
        options={usersData || []}
        value={selected || []}
        onInputChange={handleInputChange}
        inputValue={input}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label={label} />}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{ right: 0 }}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected || preFilterTagIds?.includes(option.id)}
              disabled={preFilterTagIds?.includes(option.id)}
            />
            <span style={{ color: preFilterTagIds?.includes(option.id) ? 'gray' : 'inherit' }}>
              {`${option.value}`}
            </span>
          </li>
        )}
      />
    </>
  );
};

export default AutocompleteTags;
