import React, { useEffect, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Form, Formik, FormikProps } from 'formik';
import { useDebounce } from 'usehooks-ts';
import { useGetAllTags } from '@juno/client-api';
import { ComponentData, Component as ComponentModel, Tag } from '@juno/client-api/model';
import { GenericFormikInput } from '@juno/ui';
import { useSettings } from '@juno/utils';

interface DirectoryFormProps {
  component?: ComponentModel;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
  formik: FormikProps<DirectoryComponentModel>;
}

interface DirectoryFormWrapperProps {
  component?: ComponentModel;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
}

export interface DirectoryDataModel extends ComponentData {
  title: string;
  numPerPage: number;
  showSavedUsers: boolean;
  showRecommendedUsers: boolean;
  interestsFilter: Tag[];
  skillsFilter: Tag[];
}

export interface DirectoryComponentModel extends ComponentModel {
  data: DirectoryDataModel;
}

const DirectoryForm: React.FC<DirectoryFormProps> = ({ component, onUpdate, formik }) => {
  const { handleChange, setValues, values } = formik;
  const debouncedValues = useDebounce(values, 700);
  const { site } = useSettings();

  const { data: tags, isLoading } = useGetAllTags(
    site?.id || '',
    {},
    { query: { enabled: !!site?.id } },
  );

  useEffect(() => {
    if (component) {
      onUpdate({ ...component, ...debouncedValues });
    }
  }, [debouncedValues]);
  return (
    <>
      <Typography variant='body2' sx={{ opacity: 0.6 }}>
        Editing Section
      </Typography>
      <Typography variant='h5' sx={{ mb: 4 }}>
        {values.name || 'Directory'}
      </Typography>
      <GenericFormikInput
        name='name'
        label='Name'
        type='text'
        value={values.name}
        InputProps={{ disableUnderline: true }}
        variant='filled'
        fullWidth
      />
      <GenericFormikInput
        name='data.title'
        label='Title'
        type='text'
        value={values.data.title}
        InputProps={{ disableUnderline: true }}
        variant='filled'
        fullWidth
      />
      <Box mb={2}>
        <Tooltip
          title='This will toggle visibility of the Saved Users section at the top of the Directory.'
          placement='top-start'
        >
          <FormControlLabel
            value={values?.data?.showSavedUsers}
            control={
              <Checkbox
                checked={values?.data?.showSavedUsers}
                defaultChecked={true}
                onChange={(e) =>
                  setValues({
                    ...values,
                    data: {
                      ...values.data,
                      showSavedUsers: e.target.checked,
                    },
                  })
                }
              />
            }
            label='Show saved users'
          />
        </Tooltip>
        <Tooltip
          title='This will toggle visibility of the Recommended Users section of the Directory.'
          placement='top-start'
        >
          <FormControlLabel
            value={values?.data?.showRecommendedUsers}
            control={
              <Checkbox
                checked={values?.data?.showRecommendedUsers}
                defaultChecked={true}
                onChange={(e) =>
                  setValues({
                    ...values,
                    data: {
                      ...values.data,
                      showRecommendedUsers: e.target.checked,
                    },
                  })
                }
              />
            }
            label='Show recommended users'
          />
        </Tooltip>
      </Box>
      <Stack spacing={1}>
        <Autocomplete
          id='autocomplete-user-interests'
          disableCloseOnSelect
          multiple
          onChange={(event: React.SyntheticEvent<Element, Event>, value: Tag[]) => {
            formik.setFieldValue('data.interestsFilter', value);
          }}
          getOptionLabel={(option) => `${option?.value}`}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={<CheckBoxIcon fontSize='small' />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {`${option.value}`}
            </li>
          )}
          options={tags || []}
          loading={isLoading}
          value={values?.data?.interestsFilter || []}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Filter by Interest'}
              variant='filled'
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          id='autocomplete-user-skills'
          disableCloseOnSelect
          multiple
          onChange={(event: React.SyntheticEvent<Element, Event>, value: Tag[]) => {
            formik.setFieldValue('data.skillsFilter', value);
          }}
          getOptionLabel={(option) => `${option?.value}`}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                checkedIcon={<CheckBoxIcon fontSize='small' />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {`${option.value}`}
            </li>
          )}
          options={tags || []}
          loading={isLoading}
          value={values?.data?.skillsFilter || []}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label={'Filter by Skill'}
              variant='filled'
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <Tooltip title='Number of users to display in the full list.' placement='top-start'>
          <Select
            value={values?.data?.numPerPage || 10}
            name='data.numPerPage'
            onChange={(e) =>
              setValues({
                ...values,
                data: {
                  ...values.data,
                  numPerPage: e.target.value as number,
                },
              })
            }
            fullWidth
            defaultValue={10}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </Tooltip>
      </Stack>
    </>
  );
};

const DirectoryFormWrapper: React.FC<DirectoryFormWrapperProps> = ({
  component,
  onSave,
  onUpdate,
}) => {
  return (
    <Formik
      initialValues={(component as DirectoryComponentModel) || ({} as DirectoryComponentModel)}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        if (component) {
          onSave({ ...component, ...values });
        }
      }}
      enableReinitialize
    >
      {(formik) => {
        return (
          <Form>
            <DirectoryForm
              component={component}
              onSave={onSave}
              onUpdate={onUpdate}
              formik={formik}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default DirectoryFormWrapper;
