import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import _ from 'lodash';
import { useInView } from 'react-intersection-observer';

/********************************
 *
 * Manages pagination of a list of items
 *
 * Parameters:
 * fetchResults: the results of the query
 * isLoading: if the query is loading
 * isFetching: if the query is fetching
 * search: the search string
 * debouncedSearch: the debounced search string
 * setPage: the function to increment or reset the page
 *
 * Returns:
 * data: the data to display
 * setData: the function to set the data manually if you need to
 * ref: the ref for the last element to know if it's inview
 * and we should paginate
 * loading: combination of the loading states
 *
 ********************************/

export const usePagination = (
  fetchResults: any,
  isLoading: boolean,
  isFetching: boolean,
  search: string | null,
  debouncedSearch: string | null,
  setPage: Dispatch<SetStateAction<number>>,
  initialInView = false,
) => {
  const [data, setData] = useState<any[]>([]);
  const [isLoadingHook, setIsLoadingHook] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0,
    initialInView: initialInView,
  });

  // merge the results from the query with the previous results
  useEffect(() => {
    if (!fetchResults) return;
    setData((old) => {
      const joined = _.union(old, fetchResults);
      // ? leaving this old here just in case
      // const uniq = _.uniqWith(joined, _.isEqual);
      const uniq = _.uniqBy(joined, (x) => x.id);
      return uniq;
    });
  }, [fetchResults]);

  // paginate when the ref comes into view
  useEffect(() => {
    if (inView && !isLoading && (fetchResults?.length || 0) > 0) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView]);

  // reset the page when the search string changes
  useEffect(() => {
    setPage(0);
  }, [debouncedSearch]);

  // extra little loading state to smoothen transitions with api calls/refetching
  useEffect(() => {
    if (!isFetching) {
      setIsLoadingHook(false);
    }
  }, [isFetching]);

  useEffect(() => {
    if (search === null) return;
    if (search !== '') {
      setIsLoadingHook(true);
    }
    setData([]);
  }, [search]);

  const loading = isFetching || isLoading || isLoadingHook;

  const returnValues: [
    any[],
    Dispatch<SetStateAction<any[]>>,
    (node?: Element | null | undefined) => void,
    boolean,
  ] = [data, setData, ref, loading];

  return returnValues;
};
