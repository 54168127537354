import React, { useEffect, useState } from 'react';
import { Box, Grid, Tooltip as MUITooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Group } from '@visx/group';
import Pie from '@visx/shape/lib/shapes/Pie';
import { useTooltip } from '@visx/tooltip';
import { Dayjs } from 'dayjs';
import { ANALYTICS_DASHBOARD_CATEGORIES } from '@juno/constants';
import { usePubnubContext } from '@juno/modules';
import { useSettings } from '@juno/utils';
import { HappeningNowWidget } from './HappeningNowWidget';
import { WidgetModel, widgets } from './data';
import { Overlay } from './styles';

export interface AnalyticsHappeningNowProps {
  excludedCategories: string[];
  currentDate: Dayjs;
}

export interface PieValueType {
  id: string;
  label: string;
  value: number;
  color: string;
}

const DEFAULT_PIE_DATAS: PieValueType[] = [
  {
    id: 'No users on tracked content',
    label: 'No users on tracked content',
    value: 1,
    color: grey[300],
  },
];

export const HappeningNow: React.FC<AnalyticsHappeningNowProps> = ({
  excludedCategories = [],
  currentDate,
}) => {
  const { pubnub, systemChannel } = usePubnubContext();
  const { enableLearning, enableLibrary } = useSettings();
  const [activeUserCount, setActiveUserCount] = useState<number>(0);
  const filteredWidgets = widgets.filter((category) => {
    if (!enableLearning && category.id === ANALYTICS_DASHBOARD_CATEGORIES.USERS_ON_COURSE_PAGE.id) {
      return false;
    }
    if (!enableLibrary && category.id === ANALYTICS_DASHBOARD_CATEGORIES.USERS_ON_LIBRARY_PAGE.id) {
      return false;
    }
    return !excludedCategories.includes(category.id);
  });
  const [selectedWidget, setSelectedWidget] = useState<string | null>(null);
  const [pieDatas, setPieDatas] = useState<PieValueType[]>([]);
  const pieChartWidth = 130;

  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();

  const addPieData = (widget: WidgetModel, count: number) => {
    setPieDatas((prevState) => {
      return [
        ...prevState.filter((pieData) => pieData.id !== widget.id),
        {
          id: widget.id,
          label: widget.title,
          value: count,
          color: widget.color,
        },
      ];
    });
  };

  useEffect(() => {
    if (systemChannel && pubnub && currentDate) {
      pubnub.hereNow(
        {
          channels: [systemChannel],
          includeUUIDs: false,
          includeState: false,
        },
        (status, response) => {
          if (response?.totalOccupancy >= 0) {
            setActiveUserCount(response.totalOccupancy);
          }
        },
      );
    }
  }, [pubnub, systemChannel, currentDate]);

  return (
    <Box sx={{ mb: 1, mt: 2 }}>
      <Overlay isShowing={selectedWidget !== null} />
      <Grid container spacing={2} sx={{ mt: 4 }}>
        <Grid
          item
          xs={12}
          sm={4}
          key={'pieChart'}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Box
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <svg width={pieChartWidth * 2} height={pieChartWidth * 2}>
              <Group top={pieChartWidth} left={pieChartWidth}>
                <Pie
                  data={
                    pieDatas.find((pieData) => pieData.value > 0) ? pieDatas : DEFAULT_PIE_DATAS
                  }
                  pieValue={(pieData) => pieData.value}
                  outerRadius={pieChartWidth}
                  innerRadius={(pieChartWidth * 2) / 3}
                  padAngle={0.01}
                >
                  {(pie) => {
                    // The pie design is confusing too many people so we are hiding it until we can redesign
                    return [];
                    // pie.arcs.map((arc, index) => {
                    //   const pieData = arc.data;
                    //   const arcPath = pie.path(arc) || undefined;
                    //   const arcFill = pieData.color;
                    //   const [x, y] = pie.path.centroid(arc);
                    //   return (
                    //     <g key={`arc-${pieData.id}`}>
                    //       <path
                    //         d={arcPath}
                    //         fill={arcFill}
                    //         onMouseEnter={() => {
                    //           showTooltip({
                    //             tooltipData: pieData.label,
                    //             tooltipLeft: x,
                    //             tooltipTop: y,
                    //           });
                    //         }}
                    //         onMouseLeave={hideTooltip}
                    //       />
                    //     </g>
                    //   );
                    // });
                  }}
                </Pie>
              </Group>
            </svg>
            {tooltipOpen && (
              <div
                className='tooltip'
                style={{
                  position: 'absolute',
                  transform: `translate(${tooltipLeft}px,${tooltipTop}px)`,
                  pointerEvents: 'none',
                  background: 'rgba(0,0,0,0.3)',
                  padding: '5px',
                  color: 'rgba(255,255,255)',
                }}
              >
                {JSON.stringify(tooltipData, undefined, '\n').replace('"', '').replace('"', '')}
              </div>
            )}
            <Box sx={{ position: 'absolute' }}>
              <Typography sx={{ textAlign: 'center', fontSize: '1.6rem' }}>
                {activeUserCount}
              </Typography>
              <MUITooltip
                title={'Registered Users currently logged in.'}
                placement={'right-end'}
                PopperProps={{
                  disablePortal: true,
                }}
              >
                <Typography sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                  Active Users Site-wide
                </Typography>
              </MUITooltip>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} key={'widgets'}>
          <Grid container spacing={2}>
            {filteredWidgets.map((widget, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                key={widget.id}
                sx={{ zIndex: selectedWidget === widget.id ? 2 : 0 }}
              >
                <HappeningNowWidget
                  widget={widget}
                  currentDate={currentDate}
                  isSelected={selectedWidget === widget.id}
                  setSelectedWidget={setSelectedWidget}
                  addPieData={addPieData}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
