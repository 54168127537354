import React, { SetStateAction, useState } from 'react';
import {
  ArrowCircleLeftOutlined as ArrowCircleLeftOutlinedIcon,
  Home as HomeIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { __values } from 'tslib';
import { useEventListener } from 'usehooks-ts';
import {
  getGetPageQueryKey,
  getGetPagesQueryKey,
  useDeleteComponent,
  useDeletePage,
  useUpdatePage,
} from '@juno/client-api';
import {
  Component as ComponentModel,
  Page as PageModel,
  PageTypeEnum,
  Site as SiteModel,
} from '@juno/client-api/model';
import { AlertDialog } from '@juno/ui';
import { MutationAction, onMutation } from '@juno/utils';
import AddModulePopper from './AddModulePopper';
import CreateUpdatePageDialog from './CreateUpdatePageDialog';
import ModuleList from './ModuleList';
import {
  EditPageFormContent,
  EditPageFormTopControls,
  EditPageModuleControls,
  EditPageModuleControlsTitle,
  EditPageModuleListContent,
  EditPageModuleListControls,
} from './styles';
import { createSortKey, newComponentDummyData } from './utils';

interface EditPageFormProps {
  site: SiteModel;
  page: PageModel | undefined;
  onToggleDrawer: () => void;
  onPageSave: (page?: PageModel | undefined) => void;
  isConfiguring: boolean;
  onConfigure: (value: boolean) => void;
  onModuleEdit: (key: string) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  isHomepage: boolean;
  isSaving: boolean;
  dirty: boolean;
  setValues: (values: SetStateAction<PageModel>, shouldValidate?: boolean | undefined) => void;
}

const EditPageForm: React.FC<EditPageFormProps> = ({
  site,
  page,
  onToggleDrawer,
  isConfiguring,
  onConfigure,
  onPageSave,
  onModuleEdit,
  isHomepage,
  isSaving,
  dirty,
  setValues,
  handleSubmit,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { UPDATE, DELETE } = MutationAction;
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [pageEditing, setPageEditing] = useState<PageModel | undefined>();
  const [isPageEditOpen, setIsPageEditOpen] = useState<boolean>(false);
  const refetchPage = () =>
    queryClient.invalidateQueries(getGetPageQueryKey(site.id, page?.id || ''));
  const pageUpdate = useUpdatePage(onMutation(UPDATE, 'Page', refetchPage));
  const pageDelete = useDeletePage(onMutation(DELETE, 'Page', refetchPage));
  const componentDelete = useDeleteComponent(onMutation(DELETE, 'Component'));

  useEventListener('keydown', (e) => {
    if (page && isConfiguring && e.key === 'Escape') {
      onConfigure(false);
    }
  });

  const handleConfirmDeletePage = async () => {
    setConfirmOpen(false);
    setIsDeleting(true);
    page?.components.forEach(async (c) => {
      await componentDelete.mutateAsync({ siteId: site.id, componentId: c.id });
    });
    await pageDelete.mutate({ siteId: site.id, pageId: page?.id || '' });
    await queryClient.invalidateQueries(
      getGetPagesQueryKey(site.id, { filter: { type: page?.type } }),
    );
    setIsDeleting(false);
    navigate(`/${site.slug}/admin/pages`);
  };

  // Page Create/Edit Dialog
  const handleCreateUpdatePage = async (data: PageModel | undefined) => {
    if (!data) return;
    setIsPageEditOpen(false);
    try {
      pageUpdate.mutateAsync({ siteId: site.id, pageId: pageEditing?.id || '', data });
    } catch (e) {
      console.error(e);
    }
  };

  const handleReorderModules = (components: ComponentModel[]) => {
    if (!page) return;
    setValues({ ...page, components });
  };

  const handleCreateUpdatePageCancel = async () => {
    setIsPageEditOpen(false);
    setTimeout(() => {
      setPageEditing(undefined);
    }, 500);
  };

  const handleAddModule = (componentType: string, componentName: string) => {
    if (!page) {
      throw Error('Invalid page');
    }
    const newComponent = {
      id: createSortKey(componentType),
      type: componentType,
      name: componentName,
      data: newComponentDummyData(componentType),
    } as ComponentModel;
    const newComponents = page.components || [];
    const components = [newComponent, ...newComponents];
    const newPage = { ...page, components };
    // TODO: doing this because it's not clear that it's not been saved yet
    // Maybe go back and make everything temporary until explicitly saved?
    // onPageSelected(newPage);
    setValues(newPage);
  };

  const handleDeleteModule = (key: string) => {
    if (!page) return;
    const components = page.components?.filter((item) => item.id !== key) || [];
    setValues({ ...page, components });
  };

  const handleConfigModule = (key: string) => {
    onModuleEdit(key);
    onConfigure(true);
  };

  return (
    <>
      <EditPageFormTopControls>
        <Stack direction='row'>
          <IconButton
            sx={{ mr: 1, height: 40 }}
            onClick={() =>
              navigate(
                `/${site.slug}/admin/${page?.type === PageTypeEnum.page ? 'pages' : 'library'}`,
              )
            }
            color='primary'
          >
            <ArrowCircleLeftOutlinedIcon />
          </IconButton>
          <Box>
            <Typography variant='body2' sx={{ opacity: 0.6 }}>
              Editing Page
            </Typography>
            <Typography variant='h5'>{page?.title}</Typography>
          </Box>
        </Stack>
        {/* <Box>
          <Tooltip title='Minimize'>
            <IconButton onClick={onToggleDrawer}>
              <MenuOpenIcon />
            </IconButton>
          </Tooltip>
        </Box> */}
      </EditPageFormTopControls>
      <EditPageFormContent>
        <EditPageModuleControls>
          <EditPageModuleControlsTitle>
            {isHomepage && (
              <Tooltip title='This is your homepage'>
                <HomeIcon fontSize='small' color='disabled' />
              </Tooltip>
            )}
          </EditPageModuleControlsTitle>

          {page && <AddModulePopper onAdded={handleAddModule} />}
        </EditPageModuleControls>
        <EditPageModuleListContent>
          {page && (
            <>
              <ModuleList
                modules={page.components}
                onReorderModules={handleReorderModules}
                onConfigureModule={handleConfigModule}
                onModuleDelete={handleDeleteModule}
              />
              <EditPageModuleListControls>
                <LoadingButton
                  loading={isDeleting}
                  size='small'
                  sx={{ mt: 2 }}
                  onClick={() => setConfirmOpen(true)}
                  color='error'
                  variant='contained'
                >
                  Delete Page
                </LoadingButton>
                <LoadingButton
                  loading={isSaving}
                  size='small'
                  disabled={!dirty}
                  variant='contained'
                  type='submit'
                  sx={{ mt: 2 }}
                >
                  Save Page
                </LoadingButton>
              </EditPageModuleListControls>
            </>
          )}
        </EditPageModuleListContent>
        <AlertDialog
          open={confirmOpen}
          description={`Are you sure you want to delete this page and all of its components? This action cannot be undone.`}
          onCancel={() => setConfirmOpen(false)}
          onConfirm={handleConfirmDeletePage}
        />
        <CreateUpdatePageDialog
          page={pageEditing}
          open={isPageEditOpen}
          onSubmit={handleCreateUpdatePage}
          handleClose={handleCreateUpdatePageCancel}
        />
      </EditPageFormContent>
    </>
  );
};

export default EditPageForm;
