import { useContext } from 'react';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { JunoBackButton } from '@juno/ui';
import { useSettings } from '@juno/utils';

interface LayoutProps {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  divider?: boolean;
  isRoot?: boolean;
  onBack?: () => void;
  formik?: any;
}

const Layout: React.FC<LayoutProps> = ({
  title,
  children,
  divider,
  subtitle,
  isRoot = false,
  onBack,
  formik,
}) => {
  const handleBack = () => {
    if (isRoot) {
      return;
    }
    onBack && onBack();
  };
  const { isWidget } = useSettings();

  return (
    <Box sx={{ pl: 4, pr: 4, pt: 2 }}>
      <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
        <JunoBackButton onClick={handleBack} hasIcon={!isRoot}>
          {subtitle && (
            <Typography
              variant='subtitle1'
              sx={{
                color: 'GrayText',
                fontSize: '16px',
                lineHeight: '16px',
                fontWeight: 200,
              }}
            >
              {subtitle}
            </Typography>
          )}
          <Typography sx={{ fontSize: '24px', lineHeight: '24px' }}>{title}</Typography>
        </JunoBackButton>
        {isWidget && !isRoot && (
          <Button variant='contained' color='primary' onClick={formik?.submitForm}>
            Save Changes
          </Button>
        )}
      </Stack>
      {divider && (
        <Divider
          component='hr'
          sx={{ borderBottom: 2, borderColor: 'primary.main', mb: 4, mt: 2 }}
        />
      )}
      {children}
    </Box>
  );
};

export default Layout;
