import React, { useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { GenericDateInput } from '@juno/ui';
import { UTCtoLocalString } from '@juno/utils';
import { useBreakpoint } from '@juno/utils/hooks';

interface CourseDateSectionProps {
  dateFields: string[];
  formik: any;
}
const CourseDateSection: React.FC<CourseDateSectionProps> = ({ dateFields, formik }) => {
  const formikValues = formik.values;
  const touchedFields = formik.touched;
  const errors = formik.errors;
  const setFieldValue = formik.setFieldValue;
  const setFieldTouched = formik.setFieldTouched;
  const handleBlur = formik.handleBlur;
  const [copyData, setCopyData] = useState<string | undefined>();

  // This is to get the pickers to left align their text
  const { xs, sm, md, lg } = useBreakpoint();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {/* Map over the dateFields array and return a GenericDateInput for each dateField matching the name of the dateField to the name of the formikValue */}
      {dateFields.map((dateField) => (
        <Grid item xs={12} key={dateField}>
          <GenericDateInput
            // split each label on '_' and map over the array to capitalize each word, then join the array back into a string with spaces
            label={dateField
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
            name={dateField}
            value={
              formikValues[dateField] ? UTCtoLocalString(new Date(formikValues[dateField])) : ''
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                dateField,
                !event.target.value ? null : new Date(event.target.value)?.toISOString(),
              );
              setFieldTouched(dateField, true);
            }}
            onBlur={handleBlur}
            error={touchedFields[dateField] && Boolean(errors[dateField])}
            helperText={touchedFields[dateField] && errors[dateField]}
            sx={{ minWidth: '225px', mb: 2 }}
            onCopy={(_event: React.ClipboardEvent<HTMLInputElement>) => {
              const textValue = formikValues[dateField];
              setCopyData(textValue);
            }}
            onPaste={(_event: React.ClipboardEvent<HTMLInputElement>) => {
              const textValue = copyData;
              setFieldValue(dateField, !textValue ? null : new Date(textValue)?.toISOString());
              setFieldTouched(dateField, true);
            }}
            InputProps={{
              disableUnderline: true,
            }}
            size={'small'}
          />
        </Grid>
      ))}
    </LocalizationProvider>
  );
};
export default CourseDateSection;

/* idk bout you but these old datetimepickers were a pain in the ass. Leaving an example commented out in case prod still wants the stupid ones */
/* <DateTimePicker
              label='Release Date'
              value={formik.values.date_released || ''}
              onChange={(value) => formik.setFieldValue('date_released', value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name='date_released'
                  error={formik.touched.date_released && Boolean(formik.errors.date_released)}
                  helperText={formik.touched.date_released && formik.errors.date_released}
                  onBlur={formik.handleBlur}
                  fullWidth
                  variant='outlined'
                  disabled={isDisabled}
                />
              )}
              disabled={isDisabled}
            /> */
