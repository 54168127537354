// eslint-disable-next-line @typescript-eslint/no-empty-interface
import React, { useMemo } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { CardActions, CardContent, Stack, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import {
  useCreateCourseEnrollment,
  useDeleteEnrollment,
  useDeleteWaitListedEnrollment,
  useGetCourse,
  useGetCourseEnrollments,
  useGetCourseLessons,
  useGetLearnerUsers,
  useGetWaitListedEnrollments,
  useUpdateWaitListOrder,
} from '@juno/client-api';
import { useGetUserRoleMap } from '@juno/client-api/helpers';
import {
  BaseCourse,
  Course,
  Enrollment as EnrollmentModel,
  JunoUser,
  Site as SiteModel,
  Status0c0Enum as StatusEnum,
  UpdateWaitListOrderBodyOne,
  WaitListedEnrollment,
} from '@juno/client-api/model';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';
import { useAnalyticsContext } from '@juno/modules';
import { MutationAction, calculateUserSearchFilters, onMutation, useSettings } from '@juno/utils';
import SaveBar from '../../components/SaveBar';
import AutocompleteLearners from './AutocompleteLearners';
import DragDropWaitlist from './DragDropWaitlist';
import ListLearners from './ListLearners';

interface EnrollmentStatusProps {
  locked: boolean;
  message?: string;
  color?: string;
}

export interface ItemProps extends EnrollmentModel {
  course?: BaseCourse;
  user?: JunoUser;
}

interface EnrollmentProps {
  site: SiteModel;
}

const Enrollment: React.FC<EnrollmentProps> = ({ site }) => {
  const { id: siteId, platform_id: platformId } = site;
  const params = useParams() as { courseSlug?: string };
  const courseSlug: string = params.courseSlug || '';
  const getCourse = useGetCourse(siteId, courseSlug || '');
  const getLessons = useGetCourseLessons(siteId, courseSlug || '');
  const [course, setCourse] = React.useState<Course>();
  const [waitList, setWaitList] = React.useState<WaitListedEnrollment[]>([]);
  const [enrollments, setEnrollments] = React.useState<EnrollmentModel[]>([]);
  const [status, setStatus] = React.useState<EnrollmentStatusProps>({ locked: true });
  const [recordOfTruth, setRecordOfTruth] = React.useState<WaitListedEnrollment[]>([]);
  const [isSaving, setIsSaving] = React.useState(false);
  const [optimisticValue, setOptimisticValue] = React.useState<JunoUser[]>([]);
  const [learnerSearchValue, setLearnerSearchValue] = React.useState<string>('');
  const debouncedSearchValue = useDebounce(learnerSearchValue, 200);
  const { isWidget } = useSettings();
  const [enrollmentSearchValue, setEnrollmentSearchValue] = React.useState<string>('');
  const debouncedEnrollmentSearchValue = useDebounce(enrollmentSearchValue, 200);
  const [waitlistSearchValue, setWaitlistSearchValue] = React.useState<string>('');
  const debouncedWaitlistSearchValue = useDebounce(waitlistSearchValue, 200);
  const { isSiteAdmin } = useGetUserRoleMap(siteId);
  const { firehoseActivity } = useAnalyticsContext();

  const searchFilter = useMemo(() => {
    if (!debouncedSearchValue) return {};
    return calculateUserSearchFilters(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const enrollmentSearchFilter = useMemo(() => {
    if (!debouncedEnrollmentSearchValue) return {};
    return calculateUserSearchFilters(debouncedEnrollmentSearchValue, 'user__');
  }, [debouncedEnrollmentSearchValue]);

  const waitlistSearchFilter = useMemo(() => {
    if (!debouncedWaitlistSearchValue) return {};
    return calculateUserSearchFilters(debouncedWaitlistSearchValue, 'user__');
  }, [debouncedWaitlistSearchValue]);

  // admin scoped queries
  const { data: usersData, isLoading: usersLoading } = useGetLearnerUsers(platformId, {
    ...searchFilter,
    exclude_or: {
      enrollments__course__id__in: [getCourse?.data?.id || ''],
      wait_lists__course__id__in: [getCourse?.data?.id || ''],
    },
    order: 'last_name',
    limit: 10,
    offset: 0,
  });
  const { data: waitlistData, refetch: refetchWaitlist } = useGetWaitListedEnrollments(
    siteId,
    getCourse?.data?.id || '',
    {
      ...waitlistSearchFilter,
      limit: 10,
      offset: 0,
    },
  );
  const { data: enrollmentsServerData, refetch: refetchEnrollments } = useGetCourseEnrollments(
    siteId,
    getCourse?.data?.id || '',
    {
      ...enrollmentSearchFilter,
      limit: 10,
      offset: 0,
    },
  );
  const enrollmentsData: ItemProps[] | undefined = useMemo(() => {
    return enrollmentsServerData;
  }, [enrollmentsServerData]);

  const refetch = () => {
    refetchWaitlist();
    refetchEnrollments();
    getCourse.refetch();
  };

  const updateWaitlistOrder = useUpdateWaitListOrder(
    onMutation(MutationAction.UPDATE, 'WaitListedEnrollment', refetch),
  );

  const createEnrollment = useCreateCourseEnrollment(
    onMutation(MutationAction.CREATE, 'courseEnrollment', refetch),
  );
  const deleteWaitlistedEnrollment = useDeleteWaitListedEnrollment(
    onMutation(MutationAction.DELETE, 'waitlistedEnrollment', refetch),
  );
  const deleteEnrollment = useDeleteEnrollment(
    onMutation(MutationAction.DELETE, 'enrollment', refetch),
  );

  React.useEffect(() => {
    setWaitList(waitlistData || []);
    setRecordOfTruth(waitlistData || []);
  }, [waitlistData]);

  React.useEffect(() => {
    getCourse.data && setCourse(getCourse.data);
  }, [getCourse.data]);

  React.useEffect(() => {
    if (enrollmentsData) {
      setEnrollments(enrollmentsData);
      setOptimisticValue([]);
    }
  }, [enrollmentsData]);

  const formatDate = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString('en-US', {
      dateStyle: 'short',
    });
  };

  React.useEffect(() => {
    if (course?.date_open_enrollment && new Date(course?.date_open_enrollment) > new Date()) {
      setStatus({
        locked: true,
        message: `Enrollment will open on ${formatDate(course?.date_open_enrollment)}`,
        color: 'warning.main',
      });
    } else if (
      course?.date_lock_enrollment &&
      new Date(course?.date_lock_enrollment) < new Date()
    ) {
      setStatus({
        locked: true,
        message: `Enrollment ended on ${formatDate(course?.date_lock_enrollment)}`,
        color: 'error.main',
      });
    } else if (
      course?.date_open_enrollment &&
      course?.date_lock_enrollment &&
      new Date(course?.date_open_enrollment) < new Date() &&
      new Date(course?.date_lock_enrollment) > new Date()
    ) {
      setStatus({
        locked: false,
        message: `Enrollment is open until ${formatDate(course?.date_lock_enrollment)}`,
        color: 'success.main',
      });
    } else {
      setStatus({
        locked: true,
        message: '',
        color: 'transparent',
      });
    }
  }, [course]);

  const stats = [
    {
      key: 'max_enrollments',
      value: course?.max_enrollments ? course?.max_enrollments : 'unlimited',
      label: 'Max Enrollments',
    },
    {
      key: 'enrollments_count',
      value: course?.enrollments_count,
      label: 'Total Enrolled',
    },
    {
      key: 'open_seats',
      value: course?.max_enrollments
        ? course?.max_enrollments - course?.enrollments_count
        : 'unlimited',
      label: 'Open Seats',
    },
    {
      key: 'waitlist_count',
      value: course?.waitlist_count,
      label: 'Waitlist',
    },
  ];

  const handleSave = () => {
    setIsSaving(true);
    try {
      updateWaitlistOrder.mutate({
        siteId,
        courseId: course?.id || '',
        data: waitList as unknown as UpdateWaitListOrderBodyOne,
      });
    } catch (error) {
      console.log(error);
    }
    setIsSaving(false);
  };

  const handleOptimisticValue = (value: JunoUser) => {
    setOptimisticValue((current) => [...current, value]);
  };

  const selected = useMemo(() => {
    const e = enrollments.filter((e) => !!e.user).map((e) => e.user ?? ({} as JunoUser));
    const wl = waitList.filter((w) => !!w.user).map((w) => w.user);
    return [...e, ...wl];
  }, [enrollments, waitList]);

  const handleDeleteWaitlistedEnrollment = async (learnerId: string) => {
    try {
      await deleteWaitlistedEnrollment.mutateAsync({
        siteId,
        courseId: course?.id || '',
        waitListedEnrollmentId: learnerId,
      });
      firehoseActivity(
        ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_CONTENT,
        course?.id || '',
        'admin',
        `${learnerId}`,
        ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_COURSE,
        ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_WAITLIST_REMOVE.value,
        course?.title || '',
        null,
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleCreateEnrollment = async (data: { user: JunoUser }) => {
    try {
      await createEnrollment.mutateAsync({
        siteId,
        courseId: course?.id || '',
        data: {
          status: StatusEnum.NUMBER_2,
          user_id: data.user.id,
          course_id: course?.id || '',
          id: '',
          credits_earned: 0,
          did_pass: false,
        },
      });
      firehoseActivity(
        ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_CONTENT,
        course?.id || '',
        'admin',
        `${data.user.id}`,
        ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_COURSE,
        ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_ENROLL.value,
        course?.title || '',
        null,
      );
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteEnrollment = async (data: { user: JunoUser; id: string }) => {
    try {
      await deleteEnrollment.mutate({
        siteId,
        courseId: course?.id || '',
        enrollmentId: data.id,
      });
      firehoseActivity(
        ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_CONTENT,
        course?.id || '',
        'admin',
        `${data.user.id}`,
        ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_COURSE,
        ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_UNENROLL.value,
        course?.title || '',
        null,
      );
    } catch (e) {
      console.error(e);
    }
  };

  const isGraded = !!getLessons.data?.find((lesson) => lesson.include_in_course_grade === true);

  return (
    <Grid container>
      <SaveBar
        isDirty={recordOfTruth !== waitList}
        isSaving={isSaving}
        onDiscard={() => setWaitList(recordOfTruth)}
        onSave={handleSave}
      />
      <Grid item xs={12} md={4} sx={{ padding: 2 }}>
        <Card>
          <CardHeader title='Add Learners' />
          <Divider />
          <Stack spacing={2} sx={{ padding: 2 }}>
            <AutocompleteLearners
              locked={status.locked}
              options={usersData || []}
              selected={optimisticValue ? selected.concat(optimisticValue) : selected}
              handleOptimisticValue={handleOptimisticValue}
              onSubmit={handleCreateEnrollment}
              onInputChange={setLearnerSearchValue}
              isLoading={usersLoading}
            />
          </Stack>
          <CardContent>
            <Typography sx={{ mb: 1, pl: 2, pr: 2 }}>
              Select learners to add. Once a learner is added, they are automatically enrolled. Any
              learners added after the max capacity is reached will go to the waitlist.
            </Typography>
            {stats.map((stat) => (
              <Typography key={stat.key} sx={{ pl: 2, pr: 2, pt: 2 }}>
                {stat.label}: {stat.value}
              </Typography>
            ))}
          </CardContent>

          <CardActions>
            <CircleIcon sx={{ ml: 2, color: status?.color }} fontSize='small' />
            <Typography sx={{ p: 2 }}>{status?.message}</Typography>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} md={4} sx={{ padding: 2 }}>
        <ListLearners
          label='Enrolled'
          items={enrollments}
          locked={status.locked}
          onDelete={handleDeleteEnrollment}
          siteId={siteId}
          refetchEnrollments={refetchEnrollments}
          isGraded={isGraded}
          searchValue={enrollmentSearchValue}
          setSearchValue={setEnrollmentSearchValue}
          totalItems={course?.enrollments_count || 0}
        />
      </Grid>
      <Grid item xs={12} md={4} sx={{ padding: 2 }}>
        <DragDropWaitlist
          label='Waitlist'
          data={waitList}
          locked={status.locked}
          onDelete={handleDeleteWaitlistedEnrollment}
          isSiteAdmin={isSiteAdmin}
          isWidget={isWidget}
          handleUpdate={(data: any) => setWaitList(data)}
          searchValue={waitlistSearchValue}
          setSearchValue={setWaitlistSearchValue}
          totalItems={course?.waitlist_count || 0}
        />
      </Grid>
    </Grid>
  );
};

export default Enrollment;
