import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import * as Yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { Reorder, animate, motion, useMotionValue } from 'framer-motion';
import { useDebounce } from 'usehooks-ts';
import { useSearchContent, useUpdateSiteFeatureConfig } from '@juno/client-api';
import {
  InternalLinkObject,
  NavigationItem,
  NavigationItemTypeEnum,
  SearchContentTypeEnum,
} from '@juno/client-api/model';
import { DialogAriaWrapper, IconPicker, JunoIcon } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';

const NavItemSchema = Yup.object().shape({
  text: Yup.string().required('Text is required'),
  link: Yup.string().when('internal', {
    is: false,
    then: Yup.string()
      .max(128, 'URL must not exceed 128 characters')
      .test('title-valid-url', 'URL must begin with "http://" or "https://"', function test(value) {
        if (value && value.length > 0) {
          return value.startsWith('http://') || value.startsWith('https://');
        } else {
          return true;
        }
      })
      .required('Destination is required'),
  }),
  object_id: Yup.string()
    .nullable()
    .when('internal', {
      is: true,
      then: Yup.string().required('Destination is required'),
    }),
  icon: Yup.string()
    .nullable()
    .when('type', {
      is: NavigationItemTypeEnum.icon || NavigationItemTypeEnum.icon_with_text,
      then: Yup.string().required('Icon is required'),
    }),
});

interface FormikModel {
  nav_items: NavigationItem[];
  hide_messenger: boolean;
}
interface QuickLinksProps {
  formik: FormikProps<FormikModel>;
  setPageDirty: Dispatch<SetStateAction<boolean>>;
}

const DUMMY_NAV_ITEM = {
  id: '',
  text: '',
  internal: true,
  link: '',
  order: 0,
  object_id: '',
  type: NavigationItemTypeEnum.text,
} as NavigationItem;

const HeaderLinks: React.FC<QuickLinksProps> = ({ formik, setPageDirty }) => {
  const { site } = useSettings();
  const [currentNavItem, setCurrentNavItem] = useState<NavigationItem>(DUMMY_NAV_ITEM);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { isSubmitting, dirty, setValues, values } = formik;
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 300);
  const { data: searchData, isFetching } = useSearchContent(
    site?.id || '',
    { search_term: debouncedSearch || '' },
    { query: { enabled: !!search } },
  );

  useEffect(() => {
    setPageDirty(dirty);
  }, [dirty]);

  const handleRemove = (order: number) => {
    setValues({
      ...values,
      nav_items: values.nav_items
        .filter((item) => item.order !== order)
        .map((item, i) => ({ ...item, order: i + 1 })),
    });
  };
  const handleReorder = (newOrder: NavigationItem[]) => {
    setValues({
      ...values,
      nav_items: newOrder.map((item, i) => ({ ...item, order: i + 1 })),
    });
  };

  const heightCalc = 64 * values.nav_items.length;
  const height = useMotionValue(heightCalc);

  useEffect(() => {
    animate(height, heightCalc, { type: 'tween', duration: 0.3 });
  }, [values.nav_items.length]);

  const mapContentType = (type: SearchContentTypeEnum) => {
    switch (type) {
      case SearchContentTypeEnum.page:
        return 'content | page';
      case SearchContentTypeEnum.course:
        return 'learning | course';
      case SearchContentTypeEnum.group:
        return 'community | communitygroup';
      case SearchContentTypeEnum.user:
        return 'user | user';
      case SearchContentTypeEnum.library:
        return 'content | page';
      default:
        return 'content | page';
    }
  };

  return (
    <Form>
      <Card sx={{ p: 2, mb: 4 }}>
        <Typography variant='h5'>Header Nav</Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant='body2' sx={{ mb: 4 }}>
          These are links that will appear in the navbar at the top of the page. Add, edit, and
          reorder links to achieve the experience you want.
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Button onClick={() => setDialogOpen(true)} variant='contained' sx={{ mb: 2 }}>
              Add Link
            </Button>
            <motion.div style={{ height }}>
              <Reorder.Group
                axis='y'
                values={values.nav_items}
                onReorder={handleReorder}
                as='div'
                style={{ listStyleType: 'none' }}
              >
                {values.nav_items
                  .sort((a, b) => (a.order || 0) - (b.order || 0))
                  .map((item, index) => (
                    <Reorder.Item value={item} key={`${item.id || item.text}`}>
                      <Stack
                        direction='row'
                        alignItems='center'
                        sx={{
                          border: '1px solid rgba(0,0,0,.1)',
                          mt: 1.25,
                          mb: 1.25,
                          paddingX: 2,
                          paddingY: 1,
                          background: 'rgba(0,0,0,.02)',
                          borderRadius: 2,
                          cursor: 'grab',
                          '&:hover': {
                            background: 'rgba(0,0,0,.04)',
                          },
                        }}
                        justifyContent='space-between'
                      >
                        <Stack direction='row' alignItems='center'>
                          <Box width={50}>
                            {item.icon && <JunoIcon name={item.icon} />}
                            {!item.icon && item.internal && item.internal_link_object?.icon && (
                              <Avatar
                                sx={{ height: 30, width: 30, mr: 1 }}
                                src={item.internal_link_object?.icon || ''}
                                variant='rounded'
                              />
                            )}
                          </Box>
                          <Stack>
                            <Typography variant='subtitle1' sx={{ ml: 1 }}>
                              {item.text}
                            </Typography>
                            <Typography variant='subtitle2' sx={{ ml: 1 }}>
                              {item.internal_link_object?.slug
                                ? `/${item.internal_link_object?.slug}`
                                : item.link}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack direction='row'>
                          <IconButton
                            onClick={() => {
                              setCurrentNavItem(item);
                              setDialogOpen(true);
                            }}
                            size='small'
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton onClick={() => handleRemove(item.order || -1)} size='small'>
                            <CloseIcon />
                          </IconButton>
                        </Stack>
                      </Stack>
                    </Reorder.Item>
                  ))}
              </Reorder.Group>
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant='h6' fontWeight='bold'>
              Messaging
            </Typography>
            <Typography variant='subtitle2'>Allow users to send and receive messages.</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!values.hide_messenger}
                  onChange={(e) => {
                    setValues({ ...values, hide_messenger: !e.target.checked });
                  }}
                />
              }
              label='Enable Messaging'
            />
          </Grid>
        </Grid>
        <Box textAlign='right'>
          <LoadingButton
            loading={isSubmitting}
            variant='contained'
            type='submit'
            disabled={!dirty}
            sx={{ mt: 3 }}
          >
            Save
          </LoadingButton>
        </Box>
        <Formik
          initialValues={currentNavItem}
          validationSchema={NavItemSchema}
          onSubmit={async (formValues, { setSubmitting, resetForm }) => {
            // we're editing
            if (currentNavItem.text) {
              setValues((old) => {
                return {
                  ...old,
                  nav_items: old.nav_items.map((item) =>
                    item.text === currentNavItem.text ? formValues : item,
                  ),
                };
              });
            }
            // we're creating
            else {
              setValues((old) => {
                return {
                  ...old,
                  nav_items: [...old.nav_items, { ...formValues, order: old.nav_items.length + 1 }],
                };
              });
            }
            await setCurrentNavItem(DUMMY_NAV_ITEM);
            setDialogOpen(false);
            resetForm();
          }}
          enableReinitialize
        >
          {({
            dirty: editFormDirty,
            values: formValues,
            setValues: setFormValues,
            handleSubmit: handleSubmitFormValues,
            errors,
            touched,
            setTouched,
            resetForm,
            isValid,
          }) => {
            return (
              <DialogAriaWrapper open={dialogOpen} fullWidth>
                <Box p={3}>
                  <Typography variant='subtitle1'>
                    {currentNavItem?.text ? 'Edit' : 'Create'} Header Nav Item
                  </Typography>
                </Box>
                <DialogContent>
                  <Grid container spacing={4}>
                    <Grid item sm={6}>
                      <Typography variant='subtitle1'>Link Text</Typography>
                      <Typography variant='subtitle2'>
                        This text displays on the button or as a tooltip on hover for all three link
                        styles (required).
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <TextField
                        value={formValues?.text}
                        size='small'
                        label='Link Text'
                        name={'text'}
                        fullWidth
                        onChange={(e) => {
                          setTouched({ ...touched, text: true });
                          setFormValues((old) => ({ ...old, text: e.target.value }));
                        }}
                        error={touched.text && !!errors.text}
                        helperText={touched.text && errors.text}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant='subtitle1'>Destination</Typography>
                      <Typography variant='subtitle2'>
                        The destination of the link. To create an internal link, search for Pages,
                        Courses, Groups, Users, or Library content. To create an external link that
                        will open in a new tab, uncheck internal and enter a url.
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Stack direction='row' width='100%' alignItems='flex-start'>
                        <Stack sx={{ flex: 1, flexGrow: 1 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formValues.internal || false}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setFormValues((old) => ({
                                      ...old,
                                      internal: e.target.checked,
                                      link: '',
                                      search_content_type: undefined,
                                    }));
                                  } else {
                                    setFormValues((old) => ({
                                      ...old,
                                      internal: e.target.checked,
                                      content_type: '',
                                      object_id: null,
                                      search_content_type: undefined,
                                    }));
                                  }
                                }}
                              />
                            }
                            label='Internal'
                            sx={{ width: 160, mb: 1 }}
                          />
                          {formValues.internal && formValues?.object_id && (
                            <Card
                              sx={{
                                p: 2,
                                boxShadow: 1,
                                display: 'flex',
                                background: 'rgba(0,0,0,.03)',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <Stack direction='row' alignItems='center'>
                                {formValues?.internal_link_object?.icon && (
                                  <Avatar
                                    src={formValues?.internal_link_object?.icon}
                                    variant='rounded'
                                    sx={{ mr: 2, height: 30, width: 30 }}
                                  />
                                )}
                                <Typography variant='subtitle1'>
                                  {formValues?.internal_link_object?.title}
                                </Typography>
                              </Stack>
                              <IconButton
                                size='small'
                                onClick={() =>
                                  setFormValues({
                                    ...formValues,
                                    object_id: null,
                                    internal_link_object: {} as InternalLinkObject,
                                    search_content_type: undefined,
                                  })
                                }
                              >
                                <CloseIcon />
                              </IconButton>
                            </Card>
                          )}
                          {formValues.internal && !formValues?.object_id && (
                            <Autocomplete
                              options={searchData || []}
                              getOptionLabel={(option) => option?.title}
                              noOptionsText={search ? 'No results found' : 'Search to find options'}
                              size='small'
                              loading={isFetching}
                              value={
                                searchData?.find((item) => item.id === formValues.object_id) || null
                              }
                              renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                  <Avatar src={option.icon} variant='rounded' sx={{ mr: 2 }} />
                                  <Box>
                                    <Typography>{option.title}</Typography>
                                    <Typography variant='subtitle2'>{option.type}</Typography>
                                  </Box>
                                </li>
                              )}
                              onChange={(e, newValue) => {
                                setTouched({ ...touched, content_type: true });
                                if (!newValue) {
                                  setFormValues((old) => ({
                                    ...old,
                                    object_id: null,
                                    content_type: '',
                                    internal_link_object: undefined,
                                    search_content_type: undefined,
                                  }));
                                } else {
                                  setFormValues((old) => ({
                                    ...old,
                                    object_id: newValue?.id || '',
                                    content_type: mapContentType(newValue?.type || ''),
                                    search_content_type: newValue.type,
                                    internal_link_object: {
                                      slug: newValue?.slug || '',
                                      title: newValue?.title || '',
                                      icon: newValue?.icon || '',
                                    },
                                  }));
                                }
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label='Search destinations'
                                  InputProps={{ ...params.InputProps }}
                                  onChange={(e) => setSearch(e.target.value)}
                                  error={touched.content_type && !!errors.content_type}
                                  helperText={touched.content_type && errors.content_type}
                                />
                              )}
                            />
                          )}
                          {!formValues.internal && (
                            <TextField
                              value={formValues?.link}
                              size='small'
                              label='Link URL'
                              onChange={(e) => {
                                setTouched({ ...touched, link: true });
                                setFormValues((old) => ({ ...old, link: e.target.value }));
                              }}
                              error={touched.link && !!errors.link}
                              helperText={touched.link && errors.link}
                            />
                          )}
                        </Stack>
                      </Stack>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant='subtitle1'>Icon</Typography>
                      <Typography variant='subtitle2'>
                        Choose an icon to accompany the link. If you set an icon for an internal
                        link that has a photo already, the icon will show instead of the photo.
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      {!formValues.icon && (
                        <IconPicker
                          onSelectIcon={(icon) => {
                            setTouched({ ...touched, icon: true });
                            setFormValues((old) => ({ ...old, icon: icon }));
                          }}
                        />
                      )}

                      {touched.icon && !!errors.icon && (
                        <Typography color={'red'}>{errors.icon}</Typography>
                      )}

                      {formValues.icon && (
                        <Card
                          sx={{
                            p: 2,
                            boxShadow: 1,
                            display: 'flex',
                            background: 'rgba(0,0,0,.03)',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <JunoIcon name={formValues.icon} />
                          <IconButton
                            size='small'
                            onClick={() =>
                              setFormValues({
                                ...formValues,
                                icon: '',
                              })
                            }
                          >
                            <CloseIcon />
                          </IconButton>
                        </Card>
                      )}
                    </Grid>
                    <Grid item sm={6}>
                      {' '}
                      <Typography variant='subtitle1'>Link Style</Typography>
                      <Typography variant='subtitle2'>
                        Choose from the 3 link styles:
                        <br />
                        -Text: Text with an optional icon to the left
                        <br />
                        -Icon: Only a large icon shows with a tooltip on hover
                        <br />
                        -Icon with Text: A large icon with text underneath
                        <br />
                      </Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <FormControl>
                        <FormLabel id='demo-controlled-radio-buttons-group'>Link Style</FormLabel>
                        <RadioGroup
                          aria-labelledby='demo-controlled-radio-buttons-group'
                          name='controlled-radio-buttons-group'
                          value={formValues.type}
                          onChange={(e) => {
                            setTouched({ ...touched, type: true });
                            setFormValues((old) => ({
                              ...old,
                              type: e.target.value as NavigationItemTypeEnum,
                            }));
                          }}
                        >
                          <FormControlLabel
                            value={NavigationItemTypeEnum.text}
                            control={<Radio />}
                            label='Text'
                          />
                          <FormControlLabel
                            value={NavigationItemTypeEnum.icon}
                            control={<Radio />}
                            label='Icon'
                          />
                          <FormControlLabel
                            value={NavigationItemTypeEnum.icon_with_text}
                            control={<Radio />}
                            label='Icon with Text'
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      resetForm();
                      setDialogOpen(false);
                      setCurrentNavItem(DUMMY_NAV_ITEM);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleSubmitFormValues()}
                    variant={'contained'}
                    disabled={!editFormDirty || !isValid}
                  >
                    Save
                  </Button>
                </DialogActions>
              </DialogAriaWrapper>
            );
          }}
        </Formik>
      </Card>
    </Form>
  );
};

export default HeaderLinks;
