import React, { useState } from 'react';
import { Info as InfoIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'loading' })<{
  loading: boolean;
}>(({ loading, theme }) => ({
  backgroundColor: loading ? 'initial' : '#e0e0e0',
  color: '#a6a6a6',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(0.7),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const IconContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

export interface PointsButtonProps {
  onClick: () => void;
  title: string;
}

const PointsButton: React.FC<PointsButtonProps> = ({ onClick, title }) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  return (
    <LoadingButton
      variant='outlined'
      loading={buttonLoading}
      onClick={onClick}
      sx={{ p: 0, borderColor: '#a6a6a6' }}
    >
      <TitleContainer loading={buttonLoading}>{title}</TitleContainer>
      <IconContainer sx={{ pl: 1 }}>
        <InfoIcon htmlColor='#a6a6a6' />
      </IconContainer>
    </LoadingButton>
  );
};

export default PointsButton;
