import React from 'react';
import {
  Delete as DeleteIcon,
  DragIndicator as DragIndicatorIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Notes as NotesIcon,
  Settings as SettingsIcon,
} from '@mui/icons-material';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import QuizIcon from '@mui/icons-material/Quiz';
import { Box, Card, Chip } from '@mui/material';
import { useDebounce, useElementSize } from 'usehooks-ts';
import { EMPTY_COURSE_URL } from '@juno/constants';
import { optimizeImage } from '@juno/utils';
import {
  BottomIndicatorsWrapper,
  DeleteButton,
  EllipsisTypography,
  SettingsButton,
  StyledFormImage,
  TileGridBanner,
  TileGridContainer,
  TileGridDragArea,
  TileGridTextWrapper,
  TileWrapper,
  TypeIcon,
} from './styles';

interface ResourceTileProps {
  title?: string;
  subtitle?: string;
  footer?: React.ReactNode;
  footerAction?: React.ReactNode;
  imageUrl?: string;
  hideImage?: boolean;
  onSettingsSelect?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  sortable?: boolean;
  disabled?: boolean;
  type: string;
  onDelete?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
}
const ResourceTile: React.FC<ResourceTileProps> = ({
  title,
  subtitle,
  footer,
  footerAction,
  imageUrl,
  hideImage,
  onSettingsSelect,
  sortable = false,
  disabled = false,
  type,
  onDelete,
}) => {
  const [imgRef, { width: textWidth }] = useElementSize();
  const debouncedTextWidth = useDebounce(textWidth, 200);

  return (
    <TileWrapper>
      <TileGridContainer container>
        <Card
          sx={{
            boxShadow: 'none',
            display: 'flex',
            width: '100%',
            height: 105,
            position: 'relative',
          }}
        >
          {sortable && (
            // <TileGridDragArea item>
            //   <DragIndicatorIcon />
            // </TileGridDragArea>
            <></>
          )}
          {!hideImage && (
            <TileGridBanner item xs={3} sx={{ m: 2 }}>
              <StyledFormImage
                variant='rounded'
                src={optimizeImage(450, imageUrl || EMPTY_COURSE_URL)}
                alt='Lesson Banner'
              />
            </TileGridBanner>
          )}
          <TileGridTextWrapper item xs={8} sx={{ m: 2 }} ref={imgRef}>
            <Box>
              <EllipsisTypography variant='h6' width={debouncedTextWidth}>
                {title}
              </EllipsisTypography>
            </Box>
            <BottomIndicatorsWrapper>{footer}</BottomIndicatorsWrapper>
          </TileGridTextWrapper>
          <TypeIcon>
            {type === 'question' && <QuizIcon sx={{ fontSize: 60 }} />}
            {type === 'blurb' && <NotesIcon sx={{ fontSize: 60 }} />}
            {type === 'video' && <OndemandVideoIcon sx={{ fontSize: 60 }} />}
          </TypeIcon>
        </Card>
      </TileGridContainer>

      {!(disabled && type === 'question') && (
        <SettingsButton onClick={(e) => onSettingsSelect && onSettingsSelect(e)}>
          <SettingsIcon />
        </SettingsButton>
      )}
      {!disabled && (
        <DeleteButton onClick={(e) => onDelete && onDelete(e)}>
          <DeleteIcon />
        </DeleteButton>
      )}

      <Box sx={{ position: 'absolute', right: 0, bottom: 0, m: 1 }}>{footerAction}</Box>
    </TileWrapper>
  );
};

export default ResourceTile;
