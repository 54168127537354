import React, { useEffect, useState } from 'react';
import { DeleteForever, EditNoteOutlined } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Card, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FieldArray, Form, Formik } from 'formik';
import {
  useCreateUserExperience,
  useDeleteUserExperience,
  useGetUserExperience,
  useUpdateUserExperience,
} from '@juno/client-api';
import { GenericFormikInput } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import { AnimatedIconButton } from './AnimatedIconButton';
import { useUserProfile } from './UserProfileContext';
import { EXPERIENCE_CHARACTER_LIMITS, experienceValidationSchema } from './helpers';

export const Experience: React.FC = () => {
  const userProfile = useUserProfile();
  const [isEditingArray, setIsEditingArray] = useState<boolean[]>([]);
  const theme = useTheme();
  const { site, user } = useSettings();

  const { data: experiences, refetch } = useGetUserExperience(
    site?.id || '',
    userProfile?.id || '',
    {},
    { query: { enabled: !!site?.id && !!userProfile?.id } },
  );

  const updateExperience = useUpdateUserExperience(
    onMutation(MutationAction.UPDATE, 'Experience', refetch),
  );
  const createExperience = useCreateUserExperience(
    onMutation(MutationAction.CREATE, 'Experience', refetch),
  );
  const deleteExperience = useDeleteUserExperience(
    onMutation(MutationAction.DELETE, 'Experience', refetch),
  );

  useEffect(() => {
    if (experiences) {
      setIsEditingArray(new Array(experiences.length).fill(false));
    }
  }, [experiences]);

  if (!experiences) return null;

  const toggleEdit = (index: number, values: any, remove: any) => {
    const newEditingState = [...isEditingArray];
    newEditingState[index] = !newEditingState[index];
    if (
      !newEditingState[index] &&
      values.experiences[index].title === '' &&
      values.experiences[index].company === '' &&
      values.experiences[index].start_date === '' &&
      values.experiences[index].end_date === '' &&
      values.experiences[index].description === ''
    ) {
      remove(index);
    }
    setIsEditingArray(newEditingState);
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    for (let index = 0; index < values.experiences.length; index++) {
      const experience = values.experiences[index];

      if (index < experiences.length) {
        if (
          experience.title !== experiences[index].title ||
          experience.company !== experiences[index].company ||
          experience.start_date !== experiences[index].start_date ||
          experience.end_date !== experiences[index].end_date ||
          experience.description !== experiences[index].description
        ) {
          await updateExperience.mutate({
            siteId: site?.id || '',
            userId: user?.id || '',
            experienceId: experience.id,
            data: experience,
          });
        }
      } else {
        await createExperience.mutate({
          siteId: site?.id || '',
          userId: user?.id || '',
          data: experience,
        });
      }
      toggleEdit(index, values, () => {});
    }
    setSubmitting(false);
  };

  const handleSave = async (index: number, values: any) => {
    const thisExperience = values.experiences[index];

    if (index < experiences.length) {
      if (
        thisExperience.title !== experiences[index].title ||
        thisExperience.company !== experiences[index].company ||
        thisExperience.start_date !== experiences[index].start_date ||
        thisExperience.end_date !== experiences[index].end_date ||
        thisExperience.description !== experiences[index].description
      ) {
        await updateExperience.mutateAsync({
          siteId: site?.id || '',
          userId: user?.id || '',
          experienceId: thisExperience.id,
          data: thisExperience,
        });
      }
    } else {
      await createExperience.mutateAsync({
        siteId: site?.id || '',
        userId: user?.id || '',
        data: thisExperience,
      });
    }

    const newEditingState = [...isEditingArray];
    newEditingState[index] = false;
    setIsEditingArray(newEditingState);
  };

  return (
    <Card sx={{ p: 3, mt: 2, position: 'relative' }}>
      <Typography variant='h6'>Experience</Typography>
      <Formik
        initialValues={{ experiences: experiences || [] }}
        validationSchema={experienceValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          for (let index = 0; index < values.experiences.length; index++) {
            await handleSave(index, values);
          }
          setSubmitting(false);
        }}
        validateOnMount
      >
        {({ values, submitForm, dirty }) => (
          <Form>
            <FieldArray name='experiences'>
              {({ push, remove }) => (
                <>
                  {values.experiences.length > 0 &&
                    values.experiences.map((experience, index) => (
                      <Box key={index} sx={{ position: 'relative', my: 2 }}>
                        <Grid container spacing={2} alignItems='center'>
                          {isEditingArray[index] ? (
                            <>
                              <Grid item xs={6} mt={3}>
                                <GenericFormikInput
                                  value={experience.title}
                                  name={`experiences.${index}.title`}
                                  label='Job Title'
                                  fullWidth
                                  size='small'
                                  helperText={`${values.experiences[index]?.title?.length}/${EXPERIENCE_CHARACTER_LIMITS.title}`}
                                />
                              </Grid>
                              <Grid item xs={6} mt={3}>
                                <GenericFormikInput
                                  value={experience.company}
                                  name={`experiences.${index}.company`}
                                  label='Company'
                                  fullWidth
                                  size='small'
                                  helperText={`${values.experiences[index]?.company?.length}/${EXPERIENCE_CHARACTER_LIMITS.company}`}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GenericFormikInput
                                  value={experience.start_date}
                                  name={`experiences.${index}.start_date`}
                                  label='Start Date'
                                  fullWidth
                                  size='small'
                                  helperText={`${values.experiences[index]?.start_date?.length}/${EXPERIENCE_CHARACTER_LIMITS.startDate}`}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GenericFormikInput
                                  value={experience.end_date}
                                  name={`experiences.${index}.end_date`}
                                  label='End Date'
                                  fullWidth
                                  size='small'
                                  helperText={`${
                                    values.experiences[index]?.end_date?.length || 0
                                  }/${EXPERIENCE_CHARACTER_LIMITS.endDate}`}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <GenericFormikInput
                                  value={experience.description}
                                  name={`experiences.${index}.description`}
                                  label='Description'
                                  fullWidth
                                  multiline
                                  maxRows={4}
                                  helperText={`${
                                    values.experiences[index]?.description?.length || 0
                                  }/${EXPERIENCE_CHARACTER_LIMITS.description}`}
                                />
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Box>
                                  <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                                    {experience.title}
                                  </Typography>
                                  <Typography variant='subtitle1'>{experience.company}</Typography>
                                  <Typography variant='subtitle2'>
                                    {experience.start_date} - {experience.end_date}
                                  </Typography>
                                  <Typography variant='subtitle2'>
                                    {experience.description}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                        {userProfile.isMe && (
                          <>
                            {isEditingArray[index] ? (
                              <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                                <IconButton
                                  onClick={() => toggleEdit(index, values, remove)}
                                  size='medium'
                                >
                                  <CancelIcon sx={{ color: theme.palette.error.main }} />
                                </IconButton>
                                <AnimatedIconButton
                                  onClick={submitForm}
                                  size='medium'
                                  animate={dirty}
                                >
                                  <SaveIcon color={dirty ? 'primary' : 'inherit'} />
                                </AnimatedIconButton>
                              </Box>
                            ) : (
                              <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                                <IconButton
                                  onClick={() => toggleEdit(index, values, remove)}
                                  size='medium'
                                >
                                  <EditNoteOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={async () => {
                                    try {
                                      await deleteExperience.mutate({
                                        siteId: site?.id || '',
                                        userId: user?.id || '',
                                        experienceId: values.experiences[index].id,
                                      });
                                      remove(index);
                                    } catch (e) {
                                      console.error(e);
                                    }
                                  }}
                                  size='medium'
                                >
                                  <DeleteForever />
                                </IconButton>
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                    ))}
                  {userProfile.isMe && (
                    <Box display='flex' justifyContent='center'>
                      <IconButton
                        onClick={() => {
                          push({
                            title: '',
                            company: '',
                            start_date: '',
                            end_date: '',
                            description: '',
                          });
                          setIsEditingArray([...isEditingArray, true]);
                        }}
                        size='small'
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Box>
                  )}
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Card>
  );
};
