import React, { useState } from 'react';
import * as Yup from 'yup';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Collapse,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useQueryClient } from 'react-query';
import { string } from 'yup';
import {
  getGetContentReportsQueryKey,
  getGetMyContentReportsQueryKey,
  useCreateContentReport,
} from '@juno/client-api';
import { ContentReport, ReasonEnum, StatusD4cEnum } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import DialogAriaWrapper from '../DialogAriaWrapper';

const validationSchema = Yup.object().shape({
  description: string()
    .max(400, 'Character limit: 400')
    .when('reason', { is: ReasonEnum.Other, then: string().required('Description is required') }),
});

const contentMap = [
  {
    type: 32,
    string: 'Discussion',
  },
  { type: 28, string: 'Post' },
  { type: 28, string: 'Comment' },
];

const ReasonMap = [
  {
    type: ReasonEnum.Illegal__Copywrited,
    string: 'Illegal / Copywrited',
  },
  {
    type: ReasonEnum.Harassment,
    string: 'Harassment',
  },
  {
    type: ReasonEnum.False_Information,
    string: 'False Information',
  },
  {
    type: ReasonEnum.Inappropriate,
    string: 'Inappropriate',
  },
  {
    type: ReasonEnum.Other,
    string: 'Other',
  },
];

const ReportContentDialog: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false);
  const { reportContent, setReportContent, site, user } = useSettings();
  const { reportContentDialogOpen, contentBeingReported } = reportContent;
  const createContentReport = useCreateContentReport();
  const queryClient = useQueryClient();

  const handleClose = () => {
    setReportContent({
      reportContentDialogOpen: false,
      contentBeingReported: { group: contentBeingReported?.group },
    });
    setSuccessfullySubmitted(false);
    setLoading(false);
  };

  const invalidateMyReports = () => {
    queryClient.invalidateQueries(getGetMyContentReportsQueryKey(site?.id || '', {}));
  };

  const initialValues = {
    id: '',
    object_id: contentBeingReported?.id || '',
    site: site?.id || '',
    reported_user: contentBeingReported?.author || '',
    reporting_user: contentBeingReported?.reporter || '',
    status: StatusD4cEnum.Needs_Review,
    reason: ReasonEnum.Illegal__Copywrited,
  } as ContentReport;

  if (reportContent.contentBeingReported?.group) {
    initialValues.group = reportContent.contentBeingReported.group;
  }

  return (
    <DialogAriaWrapper
      open={reportContentDialogOpen}
      onClose={handleClose}
      id='report_content_dialog'
    >
      <Formik
        key={'access-pass-form'}
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            setLoading(true);
            await createContentReport.mutateAsync({
              siteId: site?.id || '',
              parentType:
                reportContent.contentBeingReported?.type === 'Discussion' ? 'discussion' : 'thread',
              data: {
                ...values,
              },
            });
            invalidateMyReports();
            // this is going too fast, looks better with a bit of loading state
            setTimeout(() => {
              setSuccessfullySubmitted(true);
              setLoading(false);
            }, 500);
          } catch (e) {
            console.error(e);
          }
        }}
      >
        {(formik) => {
          const { values, setFieldValue, setTouched, isValid } = formik;
          const charactersLeft = 400 - (values.description?.length || 0);

          return (
            <Form>
              <Fade in={!successfullySubmitted}>
                <DialogTitle
                  sx={{
                    fontWeight: 600,
                    borderBottom: `1px solid #cac4d0`,
                  }}
                >
                  Why are you reporting this {contentBeingReported?.type}?
                </DialogTitle>
              </Fade>
              <DialogContent sx={{ width: 600, maxWidth: '85vw' }}>
                <Fade in={!successfullySubmitted}>
                  <FormControl sx={{ py: 2, width: '100%' }}>
                    <RadioGroup
                      aria-labelledby='report-content-form'
                      defaultValue={ReasonEnum.Illegal__Copywrited}
                      name='radio-buttons-group'
                      onChange={(e) => {
                        setFieldValue('description', '');
                        setTouched({ description: false });
                        setFieldValue('reason', e.target.value);
                      }}
                      sx={{
                        width: '100%',
                        px: 1,
                        '.MuiFormControlLabel-label': {
                          fontWeight: 600,
                        },
                      }}
                    >
                      {(Object.keys(ReasonEnum) as Array<keyof typeof ReasonEnum>).map((r) => (
                        <ContentReportItem
                          reason={ReasonEnum[r]}
                          charactersLeft={charactersLeft}
                          formik={formik}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Fade>
                <Fade
                  in={successfullySubmitted}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    width: '80%',
                  }}
                >
                  <Box textAlign='center'>
                    <Typography textAlign={'center'} variant='h5' mb={1}>
                      Thank you for your feedback.
                    </Typography>
                    <Typography textAlign={'center'} mb={2}>
                      A moderator has been notified and will review your report.
                    </Typography>
                    <Button onClick={handleClose} variant='outlined' sx={{ width: 167 }}>
                      Close
                    </Button>
                  </Box>
                </Fade>
              </DialogContent>
              <Fade in={!successfullySubmitted}>
                <DialogActions
                  sx={{
                    justifyContent: 'space-between',
                    pr: 2,
                    pl: 2,
                    py: 2,
                    borderTop: (theme) => `1px solid #cac4d0`,
                  }}
                >
                  <LoadingButton
                    type='submit'
                    disabled={!isValid || successfullySubmitted}
                    variant={'contained'}
                    sx={{ width: 167 }}
                    loading={loading}
                  >
                    Submit
                  </LoadingButton>
                  <Button onClick={handleClose} variant='outlined' sx={{ width: 167 }}>
                    Cancel
                  </Button>
                </DialogActions>
              </Fade>
            </Form>
          );
        }}
      </Formik>
    </DialogAriaWrapper>
  );
};

export default ReportContentDialog;

interface ContentReportItemProps {
  reason: ReasonEnum;
  charactersLeft: number;
  formik: FormikProps<ContentReport>;
}

const ContentReportItem: React.FC<ContentReportItemProps> = ({
  reason,
  charactersLeft,
  formik,
}) => {
  const { values, setFieldValue, setTouched, errors, touched } = formik;

  return (
    <>
      <FormControlLabel
        value={reason}
        control={<Radio />}
        disableTypography
        label={
          <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
            <Typography fontWeight='bold'>{reason}</Typography>
            <ArrowRightIcon
              sx={{
                mr: -2,
                transition: '200ms',
                transform: values.reason === reason ? 'rotate(90deg)' : 'rotate(0deg)',
              }}
            />
          </Stack>
        }
      />
      <Collapse
        in={values.reason === reason}
        sx={{
          mx: -1,
        }}
      >
        <Fade in={values.reason === reason}>
          <Box sx={{ mb: 3 }}>
            <TextField
              multiline
              rows={4}
              fullWidth
              inputProps={{ style: { fontSize: 15 } }}
              value={values.description}
              onChange={(e) => {
                setTouched({ description: true });
                setFieldValue('description', e.target.value);
              }}
              error={touched.description && !!errors.description}
              helperText={touched.description && errors.description}
              placeholder={`Please provide any additional information that may help us understand why you reported this content (${
                reason === ReasonEnum.Other ? 'Required' : 'Optional'
              }).`}
            />
            <Stack alignItems='flex-end'>
              <Typography variant='body2'>
                {charactersLeft >= 0 ? charactersLeft : 0} characters left
              </Typography>
            </Stack>
          </Box>
        </Fade>
      </Collapse>
    </>
  );
};
