import React, { useContext, useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import {
  AXIOS_INSTANCE,
  useGetCommunityGroups,
  useGetCommunityGroupsHierarchyAdmin,
  useGetCommunityGroupsHierarchyMember,
  useGetCourses,
  useGetFeatureConfigsPublic,
  useGetMe,
  useGetMyContentReports,
  useGetNavigationItems,
  useGetQuickLinks,
  useGetSidebarWidgets,
} from '@juno/client-api';
import { JunoUser, RoleEnum } from '@juno/client-api/model';
import { FeatureConfig } from '@juno/client-api/utils';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';
import { DefaultContext, defaultContextObject, useBreakpoint } from '@juno/utils';
import { PlatformContext } from './PlatformContext';

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);
  const [reportContent, setReportContent] = useState({
    reportContentDialogOpen: false,
  });
  React.useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  const platformContext = useContext(PlatformContext);
  const { data: junoUser } = useGetMe(
    platformContext?.platform?.id || '',
    {
      include: 'session_scheduled_attendees',
    },
    { query: { enabled: !!platformContext?.platform && auth.isAuthenticated } },
  );
  const [me, setMe] = useState<JunoUser | null>(null);

  useEffect(() => {
    if (junoUser) {
      setMe(junoUser);
    }
  }, [junoUser]);

  const { data: featureConfigs } = useGetFeatureConfigsPublic(platformContext?.site?.id || '');
  const { data: quickLinks } = useGetQuickLinks(
    platformContext?.site?.id || '',
    {},
    { query: { enabled: !!junoUser } },
  );
  const { data: sidebarWidgets } = useGetSidebarWidgets(
    platformContext?.site?.id || '',
    { order: 'order' },
    { query: { enabled: !!junoUser } },
  );
  const { data: navItems, isLoading: navItemsLoading } = useGetNavigationItems(
    platformContext?.site?.id || '',
    { order: 'order' },
    { query: { enabled: !!junoUser } },
  );
  const { data: contentReports, isLoading: contentReportsLoading } = useGetMyContentReports(
    platformContext?.site?.id || '',
    {},
    { query: { enabled: !!junoUser } },
  );

  useEffect(() => {
    const siteTitle = FeatureConfig.getThemeConfigTheme(featureConfigs)?.siteTitle;
    if (siteTitle && siteTitle !== document.title) {
      document.title = siteTitle;
    }
  }, [featureConfigs]);

  const { xs, sm, md } = useBreakpoint();
  const isSmallScreen = xs || sm || md;
  // layout state
  const [showMessenger, setShowMessenger] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(true);

  const enableContentQueries = !!platformContext?.site?.id && !!me;

  const { data: myGroups } = useGetCommunityGroups(
    platformContext?.site?.id || '',
    {
      filter: { id__in: me?.community_groups_member || [] },
    },
    { query: { enabled: enableContentQueries } },
  );

  const { data: myCourses } = useGetCourses(
    platformContext?.site?.id || '',
    {
      filter: { enrollments__user__id: me?.id || [], enrollments__date_completed__isnull: true },
    },
    { query: { enabled: enableContentQueries } },
  );

  const { data: impliedAdminOfGroups } = useGetCommunityGroupsHierarchyAdmin(
    platformContext?.site?.id || '',
    { query: { enabled: !!junoUser } },
  );
  const { data: impliedMemberOfGroups } = useGetCommunityGroupsHierarchyMember(
    platformContext?.site?.id || '',
    { query: { enabled: !!junoUser } },
  );
  const isAdmin = me?.role === RoleEnum.NUMBER_1;

  const downloadUrl = (url: string) => {
    // TODO: This will probably need updated once we begin building vnext apps, but that's for another push
    window.open(url, '_blank');
  };

  const getImageSrc = (url: string) => {
    // TODO: This will probably need updated once we begin building vnext apps, but that's for another push
    return url;
  };

  const openExternalUrl = (url: string) => {
    // TODO: This will probably need updated once we begin building vnext apps, but that's for another push
    window.location.href = url;
  };

  const enableLearning = !!featureConfigs?.find((c) => c.type === 'feature_flag')?.config?.learning;
  const enableLibrary = !!featureConfigs?.find((c) => c.type === 'feature_flag')?.config?.library;
  const restrictGZMVPFeatures = !!featureConfigs?.find((c) => c.type === 'feature_flag')?.config
    ?.restrict_gz_mvp;

  const appSettings = {
    isWidget: false,
    isClient: true,
    isAdmin,
    siteId: platformContext?.site?.id || '',
    routeKey: '',
    pageSlug: '',
    groupsSlug: '',
    groupSlug: '',
    coursesSlug: '',
    courseSlug: '',
    junoUser: undefined,
    user: me || undefined,
    setCurrentUser: () => {},
    site: platformContext?.site || undefined,
    setCurrentSite: () => {},
    onNavigate: undefined, // We can't use this in the app, so we'll instantiate it when we use it with a conditional
    downloadUrl: downloadUrl,
    getImageSrc: () => '',
    parseLinksFromDescription: () => {}, // unused in vnext
    openExternalUrl: openExternalUrl,
    sitePath: platformContext?.site?.slug || '',
    firehoseConfigurations: ANALYTICS_CONFIGURATION,
    preferredTags: [], // Making this empty array because we will have all tags accessible in client, plus tags are now auto synced from vnow to vnext
    isApp: false, // until we support full vnext app
    junoPlatformId: platformContext?.platform?.id || '',
    configs: featureConfigs,
    platformId: platformContext?.platform?.id || '',
    auth,
    showMessenger,
    setShowMessenger,
    platform: platformContext?.platform,
    showSideMenu,
    setShowSideMenu,
    myGroups,
    myCurrentCourses: myCourses,
    isSmallScreen,
    quickLinks: quickLinks || [],
    navItems: navItems || [],
    navItemsLoading,
    impliedAdminOfGroups,
    impliedMemberOfGroups,
    sidebarWidgets: sidebarWidgets || [],
    enableLearning,
    enableLibrary,
    restrictGZMVPFeatures,
    reportContent,
    setReportContent,
    myContentReports: contentReports || [],
  };

  switch (auth.activeNavigator) {
    case 'signinSilent':
      return <div>Signing you in...</div>;
    case 'signoutRedirect':
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div></div>;
  }

  if (auth.error) {
    return <div>Authentication Error... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    AXIOS_INSTANCE.defaults.headers.common['Authorization'] = 'Bearer ' + auth?.user?.access_token;
    return (
      <DefaultContext.Provider value={{ ...defaultContextObject, ...appSettings }}>
        {children}
      </DefaultContext.Provider>
    );
  } else {
    // This shouldn't happen... because if they aren't logged in they should get redirected
    return <div></div>;
  }
};

export default AppProvider;
