import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Formik } from 'formik';
import { nanoid } from 'nanoid';
import { Form, useNavigate } from 'react-router-dom';
import { string } from 'yup';
import { useCreateNewSession } from '@juno/client-api';
import { JunoUser, Session, TypeA36Enum } from '@juno/client-api/model';
import { MutationAction, onMutation, slugify, useSettings } from '@juno/utils';
import DialogAriaWrapper from '../DialogAriaWrapper';
import { GenericFormikDateSection, GenericFormikInput } from '../GenericInputComponents';

enum MeetingLengths {
  FIFTEEN = 15,
  THIRTY = 30,
  SIXTY = 60,
  NINETY = 90,
  ONE_TWENTY = 120,
}
interface MinimalSessionType {
  title: string;
  slug: string;
  date_start: string | Date;
  date_end: string | Date;
  moderators?: JunoUser[];
  scheduled_attendees?: JunoUser[];
}
interface CreatePrivateMeetingDialogProps {
  open: boolean;
  handleClose: () => void;
  selectedUser: JunoUser | null;
}
const CreatePrivateMeetingDialog: React.FC<CreatePrivateMeetingDialogProps> = ({
  open,
  handleClose,
  selectedUser,
}) => {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [scheduleForLater, setScheduleForLater] = useState(false);
  const [meetingLength, setMeetingLength] = useState(MeetingLengths.FIFTEEN);
  const { site, user: currentUser } = useSettings();
  const sessionCreate = useCreateNewSession(onMutation(MutationAction.CREATE, 'Meeting', () => {}));
  const handleCreateNewSession = async (session: Session) => {
    setIsSaving(true);
    try {
      if (session) {
        await sessionCreate.mutateAsync({ siteId: site?.id || '', data: session });
        setIsSaving(false);
        handleClose();
      }
    } catch (e) {
      console.error(e);
      setIsSaving(false);
      handleClose();
    }
  };
  const newSlug = useMemo(() => {
    return slugify(currentUser?.first_name + ' ' + currentUser?.last_name + '_meeting_' + nanoid());
  }, []);
  const INITIAL_FORM_STATE: MinimalSessionType = useMemo(() => {
    const dateStart = new Date();
    const dateEnd = new Date();
    dateEnd.setMinutes(dateStart.getMinutes() + MeetingLengths.FIFTEEN);
    return {
      title: '',
      slug: newSlug,
      date_start: dateStart,
      date_end: dateEnd,
      event_type: TypeA36Enum.NUMBER_1,
    };
  }, []);

  const validationSchema = Yup.object().shape({
    title: string().max(90, 'Title character limit is 90').required('Title is required'),
    date_start: Yup.date()
      .typeError('Must be a valid date')
      .required('Start date is required')
      .test('date_start', 'Start date must be before end date', function test(this, value) {
        if (!scheduleForLater) return true;
        if (!this?.parent?.date_end) return true;
        if (value && new Date(value).toString() !== 'Invalid Date') {
          return new Date(value) < new Date(this?.parent?.date_end);
        } else {
          return false;
        }
      }),
    date_end: Yup.date()
      .typeError('Must be a valid date')
      .required('End date is required')
      .test('date_end', 'End date must be after start date', function test(this, value) {
        if (!scheduleForLater) return true;
        if (value && new Date(value).toString() !== 'Invalid Date') {
          return new Date(value) > new Date(this?.parent?.date_start);
        } else {
          return false;
        }
      }),
  });

  return (
    <DialogAriaWrapper open={open} onClose={handleClose} id='create_private_meeting'>
      <Formik
        initialValues={{ ...INITIAL_FORM_STATE }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          setIsSaving(true);
          if (!scheduleForLater) {
            const dateStart = new Date();
            const dateEnd = new Date();
            dateEnd.setMinutes(dateEnd.getMinutes() + meetingLength);
            values.date_start = dateStart;
            values.date_end = dateEnd;
          }

          values.moderators = [currentUser || ({} as JunoUser)];
          values.scheduled_attendees = [currentUser || ({} as JunoUser)];

          const handleCreation = async () => {
            await handleCreateNewSession(values as Session);

            actions.resetForm();
            setScheduleForLater(false);
            setMeetingLength(MeetingLengths.FIFTEEN);
            setIsSaving(false);
            handleClose();

            navigate(`/${site?.slug}/sessions/${newSlug}`);
          };
          handleCreation();
        }}
        validateOnMount
        enableReinitialize={true}
      >
        {(props) => {
          const { values, handleChange, handleSubmit, setFieldValue, dirty, isValid, resetForm } =
            props;
          return (
            <Form>
              <DialogTitle>
                {`Start a private meeting with ${selectedUser?.first_name} ${selectedUser?.last_name}`}
              </DialogTitle>
              <DialogContent>
                <GenericFormikInput
                  label='Title'
                  name='title'
                  value={values.title || ''}
                  onChange={handleChange}
                  fullWidth
                  sx={{ mb: 2, mt: 1 }}
                />
                {!scheduleForLater && (
                  <FormControl fullWidth>
                    <InputLabel id='schedule-select-label'>Set a Meeting Length</InputLabel>
                    <Select
                      labelId='schedule-select-label'
                      name='meeting_length'
                      value={meetingLength}
                      onChange={(e) => {
                        setMeetingLength(e.target.value as MeetingLengths);
                      }}
                      fullWidth
                      sx={{ mb: 2, mt: 1 }}
                    >
                      <MenuItem value={MeetingLengths.FIFTEEN}>15 minutes</MenuItem>
                      <MenuItem value={MeetingLengths.THIRTY}>30 minutes</MenuItem>
                      <MenuItem value={MeetingLengths.SIXTY}>1 hour</MenuItem>
                      <MenuItem value={MeetingLengths.NINETY}>90 minutes</MenuItem>
                      <MenuItem value={MeetingLengths.ONE_TWENTY}>2 hours</MenuItem>
                    </Select>
                  </FormControl>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={scheduleForLater}
                      onChange={(e) => {
                        setScheduleForLater(e.target.checked);
                        if (e.target.checked) {
                          // check if we have a start date, if not, set it to the current date
                          if (!values.date_start) {
                            const newDateStart = new Date();
                            const newDateEnd = new Date();
                            newDateEnd.setMinutes(newDateStart.getMinutes() + meetingLength);
                            setFieldValue('date_start', newDateStart.toString());
                            setFieldValue('date_end', newDateEnd.toString());
                          }
                        }
                      }}
                    />
                  }
                  label='Schedule for later'
                />

                {scheduleForLater && (
                  <Grid container spacing={2} mb={2}>
                    <GenericFormikDateSection
                      dateFields={['date_start', 'date_end']}
                      props={props}
                    />
                  </Grid>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={isSaving}
                  onClick={(e) => {
                    e.stopPropagation();
                    resetForm();
                    handleClose();
                    setScheduleForLater(false);
                    setMeetingLength(MeetingLengths.FIFTEEN);
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  disabled={!(dirty && isValid)}
                  loading={isSaving}
                  variant='contained'
                  onClick={() => handleSubmit()}
                >
                  Save
                </LoadingButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </DialogAriaWrapper>
  );
};
export default CreatePrivateMeetingDialog;
