import React, { useEffect, useMemo, useState } from 'react';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import AdjustRoundedIcon from '@mui/icons-material/AdjustRounded';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

const useStyles = makeStyles((theme: Theme) => ({
  future: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main,
  },
  past: {
    backgroundColor: theme.palette.error.contrastText,
    color: theme.palette.error.main,
  },
  over: {
    backgroundColor: theme.palette.primary.background,
    color: theme.palette.primary.text,
  },
}));

interface GenericCountdownTimerProps {
  targetTime?: string | null;
  endTime?: string | null;
  onHitZero?: () => void;
  onHitNumber?: {
    number: number;
    callback: () => void;
  };
  countDownToEnd?: boolean;
}
const GenericCountdownTimer: React.FC<GenericCountdownTimerProps> = ({
  targetTime,
  endTime,
  onHitZero,
  onHitNumber,
  countDownToEnd = false,
}) => {
  const classes = useStyles();
  const [timeRemaining, setTimeRemaining] = useState<number>(0);

  useEffect(() => {
    const calculateTimeRemaining = () => {
      if (!targetTime) {
        return;
      }
      const now = new Date().getTime();
      const time = new Date(targetTime);
      const difference = time.getTime() - now;
      setTimeRemaining(difference);
      if (difference === 0) {
        onHitZero && onHitZero();
      }
      // need to calculate the number of seconds remaining
      const numSecsRemaining = Math.ceil(difference / 1000);
      if (onHitNumber && numSecsRemaining === onHitNumber.number) {
        onHitNumber.callback();
      }
    };

    calculateTimeRemaining();

    const intervalId = setInterval(calculateTimeRemaining, 1000);
    return () => clearInterval(intervalId);
  }, [targetTime]);

  const isFuture = timeRemaining > 0;
  const formatTime = (time: number): string => {
    const seconds = Math.floor(time / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const formattedTime = [];

    if (days > 0) {
      formattedTime.push(`${days}d`);
      if (hours % 24 > 0 || minutes % 60 > 0 || seconds % 60 > 0) {
        formattedTime.push(`${hours % 24}h`);
      }
      if (minutes % 60 > 0 || seconds % 60 > 0) {
        formattedTime.push(`${minutes % 60}m`);
      }
    } else {
      if (hours > 0) {
        formattedTime.push(`${hours}h`);
      }
      if (minutes > 0) {
        formattedTime.push(`${minutes % 60}m`);
      }
      // if ((seconds < 60 && minutes === 0) || (seconds % 59 === 0 && minutes != 0 && isFuture)) {
      // TODO double check this logic
      if ((seconds < 60 && minutes === 0) || (seconds % 59 === 0 && minutes <= 5 && isFuture)) {
        formattedTime.push(`${seconds % 60}s`);
      }
    }

    return formattedTime.join(' ');
  };

  // if we have an endTime, once the endTime is reached, we should display "Session Ended"
  if (endTime) {
    const now = new Date().getTime();
    const time = new Date(endTime);
    const difference = time.getTime() - now;
    if (difference <= 0) {
      return (
        <Typography
          variant='subtitle2'
          className={classes.over}
          padding={1}
          display='flex'
          alignItems='center'
        >
          Session Ended
        </Typography>
      );
    }
  }

  const timerText = () => {
    if (countDownToEnd) {
      return `Ends in ${formatTime(Math.abs(timeRemaining))}`;
    }
    return isFuture
      ? `Starts in ${formatTime(Math.abs(timeRemaining))}`
      : `Live for ${formatTime(Math.abs(timeRemaining))}`;
  };

  return (
    <Typography
      variant='subtitle2'
      className={isFuture ? classes.future : classes.past}
      display='flex'
      alignItems='center'
      padding={1}
      sx={{ borderRadius: 2 }}
    >
      {isFuture ? (
        <AccessTimeRoundedIcon fontSize='small' sx={{ mr: 1 }} />
      ) : (
        <AdjustRoundedIcon fontSize='small' sx={{ mr: 1 }} />
      )}
      {timerText()}
    </Typography>
  );
};
export default GenericCountdownTimer;
