import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Arrow = styled(Box)(({ theme }) => ({
  position: 'relative',
  margin: '0 auto',
  width: '16px',
  height: '100%',
}));

export const Curve = styled(Box)(({ theme }) => ({
  border: '2px solid #DADADA',
  borderColor: 'transparent transparent #DADADA #DADADA',
  height: 'calc(100% - 75px)',
  width: '16px',
  borderRadius: '0px 0px 0px 11px',
  borderTop: 0,
}));

export const Point = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '23px',
  top: 'calc(100% - 70px)',
  ':before': {
    border: '1.5px solid #DADADA',
    height: '8px',
    content: '""',
    position: 'absolute',
    top: '-8px',
    left: '-11px',
    transform: 'rotate(-50deg)',
    WebkitTransform: 'rotate(-306deg)',
    MozTransform: 'rotate(-100deg)',
    msTransform: 'rotate(-100deg)',
  },
  ':after': {
    border: '1.5px solid #DADADA',
    height: '8px',
    content: '""',
    position: 'absolute',
    top: '-12px',
    left: '-11px',
    transform: 'rotate(5deg)',
    WebkitTransform: 'rotate(-53deg)',
    MozTransform: 'rotate(12deg)',
    msTransform: 'rotate(12deg)',
  },
}));
