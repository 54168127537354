import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Divider, TextField, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useQueryClient } from 'react-query';
import {
  getGetFeatureConfigsPublicQueryKey,
  getGetFeatureConfigsQueryKey,
  useCreateFeatureConfig,
  useUpdateSiteFeatureConfig,
} from '@juno/client-api';
import { FeatureConfig, FeatureConfigConfig } from '@juno/client-api/model';
import { MutationAction, onMutation, useSettings } from '@juno/utils';

interface EmbedConfigConfig extends FeatureConfigConfig {
  embed: string;
}
export interface EmbedConfig extends FeatureConfig {
  config: EmbedConfigConfig;
}

const Embed: React.FC = () => {
  const { configs, site } = useSettings();
  const queryClient = useQueryClient();
  const [isSaving, setIsSaving] = useState(false);
  const updateConfig = useUpdateSiteFeatureConfig(
    onMutation(MutationAction.UPDATE, 'Embed Code', () => {}),
  );
  const createConfig = useCreateFeatureConfig(
    onMutation(MutationAction.CREATE, 'Embed Code', () => {}),
  );
  const embedConfig = configs?.find((config) => config.type === 'embed') as EmbedConfig;
  const embedConfigConfig = embedConfig?.config as EmbedConfigConfig;

  return (
    <Formik
      initialValues={embedConfigConfig || { embed: '' }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setIsSaving(true);
        if (embedConfig) {
          await updateConfig.mutateAsync({
            siteId: site?.id || '',
            configType: 'embed',
            data: { ...embedConfig, config: values },
          });
        } else {
          await createConfig.mutateAsync({
            siteId: site?.id || '',
            data: { id: '', type: 'embed', config: values },
          });
        }
        await queryClient.invalidateQueries(getGetFeatureConfigsQueryKey(site?.id || ''));
        await queryClient.invalidateQueries(getGetFeatureConfigsPublicQueryKey(site?.id || ''));
        setIsSaving(false);
      }}
      enableReinitialize
    >
      {(formik) => {
        return (
          <Card sx={{ p: 2, mt: 3, mb: 4, pb: 0 }}>
            <Typography variant='h5'>Embed</Typography>
            <Divider sx={{ mt: 2, mb: 4 }} />
            <Typography variant='body2' sx={{ mb: 4 }}>
              This code will be injected into the frontend of your site. Use it for chatbots or
              trackers. Only enter scripts that you trust into this input.
            </Typography>
            <TextField
              multiline
              rows={6}
              fullWidth
              sx={{ mb: 3 }}
              value={formik.values.embed}
              onChange={(e) => formik.setFieldValue('embed', e.target.value)}
            />
            <Box sx={{ textAlign: 'right' }}>
              <LoadingButton
                variant='contained'
                color='primary'
                sx={{ mb: 2 }}
                disabled={!formik.dirty}
                onClick={() => formik.handleSubmit()}
                loading={isSaving}
              >
                Save
              </LoadingButton>
            </Box>
          </Card>
        );
      }}
    </Formik>
  );
};

export default Embed;
