import { createContext, useContext } from 'react';
import { JunoUser } from '@juno/client-api/model';

const defaultMessengerContext = {
  isConversation: false,
  setIsConversation: (isConversation: boolean) => {},
  messengerTab: 0,
  setMessengerTab: (messengerTab: number) => {},
  currentChannel: '',
  setCurrentChannel: (currentChannel: string) => {},
  chat: undefined,
  maxChannelUsers: 10,
  addNewChannel: () => Promise.resolve(null),
  markRead: (channelId: string, timetoken?: string) => Promise.resolve(), // Updated to match the type definition
  removeUserFromChannel: (channelId: string, userId: string) => Promise.resolve(false),
  setChannelVisibility: (channelId: string, userId: string, hide: boolean) =>
    Promise.resolve(false),
  messengerMap: { channels: [] as Channel[] },
  createChannelThenSubscribe: (userIds: string[]) => Promise.resolve(null),
  subscribeToChannels: (channelIds: string[]) => {},
  isCreateNewConversation: false,
  setIsCreateNewConversation: (isCreateNewConversation: boolean) => {},
  currentConversationLastReadMessageId: '',
  setCurrentConversationLastReadMessageId: (messageId: string) => {},
  systemChannel: '',
};
export type MessengerMap = {
  channels: Channel[];
};

export type Channel = {
  id: string;
  name: string;
  userIds: string[];
  junoUsers: JunoUser[];
  messages: Message[];
  isHidden: boolean;
};

export interface Message {
  text: string;
  createdAt: string;
  userId: string;
  isNewMessage?: boolean;
}

type MessengerContextType = {
  isConversation: boolean;
  setIsConversation: (isConversation: boolean) => void;
  messengerTab: number;
  setMessengerTab: (messengerTab: number) => void;
  currentChannel: string;
  setCurrentChannel: (currentChannel: string) => void;
  maxChannelUsers: number;
  removeUserFromChannel: (channelId: string, userId: string) => Promise<boolean>;
  setChannelVisibility: (channelId: string, userId: string, hide: boolean) => Promise<boolean>;
  messengerMap: MessengerMap;
  createChannelThenSubscribe: (userIds: string[]) => Promise<string | null>;
  subscribeToChannels: (channelIds: string[]) => void;
  markRead: (channelId: string, timetoken?: string) => Promise<void>;
  isCreateNewConversation: boolean;
  setIsCreateNewConversation: (isCreateNewConversation: boolean) => void;
  currentConversationLastReadMessageId: string;
  setCurrentConversationLastReadMessageId: (messageId: string) => void;
  systemChannel: string;
};

export const MessengerContext = createContext<MessengerContextType>(
  defaultMessengerContext as MessengerContextType,
);

export const useMessengerContext = () => useContext(MessengerContext);
