import React, { Dispatch, SetStateAction, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Grid, IconButton } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { Reorder } from 'framer-motion';
import { useGetLessonPartResources } from '@juno/client-api';
import { CourseResource, LessonPart } from '@juno/client-api/model';
import { TEMP_ID_PREFIX } from '../QuestionBuilder';
import { Form, PayloadProps } from './Form';
import {
  AddResourceButton,
  Container,
  DeleteGridItem,
  DragIndicatorContainer,
  ResourceBox,
} from './styles';

interface ResourceBuilderProps {
  courseId: string;
  lessonId: string;
  siteId: string;
  lessonPartState: LessonPart;
  setLessonPartState: Dispatch<SetStateAction<LessonPart>>;
  isSaving: boolean;
  payloads: PayloadProps[];
  setPayloads: Dispatch<SetStateAction<PayloadProps[]>>;
}

export default function ResourceBuilder({
  courseId,
  lessonId,
  siteId,
  lessonPartState,
  setLessonPartState,
  isSaving,
  payloads,
  setPayloads,
}: ResourceBuilderProps) {
  const { data: resourceData, isLoading } = useGetLessonPartResources(
    siteId,
    courseId,
    lessonId,
    lessonPartState?.id || '',
  );

  useEffect(() => {
    setLessonPartState((old) => ({ ...old, resources: resourceData || [] }));
  }, [resourceData]);

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>,
  ) => {
    const target = e.target as HTMLInputElement;

    setLessonPartState((old) => {
      return {
        ...old,
        [target.name]: target.value,
      };
    });
  };

  const handleResourceChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>,
    resourceId: string,
  ) => {
    const target = e.target as HTMLInputElement;
    const value: string | number = target.value;

    const newResources =
      payloads?.map((resourcePayload, index) => {
        if (resourcePayload?.resource?.id === resourceId) {
          return { ...resourcePayload, [target.name]: value };
        } else return { ...resourcePayload };
      }) || [];
    setPayloads((old) => newResources);
  };

  const addResource = () => {
    setPayloads((old) => {
      const newResources = old || [];
      return [
        ...newResources,
        {
          resource: {
            id: `${TEMP_ID_PREFIX}${Date.now()}`,
            title: '',
            description: '',
            order: newResources.length,
          },
        },
      ];
    });
  };

  const deleteResource = (resourceId: string) => {
    setPayloads((old) => {
      const newResources = old.filter((payload) => payload?.resource.id !== resourceId) || [];
      return newResources.map((resource, idx) => ({ ...resource, order: idx }));
    });
  };

  const onReorder = (newOrder: PayloadProps[]) => {
    const newPayloads =
      newOrder.map((payload, order) => ({
        ...payload,
        resource: { ...payload.resource, order },
      })) || [];
    setPayloads((old) => newPayloads);
  };
  const updatePayloadByIndex = (
    payload: PayloadProps | ((old: PayloadProps) => PayloadProps),
    index: number,
  ) => {
    setPayloads((old) => {
      if (typeof payload === 'function') {
        const newPayloads = old || [];
        newPayloads[index] = payload(newPayloads[index]);
        return [...newPayloads];
      } else {
        const newPayloads = old || [];
        newPayloads[index] = payload;
        return [...newPayloads];
      }
    });
  };
  return (
    <Container container spacing={2}>
      <Grid item xs={12}>
        <Reorder.Group
          axis='y'
          values={payloads}
          as='div'
          onReorder={(newOrder) => onReorder(newOrder)}
          style={{ listStyleType: 'none' }}
        >
          {payloads?.map((payload, index) => {
            return (
              <Reorder.Item key={`lesson_resource_form_${payload?.resource?.id}_`} value={payload}>
                <ResourceBox>
                  <Grid container>
                    <Grid item xs={1}>
                      <DragIndicatorContainer>
                        <DragIndicatorIcon />
                      </DragIndicatorContainer>
                    </Grid>
                    <Grid item xs={10}>
                      <Form
                        isLoading={isLoading}
                        isNew={payload.resource.id.startsWith(TEMP_ID_PREFIX)}
                        isSaving={isSaving}
                        payload={payload}
                        setPayload={(payload) => updatePayloadByIndex(payload, index)}
                      />
                    </Grid>
                    <DeleteGridItem item xs={1}>
                      <IconButton
                        aria-label='delete'
                        onClick={() => deleteResource(payload?.resource.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </DeleteGridItem>
                  </Grid>
                </ResourceBox>
              </Reorder.Item>
            );
          })}
        </Reorder.Group>
      </Grid>
      <Grid item xs={12}>
        <AddResourceButton onClick={addResource} variant='contained' color='primary' fullWidth>
          Add Resource
        </AddResourceButton>
      </Grid>
    </Container>
  );
}
