import React, { useLayoutEffect, useRef } from 'react';

interface DangerousElementProps {
  markup: string;
}

const DangerousElement: React.FC<DangerousElementProps> = ({ markup }) => {
  const elRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const range = document.createRange();
    if (elRef.current) {
      range.selectNode(elRef.current);
      const documentFragment = range.createContextualFragment(markup);

      // Inject the markup, triggering a re-run!
      elRef.current.innerHTML = '';
      elRef.current.append(documentFragment);
    }
  }, []);

  return <div ref={elRef} dangerouslySetInnerHTML={{ __html: markup }}></div>;
};

export default DangerousElement;
