import React, { useState } from 'react';
import { Card, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  getGetCoursesQueryKey,
  useCreateCourse,
  useGetCourses,
  useUploadScormCourse,
} from '@juno/client-api';
import { Course, Site } from '@juno/client-api/model';
import { GridV2 } from '@juno/modules';
import { Container, DefaultSearchSortFilter } from '@juno/ui';
import { usePagesSearchFilter } from '@juno/utils';
import FormDialog, { CoursesForm } from './FormDialog';
import { CourseType } from './enums';

interface CoursesAdminProps {
  site?: Site;
}
const CoursesAdmin: React.FC<CoursesAdminProps> = ({ site }) => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [, , searchFilter, setSearch] = usePagesSearchFilter();
  const [sort, setSort] = useState('title');
  const { data: courses, isFetching: isLoading } = useGetCourses(site?.id || '', {
    filter: { ...searchFilter },
    order: sort,
  });

  const createCourse = useCreateCourse();
  const scormCreate = useUploadScormCourse();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const gridData = courses?.map((course) => ({
    id: course.id,
    title: course.title || '',
    abstract: course.abstract || '',
    icon: course.icon || '',
    slug: course.slug || '',
  }));

  const handleSaveCourse = async (course: CoursesForm | undefined) => {
    if (!course) return;
    const data = { title: course.title, slug: course.slug } as Course;
    try {
      await createCourse.mutateAsync({ siteId: site?.id || '', data }).then((newCourse) => {
        if (course.metadata.type === CourseType.SCORM && !!course.uploaded_file) {
          scormCreate
            .mutateAsync({
              siteId: site?.id || '',
              courseId: newCourse.id,
              data: { file_path: course.uploaded_file },
            })
            .then(() => {
              navigate(`/${site?.slug}/admin/learning/${newCourse.slug}`);
            });
        } else {
          navigate(`/${site?.slug}/admin/learning/${newCourse.slug}`);
        }
      });
      await queryClient.invalidateQueries(getGetCoursesQueryKey(site?.id || ''));
    } catch (e) {
      console.error(e);
    }
    setCreateDialogOpen(false);
  };

  return (
    <Container>
      <Card sx={{ p: 2, mb: 4 }}>
        <Typography variant='h5' sx={{ mb: 0 }}>
          Courses
        </Typography>
        <Divider sx={{ my: 2, mx: -0, mb: 4 }} />
        <DefaultSearchSortFilter
          buttonDisabled={false}
          buttonText={'New Course'}
          onClickButton={() => setCreateDialogOpen(true)}
          setSearch={(value: string) => setSearch(value)}
          setSort={setSort}
          sort={sort}
          showFilter={false}
          sortOptions={[
            { value: 'title', label: 'Alphabetical' },
            { value: 'date_created', label: 'Date Created' },
          ]}
        />
        <Stack direction='row' flexWrap={'wrap'} gap={2}>
          <GridV2 isLoading={isLoading} items={gridData || []} type='course' isAdmin={true} />
          <FormDialog
            open={createDialogOpen}
            onClose={() => setCreateDialogOpen(false)}
            onSave={(course: CoursesForm | undefined) => handleSaveCourse(course)}
            isSaving={createCourse.isLoading}
          />
        </Stack>
      </Card>
    </Container>
  );
};

export default CoursesAdmin;

export const SkeletonPagesTile: React.FC = () => {
  return (
    <Grid item xs={12} md={3} lg={2}>
      <Skeleton variant='rectangular' height={160} width={235} sx={{ borderRadius: 2 }} />
      <Skeleton variant='text' height={20} sx={{ mt: 1, width: '50%' }} />
      <Skeleton variant='text' height={16} sx={{ width: '40%' }} />
    </Grid>
  );
};
