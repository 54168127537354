import { useGetAllSpeakers } from '@juno/client-api';
import { filterObjectBySubstring } from '../Content/utils';

export const useSpeakersHook = (
  siteId: string,
  settings: any,
  dataModel: string,
  filter: any,
  filterStr: string,
  debouncedSearch: string | undefined = undefined,
) => {
  const { data: speakers, isLoading: speakersLoading } = useGetAllSpeakers(
    siteId,
    {
      filter: {
        ...filterObjectBySubstring(filter, [
          'description',
          'id',
          'image',
          'name',
          'sessions',
          'site',
          'site_id',
          'slug',
          'tags',
          'user',
          'user_id',
        ]),
        name__icontains: debouncedSearch,
      },
    },
    { query: { enabled: dataModel === 'Speaker' && !filterStr.includes('{{USER_ID}}') } },
  );

  return {
    speakers,
    speakersLoading,
  };
};
