import { ANALYTICS_DASHBOARD_CATEGORIES } from '@juno/constants';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';

const { FIREHOSE_OBJECTS, FIREHOSE_CATEGORIES, FIREHOSE_ACTIONS } = ANALYTICS_CONFIGURATION;
export interface WidgetModel {
  id: string;
  title: string;
  subTitle?: string;
  vars?: {
    [key: string]: string;
  };
  graph_path?: string;
  report_path?: string;
  filename_prefix?: string;
  key?: 'numUsers' | 'numOccurred' | 'numContent' | 'sumMetric';
  showAggregate?: boolean;
}

export interface CategoryModel {
  id: string;
  title: string;
  widgets: WidgetModel[];
}

export const CATEGORIES: CategoryModel[] = [
  {
    id: ANALYTICS_DASHBOARD_CATEGORIES.USER_OVERVIEW.id,
    title: ANALYTICS_DASHBOARD_CATEGORIES.USER_OVERVIEW.title,
    widgets: [
      {
        id: 'active_users_widget',
        title: 'Active Users',
        subTitle: 'Users active within your specified timeframe.',
        vars: {
          // Any activity allowed.
          distinct_user: 'true',
        },
        key: 'numUsers',
        graph_path: 'activity',
        report_path: 'user',
        filename_prefix: 'JUNO - Active Users',
      },
      {
        id: 'new_users_widget',
        title: 'New Users',
        subTitle: 'New Users created within your specified timeframe.',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_CREATE.value,
          category: FIREHOSE_CATEGORIES.CATEGORY_USER,
          platform_level: 'true',
        },
        graph_path: 'activity',
        report_path: 'user',
        filename_prefix: 'JUNO - New Users',
      },
      // {
      //   id: 'user_activity_points_widget',
      //   title: 'User Activity Points',
      //   subTitle: 'Activity Points earned by Users within your specified timeframe.',
      //   vars: {
      //     action: 'Earn XP',
      //     category: 'User',
      //   },
      //   key: 'sumMetric',
      //   graph_path: 'activity',
      //   report_path: 'activity',
      //   filename_prefix: 'JUNO - User Activity Points',
      // },
    ],
  },
  {
    id: ANALYTICS_DASHBOARD_CATEGORIES.EVENT_ACTIVITY.id,
    title: ANALYTICS_DASHBOARD_CATEGORIES.EVENT_ACTIVITY.title,
    widgets: [
      {
        id: 'live_session_view_widget',
        title: 'Session Views',
        subTitle: 'Sessions that were viewed',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_VIEW.value,
          category: `Content - ${FIREHOSE_CATEGORIES.CATEGORY_LIVE_SESSION}`,
        },
        graph_path: 'live_session',
        report_path: 'activity',
        filename_prefix: 'JUNO - Live Sessions Viewed',
      },
      {
        id: 'live_session_join_widget',
        title: 'Session Joins',
        subTitle: 'Sessions that were joined',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_JOIN.value,
          category: `Content - ${FIREHOSE_CATEGORIES.CATEGORY_LIVE_SESSION}`,
        },
        graph_path: 'live_session',
        report_path: 'activity',
        filename_prefix: 'JUNO - Live Sessions Joined',
      },
      {
        id: 'live_session_attend_widget',
        title: 'Session Attends',
        subTitle: 'Sessions that were attended',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_ATTEND.value,
          category: `Content - ${FIREHOSE_CATEGORIES.CATEGORY_LIVE_SESSION}`,
        },
        graph_path: 'live_session',
        report_path: 'activity',
        filename_prefix: 'JUNO - Live Sessions Attended',
      },
    ],
  },
  // {
  //   id: ANALYTICS_DASHBOARD_CATEGORIES.PARTNER_INTERACTION.id,
  //   title: ANALYTICS_DASHBOARD_CATEGORIES.PARTNER_INTERACTION.title,
  //   widgets: [
  //     {
  //       // status: done
  //       // tested: done
  //       id: 'partner_page_views_widget',
  //       title: 'Partner Page Views',
  //       subTitle: 'Total Partner page views within your specified timeframe. (Unique by user)',
  //       vars: {
  //         action: 'View',
  //         category: 'Content - Partner',
  //       },
  //       graph_path: 'activity',
  //       report_path: 'activity',
  //       filename_prefix: 'JUNO - Partner Page Views',
  //       showAggregate: true,
  //     },
  //     {
  //       // status: done
  //       // tested: done
  //       id: 'partner_scheduled_meetings_widget',
  //       title: 'Partner Mtgs Scheduled',
  //       subTitle: 'Total scheduled meetings with Partners within your specified timeframe.',
  //       vars: {
  //         action: 'Meeting Scheduled',
  //         category: 'Partner',
  //       },
  //       graph_path: 'activity',
  //       report_path: 'activity',
  //       filename_prefix: 'JUNO - Partner Meetings Scheduled',
  //       showAggregate: true,
  //     },
  //     {
  //       // status: done
  //       // tested: done
  //       id: 'partner_chats_initiated_widget',
  //       title: 'Partner Chats Initiated',
  //       subTitle: 'Total User chats started with Partners within your specified timeframe.',
  //       vars: {
  //         action: 'Partner Chat Started',
  //         category: 'Message',
  //       },
  //       graph_path: 'activity',
  //       report_path: 'activity',
  //       filename_prefix: 'JUNO - Partner Chats Initiated',
  //       showAggregate: true,
  //     },
  //   ],
  // },
  {
    id: ANALYTICS_DASHBOARD_CATEGORIES.COMMUNITY_PARTICIPATION.id,
    title: ANALYTICS_DASHBOARD_CATEGORIES.COMMUNITY_PARTICIPATION.title,
    widgets: [
      {
        id: 'forum_views_widget',
        title: 'Discussions Views',
        subTitle: 'Total user views of discussions within your specified timeframe.',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_VIEW.value,
          category: FIREHOSE_CATEGORIES.CATEGORY_FORUM,
        },
        graph_path: 'activity',
        report_path: 'activity',
        filename_prefix: 'JUNO - Forum Views',
        showAggregate: true,
      },
      {
        id: 'forum_posts_widget',
        title: 'Discussion Posts',
        subTitle: 'Total discussion posts within your specified timeframe.',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_POST.value,
          category: FIREHOSE_CATEGORIES.CATEGORY_FORUM,
        },
        graph_path: 'activity',
        report_path: 'activity',
        filename_prefix: 'JUNO - Discussion Posts',
        showAggregate: true,
      },
      {
        id: 'forum_comments_widget',
        title: 'Discussion Comments',
        subTitle: 'Total new comments on discussion posts within your specified timeframe.',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_COMMENT.value,
          category: FIREHOSE_CATEGORIES.CATEGORY_FORUM,
        },
        graph_path: 'activity',
        report_path: 'activity',
        filename_prefix: 'JUNO - Discussion Comments',
        showAggregate: true,
      },
      {
        id: 'forum_topic_follows_widget',
        title: 'Discussion Follows',
        subTitle: 'Total new discussion follows within your specified timeframe.',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_FOLLOW.value,
          category: FIREHOSE_CATEGORIES.CATEGORY_FORUM,
        },
        graph_path: 'activity',
        report_path: 'activity',
        filename_prefix: 'JUNO - Discussion Follows',
        showAggregate: true,
      },
      {
        id: 'social_feed_posts_widget',
        title: 'Social Feed Posts',
        subTitle: 'Social Feed activity within your specified timeframe.',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_POST.value,
          category: FIREHOSE_CATEGORIES.CATEGORY_FEED,
        },
        graph_path: 'activity',
        report_path: 'activity',
        filename_prefix: 'JUNO - Social Feed Posts',
        showAggregate: true,
      },
      {
        id: 'group_page_views_widget',
        title: 'Group Page Views',
        subTitle: 'Total User Group views within your specified timeframe.',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_VIEW.value,
          category: FIREHOSE_CATEGORIES.CATEGORY_COMMUNITY,
        },
        graph_path: 'activity',
        report_path: 'activity',
        filename_prefix: 'JUNO - Group Page Views',
        showAggregate: true,
      },
    ],
  },
  {
    id: ANALYTICS_DASHBOARD_CATEGORIES.LEARNING_ENGAGEMENT.id,
    title: ANALYTICS_DASHBOARD_CATEGORIES.LEARNING_ENGAGEMENT.title,
    widgets: [
      {
        id: 'courses_started_widget',
        title: 'Courses Started',
        subTitle: 'Below is a list of Courses by starts within your specified timeframe.',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_START.value,
          category: `Content - ${FIREHOSE_CATEGORIES.CATEGORY_COURSE}`,
        },
        graph_path: 'activity',
        report_path: 'activity',
        filename_prefix: 'JUNO - Courses Started',
        showAggregate: true,
      },
      {
        id: 'courses_enrolled_widget',
        title: 'Courses Enrolled',
        subTitle: 'Below is a list of Courses by enrollment within your specified timeframe.',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_ENROLL.value,
          category: `Content - ${FIREHOSE_CATEGORIES.CATEGORY_COURSE}`,
        },
        graph_path: 'activity',
        report_path: 'activity',
        filename_prefix: 'JUNO - Courses Enrolled',
        showAggregate: true,
      },
      {
        id: 'courses_completed_widget',
        title: 'Courses Completed',
        subTitle: 'Below is a list of Courses completed within your specified timeframe.',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_COMPLETE.value,
          category: `Content - ${FIREHOSE_CATEGORIES.CATEGORY_COURSE}`,
        },
        graph_path: 'activity',
        report_path: 'activity',
        filename_prefix: 'JUNO - Courses Completed',
        showAggregate: true,
      },
      {
        id: 'on_demand_library_widget',
        title: 'OnDemand / Library Views',
        subTitle: 'Total views within your specified timeframe.',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_VIEW.value,
          category: `Content - ${FIREHOSE_CATEGORIES.CATEGORY_LIBRARY}`,
        },
        graph_path: 'activity',
        report_path: 'activity',
        filename_prefix: 'JUNO - OnDemand_Library Views',
        showAggregate: true,
      },
      {
        id: 'on_demand_comments_widget',
        title: 'OnDemand / Library Comments',
        subTitle: 'Total comments within your specified timeframe.',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_COMMENT.value,
          category: `Content - ${FIREHOSE_CATEGORIES.CATEGORY_LIBRARY}`,
        },
        graph_path: 'activity',
        report_path: 'activity',
        filename_prefix: 'JUNO - OnDemand_Library Comments',
        showAggregate: true,
      },
      {
        id: 'ce_credits_earned_widget',
        title: 'Education Credits Earned',
        subTitle: 'Total education credits earned within your specified timeframe.',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_EARN.value,
          category: FIREHOSE_CATEGORIES.CATEGORY_CONTINUING_EDUCATION,
        },
        graph_path: 'activity',
        report_path: 'activity',
        filename_prefix: 'JUNO - Education Credits Earned',
        showAggregate: true,
      },
      // {
      //   // status:
      //   // tested:
      //   id: 'certificates_widget',
      //   title: 'Certificates',
      // },
      // {
      //   // status:
      //   // tested:
      //   id: 'ce_credits_earned_widget',
      //   title: 'Education Credits Earned',
      // },
    ],
  },
  // {
  //   id: ANALYTICS_DASHBOARD_CATEGORIES.NETWORKING_ENGAGEMENT.id,
  //   title: ANALYTICS_DASHBOARD_CATEGORIES.NETWORKING_ENGAGEMENT.title,
  //   widgets: [
  //     {
  //       // status: done
  //       // tested: done
  //       // https://junolive.atlassian.net/browse/JL-49 <---- need to look into this
  //       id: '1_1_dm_conversations_widget',
  //       title: '1:1 DM Conversations',
  //       vars: {
  //         action: 'Chat Started',
  //         category: 'Message',
  //       },
  //       graph_path: 'activity',
  //       report_path: 'activity',
  //       filename_prefix: 'JUNO - 1:1 DM Conversations',
  //     },
  //     {
  //       // status: done
  //       // tested: done
  //       id: '1_1_meetings_scheduled_widget',
  //       title: '1:1 Mtgs Scheduled',
  //       vars: {
  //         action: 'Meeting Scheduled',
  //         category: '1-1 Meeting',
  //       },
  //       graph_path: 'activity',
  //       report_path: 'activity',
  //       filename_prefix: 'JUNO - 1:1 Meetings Scheduled',
  //     },
  //     // { id: '1_1_meetings_executed_widget', title: '1:1 Mtgs Executed' },
  //   ],
  // },
  {
    id: ANALYTICS_DASHBOARD_CATEGORIES.ECOMMERCE_ACTIVITY.id,
    title: ANALYTICS_DASHBOARD_CATEGORIES.ECOMMERCE_ACTIVITY.title,
    widgets: [
      {
        id: 'purchases_widget',
        title: 'Purchases',
        vars: {
          action: FIREHOSE_ACTIONS.ACTION_PAYMENT_SUCCESS.value,
          category: FIREHOSE_CATEGORIES.CATEGORY_ECOMMERCE,
        },
        graph_path: 'activity',
        report_path: 'activity',
        filename_prefix: 'JUNO - User Purchases',
      },
    ],
  },
];
