import React, { useState } from 'react';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import {
  batchAttendUnattendSession,
  useCreateSessionMedia,
  useDeleteSession,
  useDeleteSessionMedia,
  useGetSession,
  useGetSessionMedia,
  useUpdateSession,
  useUpdateSessionMedia,
} from '@juno/client-api';
import { Session } from '@juno/client-api/model';
import { AdminEditPanelHeader, Container, DialogAriaWrapper, JunoSpin } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import Archives from './Archives/Archives';
// import EducationCreditSection from '../../../../../learning/src/lib/admin/CoursePanel/CourseInfo/helpers/EducationCreditSection';
import SessionInfo from './SessionInfo';
import validationSchema from './validationSchema';

const getTabs = (
  formikProps: FormikProps<any>,
  setDeleteDialogOpen: React.Dispatch<React.SetStateAction<boolean>>,
  sessionData?: Session,
) => {
  return [
    {
      slug: 'info',
      key: 'info',
      icon: <DashboardOutlinedIcon sx={{ mr: 2 }} />,
      label: 'Session Info',
      panel: <SessionInfo formikProps={formikProps} setDeleteDialogOpen={setDeleteDialogOpen} />,
    },
    {
      slug: 'breakouts',
      key: 'archives',
      icon: <FeaturedPlayListOutlinedIcon sx={{ mr: 2 }} />,
      label: 'Archives',
      panel: <Archives sessionData={sessionData} />,
    },
  ];
};
interface TabProps {
  slug: string;
  key: string;
  icon: React.ReactNode;
  label: string;
  panel: React.ReactNode;
}

const SessionPanel: React.FC = () => {
  const { site } = useSettings();
  const siteId = site?.id || '';
  const { sessionSlug = '' } = useParams<{ sessionSlug: string }>();
  const {
    data: sessionData,
    isLoading: sessionLoading,
    refetch: refetchSession,
  } = useGetSession(
    siteId,
    sessionSlug,
    {},
    {
      query: {
        enabled: !!site?.id,
      },
    },
  );

  // TODO getSessionMedia
  const {
    data: sessionMedia,
    isLoading: sessionMediaLoading,
    refetch: refetchSessionMedia,
  } = useGetSessionMedia(
    siteId,
    sessionData?.id || '',
    {},
    {
      query: {
        enabled: !!sessionData?.id && !!site?.id,
      },
    },
  );

  const refetch = () => {
    refetchSession();
    refetchSessionMedia();
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const navigate = useNavigate();

  const sessionUpdate = useUpdateSession(onMutation(MutationAction.UPDATE, 'Session', refetch));
  const sessionDelete = useDeleteSession(onMutation(MutationAction.DELETE, 'Session'));

  const mediaCreate = useCreateSessionMedia(onMutation(MutationAction.CREATE, '', refetch));
  const mediaUpdate = useUpdateSessionMedia(onMutation(MutationAction.UPDATE, '', refetch));
  const mediaDelete = useDeleteSessionMedia(onMutation(MutationAction.DELETE, '', refetch));

  const [currentTab, setCurrentTab] = useState(0);
  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      await sessionDelete.mutateAsync({ siteId, sessionId: sessionData?.id || '' });
      setDeleteLoading(false);
      navigate(-1);
    } catch (e) {
      setDeleteLoading(false);
      console.error(e);
    }
  };

  if (!sessionData || sessionLoading || sessionMediaLoading) {
    return <JunoSpin />;
  }

  return (
    <Container>
      <Formik
        key={'session-edit-formik'}
        enableReinitialize={true}
        initialValues={{ ...sessionData, media: sessionMedia }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            // compare sessionMedia to values.media, if we removed any, delete them
            const mediaToDelete = sessionMedia?.filter(
              (media) => !values.media?.find((m) => m.id === media.id),
            );
            if (mediaToDelete?.length) {
              await Promise.all(
                mediaToDelete.map(async (media) => {
                  await mediaDelete.mutateAsync({
                    siteId: siteId,
                    sessionId: sessionData.id,
                    mediaId: media.id,
                  });
                }),
              );
            }
            // if we added any, create them
            const mediaToAdd = values.media?.filter(
              (media) => !sessionMedia?.find((m) => m.id === media.id),
            );
            if (mediaToAdd?.length) {
              await Promise.all(
                mediaToAdd.map(async (media) => {
                  await mediaCreate.mutate({
                    siteId: siteId,
                    sessionId: sessionData.id,
                    data: {
                      ...media,
                      media_item: { ...media.media_item, url: media.media_item.url.split('?')[0] },
                    },
                  });
                }),
              );
            }
            // check if we updated any by comparing all attributes of each
            const mediaToUpdate = values.media?.filter((media) => {
              const existingMedia = sessionMedia?.find((m) => m.id === media.id);
              if (!existingMedia) return false;
              return JSON.stringify(existingMedia) !== JSON.stringify(media);
            });

            if (mediaToUpdate?.length) {
              await Promise.all(
                mediaToUpdate.map(async (media) => {
                  await mediaUpdate.mutateAsync({
                    siteId: siteId,
                    sessionId: sessionData.id,
                    mediaId: media.id,
                    data: {
                      ...media,
                      media_item: { ...media.media_item, url: media.media_item.url.split('?')[0] },
                    },
                  });
                }),
              );
            }

            const attendeeArray: { user_id: string; is_add: boolean }[] = [];
            const newArray = values.scheduled_attendees || [];
            const originalArray = sessionData.scheduled_attendees || [];
            const usersAdded = newArray.filter(
              (newUser) => !originalArray.some((user) => user.id === newUser.id),
            );
            const usersRemoved = originalArray.filter(
              (oldUser) => !newArray.some((user) => user.id === oldUser.id),
            );

            usersAdded.forEach((user) => {
              attendeeArray.push({ user_id: user.id, is_add: true });
            });

            usersRemoved.forEach((user) => {
              attendeeArray.push({ user_id: user.id, is_add: false });
            });

            await batchAttendUnattendSession(siteId, sessionData.id, attendeeArray);
            values.scheduled_attendees = undefined;

            await sessionUpdate.mutateAsync({
              siteId: siteId,
              sessionId: sessionData?.id || '',
              data: values,
            });
            setSubmitting(false);
            // TODO leaving this here to see if it causes downstream issues. It was causing a bug where the form would reset to old values before the new ones were returned.
            // resetForm({
            //   values: {
            //     ...newSession,
            //     media: [
            //       ...(values.media || ([] as SessionMedia[])),
            //       ...(mediaToAdd ? mediaToAdd : ([] as SessionMedia[])),
            //       ...(mediaToUpdate ? mediaToUpdate : ([] as SessionMedia[])),
            //     ],
            //   },
            // });
          } catch (e) {
            setSubmitting(false);
            console.error(e);
          }
        }}
      >
        {(props) => {
          const {
            values,
            errors,
            handleReset,
            setFieldValue,
            handleChange,
            setFieldTouched,
            handleSubmit,
            handleBlur,
            isSubmitting,
            dirty,
          } = props;
          const tabs = getTabs(props, setDeleteDialogOpen, sessionData);
          return (
            <Form>
              <Card>
                <AdminEditPanelHeader
                  bannerImageUrl={values.banner}
                  shouldShow={dirty && Object.values(errors).length === 0}
                  title={`Editing ${values.title}`}
                  onClickDiscard={handleReset}
                  onClickSave={handleSubmit}
                  onClickGoBack={() => {
                    navigate(-1);
                  }}
                  isSubmitting={isSubmitting}
                />
                <Box p={1}>
                  <Tabs
                    value={currentTab}
                    onChange={(e, value) => {
                      setCurrentTab(value);
                    }}
                  >
                    {tabs.map((tab, idx) => (
                      <Tab
                        key={tab.key}
                        id={`session-tab-${idx}`}
                        aria-controls={`session-tabpanel-${idx}`}
                        sx={{
                          textTransform: 'none',
                        }}
                        label={<>{tab.label}</>}
                      />
                    ))}
                  </Tabs>
                </Box>
              </Card>
              {tabs.map((tab, idx) => (
                <Box
                  key={tab.key}
                  role='tabpanel'
                  hidden={currentTab !== idx}
                  id={`session-tabpanel-${idx}`}
                  aria-labelledby={`session-tab-${idx}`}
                  sx={{ mt: 2, minHeight: '100vh' }}
                >
                  {currentTab === idx && <Box pt={1}>{tab.panel}</Box>}
                </Box>
              ))}

              <DialogAriaWrapper
                id={'delete_session_confirm'}
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
              >
                <DialogTitle id='delete_session_confirm-dialog-title'>Delete Session</DialogTitle>
                <DialogContent id='delete_session_confirm-dialog-description'>
                  Are you sure you would like to delete the session {values?.title}?
                </DialogContent>
                <DialogActions>
                  <Button
                    aria-label='Cancel delete session'
                    sx={{ textTransform: 'none' }}
                    onClick={() => setDeleteDialogOpen(false)}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    aria-label='Confirm delete session'
                    sx={{ textTransform: 'none' }}
                    variant={'contained'}
                    loading={deleteLoading}
                    onClick={handleDelete}
                  >
                    Delete
                  </LoadingButton>
                </DialogActions>
              </DialogAriaWrapper>

              {/* {openLoseChanges && (
              <LoseChangesDialog
                handleCancel={() => {
                  setOpenLoseChanges(false);
                }}
                handleDiscard={() => {
                  setOpenLoseChanges(false);
                  handleReset();
                }}
                handleSave={() => {
                  setOpenLoseChanges(false);
                  handleSubmit();
                }}
              />
            )} */}
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};
export default SessionPanel;
