import { JunoSpin } from '@juno/ui';
import { useSettings } from '@juno/utils';
import AutomationTool from './AutomationTool';

const AutomationWidget: React.FC = () => {
  const { site, user } = useSettings();

  const isLoading = !site || !user;

  if (isLoading) return <JunoSpin />;

  return <AutomationTool />;
};

export default AutomationWidget;
