import React, { useMemo, useState } from 'react';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  DialogContent,
  DialogTitle,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useQueryClient } from 'react-query';
import { useDebounce } from 'usehooks-ts';
import {
  getGetSingleUnsubscribeGroupUnsubscribesQueryKey,
  useCreateUnsubscribeGroupUnsubscribes,
  useGetAllUsers,
  useGetSingleUnsubscribeGroupUnsubscribes,
} from '@juno/client-api';
import { JunoUser } from '@juno/client-api/model';
import { DialogAriaWrapper } from '@juno/ui';
import { MutationAction, calculateUserSearchFilters, onMutation, useSettings } from '@juno/utils';
import DeleteUnsubscribeDialog from './DeleteUnsubscribeDialog';

interface GroupUnsubscribesDialogProps {
  groupId: string;
  groupName: string;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const GroupUnsubscribesDialog = ({
  groupId,
  groupName,
  handleClose,
}: GroupUnsubscribesDialogProps) => {
  // Variables
  const { site } = useSettings();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { CREATE } = MutationAction;
  const refetchUnsubscribes = () =>
    queryClient.invalidateQueries(
      getGetSingleUnsubscribeGroupUnsubscribesQueryKey(site?.id || '', groupId),
    );
  const addUnsubscribes = useCreateUnsubscribeGroupUnsubscribes(
    onMutation(CREATE, 'UnsubscribeGroup', refetchUnsubscribes),
  );
  const [removeUnsubscribeDialogOpen, setRemoveUnsubscribeDialogOpen] = useState<boolean>(false);
  const [unsubscribeToRemove, setUnsubscribeToRemove] = useState<string>('');
  const [selectedUnsubscribes, setSelectedUnsubscribes] = useState<JunoUser[]>([]);
  const [userSearchInput, setUserSearchInput] = useState<string>('');
  const debouncedSearchValue = useDebounce(userSearchInput, 200);

  const searchFilter = useMemo(() => {
    if (!debouncedSearchValue) return {};
    return calculateUserSearchFilters(debouncedSearchValue);
  }, [debouncedSearchValue]);

  // Queries and mutations
  const {
    data: groupUnsubscribes,
    isLoading: groupUnsubscribesLoading,
    refetch,
  } = useGetSingleUnsubscribeGroupUnsubscribes(site?.id || '', groupId);
  const { data: users, isLoading: usersLoading } = useGetAllUsers(site?.platform_id || '', {
    ...searchFilter,
    order: 'last_name',
  });

  // Functions
  const handleRemoveUnsubscribe = (unsubscribe: string) => {
    setUnsubscribeToRemove(unsubscribe);
    setRemoveUnsubscribeDialogOpen(true);
  };
  const refreshGroupUnsubscribes = () => {
    refetch();
  };
  const onChange = (event: React.SyntheticEvent, newUnsubscribes: JunoUser[]): void => {
    setSelectedUnsubscribes(newUnsubscribes);
  };
  const handleInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
    if (['change', 'blur'].includes(event?.type)) {
      setUserSearchInput(newInputValue);
    }
  };
  const handleAddUnsubscribes = () => {
    const payload = {
      unsubscribes: selectedUnsubscribes.map((user) => user.email),
    };
    addUnsubscribes
      .mutateAsync({ siteId: site?.id || '', unsubscribeGroupId: groupId, data: payload })
      .then(() => {
        refreshGroupUnsubscribes();
        setSelectedUnsubscribes([]);
      });
  };

  return (
    <DialogAriaWrapper
      fullWidth
      maxWidth='md'
      open={true}
      onClose={handleClose}
      id='groupUnsubscribes'
      PaperProps={{ sx: { borderRadius: '8px' } }}
    >
      <Box
        sx={{
          background: '#FFFFFF',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          border: '.75px solid black',
          width: '100%',
        }}
      >
        <DialogTitle
          id={'groupUnsubscribes-dialog-title'}
          style={{
            cursor: 'move',
            fontSize: '24px',
            fontFamily: 'Prompt',
            lineHeight: '36px',
            display: 'flex',
            flex: '2',
            justifyContent: 'flex-start',
          }}
        >
          {groupName} Suppressions
        </DialogTitle>
        <div
          style={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'flex-end' }}
        >
          <IconButton
            onClick={() => {
              handleClose(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </Box>
      <DialogContent id={'groupUnsubscribes-dialog-description'}>
        <Typography sx={{ mt: 1, mb: 2 }}>
          Individual users who are unsubscribed from this group
        </Typography>
        {groupUnsubscribesLoading && (
          <Skeleton
            variant='rectangular'
            height={50}
            width={'100%'}
            sx={{ borderRadius: '10px', mb: 2 }}
          ></Skeleton>
        )}
        {!groupUnsubscribesLoading && (
          <Box sx={{ mb: 4, border: `2px solid ${theme.palette.divider}`, borderRadius: '8px' }}>
            <Table>
              <TableBody>
                {(!groupUnsubscribes?.unsubscribes ||
                  groupUnsubscribes.unsubscribes.length < 1) && (
                  <TableRow key={0}>
                    <TableCell style={{ marginLeft: 6 }}>
                      Currently no unsubscribed users in this subscription group
                    </TableCell>
                  </TableRow>
                )}
                {groupUnsubscribes?.unsubscribes &&
                  groupUnsubscribes.unsubscribes.length > 0 &&
                  groupUnsubscribes.unsubscribes.map((unsubscribe, idx) => (
                    <TableRow key={idx} style={{ display: 'flex' }}>
                      <TableCell style={{ flex: '1' }}></TableCell>
                      <TableCell style={{ flex: '4' }}>{unsubscribe}</TableCell>
                      <TableCell style={{ flex: '4' }}>
                        <DeleteIcon
                          style={{ fontSize: '16px', cursor: 'pointer' }}
                          sx={{ color: 'primary.main', mr: 1 }}
                          onClick={() => {
                            handleRemoveUnsubscribe(unsubscribe);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {removeUnsubscribeDialogOpen && (
              <DeleteUnsubscribeDialog
                handleClose={setRemoveUnsubscribeDialogOpen}
                groupId={groupId}
                unsubscribe={unsubscribeToRemove}
                groupName={groupName}
                handleDeleteUnsubscribe={refreshGroupUnsubscribes}
              />
            )}
          </Box>
        )}
        <Typography sx={{ mt: 1, mb: 3 }}>
          Add users to be unsubscribed from the {groupName} subscription group
        </Typography>
        <Box sx={{ mb: 30 }}>
          <Autocomplete
            options={users || []}
            multiple
            disableCloseOnSelect
            disableClearable
            filterOptions={(x) => x}
            loading={usersLoading}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            size='small'
            value={selectedUnsubscribes || []}
            onChange={onChange}
            onInputChange={handleInputChange}
            inputValue={userSearchInput}
            renderTags={(value) => value.map((o) => null)}
            renderInput={(params) => (
              <TextField {...params} label={'Choose users to unsubscribe'} />
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  sx={{ mr: 1 }}
                  icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                  checkedIcon={<CheckBoxIcon fontSize='small' />}
                  checked={selected}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Typography>
                    {option.first_name} {option.last_name}
                  </Typography>
                  <Typography sx={{ fontSize: 12 }}>{option.email}</Typography>
                </Box>
              </li>
            )}
          />
          {selectedUnsubscribes.length > 0 && (
            <Table sx={{ mt: 3 }}>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Typography>Unsubscribe list</Typography>
                  </TableCell>
                </TableRow>
                {selectedUnsubscribes.map((unsubscribe, idx) => (
                  <TableRow>
                    <TableCell>{unsubscribe.email}</TableCell>
                  </TableRow>
                ))}
                <Button
                  aria-label='Unsubscribe-users'
                  sx={{ mt: 3 }}
                  variant='contained'
                  onClick={handleAddUnsubscribes}
                >
                  Unsubscribe users
                </Button>
              </TableBody>
            </Table>
          )}
        </Box>
      </DialogContent>
    </DialogAriaWrapper>
  );
};

export default GroupUnsubscribesDialog;
