import { useEffect, useMemo, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useDebounce } from 'usehooks-ts';
import { useGetAllUsers } from '@juno/client-api';
import { JunoUser } from '@juno/client-api/model';
import { calculateUserSearchFilters } from '@juno/utils';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
interface AutocompleteInstructorsProps {
  label: string;
  instructions?: string;
  platformId: string;
  disabled?: boolean;
  onSelect?: (value: string[]) => void;
  instructorsRemoved?: string[];
}

const AutocompleteInstructors: React.FC<AutocompleteInstructorsProps> = ({
  label,
  instructions,
  platformId,
  disabled,
  onSelect,
  instructorsRemoved,
}) => {
  const [selected, setSelected] = useState<JunoUser[] | undefined>();
  const [input, setInput] = useState<string>('');
  const debouncedSearchValue = useDebounce(input, 200);

  const searchFilter = useMemo(() => {
    if (!debouncedSearchValue) return undefined;
    return calculateUserSearchFilters(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const { data: usersData, isLoading } = useGetAllUsers(platformId, {
    ...searchFilter,
    order: 'last_name',
    limit: 250,
    offset: 0,
  });

  // TODO: Replace w/ Dom's async lazy load component w/ it's ready
  if (usersData && usersData.length === 250) {
    // If we hit 250 users, we should probably refine our search or have a better search
    console.warn(
      'Too many users returned for autocomplete, please refine your search and/or refactor code and UX.',
    );
  }

  const handleSelect = (reason: string, value: JunoUser[] | undefined) => {
    setSelected(value);
    onSelect?.(value?.map((v) => v.id) || []);
  };

  const handleInputChange = (e: React.SyntheticEvent, newInputValue: string) => {
    if (!e) return;
    setInput(newInputValue);
  };

  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: JunoUser[] | undefined,
    reason: string,
  ) => {
    handleSelect(reason, value);
  };

  // Make the AutoCompleteInstructors element on the filters modal recognize when the user removes an instructor/s from the JunoGrid page
  useEffect(() => {
    if (instructorsRemoved) {
      const newInstructorSet = selected?.filter((obj) => !instructorsRemoved.includes(obj.id));
      handleSelect('remove-option', newInstructorSet);
    }
  }, [instructorsRemoved]);

  return (
    <>
      <Typography variant='body2'>{instructions}</Typography>
      <Autocomplete
        sx={{ mb: 2, mt: 1 }}
        disabled={disabled}
        disableCloseOnSelect
        multiple
        limitTags={2}
        size='small'
        loading={isLoading}
        loadingText={<CircularProgress size={20} />}
        onChange={handleChange}
        getOptionLabel={(option) =>
          `${option.first_name.length > 0 ? option.first_name[0] + '.' : ''} ${option.last_name}`
        }
        filterOptions={(x) => x}
        options={usersData || []}
        value={selected || []}
        onInputChange={handleInputChange}
        inputValue={input}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => <TextField {...params} label={label} />}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{ right: 0 }}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {`${option.first_name.length > 0 ? option.first_name[0] + '.' : ''} ${
              option.last_name
            }`}
          </li>
        )}
      />
    </>
  );
};

export default AutocompleteInstructors;
