import React, { useEffect, useMemo, useState } from 'react';
import { AutorenewOutlined as AutorenewOutlinedIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Reorder } from 'framer-motion';
import { useQueryClient } from 'react-query';
import {
  getGetPriceLevelsQueryKey,
  useCreatePriceLevel,
  useDeletePriceLevel,
  useGetPriceLevels,
  useUpdatePriceLevel,
} from '@juno/client-api';
import { PriceLevel, Site as SiteModel } from '@juno/client-api/model';
import { AlertDialog } from '@juno/ui';
import { JunoRowCreateTile as RowCreateTile, RowTile, SaveBar } from '@juno/ui';
import { MutationAction, onMutation } from '@juno/utils';
import FormDialog from './FormDialog';

const NoItemsFound = styled(Box)(() => ({
  textAlign: 'center',
  userSelect: 'none',
}));

const CourseResourcesLoading = () => (
  <>
    <RowCreateTile text='Create a resource' />
    {[...Array(5)].map((_, idx) => (
      <Skeleton
        key={`skel-${idx}`}
        variant='rectangular'
        width={'auto'}
        height={165}
        sx={{ mt: 2 }}
      />
    ))}
  </>
);

interface PricingListProps {
  site: SiteModel;
  stripeKeysValid: boolean;
}

const MODELS = 'Price Levels';

const PricingList: React.FC<PricingListProps> = ({ site, stripeKeysValid }) => {
  const { id: siteId, platform_id: platformId } = site;
  const queryClient = useQueryClient();
  const [orderedItems, setOrderedItems] = useState<PriceLevel[] | undefined>();
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>();
  const [isSaving, setIsSaving] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const { data: itemData, isLoading: itemsLoading } = useGetPriceLevels(siteId);
  const refetch = () => queryClient.invalidateQueries(getGetPriceLevelsQueryKey(siteId));
  const createPriceLevel = useCreatePriceLevel(
    onMutation(MutationAction.CREATE, 'PriceLevel', refetch),
  );
  const updatePriceLevel = useUpdatePriceLevel(
    onMutation(MutationAction.UPDATE, 'PriceLevel', refetch),
  );
  const deleteProduct = useDeletePriceLevel(
    onMutation(MutationAction.DELETE, 'PriceLevel', refetch),
  );

  const isDirty = useMemo(() => {
    if (itemData && orderedItems) {
      return itemData.some((resource, idx) => resource.id !== orderedItems[idx]?.id);
    }
    return false;
  }, [itemData, orderedItems]);

  useEffect(() => {
    if (!itemData) return;
    setOrderedItems(itemData);
  }, [itemData]);

  const handleCreate = () => {
    setSelectedItemId(undefined);
    setFormDialogOpen(true);
  };

  const handleUpdate = (itemId: string) => {
    setSelectedItemId(itemId);
    setFormDialogOpen(true);
  };

  const handleDialogSave = (data: PriceLevel) => {
    setFormDialogOpen(false);
    if (data.id) {
      updatePriceLevel.mutate({ siteId, levelId: data.id, data });
    } else {
      createPriceLevel.mutate({ siteId, data });
    }
  };

  const handleDialogClose = () => {
    setSelectedItemId(undefined);
    setConfirmDeleteOpen(false);
    setFormDialogOpen(false);
    setIsSaving(false);
  };

  const handleDelete = () => {
    handleDialogClose();
    deleteProduct.mutate({ siteId, levelId: selectedItemId || '' });
  };

  if (itemsLoading) return <CourseResourcesLoading />;

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <RowCreateTile onClick={handleCreate} text='Add pricing level' disabled={!stripeKeysValid} />
      <Reorder.Group
        axis='y'
        onReorder={setOrderedItems}
        onClick={(e) => e.stopPropagation()}
        values={orderedItems || []}
        style={{ listStyle: 'none', padding: 0 }}
      >
        {orderedItems?.map((item) => (
          <Reorder.Item id={item.id} key={item.id} value={item} dragListener={false}>
            <RowTile title={item.name} onRowSelect={() => handleUpdate(item.id || '')} hideImage />
          </Reorder.Item>
        ))}
      </Reorder.Group>
      {(!orderedItems || orderedItems?.length === 0) && (
        <NoItemsFound sx={{ mt: 3 }}>
          <Typography variant='h6'>No items found</Typography>
        </NoItemsFound>
      )}
      {formDialogOpen && (
        <FormDialog
          item={itemData?.find((item) => item.id === selectedItemId)}
          isLoading={false}
          open={formDialogOpen}
          onClose={handleDialogClose}
          onSave={handleDialogSave}
          onDelete={() => setConfirmDeleteOpen(true)}
          isSaving={isSaving}
          siteId={siteId}
          platformId={platformId}
        />
      )}

      <AlertDialog
        open={confirmDeleteOpen}
        description={'Are you sure you want to delete this item?'}
        onCancel={() => setConfirmDeleteOpen(false)}
        onConfirm={handleDelete}
      />
    </Box>
  );
};

export default PricingList;
