import React, { useMemo, useState } from 'react';
import { PeopleAltRounded } from '@mui/icons-material';
import Clear from '@mui/icons-material/Clear';
import LoopIcon from '@mui/icons-material/Loop';
import PublishIcon from '@mui/icons-material/Publish';
import SaveIcon from '@mui/icons-material/Save';
import StarIcon from '@mui/icons-material/Star';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Badge,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';
import { usePapaParse } from 'react-papaparse';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  createMySavedReport,
  deleteMySavedReport,
  getAllUsers,
  getAnalyticsActivitiesReport,
  useGetAllTags,
  useGetMySavedReports,
} from '@juno/client-api';
import {
  GetAnalyticsActivitiesReportParams,
  JunoUser,
  SavedReport,
  Site,
  Tag,
} from '@juno/client-api/model';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';
import { AutocompleteJunoUsers, DialogAriaWrapper, customShadow } from '@juno/ui';
import { useSettings } from '@juno/utils';
import { DefaultDatePicker } from '../../components/DefaultDatePicker';
import { ExporterContentDropdown } from './ExporterContentDropdown';
import {
  CustomIconButton,
  DownloadIcon,
  OptionsSelectBox,
  PreviewIcon,
  SavedReportBox,
  SectionNumber,
  SectionTitle,
  UserActivityExporterSection,
} from './styles';

export const UserActivityExporter: React.FC = () => {
  const { site, enableLearning } = useSettings();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().startOf('date').subtract(7, 'day'),
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs().endOf('day'));
  const [timeFrame, setTimeFrame] = useState('Last 7 Days');
  const [isGeneratingDownload, setIsGeneratingDownload] = useState(false);
  const [isDownloadGenerated, setIsDownloadGenerated] = useState(false);
  const [analyticsReport, setAnalyticsReport] = useState('');
  const [userActivityAndActions, setUserActivityAndActions] = React.useState<string[]>([]);
  const [numOfResults, setNumOfResults] = React.useState(0);
  const { readRemoteFile } = usePapaParse();
  const [userReportableTags, setUserReportableTags] = React.useState<string[]>([]);
  const { FIREHOSE_ACTIONS } = ANALYTICS_CONFIGURATION;
  const firehose_actions = Object.values(FIREHOSE_ACTIONS);
  const firehoseActionTitles = firehose_actions.map((action) => action.title);
  const [selectedUsers, setSelectedUsers] = React.useState<JunoUser[]>([]);
  const [reportTitle, setReportTitle] = useState<string | undefined>(undefined);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [selectedSavedReport, setSelectedSavedReport] = useState<SavedReport | undefined>(
    undefined,
  );
  const {
    data: savedReports,
    isLoading: isLoadingSavedReports,
    refetch: refetchSavedReports,
  } = useGetMySavedReports(
    site?.id || '',
    {
      order: '-date_created',
    },
    { query: { enabled: !!site?.id } },
  );
  const nonUserActions = [
    'Analytics',
    'Close',
    'Delete',
    'Edit',
    'Report',
    'Saved',
    'Tag',
    'Update',
    'Upload',
  ];
  const userActions = firehoseActionTitles.filter((action) => {
    if (!enableLearning) {
      if (
        action === FIREHOSE_ACTIONS.ACTION_UNENROLL.value ||
        action === FIREHOSE_ACTIONS.ACTION_ENROLL.value ||
        action === FIREHOSE_ACTIONS.ACTION_WAITLIST_ADD.value ||
        action === FIREHOSE_ACTIONS.ACTION_WAITLIST_REMOVE.value
      ) {
        return false;
      }
    }
    return !nonUserActions.includes(action);
  });
  const { data: reportableTags, isLoading: areTagsLoading } = useGetAllTags(
    site?.id || '',
    {
      filter: { type__reportable: true },
    },
    { query: { enabled: !!site?.id } },
  );
  const reportableTagNames = useMemo(() => {
    return (
      reportableTags?.map((tag) => {
        return tag.value;
      }) || []
    );
  }, [reportableTags]);

  const canSaveReport = useMemo(() => {
    return site && reportTitle && timeFrame !== 'Custom';
  }, [site, reportTitle, timeFrame]);

  const handleClickGenerateReport = async () => {
    if (!startDate || !endDate) {
      return;
    }
    if (isDownloadGenerated) {
      setIsDownloadGenerated(false);
    }
    setIsGeneratingDownload(true);
    const params: GetAnalyticsActivitiesReportParams = {
      start_datetime: startDate.toISOString(),
      end_datetime: endDate.toISOString(),
      report_type: 'activity',
    };
    if (userActivityAndActions.length > 0) {
      params.action = getFirehoseActionValues().join('|');
    }
    if (userReportableTags.length > 0) {
      const tagIds: string[] = [];
      reportableTags?.forEach((tag) => {
        if (userReportableTags.includes(tag.value)) {
          tagIds.push(tag.id);
        }
      });
      params.tag_ids = tagIds.join('|');
    }

    params.user_ids = selectedUsers.map((user) => user.id).join(',');

    try {
      const result = await getAnalyticsActivitiesReport(site?.id || '', params);
      const interval = setInterval(() => {
        if (result && doesFileExist(result.url)) {
          setIsGeneratingDownload(false);
          setIsDownloadGenerated(true);
          setAnalyticsReport(result.url);
          readRemoteFile(result.url, {
            download: true,
            complete: (results) => {
              // Subtracting 2 because of the header line and the new line at the end of the report
              setNumOfResults(results?.data?.length - 2 || 0);
            },
          });
          clearInterval(interval);
        }
      }, 1000);
    } catch {
      setIsGeneratingDownload(false);
    }
  };

  const handleDownloadReport = () => {
    window.open(analyticsReport);
    setIsDownloadGenerated(false);
    setNumOfResults(0);
  };

  const handlePreviewReport = () => {
    if (site) {
      const encodedUrl = encodeURIComponent(analyticsReport);
      const url =
        location.pathname.indexOf('/sites/') !== -1
          ? `${window.origin}/sites/${site.slug}/report_preview?reportUrl=${encodedUrl}`
          : `${window.origin}/${site.slug}/report_preview?reportUrl=${encodedUrl}`;
      window.open(url);
    }
  };

  const handleSaveReport = async () => {
    if (canSaveReport) {
      setIsSaving(true);
      const params: any = {};
      if (userActivityAndActions.length > 0) {
        params.action = getFirehoseActionValues().join('|');
      }
      if (userReportableTags.length > 0) {
        const tagIds: string[] = [];
        reportableTags?.forEach((tag) => {
          if (userReportableTags.includes(tag.value)) {
            tagIds.push(tag.id);
          }
        });
        params.tag_ids = tagIds.join('|');
      }
      params.user_ids = selectedUsers.map((user) => user.id).join(',');
      params.time_frame = timeFrame;

      await createMySavedReport(site?.id || '', {
        title: reportTitle || '',
        metadata: params,
        // Autofilled on the backend
        site_id: '',
        user_id: '',
      });
      await refetchSavedReports();
      setIsSaving(false);
    }
  };

  const handleLoadReport = async (savedReport: SavedReport) => {
    setIsSaving(true);
    const metadata = savedReport?.metadata;
    const loadTimeFrame: string = metadata?.['time_frame'] as string;
    const loadActionsString: string | undefined = metadata?.['action'] as string;
    const loadActions: string[] = loadActionsString ? loadActionsString.split('|') : [];
    const loadTagsString: string | undefined = metadata?.['tag_ids'] as string;
    const loadTags: string[] = loadTagsString ? loadTagsString.split('|') : [];
    const loadUsersString: string | undefined = metadata?.['user_ids'] as string;
    const loadUsers: string[] = loadUsersString ? loadUsersString.split(',') : [];
    if (loadTimeFrame) {
      setTimeFrame(loadTimeFrame);
    }
    if (loadActions) {
      const loadUserActivityAndActions: string[] = [];
      firehose_actions.forEach((action) => {
        if (loadActions.includes(action.value)) {
          loadUserActivityAndActions.push(action.title);
        }
      });
      setUserActivityAndActions(loadUserActivityAndActions);
    }
    if (reportableTags?.length && loadTags) {
      const tags: string[] = [];
      reportableTags?.forEach((tag) => {
        if (loadTags.includes(tag.id)) {
          tags.push(tag.value);
        }
      });
      setUserReportableTags(tags);
    }
    if (loadUsers) {
      const users = await getAllUsers(site?.platform_id || '', {
        filter: { id__in: loadUsers },
      });
      setSelectedUsers(users);
    }
    setIsSaving(false);
  };

  const getFirehoseActionValues = (): string[] => {
    const reportValues: string[] = [];
    firehose_actions.forEach((action) => {
      if (userActivityAndActions.includes(action.title)) {
        reportValues.push(action.value);
      }
    });
    return reportValues;
  };
  const showTagsOption = (reportableTags?.length || 0) > 0;

  const handleCloseDelete = () => {
    setSelectedSavedReport(undefined);
  };

  const handleDeleteReport = async () => {
    if (site && selectedSavedReport?.id) {
      await deleteMySavedReport(site.id, selectedSavedReport?.id);
    }
    handleCloseDelete();
  };
  return (
    <Box sx={{ ml: -1, mr: -1, mt: 2 }}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <UserActivityExporterSection>
            <SectionTitle sx={{ mb: 4 }}>
              <SectionNumber>1</SectionNumber>
              <Typography>Choose a timeframe for the data you'd like to export</Typography>
            </SectionTitle>
            <Box sx={{ paddingLeft: '50px' }}>
              <DefaultDatePicker
                startDate={startDate}
                endDate={endDate}
                timeFrame={timeFrame}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                setTimeFrame={setTimeFrame}
              />
            </Box>
          </UserActivityExporterSection>
        </Grid>
        <Grid item xs={12}>
          <UserActivityExporterSection>
            <SectionTitle>
              <SectionNumber>2</SectionNumber>
              <Typography>Filter the user activity results</Typography>
            </SectionTitle>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <OptionsSelectBox>
                  <ExporterContentDropdown
                    optionsLabel='User Activity and Action(s)'
                    totalOptions={userActions}
                    selectedOptions={userActivityAndActions}
                    setSelectedOptions={setUserActivityAndActions}
                  />
                </OptionsSelectBox>
              </Grid>
              {showTagsOption && (
                <Grid item xs={12}>
                  <OptionsSelectBox>
                    <ExporterContentDropdown
                      optionsLabel='User Tags'
                      totalOptions={reportableTagNames}
                      selectedOptions={userReportableTags}
                      setSelectedOptions={setUserReportableTags}
                    />
                  </OptionsSelectBox>
                </Grid>
              )}
              <Grid item xs={12}>
                <Box sx={{ paddingLeft: '40px' }}>
                  <AutocompleteJunoUsers
                    accessPassIdsForUserBadge={[]}
                    currentUsers={selectedUsers || []}
                    getImageSrc={(url) => {
                      return url;
                    }}
                    label={'Choose Users'}
                    title={'Choose users'}
                    placeholder={'Search for a user'}
                    platformId={site?.platform_id || ''}
                    handleChange={(value) => {
                      setSelectedUsers(value);
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </UserActivityExporterSection>
        </Grid>
        <Grid item xs={12}>
          <UserActivityExporterSection>
            <Grid container>
              <Grid item xs={12} md={6}>
                <SectionTitle>
                  <SectionNumber>3</SectionNumber>
                  <Typography>Process your request</Typography>
                </SectionTitle>
              </Grid>
              <Grid item xs={6} md={6} sx={{ textAlign: 'right' }}>
                <Button
                  variant='contained'
                  disabled={isGeneratingDownload}
                  endIcon={
                    <LoopIcon
                      sx={{
                        marginLeft: '10px',
                        animation: `${isGeneratingDownload ? 'spin 2s linear infinite' : ''}`,
                        '@keyframes spin': {
                          '0%': {
                            transform: 'rotate(360deg)',
                          },
                          '100%': {
                            transform: 'rotate(0deg)',
                          },
                        },
                      }}
                    />
                  }
                  onClick={handleClickGenerateReport}
                >
                  Generate Your Report
                </Button>
              </Grid>
            </Grid>
          </UserActivityExporterSection>
        </Grid>
        <Grid item xs={12}>
          <UserActivityExporterSection sx={{ mb: 2 }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <SectionTitle>
                  <SectionNumber>4</SectionNumber>
                  <Typography>Download your report!</Typography>
                </SectionTitle>
              </Grid>
              <Grid item xs={6} md={6} sx={{ textAlign: 'right' }}>
                <Grid container sx={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={6} md={6}>
                    {isDownloadGenerated && (
                      <Typography style={{ display: 'inline-block', marginRight: '10px' }}>
                        {numOfResults} Results
                      </Typography>
                    )}
                    <Tooltip title={'Download Report'}>
                      <CustomIconButton
                        variant='contained'
                        disabled={!isDownloadGenerated}
                        endIcon={<DownloadIcon />}
                        onClick={handleDownloadReport}
                      />
                    </Tooltip>
                    <Tooltip title={'View Report'}>
                      <CustomIconButton
                        variant='contained'
                        disabled={!isDownloadGenerated}
                        endIcon={<PreviewIcon />}
                        onClick={handlePreviewReport}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </UserActivityExporterSection>
        </Grid>
        <Grid item xs={12}>
          <UserActivityExporterSection sx={{ mb: 2 }}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <SectionTitle>
                  <SectionNumber>
                    <StarIcon />
                  </SectionNumber>
                  <Typography>Save your report selections</Typography>
                </SectionTitle>
              </Grid>
              <Grid item xs={12} md={6} sx={{ textAlign: 'right' }}>
                <Grid container sx={{ justifyContent: 'flex-end' }}>
                  <Grid item xs={12} md={6}>
                    <Tooltip title={'Save report selections'}>
                      <CustomIconButton
                        variant='contained'
                        disabled={!canSaveReport || isSaving || isGeneratingDownload}
                        endIcon={<SaveIcon />}
                        onClick={handleSaveReport}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  sx={{ marginLeft: '40px' }}
                  disabled={isSaving}
                  id='reportTitle'
                  name='reportTitle'
                  label='Report Title'
                  fullWidth
                  margin='dense'
                  value={reportTitle}
                  onChange={(e) => setReportTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                {!canSaveReport && (
                  <Typography
                    style={{ display: 'inline-block', marginRight: '10px', marginLeft: '40px' }}
                  >
                    *To save report selections, set the report title and set the time frame to a
                    non-custom selection.
                  </Typography>
                )}
              </Grid>
            </Grid>
          </UserActivityExporterSection>
        </Grid>

        <Grid item xs={12}>
          <UserActivityExporterSection sx={{ mb: 2 }}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <SectionTitle>
                  <SectionNumber>
                    <StarIcon />
                  </SectionNumber>
                  <Typography>Load a saved report</Typography>
                </SectionTitle>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box p={1}>
                  {(savedReports?.length || 0) > 0 && (
                    <Stack
                      mt={2}
                      spacing={1}
                      sx={{
                        maxHeight: 200,
                        overflow: 'auto',
                      }}
                    >
                      {(savedReports || []).map((report, index) => (
                        <SavedReportBox
                          key={report.id}
                          sx={{
                            backgroundColor:
                              index % 2 === 0 ? 'inherit' : theme.palette.background.paper,
                          }}
                        >
                          <Grid container sx={{ justifyContent: 'flex-end' }}>
                            <Grid item xs={9}>
                              <Typography
                                sx={{ marginLeft: '40px' }}
                              >{`${report.title}`}</Typography>
                            </Grid>
                            <Grid item xs={3} sx={{ textAlign: 'right' }}>
                              <Tooltip title={'Load report selections'}>
                                <CustomIconButton
                                  variant='contained'
                                  endIcon={<PublishIcon />}
                                  onClick={(e: any) => handleLoadReport(report)}
                                />
                              </Tooltip>
                              <Tooltip title={'Delete saved report'}>
                                <CustomIconButton
                                  variant='contained'
                                  endIcon={<Clear />}
                                  onClick={(e: any) => setSelectedSavedReport(report)}
                                />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </SavedReportBox>
                      ))}
                    </Stack>
                  )}
                </Box>
              </Grid>
            </Grid>
          </UserActivityExporterSection>
        </Grid>
      </Grid>
      <DialogAriaWrapper
        open={!!selectedSavedReport}
        onClose={handleCloseDelete}
        id='delete_course'
      >
        <DialogTitle>Delete Course</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this saved report? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Cancel</Button>
          <LoadingButton
            loading={isSaving}
            variant='contained'
            onClick={handleDeleteReport}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </DialogAriaWrapper>
    </Box>
  );
};

function doesFileExist(urlToFile: string) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', urlToFile, false);
  xhr.send();

  return !(xhr.status === 403 || xhr.status === 404);
}
