import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { Download as DownloadIcon, MoreVert as MoreVertIcon } from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Skeleton,
  Typography,
} from '@mui/material';
import { useQueryClient } from 'react-query';
import {
  createGeneratedReport,
  getGetGeneratedReportQueryKey,
  useCreateGeneratedReport,
  useGetGeneratedReport,
} from '@juno/client-api';
import {
  CreateGeneratedReportParams,
  GeneratedReport,
  GeneratedReportStatusEnum,
  GeneratedReportTypeEnum,
  JunoUser as JunoUserModel,
} from '@juno/client-api/model';
import { EMPTY_PROFILE_URL } from '@juno/constants';
import { MutationAction, onMutation } from '@juno/utils';
import { optimizeImage } from '@juno/utils';
import {
  EllipsisTypography,
  StyledUserAvatar,
  TileCard,
  TileGridBanner,
  TileGridContainer,
  TileGridTextWrapper,
  TileWrapper,
} from '../styles';
import AsyncDownload from './AsyncDownload';

export const LearnersLoadingTile = () => (
  <>
    {[...Array(5)].map((_, idx) => (
      <Skeleton key={`skel-${idx}`} variant='rectangular' width={'auto'} height={60} />
    ))}
  </>
);

interface AddModulePopperProps {
  learner: JunoUserModel;
  siteId: string;
}

const NavPopper: React.FC<AddModulePopperProps> = ({ learner, siteId }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDownloadingCourses, setIsDownloadingCourses] = useState(false);
  const [isDownloadingTranscript, setIsDownloadingTranscript] = useState(false);

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const courseReportData: GeneratedReport = {
    type: GeneratedReportTypeEnum.learningstudent_courses,
    site_id: siteId,
    user_id: learner.id,
    admin_id: null,
  };
  const courseReportParams: CreateGeneratedReportParams = {
    filter: {
      user_id__in: [learner.id],
      course__date_end__gte: new Date().toISOString(),
    },
  };
  const transcriptReportData: GeneratedReport = {
    type: GeneratedReportTypeEnum.learningstudent_transcripts,
    site_id: siteId,
    user_id: learner.id,
    admin_id: null,
  };
  const transcriptReportParams: CreateGeneratedReportParams = {
    filter: {
      user_id__in: [learner.id],
    },
  };

  const handleDownloadCourses = () => {
    if (!learner) return;
    setIsDownloadingCourses(true);
    setOpen(false);
  };

  const handleDownloadTranscript = () => {
    if (!learner) return;
    setIsDownloadingTranscript(true);
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleToggle} disabled={isDownloadingCourses || isDownloadingTranscript}>
        {!isDownloadingCourses && !isDownloadingTranscript && <MoreVertIcon />}
        {isDownloadingCourses && (
          <AsyncDownload
            siteId={siteId}
            data={courseReportData}
            params={courseReportParams}
            setIsDownloading={setIsDownloadingCourses}
          />
        )}
        {isDownloadingTranscript && (
          <AsyncDownload
            siteId={siteId}
            data={transcriptReportData}
            params={transcriptReportParams}
            setIsDownloading={setIsDownloadingTranscript}
          />
        )}
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='bottom-end'
        transition
        disablePortal
        sx={{ zIndex: 5 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-end' ? 'right top' : 'left bottom',
            }}
          >
            <Paper sx={{ border: '1px solid', borderColor: 'primary.main', borderRadius: '8px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                  <Typography variant='body2' sx={{ fontWeight: 700, ml: 2 }}>
                    LEARNER OPTIONS
                  </Typography>
                  <MenuItem sx={{ fontSize: '14px' }} onClick={handleDownloadCourses}>
                    <DownloadIcon sx={{ color: 'primary.main', mr: 1 }} />
                    Current Courses
                  </MenuItem>
                  <MenuItem sx={{ fontSize: '14px' }} onClick={handleDownloadTranscript}>
                    <DownloadIcon sx={{ color: 'primary.main', mr: 1 }} />
                    Learner Transcript
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

interface LearnerTileProps {
  learner: JunoUserModel;
  imageUrl?: string;
  idx: number;
  siteId: string;
  ref: any;
}

const LearnerTile = forwardRef<HTMLDivElement, LearnerTileProps>(
  ({ learner, imageUrl, siteId, idx }, ref) => (
    <TileWrapper ref={ref}>
      <TileGridContainer>
        <TileCard idx={idx}>
          <TileGridBanner m={1}>
            <StyledUserAvatar
              src={optimizeImage(450, imageUrl || EMPTY_PROFILE_URL)}
              alt='Course Banner'
            />
          </TileGridBanner>
          <TileGridTextWrapper>
            <EllipsisTypography variant='body1'>{`${learner.first_name} ${learner.last_name}`}</EllipsisTypography>
            <EllipsisTypography variant='body2' sx={{ color: '#999999', fontStyle: 'italic' }}>
              {learner.email}
            </EllipsisTypography>
          </TileGridTextWrapper>
          <Box sx={{ display: 'flex', flex: 1 }}></Box>
          <TileGridTextWrapper m={1}>
            <EllipsisTypography variant='body2'>{`${learner.enrollments_in_progress_count} ${
              learner.enrollments_in_progress_count == '1' ? 'Course' : 'Courses'
            }`}</EllipsisTypography>
          </TileGridTextWrapper>
          <TileGridTextWrapper sx={{ alignContent: 'center' }}>
            <NavPopper learner={learner} siteId={siteId} />
          </TileGridTextWrapper>
        </TileCard>
      </TileGridContainer>
    </TileWrapper>
  ),
);

export default LearnerTile;

// for use after generating a report to poll and automatically open it
