/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type FeatureConfigTypeEnum = typeof FeatureConfigTypeEnum[keyof typeof FeatureConfigTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeatureConfigTypeEnum = {
  theme: 'theme',
  ecommerce: 'ecommerce',
  automation: 'automation',
  community: 'community',
  credits: 'credits',
  analytics_dashboard: 'analytics_dashboard',
  integrations: 'integrations',
  authentication: 'authentication',
  embed: 'embed',
  growth_zone: 'growth_zone',
  feature_flag: 'feature_flag',
  terms_of_service: 'terms_of_service',
} as const;
