import React, { useState } from 'react';
import { CheckCircle as CheckCircleIcon, FlashOn as FlashOnIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TitleContainer = styled(Box, { shouldForwardProp: (prop) => prop !== 'loading' })<{
  loading: boolean;
  disabled: boolean;
}>(({ loading, theme, disabled }) => ({
  backgroundColor: loading
    ? 'initial'
    : disabled
    ? theme.palette.grey[400]
    : theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(0.7),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const PointsContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));

export interface PointsButtonProps {
  onClick: () => void;
  title: string;
  points?: number;
  complete?: boolean;
  loading?: boolean;
  disabled?: boolean;
}

const PointsButton: React.FC<PointsButtonProps> = ({
  onClick,
  title,
  points,
  loading,
  complete = false,
  disabled = false,
}) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const handleClick = () => {
    if (complete) return;
    onClick();
    // setButtonLoading(true);
    // console.log('SIMULATING VNOW BS');
    // setTimeout(() => {
    //   onClick();
    //   setButtonLoading(false);
    // }, 1000);
  };
  const isLoading = loading || buttonLoading;
  return (
    <LoadingButton
      loading={isLoading}
      variant='outlined'
      onClick={handleClick}
      sx={{ p: 0, maxWidth: 'fit-content' }}
      disabled={disabled}
    >
      <TitleContainer loading={isLoading} disabled={disabled}>
        {complete ? 'Completed' : title}
      </TitleContainer>
      {!complete && (
        <PointsContainer sx={{ pl: 1.5 }}>
          <Box>{points}</Box>
          <FlashOnIcon sx={{ fontSize: '14px' }} />
        </PointsContainer>
      )}
      {complete && (
        <PointsContainer>
          <CheckCircleIcon />
        </PointsContainer>
      )}
    </LoadingButton>
  );
};

export default PointsButton;
