export enum CertificateType {
  DEFAULT = 'default',
  CUSTOM = 'custom',
}

export const CertificateTypeMap = {
  [CertificateType.DEFAULT]: 'Default Certificate Template',
  [CertificateType.CUSTOM]: 'Custom HTML Certificate',
};

export enum DynamicVariable {
  PLATFORM_NAME = '{platform_name}',
  LEARNER_NAME = '{learner_name}',
  COURSE_TITLE = '{course_title}',
  COMPLETION_DATE = '{completion_date}',
  COURSE_GRADE = '{course_grade}',
  CREDITS_EARNED = '{credits_earned}',
  CERTIFICATE_ID = '{certificate_id}',
}

export const DynamicVariableMap = {
  [DynamicVariable.PLATFORM_NAME]: 'Platform Name',
  [DynamicVariable.LEARNER_NAME]: 'Learner Name',
  [DynamicVariable.COURSE_TITLE]: 'Course Title',
  [DynamicVariable.COMPLETION_DATE]: 'Completion Date',
  [DynamicVariable.COURSE_GRADE]: 'Course Grade',
  [DynamicVariable.CREDITS_EARNED]: 'Credits Earned',
  [DynamicVariable.CERTIFICATE_ID]: 'Certificate ID',
};
