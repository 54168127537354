import { useEffect, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import DOMPurify from 'dompurify';
import { useFormik } from 'formik';
import { useQueryClient } from 'react-query';
import {
  getGetCertificateTemplateQueryKey,
  getGetCertificateTemplatesQueryKey,
  useDeleteCertificateTemplate,
  useUpdateCertificateTemplate,
} from '@juno/client-api';
import { CertificateTemplate } from '@juno/client-api/model';
import { TabPanel } from '@juno/ui';
import { MutationAction, a11yProps, onMutation } from '@juno/utils';
import { html } from '../utils/html';
import { validationSchema, valueMaker } from '../utils/validation';
import CertificateInfo from './CertificateInfo';
import Layout from './layout';

interface CertificateTabPanelProps {
  siteId: string;
  certificate?: CertificateTemplate;
  onBack: () => void;
}

const CertificateTabPanel: React.FC<CertificateTabPanelProps> = ({
  siteId,
  certificate,
  onBack,
}) => {
  const [value, setValue] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const queryClient = useQueryClient();

  // Mutations
  const refetchCertificate = () =>
    queryClient.invalidateQueries(getGetCertificateTemplateQueryKey(siteId, certificate?.id || ''));
  const refetchCertificates = () =>
    queryClient.invalidateQueries(getGetCertificateTemplatesQueryKey(siteId));

  const updateCertificate = useUpdateCertificateTemplate(
    onMutation(MutationAction.UPDATE, 'certificateTemplate', refetchCertificate),
  );
  const deleteCertificate = useDeleteCertificateTemplate(
    onMutation(MutationAction.DELETE, 'certificateTemplate', refetchCertificates),
  );

  // Handlers
  const handleDeleteCertificate = (id: string) => {
    deleteCertificate.mutateAsync({ siteId, templateId: id });
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSubmit = (certificate: CertificateTemplate) => {
    formik.setFieldValue('html_content', html(certificate));
    setIsSaving(true);
    if (certificate.id) {
      updateCertificate.mutateAsync(
        { siteId, templateId: certificate.id, data: certificate },
        {
          onSuccess: () => {
            // formik.resetForm({ values: valueMaker(siteId, certificate) });
            setIsSaving(false);
            handleBack();
          },
        },
      );
    }
  };

  // Formik
  const formik = useFormik({
    initialValues: valueMaker(siteId, certificate),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.html_content = DOMPurify.sanitize(values.html_content);
      handleSubmit(values);
    },
  });

  const handleBack = () => {
    onBack();
  };

  // If the certificate has an image only, then we need to set the other fields to false
  useEffect(() => {
    if (formik.values.metadata?.hasImageOnly === true) {
      formik.setFieldValue('metadata.displayPlatformName', false);
      formik.setFieldValue('metadata.displayLetterGrade', false);
      formik.setFieldValue('metadata.displayCreditsEarned', false);
      formik.setFieldValue('metadata.displayCertificateID', false);
      formik.setFieldValue('metadata.hideBackgroundImage', false);
      formik.setFieldValue('metadata.hideRibbon', false);
      formik.setFieldValue('metadata.adminName', '');
      formik.setFieldValue('metadata.adminRole', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.metadata?.hasImageOnly]);

  // Tab Definitions
  const theTabs = [
    {
      label: 'Certificate Info',
      icon: <InfoIcon />,
      component: (
        <CertificateInfo
          formik={formik}
          onDelete={handleDeleteCertificate}
          isSaving={isSaving}
          onBack={handleBack}
        />
      ),
    },
  ];

  return (
    <Layout
      title={formik.values.name || 'Certificate Title Here'}
      subtitle='Editing'
      onBack={handleBack}
      formik={formik}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 2, borderColor: 'primary.main' }}>
          <Tabs
            value={value}
            onChange={handleChangeTab}
            aria-label='basic tabs example'
            textColor='secondary'
            indicatorColor='secondary'
            sx={{
              mt: 2,
              //TODO style tabs
              '& .Mui-selected': {},
            }}
          >
            {theTabs.map((tab, index) => (
              <Tab
                key={index}
                icon={tab.icon}
                iconPosition='start'
                label={tab.label}
                sx={{ textTransform: 'none' }}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </Box>
        {theTabs.map((tab, index) => (
          <TabPanel key={index} value={value} index={index}>
            {tab.component}
          </TabPanel>
        ))}
      </Box>
    </Layout>
  );
};

export default CertificateTabPanel;
