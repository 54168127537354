import * as Yup from 'yup';
import { AccessPass } from '@juno/client-api/model';

const snackMessage = {
  success: 'ACCESS PASS CREATED SUCCESSFULLY',
  update: 'ACCESS PASS UPDATED SUCCESSFULLY',
  error: 'ACCESS PASS WAS NOT CREATED',
};

const accessPassValidationSchema = (allAccessPasses: AccessPass[]) => {
  return Yup.object({
    name: Yup.string()
      .max(90, 'Access Pass name must not exceed 90 characters')
      .required('Access Pass name is required')
      .test('title-unique-validation', 'This field must be unique', function test(value) {
        if (value && value.length > 0) {
          if (allAccessPasses && allAccessPasses.filter((ap) => ap.name === value).length > 0) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      }),
    upgrade_enrollment_url: Yup.string()
      .nullable()
      .max(128, 'Upgrade URL must not exceed 128 characters')
      .test(
        'title-valid-url',
        'The Upgrade URL field must begin with "http://" or "https://"',
        function test(value) {
          if (value && value.length > 0) {
            return !(!value.startsWith('http://') && !value.startsWith('https://'));
          } else {
            return true;
          }
        },
      ),
  });
};

export { snackMessage, accessPassValidationSchema };
