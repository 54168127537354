import React, { useEffect, useState } from 'react';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import { useCreateCommunityGroup, useGetCommunityGroups } from '@juno/client-api';
import { Site as SiteModel } from '@juno/client-api/model';
import { JUNO_ROUTE_MAP } from '@juno/constants';
import { AdminDefaultViewHeader, JunoGrid, PreviewItemProps } from '@juno/ui';
import {
  MutationAction,
  onMutation,
  useGroupsSearchFilter,
  useRouteNavigate,
  useSettings,
} from '@juno/utils';
import FormDialog, { GroupsForm } from './FormDialog';

interface CommunityGroupsPanelProps {
  site: SiteModel;
}

const MODEL = 'Group';
const PAGE_SIZE = 12;

// TODO this component is only used in the old widget and in JunoRoutes...Deprecate?
const CommunityGroupsPanel: React.FC<CommunityGroupsPanelProps> = ({ site }) => {
  const { id: siteId } = site;
  const [page, setPage] = useState(0);
  const [groups, setGroups] = useState<PreviewItemProps[]>([]);
  const [createOpen, setCreateOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [debouncedSearch, search, searchFilter, setSearch] = useGroupsSearchFilter();
  const { isWidget, isClient } = useSettings();
  const navigateTo = useNavigate();
  const navigate = useRouteNavigate();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1000,
  });
  const { data, isLoading, isFetching, refetch } = useGetCommunityGroups(siteId, {
    filter_or: searchFilter,
  });
  const groupCreate = useCreateCommunityGroup(onMutation(MutationAction.CREATE, MODEL, refetch));

  const tempGroup = {
    id: '',
    site_id: siteId || '',
    preview_text: '',
    title: '',
    slug: '',
    body: '',
    tags: [],
    admins: [],
    has_feed: false,
    has_forum: false,
  };

  useEffect(() => {
    const newData =
      data?.map((group) => {
        return {
          id: group.id || '',
          slug: group.slug || '',
          name: group.title || '',
          description: group.preview_text || '',
          image: group.list_image || '',
          date_start: group.date_released || '',
          date_created: '',
          can_edit: true,
        };
      }) || [];
    if (page === 0) {
      setGroups(newData);
      return;
    }
    setGroups((currentGroups) => [...currentGroups, ...newData]);
  }, [data]);

  useEffect(() => {
    setPage(0);
  }, [search]);

  useEffect(() => {
    if (inView && !isFetching && data?.length !== 0) {
      setPage(page + 1);
    }
  }, [inView, isFetching, data]);

  const handleStartCreate = () => {
    setCreateOpen(true);
  };

  const handleCloseDialog = () => {
    setCreateOpen(false);
  };

  const handleSaveNewGroup = async (group: GroupsForm | undefined) => {
    setIsSaving(true);
    try {
      if (group) {
        const newGroup = {
          ...tempGroup,
          ...group,
        };
        setGroups([]);
        await groupCreate.mutateAsync({ siteId, data: newGroup });
        setIsSaving(false);
        setCreateOpen(false);
      }
    } catch (e) {
      console.error(e);
      setIsSaving(false);
      setCreateOpen(false);
    }
  };

  const handleSelectGroup = (to: PreviewItemProps | undefined) => {
    if (!to) return;
    if (isClient) {
      navigateTo(`/${site.slug}/admin/community/groups/${to.slug}`);
    } else {
      navigate(JUNO_ROUTE_MAP.ADMIN_GROUP, { groupSlug: to?.slug || '' });
    }
  };

  return (
    <Box>
      <Stack
        direction='row'
        spacing={2}
        sx={{ mb: 2 }}
        justifyContent='space-between'
        alignItems='center'
      >
        {isWidget && (
          <>
            <Grid item xs={8}>
              <Typography
                variant='body2'
                sx={{ color: 'text.secondary', opacity: 0.5, fontStyle: 'italic', mb: 1 }}
              >
                Editing
              </Typography>
              <Typography variant='h4' sx={{ color: 'text.secondary', letterSpacing: '3px' }}>
                FRONT-END EDITOR: Groups
              </Typography>
            </Grid>{' '}
            <Button
              variant='contained'
              onClick={handleStartCreate}
              sx={{ textTransform: 'none' }}
              startIcon={<BookmarkAddIcon sx={{ ml: 1 }} />}
            >
              Create a Group
            </Button>
          </>
        )}
        {!isWidget && (
          <AdminDefaultViewHeader title='Community Groups'>
            <Button
              variant='contained'
              onClick={handleStartCreate}
              sx={{ textTransform: 'none' }}
              startIcon={<BookmarkAddIcon sx={{ ml: 1 }} />}
            >
              Create a Group
            </Button>
          </AdminDefaultViewHeader>
        )}
      </Stack>
      <JunoGrid
        items={groups}
        openExternalUrl={() => {}}
        onSelect={handleSelectGroup}
        // onSearch={setSearch}
        isLoading={isLoading}
        settings={{
          variant: 'square',
          showItemDescription: true,
          textAlign: 'left',
          itemWidth: 310,
          itemHeight: 174,
        }}
        ref={ref}
      />
      <FormDialog
        open={createOpen}
        onClose={handleCloseDialog}
        onSave={handleSaveNewGroup}
        isSaving={isSaving}
        allGroups={data}
      />
    </Box>
  );
};

export default CommunityGroupsPanel;
