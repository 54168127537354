import * as Yup from 'yup';
import { string } from 'yup';
import { CourseType } from './enums';

const validationSchema = Yup.object().shape({
  title: string().max(90, 'Title character limit: 90'),
  uploaded_file: string().when('metadata.type', {
    is: CourseType.SCORM,
    then: string()
      .url()
      .test('url', 'Must point to a .zip file', function (value) {
        if (value) {
          return value?.substr(value.length - 4).includes('.zip');
        }
        return false;
      }),
  }),
});

export default validationSchema;
