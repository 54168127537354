import React, { ChangeEvent, useContext } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Formik, FormikProps } from 'formik';
import { MAX_SCORM_UPLOAD_SIZE } from '@juno/constants';
import { slugify } from '@juno/utils';
import FileUploadButton from '../components/FileUploadButton';
import validationSchema from './ValidationSchema';
import { CoursesPanelContext } from './context';
import { CourseType, CourseTypeMap } from './enums';

export interface CoursesForm {
  title: string;
  slug: string;
  uploaded_file: string;
  metadata: {
    type: CourseType;
  };
}

interface FormDialogProps {
  open: boolean;
  onSave: (course: CoursesForm | undefined) => void;
  onClose: () => void;
  isSaving: boolean;
}

const FormDialog: React.FC<FormDialogProps> = ({ open, onClose, onSave, isSaving }) => {
  const INITIAL_FORM_STATE = {
    title: '',
    slug: '',
    uploaded_file: '',
    metadata: {
      type: CourseType.JUNO,
    },
  };
  const coursesPanelContext = useContext(CoursesPanelContext);

  const onFileUploaded = (formik: FormikProps<CoursesForm>) => (fileUrl: string) => {
    coursesPanelContext.setIsFileUploading(false);
    formik.setFieldValue('uploaded_file', fileUrl);
  };

  const onFileUploadStarted = () => {
    coursesPanelContext.setIsFileUploading(true);
  };

  const changeHandler =
    (formik: FormikProps<CoursesForm>) => (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.target;
      if (target.name === 'title') {
        // Update slug value based on title update for new pages
        formik.setFieldValue('slug', slugify(target.value));
      }
      if (target.name === 'slug') {
        // Require slug to follow slugify rules (alphanumeric, dashes, underscores, and uppercase here)
        formik.setFieldValue('slug', slugify(target.value, true));
      }
      formik.handleChange(e);
    };

  return (
    <Formik
      initialValues={{ ...INITIAL_FORM_STATE }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        onSave(values);
        actions.resetForm();
      }}
      enableReinitialize={true}
    >
      {(props) => (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>Create course</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              disabled={isSaving}
              id='title'
              name='title'
              label='Title'
              fullWidth
              margin='dense'
              value={props.values.title}
              onChange={changeHandler(props)}
              error={props.touched.title && Boolean(props.errors.title)}
              helperText={props.touched.title && props.errors.title}
              onBlur={props.handleBlur}
            />
            <TextField
              disabled={isSaving}
              id='slug'
              name='slug'
              label='Slug'
              fullWidth
              margin='dense'
              value={props.values.slug}
              onChange={changeHandler(props)}
              onBlur={props.handleBlur}
            />
            <FormControl fullWidth margin='dense'>
              <InputLabel id='course-type-select-label'>Course Type</InputLabel>
              <Select
                labelId='course-type-select-label'
                id='course-type-select'
                value={props.values.metadata.type}
                label='Course Type'
                onChange={props.handleChange}
                name='metadata.type'
                defaultValue={props.values.metadata.type}
              >
                {Object.entries(CourseTypeMap).map(([key, value], index) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {props.values.metadata.type === CourseType.SCORM && (
              <Typography align='center'>
                Select the SCORM, AICC, cmi5, or xAPI file you want to upload
              </Typography>
            )}
            {props.values.metadata.type === CourseType.SCORM && (
              <Box display='flex'>
                <Grid container spacing={2} mt={1}>
                  <Grid item xs={4}>
                    <FileUploadButton
                      onFileUploaded={onFileUploaded(props)}
                      onFileUploadStarted={onFileUploadStarted}
                      acceptFiles={{ 'application/zip': ['.zip', '.ZIP'] }}
                      maxSizeBytes={MAX_SCORM_UPLOAD_SIZE}
                      icon={UploadFileIcon}
                      title={'Learning Package'}
                      body={'Browse files (1GB)'}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      id='uploaded_file'
                      name='uploaded_file'
                      label='File URL'
                      placeholder=''
                      value={props.values.uploaded_file || ''}
                      onChange={changeHandler(props)}
                      error={props.touched.uploaded_file && Boolean(props.errors.uploaded_file)}
                      helperText={
                        props.touched.uploaded_file && props.errors.uploaded_file
                          ? props.errors.uploaded_file
                          : '*Supported file types: ZIP'
                      }
                      onBlur={props.handleBlur}
                      variant='outlined'
                      sx={{}}
                      disabled={isSaving}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <Button disabled={isSaving} onClick={onClose}>
                Cancel
              </Button>
              <LoadingButton
                disabled={!(props.dirty && props.isValid)}
                loading={isSaving}
                variant='contained'
                onClick={() => props.handleSubmit()}
              >
                Save
              </LoadingButton>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
export default FormDialog;
