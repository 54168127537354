import React, { useRef, useState } from 'react';
import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { JunoUser } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';

export interface RoomItem {
  id: number | string;
  name: string;
  users: JunoUser[];
}

interface RoomsLayoutComponentProps {
  rooms: RoomItem[];
  setRooms: React.Dispatch<React.SetStateAction<RoomItem[]>>;
  setHasMovedUser: React.Dispatch<React.SetStateAction<boolean>>;
  roomsActive?: boolean;
  onJoinRoom?: (roomId: number | string) => void;
}
const RoomsLayoutComponent: React.FC<RoomsLayoutComponentProps> = ({
  rooms,
  setRooms,
  setHasMovedUser,
  onJoinRoom,
  roomsActive = false,
}) => {
  const dragConstraintRef = useRef<HTMLDivElement>(null);
  const [highlightedRoomId, setHighlightedRoomId] = useState<number | string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedUser, setDraggedUser] = useState<JunoUser | null>(null);
  const theme = useTheme();
  const { user: currentUser } = useSettings();

  const handleDrop = (
    droppedUser: JunoUser,
    sourceRoomId: number | string,
    targetRoomId: number | string,
  ) => {
    setHighlightedRoomId(null);
    setIsDragging(false);
    setDraggedUser(null);
    if (sourceRoomId === targetRoomId) return;
    const updatedRooms = rooms.map((room) => {
      if (room.id === sourceRoomId) {
        return { ...room, users: room.users.filter((user) => user.id !== droppedUser.id) };
      } else if (room.id === targetRoomId) {
        return {
          ...room,
          users: [...room.users, droppedUser],
        };
      }
      return room;
    });
    setRooms(updatedRooms);
    setHasMovedUser(true);
  };
  const handleDragEnd = (user: JunoUser, sourceRoomId: number, translateY: number) => {
    const containerTop = dragConstraintRef.current?.getBoundingClientRect().top || 0;
    const relativeTranslateY = translateY - containerTop;
    const roomHeight = 200;
    const targetRoomIndex = Math.floor(relativeTranslateY / roomHeight);
    if (
      targetRoomIndex >= 0 &&
      targetRoomIndex < rooms.length &&
      rooms[targetRoomIndex].id !== sourceRoomId
    ) {
      setHasMovedUser(true);
      handleDrop(user, sourceRoomId, rooms[targetRoomIndex].id);
    }
  };
  return (
    <motion.div
      layoutScroll
      style={{
        maxHeight: 'calc(100% - 280px)',
        overflowY: 'auto',
        position: 'relative',
        paddingTop: '10px',
      }}
    >
      <Box
        display='flex'
        width='100%'
        flexDirection='column'
        ref={dragConstraintRef}
        position={'relative'}
      >
        {rooms.map((room) => (
          <motion.div
            layout
            key={room.id}
            id={`room-${room.id}`}
            onDragOver={() => {
              setHighlightedRoomId(room.id);
            }}
            style={{
              borderRadius: 1,
              border:
                highlightedRoomId === room.id ? `2px dashed ${theme.palette.primary.main}` : 'none',
            }}
          >
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography variant='body1' pl={1} pr={1}>
                {room.name}
              </Typography>
              {roomsActive ? (
                <>
                  <Typography variant='caption' pl={1} pr={1}>
                    <Button
                      // disabled if the user is in this room
                      // TODO figure out how to get ids to match
                      disabled={room.users.some(
                        (user) =>
                          user.id === currentUser?.id ||
                          user.first_name ===
                            `${currentUser?.first_name} ${currentUser?.last_name}`,
                      )}
                      variant='outlined'
                      size='small'
                      color='primary'
                      onClick={() => onJoinRoom && onJoinRoom(room.id)}
                    >
                      Join
                    </Button>
                  </Typography>
                </>
              ) : (
                <Typography variant='caption' pl={1} pr={1}>
                  ({room.users.length} users)
                </Typography>
              )}
            </Box>
            <Box
              key={room.id}
              sx={{
                color: 'white',
                p: 1,
                textAlign: 'center',
                borderRadius: 1,
                mb: 1,
              }}
            >
              <Box
                display='flex'
                flexDirection='column'
                justifyContent={room.users.length === 0 ? 'center' : 'flex-start'}
              >
                {room.users.length === 0 && (
                  <Typography variant='caption'>Drag users to assign to rooms</Typography>
                )}
                {room.users.length > 0 && (
                  <Stack spacing={1}>
                    {room.users
                      .sort((a, b) => a.first_name.localeCompare(b.first_name))
                      .map((user) => (
                        <motion.div
                          dragConstraints={dragConstraintRef}
                          dragSnapToOrigin
                          key={user.id}
                          draggable
                          dragPropagation={true}
                          layout
                          onDragStart={() => {
                            setIsDragging(true);
                            setDraggedUser(user);
                          }}
                          onDragEnd={(event) => {
                            handleDrop(user, room.id, highlightedRoomId || 1);
                          }}
                          style={{
                            opacity: isDragging && draggedUser?.id !== user.id ? 0.0 : 1,
                          }}
                        >
                          <Box bgcolor={'#232323'} borderRadius={1} p={1} sx={{ cursor: 'grab' }}>
                            <Stack direction='row' spacing={2} alignItems='center'>
                              <Avatar
                                src={user.avatar || user.icon || ''}
                                sx={{ width: 24, height: 24, cursor: 'default' }}
                              />
                              <Typography variant='body1' textAlign={'left'}>
                                {user.id === currentUser?.id
                                  ? `${user.first_name} (You)`
                                  : user.first_name}
                              </Typography>
                            </Stack>
                          </Box>
                        </motion.div>
                      ))}
                  </Stack>
                )}
              </Box>
            </Box>
          </motion.div>
        ))}
      </Box>
    </motion.div>
  );
};

export default RoomsLayoutComponent;
