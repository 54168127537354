import React, { useState } from 'react';
import { useGetAllSessions } from '@juno/client-api';
import { Schedule, ScheduleSkeleton } from '@juno/modules';
import { usePagination, useSessionsSearchFilter, useSettings } from '@juno/utils';

const PAGE_SIZE = 20;
const YourSchedule: React.FC = () => {
  const { site, user } = useSettings();
  const [search, debouncedSearch, searchFilter, setSearch] = useSessionsSearchFilter();
  const [sort, setSort] = useState('date_start');
  const [page, setPage] = useState(0);
  const {
    data: sessions,
    isLoading: isLoadingSessions,
    isFetching: isFetchingSessions,
    refetch,
  } = useGetAllSessions(
    site?.id || '',
    {
      order: sort,
      offset: page,
      limit: PAGE_SIZE,
      filter: {
        date_start__isnull: false,
        date_end__isnull: false,
        scheduled_attendees__id__in: user?.id ? [user.id] : [],
        active: true,
      },
    },
    {
      query: {
        enabled: !!site?.id && !!user?.id,
      },
    },
  );
  const [data, setData, ref, isLoading] = usePagination(
    sessions,
    isLoadingSessions,
    isFetchingSessions,
    search,
    debouncedSearch,
    setPage,
    true,
  );

  if (isLoading) {
    return <ScheduleSkeleton />;
  }

  return (
    <Schedule
      page={page}
      limit={PAGE_SIZE}
      sessions={data}
      refetchSessions={refetch}
      sort={sort}
      setSort={setSort}
      setSearch={setSearch}
      ref={ref}
      setData={setData}
      isLoading={isLoading}
      setPage={setPage}
    />
  );
};
export default YourSchedule;
