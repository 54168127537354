import React, { useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Formik } from 'formik';
import { JunoUser, RoleEnum } from '@juno/client-api/model';
import DialogAriaWrapper from '../DialogAriaWrapper';
import { GenericFormikInput } from '../GenericInputComponents';

const UserSchema = Yup.object().shape({
  first_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  last_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const UserChip = styled(Chip)(({ theme }) => ({
  padding: 0,
  height: 26,
}));

interface EditUserFormProps {
  currentUser: JunoUser | null;
  dialogOpen: boolean;
  handleClose: () => void;
  handleSave: (user: JunoUser) => void;
  isSaving: boolean;
}

const EditUserForm: React.FC<EditUserFormProps> = ({
  currentUser,
  dialogOpen,
  handleClose,
  handleSave,
  isSaving,
}) => {
  const formikRef = useRef<{ values: JunoUser }>();
  useEffect(() => {
    if (currentUser && formikRef.current) {
      formikRef.current.values = currentUser;
    }
  }, [currentUser]);

  return (
    <DialogAriaWrapper
      open={dialogOpen}
      onClose={() => handleClose()}
      maxWidth='lg'
      id='edit_user_dialog'
    >
      <Formik
        ref={formikRef}
        initialValues={
          currentUser || {
            id: '',
            created: '',
            enrollments_count: 0,
            enrollments_in_progress_count: '',
            access_passes: [],
            declared_tags: [],
            email: '',
            first_name: '',
            last_name: '',
            followed_forum_topics: [],
            community_groups_admin: [],
            community_groups_member: [],
            community_groups_pending_member: [],
            skills: [],
            interests: [],
          }
        }
        onSubmit={handleSave}
        validationSchema={UserSchema}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Box sx={{ opacity: currentUser ? 1 : 0, width: 800 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2, pl: 3 }}>
                <Typography variant='h6'> {currentUser?.id ? 'Edit' : 'Create'} User</Typography>
                <Typography sx={{ opacity: 0.7 }} variant='caption'>
                  {currentUser?.id && `ID: ${currentUser?.id}`}
                </Typography>
              </Box>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} />
                  <Grid item xs={12} sm={4}>
                    <GenericFormikInput
                      name='first_name'
                      value={props.values.first_name || ''}
                      label='First Name'
                      InputProps={{ disableUnderline: true }}
                      variant='filled'
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <GenericFormikInput
                      name='last_name'
                      value={props.values.last_name || ''}
                      label='Last Name'
                      InputProps={{ disableUnderline: true }}
                      variant='filled'
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <GenericFormikInput
                      name='email'
                      value={props.values.email || ''}
                      label='Email'
                      disabled={currentUser?.id !== ''}
                      InputProps={{ disableUnderline: true }}
                      variant='filled'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              props.setFieldValue(
                                'role',
                                e.target.checked ? RoleEnum.NUMBER_1 : RoleEnum.NUMBER_0,
                              )
                            }
                            checked={props.values.role === RoleEnum.NUMBER_1}
                          />
                        }
                        label='Admin Privileges'
                      />
                    </FormGroup>
                  </Grid>
                  {currentUser?.id && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <Typography mb={1}>Tags</Typography>
                        {currentUser?.declared_tags.map((tag) => (
                          <UserChip key={tag.id} label={tag.value} sx={{ mb: 0.5, mr: 0.5 }} />
                        ))}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography mb={1}>Access Passes</Typography>
                        {currentUser?.access_passes.map((accessPass) => (
                          <UserChip
                            key={accessPass.id}
                            label={accessPass.name}
                            sx={{ mb: 0.5, mr: 0.5 }}
                          />
                        ))}
                      </Grid>
                    </>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleClose()}>Cancel</Button>
                <LoadingButton loading={isSaving} type='submit' variant='contained'>
                  Save
                </LoadingButton>
              </DialogActions>
            </Box>
          </form>
        )}
      </Formik>
    </DialogAriaWrapper>
  );
};

export default EditUserForm;
