import React from 'react';
import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AccessPass, Product } from '@juno/client-api/model';
import { customShadow } from '@juno/ui';

interface AccessPassItemProps {
  accessPass: AccessPass;
}
interface ExtendedAccessPass extends AccessPass {
  products: Product[];
}

const AccessPassListItem: React.FC<AccessPassItemProps> = ({ accessPass }) => {
  const AP = accessPass as ExtendedAccessPass;
  const navigate = useNavigate();
  return (
    <Card
      sx={{
        borderRadius: 1,
        width: '100%',
        backgroundColor: 'background.paper',
        border: '1px solid divider',
        borderTop: '4px solid primary.main',
        boxShadow: customShadow,
      }}
    >
      <CardActionArea
        onClick={(e) => {
          if (accessPass.id) {
            navigate(accessPass.id);
          }
        }}
        sx={{ minHeight: 120, alignItems: 'flex-start', display: 'flex' }}
      >
        <CardContent
          sx={{
            padding: 2,
            width: '100%',
          }}
        >
          <Box sx={{ width: '90%' }}>
            <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{accessPass.name}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
export default AccessPassListItem;
