import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { AlignmentEnum } from '@juno/client-api/fakeModel';
import { useBreakpoint } from '@juno/utils';
import { Container } from '../JunoModule/styles';
import JunoModuleButton from '../ModuleButton';
import { SideBySideDataModel } from './form';

interface SideBySideProps {
  component: SideBySideDataModel;
}

const SideBySide: React.FC<SideBySideProps> = ({ component }) => {
  const { xs, sm } = useBreakpoint();

  const { alignment, image, title, subtitle, button } = component;

  const htmlValue = useMemo(() => {
    const sanitizedString = DOMPurify.sanitize(subtitle as string, {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'],
    });
    // uncomment to debug what's getting removed
    // console.log('Dirty values', DOMPurify.removed);
    return sanitizedString;
  }, [subtitle]);

  if (!component) {
    return <></>;
  }

  const alignmentFlexValue =
    button?.alignment === AlignmentEnum.left
      ? 'flex-start'
      : button?.alignment === AlignmentEnum.center
      ? 'center'
      : 'flex-end';

  return (
    <Container fullWidth={component?.fullWidth || false}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: xs ? 'column' : alignment === AlignmentEnum.left ? 'row' : 'row-reverse',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: 3,
          gap: 6,
        }}
      >
        <Box sx={{ width: xs ? '100%' : '50%' }}>
          <img src={image} alt='side by side' style={{ width: '100%', borderRadius: 8 }} />
        </Box>
        <Box sx={{ width: xs ? '100%' : '50%' }}>
          <Typography dangerouslySetInnerHTML={{ __html: htmlValue }} />
          <Box display='flex' justifyContent={alignmentFlexValue}>
            <JunoModuleButton {...button} />
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default SideBySide;
