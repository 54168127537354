import {
  EmailThemeConfigProps,
  FEATURE_CONFIG_AUTOMATION_TYPE,
  FEATURE_CONFIG_ECOMMERCE_TYPE,
  FEATURE_CONFIG_THEME_KEY,
  FEATURE_CONFIG_THEME_TYPE,
  ThemeConfigProps,
} from '@juno/constants';
import { JunoImage as JunoImageModel } from '../api/fakeModel';
import {
  CourseResource as CourseResourceModel,
  Download as DownloadModel,
  FeatureConfig as FeatureConfigModel,
  FeatureConfigTypeEnum,
  NavigationItem as NavigationItemModel,
  Page as PageModel,
  Site as SiteModel,
  Type0c0Enum,
  TypeD1bEnum,
} from '../api/model';

interface ParentChildNavigationMap {
  [key: string]: NavigationItemModel[];
}

export interface NavigationMap {
  mainNavItems: NavigationItemModel[];
  selectedMainNavIds: string[];
  subNavItems: NavigationItemModel[];
  selectedSubNavIds: string[];
  bottomNavItems: NavigationItemModel[];
  selectedBottomNavIds: string[];
  sideNavItems: NavigationItemModel[];
  parentIdChildNavigationMap: ParentChildNavigationMap;
}

export const FeatureConfig = {
  getConfig: (
    configs: FeatureConfigModel[] | undefined,
    type: string,
  ): FeatureConfigModel | undefined => {
    return configs?.find((c) => c.type === type);
  },
  getThemeConfig: (configs: FeatureConfigModel[] | undefined): FeatureConfigModel | undefined => {
    return configs?.find((c) => c.type === FEATURE_CONFIG_THEME_TYPE);
  },
  getThemeConfigTheme: (configs: FeatureConfigModel[] | undefined): ThemeConfigProps => {
    const themeConfig = FeatureConfig.getThemeConfig(configs);
    const configValue = themeConfig?.config?.[FEATURE_CONFIG_THEME_KEY];
    return configValue ? JSON.parse(configValue as string) : null;
  },
  getEcommerceConfig: (
    configs: FeatureConfigModel[] | undefined,
  ): FeatureConfigModel | undefined => {
    return configs?.find((c) => c.type === FEATURE_CONFIG_ECOMMERCE_TYPE);
  },
  buildThemePayload: (
    themeConfigTheme: ThemeConfigProps | undefined,
    configs?: FeatureConfigModel[] | undefined,
  ): FeatureConfigModel => {
    const themeConfig = FeatureConfig.getThemeConfig(configs);
    return {
      id: themeConfig?.id || '',
      type: FEATURE_CONFIG_THEME_TYPE,
      config: {
        ...themeConfig?.config,
        [FEATURE_CONFIG_THEME_KEY]: JSON.stringify(themeConfigTheme),
      },
    };
  },

  getEmailConfig: (configs: FeatureConfigModel[] | undefined): FeatureConfigModel | undefined => {
    return configs?.find((c) => c.type === FEATURE_CONFIG_AUTOMATION_TYPE);
  },
  getEmailConfigConfig: (configs: FeatureConfigModel[] | undefined): EmailThemeConfigProps => {
    const themeConfig = FeatureConfig.getEmailConfig(configs);
    const configValue = themeConfig?.config;
    return configValue as unknown as EmailThemeConfigProps;
  },
  buildEmailConfigPayload: (
    emailConfigTheme: EmailThemeConfigProps | undefined,
    configs?: FeatureConfigModel[] | undefined,
  ): FeatureConfigModel => {
    const emailConfig = FeatureConfig.getEmailConfig(configs);
    return {
      id: emailConfig?.id || '',
      type: FEATURE_CONFIG_AUTOMATION_TYPE,
      config: {
        ...emailConfigTheme,
      },
    };
  },
  getFeatureFlag: (configs: FeatureConfigModel[] | undefined, key: string): boolean => {
    const featureConfigs = configs?.find((c) => c.type === FeatureConfigTypeEnum.feature_flag);
    const isEnabled = featureConfigs?.config?.[key] === true;
    return isEnabled;
  },
};

export const Page = {
  getPage: (pages: PageModel[] | undefined, id: string | undefined): PageModel | undefined => {
    if (!id) return;
    return pages?.find((page) => page.id === id);
  },
  getPageBySlug: (pages: PageModel[] | undefined, slug: string): PageModel | undefined => {
    return pages?.find((page) => page.slug === slug);
  },
};

export const Site = {
  getHomepageUrl: (site: SiteModel | undefined): string => {
    return site?.slug ? (`${site.slug}/home` as string) : 'main/home';
  },
};

export const JunoImage = {
  fake: (id: string, url: string | null | undefined): JunoImageModel => ({
    id,
    created: '',
    name: '',
    alt: url || 'image',
    width: 200,
    height: 200,
    size: 1000,
    type: 'jpg',
    url: url || '',
    thumb_url: url || '',
  }),
};

export const Download = {
  fromCourseResource: (resource: CourseResourceModel): DownloadModel => ({
    id: '',
    url: '',
    title: resource.title,
    description: resource.description,
    icon: resource.icon,
    filetype: '',
  }),
};

export const LessonPart = {
  LESSON_PART_TYPES: {
    BLURB: { value: TypeD1bEnum.NUMBER_1, label: 'Blurb' },
    VIDEO: { value: TypeD1bEnum.NUMBER_2, label: 'Video' },
    QUESTION: { value: TypeD1bEnum.NUMBER_3, label: 'Question' },
    RESOURCE: { value: TypeD1bEnum.NUMBER_4, label: 'Resource' },
  },
  labelFromType: (partType: number | undefined) => {
    return (
      Object.values(LessonPart.LESSON_PART_TYPES).find((type) => type.value === partType)?.label ||
      ''
    );
  },
  isBlurbType: (partType: number | undefined) => {
    return partType === LessonPart.LESSON_PART_TYPES.BLURB.value;
  },
  isVideoType: (partType: number | undefined) => {
    return partType === LessonPart.LESSON_PART_TYPES.VIDEO.value;
  },
  isQuestionType: (partType: number | undefined) => {
    return partType === LessonPart.LESSON_PART_TYPES.QUESTION.value;
  },
  isResourceType: (partType: number | undefined) => {
    return partType === LessonPart.LESSON_PART_TYPES.RESOURCE.value;
  },
};

export const Question = {
  QUESTION_TYPES: {
    MULTIPLE_CHOICE: { value: Type0c0Enum.NUMBER_1, label: 'Multiple Choice' },
    POLL: { value: Type0c0Enum.NUMBER_2, label: 'Poll' },
    FILL_IN_THE_BLANK: { value: Type0c0Enum.NUMBER_3, label: 'Fill in the Blank' },
    RANKING: { value: Type0c0Enum.NUMBER_4, label: 'Ranking' },
    RATING: { value: Type0c0Enum.NUMBER_5, label: 'Rating' },
  },
  labelFromType: (questionType: number | undefined) => {
    return (
      Object.values(Question.QUESTION_TYPES).find((type) => type.value === questionType)?.label ||
      ''
    );
  },
};

export const Enrollment = {
  ENROLLMENT_STATUSES: {
    PENDING_APPROVAL: { value: Type0c0Enum.NUMBER_1, label: 'Pending Approval' },
    APPROVED: { value: Type0c0Enum.NUMBER_2, label: 'Approved' },
    DENIED: { value: Type0c0Enum.NUMBER_3, label: 'Denied' },
    STARTED: { value: Type0c0Enum.NUMBER_4, label: 'Started' },
    COMPLETED: { value: Type0c0Enum.NUMBER_5, label: 'Completed' },
  },
  labelFromStatus: (enrollmentStatus: number | undefined) => {
    return (
      Object.values(Enrollment.ENROLLMENT_STATUSES).find(
        (status) => status.value === enrollmentStatus,
      )?.label || ''
    );
  },
};
