import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CategoryButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  '&.MuiButton-outlined': {
    borderColor: theme.palette.divider,
    color: theme.palette.text.disabled,
    background: 'rgba(0,0,0,.03)',
    fontSize: '12px',
  },
}));

export const Overlay = styled(Box, { shouldForwardProp: (prop) => prop !== 'isShowing' })<{
  isShowing?: boolean;
}>(({ isShowing, theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0,0,0,.5)',
  display: isShowing ? 'flex' : 'none',
  zIndex: 1,
}));

export const CategoryTypography = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  letterSpacing: '.1rem',
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2),
}));
