import React, { useEffect, useMemo, useState } from 'react';
import { AutorenewOutlined } from '@mui/icons-material';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import EventNoteRoundedIcon from '@mui/icons-material/EventNoteRounded';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import ImportantDevicesRoundedIcon from '@mui/icons-material/ImportantDevicesRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import { Box, Button, Card, Divider, Grid, Stack, Typography } from '@mui/material';
import {
  eachDayOfInterval,
  format,
  getDay,
  isSameMonth,
  lastDayOfMonth,
  parseISO,
  startOfMonth,
} from 'date-fns';
import DOMPurify from 'dompurify';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSession, useGetSessionMedia } from '@juno/client-api';
import {
  JunoUser,
  ReoccurrenceEnum,
  RoleEnum,
  RoomTypeEnum,
  SearchContentTypeEnum,
  Session,
  TimingEnum,
} from '@juno/client-api/model';
import {
  AddToCalendarButton,
  Container,
  DefaultHeroBanner,
  DefaultSearchSortFilter,
  GenericCountdownTimer,
  JunoRotator,
  ProductCard,
  UserQuickView,
  UserTile,
} from '@juno/ui';
import { getContentRoute, handleFileDownload, useBreakpoint, useSettings } from '@juno/utils';
import { ProductStatus } from '../';
import SessionAttendButton from '../components/SessionAttendButton';
import SessionLikeButton from '../components/SessionLikeButton';
import { getRelativeStartsIn } from '../helpers';

const MAX_DESCRIPTION_LENGTH = 450; // TODO tweak this to use height instead of character count
interface RoomInfoProps {
  refetchSession?: () => void;
  hasStarted?: boolean;
  splashPageTimerCallback?: {
    number: number;
    callback: () => void;
  };
  isPaywalled?: boolean;
  productStatus?: ProductStatus;
}
const RoomInfo: React.FC<RoomInfoProps> = ({
  refetchSession = () => {},
  hasStarted = false,
  splashPageTimerCallback,
  isPaywalled,
  productStatus,
}) => {
  const { site, user: currentUser, isSmallScreen } = useSettings();
  const { xs, md } = useBreakpoint();
  const navigateRoute = useNavigate();

  const [attendees, setAttendees] = useState<JunoUser[]>([]);
  const [sortAttendees, setSortAttendees] = useState('first_name');
  const [search, setSearch] = useState('');
  const [expanded, setExpanded] = useState(false);
  const [userQuickViewAnchorEl, setUserQuickViewAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<JunoUser | null>(null);
  const { sessionSlug } = useParams();

  const { data: sessionData, isLoading } = useGetSession(
    site?.id || '',
    sessionSlug || '',
    {},
    {
      query: {
        enabled: !!site?.id && !!sessionSlug,
      },
    },
  );

  const bannerHeight = xs ? '32vw' : md ? '246px' : '294px';
  const displayLabel = sessionData?.chip_label
    ? sessionData?.chip_label
    : sessionData?.room_type === RoomTypeEnum.NUMBER_1
    ? 'Main Stage'
    : 'Breakout';

  const iAmAdmin = useMemo(() => {
    if (currentUser?.role === RoleEnum.NUMBER_1) return true;
    if (sessionData?.moderators) {
      return sessionData?.moderators?.some((mod) => {
        if (mod.id === currentUser?.id) {
          return true;
        }
        return false;
      });
    }
    return false;
  }, [currentUser, sessionData]);

  const getOrdinal = (n: number): string => {
    const ordinals = ['1st', '2nd', '3rd', '4th', '5th'];
    return ordinals[n - 1] || `${n}th`;
  };

  const getNthWeekday = (date: Date): string => {
    const dayOfWeek = getDay(date); // 0: Sunday, 1: Monday, ..., 6: Saturday
    const day = date.getDate();
    const daysInMonth = eachDayOfInterval({
      start: startOfMonth(date),
      end: lastDayOfMonth(date),
    }).filter((d) => getDay(d) === dayOfWeek && isSameMonth(d, date));

    const occurrence = Math.ceil(day / 7);

    if (occurrence === daysInMonth.length) {
      return `the last ${format(date, 'EEEE')}`;
    }

    return `${getOrdinal(occurrence)} ${format(date, 'EEEE')}`;
  };

  const sessionRecurrenceText = useMemo(() => {
    const dateStart = sessionData?.date_start ? parseISO(sessionData.date_start) : new Date(0);
    const timeStart = format(dateStart, 'h:mm a');

    if (sessionData?.reoccurrence === ReoccurrenceEnum.daily) {
      return `Recurs daily at ${timeStart}`;
    } else if (sessionData?.reoccurrence === ReoccurrenceEnum.monthly) {
      const nthWeekday = getNthWeekday(dateStart);
      return `Recurs monthly on the ${nthWeekday} at ${timeStart}`;
    }

    return `Recurs ${sessionData?.reoccurrence} on ${format(dateStart, 'EEEE')} at ${timeStart}`;
  }, [sessionData]);

  const { data: sessionMedia, isLoading: sessionMediaLoading } = useGetSessionMedia(
    site?.id || '',
    sessionData?.id || '',
    {},
    {
      query: {
        enabled: !!sessionData?.id && !!site?.id,
      },
    },
  );

  useEffect(() => {
    if (sessionData?.scheduled_attendees) {
      setAttendees(sessionData?.scheduled_attendees);
    }
  }, [sessionData]);

  const toggleExpanded = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  // Function to calculate the text length of HTML content
  const getTextLengthFromHTML = (html: any) => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    return tempElement?.textContent?.length;
  };

  const descriptionLength = getTextLengthFromHTML(sessionData?.description ?? '') || 0;

  const showReadMoreButton = descriptionLength > MAX_DESCRIPTION_LENGTH;

  return (
    <>
      <Card sx={{ boxShadow: 1 }}>
        <DefaultHeroBanner
          imgSrc={sessionData?.banner || ''}
          imgAlt={`Banner image for ${sessionData?.title}`}
          height={bannerHeight}
        />

        <Container>
          <Grid container spacing={0} sx={{ p: 2, pt: 1, pb: 1 }}>
            <Grid
              item
              xs={12}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Typography variant='h4' sx={{ mb: 1, fontWeight: 'bold' }}>
                {sessionData?.title}
              </Typography>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Grid
                container
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{ pl: xs ? '4px' : 0, display: 'flex', justifyContent: 'center' }}
                >
                  <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: 'center' }}>
                    {sessionData?.room_type === RoomTypeEnum.NUMBER_1 ? (
                      <ImportantDevicesRoundedIcon fontSize='small' sx={{ mr: 1 }} />
                    ) : (
                      <GroupsRoundedIcon fontSize='small' sx={{ mr: 1 }} />
                    )}
                    {displayLabel}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={4}
                  sx={{ pl: xs ? '8px' : 0, display: 'flex', justifyContent: 'center' }}
                >
                  <Typography variant='subtitle2' sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* // TODO call onClick  */}
                    <LocationOnRoundedIcon fontSize='small' sx={{ mr: 1 }} />
                    {sessionData?.location || 'No location'}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={4} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <SessionLikeButton
                    sessionData={sessionData || ({} as Session)}
                    refetchSession={refetchSession}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Card>
      <Container sx={{ mt: 2, boxShadow: 1 }}>
        {isPaywalled && (
          <ProductCard
            productStatus={productStatus || ({} as ProductStatus)}
            showQuantity={sessionData?.inperson}
            contentId={sessionData?.id || ''}
          />
        )}
        <Card sx={{ p: 2, boxShadow: 1 }}>
          <Grid container>
            <Grid item xs={12}>
              {/* // TODO add access overlay */}
              <Typography
                variant='body1'
                sx={{
                  mb: 2,
                  maxHeight: expanded ? 'none' : '30vh', // Adjust the maxHeight based on the expanded state
                  overflow: 'hidden',
                  WebkitLineClamp: expanded ? 'none' : 8,
                  WebkitBoxOrient: 'vertical',
                  display: '-webkit-box',
                  textOverflow: 'ellipsis',
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(sessionData?.description ?? '', {
                    ADD_TAGS: ['iframe'],
                    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
                  }),
                }}
              />

              <Box display={'flex'} justifyContent={'space-between'}>
                {iAmAdmin && !isSmallScreen && (
                  <Button
                    variant='outlined'
                    size='small'
                    sx={{ mr: 1 }}
                    onClick={() => navigateRoute('edit')}
                  >
                    Edit Session
                  </Button>
                )}
                {showReadMoreButton && (
                  <Button size='small' onClick={toggleExpanded}>
                    {expanded ? 'See Less' : 'Read More'}
                  </Button>
                )}
                {hasStarted ? (
                  <Stack direction={'row'} spacing={1}>
                    <Button
                      variant='contained'
                      onClick={() => {
                        splashPageTimerCallback && splashPageTimerCallback.callback();
                      }}
                      disabled={isPaywalled}
                    >
                      Join Session
                    </Button>
                  </Stack>
                ) : (
                  <SessionAttendButton
                    sessionData={sessionData || ({} as Session)}
                    refetchSession={refetchSession}
                  />
                )}
              </Box>
            </Grid>
          </Grid>
        </Card>
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card sx={{ p: 2, boxShadow: 1 }}>
                  <Box pl='2px'>
                    <Typography
                      variant='subtitle2'
                      sx={{ display: 'flex', alignItems: 'center', padding: 1 }}
                    >
                      <PeopleAltRoundedIcon fontSize='small' sx={{ mr: 1 }} />
                      {`${sessionData?.scheduled_attendees?.length || 0} Attendees`}
                    </Typography>
                  </Box>
                  <Box pl='4px'>
                    {/* // TODO add url */}
                    <AddToCalendarButton
                      title={sessionData?.title || ''}
                      description={sessionData?.description || ''}
                      dateStart={sessionData?.date_start || ''}
                      dateEnd={sessionData?.date_end || ''}
                      location={sessionData?.location || 'Online'}
                      reoccurrence={sessionData?.reoccurrence || ReoccurrenceEnum.once}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant='subtitle2'
                      sx={{ display: 'flex', alignItems: 'center', padding: 1 }}
                    >
                      <EventNoteRoundedIcon fontSize='small' sx={{ mr: 1 }} />
                      {/* // TODO do we want a tooltip to say "series" on recurrings? */}
                      {sessionData?.date_start &&
                        sessionData?.date_end &&
                        getRelativeStartsIn(
                          sessionData?.date_start,
                          sessionData?.date_end,
                          sessionData?.reoccurrence,
                          sessionData?.time_end,
                        )}
                    </Typography>
                    {sessionData?.reoccurrence !== ReoccurrenceEnum.once && (
                      <Typography
                        variant='subtitle2'
                        sx={{ display: 'flex', alignItems: 'center', padding: 1 }}
                      >
                        <AutorenewOutlined fontSize='small' sx={{ mr: 1 }} />
                        {sessionRecurrenceText}
                      </Typography>
                    )}
                    {sessionData?.reoccurrence === ReoccurrenceEnum.once && (
                      <GenericCountdownTimer
                        targetTime={sessionData?.date_start}
                        endTime={sessionData?.date_end}
                        onHitZero={() => {
                          // TODO do we want to do anything?
                        }}
                      />
                    )}
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card sx={{ boxShadow: 1, p: 2, pb: 3 }}>
                  <Typography variant={'body1'} fontWeight={'bold'} textAlign={'center'}>
                    Meet Your Presenters
                  </Typography>
                  <JunoRotator
                    onSelect={async (item, e) => {
                      setSelectedUser(
                        sessionData?.presenters?.find((user) => user.id === item.id) || null,
                      );
                      setUserQuickViewAnchorEl(e?.target as HTMLElement);
                    }}
                    settings={{
                      title: '',
                      slideHeight: 90,
                      slideWidth: 90,
                      arrows: false,
                      dots: true,
                      fullWidth: true,
                      slideVariant: 'circular',
                      showPauseButton: false,
                    }}
                    slides={
                      sessionData?.presenters?.map((user: JunoUser) => {
                        return {
                          id: user.id,
                          slug: user.id,
                          name: `${user.first_name} ${user.last_name}`,
                          description: user.email,
                          image: user.avatar || user.icon || '',
                        };
                      }) || []
                    }
                  />
                </Card>
                <UserQuickView
                  anchorEl={userQuickViewAnchorEl}
                  userId={selectedUser?.id || ''}
                  open={Boolean(userQuickViewAnchorEl)}
                  setAnchorEl={setUserQuickViewAnchorEl}
                  onViewProfile={(userEmail: string): void => {
                    navigateRoute(
                      getContentRoute(
                        site?.slug || '',
                        selectedUser?.id || '',
                        SearchContentTypeEnum.user,
                      ),
                    );
                  }}
                  onMessageUser={function (userId: string): void {
                    throw new Error('Function not implemented.');
                  }}
                />
              </Grid>
              {sessionMedia &&
                sessionMedia?.length > 0 &&
                sessionMedia.some((media) => media.timing === TimingEnum.download) && (
                  <Grid item xs={12}>
                    <Card sx={{ p: 2, mt: 1, boxShadow: 1 }}>
                      <Typography variant={'body1'} fontWeight={'bold'} textAlign={'center'}>
                        Resources
                      </Typography>
                      <Box p={2}>
                        {sessionMedia?.map((media) => {
                          if (media.timing === TimingEnum.download) {
                            return (
                              <Button
                                key={media.id}
                                variant='outlined'
                                component='label'
                                startIcon={<DownloadRoundedIcon />}
                                color={'primary'}
                                sx={{ mr: 1 }}
                                onClick={() => {
                                  handleFileDownload(
                                    media.media_item.url,
                                    media.media_item.description,
                                  );
                                }}
                              >
                                {media.media_item.description}
                              </Button>
                            );
                          }
                          return null;
                        })}
                      </Box>
                    </Card>
                  </Grid>
                )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 2, pb: 1, boxShadow: 1 }}>
              <Typography variant={'body1'} fontWeight={'bold'} textAlign={'center'} mb={1}>
                Attendee Directory
              </Typography>
              <DefaultSearchSortFilter
                buttonDisabled={true}
                showButton={false}
                buttonText={''}
                onClickButton={() => {}}
                setSearch={(value) => setSearch(value)}
                showFilter={false}
                showSort={true}
                sort={sortAttendees}
                setSort={(value) => {
                  setSortAttendees(value);
                }}
                sortOptions={[
                  { value: 'first_name', label: 'First Name' },
                  { value: 'last_name', label: 'Last Name' },
                  { value: 'email', label: 'Email' },
                ]}
              />
              <Box pt={2} height={'fit-content'} overflow={'auto'}>
                <Divider sx={{ mb: 1 }} />
                <Box mb={2}>
                  {attendees
                    .sort((a, b) => {
                      if (sortAttendees === 'first_name') {
                        return a.first_name.localeCompare(b.first_name);
                      }
                      if (sortAttendees === 'last_name') {
                        return a.last_name.localeCompare(b.last_name);
                      }
                      if (sortAttendees === 'email') {
                        return a.email.localeCompare(b.email);
                      }
                      return 0;
                    })
                    .filter((attendee) => {
                      return (
                        attendee.first_name.toLowerCase().includes(search.toLowerCase()) ||
                        attendee.last_name.toLowerCase().includes(search.toLowerCase()) ||
                        attendee.email.toLowerCase().includes(search.toLowerCase())
                      );
                    })
                    .map((attendee, index) => {
                      return <UserTile key={attendee.id} user={attendee} company={true} />;
                    })}
                  {attendees.length === 0 && <Typography pl={1}>No attendees found</Typography>}
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
export default RoomInfo;
