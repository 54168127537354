import { Box, Stack, Typography } from '@mui/material';
import { JunoBackButton } from '@juno/ui';

interface LayoutProps {
  children: React.ReactNode;
  title: string;
  onBack: () => void;
}

const Layout: React.FC<LayoutProps> = ({ children, title, onBack }) => {
  const handleBack = () => {
    onBack && onBack();
  };

  return (
    <Box sx={{ pl: 4, pr: 4, pt: 2 }}>
      <Stack direction={'row'} sx={{ justifyContent: 'space-between' }}>
        <JunoBackButton onClick={handleBack}>
          <Typography sx={{ fontSize: '24px', lineHeight: '24px' }}>{title}</Typography>
        </JunoBackButton>
      </Stack>
      {children}
    </Box>
  );
};

export default Layout;
