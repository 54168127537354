import React, { useEffect, useState } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import { FormControl, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { Link } from 'react-router-dom';
import {
  createEducationCredit,
  createEducationCreditRequirement,
  useGetAllEducationCredits,
} from '@juno/client-api';
import {
  CertificateTemplate,
  Course,
  EducationCredit,
  EducationCreditRequirement,
} from '@juno/client-api/model';
import { DefaultMultiChipAutocomplete } from '@juno/ui';
import { snackOptions } from '@juno/utils';
import NewEducationCreditDialog from './NewEducationCreditDialog';

interface EducationCreditSectionProps {
  siteId: string;
  educationCredits: EducationCredit[];
  course: Course;
  handleChange: () => void;
  setFieldValue: (key: string, value: any) => void;
  refetchEducationCredits: () => void;
  isFetContext: boolean;
}
const EducationCreditSection: React.FC<EducationCreditSectionProps> = ({
  siteId,
  educationCredits,
  course,
  handleChange,
  setFieldValue,
  refetchEducationCredits,
  isFetContext,
}) => {
  const [linkText, setLinkText] = useState('Create New Education Credit');
  const [createNew, setCreateNew] = useState(true);
  const [createNewDialogShowing, setCreateNewDialogShowing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { data: allEducationCredits } = useGetAllEducationCredits(siteId);
  const [isLoading, setIsLoading] = useState(false);

  const theme = useTheme();
  useEffect(() => {
    if (educationCredits?.length > 0) {
      setLinkText('Edit Education Credit(s)');
      setCreateNew(false);
    } else {
      setLinkText('Create New Education Credit');
      setCreateNew(true);
    }
  }, [educationCredits]);

  const handleCreateNew = (values: {
    title: string;
    date_released: string;
    date_claimable: string;
    certificate: CertificateTemplate;
  }) => {
    setIsLoading(true);
    const newEducationCredit = {
      name: values.title,
      date_released: values.date_released !== '' ? values.date_released : null,
      date_claimable: values.date_claimable !== '' ? values.date_claimable : null,
      certificate_template_id: values.certificate.id,
      num_credits: course.credits ? `${course.credits}` : '0.00',
      site_id: siteId,
      tags: [],
      access_passes: [],
    } as EducationCredit;
    createEducationCredit(siteId, newEducationCredit)
      .then((ecResp) => {
        const newRequirement = {
          education_credit_id: ecResp.id,
          course_id: course.id,
          earned_hours: course.credits,
          completion_threshold: course.passing_percent,
        } as EducationCreditRequirement;
        // create new requirement
        if (ecResp.id) {
          createEducationCreditRequirement(siteId, ecResp.id, newRequirement)
            .then((ecReqResp) => {
              setCreateNewDialogShowing(false);
              enqueueSnackbar(
                'Successfully created new education credit and requirement',
                snackOptions('success'),
              );
              refetchEducationCredits();
            })
            .catch((err) => {
              console.error(err);
              enqueueSnackbar('Error creating education credit requirement', snackOptions('error'));
            });
        }
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar('Error creating education credit', snackOptions('error'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <DefaultMultiChipAutocomplete
              label={'Education Credit(s)'}
              fieldValue={'education_credits'}
              allContentOptions={
                allEducationCredits
                  ? allEducationCredits?.sort((a, b) => -b?.name?.localeCompare(a?.name))
                  : []
              }
              filteredOptions={
                educationCredits
                  ? educationCredits?.sort((a, b) => -b?.name?.localeCompare(a?.name))
                  : []
              }
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              filterSelectedOptions={false}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {!isFetContext && (
            <FormControl fullWidth>
              <Link
                to={createNew ? '' : '../../../education_credits'}
                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
                onClick={() => {
                  if (createNew) {
                    setCreateNewDialogShowing(true);
                  }
                }}
              >
                <Typography
                  sx={{
                    fontSize: theme.typography.caption,
                    color: theme.palette.primary.main,
                    textAlign: 'right',
                  }}
                >
                  {linkText}
                </Typography>
                {!createNew && (
                  <LaunchIcon
                    sx={{
                      fontSize: theme.typography.caption,
                      color: theme.palette.primary.main,
                      ml: 1,
                    }}
                  />
                )}
              </Link>
            </FormControl>
          )}
        </Grid>
      </Grid>
      <NewEducationCreditDialog
        siteId={siteId}
        open={createNewDialogShowing}
        setCreateNewDialogShowing={setCreateNewDialogShowing}
        allEducationCredits={allEducationCredits ?? []}
        title={course.title}
        date_released={course.date_released ?? ''}
        onCreate={handleCreateNew}
        isLoading={isLoading}
      />
    </>
  );
};
export default EducationCreditSection;
