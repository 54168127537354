import React from 'react';
import { Box, Skeleton } from '@mui/material';

export const JunoSkeleton = () => {
  return (
    <Box sx={{ width: '100%', height: '100vh' }}>
      {/* Header Skeleton */}
      <Skeleton variant='rectangular' width='100%' height='60px' />

      {/* Columns container */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          height: 'calc(100vh - 90px)',
          marginTop: (theme) => theme.spacing(2),
          width: '100%',
        }}
      >
        {/* Side column */}
        <Box sx={{ width: 290, marginX: 1, paddingX: 0.5 }}>
          <Skeleton variant='rectangular' width='100%' height='25%' sx={{ borderRadius: 2 }} />
          <Skeleton
            variant='rectangular'
            width='100%'
            height='25%'
            sx={{ mt: 2, borderRadius: 2 }}
          />
          <Skeleton
            variant='rectangular'
            width='100%'
            height='25%'
            sx={{ mt: 2, borderRadius: 2 }}
          />
        </Box>

        {/* Middle column */}
        <Box sx={{ flex: 1, marginX: 2 }}>
          <Skeleton
            variant='rectangular'
            width='100%'
            height='100%'
            sx={{ borderRadius: (theme) => theme.spacing(1) }}
          />
        </Box>
      </Box>
    </Box>
  );
};
