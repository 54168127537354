import { AppBar, Box, Button, FormControl, Tab, TabProps, TextField, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

export const HeaderWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'showSideMenu' && prop !== 'showSubnav',
})<{
  showSideMenu?: boolean;
  showSubnav?: boolean;
}>(({ theme, showSideMenu, showSubnav }) => ({
  margin: '0',
  left: showSideMenu ? 280 : 0,
  position: 'relative',
  overflow: 'hidden',
  transition: 'left 0.3s ease-in-out',
  paddingTop: showSubnav ? '110px' : '60px',
  '& header': {
    top: 0,
    position: 'fixed',
    height: showSubnav ? 110 : 60,
  },
}));

export const AppBarWrapper = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'showSideMenu',
})<{
  showSideMenu?: boolean;
}>(({ theme, showSideMenu }) => ({
  left: showSideMenu ? 280 : 0,
  transition: 'left 0.3s ease-in-out',
  boxShadow: theme.shadows[1],
}));

export const MainNavContent = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'showSideMenu',
})<{
  showSideMenu?: boolean;
}>(({ theme, showSideMenu }) => ({
  height: 60,
  minHeight: '50px !important',
  backgroundColor: '#fff',
  color: '#000',
  width: '100vw',
  position: 'relative',
  top: 0,
  paddingLeft: '0px !important',
  paddingRight: '0px !important',
}));

export const SubNavContent = styled(Toolbar)(({ theme }) => ({
  top: 0,
  height: 50,
  minHeight: '50px !important',
  backgroundColor: theme.palette.secondary.light,
  color: '#000',
  width: '100%',
  position: 'relative',
  paddingLeft: '15px !important',
  paddingRight: '15px !important',
}));

export const LogoLink = styled(Toolbar, {
  shouldForwardProp: (prop) => prop !== 'searchOpen' && prop !== 'to',
})<{
  searchOpen?: boolean;
}>(({ theme, searchOpen }) => ({
  cursor: 'pointer',
  display: 'flex',
  height: '60px',
  justifyContent: 'center',
  marginRight: searchOpen ? 0 : 2,
  marginLeft: searchOpen ? 0 : 1,
  visibility: searchOpen ? 'hidden' : 'visible',
  width: searchOpen ? 0 : 'auto',
  overflow: 'hidden',
  transition: 'opacity .35s',
  opacity: searchOpen ? 0 : 1,
  minHeight: '0px!important',
  paddingLeft: '8px!important',
  paddingRight: '8px!important',
}));

export const Logo = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  maxWidth: 110,
  minWidth: 80,
}));
// TODO need to clean up these methods - see /Header
export const HeaderLinkWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const HeaderLinkRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSmallScreen',
})<{
  isSmallScreen?: boolean;
}>(({ theme, isSmallScreen }) => {
  if (isSmallScreen) {
    return {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      background: '#fff',
      boxShadow: theme.shadows[5],
      justifyContent: 'flex-start',
      display: 'flex',
      overflowX: 'auto',
    };
  }
  return {
    columnGap: '0px',
    display: 'flex',
  };
});

export const HeaderLinkRowWrapper = styled(Box)(() => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  justifyContent: 'space-between',
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  height: 60,
}));

export const SearchField = styled(TextField)(() => ({
  fontSize: '0.8rem',
  borderRadius: 2,
  '.MuiOutlinedInput-root': {
    borderRadius: '8px',
  },
  '&:hover': {
    borderColor: 'rgba(0,0,0,.1)',
    backgroundColor: 'rgba(0,0,0,.01)',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0,0,0,.2)',
      backgroundColor: 'rgba(0,0,0,.01)',
    },
  },
}));

export const SearchContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'searchOpen',
})<{
  searchOpen?: boolean;
}>(({ theme, searchOpen }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  minHeight: searchOpen ? 130 : 0,
  background: searchOpen ? '#fff' : 'transparent',
  boxShadow: searchOpen ? theme.shadows[6] : 'none',
  borderBottomRightRadius: searchOpen ? 6 : 0,
  borderBottomLeftRadius: searchOpen ? 6 : 0,
  overflowY: 'auto',
  maxHeight: 500,
  width: 350,
}));

export const HeaderLinkButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{
  selected?: boolean;
}>(({ theme, selected }) => ({
  color: selected ? theme.palette.primary.main : '#000',
  //backgroundColor: selected ? '#eaeaea' : 'transparent',
  borderBottom: selected ? `solid 3px ${theme.palette.primary.main}` : 'solid 3px transparent',
  fontSize: '14px',
  fontFamily: 'Prompt, sans-serif',
  textTransform: 'none',
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'end',
  marginTop: 3,
  height: 57,
  transition: 'opacity 0.15s ease-in-out',
  minWidth: 90,
  '&:hover': {
    backgroundColor: '#eaeaea',
  },
  '.MuiSvgIcon-root': {
    fontSize: 26,
  },
}));

export const HeaderSubnavLinkWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '10px 0px',
  whiteSpace: 'nowrap',
  overflow: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const HeaderSubNavLinkRow = styled(Box)(() => ({
  display: 'flex',
  columnGap: '44px',
  marginLeft: '6px',
}));

export const HeaderSubNavLinkButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{
  selected?: boolean;
}>(({ theme, selected }) => ({
  minWidth: 0,
  padding: 0,
  color: theme.palette.primary.contrastText,
  opacity: selected ? 1 : 0.5,
  fontSize: '12px',
  fontFamily: 'Prompt, sans-serif',
  textTransform: 'none',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

export const StyledFormControl = styled(FormControl)(() => ({
  marginTop: '5px',
  marginBottom: '25px',
  border: 'none',
  borderRadius: '8px',
}));

export const StyledFormImage = styled('img')(() => ({
  margin: '10px auto',
  width: 'fit-content',
  maxWidth: 'calc(100% - 10px)',
  height: 50,
  objectFit: 'contain',
}));

export const StyledImageInputBox = styled(Box)(() => ({}));
