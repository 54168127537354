import { useEffect, useState } from 'react';
import { useBreakpoint } from './useBreakpoint';

export const allCardDensities = ['default', 'comfortable', 'collapsed'] as const;
export type CardDensity = (typeof allCardDensities)[number];

export function useCardDensity(): CardDensity {
  const breakpoint = useBreakpoint();
  const [density, setDensity] = useState<CardDensity>('default');

  useEffect(() => {
    const { xs, sm, md } = breakpoint;
    if (xs || sm) {
      return setDensity('collapsed');
    }
    if (md) {
      return setDensity('comfortable');
    }
    return setDensity('default');
  }, [breakpoint]);

  return density;
}
