/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type SearchContentTypeEnum = typeof SearchContentTypeEnum[keyof typeof SearchContentTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchContentTypeEnum = {
  page: 'page',
  library: 'library',
  group: 'group',
  course: 'course',
  user: 'user',
  session: 'session',
  speaker: 'speaker',
} as const;
