import { EducationCredit, JunoUser } from '@juno/client-api/model';

const certInterpolate = (
  text: string,
  user: JunoUser,
  credit: EducationCredit,
  completionDate?: string,
  platformName?: string,
) => {
  // Interpolate a few things on the preview certificate so the user doesn't see a bunch of variables
  let formattedDate = 'TBD';
  if (completionDate && completionDate !== 'TBD') {
    formattedDate = new Date(completionDate).toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  }
  return text
    .replace('{learner_name}', user.first_name + ' ' + user.last_name)
    .replace('{course_title}', credit.name ?? '')
    .replace('{credits_earned}', credit.num_credits ?? 'TBD')
    .replace('{credit_name}', credit.name ?? 'TBD')
    .replace('{completion_date}', formattedDate)
    .replace('{platform_name}', platformName ?? 'TBD')
    .replace('{certificate_id}', 'TBD');
};

export { certInterpolate };
