/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type TypeD1bEnum = typeof TypeD1bEnum[keyof typeof TypeD1bEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TypeD1bEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
  NUMBER_3: 3,
  NUMBER_4: 4,
} as const;
