import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import {
  getSessionParticipants,
  useDeleteSessionArchive,
  useGetSessionArchives,
} from '@juno/client-api';
import { JunoUser, Session } from '@juno/client-api/model';
import { DefaultSearchSortFilter, DialogAriaWrapper, UserTile } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import { ArchiveSkeleton, convertDurationToTime } from './helpers';

interface DailyRecording {
  id: string;
  room_name: string;
  start_ts: number;
  status: string;
  max_participants: number;
  duration: number;
  share_token: string;
  tracks: any[];
  s3key: string;
  mtgSessionId: string;
  isVttEnabled: boolean;
  signed_url: string;
}

/** ? A Full Daily response from the recordings API looks like this (with only one recording):
"recordings": {
    "total_count": 1,
    "data": [
        {
            "id": "UUID",
            "room_name": "LLJQwXGsvFAZHWTMn3d7",
            "start_ts": 1715193567,
            "status": "finished",
            "max_participants": 1,
            "duration": 361,
            "share_token": "D7YIAadi12Ek",
            "tracks": [],
            "s3key": "S3KEY_STRING",
            "mtgSessionId": "0c33aa5e-4b62-48bf-bdd6-ca75c471c6e1",
            "isVttEnabled": false,
            "signed_url": "SIGNED_S3_URL"
        },
    ]
}
   */
interface FullArchive {
  archive: {
    room_name: string;
    recording_id: string;
    date_created: string;
  };
  recordings: {
    total_count: number;
    data: DailyRecording[];
  };
}

interface MainRoomTabProps {
  sessionData: Session | undefined;
}
const MainRoomTab: React.FC<MainRoomTabProps> = ({ sessionData }) => {
  const { site } = useSettings();
  const [participantSearch, setParticipantSearch] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const {
    data: archives,
    isLoading,
    refetch,
  } = useGetSessionArchives(
    site?.id || '',
    sessionData?.id || '',
    {
      order: '-date_created',
    },
    {
      query: {
        enabled: !!sessionData?.id && !!site?.id,
      },
    },
  );

  const [archiveList, setArchiveList] = useState<DailyRecording[]>([]);
  const [thisArchiveId, setThisArchiveId] = useState('');
  const [participantsShown, setParticipantsShown] = useState<JunoUser[] | any>([]);
  const [loadingParticipants, setLoadingParticipants] = useState(false);

  useEffect(() => {
    if (archives && sessionData) {
      const allArchives = archives as {
        archives: FullArchive[];
      };
      if (allArchives.archives.length === 0) {
        return;
      }

      const fullArchive = allArchives.archives[0];
      setArchiveList(fullArchive.recordings.data);
      if (fullArchive.recordings.data?.length > 0) {
        setThisArchiveId(fullArchive.recordings.data?.[0].id);
      }
      const getParticipants = async () => {
        setLoadingParticipants(true);
        const participantResponse = await getSessionParticipants(
          site?.id || '',
          sessionData?.id || '',
        );
        const allParticipants = participantResponse
          .map((participantItem) => {
            return {
              ...participantItem.user,
            };
          })
          .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
        setParticipantsShown(allParticipants);
        setLoadingParticipants(false);
      };
      if (participantsShown.length === 0) {
        getParticipants();
      }
    }
  }, [archives, sessionData]);

  const archiveDelete = useDeleteSessionArchive(
    onMutation(MutationAction.DELETE, 'Archive', refetch),
  );
  const deleteArchive = async (archiveId: string) => {
    setDeleteLoading(true);
    await archiveDelete.mutateAsync({
      siteId: site?.id || '',
      sessionId: sessionData?.id || '',
      archiveId,
    });
    setDeleteLoading(false);
    setDeleteDialogOpen(false);
  };

  const thisArchive = useMemo(() => {
    if (!archives) {
      return null;
    }
    return archiveList?.find((archive) => archive.id === thisArchiveId);
  }, [thisArchiveId, archives]);

  if (isLoading) {
    return <ArchiveSkeleton />;
  }

  return (
    <Box>
      {archiveList && archiveList.length === 0 && (
        <Typography variant={'body2'} pl={3}>
          No archives yet
        </Typography>
      )}
      {archiveList && archiveList.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card sx={{ overflow: 'visible', p: 2, boxShadow: 1 }}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant={'subtitle1'} mb={1}>
                  Participants
                </Typography>
              </Box>
              <Box>
                <Box mb={1}>
                  <DefaultSearchSortFilter
                    setSearch={setParticipantSearch}
                    buttonDisabled={true}
                    buttonText={''}
                    onClickButton={() => {}}
                    showButton={false}
                    showFilter={false}
                    showSort={false}
                  />
                </Box>
                {loadingParticipants && (
                  <Skeleton variant='rectangular' height={60} sx={{ borderRadius: 2 }} />
                )}
                {!loadingParticipants &&
                  participantsShown?.map((user: JunoUser) => (
                    <Card sx={{ p: 1, boxShadow: 2, mb: 1, overflow: 'visible' }}>
                      <UserTile user={user} />
                    </Card>
                  ))}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6} position={'relative'}>
            <Card sx={{ boxShadow: 1, p: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant={'subtitle1'} mb={2} sx={{ width: 'fit-content' }}>
                Preview
              </Typography>

              <Select
                value={thisArchiveId}
                size='small'
                sx={{ mb: 1 }}
                onChange={(e) => {
                  setThisArchiveId(e.target.value as string);
                }}
              >
                {archiveList
                  ?.sort((a, b) => a.start_ts - b.start_ts)
                  .map((archive, index) => (
                    <MenuItem key={archive.id} value={archive.id}>
                      <Typography>{`${new Date(
                        archive?.start_ts ? archive?.start_ts * 1000 : 0,
                      ).toLocaleString()} (${convertDurationToTime(
                        archive?.duration || 0,
                      )})`}</Typography>
                    </MenuItem>
                  ))}
              </Select>
              {thisArchive?.signed_url ? (
                <Box
                  sx={{
                    minHeight: 200,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <video
                    preload='metadata'
                    controls
                    src={thisArchive?.signed_url}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    borderRadius: 2,
                    backgroundColor: 'rgb(155,155,155,0.12)',
                    height: 280,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant={'h6'}>No video available</Typography>
                </Box>
              )}
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant='caption'>{`Started: ${new Date(
                  thisArchive?.start_ts ? thisArchive?.start_ts * 1000 : 0,
                ).toLocaleString()}`}</Typography>
                {thisArchive?.status === 'finished' ? (
                  <Typography variant='caption'>{`Duration: ${convertDurationToTime(
                    thisArchive?.duration || 0,
                  )}`}</Typography>
                ) : (
                  <Typography variant='caption'>Recording in progress</Typography>
                )}
              </Box>
            </Card>
            <Card sx={{ mt: 2, p: 2, boxShadow: 1 }}>
              <Typography variant={'subtitle1'} mb={1}>
                Actions
              </Typography>
              <Stack
                spacing={1}
                mt={1}
                display={'flex'}
                justifyContent={'flex-start'}
                sx={{ width: 'fit-content' }}
              >
                <Button
                  size='small'
                  variant='contained'
                  sx={{ p: '8px 30px' }}
                  onClick={() => {
                    window.open(thisArchive?.signed_url, '_blank');
                  }}
                >
                  Preview and Download Archive
                </Button>
                <Button
                  size='small'
                  variant='contained'
                  sx={{ color: 'white', bgcolor: 'red', p: '8px 30px' }}
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  Delete Archive
                </Button>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      )}
      <DialogAriaWrapper
        id={'delete_session_confirm'}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle id='delete_archive_confirm-dialog-title'>Delete Archive</DialogTitle>
        <DialogContent id='delete_archive_confirm-dialog-description'>
          <Typography variant='body2'>
            Are you sure you want to delete this Archive? This action cannot be undone. (Deleting
            this archive will not delete the recording from the S3 bucket.)
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            aria-label='Cancel delete archive'
            sx={{ textTransform: 'none' }}
            onClick={() => setDeleteDialogOpen(false)}
          >
            Cancel
          </Button>
          <LoadingButton
            aria-label='Confirm delete archive'
            sx={{ textTransform: 'none' }}
            variant={'contained'}
            loading={deleteLoading}
            onClick={() => deleteArchive(thisArchiveId)}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </DialogAriaWrapper>
    </Box>
  );
};
export default MainRoomTab;
