import React, { useRef, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import GifIcon from '@mui/icons-material/Gif';
import SendIcon from '@mui/icons-material/Send';
import { Box, IconButton, InputBase, Paper } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import GifPicker from 'gif-picker-react';
import { JunoUser } from '@juno/client-api/model';
import { useMessengerContext, useSettings } from '@juno/utils';
import { usePubnubContext } from '../../Contexts/PubnubContext';
import { SendMessageParams, sendMessage } from '../Utils';
import { messengerTabMap } from '../index';

interface MessageInputProps {
  newUsers?: JunoUser[];
}

const MessageInput: React.FC<MessageInputProps> = ({ newUsers }) => {
  const [message, setMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showGifPicker, setShowGifPicker] = useState(false);
  const [iconsCollapsed, setIconsCollapsed] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const { pubnub } = usePubnubContext();
  const {
    currentChannel,
    createChannelThenSubscribe,
    setMessengerTab,
    setCurrentChannel,
    setIsConversation,
  } = useMessengerContext();

  const { user } = useSettings();

  const handleSendMessage = (m: string, e?: React.KeyboardEvent) => {
    // Only proceed if there's a message to send
    if (!m.trim()) return;

    // If the Enter key is pressed, prevent the default form submission behavior
    if (e && e.key === 'Enter') e.preventDefault();

    const sendPubNubMessage = (channel?: string) => {
      // Common logic to send message
      const pubnubMessagePayload: SendMessageParams = {
        pubnub,
        channel: channel || currentChannel,
        message: m,
      };
      sendMessage(pubnubMessagePayload).then(() => setMessage(''));
    };

    if (newUsers && newUsers.length > 0 && user?.id) {
      // Create a new channel and then send the message
      createChannelThenSubscribe([user?.id, ...newUsers.map((junoUser) => junoUser.id)])
        .then((channelId) => {
          if (channelId) {
            setMessengerTab(messengerTabMap.Messenger);
            setCurrentChannel(channelId);
            setIsConversation(true);
            return channelId;
          }
        })
        .then((channelId) => {
          // Give things time to propagate before sending the message
          setTimeout(() => sendPubNubMessage(channelId), 2000);
        });
    } else {
      // Send message in the current channel
      sendPubNubMessage();
    }
  };

  return (
    <Box sx={{ mt: 2, position: 'relative' }}>
      {showEmojiPicker && (
        <Box sx={{ position: 'absolute', bottom: '100%', width: '100%', zIndex: 1000 }}>
          <EmojiPicker
            width={'100%'}
            onEmojiClick={(emoji, event) => {
              setMessage(message + emoji.emoji);
              setShowEmojiPicker(false);
              setIconsCollapsed(true);
            }}
          />
        </Box>
      )}
      {showGifPicker && (
        <Box sx={{ position: 'absolute', bottom: '100%', width: '100%', zIndex: 1000 }}>
          <GifPicker
            width={'100%'}
            tenorApiKey={'AIzaSyCRqoNgPXNknd3_CJOYvYo81Z-pMdFB-KY'}
            onGifClick={(gifUrl: any) => {
              handleSendMessage(gifUrl.url);
              setShowGifPicker(false);
              setIconsCollapsed(true);
            }}
          />
        </Box>
      )}

      <Paper component='form' sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
        {iconsCollapsed ? (
          <IconButton
            sx={{ p: '10px' }}
            aria-label='expand'
            onClick={() => setIconsCollapsed(false)}
          >
            <ArrowForwardIosIcon fontSize={'small'} />
          </IconButton>
        ) : (
          <>
            <IconButton
              sx={{
                pl: '10px',
                py: '10px',
                pr: '10px',
                '&:hover': {
                  color: 'primary.main',
                  boxShadow: 'none',
                  backgroundColor: 'transparent',
                },
              }}
              aria-label='emoji'
              onClick={() => {
                setShowGifPicker(false);
                setShowEmojiPicker((old) => !old);
              }}
            >
              <EmojiEmotionsIcon />
            </IconButton>
            <IconButton
              sx={{
                p: '10px',
                '&:hover': {
                  color: 'primary.main',
                  boxShadow: 'none',
                  backgroundColor: 'transparent',
                },
              }}
              aria-label='gif'
              onClick={() => {
                setShowEmojiPicker(false);
                setShowGifPicker(!showGifPicker);
                setIconsCollapsed(true);
              }}
            >
              <GifIcon fontSize={'large'} />
            </IconButton>
          </>
        )}
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder='Type message'
          multiline={true}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onFocus={() => {
            setIconsCollapsed(true);
            setShowGifPicker(false);
            setShowEmojiPicker(false);
          }}
          onBlur={() => message.length === 0 && setIconsCollapsed(false)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSendMessage(message, e);
              setIconsCollapsed(false);
            }
          }}
          inputRef={inputRef}
        />

        <IconButton
          sx={{ p: '10px' }}
          aria-label='send'
          onClick={(e) => {
            handleSendMessage(message);
          }}
          disabled={message.trim() === ''}
        >
          <SendIcon />
        </IconButton>
      </Paper>
    </Box>
  );
};

export default MessageInput;
