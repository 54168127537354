import { Breakpoint, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export type Breakpoints = {
  [key in Breakpoint]: boolean;
};

export function useBreakpoint(): Breakpoints {
  const theme = useTheme();
  // xs should only be true if the screen is 0px wide
  const xs = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const sm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const md = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const lg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const xl = useMediaQuery(theme.breakpoints.between('xl', 9999));

  const breakpoints = {
    xs,
    sm,
    md,
    lg,
    xl,
  };

  return breakpoints;
}
