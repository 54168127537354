import React, { useEffect, useMemo, useState } from 'react';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { Reorder } from 'framer-motion';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getGetCourseLessonsQueryKey,
  getGetCourseQueryKey,
  useCreateLesson,
  useGetCourse,
  useGetCourseLessons,
  useUpdateCourse,
} from '@juno/client-api';
import { Lesson as LessonModel, Site as SiteModel } from '@juno/client-api/model';
import { LessonPart as LessonPartUtils } from '@juno/client-api/utils';
import { JUNO_ROUTE_MAP } from '@juno/constants';
import { JunoRowCreateTile as RowCreateTile } from '@juno/ui';
import { MutationAction, onMutation, useRouteNavigate, useSettings } from '@juno/utils';
import AdminLessonPanel from '../../LessonPanel';
import RowTile from '../../components/RowTile';
import SaveBar from '../../components/SaveBar';
import { COURSE_STATUS, CourseStatus } from '../../constants';
import FormDialog, { PayloadProps } from './FormDialog';
import { GradedIndicator, NoLessonsFound } from './styles';

const LessonPlanLoading = () => (
  <>
    <RowCreateTile text='Create a lesson' />
    {[...Array(5)].map((_, idx) => (
      <Skeleton
        key={`skel-${idx}`}
        variant='rectangular'
        width={'auto'}
        height={165}
        sx={{ mt: 2 }}
      />
    ))}
  </>
);

interface LessonPlanProps {
  site: SiteModel;
  courseStatus: CourseStatus;
}

const LessonPlan: React.FC<LessonPlanProps> = ({ site, courseStatus }) => {
  const navigate = useNavigate();
  const { isWidget, isClient } = useSettings();
  const { id: siteId, slug: slug } = site;
  const params = useParams() as { courseSlug?: string; tabSlug?: string; lessonSlug?: string };
  const siteSlug: string = slug || '';
  const courseSlug: string = params.courseSlug || '';
  const lessonSlug: string = params.lessonSlug || '';
  const [updatedLessons, setUpdatedLessons] = useState<LessonModel[] | undefined>();
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const learningNavigate = useRouteNavigate();
  const queryClient = useQueryClient();
  const { data: course, isLoading: courseLoading } = useGetCourse(siteId, courseSlug);
  const { data: lessonsData, isLoading: lessonsLoading } = useGetCourseLessons(siteId, courseSlug, {
    order: 'order',
  });
  const isScorm = !!course?.metadata?.scorm_course;
  const isLocked = courseStatus === COURSE_STATUS.LOCKED || isScorm;
  const isDisabled = isLocked || courseStatus === COURSE_STATUS.DISABLED;
  const disabledText = isScorm
    ? 'Cannot create lessons on SCORM courses'
    : isLocked
    ? 'Cannot create lessons on locked courses'
    : 'Cannot create lessons on disabled courses';
  const isLoading = courseLoading || lessonsLoading;

  // Course mutations
  const refetchCourse = () =>
    queryClient.invalidateQueries(getGetCourseQueryKey(siteId, courseSlug));
  const refetchLessons = () =>
    queryClient.invalidateQueries(getGetCourseLessonsQueryKey(siteId, courseSlug));
  const refetch = () => {
    refetchCourse();
    refetchLessons();
  };
  const courseUpdate = useUpdateCourse(onMutation(MutationAction.UPDATE, 'Course', refetch));
  const createLesson = useCreateLesson(onMutation(MutationAction.CREATE, 'Lesson', refetch));

  useEffect(() => {
    lessonsData && setUpdatedLessons(lessonsData);
  }, [lessonsData]);

  const isDirty = useMemo(() => {
    if (lessonsData && updatedLessons) {
      return lessonsData.some((lesson, idx) => lesson.id !== updatedLessons[idx]?.id);
    }
    return false;
  }, [lessonsData, updatedLessons]);

  const handleSaveCourse = async () => {
    const data = course;
    if (!data) return;
    setIsSaving(true);
    const newData = { ...data, lessons: updatedLessons };
    await courseUpdate.mutateAsync({ siteId, courseId: data.id, data: newData });
    setIsSaving(false);
  };

  const handleCreateLesson = () => {
    setFormDialogOpen(true);
  };

  const handleDialogClose = () => {
    setFormDialogOpen(false);
    setIsSaving(false);
  };

  const handleDialogSave = async (payload: PayloadProps | undefined) => {
    setIsSaving(true);
    const courseId = course?.id || '';
    const data = {
      ...payload,
      order: lessonsData?.length || 0,
      // metadata: { retake_limit: 1 },
    } as unknown as LessonModel;
    try {
      await createLesson.mutateAsync({ siteId, courseId, data });
      setIsSaving(false);
      setFormDialogOpen(false);
    } catch (e) {
      console.error('error', e);
      setIsSaving(false);
    }
  };

  const handleSettingsClick = (lessonSlug: string) => {
    if (isClient) {
      navigate(`/${siteSlug}/admin/learning/${courseSlug}/lessons/${lessonSlug}`);
    } else {
      learningNavigate(JUNO_ROUTE_MAP.ADMIN_LESSON, {
        siteSlug,
        courseSlug,
        lessonSlug,
      });
    }
  };

  const handleDiscard = () => {
    setUpdatedLessons(lessonsData || []);
  };

  const lessonQuestionText = (lesson: LessonModel) => {
    let questCount = 0;
    let videoCount = 0;
    let blurbCount = 0;

    lesson?.parts?.forEach((part) => {
      if (part.type === LessonPartUtils.LESSON_PART_TYPES.QUESTION.value) {
        questCount++;
      } else if (part.type === LessonPartUtils.LESSON_PART_TYPES.VIDEO.value) {
        videoCount++;
      } else if (part.type === LessonPartUtils.LESSON_PART_TYPES.BLURB.value) {
        blurbCount++;
      }
    });

    const labelArr = [];
    if (questCount) labelArr.push(questCount === 1 ? '1 Question' : `${questCount} Questions`);
    if (videoCount) labelArr.push(videoCount === 1 ? '1 Video' : `${videoCount} Videos`);
    if (blurbCount) labelArr.push(blurbCount === 1 ? '1 Blurb' : `${blurbCount} Blurbs`);
    return labelArr.join(', ');
  };

  if (isLoading) return <LessonPlanLoading />;

  return (
    <>
      <SaveBar
        isDirty={isDirty}
        isSaving={isSaving}
        onSave={handleSaveCourse}
        onDiscard={handleDiscard}
      />
      {lessonSlug && <AdminLessonPanel site={site} />}
      {!lessonSlug && (
        <>
          {isDisabled && (
            <Tooltip title={disabledText}>
              <Box>
                <RowCreateTile
                  disabled={isDisabled}
                  onClick={handleCreateLesson}
                  text='Create a lesson'
                />
              </Box>
            </Tooltip>
          )}
          {!isDisabled && <RowCreateTile onClick={handleCreateLesson} text='Create a lesson' />}

          <Reorder.Group
            axis='y'
            onReorder={setUpdatedLessons}
            onClick={(e) => e.stopPropagation()}
            values={updatedLessons || []}
            style={{ listStyle: 'none', padding: 0 }}
          >
            {updatedLessons?.map((lesson) => (
              <Reorder.Item
                id={lesson.id}
                key={lesson.id}
                value={lesson}
                dragListener={!isDisabled}
              >
                <RowTile
                  key={lesson.id}
                  title={lesson.title}
                  subtitle={lesson.abstract}
                  imageUrl={lesson.banner}
                  onSettingsSelect={() => handleSettingsClick(lesson.slug)}
                  sortable={!isDisabled}
                  footer={
                    <>
                      {lesson?.include_in_course_grade && (
                        <GradedIndicator variant='body2'>Graded</GradedIndicator>
                      )}
                      <Typography variant='h6'>{lessonQuestionText(lesson)}</Typography>
                    </>
                  }
                />
              </Reorder.Item>
            ))}
          </Reorder.Group>
          {updatedLessons?.length === 0 && (
            <NoLessonsFound sx={{ mt: 3 }}>
              <Typography variant='h6'>No lessons found</Typography>
            </NoLessonsFound>
          )}
        </>
      )}

      <FormDialog
        open={formDialogOpen}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        isSaving={isSaving}
      />
    </>
  );
};

export default LessonPlan;
