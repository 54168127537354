import { useMemo } from 'react';
import { DailyCall } from '@daily-co/daily-js';
import {
  getSessionSubBreakouts,
  useCreateSessionSubBreakouts,
  useGetSessionSubBreakouts,
  useUpdateSessionSubBreakout,
} from '@juno/client-api';
import { Session, SessionSubBreakout } from '@juno/client-api/model';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import { BREAKOUT_ACTIONS } from '../../../constants';
import { useCallState } from '../../../contexts/CallProvider';
import { useChat } from '../../../contexts/ChatProvider';
import { RoomItem } from './RoomsLayoutComponent';

export const useSubBreakoutsHook = () => {
  const { roomInfo, callObject: daily } = useCallState();
  const { sendMessage } = useChat();
  const sessionData = useMemo(() => {
    if (roomInfo) {
      return (roomInfo as Record<string, unknown>)['session'] as Session;
    }
    return {} as Session;
  }, [roomInfo]);
  const { site } = useSettings();
  const {
    data: subBreakouts,
    isLoading: subBreakoutsLoading,
    refetch: refetchSubBreakouts,
  } = useGetSessionSubBreakouts(
    site?.id || '',
    sessionData.id,
    {},
    {
      query: {
        enabled: !!site?.id && !!sessionData?.id,
      },
    },
  );

  const callObject = useMemo(() => {
    if (daily) {
      return daily as DailyCall;
    }
    return null;
  }, [daily]);

  const getBreakoutsForChild = async (childId: string) => {
    return await getSessionSubBreakouts(site?.id || '', childId, {
      filter: {
        actual_length_in_ms: 0,
      },
    });
  };

  const createSubBreakoutsMutation = useCreateSessionSubBreakouts(
    onMutation(MutationAction.CREATE, 'Breakout Rooms', refetchSubBreakouts),
  );
  const updateSubBreakouts = useUpdateSessionSubBreakout(
    onMutation(MutationAction.UPDATE, '', refetchSubBreakouts),
  );

  const createSubBreakouts = async (rooms: RoomItem[], autoJoin = true) => {
    // remove the main room from the list of rooms and any room with no users so we dont create random blank rooms
    const roomsToCreate = rooms.filter(
      (room) => room.id !== sessionData.id && room.users.length > 0,
    );

    if (roomsToCreate.length === 0) {
      return null;
    }

    const subBreakoutsToCreate = roomsToCreate.map((room) => {
      return {
        id: '',
        session: '',
        parent_session: sessionData.id || '',
        assigned_users: room.users,
      };
    }) as SessionSubBreakout[];

    const response = await createSubBreakoutsMutation.mutateAsync({
      siteId: site?.id || '',
      sessionId: sessionData.id || '',
      data: subBreakoutsToCreate,
    });
    // go thru each rooms' assigned_users and reduce the assigned_users array to just the user ids
    // then also for each room, fire off a message to the room
    // This helps to keep the data in the message small since Daily only allows 4096 mb of data
    const roomMessages = response.map((room) => {
      return {
        ...room,
        assigned_users: room.assigned_users.map((user) => user.id),
        auto_join: autoJoin,
      };
    });

    callObject?.setMeetingSessionData(
      { breakouts_are_live: true, num_breakouts: response.length, auto_join: autoJoin },
      'shallow-merge',
    );

    // TODO need to load test this will large number of rooms and users
    // ? I tested with the mockdata at the bottom of this file and the send worked fine
    for (const room of roomMessages) {
      sendMessage(
        {
          rooms: [room],
        },
        BREAKOUT_ACTIONS.SEND_TO_ROOM,
      );
    }

    return response;
  };

  const endSubBreakouts = async (waitTime: number, parentId?: string) => {
    let sessionId = sessionData.id;
    if (sessionData.subbreakout) {
      sessionId = parentId || '';
    }
    const endedBreakouts = await updateSubBreakouts.mutateAsync({
      siteId: site?.id || '',
      sessionId: sessionId,
      data: {
        wait_time: waitTime,
      },
    });
    callObject?.setMeetingSessionData({ breakouts_are_live: false }, 'shallow-merge');
    return endedBreakouts;
  };
  return {
    subBreakouts,
    subBreakoutsLoading,
    createSubBreakouts,
    endSubBreakouts,
    getBreakoutsForChild,
  };
};

// need to generate mock data for 15 rooms of 15 people each
const mockRoomMessages = Array.from({ length: 15 }, (_, i) => {
  return {
    id: i,
    assigned_users: Array.from({ length: 15 }, (_, j) => {
      return {
        id: `${i}-${j}`,
        first_name: `User ${j}`,
      };
    }),
  };
});
