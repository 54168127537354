import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AddCircleOutline as AddCircleOutlineIcon,
  DeleteTwoTone as DeleteTwoToneIcon,
  Edit,
  ImageOutlined as ImageOutlinedIcon,
  RotateLeft as RotateLeftIcon,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import axios, { AxiosError } from 'axios';
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { JunoImage, PreviewFile } from '@juno/client-api/fakeModel';
import { notification, readImageFile, uploadImagesToCloudinary, useSettings } from '@juno/utils';
import ImageLibrary from './ImageLibrary';
import ImageUploadModal from './ImageUploadModal';
import UploadPreview from './UploadPreview';
import { AspectRatioType } from './constants';
import { DropAreaBox, ImageBox, ImageBoxContainer, InstructionsBox, PreviewRoot } from './styles';

export interface JunoImageUploadProps {
  onFileUploaded?: (selected: JunoImage | null) => void;
  onFileSelected?: (file: File | null) => void;
  disabled?: boolean;
  src?: string;
  srcUrl?: string;
  defaultSrc?: string;
  allowEdit?: boolean;
  allowLibrary?: boolean;
  dropzoneText?: string;
  style?: React.CSSProperties;
  description?: React.ReactNode;
  aspectRatios?: AspectRatioType[];
  maxSize?: number;
  iconInsteadOfText?: boolean;
  hoverForControls?: boolean;
  backgroundSize?: string;
}

export interface PayloadProps {
  file?: File;
  fileUrl?: string;
}

const ACCEPTED_IMAGE_EXTENSIONS = ['png', 'jpeg', 'gif', 'webp', 'bmp', 'svg'];
const ACCEPTED_FILE_TYPES = ACCEPTED_IMAGE_EXTENSIONS.map((ext) => `image/${ext}`);
const ACCEPTED_FILES = ACCEPTED_IMAGE_EXTENSIONS.reduce((acc: Accept, ext) => {
  acc[`image/${ext}`] = [];
  return acc;
}, {});

const JunoImageUpload: React.FC<JunoImageUploadProps> = ({
  onFileUploaded,
  onFileSelected,
  disabled,
  src,
  srcUrl,
  defaultSrc,
  allowEdit = true,
  allowLibrary = false,
  dropzoneText = 'Max file size: 10MB',
  description,
  aspectRatios,
  style,
  maxSize,
  iconInsteadOfText = false,
  hoverForControls = false,
  backgroundSize = 'contain',
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewFiles, setPreviewFiles] = useState<PreviewFile[]>([]);
  const [uploadOpen, setUploadOpen] = useState<boolean>(false);
  const [uploadPercent, setUploadPercent] = useState<number>(0);
  const [payload, setPayload] = useState<PayloadProps | undefined>();
  const [selectedLibraryFile, setSelectedLibraryFile] = useState<JunoImage | null>(null);
  const { isSmallScreen } = useSettings();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleUploadCancel = () => {
    setUploadOpen(false);
  };

  const handlePreviewOpen = () => {
    if (disabled) return;
    setPreviewOpen(true);
  };

  const onDropAreaClick = (e: React.MouseEvent) => {
    if (!allowLibrary) return;
    handlePreviewOpen();
  };

  const handlePreviewClose = () => {
    setTabIndex(0);
    setPreviewFiles([]);
    setSelectedLibraryFile(null);
    setPreviewOpen(false);
  };

  const handlePreviewCancel = () => {
    resetImage();
    handlePreviewClose();
  };

  const handleDropImage = useCallback((files: File[]) => {
    readImageFile(files?.[0], (b64Str) => {
      setPayload((oldPayload) => ({ ...oldPayload, file: files?.[0], fileUrl: b64Str }));
      onFileSelected && onFileSelected(files?.[0]);
      const file = files?.[0] as PreviewFile;
      if (file && ACCEPTED_FILE_TYPES.includes(file.type)) {
        file.preview = URL.createObjectURL(file);
      }
      setPreviewFiles([file]);
      handlePreviewOpen();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDropRejected = (fileRejections: FileRejection[], event: DropEvent) => {
    fileRejections.forEach((fileRejection) => {
      const error = fileRejection.errors[0];
      let message = undefined;
      if (error.code === 'file-too-large' && maxSize) {
        const sizeInMB = maxSize / 1024 / 1024;
        message = `File too large. Max file size: ${sizeInMB}MB`;
      } else if (error.code === 'file-invalid-type') {
        message = `file type ${
          fileRejection.file.type
        } not accepted. accepted file types are ${ACCEPTED_IMAGE_EXTENSIONS.join(', ')}`;
      } else {
        message = error.message;
      }
      notification.error(message);
    });
  };

  const shouldDisplayRemoveButton = useMemo(() => {
    if (payload?.fileUrl && payload?.fileUrl !== defaultSrc) return true;
    if (payload?.file) return true;
    return false;
  }, [defaultSrc, payload]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDropImage,
    disabled,
    noClick: allowLibrary,
    accept: ACCEPTED_FILES,
    maxSize: maxSize,
    onDropRejected: handleDropRejected,
  });

  const readFile = useCallback((file: File) => {
    readImageFile(file, (b64Str) => {
      setPayload((oldPayload) => ({ ...oldPayload, file: file, fileUrl: b64Str }));
    });
  }, []);

  const clearImage = () => {
    const fileUrl = defaultSrc || '';
    setPayload((oldPayload) => ({ ...oldPayload, file: undefined, fileUrl }));
    setPreviewFiles([]);
    onFileUploaded && onFileUploaded(null);
    onFileSelected && onFileSelected(null);
  };

  const editBlob = (blob: Blob) => {
    const file = new File([blob], 'edit', { type: 'image' }) as PreviewFile;
    file.preview = URL.createObjectURL(file);
    setPayload((oldPayload) => ({ ...oldPayload, file: file, fileUrl: srcUrl }));
    setPreviewFiles([file]);
    setPreviewOpen(true);
  };
  const editImage = () => {
    if (!srcUrl) {
      return;
    }
    if (srcUrl.startsWith('http')) {
      axios
        .get(srcUrl, { responseType: 'blob' })
        .then((response) => {
          editBlob(response.data);
        })
        .catch((e) => console.error(e));
    } else {
      fetch(srcUrl)
        .then((res) => res.blob())
        .then((blob) => {
          editBlob(blob);
        })
        .catch((e) => console.error(e));
    }
  };

  const resetImage = () => {
    // resets to initial src if exists or clears the image if not
    if (!src) return clearImage();
    if (typeof src === 'string') {
      setPayload((oldPayload) => ({ ...oldPayload, file: undefined, fileUrl: src }));
    } else {
      readFile(src as File);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetImage(), [src]);

  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (disabled) return;
    e.stopPropagation();
    clearImage();
  };

  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (disabled) return;
    e.stopPropagation();
    editImage();
  };

  const handlePreviewSave = async () => {
    if (tabIndex === 0) {
      if (onFileSelected) {
        // don't upload, just return the File
        readFile(previewFiles[0]);
        onFileSelected(previewFiles[0]);
      } else if (onFileUploaded) {
        // upload the file and return the JunoImage
        setUploadOpen(true);
        try {
          const image = await uploadImagesToCloudinary(previewFiles, setUploadPercent);
          onFileUploaded && onFileUploaded(image);
        } catch (e: unknown) {
          resetImage();
          const err = (e as AxiosError)?.response?.data as { error: { message: string } };
          const message = err?.error?.message;
          notification.error(message);
        } finally {
          setUploadOpen(false);
          setUploadPercent(0);
        }
      }
    } else {
      if (!selectedLibraryFile) return;
      // save library file
      onFileUploaded && onFileUploaded(selectedLibraryFile);
    }
    handlePreviewClose();
  };

  return (
    <Box sx={{ height: '100%', width: '100%', ...style }}>
      <DropAreaBox
        {...getRootProps()}
        allowed={!disabled}
        position={'relative'}
        sx={
          hoverForControls
            ? {
                '.MuiIconButton-root': { display: 'none' },
                '&:hover': { '.MuiIconButton-root': { display: 'block' } },
              }
            : {
                '.image-box-container:hover': {
                  opacity: !disabled ? 0.5 : 1,
                },
              }
        }
      >
        {shouldDisplayRemoveButton && !disabled && (
          <Box
            position={'absolute'}
            sx={{
              zIndex: 2,
              top: 0,
              left: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Tooltip title={defaultSrc ? 'Reset image' : 'Remove image'}>
              <IconButton
                onClick={handleDeleteClick}
                disabled={disabled}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: isSmallScreen ? '1.1rem' : '1.5rem',
                  },
                }}
              >
                {!defaultSrc && <DeleteTwoToneIcon color='primary' />}
                {defaultSrc && <RotateLeftIcon color='primary' />}
              </IconButton>
            </Tooltip>
            <Tooltip title={'Edit image'}>
              <IconButton
                onClick={handleEditClick}
                disabled={disabled}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: isSmallScreen ? '1.1rem' : '1.5rem',
                  },
                }}
              >
                <Edit color='primary' />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <input {...getInputProps()} disabled={disabled} name={undefined} />
        {!disabled && (
          <ImageBoxContainer
            onClick={onDropAreaClick}
            className='image-box-container'
            style={{ backgroundSize: backgroundSize }}
          >
            {!payload?.file && !payload?.fileUrl && (
              <InstructionsBox>
                {iconInsteadOfText ? (
                  <ImageOutlinedIcon sx={{ fontSize: '2.5rem' }} />
                ) : (
                  <>
                    <AddCircleOutlineIcon />
                    <Typography variant='body2'>Click or drag to add an image</Typography>
                  </>
                )}
              </InstructionsBox>
            )}
          </ImageBoxContainer>
        )}
        {payload?.fileUrl && (
          <ImageBox
            sx={{ backgroundImage: `url(${payload?.fileUrl})` }}
            className='image-box-container'
          />
        )}
      </DropAreaBox>
      <ImageUploadModal onCancel={handleUploadCancel} open={uploadOpen} progress={uploadPercent} />
      <Dialog
        open={previewOpen}
        onClose={handlePreviewCancel}
        aria-labelledby='juno-dialog-image-upload-title'
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle id='juno-dialog-image-upload-title'>Select Image</DialogTitle>
        <DialogContent>
          <PreviewRoot>
            <AppBar position='static'>
              {allowLibrary && (
                <Tabs
                  value={tabIndex}
                  onChange={handleTabChange}
                  aria-label='Juno Image Upload/Library'
                >
                  <Tab label='Upload' id='simple-tab-0' aria-controls='simple-tabpanel-0' />
                  <Tab label='Library' id='simple-tab-1' aria-controls='simple-tabpanel-1' />
                </Tabs>
              )}
            </AppBar>
            <Box p={3}>
              {tabIndex === 0 && (
                <UploadPreview
                  previewFiles={previewFiles}
                  setPreviewFiles={setPreviewFiles}
                  dropzoneText={dropzoneText}
                  allowEdit={allowEdit}
                  aspectRatios={aspectRatios}
                />
              )}
              {tabIndex === 1 && <ImageLibrary onSelected={setSelectedLibraryFile} />}
            </Box>
          </PreviewRoot>
          {description && <Box sx={{ mt: 2 }}>{description}</Box>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePreviewCancel} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={handlePreviewSave}
            color='primary'
            variant='contained'
            disabled={
              (previewFiles.length === 0 && tabIndex === 0) ||
              (selectedLibraryFile == null && tabIndex === 1)
            }
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default JunoImageUpload;
