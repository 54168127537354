import React, { useState } from 'react';
import { AddCircleOutline, ClearRounded, DeleteOutline } from '@mui/icons-material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  LinearProgress,
  LinearProgressProps,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { getPresignedUrlForS3Url } from '@juno/client-api';
import {
  Download,
  SessionMedia,
  TimingEnum as SessionMediaTiming,
  SessionMediaTypeEnum,
} from '@juno/client-api/model';
import { useS3Upload } from '@juno/modules';
import { GenericFormikInput, VisuallyHiddenInput, customShadow } from '@juno/ui';
import { truncateFileName, useSettings } from '@juno/utils';
import { CustomAccordionSummary } from './helpers';

interface MediaSectionProps {
  setFieldValue: any;
  media: SessionMedia[];
  handleChange: any;
}
const MediaSection: React.FC<MediaSectionProps> = ({
  media: currentMedia,
  handleChange,
  setFieldValue,
}) => {
  const [expanded, setExpanded] = useState(currentMedia.length > 0);
  const [sessionMedia, setSessionMedia] = useState<SessionMedia[]>(currentMedia);
  const [newRow, setNewRow] = useState(true);
  const [value, setValue] = useState(0);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { uploadFileToS3 } = useS3Upload();
  const { site } = useSettings();

  return (
    <Accordion
      sx={{ background: 'none', boxShadow: customShadow }}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <CustomAccordionSummary
        aria-controls='media-content'
        id='media-header'
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ ml: 1 }}>Media</Typography>
          {expanded && sessionMedia.length > 0 && (
            <IconButton
              color='primary'
              aria-label='add new row'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setNewRow(true);
              }}
            >
              <AddCircleOutline />
            </IconButton>
          )}
        </Box>
      </CustomAccordionSummary>
      <AccordionDetails>
        {(newRow || sessionMedia.length === 0) && (
          <Box>
            <Typography variant='caption'>Add session media</Typography>
            <Box display='flex' mb={2}>
              <GenericFormikInput
                placeholder='Image/Video URL'
                value={''}
                name='new_media'
                type='url'
                sx={{
                  display: 'inline',
                }}
              />
              <Button size='small' component='label' variant='text'>
                <CloudUploadIcon />
                <VisuallyHiddenInput
                  multiple={false}
                  type='file'
                  onChange={(e) => {
                    const file = e.target.files?.[0];
                    if (file === undefined) return;

                    // check in sessionMedia if i already have this file. If I do, just go to the appropriate tab
                    const haveFile = sessionMedia.find((m) => m.media_item.title === file.name);
                    if (haveFile) {
                      const index = sessionMedia.findIndex((m) => m.media_item.title === file.name);
                      setValue(index);
                      setNewRow(false);
                      return;
                    }

                    try {
                      setUploadProgress(0.01); // set to 1% to show progress bar
                      const mediaItem = {
                        title: file.name,
                        description: file.name,
                        url: '',
                        filetype: file.type,
                      } as Download;
                      const sessionMediaItem = {
                        media_item: mediaItem,
                        type: 'resource',
                        timing: 'download',
                      } as SessionMedia;
                      const awaitUpload = async (file: File) => {
                        const uploadResult = await uploadFileToS3(file, setUploadProgress);
                        const signedUrl = await getPresignedUrlForS3Url(site?.id || '', {
                          url: uploadResult,
                        });
                        mediaItem.url = signedUrl.url;
                        setSessionMedia([...sessionMedia, sessionMediaItem]);
                        setValue(sessionMedia.length);
                        setFieldValue('media', [...sessionMedia, sessionMediaItem]);
                      };
                      setSessionMedia([...sessionMedia, sessionMediaItem]);
                      setValue(sessionMedia.length);
                      setNewRow(false);
                      setFieldValue('media', [...sessionMedia, sessionMediaItem]);

                      awaitUpload(file);
                    } catch (e: any) {
                      // TODO better error handling
                      alert(e.message);
                    }
                  }}
                />
              </Button>
              {sessionMedia.length > 0 && (
                <Button
                  aria-label='cancel'
                  size='small'
                  component='label'
                  variant='text'
                  onClick={() => setNewRow(false)}
                >
                  <ClearRounded />
                </Button>
              )}
            </Box>
          </Box>
        )}
        {sessionMedia.length > 0 && (
          <Stack spacing={1}>
            <Tabs
              value={value}
              onChange={(e: any, newValue: any) => {
                setValue(newValue);
              }}
              aria-label='Session Media Tabs'
              scrollButtons='auto'
              variant='scrollable'
            >
              {sessionMedia.map((media, index) => (
                <Tab
                  key={media.id}
                  label={
                    <Box display='flex' justifyContent='center' alignItems='center'>
                      <Typography variant='caption' textTransform='none'>
                        {truncateFileName(media.media_item.description || '')}
                      </Typography>
                      <IconButton
                        color='inherit'
                        aria-label='remove'
                        onClick={(e: any) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setValue(0);
                          setSessionMedia((prevMedia) =>
                            prevMedia.filter((m) => m.media_item.title !== media.media_item.title),
                          );
                          setFieldValue(
                            'media',
                            sessionMedia.filter(
                              (m) => m.media_item.title !== media.media_item.title,
                            ),
                          );
                        }}
                      >
                        <DeleteOutline fontSize='small' />
                      </IconButton>
                    </Box>
                  }
                />
              ))}
            </Tabs>

            {sessionMedia.map((media, index) => {
              return (
                <TabPanel key={`media.${index}.media_item.title`} value={value} index={index}>
                  <Grid container>
                    <Grid item xs={6}>
                      <GenericFormikInput
                        label='Display name'
                        placeholder='Display name'
                        value={media.media_item.description}
                        id={`media[${index}].media_item.description`}
                        name={`media[${index}].media_item.description`}
                        type='text'
                        onChange={(e: any) => {
                          const newSessionMedia = [...sessionMedia];
                          newSessionMedia[index].media_item.description = e.target.value;
                          setSessionMedia(newSessionMedia);
                          handleChange(e, `media.${index}.media_item.description`);
                          setFieldValue('media', newSessionMedia);
                        }}
                      />
                      <GenericFormikInput
                        label='Media URL'
                        placeholder='Media url'
                        value={media.media_item.url}
                        id={`media[${index}].media_item.url`}
                        name={`media[${index}].media_item.url`}
                        type='url'
                        disabled
                      />
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel id={`media_type_label_${index}`}>Type</InputLabel>
                            <Select
                              labelId={`media_type_label_${index}`}
                              id={`media[${index}].type`}
                              name={`media[${index}].type`}
                              value={media.type}
                              label='Type'
                              onChange={(e: any) => {
                                const newSessionMedia = [...sessionMedia];
                                newSessionMedia[index].type = e.target.value;
                                setSessionMedia(newSessionMedia);
                                handleChange(e, `media.${index}.type`);
                                setFieldValue('media', newSessionMedia);
                              }}
                            >
                              <MenuItem value={'resource'}>Resource</MenuItem>
                              <MenuItem value={'playable'}>Playable</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel id={`media_type_label_${index}`}>Timing</InputLabel>
                            <Select
                              disabled={media.type === SessionMediaTypeEnum.resource}
                              labelId={`media_timing_label_${index}`}
                              id={`media[${index}].timing`}
                              name={`media[${index}].timing`}
                              value={`${
                                media.type === SessionMediaTypeEnum.resource
                                  ? SessionMediaTiming.download
                                  : media.timing
                              }`}
                              label='Timing'
                              onChange={(e: any) => {
                                const newSessionMedia = [...sessionMedia];
                                newSessionMedia[index].timing = e.target.value;
                                setSessionMedia(newSessionMedia);
                                handleChange(e, `media.${index}.timing`);
                                setFieldValue('media', newSessionMedia);
                              }}
                            >
                              <MenuItem value={SessionMediaTiming.download}>
                                Always available as a Download
                              </MenuItem>
                              <MenuItem value={SessionMediaTiming.before}>
                                Start of Session
                              </MenuItem>
                              <MenuItem value={SessionMediaTiming.after}>End of Session</MenuItem>
                              <MenuItem value={SessionMediaTiming.controlled}>
                                Moderator Controlled
                              </MenuItem>
                              {/* // TODO future build out */}
                              {/* <MenuItem value={'scheduled'}>
                              Schedule
                            </MenuItem> */}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                    {uploadProgress > 0 && uploadProgress < 1 ? (
                      <Grid item xs={6} pl={2}>
                        <Card sx={{ height: 200, p: 2 }}>
                          <Typography align='center' mt={1} variant='body2'>
                            Uploading {media.media_item.title}, please wait...
                          </Typography>
                          <Box>
                            <LinearProgressWithLabel value={uploadProgress * 100} />
                          </Box>
                        </Card>
                      </Grid>
                    ) : (
                      <MediaPreview media={media.media_item} />
                    )}
                  </Grid>
                </TabPanel>
              );
            })}
          </Stack>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
export default MediaSection;

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`session-media-tabpanel-${index}`}
      aria-labelledby={`session-media-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Stack sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Stack>
      )}
    </div>
  );
};
export const MediaPreview = ({
  media,
  handleRemove,
}: {
  media: Download;
  handleRemove?: () => void;
}) => {
  const aspectRatio = 9 / 16;
  const maxHeight = 250;

  const getMediaType = (type: string): string => {
    if (!type) return 'unknown';
    if (type.search('video') >= 0) return 'video';
    if (type.search('image') >= 0) return 'image';
    if (type.search('audio') >= 0) return 'audio';
    if (type.search('pdf') >= 0) return 'pdf';
    return 'unknown';
  };

  const renderMedia = () => {
    if (!media) return null;
    const mediaType = getMediaType(media.filetype);
    switch (mediaType) {
      case 'pdf':
        return (
          <object
            data={media.url}
            type='application/pdf'
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              objectFit: 'contain',
            }}
          ></object>
        );
      case 'audio':
        return (
          <audio
            src={media.url}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            controls
          />
        );
      case 'video':
        return (
          <video
            preload='metadata'
            src={media.url}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            controls
          />
        );
      case 'image':
        return (
          <img
            src={media.url}
            alt='media preview'
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        );
      case 'powerpoint':
      case 'spreadsheet':
      case 'text':
      case 'word':
      default:
        return (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              color: 'background.paper',
            }}
          >
            <DescriptionOutlinedIcon sx={{ fontSize: '64px' }} color='inherit' />
            <Typography
              variant='caption'
              color='background.paper'
              sx={{ maxWidth: '60%', overflowWrap: 'anywhere', textAlign: 'center', mt: 1 }}
            >
              We currently do not support previewing this type of file. Please convert to a pdf to
              allow for previewing, otherwise set the type to Resource.
            </Typography>
          </Box>
        );
    }
  };

  return (
    <Grid item xs={6} pl={2}>
      <Card>
        <Box
          sx={{
            position: 'relative',
            backgroundColor: 'black',
            width: '100%',
            height: 0,
            paddingTop: `${aspectRatio * 100}%`,
            maxHeight: `${maxHeight}px`,
          }}
        >
          {renderMedia()}
          <IconButton
            color='inherit'
            aria-label='remove'
            onClick={(e: any) => {
              e.stopPropagation();
              e.preventDefault();
              handleRemove && handleRemove();
            }}
            sx={{
              position: 'absolute',
              top: 3,
              right: 3,
              backgroundColor: 'rgba(255,255,255,0.7)',
              '&:hover': {
                backgroundColor: 'rgba(255,255,255,0.9)',
                color: 'primary.main',
              },
            }}
          >
            <DeleteOutline fontSize='small' />
          </IconButton>
        </Box>
        <Typography align='center' mt={1} variant='body2'>
          {media.title}
        </Typography>
      </Card>
    </Grid>
  );
};

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant='determinate' {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.secondary'>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
