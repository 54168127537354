interface LivePreviewProps {
  html: string;
  scale?: number;
  isThumbnail?: boolean;
  style?: React.CSSProperties;
}

const LivePreview: React.FC<LivePreviewProps> = ({ html, scale, isThumbnail, style }) => {
  return (
    <iframe
      src={URL.createObjectURL(new Blob([html], { type: 'text/html' }))} // eslint-disable-line
      title='certificate'
      scrolling='no'
      allow='blob:*'
      style={
        style || {
          border: 'none',
          transformOrigin: 'top left',
          transform: `scale(${scale || 1})`,
          height: isThumbnail ? '925px' : '100%',
          width: isThumbnail ? '1225px' : '100%',
        }
      }
    ></iframe>
  );
};

export default LivePreview;
