/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type EmailTypeEnum = typeof EmailTypeEnum[keyof typeof EmailTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmailTypeEnum = {
  CUSTOM: 'CUSTOM',
  WELCOME: 'WELCOME',
  COURSE_ENROLLMENT: 'COURSE_ENROLLMENT',
  COURSE_WAITLIST: 'COURSE_WAITLIST',
  NEW_FORUM_TOPIC: 'NEW_FORUM_TOPIC',
  NEW_FORUM_POST: 'NEW_FORUM_POST',
  NEW_FEED_POST: 'NEW_FEED_POST',
  TAGGED_FORUM_POST: 'TAGGED_FORUM_POST',
  TAGGED_FEED_POST: 'TAGGED_FEED_POST',
  COMMENT_FORUM_POST: 'COMMENT_FORUM_POST',
  COMMENT_FEED_POST: 'COMMENT_FEED_POST',
  REPLY_YOUR_COMMENT_FORUM: 'REPLY_YOUR_COMMENT_FORUM',
  REPLY_COMMENT_YOUR_FORUM_POST: 'REPLY_COMMENT_YOUR_FORUM_POST',
  REPLY_YOUR_COMMENT_FEED: 'REPLY_YOUR_COMMENT_FEED',
  REPLY_COMMENT_YOUR_FEED_POST: 'REPLY_COMMENT_YOUR_FEED_POST',
  LIKE_FORUM_POST: 'LIKE_FORUM_POST',
  LIKE_FEED_POST: 'LIKE_FEED_POST',
  LIKE_YOUR_FORUM_COMMENT: 'LIKE_YOUR_FORUM_COMMENT',
  LIKE_YOUR_FEED_COMMENT: 'LIKE_YOUR_FEED_COMMENT',
} as const;
