import {
  Course,
  Lesson,
  LessonCompletion,
  LessonPart,
  Question,
  UserAnswer,
} from '@juno/client-api/model';
import { Question as QuestionUtils } from '@juno/client-api/utils';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';
import { CompletionMetadataModel, VideoCompletionModel } from './lesson';

const getQuestions = (parts: LessonPart[] | undefined) => {
  const questionParts = parts?.filter((part) =>
    part?.question?.length ? part.question.length > 0 : false,
  );
  return questionParts?.map((part) => part.question?.[0]) || [];
};

const wasQuestionAnswered = (question: Question | undefined, userAnswers: UserAnswer[]) => {
  if (!question) return true;
  const answered = userAnswers.find((answer) => answer.question_id === question.id);
  return !!answered;
};

const getQuestionInitialAnswers = (
  question: Question | undefined,
  userAnswers: UserAnswer[],
): string[] => {
  if (!question) return [];
  const existingAnswer = userAnswers.find((answer) => answer.question_id === question.id);
  if (existingAnswer) {
    switch (question?.type) {
      case QuestionUtils.QUESTION_TYPES.MULTIPLE_CHOICE.value:
      case QuestionUtils.QUESTION_TYPES.POLL.value:
        return existingAnswer?.answers?.map((answer) => answer.id) || [];
      case QuestionUtils.QUESTION_TYPES.FILL_IN_THE_BLANK.value:
        if (existingAnswer?.metadata?.fillintheblank_answer) {
          return [existingAnswer.metadata.fillintheblank_answer as string];
        }
        break;
      case QuestionUtils.QUESTION_TYPES.RANKING.value:
        if (existingAnswer?.metadata?.rankingAnswer) {
          return existingAnswer.metadata.rankingAnswer as string[];
        }
        break;
      case QuestionUtils.QUESTION_TYPES.RATING.value:
        if (existingAnswer?.metadata?.ratingAnswer) {
          return [`${existingAnswer.metadata.ratingAnswer}`];
        }
        break;
    }
  }
  return [];
};

const getVideoParts = (parts: LessonPart[]): LessonPart[] => {
  return parts?.filter((part) => part?.video_url && part.video_url !== '');
};

const wasVideoWatched = (
  videoPart: LessonPart | undefined,
  videoCompletions: VideoCompletionModel | undefined,
) => {
  const requiredCompletion =
    videoPart?.video_required_completion_percent ||
    videoPart?.video_required_completion_percent === 0
      ? videoPart?.video_required_completion_percent
      : 80;

  if (requiredCompletion === 0) {
    return true;
  } else {
    const watched = videoCompletions?.[videoPart?.id || ''] || 0;

    if (watched >= requiredCompletion) {
      return true;
    } else {
      return false;
    }
  }
};

const highlightIncompleteQuestionsAndScroll = (parts: LessonPart[], userAnswers: UserAnswer[]) => {
  const firstIncompletePart = parts?.find(
    (p) => !wasQuestionAnswered(p?.question?.[0], userAnswers),
  );
  if (firstIncompletePart) {
    const partId = `#part-${firstIncompletePart.id}`;
    // get element from the widget id (maybe not ideal)
    const partEl = document
      .querySelector('#juno-learning-scroll section')
      ?.shadowRoot?.querySelector(partId);
    if (partEl) {
      // hack to wait until the dialog is closed before scrolling
      setTimeout(() => {
        partEl.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  }
};

const highlightIncompleteVideosAndScroll = (
  parts: LessonPart[],
  videoCompletions: VideoCompletionModel | undefined,
) => {
  const firstIncompletePart = parts?.find((p) => !wasVideoWatched(p, videoCompletions));
  if (firstIncompletePart) {
    const partId = `#part-${firstIncompletePart.id}`;
    // get element from the widget id (maybe not ideal)
    const partEl = document
      .querySelector('#juno-learning-scroll section')
      ?.shadowRoot?.querySelector(partId);
    if (partEl) {
      // hack to wait until the dialog is closed before scrolling
      setTimeout(() => {
        partEl.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  }
};

const checkIncomplete = (
  parts: LessonPart[],
  videoCompletions: VideoCompletionModel | undefined,
  callback: (questions: (Question | undefined)[]) => void,
  unwatchedVideoCallback?: () => void,
) => {
  const questions = getQuestions(parts);
  const videoParts = getVideoParts(parts);
  let unwatchedVideo = false;
  videoParts?.map((part) => {
    const wasWatched = wasVideoWatched(part, videoCompletions);
    if (!wasWatched) {
      unwatchedVideo = true;
    }
  });
  if (unwatchedVideo) {
    unwatchedVideoCallback && unwatchedVideoCallback();
    return;
  }
  callback(questions);
};

const generateAnsweringQuestionActivityData = (
  userAnswers: UserAnswer[],
  parts: LessonPart[],
  activityFirehose: (objectId: string, action: string, label: string) => void,
) => {
  userAnswers.forEach((answer) => {
    const part = parts?.find((part) => part.question?.[0]?.id === answer.question_id);
    const question = part?.question?.[0];
    if (question) {
      const { type, label } = getTypeAndLabel(question, answer);
      activityFirehose(answer?.question_id || '', type, label);
    }
  });
};

const getTypeAndLabel = (question: Question, answer: UserAnswer) => {
  const { FIREHOSE_CATEGORIES } = ANALYTICS_CONFIGURATION;
  let type = '';
  let label = '';
  switch (question?.type) {
    case 1:
      type = FIREHOSE_CATEGORIES.CATEGORY_QUESTION_MULTIPLE_CHOICE;
      label = answer?.answers?.[0]?.title || '';
      break;
    case 2:
      type = FIREHOSE_CATEGORIES.CATEGORY_QUESTION_POLL;
      label = answer?.answers?.[0]?.title || '';
      break;
    case 3:
      type = FIREHOSE_CATEGORIES.CATEGORY_QUESTION_FILL_IN_THE_BLANK;
      label =
        typeof answer?.metadata?.fillintheblank_answer === 'string'
          ? answer?.metadata?.fillintheblank_answer
          : '';
      break;
    case 4:
      type = FIREHOSE_CATEGORIES.CATEGORY_QUESTION_RANKING;
      label = '';
      if (
        Array.isArray(answer?.metadata?.rankingAnswer) &&
        answer?.metadata?.rankingAnswer != null
      ) {
        answer?.metadata?.rankingAnswer.map((rankAnswer) => {
          const obj = question.answers.find((obj) => obj.id === rankAnswer);
          label += obj ? `| ${obj.title} ` : '';
          return obj ? `${obj.title} | ` : '';
        });
      }
      break;
    case 5:
      type = FIREHOSE_CATEGORIES.CATEGORY_QUESTION_RATING;
      label =
        typeof answer?.metadata?.ratingAnswer === 'number'
          ? answer?.metadata?.ratingAnswer.toString()
          : '';
      break;
  }
  return { type, label };
};

const setVideoWatched = async (
  partId: string,
  percent: number,
  currentCompletion: LessonCompletion | undefined,
  updateCompletion: any,
  siteId: string,
  course: Course | undefined,
  lesson: Lesson | undefined,
) => {
  if (!currentCompletion) return;
  const data = {
    id: currentCompletion.id,
    enrollment_id: currentCompletion.enrollment_id,
    lesson_id: currentCompletion.lesson_id,
    metadata: currentCompletion.metadata,
  };
  const meta: CompletionMetadataModel | undefined = currentCompletion?.metadata;
  let completion: VideoCompletionModel = {};

  if (meta?.video_completion) {
    completion = {
      ...meta.video_completion,
      [partId]: percent,
    };
  } else {
    completion = {
      [partId]: percent,
    };
  }

  if (data.metadata) {
    data.metadata.video_completion = completion;
  } else {
    data.metadata = {
      video_completion: completion,
    };
  }

  try {
    return updateCompletion.mutateAsync({
      siteId,
      courseId: course?.id,
      lessonId: lesson?.id,
      completionId: data.id,
      data,
    });
  } catch {
    throw new Error('Error updating video_completion on LessonCompletion');
  }
};

export {
  wasQuestionAnswered,
  getQuestionInitialAnswers,
  wasVideoWatched,
  checkIncomplete,
  highlightIncompleteQuestionsAndScroll,
  highlightIncompleteVideosAndScroll,
  generateAnsweringQuestionActivityData,
  setVideoWatched,
};
