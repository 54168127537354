import { useMemo } from 'react';
import { RadioButtonCheckedOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Session } from '@juno/client-api/model';
import { GenericCountdownTimer } from '@juno/ui';
import { useBreakpoint } from '@juno/utils';
import { useCallState } from '../../contexts/CallProvider';

export const Header = () => {
  const { xs } = useBreakpoint();

  const { roomInfo, recordingState } = useCallState();
  const sessionData = useMemo(() => {
    if (roomInfo) {
      return (roomInfo as Record<string, unknown>)['session'] as Session;
    }
    return {} as Session;
  }, [roomInfo]);
  return useMemo(
    () => (
      <Box
        p={1}
        sx={{ height: '60px' }}
        display={'flex'}
        color={'white'}
        justifyContent={'center'}
        alignContent={'center'}
        position={'relative'}
      >
        <Typography
          variant='subtitle2'
          sx={{
            position: 'absolute',
            left: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {recordingState === 'started' && (
            <RadioButtonCheckedOutlined sx={{ mr: 1, color: 'red' }} />
          )}
          {/* // TODO if in greenroom */}
          <GenericCountdownTimer
            targetTime={sessionData.date_end}
            endTime={sessionData.date_end}
            countDownToEnd={true}
          />
        </Typography>
        {!xs && (
          <Typography
            variant='h6'
            // TODO for some reason long headers get all weird
            // sx={{
            //   overflow: 'hidden',
            //   maxWidth: '30%',
            //   textOverflow: 'ellipsis',
            //   whiteSpace: 'nowrap',
            // }}
          >
            {sessionData.title}
          </Typography>
        )}
      </Box>
    ),
    [sessionData, xs, recordingState],
  );
};

export default Header;
