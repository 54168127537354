import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const ReportsTab = styled(Tab)(({ theme }) => ({
  padding: 1,
  minHeight: 35,
  minWidth: 120,
  textTransform: 'none',
  '&.Mui-selected': {
    backgroundColor: '#fff',
    color: theme.palette.primary.main,
  },
}));

export const DownloadRowContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'status',
})<{ status: string | number }>(({ theme, status }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginBottom: 15,
  height: 90,
  background: theme.palette.background.default,
  border: `1px solid ${status === 'ready' ? theme.palette.primary.main : theme.palette.divider}`,
  borderRadius: 8,
  '&:hover .MuiButton-root': {
    color: '#fff',
    background: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
  },
}));

export const TitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export const DownloadRowMain = styled(Box)({
  height: 65,
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const DownloadRowFooter = styled(Typography)(({ theme }) => ({
  background: 'rgba(122,122,122,.1)',
  height: 25,
  padding: '0px 10px',
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
}));

export const DownloadButton = styled(Button)({
  width: 40,
  height: 40,
  minWidth: 45,
  background: 'rgba(122,122,122,.08)',
});
