import { Box, Grid } from '@mui/material';
import { useDeepCompareMemo } from 'use-deep-compare';
import { useUIState } from '../../../../contexts/UIStateProvider';
import Tile from '../../../Tile';

export const ScreensAndPins = ({ items }) => {
  const { pinnedId } = useUIState();

  const visibleItems = useDeepCompareMemo(() => {
    const isPinnedScreenshare = ({ id, isScreenshare }) => isScreenshare && id === pinnedId;
    if (items.some(isPinnedScreenshare)) {
      return items.filter(isPinnedScreenshare);
    }
    return items;
  }, [items, pinnedId]);

  return (
    <Box height={'100%'}>
      <Grid container spacing={1} height={'100%'} width={'100%'}>
        {visibleItems.map((item) => (
          <Grid
            item
            key={item.id}
            maxHeight={'100%'}
            borderRadius={'8px'}
            // xs = 12 divided by the number of screenShares/pins
            xs={12 / visibleItems.length}
          >
            {item.isScreenshare ? (
              <Tile showName={false} participant={item} mirrored={false} />
            ) : (
              <Tile
                participant={item}
                onVideoResize={() => {
                  console.log('onVideoResize');
                }}
              />
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ScreensAndPins;
