import React from 'react';
import { Button, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import {
  getGetSingleUnsubscribeGroupUnsubscribesQueryKey,
  useDeleteUnsubscribeGroupUnsubscribes,
} from '@juno/client-api';
import { SimpleDialog } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';

interface DeleteUnsubscribeDialogProps {
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  groupId: string;
  unsubscribe: string;
  groupName: string;
  handleDeleteUnsubscribe: () => void;
}

const DeleteUnsubscribeDialog: React.FC<DeleteUnsubscribeDialogProps> = ({
  handleClose,
  groupId,
  unsubscribe,
  groupName,
  handleDeleteUnsubscribe,
}) => {
  // Variables
  const { site } = useSettings();
  const queryClient = useQueryClient();
  const { DELETE } = MutationAction;
  const refetchGroups = () => {
    queryClient.invalidateQueries(
      getGetSingleUnsubscribeGroupUnsubscribesQueryKey(site?.id || '', groupId),
    );
  };
  const unsubscribeDelete = useDeleteUnsubscribeGroupUnsubscribes(
    onMutation(DELETE, 'UnsubscribeGroup', refetchGroups),
  );

  // Functions
  const removeUnsubscribe = () => {
    unsubscribeDelete
      .mutateAsync({ siteId: site?.id || '', unsubscribeGroupId: groupId, email: unsubscribe })
      .then(() => {
        refetchGroups();
        handleDeleteUnsubscribe();
        handleClose(false);
      })
      .catch((err) => {
        handleClose(true);
      });
  };

  return (
    <SimpleDialog
      open={true}
      onClose={() => {
        handleClose(true);
      }}
      buttonTitle={"Yes, I'm sure"}
      buttonOverrideProps={{ onClick: removeUnsubscribe, sx: { ml: 2, mr: 2, mb: 3 } }}
    >
      <Typography>
        Are you sure want to re-subscribe user {unsubscribe} to the {groupName} email subscription
        group?
      </Typography>
      <Button
        aria-label='Cancel'
        sx={{ mt: 4 }}
        fullWidth
        variant='outlined'
        onClick={() => {
          handleClose(false);
        }}
      >
        Cancel
      </Button>
    </SimpleDialog>
  );
};

export default DeleteUnsubscribeDialog;
