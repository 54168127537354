import React from 'react';
import { Box, Card } from '@mui/material';
import { NotificationCircle } from '@juno/ui';
import { useMessengerContext, useSettings } from '@juno/utils';
import { Conversation } from './Conversation/Conversation';
import { Conversations } from './Conversations/Conversations';
import './styles.css';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`messenger-tabpanel-${index}`}
      aria-labelledby={`messenger-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `messenger-tab-${index}`,
    'aria-controls': `messenger-tabpanel-${index}`,
  };
}

export const messengerTabMap = {
  Messenger: 0,
  People: 1,
};

interface TabWithNotificationProps {
  icon: React.ReactNode;
  hasNotification: boolean;
  count: number;
}

const TabWithNotification = ({ icon, hasNotification, count }: TabWithNotificationProps) => {
  return (
    <div style={{ position: 'relative' }}>
      {hasNotification && <NotificationCircle count={count} />}
      {icon}
    </div>
  );
};

export const Messenger = () => {
  const {
    messengerTab,
    setMessengerTab,
    isConversation,
    currentChannel,
    setIsConversation,
    messengerMap,
    setCurrentChannel,
  } = useMessengerContext();

  const { user } = useSettings();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setMessengerTab(newValue);
    setIsConversation(false);
  };

  const totalUnreadMessageCount = messengerMap.channels.reduce((acc, channel) => {
    return (
      acc +
      channel.messages.reduce((acc, message) => {
        if (message.userId !== user?.id) {
          return message.isNewMessage ? acc + 1 : acc;
        } else {
          return acc;
        }
      }, 0)
    );
  }, 0);

  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', position: 'relative' }}>
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    marginTop: 1.5,*/}
      {/*    marginLeft: '-20px',*/}
      {/*    height: 'fit-content',*/}
      {/*    display: 'flex',*/}
      {/*    justifyContent: 'center',*/}
      {/*    width: '30px',*/}
      {/*    bgcolor: 'white',*/}
      {/*    borderRadius: '10px 0px 0px 10px',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Tabs*/}
      {/*    value={messengerTab}*/}
      {/*    onChange={handleChange}*/}
      {/*    orientation='vertical'*/}
      {/*    sx={{*/}
      {/*      color: 'primary.main',*/}
      {/*      width: '50px',*/}
      {/*      height: '100%',*/}
      {/*      borderRadius: '10px 0px 0px 10px',*/}
      {/*      '& .MuiTabs-indicator': {*/}
      {/*        backgroundColor: 'transparent', // Hide the indicator line*/}
      {/*      },*/}

      {/*      '& .MuiTab-root.Mui-selected': {*/}
      {/*        color: 'primary.main',*/}
      {/*      },*/}
      {/*    }}*/}
      {/*    aria-label='juno messenger tabs'*/}
      {/*  >*/}
      {/*    <Tab*/}
      {/*      icon={*/}
      {/*        <TabWithNotification*/}
      {/*          icon={<ChatIcon fontSize='small' />}*/}
      {/*          hasNotification={true}*/}
      {/*          count={totalUnreadMessageCount}*/}
      {/*        />*/}
      {/*      }*/}
      {/*      sx={{ minWidth: 'unset', maxWidth: '50px' }}*/}
      {/*      {...a11yProps(0)}*/}
      {/*      onClick={() => {*/}
      {/*        setIsConversation(false);*/}
      {/*      }}*/}
      {/*    />*/}
      {/*    <Tab*/}
      {/*      icon={*/}
      {/*        <TabWithNotification*/}
      {/*          icon={<HubIcon fontSize='small' />}*/}
      {/*          hasNotification={true}*/}
      {/*          count={totalUnreadMessageCount}*/}
      {/*        />*/}
      {/*      }*/}
      {/*      sx={{ minWidth: 'unset', maxWidth: '50px' }}*/}
      {/*      {...a11yProps(1)}*/}
      {/*      onClick={() => {*/}
      {/*        setIsConversation(false);*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </Tabs>*/}
      {/*</Box>*/}
      <Card
        sx={{
          flexGrow: 1,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 0, // Adjust this value to control the overlap from the left
        }}
      >
        {/*<CustomTabPanel value={messengerTab} index={1}>*/}
        {/*  <Recommendations />*/}
        {/*</CustomTabPanel>*/}
        <CustomTabPanel value={messengerTab} index={0}>
          {isConversation ? <Conversation /> : <Conversations />}
        </CustomTabPanel>
      </Card>
    </Box>
  );
};

export default Messenger;
