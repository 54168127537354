import React from 'react';
import { LiveTvOutlined, WebAssetOffOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { ReoccurrenceEnum } from '@juno/client-api/model';
import { AddToCalendarButton } from '@juno/ui';

interface StateIconProps {
  title: string;
  description: string;
  location: string;
  dateStart: string;
  dateEnd: string;
  handleClick: () => void;
  reoccurrence: ReoccurrenceEnum;
}
const StateIcon: React.FC<StateIconProps> = ({
  title,
  description,
  location,
  dateStart,
  dateEnd,
  handleClick,
  reoccurrence,
}) => {
  const now = new Date();
  const start = new Date(dateStart);
  const end = new Date(dateEnd);
  if (now < start) {
    return (
      // TODO add url
      <AddToCalendarButton
        title={title}
        description={description}
        dateStart={dateStart}
        dateEnd={dateEnd}
        location={location}
        btnProps={{
          iconOnly: true,
          iconColor: 'primary',
          iconStyle: { padding: '0' },
        }}
        reoccurrence={reoccurrence}
      />
    );
  } else if (now > start && now < end) {
    return (
      <Tooltip title='Join now' placement='top'>
        <IconButton color='primary' sx={{ p: 0 }} onClick={handleClick}>
          <LiveTvOutlined />
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title='Session ended' placement='top'>
        <IconButton sx={{ p: 0 }}>
          <WebAssetOffOutlined />
        </IconButton>
      </Tooltip>
    );
  }
};
export default StateIcon;
