import {
  Box,
  Button,
  Card,
  Drawer,
  List,
  ListItemText,
  MenuItem,
  PaletteOptions,
  Select,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Canvas = styled('div', {
  shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'editDrawerWidth',
})<{
  backgroundColor?: string;
  editDrawerWidth?: number;
}>(({ theme, backgroundColor, editDrawerWidth = 0 }) => ({
  position: 'relative',
  backgroundColor,
  marginLeft: editDrawerWidth,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  minHeight: `calc(100vh - ${theme.spacing(7)} - ${theme.spacing(3)} - ${theme.spacing(3)})`,
  ul: {
    padding: 0,
    listStyle: 'none',
  },
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  gridTemplateColumns: 'minmax(0, 1fr)',
}));

export const SectionMask = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  // left: '-20px',
  height: '100%',
  // width: 'calc(100% + 40px)',
  width: '100%',
  zIndex: 1,
  cursor: 'move',
  '&:hover': {
    backgroundColor: '#80808055',
    borderRadius: '25px',
  },
  '.controls': {
    display: 'none',
  },
  '&:hover > .controls': {
    display: 'flex',
    cursor: 'pointer',
  },
}));

export const SectionControls = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  top: '50%',
  margin: 'auto',
  backgroundColor: '#808080CC',
  width: '100px',
  height: '50px',
  marginTop: '-25px',
  borderRadius: '50px',
}));

export const StyledModulePanel = styled(Card)(() => ({
  position: 'relative',
}));

export const PagePreviewWrapper = styled('div')(() => ({
  position: 'relative',
}));

export const PagePreviewContent = styled('div')(() => ({
  margin: 0,
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  gridTemplateColumns: 'minmax(0, 1fr)',
}));

export const ThemeSelectControl = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'palette',
})<{
  palette: PaletteOptions;
}>(({ palette }) => ({
  backgroundColor: palette.background?.default,
  '& .MuiSelect-icon': {
    color: `${palette.text?.primary} !important`,
  },
  '& .MuiSvgIcon-root.check-icon': {
    display: 'none',
  },
}));

export const ThemeMenuItem = styled(MenuItem, { shouldForwardProp: (prop) => prop !== 'palette' })<{
  palette: PaletteOptions;
}>(({ palette }) => ({
  backgroundColor: palette?.background?.default,
  color: palette?.text?.primary,
}));

export const ThemeSelectListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'palette',
})<{
  palette: PaletteOptions;
}>(({ palette }) => ({
  color: palette?.text?.primary,
}));

export const DrawerWrapper = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'width' })<{
  width: number;
}>(({ width }) => ({
  width,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width,
    boxSizing: 'border-box',
    overflow: 'auto',
    padding: '5px',
    height: '100%',
  },
}));

export const ModuleListWrapper = styled(List)(() => ({
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  padding: 0,
}));

export const ModuleListItem = styled(Card)(({ theme }) => ({
  display: 'flex',
  marginBottom: 8,
  padding: 4,
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  boxShadow: theme.shadows[1],
  background: 'rgba(0,0,0,.03)',

  '.module-icon': {
    display: 'inline-block',
  },
  '.drag-icon': {
    display: 'none',
  },
  '&:hover': {
    '.module-icon': {
      display: 'none',
    },
    '.drag-icon': {
      display: 'inline-block',
    },
  },
}));

export const EditPageFormTopControls = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '20px 10px',
  columnGap: '10px',
  paddingTop: `${theme.mixins.toolbar.minHeight}px`,
}));

export const EditPageFormContent = styled('div')(() => ({
  margin: '0px 10px 20px 10px',
  paddingBottom: 50,
}));

export const EditPageModuleControls = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0px 0px 10px 0px',
}));

export const EditPageModuleControlsTitle = styled('div')(() => ({
  margin: 0,
  display: 'flex',
  alignItems: 'center',
  columnGap: '10px',
  fontSize: '24px',
}));

export const EditPageModuleListContent = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'column',
  gap: 20,
}));

export const EditPageModuleListControls = styled('div')(() => ({
  display: 'flex',
  placeContent: 'space-between',
}));

export const SiteDesignButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  width: 340,
  textAlign: 'center',
  borderBottom: 0,
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.background.paper,
    borderBottom: 0,
  },
}));

export const SiteDesignButtonLabel = styled('span')(() => ({
  fontSize: 16,
  margin: 10,
}));

export const SiteDesignContentButton = styled(Button)(() => ({
  textAlign: 'center',
  width: '100%',
  height: 54,
  borderBottom: '1px solid',
}));

export const SiteDesignContent = styled(Box)(({ theme }) => ({}));

export const SiteDesignContentPanel = styled(Box)(() => ({
  padding: '20px 0px',
  display: 'grid',
  gap: '20px',
}));
