import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  East as EastIcon,
  PauseCircleFilled as PauseCircleFilledIcon,
  PlayCircleFilled as PlayCircleFilledIcon,
  West as WestIcon,
} from '@mui/icons-material';
import { Box, Skeleton, Typography } from '@mui/material';
import Slider from 'react-slick';
import { useElementSize } from 'usehooks-ts';
import { optimizeImage } from '@juno/utils';
import { DateFilter } from '../DateFilter';
import {
  Container,
  PauseButton,
  PauseButtonContainer,
  ProgressIndicator,
  SlideContainer,
  SlideDescription,
  SlideImage,
  SlideTitle,
  Title,
} from './styles';

export interface RotatorSettings {
  dataModel?: string;
  title?: string;
  filter?: string;
  filter_and_or?: object;
  order?: string;
  preSortName?: string;
  preSortOrder?: string;
  preFilterTags?: boolean;
  preFilterUserActions?: boolean;
  selectedUserActions?: object;
  requireTags?: string[];
  optionalTags?: string[];
  excludeTags?: string[];
  dateFilters?: DateFilter[];
  dateFilterString?: string;
  filterOnlyMyGroups?: boolean;
  filterByPrivacyLevel?: string;
  arrows?: boolean;
  dots?: boolean;
  minSpacing?: number;
  slideVariant?: 'rounded' | 'square' | 'circular';
  slideHeight?: number;
  slideWidth?: number;
  showTitle?: boolean;
  showItemTitle?: boolean;
  showItemDescription?: boolean;
  showPauseButton?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  showProgress?: boolean;
  fullWidth?: boolean;
}

const DEFAULTS: { [key: string]: any } = {
  dataModel: 'Group',
  title: '',
  filter: '',
  order: '',
  arrows: true,
  dots: false,
  minSpacing: 40,
  slideVariant: 'circular',
  slideHeight: 172,
  slideWidth: 172,
  showTitle: true,
  showItemTitle: true,
  showItemDescription: false,
  showPauseButton: true,
  textAlign: 'center',
  showProgress: false,
};

export const buildRotatorSettings = (
  settings: RotatorSettings | undefined,
  containerWidth = 9999,
) => {
  const getProp = (key: string) => {
    const tempSettings: {
      [key: string]: string | string[] | boolean | number | object | DateFilter[];
    } = settings || DEFAULTS;
    return key in tempSettings ? tempSettings[key] : DEFAULTS[key];
  };
  return {
    dataModel: getProp('dataModel'),
    title: getProp('title'),
    filter: getProp('filter'),
    filter_and_or: getProp('filter_and_or'),
    order: getProp('order'),
    preSortName: getProp('preSortName'),
    preSortOrder: getProp('preSortOrder'),
    preFilterTags: getProp('preFilterTags'),
    preFilterUserActions: getProp('preFilterUserActions'),
    selectedUserActions: getProp('selectedUserActions'),
    requireTags: getProp('requireTags'),
    optionalTags: getProp('optionalTags'),
    excludeTags: getProp('excludeTags'),
    dateFilters: getProp('dateFilters'),
    dateFilterString: getProp('dateFilterString'),
    filterOnlyMyGroups: getProp('filterOnlyMyGroups'),
    filterByPrivacyLevel: getProp('filterByPrivacyLevel'),
    arrows: getProp('arrows'),
    dots: getProp('dots'),
    minSpacing: getProp('minSpacing'),
    slideVariant: getProp('slideVariant'),
    slideHeight: Math.min(containerWidth, getProp('slideHeight')),
    slideWidth: Math.min(containerWidth, getProp('slideWidth')),
    showTitle: getProp('showTitle'),
    showItemTitle: getProp('showItemTitle'),
    showItemDescription: getProp('showItemDescription'),
    showPauseButton: getProp('showPauseButton'),
    textAlign: getProp('textAlign'),
    showProgress: getProp('showProgress'),
    fullWidth: getProp('fullWidth'),
  };
};

export interface SlideItemDataProps {
  id: string;
  client_id?: string;
  slug: string;
  name: string;
  description: string;
  image: string;
  progressText?: string;
}

export interface SpeakersCarouselProps {
  isLoading?: boolean;
  slides: SlideItemDataProps[];
  settings?: RotatorSettings;
  onSelect?: (item: SlideItemDataProps, e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

interface PauseButtonProps {
  callback: () => void;
  isPaused: boolean;
  visible: boolean;
}

const PauseButtonControl: React.FC<PauseButtonProps> = ({ callback, isPaused, visible }) => {
  const text = isPaused ? 'PLAY' : 'PAUSE';
  const Icon = isPaused ? PlayCircleFilledIcon : PauseCircleFilledIcon;
  return (
    <PauseButtonContainer onClick={() => callback()}>
      {visible && (
        <PauseButton>
          <Icon style={{ marginRight: 2 }} fontSize={'medium'} />
          {text}
        </PauseButton>
      )}
    </PauseButtonContainer>
  );
};

const ArrowButton = (props: any) => {
  const { className, style, onClick, Icon } = props;
  const isPrev = className.includes('slick-prev');
  const arrowPosition = isPrev ? 'left' : 'right';
  return (
    <Box
      className={className}
      style={{
        ...style,
        width: '35px',
        height: '35px',
        borderRadius: '50%',
        top: 'calc(50% - 18px)',
        [arrowPosition]: '5px',
        zIndex: 1,
      }}
      sx={{
        backgroundColor: (theme) => `${theme.palette.background.default} !important`,
      }}
      onClick={onClick}
    >
      <Icon fontSize='large' color='primary' />
    </Box>
  );
};

const JunoRotator: React.FC<SpeakersCarouselProps> = ({
  isLoading,
  settings,
  slides,
  onSelect,
}) => {
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [isPaused, setIsPaused] = useState(false);
  const [slickStyles, setSlickStyles] = useState('');
  const [isDragging, setIsDragging] = useState(false);
  const sliderRef = useRef<Slider>(null);
  const [containerRef, { width: containerWidth }] = useElementSize();

  const settingsObj = useMemo(
    () => buildRotatorSettings(settings, containerWidth),
    [settings, containerWidth],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const css = require('./index.string.css');
    setSlickStyles(css?.[0]?.[1]);
  }, []);

  useEffect(() => {
    // Making the carousel responsive
    if (!slides.length) return;
    const { slideWidth, minSpacing } = settingsObj;
    const calculatedSlidesToShow = Math.floor(containerWidth / (slideWidth + minSpacing));
    const slideCount = Math.min(slides.length, calculatedSlidesToShow) || 1;
    if (slideCount !== slidesToShow) {
      setSlidesToShow(slideCount);
    }
  }, [slides.length, slidesToShow, containerWidth, settingsObj]);

  const handleClick = (
    item: SlideItemDataProps,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (isDragging) return;
    onSelect && onSelect(item, e);
  };

  const slickSettings = {
    autoplay: true,
    infinite: true,
    speed: 2000,
    autoplaySpeed: 8000,
    slidesToShow,
    slidesToScroll: slidesToShow,
    initialSlide: 0,
    arrows: settingsObj.arrows,
    dots: settingsObj.dots,
    prevArrow: <ArrowButton Icon={WestIcon} />,
    nextArrow: <ArrowButton Icon={EastIcon} />,
    beforeChange: () => setIsDragging(true),
    afterChange: () => setIsDragging(false),
  };

  const handlePauseClicked = () => {
    if (!isPaused) {
      sliderRef.current?.slickPause();
    } else {
      sliderRef.current?.slickPlay();
    }
    setIsPaused(!isPaused);
  };

  const limitedSlides = () => {
    const actualSlideWidth =
      settingsObj.slideWidth < 170
        ? settingsObj.slideWidth
        : settingsObj.slideVariant === 'circular'
        ? 250
        : 300;
    const actualSlideHeight =
      settingsObj.slideHeight < 170
        ? settingsObj.slideHeight
        : settingsObj.slideVariant === 'circular'
        ? 250
        : 200;

    return (
      <Box display={'flex'} justifyContent={'space-around'} width={'100%'}>
        {slides?.map((item) => (
          <Box key={item.id}>
            {settingsObj.dataModel !== 'Library' ? (
              <Box
                sx={{
                  borderRadius:
                    settingsObj.slideVariant === 'circular'
                      ? 20
                      : settingsObj.slideVariant === 'rounded'
                      ? 10
                      : 0,
                  width: 'fit-content',
                  margin: 'auto',
                  mt: 1,
                }}
              >
                <SlideContainer
                  slideWidth={actualSlideWidth}
                  textAlign={settingsObj.textAlign}
                  onClick={(e) => handleClick(item, e)}
                >
                  <SlideImage
                    src={optimizeImage(settingsObj.slideWidth, item.image)}
                    alt={item.name}
                    slideWidth={actualSlideWidth}
                    slideHeight={actualSlideHeight}
                    variant={settingsObj.slideVariant}
                  />
                  {settingsObj.showItemTitle && (
                    <SlideTitle color='text.primary'>{item.name}</SlideTitle>
                  )}
                  {settingsObj.showItemDescription && (
                    <SlideDescription color='text.primary'>{item.description}</SlideDescription>
                  )}
                </SlideContainer>
              </Box>
            ) : (
              <Box
                sx={{
                  bgcolor: 'rgba(0,0,0,1)',
                  color: 'white',
                  borderRadius:
                    settingsObj.slideVariant === 'circular'
                      ? '50%'
                      : settingsObj.slideVariant === 'rounded'
                      ? 1
                      : '0px',
                  width: actualSlideWidth,
                  height: actualSlideHeight,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h5' fontWeight={'bolder'}>
                  {item.name}
                </Typography>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    );
  };

  if (isLoading) {
    return (
      <Container fullWidth={settings?.fullWidth || false} style={{ margin: '41px auto' }}>
        <Skeleton variant='text' height={30} width={300}></Skeleton>
        <Skeleton variant='rectangular' height={210} style={{ marginTop: 40 }} />
      </Container>
    );
  }

  if (!slides?.length || slides.length < 1) {
    return <></>;
  }

  return (
    <Container fullWidth={settingsObj.fullWidth || false} ref={containerRef} style={{}}>
      {settingsObj.showTitle && (
        <Title color='text.primary' textAlign={slides?.length >= 3 ? 'left' : 'center'}>
          {settingsObj.title}
        </Title>
      )}
      {slides?.length < 3 && limitedSlides()}
      {slides.length >= 3 && (
        <>
          <PauseButtonControl
            callback={handlePauseClicked}
            isPaused={isPaused}
            visible={settingsObj.showPauseButton}
          />
          <Slider ref={sliderRef} {...slickSettings}>
            {slides?.map((item) => (
              <Box key={item.id}>
                {settingsObj.dataModel !== 'Library' ? (
                  <SlideContainer
                    slideWidth={settingsObj.slideWidth}
                    textAlign={settingsObj.textAlign}
                    onClick={() => handleClick(item)}
                  >
                    <SlideImage
                      src={optimizeImage(settingsObj.slideWidth, item.image)}
                      alt={item.name}
                      slideWidth={settingsObj.slideWidth}
                      slideHeight={settingsObj.slideHeight}
                      variant={settingsObj.slideVariant}
                    />
                    {settingsObj.showProgress && (
                      <ProgressIndicator
                        itemHeight={settingsObj.slideHeight}
                        sx={{ color: 'primary.contrastText', bgcolor: 'primary.main' }}
                      >
                        <Typography>{item.progressText}</Typography>
                      </ProgressIndicator>
                    )}
                    {settingsObj.showItemTitle && (
                      <SlideTitle color='text.primary'>{item.name}</SlideTitle>
                    )}
                    {settingsObj.showItemDescription && (
                      <SlideDescription color='text.primary'>{item.description}</SlideDescription>
                    )}
                  </SlideContainer>
                ) : (
                  <Box
                    sx={{
                      borderRadius:
                        settingsObj.slideVariant === 'circular'
                          ? '50%'
                          : settingsObj.slideVariant === 'rounded'
                          ? 1
                          : 0,
                      margin: 'auto',
                      mt: 1,
                      bgcolor: 'rgba(0,0,0,1)',
                      color: 'white',
                      width: settingsObj.slideWidth,
                      height: settingsObj.slideHeight,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      textAlign: settingsObj.textAlign,
                    }}
                  >
                    <Typography variant='h5' fontWeight={'bolder'}>
                      {item.name}
                    </Typography>
                  </Box>
                )}
              </Box>
            ))}
          </Slider>
        </>
      )}

      <style>{`
        ${slickStyles}
      `}</style>
    </Container>
  );
};

export default JunoRotator;
