import React, { useEffect, useState } from 'react';
import {
  CampaignOutlined,
  Close as CloseIcon,
  Code as CodeIcon,
  DragIndicator as DragIndicatorIcon,
  EventNote as EventNoteIcon,
  VerticalAlignBottom as FooterIcon,
  GridView as GridViewIcon,
  VerticalAlignTop as HeaderIcon,
  Panorama as PanoramaIcon,
  RecentActorsOutlined,
  ViewCarousel as ViewCarouselIcon,
  Wallpaper as WallpaperIcon,
} from '@mui/icons-material';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import ForumIcon from '@mui/icons-material/Forum';
import ViewDayIcon from '@mui/icons-material/ViewDay';
import { IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { Reorder } from 'framer-motion';
import { Component as ComponentModel } from '@juno/client-api/model';
import { ModuleListItem, ModuleListWrapper } from './styles';

interface ReorderObject {
  id: string;
  name: string;
  type: string;
}

interface ModuleItemProps {
  content: ReorderObject;
  onConfigureModule: (moduleId: string) => void;
  onModuleDelete?: (moduleId: string) => void;
}

interface ModuleMapProps {
  [key: string]: {
    draggable: boolean;
    icon: React.ReactElement;
  };
}

const MODULE_MAP: ModuleMapProps = {
  header: { draggable: false, icon: <HeaderIcon /> },
  footer: { draggable: false, icon: <FooterIcon /> },
  featured_rotator: { draggable: true, icon: <ViewCarouselIcon className='module-icon' /> },
  speaker_preview: { draggable: true, icon: <GridViewIcon className='module-icon' /> },
  grid: { draggable: true, icon: <GridViewIcon className='module-icon' /> },
  blurb: { draggable: true, icon: <CodeIcon className='module-icon' /> },
  global_feed: { draggable: true, icon: <ViewDayIcon className='module-icon' /> },
  global_forum: { draggable: true, icon: <ForumIcon className='module-icon' /> },
  hero: { draggable: true, icon: <PanoramaIcon className='module-icon' /> },
  side_by_side: { draggable: true, icon: <ChromeReaderModeIcon className='module-icon' /> },
  schedule: { draggable: true, icon: <EventNoteIcon className='module-icon' /> },
  directory: { draggable: true, icon: <RecentActorsOutlined className='module-icon' /> },
  announcements: { draggable: true, icon: <CampaignOutlined className='module-icon' /> },
};

const ModuleItem: React.FC<ModuleItemProps> = ({ content, onConfigureModule, onModuleDelete }) => {
  const { id, name, type } = content;
  return (
    <ModuleListItem
      key={id}
      onClick={(evt: React.MouseEvent) => {
        evt.stopPropagation();
        onConfigureModule(id);
      }}
    >
      <ListItemIcon>
        {MODULE_MAP[type]?.draggable && <DragIndicatorIcon className='drag-icon' />}
        {MODULE_MAP[type]?.icon || null}
      </ListItemIcon>
      <ListItemText primary={name} />
      {onModuleDelete && (
        <IconButton
          onClick={(evt: React.MouseEvent) => {
            evt.stopPropagation();
            onModuleDelete(id);
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </ModuleListItem>
  );
};

interface ModuleListProps {
  modules: ComponentModel[];
  onReorderModules: (modules: ComponentModel[]) => void;
  onConfigureModule: (moduleId: string) => void;
  onModuleDelete: (moduleId: string) => void;
}

const ModuleList: React.FC<ModuleListProps> = ({
  modules,
  onReorderModules,
  onConfigureModule,
  onModuleDelete,
}) => {
  const [elements, setElements] = useState<ReorderObject[]>([]);
  const [elementsDragging, setElementsDragging] = useState(new Set());

  const handleRowClick = (moduleId: string) => {
    if (elementsDragging.size > 0) return;
    onConfigureModule(moduleId);
  };

  const handleRowDeleteClick = (moduleId: string) => {
    if (elementsDragging.size > 0) return;
    onModuleDelete(moduleId);
  };

  useEffect(() => {
    setElements(modules?.map(({ name, id, type }) => ({ name, id, type })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modules]);

  const handleReorderModules = () => {
    const idModuleMap = new Map(modules?.map((obj) => [obj.id, obj]));
    const orderedModules = elements
      .filter((obj) => !!idModuleMap.get(obj.id))
      .map((obj) => idModuleMap.get(obj.id) as ComponentModel);
    onReorderModules(orderedModules);
  };

  const handleDragStart = (id: string) => {
    setElementsDragging((currentElements) => currentElements.add(id));
  };

  const handleDragEnd = (id: string) => {
    setElementsDragging((currentElements) => {
      currentElements.delete(id);
      return currentElements;
    });
    handleReorderModules();
  };

  return (
    <ModuleListWrapper>
      <Reorder.Group
        axis='y'
        onReorder={setElements}
        onClick={(e) => e.stopPropagation()}
        values={elements || []}
      >
        {elements?.map((content) => (
          <Reorder.Item
            id={content.id}
            key={content.id}
            value={content}
            onDragStart={() => handleDragStart(content.id)}
            onDragTransitionEnd={() => handleDragEnd(content.id)}
          >
            <ModuleItem
              content={content}
              onConfigureModule={handleRowClick}
              onModuleDelete={handleRowDeleteClick}
            />
          </Reorder.Item>
        ))}
      </Reorder.Group>
    </ModuleListWrapper>
  );
};

export default ModuleList;
