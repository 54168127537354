import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Divider, Typography } from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { Formik } from 'formik';
import { useQueryClient } from 'react-query';
import {
  getGetFeatureConfigsPublicQueryKey,
  getGetFeatureConfigsQueryKey,
  useCreateFeatureConfig,
  useUpdateSiteFeatureConfig,
} from '@juno/client-api';
import { FeatureConfig, FeatureConfigConfig } from '@juno/client-api/model';
import { TINY_MCE_DEFAULT_CONFIG } from '@juno/constants';
import { GenericFormikInput } from '@juno/ui';
import { MutationAction, onMutation, uploadTinyMceImageCloudinary, useSettings } from '@juno/utils';

interface TermsOfServiceConfigConfig extends FeatureConfigConfig {
  title: string;
  body: string;
}
export interface TermsOfServiceConfig extends FeatureConfig {
  config: TermsOfServiceConfigConfig;
}

const TermsOfServiceForm: React.FC = () => {
  const { configs, site } = useSettings();
  const queryClient = useQueryClient();
  const [isSaving, setIsSaving] = useState(false);
  const updateConfig = useUpdateSiteFeatureConfig(
    onMutation(MutationAction.UPDATE, 'Terms of Use', () => {}),
  );
  const createConfig = useCreateFeatureConfig(
    onMutation(MutationAction.CREATE, 'Terms of Use', () => {}),
  );
  const termsOfServiceConfig = configs?.find(
    (config) => config.type === 'terms_of_service',
  ) as TermsOfServiceConfig;
  const termsOfServiceConfigConfig = termsOfServiceConfig?.config as TermsOfServiceConfigConfig;

  return (
    <Formik
      initialValues={termsOfServiceConfigConfig || { embed: '' }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        setIsSaving(true);
        if (termsOfServiceConfig) {
          await updateConfig.mutateAsync({
            siteId: site?.id || '',
            configType: 'terms_of_service',
            data: { ...termsOfServiceConfig, config: values },
          });
        } else {
          await createConfig.mutateAsync({
            siteId: site?.id || '',
            data: { id: '', type: 'terms_of_service', config: values },
          });
        }
        await queryClient.invalidateQueries(getGetFeatureConfigsQueryKey(site?.id || ''));
        await queryClient.invalidateQueries(getGetFeatureConfigsPublicQueryKey(site?.id || ''));
        setIsSaving(false);
      }}
      enableReinitialize
    >
      {({ values, dirty, handleSubmit, handleChange }) => {
        return (
          <Card sx={{ p: 2, mt: 3, mb: 4, pb: 0 }}>
            <Typography variant='h5' sx={{ mb: 0 }}>
              Terms of Use
            </Typography>
            <Divider sx={{ my: 2, mx: -0, mb: 4 }} />
            <Typography variant='body2' sx={{ mb: 5, mt: 1 }}>
              This prompt will display to users upon first login. Users will not be able to access
              the site until they accept the terms of service. This is a legal requirement for many
              sites.
            </Typography>
            <GenericFormikInput
              label='Dialog Title'
              type='text'
              name='title'
              value={values.title}
            />
            <Editor
              apiKey={process.env.NX_TINY_MCE_API_KEY}
              disabled // TODO this is disabled for now so that clients can't edit the terms of service per GZ
              onEditorChange={(value: string) => handleChange({ target: { name: 'body', value } })}
              value={values.body || ''}
              init={{
                ...TINY_MCE_DEFAULT_CONFIG,
                images_upload_handler: uploadTinyMceImageCloudinary,
                setup: (editor) => {
                  editor.setProgressState(true);
                },
              }}
            />
            <Box sx={{ textAlign: 'right' }}>
              <LoadingButton
                variant='contained'
                color='primary'
                sx={{ mb: 2 }}
                disabled={!dirty}
                onClick={() => handleSubmit()}
                loading={isSaving}
              >
                Save
              </LoadingButton>
            </Box>
          </Card>
        );
      }}
    </Formik>
  );
};

export default TermsOfServiceForm;
