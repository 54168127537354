import React, { useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import { useAttendUnattendSession } from '@juno/client-api';
import { Session } from '@juno/client-api/model';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';
import { useAnalyticsContext } from '@juno/modules';
import { MutationAction, onMutation, useSettings } from '@juno/utils';

interface SessionAttendButtonProps {
  sessionData: Session;
  refetchSession: () => void;
  btnProps?: any;
}
const SessionAttendButton: React.FC<SessionAttendButtonProps> = ({
  sessionData,
  refetchSession,
  btnProps,
}) => {
  const { site, user: thisUser } = useSettings();
  const { firehoseActivity } = useAnalyticsContext();
  const { scheduled_attendees } = sessionData;
  const attendUnattendSession = useAttendUnattendSession(
    onMutation(MutationAction.UPDATE, '', refetchSession),
  );

  const iAmAttending = scheduled_attendees
    ? scheduled_attendees.filter((user) => user.id === thisUser?.id).length > 0
    : false;

  const handleAttendButtonClick = async () => {
    const attendData = { user_id: thisUser?.id || '', is_add: !iAmAttending };
    await attendUnattendSession.mutateAsync({
      siteId: site?.id || '',
      sessionId: sessionData.slug,
      data: attendData,
    });
    const action = iAmAttending
      ? ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_UNATTEND.value
      : ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_ATTEND.value;
    firehoseActivity(
      ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_CONTENT,
      sessionData?.id || '',
      null,
      `${thisUser?.id || ''}`,
      ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_LIVE_SESSION,
      action,
      sessionData?.title || '',
      null,
    );
    refetchSession();
  };
  const btnText = useMemo(() => {
    if (sessionData.date_end && new Date(sessionData.date_end) < new Date()) {
      if (iAmAttending) {
        return 'Attended';
      } else {
        return 'Event has passed';
      }
    } else {
      if (iAmAttending) {
        return 'Unattend Session';
      } else {
        return 'Attend Session';
      }
    }
  }, [sessionData, iAmAttending]);

  return (
    <LoadingButton
      component='label'
      sx={{ textTransform: 'none' }}
      variant={iAmAttending ? 'outlined' : 'contained'}
      onClick={handleAttendButtonClick}
      disabled={sessionData.date_end && new Date(sessionData.date_end) < new Date()}
      {...btnProps}
    >
      {btnText}
    </LoadingButton>
  );
};
export default SessionAttendButton;
