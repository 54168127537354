import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SaveBarWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const SaveBarBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDirty' && prop !== 'isSaving',
})<{
  isDirty: boolean;
  isSaving?: boolean;
}>(({ isDirty, isSaving, theme }) => ({
  position: 'fixed',
  top: 76,
  right: isDirty || isSaving ? 20 : -250,
  marginTop: 0,
  padding: 5,
  gap: 10,
  zIndex: 1201,
  transition: '150ms ease all',
  display: 'flex',
  alignItems: 'center',
  width: 250,
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
}));
