/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type UserPurchaseStatusEnum = typeof UserPurchaseStatusEnum[keyof typeof UserPurchaseStatusEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserPurchaseStatusEnum = {
  pending: 'pending',
  completed: 'completed',
  failed: 'failed',
} as const;
