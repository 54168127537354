import React, { ChangeEvent, ReactNode, SyntheticEvent, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDebounce } from 'usehooks-ts';
import { useGetAllTags } from '@juno/client-api';
import { FeatureConfigTypeEnum, PrivacyEnum, Tag } from '@juno/client-api/model';
import { DateFilter, DialogAriaWrapper, JunoSpin } from '@juno/ui';
import { RelativeTimeFrameOptions, getBeginningOfDay, getEndOfDay, useSettings } from '@juno/utils';
import { TabItemObject } from '../index';
import AutocompleteTags from './AutocompleteTags';
import DatePreFilter from './DatePreFilter';
import {
  calculateDateFilterString,
  handleAddDateFilter,
  handleRemoveDateFilter,
} from './dateFilterUtils';
import { sortContent } from './sortFilterUtils';
import {
  BeforeAfterBetweenOptions,
  DateTypeOptions,
  DateTypeToFilterString,
  EnrollmentStatus,
  SortByOptions,
  SortOrderOptions,
  UserActions,
  checkJsonWorthiness,
} from './utils';

export interface CreateUpdatePageDialogProps {
  siteId: string;
  tab: TabItemObject | undefined;
  open: boolean;
  handleClose: () => void;
  onChange: (values: TabItemObject) => void;
  onContentChosen: ({ target }: ChangeEvent<HTMLInputElement>) => void;
  settings: any;
}

export const defaultDateFilter: DateFilter = {
  idx: 0,
  dateType: '',
  timeRange: '',
  previousUpcomingDateRange: 0,
  relativeTimeFrame: RelativeTimeFrameOptions.Today,
  beforeAfterBetween: BeforeAfterBetweenOptions.Before,
  beforeAfterDate: '',
  fromDate: getBeginningOfDay(new Date()).toISOString(),
  toDate: getEndOfDay(new Date()).toISOString(),
  uniqueKey: '12345ABCDEF',
};

interface PayloadFilterString {
  [key: string]: string;
}

const CreateUpdatePageDialog = ({
  siteId,
  tab,
  open,
  onChange,
  handleClose,
  onContentChosen,
  settings,
}: CreateUpdatePageDialogProps) => {
  const { enableLearning } = useSettings();
  const [payload, setPayload] = useState<TabItemObject | undefined>(tab);
  const title = tab ? `Tab settings` : `New Tab`;
  const buttonLabel = tab ? `Save` : `Create Tab`;
  const [filterByTags, setFilterByTags] = useState(tab?.preFilterTags || false);
  const [filterByUserActions, setFilterByUserActions] = useState(false);
  const [selectedUserActions, setSelectedUserActions] = useState<string[]>([]);
  const [filterByDates, setFilterByDates] = useState<boolean>(false);
  const [dateFilters, setDateFilters] = useState<DateFilter[]>([defaultDateFilter]);
  const [dateFilterStringIsChanged, setDateFilterStringIsChanged] = useState<boolean>(false);
  const [dateFiltersRemoved, setDateFiltersRemoved] = useState<DateFilter[]>([]);
  const [selectedSortByOption, setSelectedSortByOption] = useState<string>(tab?.preSortName || '');
  const [selectedSortOrder, setSelectedSortOrder] = useState<string>(tab?.preSortOrder || '');

  // Queries
  const { data: siteTags, isLoading: isLoadingTags } = useGetAllTags(siteId);

  // Find a specific set of Tags
  const mapTags = (tagIds: string[]) => {
    if (siteTags && tagIds) {
      return tagIds
        .map((tagId) => siteTags.find((tag) => tag.id === tagId))
        .filter((tag) => tag !== undefined) as Tag[];
    }
    return [];
  };

  const onLabelChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const newPayload = { ...payload, [target.name]: target.value } as TabItemObject;
    setPayload(newPayload);
  };

  const onFilterByTags = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const newPayload = {
      ...payload,
      preFilterTags: false,
      requireTags: [],
      optionalTags: [],
      excludeTags: [],
    } as TabItemObject;

    if (!payload?.preFilterTags || !payload.preFilterTags) {
      newPayload.preFilterTags = true;
      setFilterByTags(true);
    } else {
      newPayload.preFilterTags = false;
      setFilterByTags(false);
    }
    setPayload(newPayload);
  };

  const onFilterOnlyMyGroups = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setPayload({ ...payload, filterOnlyMyGroups: target.checked } as TabItemObject);
  };
  const onFilterByPrivacyLevel = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setPayload({
      ...payload,
      filterByPrivacyLevel: target.checked ? PrivacyEnum.public : '',
    } as TabItemObject);
  };
  const handlePrivacyLevelChange = ({ target }: SelectChangeEvent) => {
    setPayload({ ...payload, filterByPrivacyLevel: target.value as string } as TabItemObject);
  };

  const onFilterByUserActions = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const jsonifiedFilterString = checkJsonWorthiness(payload?.filter);
    // Clear out previously saved User Action filters while preserving Date filters
    if (jsonifiedFilterString) {
      delete jsonifiedFilterString['wait_list__user_id'];
      delete jsonifiedFilterString['enrollments__user_id'];
      delete jsonifiedFilterString['enrollments__status__in'];
    }

    const newPayload = {
      ...payload,
      preFilterUserActions: false,
      filter_and_or: undefined,
      filter: jsonifiedFilterString ? JSON.stringify(jsonifiedFilterString) : '',
      selectedUserActions: {
        [UserActions.Enroll]: false,
        [UserActions.Start]: false,
        [UserActions.Complete]: false,
        [UserActions.Waitlist]: false,
      },
    } as TabItemObject;

    if (!payload?.preFilterUserActions || !payload.preFilterUserActions) {
      newPayload.preFilterUserActions = true;
      setFilterByUserActions(true);
    } else {
      newPayload.preFilterUserActions = false;
      setFilterByUserActions(false);
    }
    setPayload(newPayload);
  };

  const onTagChange = (event: SyntheticEvent, newValue: Tag[], tagFilterType: string) => {
    const newPayload = { ...payload } as TabItemObject;

    // Did a tag get added or removed?
    const tagAdded = newValue.length > (payload as any)[tagFilterType]?.length;
    // A tag was added
    if (tagAdded) {
      const addedTag = newValue[newValue.length - 1];
      // Make sure the tag isn't already in use
      if (
        !payload?.requireTags?.includes(addedTag.id) &&
        !payload?.optionalTags?.includes(addedTag.id) &&
        !payload?.excludeTags?.includes(addedTag.id)
      ) {
        (newPayload as any)[tagFilterType] = newValue.map((tag) => tag.id);
        setPayload(newPayload);
      }
      // A tag was removed
    } else {
      (newPayload as any)[tagFilterType] = newValue.map((tag) => tag.id);
      setPayload(newPayload);
    }
  };

  const onUserActionChange = (event: SyntheticEvent, newValue: string[]) => {
    const jsonifiedFilterString = checkJsonWorthiness(payload?.filter);
    // Set the Selected User Actions
    const selectedUserActions: Record<string, boolean> = {};
    newValue.forEach((action: string) => {
      selectedUserActions[action] = true;
    });
    // Now distinguish between whether our User Action set necessitates filter or filter_and_or
    let newPayload: TabItemObject;
    // Waitlist is the only User Action selected
    if (newValue.includes(UserActions.Waitlist) && newValue.length === 1) {
      // Append filter string with a Waitlist specific filter param
      jsonifiedFilterString['wait_list__user_id'] = '{{USER_ID}}';
      newPayload = {
        ...payload,
        selectedUserActions: selectedUserActions,
        filter: JSON.stringify(jsonifiedFilterString),
        filter_and_or: undefined,
      } as TabItemObject;
      // There's a combination of Waitlist and other Enrollment User Actions
    } else if (newValue.includes(UserActions.Waitlist)) {
      newPayload = {
        ...payload,
        selectedUserActions: selectedUserActions,
        filter: JSON.stringify(jsonifiedFilterString),
        filter_and_or: {
          and: {
            enrollments__user_id: '{{USER_ID}}',
            enrollments__status__in: newValue
              .filter((action: string) => action != UserActions.Waitlist)
              .map((action: string) => EnrollmentStatus[action as keyof typeof EnrollmentStatus]),
          },
          or: {
            wait_list__user_id: '{{USER_ID}}',
          },
        },
      } as TabItemObject;
      // Waitlist is not a selected User Action
    } else {
      // Append filter string with enrollments filter params
      jsonifiedFilterString['enrollments__user_id'] = '{{USER_ID}}';
      jsonifiedFilterString['enrollments__status__in'] = newValue.map(
        (action: string) => EnrollmentStatus[action as keyof typeof EnrollmentStatus],
      );
      newPayload = {
        ...payload,
        selectedUserActions: selectedUserActions,
        filter: JSON.stringify(jsonifiedFilterString),
        filter_and_or: undefined,
      } as TabItemObject;
    }
    // Save changes
    setPayload(newPayload);
    setSelectedUserActions(newValue);
  };

  const onFilterByDates = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFilterByDates((prevState) => !prevState);
    if (!filterByDates) {
      setDateFilters([defaultDateFilter]);
      setDateFilterStringIsChanged(false);
      const jsonifiedFilterString = checkJsonWorthiness(payload?.filter);
      for (const key in DateTypeToFilterString.keys()) {
        delete jsonifiedFilterString[DateTypeToFilterString.get(key) + '__lte'];
        delete jsonifiedFilterString[DateTypeToFilterString.get(key) + '__gte'];
      }
      const newPayload = {
        ...payload,
        filter: JSON.stringify(jsonifiedFilterString),
        dateFilterString: '',
      } as TabItemObject;
      setPayload(newPayload);
    }
  };

  /**
   *  PreSorting
   */

  const handleSortByChange = (event: SelectChangeEvent<string>, name: string) => {
    switch (name) {
      case 'sortBy':
        setSelectedSortByOption(event.target.value);
        break;
      case 'sortOrder':
        setSelectedSortOrder(event.target.value);
        break;
      default:
        break;
    }
  };

  // Handle when the first option the user chooses to sort by is the default (Alphabetical)
  const handleOpenSortOptions = () => {
    if (selectedSortByOption === SortByOptions.Alphabetical) {
      handleSortByChange(
        { target: { value: SortByOptions.Alphabetical } } as SelectChangeEvent<string>,
        'sortBy',
      );
    }
  };
  // Handle when the first option the user chooses to order by is the default (Ascending)
  const handleOpenOrderOptions = () => {
    if (selectedSortOrder === SortOrderOptions.Ascending) {
      handleSortByChange(
        { target: { value: SortOrderOptions.Ascending } } as SelectChangeEvent<string>,
        'sortOrder',
      );
    }
  };

  const clearOutFilterString = (payloadFilterString: PayloadFilterString): PayloadFilterString => {
    const updatedPayloadFilterString = payloadFilterString;
    for (const value of DateTypeToFilterString.values()) {
      if (value + '__lte' in updatedPayloadFilterString) {
        delete updatedPayloadFilterString[value + '__lte'];
      }
      if (value + '__gte' in updatedPayloadFilterString) {
        delete updatedPayloadFilterString[value + '__gte'];
      }
    }
    return updatedPayloadFilterString;
  };

  // Asynchronously save the new payload and then save the full tab settings to preserve changes
  const handleSave = async () => {
    if (payload) {
      if (
        (!payload.requireTags || payload.requireTags.length === 0) &&
        (!payload.optionalTags || payload.optionalTags.length === 0) &&
        (!payload.excludeTags || payload.excludeTags.length === 0)
      ) {
        payload.preFilterTags = false;
        setFilterByTags(false);
      }

      // Set Date Filter params
      let jsonifiedFilterString = checkJsonWorthiness(payload.filter);
      if (!filterByDates) {
        // Zero out the filter string and date filter set if we don't have any date filters or pre sorting set
        jsonifiedFilterString = clearOutFilterString(jsonifiedFilterString);
        payload.filter = JSON.stringify(jsonifiedFilterString);
        payload.dateFilters = [];
      } else {
        // Set a new filter param based on the chosen date filters
        if (dateFilterStringIsChanged) {
          jsonifiedFilterString = clearOutFilterString(jsonifiedFilterString);
          payload.filter = JSON.stringify(jsonifiedFilterString);
          const [updatedFilterString, updatedDateFilters] = calculateDateFilterString(
            payload,
            dateFilters,
            dateFiltersRemoved,
            false,
          );
          payload.filter = updatedFilterString;
          payload.dateFilterString = updatedFilterString;
          payload.dateFilters = updatedDateFilters;
        }
      }

      // Set order param based on preSort
      payload.preSortOrder = selectedSortOrder;
      payload.preSortName = selectedSortByOption;
      const newOrder = sortContent(selectedSortByOption, selectedSortOrder);
      payload.order = newOrder ? newOrder : '';

      // Save changes
      if (!selectedUserActions || selectedUserActions.length < 1) {
        payload.preFilterUserActions = false;
        setFilterByUserActions(false);
        setSelectedUserActions([]);
        onChange(payload);
        setDateFiltersRemoved([]);
      } else {
        onChange(payload);
        setDateFiltersRemoved([]);
      }
    }
  };

  // Make sure the modal preloads tab-specific filters
  useEffect(() => {
    setSelectedSortByOption(tab?.preSortName || '');
    setSelectedSortOrder(tab?.preSortOrder ? tab.preSortOrder : '');
    setPayload(tab);
    // Do we have any tag filters set?
    if (tab?.preFilterTags) {
      setFilterByTags(tab.preFilterTags);
    } else {
      setFilterByTags(false);
    }
    // Do we have any User Action filters set?
    if (tab?.preFilterUserActions) {
      setSelectedUserActions(
        Object.entries(tab.selectedUserActions || {})
          .filter(([, value]) => value === true)
          .map(([key]) => key) || [],
      );
      setFilterByUserActions(tab.preFilterUserActions);
    } else {
      setFilterByUserActions(false);
      setSelectedUserActions([]);
    }
    // Do we have any Date filters set?
    if (tab?.dateFilters && tab.dateFilters.length > 0 && tab.dateFilters[0].dateType !== '') {
      setFilterByDates(true);
      setDateFilters(tab.dateFilters);
    } else {
      setFilterByDates(false);
      setDateFilters([defaultDateFilter]);
    }
  }, [tab]);

  // Register dateFilter updates
  useEffect(() => {
    let newPayload;
    if (dateFilters) {
      newPayload = { ...payload, dateFilters: dateFilters } as TabItemObject;
      setPayload(newPayload);
    }
  }, [dateFilters]);

  if (isLoadingTags) {
    return <JunoSpin />;
  }

  return (
    <DialogAriaWrapper
      fullWidth={true}
      maxWidth='sm'
      open={open}
      onClose={handleClose}
      aria-labelledby='responsive-dialog-title'
      PaperProps={{ sx: { borderRadius: '8px' } }}
    >
      <Box
        sx={{
          background: '#FFFFFF',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: 3,
          pr: 2,
        }}
      >
        <Box>
          <Typography variant='body2' sx={{ opacity: 0.6, mt: 2 }}>
            Editing
          </Typography>
          <Typography variant='h5' sx={{ mb: 2 }}>
            {title}
          </Typography>
        </Box>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </Box>
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <TextField
            fullWidth
            id='label'
            name='label'
            label='Tab Label'
            value={payload?.label || ''}
            onChange={onLabelChange}
            InputProps={{ disableUnderline: true }}
            variant='filled'
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <TextField
            name='dataModel'
            select
            label='Content Type'
            value={settings.dataModel}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              //disable course specific filters if not selected
              if (e.target.value !== 'Course') {
                setFilterByDates(false);
                setFilterByUserActions(false);
              }
              onContentChosen(e);
            }}
            InputProps={{ disableUnderline: true }}
            variant='filled'
          >
            <MenuItem value='Speaker'>Speaker</MenuItem>
            {enableLearning && <MenuItem value='Course'>Course</MenuItem>}
            <MenuItem value='Group'>Group</MenuItem>
            <MenuItem value='Library'>Library</MenuItem>
            <MenuItem value='Session'>Session</MenuItem>
          </TextField>
        </FormControl>
        {settings.dataModel !== 'Library' && (
          <Typography sx={{ mt: 1, mb: 1 }}>Filter Content</Typography>
        )}
        <Box>
          <Grid container direction='column'>
            {settings.dataModel !== 'Library' && (
              <>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='preFilterTags'
                        checked={payload?.preFilterTags === true}
                        onChange={onFilterByTags}
                      />
                    }
                    label='Tags'
                    sx={{ pl: 2 }}
                  />
                </Grid>
                {filterByTags && (
                  <Grid item>
                    <FormControl fullWidth sx={{ mt: 1, pr: 2, pb: 2, pl: 6 }}>
                      <AutocompleteTags
                        label={'Require Tag(s)'}
                        requireTags={mapTags(payload?.requireTags || [])}
                        optionalTags={mapTags(payload?.optionalTags || [])}
                        excludeTags={mapTags(payload?.excludeTags || [])}
                        siteTags={siteTags}
                        handleChange={(event: SyntheticEvent, newValue: Tag[]) => {
                          onTagChange(event, newValue, 'requireTags');
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 1, pr: 2, pb: 2, pl: 6 }}>
                      <AutocompleteTags
                        label={'Optional Tag(s)'}
                        requireTags={mapTags(payload?.requireTags || [])}
                        optionalTags={mapTags(payload?.optionalTags || [])}
                        excludeTags={mapTags(payload?.excludeTags || [])}
                        siteTags={siteTags}
                        handleChange={(event: SyntheticEvent, newValue: Tag[]) => {
                          onTagChange(event, newValue, 'optionalTags');
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 1, pr: 2, pb: 2, pl: 6 }}>
                      <AutocompleteTags
                        label={'Exclude Tag(s)'}
                        requireTags={mapTags(payload?.requireTags || [])}
                        optionalTags={mapTags(payload?.optionalTags || [])}
                        excludeTags={mapTags(payload?.excludeTags || [])}
                        siteTags={siteTags}
                        handleChange={(event: SyntheticEvent, newValue: Tag[]) => {
                          onTagChange(event, newValue, 'excludeTags');
                        }}
                      />
                    </FormControl>
                  </Grid>
                )}
              </>
            )}
            {settings.dataModel === 'Course' && (
              <FormControlLabel
                control={
                  <Checkbox
                    name='preFilterUserActions'
                    checked={payload?.preFilterUserActions === true}
                    onChange={onFilterByUserActions}
                  />
                }
                label='User Actions'
                sx={{ pl: 2 }}
              />
            )}
            {filterByUserActions && (
              <FormControl fullWidth sx={{ mt: 1, pr: 2, pl: 6 }}>
                <Autocomplete
                  multiple
                  options={[
                    UserActions.Complete,
                    UserActions.Enroll,
                    UserActions.Start,
                    UserActions.Waitlist,
                  ]}
                  value={selectedUserActions}
                  onChange={(event, value) => onUserActionChange(event, value)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <FormControlLabel control={<Checkbox checked={selected} />} label={option} />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Optional User Action(s)'}
                      InputLabelProps={{ shrink: selectedUserActions?.length > 0 }}
                      InputProps={{ ...params.InputProps, disableUnderline: true }}
                      variant='filled'
                    />
                  )}
                  sx={{ pb: 3 }}
                />
              </FormControl>
            )}

            {settings.dataModel !== 'Session' && settings.dataModel !== 'Library' && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name='preFilterByDate'
                        checked={filterByDates}
                        onChange={onFilterByDates}
                      />
                    }
                    label='Dates'
                    sx={{ pl: 2 }}
                  />
                  <IconButton
                    sx={{ ml: 'auto', mr: 2 }}
                    onClick={() => {
                      handleAddDateFilter(dateFilters.length, setDateFilters);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
                {filterByDates && (
                  <Typography
                    sx={{ ml: 6, fontSize: 12, fontStyle: 'italic' }}
                    style={{ color: 'grey' }}
                  >
                    All dates/times are inclusive
                  </Typography>
                )}
                {filterByDates && (
                  <>
                    {dateFilters.map((filter, idx) => (
                      <DatePreFilter
                        dateFilter={filter}
                        dateFilters={dateFilters}
                        setDateFilters={setDateFilters}
                        removeDateFilter={() =>
                          handleRemoveDateFilter(
                            filter.idx,
                            dateFilters,
                            dateFiltersRemoved,
                            setDateFiltersRemoved,
                            setDateFilterStringIsChanged,
                            setDateFilters,
                          )
                        }
                        key={filter.uniqueKey}
                        setDateFilterStringIsChanged={setDateFilterStringIsChanged}
                        dateTypeOptions={
                          settings.dataModel === 'Group'
                            ? [DateTypeOptions.ReleaseDate, DateTypeOptions.EndDate]
                            : Object.values(DateTypeOptions)
                        }
                      />
                    ))}
                  </>
                )}
              </>
            )}

            {settings.dataModel === 'Group' && (
              <FormControlLabel
                control={
                  <Checkbox
                    name='filterOnlyMyGroups'
                    checked={payload?.filterOnlyMyGroups === true}
                    onChange={onFilterOnlyMyGroups}
                  />
                }
                label='Groups I am a member of'
                sx={{ pl: 2 }}
              />
            )}
            {settings.dataModel === 'Group' && (
              <FormControlLabel
                control={
                  <Checkbox
                    name='filterByPrivacyLevel'
                    checked={!!payload?.filterByPrivacyLevel}
                    onChange={onFilterByPrivacyLevel}
                  />
                }
                label='Privacy Level'
                sx={{ pl: 2 }}
              />
            )}
            {payload?.filterByPrivacyLevel && (
              <Box pl={6} pt={1} pb={1} pr={2}>
                <FormControl variant='filled'>
                  <InputLabel>Privacy</InputLabel>
                  <Select
                    sx={{ width: 200 }}
                    onChange={handlePrivacyLevelChange}
                    value={payload.filterByPrivacyLevel}
                    disableUnderline
                  >
                    <MenuItem value={PrivacyEnum.public}>Public</MenuItem>
                    <MenuItem value={PrivacyEnum.private}>Private</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
          </Grid>
        </Box>
        <Typography sx={{ mt: 2, mb: 2 }}>Sort Content</Typography>
        <Typography sx={{ fontSize: 12, fontStyle: 'italic', mb: 2 }} style={{ color: 'grey' }}>
          Set default order for content displayed in grid on page load
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <FormControl fullWidth sx={{ pr: 1, mb: 2 }} variant='filled'>
            <InputLabel htmlFor='sort-option'>Sort By</InputLabel>
            <Select
              label='Sort By'
              value={selectedSortByOption}
              onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
                handleSortByChange(event, 'sortBy');
              }}
              displayEmpty
              renderValue={() => selectedSortByOption}
              onOpen={handleOpenSortOptions}
              disableUnderline
            >
              <MenuItem value={SortByOptions.Alphabetical}>
                <FormControlLabel
                  control={<Radio size='small' />}
                  label={SortByOptions.Alphabetical}
                />
              </MenuItem>
              <MenuItem value={SortByOptions.Newest}>
                <FormControlLabel control={<Radio size='small' />} label={SortByOptions.Newest} />
              </MenuItem>
              {settings.dataModel === 'Course' && (
                <MenuItem value={SortByOptions.StartDate}>
                  <FormControlLabel
                    control={<Radio size='small' />}
                    label={SortByOptions.StartDate}
                  />
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ ml: 1, mb: 2 }} variant='filled'>
            <InputLabel htmlFor='sort-order'>Sort Order</InputLabel>
            <Select
              label='Sort Order'
              value={selectedSortOrder}
              onChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
                handleSortByChange(event, 'sortOrder');
              }}
              displayEmpty
              renderValue={() => selectedSortOrder}
              onOpen={handleOpenOrderOptions}
              disableUnderline
            >
              <MenuItem value={SortOrderOptions.Ascending}>
                <FormControlLabel
                  control={<Radio size='small' />}
                  label={SortOrderOptions.Ascending}
                />
              </MenuItem>
              <MenuItem value={SortOrderOptions.Descending}>
                <FormControlLabel
                  control={<Radio size='small' />}
                  label={SortOrderOptions.Descending}
                />
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions sx={{ pl: 1 }}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          color='primary'
          type='submit'
          form='dialogForm'
          aria-label='save'
          variant='contained'
          onClick={handleSave}
        >
          {buttonLabel}
        </Button>
      </DialogActions>
    </DialogAriaWrapper>
  );
};

export default CreateUpdatePageDialog;
