import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Box, { shouldForwardProp: (prop) => prop !== 'fullWidth' })<{
  fullWidth?: boolean;
}>(({ fullWidth }) => ({
  width: !fullWidth ? 1020 : '100%',
  maxWidth: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  zIndex: 5,
}));
