import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

interface RestrictCourseEnrollDialogProps {
  handleClose: () => void;
  handleUpgrade: (url: string) => void;
  courseName: string;
  clientName?: string;
  upgradeUrl?: string;
}

const RestrictCourseEnrollDialog: React.FC<RestrictCourseEnrollDialogProps> = ({
  handleClose,
  handleUpgrade,
  courseName,
  clientName,
  upgradeUrl,
}) => {
  return (
    <Dialog open={true} onClose={handleClose} maxWidth='xs'>
      <DialogTitle sx={{ pt: 2, pb: 1, alignSelf: 'center' }}>Premium Content</DialogTitle>
      <DialogContent>
        <DialogContentText align='center' component='div'>
          <Typography sx={{ mb: -2 }}>
            You must upgrade to access {courseName}. This course is not available without contacting{' '}
            {clientName}. Click 'UPGRADE NOW' to gain access to this course.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', p: 3 }}>
        {upgradeUrl && upgradeUrl !== '' ? (
          <Button
            fullWidth
            variant={'contained'}
            onClick={() => {
              handleUpgrade(upgradeUrl as string);
            }}
            sx={{ mb: -2, height: '35px' }}
            style={{
              letterSpacing: '2px',
              color: 'white',
              textDecoration: 'none',
              width: '100%',
            }}
          >
            UPGRADE NOW
          </Button>
        ) : (
          <Button
            fullWidth
            variant={'contained'}
            sx={{ mb: -2, letterSpacing: '2px' }}
            disabled={true}
          >
            An upgrade resource has not yet been provided. Contact {clientName} for details.
          </Button>
        )}
      </DialogActions>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          fullWidth
          variant={'outlined'}
          onClick={handleClose}
          sx={{ letterSpacing: '2px', mb: 1, height: '35px' }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RestrictCourseEnrollDialog;
