import React, { useEffect, useMemo, useRef, useState } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Rating,
  Skeleton,
  Snackbar,
  Stack,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Editor } from '@tinymce/tinymce-react';
import DOMPurify from 'dompurify';
import { motion } from 'framer-motion';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  useCreateAISuggestionRecord,
  useCreateForumTopic,
  useDeleteForumTopic,
  useGenerateContentWithOpenAI,
  useGetAllTagTypes,
  useGetAllTags,
  useGetFeatureConfigs,
  useGetForumTopics,
  useGetMe,
  useGetSiteForum,
  useUpdateForumTopic,
} from '@juno/client-api';
import {
  AISuggestionRecord,
  Feed,
  Forum,
  ForumTopic,
  OpenAIRequest,
  Tag,
} from '@juno/client-api/model';
import { FeatureConfig as FeatureConfigUtils } from '@juno/client-api/utils';
import { ANALYTICS_CONFIGURATION, FEATURE_FLAG_KEYS_ENUM } from '@juno/constants';
import { Container, DefaultSearchSortFilter, DialogAriaWrapper, JunoSpin } from '@juno/ui';
import {
  MutationAction,
  onMutation,
  uploadTinyMceImageCloudinary,
  useBreakpoint,
  useForumTopicsSearchFilter,
  usePagination,
  useSettings,
} from '@juno/utils';
import { useAnalyticsContext } from '../Contexts/AnalyticsContext';
import FeedComponent from '../Feed';
import ForumTopicCard from './ForumTopicCard';

const PAGE_SIZE = 15;
interface ForumComponentProps {
  siteId: string;
  forum: Forum;
  userCanInteract?: boolean;
  iAmAdmin?: boolean;
  topicsEnabled?: boolean;
  blockUserInteractionMessage?: string;
  feedBlockUserInteractionMessage?: string;
}
const ForumComponent: React.FC<ForumComponentProps> = ({
  siteId,
  forum,
  userCanInteract = true,
  iAmAdmin = false,
  topicsEnabled = true,
  blockUserInteractionMessage = '',
  feedBlockUserInteractionMessage = '',
}) => {
  const [currentQueryParameters, setSearchParams] = useSearchParams();
  const topicToViewId = currentQueryParameters.get('topic_id');
  const topicRefs = useRef<Record<string, HTMLDivElement | null>>({});
  const [showNewDialog, setShowNewDialog] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [filters, setFilters] = useState<Tag[]>([]);
  const [sort, setSort] = useState('newest');
  const [debouncedSearch, search, searchFilter, setSearch] = useForumTopicsSearchFilter();
  const [selectedTopic, setSelectedTopic] = useState<ForumTopic | null>(null);
  const [editingTopic, setEditingTopic] = useState<ForumTopic | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const { site, configs } = useSettings();
  const platformId = site?.platform_id || '';
  const [hasViewed, setHasViewed] = useState<boolean>(false);
  const { firehoseActivity } = useAnalyticsContext();
  const { user: currentUser } = useSettings();
  const location = useLocation();

  const combinedFilter = useMemo(() => {
    if (filters.length === 0) {
      return {
        filter_or: searchFilter,
      };
    }
    return {
      filter_or: {
        ...searchFilter,
      },
      filter_and: {
        tags__in: filters.map((t) => t.id),
      },
    };
  }, [searchFilter, filters, sort]);

  const { data: me, refetch: refetchMe } = useGetMe(
    platformId,
    {},
    { query: { enabled: !!platformId } },
  );
  const [page, setPage] = useState(0);
  const {
    data: forumTopics,
    isLoading: isLoadingTopics,
    refetch: refetchTopics,
    isFetching: isFetchingTopics,
    isRefetching: isRefetchingTopics,
  } = useGetForumTopics(
    siteId,
    forum.id,
    {
      ...combinedFilter,
      limit: PAGE_SIZE,
      offset: page,
      order: sort === 'newest' ? '-pinned,-date_created' : '-pinned,title',
    },
    { query: { enabled: !!forum.id } },
  );

  const [data, setData, ref, isLoading] = usePagination(
    forumTopics,
    isLoadingTopics,
    isFetchingTopics,
    search,
    debouncedSearch,
    setPage,
    false,
  );

  const [isTopicFound, setIsTopicFound] = useState(false);

  useEffect(() => {
    if (topicToViewId && forumTopics && !isTopicFound) {
      const topic = forumTopics.find((t) => t.feed.id === topicToViewId);
      if (topic) {
        setSelectedTopic(topic);
        const topicElement = topicRefs.current[topic.id];
        if (topicElement) {
          const topPosition = topicElement.getBoundingClientRect().top + window.scrollY - 100;
          window.scrollTo({ top: topPosition, behavior: 'smooth' });
          setIsTopicFound(true);
        }
      } else {
        // Scroll to the pagination ref if the topic is not found
        if (ref && typeof ref === 'function') {
          ref();
          setPage(page + 1);
          setTimeout(() => {
            const paginationElement = document.querySelector('.pagination-element');
            if (paginationElement) {
              const topPosition =
                paginationElement.getBoundingClientRect().top + window.scrollY - 100;
              window.scrollTo({ top: topPosition, behavior: 'smooth' });
            }
          }, 0); // Delay to ensure the element is rendered
        }
      }
    }
    if (page === 0) {
      setData(forumTopics || []);
    }
  }, [forumTopics, currentQueryParameters, isTopicFound, page]);

  const { data: tags } = useGetAllTags(siteId);
  const { data: tagTypes } = useGetAllTagTypes(siteId);

  const createTopic = useCreateForumTopic(onMutation(MutationAction.CREATE, '', refetchTopics));
  const editTopic = useUpdateForumTopic(onMutation(MutationAction.UPDATE, '', refetchTopics));
  const deleteTopic = useDeleteForumTopic(onMutation(MutationAction.DELETE, '', refetchTopics));
  const handleSubmitNewTopic = async (newTopic: ForumTopic) => {
    setIsSaving(true);
    try {
      const pageSlug = location.pathname.split('/').pop() || '';
      const topic = await createTopic.mutateAsync({
        siteId,
        forumId: forum.id,
        data: {
          ...newTopic,
          id: '',
          metadata: {
            page_slug: pageSlug,
          },
        },
      });
      firehoseActivity(
        ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_COMMUNITY,
        topic.id,
        forum.id,
        `${currentUser?.id || ''}`,
        ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_FORUM_TOPIC,
        ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_CREATE.value,
        topic.title,
        null,
      );
      setData((old) => [topic, ...old]);
      setIsSaving(false);
      setShowNewDialog(false);
    } catch (e) {
      console.error(e);
      setIsSaving(false);
      setShowNewDialog(false);
    }
    // TODO navigate to new topic
  };

  const handleEditTopic = (topic: ForumTopic) => {
    setEditingTopic(topic);
    setShowNewDialog(true);
  };

  const handleSubmitEditTopic = async (topic: ForumTopic) => {
    setIsSaving(true);
    try {
      await editTopic.mutateAsync({
        siteId,
        forumId: forum.id,
        topicId: topic.id,
        data: topic,
      });
      setIsSaving(false);
      setShowNewDialog(false);
      setEditingTopic(null);
    } catch (e) {
      console.error(e);
      setIsSaving(false);
      setShowNewDialog(false);
      setEditingTopic(null);
    }
  };

  const handleDeleteTopic = () => {
    setDeleteDialogOpen(true);
  };

  const handleDelete = async () => {
    setIsSaving(true);
    setDeleteDialogOpen(false);
    try {
      await deleteTopic.mutateAsync({
        siteId,
        forumId: forum.id,
        topicId: selectedTopic?.id || '',
      });
      firehoseActivity(
        ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_COMMUNITY,
        selectedTopic?.id || '',
        forum.id,
        `${currentUser?.id || ''}`,
        ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_FORUM_TOPIC,
        ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_DELETE.value,
        selectedTopic?.title || '',
        null,
      );
      setIsSaving(false);
      setShowNewDialog(false);
      setEditingTopic(null);
      setSelectedTopic(null);
    } catch (e) {
      console.error(e);
      setIsSaving(false);
      setShowNewDialog(false);
      setEditingTopic(null);
    }
  };

  const hasReportedView = useRef(false);
  useEffect(() => {
    // Record forum view analytics
    if (forum && me && !hasReportedView.current) {
      hasReportedView.current = true;
      firehoseActivity(
        ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_COMMUNITY,
        forum?.id || '',
        null,
        `${me?.id || ''}`,
        ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_FORUM,
        ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_VIEW.value,
        '',
        null,
      );
    }
  }, [firehoseActivity, forum, me]);

  const htmlValue = useMemo(() => {
    const sanitizedString = DOMPurify.sanitize(selectedTopic?.description || ('' as string), {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'],
    });
    // uncomment to debug what's getting removed
    // console.log('Dirty values', DOMPurify.removed);
    return sanitizedString;
  }, [selectedTopic?.description]);

  return (
    <Box>
      {!selectedTopic && (
        <>
          <DefaultSearchSortFilter
            buttonDisabled={!userCanInteract}
            buttonText={'New Discussion'}
            sort={sort}
            sortOptions={[
              { value: 'alphabetical', label: 'Alphabetical' },
              { value: 'newest', label: 'Newest' },
            ]}
            onClickButton={() => setShowNewDialog(true)}
            setSearch={(value) => setSearch(value)}
            setSort={(value) => {
              setSort(value);
              setPage(0);
            }}
            onClickFilter={() => setShowFilters(true)}
          />
          {filters.length > 0 && (
            <Box mt={2} mb={1}>
              {filters.map((tag) => (
                <Chip
                  key={`filter_${tag.id}`}
                  label={tag.value}
                  sx={{ mr: 1, mt: 1 }}
                  onDelete={() => setFilters((old) => old.filter((t) => t.id !== tag.id))}
                />
              ))}
            </Box>
          )}
          {!isLoading && data?.length === 0 && !debouncedSearch && userCanInteract && (
            <Box
              display='flex'
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              minHeight='100px'
            >
              <Typography variant='body2'>Be the first to start a conversation.</Typography>
            </Box>
          )}
          {data && data.length > 0 && (
            <Stack spacing={1} pt={2}>
              {data?.map((topic: ForumTopic) => {
                return (
                  <Box
                    key={`topic__${topic.id}`}
                    ref={(el) => {
                      topicRefs.current[topic.id] = el as HTMLDivElement;
                    }}
                  >
                    <motion.div
                      animate={{
                        scale: topicToViewId === topic.id ? [1, 1.1, 1] : 1,
                      }}
                    >
                      <ForumTopicCard
                        userCanInteract={userCanInteract}
                        topic={topic}
                        me={me}
                        refetchMe={refetchMe}
                        siteId={siteId}
                        refetchTopics={refetchTopics}
                        iAmAdmin={iAmAdmin}
                        handleEditTopic={handleEditTopic}
                        setSelectedTopic={setSelectedTopic}
                        setDeleteDialogOpen={setDeleteDialogOpen}
                      />
                    </motion.div>
                  </Box>
                );
              })}

              {isLoading && (
                <Skeleton height={180} sx={{ mt: 2, transform: 'none', transformOrigin: '0 0' }} />
              )}
              {!isLoading && data && data?.length % PAGE_SIZE === 0 && (
                <Box ref={ref} className='pagination-element' sx={{ height: 20 }}></Box>
              )}
            </Stack>
          )}
          {showNewDialog && (
            <CreateNewTopicDialog
              handleSubmit={handleSubmitNewTopic}
              handleCancel={() => setShowNewDialog((old) => !old)}
              tags={tags || []}
              isSaving={isSaving}
              editingTopic={editingTopic}
              handleEditSubmit={handleSubmitEditTopic}
              handleDeleteTopic={handleDeleteTopic}
            />
          )}
          <DialogAriaWrapper
            id={`forum_filters`}
            open={showFilters}
            onClose={() => setShowFilters(false)}
            fullWidth={true}
            maxWidth={'sm'}
          >
            <DialogTitle>Filter discussions by tags</DialogTitle>
            <IconButton
              aria-label='close'
              onClick={() => setShowFilters(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              {tagTypes?.map((tagType) => {
                const tagsOfType = tags?.filter((tag) => tag.type.id === tagType.id);
                return (
                  <Box key={tagType.id}>
                    <Typography fontWeight='bold' sx={{ mt: 2, mb: 1 }}>
                      {tagType.value}
                    </Typography>
                    {tagsOfType?.map((tag) => {
                      const isSelected = filters.find((t) => t.id === tag.id);
                      return (
                        <Chip
                          key={tag.id}
                          label={tag.value}
                          sx={{
                            mr: 1,
                            mb: 1,
                            cursor: 'pointer',
                            backgroundColor: isSelected
                              ? (theme) => theme.palette.primary.main
                              : 'auto',
                            color: isSelected ? '#fff' : 'auto',
                            ':hover': {
                              backgroundColor: isSelected
                                ? (theme) => theme.palette.primary.main
                                : 'rgba(0,0,0,.12)',
                            },
                          }}
                          onClick={() => {
                            if (filters.find((t) => t.id === tag.id)) {
                              setFilters((old) => old.filter((f) => f.id !== tag.id));
                            } else {
                              setFilters((old) => [...old, tag]);
                            }
                          }}
                        />
                      );
                    })}
                  </Box>
                );
              })}
            </DialogContent>
          </DialogAriaWrapper>
        </>
      )}
      {selectedTopic && (
        <Box>
          <Stack direction='row' alignItems='center' mb={2}>
            <Box mr={2}>
              <Tooltip title='Back to forum topics'>
                <IconButton
                  onClick={() => {
                    setSelectedTopic(null);
                    setSearchParams({});
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Typography variant='h5'>{selectedTopic.title}</Typography>
              <Typography
                dangerouslySetInnerHTML={{
                  __html: htmlValue as unknown as string,
                }}
              />
            </Box>
          </Stack>
          <FeedComponent
            siteId={siteId}
            feed={selectedTopic?.feed || ({} as Feed)}
            userCanInteract={userCanInteract}
            blockUserInteractionMessage={feedBlockUserInteractionMessage}
            iAmAdmin={iAmAdmin}
            isInForum={true}
          />
        </Box>
      )}
      <DialogAriaWrapper open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Discussion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this discussion?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size='small'
            color='inherit'
            onClick={() => setDeleteDialogOpen(false)}
            aria-label='Cancel'
            variant='text'
            sx={{ textTransform: 'none' }}
          >
            Cancel
          </Button>
          <LoadingButton
            size='small'
            color='primary'
            onClick={() => handleDelete()}
            aria-label='Delete'
            variant='contained'
            sx={{ textTransform: 'none' }}
            loading={isSaving}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </DialogAriaWrapper>
    </Box>
  );
};
export default ForumComponent;

interface GlobalForumProps {
  siteId: string;
  title: string;
}

export const GlobalForum: React.FC<GlobalForumProps> = ({ siteId, title }) => {
  const { xs, sm, md, lg } = useBreakpoint();
  const { data: forum } = useGetSiteForum(siteId);
  const { user } = useSettings();
  const iAmAdmin = user?.role === 1 || false;
  if (!forum) {
    return <></>;
  }
  return (
    <Container p={2}>
      {title && (
        <Typography variant='h5' sx={{ ml: 1, mb: 2 }}>
          {title}
        </Typography>
      )}
      <ForumComponent siteId={siteId} forum={forum} iAmAdmin={iAmAdmin} />
    </Container>
  );
};

interface CreateNewTopicDialogProps {
  handleSubmit: (newTopic: ForumTopic) => void;
  handleCancel: () => void;
  tags: Tag[];
  isSaving: boolean;
  editingTopic: ForumTopic | null;
  handleEditSubmit: (topic: ForumTopic) => void;
  handleDeleteTopic: () => void;
}

interface CreateNewTopicDialogProps {
  handleSubmit: (newTopic: ForumTopic) => void;
  handleCancel: () => void;
  tags: Tag[];
  isSaving: boolean;
  editingTopic: ForumTopic | null;
  handleEditSubmit: (topic: ForumTopic) => void;
  handleDeleteTopic: () => void;
}

const CreateNewTopicDialog: React.FC<CreateNewTopicDialogProps> = ({
  handleSubmit,
  handleCancel,
  tags,
  isSaving,
  editingTopic,
  handleEditSubmit,
  handleDeleteTopic,
}) => {
  const { site, user } = useSettings();
  const theme = useTheme(); // Use the theme
  const editorRef = useRef<any>(null);
  const [title, setTitle] = useState(editingTopic?.title || '');
  const [description, setDescription] = useState(editingTopic?.description || '');
  const [tagsState, setTagsState] = useState<Tag[]>(editingTopic?.tags || []);
  const [aiAssistMode, setAIAssistMode] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State to control snackbar visibility
  const [fabPulse, setFabPulse] = useState(false); // State to control fab pulsing animation
  const [loading, setLoading] = useState(false); // State to control loading visibility
  const [wasAIGenerated, setWasAIGenerated] = useState(false); // State to control if AI was generated
  const [originalSuggestion, setOriginalSuggestion] = useState(''); // State to store the original suggestion
  const [rating, setRating] = useState<number | null>(null);
  const { data: configs, isLoading: configsLoading } = useGetFeatureConfigs(site?.id || '');
  const isAIAssistEnabled = FeatureConfigUtils.getFeatureFlag(
    configs,
    FEATURE_FLAG_KEYS_ENUM.AIAssist,
  );

  const steps = ['Enter AI Assist', 'Generate', 'Review!'];

  useEffect(() => {
    if (isAIAssistEnabled) {
      const showSnackbarTimer = setTimeout(() => {
        setSnackbarOpen(true);
        setFabPulse(true); // Start pulsing the FAB
        const hideSnackbarTimer = setTimeout(() => {
          setSnackbarOpen(false);
          setFabPulse(false); // Stop pulsing the FAB
        }, 5000); // Hide after 5 seconds
        return () => clearTimeout(hideSnackbarTimer);
      }, 1000); // Show after 1 second
      return () => clearTimeout(showSnackbarTimer);
    }
  }, [isAIAssistEnabled]);

  const genSuggestion = useGenerateContentWithOpenAI(
    onMutation(MutationAction.CREATE, 'GenerateContentWithOpenAIParams', () => {}),
  );

  const recordAISuggestion = useCreateAISuggestionRecord(
    onMutation(MutationAction.CREATE, 'AISuggestionRecord', () => {}),
  );

  const handleAIAssist = () => {
    setAIAssistMode(true);
  };

  const handleGenerateSuggestions = async () => {
    setLoading(true);
    const basePrompt = `Generate json for an engaging discussion using the prompt. Ensure there is a key for the title and for description. This is going in a rich text editor so use bold, italics, and emojis to maximize engagement. Use the following prompt to generate the description: `;
    try {
      // This feels a bit hacky at this point BUT... there were a few times when testing
      // where this returned something not JSON serializable, and rerunning it solved it.
      // TODO: Before this gets to production we should add an error state.
      const suggestion = await genSuggestion.mutateAsync({
        siteId: site?.id || '',
        data: {
          prompt: basePrompt + prompt,
        } as OpenAIRequest,
      });

      const suggestionJson = JSON.parse(suggestion?.response || '');
      setTitle(suggestionJson.title);
      setDescription(suggestionJson.description);
      setOriginalSuggestion(suggestion?.response || '');
      setWasAIGenerated(true);
      setLoading(false);
      setAIAssistMode(false);
    } catch (e) {
      try {
        const suggestion = await genSuggestion.mutateAsync({
          siteId: site?.id || '',
          data: {
            prompt: basePrompt + prompt,
          } as OpenAIRequest,
        });

        const suggestionJson = JSON.parse(suggestion?.response || '');
        setTitle(suggestionJson.title);
        setDescription(suggestionJson.description);
        setOriginalSuggestion(suggestion?.response || '');
        setWasAIGenerated(true);
        setLoading(false);
        setAIAssistMode(false);
      } catch (e) {
        console.error(e);
        setLoading(false);
        // TODO: Add error page state
        setAIAssistMode(false);
      }
    }
  };

  const handleCreateTopic = () => {
    if (editingTopic) {
      handleEditSubmit({
        ...editingTopic,
        title,
        description,
        tags: tagsState,
      });
    } else {
      handleSubmit({
        title,
        description,
        tags: tagsState,
        author: user?.id || '',
      } as ForumTopic);
      if (wasAIGenerated) {
        recordAISuggestion.mutateAsync({
          data: {
            suggested_text: originalSuggestion,
            final_text: JSON.stringify({ title, description }),
            prompt_used: prompt,
            rating: rating,
            relative_url: window.location.pathname + window.location.search,
            source: 'Discussion',
          } as AISuggestionRecord,
          siteId: site?.id || '',
        });
      }
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter' && !loading) {
      e.preventDefault();
      handleGenerateSuggestions();
    }
  };

  return (
    <DialogAriaWrapper id={`create_new_topic_dialog`} open={true} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle id='create_new_topic_dialog-dialog-title' sx={{ pb: 0 }}>
        {aiAssistMode
          ? 'Start a Discussion: AI Assist'
          : editingTopic
          ? 'Edit Discussion'
          : 'Start a Discussion'}
        {editingTopic && !aiAssistMode && (
          <Button
            size='small'
            sx={{ textTransform: 'none', ml: 1 }}
            onClick={() => handleDeleteTopic()}
          >
            Delete
          </Button>
        )}
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent id='create_new_topic_dialog-dialog-description'>
        {aiAssistMode ? (
          <Box>
            <DialogActions>
              {loading ? (
                <Box sx={{ position: 'relative', height: '56px', width: '100%', ml: 19 }}>
                  <JunoSpin />
                </Box>
              ) : (
                <TextField
                  fullWidth
                  size='small'
                  placeholder='i.e. How can AI improve engagement in our association?'
                  value={prompt}
                  onChange={(e) => setPrompt(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              )}
              <Button
                variant='contained'
                color='primary'
                disabled={loading}
                onClick={handleGenerateSuggestions}
              >
                Generate
              </Button>
              <Button
                variant='outlined'
                color='secondary'
                disabled={loading}
                onClick={() => setAIAssistMode(false)}
              >
                Cancel
              </Button>
            </DialogActions>
            <Box sx={{ width: '100%', mt: 4 }}>
              <Stepper activeStep={1} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Box>
        ) : (
          <Stack spacing={1}>
            <TextField
              fullWidth
              placeholder={'Discussion title'}
              value={title}
              name='title'
              autoFocus
              onChange={(e) => setTitle(e.target.value)}
            />
            <Editor
              apiKey={process.env.NX_TINY_MCE_API_KEY}
              onEditorChange={(value: string) => setDescription(value)}
              value={description || ''}
              onInit={(evt, editor) => (editorRef.current = editor)}
              init={{
                highlight_on_focus: true,
                images_upload_handler: uploadTinyMceImageCloudinary,
                contextmenu: false,
                height: 250,
                toolbar:
                  'emoticons bold italic underline strikethrough link | alignleft aligncenter alignright alignjustify outdent indent | bullist numlist blockquote',
                menubar: false,
                statusbar: false,
                toolbar_location: 'bottom',
                setup: (editor) => {
                  editor.setProgressState(true);
                },
              }}
              disabled={false}
              plugins={'autolink emoticons link'}
            />
            <Autocomplete
              disablePortal={false}
              id='combo-box-demo'
              options={tags}
              value={tagsState}
              fullWidth
              getOptionLabel={(tag) => tag.value}
              onChange={(e, newValue) => setTagsState(newValue)}
              multiple
              renderInput={(params) => <TextField {...params} label='Tags' />}
            />
          </Stack>
        )}
      </DialogContent>
      {!aiAssistMode && (
        <DialogActions>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Box sx={{ ml: 10, mb: 1 }}>
              {wasAIGenerated && (
                <Box>
                  <Typography>How helpful was AI Assist?</Typography>
                  <Rating
                    name='ai-assist-rating'
                    value={rating ? rating : 0}
                    onChange={(event, newValue) => {
                      setRating(newValue);
                    }}
                  />
                </Box>
              )}
            </Box>

            <Box sx={{ mt: 3, display: 'flex', alignItems: 'center' }}>
              <Button
                size='small'
                color='inherit'
                onClick={handleCancel}
                aria-label='Cancel editing'
                variant='text'
                sx={{ textTransform: 'none' }}
              >
                Cancel
              </Button>
              <LoadingButton
                size='small'
                color='primary'
                onClick={handleCreateTopic}
                aria-label='Create topic'
                variant='contained'
                sx={{ textTransform: 'none', ml: 1 }}
                disabled={!title || !description}
                loading={isSaving}
              >
                {editingTopic ? 'Save' : 'Create'}
              </LoadingButton>
            </Box>
          </Box>
        </DialogActions>
      )}
      {!aiAssistMode && isAIAssistEnabled && (
        <Tooltip title='AI Assist' placement='left'>
          <Fab
            color='primary'
            aria-label='AI Assist'
            onClick={handleAIAssist}
            sx={{
              position: 'absolute',
              bottom: 16,
              left: 16,
              animation: fabPulse ? 'pulse 1.7s infinite' : 'none',
            }}
          >
            <AutoAwesomeIcon />
          </Fab>
        </Tooltip>
      )}
      <Box
        sx={{
          position: 'absolute',
          bottom: 80,
          left: 16,
          zIndex: 1300, // Ensure it's on top
        }}
      >
        <Snackbar
          open={snackbarOpen}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity='info'
            sx={{
              width: '100%',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.info.contrastText,
              '& .MuiAlert-icon': { color: theme.palette.info.contrastText },
            }}
          >
            Use the AI Assist button to generate suggestions for your discussion.
          </Alert>
        </Snackbar>
      </Box>
      <style>
        {`
          @keyframes pulse {
            0% {
              transform: scale(1);
            }
            50% {
              transform: scale(1.1);
            }
            100% {
              transform: scale(1);
            }
          }
        `}
      </style>
    </DialogAriaWrapper>
  );
};
