export enum RelativeTimeFrameOptions {
  LastMonth = 'Last Month',
  LastWeek = 'Last Week',
  LastYear = 'Last Year',
  NextMonth = 'Next Month',
  NextWeek = 'Next Week',
  NextYear = 'Next Year',
  ThisMonth = 'This Month',
  ThisWeek = 'This Week',
  ThisYear = 'This Year',
  Today = 'Today',
}
