import React, { useEffect, useState } from 'react';
import { EditNoteOutlined } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForever from '@mui/icons-material/DeleteForever';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Card, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FieldArray, Form, Formik } from 'formik';
import {
  useCreateUserEducation,
  useDeleteUserEducation,
  useGetUserEducation,
  useUpdateUserEducation,
} from '@juno/client-api';
import { GenericFormikInput } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import { AnimatedIconButton } from './AnimatedIconButton';
import { useUserProfile } from './UserProfileContext';
import { EDUCATION_CHARACTER_LIMITS, educationValidationSchema } from './helpers';

export const Education: React.FC = () => {
  const userProfile = useUserProfile();
  const [isEditingArray, setIsEditingArray] = useState<boolean[]>([]);
  const theme = useTheme();
  const { site, user } = useSettings();

  const { data: education, refetch } = useGetUserEducation(
    site?.id || '',
    userProfile?.id || '',
    {},
    { query: { enabled: !!site?.id && !!userProfile?.id } },
  );

  const updateEducation = useUpdateUserEducation(
    onMutation(MutationAction.UPDATE, 'Education', refetch),
  );
  const createEducation = useCreateUserEducation(
    onMutation(MutationAction.CREATE, 'Education', refetch),
  );
  const deleteEducation = useDeleteUserEducation(
    onMutation(MutationAction.DELETE, 'Education', refetch),
  );

  useEffect(() => {
    if (education) {
      setIsEditingArray(new Array(education.length).fill(false));
    }
  }, [education]);

  if (!education) return null;

  const toggleEdit = (index: number, values: any, remove: any) => {
    const newEditingState = [...isEditingArray];
    newEditingState[index] = !newEditingState[index];
    if (
      !newEditingState[index] &&
      values.education[index].school_name === '' &&
      values.education[index].degree === '' &&
      values.education[index].field_of_study === '' &&
      values.education[index].start_date === '' &&
      values.education[index].end_date === ''
    ) {
      remove(index);
    }
    setIsEditingArray(newEditingState);
  };

  const handleSave = async (index: number, values: any) => {
    const thisEducation = values.education[index];

    if (index < education.length) {
      if (
        thisEducation.school_name !== education[index].school_name ||
        thisEducation.degree !== education[index].degree ||
        thisEducation.start_date !== education[index].start_date ||
        thisEducation.end_date !== education[index].end_date ||
        thisEducation.field_of_study !== education[index].field_of_study
      ) {
        await updateEducation.mutateAsync({
          siteId: site?.id || '',
          userId: user?.id || '',
          educationId: thisEducation.id,
          data: thisEducation,
        });
      }
    } else {
      await createEducation.mutateAsync({
        siteId: site?.id || '',
        userId: user?.id || '',
        data: thisEducation,
      });
    }

    const newEditingState = [...isEditingArray];
    newEditingState[index] = false;
    setIsEditingArray(newEditingState);
  };

  return (
    <Card sx={{ p: 3, mt: 2, position: 'relative', boxShadow: 1 }}>
      <Typography variant='h6'>Education</Typography>
      <Formik
        initialValues={{ education: education || [] }}
        validationSchema={educationValidationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          for (let index = 0; index < values.education.length; index++) {
            await handleSave(index, values);
          }
          setSubmitting(false);
        }}
        validateOnMount
      >
        {({ values, submitForm, dirty }) => (
          <Form>
            <FieldArray name='education'>
              {({ push, remove }) => (
                <>
                  {values.education.length > 0 &&
                    values.education.map((education, index) => (
                      <Box key={index} sx={{ position: 'relative', my: 2 }}>
                        <Grid container spacing={2} alignItems='center'>
                          {isEditingArray[index] ? (
                            <>
                              <Grid item xs={12} mt={3}>
                                <GenericFormikInput
                                  value={education.school_name}
                                  name={`education.${index}.school_name`}
                                  label='School Name'
                                  fullWidth
                                  size='small'
                                  helperText={`${
                                    values.education[index]?.school_name?.length || 0
                                  }/${EDUCATION_CHARACTER_LIMITS.schoolName}`}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GenericFormikInput
                                  value={education.degree}
                                  name={`education.${index}.degree`}
                                  label='Degree'
                                  fullWidth
                                  size='small'
                                  helperText={`${values.education[index]?.degree?.length || 0}/${
                                    EDUCATION_CHARACTER_LIMITS.degree
                                  }`}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GenericFormikInput
                                  value={education.field_of_study}
                                  name={`education.${index}.field_of_study`}
                                  label='Field of Study'
                                  fullWidth
                                  size='small'
                                  helperText={`${
                                    values.education[index]?.field_of_study?.length || 0
                                  }/${EDUCATION_CHARACTER_LIMITS.fieldOfStudy}`}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GenericFormikInput
                                  value={education.start_date}
                                  name={`education.${index}.start_date`}
                                  label='Start Date'
                                  fullWidth
                                  size='small'
                                  helperText={`${
                                    values.education[index]?.start_date?.length || 0
                                  }/${EDUCATION_CHARACTER_LIMITS.startDate}`}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <GenericFormikInput
                                  value={education.end_date}
                                  name={`education.${index}.end_date`}
                                  label='End Date'
                                  fullWidth
                                  size='small'
                                  helperText={`${values.education[index]?.end_date?.length || 0}/${
                                    EDUCATION_CHARACTER_LIMITS.endDate
                                  }`}
                                />
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <Box>
                                  <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                                    {education.school_name}
                                  </Typography>
                                  <Typography variant='subtitle1'>{education.degree}</Typography>
                                  <Typography variant='body2'>
                                    {education.field_of_study}
                                  </Typography>
                                  <Typography variant='body2'>
                                    {education.start_date} - {education.end_date}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                          )}
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                        {userProfile.isMe && (
                          <>
                            {isEditingArray[index] ? (
                              <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                                <IconButton
                                  onClick={() => toggleEdit(index, values, remove)}
                                  size='medium'
                                >
                                  <CancelIcon sx={{ color: theme.palette.error.main }} />
                                </IconButton>
                                <AnimatedIconButton
                                  onClick={() => handleSave(index, values)}
                                  size='medium'
                                  animate={dirty}
                                >
                                  <SaveIcon color={dirty ? 'primary' : 'inherit'} />
                                </AnimatedIconButton>
                              </Box>
                            ) : (
                              <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                                <IconButton
                                  onClick={() => toggleEdit(index, values, remove)}
                                  size='medium'
                                >
                                  <EditNoteOutlined />
                                </IconButton>
                                <IconButton
                                  onClick={async () => {
                                    try {
                                      await deleteEducation.mutate({
                                        siteId: site?.id || '',
                                        userId: user?.id || '',
                                        educationId: values.education[index].id,
                                      });
                                      remove(index);
                                    } catch (e) {
                                      console.error(e);
                                    }
                                  }}
                                  size='medium'
                                >
                                  <DeleteForever />
                                </IconButton>
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                    ))}
                  {userProfile.isMe && (
                    <Box display='flex' justifyContent='center'>
                      <IconButton
                        onClick={() => {
                          push({
                            school_name: '',
                            degree: '',
                            field_of_study: '',
                            start_date: '',
                            end_date: '',
                          });
                          setIsEditingArray([...isEditingArray, true]);
                        }}
                        size='small'
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Box>
                  )}
                </>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </Card>
  );
};
