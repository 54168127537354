import React, { useState } from 'react';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import { LoadingButton } from '@mui/lab';
import { Box, ListItemIcon, MenuItem, Typography } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {
  useCreateSessionBackground,
  useDeleteSessionBackground,
  useGetSessionBackgrounds,
} from '@juno/client-api';
import { JunoUser, SessionBackground } from '@juno/client-api/model';
import { useS3Upload } from '@juno/modules';
import { BasicKebobMenu, VisuallyHiddenInput } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';

const DeveloperAdmin: React.FC = () => {
  const { site, user } = useSettings();
  const { uploadFileToS3 } = useS3Upload();
  const [uploadProgress, setUploadProgress] = useState(0);
  const { data: standardBackgrounds, refetch } = useGetSessionBackgrounds(
    site?.id || '',
    { filter: { site_level_bg: true } },
    { query: { enabled: !!site?.id } },
  );
  const createSessionBackground = useCreateSessionBackground(
    onMutation(MutationAction.CREATE, '', refetch),
  );
  const deleteSessionBackground = useDeleteSessionBackground(
    onMutation(MutationAction.DELETE, '', refetch),
  );

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file === undefined) return;

    try {
      setUploadProgress(0.01); // set to 1% to show progress bar
      const awaitUpload = async (file: File) => {
        const uploadResult = await uploadFileToS3(file, setUploadProgress);
        const background = {
          user_id: user?.id,
          image: uploadResult,
          site_level_bg: true,
        } as SessionBackground;
        await createSessionBackground.mutateAsync({
          siteId: site?.id || '',
          data: background,
        });
        setUploadProgress(0);
      };

      awaitUpload(file);
    } catch (e: any) {
      // TODO better error handling
      setUploadProgress(0);
      console.error(e);
    }
  };
  return (
    <>
      <LoadingButton variant='text' loading={uploadProgress > 0} component='label'>
        <AddPhotoAlternateOutlinedIcon />
        <VisuallyHiddenInput multiple={false} type='file' accept='.jpg' onChange={handleChange} />
      </LoadingButton>
      {standardBackgrounds && (
        <ImageList sx={{ width: '100%' }} cols={4} rowHeight={'auto'}>
          {standardBackgrounds?.map((background) => (
            <ImageListItem key={background.id} sx={{ p: 0 }}>
              <Box
                sx={{
                  width: '100%',
                  height: 150,
                  backgroundImage: `url(${background.image})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: 2,
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <BasicKebobMenu>
                  <Box>
                    <MenuItem
                      onClick={() => {
                        deleteSessionBackground.mutateAsync({
                          siteId: site?.id || '',
                          backgroundId: background.id,
                        });
                      }}
                      aria-label='Delete file'
                    >
                      <ListItemIcon>
                        <DeleteForeverRoundedIcon color='primary' fontSize='small' />
                      </ListItemIcon>
                      <Typography>Delete</Typography>
                    </MenuItem>
                  </Box>
                </BasicKebobMenu>
              </Box>
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </>
  );
};
export default DeveloperAdmin;
