import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PubNub from 'pubnub';
import Pubnub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { JunoSkeleton } from '@juno/ui';
import { useSettings } from '@juno/utils';

const SUBSCRIBE_KEY = process.env.NX_PUBNUB_SUBSCRIBE_KEY || 'UNINITIALIZED';
const PUBLISH_KEY = process.env.NX_PUBNUB_PUBLISH_KEY || 'UNINITIALIZED';

const defaultPubnubContext = {
  pubnub: new PubNub({
    publishKey: PUBLISH_KEY,
    subscribeKey: SUBSCRIBE_KEY,
    userId: 'UNINITIALIZED',
  }),
  systemChannel: '',
};

const PubnubContext = createContext<PubnubContextType>(defaultPubnubContext as PubnubContextType);

export const usePubnubContext = () => useContext(PubnubContext);

type PubnubContextType = {
  pubnub: PubNub;
  systemChannel: string;
};

type PubnubProviderProps = {
  // Need to pass this in for now to differentiate how it is handled in Das
  isSuperAdmin?: boolean;
  children: React.ReactNode;
};

export const JunoPubnubProvider: React.FC<PubnubProviderProps> = ({ isSuperAdmin, children }) => {
  const { site, user } = useSettings();
  const [pubnub, setPubnub] = useState<Pubnub | undefined>(undefined);
  // Maps of data required for the messenger. Storing them here to avoid re-fetching, avoid passing them as props
  // This channel is used to send messages to people and control their messenger. i.e. add them to a channel etc.
  // It is also used to track active users on the platform based on if they are subscribed to this
  const systemChannel = useMemo(() => {
    if (site) {
      return `system-${site.platform_id}`.slice(0, -7);
    }
    return '';
  }, [site]);

  // Initialize the pubnub instance and chat instance
  useEffect(() => {
    // Configure the PubNub instance
    if (isSuperAdmin) {
      setPubnub(
        new Pubnub({
          publishKey: PUBLISH_KEY,
          subscribeKey: SUBSCRIBE_KEY,
          userId: 'admin',
        }),
      );
    } else if (user?.id) {
      setPubnub(
        new Pubnub({
          publishKey: PUBLISH_KEY,
          subscribeKey: SUBSCRIBE_KEY,
          userId: user?.id,
        }),
      );
    }
  }, [user?.id, isSuperAdmin]);
  if (!pubnub) {
    return <></>;
  }

  const contextValue = {
    pubnub,
    systemChannel,
  };
  return (
    <PubnubContext.Provider value={contextValue}>
      <PubNubProvider client={pubnub}>{children}</PubNubProvider>
    </PubnubContext.Provider>
  );
};
