import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useDebounce } from 'usehooks-ts';
import { ComponentData, Component as ComponentModel } from '@juno/client-api/model';

interface ScheduleFormProps {
  component?: ComponentModel;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
  formik: FormikProps<AnnouncementsComponentModel>;
}

interface AnnouncementsFormWrapperProps {
  component?: ComponentModel;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
}

export interface AnnouncementsDataModel extends ComponentData {
  title: string;
  global_feed: boolean;
  global_forum: boolean;
}

export interface AnnouncementsComponentModel extends ComponentModel {
  data: AnnouncementsDataModel;
}

const AnnouncementsForm: React.FC<ScheduleFormProps> = ({ component, onUpdate, formik }) => {
  const { handleChange, setValues, values } = formik;
  const debouncedValues = useDebounce(values, 700);
  useEffect(() => {
    if (component) {
      onUpdate({ ...component, ...debouncedValues });
    }
  }, [debouncedValues]);
  return (
    <>
      <Typography variant='body2' sx={{ opacity: 0.6 }}>
        Editing Section
      </Typography>
      <Typography variant='h5' sx={{ mb: 4 }}>
        {values.name || 'Announcements'}
      </Typography>
      <TextField
        value={values.name}
        onChange={handleChange}
        name='name'
        label='Name'
        variant='filled'
        fullWidth
        InputProps={{ disableUnderline: true }}
        sx={{ mb: 2 }}
      />
      <Stack spacing={1}>
        <TextField
          value={values?.data?.title}
          onChange={(e) => {
            setValues({
              ...values,
              data: {
                ...values.data,
                title: e.target.value,
              },
            });
          }}
          name='title'
          label='Section Title'
          variant='filled'
          fullWidth
          InputProps={{ disableUnderline: true }}
          sx={{ mb: 2 }}
        />
        <Typography variant='body1'>Show Announcements From</Typography>
        <Tooltip
          title='This will show all announcements from the global feed.'
          placement='top-start'
        >
          <FormControlLabel
            value={values?.data?.global_feed}
            control={
              <Checkbox
                checked={values?.data?.global_feed}
                onChange={(e) =>
                  setValues({
                    ...values,
                    data: {
                      ...values.data,
                      global_feed: e.target.checked,
                    },
                  })
                }
              />
            }
            label='Global Feed'
          />
        </Tooltip>
        <Tooltip
          title='This will show all announcements from the global discussions.'
          placement='top-start'
        >
          <FormControlLabel
            value={values?.data?.global_forum}
            control={
              <Checkbox
                checked={values?.data?.global_forum}
                onChange={(e) =>
                  setValues({
                    ...values,
                    data: {
                      ...values.data,
                      global_forum: e.target.checked,
                    },
                  })
                }
              />
            }
            label='Global Discussions'
          />
        </Tooltip>
      </Stack>
    </>
  );
};

const AnnouncementsFormWrapper: React.FC<AnnouncementsFormWrapperProps> = ({
  component,
  onSave,
  onUpdate,
}) => {
  return (
    <Formik
      initialValues={
        (component as AnnouncementsComponentModel) || ({} as AnnouncementsComponentModel)
      }
      onSubmit={async (values) => {
        if (component) {
          onSave({ ...component, ...values });
        }
      }}
      enableReinitialize
    >
      {(formik) => {
        return (
          <Form>
            <AnnouncementsForm
              component={component}
              onSave={onSave}
              onUpdate={onUpdate}
              formik={formik}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default AnnouncementsFormWrapper;
