import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import './BurnEffect.scss';

const generateRandomPositions = (count: number) => {
  const shiftLeft = 100; // Shift all positions 100px to the left
  return Array.from({ length: count }).map(() => ({
    x: Math.random() * window.innerWidth - shiftLeft,
    y: Math.random() * window.innerHeight,
  }));
};

const generateRandomSizes = (count: number) => {
  if (count < 5) {
    return Array.from({ length: count }).map(() => '275px');
  } else if (count > 6) {
    return Array.from({ length: count }).map(() => `${75 + Math.random() * 100}px`);
  }
  return Array.from({ length: count }).map(() => '225px');
};

const generateRandomDurations = (count: number) => {
  return Array.from({ length: count }).map(() => 4 + Math.random() * 7); // gif iterations - the gifs chosen repeat about every second
};

const generateRandomOffsets = () => ({
  x: (Math.random() - 0.5) * 600, // Random value between -300 and 300
  y: (Math.random() - 0.5) * 600,
});

const BurnEffect: React.FC<{ onEnd: () => void; gifToShow: string }> = ({ onEnd, gifToShow }) => {
  const gifCount = 5 + Math.floor(Math.random() * 25);
  const positions = generateRandomPositions(gifCount);
  const sizes = generateRandomSizes(gifCount);
  const durations = generateRandomDurations(gifCount);

  useEffect(() => {
    const timeoutId = setTimeout(onEnd, 6000); // End the effect after 6 seconds
    return () => clearTimeout(timeoutId);
  }, [onEnd]);

  return (
    <div className='burn-effect'>
      {Array.from({ length: gifCount }).map((_, index) => {
        const duration = durations[index];
        const offset = generateRandomOffsets();

        return (
          <motion.div
            key={index}
            initial={{ opacity: 1, x: positions[index].x, y: positions[index].y }}
            animate={{
              x: positions[index].x + offset.x,
              y: positions[index].y + offset.y,
            }}
            transition={{
              duration,
              ease: 'easeInOut',
              times: [0, 0.7, 1],
            }}
            style={{
              position: 'absolute',
              width: sizes[index],
              height: sizes[index],
              background: `url(${gifToShow}) no-repeat center center`,
              backgroundSize: 'contain',
            }}
          />
        );
      })}
    </div>
  );
};

export default BurnEffect;
