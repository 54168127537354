// Django Filters on uuid don't like it when you give them invalid ids. Don't pass it in unless this regex passes.
const uuidRegexExp =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;

// Split the text into words by spaces. Quoted text will be one word.
export const splitWords = (text: string) => {
  const words: string[] = [];
  let currentWord: string[] = [];
  let isInQuote = false;
  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i);
    if (char === '"') {
      // Switch to in or out of quote
      isInQuote = !isInQuote;
      // Save previous word if entering or exiting quote
      if (currentWord.length > 0) {
        words.push(currentWord.join(''));
        currentWord = [];
      }
    } else if (char === ' ' && !isInQuote) {
      // Skip space outside quote. Save word. Clear word.
      if (currentWord.length > 0) {
        words.push(currentWord.join(''));
        currentWord = [];
      }
    } else {
      // Push character to word
      currentWord.push(char);
    }
  }
  // Capture any word still in the queue
  if (currentWord.length > 0) {
    words.push(currentWord.join(''));
  }
  return words;
};
export const calculateUserSearchFilters = (searchText: string, modelKey = '') => {
  const words = splitWords(searchText);
  if (words.length < 1) {
    return {
      include: 'settings',
    };
  }
  if (words.length === 1) {
    const word = words[0];
    return {
      filter_or: {
        [`${modelKey}first_name__istartswith`]: word,
        [`${modelKey}last_name__istartswith`]: word,
        [`${modelKey}email__icontains`]: word,
        [`${modelKey}id__iexact`]: uuidRegexExp.test(word) ? word : undefined,
      },
      include: 'settings',
    };
  }
  const validIds = words.filter((w) => uuidRegexExp.test(w));
  if (words.length === 2) {
    const first_name = words[0];
    const last_name = words[1];

    return {
      filter_and_or: {
        and: {
          [`${modelKey}first_name__iexact`]: first_name,
          [`${modelKey}last_name__istartswith`]: last_name,
        },
        or: {
          [`${modelKey}email__iin`]: words,
          [`${modelKey}id__iin`]: validIds.length > 0 ? validIds : undefined,
        },
      },
      include: 'settings',
    };
  }
  // Words length > 2
  return {
    filter_or: {
      [`${modelKey}email__iin`]: words,
      [`${modelKey}id__iin`]: validIds.length > 0 ? validIds : undefined,
    },
    include: 'settings',
  };
};
