import React from 'react';
import { ConfigCatProvider, LogLevel, createConsoleLogger } from 'configcat-react';
import { SnackbarProvider } from 'notistack';
import { config } from 'process';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  AnalyticsProvider,
  AppProvider,
  JunoPubnubProvider,
  MessengerProvider,
  NotificationProvider,
  PlatformProvider,
  S3UploadProvider,
} from '@juno/modules';
import ThemeWrapper from './components/ThemeWrapper';
import ClientRoutes from './routes';

// TODO: client-side observability
// In architects meeting 6/4/24, we decided the backend observability is good enough for now.
// We will revisit this in the future.
// export const faro = initializeFaro();

const CONFIGCAT_SDK_KEY = process.env.NX_CONFIGCAT_SDK_KEY || '**';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const App = () => {
  const logger = createConsoleLogger(LogLevel.Info);

  return (
    <ConfigCatProvider sdkKey={CONFIGCAT_SDK_KEY} options={{ logger }}>
      <QueryClientProvider client={queryClient}>
        <PlatformProvider>
          <AppProvider>
            <ThemeWrapper>
              <S3UploadProvider>
                <JunoPubnubProvider>
                  <NotificationProvider>
                    <MessengerProvider>
                      <AnalyticsProvider>
                        <SnackbarProvider>
                          <ClientRoutes />
                        </SnackbarProvider>
                      </AnalyticsProvider>
                    </MessengerProvider>
                  </NotificationProvider>
                </JunoPubnubProvider>
              </S3UploadProvider>
            </ThemeWrapper>
          </AppProvider>
        </PlatformProvider>
      </QueryClientProvider>
    </ConfigCatProvider>
  );
};

export default App;
