import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMessengerContext, useSettings } from '@juno/utils';
import { getTimeSince } from '../Utils';
import { messengerTabMap } from '../index';

export interface ConversationsRowProps {
  channelId: string;
  channelName?: string;
  channelMembers: string;
  lastChannelMessage: string;
  createdAt: string;
  avatarSrc?: string;
  unreadCount: number;
}

export const ConversationsRow: React.FC<ConversationsRowProps> = ({
  channelId,
  channelName,
  channelMembers,
  lastChannelMessage,
  createdAt,
  avatarSrc,
  unreadCount,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const formattedTimeSinceLastMessage = getTimeSince(createdAt);
  const { user } = useSettings();
  const {
    setIsConversation,
    setMessengerTab,
    setCurrentChannel,
    setChannelVisibility,
    markRead,
    messengerMap,
    setCurrentConversationLastReadMessageId,
  } = useMessengerContext();

  const navigate = useNavigate();
  const { sitePath } = useSettings();

  const memberCount =
    channelMembers.split(',').length > 1 ? channelMembers.split(',').length + 1 : 1;

  const handleHideChannel = () => {
    if (!user?.id) return;
    setChannelVisibility(channelId, user?.id, true).then((success) => {
      if (success) {
        console.log(`Channel ${channelId} hidden successfully`);
      } else {
        console.error(`Failed to hide channel ${channelId}`);
      }
    });
  };

  const handleConversationClick = (e: any) => {
    if (channelId) {
      // Find the channel in messages map, then find the last unread/isNewMessage message, and set setCurrentConversationLastReadMessageId to the last message id
      setCurrentConversationLastReadMessageId(
        messengerMap.channels
          .find((channel) => channel.id === channelId)
          ?.messages.filter((message) => message.isNewMessage)
          .slice(-1)[0]?.createdAt || '',
      );

      markRead(channelId, undefined).then((success) => {
        setCurrentChannel(channelId);
        setIsConversation(true);
        setMessengerTab(messengerTabMap.Messenger);
      });
    } else {
      console.error('No channelId found');
    }
  };

  const getTheOtherUser = () => {
    const otherUser = messengerMap.channels
      .find((channel) => channel.id === channelId)
      ?.junoUsers.filter((junoUser) => junoUser.id !== user?.id);

    if (!otherUser) {
      console.error('No other user found');
      return;
    }

    if (otherUser.length > 1) {
      console.error(
        'More than one other user found. getTheOtherUser is only for 1:1 conversations',
      );
      return;
    }

    return otherUser[0];
  };

  const handleAvatarClick = () => {
    const userId = getTheOtherUser()?.id;
    if (!userId) {
      console.error('No user id found');
      return;
    }
    navigate(`/${sitePath}/user/${userId}`);
  };

  const handleBlockUser = () => {
    console.log(`Block user functionality will be implemented here for channel ${channelId}`);
  };

  return (
    <ListItem
      alignItems='flex-start'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        onMouseDown={(e) => (memberCount > 1 ? handleConversationClick(e) : handleAvatarClick())}
        sx={{ cursor: 'pointer' }}
      >
        <ListItemAvatar
          sx={{ marginRight: 1, minWidth: 0, alignSelf: 'center', marginBottom: 0.7 }}
        >
          {memberCount > 1 ? (
            <Avatar sx={{ width: 34, height: 34 }}>{memberCount}</Avatar>
          ) : (
            <Avatar
              alt={channelMembers[0]}
              src={avatarSrc ?? undefined}
              sx={{ width: 34, height: 34 }}
            />
          )}
        </ListItemAvatar>
      </Box>
      <Grid
        container
        justifyContent='space-between'
        alignItems='center'
        sx={{ width: '100%', cursor: 'pointer' }}
      >
        <Grid item xs={10} onMouseDown={handleConversationClick}>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body1',
              style: {
                whiteSpace: 'unset !important',
              },
            }}
            primary={
              <Typography
                sx={{
                  display: 'block',
                  fontSize: '1.1rem',
                  whiteSpace: 'unset !important',
                  overflowWrap: 'break-word', // Allows long words to be broken and wrapped to the next line
                }}
                component='span'
                variant='body1'
                color='text.primary'
                fontWeight={unreadCount > 0 ? 600 : 400}
              >
                {channelMembers}
              </Typography>
            }
            secondary={
              <Typography
                sx={{
                  display: 'block',
                  color: 'gray',
                }}
                component='span'
              >
                {lastChannelMessage ? lastChannelMessage.slice(0, 27) + '...' : ''}
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={2}>
          {isHovered ? (
            <Grid
              item
              xs={4}
              sx={{
                textAlign: 'right',
                display: 'flex',
                alignItems: 'right',
                justifyContent: 'flex-start',
                margin: 0,
                padding: 0,
              }}
            >
              <IconButton
                onClick={handleHideChannel}
                size='small'
                sx={{ padding: 0, '&:hover': { backgroundColor: 'transparent' } }}
              >
                <CloseIcon fontSize='small' />
              </IconButton>
            </Grid>
          ) : (
            <Typography color='gray'>{formattedTimeSinceLastMessage ?? ''}</Typography>
          )}
        </Grid>
      </Grid>
    </ListItem>
  );
};
