import React, { useEffect, useMemo } from 'react';
import { useGetAccessPass, useGetProduct, useGetUserPurchases } from '@juno/client-api';
import {
  AccessPass as AccessPassModel,
  Course as CourseModel,
  JunoUser,
  Product as ProductModel,
  UserPurchaseStatusEnum,
} from '@juno/client-api/model';

export interface ExtendedAccessPassModel extends AccessPassModel {
  course_complete_percentage?: string;
  products: ProductModel[];
}

export const useAccessPassPurchaseState = (
  siteId: string,
  user: JunoUser | undefined,
  accessPasses: AccessPassModel[],
  refetch?: () => void,
  onPurchaseFulfilled?: () => void,
) => {
  //takes an access pass
  const extendedAccessPasses = accessPasses as ExtendedAccessPassModel[];
  const product = extendedAccessPasses?.find((pass) => pass?.products?.[0])?.products?.[0];
  const myAccessPass = user?.access_passes.find((ap) =>
    accessPasses.find((pass) => ap.id === pass.id),
  );
  const myPriceLevel = user?.price_level;
  const myPrice = product?.product_prices.find(
    (price) => price.price_level.id === myPriceLevel || '',
  );

  // purchases by this user with this product
  const { data: purchaseData, refetch: refetchPurchases } = useGetUserPurchases(
    siteId,
    user?.id || '',
    {
      filter: { product_id__in: [product?.id || ''] },
    },
    { query: { enabled: product?.id && user?.id ? true : false } },
  );

  const myPayment = purchaseData?.find(
    (purchase) => purchase.status === UserPurchaseStatusEnum.completed,
  );

  // Effect for determining if we should be polling UserPurchase object waiting for completion
  useEffect(() => {
    if (!product) {
      return;
    }
    // we've paid
    if (purchaseData?.find((purchase) => purchase.status === UserPurchaseStatusEnum.completed)) {
      refetch && refetch();
      onPurchaseFulfilled && onPurchaseFulfilled();
      return;
    }
    // no pending transaction
    if (!purchaseData?.find((purchase) => purchase.status === UserPurchaseStatusEnum.pending)) {
      return;
    }
    // there is a pending completion so lets poll it
    const interval = setInterval(() => {
      refetchPurchases();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [purchaseData, accessPasses]);

  return { product, myPrice, myPayment, myAccessPass };
};
