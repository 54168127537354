import React, { useEffect } from 'react';
import { FormControlLabel, Stack, Switch } from '@mui/material';

interface IncludeSectionProps {
  allow_chat?: boolean;
  allow_anon_chat?: boolean;
  allow_qa?: boolean;
  allow_attendee_qa?: boolean;
  allow_polls?: boolean;
  allow_attendees?: boolean;
  allow_reactions?: boolean;
  record_chat?: boolean;
  handleChange: any;
}
const IncludeSection: React.FC<IncludeSectionProps> = ({
  allow_chat = true,
  allow_anon_chat = false,
  allow_qa = true,
  allow_attendee_qa = true,
  allow_polls = true,
  allow_attendees = true,
  allow_reactions = true,
  record_chat = false,
  handleChange,
}) => {
  useEffect(() => {
    if (record_chat === true) {
      handleChange({ target: { name: 'allow_anon_chat', value: false } });
    }
  }, [record_chat]);
  return (
    <Stack>
      <FormControlLabel
        control={<Switch checked={allow_chat} onChange={handleChange} name='allow_chat' />}
        label='Chat'
      />
      {/* {allow_chat && (
        <FormControlLabel
          sx={{ ml: 4 }}
          control={
            <Switch
              checked={allow_anon_chat}
              onChange={handleChange}
              name='allow_anon_chat'
              disabled={record_chat === true}
            />
          }
          label='Allow anonymous chat'
        />
      )} */}
      <FormControlLabel
        control={<Switch checked={allow_qa} onChange={handleChange} name='allow_qa' />}
        label='Q&A'
      />
      {/* {allow_qa && (
        <FormControlLabel
          sx={{ ml: 4 }}
          control={
            <Switch checked={allow_attendee_qa} onChange={handleChange} name='allow_attendee_qa' />
          }
          label='Allow attendees to ask questions'
        />
      )} */}
      {/* <FormControlLabel
        control={<Switch checked={allow_polls} onChange={handleChange} name='allow_polls' />}
        label='Polls'
      /> */}
      <FormControlLabel
        control={
          <Switch checked={allow_attendees} onChange={handleChange} name='allow_attendees' />
        }
        label='Attendee List'
      />
      {/* <FormControlLabel
        control={
          <Switch checked={allow_reactions} onChange={handleChange} name='allow_reactions' />
        }
        label='Reactions'
      /> */}
    </Stack>
  );
};
export default IncludeSection;
