import React, { useEffect, useRef, useState } from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, Button, LinearProgress, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ReactDOM from 'react-dom';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import {
  LessonCompletion as LessonCompletionModel,
  LessonPart as LessonPartModel,
} from '@juno/client-api/model';
import { useAnalyticsContext } from '@juno/modules';
import { useSettings } from '@juno/utils';

export const VideoButton = styled(Button)({
  marginRight: 10,
  textTransform: 'none',
});

export const FlexedBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const VideoBody = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isIncomplete',
})<{ isIncomplete?: boolean }>(({ isIncomplete, theme }) => ({
  borderRadius: theme.spacing(1),
  border: `solid 1px ${!isIncomplete ? theme.palette.background.paper : theme.palette.error.light}`,
  padding: 5,
}));

interface VideoPartProps {
  part: LessonPartModel;
  completion?: LessonCompletionModel;
  setVideoWatched?: (id: string, p: number) => void;
  isIncomplete?: boolean;
  id: string;
  shouldPause?: boolean;
  lessonId: string;
  showNativeControls?: boolean;
}

interface VideoCompletionMetadataModel {
  [key: string]: unknown;
  video_completion?: {
    [key: string]: number;
  };
}

const VideoPart: React.FC<VideoPartProps> = ({
  part,
  completion,
  setVideoWatched,
  isIncomplete = false,
  shouldPause = false,
  id,
  lessonId,
  showNativeControls = false,
}) => {
  const theme = useTheme();
  const { user } = useSettings();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const mobileSize = useMediaQuery(theme.breakpoints.down('md'));

  // Grab previously reported spot in the video
  const metadata: VideoCompletionMetadataModel | undefined = completion?.metadata;
  const previouslyCompleted = metadata?.video_completion?.[part.id] || 0;
  const [isPlaying, setIsPlaying] = useState(false);
  const [isVideoReady, setIsVideoReady] = useState(false);
  const [hasWatched, setHasWatched] = useState<number>(previouslyCompleted / 100);
  const [lastReported, setLastReported] = useState<number>(previouslyCompleted / 100);
  const videoPartRef = useRef<ReactPlayer | null>(null);
  const videoWrapperRef = useRef<Element | null>(null);
  const { junoUser } = useSettings();
  const { firehoseActivity } = useAnalyticsContext();
  // ReactPlayer's handleProgress is more accurate than the getDuration() funct we were previously relying upon
  const handleProgress = (state: { played: number }) => {
    const currentCompletion = Math.round(state.played * 100) / 100;
    // Update the display watch percentage and set it ever so slightly behind the actual watch percentage
    // This guarantees that the user will never think they've reached the required watch percentage when they actually haven't
    if (currentCompletion - 0.001 >= hasWatched + 0.01) {
      setHasWatched(currentCompletion - 0.001);
    }
    // Update the DB every 10%
    if (currentCompletion >= lastReported + 0.1) {
      if (setVideoWatched) {
        // Provide a small completion percentage buffer otherwise we never get fully to 100%
        if (currentCompletion >= 0.99) {
          setVideoWatched(part?.id || '', 100);
        } else {
          setVideoWatched(part?.id || '', lastReported * 100 + 10);
        }
      }
      setLastReported(lastReported + 0.1);
    }
  };

  // 1 second interval for tracking video played progress and saving every 10% of progress in the DB
  useEffect(() => {
    let interval: any;
    if (isPlaying) {
      interval = setInterval(() => {
        const currentCompletion =
          (videoPartRef.current?.getCurrentTime() || 0) /
          (videoPartRef.current?.getDuration() || 1);
        if (currentCompletion >= lastReported + 0.1) {
          if (setVideoWatched) {
            setVideoWatched(part?.id || '', lastReported * 100 + 10);
            setLastReported(lastReported + 0.1);
          }
        }
      }, 1000);
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [isPlaying, lastReported]);

  const handleEnded = () => {
    setHasWatched(1);
  };

  const playPause = () => {
    if (isPlaying) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);

      // Report playing a video
      firehoseActivity(
        'Content',
        lessonId,
        null,
        `${user?.id || ''}`,
        'Content - [lesson]',
        'Play',
        null,
        null,
      );
    }
  };

  useEffect(() => {
    if (isPlaying && shouldPause) {
      setIsPlaying(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldPause]);

  const goBack30 = () => {
    const currentTime = videoPartRef.current?.getCurrentTime();
    if (currentTime) {
      const newTime = currentTime - 30 < 0 ? 0 : currentTime - 30;
      videoPartRef.current?.seekTo(newTime, 'seconds');
      const newHasWatched = hasWatched - 30 / (videoPartRef.current?.getDuration() || 1);
      setHasWatched(newHasWatched > 0 ? Math.round(newHasWatched * 100) / 100 : 0);
    }
  };

  const restartVideo = () => {
    if (videoPartRef?.current) {
      videoPartRef.current.seekTo(0, 'fraction');
      setHasWatched(0);
    }
  };

  const required_percent =
    part?.video_required_completion_percent || part?.video_required_completion_percent === 0
      ? part?.video_required_completion_percent
      : 80;

  const onReady = () => {
    // onReady actually fires again after seek so we have to store some state here to not
    // keep seeking to their starting point.
    if (!isVideoReady) {
      if (hasWatched == 0) {
        setIsVideoReady(true);
      } else {
        const currentTime = videoPartRef.current?.getCurrentTime();
        const hasSeeked = currentTime !== null && currentTime !== 0;
        if (hasSeeked) {
          setIsVideoReady(true);
          return;
        } else {
          videoPartRef.current?.seekTo(hasWatched, 'fraction');
          const newCurrentTime = videoPartRef.current?.getCurrentTime();
          const newHasSeeked = newCurrentTime !== null && newCurrentTime !== 0;
          if (newHasSeeked) {
            setIsVideoReady(true);
          } else {
            // There's an issue with vimeo where it wasn't loading fast enough so it wouldn't seek
            // all this extra logic is to rerun this until seek is possible and correct it without a stutter
            setTimeout(() => {
              onReady();
            }, 500);
          }
        }
      }
    }
  };

  const onToggleScreen = () => {
    if (isFullscreen) {
      document.exitFullscreen();
    } else {
      //videoWrapperRef?.current?.requestFullscreen();
      const node = ReactDOM.findDOMNode(videoPartRef.current) as Element;
      screenfull.request(node);
    }
  };

  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement));
    }

    document.addEventListener('fullscreenchange', onFullscreenChange);

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange);
  }, []);

  return (
    <>
      {part.title && <Typography variant='h5'>{part.title}</Typography>}
      <VideoBody isIncomplete={isIncomplete} id={id} ref={videoWrapperRef}>
        <Box
          style={{
            paddingTop: '56.25%',
            position: 'relative',
            background: '#000',
            pointerEvents: showNativeControls ? 'auto' : 'none',
          }}
        >
          <ReactPlayer
            url={part?.video_url || ''}
            controls={showNativeControls}
            playing={isPlaying}
            ref={videoPartRef}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            onProgress={handleProgress}
            onEnded={handleEnded}
            onReady={onReady}
            config={{
              youtube: {
                playerVars: { origin: null },
              },
            }}
            width='100%'
            height='100%'
            style={{ position: 'absolute', top: 0, left: 0 }}
          />
        </Box>
        {!showNativeControls && (
          <Box
            style={{
              position: isFullscreen ? 'fixed' : 'relative',
              bottom: isFullscreen ? 0 : 'auto',
              left: isFullscreen ? theme.spacing(4) : 'auto',
              right: isFullscreen ? theme.spacing(4) : 'auto',
            }}
          >
            {' '}
            <LinearProgress
              variant='determinate'
              value={hasWatched * 100}
              sx={{ marginTop: '-4px' }}
            />
            <FlexedBox
              sx={{
                marginTop: 1,
              }}
            >
              <FlexedBox sx={{ maxWidth: 'calc(100% - 50px)' }}>
                <VideoButton
                  onClick={() => playPause()}
                  variant='contained'
                  sx={mobileSize ? {} : { width: 110 }}
                >
                  {isPlaying ? (
                    <>
                      <PauseIcon sx={mobileSize ? { marginRight: 0 } : { marginRight: 1 }} />
                      {mobileSize ? '' : 'PAUSE'}
                    </>
                  ) : (
                    <>
                      <PlayArrowIcon sx={mobileSize ? { marginRight: 0 } : { marginRight: 1 }} />
                      {mobileSize ? '' : 'PLAY'}
                    </>
                  )}
                </VideoButton>{' '}
                <VideoButton onClick={onToggleScreen} sx={{ minWidth: 40 }}>
                  {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </VideoButton>
                <VideoButton
                  onClick={() => {
                    goBack30();
                  }}
                  size='small'
                >
                  <UndoIcon sx={{ marginRight: 1 }} /> 30s
                </VideoButton>{' '}
                <VideoButton
                  onClick={() => {
                    restartVideo();
                  }}
                  size='small'
                >
                  <SettingsBackupRestoreIcon sx={{ marginRight: 1 }} /> Restart
                </VideoButton>
              </FlexedBox>
              <Typography sx={{ color: isFullscreen ? 'white' : '#000' }}>
                {Math.round(hasWatched * 100)}%
              </Typography>
            </FlexedBox>
            {required_percent > 0 && (
              <Typography variant='body2' sx={{ marginTop: 1 }}>
                *You must watch {required_percent}% of the video to finish the lesson
              </Typography>
            )}{' '}
          </Box>
        )}
      </VideoBody>
    </>
  );
};

export default VideoPart;
