import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import {
  getGetAllCourseRequirementsQueryKey,
  useCreateCourseRequirement,
  useGetAllCourseRequirements,
} from '@juno/client-api';
import { CourseRequirement, Site as SiteModel } from '@juno/client-api/model';
import {
  AlertDialog,
  DefaultSearchBox,
  JunoSpin,
  JunoRowCreateTile as RowCreateTile,
} from '@juno/ui';
import { MutationAction, onMutation } from '@juno/utils';
import { PrereqContent } from './helpers/AutoCompletePrereqContent';
import FormDialog from './helpers/FormDialog';
import PrereqListItem from './helpers/PrereqListItem';

const NoItemsFound = styled(Box)(() => ({
  textAlign: 'center',
  userSelect: 'none',
}));

interface PrereqListProps {
  site: SiteModel;
}

const PrerequisiteList: React.FC<PrereqListProps> = ({ site }) => {
  const { id: siteId } = site;
  const queryClient = useQueryClient();
  const [formDialogOpen, setFormDialogOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const refetch = () => {
    queryClient.invalidateQueries(getGetAllCourseRequirementsQueryKey(siteId));
  };
  const navigate = useNavigate();
  const createCourseRequirement = useCreateCourseRequirement(
    onMutation(MutationAction.CREATE, 'CourseRequirement', refetch),
  );
  const [prereqSearchInput] = useState('');
  const debouncedSearchValue = useDebounce(prereqSearchInput, 200);

  const getSearchFilter = () => {
    if (!debouncedSearchValue) return undefined;
    return {
      name__icontains: debouncedSearchValue,
    };
  };

  const { data: prereqs, isLoading } = useGetAllCourseRequirements(siteId, {
    filter_or: getSearchFilter(),
    order: 'name',
  });

  const handleCreate = () => {
    setFormDialogOpen(true);
  };

  const handleDialogSave = (
    data: CourseRequirement,
    grantingContent: PrereqContent[],
    requiringContent: PrereqContent[],
  ) => {
    setFormDialogOpen(false);
    createCourseRequirement.mutate({ siteId, data });
  };

  const handleDialogClose = () => {
    setConfirmDeleteOpen(false);
    setFormDialogOpen(false);
    setIsSaving(false);
  };

  const handleDelete = () => {
    handleDialogClose();
  };

  const [searchTerm, setSearchTerm] = useState('');
  const handleChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  if (isLoading) {
    return <JunoSpin />;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <RowCreateTile onClick={handleCreate} text='Create a Prerequisite' />
      <DefaultSearchBox searchTerm={searchTerm} onChange={handleChange} />
      {prereqs?.length === 0 && (
        <NoItemsFound sx={{ mt: 3 }}>
          <Typography variant='h6'>No items found</Typography>
        </NoItemsFound>
      )}
      {prereqs?.map((item) => {
        if (item.name.toLowerCase().search(searchTerm.toLowerCase()) >= 0) {
          return (
            <PrereqListItem
              siteId={site.id}
              key={item.id}
              prereq={item}
              handleClick={(id: string) => {
                navigate(`prereqs/${id}`);
              }}
            />
          );
        } else {
          return null;
        }
      })}
      <FormDialog
        site={site}
        isLoading={false}
        open={formDialogOpen}
        onClose={handleDialogClose}
        onSave={handleDialogSave}
        onDelete={() => setConfirmDeleteOpen(true)}
        isSaving={isSaving}
      />
      <AlertDialog
        open={confirmDeleteOpen}
        description={'Are you sure you want to delete this item?'}
        onCancel={() => setConfirmDeleteOpen(false)}
        onConfirm={handleDelete}
      />
    </Box>
  );
};

export default PrerequisiteList;
