/**
 ** TO ADD AN IMPORT, FOLLOW THESE STEPS:
 * Step 1. Add validation consts in /utils/validation.ts - Use yup for validation
 * Step 2. Add the bucket to utils/headerKeys.ts
 * Step 3. Add objects to the bucket object that map to the model for the type of import
 * Step 4. Add the bucket to the bucketSelector in /helpers/BucketSelector.tsx
 * Step 5. Add the bucket to the switch statement in the switchTypeAndReturnShapedValue in /utils/index.tsx function if there is a relational field
 * Step 6: Add the bucket to the getAutocompleteOptions method in /wizard-parts/DialogContent if there is a relational field
 * Step 7: Add the item to the handlePublishImport method in this file
 */
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import { useGetAllLessons, useGetAllTags, useGetCourses } from '@juno/client-api';
import { useGetUserRoleMap } from '@juno/client-api/helpers';
import { ImportRecordTypeEnum, Lesson, LessonPart, Question, Site } from '@juno/client-api/model';
import { useS3Upload } from '@juno/modules';
import { JunoSpin } from '@juno/ui';
import { snackOptions } from '@juno/utils';
import HeaderConfirmationDialog from '../HeaderConfirmationDialog';
import ImporterHeaderButtons from '../ImportValidationDialog/ImporterHeader';
import BucketSelector from '../helpers/BucketSelector';
import ImportCsvReader from '../helpers/ImportCsvReader';
import { importUploadProcessor } from '../helpers/ImportUploadProcessor';
import {
  ImportArrayModel,
  countTableRowErrors,
  getColumnDefinitionsForTable,
  getTableRowErrors,
  handleFormikValueManipulationByBucket,
} from '../utils';
import { compareExtraKeys, compareRequiredKeys, headerKeys } from '../utils/headerKeys';
import ImportFormik from './ImportFormik';

interface ImportTabProps {
  site: Site;
  currentTab: number;
}
const ImportTab: React.FC<ImportTabProps> = ({ site, currentTab }) => {
  const importFormRef = useRef<any>(null);
  const originalErrorRowRef = useRef([]);

  const [bucket, setBucket] = useState('');
  const [totalErrors, setImportErrors] = useState(0);
  const [reqdKeys, setReqdKeys] = useState<any[]>([]);
  const [extraKeys, setExtraKeys] = useState<any[]>([]);
  const [rows, setRows] = useState<any[]>([]);
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [validationOpen, setValidationOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [headerConfirmOpen, setHeaderConfirmOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading: isLoadingUser, user } = useGetUserRoleMap(site.id);
  const { data: siteTags, isLoading: isLoadingTags } = useGetAllTags(site.id);
  const { data: courses, isLoading: isLoadingCourses } = useGetCourses(
    site.id,
    {},
    { query: { enabled: true } },
  );
  const { data: lessonList, isLoading: isLoadingLessons } = useGetAllLessons(site.id);
  const [questionList, setQuestionList] = useState<Question[]>([]);
  const { uploadFileToS3 } = useS3Upload();

  useEffect(() => {
    // Get all question titles and ids out of lesson->parts->question
    const questions: Question[] = [];
    lessonList?.forEach((lesson: Lesson) => {
      lesson.parts?.forEach((part: LessonPart) => {
        part?.question?.forEach((question: Question) => {
          questions.push({
            id: question.id,
            name: question.name,
          } as Question);
        });
      });
    });
    setQuestionList(questions);
  }, [lessonList]);

  useEffect(() => {
    if (rows.length > 0) {
      setRows([]);
      setTableRows([]);
    }
    const cols = getColumnDefinitionsForTable(bucket);
    setColumns(cols);
  }, [bucket]);

  const handleFileLoad = (newState: any) => {
    // comparing headers must happen before we manipulate the data
    handleHeaderValidation(newState);
    // have to go thru and manipulate
    const newVals = handleFormikValueManipulationByBucket(bucket, newState, {
      courseList: courses,
      siteTags: siteTags,
      questionList: questionList,
      lessonList: lessonList,
    });
    const withIds = newVals.map((row: any, i: number) => ({ ...row, rowId: i }));
    setRows(withIds);
    setTableRows(withIds);
  };

  const handleHeaderValidation = (row: any) => {
    const required = compareRequiredKeys(row[0], bucket);
    const extra = compareExtraKeys(row[0], bucket);
    setReqdKeys(required);
    setExtraKeys(extra);
    if (required.length > 0 || extra.length > 0) {
      setHeaderConfirmOpen(true);
    }
  };

  const handleClear = () => {
    importFormRef.current?.setValues({ rows: [] });
    setRows([]);
    setTableRows([]);
  };

  const validate = (values: any) => {
    setTableRows(values.rows);
    const errors: { rows: any } = {
      rows: [],
    };

    // call countTableRowErrors and setTotalErrors to the result
    let count = 0;
    values.rows.forEach((row: any, i: number) => {
      count += countTableRowErrors([row]);
    });
    setImportErrors(count);

    // Call getTableRowErrors, then reduce the resulting array values down to a single new array of key value pairs where the key is the errorField and the value is the errorMessage.
    const rowErrors = getTableRowErrors(values.rows);
    if (rowErrors.length > 0) {
      errors.rows = rowErrors.reduce((acc: ImportArrayModel, obj: ImportArrayModel) => {
        const key = obj['rowId'];
        const curGroup = acc[key] ?? [];
        return { ...acc, [key]: [...curGroup, obj] };
      }, {} as ImportArrayModel);
    }

    return errors;
  };

  const deleteItem = (rowIndex: number, setFieldValue: any) => {
    const newRows = rows.filter((row, i) => i !== rowIndex);
    // reset our rowIds
    const resetIds = newRows.map((row: any, i: number) => ({
      ...row,
      rowId: i,
    }));
    setRows(resetIds);
    setTableRows(resetIds);
    setFieldValue('rows', resetIds);
  };

  const deleteAll = (remainingValues: any, setFieldValue: any) => {
    // reset our rowIds
    const resetIds = remainingValues.map((row: any, i: number) => ({
      ...row,
      rowId: i,
    }));
    setRows(resetIds);
    setTableRows(resetIds);
    setFieldValue('rows', resetIds);
  };

  const handlePublishImport = () => {
    let importType: ImportRecordTypeEnum = '' as ImportRecordTypeEnum;
    let relationalArray: any[] = [];
    let matchKey = '';

    // TODO need to add all other buckets as they are added
    switch (bucket) {
      case 'Courses':
        importType = ImportRecordTypeEnum.learningcourses;
        break;
      case 'Course Resources':
        importType = ImportRecordTypeEnum.learningcourse_resources;
        break;
      case 'Lessons':
        importType = ImportRecordTypeEnum.learninglessons;
        relationalArray = courses as any[];
        matchKey = 'slug';
        break;
      case 'Lesson Parts':
        importType = ImportRecordTypeEnum.learninglesson_parts;
        break;
      case 'Questions':
        importType = ImportRecordTypeEnum.learningquestions;
        break;
      case 'Question Answers':
        importType = ImportRecordTypeEnum.learninganswers;
        break;
      case 'Users':
        importType = ImportRecordTypeEnum.learninguser_management;
        break;
    }

    const bucketKeys = getColumnDefinitionsForTable(bucket);
    importUploadProcessor({
      rawValueRows: importFormRef.current.values.rows,
      importType,
      matchKey,
      relationalArray,
      bucketKeys,
      bucket,
      site,
      user,
      uploadFileToS3,
    })
      .then(() => {
        enqueueSnackbar(
          'Import has been initiated. Please check the Change Logs for progress.',
          snackOptions('success'),
        );
      })
      .catch((errMessage: string) => {
        enqueueSnackbar(errMessage, snackOptions('error'));
      });

    setConfirmationOpen(false);
    handleClear();
  };

  if (isLoadingTags || isLoadingCourses || isLoadingUser || isLoadingLessons) {
    return <JunoSpin />;
  }

  return (
    <Box p={2}>
      <Typography variant='h6' sx={{ mb: 2, ml: 1 }}>
        Choose a Data Bucket
      </Typography>
      <Stack direction='row' justifyContent='space-between'>
        <BucketSelector bucket={bucket} handleChange={(e) => setBucket(e.target.value)} />
        <ImporterHeaderButtons
          currentTab={currentTab}
          totalErrors={totalErrors}
          shouldShow={rows.length > 0 ?? false}
          onClickDiscard={handleClear}
          onClickValidate={() => {
            originalErrorRowRef.current = [];
            if (totalErrors > 0) {
              setValidationOpen(true);
            } else {
              setConfirmationOpen(true);
            }
          }}
        />
      </Stack>
      <Card sx={{ width: '100%', p: 3 }}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={12}>
            {!bucket && rows.length === 0 && (
              <Typography variant='body1'>Please choose a Data Bucket above.</Typography>
            )}

            {rows.length > 0 && (
              <Grid item xs={12} justifyContent='flex-end' display='flex'>
                <Button
                  disabled={selectionModel.length === 0}
                  onClick={() => {
                    setDeleteOpen(true);
                  }}
                >
                  Delete Selection(s)
                </Button>
              </Grid>
            )}
            {columns.length > 0 && bucket && (
              <ImportFormik
                importFormRef={importFormRef}
                rows={rows}
                setRows={setRows}
                tableRows={tableRows}
                setTableRows={setTableRows}
                columns={columns}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                validationOpen={validationOpen}
                setValidationOpen={setValidationOpen}
                originalErrorRowRef={originalErrorRowRef}
                courses={courses ?? []}
                siteTags={siteTags ?? []}
                lessonList={lessonList ?? []}
                questionList={questionList ?? []}
                totalErrors={totalErrors}
                validate={validate}
                deleteOpen={deleteOpen}
                setDeleteOpen={setDeleteOpen}
                confirmationOpen={confirmationOpen}
                setConfirmationOpen={setConfirmationOpen}
                handlePublishImport={handlePublishImport}
                deleteItem={deleteItem}
                deleteAll={deleteAll}
                bucket={bucket}
              />
            )}
            {bucket && rows.length === 0 && (
              <ImportCsvReader
                handleState={handleFileLoad}
                bucket={bucket}
                transformHeader={(header: string) => {
                  if (bucket) {
                    const thisKey = headerKeys[bucket]?.filter((key) => key.headerName == header);
                    return (thisKey[0] && thisKey[0]?.name) || header;
                  }
                  return header;
                }}
              />
            )}
            {headerConfirmOpen && (
              <HeaderConfirmationDialog
                handleClose={() => setHeaderConfirmOpen(false)}
                missingHeaders={reqdKeys}
                extraHeaders={extraKeys}
                handleClear={handleClear}
              />
            )}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};
export default ImportTab;
