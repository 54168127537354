import React from 'react';
import { useInView } from 'react-intersection-observer';

type VirtualScrollChildProps = {
  height: number;
  threshold?: number;
  children?: React.ReactNode;
  initialInView?: boolean;
};
/**
 * A wrapper component for children of
 * VirtualScroll. Computes inline style and
 * handles whether to display props.children.
 */
const VirtualScrollChild = ({
  height,
  threshold = 0,
  initialInView = false,
  children,
}: VirtualScrollChildProps) => {
  const [ref, inView] = useInView({ threshold: threshold, initialInView: initialInView });
  const style = {
    height: `${height}px`,
  };
  return (
    <div style={style} ref={ref}>
      {inView ? children : null}
    </div>
  );
};

export default VirtualScrollChild;
