export const CHIP_DATE_FORMAT = 'MM/DD/YYYY';
export interface RoleMapType {
  [key: string]: { key: string; value: string };
}

export const ROLE_MAP: RoleMapType = {
  ADMIN: { key: 'admin', value: 'Admin' },
  MANAGER: { key: 'manager', value: 'Manager' },
  LEARNER: { key: 'learner', value: 'Learner' },
};

export enum TileType {
  COURSE,
  ENROLLMENT,
  WAITLIST,
}

// Admin Course List
export const ADMIN_COURSE_ORDER = 'date_start';
export const ADMIN_COURSE_INCLUDE = 'manager_enrollments_count,manager_waitlist_count';
export const ADMIN_COURSE_SEARCH_FIELD = 'title__icontains';

// Current Course List
export const CURRENT_COURSE_FILTER = {
  date_completed__isnull: true,
  course__date_end__gte: new Date().toISOString(),
};
export const CURRENT_COURSE_INCLUDE = 'lessons_complete_percent';
export const CURRENT_COURSE_SEARCH_FIELD = 'course__title__icontains';

// Waitlisted Course List
export const WAITLISTED_COURSE_INCLUDE = 'course';
export const WAITLISTED_COURSE_SEARCH_FIELD = 'course__title__icontains';

// Completed Course List
export const COMPLETED_COURSE_FILTER = { date_completed__isnull: false };
export const COMPLETED_COURSE_SEARCH_FIELD = 'course__title__icontains';
