/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type ImportRecordTypeEnum = typeof ImportRecordTypeEnum[keyof typeof ImportRecordTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImportRecordTypeEnum = {
  learningcourses: 'learning.courses',
  learninglessons: 'learning.lessons',
  learninglesson_parts: 'learning.lesson_parts',
  learningquestions: 'learning.questions',
  learninganswers: 'learning.answers',
  learningcourse_resources: 'learning.course_resources',
  learninguser_management: 'learning.user_management',
} as const;
