import React from 'react';
import { GridSelectionModel } from '@mui/x-data-grid';
import { StyledDataGrid } from '@juno/utils';
import { ImportArrayModel, isTableRowValid } from '../utils';

interface ImportDataGridProps {
  tableRows: ImportArrayModel[];
  selectionModel: GridSelectionModel;
  setSelectionModel: React.Dispatch<React.SetStateAction<GridSelectionModel>>;
  columns: any[];
}
const ImportDataGrid: React.FC<ImportDataGridProps> = ({
  tableRows,
  columns,
  selectionModel,
  setSelectionModel,
}) => {
  return (
    <StyledDataGrid
      checkboxSelection
      onSelectionModelChange={(newSelectionModel) => {
        setSelectionModel(newSelectionModel);
      }}
      selectionModel={selectionModel}
      rows={tableRows}
      columns={columns}
      pageSize={20}
      rowsPerPageOptions={[5, 20]}
      getRowId={(row) => row.rowId}
      autoHeight
      disableColumnFilter
      disableColumnMenu
      disableSelectionOnClick
      getRowClassName={(params) => {
        return `JUNO-styled-data-grid-theme--${
          isTableRowValid([params.row] as ImportArrayModel[]) ? 'valid' : 'invalid'
        }`;
      }}
    />
  );
};
export default ImportDataGrid;
