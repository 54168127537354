import React from 'react';
import LockPersonTwoToneIcon from '@mui/icons-material/LockPersonTwoTone';
import { Alert, Stack } from '@mui/material';
import { Container } from '../Container';

const FeatureNotAvailablePage: React.FC = () => {
  return (
    <Container>
      <Stack direction='row' spacing={4} alignItems='center' mt={4}>
        <Alert
          severity='error'
          icon={
            <LockPersonTwoToneIcon
              sx={{
                fontSize: 30,
                background: '#fff',
                borderRadius: '50%',
                height: 50,
                width: 50,
                p: 1,
                mr: 2,
              }}
            />
          }
        >
          This feature is not available with your current package. If you believe this to be an
          error or wish to upgrade, please speak with your sales rep.
        </Alert>
      </Stack>
    </Container>
  );
};

export default FeatureNotAvailablePage;
