import { GridValueFormatterParams } from '@mui/x-data-grid';
import _ from 'lodash';
import {
  v_CourseResources_course_slug,
  v_CourseResources_description,
  v_CourseResources_download,
  v_CourseResources_icon,
  v_CourseResources_title,
  v_Courses_abstract,
  v_Courses_banner_video,
  v_Courses_credits,
  v_Courses_date_end,
  v_Courses_date_lock_enrollment,
  v_Courses_date_open_enrollment,
  v_Courses_date_released,
  v_Courses_date_start,
  v_Courses_enrollment_required,
  v_Courses_icon,
  v_Courses_max_enrollments,
  v_Courses_passing_percent,
  v_Courses_scorm,
  v_Courses_slug,
  v_Courses_tags,
  v_Courses_title,
  v_LessonParts_lesson_slug,
  v_LessonParts_question_type,
  v_LessonParts_rating_increment,
  v_LessonParts_rating_max,
  v_LessonParts_rating_min,
  v_LessonParts_score,
  v_LessonParts_title,
  v_LessonParts_type,
  v_LessonParts_video_required_completion_percent,
  v_LessonParts_video_url,
  v_Lessons_abstract,
  v_Lessons_banner,
  v_Lessons_course_id,
  v_Lessons_include_in_course_grade,
  v_Lessons_passing_percent,
  v_Lessons_slug,
  v_Lessons_title,
  v_QuestionAnswers_answer_text,
  v_QuestionAnswers_is_correct,
  v_QuestionAnswers_question_id,
  v_Questions_name,
  v_Questions_show_voting_results,
  v_Questions_type,
  v_Users_accesspasses,
  v_Users_avatar,
  v_Users_banner,
  v_Users_company,
  v_Users_declaredtags,
  v_Users_email,
  v_Users_firstname,
  v_Users_icon,
  v_Users_lastname,
  v_Users_phone,
  v_Users_pricelevel,
  v_Users_role,
  v_Users_social,
  v_Users_title,
  v__TESTS__context,
  v__TESTS__slug,
  v__TESTS__title,
} from './yupValidations';

export interface KeyProps {
  name: string;
  headerName: string;
  required: boolean;
  width?: number;
  type?: string;
  default?: string | number | boolean;
  valueFormatter?: (params: GridValueFormatterParams) => void;
  validation?: any;
}

interface KeysProps {
  [key: string]: KeyProps[];
}

const formatQuestionType = (params: GridValueFormatterParams) => {
  return params.value === '' || params.value === null || params.value === undefined
    ? params.value
    : String(params.value).toUpperCase();
};

const headerKeys: KeysProps = {
  Courses: [
    {
      validation: v_Courses_title,
      name: 'title',
      headerName: 'Title',
      type: 'course_title',
      required: true,
    },
    {
      validation: v_Courses_slug,
      name: 'slug',
      headerName: 'Slug',
      required: true,
      type: 'course_slug_reverse',
    },
    {
      validation: v_Courses_abstract,
      name: 'abstract',
      headerName: 'Preview Text',
      required: false,
    },
    { name: 'description', headerName: 'Body', required: false },
    {
      validation: v_Courses_credits,
      name: 'credits',
      headerName: 'Credits',
      type: 'number',
      required: false,
      default: 0.0,
    },
    {
      validation: v_Courses_passing_percent,
      name: 'passing_percent',
      headerName: 'Passing Percentage',
      type: 'percent',
      required: false,
      default: 60,
    },
    {
      validation: v_Courses_max_enrollments,
      name: 'max_enrollments',
      headerName: 'Max Capacity',
      type: 'number',
      required: false,
      default: 0,
    },
    {
      validation: v_Courses_enrollment_required,
      name: 'enrollment_required',
      headerName: 'Enrollment Required',
      type: 'checkbox',
      required: false,
    },
    {
      validation: v_Courses_banner_video,
      name: 'banner_video',
      headerName: 'Video URL',
      type: 'url',
      required: false,
    },
    {
      validation: v_Courses_icon,
      name: 'icon',
      headerName: 'Image URL',
      type: 'url',
      required: false,
    },
    {
      validation: v_Courses_date_start,
      name: 'date_start',
      headerName: 'Start Date',
      type: 'datetime-local',
      required: false,
    },
    {
      validation: v_Courses_date_end,
      headerName: 'End Date',
      name: 'date_end',
      type: 'datetime-local',
      required: false,
    },
    {
      validation: v_Courses_date_released,
      headerName: 'Release Date',
      name: 'date_released',
      type: 'datetime-local',
      required: false,
    },
    {
      validation: v_Courses_date_open_enrollment,
      headerName: 'Open Enrollment Date',
      name: 'date_open_enrollment',
      type: 'datetime-local',
      required: false,
    },
    {
      validation: v_Courses_date_lock_enrollment,
      headerName: 'Closed Enrollment Date',
      name: 'date_lock_enrollment',
      type: 'datetime-local',
      required: false,
    },
    {
      validation: v_Courses_tags,
      headerName: 'Tags',
      name: 'tags',
      type: 'autocomplete-tags',
      required: false,
    },
    {
      validation: v_Courses_scorm,
      name: 'scorm_file_path',
      headerName: 'Learning Package URL',
      type: 'url',
      required: false,
    },
  ],
  'Course Resources': [
    {
      name: 'course_slug',
      type: 'autocomplete-course_slug',
      headerName: 'Course Slug',
      required: true,
      validation: v_CourseResources_course_slug,
    },
    {
      name: 'title',
      headerName: 'Title',
      required: true,
      validation: v_CourseResources_title,
    },
    {
      name: 'description',
      headerName: 'Description',
      required: false,
      validation: v_CourseResources_description,
    },
    {
      name: 'icon',
      headerName: 'Image URL',
      type: 'url',
      required: true,
      validation: v_CourseResources_icon,
    },
    {
      name: 'download',
      headerName: 'File URL',
      type: 'url',
      required: true,
      validation: v_CourseResources_download,
    },
  ],
  Lessons: [
    {
      headerName: 'Course Slug',
      name: 'course_id',
      type: 'autocomplete-course_id',
      required: true,
      validation: v_Lessons_course_id,
    },
    { headerName: 'Title', name: 'title', required: true, validation: v_Lessons_title },
    {
      headerName: 'Slug',
      name: 'slug',
      type: 'lesson_slug',
      required: true,
      validation: v_Lessons_slug,
      // valueFormatter: formatSlugObject,
    },
    {
      headerName: 'Preview Text',
      name: 'abstract',
      required: false,
      validation: v_Lessons_abstract,
    },
    {
      headerName: 'Passing Percentage',
      name: 'passing_percent',
      type: 'percent',
      required: false,
      default: 60,
      validation: v_Lessons_passing_percent,
    },
    {
      headerName: 'Graded',
      name: 'include_in_course_grade',
      type: 'checkbox',
      required: false,
      validation: v_Lessons_include_in_course_grade,
    },
    // TODO timed, retakes, retake qty
    {
      headerName: 'Image URL',
      name: 'banner',
      type: 'url',
      required: false,
      validation: v_Lessons_banner,
    },
  ],
  'Lesson Parts': [
    {
      headerName: 'Lesson Slug',
      name: 'lesson_slug',
      type: 'autocomplete-lesson_slug',
      required: true,
      validation: v_LessonParts_lesson_slug,
    },
    {
      headerName: 'Type',
      name: 'type',
      required: true,
      validation: v_LessonParts_type,
    },
    {
      headerName: 'Title',
      name: 'title',
      required: false,
      validation: v_LessonParts_title,
    },
    {
      headerName: 'Question Type',
      name: 'question_type',
      required: false,
      validation: v_LessonParts_question_type,
    },
    {
      headerName: 'Question Description',
      name: 'question_description',
      required: false,
    },
    {
      headerName: 'Question Score',
      name: 'score',
      required: false,
      type: 'number',
      validation: v_LessonParts_score,
    },
    {
      headerName: 'Rating Min',
      name: 'rating_min',
      required: false,
      type: 'number',
      validation: v_LessonParts_rating_min,
    },
    {
      headerName: 'Rating Max',
      name: 'rating_max',
      required: false,
      type: 'number',
      validation: v_LessonParts_rating_max,
    },
    {
      headerName: 'Rating Increment',
      name: 'rating_increment',
      required: false,
      type: 'number',
      validation: v_LessonParts_rating_increment,
    },
    {
      headerName: 'Video URL',
      name: 'video_url',
      type: 'url',
      required: false,
      validation: v_LessonParts_video_url,
    },
    {
      headerName: 'Video Required to Watch',
      name: 'video_required_completion_percent',
      type: 'percent',
      required: false,
      validation: v_LessonParts_video_required_completion_percent,
      default: 80,
    },
    { headerName: 'Blurb Description', name: 'body', required: false },
  ],
  // TODO Questions are no longer imported separately
  Questions: [
    { headerName: 'Question Title', name: 'name', required: true, validation: v_Questions_name },
    {
      headerName: 'Question Body',
      name: 'question',
      type: 'textarea',
      required: true,
    },
    {
      headerName: 'Question Type',
      valueFormatter: formatQuestionType,
      name: 'type',
      type: 'autocomplete-type',
      required: true,
      validation: v_Questions_type,
    },
    {
      headerName: 'Show Voting Results',
      name: 'show_voting_results',
      type: 'checkbox',
      required: false,
      validation: v_Questions_show_voting_results,
    },
  ],
  'Question Answers': [
    {
      headerName: 'Question Title',
      name: 'question_id',
      type: 'autocomplete-question_id',
      required: true,
      validation: v_QuestionAnswers_question_id,
    },
    {
      headerName: 'Question Type',
      name: 'question_type',
      required: false,
    },
    {
      headerName: 'Answer Text',
      name: 'answer_text',
      required: false,
      validation: v_QuestionAnswers_answer_text,
    },
    {
      headerName: 'Multiple Choice Correct',
      name: 'is_correct',
      type: 'checkbox',
      required: false,
      validation: v_QuestionAnswers_is_correct,
    },
  ],
  Users: [
    {
      name: 'email',
      headerName: 'Email',
      type: 'user_email',
      required: true,
      validation: v_Users_email,
    },
    {
      name: 'first_name',
      headerName: 'First Name',
      type: 'first_name',
      required: true,
      validation: v_Users_firstname,
    },
    {
      name: 'last_name',
      headerName: 'Last Name',
      type: 'last_name',
      required: true,
      validation: v_Users_lastname,
    },
    {
      name: 'company',
      headerName: 'Company',
      type: 'company',
      required: false,
      validation: v_Users_company,
    },
    {
      name: 'title',
      headerName: 'Title',
      type: 'title',
      required: false,
      validation: v_Users_title,
    },
    {
      name: 'phone',
      headerName: 'Phone',
      type: 'user_phone',
      required: false,
      validation: v_Users_phone,
    },
    {
      name: 'linkedin',
      headerName: 'Linkedin',
      type: 'url',
      required: false,
      validation: v_Users_social,
    },
    {
      name: 'twitter',
      headerName: 'X / Twitter',
      type: 'url',
      required: false,
      validation: v_Users_social,
    },
    {
      name: 'facebook',
      headerName: 'Facebook',
      type: 'url',
      required: false,
      validation: v_Users_social,
    },
    {
      name: 'instagram',
      headerName: 'Instagram',
      type: 'url',
      required: false,
      validation: v_Users_social,
    },
    {
      name: 'youtube',
      headerName: 'YouTube',
      type: 'url',
      required: false,
      validation: v_Users_social,
    },
    {
      name: 'website',
      headerName: 'Website',
      type: 'url',
      required: false,
      validation: v_Users_social,
    },
    {
      name: 'icon',
      headerName: 'Icon URL',
      type: 'url',
      required: false,
      validation: v_Users_icon,
    },
    {
      name: 'avatar',
      headerName: 'Avatar URL',
      type: 'url',
      required: false,
      validation: v_Users_avatar,
    },
    {
      name: 'banner',
      headerName: 'Banner URL',
      type: 'url',
      required: false,
      validation: v_Users_banner,
    },
    {
      name: 'role',
      headerName: 'Role',
      type: 'number',
      required: false,
      validation: v_Users_role,
    },
    {
      name: 'access_passes',
      headerName: 'Access Passes',
      type: 'autocomplete-access-passes',
      required: false,
      validation: v_Users_accesspasses,
    },
    {
      name: 'price_level',
      headerName: 'Price Level',
      type: 'autocomplete-price-level',
      required: false,
      validation: v_Users_pricelevel,
    },
    {
      name: 'declared_tags',
      headerName: 'Declared Tags',
      type: 'autocomplete-tags',
      required: false,
      validation: v_Users_declaredtags,
    },
  ],
  _TESTS_: [
    { headerName: 'Test Title', name: 'title', required: true },
    { headerName: 'Test Slug', name: 'slug', required: true },
    { headerName: 'Test Default', name: 'test_default', required: false, default: 0 },
  ],
  _VALIDATION_TESTS_: [
    { headerName: 'Test Title', name: 'title', required: true, validation: v__TESTS__title },
    { headerName: 'Test Slug', name: 'slug', required: true, validation: v__TESTS__slug },
  ],
  _CONTEXTUAL_TESTS_: [
    { headerName: 'Test Title', name: 'title', required: true, validation: v__TESTS__title },
    { headerName: 'Test Slug', name: 'slug', required: true, validation: v__TESTS__slug },
    { headerName: 'Test Context', name: 'context', required: false, validation: v__TESTS__context },
  ],
  _RELATIONAL_TESTS_: [
    { headerName: 'Test Title', name: 'title', required: true },
    { headerName: 'Test Slug', name: 'slug', required: true },
    { headerName: 'Test Default', name: 'test_default', required: false, default: 0 },
    {
      headerName: 'Test Relational',
      name: 'test_relational',
      type: 'autocomplete-test_relational',
      required: false,
    },
  ],
};

/**
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 * @param row object of type T
 * @param bucket string
 * @returns [] or [{key: string, headerName: string}]
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */
// compareRequiredKeys should take a row of type T and a string bucket name and return an array of items that are missing required keys in the associated bucket
const compareRequiredKeys = <T>(row: T, bucket: string) => {
  if (!row) {
    return [];
  }
  const requiredKeys: string[] = headerKeys[bucket]
    .filter((key) => key.required)
    .map((key) => key.name);
  const headers = Object.keys(row);
  const missingKeys = _.differenceWith(requiredKeys, headers, _.isEqual);
  return headerKeys[bucket]
    .filter((key) => missingKeys.includes(key.name))
    .map((key) => {
      return { key: key.name, headerName: key.headerName };
    });
};

/**
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 * @param row object of type T
 * @param bucket string
 * @returns [] or [{key: string, headerName: string}]
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */
// compareExtraKeys should take a row of type T and a string bucket name and return an array of items in the row that do not have keys in the associated bucket
const compareExtraKeys = <T>(row: T, bucket: string) => {
  if (!row) {
    return [];
  }
  const headers = Object.keys(row);
  const extraKeys = _.differenceWith(
    headers,
    headerKeys[bucket].map((key) => key.name),
    _.isEqual,
  );
  if (extraKeys.length > 0) {
    return extraKeys.map((key) => {
      return { key: key, headerName: key };
    });
  } else {
    return [];
  }
};

export { headerKeys, compareRequiredKeys, compareExtraKeys };
