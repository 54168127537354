import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useDebounce } from 'usehooks-ts';
import { calculateUserSearchFilters } from '../SearchUtils';

export const useSearchFilter = () => {
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 300);

  const searchFilter = useMemo(() => {
    if (!debouncedSearch) return {};
    return calculateUserSearchFilters(debouncedSearch);
  }, [debouncedSearch]);

  const returnValues: [
    string | null,
    string | null,
    {
      filter_and_or?: { [key: string]: unknown };
      filter_or?: { [key: string]: unknown };
      filter?: { [key: string]: unknown };
    },
    Dispatch<SetStateAction<string | null>>,
  ] = [debouncedSearch, search, searchFilter, setSearch];

  return returnValues;
};

export const useFilesSearchFilter = () => {
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 300);

  const searchFilter = useMemo(() => {
    if (!debouncedSearch) return {};
    return {
      filter_or: {
        download__title__icontains: debouncedSearch,
        download__description__icontains: debouncedSearch,
      },
    };
  }, [debouncedSearch]);

  const returnValues: [
    string | null,
    string | null,
    {
      filter_and_or?: { [key: string]: unknown };
      filter_or?: { [key: string]: unknown };
      filter?: { [key: string]: unknown };
    },
    Dispatch<SetStateAction<string | null>>,
  ] = [debouncedSearch, search, searchFilter, setSearch];

  return returnValues;
};

export const useForumTopicsSearchFilter = () => {
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 300);

  const searchFilter = useMemo(() => {
    if (!debouncedSearch) return {};
    return {
      title__icontains: debouncedSearch,
      description__icontains: debouncedSearch,
    };
  }, [debouncedSearch]);

  const returnValues: [
    string | null,
    string | null,
    {
      [key: string]: unknown;
    },
    Dispatch<SetStateAction<string | null>>,
  ] = [debouncedSearch, search, searchFilter, setSearch];

  return returnValues;
};

export const usePagesSearchFilter = () => {
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 300);

  const searchFilter = useMemo(() => {
    if (!debouncedSearch) return {};
    return {
      title__icontains: debouncedSearch,
      slug__icontains: debouncedSearch,
    };
  }, [debouncedSearch]);

  const returnValues: [
    string | null,
    string | null,
    {
      [key: string]: unknown;
    },
    Dispatch<SetStateAction<string | null>>,
  ] = [debouncedSearch, search, searchFilter, setSearch];

  return returnValues;
};

export const useFeedPostSearchFilter = () => {
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 300);

  const searchFilter = useMemo(() => {
    if (!debouncedSearch) return {};
    const userFilters = calculateUserSearchFilters(debouncedSearch, 'author__');
    return {
      filter_or: {
        body__icontains: debouncedSearch,
        ...userFilters.filter_or,
        ...userFilters.filter_and_or,
      },
    };
  }, [debouncedSearch]);

  const returnValues: [
    string | null,
    string | null,
    {
      filter_and_or?: { [key: string]: unknown };
      filter_or?: { [key: string]: unknown };
      filter?: { [key: string]: unknown };
    },
    Dispatch<SetStateAction<string | null>>,
  ] = [debouncedSearch, search, searchFilter, setSearch];

  return returnValues;
};

export const useGroupsSearchFilter = () => {
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 300);

  const searchFilter = useMemo(() => {
    if (!debouncedSearch) return {};
    return {
      title__icontains: debouncedSearch,
      body__icontains: debouncedSearch,
    };
  }, [debouncedSearch]);

  const returnValues: [
    string | null,
    string | null,
    {
      [key: string]: unknown;
    },
    Dispatch<SetStateAction<string | null>>,
  ] = [debouncedSearch, search, searchFilter, setSearch];

  return returnValues;
};

export const useSessionsSearchFilter = () => {
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 300);

  const searchFilter = useMemo(() => {
    if (!debouncedSearch) return {};
    return {
      title__icontains: debouncedSearch,
      slug__icontains: debouncedSearch,
      description__icontains: debouncedSearch,
      preview_text__icontains: debouncedSearch,
    };
  }, [debouncedSearch]);

  const returnValues: [
    string | null,
    string | null,
    {
      [key: string]: unknown;
    },
    Dispatch<SetStateAction<string | null>>,
  ] = [debouncedSearch, search, searchFilter, setSearch];

  return returnValues;
};

export const useContentReportsSearchFilter = () => {
  const [search, setSearch] = useState<string | null>(null);
  const debouncedSearch = useDebounce(search, 300);

  const searchFilter = useMemo(() => {
    if (!debouncedSearch) return {};
    const reportingUserSearchFilters = calculateUserSearchFilters(
      debouncedSearch,
      'reporting_user__',
    );
    const reportedUserSearchFilters = calculateUserSearchFilters(
      debouncedSearch,
      'reported_user__',
    );

    return {
      reported_user__first_name__icontains: debouncedSearch,
      reported_user__last_name__icontains: debouncedSearch,
      reporting_user__first_name__icontains: debouncedSearch,
      reporting_user__last_name__icontains: debouncedSearch,
      reason__icontains: debouncedSearch,
      description__icontains: debouncedSearch,
    };
  }, [debouncedSearch]);

  const returnValues: [
    string | null,
    string | null,
    {
      [key: string]: unknown;
    },
    Dispatch<SetStateAction<string | null>>,
  ] = [debouncedSearch, search, searchFilter, setSearch];

  return returnValues;
};
