import React from 'react';
import { RecentActors, RecentActorsOutlined } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import ArrosBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import AutoAwesomeMosaicOutlinedIcon from '@mui/icons-material/AutoAwesomeMosaicOutlined';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HistoryIcon from '@mui/icons-material/History';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlined from '@mui/icons-material/HomeOutlined';
import LocalLibrary from '@mui/icons-material/LocalLibrary';
import LocalLibraryOutlined from '@mui/icons-material/LocalLibraryOutlined';
import MessageIcon from '@mui/icons-material/Message';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import OnDemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';
import SchoolIcon from '@mui/icons-material/School';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SpeakerGroupIcon from '@mui/icons-material/SpeakerGroup';
import SpeakerGroupOutlinedIcon from '@mui/icons-material/SpeakerGroupOutlined';
import StoreFrontIcon from '@mui/icons-material/Storefront';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import { Box, Card, Grid, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Component, SearchContentTypeEnum } from '@juno/client-api/model';
import { getContentRoute, useSettings } from '@juno/utils';

const ICONS = [
  'home',
  'home_outlined',
  'account_circle',
  'account_circle_outlined',
  'people',
  'people_outlined',
  'person',
  'person_add',
  'local_library',
  'local_library_outlined',
  'school',
  'school_outlined',
  'assessment',
  'assessment_outlined',
  'arrow_back_ios',
  'arrow_forward_ios',
  'arrow_downward',
  'arrow_upward',
  'auto_awesome_mosaic',
  'auto_awesome_mosaic_outlined',
  'groups',
  'groups_outlined',
  'speaker_group',
  'speaker_group_outlined',
  'bookmarks',
  'bookmarks_outlined',
  'build_circle',
  'build_circle_outlined',
  'on_demand_video',
  'personal_video',
  'email',
  'email_outlined',
  'history',
  'watch_later',
  'watch_later_outlined',
  'message',
  'message_outlined',
  'delete',
  'edit',
  'storefront',
  'shopping_cart',
  'shopping_cart_outlined',
  'directory',
  'directory_outlined',
];

interface IconPickerProps {
  onSelectIcon: (icon: string) => void;
}

const IconPicker: React.FC<IconPickerProps> = ({ onSelectIcon }) => {
  return (
    <Card sx={{ boxShadow: 1 }}>
      <Stack direction='row' flexWrap={'wrap'}>
        {ICONS.map((icon) => (
          <Tooltip
            key={icon}
            title={icon.split('_').join(' ')}
            componentsProps={{
              tooltip: {
                sx: {
                  textTransform: 'capitalize',
                },
              },
            }}
          >
            <Box
              height={40}
              width={40}
              display={'flex'}
              justifyContent='center'
              alignItems='center'
              sx={{ cursor: 'pointer', '&:hover': { background: 'rgba(0,0,0,0.15)' } }}
              onClick={() => onSelectIcon(icon)}
            >
              <JunoIcon name={icon} />
            </Box>
          </Tooltip>
        ))}
      </Stack>
    </Card>
  );
};

export default IconPicker;

interface IconProps {
  name: string;
  size?: number;
  sx?: SxProps;
}

export const JunoIcon: React.FC<IconProps> = ({ name, size = 24, sx = {} }) => {
  switch (name) {
    case 'home':
      return <HomeIcon sx={{ fontSize: size, ...sx }} />;
    case 'account_circle':
      return <AccountCircleIcon sx={{ fontSize: size, ...sx }} />;
    case 'people':
      return <PeopleIcon sx={{ fontSize: size, ...sx }} />;
    case 'person':
      return <PersonIcon sx={{ fontSize: size, ...sx }} />;
    case 'person_add':
      return <PersonAddIcon sx={{ fontSize: size, ...sx }} />;
    case 'home_outlined':
      return <HomeOutlined sx={{ fontSize: size, ...sx }} />;
    case 'people_outlined':
      return <PeopleOutlinedIcon sx={{ fontSize: size, ...sx }} />;
    case 'account_circle_outlined':
      return <AccountCircleOutlined sx={{ fontSize: size, ...sx }} />;
    case 'local_library':
      return <LocalLibrary sx={{ fontSize: size, ...sx }} />;
    case 'local_library_outlined':
      return <LocalLibraryOutlined sx={{ fontSize: size, ...sx }} />;
    case 'school':
      return <SchoolIcon sx={{ fontSize: size, ...sx }} />;
    case 'school_outlined':
      return <SchoolOutlinedIcon sx={{ fontSize: size, ...sx }} />;
    case 'assessment':
      return <AssessmentIcon sx={{ fontSize: size, ...sx }} />;
    case 'assessment_outlined':
      return <AssessmentOutlinedIcon sx={{ fontSize: size, ...sx }} />;
    case 'arrow_back_ios':
      return <ArrosBackIosIcon sx={{ fontSize: size, ...sx }} />;
    case 'arrow_forward_ios':
      return <ArrowForwardIosIcon sx={{ fontSize: size, ...sx }} />;
    case 'arrow_downward':
      return <ArrowDownward sx={{ fontSize: size, ...sx }} />;
    case 'arrow_upward':
      return <ArrowUpward sx={{ fontSize: size, ...sx }} />;
    case 'auto_awesome_mosaic':
      return <AutoAwesomeMosaicIcon sx={{ fontSize: size, ...sx }} />;
    case 'auto_awesome_mosaic_outlined':
      return <AutoAwesomeMosaicOutlinedIcon sx={{ fontSize: size, ...sx }} />;
    case 'groups':
      return <GroupsIcon sx={{ fontSize: size, ...sx }} />;
    case 'groups_outlined':
      return <GroupsOutlinedIcon sx={{ fontSize: size, ...sx }} />;
    case 'speaker_group':
      return <SpeakerGroupIcon sx={{ fontSize: size, ...sx }} />;
    case 'speaker_group_outlined':
      return <SpeakerGroupOutlinedIcon sx={{ fontSize: size, ...sx }} />;
    case 'bookmarks':
      return <BookmarksIcon sx={{ fontSize: size, ...sx }} />;
    case 'bookmarks_outlined':
      return <BookmarksOutlinedIcon sx={{ fontSize: size, ...sx }} />;
    case 'build_circle':
      return <BuildCircleIcon sx={{ fontSize: size, ...sx }} />;
    case 'build_circle_outlined':
      return <BuildCircleOutlinedIcon sx={{ fontSize: size, ...sx }} />;
    case 'on_demand_video':
      return <OnDemandVideoIcon sx={{ fontSize: size, ...sx }} />;
    case 'personal_video':
      return <PersonalVideoIcon sx={{ fontSize: size, ...sx }} />;
    case 'email':
      return <EmailIcon sx={{ fontSize: size, ...sx }} />;
    case 'email_outlined':
      return <EmailOutlinedIcon sx={{ fontSize: size, ...sx }} />;
    case 'history':
      return <HistoryIcon sx={{ fontSize: size, ...sx }} />;
    case 'watch_later':
      return <WatchLaterIcon sx={{ fontSize: size, ...sx }} />;
    case 'watch_later_outlined':
      return <WatchLaterOutlinedIcon sx={{ fontSize: size, ...sx }} />;
    case 'message':
      return <MessageIcon sx={{ fontSize: size, ...sx }} />;
    case 'message_outlined':
      return <MessageOutlinedIcon sx={{ fontSize: size, ...sx }} />;
    case 'delete':
      return <DeleteIcon sx={{ fontSize: size, ...sx }} />;
    case 'edit':
      return <EditIcon sx={{ fontSize: size, ...sx }} />;
    case 'storefront':
      return <StoreFrontIcon sx={{ fontSize: size, ...sx }} />;
    case 'shopping_cart':
      return <ShoppingCartIcon sx={{ fontSize: size, ...sx }} />;
    case 'shopping_cart_outlined':
      return <ShoppingCartOutlinedIcon sx={{ fontSize: size, ...sx }} />;
    case 'directory':
      return <RecentActors sx={{ fontSize: size, ...sx }} />;
    case 'directory_outlined':
      return <RecentActorsOutlined sx={{ fontSize: size, ...sx }} />;
  }
  return <></>;
};
