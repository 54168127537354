/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type DayOfWeekEnum = typeof DayOfWeekEnum[keyof typeof DayOfWeekEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DayOfWeekEnum = {
  Sunday: 'Sunday',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Null: 'Null',
} as const;
