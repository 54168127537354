import { useEffect, useMemo } from 'react';
import { debounce } from 'debounce';
import { useSound } from './useSound';

/**
 * Convenience hook to play `join.mp3` when participants join the call
 */
export const useMessageSound = () => {
  // TODO this sound doesn't exist
  const { load, play } = useSound('assets/message.mp3');

  useEffect(() => {
    load();
  }, [load]);

  return useMemo(() => debounce(() => play(), 5000, true), [play]);
};

export default useMessageSound;
