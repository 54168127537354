import CloseIcon from '@mui/icons-material/Close';
import ListIcon from '@mui/icons-material/List';
import { Box, Button, List, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const WidgetBox = styled(List, { shouldForwardProp: (prop) => prop !== 'isSelected' })<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.divider}`,
  padding: isSelected ? theme.spacing(4) : theme.spacing(1.5),
  display: 'flex',
  flexDirection: isSelected ? 'column' : 'row',
  justifyContent: 'flex-start',
  position: 'relative',
  boxShadow: isSelected ? theme.shadows[6] : 'none',
  maxWidth: '100%',
  transition: '300ms box-shadow',
  overflow: 'hidden',
  height: '100%',
  '&:hover': {
    boxShadow: theme.shadows[6],
  },
}));

export const IconBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'isSelected' })<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  borderRadius: 4,
  border: `1px solid ${theme.palette.divider}`,
  padding: isSelected ? theme.spacing(4) : theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  boxShadow: isSelected ? theme.shadows[6] : 'none',
  maxWidth: 40,
  overflow: 'hidden',
  height: '100%',
  alignItems: 'center',
  marginRight: 12,
}));

export const WidgetTitleBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'isSelected' })<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  display: isSelected ? 'flex' : undefined,
  flexDirection: 'row',
}));

export const WidgetListIcon = styled(ListIcon)({
  position: 'absolute',
  top: 12,
  right: 12,
  width: 24,
  height: 24,
  svg: {},
  opacity: 0.5,
});

export const WidgetCount = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  fontWeight: isSelected ? 'bold' : undefined,
  display: 'inline',
  marginRight: 8,
  fontSize: '1.6rem',
}));

export const WidgetTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  fontSize: isSelected ? '1.6rem' : '.8rem',
  marginBottom: theme.spacing(1),
  fontWeight: isSelected ? 'bold' : undefined,
}));

export const WidgetSubtitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  display: isSelected ? 'flex' : 'none',
  marginBottom: theme.spacing(2),
  opacity: 0.5,
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.error.main,
  alignItems: 'center',
  justifyContent: 'center',
  height: 140,
}));

export const BreakdownTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  marginLeft: 10,
  marginRight: 20,
  opacity: 0.5,
}));

export const BreakdownCount = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  fontWeight: 'bold',
  display: 'inline',
  marginRight: '20px',
  fontSize: '1.6rem',
  alignSelf: 'right',
}));

export const OpenButton = styled(Button)({
  position: 'relative',
  width: 40,
  height: 32,
  maxWidth: 50,
  minWidth: 0,
  svg: {},
});

export const ChartCloseIcon = styled(CloseIcon)(({ theme }) => ({
  position: 'absolute',
  top: -45,
  right: -45,
  transition: '0ms opacity',
  color: '#fff',
  fontSize: '2.5rem',
  cursor: 'pointer',
  padding: 5,
}));

export const BreakdownListItem = styled(Box)({
  display: 'flex',
  backgroundColor: '#00000010',
  alignItems: 'center',
  padding: '20px',
  cursor: 'pointer',
  justifyContent: 'space-between',
  marginBottom: '16px',
});
