import { useEffect, useMemo, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getGetEmailCampaignQueryKey,
  useDeleteEmailCampaign,
  useGetEmailCampaign,
  useUpdateEmailCampaign,
} from '@juno/client-api';
import {
  EmailCampaign as EmailCampaignModel,
  Site as SiteModel,
  TypeBd0Enum as campaignType,
} from '@juno/client-api/model';
import { JunoBackLink } from '@juno/ui';
import { MutationAction, notification, onMutation, useSettings } from '@juno/utils';
import DeleteCampaignDialog from '../Dialogs/DeleteCampaignDialog';
import { getChangedValues, metadataIsChanged } from '../utils/utils';
import {
  CampaignRecipientSectionFormik,
  CampaignRecipientSectionFormikValues,
  CampaignScheduleSectionFormik,
  CampaignScheduleSectionFormikValues,
  NewCampaignSchema,
  NewCampaignSectionFormik,
  NewCampaignSectionFormikValues,
  ScheduleSchema,
  SidebarSectionFormik,
  SidebarSectionFormikValues,
} from '../utils/validationSchema';
import CampaignRecipientsSection from './sections/CampaignRecipientSection';
import CampaignScheduleSection from './sections/CampaignScheduleSection';
import CampaignSidebar from './sections/CampaignSidebarSection';
import NewCampaignSection from './sections/NewCampaignSection';

export const drawerWidth = 360;

const CampaignBuilder: React.FC = () => {
  // Variables
  const { site } = useSettings();
  const { id: campaignId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const isWidget = location.pathname.includes('vnext-admin');
  const backPath = isWidget ? location.pathname.match(/.*emailer/)?.[0] : undefined;
  const { UPDATE, DELETE } = MutationAction;
  const refetchCampaign = () => {
    queryClient.invalidateQueries(getGetEmailCampaignQueryKey(site?.id || '', campaignId || ''));
  };
  const [openDelete, setOpenDelete] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [emailCampaignRecipientCount, setEmailCampaignRecipientCount] = useState<number>(0);

  // Queries and mutations
  const { data: emailCampaign, isLoading: campaignLoading } = useGetEmailCampaign(
    site?.id || '',
    campaignId || '',
    { query: { enabled: !!site?.id && !!campaignId } },
  );
  const campaignUpdate = useUpdateEmailCampaign(
    onMutation(UPDATE, 'EmailCampaign', refetchCampaign),
  );
  const campaignDelete = useDeleteEmailCampaign(
    onMutation(DELETE, 'EmailCampaign', refetchCampaign),
  );
  // const [isActivated, setIsActivated] = useState<boolean>(emailCampaign?.is_active || false);
  const isActivated = useMemo(() => {
    return emailCampaign?.is_active || false;
  }, [emailCampaign]);
  const [templateIsSaved, setTemplateIsSaved] = useState<boolean>(
    Boolean(emailCampaign?.template) || false,
  );

  const [newCampaignSectionValues, setNewCampaignSectionValues] =
    useState<NewCampaignSectionFormikValues>(NewCampaignSectionFormik(emailCampaign));
  const [scheduleSectionValues, setScheduleSectionValues] =
    useState<CampaignScheduleSectionFormikValues>(CampaignScheduleSectionFormik(emailCampaign));
  const [recipientSectionValues, setRecipientSectionValues] =
    useState<CampaignRecipientSectionFormikValues>(CampaignRecipientSectionFormik(emailCampaign));
  const [sidebarSectionValues, setSidebarSectionValues] = useState<SidebarSectionFormikValues>(
    SidebarSectionFormik(emailCampaign),
  );

  // Functions
  const handlePut = async (campaign: EmailCampaignModel): Promise<void> => {
    if (campaign.id) {
      // PUT endpoint gets sad if the EmailCampaign template field is null, so clip it
      if (!campaign.template) {
        delete campaign.template;
      }
      return campaignUpdate
        .mutateAsync({
          siteId: site?.id || '',
          emailCampaignId: campaign.id,
          data: campaign,
        })
        .then((res: any) => {
          refetchCampaign();
        });
    } else {
      // Shouldn't see this except maybe during loading, but then we should just do nothing.
      console.error('An error occurred trying to save the Email Campaign');
    }
  };

  const handleDeleteCampaign = () => {
    if (!campaignId) {
      return;
    }
    campaignDelete
      .mutateAsync({ siteId: site?.id || '', emailCampaignId: campaignId })
      .catch((err) => notification.error(err.message))
      .finally(() => navigate(-1));
  };

  const handleAllSubmit = (): Promise<void> => {
    const values = {
      ...sidebarSectionValues,
      ...scheduleSectionValues,
      ...recipientSectionValues,
      ...newCampaignSectionValues,
    };

    return handlePut(values).then(() => {
      setHasChanged(false);
    });
  };

  // Once the EmailCampaign loads, update the values
  useEffect(() => {
    if (emailCampaign) {
      setNewCampaignSectionValues(NewCampaignSectionFormik(emailCampaign));
      setScheduleSectionValues(CampaignScheduleSectionFormik(emailCampaign));
      setRecipientSectionValues(CampaignRecipientSectionFormik(emailCampaign));
      setSidebarSectionValues(SidebarSectionFormik(emailCampaign));
    }
  }, [emailCampaign]);

  // Tell the base CampaignBuilder page about changes made to individual sections
  useEffect(() => {
    if (emailCampaign) {
      const changed =
        Object.keys(
          getChangedValues(newCampaignSectionValues, NewCampaignSectionFormik(emailCampaign)),
        ).length > 0 ||
        Object.keys(
          getChangedValues(scheduleSectionValues, CampaignScheduleSectionFormik(emailCampaign)),
        ).length > 0 ||
        metadataIsChanged(recipientSectionValues, CampaignRecipientSectionFormik(emailCampaign));

      if (changed) {
        setHasChanged(true);
      } else {
        setHasChanged(false);
      }
    }
  }, [newCampaignSectionValues, scheduleSectionValues, recipientSectionValues, emailCampaign]);

  // If the Email Campaign Recipient head count has been updated, save the results in the campaign metadata
  useEffect(() => {
    if (emailCampaignRecipientCount) {
      setRecipientSectionValues({
        ...recipientSectionValues,
        metadata: {
          ...recipientSectionValues.metadata,
          recipient_count: emailCampaignRecipientCount,
        },
      });
    }
  }, [emailCampaignRecipientCount]);

  useEffect(() => {
    if (emailCampaign?.template) {
      setTemplateIsSaved(true);
    }
  }, [emailCampaign?.template]);

  if (campaignLoading)
    return (
      <Box sx={{ mt: '150px' }}>
        <Grid
          container
          spacing={2}
          sx={{
            border: '1px dashed',
            borderRadius: '10px',
            p: 3,
            width: `calc(100% - ${drawerWidth}px)`,
          }}
        >
          <Typography variant='h6' sx={{ mb: 2 }}>
            NEW CAMPAIGN
          </Typography>
          <Grid item xs={12} mb={2}>
            <Skeleton variant='rectangular' height={60} width={'100%'}></Skeleton>
          </Grid>
          <Grid item xs={6} mb={2}>
            <Skeleton variant='rectangular' height={60} width={'350px'}></Skeleton>
          </Grid>
          <Grid item xs={6} mb={2}>
            <Skeleton variant='rectangular' height={60} width={'350px'}></Skeleton>
          </Grid>
          <Grid item xs={4} mb={2}>
            <Skeleton variant='rectangular' height={60} width={'225px'}></Skeleton>
          </Grid>
          <Grid item xs={4} mb={2}>
            <Skeleton variant='rectangular' height={60} width={'225px'}></Skeleton>
          </Grid>
          <Grid item xs={4} mb={2}>
            <Skeleton variant='rectangular' height={60} width={'230px'}></Skeleton>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          mt={3}
          sx={{
            border: '1px dashed',
            borderRadius: '10px',
            p: 3,
            width: `calc(100% - ${drawerWidth}px)`,
          }}
        >
          <Typography variant='h6'>CAMPAIGN SCHEDULE</Typography>
          <Grid item xs={12}></Grid>
          <Grid item xs={4}>
            <Skeleton variant='rectangular' height={60} width={'230px'}></Skeleton>
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant='rectangular' height={60} width={'230px'}></Skeleton>
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant='rectangular' height={60} width={'230px'}></Skeleton>
          </Grid>
        </Grid>
        <Skeleton
          variant='rectangular'
          height={50}
          width={'250px'}
          sx={{ mt: 3, borderRadius: '10px' }}
        ></Skeleton>
        <Skeleton
          variant='rectangular'
          animation='wave'
          sx={{
            width: 350,
            height: '100vh',
            position: 'fixed',
            right: 0,
            top: 50,
          }}
        ></Skeleton>
      </Box>
    );

  return (
    <Box sx={{ width: `calc(100% - ${drawerWidth}px)`, p: 2 }}>
      <Box>
        <Stack
          direction={'row'}
          justifyContent='space-between'
          alignItems={'center'}
          sx={{ mb: 2 }}
        >
          <JunoBackLink text='Back to Campaign List' hasChanged={hasChanged} backPath={backPath} />
          <Button
            aria-label='Save'
            variant='contained'
            disabled={isActivated}
            color='secondary'
            onClick={() => {
              handleAllSubmit().catch((err) => notification.error(err.message));
            }}
          >
            Save Changes
          </Button>
        </Stack>
      </Box>
      <Formik
        enableReinitialize={true}
        validationSchema={NewCampaignSchema}
        initialValues={NewCampaignSectionFormik(emailCampaign)}
        onSubmit={() => {}}
        validateOnChange={true}
      >
        {(formik) => (
          <NewCampaignSection
            updateNewCampaignSectionValues={setNewCampaignSectionValues}
            formik={formik}
            isActive={isActivated}
          />
        )}
      </Formik>
      <Formik
        enableReinitialize={true}
        initialValues={CampaignScheduleSectionFormik(emailCampaign)}
        validationSchema={ScheduleSchema}
        onSubmit={() => {}}
      >
        {(formik) => (
          <CampaignScheduleSection
            formik={formik}
            updateCampaignScheduleFormikValues={setScheduleSectionValues}
            isActive={isActivated}
            newCampaignSectionValues={newCampaignSectionValues}
          />
        )}
      </Formik>
      {emailCampaign && newCampaignSectionValues.type === campaignType.CUSTOM && (
        <Formik
          enableReinitialize={true}
          initialValues={CampaignRecipientSectionFormik(emailCampaign)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <CampaignRecipientsSection
              formik={formik}
              setRecipientSectionValues={setRecipientSectionValues}
              isActive={isActivated}
              emailCampaignName={emailCampaign?.name || 'CUSTOM campaign'}
              headCount={emailCampaignRecipientCount}
              setHeadCount={setEmailCampaignRecipientCount}
            />
          )}
        </Formik>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={SidebarSectionFormik(emailCampaign)}
        onSubmit={() => {}}
      >
        {(formik) => (
          <CampaignSidebar
            formik={formik}
            setSidebarSectionFormikValues={setSidebarSectionValues}
            newCampaignSectionValues={newCampaignSectionValues}
            campaignScheduleSectionValues={scheduleSectionValues}
            campaignRecipientsSectionValues={recipientSectionValues}
            emailCampaignTypeChanged={newCampaignSectionValues.type}
            handleSubmit={handleAllSubmit}
            headCount={emailCampaignRecipientCount}
            templateIsSaved={templateIsSaved}
          />
        )}
      </Formik>
      <Box>
        <Button
          aria-label='Delete'
          variant='contained'
          disabled={isActivated}
          color='error'
          sx={{ mt: 3, mb: 3, letterSpacing: '3px', p: '12px 16px' }}
          startIcon={<DeleteForeverIcon />}
          onClick={() => {
            setOpenDelete((old) => !old);
          }}
        >
          Delete Campaign
        </Button>
      </Box>
      {openDelete && (
        <DeleteCampaignDialog
          handleClose={() => {
            setOpenDelete(false);
          }}
          handleDeleteCampaign={() => {
            setOpenDelete(false);
            handleDeleteCampaign();
          }}
        />
      )}
    </Box>
  );
};

export default CampaignBuilder;
