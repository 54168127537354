import React, { createContext, useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Skeleton,
  Typography,
} from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  useCreateFeedThread,
  useGetAllTags,
  useGetFeedThreads,
  useGetSiteFeed,
  useGetThread,
} from '@juno/client-api';
import { useGetUserRoleMap } from '@juno/client-api/helpers';
import { CommunityUser, FeatureConfigTypeEnum, Feed, Tag, Thread } from '@juno/client-api/model';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';
import { Container, DefaultSearchSortFilter, DialogAriaWrapper } from '@juno/ui';
import {
  MutationAction,
  onMutation,
  useFeedPostSearchFilter,
  usePagination,
  useSearchFilter,
  useSettings,
} from '@juno/utils';
import { useAnalyticsContext } from '../Contexts/AnalyticsContext';
import ThreadPost from '../Thread';
import SinglePostView from '../Thread/SinglePostView';
import PostDetailDialog from '../Thread/ThreadPost/PostDetailDialog/index';
import CreateNewPostDialog from './CreateNewPostDialog';

interface FeedComponentProps {
  siteId: string;
  feed: Feed;
  showTitle?: boolean;
  onNavigate?: (props: any) => void;
  userCanInteract?: boolean;
  iAmAdmin?: boolean;
  blockUserInteractionMessage?: string;
  isInForum?: boolean;
}

enum FeedSort {
  Newest = 'newest',
  Likes = 'likes',
}
interface FeedContextProps {
  tags: Tag[];
  iAmAdmin: boolean;
}
export const FeedContext = createContext<FeedContextProps | null>(null);

const PAGE_SIZE = 15;
export const TagsContext = createContext<Tag[]>([]);

const FeedComponent: React.FC<FeedComponentProps> = ({
  siteId,
  feed,
  showTitle = true,
  onNavigate,
  userCanInteract = true,
  iAmAdmin = false,
  blockUserInteractionMessage = 'You do not have permission to interact with this feed.',
  isInForum = false,
}) => {
  const [currentQueryParameters, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(FeedSort.Newest);
  const [showNewDialog, setShowNewDialog] = useState(false);
  const [showPostDetails, setShowPostDetails] = useState(false);
  const [selectedPost, setSelectedPost] = useState<Thread>({} as Thread);
  const [debouncedSearch, search, filter, setSearch] = useSearchFilter();
  const [debouncedPostSearch, postSearch, postFilter, setPostSearch] = useFeedPostSearchFilter();
  const { getImageSrc, configs, site, isClient } = useSettings();
  const platformId = site?.platform_id || '';
  const [tagFilter, setTagFilter] = useState<Tag[]>([]);
  const [showFilters, setShowFilters] = useState(false);
  const [onlyMyThreadFilter, setOnlyMyThreadFilter] = useState(false);
  const { firehoseActivity } = useAnalyticsContext();
  const forumFeedTagTypes = configs?.find(
    (config) => config.type === FeatureConfigTypeEnum.community,
  );

  const { data: tags } = useGetAllTags(siteId, {
    filter: {
      type__in: forumFeedTagTypes?.config?.filter_tag_types,
    },
  });

  const filterWithTags = { ...filter };

  if (tagFilter.length > 0) {
    if (filterWithTags.filter) {
      filterWithTags.filter = {
        ...filterWithTags.filter,
        tags__in: tagFilter.map((tag) => tag.id),
      };
    } else {
      filterWithTags.filter = {
        tags__in: tagFilter.map((tag) => tag.id),
      };
    }
  }
  const { user } = useGetUserRoleMap(siteId);

  const threadIdParam = currentQueryParameters.get('thread_id');
  const parentThreadIdParam = currentQueryParameters.get('parent_thread_id');
  const threadToGetId = parentThreadIdParam !== 'None' ? parentThreadIdParam : threadIdParam;

  const { data: singleThread, refetch: refetchSingleThread } = useGetThread(
    siteId,
    threadToGetId || '',
    {
      query: { enabled: threadToGetId !== null },
    },
  );
  const [showSingleThread, setShowSingleThread] = useState(threadToGetId !== null);

  const {
    data: feedThreads,
    isLoading: isLoadingThreads,
    isFetching: isFetchingThreads,
    refetch: refetchFeedThreads,
  } = useGetFeedThreads(
    siteId,
    feed.id,
    {
      ...filterWithTags,
      ...postFilter,
      filter_and: onlyMyThreadFilter ? { author_id: user?.id } : undefined,
      limit: PAGE_SIZE,
      offset: page,
      order: sort === 'newest' ? '-pinned,-date_created' : '-pinned,-num_likes',
    },
    { query: { enabled: !!feed.id && !showSingleThread && threadIdParam === null } },
  );

  const [data, setData, ref, isLoading] = usePagination(
    feedThreads,
    isLoadingThreads,
    isFetchingThreads,
    postSearch,
    debouncedPostSearch,
    setPage,
    true,
  );

  const currentCommunityUser: CommunityUser = {
    id: user?.id || '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    email: user?.email || '',
    icon: user?.icon,
    avatar: user?.avatar,
  };

  const createFeedThread = useCreateFeedThread(onMutation(MutationAction.CREATE, ''));

  const handleSubmitNewPost = async (post: Thread) => {
    const pageSlug = location.pathname.split('/').pop() || '';
    const newThread = await createFeedThread.mutateAsync({
      siteId,
      feedId: feed?.id || '',
      data: {
        ...post,
        author_id: currentCommunityUser.id,
        metadata: {
          page_slug: pageSlug,
        },
      },
    });
    firehoseActivity(
      ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_COMMUNITY,
      feed?.id || '',
      null,
      `${user?.id || ''}`,
      isInForum
        ? ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_FORUM
        : ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_FEED,
      ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_POST.value,
      newThread?.id || '',
      null,
    );
    setData((old) => [newThread, ...old]);
    setShowNewDialog(false);
  };

  const handleTagFilter = (tag: Tag) => {
    setData([]);
    setPage(0);

    if (tagFilter.find((t) => t.id === tag.id)) {
      setTagFilter((old) => old.filter((t) => t.id !== tag.id));
    } else {
      setTagFilter((old) => [...old, tag]);
    }
  };

  const handleOnlyMyThreadFilter = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setData([]);
    setPage(0);
    setOnlyMyThreadFilter(target.checked);
  };

  useEffect(() => {
    if (currentQueryParameters.get('thread_id') === null) {
      setShowSingleThread(false);
    }
  }, [currentQueryParameters, location]);

  const hasReportedView = useRef(false);
  useEffect(() => {
    // Record feed view analytics
    if (feed && user && !hasReportedView.current) {
      hasReportedView.current = true;
      firehoseActivity(
        ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_COMMUNITY,
        feed?.id || '',
        null,
        `${user?.id || ''}`,
        ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_FEED,
        ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_VIEW.value,
        '',
        null,
      );
    }
  }, [firehoseActivity, feed, user]);

  return (
    <FeedContext.Provider value={{ tags: tags || [], iAmAdmin }}>
      <TagsContext.Provider value={tags || []}>
        <Box>
          {showTitle && (
            <Typography component='span' variant='h5' aria-label='Feed title'>
              {feed?.title}
            </Typography>
          )}
          <>
            {showSingleThread && singleThread ? (
              <SinglePostView
                siteId={siteId}
                singleThread={singleThread}
                onGoBack={() => {
                  setSearchParams({});
                  setPage(0);
                  refetchFeedThreads();
                }}
                platformId={platformId}
                setData={setData}
                currentCommunityUser={currentCommunityUser}
                refetch={refetchSingleThread}
                isInForum={isInForum}
              />
            ) : (
              <>
                {userCanInteract && (
                  <DefaultSearchSortFilter
                    buttonDisabled={false}
                    buttonText={'New Post'}
                    onClickFilter={() => setShowFilters(true)}
                    onClickButton={() => setShowNewDialog(true)}
                    setSearch={setPostSearch}
                    sort={sort}
                    setSort={(value) => {
                      setData([]);
                      setPage(0);
                      setSort(value as FeedSort);
                    }}
                    sortOptions={[
                      { value: FeedSort.Newest, label: 'Newest' },
                      { value: FeedSort.Likes, label: 'Likes' },
                    ]}
                  />
                )}
                {tagFilter.map((t) => (
                  <Chip
                    sx={{ mt: 2, mr: 1 }}
                    label={t.value}
                    onDelete={() => {
                      setData([]);
                      setPage(0);
                      setTagFilter((old) => old.filter((tag) => tag.id !== t.id));
                    }}
                  />
                ))}
                {!userCanInteract && (
                  <Alert severity='warning'>{blockUserInteractionMessage}</Alert>
                )}
                {!isLoading && data?.length === 0 && userCanInteract && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    minHeight='100px'
                  >
                    <Typography variant='body2'>Be the first to start a conversation.</Typography>
                  </Box>
                )}
                {data?.map((thread: Thread) => {
                  return (
                    <ThreadPost
                      userCanInteract={userCanInteract}
                      setData={setData}
                      platformId={platformId}
                      onNavigate={onNavigate}
                      key={thread.id}
                      siteId={siteId}
                      thread={thread}
                      refetchParentThread={refetchFeedThreads}
                      handleTagFilter={handleTagFilter}
                      handleOpenPostDetails={(thread: Thread) => {
                        setSelectedPost(thread);
                        setShowPostDetails(true);
                      }}
                    />
                  );
                })}
              </>
            )}
            {isLoading && (
              <Skeleton height={180} sx={{ mt: 2, transform: 'none', transformOrigin: '0 0' }} />
            )}
            {!isLoading && data && data?.length % PAGE_SIZE === 0 && (
              <Box ref={ref} sx={{ height: 20 }}></Box>
            )}
          </>
          <CreateNewPostDialog
            platformId={platformId}
            siteId={siteId}
            handleSubmit={handleSubmitNewPost}
            handleCancel={() => setShowNewDialog((old) => !old)}
            open={showNewDialog}
          />
          {selectedPost.id && (
            <PostDetailDialog
              open={showPostDetails}
              userCanInteract={userCanInteract}
              setData={setData}
              platformId={platformId}
              siteId={siteId}
              thread={selectedPost}
              handleCloseDialog={() => {
                setShowPostDetails(false);
                setSelectedPost({} as Thread);
              }}
              refetch={() => {}}
              currentCommunityUser={currentCommunityUser}
              onNavigate={onNavigate}
              isInForum={isInForum}
            />
          )}
          <DialogAriaWrapper
            id={`thread_filters`}
            open={showFilters}
            onClose={() => setShowFilters(false)}
            fullWidth={true}
            maxWidth={'xs'}
          >
            <DialogTitle>Filter posts</DialogTitle>
            <IconButton
              aria-label='close'
              onClick={() => setShowFilters(false)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <FormControlLabel
                control={
                  <Checkbox
                    name='filterOnlyMyPosts'
                    checked={onlyMyThreadFilter}
                    onChange={handleOnlyMyThreadFilter}
                  />
                }
                label='Only My Posts'
                sx={{ pl: 2 }}
              />
            </DialogContent>
          </DialogAriaWrapper>
        </Box>
      </TagsContext.Provider>
    </FeedContext.Provider>
  );
};

interface GlobalFeedProps {
  siteId: string;
  title?: string;
}

export const GlobalFeed: React.FC<GlobalFeedProps> = ({ siteId, title }) => {
  const { data: feed } = useGetSiteFeed(siteId);
  const { user } = useSettings();
  const iAmAdmin = user?.role === 1 || false;
  if (!feed) {
    return <></>;
  }
  return (
    <Container p={2}>
      {title && (
        <Typography variant='h5' sx={{ ml: 1, mb: 2 }}>
          {title}
        </Typography>
      )}
      <FeedComponent siteId={siteId} feed={feed} iAmAdmin={iAmAdmin} />
    </Container>
  );
};
export default FeedComponent;
