import React, { useEffect, useState } from 'react';
import { Download as DownloadIcon } from '@mui/icons-material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import Select from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getGetAllGeneratedReportsQueryKey,
  useCreateGeneratedReport,
  useGetAllGeneratedReports,
  useGetCourses,
} from '@juno/client-api';
import {
  Course,
  CreateGeneratedReportParams,
  GeneratedReport,
  GeneratedReportStatusEnum,
  GeneratedReportTypeEnum,
  GetAllGeneratedReportsParams,
  JunoUser,
  Site,
} from '@juno/client-api/model';
import { MutationAction, a11yProps, onMutation } from '@juno/utils';
import {
  DownloadButton,
  DownloadRowContainer,
  DownloadRowFooter,
  DownloadRowMain,
  HeaderBox,
  ReportsTab,
  TitleContainer,
} from './styles';

enum FilterOption {
  Course = 'Course',
  StartDate = 'Course Start Date',
}
interface ReportsModel {
  title: string;
  path: string;
  start: string;
  end: string;
  course: string;
  enabled: boolean;
  type: GeneratedReportTypeEnum;
  additionalContext?: { [key: string]: string };
}

const REPORTS: ReportsModel[] = [
  {
    title: 'Students enrolled by course',
    path: 'learning/reports/enrollments',
    start: 'course__date_start__gte',
    end: 'course__date_start__lte',
    course: 'course_id__in',
    enabled: true,
    type: GeneratedReportTypeEnum.learningenrollments,
  },
  {
    title: 'Students on waitlist by course',
    path: 'learning/reports/waitlists',
    start: 'course__date_start__gte',
    end: 'course__date_start__lte',
    course: 'course_id__in',
    enabled: true,
    type: GeneratedReportTypeEnum.learningwait_lists,
  },
  {
    title: 'Students completed by course',
    path: '',
    start: 'course__date_start__gte',
    end: 'course__date_start__lte',
    course: 'course_id__in',
    enabled: false,
    // put a dummy type in here because we have to
    type: GeneratedReportTypeEnum.learningenrollments,
  },
  {
    title: 'Quiz scores',
    path: '',
    start: 'date_created__gte',
    end: 'date_created__lte',
    course: 'course_id__in',
    enabled: false,
    // put a dummy type in here because we have to
    type: GeneratedReportTypeEnum.learningenrollments,
  },
  {
    title: 'Students from each school per course',
    path: 'learning/reports/enrollments_by_group',
    start: 'course__date_start__gte',
    end: 'course__date_start__lte',
    course: 'course_id__in',
    enabled: true,
    type: GeneratedReportTypeEnum.learningenrollments_by_group,
  },
  {
    title: 'Questions answered by learner',
    path: 'learning/reports/user_answers',
    start: 'lesson_completion__lesson__course__date_start__gte',
    end: 'lesson_completion__lesson__course__date_start__lte',
    course: 'lesson_completion__lesson__course_id__in',
    enabled: true,
    type: GeneratedReportTypeEnum.learninguser_answers,
  },
  {
    title: 'Students started by course',
    path: '',
    start: 'date_created__gte',
    end: 'date_created__lte',
    course: 'course_id__in',
    enabled: false,
    // put a dummy type in here because we have to
    type: GeneratedReportTypeEnum.learningenrollments,
  },
  {
    title: 'Student Transcripts',
    path: 'learning/reports/student_transcripts',
    start: 'course__date_start__gte',
    end: 'course__date_start__lte',
    course: 'course_id__in',
    enabled: true,
    type: GeneratedReportTypeEnum.learningstudent_transcripts,
    additionalContext: {
      scope: 'all',
    },
  },
  {
    title: 'Downloads',
    path: '',
    start: 'start_datetime',
    end: 'end_datetime',
    course: 'content_ids',
    enabled: true,
    // connect to Steveo's new proxy analytics report type
    type: GeneratedReportTypeEnum.proxiesanalytics_activity,
  },
];

interface ReportsDialogProps {
  site: Site;
  open: boolean;
  onClose: () => void;
  course?: Course;
  userData: JunoUser | undefined;
}

const ReportsDialog: React.FC<ReportsDialogProps> = ({ site, open, onClose, course, userData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [filterOption, setFilterOption] = useState(FilterOption.Course);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isCourseSelectOpen, setIsCourseSelectOpen] = useState(false);
  const [selectedCourseIds, setSelectedCourseIds] = useState<string[]>([]);
  const [options, setOptions] = React.useState<readonly Course[]>([]);
  const [currentTab, setCurrentTab] = React.useState(0);
  const [notificationNumber, setNotificationNumber] = React.useState(0);
  const queryClient = useQueryClient();
  const { data: courses, isLoading } = useGetCourses(
    site.id,
    {
      order: 'title',
    },
    { query: { enabled: isCourseSelectOpen } },
  );
  const showFilter = !course;

  useEffect(() => {
    if (!course) {
      setOptions(courses || []);
    } else {
      setOptions([course]);
      setSelectedCourseIds([course.id]);
    }
  }, [courses, course]);

  // fetch our reports and set up CRUD hooks
  const oneDayAgo = new Date();
  oneDayAgo.setTime(oneDayAgo.getTime() - 24 * 60 * 60 * 1000);
  const [filterDate] = oneDayAgo.toISOString().split('T');
  const getAllGeneratedReportsParams: GetAllGeneratedReportsParams = {
    order: '-date_created',
    filter: { date_created__gte: filterDate },
  };
  const { data: reports, isLoading: reportsLoading } = useGetAllGeneratedReports(
    site.id,
    getAllGeneratedReportsParams,
  );
  const [checkInterval, setCheckInterval] = useState<NodeJS.Timer>();
  const refetch = () =>
    queryClient.invalidateQueries(
      getGetAllGeneratedReportsQueryKey(site.id, getAllGeneratedReportsParams),
    );
  const reportCreate = useCreateGeneratedReport(
    onMutation(MutationAction.CREATE, 'GeneratedReports', refetch),
  );

  useEffect(() => {
    const unfinished = reports?.find(
      (report) =>
        report.status === GeneratedReportStatusEnum.NUMBER_1 ||
        report.status === GeneratedReportStatusEnum.NUMBER_0,
    );
    if (unfinished && !checkInterval) {
      const interval = setInterval(() => {
        refetch();
      }, 5000);
      setCheckInterval(interval);
    } else if (!unfinished && checkInterval) {
      clearInterval(checkInterval);
      setCheckInterval(undefined);
    }
  }, [reports]);

  const handleSelectCourses = (event: React.SyntheticEvent<Element, Event>, values: Course[]) => {
    setSelectedCourseIds(values.map((item) => item.id));
  };

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
    if (newValue === 1) {
      setNotificationNumber(0);
    }
  };

  const setReportStartFilterParams = (
    report: ReportsModel,
    params: CreateGeneratedReportParams,
    startString: string,
  ) => {
    if (startString && params.filter && filterOption === FilterOption.StartDate) {
      if (report.type === 'learning.user_answers') {
        params.filter['lesson_completion__lesson__course__date_start__gte'] = startString;
      } else if (report.type === 'proxies.analytics_activity') {
        const startOfDay = startDate instanceof Date ? new Date(startDate) : '';
        if (startOfDay instanceof Date) {
          startOfDay.setHours(0, 0, 0, 0);
        }
        params.filter['start_datetime'] =
          startOfDay instanceof Date
            ? startOfDay.toISOString()
            : dayjs().startOf('date').subtract(7, 'day').toISOString();
      } else {
        params.filter['course__date_start__gte'] = startString;
      }
    } else if (!startString && params.filter && report.type === 'proxies.analytics_activity') {
      params.filter['start_datetime'] = dayjs().startOf('date').subtract(7, 'day').toISOString();
    }
  };

  const setReportEndFilterParams = (
    report: ReportsModel,
    params: CreateGeneratedReportParams,
    endString: string,
  ) => {
    if (endString && params.filter && filterOption === FilterOption.StartDate) {
      if (report.type === 'learning.user_answers') {
        params.filter['lesson_completion__lesson__course__date_start__lte'] = endString;
      } else if (report.type === 'proxies.analytics_activity') {
        const endOfDay = endDate instanceof Date ? new Date(endDate) : '';
        if (endOfDay instanceof Date) {
          endOfDay.setHours(23, 59, 59, 999);
        }
        params.filter['end_datetime'] =
          endOfDay instanceof Date ? endOfDay.toISOString() : dayjs().endOf('day').toISOString();
      } else {
        params.filter['course__date_start__lte'] = endString;
      }
    } else if (!endString && params.filter && report.type === 'proxies.analytics_activity') {
      params.filter['end_datetime'] = dayjs().endOf('day').toISOString();
    }
  };

  const setReportMiscFilterParams = (report: ReportsModel, params: CreateGeneratedReportParams) => {
    if (selectedCourseIds.length > 0 && params.filter && filterOption === FilterOption.Course) {
      if (report.type === 'proxies.analytics_activity') {
        params.filter[report.course] = selectedCourseIds.join('|');
      } else {
        params.filter[report.course] = selectedCourseIds;
      }
    }

    if (report.title === 'Downloads' && params.filter) {
      params.filter = { ...params.filter, category: 'Download', action: 'Download' };
    }

    if (params.context) {
      params.context['report_path'] = 'activity';
      if (report.additionalContext) {
        params.context = { ...params.context, ...report.additionalContext };
      }
    }
  };

  const initializeReport = async (report: ReportsModel) => {
    const data = {
      type: report.type,
      site_id: site.id,
      // These are set on the backend
      admin_id: null,
      user_id: null,
    };

    const params: CreateGeneratedReportParams = {
      filter: {},
      context: {},
    };

    const [start] = startDate ? startDate.toISOString().split('T') : '';
    const [end] = endDate ? endDate.toISOString().split('T') : '';
    setReportStartFilterParams(report, params, start);
    setReportEndFilterParams(report, params, end);
    setReportMiscFilterParams(report, params);

    try {
      await reportCreate.mutate({ siteId: site.id, data: data, params: params });
      setNotificationNumber((old) => old + 1);
    } catch {
      console.error('error creating report');
    }
  };

  const handlePreviewReport = (analyticsReport: string | null | undefined) => {
    if (analyticsReport) {
      const encodedUrl = encodeURIComponent(analyticsReport);
      const url =
        location.pathname.indexOf('/sites/') !== -1
          ? `${window.origin}/sites/${site.slug}/report_preview?reportUrl=${encodedUrl}`
          : `${window.origin}/${site.slug}/report_preview?reportUrl=${encodedUrl}`;
      window.open(url);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth='md'>
      <HeaderBox>
        <DialogTitle>Course Reports</DialogTitle>
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            border: 'solid rgba(128,128,128,0.2) 1px',
            borderRadius: '4px',
            minHeight: 0,
          }}
        >
          <ReportsTab label='Request' {...a11yProps(0)} />
          <ReportsTab
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                Download
                {notificationNumber !== 0 && (
                  <Badge badgeContent={notificationNumber} color='primary'>
                    <span style={{ width: 20 }} />
                  </Badge>
                )}
              </Box>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </HeaderBox>

      <TabPanel value={currentTab} index={0}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DialogContent sx={{ gap: 2, display: 'grid' }}>
            {showFilter && (
              <Box sx={{ gap: 2, display: 'grid' }}>
                <Typography>Filter Report</Typography>
                <Select
                  id='filter_option'
                  name='filter_option'
                  value={filterOption}
                  onChange={(e) => setFilterOption(e.target.value as FilterOption)}
                >
                  {Object.values(FilterOption).map((entry) => (
                    <MenuItem value={entry} key={`${entry}`}>
                      {entry}
                    </MenuItem>
                  ))}
                </Select>
                {filterOption === FilterOption.Course && (
                  <Box>
                    <Box sx={{ display: 'flex', mt: 1.5, mb: 3, gap: 1 }}>
                      <Autocomplete
                        fullWidth
                        multiple
                        disabled={!!course}
                        open={isCourseSelectOpen}
                        onOpen={() => setIsCourseSelectOpen(true)}
                        onClose={() => setIsCourseSelectOpen(false)}
                        onChange={handleSelectCourses}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.title}
                        options={options}
                        loading={isLoading}
                        value={options.filter((option) => selectedCourseIds.includes(option.id))}
                        renderOption={(props, option) => (
                          <li {...props} key={option.id}>
                            {option.title}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label='Select Course'
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {isLoading ? (
                                    <CircularProgress color='inherit' size={20} />
                                  ) : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>
                              ),
                            }}
                          />
                        )}
                      />
                    </Box>
                  </Box>
                )}
                {filterOption === FilterOption.StartDate && (
                  <Box>
                    <Box sx={{ display: 'flex', mt: 1.5, mb: 3, gap: 1 }}>
                      <DesktopDatePicker
                        // disabled
                        label='From'
                        inputFormat='MM/dd/yyyy'
                        value={startDate}
                        onChange={(date) => {
                          const isValidDate = date instanceof Date && !isNaN(date.valueOf());
                          setStartDate(isValidDate ? date : null);
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                      <DesktopDatePicker
                        // disabled
                        label='To'
                        inputFormat='MM/dd/yyyy'
                        value={endDate}
                        onChange={(date) => {
                          const isValidDate = date instanceof Date && !isNaN(date.valueOf());
                          setEndDate(isValidDate ? date : null);
                        }}
                        renderInput={(params) => <TextField fullWidth {...params} />}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )}
            <Grid container spacing={1}>
              {!reportsLoading &&
                REPORTS.filter((report) => report.enabled).map((report) => (
                  <Grid key={report.title} item xs={6}>
                    <LoadingButton
                      sx={{ textTransform: 'none', justifyContent: 'space-between' }}
                      fullWidth
                      variant='outlined'
                      size='large'
                      endIcon={<DownloadIcon />}
                      onClick={(e) => initializeReport(report)}
                    >
                      {report.title}
                    </LoadingButton>
                  </Grid>
                ))}
            </Grid>
          </DialogContent>
        </LocalizationProvider>
        <DialogActions>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Button variant='contained' onClick={onClose}>
              Close
            </Button>
          </Box>
        </DialogActions>
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <>
          <Typography variant='body2' sx={{ mb: 1 }}>
            Reports generated in the last 24 hours:
          </Typography>
          {reports?.map((report) => (
            <DownloadRow
              key={report.id}
              report={report}
              downloadReport={() => window.open(report?.report_url || '')}
              previewReport={() => handlePreviewReport(report?.report_url)}
              site={site}
            />
          ))}
        </>
      </TabPanel>
    </Dialog>
  );
};

interface DownloadRowProps {
  report: GeneratedReport;
  downloadReport: (report: GeneratedReport) => void;
  previewReport: (report: GeneratedReport) => void;
  site: Site;
}
interface ReportMetaModel {
  filters?: { [key: string]: string };
}

function DownloadRow(props: DownloadRowProps) {
  const { report, downloadReport, previewReport, site } = props;
  const reportDetails = REPORTS.find((r) => r.type === report.type);
  let dateString = '';
  let filterString = '';
  // have to do this weirdness to get around unknown typing of metadata
  const meta: ReportMetaModel = report?.metadata || {};
  const startDate = meta.filters?.[reportDetails?.start || ''] || false;
  const endDate = meta.filters?.[reportDetails?.end || ''] || false;
  if (startDate && endDate) {
    dateString = `${startDate} - ${endDate}`;
  } else if (startDate) {
    dateString = `After ${startDate}`;
  } else if (endDate) {
    dateString = `Before ${endDate}`;
  }
  if (dateString) {
    filterString = `, Filtered by Start Date : ${dateString}`;
  }
  let courseIds: string[];
  let courseNames = '';
  const courseIdsValue = meta.filters?.[reportDetails?.course || ''] || [];
  if (typeof courseIdsValue == 'string') {
    courseIds = courseIdsValue.split('|');
  } else {
    courseIds = courseIdsValue;
  }
  const { data: courses } = useGetCourses(
    site.id,
    {
      filter: {
        id__in: courseIds,
      },
    },
    {},
  );

  if (courseIds.length) {
    const filteredCourses = courses?.filter((aCourse) => courseIds.includes(aCourse.id));
    courseNames = filteredCourses?.map((aCourse) => aCourse.title).join(', ') || '';
  }
  if (courseNames) {
    filterString = `, Filtered by Courses: ${courseNames}${filterString}`;
  }
  return (
    <DownloadRowContainer status={report.status || ''}>
      <DownloadRowMain>
        <TitleContainer>
          <Typography sx={{ mr: 1, fontSize: '1.15rem' }}>{reportDetails?.title || ''}</Typography>
        </TitleContainer>
        <Box>
          {report.status === GeneratedReportStatusEnum['NUMBER_MINUS_1'] && (
            <Chip label={'Failed'} color={'default'} size='small' />
          )}
          {report.status === GeneratedReportStatusEnum['NUMBER_0'] && (
            <Chip label={'Processing'} color={'primary'} size='small' />
          )}
          {report.status === GeneratedReportStatusEnum['NUMBER_2'] && (
            <DownloadButton onClick={(e) => downloadReport(report)} size='small'>
              <DownloadIcon />
            </DownloadButton>
          )}
          {report.status === GeneratedReportStatusEnum['NUMBER_2'] && (
            <DownloadButton onClick={(e) => previewReport(report)} size='small'>
              <VisibilityIcon />
            </DownloadButton>
          )}
        </Box>
      </DownloadRowMain>
      <DownloadRowFooter variant='body2'>
        Created &#64; {new Date(report.date_created || '').toLocaleString('en-US')}
        {filterString}
      </DownloadRowFooter>
    </DownloadRowContainer>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ height: 607 }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default ReportsDialog;
