import React, { useEffect, useMemo, useState } from 'react';
import { Add, Remove } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { JunoUser, Session } from '@juno/client-api/model';
import { DialogAriaWrapper, JunoSpin } from '@juno/ui';
import { useSettings } from '@juno/utils';
import Aside from '..';
import { EndRoomOptionLabels, EndRoomOptions, SUBBREAKOUT_ASIDE } from '../../../constants';
import { useCallState } from '../../../contexts/CallProvider';
import { useParticipants } from '../../../contexts/ParticipantsProvider';
import { useUIState } from '../../../contexts/UIStateProvider';
import LiveBreakoutRooms from './LiveBreakoutRooms';
import RoomsLayoutComponent, { RoomItem } from './RoomsLayoutComponent';
import { useSubBreakoutsHook } from './hooks';

const BreakoutRoomAside: React.FC = () => {
  const { showAside, setShowAside } = useUIState();
  const { participants } = useParticipants();
  const { user: currentUser } = useSettings();
  const [hasMovedUser, setHasMovedUser] = useState(false);
  const [hasAutoAssigned, setHasAutoAssigned] = useState(true);
  const { roomInfo } = useCallState();
  const sessionData = useMemo(() => {
    if (roomInfo) {
      return (roomInfo as Record<string, unknown>)['session'] as Session;
    }
    return {} as Session;
  }, [roomInfo]);
  const sessionTitle =
    sessionData.title?.length > 20 ? `${sessionData.title.slice(0, 20)}...` : sessionData.title;
  const { subBreakouts, subBreakoutsLoading, createSubBreakouts, endSubBreakouts } =
    useSubBreakoutsHook();
  const [creatingBreakouts, setCreatingBreakouts] = useState(false);
  const [rooms, setRooms] = useState<RoomItem[]>([]);
  const [isEnding, setIsEnding] = useState(false);

  const allUsers = useMemo(() => {
    if (!participants) return [];
    // convert participants to JunoUser
    return participants.map((participant: any) => {
      return {
        id: participant.userData?.userId,
        first_name: participant.name,
        avatar: participant.userData?.userAvatar,
      };
    }) as JunoUser[];
  }, [participants]);
  const [hasStartedBreakouts, setHasStartedBreakouts] = useState(false);
  const [createRoomsDialogOpen, setCreateRoomsDialogOpen] = useState(false);
  const [endRoomsDialogOpen, setEndRoomsDialogOpen] = useState(false);
  const [numRooms, setNumRooms] = useState<number>(2);
  const [endInTime, setEndInTime] = useState<number>(EndRoomOptions.EndIn1Minute);
  const [autoJoin, setAutoJoin] = useState(true);

  useEffect(() => {
    if (subBreakouts && subBreakouts.length > 0) {
      // check actual_length_in_ms in any of the subbreakouts, if we dont that means we still have some active breakouts
      const liveBreakouts = subBreakouts.filter(
        (subBreakout) => subBreakout.actual_length_in_ms === 0,
      );
      const hasStarted = liveBreakouts?.some(
        (subBreakout) => new Date(subBreakout.date_end || '') > new Date(),
      );
      setHasStartedBreakouts(hasStarted);
    }
  }, [subBreakouts, sessionData]);

  const peoplePerRoom = useMemo(() => {
    // take total number of users, not including currentuser and divide by number of rooms
    // if number of users is not divisible by number of rooms, add 1 to each room until all users are assigned
    const otherUsers = allUsers.slice(1);
    const numUsers = otherUsers.length;
    const numUsersPerRoom = Math.floor(numUsers / numRooms);
    const remainder = numUsers % numRooms;
    if (remainder > 0) {
      return `${numUsersPerRoom} - ${numUsersPerRoom + 1}`;
    }
    return numUsersPerRoom;
  }, [numRooms, rooms]);

  // auto generate rooms from numRooms and allUsers
  const initialRooms: RoomItem[] = useMemo(() => {
    const otherUsers = allUsers.slice(1);
    const numUsers = otherUsers.length;
    const numUsersPerRoom = Math.floor(numUsers / numRooms);
    const remainder = numUsers % numRooms;
    return [
      { id: sessionData.id, name: sessionTitle, users: currentUser ? [currentUser] : [] },
      ...[...Array(numRooms)].map((_, index) => {
        const start = index * numUsersPerRoom + Math.min(index, remainder);
        const end = start + numUsersPerRoom + (index < remainder ? 1 : 0);
        return {
          id: index + 2,
          name: `Room ${index + 1}`,
          users: otherUsers.slice(start, end),
        };
      }),
    ];
  }, [numRooms, allUsers, sessionData]);

  const maxNumRooms = useMemo(() => {
    const maxNumRooms = Math.floor(allUsers.length / 2);
    if (maxNumRooms > 10) {
      return 10;
    }
    return maxNumRooms < 2 ? 2 : maxNumRooms;
  }, [initialRooms]);

  useEffect(() => {
    // need this useEffect instead of setting the rooms state initially since sessionData is async and needs to load before setting rooms
    if (initialRooms && initialRooms.length > 0) {
      setRooms(initialRooms);
    }
  }, [initialRooms]);

  useEffect(() => {
    if (!subBreakouts || subBreakoutsLoading)
      if (subBreakouts && subBreakouts.length > 0) {
        setHasStartedBreakouts(true);
      }
  }, [subBreakouts, subBreakoutsLoading]);

  const handleIncrement = () => {
    if (numRooms < maxNumRooms && numRooms < 10) {
      setNumRooms(numRooms + 1);
      setHasAutoAssigned(false);
    }
  };

  const handleDecrement = () => {
    if (numRooms > 2) {
      setNumRooms(numRooms - 1);
      setHasAutoAssigned(false);
    }
  };

  const renderEndLabel = () => {
    switch (endInTime) {
      case EndRoomOptions.EndImmediately:
        return EndRoomOptionLabels.EndImmediately;
      case EndRoomOptions.EndIn10Seconds:
        return EndRoomOptionLabels.EndIn10Seconds;
      case EndRoomOptions.EndIn30Seconds:
        return EndRoomOptionLabels.EndIn30Seconds;
      case EndRoomOptions.EndIn1Minute:
        return EndRoomOptionLabels.EndIn1Minute;
      default:
        return 'End';
    }
  };

  if (!showAside || showAside !== SUBBREAKOUT_ASIDE || !sessionData) {
    return null;
  }
  return (
    <Aside onClose={() => setShowAside(false)}>
      <Box
        sx={{
          position: 'relative',
          height: '100%',
        }}
      >
        {creatingBreakouts && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              zIndex: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              pt: 4,
              borderRadius: 1,
            }}
          >
            <Typography variant='h6' color='primary.main'>
              Creating Breakout Rooms...
            </Typography>
            <JunoSpin />
          </Box>
        )}
        <Card
          sx={{
            background: '#5e5a63',
            color: 'white',
            p: 1,
            textAlign: 'center',
          }}
        >
          <Typography variant={'subtitle2'}>Breakout Rooms</Typography>
        </Card>
        {!hasStartedBreakouts && (
          <>
            <Box pt={2}>
              <Typography variant={'subtitle2'}>Number of Rooms</Typography>
              <Box display='flex' alignItems='center'>
                <IconButton
                  onClick={handleDecrement}
                  sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)', '&:hover': { bgcolor: 'white' } }}
                  disabled={numRooms === 2}
                >
                  <Remove fontSize='small' />
                </IconButton>
                <TextField
                  variant='outlined'
                  type='text'
                  value={numRooms < 2 ? 2 : numRooms > 10 ? maxNumRooms : numRooms}
                  onChange={(e) => {
                    if (e.target.value === '') return;
                    let newNum = parseInt(e.target.value);
                    if (newNum > maxNumRooms) {
                      newNum = maxNumRooms;
                    }
                    if (newNum < 2) {
                      newNum = 2;
                    }
                    setNumRooms(newNum);
                    setHasAutoAssigned(false);
                  }}
                  inputProps={{
                    min: 2,
                    max: maxNumRooms,
                    step: 'any',
                    style: { textAlign: 'center', padding: 3 },
                  }}
                  fullWidth
                  sx={{
                    '& input': { textAlign: 'center' },
                    bgcolor: 'white',
                    borderRadius: 1,
                    ml: 1,
                    mr: 1,
                  }}
                />
                <IconButton
                  onClick={handleIncrement}
                  sx={{ bgcolor: 'rgba(255, 255, 255, 0.5)', '&:hover': { bgcolor: 'white' } }}
                  disabled={numRooms === maxNumRooms}
                >
                  <Add fontSize='small' />
                </IconButton>
              </Box>
              <Typography sx={{ pl: 2 }} variant={'caption'}>
                {peoplePerRoom} people per room ({allUsers.length - 1} total)
              </Typography>
            </Box>
            <Box mb={1}>
              <Box display={'flex'} justifyContent={'space-between'} pt={1} pb={1}>
                <Button
                  variant='text'
                  color='primary'
                  disabled={hasAutoAssigned && !hasMovedUser}
                  onClick={() => {
                    const otherUsers = allUsers.slice(1);
                    const numUsers = otherUsers.length;
                    const numUsersPerRoom = Math.floor(numUsers / numRooms);
                    const remainder = numUsers % numRooms;
                    const newRooms: RoomItem[] = [
                      {
                        id: sessionData.id,
                        name: sessionTitle,
                        users: currentUser ? [currentUser] : [],
                      },
                      ...[...Array(numRooms)].map((_, index) => {
                        const start = index * numUsersPerRoom + Math.min(index, remainder);
                        const end = start + numUsersPerRoom + (index < remainder ? 1 : 0);
                        return {
                          id: index + 2,
                          name: `Room ${index + 1}`,
                          users: otherUsers.slice(start, end),
                        };
                      }),
                    ];
                    setRooms(newRooms);
                    setHasMovedUser(false);
                    setHasAutoAssigned(true);
                  }}
                >
                  Reset Auto-assign
                </Button>
                <Button
                  variant='text'
                  color='primary'
                  disabled={!hasAutoAssigned && !hasMovedUser}
                  onClick={() => {
                    const newRooms: RoomItem[] = [
                      { id: sessionData.id, name: sessionTitle, users: [...allUsers] },
                      ...[...Array(numRooms)].map((_, index) => {
                        return {
                          id: index + 2,
                          name: `Room ${index + 1}`,
                          users: [],
                        };
                      }),
                    ];
                    setRooms(newRooms);
                    setHasMovedUser(false);
                    setHasAutoAssigned(false);
                  }}
                >
                  Assign Manually
                </Button>
              </Box>
            </Box>
            <RoomsLayoutComponent
              rooms={rooms}
              setRooms={setRooms}
              setHasMovedUser={setHasMovedUser}
            />
          </>
        )}
        {hasStartedBreakouts && (
          <LiveBreakoutRooms
            setHasStartedBreakouts={setHasStartedBreakouts}
            subBreakouts={subBreakouts}
          />
        )}
        <Box pt={1}>
          {!hasStartedBreakouts && (
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  onChange={(e) => {
                    setAutoJoin(e.target.checked);
                  }}
                />
              }
              label='Join users automatically'
            />
          )}
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              if (hasStartedBreakouts) {
                setEndRoomsDialogOpen(true);
              } else {
                const roomsToCreate = rooms.filter(
                  (room) => room.id !== sessionData.id && room.users.length > 0,
                );
                if (roomsToCreate.length === 0) {
                  return;
                }
                setNumRooms(roomsToCreate.length);
                setCreateRoomsDialogOpen(true);
              }
            }}
            disabled={!hasAutoAssigned && !hasMovedUser}
          >
            {hasStartedBreakouts ? 'End breakouts' : 'Start Breakouts'}
          </Button>
        </Box>
      </Box>
      <DialogAriaWrapper
        id='create-rooms-dialog'
        open={createRoomsDialogOpen}
        onClose={() => setCreateRoomsDialogOpen(false)}
      >
        <DialogTitle>Confirm Breakout Rooms?</DialogTitle>
        <DialogContent>
          <Typography variant='body1'>{`You are about to create ${numRooms} rooms, with ${peoplePerRoom} users per room.`}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setCreateRoomsDialogOpen(false)}>
            Cancel
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              setCreateRoomsDialogOpen(false);
              setCreatingBreakouts(true);
              const createRooms = async () => {
                await createSubBreakouts(rooms, autoJoin);
                setCreatingBreakouts(false);
                setHasStartedBreakouts(true);
              };
              createRooms();
            }}
          >
            Create
          </Button>
        </DialogActions>
      </DialogAriaWrapper>
      <DialogAriaWrapper
        id='end-rooms-dialog'
        open={endRoomsDialogOpen}
        onClose={() => setEndRoomsDialogOpen(false)}
      >
        <DialogTitle>End Breakout Rooms?</DialogTitle>
        <DialogContent>
          <Select
            sx={{ mb: 2 }}
            fullWidth
            value={endInTime}
            onChange={(e) => setEndInTime(e.target.value as EndRoomOptions)}
          >
            <MenuItem value={EndRoomOptions.EndImmediately}>
              {EndRoomOptionLabels.EndImmediately}
            </MenuItem>
            <MenuItem value={EndRoomOptions.EndIn10Seconds}>
              {EndRoomOptionLabels.EndIn10Seconds}
            </MenuItem>
            <MenuItem value={EndRoomOptions.EndIn30Seconds}>
              {EndRoomOptionLabels.EndIn30Seconds}
            </MenuItem>
            <MenuItem value={EndRoomOptions.EndIn1Minute}>
              {EndRoomOptionLabels.EndIn1Minute}
            </MenuItem>
          </Select>
          <Typography variant='body1' component={'div'}>
            Choosing a time will warn users before closing the breakout rooms.
          </Typography>
          <Typography variant='body1'>
            Ending immediately will send users directly to "{sessionData.title}".
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setEndRoomsDialogOpen(false)}>
            Cancel
          </Button>
          <LoadingButton
            loading={isEnding}
            variant='contained'
            onClick={() => {
              setIsEnding(true);
              const endBreakouts = async () => {
                await endSubBreakouts(endInTime, subBreakouts?.[0].parent_session);
                setIsEnding(false);
                setHasStartedBreakouts(false);
                setEndRoomsDialogOpen(false);
              };
              endBreakouts();
            }}
          >
            {renderEndLabel()}
          </LoadingButton>
        </DialogActions>
      </DialogAriaWrapper>
    </Aside>
  );
};
export default BreakoutRoomAside;
