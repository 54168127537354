import React, { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Box, Button, FormHelperText, Grid, Input, TextField } from '@mui/material';
import { JunoImage as JunoImageModel } from '@juno/client-api/fakeModel';
import { FILE_UPLOAD_TYPES } from '@juno/constants';
import { ASPECT_RATIOS, JunoImageUpload, JunoSpin } from '@juno/ui';
import { optimizeImage, readImageFile, validateSelectedFileSize } from '@juno/utils';

interface FormProps {
  isLoading: boolean;
  isNew: boolean;
  isSaving: boolean;
  //onResourceChange: ( e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<any>, resourceId: string) => void
  payload: PayloadProps;
  setPayload: (payload: PayloadProps | ((old: PayloadProps) => PayloadProps)) => void;
}

export interface PayloadProps {
  resource?: any;
  file?: File | null;
  iconFile?: File;
  iconUrl?: string;
}

export const Form = ({ isLoading, isNew, isSaving, payload, setPayload }: FormProps) => {
  //const [payload, setPayload] = useState<PayloadProps | undefined>();
  const [isTitleTouched, setIsTitleTouched] = useState<boolean>(false);
  const [isDescriptionTouched, setIsDescriptionTouched] = useState<boolean>(false);

  const isTitleValid = () => {
    return payload?.resource?.title && payload?.resource?.title?.length <= 67;
  };

  const isDescriptionValid = () => {
    return payload?.resource?.description && payload?.resource?.description?.length <= 78;
  };

  const isValid = useMemo(() => {
    const requiredFields: (keyof PayloadProps)[] = isNew
      ? ['resource']
      : ['resource', 'file', 'iconFile', 'iconUrl'];
    const hasRequiredFields = !requiredFields.some((fieldId) => !payload?.[fieldId]);
    return (
      hasRequiredFields &&
      isTitleTouched &&
      isTitleValid() &&
      isDescriptionTouched &&
      isDescriptionValid()
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const handleUpdate = (key: string, val: unknown) => {
    const newResource = { ...payload?.resource, [key]: val };
    setPayload({ ...payload, resource: newResource });
  };

  const changeHandler = {
    checkbox: ({ target: t }: ChangeEvent<HTMLInputElement>) => handleUpdate(t.name, t.checked),
    text: ({ target: t }: ChangeEvent<HTMLInputElement>) => handleUpdate(t.name, t.value),
    textarea: ({ target: t }: ChangeEvent<HTMLTextAreaElement>) => handleUpdate(t.name, t.value),
    tinymcetextarea: (key: string, value: string) => handleUpdate(key, value),
    image: (key: string, image: JunoImageModel) => handleUpdate(key, image.url),
    number: ({ target: t }: ChangeEvent<HTMLInputElement>) =>
      handleUpdate(t.name, parseInt(t.value) || 0),
    file: ({ target: t }: ChangeEvent<HTMLInputElement>) => {
      const file = t.files?.[0];
      if (validateSelectedFileSize(file)) {
        setPayload({ ...payload, file });
      } else {
        setPayload({ ...payload, file: null });
      }
    },
    iconFile: (iconFile: File, iconUrl: string) => {
      setPayload((old) => {
        return { ...old, iconFile, iconUrl };
      });
    },
  };

  const onDrop = useCallback((file: File | null) => {
    if (!file) return;
    readImageFile(file, (b64Str) => changeHandler.iconFile(file, b64Str));
  }, []);

  if (isLoading) return <JunoSpin />;

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={7}>
          <TextField
            disabled={isSaving}
            id='title'
            name='title'
            label='Title'
            fullWidth
            margin='dense'
            value={payload?.resource?.title || ''}
            onChange={changeHandler.text}
            error={isTitleTouched && !isTitleValid()}
            onFocus={(e: any) => {
              setIsTitleTouched(true);
            }}
          />
          <FormHelperText error>
            {isTitleTouched &&
              !isTitleValid() &&
              (payload?.resource?.title
                ? 'Title must be less than 67 characters'
                : 'Title is required')}
          </FormHelperText>
          <TextField
            disabled={isSaving}
            id='description'
            name='description'
            label='Description'
            fullWidth
            margin='dense'
            value={payload?.resource?.description || ''}
            onChange={changeHandler.text}
            error={isDescriptionTouched && !isDescriptionValid()}
            onFocus={(e: any) => {
              setIsDescriptionTouched(true);
            }}
          />
          <FormHelperText error>
            {isDescriptionTouched &&
              !isDescriptionValid() &&
              (payload?.resource?.description
                ? 'Description must be less than 78 characters'
                : 'Description is required')}
          </FormHelperText>
        </Grid>
        <Grid item xs={6} sm={5}>
          <Box sx={{ mt: 1 }}>
            <JunoImageUpload
              style={{ aspectRatio: '16/9' }}
              src={optimizeImage(200, payload?.resource?.iconUrl || payload?.resource?.icon)}
              srcUrl={payload?.resource?.iconUrl || payload?.resource?.icon || payload?.iconUrl}
              aspectRatios={[ASPECT_RATIOS['SIXTEEN_NINE']]}
              onFileSelected={onDrop}
            />
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex' }}>
        <TextField
          disabled
          id='url'
          name='url'
          label='File'
          fullWidth
          margin='dense'
          variant='standard'
          value={payload?.file?.name || payload?.resource?.download?.url || ''}
        />
        <TextField
          disabled
          id='filetype'
          name='filetype'
          label='Type'
          margin='dense'
          sx={{ ml: 1 }}
          variant='standard'
          value={payload?.file?.type || payload?.resource?.download?.filetype || ''}
        />
        <Button
          variant='contained'
          sx={{ width: '270px', ml: 1, mt: 1, mb: 0.5 }}
          component='label'
          disabled={isSaving}
        >
          Upload File
          <Input
            type='file'
            inputProps={{
              accept: FILE_UPLOAD_TYPES.join(','),
              multiple: true,
            }}
            onChange={changeHandler.file}
            style={{ display: 'none' }}
          />
        </Button>
      </Box>
    </>
  );
};
