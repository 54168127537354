import { Box, Button, Card, Checkbox, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Container = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(2),
  width: 1000,
  maxWidth: '100%',
}));

export const DeleteGridItem = styled(Grid)(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
}));

export const DragIndicatorContainer = styled(Box)(({ theme }) => ({
  width: 35,
  minWidth: 35,
  background: 'rgba(255,255,255,.6)',
  height: '100%',
  position: 'absolute',
  left: 0,
  top: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'grab',
  color: theme.palette.text.primary,
  borderRadius: 0,
}));

export const AddAnswerButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(3),
  width: '100%',
}));

export const AnswerBox = styled(Card)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: 8,
  marginBottom: theme.spacing(2),
  position: 'relative',
  height: 69,
  display: 'flex',
  alignItems: 'center',
}));

export const CorrectCheckbox = styled(Checkbox)(() => ({
  padding: 4,
}));

export const PreviewCard = styled(Card)(() => ({
  width: '100%',
}));
