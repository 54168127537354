import * as Yup from 'yup';
import { string } from 'yup';

const dateError = 'Date is invalid';
const validationSchema = Yup.object().shape({
  title: string().max(90, 'Title character limit: 90').required('Title is required'),
  chip_label: string().max(30, 'Display Label cannot be more than 30 characters'),
  preview_text: string().max(90, 'Preview Text cannot be more than 90 characters'),
  slug: string()
    .max(90, 'Slug character limit is 90')
    .required('Slug is required')
    .test(
      'slug_no_space_or_special',
      'Slug cannot have spaces or special characters',
      function test(value) {
        if (value && value.length > 0) {
          return !/[^a-zA-Z0-9-_]/.test(value);
        } else {
          return true;
        }
      },
    ),
  date_start: Yup.date()
    .nullable()
    .typeError(dateError)
    .test('before-end', 'Date must be before the end date', function (this, value) {
      const date_end = this?.options?.context?.['date_end'];
      if (!value || !date_end) {
        return true;
      }
      return new Date(value).getTime() < new Date(date_end).getTime();
    }),
  date_end: Yup.date()
    .nullable()
    .typeError(dateError)
    .test('after-start', 'Date must be after the start date', function (this, value) {
      const date_start = this?.options?.context?.['date_start'];
      if (!value || !date_start) {
        return true;
      }
      return new Date(value).getTime() > new Date(date_start).getTime();
    }),
});

export default validationSchema;
