import React from 'react';
import {
  AccountTreeOutlined,
  FilterAltOutlined,
  FormatListBulletedOutlined,
  GridOnOutlined,
} from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  Tooltip,
} from '@mui/material';
import { useBreakpoint } from '@juno/utils';

interface SortOptions {
  value: string;
  label: string;
}
interface DefaultSearchSortFilterProps {
  buttonDisabled: boolean;
  buttonText: string;
  onClickButton: () => void;
  setSearch: (value: string) => void;
  showButton?: boolean;
  showSort?: boolean;
  sort?: string;
  setSort?: (value: string) => void;
  sortOptions?: SortOptions[];
  onClickFilter?: () => void;
  showFilter?: boolean;
  showLayoutOptions?: boolean;
  layoutMode?: 'grid' | 'list' | 'tree';
  setLayoutMode?: React.Dispatch<React.SetStateAction<'grid' | 'list' | 'tree'>>;
}
const DefaultSearchSortFilter: React.FC<DefaultSearchSortFilterProps> = ({
  buttonDisabled,
  buttonText,
  onClickButton,
  setSearch,
  sort,
  setSort,
  sortOptions,
  onClickFilter,
  showButton = true,
  showSort = true,
  showFilter = true,
  showLayoutOptions = false,
  layoutMode = 'grid',
  setLayoutMode = () => {},
}) => {
  const { xs } = useBreakpoint();

  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      {xs ? (
        <SmallScreen
          showButton={showButton}
          buttonDisabled={buttonDisabled}
          buttonText={buttonText}
          onClickButton={onClickButton}
          setSearch={setSearch}
          showSort={showSort}
          sort={sort}
          setSort={setSort}
          sortOptions={sortOptions}
          onClickFilter={onClickFilter}
          showFilter={showFilter}
          showLayoutOptions={showLayoutOptions}
          layoutMode={layoutMode}
          setLayoutMode={setLayoutMode}
        />
      ) : (
        <RegularScreen
          showButton={showButton}
          buttonDisabled={buttonDisabled}
          buttonText={buttonText}
          onClickButton={onClickButton}
          setSearch={setSearch}
          showSort={showSort}
          sort={sort}
          setSort={setSort}
          sortOptions={sortOptions}
          onClickFilter={onClickFilter}
          showFilter={showFilter}
          showLayoutOptions={showLayoutOptions}
          layoutMode={layoutMode}
          setLayoutMode={setLayoutMode}
        />
      )}
    </Box>
  );
};
export default DefaultSearchSortFilter;

// ? The main reason there are 2 of these is due to placement of the components. Otherwise, the components are the same.
const SmallScreen: React.FC<DefaultSearchSortFilterProps> = ({
  showButton,
  buttonDisabled,
  buttonText,
  onClickButton,
  setSearch,
  showSort,
  sort,
  setSort,
  sortOptions,
  onClickFilter,
  showFilter,
  showLayoutOptions,
  layoutMode,
  setLayoutMode,
}) => {
  return (
    <Stack direction={'column'} spacing={1} width={'100%'} justifyContent={'space-between'}>
      <Stack
        direction={'row'}
        spacing={1}
        width={'100%'}
        height={'auto'}
        justifyContent={'space-between'}
      >
        {showButton && (
          <Button
            disabled={buttonDisabled}
            variant='contained'
            onClick={onClickButton}
            size='small'
            sx={{
              width: '100%',
              textTransform: 'none',
              boxShadow: 'none',
              height: '40px',
              borderRadius: 2,
            }}
          >
            {buttonText}
          </Button>
        )}
        {showSort && (
          <Select
            value={sort}
            onChange={(e) => setSort && setSort(e.target.value as string)}
            size='small'
            sx={{ ...selectStyles, width: '100%', height: '40px' }}
            MenuProps={{
              PaperProps: {
                sx: selectMenuStyles,
              },
            }}
          >
            {sortOptions?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
        {showFilter && (
          <Tooltip title='Filter' placement='top'>
            <IconButton
              aria-label='show filters'
              onClick={onClickFilter}
              sx={{
                ...toggleButtonStyle,
                ml: 1,
                borderRadius: 2,
                width: 40,
              }}
            >
              <FilterAltOutlined />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack
        direction={'row'}
        spacing={1}
        width={'100%'}
        height={'auto'}
        justifyContent={'space-between'}
      >
        <TextField
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          hiddenLabel
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder='Search...' // The placeholder is not a param so that all search fields are consistent
          fullWidth
          size='small'
          sx={searchStyles}
        />
        {showLayoutOptions && (
          <ButtonGroup color='primary' variant='text' sx={{ ml: 1 }}>
            <ToggleButton
              value='grid'
              selected={layoutMode === 'grid'}
              onClick={() => setLayoutMode && setLayoutMode('grid')}
              sx={toggleButtonStyle}
              aria-label='Grid view'
            >
              <GridOnOutlined
                fontSize='small'
                color={layoutMode === 'grid' ? 'primary' : 'inherit'}
              />
            </ToggleButton>
            <ToggleButton
              value='list'
              selected={layoutMode === 'list'}
              onClick={() => setLayoutMode && setLayoutMode('list')}
              sx={toggleButtonStyle}
              aria-label='List view'
            >
              <FormatListBulletedOutlined
                fontSize='small'
                color={layoutMode === 'list' ? 'primary' : 'inherit'}
              />
            </ToggleButton>
            <ToggleButton
              value='tree'
              selected={layoutMode === 'tree'}
              onClick={() => setLayoutMode && setLayoutMode('tree')}
              sx={toggleButtonStyle}
              aria-label='List view'
            >
              <AccountTreeOutlined
                fontSize='small'
                color={layoutMode === 'tree' ? 'primary' : 'inherit'}
              />
            </ToggleButton>
          </ButtonGroup>
        )}
      </Stack>
      {/* // TODO: add to this component and remove from other areas */}
      {/* {filters.length > 0 && (
            <Box mt={2} mb={1}>
              {filters.map((tag) => (
                <Chip
                  key={`filter_${tag.id}`}
                  label={tag.value}
                  sx={{ mr: 1, mt: 1 }}
                  onDelete={() => setFilters((old) => old.filter((t) => t.id !== tag.id))}
                />
              ))}
            </Box>
          )} */}
    </Stack>
  );
};
const RegularScreen: React.FC<DefaultSearchSortFilterProps> = ({
  showButton,
  buttonDisabled,
  buttonText,
  onClickButton,
  setSearch,
  showSort,
  sort,
  setSort,
  sortOptions,
  onClickFilter,
  showFilter,
  showLayoutOptions,
  layoutMode,
  setLayoutMode,
}) => {
  return (
    <Stack
      spacing={1}
      width={'100%'}
      height={'40px'}
      justifyContent={'space-between'}
      direction={'row'}
    >
      {showButton && (
        <Button
          disabled={buttonDisabled}
          variant='contained'
          onClick={onClickButton}
          size='small'
          sx={{
            width: 200,
            mr: 2,
            textTransform: 'none',
            boxShadow: 'none',
            height: '40px',
            borderRadius: 2,
          }}
        >
          {buttonText}
        </Button>
      )}
      <TextField
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        hiddenLabel
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder='Search...' // The placeholder is not a param so that all search fields are consistent
        fullWidth
        size='small'
        sx={searchStyles}
      />
      {showSort && (
        <Select
          value={sort}
          onChange={(e) => setSort && setSort(e.target.value as string)}
          size='small'
          sx={selectStyles}
          MenuProps={{
            PaperProps: {
              sx: selectMenuStyles,
            },
          }}
        >
          {sortOptions?.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{
                borderRadius: 2,
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      {showFilter && (
        <Tooltip title='Filter' placement='top'>
          <IconButton
            aria-label='show filters'
            onClick={onClickFilter}
            sx={{
              ...toggleButtonStyle,
              ml: 1,
              borderRadius: 2,
              width: 40,
            }}
          >
            <FilterAltOutlined />
          </IconButton>
        </Tooltip>
      )}
      {showLayoutOptions && (
        <ButtonGroup color='primary' variant='text' sx={{ ml: 1 }}>
          <Tooltip title='Grid view' placement='top'>
            <ToggleButton
              value='grid'
              selected={layoutMode === 'grid'}
              onClick={() => setLayoutMode && setLayoutMode('grid')}
              sx={toggleButtonStyle}
              aria-label='Grid view'
            >
              <GridOnOutlined
                fontSize='small'
                color={layoutMode === 'grid' ? 'primary' : 'inherit'}
              />
            </ToggleButton>
          </Tooltip>
          <Tooltip title='List view' placement='top'>
            <ToggleButton
              value='list'
              selected={layoutMode === 'list'}
              onClick={() => setLayoutMode && setLayoutMode('list')}
              sx={{ ...toggleButtonStyle, ml: 1 }}
              aria-label='List view'
            >
              <FormatListBulletedOutlined
                fontSize='small'
                color={layoutMode === 'list' ? 'primary' : 'inherit'}
              />
            </ToggleButton>
          </Tooltip>
          <Tooltip title='Tree view' placement='top'>
            <ToggleButton
              value='tree'
              selected={layoutMode === 'tree'}
              onClick={() => setLayoutMode && setLayoutMode('tree')}
              sx={{ ...toggleButtonStyle, ml: 1 }}
              aria-label='List view'
            >
              <AccountTreeOutlined
                fontSize='small'
                color={layoutMode === 'tree' ? 'primary' : 'inherit'}
              />
            </ToggleButton>
          </Tooltip>
        </ButtonGroup>
      )}
      {/* // TODO: add to this component and remove from other areas */}
      {/* {filters.length > 0 && (
            <Box mt={2} mb={1}>
              {filters.map((tag) => (
                <Chip
                  key={`filter_${tag.id}`}
                  label={tag.value}
                  sx={{ mr: 1, mt: 1 }}
                  onDelete={() => setFilters((old) => old.filter((t) => t.id !== tag.id))}
                />
              ))}
            </Box>
          )} */}
    </Stack>
  );
};

const toggleButtonStyle = {
  border: '1px solid transparent',
  borderRadius: 2,
  '&:hover': {
    borderColor: 'rgba(0,0,0,.2)',
    backgroundColor: 'rgba(0,0,0,.05)',
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },
};

const selectStyles = {
  fontSize: '0.8rem',
  width: 200,
  bgcolor: 'white',

  borderRadius: 2,
  '&:hover': {
    borderColor: 'rgba(0,0,0,.1)',
    backgroundColor: 'rgba(0,0,0,.01)',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0,0,0,.2)',
      backgroundColor: 'rgba(0,0,0,.01)',
    },
  },
};

const selectMenuStyles = {
  borderRadius: 2,
  '.MuiButtonBase-root.MuiMenuItem-root': {
    fontSize: '0.8rem',
    borderRadius: 0,
    '&.Mui-selected': {
      backgroundColor: 'white',
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.05)',
    },
  },
  '.Mui-selected': {
    color: (theme: any) => theme.palette.primary.main,
    backgroundColor: 'white',
  },
};

const searchStyles = {
  bgcolor: 'white',
  borderRadius: 2,
  '.MuiInputBase-root': {
    borderRadius: 2,
  },
  '.MuiFilledInput-root': {
    backgroundColor: 'white',
    borderRadius: 2,
  },
  '.MuiOutlinedInput-notchedOutline': {
    '&:hover': {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
  },
  '&:hover': {
    borderColor: 'rgba(0,0,0,.1)',
    backgroundColor: 'rgba(0,0,0,.01)',
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0,0,0,.2)',
      backgroundColor: 'rgba(0,0,0,.01)',
    },
  },
};
