import _ from 'lodash';
import {
  EmailRecord as EmailRecordModel,
  Tag as TagModel,
  TypeBd0Enum as campaignType,
  FrequencyEnum as frequencyOptions,
} from '@juno/client-api/model';
import { CampaignRecipientSectionFormikValues } from './validationSchema';

const tags_string = (tags: TagModel[] | undefined | null) => {
  return tags?.map((tag) => tag.value).join('|');
};

const snackMessage = {
  save: 'DRAFT SAVED',
  update: 'DRAFT UPDATED',
  activate: 'CAMPAIGN ACTIVATED',
  deactivate: 'CAMPAIGN DEACTIVATED',
  error: 'CAMPAIGN ERROR',
};

type StatusProps = 'default' | 'loading' | 'loaded';

const getStatus = (is_active?: boolean | null | undefined, end_date?: Date | null | string) => {
  if (is_active && end_date && end_date < new Date()) {
    return 'Completed';
  }
  if (is_active) {
    return 'Active';
  } else {
    return 'Draft';
  }
};

const FrequencyMap = {
  [frequencyOptions.ONE_TIME]: 'One Time',
  [frequencyOptions.DAILY]: 'Daily',
  [frequencyOptions.WEEKLY]: 'Weekly',
  [frequencyOptions.MONTHLY]: 'Monthly',
  [frequencyOptions.AUTOMATIC]: 'Automatic',
};

interface RecipientProps {
  id: string;
  last_name: string;
  first_name: string;
  email: string;
}

function campaignListTabProps(index: number) {
  return {
    id: `tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const getChangedValues = (values: any, initialValues: any) => {
  return Object.entries(values).reduce((acc: any, [key, value]) => {
    const hasChanged = !_.isEqual(initialValues[key], value);

    if (hasChanged) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

const metadataIsChanged = (
  values: CampaignRecipientSectionFormikValues,
  initialValues: CampaignRecipientSectionFormikValues,
): boolean => {
  // Check if 'has_all' has changed
  if (values.metadata.has_all !== initialValues.metadata.has_all) {
    return true;
  }

  // Check if the 'metadata.tags' arrays have the same set of strings (regardless of order)
  const sortedTagsValues = values.metadata.tags ? [...values.metadata.tags].sort() : [];
  const sortedTagsInitial = initialValues.metadata.tags
    ? [...initialValues.metadata.tags].sort()
    : [];

  return !_.isEqual(sortedTagsValues, sortedTagsInitial);
};

const formattedAnalyticsTableDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  });
};

const getFirstName = (emailAddress: string) => {
  const regex = emailAddress.match(/^([^.@]+)/);
  if (!regex) return '';
  const firstName = regex[0];
  return firstName.charAt(0).toUpperCase() + firstName.slice(1);
};

enum EmailCampaignType {
  Welcome = 'WELCOME',
  CourseEnrollment = 'COURSE_ENROLLMENT',
  CourseWaitlist = 'COURSE_WAITLIST',
  NewForumTopic = 'NEW_FORUM_TOPIC',
  NewForumPost = 'NEW_FORUM_POST',
  NewFeedPost = 'NEW_FEED_POST',
  TaggedForumPost = 'TAGGED_FORUM_POST',
  TaggedFeedPost = 'TAGGED_FEED_POST',
  CommentForumPost = 'COMMENT_FORUM_POST',
  CommentFeedPost = 'COMMENT_FEED_POST',
  ReplyYourCommentForum = 'REPLY_YOUR_COMMENT_FORUM',
  ReplyYourCommentForumPost = 'REPLY_COMMENT_YOUR_FORUM_POST',
  ReplyYourCommentFeed = 'REPLY_YOUR_COMMENT_FEED',
  ReplyYourCommentFeedPost = 'REPLY_COMMENT_YOUR_FEED_POST',
  LikeForumPost = 'LIKE_FORUM_POST',
  LikeFeedPost = 'LIKE_FEED_POST',
  LikeYourForumComment = 'LIKE_YOUR_FORUM_COMMENT',
  LikeYourFeedComment = 'LIKE_YOUR_FEED_COMMENT',
  Custom = 'CUSTOM',
  // TODO: implement Forum Digest
  // ForumDigest = "FORUM_DIGEST",
}

const EmailCampaignTypeMap: Record<campaignType, string> = {
  [EmailCampaignType.Welcome]: 'Welcome Email',
  [EmailCampaignType.CourseEnrollment]: 'Course Enrollment',
  [EmailCampaignType.CourseWaitlist]: 'Course Waitlist',
  [EmailCampaignType.NewForumTopic]: 'New Forum Topic',
  [EmailCampaignType.NewForumPost]: 'New Forum Post',
  [EmailCampaignType.NewFeedPost]: 'New Feed Post',
  [EmailCampaignType.TaggedForumPost]: 'Tagged on Forum Post',
  [EmailCampaignType.TaggedFeedPost]: 'Tagged on Feed Post',
  [EmailCampaignType.CommentForumPost]: 'Comment on Forum Post',
  [EmailCampaignType.CommentFeedPost]: 'Comment on Feed Post',
  [EmailCampaignType.ReplyYourCommentForum]: 'Reply to Comment on Forum',
  [EmailCampaignType.ReplyYourCommentForumPost]: 'Reply to Comment on Forum Post',
  [EmailCampaignType.ReplyYourCommentFeed]: 'Reply to Comment on Feed',
  [EmailCampaignType.ReplyYourCommentFeedPost]: 'Reply to Comment on Feed Post',
  [EmailCampaignType.LikeForumPost]: 'Like on Forum Post',
  [EmailCampaignType.LikeFeedPost]: 'Like on Feed Post',
  [EmailCampaignType.LikeYourForumComment]: 'Like on Forum Comment',
  [EmailCampaignType.LikeYourFeedComment]: 'Like on Feed Comment',
  [EmailCampaignType.Custom]: 'Custom',
  // TODO: add Forum Digest
  // [EmailCampaignType.ForumDigest]: "Forum Digest",
};

const initialAnalyticsTypeObject = () => {
  // Initialize return object by adding a k/v pair for each EmailCampaign type where the key is the type and the value is an empty array
  return Object.values(EmailCampaignType).reduce((acc, type) => {
    acc[type] = [];
    return acc;
  }, {} as Record<EmailCampaignType, EmailRecordModel[]>);
};

const organizeAnalyticsTypeRecords = (
  initialEmailRecordsTypeObject: Record<EmailCampaignType, EmailRecordModel[]>,
  emailRecords: EmailRecordModel[],
) => {
  // Load the emailRecords into type-specific arrays
  return emailRecords.reduce((acc, idx) => {
    acc[idx.email_type].push(idx);
    return acc;
  }, initialEmailRecordsTypeObject);
};

const getUserPreferenceValue = (type: string, value: string) => {
  switch (type) {
    case EmailCampaignType.CourseEnrollment:
      return 'LMS Enrollment Activity';
    case EmailCampaignType.CourseWaitlist:
      return 'LMS Waitlist Activity';
    case EmailCampaignType.NewForumTopic:
      return 'New Forum Topics';
    case EmailCampaignType.NewForumPost:
      return 'Forum Activity';
    case EmailCampaignType.TaggedForumPost:
      return 'Tagged on a post';
    case EmailCampaignType.CommentForumPost:
      return 'Responses on posts & comments';
    case EmailCampaignType.ReplyYourCommentForum:
      return 'Responses on posts & comments';
    case EmailCampaignType.ReplyYourCommentForumPost:
      return 'Responses on posts & comments';
    case EmailCampaignType.LikeForumPost:
      return 'Likes on posts & comments';
    case EmailCampaignType.LikeYourForumComment:
      return 'Likes on posts & comments';
    case EmailCampaignType.NewFeedPost:
      return 'Social Feed Activity';
    case EmailCampaignType.TaggedFeedPost:
      return 'Tagged on a post';
    case EmailCampaignType.CommentFeedPost:
      return 'Responses on posts & comments';
    case EmailCampaignType.ReplyYourCommentFeed:
      return 'Responses on posts & comments';
    case EmailCampaignType.ReplyYourCommentFeedPost:
      return 'Responses on posts & comments';
    case EmailCampaignType.LikeFeedPost:
      return 'Likes on posts & comments';
    case EmailCampaignType.LikeYourFeedComment:
      return 'Likes on posts & comments';
    default:
      return value;
  }
};

// Exclude 'CUSTOM' from the type
type campaignTypesExcludingCustom = Exclude<keyof typeof campaignType, 'CUSTOM'>;
const EmailCampaignTypeToTemplateMap: { [key in campaignTypesExcludingCustom]: string } = {
  ['WELCOME']: 'welcome_email_1.0',
  ['COURSE_ENROLLMENT']: 'course_enrollment_waitlist_1.0',
  ['COURSE_WAITLIST']: 'course_enrollment_waitlist_1.0',
  ['NEW_FORUM_TOPIC']: 'forum_topic_new_1.0',
  ['NEW_FORUM_POST']: 'forum_post_new_1.0',
  ['NEW_FEED_POST']: 'feed_post_new_1.0',
  ['TAGGED_FORUM_POST']: 'forum_post_tagged_1.0',
  ['TAGGED_FEED_POST']: 'feed_post_tagged_1.0',
  ['COMMENT_FORUM_POST']: 'forum_post_comment_1.0',
  ['COMMENT_FEED_POST']: 'feed_post_comment_1.0',
  ['REPLY_YOUR_COMMENT_FORUM']: 'forum_post_your_comment_reply_1.0',
  ['REPLY_COMMENT_YOUR_FORUM_POST']: 'forum_post_comment_reply_1.0',
  ['REPLY_YOUR_COMMENT_FEED']: 'feed_post_your_comment_reply_1.0',
  ['REPLY_COMMENT_YOUR_FEED_POST']: 'feed_post_comment_reply_1.0',
  ['LIKE_FORUM_POST']: 'forum_post_liked_1.0',
  ['LIKE_FEED_POST']: 'feed_post_liked_1.0',
  ['LIKE_YOUR_FORUM_COMMENT']: 'forum_comment_liked_1.0',
  ['LIKE_YOUR_FEED_COMMENT']: 'feed_comment_liked_1.0',
  // TODO: add Forum Digest once the type is implemented
  // [EmailCampaignType.ForumDigest]: "forum_digest_1.0",
};

export {
  tags_string,
  getStatus,
  snackMessage,
  campaignListTabProps,
  getChangedValues,
  metadataIsChanged,
  EmailCampaignType,
  EmailCampaignTypeMap,
  getUserPreferenceValue,
  EmailCampaignTypeToTemplateMap,
  FrequencyMap,
  StatusProps,
  RecipientProps,
  formattedAnalyticsTableDate,
  initialAnalyticsTypeObject,
  organizeAnalyticsTypeRecords,
  getFirstName,
};
