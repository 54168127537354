import * as React from 'react';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';
import { useGetAllUsers } from '@juno/client-api';
import { Site as SiteModel } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import { RecipientProps } from '../../utils/utils';
import MailingListGrid from './MailingListGrid';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface MailingListProps {
  open: boolean;
  handleClose: () => void;
  hasAll: boolean;
  tags: string[];
  fileName: string;
  setRecipientsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setHeadCount: React.Dispatch<React.SetStateAction<number>>;
}

const MailingList: React.FC<MailingListProps> = ({
  open,
  handleClose,
  hasAll,
  tags,
  fileName,
  setRecipientsLoading,
  setHeadCount,
}) => {
  // Variables
  const { site } = useSettings();
  const [recipients, setRecipients] = useState<RecipientProps[]>([]);

  // Queries and mutations
  const { data: campaignRecipients, isLoading: campaignRecipientsLoading } = useGetAllUsers(
    site?.platform_id || '',
    {
      filter: hasAll ? undefined : { declared_tags__in: tags },
      declared_tags_and: hasAll ? { declared_tags__in: tags } : undefined,
    },
    { query: { enabled: !!site?.platform_id } },
  );

  // Functions
  useEffect(() => {
    if (campaignRecipients) {
      setHeadCount(campaignRecipients.length);
      setRecipients(
        campaignRecipients.map((user) => ({
          id: user.id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        })),
      );
    }
  }, [campaignRecipients]);

  // Tell the UI once the recipients have fully loaded
  useEffect(() => {
    if (!campaignRecipientsLoading) {
      setRecipientsLoading(false);
    }
  }, [campaignRecipientsLoading]);

  return (
    <Dialog open={open} onClose={handleClose} fullScreen TransitionComponent={Transition}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
            Mailing List Preview
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ width: '100%' }}
        >
          <Typography color='primary'>
            This campaign sends to users based on a regular schedule cadence. {recipients.length}{' '}
            recipients are forecasted to receive this email based on the selected tags.
          </Typography>
        </Stack>
      </Toolbar>
      <Divider />

      <MailingListGrid mailingList={recipients} hasAll={hasAll} fileName={fileName} />
    </Dialog>
  );
};

export default MailingList;
