import React, { SyntheticEvent } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Chip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { Tag } from '@juno/client-api/model';
import { GroupHeader, GroupItems } from '@juno/utils';

interface AutocompleteTagsProps {
  label: string;
  requireTags: Tag[];
  optionalTags: Tag[];
  excludeTags: Tag[];
  siteTags: Tag[] | undefined;
  handleChange: (event: SyntheticEvent, newValue: Tag[]) => void;
}
const AutocompleteTags: React.FC<AutocompleteTagsProps> = ({
  label,
  requireTags,
  optionalTags,
  excludeTags,
  siteTags,
  handleChange,
}) => {
  const tags = siteTags ?? [];
  tags?.sort((a, b) => -b?.value?.localeCompare(a?.value));

  const getValueByLabel = (label: string) => {
    if (label.includes('Require')) {
      return requireTags;
    } else if (label.includes('Optional')) {
      return optionalTags;
    } else {
      return excludeTags;
    }
  };

  const handleDisableTagOption = (tagFilterType: string, option: Tag) => {
    switch (tagFilterType) {
      case 'Require Tag(s)':
        return (
          (optionalTags?.length > 0 || excludeTags?.length > 0) &&
          (optionalTags?.some((tag) => tag.id === option.id) ||
            excludeTags?.some((tag) => tag.id === option.id))
        );
      case 'Optional Tag(s)':
        return (
          (requireTags?.length > 0 || excludeTags?.length > 0) &&
          (requireTags?.some((tag) => tag.id === option.id) ||
            excludeTags?.some((tag) => tag.id === option.id))
        );
      case 'Exclude Tag(s)':
        return (
          (requireTags?.length > 0 || optionalTags?.length > 0) &&
          (requireTags?.some((tag) => tag.id === option.id) ||
            optionalTags?.some((tag) => tag.id === option.id))
        );
      default:
        return false;
    }
  };

  const shrinkLabel = (label: string) => {
    if (label.includes('Require')) {
      return requireTags?.length > 0;
    } else {
      if (label.includes('Optional')) {
        return optionalTags?.length > 0;
      }
    }
    return excludeTags?.length > 0;
  };

  return (
    <>
      <Autocomplete
        disableCloseOnSelect
        multiple
        getOptionLabel={(option) => {
          return typeof option === 'string' ? option : option.value;
        }}
        value={getValueByLabel(label)}
        options={tags?.sort((a, b) => -b.type?.value?.localeCompare(a.type?.value)) ?? []}
        groupBy={(option) => option.type?.value}
        renderGroup={(params) => (
          <li key={params.key}>
            <GroupHeader style={{ zIndex: 1 }}>{params.group}</GroupHeader>
            <GroupItems>{params.children}</GroupItems>
          </li>
        )}
        onChange={handleChange}
        renderOption={(
          props: JSX.IntrinsicAttributes &
            React.ClassAttributes<HTMLLIElement> &
            React.LiHTMLAttributes<HTMLLIElement>,
          option,
          { selected }: any,
        ) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
              checkedIcon={<CheckBoxIcon fontSize='small' />}
              style={{ marginRight: 8, zIndex: 0 }}
              checked={selected || handleDisableTagOption(label, option)}
              disabled={handleDisableTagOption(label, option)}
            />
            <span style={{ color: handleDisableTagOption(label, option) ? 'gray' : 'inherit' }}>
              {`${option.value}`}
            </span>
          </li>
        )}
        renderTags={(tagList: Tag[], getTagProps: any) => {
          return tagList.map((option: Tag, index) => {
            return (
              <Chip
                {...getTagProps({ index })}
                color='default'
                label={<span>{option.value}</span>}
              ></Chip>
            );
          });
        }}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: shrinkLabel(label) }}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{params.InputProps?.endAdornment}</>,
              disableUnderline: true,
            }}
            variant='filled'
          />
        )}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value;
        }}
      />
    </>
  );
};

export default AutocompleteTags;
