import React, { useEffect } from 'react';
import { Button, Typography } from '@mui/material';
import { FormikProps } from 'formik';
import { SimpleDialog } from '@juno/ui';
import { SidebarSectionFormikValues } from '../utils/validationSchema';

interface ConfirmDeactivateCampaignDialogProps {
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  formik: FormikProps<SidebarSectionFormikValues>;
  handleDeactivateCampaign: () => Promise<void>;
  setSidebarSectionFormikValues: React.Dispatch<React.SetStateAction<SidebarSectionFormikValues>>;
}

const ConfirmDeactivateCampaignDialog: React.FC<ConfirmDeactivateCampaignDialogProps> = ({
  handleClose,
  formik,
  handleDeactivateCampaign,
  setSidebarSectionFormikValues,
}) => {
  // Variables
  const { values, setFieldValue } = formik;

  // Functions
  useEffect(() => {
    if (!values.is_active) {
      handleDeactivateCampaign().then(() => {
        handleClose(false);
      });
    }
  }, [values.is_active]);

  const deactivateCampaign = () => {
    setSidebarSectionFormikValues({ ...values, is_active: false });
    setFieldValue('is_active', false);
  };

  return (
    <SimpleDialog
      open={true}
      buttonTitle={'Deactivate now'}
      buttonOverrideProps={{ onClick: deactivateCampaign, sx: { ml: 2, mr: 2, mb: 3 } }}
    >
      <Typography>This campaign will end immediately upon deactivation.</Typography>
      <Typography>Please confirm that you would like to deactivate this campaign now.</Typography>
      <Button
        aria-label='cancel-deactivating-campaign-button'
        sx={{ mt: 4 }}
        fullWidth
        variant='outlined'
        onClick={() => {
          handleClose(false);
        }}
      >
        Cancel
      </Button>
    </SimpleDialog>
  );
};

export default ConfirmDeactivateCampaignDialog;
