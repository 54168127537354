/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type RoomTypeEnum = typeof RoomTypeEnum[keyof typeof RoomTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RoomTypeEnum = {
  NUMBER_1: 1,
  NUMBER_2: 2,
} as const;
