import { TypeBd0Enum as campaignType } from '@juno/client-api/model';

const USER_MAKING_ACTION_TRUNCATED_NAME = '@User';
const AUTHOR_OF_ORIGINAL_POST = 'Jane Doe';

const WelcomeEmailPayload = {
  last_name: 'LastName-TestRecipient',
  magic_link: 'https://www.junolive.com',
  site_nickname: 'Company Site',
  logo: 'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_company_logo.png',
  banner_image: 'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_banner_image_small.png',
};

const EnrollmentUpdateEmailPayload = {
  last_name: 'LastName-TestRecipient',
  brand_image: 'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_brand_image.png',
  post_title: 'Company Learning Course Enrollment Update!',
  course_title: 'Lorem Ipsum Learning Course',
  button_link: 'https://www.junolive.com',
  button_text: 'Go to course',
};

const NewTaggedLikedFeedEmailPayload = {
  brand_image: 'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_brand_image.png',
  user_making_action_truncated_name: USER_MAKING_ACTION_TRUNCATED_NAME,
  header_content_name: 'Welcome to Feed Post Header Content Area!',
  author_of_original_post_pic:
    'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_avatar_icon_small.png',
  author_of_original_post: AUTHOR_OF_ORIGINAL_POST,
  post_title: 'A lorem ipsum feed post update',
  post_description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sit amet accumsan tortor. Curabitur mollis, ' +
    'nulla at tincidunt vehicula, enim odio lacinia massa, a dictum dolor enim a lorem. Donec.',
  post_image: 'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_post_image.png',
  like_count: 20,
  comment_count: 5,
  button_link: 'https://www.junolive.com',
  button_text: 'Check it out',
};

const FeedPostCommentsEmailPayload = {
  brand_image: 'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_brand_image.png',
  user_making_action_truncated_name: USER_MAKING_ACTION_TRUNCATED_NAME,
  header_content_name: 'Welcome to Feed Post Header Content Area!',
  author_of_original_post_pic:
    'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_avatar_icon_small.png',
  author_of_original_post: AUTHOR_OF_ORIGINAL_POST,
  post_title: 'Comments on a lorem ipsum feed post',
  user_making_action_pic:
    'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_avatar_icon_small.png',
  user_making_action_full_name: 'Lorem Allen Ipsum',
  post_description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sit amet accumsan tortor. Curabitur mollis, ' +
    'nulla at tincidunt vehicula, enim odio lacinia massa, a dictum dolor enim a lorem. Donec.',
  button_link: 'https://www.junolive.com',
  button_text: 'Check it out',
};

const LikeYourFeedPostCommentEmailPayload = {
  brand_image: 'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_brand_image.png',
  user_making_action_truncated_name: USER_MAKING_ACTION_TRUNCATED_NAME,
  header_content_name: 'Welcome to Feed Post Header Content Area!',
  author_of_original_post_pic:
    'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_avatar_icon_small.png',
  author_of_original_post: AUTHOR_OF_ORIGINAL_POST,
  post_title: 'Check out who liked your feed post comment!',
  author_of_comment_pic:
    'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_avatar_icon_small.png',
  author_of_comment_full_name: 'Lorem Allen Ipsum',
  post_description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sit amet accumsan tortor. Curabitur mollis, ' +
    'nulla at tincidunt vehicula, enim odio lacinia massa, a dictum dolor enim a lorem. Donec.',
  like_count: 20,
  comment_count: 5,
  button_link: 'https://www.junolive.com',
  button_text: 'Check it out',
};

const NewForumTopicEmailPayload = {
  brand_image: 'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_brand_image.png',
  post_title: 'Check out this new forum topic!',
  button_link: 'https://www.junolive.com',
  button_text: 'Check it out',
};

const NewTaggedLikedForumEmailPayload = {
  brand_image: 'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_brand_image.png',
  user_making_action_truncated_name: USER_MAKING_ACTION_TRUNCATED_NAME,
  sub_header: 'Welcome to Forum Post Header Content Area!',
  author_of_original_post_pic:
    'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_avatar_icon_small.png',
  author_of_original_post: AUTHOR_OF_ORIGINAL_POST,
  post_title: 'A lorem ipsum forum post update',
  post_description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sit amet accumsan tortor. Curabitur mollis, ' +
    'nulla at tincidunt vehicula, enim odio lacinia massa, a dictum dolor enim a lorem. Donec.',
  post_image: 'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_post_image.png',
  like_count: 20,
  comment_count: 5,
  button_link: 'https://www.junolive.com',
  button_text: 'Check it out!',
};

const ForumPostCommentsEmailPayload = {
  brand_image: 'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_brand_image.png',
  user_making_action_truncated_name: USER_MAKING_ACTION_TRUNCATED_NAME,
  sub_header: 'Welcome to Forum Post Header Content Area!',
  author_of_original_post_pic:
    'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_avatar_icon_small.png',
  author_of_original_post: AUTHOR_OF_ORIGINAL_POST,
  post_title: 'Comments on a Lorem Ipsum forum post',
  user_making_action_pic:
    'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_avatar_icon_small.png',
  user_making_action_full_name: 'Lorem Allen Ipsum',
  post_description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sit amet accumsan tortor. Curabitur mollis, ' +
    'nulla at tincidunt vehicula, enim odio lacinia massa, a dictum dolor enim a lorem. Donec.',
  button_link: 'https://www.junolive.com',
  button_text: 'Check it out!',
};

const LikeYourForumPostCommentEmailPayload = {
  brand_image: 'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_brand_image.png',
  user_making_action_truncated_name: USER_MAKING_ACTION_TRUNCATED_NAME,
  sub_header: 'Welcome to Forum Post Header Content Area!',
  author_of_original_post_pic:
    'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_avatar_icon_small.png',
  author_of_original_post: AUTHOR_OF_ORIGINAL_POST,
  post_title: 'Check out who liked your forum post comments!',
  author_of_comment_pic:
    'https://cdn-juno.s3.amazonaws.com/assets/ui/generic_email_avatar_icon_small.png',
  author_of_comment_full_name: 'Lorem Allen Ipsum',
  post_description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque sit amet accumsan tortor. Curabitur mollis, ' +
    'nulla at tincidunt vehicula, enim odio lacinia massa, a dictum dolor enim a lorem. Donec.',
  like_count: 20,
  comment_count: 5,
  button_link: 'https://www.junolive.com',
  button_text: 'Check it out!',
};

const CampaignTypeToTestEmailSerializerFieldName: { [key in campaignType]: string } = {
  ['CUSTOM']: 'temporary_string',
  ['WELCOME']: 'welcome_email_fields',
  ['COURSE_ENROLLMENT']: 'enrollment_update_fields',
  ['COURSE_WAITLIST']: 'enrollment_update_fields',
  ['NEW_FORUM_TOPIC']: 'new_forum_topic_fields',
  ['NEW_FORUM_POST']: 'new_tagged_liked_forum_post_fields',
  ['NEW_FEED_POST']: 'new_tagged_liked_feed_post_fields',
  ['TAGGED_FORUM_POST']: 'new_tagged_liked_forum_post_fields',
  ['TAGGED_FEED_POST']: 'new_tagged_liked_feed_post_fields',
  ['COMMENT_FORUM_POST']: 'comments_forum_post_fields',
  ['COMMENT_FEED_POST']: 'comments_feed_post_fields',
  ['REPLY_YOUR_COMMENT_FORUM']: 'comments_forum_post_fields',
  ['REPLY_COMMENT_YOUR_FORUM_POST']: 'comments_forum_post_fields',
  ['REPLY_YOUR_COMMENT_FEED']: 'comments_feed_post_fields',
  ['REPLY_COMMENT_YOUR_FEED_POST']: 'comments_feed_post_fields',
  ['LIKE_FORUM_POST']: 'new_tagged_liked_forum_post_fields',
  ['LIKE_FEED_POST']: 'new_tagged_liked_feed_post_fields',
  ['LIKE_YOUR_FORUM_COMMENT']: 'like_your_forum_comment_fields',
  ['LIKE_YOUR_FEED_COMMENT']: 'like_your_feed_comment_fields',
};

const CampaignTypeToTestEmailPayload: { [key in campaignType]: object } = {
  ['CUSTOM']: {},
  ['WELCOME']: WelcomeEmailPayload,
  ['COURSE_ENROLLMENT']: EnrollmentUpdateEmailPayload,
  ['COURSE_WAITLIST']: EnrollmentUpdateEmailPayload,
  ['NEW_FORUM_TOPIC']: NewForumTopicEmailPayload,
  ['NEW_FORUM_POST']: NewTaggedLikedForumEmailPayload,
  ['NEW_FEED_POST']: NewTaggedLikedFeedEmailPayload,
  ['TAGGED_FORUM_POST']: NewTaggedLikedForumEmailPayload,
  ['TAGGED_FEED_POST']: NewTaggedLikedFeedEmailPayload,
  ['COMMENT_FORUM_POST']: ForumPostCommentsEmailPayload,
  ['COMMENT_FEED_POST']: FeedPostCommentsEmailPayload,
  ['REPLY_YOUR_COMMENT_FORUM']: ForumPostCommentsEmailPayload,
  ['REPLY_COMMENT_YOUR_FORUM_POST']: ForumPostCommentsEmailPayload,
  ['REPLY_YOUR_COMMENT_FEED']: FeedPostCommentsEmailPayload,
  ['REPLY_COMMENT_YOUR_FEED_POST']: FeedPostCommentsEmailPayload,
  ['LIKE_FORUM_POST']: NewTaggedLikedForumEmailPayload,
  ['LIKE_FEED_POST']: NewTaggedLikedFeedEmailPayload,
  ['LIKE_YOUR_FORUM_COMMENT']: LikeYourForumPostCommentEmailPayload,
  ['LIKE_YOUR_FEED_COMMENT']: LikeYourFeedPostCommentEmailPayload,
};

const email_type_to_unsubscribe_group_mapping: { [key in campaignType]: string } = {
  COURSE_ENROLLMENT: 'LMS Enrollment Activity',
  COURSE_WAITLIST: 'LMS Waitlist Activity',
  NEW_FORUM_TOPIC: 'New Forum Topics',
  NEW_FORUM_POST: 'Forum Activity',
  NEW_FEED_POST: 'Social Feed Activity',
  TAGGED_FORUM_POST: 'Tagged on a post',
  TAGGED_FEED_POST: 'Tagged on a post',
  COMMENT_FORUM_POST: 'Responses on posts & comments',
  COMMENT_FEED_POST: 'Responses on posts & comments',
  REPLY_YOUR_COMMENT_FORUM: 'Responses on posts & comments',
  REPLY_COMMENT_YOUR_FORUM_POST: 'Responses on posts & comments',
  REPLY_YOUR_COMMENT_FEED: 'Responses on posts & comments',
  REPLY_COMMENT_YOUR_FEED_POST: 'Responses on posts & comments',
  LIKE_FORUM_POST: 'Likes on posts & comments',
  LIKE_YOUR_FEED_COMMENT: 'Likes on posts & comments',
  LIKE_YOUR_FORUM_COMMENT: 'Likes on posts & comments',
  LIKE_FEED_POST: 'Likes on posts & comments',
  CUSTOM: 'lorem ipsum holder',
  WELCOME: 'lorem ipsum holder',
};

export {
  CampaignTypeToTestEmailPayload,
  CampaignTypeToTestEmailSerializerFieldName,
  email_type_to_unsubscribe_group_mapping,
  WelcomeEmailPayload,
};
