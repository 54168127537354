import React from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { Box, Card, Divider, Tab, Tabs, Typography } from '@mui/material';
import { Site } from '@juno/client-api/model';
import { AdminDefaultViewHeader, Container } from '@juno/ui';
import ExportTab from './ExportTab';
import ImportTab from './ImportTab';
import ChangeLogs from './powerdump/ChangeLogs';

interface ImportExportProps {
  site: Site;
}
export const ImportExport: React.FC<ImportExportProps> = ({ site }) => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setCurrentTab(newTab);
  };

  const TABS = [
    {
      slug: 'import',
      key: 'import-tab',
      icon: <FileUploadOutlinedIcon sx={{ mr: 2 }} />,
      label: 'Import Content',
      color: '#C7169C',
      panel: <ImportTab site={site} currentTab={currentTab} />,
    },
    // {
    //   slug: 'export',
    //   key: 'export-tab',
    //   icon: <FileDownloadOutlinedIcon sx={{ mr: 2 }} />,
    //   label: 'Export Content',
    //   color: '#C7169C',
    //   panel: <ExportTab site={site} />,
    // },
    {
      slug: 'changes',
      key: 'import-export-changes-tab',
      icon: <QueryBuilderIcon sx={{ mr: 2 }} />,
      label: 'Change Log',
      color: '#C7169C',
      panel: <ChangeLogs siteId={site.id} />,
    },
  ];

  return (
    <Container>
      <Card sx={{ p: 2 }}>
        <Typography variant='h5'>Importer</Typography>
        <Divider sx={{ mt: 2, mb: 4 }} />
        <Box key='import-export-tabs' role='tabpanel' hidden={false}>
          <Tabs value={currentTab} onChange={handleTabChange}>
            {TABS.map((tab, idx) => (
              <Tab
                key={tab.key}
                icon={tab.icon}
                iconPosition='start'
                label={tab.label}
                id={`tab-${idx}`}
                aria-controls={`tabpanel-${idx}`}
                sx={{ textTransform: 'none', letterSpacing: '1px' }}
              />
            ))}
          </Tabs>
          {TABS[currentTab].panel}
        </Box>
      </Card>
    </Container>
  );
};
