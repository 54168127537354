import * as React from 'react';
import { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { useGetAllImportRecords } from '@juno/client-api';
import { ImportRecord } from '@juno/client-api/model';
import { JunoSpin } from '@juno/ui';
import { capitalize } from '../utils';

const formatDateTime = (params: GridValueFormatterParams) => {
  try {
    const date = new Date(params.value);
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('en-US', {
      month: 'short',
    });
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const mins = String(date.getMinutes()).padStart(2, '0');
    return `${day} ${month}, ${year} ${hours}:${mins}`;
  } catch (err) {
    return 'Date not formatted correctly';
  }
};

const columns: GridColDef[] = [
  {
    field: 'date_created',
    headerName: 'Date',
    width: 200,
    editable: false,
    renderHeader: (value) => <strong>{capitalize('Date Created')}</strong>,
    valueFormatter: formatDateTime,
  },
  {
    field: 'user',
    headerName: 'Test',
    width: 200,
    editable: false,
    renderHeader: (value) => <strong>{capitalize(value.field)}</strong>,
    valueGetter: (params: GridValueGetterParams) => {
      if (params.row?.admin) {
        return params.row?.admin.email;
      }
      if (params.row?.user) {
        return params.row?.user.email;
      }
      return 'User not found';
    },
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 200,
    editable: false,
    renderHeader: (value) => <strong>{capitalize(value.field)}</strong>,
  },
  {
    field: 'csv_url',
    headerName: 'csv_url',
    editable: false,
  },
  {
    field: 'site_id',
    headerName: 'site_id',
    editable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    editable: false,
    renderHeader: (value) => <strong>{capitalize(value.field)}</strong>,
    valueGetter: (params: GridValueGetterParams) => {
      switch (params.value) {
        case 0:
          return 'Pending';
        case 1:
          return 'Processing';
        case 2:
          return 'Complete';
        default:
          //case -1:
          return 'Error';
      }
    },
  },
];

interface ChangeLogsProps {
  siteId: string;
}

const ChangeLogs: React.FC<ChangeLogsProps> = ({ siteId }) => {
  const { data: importRecords, isLoading: isLoadingImportRecords } = useGetAllImportRecords(siteId);
  const [open, setOpen] = useState(false);
  const [chosenRows, setChosenRows] = useState<(ImportRecord | undefined)[]>([]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleView = (row: any) => {
    handleClose();
  };

  const handleDownload = () => {
    // we have to use iframes here in order to download multiple files at the same time due to browser security
    chosenRows.forEach((row) => {
      const iframe = document.createElement('iframe');
      iframe.style.visibility = 'collapse';
      document.body.append(iframe);

      iframe?.contentDocument &&
        iframe.contentDocument.write(
          `<form action="https://${process.env.NX_AWS_BUCKET}.s3.us-east-2.amazonaws.com/${row?.csv_url}" method="GET"></form>`,
        );
      iframe?.contentDocument && iframe.contentDocument.forms[0].submit();

      setTimeout(() => iframe.remove(), 2000);
    });
    handleClose();
  };

  if (!columns || isLoadingImportRecords) {
    return <JunoSpin />;
  }

  return (
    <>
      <Card sx={{ height: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ m: 2 }} variant='h6'>
            Change Logs
          </Typography>
          <Button
            onClick={handleDownload}
            sx={{ textTransform: 'none', m: 2 }}
            disabled={chosenRows.length === 0}
          >
            Download Selection
          </Button>
        </Box>
        <Box>
          <DataGrid
            initialState={{
              sorting: {
                sortModel: [{ field: 'date_created', sort: 'desc' }],
              },
            }}
            columnVisibilityModel={{
              csv_url: false,
              site_id: false,
            }}
            rows={importRecords ?? []}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10, 20]}
            disableSelectionOnClick
            checkboxSelection
            autoHeight
            onSelectionModelChange={(ids) => {
              const selectedRows = ids.map((id) => importRecords?.find((row) => row.id === id));
              setChosenRows(selectedRows);
            }}
            sx={{ pl: 1 }}
          />
        </Box>
      </Card>
      <Dialog open={open}>
        <DialogTitle justifyContent='space-between' display='flex'>
          Chosen row
          <ClearIcon onClick={handleClose} sx={{ cursor: 'pointer' }} />
        </DialogTitle>
        <DialogContent>Would you like to view or download?</DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button disabled={true} onClick={handleView}>
            View
          </Button>
          <Button onClick={handleDownload}>Download</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangeLogs;
