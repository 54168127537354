import React from 'react';
import {
  Group as GroupIcon,
  MarkEmailUnreadOutlined as MarkEmailUnreadOutlinedIcon,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { EmailCampaign, TypeBd0Enum as campaignType } from '@juno/client-api/model';
import { getScheduleString } from '@juno/utils';
import StatusIcon from '../utils/StatusIcon';
import { getStatus } from '../utils/utils';

interface EmailCampaignListItemProps {
  emailCampaign: EmailCampaign;
}

const EmailCampaignListItem: React.FC<EmailCampaignListItemProps> = ({ emailCampaign }) => {
  const status = getStatus(emailCampaign.is_active, emailCampaign.schedule?.end_date);
  const recipientCount = emailCampaign.metadata?.recipient_count;
  const cardTitle = () => (
    <Stack direction={'row'} justifyContent='space-between' alignItems='center'>
      <Box display='flex' alignItems='center'>
        <Box mr={1} display='flex'>
          <MarkEmailUnreadOutlinedIcon color='primary' />
        </Box>
        <Box sx={{ fontSize: '14px', fontWeight: '600' }}>{emailCampaign.name}</Box>
      </Box>
      <StatusIcon status={status} />
    </Stack>
  );

  return (
    <Card sx={{ borderRadius: '10px', width: '100%', mb: 2 }}>
      <CardActionArea>
        <CardHeader title={cardTitle()} sx={{ padding: '20px 20px 0 20px' }} />
        <CardContent sx={{ padding: '0 20px 20px 20px', display: 'flex', width: '100%' }}>
          <Stack
            sx={{ width: '100%' }}
            direction={'row'}
            justifyContent='space-between'
            alignItems='end'
          >
            <Box sx={{ display: 'flex' }}>
              {emailCampaign.type === campaignType.CUSTOM && (
                <>
                  {typeof recipientCount === 'number' && (
                    <Typography sx={{ color: 'gray', mr: 1 }}>{recipientCount}</Typography>
                  )}
                  <GroupIcon sx={{ mr: 2, color: 'gray' }} />
                </>
              )}
              <Box sx={{ mt: 1 }}>{getScheduleString(emailCampaign.schedule)}</Box>
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default EmailCampaignListItem;
