import React, { useEffect, useMemo, useState } from 'react';
import { AutorenewOutlined, LocationOnOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Grid,
  List,
  ListItem,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { QueryObserverResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getSession, useAttendUnattendSession, useGetAllSessions } from '@juno/client-api';
import { JunoUser, ReoccurrenceEnum, Session } from '@juno/client-api/model';
import { Container, DefaultSearchSortFilter } from '@juno/ui';
import {
  MutationAction,
  determineSessionToShow,
  onMutation,
  useBreakpoint,
  usePagination,
  useSessionsSearchFilter,
  useSettings,
} from '@juno/utils';
import AttendIcon from './AttendIcon';
import StateIcon from './StateIcon';
import { ScheduleDataModel } from './form';

interface EnhancedSession extends Session {
  attendee_list?: JunoUser[];
}

interface ScheduleProps {
  sessions: EnhancedSession[];
  isLoading: boolean;
  refetchSessions: () => Promise<QueryObserverResult<Session[], unknown>> | void;
  page: number;
  limit: number;
  sort?: string;
  setSort?: (sort: string) => void;
  setSearch?: React.Dispatch<React.SetStateAction<string | null>>;
  ref?: (node?: Element | null | undefined) => void;
  setData?: React.Dispatch<React.SetStateAction<any[]>>;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  showSearchFilter?: boolean;
}

const Schedule: React.FC<ScheduleProps> = ({
  sessions,
  isLoading,
  refetchSessions,
  sort,
  setSort,
  setSearch,
  ref,
  setData,
  setPage,
  page,
  limit,
  showSearchFilter = true,
}) => {
  const { user: currentUser, site } = useSettings();
  const { xs, sm } = useBreakpoint();
  const navigateTo = useNavigate();

  // TODO need to somehow dynamically set the scrollRefs
  const scrollRefs: { [date: string]: React.RefObject<HTMLUListElement> } = {};
  const [verticalBarHeights, setVerticalBarHeights] = useState<{ [date: string]: number }>({});

  const [loading, setLoading] = useState(false);

  const attendUnattendSession = useAttendUnattendSession(
    onMutation(MutationAction.UPDATE, '', refetchSessions),
  );

  const handleAttendButtonClick = async (session: Session) => {
    setLoading(true);

    let iAmAttending = false;

    if (session.original_session) {
      const actualSession = await getSession(site?.id || '', session.original_session);
      iAmAttending = actualSession.scheduled_attendees
        ? actualSession.scheduled_attendees?.filter((user) => user.id === currentUser?.id).length >
          0
        : false;
    } else {
      iAmAttending = session.scheduled_attendees
        ? session.scheduled_attendees?.filter((user) => user.id === currentUser?.id).length > 0
        : false;
    }
    const attendData = { user_id: currentUser?.id || '', is_add: !iAmAttending };
    await attendUnattendSession.mutateAsync({
      siteId: site?.id || '',
      sessionId: session.slug,
      data: attendData,
    });
    await refetchSessions();
    setLoading(false);
  };

  const handleSessionSelect = (item: Session) => {
    if (!site) return;
    navigateTo(`/${site.slug}/sessions/${item.slug}`);
  };

  const groupedSessions: { [date: string]: Session[] } = useMemo(() => {
    const result: { [date: string]: Session[] } = {};
    sessions.forEach((session) => {
      const date = new Date(session.date_start || '').toLocaleDateString();
      if (!result[date]) {
        result[date] = [];
      }
      result[date].push(session);
    });
    return result;
  }, [sessions]);

  const handleDateClick = (date: Date) => {
    // TODO this doesnt work yet since scrollRefs is not set
    const dateString = date.toLocaleDateString();
    if (scrollRefs[dateString]?.current) {
      scrollRefs[dateString].current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    // Calculate the height of the vertical bar based on the content for each section
    const updatedVerticalBarHeights = Object.entries(groupedSessions).reduce(
      (heights, [date, sessions]) => {
        const listHeight =
          sessions.length * 100 + // Adjusted the height of a session card
          sessions.length * 2 + // Adjusted the margin/padding between session cards
          1; // For the border

        return {
          ...heights,
          [date]: listHeight,
        };
      },
      {},
    );

    setVerticalBarHeights(updatedVerticalBarHeights);
  }, [groupedSessions]);

  return (
    <Container>
      <Box pt={1}>
        {showSearchFilter && (
          <DefaultSearchSortFilter
            buttonDisabled={true}
            showButton={false}
            buttonText={''}
            // ? removing sort for now but we may implement later
            // sort={sort}
            // sortOptions={[
            //   { value: 'title', label: 'Alphabetical' },
            //   { value: 'date_start', label: 'Start Date' },
            // ]}
            // setSort={(value) => {
            //   setData && setData([]);
            //   setSort && setSort(value);
            // }}
            showSort={false}
            onClickButton={() => {}}
            setSearch={(value) => setSearch && setSearch(value)}
            showFilter={false}
          />
        )}
        {!sessions ? (
          <ScheduleSkeleton />
        ) : (
          <List sx={{ flex: 1, width: '100%' }}>
            {Object.entries(groupedSessions).map(([date, sessions], index) => (
              <Box key={date}>
                <ListItem sx={{ p: 0, mt: 1 }}>
                  <Box display='flex' justifyContent='flex-start' width='100%'>
                    <Paper
                      style={{
                        boxShadow: 'none',
                        backgroundColor: 'transparent',
                        height: verticalBarHeights[date] || 'auto',
                        width: '80px',
                        overflowY: 'auto',
                        position: 'relative',
                      }}
                    >
                      <Box
                        key={date}
                        style={{
                          textAlign: 'center',
                          marginRight: '8px',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleDateClick(new Date(date))}
                      >
                        <Typography variant='body2' component='div'>
                          {new Date(date).toLocaleString('default', { month: 'short' })}{' '}
                          {new Date(date).getDate()}
                        </Typography>
                      </Box>
                      <div
                        style={{
                          position: 'absolute',
                          top: '30px',
                          bottom: '0',
                          left: '50%',
                          borderLeft: '1px solid #ddd',
                          transform: 'translateX(-50%)',
                        }}
                      />
                    </Paper>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <List ref={scrollRefs[date]} sx={{ width: '100%' }}>
                        {sessions.map((initialSession: EnhancedSession, index) => {
                          const session = determineSessionToShow(initialSession) as EnhancedSession;
                          return (
                            <ListItem key={session.id} sx={{ p: '2px' }}>
                              <Card sx={{ height: '100%', width: '100%' }}>
                                <Grid container height='100%' sx={{ minHeight: '100px' }}>
                                  <Grid item xs={1}>
                                    <Stack
                                      p={1}
                                      spacing={1}
                                      height={'100%'}
                                      justifyContent={'space-between'}
                                      flexDirection={'column'}
                                    >
                                      <StateIcon
                                        title={session.title}
                                        description={
                                          session.description || session.preview_text || ''
                                        }
                                        location={session.location || 'Online'}
                                        dateStart={session.date_start || ''}
                                        dateEnd={session.date_end || ''}
                                        handleClick={() => handleSessionSelect(session)}
                                        reoccurrence={session.reoccurrence || ReoccurrenceEnum.once}
                                      />
                                      {/* // TODO this isnt updating the ux correctly even tho the refetch works */}
                                      {currentUser && (
                                        <AttendIcon
                                          sessionId={session.original_session || session.id}
                                          dateEnd={session.date_end || ''}
                                          scheduled_attendees={
                                            session?.attendee_list ||
                                            session.scheduled_attendees ||
                                            []
                                          }
                                          currentUser={currentUser}
                                          handleClick={() => handleAttendButtonClick(session)}
                                          isLoading={loading}
                                        />
                                      )}
                                    </Stack>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'flex-start',
                                      justifyContent: 'space-between',
                                      p: 1,
                                    }}
                                  >
                                    <Typography variant='body1' fontSize={xs ? '0.7rem' : '1rem'}>
                                      {formatTime(session.date_start || '', session.date_end || '')}
                                    </Typography>
                                    <Typography
                                      variant='body1'
                                      display={'flex'}
                                      justifyContent={'center'}
                                      justifySelf={'flex-start'}
                                      pt={1}
                                      fontSize={xs ? '0.7rem' : '1rem'}
                                    >
                                      {!xs && <LocationOnOutlined fontSize='small' />}
                                      {session.location || 'Online'}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={xs ? 5 : 6} pt={1} pb={1} pr={1} sx={{ flex: 1 }}>
                                    <Box
                                      display={'flex'}
                                      justifyContent={'flex-start'}
                                      alignItems={'center'}
                                    >
                                      {session.reoccurrence !== ReoccurrenceEnum.once && (
                                        <Tooltip
                                          title={`Session recurs ${session.reoccurrence}`}
                                          placement='top'
                                        >
                                          <AutorenewOutlined fontSize='small' sx={{ pr: '2px' }} />
                                        </Tooltip>
                                      )}
                                      <Tooltip title={session.title} placement='top-start'>
                                        <Typography
                                          fontWeight={'bold'}
                                          onClick={() => handleSessionSelect(session)}
                                          sx={{
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            display: '-webkit-box',
                                            WebkitLineClamp: 1,
                                            WebkitBoxOrient: 'vertical',
                                            textOverflow: 'ellipsis',
                                          }}
                                          fontSize={xs ? '0.8rem' : '1rem'}
                                        >
                                          {session.title}
                                        </Typography>
                                      </Tooltip>
                                    </Box>
                                    <Typography
                                      variant='body2'
                                      sx={{
                                        fontStyle: 'italic',
                                      }}
                                      fontSize={xs ? '0.7rem' : '0.9rem'}
                                    >
                                      {session.preview_text}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={xs ? 3 : 2}
                                    onClick={() => handleSessionSelect(session)}
                                    sx={{
                                      cursor: 'pointer',
                                      backgroundImage: `url(${session.icon})`,
                                      backgroundSize: 'cover',
                                      backgroundPosition: 'center',
                                    }}
                                  ></Grid>
                                </Grid>
                              </Card>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  </Box>
                </ListItem>

                {index === Object.entries(groupedSessions).length - 1 &&
                  sessions.length % limit === 0 && (
                    <Button
                      variant='text'
                      onClick={() => {
                        setPage && setPage((page) => page + 1);
                      }}
                    >
                      Load More
                    </Button>
                  )}
              </Box>
            ))}
            {isLoading && (
              <Skeleton height={180} sx={{ mt: 2, transform: 'none', transformOrigin: '0 0' }} />
            )}
            {!isLoading &&
              Object.entries(groupedSessions) &&
              Object.entries(groupedSessions)?.length % PAGE_SIZE === 0 && (
                <Box ref={ref} sx={{ height: 20 }}></Box>
              )}
          </List>
        )}
      </Box>
    </Container>
  );
};

export default Schedule;

const PAGE_SIZE = 20;
interface ScheduleModuleProps {
  component?: ScheduleDataModel;
}
export const ScheduleModule: React.FC<ScheduleModuleProps> = ({ component }) => {
  // This takes the component data and determines which sessions to pass to the Schedule component.
  const { site } = useSettings();
  const [sort, setSort] = useState('date_start');
  const [search, debouncedSearch, searchFilter, setSearch] = useSessionsSearchFilter();
  const [page, setPage] = useState(0);
  const dateFilter = useMemo(() => {
    let filter = {};
    if (component?.hide_past_sessions) {
      filter = {
        ...filter,
        date_end__gte: new Date(),
      };
    }
    if (component?.hide_upcoming_sessions) {
      filter = {
        ...filter,
        date_start__lte: new Date(new Date().setHours(23, 59, 59, 999)),
      };
    }
    if (component?.hide_in_person_sessions) {
      filter = {
        ...filter,
        inperson: false,
      };
    }
    if (component?.display_label_filter) {
      filter = {
        ...filter,
        chip_label__icontains: component.display_label_filter?.toLowerCase(),
      };
    }
    return filter;
  }, [component]);

  useEffect(() => {
    setData([]);
  }, [component]);

  const {
    data: sessions,
    isLoading: isLoadingSessions,
    isFetching: isFetchingSessions,
    refetch,
  } = useGetAllSessions(
    site?.id || '',
    {
      order: sort,
      filter: { recurring_sessions_include: 'true', active: true, ...dateFilter },
      filter_or: searchFilter,
      limit: component?.limit || PAGE_SIZE,
      offset: page,
    },
    {
      query: {
        enabled: !!site?.id,
      },
    },
  );
  const [data, setData, ref, isLoading] = usePagination(
    sessions,
    isLoadingSessions,
    isFetchingSessions,
    search,
    debouncedSearch,
    setPage,
    true,
  );

  if (!component) {
    return null;
  }

  return (
    <Schedule
      page={page}
      limit={component?.limit || PAGE_SIZE}
      sessions={data}
      refetchSessions={refetch}
      sort={sort}
      setSort={setSort}
      setSearch={setSearch}
      ref={ref}
      setData={setData}
      isLoading={isLoading}
      setPage={setPage}
    />
  );
};

export const ScheduleSkeleton = () => {
  return (
    <Card>
      <Skeleton variant='rectangular' height='120px' width='100%' sx={{ mb: 2 }}></Skeleton>
      <Skeleton variant='rectangular' height='120px' width='100%' sx={{ mb: 2 }}></Skeleton>
      <Skeleton variant='rectangular' height='120px' width='100%' sx={{ mb: 2 }}></Skeleton>
    </Card>
  );
};

const formatTime = (dateStartString: string, dateEndString: string): string => {
  const dateStart = new Date(dateStartString);
  const dateEnd = new Date(dateEndString);

  const startHours = dateStart.getHours();
  const startMinutes = dateStart.getMinutes();
  const endHours = dateEnd.getHours();
  const endMinutes = dateEnd.getMinutes();

  const startPeriod = startHours >= 12 ? 'pm' : 'am';
  const endPeriod = endHours >= 12 ? 'pm' : 'am';

  const formattedStartHours = startHours % 12 || 12;
  const formattedEndHours = endHours % 12 || 12;

  const formattedStartMinutes = startMinutes < 10 ? '0' + startMinutes : startMinutes;
  const formattedEndMinutes = endMinutes < 10 ? '0' + endMinutes : endMinutes;

  if (dateStart.toDateString() === dateEnd.toDateString()) {
    // Same day
    return `${formattedStartHours}:${formattedStartMinutes}${startPeriod} - ${formattedEndHours}:${formattedEndMinutes}${endPeriod}`;
  } else {
    // Different days
    return `${formattedStartHours}:${formattedStartMinutes}${startPeriod} - ${dateEnd.toLocaleString(
      'default',
      { month: 'short' },
    )} ${dateEnd.getDate()} @${formattedEndHours}:${formattedEndMinutes}${endPeriod}`;
  }
};
