import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DailyCall } from '@daily-co/daily-js';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import { Tooltip, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { Session } from '@juno/client-api/model';
import * as APP_MESSAGE_ACTIONS from '../../constants';
import { useCallState } from '../../contexts/CallProvider';
import { useChat } from '../../contexts/ChatProvider';
import { TrayIcon } from '../../styles/tray';

interface CustomLeaveButtonProps {
  onClick: () => void;
}

const CustomLeaveButton: React.FC<CustomLeaveButtonProps> = ({ onClick }) => {
  const { endBreakoutMessage, switchRooms } = useChat();
  const [message, setMessage] = useState('');
  const { roomInfo, callObject } = useCallState();
  const sessionData = useMemo(() => {
    if (roomInfo) {
      return (roomInfo as Record<string, unknown>)['session'] as Session;
    }
    return {} as Session;
  }, [roomInfo]);

  const waitTimeRef = useRef<number | null>(null);

  const clickButton = async () => {
    try {
      onClick();
      await (callObject as unknown as DailyCall)?.leave();
      if (message !== '') {
        setMessage('');
        switchRooms(endBreakoutMessage.main_url);
      } else if (sessionData?.subbreakout) {
        const parentSession = localStorage.getItem('parentSession');
        if (parentSession) {
          localStorage.removeItem('parentSession');
          switchRooms(parentSession);
        } else {
          console.error('Parent session not found');
        }
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    if (endBreakoutMessage && endBreakoutMessage.wait_time) {
      const waitTime = endBreakoutMessage.wait_time;
      if (waitTime) {
        const timeInMs = () => {
          switch (waitTime) {
            case APP_MESSAGE_ACTIONS.EndRoomOptions.EndIn10Seconds:
              setMessage('Ending in 10 seconds');
              return 10000;
            case APP_MESSAGE_ACTIONS.EndRoomOptions.EndIn30Seconds:
              setMessage('Ending in 30 seconds');
              return 30000;
            case APP_MESSAGE_ACTIONS.EndRoomOptions.EndIn1Minute:
              setMessage('Ending in 1 minute');
              return 60000;
            default:
              return 10000;
          }
        };
        waitTimeRef.current = timeInMs();
        const intervalId = setInterval(() => {
          if (waitTimeRef.current && waitTimeRef.current > 0) {
            waitTimeRef.current = waitTimeRef.current - 1000;
            if (waitTimeRef.current > 0) {
              setMessage(`Ending in ${Math.floor(waitTimeRef.current / 1000)} seconds`);
            } else {
              clearInterval(intervalId);
            }
          } else {
            clearInterval(intervalId);
          }
        }, 1000);
      }
    }
  }, [endBreakoutMessage]);

  const buttonText = useMemo(() => {
    if (message === '' && !sessionData?.subbreakout) {
      return 'Leave Call';
    } else {
      return 'Return to Main Room';
    }
  }, [sessionData, message]);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <Tooltip placement='top' title={buttonText}>
          <TrayIcon
            enabled={false}
            onClick={clickButton}
            sx={{
              background: 'red',
              borderColor: 'red',
              borderRadius: message !== '' ? '6px 1px 1px 6px' : 'auto',
            }}
          >
            <ExitToAppOutlinedIcon
              sx={{
                transform: 'scaleX(-1)',
              }}
            />
          </TrayIcon>
        </Tooltip>
        {message !== '' && (
          <motion.div
            initial={{ x: '-100%', opacity: 0, zIndex: 0 }}
            animate={{ x: 0.5, opacity: 1, zIndex: 1000 }}
            transition={{ duration: 1 }}
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '200px',
              position: 'absolute',
              left: '50px',
              background: 'red',
              borderRadius: '1px 6px 6px 1px',
              padding: '4px 8px',
            }}
          >
            <Typography variant='body1' sx={{ color: 'white' }}>
              {message}
            </Typography>
          </motion.div>
        )}
      </div>
    </>
  );
};

export default CustomLeaveButton;
