import * as React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { DialogAriaWrapper } from '../../index';

interface SimpleDialogProps {
  open?: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  buttonTitle?: string;
  children?: React.ReactNode;
  buttonOverrideProps?: ButtonProps;
}

const SimpleDialog: React.FC<SimpleDialogProps> = ({
  open = false,
  onClose,
  title,
  children,
  buttonTitle = 'OK',
  buttonOverrideProps = {} as ButtonProps,
}) => {
  return (
    <DialogAriaWrapper
      id='simpleDialog'
      open={open}
      onClose={onClose}
      maxWidth='xs'
      sx={{ textAlign: 'center' }}
      aria-labelledby='simpleDialog-dialog-title'
      aria-describedby='simpleDialog-dialog-description'
    >
      <DialogTitle id='simpleDialog-dialog-title'>{title}</DialogTitle>
      <DialogContent id='simpleDialog-dialog-description'>{children}</DialogContent>
      <DialogActions>
        <Button fullWidth variant='contained' onClick={onClose} autoFocus {...buttonOverrideProps}>
          {buttonTitle}
        </Button>
      </DialogActions>
    </DialogAriaWrapper>
  );
};

export default SimpleDialog;
