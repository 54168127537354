import React from 'react';
import { Container, Grid, Skeleton } from '@mui/material';

interface DataLoadingSkeletonGridProps {
  variant?: 'rectangular' | 'circular';
}

const DataLoadingSkeletonGrid: React.FC<DataLoadingSkeletonGridProps> = ({ ...props }) => {
  return (
    <Container maxWidth='md' style={{ padding: '16px' }}>
      <Grid container spacing={2}>
        {[...Array(9)].map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Skeleton
              variant={props.variant || 'rectangular'}
              height={150}
              style={{ backgroundColor: '#e0e0e0', borderRadius: '4px' }}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
export default DataLoadingSkeletonGrid;
