import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DrawerWrapper = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'width' })<{
  width: number;
}>(({ width }) => ({
  '.MuiBackdrop-root ': {
    backgroundColor: 'rgb(0,0,0,0)',
  },
  '& .MuiDrawer-paper': {
    width,
    boxSizing: 'border-box',
    overflow: 'auto',
    backgroundColor: '#262626',
    fontFamily: 'Prompt, sans-serif',
  },
}));

export const ProfileContainer = styled(Box)(() => ({
  display: 'flex',
  cursor: 'pointer',
  padding: '24px 20px 24px 20px',
}));

export const ProfileAvatar = styled(Avatar)(() => ({
  width: 36,
  height: 36,
}));

export const ProfileTextBlock = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'column',
  lineHeight: '22px',
  marginLeft: '12px',
  marginTop: '-4px',
  letterSpacing: '-0.4px',
}));

export const ProfileName = styled(Box)(() => ({
  fontSize: '17px',
  color: 'rgba(255,255,255,0.65)',
}));

export const ProfileButtonText = styled(Box)(() => ({
  fontSize: '13px',
  color: 'rgba(255,255,255,0.65)',
}));

export const StyledAccordion = styled(Accordion)(() => ({
  background: 'none',
  color: 'rgba(255,255,255,0.65)',
  '&.MuiPaper-root': {
    boxShadow: 'none',
  },
  '&.MuiPaper-root:before': {
    background: 'none',
  },
}));

export const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  display: 'flex',
  paddingLeft: '24px',
  height: '56px',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: '#fff',
  },
  '& .main-nav-title': {
    transition: 'font-size 0.15s ease-in-out',
  },
  '&:hover': {
    '& .main-nav-title': {
      fontSize: '16px',
    },
  },
}));

export const LinkIcon = styled('i')(() => ({
  width: '22px',
  height: '22px',
  marginRight: '10px',
}));

export const BaseLinkText = styled(Box)(() => ({
  marginLeft: '6px',
  fontSize: '14px',
}));

export const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  fontSize: '14px',
  backgroundColor: 'rgba(255,255,255,0.15)',
  padding: '20px',
  display: 'flex',
  flexFlow: 'column',
}));

export const StyledAccordionDetailText = styled(Box)(() => ({
  cursor: 'pointer',
  padding: '12px',
  transition: 'font-size 0.15s ease-in-out',
  '&:hover': {
    fontSize: '16px',
  },
}));

export const Logo = styled('img')(() => ({
  width: '88px',
  objectFit: 'contain',
  marginBottom: '10px',
}));

export const JunoBranding = styled(Box)(() => ({
  padding: '31px',
  display: 'flex',
  flexFlow: 'column',
  fontSize: '12px',
}));

export const JunoBrandingLink = styled('a')(() => ({
  textDecoration: 'none',
  color: '#fff',
  transition: 'font-size 0.15s ease-in-out',
  '&:hover': {
    fontSize: '16px',
  },
}));

export const JunoVersion = styled(Box)(() => ({
  color: 'rgb(119,119,119)',
}));
