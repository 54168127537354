import { useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ASPECT_RATIOS, JunoImageUpload } from '@juno/ui';
import { useSettings } from '@juno/utils';
import { html } from '../../admin/utils/html';
import LivePreview from '../LivePreview';

interface DefaultCertificateTemplateProps {
  formik: any;
  subtitleStyles: any;
  onDelete?: () => void;
}

// Max image size is 10MB
const MAX_IMAGE_SIZE = 1024 * 1024 * 10;
const DEFAULT_URL =
  'https://res.cloudinary.com/dxx7aeiow/image/upload/v1675730575/ukk6wfidm9j1d0kcutgd.jpg';

const DefaultCertificateTemplate: React.FC<DefaultCertificateTemplateProps> = ({
  formik,
  subtitleStyles,
  onDelete,
}) => {
  const { isWidget } = useSettings();

  useEffect(() => {
    formik.setFieldValue('html_content', html(formik.values));
  }, [
    formik.values.metadata?.adminName,
    formik.values.metadata?.adminRole,
    formik.values.metadata?.backgroundImage,
    formik.values.metadata?.displayPlatformName,
    formik.values.metadata?.displayLetterGrade,
    formik.values.metadata?.displayCreditsEarned,
    formik.values.metadata?.displayCertificateID,
    formik.values.metadata?.hideBackgroundImage,
    formik.values.metadata?.hideRibbon,
    formik.values.metadata?.hasImageOnly,
  ]);

  return (
    <>
      <Grid item xs={12} sm={3}>
        <Typography sx={{ ...subtitleStyles, mb: 1 }}>Information</Typography>
        <TextField
          fullWidth
          id='name'
          name='name'
          label='Certificate Title'
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          onBlur={formik.handleBlur}
          variant='outlined'
          sx={{ mb: 2, mt: 2 }}
        />
        <TextField
          fullWidth
          id='description'
          name='metadata.description'
          label='Certificate Description'
          value={formik.values.metadata?.description}
          onChange={formik.handleChange}
          error={
            formik.touched.metadata?.description && Boolean(formik.errors.metadata?.description)
          }
          helperText={formik.touched.metadata?.description && formik.errors.metadata?.description}
          onBlur={formik.handleBlur}
          variant='outlined'
        />
        <Typography sx={{ ...subtitleStyles, mt: 1, mb: 3, fontWeight: 200, fontStyle: 'italic' }}>
          List description; not displayed to the user
        </Typography>
        <fieldset style={{ margin: 0, borderColor: 'rgba(255,255,255,0.23)' }}>
          <legend>
            <Typography variant='caption'>Background Image</Typography>
          </legend>
          <JunoImageUpload
            style={{ aspectRatio: '5/4' }}
            aspectRatios={[ASPECT_RATIOS['FIVE_FOUR']]}
            src={formik.values.metadata?.backgroundImage}
            defaultSrc={DEFAULT_URL}
            onFileUploaded={(image) =>
              formik.setFieldValue('metadata.backgroundImage', image?.url || DEFAULT_URL)
            }
            description={
              <ul>
                <li>Upload an 8"x10" landscape image (aspect ratio 5:4)</li>
                <li>Max file size 10MB</li>
              </ul>
            }
            maxSize={MAX_IMAGE_SIZE}
          />
        </fieldset>
        <Typography sx={{ ...subtitleStyles, mt: 2, mb: 2 }}>Template Information</Typography>
        <Box sx={{ border: '1px solid #E0E0E0', borderRadius: '4px', p: 2 }}>
          <Typography sx={{ ...subtitleStyles, mb: 2 }}>Admin Details</Typography>
          <Typography sx={{ fontStyle: 'italics', fontWeight: 200 }}>
            Person who will appear as the endorsed signature displayed on the certificate
          </Typography>
          <TextField
            fullWidth
            id='adminName'
            name='metadata.adminName'
            label='Admin Name'
            value={formik.values.metadata?.adminName}
            onChange={formik.handleChange}
            error={formik.touched.metadata?.adminName && Boolean(formik.errors.metadata?.adminName)}
            helperText={formik.touched.metadata?.adminName && formik.errors.metadata?.adminName}
            onBlur={formik.handleBlur}
            variant='outlined'
            sx={{ mb: 2, mt: 2 }}
            disabled={formik.values.metadata?.hasImageOnly}
          />
          <TextField
            fullWidth
            id='adminRole'
            name='metadata.adminRole'
            label='Admin Role'
            value={formik.values.metadata?.adminRole}
            onChange={formik.handleChange}
            error={formik.touched.metadata?.adminRole && Boolean(formik.errors.metadata?.adminRole)}
            helperText={formik.touched.metadata?.adminRole && formik.errors.metadata?.adminRole}
            onBlur={formik.handleBlur}
            variant='outlined'
            disabled={formik.values.metadata?.hasImageOnly}
            sx={{ mb: 2 }}
          />
          <Stack direction='column' spacing={1}>
            <Typography sx={subtitleStyles}>Template Options</Typography>
            <FormControlLabel
              control={<Checkbox checked={formik.values.metadata?.displayPlatformName} />}
              label='Display Platform Name'
              name='metadata.displayPlatformName'
              onChange={formik.handleChange}
              disabled={formik.values.metadata?.hasImageOnly}
              onBlur={formik.handleBlur}
            />
            {/*<FormControlLabel*/}
            {/*  control={<Checkbox checked={formik.values.metadata?.displayLetterGrade} />}*/}
            {/*  label='Display Letter Grade'*/}
            {/*  name='metadata.displayLetterGrade'*/}
            {/*  onChange={formik.handleChange}*/}
            {/*  disabled={formik.values.metadata?.hasImageOnly}*/}
            {/*  onBlur={formik.handleBlur}*/}
            {/*/>*/}
            <FormControlLabel
              control={<Checkbox checked={formik.values.metadata?.displayCreditsEarned} />}
              label='Display Credits Earned'
              name='metadata.displayCreditsEarned'
              onChange={formik.handleChange}
              disabled={formik.values.metadata?.hasImageOnly}
              onBlur={formik.handleBlur}
            />
            <FormControlLabel
              control={<Checkbox checked={formik.values.metadata?.displayCertificateID} />}
              label='Display Certificate ID'
              name='metadata.displayCertificateID'
              onChange={formik.handleChange}
              disabled={formik.values.metadata?.hasImageOnly}
              onBlur={formik.handleBlur}
            />
            <FormControlLabel
              control={<Checkbox checked={formik.values.metadata?.hideBackgroundImage} />}
              label='Hide Background Image'
              name='metadata.hideBackgroundImage'
              onChange={formik.handleChange}
              disabled={formik.values.metadata?.hasImageOnly}
              onBlur={formik.handleBlur}
            />
            <FormControlLabel
              control={<Checkbox checked={formik.values.metadata?.hideRibbon} />}
              label='Hide Ribbon'
              name='metadata.hideRibbon'
              onChange={formik.handleChange}
              disabled={formik.values.metadata?.hasImageOnly}
              onBlur={formik.handleBlur}
            />
            <FormControlLabel
              control={<Checkbox checked={formik.values.metadata?.hasImageOnly} />}
              label='Use Image Only'
              name='metadata.hasImageOnly'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {/* <FormControlLabel
        control={<Checkbox checked={formik.values.metadata?.default} />}
        label='Set as Default Certificate'
        name='metadata.default'
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      /> */}
          </Stack>
        </Box>
        <Button
          variant='contained'
          color='error'
          startIcon={<DeleteIcon />}
          sx={{ mt: 2 }}
          onClick={onDelete}
        >
          Delete Certificate
        </Button>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={12} sx={{ pl: 4, height: '100%' }}>
            <Paper
              elevation={3}
              sx={{
                position: 'sticky',
                top: '145px',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                margin: 'auto',
                width: '1225px',
                minWidth: '1225px',
                maxWidth: '1225px',
                height: '925px',
                minHeight: '925px',
                maxHeight: '925px',
                transform: isWidget ? 'scale(0.5)' : 'scale(0.75)',
                transformOrigin: 'top left',
              }}
            >
              <LivePreview html={formik.values.html_content} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DefaultCertificateTemplate;
