import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';

interface LoseChangesDialogProps {
  handleCancel: () => void;
  handleDiscard: () => void;
  handleSave: () => void;
}

const LoseChangesDialog: React.FC<LoseChangesDialogProps> = ({
  handleCancel,
  handleDiscard,
  handleSave,
}) => {
  return (
    <Dialog open={true} onClose={handleCancel} maxWidth='xs'>
      <DialogTitle sx={{ fontWeight: '600', pt: 4, pb: 4, alignSelf: 'left' }}>
        Save Changes
      </DialogTitle>
      <DialogContent>
        <DialogContentText align='left' component='div'>
          <Typography sx={{ mb: 3 }}>
            Would you like to save your changes before leaving?
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', pr: 2, pl: 2 }}>
        <Button onClick={handleCancel} sx={{ letterSpacing: '2px', color: 'black' }}>
          Cancel
        </Button>
        <Typography component='div' sx={{ width: '50%', textAlign: 'right' }}>
          <Button onClick={handleDiscard} sx={{ letterSpacing: '2px', mr: 2 }}>
            Discard
          </Button>
          <Button onClick={handleSave} sx={{ letterSpacing: '2px' }}>
            Save
          </Button>
        </Typography>
      </DialogActions>
    </Dialog>
  );
};

export default LoseChangesDialog;
