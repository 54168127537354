import { Box, Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LayoutContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showSideMenu' && prop !== 'showSubnav',
})<{
  showSideMenu?: boolean;
  showSubnav?: boolean;
}>(({ showSideMenu, showSubnav, theme }) => ({
  // margin: '0',
  // paddingBottom: 200,
  position: 'relative',
  background: '#f3f6f8',
  minHeight: '100vh',
  // overflow: 'hidden',
  transition: 'left 0.3s ease-in-out',
  '& header': {
    top: 0,
    position: 'fixed',
    height: showSubnav ? 110 : 60,
  },
}));

export const LayoutDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'drawerWidth' && prop !== 'isSmallScreen',
})<{
  drawerWidth?: number;
  isSmallScreen?: boolean;
}>(({ drawerWidth, isSmallScreen, theme }) => ({
  position: 'relative',

  '> .MuiPaper-root': {
    zIndex: 999,
    width: drawerWidth,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    paddingTop: '0px',
    marginTop: isSmallScreen ? 0 : theme.spacing(9.5),
    background: isSmallScreen ? '#f3f3f3' : 'transparent',
    border: 'none',
    boxShadow: 'none',
    maxHeight: isSmallScreen ? '100%' : `calc(100vh - ${theme.spacing(9.5)})`,
    scrollbarColor: 'rgba(0,0,0,.0) transparent',
    '&:hover': {
      scrollbarColor: 'rgba(0,0,0,.25) transparent',
    },
  },
}));

export const LayoutMainBox = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== 'drawerWidth' &&
    prop !== 'isSmallScreen' &&
    prop !== 'showSideMenu' &&
    prop !== 'showMessenger' &&
    prop !== 'mainWidth' &&
    prop !== 'isAdmin' &&
    prop !== 'isPageBuilder',
})<{
  drawerWidth?: number;
  isSmallScreen?: boolean;
  showSideMenu?: boolean;
  showMessenger?: boolean;
  isAdmin?: boolean;
  mainWidth?: string;
  isPageBuilder?: boolean;
}>(
  ({
    drawerWidth,
    isSmallScreen,
    showSideMenu,
    showMessenger,
    mainWidth,
    isAdmin,
    theme,
    isPageBuilder,
  }) => ({
    transition: '180ms',
    marginTop: isPageBuilder
      ? 0
      : isAdmin
      ? theme.spacing(2)
      : isSmallScreen
      ? 0
      : showSideMenu || showMessenger
      ? theme.spacing(2)
      : 0,
    overflow: 'hidden',
    paddingRight: isSmallScreen ? 0 : showSideMenu || showMessenger ? theme.spacing(2) : 0,
    paddingLeft: isSmallScreen ? 0 : showSideMenu || showMessenger ? theme.spacing(2) : 0,
    paddingBottom: isSmallScreen ? 0 : showSideMenu || showMessenger ? theme.spacing(3) : 0,
    marginLeft: isSmallScreen ? 0 : showSideMenu ? `${drawerWidth}px` : 0,
    width: isSmallScreen ? '100%' : mainWidth,
  }),
);
