import React, { useMemo, useState } from 'react';
import { QueryStats as QueryStatsIcon } from '@mui/icons-material';
import BrowserUpdatedOutlinedIcon from '@mui/icons-material/BrowserUpdatedOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Box, Button, Card, Tabs, Tooltip } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetCourse } from '@juno/client-api';
import { useGetUserRoleMap } from '@juno/client-api/helpers';
import { Course, Site } from '@juno/client-api/model';
import {
  FET_ALLOWED_ACTIONS,
  JUNO_ROUTE_MAP,
  LEARNING_ROLE_TYPE,
  LearningRoleType,
  ROLE_ALLOWED_FET_ACTIONS,
} from '@juno/constants';
import { Container, Juno404, JunoSpin } from '@juno/ui';
import { useRouteNavigate, useSettings } from '@juno/utils';
import BackButton from '../components/BackButton';
import CourseStatusMessage from '../components/CourseStatusMessage';
import ReportsDialog from '../components/ReportsDialog';
import VideoUploadCancellationModal from '../components/VideoUploadCancellationModal';
import { COURSE_STATUS, CourseStatus } from '../constants';
import CourseInfo from './CourseInfo';
import CourseResources from './CourseResources';
import Enrollment from './Enrollment';
import LessonPlan from './LessonPlan';
import { CoursePanelContext } from './context';
import { CourseTab, CourseTitle, EditingLabel } from './styles';

interface CoursePanelProps {
  site: Site;
}

interface TabProps {
  slug: string;
  key: string;
  icon: React.ReactNode;
  label: string;
  panel: React.ReactNode;
}

const getFilteredTabs = (
  site: Site,
  role: LearningRoleType,
  courseStatus: CourseStatus,
  all: boolean,
  courseData: Course,
) => {
  const TABS: TabProps[] = [
    {
      slug: 'info',
      key: FET_ALLOWED_ACTIONS.COURSEINFO,
      icon: <DashboardOutlinedIcon sx={{ mr: 2 }} />,
      label: 'Course Info',
      panel: <CourseInfo site={site} courseStatus={courseStatus} courseData={courseData} />,
    },
    {
      slug: 'lessons',
      key: FET_ALLOWED_ACTIONS.LESSONPLAN,
      icon: <FeaturedPlayListOutlinedIcon sx={{ mr: 2 }} />,
      label: 'Lesson Plan',
      panel: <LessonPlan site={site} courseStatus={courseStatus} />,
    },
    {
      slug: 'enrollments',
      key: FET_ALLOWED_ACTIONS.ENROLLMENT,
      icon: <PeopleAltOutlinedIcon sx={{ mr: 2 }} />,
      label: 'Enrollment',
      panel: <Enrollment site={site} />,
    },
    {
      slug: 'resources',
      key: FET_ALLOWED_ACTIONS.RESOURCES,
      icon: <BrowserUpdatedOutlinedIcon sx={{ mr: 2 }} />,
      label: 'Resources',
      panel: <CourseResources site={site} />,
    },
  ];
  if (all) return TABS;
  const filteredTabs: TabProps[] = [];
  ROLE_ALLOWED_FET_ACTIONS[role].forEach((actions) => {
    const tab = TABS.find((tab) => tab.key === actions);
    if (tab) filteredTabs.push(tab);
  });
  return filteredTabs;
};

const CoursePanel: React.FC<CoursePanelProps> = ({ site }) => {
  const { id: siteId, slug: slug } = site;
  const siteSlug: string = slug || '';
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const [openVideoUploadCancellationModal, setOpenVideoUploadCancellationModal] = useState(false);
  const { isWidget, isClient } = useSettings();
  const params = useParams() as { courseSlug?: string; tabSlug?: string; lessonSlug?: string };
  const courseSlug: string = params.courseSlug || '';
  const tabSlug: string = params.tabSlug || '';
  const lessonSlug: string = params.lessonSlug || '';
  const [reportsOpen, setReportsOpen] = useState(false);
  const learningNavigate = useRouteNavigate();
  const roleMap = useGetUserRoleMap(siteId);
  const { isLoading: roleMapLoading, user, learningRole } = roleMap;
  const { data: courseData, isLoading: courseLoading } = useGetCourse(siteId, courseSlug);
  const isCourseInstructor = courseData?.instructors?.find((ins) => ins.id === user?.id);
  const isLoading = courseLoading || roleMapLoading;
  const [tabRedirect, setTabRedirect] = useState(-1);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleOpenReports = () => setReportsOpen(true);
  const handleCloseReports = () => setReportsOpen(false);

  const courseStatus = useMemo(() => {
    const now = new Date().toISOString();
    const dateStart = courseData?.date_start && new Date(courseData?.date_start).toISOString();
    const disabled = dateStart && dateStart < now;
    const locked = courseData?.lesson_completions_count && courseData.lesson_completions_count > 0;
    if (locked) return COURSE_STATUS.LOCKED;
    if (disabled) return COURSE_STATUS.DISABLED;
    return COURSE_STATUS.ENABLED;
  }, [courseData]);

  const filteredTabs = useMemo(() => {
    if (courseData) {
      return getFilteredTabs(site, learningRole, courseStatus, !isWidget, courseData);
    }
    return [];
  }, [site, learningRole, courseStatus, isWidget, courseData]);

  if (isLoading || !courseData) return <JunoSpin />;
  if (!isCourseInstructor && learningRole === LEARNING_ROLE_TYPE.INSTRUCTOR) return <Juno404 />;

  const courseTabList = filteredTabs.map((tab) => tab.slug);
  const currentTab = lessonSlug
    ? 1
    : courseTabList.indexOf(tabSlug) < 0
    ? 0
    : courseTabList.indexOf(tabSlug);

  const handleBackButtonClick = () => {
    if (!isVideoUploading) {
      if (isWidget) {
        const newPath = pathname.split('/').slice(0, -2).join('/');
        navigate(newPath);
      } else {
        navigate(`/${site?.slug}/admin/learning`);
      }
    } else {
      setTabRedirect(-1);
      setOpenVideoUploadCancellationModal(true);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    if (!isVideoUploading) {
      if (isClient) {
        navigate(`/${site.slug}/admin/learning/${courseSlug}/${courseTabList[newTab]}`);
      } else {
        learningNavigate(JUNO_ROUTE_MAP.ADMIN_COURSE_TAB, {
          siteSlug,
          courseSlug,
          tabSlug: courseTabList[newTab],
        });
      }
      return;
    }
    setTabRedirect(newTab);
    setOpenVideoUploadCancellationModal(true);
  };

  const handleVideoUploadCancelConfirmed = () => {
    setIsVideoUploading(false);
    setOpenVideoUploadCancellationModal(false);
    if (tabRedirect === -1) {
      learningNavigate(JUNO_ROUTE_MAP.ADMIN_COURSES, { siteSlug });
    } else {
      learningNavigate(JUNO_ROUTE_MAP.ADMIN_COURSE_TAB, {
        siteSlug,
        courseSlug,
        tabSlug: courseTabList[tabRedirect],
      });
    }
  };

  return (
    <Container minHeight={'150vh'}>
      <CoursePanelContext.Provider value={{ isVideoUploading, setIsVideoUploading }}>
        <Card sx={{ boxShadow: 1, mb: 2 }}>
          <Box
            sx={{
              backgroundImage: `url(${courseData?.banner || ''})`,
              backgroundSize: 'cover',
              height: 300,
              width: '100%',
            }}
          />
          <CourseStatusMessage status={courseStatus} />
          <BackButton onClick={handleBackButtonClick}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: 'inherit',
                alignItems: 'center',
              }}
            >
              <CourseTitle variant='h5'>
                {`${isWidget ? 'FRONT-END EDITOR: ' : ''}${courseData?.title || ''}`}
              </CourseTitle>
              {
                <Tooltip title='Reports'>
                  <Button
                    size='large'
                    variant='contained'
                    sx={{ ml: 1 }}
                    onClick={handleOpenReports}
                  >
                    <QueryStatsIcon />
                  </Button>
                </Tooltip>
              }
            </Box>
          </BackButton>

          <Box>
            <Tabs value={currentTab} onChange={handleChange}>
              {filteredTabs.map((tab, idx) => (
                <CourseTab
                  key={tab.key}
                  id={`simple-tab-${idx}`}
                  aria-controls={`simple-tabpanel-${idx}`}
                  label={
                    <>
                      {tab.icon} {tab.label}
                    </>
                  }
                />
              ))}
            </Tabs>
          </Box>
        </Card>
        {filteredTabs.map((tab, idx) => (
          <Box
            key={tab.key}
            role='tabpanel'
            hidden={currentTab !== idx}
            id={`simple-tabpanel-${idx}`}
            aria-labelledby={`simple-tab-${idx}`}
          >
            {currentTab === idx && <Box>{tab.panel}</Box>}
          </Box>
        ))}
        <ReportsDialog
          site={site}
          open={reportsOpen}
          onClose={handleCloseReports}
          course={courseData}
          userData={roleMap.user}
        />
        <VideoUploadCancellationModal
          open={openVideoUploadCancellationModal}
          onClose={() => {
            setOpenVideoUploadCancellationModal(false);
          }}
          onCancelConfirm={handleVideoUploadCancelConfirmed}
        />
      </CoursePanelContext.Provider>
    </Container>
  );
};

export default CoursePanel;
