import { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { useInView } from 'react-intersection-observer';
import { useQueryClient } from 'react-query';
import { useDebounce } from 'usehooks-ts';
import { getGetAllUsersQueryKey } from '@juno/client-api';
import { useCreateUser, useDeleteUser, useGetAllUsers, useUpdateUser } from '@juno/client-api';
import { GetAllUsersParams, JunoUser } from '@juno/client-api/model';
import { MutationAction, onMutation } from '../FormUtils';
import { calculateUserSearchFilters } from '../SearchUtils';

export enum UserListActionEnum {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  NONE = 'NONE',
}

interface ListValuesModel {
  list: JunoUser[];
  isLoadingPage: boolean;
  isInitialLoad: boolean;
  ref: (node?: Element | null | undefined) => void;
}

interface UseDynamicUserListReturn {
  list: ListValuesModel;
  secondaryList: ListValuesModel;
  handleUpdateUser: (user: JunoUser, action: UserListActionEnum) => void;
  handleCreateUser: (user: JunoUser, action: UserListActionEnum) => void;
  handleDeleteUser: (user: JunoUser, action: UserListActionEnum) => void;
}

export const useDynamicUserList = (
  query: GetAllUsersParams,
  pageSize = 25,
  searchValue: string | undefined,
  searchValue2: string | undefined,
  platformId: string,
  oppositeQuery?: GetAllUsersParams,
  sortFunction?: (a: JunoUser, b: JunoUser) => number,
  includeOppositeList?: boolean,
  enabled?: boolean,
): UseDynamicUserListReturn => {
  const [usersList, setUsersList] = useState<JunoUser[]>([]);
  const [usersListLastSafeState, setUsersListLastSafeState] = useState<JunoUser[]>([]);
  const [oppositeList, setOppositeList] = useState<JunoUser[]>([]); // opposite of usersList, used for the autocomplete if you need it
  const [oppositeListSafeState, setOppositeListSafeState] = useState<JunoUser[]>([]);
  const [usersLoaded, setUsersLoaded] = useState(false); // is initial fetch complete
  const [usersLoadedOpposite, setUsersLoadedOpposite] = useState(false);
  const [page1, setPage1] = useState(0);
  const [page2, setPage2] = useState(0);
  const debouncedSearchValue = useDebounce(searchValue, 200);
  const debouncedSearchValue2 = useDebounce(searchValue2, 200);
  const queryClient = useQueryClient();
  // if something gets removed from a list, we can't just paginate to the next page or we'll lose 1 item
  const needToRefetch1 = useRef(false);
  const needToRefetch2 = useRef(false);

  const { ref, inView } = useInView({
    threshold: 0,
    initialInView: false,
  });

  const { ref: secondaryRef, inView: secondaryInView } = useInView({
    threshold: 0,
    initialInView: false,
  });

  // search query
  const searchFilter = useMemo(() => {
    if (!debouncedSearchValue) return {};
    return calculateUserSearchFilters(debouncedSearchValue);
  }, [debouncedSearchValue]);

  // search query
  const secondarySearchFilter = useMemo(() => {
    if (!debouncedSearchValue2) return {};
    return calculateUserSearchFilters(debouncedSearchValue2);
  }, [debouncedSearchValue2]);

  /********* FETCHES & MUTATIONS ********/
  const { data, isLoading, isFetched, refetch, isRefetching } = useGetAllUsers(
    platformId,
    {
      ...query,
      ...searchFilter,
      limit: pageSize,
      offset: page1,
    },
    { query: { enabled } },
  );

  const oppositeQ = oppositeQuery ?? {};

  const {
    data: dataOpposite,
    isLoading: isLoadingOpposite,
    isFetched: isFetchedOpposite,
    refetch: refetchSecondary,
    isRefetching: isRefetchingSecondary,
  } = useGetAllUsers(
    platformId,
    {
      ...oppositeQ,
      ...secondarySearchFilter,
      limit: pageSize,
      offset: page2,
    },
    {
      query: {
        enabled: includeOppositeList && enabled,
      },
    },
  );

  const updateUser = useUpdateUser(onMutation(MutationAction.UPDATE, 'User', () => {}));
  const createUser = useCreateUser(onMutation(MutationAction.CREATE, 'User', () => {}));
  const deleteUser = useDeleteUser(onMutation(MutationAction.DELETE, 'User', () => {}));

  /************** HANDLERS *************/
  const handleOptimisticUpdate = (user: JunoUser, action: UserListActionEnum) => {
    switch (action) {
      case UserListActionEnum.ADD:
        setUsersList((current: JunoUser[]) => _.uniqWith([...current, user], _.isEqual));
        setOppositeList((current: JunoUser[]) =>
          _.uniqWith(
            current.filter((u) => u.id !== user.id),
            _.isEqual,
          ),
        );
        needToRefetch2.current = true;
        break;
      case UserListActionEnum.REMOVE:
        setUsersList((current: JunoUser[]) =>
          _.uniqWith(
            current.filter((u) => u.id !== user.id),
            _.isEqual,
          ),
        );
        setOppositeList((current: JunoUser[]) => _.uniqWith([...current, user], _.isEqual));
        needToRefetch1.current = true;
        break;
      case UserListActionEnum.NONE:
      default:
        break;
    }
  };

  const handleUpdateUser = async (user: JunoUser, action: UserListActionEnum) => {
    handleOptimisticUpdate(user, action);
    try {
      await updateUser.mutate({
        platformId,
        userId: user.id,
        data: user,
      });
      setUsersListLastSafeState(usersList); // success! update the safe list
      setOppositeListSafeState(oppositeList);
    } catch {
      setUsersList(usersListLastSafeState); // failure! fallback to last safe list
      setOppositeList(oppositeListSafeState);
    }
  };

  const handleCreateUser = async (user: JunoUser, action: UserListActionEnum) => {
    handleOptimisticUpdate(user, action);
    try {
      await createUser.mutate({
        platformId,
        data: user,
      });
      setUsersListLastSafeState(usersList); // success! update the safe list
      setOppositeListSafeState(oppositeList);
    } catch {
      setUsersList(usersListLastSafeState); // failure! fallback to last safe list
      setOppositeList(oppositeListSafeState);
    }
  };

  const handleDeleteUser = async (user: JunoUser, action: UserListActionEnum) => {
    handleOptimisticUpdate(user, action);
    try {
      await deleteUser.mutate({
        platformId,
        userId: user.id,
      });
      setUsersListLastSafeState(usersList); // success! update the safe list
      setOppositeListSafeState(oppositeList);
    } catch {
      setUsersList(usersListLastSafeState); // failure! fallback to last safe list
      setOppositeList(oppositeListSafeState);
    }
  };

  /************** SYNCHRONIZATION **************/
  useEffect(() => {
    if (!usersLoaded && data) {
      // only on initial load of data, set the list
      setUsersList(data || []);
      setUsersListLastSafeState(data || []);
      setUsersLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    if (!usersLoadedOpposite && dataOpposite) {
      // only on initial load of data, set the list
      setOppositeList(dataOpposite || []);
      setOppositeListSafeState(dataOpposite || []);
      setUsersLoadedOpposite(true);
    }
  }, [dataOpposite]);

  useEffect(() => {
    needToRefetch1.current = false;
    if (page1 === 0) {
      setUsersList(data || []);
      setUsersListLastSafeState(data || []);
    } else {
      const joined = _.union(usersList, data);
      const uniq = _.uniqWith(joined, _.isEqual);
      setUsersList(uniq);
      setUsersListLastSafeState(uniq);
    } // join the new paginated data with our list
  }, [data]);

  useEffect(() => {
    needToRefetch2.current = false;
    if (page2 === 0) {
      setOppositeList(dataOpposite || []);
      setOppositeListSafeState(dataOpposite || []);
    } else {
      const joined = _.union(oppositeList, dataOpposite);
      const uniq = _.uniqWith(joined, _.isEqual);
      setOppositeList(uniq);
      setOppositeListSafeState(uniq);
    }
    // join the new paginated data with our list
  }, [dataOpposite]);

  useEffect(() => {
    setPage1(0);
  }, [debouncedSearchValue]);
  useEffect(() => {
    setPage2(0);
  }, [debouncedSearchValue2]);

  const sortedUsersList = useMemo(() => {
    return sortFunction ? usersList.sort(sortFunction) : usersList;
  }, [usersList]);

  const sortedOppositeList = useMemo(() => {
    return sortFunction ? oppositeList.sort(sortFunction) : oppositeList;
  }, [oppositeList]);

  useEffect(() => {
    if (inView && isFetched && usersLoaded && data && !(data.length < pageSize)) {
      if (needToRefetch1.current) {
        needToRefetch1.current = false;
        refetch();
      } else {
        setPage1((old) => old + 1);
      }
    }
  }, [inView]);

  useEffect(() => {
    if (
      secondaryInView &&
      isFetchedOpposite &&
      usersLoadedOpposite &&
      dataOpposite &&
      !(dataOpposite.length < pageSize)
    ) {
      if (needToRefetch2.current) {
        needToRefetch2.current = false;
        refetchSecondary();
      } else {
        setPage2((old) => old + 1);
      }
    }
  }, [secondaryInView]);

  useEffect(() => {
    return () => {
      // clean up so we get a fresh list on next load
      queryClient.removeQueries(getGetAllUsersQueryKey(platformId));
    };
  }, []);

  return {
    list: {
      list: sortedUsersList,
      isLoadingPage: (isLoading && usersLoaded && !isFetched) || isRefetching,
      isInitialLoad: !usersLoaded,
      ref: ref,
    },
    secondaryList: {
      list: sortedOppositeList,
      isLoadingPage:
        (isLoadingOpposite && usersLoadedOpposite && !isFetchedOpposite) || isRefetchingSecondary,
      isInitialLoad: !usersLoadedOpposite,
      ref: secondaryRef,
    },
    handleUpdateUser,
    handleCreateUser,
    handleDeleteUser,
  };
};
