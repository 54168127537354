/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type NavigationItemTypeEnum = typeof NavigationItemTypeEnum[keyof typeof NavigationItemTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const NavigationItemTypeEnum = {
  icon: 'icon',
  text: 'text',
  icon_with_text: 'icon_with_text',
} as const;
