import React, { useEffect, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, FormControl, Grid } from '@mui/material';
import { Form } from 'formik';
import { BaseCourseRequirement, Course } from '@juno/client-api/model';
import { DefaultDeleteDialog, DefaultMultiChipAutocomplete, GenericFormikInput } from '@juno/ui';

interface EditFormProps {
  req: any;
  prereqs: BaseCourseRequirement[] | undefined;
  allCourses: Course[] | undefined;
  handleDelete: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setFieldTouched: (
    field: string,
    isTouched: boolean,
    shouldValidate?: boolean | undefined,
  ) => void;
  handleChange: any;
  handleDirty: (force?: boolean) => void;
}
const EditForm: React.FC<EditFormProps> = ({
  req,
  prereqs,
  allCourses,
  handleDelete,
  setFieldValue,
  handleChange,
  setFieldTouched,
  handleDirty,
}) => {
  const { name, equivalents, grants_requirements } = req;

  const [openDelete, setOpenDelete] = useState(false);
  useEffect(() => {
    handleDirty();
  }, [req]);
  return (
    <Form>
      <Box p='10px 12px'>
        <FormControl fullWidth>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Box>
                <GenericFormikInput
                  label='Prerequisite Title'
                  name='name'
                  type='text'
                  placeholder='Prerequisite Title'
                  value={name}
                />
              </Box>
              <Box>
                <DefaultMultiChipAutocomplete
                  fieldValue='equivalents'
                  label={'Equivalents'}
                  allContentOptions={prereqs
                    ?.filter((p) => p.id != req.id)
                    .sort((a, b) => -b?.name?.localeCompare(a?.name))}
                  filteredOptions={equivalents}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  handleChange={handleChange}
                />
              </Box>
              <Box>
                <DefaultMultiChipAutocomplete
                  fieldValue='grants_requirements'
                  label={'Courses connected to Prerequisite'}
                  allContentOptions={allCourses?.sort((a, b) => -b?.title?.localeCompare(a?.title))}
                  filteredOptions={grants_requirements || []}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                  handleChange={handleChange}
                />
              </Box>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
      <Box>
        <Button
          variant='contained'
          color='error'
          sx={{ mt: 3, mb: 3, letterSpacing: '3px', p: '12px 16px' }}
          startIcon={<DeleteForeverIcon />}
          onClick={() => {
            setOpenDelete((old) => !old);
          }}
        >
          Delete Prerequisite
        </Button>
      </Box>
      {openDelete && (
        <DefaultDeleteDialog
          deleteTitle={'Prerequisite'}
          handleClose={() => {
            setOpenDelete(false);
          }}
          handleDelete={() => {
            setOpenDelete(false);
            handleDelete();
          }}
        />
      )}
    </Form>
  );
};
export default EditForm;
