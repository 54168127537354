import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  useCreateGroupResources,
  useDeleteGroupResource,
  useGetGroupResources,
  useUpdateGroupResource,
} from '@juno/client-api';
import { useGetUserRoleMap } from '@juno/client-api/helpers';
import { CommunityGroup, Download, GroupResource } from '@juno/client-api/model';
import { ConfirmDeleteDialog, DataLoadingSkeletonGrid, DefaultSearchSortFilter } from '@juno/ui';
import { MutationAction, onMutation, useFilesSearchFilter } from '@juno/utils';
import { useBreakpoint } from '@juno/utils/hooks';
import UploadFilesDialog from '../UploadFilesDialog';
import FileGridView from './FileGridView';
import FileListView from './FileListView';
import FileTreeView, { FileTree } from './FileTreeView';

interface FilesTabProps {
  siteId: string;
  group: CommunityGroup | undefined;
  refetchGroup: () => void;
  isAdminPage?: boolean;
  iAmAdmin?: boolean;
  userCanInteract?: boolean;
}
const FilesTab: React.FC<FilesTabProps> = ({
  siteId,
  group,
  refetchGroup,
  isAdminPage,
  iAmAdmin = false,
  userCanInteract = true,
}) => {
  const [debouncedSearch, search, filter, setSearch] = useFilesSearchFilter();

  const [folderHierarchy, setFolderHierarchy] = useState<FileTree>({});
  const [showFileUpload, setShowFileUpload] = useState(false);
  // TODO hoveredCardResourceId is set but not used?
  const [hoveredCardResourceId, setHoveredCardResourceId] = useState<string | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<GroupResource>({} as GroupResource);
  const [itemToDelete, setItemToDelete] = useState<GroupResource | null>(null);
  const [viewMode, setViewMode] = useState<'grid' | 'list' | 'tree'>('grid');
  const { user: currentUser } = useGetUserRoleMap(siteId);
  const [fileTree, setFileTree] = useState<FileTree>({});

  const {
    data: groupResources,
    isLoading: isLoadingResources,
    refetch: refetchResources,
  } = useGetGroupResources(siteId, group?.id ?? '', {
    ...filter,
    order: '-download__date_created',
  });
  const refetch = () => {
    refetchGroup();
    refetchResources();
  };

  const createResources = useCreateGroupResources(onMutation(MutationAction.CREATE, '', refetch));
  const updateResource = useUpdateGroupResource(onMutation(MutationAction.UPDATE, '', refetch));
  const deleteResource = useDeleteGroupResource(
    onMutation(MutationAction.DELETE, 'Resource', refetch),
  );

  useEffect(() => {
    if (!groupResources) return;
    const buildFileTree = (files: GroupResource[]) => {
      const tree: FileTree = {};
      const hierarchy: FileTree = {};

      files.forEach((resource) => {
        let pathComponents = resource.download.url.split('/');
        const fileName = pathComponents.pop() as string;
        let currentTreeLevel: FileTree = tree;
        let currentHierarchyLevel: FileTree = hierarchy;

        const indexOfAmazon = pathComponents.findIndex((path) => path.includes('amazonaws'));
        if (indexOfAmazon > -1) {
          pathComponents = pathComponents.slice(indexOfAmazon + 1);
        }

        if (pathComponents[0] === siteId) {
          pathComponents.shift();
        }

        pathComponents = pathComponents.filter((path) => path !== '');

        pathComponents.forEach((folder) => {
          if (!currentTreeLevel[folder]) {
            currentTreeLevel[folder] = {};
            currentHierarchyLevel[folder] = {};
          }
          currentTreeLevel = currentTreeLevel[folder] as FileTree;
          currentHierarchyLevel = currentHierarchyLevel[folder] as FileTree;
        });

        currentTreeLevel[fileName] = resource;
      });

      return { tree, hierarchy };
    };

    const { tree, hierarchy } = buildFileTree(groupResources);
    setFileTree(tree);
    setFolderHierarchy(hierarchy);
  }, [groupResources, siteId]);

  const renderView = () => {
    switch (viewMode) {
      case 'tree':
        return (
          <FileTreeView
            fileTree={fileTree}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            itemToEdit={itemToEdit}
            setItemToEdit={setItemToEdit}
            onEditSubmit={() => {
              updateResource.mutate({
                siteId: siteId,
                groupId: group?.id ?? '',
                resourceId: itemToEdit?.id ?? '',
                data: itemToEdit,
              });
              setIsEditing(false);
              setItemToEdit({} as GroupResource);
            }}
            onClickDelete={(file) => {
              setShowDeleteConfirm(true);
              setHoveredCardResourceId(null);
              setItemToDelete(file);
            }}
            iAmAdmin={iAmAdmin}
            isAdminPage={isAdminPage}
          />
        );
      case 'list':
        return (
          <FileListView
            files={groupResources || []}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setItemToEdit={setItemToEdit}
            itemToEdit={itemToEdit}
            onEditSubmit={() => {
              updateResource.mutate({
                siteId: siteId,
                groupId: group?.id ?? '',
                resourceId: itemToEdit?.id ?? '',
                data: itemToEdit,
              });
              setIsEditing(false);
              setItemToEdit({} as GroupResource);
            }}
            iAmAdmin={iAmAdmin}
            isAdminPage={isAdminPage}
            onClickDelete={(file) => {
              setShowDeleteConfirm(true);
              setHoveredCardResourceId(null);
              setItemToDelete(file);
            }}
          />
        );
      case 'grid':
      default:
        return (
          <FileGridView
            files={groupResources || []}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setItemToEdit={setItemToEdit}
            itemToEdit={itemToEdit}
            onEditSubmit={() => {
              updateResource.mutate({
                siteId: siteId,
                groupId: group?.id ?? '',
                resourceId: itemToEdit?.id ?? '',
                data: itemToEdit,
              });
              setIsEditing(false);
              setItemToEdit({} as GroupResource);
            }}
            iAmAdmin={iAmAdmin}
            isAdminPage={isAdminPage}
            onClickDelete={(file) => {
              setShowDeleteConfirm(true);
              setHoveredCardResourceId(null);
              setItemToDelete(file);
            }}
          />
        );
    }
  };

  return (
    <Box>
      <Box display={'flex'} alignItems={'center'}>
        <DefaultSearchSortFilter
          buttonDisabled={!userCanInteract}
          buttonText={'Upload'}
          sort={'date'}
          sortOptions={[
            { value: 'date', label: 'Date' },
            { value: 'title', label: 'Title' },
          ]}
          onClickButton={() => setShowFileUpload(true)}
          setSearch={(value) => setSearch(value)}
          showFilter={false}
          showSort={false}
          showLayoutOptions={true}
          layoutMode={viewMode}
          setLayoutMode={setViewMode}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Box>
          {isLoadingResources && <DataLoadingSkeletonGrid />}
          {!isLoadingResources && (
            <>
              {groupResources?.length === 0 ? (
                <Typography variant='body2' mt={1} sx={{ margin: '2em auto 0 auto' }}>
                  {!search ? 'No files have been uploaded yet.' : 'No files match your search'}
                </Typography>
              ) : (
                renderView()
              )}
            </>
          )}
        </Box>
      </Box>

      {showDeleteConfirm && (
        <ConfirmDeleteDialog
          handleClose={() => {
            setShowDeleteConfirm(false);
            setItemToDelete(null);
          }}
          handleDelete={() => {
            setShowDeleteConfirm(false);
            deleteResource.mutate({
              siteId: siteId,
              groupId: group?.id ?? '',
              resourceId: itemToDelete?.id ?? '',
            });
            setItemToDelete(null);
          }}
          title={'Are you sure you want to delete?'}
          message={
            <Typography>
              Are you sure you want to delete this file? This action cannot be undone.
            </Typography>
          }
        />
      )}
      <UploadFilesDialog
        folderHierarchy={folderHierarchy}
        open={showFileUpload}
        setShowFileUpload={setShowFileUpload}
        uploadFiles={async (uploads: Download[]) => {
          try {
            // have to remove the id from the download object, then batch create the downloads (resources)
            const uploadsWithoutId = uploads.map(({ id, ...rest }) => rest as Download);
            // convert the newFileDownloads[] to a GroupResources[]
            const newGroupResources = uploadsWithoutId.map(
              (download) =>
                ({
                  group_id: group?.id ?? '',
                  download: download,
                  author_id: currentUser?.id,
                } as GroupResource),
            );

            // batch create the group resources
            await createResources.mutateAsync({
              siteId,
              groupId: group?.id ?? '',
              data: newGroupResources,
            });
            refetch();
            setShowFileUpload(false);
          } catch (error) {
            console.error('An unexpected error occurred:', error);
            refetch();
            setShowFileUpload(false);
          }
        }}
      />
    </Box>
  );
};
export default FilesTab;
