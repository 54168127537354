import React from 'react';
import { Box, List, ListItem, Typography } from '@mui/material';
import { useGetAllCourseRequirements } from '@juno/client-api';
import { GetAllCourseRequirementsParams } from '@juno/client-api/model';

interface PrerequisiteBlurbProps {
  prereqIds?: string[];
  siteId: string;
}

const PrerequisiteBlurb: React.FC<PrerequisiteBlurbProps> = ({ prereqIds, siteId }) => {
  const getAllCourseRequirementParams: GetAllCourseRequirementsParams = {
    filter: {
      id__in: prereqIds,
    },
  };
  const { data: prereqs } = useGetAllCourseRequirements(siteId, getAllCourseRequirementParams);

  if (!prereqs || prereqs.length === 0) return null;

  return (
    <Box
      sx={{
        mt: 1,
        border: '1px solid #AEAEAE',
        borderRadius: '4px',
        padding: 2,
        background: '#F2F2F2',
      }}
    >
      <Typography color='text.primary' variant='body2' sx={{ fontWeight: 'bold', opacity: 0.7 }}>
        Prerequisites
      </Typography>
      <Box>
        <Typography variant='caption' color='text.primary' sx={{ opacity: 0.7 }}>
          <i>Conditions must be met for each set of prerequisites listed.</i>
        </Typography>
      </Box>
      <List sx={{ listStyleType: 'disc', pl: 4 }}>
        {prereqs &&
          prereqs.map((prereq, idx) => {
            if (prereq.equivalents && prereq.equivalents.length > 0) {
              let prereqList = prereq.name;
              prereq.equivalents.forEach((equivalent) => {
                prereqList += ` OR ${equivalent.name}`;
              });
              return (
                <ListItem key={idx} sx={{ display: 'list-item', opacity: 0.7 }}>
                  <Typography variant='body2' color='text.primary'>
                    {prereqList}
                  </Typography>
                </ListItem>
              );
            }
            return (
              <ListItem key={idx} sx={{ display: 'list-item', opacity: 0.7 }}>
                <Typography variant='body2' color='text.primary'>
                  {prereq.name}
                </Typography>
              </ListItem>
            );
          })}
      </List>
    </Box>
  );
};

export default PrerequisiteBlurb;
