import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
  Typography,
} from '@mui/material';
import { useGetSendGridTemplate } from '@juno/client-api';
import { Template as TemplateModel } from '@juno/client-api/model';
import { DialogAriaWrapper } from '@juno/ui';
import { useSettings } from '@juno/utils';

interface CampaignTemplatePreviewProps {
  template: TemplateModel;
}

const devices = [
  { name: 'Desktop', w: '100%', h: '100%' },
  { name: 'Tablet', w: 1024, h: 1366 },
  { name: 'Mobile', w: 428, h: 926 },
];

const CampaignTemplatePreview: React.FC<CampaignTemplatePreviewProps> = ({ template }) => {
  // Variables
  const { site } = useSettings();
  const [previewOpen, setPreviewOpen] = useState<boolean>(false);
  const [select, setSelect] = useState<string>('0');
  const [htmlContent, setHtmlContent] = useState<string>('');

  // Queries and mutations
  const { data: templateHTML, isLoading: templateLoading } = useGetSendGridTemplate(
    site?.id || '',
    template.base_id,
    template.version_id,
  );

  // Functions
  const handleChange = (event: SelectChangeEvent) => {
    setSelect(event.target.value as string);
  };

  useEffect(() => {
    if (templateHTML && templateHTML.html_content) {
      setHtmlContent(templateHTML.html_content as unknown as string);
    }
  }, [templateHTML]);

  return (
    <>
      {templateHTML && (
        <DialogAriaWrapper
          id={'emailTemplatePreviewDialog'}
          open={previewOpen}
          onClose={() => setPreviewOpen(false)}
          fullScreen
        >
          <AppBar sx={{ position: 'relative' }}>
            <DialogTitle id={'emailTemplateDialog-dialog-title'}>
              <Toolbar>
                <IconButton
                  edge='start'
                  color='inherit'
                  onClick={() => setPreviewOpen(false)}
                  aria-label='close'
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                  Template Preview
                </Typography>
                <FormControl sx={{ '& .MuiSelect-select': { pt: 1, pb: 1 } }}>
                  <InputLabel id='demo-simple-select-label'>Device</InputLabel>
                  <Select value={select} label='Device' onChange={handleChange}>
                    {devices.map((device, i) => (
                      <MenuItem value={i} key={i}>
                        {device.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Toolbar>
            </DialogTitle>
          </AppBar>
          <DialogContent
            id={'emailTemplateDialog-dialog-description'}
            sx={{
              backgroundColor: 'black',
              display: 'flex',
              justifyContent: 'center',
              height: '90vh',
            }}
          >
            <Box
              component='iframe'
              id='iframe'
              style={{}}
              title='iframe'
              sandbox='allow-same-origin'
              srcDoc={htmlContent}
              sx={{
                backgroundColor: 'white',
                width: devices[parseInt(select)].w,
                height: devices[parseInt(select)].h,
              }}
            />
          </DialogContent>
        </DialogAriaWrapper>
      )}
      {/* Hiding preview until it is fully built out */}
      {/*{templateLoading ? (*/}
      {/*  <LoadingButton*/}
      {/*    sx={{ mt: 2 }}*/}
      {/*    fullWidth*/}
      {/*    loading*/}
      {/*    loadingPosition='start'*/}
      {/*    startIcon={<SaveIcon />}*/}
      {/*    variant='outlined'*/}
      {/*  >*/}
      {/*    Loading*/}
      {/*  </LoadingButton>*/}
      {/*) : (*/}
      {/*  <Button*/}
      {/*    aria-label='Preview-email-template'*/}
      {/*    onClick={() => setPreviewOpen(true)}*/}
      {/*    sx={{ mt: 2 }}*/}
      {/*    variant='outlined'*/}
      {/*    color={'primary'}*/}
      {/*    fullWidth*/}
      {/*    disabled={templateLoading}*/}
      {/*  >*/}
      {/*    Preview Template*/}
      {/*  </Button>*/}
      {/*)}*/}
    </>
  );
};

export default CampaignTemplatePreview;
