import React, { useEffect, useState } from 'react';
import { Box, Card, Divider, Grid, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getContentUrl,
  useGetGlobalFeedAnnouncements,
  useGetGlobalForumAnnouncements,
} from '@juno/client-api';
import { Thread } from '@juno/client-api/model';
import { Container } from '@juno/ui';
import { useSettings } from '@juno/utils';
import { AnnouncementsDataModel } from './form';

interface ScheduleProps {
  announcements: Thread[];
  title?: string;
}

const Announcements: React.FC<ScheduleProps> = ({ announcements, title }) => {
  const { site } = useSettings();
  const navigate = useNavigate();
  const location = useLocation();
  const goToAnnouncement = async (threadId: string) => {
    const urlResponse = await getContentUrl(
      site?.id || '',
      threadId,
      location.pathname.split('/').pop() || '',
    );
    navigate(`/${site?.slug}${urlResponse.url}`);
  };
  return (
    <Container mt={1}>
      <Grid item xs={12}>
        <Card sx={{ p: 2, pt: 1 }}>
          <Typography variant={'body1'} fontWeight={'bold'} textAlign={'center'} mb={1}>
            {title || 'Announcements'}
          </Typography>
          {announcements.map((announcement) => (
            <Box
              key={announcement.id}
              sx={{ mb: 1 }}
              onClick={() => goToAnnouncement(announcement.id)}
            >
              <Typography
                variant='body1'
                sx={{ mb: 1, cursor: 'pointer' }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize((announcement?.body || '') as string, {
                    ADD_TAGS: ['iframe'],
                    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'],
                  }),
                }}
              ></Typography>
              <Divider />
            </Box>
          ))}
        </Card>
      </Grid>
    </Container>
  );
};

export default Announcements;

const PAGE_SIZE = 20;
interface AnnouncementsModuleProps {
  component?: AnnouncementsDataModel;
}
export const AnnouncementsModule: React.FC<AnnouncementsModuleProps> = ({ component }) => {
  const { site } = useSettings();
  const [combinedAnnouncements, setCombinedAnnouncements] = useState<Thread[]>([]);
  const { data: forumAnnouncements } = useGetGlobalForumAnnouncements(
    site?.id || '',
    {},
    { query: { enabled: !!site?.id } },
  );
  const { data: feedAnnouncements } = useGetGlobalFeedAnnouncements(
    site?.id || '',
    {},
    { query: { enabled: !!site?.id } },
  );

  useEffect(() => {
    if (forumAnnouncements && feedAnnouncements) {
      if (component?.global_feed && component?.global_forum) {
        const combined = [...forumAnnouncements, ...feedAnnouncements].sort((a, b) => {
          return (
            new Date(b.date_created as string).getTime() -
            new Date(a.date_created as string).getTime()
          );
        });
        setCombinedAnnouncements(combined);
      } else if (component?.global_feed) {
        setCombinedAnnouncements(feedAnnouncements);
      } else if (component?.global_forum) {
        setCombinedAnnouncements(forumAnnouncements);
      }
    }
  }, [forumAnnouncements, feedAnnouncements, component]);

  if (!component) {
    return null;
  }

  return <Announcements announcements={combinedAnnouncements} title={component.title as string} />;
};
