import React from 'react';
import { Settings } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useGetPages } from '@juno/client-api';
import { useGetUserRoleMap } from '@juno/client-api/helpers';
import { FeatureConfig as FeatureConfigModel, RoleEnum } from '@juno/client-api/model';
import { Page } from '@juno/client-api/utils';
import { JUNO_ROUTE_MAP } from '@juno/constants';
import { JunoModule } from '@juno/modules';
import { Juno404, JunoSpin } from '@juno/ui';
import { useBreakpoint, useRouteNavigate, useSettings } from '@juno/utils';

interface SessionsPageProps {
  siteId: string;
  configs: FeatureConfigModel[];
}
const SessionsPage: React.FC<SessionsPageProps> = ({ siteId, configs }) => {
  const { user } = useGetUserRoleMap(siteId);
  const { openExternalUrl, isApp } = useSettings();
  const { data: pages, isLoading } = useGetPages(siteId);
  const navigate = useRouteNavigate();
  const params = useParams();
  const handleSessionSelect = (item: any) => {
    navigate(JUNO_ROUTE_MAP.CLIENT_SESSION, { sessionSlug: item.slug });
  };

  const { xs } = useBreakpoint();
  const page = Page.getPageBySlug(pages, 'sessions');

  const handleAdminSelect = () => {
    navigate(JUNO_ROUTE_MAP.ADMIN_SESSIONS, {});
  };

  if (isLoading) return <JunoSpin />;
  if (!siteId || !page) return <Juno404 />;

  return (
    <Box sx={{ width: 1020, maxWidth: '90%', ml: 'auto', mr: 'auto', mt: 5 }}>
      {page.components.map((component) => (
        <JunoModule
          key={component.id}
          siteId={siteId}
          configs={configs}
          component={{
            ...component,
            data: {
              ...component.data,
              onSelect: handleSessionSelect,
              openExternalUrl: openExternalUrl,
            },
          }}
        />
      ))}

      {user?.role === RoleEnum.NUMBER_1 && !xs && (
        <IconButton
          size='small'
          onClick={handleAdminSelect}
          sx={{
            bgcolor: 'primary.main',
            display: 'block',
            width: '90px',
            height: '90px',
            marginLeft: '20px',
            position: 'fixed',
            bottom: '50px',
            left: '50px',
            '&:hover': {
              bgcolor: 'primary.main',
              opacity: 0.8,
            },
          }}
        >
          <Settings fontSize='small' sx={{ color: '#fff', lineHeight: '20px' }} />
          <Typography sx={{ color: '#fff' }}>Edit</Typography>
        </IconButton>
      )}
    </Box>
  );
};
export default SessionsPage;
