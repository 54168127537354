import React, { Ref, useEffect, useRef, useState } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BoundingBox, Reorder, useDragControls } from 'framer-motion';
import { useDebounce } from 'usehooks-ts';
import { Answer, Question } from '@juno/client-api/model';

/*import { RankingItem} from './styles';*/

interface RankingProps {
  question: Question;
  handleSetAnswer: (answer: string[]) => void;
  displayOnly: boolean;
  answer: string[];
}

const AnswerContainer = styled(Grid)(({ theme }) => ({
  margin: `${theme.spacing(2)} 0`,
}));
const AnswerTypography = styled(Typography)(({ theme }) => ({
  flex: '100%',
  marginRight: theme.spacing(3),
  flexGrow: 1,
  padding: theme.spacing(2),
}));
const AnswerLetter = styled(Box)(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.primary.light}`,
  padding: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const AnswerButton = styled(Button)(({ theme }) => ({
  padding: 0,
  width: '100%',
  borderRadius: '30px',
  textTransform: 'none',
  display: 'flex',
  position: 'relative',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
}));

function shuffleAnswers(array: Answer[]): Answer[] {
  if (array.length < 2) {
    return array;
  }
  const newArray = array.map((item) => {
    return { ...item };
  });
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [newArray[currentIndex], newArray[randomIndex]] = [
      newArray[randomIndex],
      newArray[currentIndex],
    ];
  }

  // check to make sure we are in fact changing the order
  let isNew = true;
  newArray.map((answer, index) => {
    if (answer.order != index) {
      isNew = true;
    }
  });

  if (!isNew) {
    return shuffleAnswers(array);
  }

  return newArray;
}

interface RankingItemProps {
  answer: Answer;
  key: string;
  constraintsRef: Partial<BoundingBox>;
}

function RankingItem({ answer, constraintsRef, key }: RankingItemProps): React.ReactElement {
  return (
    <Reorder.Item
      className='reorderItem'
      key={key}
      style={{ pointerEvents: 'none' }}
      value={answer}
      dragElastic={0.05}

      /*
      dragConstraints={constraintsRef}
        LEON NOTES:
        this is disabled until it works during scrolling.

        CURRENT FINDINGS:
        - it works fine if I take layoutscroll off off juno-learning-scroll, don't know why
        - it works fine if we take scrolling OFF of the vnext stuff and just use the vNow scrolling
           - WHICH happens if in app.js you have my newest branch and add embeddedScrolling: true, to App.prototype.defaultVNextWidgetProps

      */
    >
      <AnswerContainer item xs={12} md={12}>
        <AnswerButton variant='contained'>
          <AnswerLetter style={{ pointerEvents: 'all' }}>
            <DragIndicatorIcon />
          </AnswerLetter>
          <AnswerTypography>{answer.title}</AnswerTypography>
        </AnswerButton>
      </AnswerContainer>
    </Reorder.Item>
  );
}

export default function Ranking({
  question,
  answer,
  displayOnly,
  handleSetAnswer,
}: RankingProps): React.ReactElement {
  const { answers, name, answer_changeable, metadata } = question;
  const [answersState, setAnswersState] = useState<Answer[]>([]);
  const debouncedAnswerState = useDebounce(answersState, 500);
  const initialLoadRef = useRef(true);

  useEffect(() => {
    if (answer && answer.length) {
      // Set existing answer order
      const currentAnswerOrder = answer
        .map((answer_id) => answers.find((a) => a.id === answer_id))
        .filter((a) => a !== undefined) as Answer[];
      setAnswersState(currentAnswerOrder);
    }
  }, [answer, answers]);

  useEffect(() => {
    if (!displayOnly) {
      if (answersState.length === 0 && !answer?.length) {
        // No answer, shuffle
        setAnswersState(shuffleAnswers(answers));
      }
    } else {
      setAnswersState(answers);
    }
  }, [answers, answersState, displayOnly]);

  useEffect(() => {
    if (!debouncedAnswerState || debouncedAnswerState.length === 0) return;
    if (initialLoadRef.current) {
      initialLoadRef.current = false;
    } else {
      const answerIds = debouncedAnswerState.map((answer) => answer.id);
      handleSetAnswer(answerIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAnswerState]);

  const constraintsRef = useRef(null);

  return (
    <Grid container>
      <Grid item xs={12} sx={{ m: 0 }}>
        <Reorder.Group
          values={answersState}
          onReorder={setAnswersState}
          axis='y'
          style={{ overflowY: 'visible', listStyleType: 'none', padding: 0, margin: 0 }}
          ref={constraintsRef}
        >
          {answersState.map((answer, index) => {
            return (
              <RankingItem
                answer={answer}
                key={answer.id}
                constraintsRef={constraintsRef as Partial<BoundingBox>}
              />
            );
          })}
        </Reorder.Group>
      </Grid>
    </Grid>
  );
}
