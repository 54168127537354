import React, { useEffect, useState } from 'react';
import { Container, Content, Title } from './styles';

const FALLBACK_IMAGE_URL = 'https://media3.giphy.com/media/guSDEekNFb23C/giphy.gif';
const BASE_URL = 'https://api.giphy.com/v1/gifs/search';
const API_KEY = 'Gc7131jiJuvI7IdN0HZ1D7nh0ow5BU6g';

const fetch404ImageUrl = async (): Promise<string> => {
  const params = {
    api_key: API_KEY,
    type: 'gifs',
    q: 'confusion',
    limit: 1,
    offset: Math.floor(Math.random() * 100),
  };
  const queryString = Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .join('&');
  try {
    const url = `${BASE_URL}?${queryString}`;
    const response = await fetch(url);
    const json = await response.json();
    const gifUrl = json.data[0].images.original.url;
    return gifUrl;
  } catch (error) {
    return FALLBACK_IMAGE_URL;
  }
};

export default () => {
  const [imageUrl, setImageUrl] = useState('');
  // TODO we need a 404 page but this pulls random "non-client-approved" gifs
  // useEffect(() => {
  //   (async () => {
  //     const gifUrl = await fetch404ImageUrl();
  //     setImageUrl(gifUrl);
  //   })();
  // }, []);

  return (
    <Container imageUrl={imageUrl}>
      <Content>
        <Title>404</Title>
        <span>Page not found</span>
      </Content>
    </Container>
  );
};
