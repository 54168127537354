import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useQueryClient } from 'react-query';
import {
  getGetPagesQueryKey,
  useCreateComponent,
  useCreateFeatureConfig,
  useGetComponent,
  useUpdateComponent,
  useUpdateSiteFeatureConfig,
} from '@juno/client-api';
import {
  Component as ComponentModel,
  FeatureConfig as FeatureConfigModel,
  NavigationItem as NavigationItemModel,
  Page as PageModel,
} from '@juno/client-api/model';
import { FeatureConfig as FeatureConfigUtils } from '@juno/client-api/utils';
import { DEFAULT_THEME_CONFIG_PRESET } from '@juno/constants';
import { JunoSpin } from '@juno/ui';
import { MutationAction, onMutation } from '@juno/utils';
import BlurbForm from '../Blurb/form';

interface ModuleEditFormProps {
  siteId: string;
  configs: FeatureConfigModel[];
  navigationItems: NavigationItemModel[];
  pages: PageModel[];
  onSave: (payload: NavigationItemModel) => void;
  onUpdate: (payload: NavigationItemModel) => void;
  onDelete: (linkId: string) => void;
}

const BlurbEditForm = ({ siteId, configs, onSave, onUpdate }: ModuleEditFormProps) => {
  const queryClient = useQueryClient();
  const themeConfig = useMemo(() => FeatureConfigUtils.getThemeConfig(configs), [configs]);
  const footerComponentId = useMemo(
    () => (themeConfig?.config?.footerComponentId || '') as string,
    [themeConfig],
  );
  const { UPDATE, CREATE } = MutationAction;
  const { data: existingComponent, isLoading } = useGetComponent(siteId, footerComponentId);
  const refetchPages = () => queryClient.invalidateQueries(getGetPagesQueryKey(siteId));
  const createComponent = useCreateComponent(onMutation(CREATE, 'Component', refetchPages));
  const updateComponent = useUpdateComponent(onMutation(UPDATE, 'Component', refetchPages));
  const createConfig = useCreateFeatureConfig(onMutation(CREATE, 'FeatureConfig', refetchPages));
  const updateConfig = useUpdateSiteFeatureConfig(
    onMutation(UPDATE, 'FeatureConfig', refetchPages),
  );

  const handleCreateUpdateFeatureConfig = (componentId: string) => {
    if (!themeConfig) {
      const configData = FeatureConfigUtils.buildThemePayload(DEFAULT_THEME_CONFIG_PRESET);
      configData.config = { ...configData.config, footerComponentId: componentId };
      createConfig.mutate({ siteId, data: configData });
      return;
    }
    const configConfigData = { ...themeConfig.config, footerComponentId: componentId };
    const configData = { ...themeConfig, config: configConfigData };
    updateConfig.mutate({ siteId, configType: configData.type, data: configData });
  };

  const handleCreateUpdateComponent = async (data: ComponentModel) => {
    if (!data.id) {
      const component = await createComponent.mutateAsync({ siteId, data });
      handleCreateUpdateFeatureConfig(component.id);
      return;
    }
    const component = await updateComponent.mutateAsync({ siteId, componentId: data.id, data });
    handleCreateUpdateFeatureConfig(component.id);
  };

  if (isLoading) return <JunoSpin />;
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <span style={{ fontSize: 24 }}>Footer Blurb</span>
      </Box>
      <BlurbForm
        component={existingComponent}
        onSave={handleCreateUpdateComponent}
        onUpdate={(component) => console.log('UPDATE', component)}
      />
    </>
  );
};

export default BlurbEditForm;
