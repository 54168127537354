import React, { useContext, useEffect, useState } from 'react';
import { BookmarkAdd as BookmarkAddIcon, QueryStats as QueryStatsIcon } from '@mui/icons-material';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { Box, Button, CircularProgress, Grid, Tab, Tabs, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridRowModel } from '@mui/x-data-grid';
import { useInView } from 'react-intersection-observer';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useDebounce } from 'usehooks-ts';
import {
  getGetCoursesQueryKey,
  useCreateCourse,
  useGetCourses,
  useUploadScormCourse,
} from '@juno/client-api';
import { useGetUserRoleMap } from '@juno/client-api/helpers';
import { Course, JunoUser, Site as SiteModel } from '@juno/client-api/model';
import { JunoImage } from '@juno/client-api/utils';
import {
  EMPTY_COURSE_URL,
  FET_ALLOWED_ACTIONS,
  JUNO_ROUTE_MAP,
  LEARNING_ROLE_TYPE,
  ROLE_ALLOWED_FET_ACTIONS,
} from '@juno/constants';
import { AdminDefaultViewHeader, JunoGrid, JunoSpin, PreviewItemProps, TabPanel } from '@juno/ui';
import { MutationAction, onMutation, useRouteNavigate, useSettings } from '@juno/utils';
import PrerequisiteList from '../CoursePanel/Prerequisites';
import ReportsDialog from '../components/ReportsDialog';
import FormDialog, { CoursesForm } from './FormDialog';
import { CourseType } from './enums';

interface UICourseEntry extends Course, GridRowModel {}

const MODEL = 'Course';
const PAGE_SIZE = 12;

const toForm = (values: UICourseEntry, idx: number): UICourseEntry => {
  return {
    ...values,
    // TODO: update when API supports a Juno Image type.
    image_banner: JunoImage.fake(`image-${idx}`, values.image_banner) as unknown as string,
    image_rotator: JunoImage.fake(`image-${idx}`, values.image_rotator) as unknown as string,
  };
};

const CreateButton = styled(Button)(({ theme }) => ({}));

interface CoursesPanelProps {
  site: SiteModel;
}
// TODO this component is only used in the old widget, JunoRoutes and in a duplicate route in routes.tsx...Deprecate?
const CoursesPanel: React.FC<CoursesPanelProps> = ({ site }) => {
  const navigate = useNavigate();

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    delay: 1000,
  });
  const { id: siteId, slug } = site;
  const [open, setOpen] = useState(false);
  const [reportsOpen, setReportsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [coursePage, setCoursePage] = useState(0);
  const [courses, setCourses] = useState<PreviewItemProps[]>([]);
  const [isEndOfList, setIsEndOfList] = useState(false);
  const debouncedSearch = useDebounce(search, 500);
  const learningNavigate = useRouteNavigate();
  const { isWidget, coursesSlug, openExternalUrl, isClient } = useSettings();
  const queryClient = useQueryClient();
  const roleMap = useGetUserRoleMap(siteId);
  const { user, isLoading: routesLoading, learningRole } = roleMap;
  const canUserCreateCourse = ROLE_ALLOWED_FET_ACTIONS[learningRole].includes(
    FET_ALLOWED_ACTIONS.COURSES,
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // New course dialog
  const handleOpen = () => {
    setValue(0);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleOpenReports = () => {
    setValue(0);
    setReportsOpen(true);
  };
  const handleCloseReports = () => setReportsOpen(false);

  // Queries
  const coursesFilter = { title__icontains: debouncedSearch };
  const instCoursesFilter = { title__icontains: debouncedSearch, instructors__id: user?.id };
  const {
    data: coursesData,
    isLoading,
    isFetching,
  } = useGetCourses(
    siteId,
    {
      order: '-date_created',
      filter: learningRole === LEARNING_ROLE_TYPE.INSTRUCTOR ? instCoursesFilter : coursesFilter,
      offset: coursePage,
      limit: PAGE_SIZE,
    },
    { query: { enabled: !!user && !routesLoading } },
  );

  // Mutations
  const refetch = () => {
    setCourses([]);
    setCoursePage(0);
    queryClient.invalidateQueries(getGetCoursesQueryKey(siteId));
  };
  const courseCreate = useCreateCourse(onMutation(MutationAction.CREATE, MODEL, refetch));
  const scormCreate = useUploadScormCourse();

  useEffect(() => {
    setCoursePage(0);
    setCourses([]);
  }, [debouncedSearch]);

  useEffect(() => {
    if (inView && !isFetching && coursesData?.length !== 0) {
      setCoursePage(coursePage + 1);
    }
  }, [inView, isFetching, coursesData]);

  useEffect(() => {
    if (!coursesData) return;
    if (coursesData?.length === 0) {
      setIsEndOfList(true);
      return;
    }
    const newCourses = coursesData?.map((entry, idx) => toForm(entry, idx)) || [];
    const data = newCourses?.map((course) => {
      return {
        id: course.id || '',
        slug: course.slug || '',
        name: course.title || '',
        description: course.abstract || '',
        image: course.icon || EMPTY_COURSE_URL,
        date_start: course.date_start || '',
        date_created: course.date_created || '',
        can_edit: course.lesson_completions_count === 0,
      };
    });
    if (coursePage === 0) {
      setCourses(data || []);
      return;
    }
    setCourses((currentCourses) => [...currentCourses, ...data]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coursesData]);

  const handleSelectCourse = (to: PreviewItemProps | undefined) => {
    if (!to) return;

    if (isClient) {
      navigate(`/${site.slug}/admin/learning/${to.slug}`);
    } else {
      learningNavigate(JUNO_ROUTE_MAP.ADMIN_COURSE, { siteSlug: slug || '', courseSlug: to.slug });
    }
  };

  const handleGoBack = () => {
    //learningNavigate(JUNO_ROUTE_MAP.CLIENT_COURSES, {}, coursesSlug || '');
    window.history.back();
  };

  const handleSaveCourse = async (course: CoursesForm | undefined) => {
    if (!course) return;
    const data = { title: course.title, slug: course.slug } as Course;
    if (learningRole === LEARNING_ROLE_TYPE.INSTRUCTOR) {
      // If only an instructor, add the instructor to the course.
      data.instructors = [user as JunoUser];
    }
    try {
      await courseCreate.mutateAsync({ siteId, data }).then((newCourse) => {
        if (course.metadata.type === CourseType.SCORM && !!course.uploaded_file) {
          scormCreate.mutateAsync({
            siteId,
            courseId: newCourse.id,
            data: { file_path: course.uploaded_file },
          });
        }
      });
    } catch (e) {
      console.error(e);
    }
    handleClose();
  };

  if (routesLoading) return <JunoSpin />;

  const headerButtons = (
    <Box>
      <Button
        sx={{
          mr: 1,
          boxShadow: 'none',
          textTransform: 'none',
          color: (theme) => theme.palette.text.primary,
        }}
        onClick={handleOpenReports}
        startIcon={<QueryStatsIcon sx={{ color: (theme) => theme.palette.info.main }} />}
      >
        Reports
      </Button>
      <Button
        onClick={handleOpen}
        variant='contained'
        startIcon={<BookmarkAddIcon />}
        sx={{ boxShadow: 'none', textTransform: 'none' }}
      >
        Create A Course
      </Button>
    </Box>
  );

  return (
    <Box sx={{ width: '100%', m: 'auto', p: 4, maxWidth: isWidget ? '980px' : 'auto' }}>
      <Box sx={{ display: 'flex', mb: 2 }}>
        {!isWidget && !isClient && (
          <AdminDefaultViewHeader title={`Courses`}>{headerButtons}</AdminDefaultViewHeader>
        )}
        {isWidget && (
          <Grid item xs={8}>
            <Typography
              variant='body2'
              sx={{ color: 'text.secondary', opacity: 0.5, fontStyle: 'italic', mb: 1 }}
            >
              Editing
            </Typography>
            <Typography variant='h4' sx={{ color: 'text.secondary', letterSpacing: '3px' }}>
              FRONT-END EDITOR: COURSES
            </Typography>
          </Grid>
        )}
        {isClient && (
          <Box>
            <Typography variant='h6' sx={{ mt: 1, mb: 1 }}>
              Courses
            </Typography>
            <Typography variant='body2' sx={{ mb: 4, mt: 1, maxWidth: 900 }}>
              Create or edit courses and prerequisites for those courses.
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label={
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <LibraryBooksOutlinedIcon fontSize='small' color='primary' />
                Course Catalog
              </Box>
            }
            sx={{ textTransform: 'none' }}
          />
          {(roleMap.isInstructor || roleMap.isSiteAdmin) && (
            <Tab
              label={
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <ShareOutlinedIcon fontSize='small' color='primary' />
                  Prerequisites
                </Box>
              }
              sx={{ textTransform: 'none' }}
            />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box sx={{ m: 'auto', mt: 1, maxWidth: isWidget ? '980px' : 'auto' }}>
          <Grid container sx={{ justifyContent: 'right' }}>
            <Grid item xs={4} sx={{ textAlign: 'right' }}>
              {canUserCreateCourse && isWidget && headerButtons}
            </Grid>
          </Grid>
          <JunoGrid
            items={courses}
            openExternalUrl={openExternalUrl}
            onSelect={handleSelectCourse}
            // onSearch={setSearch}
            settings={{
              variant: 'rounded',
              showItemDescription: true,
              textAlign: 'left',
              itemWidth: 310,
              itemHeight: 174,
            }}
            ref={ref}
          />
          {isLoading && (
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress />
            </Box>
          )}
          {isEndOfList && (
            <Typography variant='body1' sx={{ textAlign: 'center', mt: 2 }}>
              - No more courses found -
            </Typography>
          )}
          <FormDialog
            open={open}
            onClose={handleClose}
            onSave={(course: CoursesForm | undefined) => handleSaveCourse(course)}
            isSaving={courseCreate.isLoading}
          />
          <ReportsDialog
            site={site}
            open={reportsOpen}
            onClose={handleCloseReports}
            userData={roleMap.user}
          />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PrerequisiteList site={site} />
      </TabPanel>
    </Box>
  );
};

export default CoursesPanel;
