import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import EditOffIcon from '@mui/icons-material/EditOff';
import LockIcon from '@mui/icons-material/Lock';
import { Box, Chip, Grid, Tab, Tabs, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useElementSize } from 'usehooks-ts';
import { useGetAllTags, useGetAllUsers } from '@juno/client-api';
import { optimizeImage } from '@juno/utils';
import CourseFilterDialog from '../CourseFilterDialog';
import { CourseFilterContext } from '../CourseFilterDialog/context';
import DataLoadingSkeletonGrid from '../DataLoadingSkeletonGrid';
import RestrictCoursePreviewDialog from './RestrictCoursePreviewDialog';
import { CHIP_DATE_FORMAT } from './constants';
import {
  Container,
  GridItemContainer,
  GridItemDescription,
  GridItemImage,
  GridItemTitle,
  GridItemsContainer,
  LockedIndicator,
  PaymentIcon,
  ProgressIndicator,
  Title,
} from './styles';

export interface GridSettings {
  dataModel?: string;
  tabs?: [];
  title?: string;
  showTitle?: boolean;
  showFiltersButton?: boolean;
  spacing?: number;
  variant?: 'rounded' | 'square' | 'circular';
  itemHeight?: number;
  itemWidth?: number;
  showItemTitle?: boolean;
  showItemDescription?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  showProgress?: boolean;
  order?: 'newest' | 'default';
  showFilterInstructors?: boolean;
  showFilterStartDates?: boolean;
  filterTagTypes?: string[];
  showSortSelect?: boolean;
  showSortCourseName?: boolean;
  showSortMostPopular?: boolean;
  showSortNewest?: boolean;
  openExternalUrl?: (url: string) => void;
  onCards?: boolean;
}

const DEFAULTS: { [key: string]: any } = {
  dataModel: 'Group',
  tabs: [],
  title: '',
  showTitle: true,
  showFiltersButton: false,
  spacing: 20,
  variant: 'circular',
  itemHeight: 205,
  itemWidth: 205,
  showItemTitle: true,
  showItemDescription: false,
  textAlign: 'center',
  showProgress: false,
  showFilterInstructors: false,
  showFilterStartDates: false,
  filterTagTypes: [],
  showSortSelect: false,
  showSortCourseName: false,
  showSortMostPopular: false,
  showSortNewest: false,
};

export const buildGridSettings = (settings: GridSettings | undefined) => {
  const getProp = (key: string) => {
    const tempSettings: { [key: string]: any } = settings || DEFAULTS;
    return key in tempSettings ? tempSettings[key] : DEFAULTS[key];
  };
  return {
    dataModel: getProp('dataModel'),
    tabs: getProp('tabs'),
    title: getProp('title'),
    showTitle: getProp('showTitle'),
    showFiltersButton: getProp('showFiltersButton'),
    spacing: getProp('spacing'),
    variant: getProp('variant'),
    itemHeight: getProp('itemHeight'),
    itemWidth: getProp('itemWidth'),
    showItemTitle: getProp('showItemTitle'),
    showItemDescription: getProp('showItemDescription'),
    textAlign: getProp('textAlign'),
    showProgress: getProp('showProgress'),
    order: getProp('order'),
    showFilterInstructors: getProp('showFilterInstructors'),
    showFilterStartDates: getProp('showFilterStartDates'),
    filterTagTypes: getProp('filterTagTypes'),
    showSortSelect: getProp('showSortSelect'),
    showSortCourseName: getProp('showSortCourseName'),
    showSortNewest: getProp('showSortNewest'),
    showSortStartDate: getProp('showSortStartDate'),
    showSortMostPopular: getProp('showSortMostPopular'),
    openExternalUrl: getProp('openExternalUrl'),
  };
};

export interface GridItemDataProps {
  id: string;
  clientName?: string;
  upgradeUrl?: string;
  slug: string;
  name: string;
  description: string;
  image: string;
  progressText?: string;
  date_start?: string;
  paywall?: boolean;
  restrict_preview?: boolean;
  restrict_access?: boolean;
  can_edit?: boolean;
}

interface JunoGridTabProps {
  id: string;
  label: string;
  filter: string;
  temporarySort?: string;
}

export interface JunoGridProps {
  items: GridItemDataProps[];
  clientName?: string;
  tabs?: JunoGridTabProps[];
  selectedTabId?: string | undefined;
  onTabSelected?: (tabId: string) => void;
  setSelectedTabSortTemporaryOrder?: Dispatch<SetStateAction<string>>;
  isLoading?: boolean;
  settings?: GridSettings;
  openExternalUrl?: (url: string) => void;
  onSelect?: (item: GridItemDataProps) => void;
  onFilter?: (filter: string) => void;
  sortComponent?: React.ReactNode;
  filterComponent?: React.ReactNode;
}

const JunoGrid = forwardRef<HTMLDivElement, JunoGridProps>(
  (
    {
      items,
      clientName,
      tabs,
      onTabSelected,
      selectedTabId,
      setSelectedTabSortTemporaryOrder,
      isLoading,
      settings,
      onSelect,
      onFilter,
      sortComponent,
      filterComponent,
    },
    ref,
  ) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [squareRef, { width }] = useElementSize();
    const settingsObj = useMemo(() => buildGridSettings(settings), [settings]);
    const [accessAlertDialogOpen, setAccessAlertDialogOpen] = useState(false);
    const [courseName, setCourseName] = useState('');
    const [upgradeUrl, setUpgradeUrl] = useState('');
    const openExternalUrl = settings?.openExternalUrl;
    const courseFilterContext = useContext(CourseFilterContext);
    const [dateStartAfterChipLabel, setDateStartAfterChipLabel] = useState('');
    const [dateStartBeforeChipLabel, setDateStartBeforeChipLabel] = useState('');
    const [filteredInstructors, setFilteredInstructors] = useState<string[]>(
      courseFilterContext.tempFilter.instructors__id__in || ['-1'],
    );
    const [filteredTags, setFilteredTags] = useState<string[]>(['-1']);

    const handleClick = (item: GridItemDataProps) => {
      if (item.restrict_access && item.restrict_preview) {
        setAccessAlertDialogOpen(true);
        setCourseName(item.name);
        setUpgradeUrl(item.upgradeUrl || '');
      } else {
        onSelect && onSelect(item);
      }
    };

    const handleClickOpen = () => {
      setDialogOpen(true);
    };

    const handleClose = () => {
      setDialogOpen(false);
    };

    // Filter chips manipulation
    const updateTagsRemoved = (tagId: string, typeId: string) => {
      courseFilterContext.setTagsRemoved((prevTagsRemoved: any) => ({
        ...prevTagsRemoved,
        [typeId]: [...(prevTagsRemoved[typeId] || []), tagId],
      }));
    };
    const handleDeleteFilter = (filter: string, id?: string, typeId?: string) => {
      let removeInstructor;
      let removeTag;
      if (courseFilterContext.tempFilter.instructors__id__in) {
        removeInstructor = courseFilterContext.tempFilter.instructors__id__in.filter(
          (instructor: string) => instructor !== id,
        );
      }
      if (courseFilterContext.tempFilter.tags__id__in) {
        removeTag = courseFilterContext.tempFilter.tags__id__in.filter((tag: string) => tag !== id);
      }
      switch (filter) {
        case 'date_start__gte':
          onFilter && onFilter('filter');
          courseFilterContext.handleDateUpdate(null, 'date_start__gte');
          setDateStartAfterChipLabel('');
          break;
        case 'date_start__lte':
          onFilter && onFilter('filter');
          courseFilterContext.handleDateUpdate(null, 'date_start__lte');
          setDateStartBeforeChipLabel('');
          break;
        case 'instructors__id__in':
          onFilter && onFilter('filter');
          courseFilterContext.handleStringListUpdate(removeInstructor, 'instructors__id__in');
          courseFilterContext.setInstructorsRemoved((prevInstructorSet) => [
            ...prevInstructorSet,
            id || '',
          ]);
          break;
        case 'tags__id__in':
          onFilter && onFilter('filter');
          courseFilterContext.handleTagListUpdate(removeTag, 'tags__id__in');
          updateTagsRemoved(id || '', typeId || '');
          break;
        default:
          return;
      }
    };
    const { data: instructorData } = useGetAllUsers(
      courseFilterContext.platformId,
      {
        filter_or: { id__in: filteredInstructors },
        order: 'last_name',
      },
      { query: { enabled: !filteredInstructors.includes('-1') } },
    );
    const { data: tagData } = useGetAllTags(
      courseFilterContext.siteId,
      {
        filter_or: { id__in: filteredTags },
        order: 'type',
      },
      { query: { enabled: !filteredTags.includes('-1') } },
    );
    // Identify when the user selects filtered options
    useEffect(() => {
      if (filterComponent) {
        const courseFilters = (filterComponent as ReactElement).props.values;
        if (Object.keys(courseFilters).includes('date_start__gte')) {
          const formattedDate = dayjs(courseFilterContext.tempFilter['date_start__gte']).format(
            CHIP_DATE_FORMAT,
          );
          setDateStartAfterChipLabel('Start \u2265 ' + formattedDate);
          onFilter && onFilter('filter');
        } else {
          setDateStartAfterChipLabel('');
          onFilter && onFilter('filter');
        }
        if (Object.keys(courseFilters).includes('date_start__lte')) {
          const formattedDate = dayjs(courseFilterContext.tempFilter['date_start__lte']).format(
            CHIP_DATE_FORMAT,
          );
          setDateStartBeforeChipLabel('Start \u2264 ' + formattedDate);
        } else {
          setDateStartBeforeChipLabel('');
        }
        if (Object.keys(courseFilters).includes('instructors__id__in')) {
          setFilteredInstructors(courseFilters.instructors__id__in);
        } else {
          setFilteredInstructors(['-1']);
        }
        if (Object.keys(courseFilters).includes('tags__id__in')) {
          setFilteredTags(courseFilters.tags__id__in);
        } else {
          setFilteredTags(['-1']);
        }
      } else {
        setDateStartAfterChipLabel('');
        setDateStartBeforeChipLabel('');
      }
    }, [filterComponent]);

    return (
      <Container width={width} ref={squareRef}>
        {settingsObj.showTitle && <Title color='text.primary'>{settingsObj.title}</Title>}
        {/*
          Add a Grid right below the search bar to hold filtered option chips so the user
          doesn't have to have the filter dialog open to know what they are filtering against
        */}
        <Box sx={{ height: '100%', margin: `0px auto` }}>
          <Grid container spacing={1}>
            {dateStartAfterChipLabel && (
              <Grid item>
                <Chip
                  sx={{ borderRadius: '4px 4px 4px 4px', fontSize: '12px', height: '20px' }}
                  variant={'outlined'}
                  onDelete={() => handleDeleteFilter('date_start__gte')}
                  deleteIcon={<CloseIcon style={{ fontSize: '12px' }} />}
                  label={dateStartAfterChipLabel}
                  color='primary'
                />
              </Grid>
            )}
            {dateStartBeforeChipLabel && (
              <Grid item>
                <Chip
                  sx={{ borderRadius: '4px 4px 4px 4px', fontSize: '12px', height: '20px' }}
                  variant={'outlined'}
                  onDelete={() => handleDeleteFilter('date_start__lte')}
                  deleteIcon={<CloseIcon style={{ fontSize: '12px' }} />}
                  label={dateStartBeforeChipLabel}
                  color='primary'
                />
              </Grid>
            )}
            {instructorData &&
              instructorData.map((item) => (
                <Grid item key={item.id}>
                  <Chip
                    sx={{ borderRadius: '4px 4px 4px 4px', fontSize: '12px', height: '20px' }}
                    variant={'outlined'}
                    onDelete={() => handleDeleteFilter('instructors__id__in', item.id)}
                    deleteIcon={<CloseIcon style={{ fontSize: '12px' }} />}
                    label={`${item.first_name.length > 0 ? item.first_name[0] + '.' : ''} ${
                      item.last_name
                    }`}
                    color='primary'
                  />
                </Grid>
              ))}
            {tagData &&
              tagData.map((item) => (
                <Grid item key={item.id}>
                  <Chip
                    sx={{ borderRadius: '4px 4px 4px 4px', fontSize: '12px', height: '20px' }}
                    variant={'outlined'}
                    onDelete={() => handleDeleteFilter('tags__id__in', item.id, item.type.id)}
                    deleteIcon={<CloseIcon style={{ fontSize: '12px' }} />}
                    label={`${item.value}`}
                    color='primary'
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
        {/* End filter chips Grid */}
        {(tabs?.length || 0) > 1 && (
          <Tabs
            scrollButtons='auto'
            variant='scrollable'
            value={selectedTabId || ''}
            onChange={(_: React.SyntheticEvent, selectedId: string) => {
              const previousTab = tabs?.find((tab) => tab.id === selectedTabId);
              if (previousTab) {
                previousTab.temporarySort = '';
                if (setSelectedTabSortTemporaryOrder) {
                  setSelectedTabSortTemporaryOrder('');
                }
              }
              onTabSelected && onTabSelected(selectedId);
            }}
            aria-label='filtered results tabs'
            sx={{ mb: 2 }}
          >
            {tabs?.map((tabObj) => (
              <Tab
                key={tabObj.id}
                value={tabObj.id}
                label={tabObj.label}
                style={{ textTransform: 'none' }}
              />
            ))}
          </Tabs>
        )}
        {isLoading && <DataLoadingSkeletonGrid />}
        {!isLoading && (
          <>
            {items?.length === 0 ? (
              <Typography sx={{ mt: 6, textAlign: 'center' }}>No results found</Typography>
            ) : (
              <GridItemsContainer
                itemWidth={settingsObj.itemWidth}
                spacing={settingsObj.spacing}
                sx={{ alignItems: 'start' }}
              >
                {items?.map((item, idx) => (
                  <GridItemContainer
                    onClick={() => handleClick(item)}
                    sx={{ textAlign: settingsObj.textAlign }}
                    ref={(idx === items.length - 1 && ref) || null}
                    key={item.id}
                  >
                    {settingsObj.dataModel !== 'Library' ? (
                      <GridItemImage
                        alt={item.name}
                        src={optimizeImage(settingsObj.itemWidth, item.image)}
                        itemWidth={settingsObj.itemWidth}
                        itemHeight={settingsObj.itemHeight}
                        variant={settingsObj.variant}
                      />
                    ) : (
                      <Box
                        sx={{
                          bgcolor: 'rgba(0,0,0,1)',
                          color: 'white',
                          borderRadius:
                            settingsObj.variant === 'circular'
                              ? '50%'
                              : settingsObj.variant === 'rounded'
                              ? 1
                              : '0px',
                          width: settingsObj.itemWidth,
                          height: settingsObj.itemHeight,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant='h5' fontWeight={'bolder'}>
                          {item.name}
                        </Typography>
                      </Box>
                    )}
                    {item.restrict_access && item.restrict_preview ? (
                      <LockedIndicator
                        itemHeight={settingsObj.itemHeight}
                        sx={{ color: 'primary.main' }}
                      >
                        <LockIcon />
                      </LockedIndicator>
                    ) : (
                      settingsObj.showProgress && (
                        <ProgressIndicator
                          itemHeight={settingsObj.itemHeight}
                          sx={{ color: 'primary.contrastText', bgcolor: 'primary.main' }}
                        >
                          <Typography>{item.progressText}</Typography>
                        </ProgressIndicator>
                      )
                    )}
                    {/* tired of trying to edit courses that can't be edited anymore - Dom */}
                    {item.can_edit === false && (
                      <LockedIndicator
                        itemHeight={settingsObj.itemHeight}
                        sx={{ color: 'primary.background', opacity: 0.5 }}
                      >
                        <EditOffIcon />
                      </LockedIndicator>
                    )}

                    {settingsObj.showItemTitle && (
                      <GridItemTitle color='text.primary'>{item.name}</GridItemTitle>
                    )}
                    {settingsObj.showItemDescription && (
                      <GridItemDescription color='text.primary'>
                        {item.description}
                      </GridItemDescription>
                    )}
                    {item.paywall && <PaymentIcon />}
                  </GridItemContainer>
                ))}
              </GridItemsContainer>
            )}
          </>
        )}

        {accessAlertDialogOpen && (
          <RestrictCoursePreviewDialog
            handleClose={() => {
              setAccessAlertDialogOpen(false);
            }}
            handleUpgrade={(url) => {
              openExternalUrl?.call(this, url);
              setAccessAlertDialogOpen(false);
            }}
            courseName={courseName}
            clientName={clientName}
            upgradeUrl={upgradeUrl}
          />
        )}

        <CourseFilterDialog
          open={dialogOpen}
          onClose={handleClose}
          onFilter={onFilter}
          filterComponent={filterComponent}
        />
      </Container>
    );
  },
);

export default JunoGrid;
