import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Card, TextField, Typography } from '@mui/material';
import { useFeatureFlag } from 'configcat-react';
import { Site as SiteModel } from '@juno/client-api/model';
import { FeatureConfig } from '@juno/client-api/utils';
import { EmailThemeConfigProps, ThemeConfigProps } from '@juno/constants';
import { EditHeaderForm, SiteDesignPanel, SiteEmailDesignPanel } from '@juno/modules';
import { Container, JunoSpin } from '@juno/ui';
import { useSettings } from '@juno/utils';
import Embed from './Embed';
import TermsOfServiceForm from './TermsOfServiceForm';

interface AppearanceProps {
  site: SiteModel;
}

const General: React.FC<AppearanceProps> = ({ site }) => {
  const { configs } = useSettings();
  const [themeConfig, setThemeConfig] = useState<ThemeConfigProps | undefined>(
    FeatureConfig.getThemeConfigTheme(configs),
  );
  const [emailConfig, setEmailConfig] = useState<EmailThemeConfigProps | undefined>(
    FeatureConfig.getEmailConfigConfig(configs),
  );
  const { value: termsOfServiceValue, loading: termsOfServiceLoading } = useFeatureFlag(
    'termsOfService',
    false,
  );

  const handleEditFixture = (fixture: string) => {
    const moduleEl = document.getElementById(`pagebuilder-${fixture}`) as HTMLElement;
    if (moduleEl) {
      setTimeout(() => window.scrollTo({ behavior: 'smooth', top: moduleEl.offsetTop }), 100);
    }
  };

  const handleSaveEditFixture = () => {};

  const handleDeleteFixture = () => {};

  const handleUpdateEditFixture = () => {
    // setModuleEditing(component);
  };

  if (termsOfServiceLoading) return <JunoSpin />;

  return (
    <Container>
      <Card sx={{ p: 2, pb: 0, boxShadow: (theme) => theme.shadows[1] }}>
        <Typography variant='overline' sx={{ mb: 1 }}>
          Branding
        </Typography>
        <Typography variant='body2' sx={{ mb: 5, mt: 1 }}>
          Click on or drag an image to either image container below.
        </Typography>
        <EditHeaderForm
          siteId={site.id}
          configs={configs || []}
          navigationItems={[]}
          pages={[]}
          onSave={handleSaveEditFixture}
          onUpdate={handleUpdateEditFixture}
          onDelete={handleDeleteFixture}
        />
      </Card>
      <Card sx={{ p: 2, mt: 3, mb: 4, pb: 0, boxShadow: (theme) => theme.shadows[1] }}>
        <Typography variant='overline' sx={{ mb: 1 }}>
          Theme
        </Typography>
        <Typography variant='body2' sx={{ mb: 3, mt: 1 }}>
          Choose your site's font and theme colors.
        </Typography>
        <SiteDesignPanel
          site={site}
          themeConfig={themeConfig}
          setThemeConfig={setThemeConfig}
          configs={configs}
        />
      </Card>
      <Card sx={{ p: 2, mt: 3, mb: 4, pb: 0, boxShadow: (theme) => theme.shadows[1] }}>
        <Typography variant='overline' sx={{ mb: 1 }}>
          Site Email Branding
        </Typography>
        <Typography variant='body2' sx={{ mb: 3, mt: 1 }}>
          Choose your site's email branding and colors.
        </Typography>
        <SiteEmailDesignPanel
          site={site}
          emailConfig={emailConfig}
          setEmailConfig={setEmailConfig}
          configs={configs}
        />
      </Card>
      {termsOfServiceValue && <TermsOfServiceForm />}
      <Embed />
    </Container>
  );
};

export default General;
