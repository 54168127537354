import * as Yup from 'yup';
import { string } from 'yup';

const dateError = 'Date is invalid';

const validationSchema = Yup.object().shape({
  title: string().max(90, 'Title character limit: 90'),
  slug: string().max(90, 'Slug character limit: 90'),
  preview_text: string().max(78, 'Preview text character limit: 78').nullable(),
  end_date: Yup.date()
    .nullable()
    .typeError(dateError)
    .when('release_date', {
      is: (release_date: Date) => release_date,
      then: Yup.date()
        .typeError(dateError)
        .required('Required')
        .test('after_release', 'must be after release date', function (value) {
          if (this.parent.release_date && value) {
            const start = new Date(this.parent.release_date).getTime();
            const end = value.getTime();
            return end > start;
          }
          return true;
        })
        .nullable(),
    }),
});

export default validationSchema;
