import React, { useRef, useState } from 'react';
import {
  ClickAwayListener,
  Fade,
  IconButton,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
} from '@mui/material';
import { Options } from '@popperjs/core';

interface JunoPopperMenuProps {
  icon: React.ReactNode;
  children?: React.ReactNode;
  placement?: PopperPlacementType;
  popperStyle?: React.CSSProperties;
  menuStyle?: React.CSSProperties;
  modifiers?: Options['modifiers'];
  menuWidth?: number;
  style?: React.CSSProperties;
}

const JunoPopperMenu: React.FC<JunoPopperMenuProps> = ({
  children,
  icon,
  popperStyle,
  menuStyle,
  modifiers = [],
  placement = 'bottom-end',
  style,
}) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpen = () => setMenuOpen(true);
  const handleClose = () => setMenuOpen(false);

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      handleClose();
    } else if (event.key === 'Escape') {
      handleClose();
    }
  };

  return (
    <div style={{ ...style }}>
      <IconButton onClick={handleOpen} ref={anchorRef}>
        {icon}
      </IconButton>
      <Popper
        open={menuOpen}
        anchorEl={anchorRef.current}
        placement={placement}
        transition
        disablePortal
        style={{ borderRadius: '8px', zIndex: 1300, ...popperStyle }}
        modifiers={modifiers}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={100}>
            <Paper style={{ borderRadius: '8px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={menuOpen}
                  onKeyDown={handleListKeyDown}
                  onClick={handleClose}
                  style={menuStyle}
                >
                  {children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

export default JunoPopperMenu;
