/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type ReoccurrenceEnum = typeof ReoccurrenceEnum[keyof typeof ReoccurrenceEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReoccurrenceEnum = {
  once: 'once',
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
  yearly: 'yearly',
} as const;
