import { useUIState } from '../../contexts/UIStateProvider';
import BreakoutRoomAside from '../Aside/BreakoutRoomAside';
import ChatAside from '../Aside/ChatAside';
import PeopleAside from '../Aside/PeopleAside';
import QuestionAnswerAside from '../Aside/QuestionAnswerAside';

export const Asides = () => {
  const { asides } = useUIState();

  return (
    <>
      <ChatAside />
      <PeopleAside />
      <QuestionAnswerAside />
      <BreakoutRoomAside />
      {asides.map((AsideComponent) => (
        <AsideComponent key={AsideComponent.name} />
      ))}
    </>
  );
};

export default Asides;
