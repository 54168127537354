import * as React from 'react';
import { Box, Button, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

interface ScoreDialogProps {
  open?: boolean;
  handleMoveOn?: () => void;
  handleRetake?: () => void;
  score?: number;
  passingScore?: number;
  subject?: string;
  // title?: React.ReactNode;
  buttonTitle?: string;
  canIRetake?: boolean;
  numRetakesLeft?: number;
  retakesEnabled?: boolean;
}

const ScoreDialog: React.FC<ScoreDialogProps> = ({
  open = true,
  score = 0,
  passingScore = 0,
  subject = 'lesson',
  buttonTitle = 'Complete lesson',
  numRetakesLeft,
  canIRetake,
  handleMoveOn,
  handleRetake,
  retakesEnabled,
}) => {
  const handleClose = () => {
    handleMoveOn && handleMoveOn();
  };
  const passed = score >= passingScore;
  const title = passed ? 'You Passed!' : 'Bad Luck!';
  const retakeString =
    (numRetakesLeft || 0) < 0
      ? `You can retake this lesson as many times as you want if you'd like to improve your score.`
      : `You can retake this lesson up to ${numRetakesLeft} time${
          (numRetakesLeft || 0) === 1 ? '' : 's'
        } if you'd like to improve your score.`;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='xs'
      sx={{ textAlign: 'center' }}
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        {!passed && (
          <Typography sx={{ mb: 2 }}>{`You need at least ${passingScore}% to pass`}</Typography>
        )}
        {passed && <Typography>Great job, you passed the {subject}!</Typography>}
        <Typography sx={{ fontWeight: 'bold' }}>{`You scored ${score}%`}</Typography>
        {retakesEnabled && canIRetake && <Typography sx={{ mt: 2 }}>{retakeString}</Typography>}
      </DialogContent>
      <Box sx={{ flexDirection: 'column', padding: 2 }}>
        {retakesEnabled && (
          <Button
            fullWidth
            variant='outlined'
            onClick={handleRetake}
            disabled={!canIRetake}
            size='large'
            sx={{ mb: 1 }}
          >
            {numRetakesLeft === 0 ? 'No retakes left' : 'Retake the lesson'}
          </Button>
        )}
        <Button fullWidth variant='contained' onClick={handleMoveOn} autoFocus size='large'>
          {buttonTitle}
        </Button>
      </Box>
    </Dialog>
  );
};

export default ScoreDialog;
