import React from 'react';
import { Alert, AlertColor, Box } from '@mui/material';
import classNames from 'classnames';
import RCNotification from 'rc-notification';
import {
  NotificationProps,
  NotificationInstance as RCNotificationInstance,
} from 'rc-notification/lib/Notification';

const DEFAULT_DURATION = 4;
const MAX_COUNT = 1;
const DEFAULT_PREFIX_CLS = 'juno-note';

export interface NotificationCallbackArgs {
  prefixCls: string;
  instance: RCNotificationInstance;
}

export interface ArgsProps {
  message?: React.ReactNode;
  description?: React.ReactNode;
  btn?: React.ReactNode;
  key?: string;
  onClose?: () => void;
  duration?: number | null;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  prefixCls?: string;
  className?: string;
  readonly type?: string;
  onClick?: () => void;
  getContainer?: () => HTMLElement;
  closeIcon?: React.ReactNode;
  autoDismiss?: boolean;
}

export interface NotificationInstance {
  info(message: React.ReactNode, args?: ArgsProps): void;
  warning(message: React.ReactNode, args?: ArgsProps): void;
  error(message: React.ReactNode, args?: ArgsProps): void;
  success(message: React.ReactNode, args?: ArgsProps): void;
}

export interface NotificationApi extends NotificationInstance {
  open(message: React.ReactNode, args?: ArgsProps): void;
  close(key: string): void;
  closeAll(callback?: () => void): void;
  destroy(): void;
}

const getNotificationStyles = () => ({
  display: 'flex',
  position: 'fixed',
  bottom: 22,
  right: 22,
  border: '1px solid rgba(85, 72, 31, 0.2)',
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  fontSize: '0.875em',
  fontWeight: 500,
  borderRadius: 4,
  maxWidth: 344,
  height: 'fit-content',
  zIndex: 1301,
});

const getAnimationStyles = (prefixCls: string) => `
  .${prefixCls}-fade-enter,
  .${prefixCls}-fade-appear {
    animation: 0.25s ease-in-out 0s 1 normal paused NotificationFadeIn;
  }

  .${prefixCls}-fade-leave {
    opacity: 0;
    animation: 0.25s ease-in-out 0s 1 normal paused NotificationFadeOut;
  }

  .${prefixCls}-fade-enter.${prefixCls}-fade-enter-active,
  .${prefixCls}-fade-appear.${prefixCls}-fade-appear-active,
  .${prefixCls}-fade-leave.${prefixCls}-fade-leave-active {
    animation-play-state: running;
  }

  @keyframes NotificationFadeIn {
    0% { right: 0px; opacity: 0; }
    100% { right: 22px; opacity: 1; }
  }

  @keyframes NotificationFadeOut {
    0% { right: 22px; opacity: 1; }
    100% { right: 0px; opacity: 0; }
  }
`;

const notificationInstance: { [key: string]: Promise<RCNotificationInstance> } = {};

const getNotificationInstance = (
  args: ArgsProps,
  callback: (info: NotificationCallbackArgs) => void,
) => {
  const { getContainer, prefixCls: customPrefixCls } = args;
  const prefixCls = `${customPrefixCls || DEFAULT_PREFIX_CLS}-notification`;
  const cacheKey = `${prefixCls}-cache`;

  const cacheInstance = notificationInstance[cacheKey];
  if (cacheInstance) {
    Promise.resolve(cacheInstance).then((instance) => {
      callback && callback({ prefixCls, instance });
    });
    return;
  }

  notificationInstance[cacheKey] = new Promise((resolve) => {
    RCNotification.newInstance(
      {
        prefixCls,
        className: classNames(cacheKey, { [`${prefixCls}-rtl`]: false }),
        getContainer,
        maxCount: MAX_COUNT,
      },
      (notification) => {
        resolve(notification);
        callback &&
          callback({
            prefixCls,
            instance: notification,
          });
      },
    );
  });
};

const getRCNoticeProps = (args: ArgsProps, prefixCls: string) => {
  const {
    duration: durationArg,
    type,
    message,
    onClose,
    onClick,
    style,
    className,
    autoDismiss,
  } = args;

  const duration = durationArg === undefined ? DEFAULT_DURATION : durationArg;

  const notificationClass = classNames(className, {
    [`${prefixCls}-${type}`]: !!type,
  });

  return {
    content: (
      <Box style={{ display: 'flex', ...style }}>
        <Alert variant='filled' severity={type as AlertColor | undefined}>
          {message}
        </Alert>
        <style>{getAnimationStyles(prefixCls)}</style>
      </Box>
    ),
    duration: autoDismiss !== false ? duration : null,
    closable: true,
    // closeIcon: <div className={`${prefixCls}-close-x`}>{closeIconNode}</div>,
    onClose,
    onClick,
    style: { ...getNotificationStyles(), ...style },
    className: notificationClass,
  };
};

const closeAllNotifications = (callback?: () => void) => {
  const instanceKeys = Object.keys(notificationInstance);
  if (instanceKeys.length === 0) {
    callback && callback();
    return;
  }
  instanceKeys.forEach((cacheKey) =>
    Promise.resolve(notificationInstance[cacheKey]).then((instance) => {
      const notices = instance?.component?.state?.notices || [];
      notices.forEach(({ notice: { key } }) => {
        if (key) {
          instance.removeNotice(key);
        }
      });
      callback && callback();
    }),
  );
};

const notice = (message: string | React.ReactNode, args: ArgsProps) => {
  const newArgs = { ...args, message };
  closeAllNotifications(() => {
    getNotificationInstance(newArgs, ({ prefixCls, instance }) => {
      instance.notice(getRCNoticeProps(newArgs, prefixCls) as NotificationProps);
    });
  });
};

const api = {
  open: notice,
  close(key: string) {
    Object.keys(notificationInstance).forEach((cacheKey) =>
      Promise.resolve(notificationInstance[cacheKey]).then((instance) => {
        instance.removeNotice(key);
      }),
    );
  },
  closeAll: closeAllNotifications,
  destroy() {
    Object.keys(notificationInstance).forEach((cacheKey) => {
      Promise.resolve(notificationInstance[cacheKey]).then((instance) => {
        instance.destroy();
      });
      delete notificationInstance[cacheKey];
    });
  },
  info: (message: React.ReactNode, args?: ArgsProps) =>
    api.open(message || args?.message, { ...args, type: 'info' }),
  warning: (message: React.ReactNode, args?: ArgsProps) =>
    api.open(message || args?.message, { ...args, type: 'warning' }),
  error: (message: React.ReactNode, args?: ArgsProps) =>
    api.open(message || args?.message, { ...args, type: 'error' }),
  success: (message: React.ReactNode, args?: ArgsProps) =>
    api.open(message || args?.message, { ...args, type: 'success' }),
};

export default api as NotificationApi;
