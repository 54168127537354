import React, { useRef } from 'react';
import { Box } from '@mui/material';
import { createPortal } from 'react-dom';
import { useOutsideAlerter } from '@juno/utils';
import { useBreakpoint } from '@juno/utils/hooks';

interface AppSafePortalProps {
  handleClose: () => void;
  children: React.ReactNode;
}
const AppSafePortal: React.FC<AppSafePortalProps> = ({ handleClose, children }) => {
  const { xs } = useBreakpoint();
  const portalRef = useRef(null);

  useOutsideAlerter(portalRef, (e) => {
    handleClose();
  });
  return (
    <>
      {createPortal(
        <Box
          position={'fixed'}
          sx={{
            display: 'flex',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            flexDirection: 'column',
            background: 'rgba(0,0,0,0.40)',
            pointerEvents: 'all',
          }}
        >
          <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                p: 0,
                pb: 'var(--bottomsafearea)',
                pt: 'var(--topsafearea)',
                background: 'white',
                width: xs ? '100vw' : '60vw',
                height: xs ? '100vh' : 'auto',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                borderRadius: '4px',
                boxShadow:
                  '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
              }}
              ref={portalRef}
            >
              {children}
            </Box>
          </Box>
        </Box>,
        document.getElementById('messages') ?? document.body,
      )}
    </>
  );
};
export default AppSafePortal;
