import React, { useMemo } from 'react';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { LessonPart, Question } from '@juno/client-api/model';
import { LessonPart as LessonPartUtils } from '@juno/client-api/utils';
import { Blurb } from '@juno/modules';
import { Container, JunoRotator, LessonTimer } from '@juno/ui';
import { optimizeImage } from '@juno/utils';
import { useBreakpoint } from '@juno/utils/hooks';
import CourseProgressBanner from '../../CourseProgressBanner';
import JunoQuestion from '../../JunoQuestion';
import PointsButton from '../../components/PointsButton';
import ScoreDialog from '../../components/ScoreDialog';
import VideoPart from '../VideoPart';
import {
  checkIncomplete,
  getQuestionInitialAnswers,
  highlightIncompleteQuestionsAndScroll,
  highlightIncompleteVideosAndScroll,
  wasQuestionAnswered,
  wasVideoWatched,
} from '../utils/methods';
import {
  ButtonContainer,
  DetailImage,
  LessonButtonSectionBox,
  LessonNavigationButton,
} from '../utils/styles';
import {
  IncompleteDialog,
  RetakeDialog,
  RetakeErrorDialog,
  TimedLessonPromptDialog,
  TimesUpDialog,
  UnwatchedVideoDialog,
} from './dialogs';

export const lessonPageRenderer = ({ ...props }) => {
  const {
    canIRetake,
    course,
    currentCompletion,
    handleGoBack,
    handleLessonCompletedButtonClick,
    handleNext,
    handlePreviousLessonButtonClick,
    handleTimesUp,
    handleVideoActivity,
    isLessonComplete,
    isSaving,
    isTimed,
    lesson,
    lessonCompleteDialogOpen,
    lessonScore,
    numRetakesLeft,
    onAnswerFITBQuestion,
    onAnswerMultipleChoiceQuestion,
    onAnswerRankingQuestion,
    onAnswerRatingQuestion,
    openRetakeErrorModal,
    openRetakeModal,
    parts,
    percentComplete,
    refresh,
    retakeTheLesson,
    retakesEnabled,
    scormUrl,
    setLessonCompleteDialogOpen,
    setOpenRetakeErrorModal,
    setOpenRetakeModal,
    setShouldPauseVideo,
    setShowIncompleteDialog,
    setShowIncompleteQuestions,
    setShowTimedLessonPrompt,
    setShowUnwatchedDialog,
    setShowUnwatchedVideos,
    shouldPauseVideo,
    showIncompleteDialog,
    showIncompleteQuestions,
    showTimedLessonPrompt,
    showUnwatchedDialog,
    showUnwatchedVideos,
    showTimesUpDialog,
    setShowTimesUpDialog,
    step,
    timeStarted,
    totalSteps,
    userAnswers,
    videoCompletions,
    canCompleteLesson,
    siteId,
    imgRef,
    imageWidth,
    startTimedLesson,
    downloadPartResource,
    isRetake,
  } = props;

  const isLoading = !course || !lesson || !parts || !currentCompletion;
  const showTimer =
    isTimed &&
    !isLoading &&
    !isLessonComplete &&
    currentCompletion?.date_started &&
    !showTimesUpDialog &&
    !lessonCompleteDialogOpen;

  const dateStarted = new Date(currentCompletion?.date_started);
  const diff = (timeStarted.current.getTime() - dateStarted.getTime()) / 1000;
  const startingTime = Math.floor(lesson?.time_allowed - diff);

  const handleRetakeLesson = async () => {
    setOpenRetakeModal(true);
    setLessonCompleteDialogOpen(false);
  };

  return (
    <>
      {showTimer && (
        <LessonTimer
          time={startingTime}
          timeAllowed={lesson.time_allowed}
          handleTimesUp={() => {
            setShowTimesUpDialog(true);
          }}
        />
      )}
      <Container>
        {isLoading ? (
          <Skeleton variant='rectangular' width='100%' height={90} />
        ) : (
          <CourseProgressBanner
            title={course.title}
            icon={course.icon}
            step={step}
            totalSteps={totalSteps}
            percentComplete={percentComplete}
            onBack={handleGoBack}
          />
        )}

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: '40px',
          }}
        >
          <Typography variant='h5' color='text.primary' sx={{ fontWeight: 'bold' }}>
            {isLoading ? <Skeleton variant='rectangular' width={120} /> : lesson.title}
          </Typography>
          {isLoading ? (
            <Skeleton variant='rectangular' width={120} />
          ) : (
            isLessonComplete &&
            canIRetake &&
            !refresh && (
              <Button variant={`contained`} onClick={handleRetakeLesson}>
                Retake
              </Button>
            )
          )}
        </Box>
        {isLoading ? (
          <Skeleton variant='rectangular' width='100%' height={250} sx={{ mt: 4 }} />
        ) : (
          <>
            {lesson.banner && (
              <DetailImage
                ref={imgRef}
                imageWidth={imageWidth}
                src={optimizeImage(imageWidth, lesson.banner)}
                alt={'lesson banner'}
                variant='rounded'
                sx={{ mt: 4 }}
              />
            )}
            <Box sx={{ mt: 4 }}>
              <Blurb html={lesson.description} />
            </Box>
          </>
        )}
      </Container>
      {!!scormUrl && (
        <Box sx={{ position: 'relative', paddingBottom: '56.25%' }}>
          <Box
            component='iframe'
            id='iframe'
            title='iframe'
            src={scormUrl || ''}
            sx={{
              backgroundColor: 'white',
              width: '1',
              height: '100%',
              maxHeight: 'calc(100vh - 60px)',
              overflow: 'auto',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        </Box>
      )}
      <Container>
        {isLoading || showTimedLessonPrompt || refresh ? (
          <Skeleton variant='rectangular' width='100%' height={90} sx={{ mt: 2 }} />
        ) : (
          <LessonPartsSection
            parts={parts}
            onAnswerMultipleChoiceQuestion={onAnswerMultipleChoiceQuestion}
            onAnswerRankingQuestion={onAnswerRankingQuestion}
            onAnswerRatingQuestion={onAnswerRatingQuestion}
            onAnswerFITBQuestion={onAnswerFITBQuestion}
            isLessonComplete={isLessonComplete}
            showIncompleteQuestions={showIncompleteQuestions}
            userAnswers={userAnswers}
            currentCompletion={currentCompletion}
            handleVideoActivity={handleVideoActivity}
            showUnwatchedVideos={showUnwatchedVideos}
            setShowUnwatchedVideos={setShowUnwatchedVideos}
            videoCompletions={videoCompletions}
            shouldPauseVideo={shouldPauseVideo}
            lesson={lesson}
            courseId={course.id}
            siteId={siteId}
            downloadPartResource={downloadPartResource}
            isRetake={isRetake}
          />
        )}

        {isLoading || showTimedLessonPrompt || refresh ? (
          <Skeleton variant='rectangular' width={120} height={60} sx={{ mt: 2, float: 'right' }} />
        ) : (
          <LessonButtonSection
            step={step}
            isSaving={isSaving}
            handlePreviousLessonButtonClick={handlePreviousLessonButtonClick}
            isLessonComplete={isLessonComplete}
            setShouldPauseVideo={setShouldPauseVideo}
            parts={parts}
            videoCompletions={videoCompletions}
            userAnswers={userAnswers}
            lesson={lesson}
            handleNext={handleNext}
            setShowIncompleteDialog={setShowIncompleteDialog}
            setShowUnwatchedDialog={setShowUnwatchedDialog}
            handleLessonCompletedButtonClick={handleLessonCompletedButtonClick}
            canCompleteLesson={canCompleteLesson}
          />
        )}
        {/* DIALOGS */}
        {!isLoading && (
          <>
            {/* showUnwatchedDialog */}
            <UnwatchedVideoDialog
              open={showUnwatchedDialog}
              onClose={() => {
                setShowUnwatchedDialog(false);
                setShowUnwatchedVideos(true);
                highlightIncompleteVideosAndScroll(parts ?? [], videoCompletions);
              }}
            />
            {/* showIncompleteDialog */}
            <IncompleteDialog
              open={showIncompleteDialog}
              onClose={() => {
                setShouldPauseVideo(false);
                setShowIncompleteDialog(false);
                setShowIncompleteQuestions(true);
                highlightIncompleteQuestionsAndScroll(parts ?? [], userAnswers);
              }}
              isSaving={isSaving}
              handleNext={handleNext}
            />
            {/* openRetakeModal */}
            <RetakeDialog
              open={openRetakeModal}
              onClose={() => {
                setOpenRetakeModal(false);
              }}
              onRetake={() => {
                setOpenRetakeModal(false);
                if (isTimed) {
                  setShowTimedLessonPrompt(true);
                } else {
                  retakeTheLesson();
                }
              }}
            />
            {/* openRetakeErrorModal */}
            <RetakeErrorDialog
              open={openRetakeErrorModal}
              onClose={() => setOpenRetakeErrorModal(false)}
            />
            {/* showTimedLessonPrompt */}
            <TimedLessonPromptDialog
              open={showTimedLessonPrompt}
              onClose={() => {
                handleGoBack();
              }}
              onBegin={() => {
                setShowTimedLessonPrompt(false);
                if (isLessonComplete) {
                  retakeTheLesson();
                } else {
                  startTimedLesson();
                }
              }}
              timeAllowed={lesson?.time_allowed ?? 0}
            />
            {/* showTimesUpDialog */}
            <TimesUpDialog
              open={showTimesUpDialog}
              onClose={() => {
                setShowTimesUpDialog(false);
                handleTimesUp();
              }}
            />
            {/* lessonCompleteDialogOpen */}
            <ScoreDialog
              score={lessonScore}
              passingScore={lesson.passing_percent}
              open={lessonCompleteDialogOpen}
              handleMoveOn={handleLessonCompletedButtonClick}
              handleRetake={handleRetakeLesson}
              canIRetake={canIRetake}
              numRetakesLeft={numRetakesLeft}
              retakesEnabled={retakesEnabled}
              buttonTitle='Complete lesson'
              subject='lesson'
            />
          </>
        )}
      </Container>
    </>
  );
};

const LessonPartsSection = ({
  parts,
  onAnswerMultipleChoiceQuestion,
  onAnswerRankingQuestion,
  onAnswerRatingQuestion,
  onAnswerFITBQuestion,
  isLessonComplete,
  showIncompleteQuestions,
  userAnswers,
  currentCompletion,
  handleVideoActivity,
  showUnwatchedVideos,
  setShowUnwatchedVideos,
  videoCompletions,
  shouldPauseVideo,
  lesson,
  courseId,
  siteId,
  downloadPartResource,
  isRetake,
}: any) => {
  const getIsIncomplete = (part: LessonPart) => {
    if (
      !currentCompletion.metadata.video_completion?.[part.id] ||
      currentCompletion.metadata.video_completion?.[part.id] === 0
    ) {
      setShowUnwatchedVideos(false);
    }
    return !!(showUnwatchedVideos && !wasVideoWatched(part, videoCompletions));
  };

  const sortedParts = useMemo(() => {
    if (lesson?.randomize_lesson_part_order) {
      return [...parts].sort(() => Math.random() - 0.5);
    }
    return parts;
  }, [parts, lesson?.randomize_lesson_part_order]);

  return sortedParts?.map((part: LessonPart) => {
    if (LessonPartUtils.isQuestionType(part.type)) {
      return (
        <JunoQuestion
          key={part.id}
          id={`part-${part.id}`}
          question={part?.question?.[0]}
          onAnswerMultipleChoice={onAnswerMultipleChoiceQuestion}
          onAnswerRanking={onAnswerRankingQuestion}
          onAnswerRating={onAnswerRatingQuestion}
          onAnswerFillInTheBlank={onAnswerFITBQuestion}
          disabled={isLessonComplete}
          isIncomplete={
            showIncompleteQuestions && !wasQuestionAnswered(part?.question?.[0], userAnswers)
          }
          lessonId={lesson.id}
          courseId={courseId}
          siteId={siteId}
          initialAnswers={getQuestionInitialAnswers(part?.question?.[0], userAnswers)}
          isRetake={isRetake}
        />
      );
    } else if (LessonPartUtils.isBlurbType(part.type)) {
      return <Blurb key={part.id} html={part.body} />;
    } else if (LessonPartUtils.isVideoType(part.type)) {
      return (
        <VideoPart
          id={`part-${part.id}`}
          part={part}
          completion={currentCompletion}
          setVideoWatched={handleVideoActivity}
          key={part.id}
          isIncomplete={getIsIncomplete(part)}
          shouldPause={shouldPauseVideo}
          lessonId={lesson?.id || ''}
          showNativeControls={isLessonComplete || part.video_required_completion_percent === 0}
        />
      );
    } else if (LessonPartUtils.isResourceType(part.type)) {
      return (
        <JunoRotator
          isLoading={false}
          slides={
            part.resources?.map((r) => ({
              id: r.id,
              slug: r.id,
              name: r.title,
              description: r.description,
              image: r.icon || '',
            })) || []
          }
          settings={{
            showPauseButton: false,
            title: 'Resources',
            slideVariant: 'rounded',
            slideWidth: 240,
            slideHeight: 150,
            minSpacing: 10,
            textAlign: 'left',
            showItemDescription: true,
          }}
          onSelect={(slide) => {
            downloadPartResource(part, slide.id);
          }}
        />
      );
    }
    return null;
  });
};

const LessonButtonSection = ({
  step,
  isSaving,
  handlePreviousLessonButtonClick,
  isLessonComplete,
  setShouldPauseVideo,
  parts,
  videoCompletions,
  userAnswers,
  lesson,
  handleNext,
  setShowIncompleteDialog,
  setShowUnwatchedDialog,
  handleLessonCompletedButtonClick,
  canCompleteLesson,
}: any) => {
  const { xs } = useBreakpoint();
  return (
    <LessonButtonSectionBox
      sx={{ display: 'flex', justifyContent: step > 1 ? 'space-between' : 'right' }}
    >
      {step > 1 && (
        <ButtonContainer xs isPrevButton={true}>
          <LessonNavigationButton
            variant='outlined'
            loading={isSaving}
            onClick={() => {
              handlePreviousLessonButtonClick();
            }}
          >
            {`Previous ${xs ? '' : ' Lesson'}`}
          </LessonNavigationButton>
        </ButtonContainer>
      )}
      <ButtonContainer xs>
        {!isLessonComplete && (
          <PointsButton
            title={`Complete ${xs ? '' : ' Lesson'}`}
            points={10}
            onClick={() => {
              setShouldPauseVideo(true);
              checkIncomplete(
                parts ?? [],
                videoCompletions,
                (questions: (Question | undefined)[]) => {
                  if (
                    !lesson?.include_in_course_grade ||
                    !questions ||
                    userAnswers.length === questions.length
                  ) {
                    handleNext();
                  } else {
                    setShowIncompleteDialog(true);
                  }
                },
                () => {
                  setShowUnwatchedDialog(true);
                },
              );
            }}
            loading={isSaving}
            disabled={!canCompleteLesson}
          />
        )}
        {isLessonComplete && (
          <LessonNavigationButton
            variant='outlined'
            loading={isSaving}
            onClick={() => {
              handleLessonCompletedButtonClick();
            }}
          >
            {`Next ${xs ? '' : ' Lesson'}`}
          </LessonNavigationButton>
        )}
      </ButtonContainer>
    </LessonButtonSectionBox>
  );
};
