import React, { useEffect, useRef, useState } from 'react';
import { RefObject } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, List, Tooltip as MUITooltip, Skeleton, Typography } from '@mui/material';
import { curveMonotoneX } from '@visx/curve';
import { AnimatedAxis, LineSeries, Tooltip, XYChart } from '@visx/xychart';
import dayjs, { Dayjs } from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEventListener } from 'usehooks-ts';
import {
  getAnalyticsActivitiesAggregate,
  getAnalyticsActivitiesGraph,
  getAnalyticsActivitiesReport,
} from '@juno/client-api';
import {
  GetAnalyticsActivitiesAggregateParams,
  GetAnalyticsActivitiesGraphParams,
  GetAnalyticsActivitiesReportParams,
} from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import {
  AnalyticsAggregateModel,
  AnalyticsIntervalModel,
  LineChartWidgetProps,
  lineColors,
} from './constants';
import {
  AggregateDownloadButton,
  AggregateDownloadCircularProgress,
  AggregateListItem,
  ChartBox,
  ChartCloseIcon,
  ChartZoomIcon,
  DownloadButton,
  DownloadCircularProgress,
  ErrorText,
  Percentage,
  PreviewButton,
  WidgetBox,
  WidgetSubtitle,
  WidgetTitle,
} from './styles';

export const LineChart: React.FC<LineChartWidgetProps> = ({
  widget,
  startDate,
  endDate,
  index,
  isSelected,
  setSelectedWidget,
}) => {
  const { site } = useSettings();
  const [data, setData] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(true);
  const [downloadProcessing, setDownloadProcessing] = useState(false);
  const [downloadReady, setDownloadReady] = useState<string | undefined>(undefined);
  const outsideRef = useRef(null);
  const [isErrorAggregate, setIsErrorAggregate] = useState(false);
  const [isLoadingAggregate, setIsLoadingAggregate] = useState(true);
  const [aggregateData, setAggregateData] = useState<AnalyticsAggregateModel[] | undefined>(
    undefined,
  );
  const [downloadContentId, setDownloadContentId] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    handleLoadData();
  }, [widget, startDate, endDate]);

  useEffect(() => {
    if (isSelected && widget?.showAggregate) {
      handleLoadAggregateData();
    }
  }, [widget, startDate, endDate, isSelected]);

  // User may be actively inputting data if dates are null or too early
  const shouldLoadData = () =>
    endDate && startDate && startDate.year() >= 2020 && endDate.year() >= 2020;

  const loadSharedParamData = () => {
    if (!startDate || !endDate) {
      return undefined;
    }
    const endOfDay: Dayjs = dayjs(endDate).endOf('day');
    const params:
      | GetAnalyticsActivitiesGraphParams
      | GetAnalyticsActivitiesAggregateParams
      | GetAnalyticsActivitiesReportParams = {
      start_datetime: startDate.toISOString(),
      end_datetime: endOfDay.toISOString(),
    };

    if (widget?.vars?.['category']) {
      params.category = widget?.vars?.['category'];
    }

    if (widget?.vars?.['action']) {
      params.action = widget?.vars?.['action'];
    }

    if (widget?.vars?.['platform_level']) {
      params.platform_level = true;
    }

    return params;
  };
  const handleLoadData = async () => {
    if (!shouldLoadData()) {
      return;
    }
    setIsLoading(true);

    const params: GetAnalyticsActivitiesGraphParams | undefined = loadSharedParamData();

    try {
      const result = await getAnalyticsActivitiesGraph(site?.id || '', params);
      setData(result);
      setIsError(false);
      setIsLoading(false);
    } catch {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const handleLoadAggregateData = async () => {
    if (!shouldLoadData()) {
      return;
    }
    setIsLoadingAggregate(true);
    const params: GetAnalyticsActivitiesAggregateParams | undefined = loadSharedParamData();
    try {
      const result = await getAnalyticsActivitiesAggregate(site?.id || '', params);
      setAggregateData(result as unknown as AnalyticsAggregateModel[]);
      setIsErrorAggregate(false);
      setIsLoadingAggregate(false);
    } catch {
      setIsErrorAggregate(true);
      setIsLoadingAggregate(false);
    }
    setIsLoadingAggregate(false);
  };

  const tryDownloadReport = async (contentId?: string) => {
    if (contentId !== downloadContentId || (!downloadProcessing && !downloadReady)) {
      // Different contentId specified or download hasn't been initiated
      setDownloadContentId(contentId);
      setDownloadProcessing(true);
      const sharedParams = loadSharedParamData();
      if (!sharedParams) {
        return;
      }
      const params: GetAnalyticsActivitiesReportParams = {
        ...sharedParams,
        filename_prefix: widget?.filename_prefix || '',
        report_type: widget?.report_path || '',
      };
      if (params) {
        params.filename_prefix = widget?.filename_prefix || '';
      }

      if (contentId) {
        params.content_ids = contentId;
      }

      try {
        const result = await getAnalyticsActivitiesReport(site?.id || '', params);
        const interval = setInterval(() => {
          if (result && doesFileExist(result.url)) {
            setDownloadReady(result.url);
            setDownloadProcessing(false);
            clearInterval(interval);
          }
        }, 1000);
      } catch {
        setDownloadProcessing(false);
      }
      return true;
    }
    return false;
  };

  const handleDownloadReport = async (contentId?: string) => {
    const didInitiateReport = await tryDownloadReport(contentId);
    if (didInitiateReport || downloadProcessing) {
      return;
    } else {
      window.open(downloadReady);
      setDownloadReady(undefined);
      setDownloadContentId(undefined);
    }
  };

  // visx isn't built for typescript so we need any here to render the tooltip
  const accessors = {
    xAccessor: (d: any) => d.x,
    yAccessor: (d: any) => d.y,
  };

  const chartData = data?.intervals?.map((item: AnalyticsIntervalModel) => {
    // need unique x axis values so we need to create different keys based on how long the period is
    let isYear = false;
    if (startDate && endDate) {
      const difference = endDate.valueOf() - startDate.valueOf();
      isYear = difference / 1000 / 60 / 60 / 24 > 365;
    }

    let dateString = `${item.MONTH}/${item.DAY}`;
    if (isYear) {
      dateString += `/${item.YEAR}`;
    }

    if (item.SECOND) {
      dateString += ` ${item.HOUR}:${item.MINUTE}:${item.SECOND}`;
    } else if (item.MINUTE) {
      dateString += ` ${item.HOUR}:${item.MINUTE}`;
    } else if (item.HOUR) {
      dateString += ` ${item.HOUR}:00`;
    }

    if (item.HOUR === 0 && item.HOUR !== undefined) {
      dateString += '00:00';
    }

    return {
      x: dateString,
      y: item[widget.key || 'numOccurred'],
    };
  });

  const handleSelectWidget = () => {
    setAnimationFinished(false);
    setTimeout(() => {
      setAnimationFinished(true);
    }, 150);
  };

  useEffect(() => {
    if (!animationFinished && !isSelected) {
      setSelectedWidget(widget.id);
    }
    if (!animationFinished && isSelected) {
      setSelectedWidget(null);
    }
  }, [animationFinished]);

  useOnClickOutside(outsideRef, isSelected ? handleSelectWidget : () => {});

  const handlePreviewReport = async (contentId?: string) => {
    const didInitiateReport = await tryDownloadReport(contentId);
    if (didInitiateReport || downloadProcessing) {
      return;
    } else if (downloadReady && site) {
      const encodedUrl = encodeURIComponent(downloadReady);
      const url =
        location.pathname.indexOf('/sites/') !== -1
          ? `${window.origin}/sites/${site.slug}/report_preview?reportUrl=${encodedUrl}`
          : `${window.origin}/${site.slug}/report_preview?reportUrl=${encodedUrl}`;
      window.open(url);
    }
  };

  return (
    <div
      style={
        isSelected
          ? {
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              width: 880,
              maxWidth: '100%',
              maxHeight: '60%',
            }
          : { width: '100%' }
      }
    >
      <ChartCloseIcon />
      <div
        style={{ zIndex: isSelected ? 5 : 1, background: '#fff', borderRadius: 8, height: '100%' }}
        ref={outsideRef}
      >
        <WidgetBox isSelected={isSelected}>
          <WidgetTitle isSelected={isSelected}>{widget.title}</WidgetTitle>
          <WidgetSubtitle variant='body2' isSelected={isSelected}>
            {widget.subTitle}
          </WidgetSubtitle>
          {isLoading && <Box sx={{ height: '48px' }} />}
          {!isLoading && !isError && (
            <Box sx={{ marginBottom: 2 }}>
              <Typography variant='h6' sx={{ display: 'inline', marginRight: 1 }}>
                {data[widget.key || 'numOccurred'].curr}
              </Typography>{' '}
              <Percentage
                variant='body2'
                sx={{ display: 'inline' }}
                percent={data[widget.key || 'numOccurred'].percentChange}
              >
                {data[widget.key || 'numOccurred'].prev != 0 && (
                  <>
                    {data[widget.key || 'numOccurred'].percentChange > 0 && '+'}
                    {data[widget.key || 'numOccurred'].percentChange}%
                  </>
                )}
              </Percentage>
            </Box>
          )}
          {(isLoading || !animationFinished) && (
            <Skeleton
              height={isSelected ? 440 : 100}
              variant={'rectangular'}
              sx={{ borderRadius: 2 }}
            />
          )}
          <List
            sx={{
              maxHeight: '450px',
              overflow: 'auto',
            }}
          >
            {!isLoading && !isError && animationFinished && (
              <ChartBox
                sx={{
                  height: isSelected ? 440 : 100,
                  cursor: isSelected ? 'default' : 'pointer',
                  paddingLeft: '30px',
                }}
                onClick={isSelected ? () => {} : handleSelectWidget}
              >
                {!isSelected && <ChartZoomIcon />}
                <XYChart
                  height={isSelected ? 420 : 100}
                  xScale={{ type: 'band' }}
                  yScale={
                    parseInt(data[widget.key || 'numOccurred'].curr) === 0
                      ? { type: 'linear', domain: [0, 100] }
                      : { type: 'linear' }
                  }
                  margin={{ top: 20, left: 40, right: 30, bottom: 20 }}
                >
                  <LineSeries
                    dataKey={widget.title}
                    data={chartData}
                    {...accessors}
                    strokeWidth={3}
                    stroke={lineColors[index]}
                    curve={curveMonotoneX}
                  />
                  {isSelected && animationFinished && (
                    <>
                      <Tooltip
                        snapTooltipToDatumX
                        snapTooltipToDatumY
                        showVerticalCrosshair
                        showSeriesGlyphs
                        renderTooltip={({ tooltipData, colorScale }) => (
                          <div>
                            <div
                              style={{
                                color: colorScale
                                  ? colorScale(tooltipData?.nearestDatum?.key || '')
                                  : '',
                              }}
                            >
                              {tooltipData?.nearestDatum?.key}
                            </div>
                            {accessors.xAccessor(
                              tooltipData?.nearestDatum?.datum || { x: '', y: 0 },
                            )}
                            {', '}
                            {accessors.yAccessor(
                              tooltipData?.nearestDatum?.datum || { x: '', y: 0 },
                            )}
                          </div>
                        )}
                      />
                      <AnimatedAxis orientation='left' numTicks={5} />
                      <AnimatedAxis orientation='bottom' numTicks={5} strokeWidth={0.5} />
                    </>
                  )}
                </XYChart>
              </ChartBox>
            )}

            {widget?.showAggregate && !isLoadingAggregate && isSelected && (
              <Box sx={{ height: '20px' }}></Box>
            )}
            {widget?.showAggregate &&
              !isLoadingAggregate &&
              !isErrorAggregate &&
              isSelected &&
              aggregateData?.map((aggregateElem: AnalyticsAggregateModel) => {
                return (
                  <AggregateListItem key={aggregateElem.content_id}>
                    <Typography
                      sx={{
                        marginLeft: '10px',
                      }}
                    >{`${aggregateElem.content_title}`}</Typography>
                    <Box
                      sx={{
                        flex: 1,
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        alignSelf: 'right',
                        marginRight: '10px',
                      }}
                    >{`${aggregateElem.aggregate_value}`}</Typography>
                    <MUITooltip
                      title={'Download Report'}
                      placement={'bottom'}
                      PopperProps={{
                        disablePortal: true,
                      }}
                    >
                      <AggregateDownloadButton
                        color={
                          downloadReady && downloadContentId === aggregateElem.content_id
                            ? 'primary'
                            : 'inherit'
                        }
                        onClick={() => {
                          handleDownloadReport(aggregateElem.content_id);
                        }}
                        size={'small'}
                        variant={
                          downloadReady && downloadContentId === aggregateElem.content_id
                            ? 'contained'
                            : 'text'
                        }
                        style={{ pointerEvents: downloadProcessing ? 'none' : 'auto' }}
                        aria-label={'download'}
                      >
                        {downloadProcessing && downloadContentId === aggregateElem.content_id ? (
                          <AggregateDownloadCircularProgress size={22} />
                        ) : (
                          <DownloadIcon
                            sx={{
                              opacity:
                                downloadReady && downloadContentId === aggregateElem.content_id
                                  ? 1
                                  : 0.5,
                            }}
                          />
                        )}
                      </AggregateDownloadButton>
                    </MUITooltip>
                  </AggregateListItem>
                );
              })}
          </List>

          {isError && <ErrorText>Error fetching data</ErrorText>}
          {isErrorAggregate && <ErrorText>Error fetching aggregate data</ErrorText>}
          {!isLoading && !isError && (
            <MUITooltip
              title={'Download Report'}
              placement={'top'}
              PopperProps={{
                disablePortal: true,
              }}
            >
              <DownloadButton
                color={downloadReady && !downloadContentId ? 'primary' : 'inherit'}
                onClick={() => {
                  handleDownloadReport(undefined);
                }}
                size={'small'}
                variant={downloadReady && !downloadContentId ? 'contained' : 'text'}
                style={{ pointerEvents: downloadProcessing ? 'none' : 'auto' }}
                isSelected={isSelected}
                aria-label={'download'}
              >
                {downloadProcessing && !downloadContentId ? (
                  <DownloadCircularProgress size={22} />
                ) : (
                  <DownloadIcon sx={{ opacity: downloadReady && !downloadContentId ? 1 : 0.5 }} />
                )}
              </DownloadButton>
            </MUITooltip>
          )}
          {!isLoading && !isError && (
            <MUITooltip
              title={'Preview Report'}
              placement={'top'}
              PopperProps={{
                disablePortal: true,
              }}
            >
              <PreviewButton
                color={downloadReady && !downloadContentId ? 'primary' : 'inherit'}
                onClick={() => handlePreviewReport(undefined)}
                size={'small'}
                variant={downloadReady && !downloadContentId ? 'contained' : 'text'}
                style={{ pointerEvents: downloadProcessing ? 'none' : 'auto' }}
                isSelected={isSelected}
                aria-label={'preview'}
              >
                {downloadProcessing && !downloadContentId ? (
                  <DownloadCircularProgress size={22} />
                ) : (
                  <VisibilityIcon sx={{ opacity: downloadReady && !downloadContentId ? 1 : 0.5 }} />
                )}
              </PreviewButton>
            </MUITooltip>
          )}
        </WidgetBox>
      </div>
    </div>
  );
};

function doesFileExist(urlToFile: string) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', urlToFile, false);
  xhr.send();

  return !(xhr.status === 403 || xhr.status === 404);
}

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent) => void,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown',
): void {
  useEventListener(mouseEvent, (event) => {
    const el = ref?.current;

    // Do nothing if clicking ref's element or descendent elements
    // had to use composedPath() instead of .contains because the shadow dom moves the click to the parent of the shadow dom
    if (!el || event.composedPath().includes(el)) {
      return;
    }

    handler(event);
  });
}
