interface TabProps {
  slug: string;
  key: string;
  label: string;
  color: string;
  index: number;
}

const TABS = [
  {
    slug: 'group_feed',
    key: 'group-feed-tab',
    label: 'Feed',
    color: '#C7169C', // TODO - use theme
    index: 1,
  },
  {
    slug: 'group_about',
    key: 'group-about-tab',
    label: 'About',
    color: '#C7169C', // TODO - use theme
    index: 2,
  },
  {
    slug: 'group_forum',
    key: 'group-forum-tab',
    label: 'Discussions',
    color: '#C7169C', // TODO - use theme
    index: 3,
  },
  {
    slug: 'group_members',
    key: 'group-members-tab',
    label: 'Members',
    color: '#C7169C', // TODO - use theme
    index: 4,
  },
  {
    slug: 'group_files',
    key: 'group-files-tab',
    label: 'Resources',
    color: '#C7169C', // TODO - use theme
    index: 5,
  },
  {
    slug: 'group_meetings',
    key: 'group-meetings-tab',
    label: 'Meetings',
    color: '#C7169C', // TODO - use theme
    index: 6,
  },
];

export { TABS, TabProps };
