import * as React from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useSettings } from '@juno/utils';

interface BucketSelectorProps {
  bucket: (typeof buckets)[number];
  handleChange: (event: SelectChangeEvent) => void;
}

const BucketSelector: React.FC<BucketSelectorProps> = ({ bucket, handleChange }) => {
  const { enableLearning } = useSettings();

  return (
    <Box sx={{ minWidth: 250 }}>
      <FormControl fullWidth>
        <InputLabel id='demo-simple-select-label'>{`Content Type`}</InputLabel>
        <Select value={bucket} label='Content Type' onChange={handleChange}>
          {buckets
            .filter((bucket) => {
              if (enableLearning || bucket === 'Users') return true;
              return false;
            })
            .map((bucket) => {
              return (
                <MenuItem key={bucket} value={bucket}>
                  {bucket}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default BucketSelector;

// TODO do we need to export this?
export const buckets = [
  'Courses',
  'Course Resources',
  'Lessons',
  'Lesson Parts',
  'Question Answers',
  'Users',
];
