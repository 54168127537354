import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { Alert, AlertColor, Box, Typography } from '@mui/material';
import { COURSE_STATUS, CourseStatus } from '../../constants';

interface CourseStatusMessageProps {
  status: CourseStatus;
  onClick?: () => void;
}

const CourseStatusMessage: React.FC<CourseStatusMessageProps> = ({ status, onClick }) => {
  const STATUS_MAP = {
    [COURSE_STATUS.ENABLED]: {
      severity: 'success',
      title: '',
      subtitle: "Feel free to edit anything, this course hasn't started yet!",
    },
    [COURSE_STATUS.DISABLED]: {
      severity: 'warning',
      title: '',
      subtitle:
        'This course is live but no learners have started yet. After adjusting the course dates, you may still edit all course and lesson details.',
    },
    [COURSE_STATUS.LOCKED]: {
      severity: 'error',
      title: '',
      subtitle:
        'This course has active learners in progress. Some parts of a lesson can no longer be edited.',
    },
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', userSelect: 'none', mb: 2 }}>
      <Alert severity={STATUS_MAP[status].severity as AlertColor} sx={{ width: '100%' }}>
        {STATUS_MAP[status].subtitle}
      </Alert>
    </Box>
  );
};

export default CourseStatusMessage;
