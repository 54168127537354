import React, { useEffect, useState } from 'react';
import { EditNoteOutlined } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Card, IconButton, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useGetUserSettings } from '@juno/client-api';
import { useSettings } from '@juno/utils';
import { AnimatedIconButton } from './AnimatedIconButton';
import { useUserProfile, useUserProfileUpdate } from './UserProfileContext';

const CHARACTER_LIMITS = {
  about: 1024,
};

export const About = () => {
  const userProfile = useUserProfile();
  const updateUserProfile = useUserProfileUpdate();
  const [isEditing, setIsEditing] = useState(false);
  const [editedAbout, setEditedAbout] = useState(userProfile?.about || '');
  const [initialAbout, setInitialAbout] = useState(userProfile?.about || '');
  const [isEdited, setIsEdited] = useState(false);
  const [isAboutFocused, setIsAboutFocused] = useState(false);
  const [aboutErrorMessage, setAboutErrorMessage] = useState('');
  const theme = useTheme();
  const { site } = useSettings();

  useEffect(() => {
    setInitialAbout(userProfile?.about || '');
    setIsEditing(false);
  }, [userProfile?.about]);

  const handleIconClick = () => {
    if (isEditing && userProfile) {
      updateUserProfile({ ...userProfile, about: editedAbout });
      setInitialAbout(editedAbout);
    }
    setIsEditing(!isEditing);
    if (!isEditing) {
      setIsEdited(false);
    }
  };

  const handleCancelEdit = () => {
    setEditedAbout(initialAbout);
    setIsEditing(false);
    setIsEdited(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAbout = event.target.value;
    if (newAbout.length <= CHARACTER_LIMITS.about) {
      setEditedAbout(newAbout);
      setIsEdited(newAbout !== initialAbout);
      setAboutErrorMessage('');
    } else {
      setAboutErrorMessage(`Maximum character limit of ${CHARACTER_LIMITS.about} exceeded`);
    }
  };

  const handleAboutFocus = () => {
    setIsAboutFocused(true);
  };

  const handleAboutBlur = () => {
    setIsAboutFocused(false);
  };

  const { data: userSettings, isLoading: isLoadingUserSettings } = useGetUserSettings(
    site?.id || '',
    userProfile?.id || '',
    {},
    { query: { enabled: !!site?.id && !!userProfile?.id } },
  );

  if (initialAbout.length === 0 && !userProfile.isMe) {
    return <div></div>;
  }

  return (
    <Box>
      {userProfile && (
        <Card sx={{ p: 3, mt: 2, position: 'relative', boxShadow: 1 }}>
          {userProfile.isMe && (
            <Box sx={{ position: 'absolute', top: 0, right: 0, display: 'flex' }}>
              {isEditing ? (
                <>
                  <IconButton onClick={handleCancelEdit} size='medium'>
                    <CancelIcon sx={{ color: theme.palette.error.main }} />
                  </IconButton>
                  {isEditing && userProfile.isMe && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
                      <AnimatedIconButton
                        onClick={handleIconClick}
                        size='medium'
                        animate={isEdited}
                      >
                        <SaveIcon color={isEdited ? 'primary' : 'inherit'} />
                      </AnimatedIconButton>
                    </Box>
                  )}
                </>
              ) : (
                <AnimatedIconButton onClick={handleIconClick} size='medium' animate={isEdited}>
                  <EditNoteOutlined color='disabled' />
                </AnimatedIconButton>
              )}
            </Box>
          )}

          <Box mb={2}>
            <Typography variant='h6'>About</Typography>
          </Box>

          {isEditing ? (
            <TextField
              fullWidth
              multiline
              rows={11}
              value={editedAbout}
              onChange={handleChange}
              variant='outlined'
              sx={{ my: 2 }}
              onFocus={handleAboutFocus}
              onBlur={handleAboutBlur}
              helperText={
                isAboutFocused
                  ? `${editedAbout.length}/${CHARACTER_LIMITS.about} ${aboutErrorMessage}`
                  : ' '
              }
              FormHelperTextProps={{ error: !!aboutErrorMessage }}
            />
          ) : (
            <>
              {userSettings?.private_profile && !userProfile.isMe ? (
                <Box display={'flex'} justifyContent={'center'}>
                  <Typography variant='body2' sx={{ whiteSpace: 'pre-wrap' }}>
                    This user has chosen to have a private profile.
                  </Typography>
                </Box>
              ) : (
                <Typography variant='body1' sx={{ whiteSpace: 'pre-wrap' }}>
                  {userProfile.about}
                </Typography>
              )}
            </>
          )}
        </Card>
      )}
    </Box>
  );
};
