import { Box, IconButton, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PreviewRoot = styled(Paper)(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  marginTop: theme.spacing(1),
}));

export const DropAreaBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'allowed' })<{
  allowed: boolean;
}>(({ allowed }) => ({
  position: 'relative',
  border: 'none',
  backgroundColor: 'rgb(155,155,155,0.12)',
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  userSelect: 'none',
  cursor: allowed ? 'pointer' : 'default',
  borderRadius: 12,
  '&:hover': { backgroundColor: 'rgb(155,155,155,0.20)' },
}));

export const InstructionsBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  alignSelf: 'center',
  color: 'rgb(155,155,155,0.6)',
  position: 'relative',
}));

export const ImageBoxContainer = styled(Box)(() => ({
  position: 'relative',
  height: '100%',
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
}));

export const ImageBox = styled(Box)(() => ({
  objectFit: 'cover',
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
}));

export const DeleteButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 5,
  right: 5,
  zIndex: 1,
  background: '#fff',
}));

export const EditButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 5,
  right: 50,
  zIndex: 1,
  background: '#fff',
}));
