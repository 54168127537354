import React from 'react';
import { EnrollmentModel } from './components/EnrollmentTile';
import { WaitlistTypography } from './styles';

const WaitlistedCourseTileContent = (_: EnrollmentModel) => {
  return (
    <>
      <WaitlistTypography color='primary.contrastText'>Waitlist</WaitlistTypography>
    </>
  );
};

export default WaitlistedCourseTileContent;
