import { Dispatch, SetStateAction } from 'react';
import { RotatorSettings } from '@juno/ui';
import { DateFilter } from '@juno/ui';
import {
  RelativeTimeFrameOptions,
  compareDateStrings,
  generateRandomString,
  interpolatePreviousUpcomingTimeFrame,
  interpolateRelativeTimeFrame,
} from '@juno/utils';
import { TabItemObject } from '../index';
import {
  BeforeAfterBetweenOptions,
  DateTypeToFilterString,
  TimeRange,
  checkJsonWorthiness,
} from './utils';

/**
 *  UI Logic
 */

// Add a new Date Filter Component
export const handleAddDateFilter = (
  filterCount: number,
  setDateFilters: Dispatch<SetStateAction<DateFilter[]>>,
) => {
  const today = new Date();
  const fromDate = today;
  const toDate = today;
  fromDate.setHours(0, 1, 0, 0);
  toDate.setHours(23, 59, 0, 0);
  const newFilter = {
    idx: filterCount,
    dateType: '',
    timeRange: '',
    previousUpcomingDateRange: 0,
    relativeTimeFrame: RelativeTimeFrameOptions.Today,
    beforeAfterBetween: BeforeAfterBetweenOptions.Before,
    beforeAfterDate: '',
    fromDate: fromDate.toISOString(),
    toDate: toDate.toISOString(),
    uniqueKey: generateRandomString(10),
  };
  setDateFilters((prevFilters) => [...prevFilters, newFilter]);
};

// Remove a specific DatePreFilterComponent from the list
export const handleRemoveDateFilter = (
  idx: number,
  dateFilters: DateFilter[],
  dateFiltersRemoved: DateFilter[],
  setDateFiltersRemoved: Dispatch<SetStateAction<DateFilter[]>>,
  setDateFilterStringIsChanged: Dispatch<SetStateAction<boolean>>,
  setDateFilters: Dispatch<SetStateAction<DateFilter[]>>,
) => {
  if (
    !(dateFilters[idx]['dateType'] in dateFiltersRemoved) &&
    isDateFilterComplete(dateFilters[idx])
  ) {
    setDateFiltersRemoved((prevValue: DateFilter[]) => [...prevValue, dateFilters[idx]]);
  }
  setDateFilterStringIsChanged(true);
  if (dateFilters.length === 1) {
    setDateFilters([]);
  } else {
    // Shed the unwanted Date Filter
    const newDateFilterArray = dateFilters.filter((_, index) => index !== idx);
    // Update the idx property for the remaining Date Filter objects
    const updatedDateFilterArray = newDateFilterArray.map((dateFilter, index) => ({
      ...dateFilter,
      idx: index,
    }));
    setDateFilters(updatedDateFilterArray);
  }
};

/**
 *  Filtering Logic
 */

export const isDateFilterComplete = (dateFilter: DateFilter) => {
  // If the full DateFilter component isn't complete, we return false
  if (!dateFilter['dateType'] || !dateFilter['timeRange']) return false;
  if (!dateFilter['beforeAfterBetween']) return false;
  // If a Relative Filter has relativeTimeFrame, toDate, and fromDate set, it is good to go
  if (
    dateFilter['timeRange'] === TimeRange.Relative &&
    dateFilter['relativeTimeFrame'] &&
    dateFilter['fromDate'] &&
    dateFilter['toDate']
  )
    return true;
  // If a Previous/Upcoming Filter has a fromDate and toDate set, it is good to go
  if (
    dateFilter['timeRange'] === TimeRange.Previous ||
    dateFilter['timeRange'] === TimeRange.Upcoming
  ) {
    if (dateFilter['previousUpcomingDateRange'] > 0) return true;
  }
  // If the Specific Filter is an After or a Before, the beforeAfterDate field is required
  if (
    dateFilter['beforeAfterBetween'] !== BeforeAfterBetweenOptions.Between &&
    !dateFilter['beforeAfterDate']
  )
    return false;
  // If the Specific Filter is Between, the toDate and fromDate are required
  if (
    dateFilter['beforeAfterBetween'] === BeforeAfterBetweenOptions.Between &&
    (!dateFilter['toDate'] || !dateFilter['fromDate'])
  )
    return false;
  // We have a valid Date Filter
  return true;
};

export const createDateTypeKeyObject = (dateTypeFilters: DateFilter[]) => {
  return dateTypeFilters.reduce(
    (accumulator: { [key: string]: DateFilter[] }, dateFilter: DateFilter) => {
      if (!accumulator[dateFilter.dateType]) {
        accumulator[dateFilter.dateType] = [dateFilter];
      } else {
        accumulator[dateFilter.dateType].push(dateFilter);
      }
      return accumulator;
    },
    {},
  );
};

export const handleRemoveLastFilterType = (
  remainingDateFilters: { [key: string]: DateFilter[] },
  removedDateFilters: { [key: string]: DateFilter[] },
  jsonifiedFilterString: { [key: string]: string },
): { [key: string]: string } => {
  const updatedJsonifiedFilterString = jsonifiedFilterString;
  for (const dateType of Object.keys(removedDateFilters)) {
    if (!(dateType in remainingDateFilters)) {
      delete updatedJsonifiedFilterString[DateTypeToFilterString.get(dateType) + '__gte'];
      delete updatedJsonifiedFilterString[DateTypeToFilterString.get(dateType) + '__lte'];
    }
  }
  return updatedJsonifiedFilterString;
};

// The easy case is when there's only 1 dateFilter of this dateType
export const handleEasyCase = (
  jsonifiedFilterString: { [key: string]: string },
  dateFilter: DateFilter,
): { [key: string]: string } => {
  const updatedJsonifiedFilterString = jsonifiedFilterString;
  const { dateType, beforeAfterBetween, beforeAfterDate, toDate, fromDate, timeRange } = dateFilter;
  if (
    timeRange === TimeRange.Relative ||
    timeRange === TimeRange.Previous ||
    timeRange === TimeRange.Upcoming ||
    beforeAfterBetween === BeforeAfterBetweenOptions.Between
  ) {
    updatedJsonifiedFilterString[DateTypeToFilterString.get(dateType) + '__gte'] = fromDate;
    updatedJsonifiedFilterString[DateTypeToFilterString.get(dateType) + '__lte'] = toDate;
  } else {
    const filterParam =
      beforeAfterBetween === BeforeAfterBetweenOptions.After
        ? DateTypeToFilterString.get(dateType) + '__gte'
        : DateTypeToFilterString.get(dateType) + '__lte';
    const oppositeFilterParam =
      beforeAfterBetween === BeforeAfterBetweenOptions.After
        ? DateTypeToFilterString.get(dateType) + '__lte'
        : DateTypeToFilterString.get(dateType) + '__gte';
    updatedJsonifiedFilterString[filterParam] = beforeAfterDate;
    // If a dateFilter with both lte and gte params was just removed, we need to remove the outlier
    delete updatedJsonifiedFilterString[oppositeFilterParam];
  }
  return updatedJsonifiedFilterString;
};

export const handleCheckFilterStringForRemoval = (
  jsonifiedFilterString: { [key: string]: string },
  dateFilter: DateFilter,
  removedFilters: DateFilter[],
): { [key: string]: string } => {
  const { dateType } = dateFilter;
  const afterFilterParam = DateTypeToFilterString.get(dateType) + '__gte';
  const beforeFilterParam = DateTypeToFilterString.get(dateType) + '__lte';

  const isSingleCheck = (filter: DateFilter) =>
    filter['timeRange'] === TimeRange.Specific &&
    filter['beforeAfterBetween'] !== BeforeAfterBetweenOptions.Between;

  const removeFilter = (
    filter: DateFilter,
    jsonifiedFilterString: { [key: string]: string },
  ): { [key: string]: string } => {
    const updatedJsonifiedFilterString = jsonifiedFilterString;
    const filterParam =
      filter['beforeAfterBetween'] === BeforeAfterBetweenOptions.After
        ? afterFilterParam
        : beforeFilterParam;

    if (updatedJsonifiedFilterString[filterParam] === filter['beforeAfterDate']) {
      delete updatedJsonifiedFilterString[filterParam];
    }
    return updatedJsonifiedFilterString;
  };

  const removeBetweenFilters = (
    filter: DateFilter,
    jsonifiedFilterString: { [key: string]: string },
  ) => {
    const updatedJsonifiedFilterString = jsonifiedFilterString;
    if (updatedJsonifiedFilterString[afterFilterParam] === filter['fromDate']) {
      delete updatedJsonifiedFilterString[afterFilterParam];
    }
    if (updatedJsonifiedFilterString[beforeFilterParam] === filter['toDate']) {
      delete updatedJsonifiedFilterString[beforeFilterParam];
    }
    return updatedJsonifiedFilterString;
  };

  let updatedJsonifiedFilterString = jsonifiedFilterString;

  for (const filter of removedFilters) {
    if (isSingleCheck(filter)) {
      updatedJsonifiedFilterString = removeFilter(filter, updatedJsonifiedFilterString);
    } else {
      updatedJsonifiedFilterString = removeBetweenFilters(filter, updatedJsonifiedFilterString);
    }
  }
  return updatedJsonifiedFilterString;
};

// We have an array of dateFilters of the same type - so calculate the greatest/least for the filter param
export const handleMultipleFiltersCase = (
  jsonifiedFilterString: { [key: string]: string },
  dateFilter: DateFilter,
): { [key: string]: string } => {
  const updatedJsonifiedFilterString = jsonifiedFilterString;
  const { dateType, beforeAfterBetween, beforeAfterDate, toDate, fromDate, timeRange } = dateFilter;
  const firstPartFilterParam = DateTypeToFilterString.get(dateType) + '__';
  const singleCheck =
    timeRange === TimeRange.Specific && !(beforeAfterBetween === BeforeAfterBetweenOptions.Between);
  if (singleCheck) {
    // Compare either After or Before param
    const filterParam =
      beforeAfterBetween === BeforeAfterBetweenOptions.After
        ? firstPartFilterParam + 'gte'
        : firstPartFilterParam + 'lte';
    if (
      !(filterParam in updatedJsonifiedFilterString) ||
      compareDateStrings(
        beforeAfterDate,
        updatedJsonifiedFilterString[filterParam],
        beforeAfterBetween === BeforeAfterBetweenOptions.After,
      )
    ) {
      updatedJsonifiedFilterString[filterParam] = beforeAfterDate;
    }
  } else {
    // Compare fromDate
    if (
      !(firstPartFilterParam + 'gte' in updatedJsonifiedFilterString) ||
      compareDateStrings(fromDate, updatedJsonifiedFilterString[firstPartFilterParam + 'gte'], true)
    ) {
      updatedJsonifiedFilterString[firstPartFilterParam + 'gte'] = fromDate;
    }
    // Compare toDate
    if (
      !(firstPartFilterParam + 'lte' in updatedJsonifiedFilterString) ||
      compareDateStrings(toDate, updatedJsonifiedFilterString[firstPartFilterParam + 'lte'], false)
    ) {
      updatedJsonifiedFilterString[firstPartFilterParam + 'lte'] = toDate;
    }
  }
  return updatedJsonifiedFilterString;
};

const removeDynamicDateFilters = (dateFilters: DateFilter[]) => {
  return dateFilters.filter((filter) => filter.timeRange === TimeRange.Specific);
};

const calculateDynamicDateFilters = (dateFilters: DateFilter[]): DateFilter[] => {
  const updatedDateFilters = dateFilters;
  for (const dateFilter of updatedDateFilters) {
    if (dateFilter.timeRange !== TimeRange.Specific) {
      switch (dateFilter.timeRange) {
        case TimeRange.Previous:
          {
            const [fromDate, toDate] = interpolatePreviousUpcomingTimeFrame(
              dateFilter.previousUpcomingDateRange,
              TimeRange.Previous,
            );
            dateFilter.fromDate = fromDate;
            dateFilter.toDate = toDate;
          }
          break;
        case TimeRange.Upcoming:
          {
            const [fromDate, toDate] = interpolatePreviousUpcomingTimeFrame(
              dateFilter.previousUpcomingDateRange,
              TimeRange.Upcoming,
            );
            dateFilter.fromDate = fromDate;
            dateFilter.toDate = toDate;
          }
          break;
        case TimeRange.Relative: {
          const [fromDate, toDate] = interpolateRelativeTimeFrame(dateFilter.relativeTimeFrame);
          dateFilter.fromDate = fromDate;
          dateFilter.toDate = toDate;
          break;
        }
        default:
          break;
      }
    }
  }
  return updatedDateFilters;
};

export const calculateDateFilterString = (
  payload: TabItemObject | RotatorSettings | undefined,
  dateFilters: DateFilter[],
  dateFiltersRemoved: DateFilter[],
  isPageLoad: boolean,
): [string, DateFilter[]] => {
  let focusedDateFilters;
  // Ignore dynamically-dated filters unless this is a JunoGrid page load
  if (!isPageLoad) {
    focusedDateFilters = removeDynamicDateFilters(dateFilters);
    // For dynamically-dated filters, we need to set the toDate and fromDate fields
  } else {
    focusedDateFilters = calculateDynamicDateFilters(dateFilters);
  }
  // Capture the existing filterString
  const jsonifiedFilterString = checkJsonWorthiness(payload?.filter);
  // Ignore incomplete Date Filters
  const completeDateFilters = focusedDateFilters.filter(isDateFilterComplete);
  // Reorganize the completeDateFilters array into an object where each key is a dateType holding a corresponding array of Date filters
  const organizedDateFilters = createDateTypeKeyObject(completeDateFilters);
  // Capture the deleted dateFilters too to make sure we point params away from values that have been removed
  const removedDateFilters = createDateTypeKeyObject(dateFiltersRemoved);

  let updatedFilterString = jsonifiedFilterString;

  // Handle the case where we're removing the last filter of a particular dateType
  if (dateFiltersRemoved.length > 0) {
    updatedFilterString = handleRemoveLastFilterType(
      organizedDateFilters,
      removedDateFilters,
      jsonifiedFilterString,
    );
  }

  // Go through each dateType and calculate its filterString params
  for (const [dateType, dateTypeFilters] of Object.entries(organizedDateFilters)) {
    // Handle the case where we're adding the first filter of this dateType OR we're removing the 2nd to last filter of this dateType
    if (dateTypeFilters.length === 1) {
      updatedFilterString = handleEasyCase(updatedFilterString, dateTypeFilters[0]);
      // Calculate greatest/least date values among the remaining dateFilters
    } else {
      for (const dateTypeItem of dateTypeFilters) {
        // If a filter of this dateType has not just been removed
        if (!(dateType in removedDateFilters)) {
          updatedFilterString = handleMultipleFiltersCase(updatedFilterString, dateTypeItem);
          // A filter/s of this dateType was just removed, so we better make sure we don't hold onto deleted date values
        } else {
          // Clean the filterString from having params that correspond with removed dateFilters
          updatedFilterString = handleCheckFilterStringForRemoval(
            updatedFilterString,
            dateTypeItem,
            removedDateFilters[dateType],
          );
          // Calculate an updated filterString
          updatedFilterString = handleMultipleFiltersCase(updatedFilterString, dateTypeItem);
        }
      }
    }
  }

  // Send back the updates
  const returnFilters = isPageLoad ? focusedDateFilters : dateFilters;
  return [JSON.stringify(updatedFilterString), returnFilters];
};
