import React, { useMemo, useState } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Box,
  Card,
  Divider,
  IconButton,
  Tooltip as MUITooltip,
  Tab,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import { useGetFeatureConfigs } from '@juno/client-api';
import { FeatureConfig, FeatureConfigConfig, FeatureConfigTypeEnum } from '@juno/client-api/model';
import { Container } from '@juno/ui';
import { useSettings } from '@juno/utils';
import { ExamineYourData } from './ExamineYourData';
import { HappeningNow } from './HappeningNow';
import { UserActivityExporter } from './UserActivityExporter';

dayjs.extend(advancedFormat);
dayjs.extend(timezone);

export interface ReportableTagsModel {
  title: string;
  value: number;
}

interface JUNOAnalyticsProps {
  isSuperAdmin: boolean;
}

interface AnalyticsDashboardExtendedConfig extends FeatureConfig {
  config: AnalyticsDashboardConfig;
}
export interface AnalyticsDashboardConfig extends FeatureConfigConfig {
  excludedCategories: string[];
}

export const JunoAnalytics: React.FC<JUNOAnalyticsProps> = ({ isSuperAdmin }) => {
  const { site } = useSettings();
  const [selectedTab, setSelectedTab] = useState('Happening Now');
  const [currentDate, setCurrentDate] = useState(dayjs());
  const { data: configsData, isLoading: isConfigLoading } = useGetFeatureConfigs(
    site?.id || '',
    {
      filter: {
        type: FeatureConfigTypeEnum.analytics_dashboard,
      },
    },
    { query: { enabled: !!site?.id } },
  );
  const excludedCategories = useMemo(() => {
    return (configsData?.[0] as AnalyticsDashboardExtendedConfig)?.config?.excludedCategories || [];
  }, [configsData]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };
  const tabDivider = (
    <Divider
      orientation='vertical'
      style={{
        height: 14,
        position: 'absolute',
        right: 0,
        backgroundColor: 'black',
        width: 1.5,
        marginTop: 2,
      }}
    ></Divider>
  );

  return (
    <Container>
      <Card sx={{ p: 2 }}>
        <TabContext value={selectedTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <TabList onChange={handleChange} aria-label='analytics dashboard tabs'>
              <Tab label='Happening Now' value='Happening Now' icon={tabDivider} />
              <Tab label='Examine Your Data' value='Examine Your Data' icon={tabDivider} />
              <Tab label='User Activity Exporter' value='User Actvity Exporter' />
            </TabList>
            {selectedTab === 'Happening Now' && (
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography>{currentDate.format('hh:mm A z')}</Typography>
                <MUITooltip
                  title={'Refresh'}
                  placement={'bottom'}
                  PopperProps={{
                    disablePortal: true,
                  }}
                >
                  <IconButton
                    color={'inherit'}
                    onClick={() => {
                      setCurrentDate(dayjs());
                    }}
                    size={'small'}
                    aria-label={'refresh'}
                  >
                    <RefreshIcon />
                  </IconButton>
                </MUITooltip>
              </Box>
            )}
          </Box>
          <TabPanel value='Happening Now'>
            <HappeningNow excludedCategories={excludedCategories} currentDate={currentDate} />
          </TabPanel>
          <TabPanel value='Examine Your Data'>
            <ExamineYourData excludedCategories={excludedCategories || []} />
          </TabPanel>
          <TabPanel value='User Actvity Exporter'>
            <UserActivityExporter />
          </TabPanel>
        </TabContext>
      </Card>
    </Container>
  );
};
