import React, { useEffect, useRef } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Typography,
} from '@mui/material';
import { FormikProps } from 'formik';
import { Schedule } from '@juno/client-api/model';
import { DialogAriaWrapper } from '@juno/ui';
import { getScheduleString } from '@juno/utils';
import { SidebarSectionFormikValues } from '../utils/validationSchema';

interface ActivateCampaignDialogProps {
  open: boolean;
  handleClose: () => void;
  handleActivateCampaign: () => Promise<void>;
  formik: FormikProps<SidebarSectionFormikValues>;
  setSidebarSectionFormikValues: React.Dispatch<React.SetStateAction<SidebarSectionFormikValues>>;
  unsavedSchedule: Schedule;
}

const ActivateCampaignDialog: React.FC<ActivateCampaignDialogProps> = ({
  open,
  handleClose,
  formik,
  handleActivateCampaign,
  setSidebarSectionFormikValues,
  unsavedSchedule,
}) => {
  // Variables
  const { values, setFieldValue } = formik;
  const isActive = useRef(values.is_active);

  // Functions
  useEffect(() => {
    if (isActive.current !== values.is_active) {
      handleActivateCampaign()
        .then(() => {
          handleClose();
        })
        .catch((err) => {
          // Reset isActive on failure
          setFieldValue('is_active', isActive.current);
        });
    }
  }, [values.is_active]);

  return (
    <DialogAriaWrapper
      id={'activateEmailCampaignDialog'}
      open={open}
      onClose={handleClose}
      maxWidth='xs'
    >
      <DialogTitle
        id={'activateEmailCampaignDialog-dialog-title'}
        sx={{ pt: 4, alignSelf: 'center' }}
      >
        Activate Campaign
      </DialogTitle>
      <DialogContent id={'activateEmailCampaignDialog-dialog-description'}>
        <DialogContentText align='center'>
          <Typography sx={{ mb: 2 }}>
            Please ensure the following campaign details are accurate and confirm activation below.
          </Typography>
          <Paper
            sx={{
              bgcolor: (theme) => {
                return theme.palette.mode === 'dark' ? '#1e1e1e' : '#E4E6E5';
              },
              pt: 2,
              pb: 2,
            }}
            elevation={0}
          >
            <Typography>{values.name}</Typography>
            {getScheduleString(unsavedSchedule)}
          </Paper>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 4 }}>
        <Button
          aria-label='cancel-campaign-activation-button'
          onClick={handleClose}
          color='primary'
          variant='outlined'
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            setFieldValue('is_active', true);
            setSidebarSectionFormikValues({ ...values, is_active: true });
          }}
          color='primary'
          variant='contained'
        >
          Activate Now
        </Button>
      </DialogActions>
    </DialogAriaWrapper>
  );
};

export default ActivateCampaignDialog;
