import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetAllSessions, useGetMe, useGetPages, useGetSite } from '@juno/client-api';
import { JunoRotator, PreviewItemProps } from '@juno/ui';
import { useSettings } from '@juno/utils';
import { handleGroupFilter, useGroupsHook } from '../CommunityGroup/hooks';
import { handleContentPreFilter } from '../Content/utils';
import { MODEL_MAP, setDefaultFilters } from '../ContentGrid';
import { useCoursesHook } from '../Course/hooks';
import { useSpeakersHook } from '../Speaker/hooks';

export interface ContentRotatorProps {
  siteId: string;
  settings: any;
}

const ContentRotator: React.FC<ContentRotatorProps> = ({ siteId, settings }) => {
  const dataModel = settings?.dataModel || 'Group';
  const navigate = useNavigate();
  const { isClient } = useSettings();
  // Needed for search/replace for handlebar'ish syntax
  const { data: siteData, isLoading: siteLoading } = useGetSite(siteId);
  const { data: userData, isLoading: userLoading } = useGetMe(siteData?.platform_id || '');

  const [filter, filterStr] = useMemo(() => {
    try {
      const genericPreFilter = handleContentPreFilter(settings, userData);
      let filter = genericPreFilter.filter;
      const filterStr = genericPreFilter.filterStr;

      if (dataModel === 'Group') {
        filter = handleGroupFilter(settings, filter, userData);
      }
      if (dataModel === 'Course') {
        // If the filter_and_or param is set, we need to replace the USER_ID's
        if (settings?.filter_and_or && userData?.id) {
          const serializedFilterAndOr = JSON.stringify(settings.filter_and_or);
          const replacedUserIds = serializedFilterAndOr.replace(/{{USER_ID}}/g, userData.id);
          settings.filter_and_or = JSON.parse(replacedUserIds);
        }
      }
      setDefaultFilters(dataModel, filter);

      return [filter, filterStr];
    } catch (e) {
      console.debug(e);
      return [{}, settings?.filter || '{}'];
    }
  }, [settings, userData]);

  const { speakers, speakersLoading } = useSpeakersHook(
    siteId,
    settings,
    dataModel,
    filter,
    filterStr,
  );

  const { courses, coursesLoading } = useCoursesHook(
    siteId,
    settings,
    dataModel,
    filter,
    filterStr,
  );

  const { groupsLoading, communityGroups } = useGroupsHook(
    siteId,
    settings,
    dataModel,
    filter,
    filterStr,
  );

  // SESSION QUERIES
  const { data: sessions, isLoading: sessionsLoading } = useGetAllSessions(
    siteId,
    {
      order: settings?.order || '-date_created',
      filter: {
        ...filter,
        subbreakout: false,
        active: true,
      },
    },
    { query: { enabled: dataModel === 'Session' && !filterStr.includes('{{USER_ID}}') } },
  );

  const { data: library, isLoading: libraryLoading } = useGetPages(
    siteId,
    {
      order: settings?.order,
      filter: {
        type: 'library',
        ...filter,
      },
    },
    { query: { enabled: dataModel === 'Library' } },
  );

  const isLoading =
    speakersLoading ||
    coursesLoading ||
    siteLoading ||
    userLoading ||
    groupsLoading ||
    libraryLoading ||
    sessionsLoading;
  const items =
    MODEL_MAP[dataModel]?.mapItems({ speakers, courses, communityGroups, sessions, library }) || [];
  const basePath = MODEL_MAP[dataModel]?.basePath || '';

  const handleSelect = (item: PreviewItemProps) => {
    if (isClient) {
      navigate(`/${siteData?.slug}${basePath}/${item.slug}`);
    } else if (settings?.onSelect) {
      settings.onSelect(item);
    } else {
      navigate(`${basePath}/${item.slug}`);
    }
  };

  return (
    <Box pt={3} pb={3}>
      <JunoRotator
        settings={settings}
        slides={items}
        isLoading={isLoading}
        onSelect={handleSelect}
      />
    </Box>
  );
};

export default ContentRotator;
