import React, { useContext, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Chip, Grid, IconButton, InputBase, Paper, Typography } from '@mui/material';
import { useGetAllUsers } from '@juno/client-api';
import { JunoUser } from '@juno/client-api/model';
import { useMessengerContext, useSettings } from '@juno/utils';
import { PlatformContext } from '../../Contexts/PlatformContext';
import MessageInput from '../Conversation/MessageInput';
import { CreateNewConversationRow } from './CreateNewConversationRow';
import CreateNewConversationSkeleton from './CreateNewConversationSkeleton';

export const CreateNewConversation: React.FC = () => {
  const platformContext = useContext(PlatformContext);
  const { setIsCreateNewConversation } = useMessengerContext();
  const { user } = useSettings();
  const [inputValue, setInputValue] = useState('');
  const [selectedUsers, setSelectedUsers] = useState<JunoUser[]>([]);

  const searchParams = inputValue
    ? {
        first_name__icontains: inputValue,
        last_name__icontains: inputValue,
        email__icontains: inputValue,
      }
    : {};

  const { data: usersData, isLoading } = useGetAllUsers(
    platformContext?.platform?.id || '',
    {
      filter_or: searchParams,
      exclude_or: {
        settings__private_messaging: true,
      },
      limit: 10,
      offset: 0,
    },
    {
      query: {
        enabled: !!platformContext?.platform?.id,
      },
    },
  );

  // Function to handle input value change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  // Function to remove a user from the selected users
  const handleDeleteUser = (userId: string) => {
    setSelectedUsers(selectedUsers.filter((user) => user.id !== userId));
  };

  const handleSelectUser = () => {
    setInputValue('');
  };

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Box sx={{ marginBottom: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <IconButton
              aria-label='back'
              onClick={() => setIsCreateNewConversation(false)}
              sx={{ mt: 1 }}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
          </Grid>

          <Grid item xs={10}>
            <Typography
              variant='subtitle2'
              component='h5'
              sx={{
                padding: 2,
                textAlign: 'center',
                fontWeight: 'bold',
                fontSize: '.95rem',
              }}
            >
              Create New Conversation
            </Typography>
          </Grid>

          <Grid item xs={1}></Grid>
        </Grid>

        <Paper
          component='div'
          variant='outlined'
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '10px',
            padding: '5px',
            marginRight: '10px',
            marginLeft: '10px',
            paddingTop: '12px',
            border: '1px solid rgba(0, 0, 0, 0.23)', // Match OutlinedInput border
            borderRadius: '4px', // Match OutlinedInput border radius
            '&:focus-within': {
              borderColor: 'primary.main',
              borderWidth: '2px', // Highlight border on focus
            },
          }}
        >
          {selectedUsers.map((user) => (
            <Chip
              key={user.id}
              label={`${user.first_name} ${user.last_name.slice(0, 1).toUpperCase()}`}
              onDelete={() => handleDeleteUser(user.id)}
              size='medium'
            />
          ))}
          <InputBase
            sx={{ ml: 1, flex: 1, height: '25px' }}
            placeholder={selectedUsers.length === 0 ? 'Type to search people...' : ''}
            inputProps={{ 'aria-label': 'type to search people' }}
            value={inputValue}
            onChange={handleInputChange}
            multiline
            fullWidth
          />
        </Paper>
      </Box>
      <Box sx={{ maxHeight: 360, overflow: 'auto' }}>
        {isLoading ? (
          <CreateNewConversationSkeleton />
        ) : usersData && usersData.length > 0 ? (
          usersData
            .filter((junoUser) => junoUser.id !== user?.id)
            .map((junoUser) => (
              <CreateNewConversationRow
                key={junoUser.id}
                junoUser={junoUser}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                handleSelectUser={handleSelectUser}
              />
            ))
        ) : (
          <Typography variant='body2' sx={{ textAlign: 'center', mt: 2 }}>
            No users found
          </Typography>
        )}
      </Box>

      <Box sx={{ maxHeight: 360 }}>
        <MessageInput newUsers={selectedUsers} />
      </Box>
    </Box>
  );
};
