import { Tab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const CourseTab = styled(Tab)(({ theme }) => ({
  backgroundColor: 'transparent',
  '&.MuiButtonBase-root': {
    backgroundColor: 'transparent',
    alignItems: 'center',
    padding: '10px 20px',
    flexDirection: 'row',
  },
  '&.Mui-selected': { color: theme.palette.text.primary },
}));

export const EditingLabel = styled(Typography)(({ theme }) => ({
  color: 'text.secondary',
  marginBottom: theme.spacing(1),
  opacity: 0.5,
  fontStyle: 'italic',
}));

export const CourseTitle = styled(Typography)(({ theme }) => ({
  color: 'text.secondary',
  // marginTop: theme.spacing(1),
  letterSpacing: '1px',
}));
