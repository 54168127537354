import React, { useEffect, useMemo, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { getSessionArchives, useDeleteSession, useGetSessionSubBreakouts } from '@juno/client-api';
import { JunoUser, Session } from '@juno/client-api/model';
import { DefaultSearchSortFilter, DialogAriaWrapper, UserTile } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import { ArchiveSkeleton, convertDurationToTime } from './helpers';

interface BreakoutRoomsTabProps {
  sessionData: Session | undefined;
}
const BreakoutRoomsTab: React.FC<BreakoutRoomsTabProps> = ({ sessionData }) => {
  const { site } = useSettings();
  const [participantSearch, setParticipantSearch] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const {
    data: breakoutRooms,
    isLoading,
    refetch,
  } = useGetSessionSubBreakouts(
    site?.id || '',
    sessionData?.id || '',
    {
      order: '-date_created',
    },
    {
      query: {
        enabled: !!sessionData?.id && !!site?.id,
      },
    },
  );
  const [archiveUrl, setArchiveUrl] = useState<string | undefined>(undefined);
  const [thisRoomId, setThisRoomId] = useState(breakoutRooms?.[0]?.id || '');
  const [participantsShown, setParticipantsShown] = useState<JunoUser[] | undefined>(
    breakoutRooms?.[0]?.assigned_users || [],
  );
  useEffect(() => {
    if (breakoutRooms) {
      setThisRoomId(breakoutRooms[0]?.id);
      setParticipantsShown(breakoutRooms[0]?.assigned_users);
    }
  }, [breakoutRooms]);

  useEffect(() => {
    if (breakoutRooms) {
      const thisRoom = breakoutRooms.find((room) => room.id === thisRoomId);
      setParticipantsShown(
        thisRoom?.assigned_users.filter(
          (user) =>
            `${user.first_name} ${user.last_name}`
              .toLowerCase()
              .includes(participantSearch.toLowerCase()) ||
            user.email.toLowerCase().includes(participantSearch.toLowerCase()),
        ),
      );
      const getArchive = async () => {
        const archiveResp = (await getSessionArchives(
          site?.id || '',
          thisRoom?.session || '',
        )) as any;
        const archives = archiveResp?.archives?.[0].recordings?.data;
        setArchiveUrl(archives?.[0]?.signed_url);
      };
      if (thisRoom) {
        getArchive();
      }
    }
  }, [participantSearch, thisRoomId, breakoutRooms]);
  const sessionDelete = useDeleteSession(onMutation(MutationAction.DELETE, 'Breakout Room'));
  const deleteBreakout = async (sessionId: string) => {
    setDeleteLoading(true);
    await sessionDelete.mutateAsync({ siteId: site?.id || '', sessionId });
    refetch();
    setDeleteLoading(false);
    setDeleteDialogOpen(false);
  };

  const thisRoom = useMemo(() => {
    return breakoutRooms?.find((room) => room.id === thisRoomId);
  }, [thisRoomId, breakoutRooms]);
  if (isLoading) {
    return <ArchiveSkeleton />;
  }

  return (
    <Box>
      {breakoutRooms && breakoutRooms.length === 0 && (
        <Typography variant={'body2'} pl={3}>
          No breakout rooms created yet
        </Typography>
      )}
      {breakoutRooms && breakoutRooms.length > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card sx={{ overflow: 'visible', p: 2, boxShadow: 1 }}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant={'subtitle1'} mb={1}>
                  Participants
                </Typography>
              </Box>
              <Box>
                <Box mb={1}>
                  <DefaultSearchSortFilter
                    setSearch={setParticipantSearch}
                    buttonDisabled={true}
                    buttonText={''}
                    onClickButton={() => {}}
                    showButton={false}
                    showFilter={false}
                    showSort={false}
                  />
                </Box>
                {participantsShown?.map((user) => (
                  <Card sx={{ p: 1, boxShadow: 2, mb: 1, overflow: 'visible' }}>
                    <UserTile user={user} />
                  </Card>
                ))}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6} p={2} position={'relative'}>
            <Card sx={{ boxShadow: 1, p: 2, display: 'flex', flexDirection: 'column' }}>
              <Typography variant={'subtitle1'} mb={2} sx={{ width: 'fit-content' }}>
                Preview
              </Typography>
              <Select
                value={thisRoomId}
                size='small'
                sx={{ mb: 1 }}
                onChange={(e) => {
                  setThisRoomId(e.target.value as string);
                }}
              >
                {breakoutRooms?.map((breakoutRoom, index) => (
                  <MenuItem key={breakoutRoom.id} value={breakoutRoom.id}>
                    <Typography>{`Breakout Room ${index + 1}`}</Typography>
                  </MenuItem>
                ))}
              </Select>
              {archiveUrl ? (
                <Box
                  sx={{
                    minHeight: 200,
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <video
                    preload='metadata'
                    controls
                    src={archiveUrl}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: 'auto',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    borderRadius: 2,
                    backgroundColor: 'rgb(155,155,155,0.12)',
                    height: 200,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <Typography variant={'h6'}>No video available</Typography>
                </Box>
              )}
              <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant='caption'>{`Started: ${new Date(
                  thisRoom?.date_start || '',
                ).toLocaleString()}`}</Typography>
                <Typography variant='caption'>{`Duration: ${convertDurationToTime(
                  thisRoom?.actual_length_in_ms ? thisRoom?.actual_length_in_ms / 1000 : 0,
                )}`}</Typography>
              </Box>
            </Card>
            <Card sx={{ mt: 2, p: 2, boxShadow: 1 }}>
              <Typography variant={'subtitle1'} mb={1}>
                Actions
              </Typography>
              <Stack
                spacing={1}
                mt={1}
                display={'flex'}
                justifyContent={'flex-start'}
                sx={{ width: 'fit-content' }}
              >
                <Button
                  size='small'
                  variant='contained'
                  sx={{ p: '8px 30px' }}
                  onClick={() => {
                    window.open(archiveUrl, '_blank');
                  }}
                >
                  Preview and Download Archive
                </Button>
                <Button
                  size='small'
                  variant='contained'
                  sx={{ color: 'white', bgcolor: 'red', p: '8px 30px' }}
                  onClick={() => setDeleteDialogOpen(true)}
                >
                  Delete Breakout Room
                </Button>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      )}
      <DialogAriaWrapper
        id={'delete_session_confirm'}
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle id='delete_session_confirm-dialog-title'>Delete Session</DialogTitle>
        <DialogContent id='delete_session_confirm-dialog-description'>
          Are you sure you want to delete this Breakout Room? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button
            aria-label='Cancel delete session'
            sx={{ textTransform: 'none' }}
            onClick={() => setDeleteDialogOpen(false)}
          >
            Cancel
          </Button>
          <LoadingButton
            aria-label='Confirm delete session'
            sx={{ textTransform: 'none' }}
            variant={'contained'}
            loading={deleteLoading}
            onClick={() => deleteBreakout(thisRoom?.session || '')}
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </DialogAriaWrapper>
    </Box>
  );
};
export default BreakoutRoomsTab;
