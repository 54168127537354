import AddIcon from '@mui/icons-material/Add';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, FormControl, Grid, IconButton, MenuItem, Select } from '@mui/material';
import { DefaultSearchBox } from '@juno/ui';

interface CommunityActionBarProps {
  showCreateNew?: boolean;
  newBtnText?: string;
  searchPlacholder?: string;
  clickNew?: () => void;
}
const CommunityActionBar = ({
  showCreateNew,
  newBtnText,
  searchPlacholder,
  clickNew,
}: CommunityActionBarProps) => {
  return (
    <Grid container spacing={1} alignItems='center'>
      {showCreateNew && (
        <Grid item xs={12} sm={3}>
          <Button
            startIcon={<AddIcon />}
            variant='contained'
            color='primary'
            fullWidth
            onClick={() => {
              clickNew && clickNew();
            }}
          >
            {newBtnText ?? 'New Post'}
          </Button>
        </Grid>
      )}
      <Grid item xs={12} sm={!showCreateNew ? 8 : 5}>
        <DefaultSearchBox
          placeholder={searchPlacholder ?? 'Search...'}
          searchTerm={''}
          onChange={() => {
            console.log('TODO: implement search on feed');
          }}
          fieldProps={{ size: 'small' }}
        />
      </Grid>
      <Grid item xs={10} sm={3}>
        <FormControl sx={{ m: 0, minWidth: 150 }} size='small'>
          <Select label='' value='1'>
            <MenuItem value='1'>Most Recent</MenuItem>
            <MenuItem value='2'>Other Filter 1</MenuItem>
            <MenuItem value='3'>Other Filter 2</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2} sm={1}>
        {/* // TODO change color to theme */}
        <IconButton aria-label='filter' sx={{ borderRadius: '4px', border: '1px solid #C7169C' }}>
          <FilterAltIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export { CommunityActionBar };
