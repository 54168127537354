import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormControlRow = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  gap: theme.spacing(1),
  flexDirection: 'row',
  justifyContent: 'space-evenly',
}));
