import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useBreakpoint, useSettings } from '@juno/utils';
import { useCallState } from '../../contexts/CallProvider';
import { useCallUI } from '../../hooks/useCallUI';
import Room from '../Room';
import { Asides } from './Asides';
import { Modals } from './Modals';

export const App = ({ customComponentForState, leaveCall, sessionData }) => {
  const { roomExp, state, roomInfo, setRoomInfo } = useCallState();
  const { showSideMenu, showMessenger, isSmallScreen } = useSettings();
  const { xs } = useBreakpoint();
  const componentForState = useCallUI({
    state,
    room: <Room />,
    callEnded: leaveCall,
    ...customComponentForState,
  });

  useEffect(() => {
    setRoomInfo && setRoomInfo({ ...roomInfo, session: sessionData });
  }, [sessionData]);

  const isContained = showSideMenu || showMessenger;

  // Memoize children to avoid unnecessary renders from HOC
  return useMemo(
    () => (
      <>
        <Box
          className='app'
          p={'5px'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          position={'relative'}
          sx={{
            bgcolor: '#262626',
            height: (theme) =>
              isContained
                ? isSmallScreen
                  ? 'calc(100vh - 120px)'
                  : `calc(100vh - 60px - ${theme.spacing(4)})`
                : isSmallScreen
                ? 'calc(100vh - 120px)'
                : 'calc(100vh - 60px)',
          }}
        >
          {componentForState()}
          <Modals />
          <Asides />
        </Box>
      </>
    ),
    [componentForState, roomExp, isContained],
  );
};

App.propTypes = {
  customComponentForState: PropTypes.any,
  leaveCall: PropTypes.func,
  sessionData: PropTypes.object,
};

export default App;
