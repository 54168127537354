import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import BurnEffect from './Effects/BurnEffect';

const Emoji = styled(motion.span)({
  fontSize: '2rem',
  position: 'absolute',
  zIndex: 9999,
  opacity: 1,
});

interface FlyingEmojiProps {
  emoji: string;
  onExited: () => void;
}
const FlyingEmoji: React.FC<FlyingEmojiProps> = ({ emoji, onExited }) => {
  const [position, setPosition] = useState({ x: Math.random() * 200, y: window.innerHeight - 250 });
  const [gifToShow, setGifToShow] = useState('');
  const [burning, setBurning] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setGifToShow('');
      onExited(); // Call the onExited callback after 5 seconds to clean up the emoji
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [onExited]);

  useEffect(() => {
    switch (emoji) {
      case FlyEmojis.Happy:
        setGifToShow('https://giphy.com/embed/R0BQ4q2xHKAdlBaVa2');
        break;
      case FlyEmojis.Clap:
        setGifToShow('https://giphy.com/embed/5w39AhTMInyt06d4Fu');
        break;
      case FlyEmojis.Shocked:
        setGifToShow('https://giphy.com/embed/hqssoHVMhlOpIWeS3d');
        break;
      case FlyEmojis.Sad:
        setGifToShow('https://giphy.com/embed/YhMV2cdUWTrPVpZOFN');
        break;
      case FlyEmojis.Party:
        setGifToShow('https://giphy.com/embed/cCbvlU3pS5jdRIqAQn');
        break;
      case FlyEmojis.Heart:
        setGifToShow('https://giphy.com/embed/Fy0HBOMVnF065EUed8');
        break;
      case FlyEmojis.ThumbsDown:
      case FlyEmojis.ThumbsUp:
        setGifToShow('https://giphy.com/embed/zUMb2gnh5pvaanPOT0');
        break;
      case FlyEmojis.Thinking:
        setGifToShow('https://giphy.com/embed/UPw6Bla17cDo0YvX9S');
        break;
      case EggEmojis.Fireworks:
        setBurning(true);
        setGifToShow('https://i.gifer.com/2r67.gif');
        break;
      case EggEmojis.Parrot:
        setBurning(true);
        setGifToShow('https://i.gifer.com/6oa.gif');
        break;
      case '🔥':
      case EggEmojis.MushroomCloud:
        setBurning(true);
        setGifToShow('https://i.gifer.com/YQDj.gif');
        break;
      default:
        setGifToShow('');
    }
  }, [emoji]);

  if (gifToShow === '' && !burning) {
    return null;
  }

  return (
    <>
      {burning && <BurnEffect onEnd={() => setBurning(false)} gifToShow={gifToShow} />}
      {!burning && (
        <motion.div
          style={{
            position: 'fixed',
            top: position.y,
            left: position.x,
            opacity: 1,
          }}
          animate={{ y: -position.y, x: position.x, opacity: 0.1 }} // Animate the emoji up
          transition={{
            duration: 8,
            repeat: 0,
            ease: [0.15, 0.5 * Math.random(), 0.5 * Math.random(), 0.67],
          }}
        >
          <iframe
            src={gifToShow}
            width='70px'
            height='70px'
            style={{
              border: 'none',
              transform: emoji === FlyEmojis.ThumbsDown ? 'scaleY(-1)' : 'none',
            }}
          ></iframe>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 9999,
              opacity: 0,
            }}
          />
        </motion.div>
      )}
    </>
  );
};

export default FlyingEmoji;

export enum FlyEmojis {
  Heart = '❤️',
  ThumbsUp = '👍',
  Clap = '👏',
  Party = '🎉',
  Happy = '😄',
  Shocked = '😲',
  Thinking = '🤔',
  Sad = '😭',
  ThumbsDown = '👎',
}
export enum EggEmojis {
  MushroomCloud = '🔥',
  Fireworks = '🎆',
  Parrot = '🦜',
}

export const emojiSequences = {
  [EggEmojis.Fireworks]: [
    FlyEmojis.Party,
    FlyEmojis.Thinking,
    FlyEmojis.Heart,
    FlyEmojis.ThumbsUp,
    FlyEmojis.Happy,
    FlyEmojis.Clap,
  ],
  [EggEmojis.MushroomCloud]: [
    FlyEmojis.Happy,
    FlyEmojis.Shocked,
    FlyEmojis.Thinking,
    FlyEmojis.Sad,
    FlyEmojis.Clap,
    FlyEmojis.Party,
  ],
  // can only get the parrot if you get the mushroom cloud first
  [EggEmojis.Parrot]: [
    EggEmojis.MushroomCloud,
    FlyEmojis.Happy,
    FlyEmojis.Heart,
    FlyEmojis.ThumbsUp,
    FlyEmojis.Party,
    FlyEmojis.Thinking,
  ],
};

export const checkSequence = (sequence: any, targetSequence: any) => {
  if (sequence.length !== targetSequence.length) return false;
  for (let i = 0; i < sequence.length; i++) {
    if (sequence[i] !== targetSequence[i]) return false;
  }
  return true;
};
