/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, CircularProgress, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormikProps } from 'formik';
import { useGetAllTags } from '@juno/client-api';
import { Site as SiteModel } from '@juno/client-api/model';
import { AutocompleteTags } from '@juno/ui';
import { useSettings } from '@juno/utils';
import { LittleSwitch } from '../../utils/styles';
import { StatusProps } from '../../utils/utils';
import { CampaignRecipientSectionFormikValues } from '../../utils/validationSchema';
import MailingList from '../helpers/MailingList';

interface CampaignRecipientsSectionProps {
  formik: FormikProps<CampaignRecipientSectionFormikValues>;
  setRecipientSectionValues: React.Dispatch<
    React.SetStateAction<CampaignRecipientSectionFormikValues>
  >;
  isActive: boolean;
  emailCampaignName: string;
  headCount: number;
  setHeadCount: React.Dispatch<React.SetStateAction<number>>;
}

const CampaignRecipientsSection: React.FC<CampaignRecipientsSectionProps> = ({
  formik,
  setRecipientSectionValues,
  isActive,
  emailCampaignName,
  headCount,
  setHeadCount,
}) => {
  // Variables
  const { site } = useSettings();
  const [open, setOpen] = useState<boolean>(false);
  const [hasAll, setHasAll] = useState<boolean>(true);
  const [recipientTagList, setRecipientTagList] = useState<string[]>(
    formik.values.metadata.tags || [],
  );
  const [recipientsAreLoading, setRecipientsAreLoading] = useState<boolean>(true);
  const [status, setStatus] = useState<StatusProps>('loading');

  // Queries and mutations
  const { data: siteTags, isLoading: tagsLoading } = useGetAllTags(
    site?.id || '',
    {},
    { query: { enabled: !!site?.id } },
  );

  // Functions
  useEffect(() => {
    if (!tagsLoading) {
      setStatus('loaded');
    }
  }, [tagsLoading]);

  // Default has_all to the UI toggle value
  useEffect(() => {
    // When CAMPAIGN RECIPIENTS section renders for the first time, has_all will be undefined
    if (formik.values.metadata.has_all === undefined) {
      formik.setFieldValue('metadata.has_all', true);
      setRecipientSectionValues({
        metadata: {
          ...formik.values.metadata,
          has_all: true,
        },
      });
      setHasAll(true);
      // Propogate previously saved has_all value
    } else {
      setHasAll(formik.values.metadata.has_all);
    }
  }, [formik.values.metadata.has_all]);

  useEffect(() => {
    // When AutocompleteTags updates our tag set, we need to propagate the update
    if (formik.values.tags) {
      setRecipientTagList(formik.values.tags.map((tag) => tag.id));
      formik.setFieldValue(
        'metadata.tags',
        formik.values.tags.map((tag) => tag.id),
      );
      setRecipientSectionValues({
        metadata: {
          ...formik.values.metadata,
          tags: formik.values.tags.map((tag) => tag.id),
        },
      });
      // formik.values.tags will be undefined when the page is reloaded, or the user enters the campaign builder from the campaign list view
      // 2 cases to handle when the formik.values.tags array is undefined
    } else {
      // CASE # 1: the campaign has a previously saved tag set
      if (formik.values.metadata.tags && siteTags) {
        formik.setFieldValue(
          'tags',
          siteTags.filter(
            (tag) => formik.values.metadata.tags && formik.values.metadata.tags.includes(tag.id),
          ),
        );
        // CASE # 2: the campaign doesn't yet have a tag set
      } else if (!formik.values.metadata.tags) {
        formik.setFieldValue('tags', []);
      }
    }
  }, [formik.values.tags, siteTags]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ border: '1px dashed', borderRadius: '10px', p: 3, mt: 3 }}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h6' maxWidth={'50%'}>
          CAMPAIGN RECIPIENTS
        </Typography>
        <Typography variant='body1' maxWidth={'50%'}>
          {recipientTagList.length > 0 ? (
            <>
              {recipientsAreLoading ? (
                <CircularProgress size={'1em'} sx={{ marginRight: '5px' }} />
              ) : (
                <>{headCount}</>
              )}
              {`${headCount === 1 ? ' person qualifies ' : ' people qualify '} for this email`}
            </>
          ) : (
            <>{headCount} people will receive this email</>
          )}
        </Typography>
      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <>&nbsp;</>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography variant='body1'>ANY</Typography>
          <LittleSwitch
            id='has_all'
            checked={hasAll}
            disabled={isActive}
            inputProps={{ 'aria-label': 'ant design' }}
            onChange={(event) => {
              setHasAll((value) => !value);
              formik.setFieldValue('has_all', hasAll);
              setRecipientSectionValues({
                metadata: {
                  ...formik.values.metadata,
                  has_all: !hasAll,
                },
              });
            }}
          />
          <Typography variant='body1'>ALL</Typography>
        </Stack>
      </Stack>
      {(tagsLoading || !formik.values.tags) && (
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <Skeleton
              variant='rectangular'
              height={50}
              width={'100%'}
              sx={{ borderRadius: '5px' }}
            ></Skeleton>
          </Grid>
        </Grid>
      )}
      {!tagsLoading && formik.values.tags && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AutocompleteTags label='Tags' disabled={isActive} formik={formik} />
          </Grid>
        </Grid>
      )}
      {status === 'loading' && (
        <Grid container spacing={3} sx={{ mt: 1 }}>
          <Grid item xs={12}>
            <Skeleton
              variant='rectangular'
              height={50}
              width={'100%'}
              sx={{ borderRadius: '5px' }}
            ></Skeleton>
          </Grid>
        </Grid>
      )}
      {(status === 'default' || status === 'loaded') && (
        <Button
          aria-label='View-mailing-list'
          variant='outlined'
          startIcon={<VisibilityIcon />}
          fullWidth
          sx={{ mt: 3 }}
          onClick={handleClickOpen}
        >
          VIEW MAILING LIST
        </Button>
      )}
      {formik.values.metadata.tags && (
        <MailingList
          open={open}
          handleClose={handleClose}
          hasAll={hasAll}
          tags={recipientTagList}
          fileName={emailCampaignName + '_MailingList'}
          setRecipientsLoading={setRecipientsAreLoading}
          setHeadCount={setHeadCount}
        />
      )}
    </Box>
  );
};

export default CampaignRecipientsSection;
