import { ElementType, useEffect, useMemo, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Accept, useDropzone } from 'react-dropzone';
import { useS3Upload } from '@juno/modules';
import { formatBytes } from '@juno/utils';
import {
  FileUploadWrapper,
  UploadStatusWrapper,
  acceptStyle,
  baseStyle,
  focusedStyle,
  rejectStyle,
} from './styles';

type FileUploadButtonProps = {
  onFileUploadStarted?: () => void;
  onFileUploaded?: (fileUrl: string) => void;
  acceptFiles: Accept;
  maxSizeBytes: number;
  icon: ElementType;
  title: string;
  body: string;
};

const FileUploadButton = ({
  onFileUploadStarted,
  onFileUploaded,
  acceptFiles,
  maxSizeBytes,
  icon,
  title,
  body,
}: FileUploadButtonProps) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [isUploadErrorDialogOpen, setIsUploadErrorDialogOpen] = useState(false);
  const [fileUploadError, setFileUploadError] = useState<string | null>(null);
  const { uploadFileToS3 } = useS3Upload();
  const FileIcon = icon;
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: acceptFiles,
    maxFiles: 1,
    maxSize: maxSizeBytes,
    multiple: false,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setIsConfirmationDialogOpen(true);
    }
  }, [acceptedFiles]);

  const onCancelUpload = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onUpload = async () => {
    setIsUploading(true);
    setIsConfirmationDialogOpen(false);
    if (onFileUploadStarted) {
      onFileUploadStarted();
    }

    try {
      const url = await uploadFileToS3(acceptedFiles[0], (percentage) => {
        setUploadProgress(Math.floor(percentage * 100));
      });
      setIsUploading(false);

      if (onFileUploaded) {
        onFileUploaded(url);
      }
    } catch (err) {
      setFileUploadError(err as string);
    }
  };

  useEffect(() => {
    if (fileRejections.length > 0) {
      setIsUploadErrorDialogOpen(true);
    }
  }, [fileRejections]);

  const onCloseUploadErrorDialog = () => {
    setFileUploadError(null);
    setIsUploadErrorDialogOpen(false);
  };

  return (
    <>
      <FileUploadWrapper>
        <fieldset>
          <legend>
            <Typography className='legend-label' variant='caption'>
              {title}
            </Typography>
          </legend>
        </fieldset>

        <Typography className='label' variant='caption'>
          {title}
        </Typography>

        {isUploading ? (
          <UploadStatusWrapper>
            <Box sx={{ overflow: 'hidden' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <FileIcon sx={{ fontSize: 12 }} />
                <Typography variant='caption' sx={{ lineHeight: 1 }}>
                  {uploadProgress === 100 ? 'Complete!' : 'In Progress'}
                </Typography>
              </Box>

              <Typography
                sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {acceptedFiles[0]?.name}
              </Typography>
            </Box>
            <Box sx={{ flexShrink: 0 }}>
              {uploadProgress === 100 ? (
                <CheckCircleOutlineIcon color='primary' sx={{ fontSize: 20 }} />
              ) : (
                <>
                  <CircularProgress
                    variant='determinate'
                    value={100}
                    className={'background'}
                    size={20}
                    thickness={4}
                  />
                  <CircularProgress
                    variant='determinate'
                    value={uploadProgress}
                    size={20}
                    thickness={4}
                  />
                </>
              )}
            </Box>
          </UploadStatusWrapper>
        ) : (
          <Button variant='text' className='uploadBtn' {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <AddCircleOutlineIcon fontSize='small' />
            <span>{body}</span>
          </Button>
        )}
      </FileUploadWrapper>
      <Dialog onClose={onCancelUpload} open={isConfirmationDialogOpen} fullWidth maxWidth='xs'>
        <DialogTitle sx={{ fontWeight: 'bold' }}>File Confirmation</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography sx={{ fontWeight: 'bold' }}>File Name</Typography>
              <Typography>{acceptedFiles[0]?.name}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: 'bold' }}>File Size</Typography>
              <Typography>{formatBytes(acceptedFiles[0]?.size)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: 'bold' }}>File Type</Typography>
              <Typography>{acceptedFiles[0]?.type}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', padding: '8px 16px' }}>
          <Button sx={{ color: '#555' }} onClick={onCancelUpload}>
            Cancel
          </Button>
          <Button onClick={onUpload} autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={onCloseUploadErrorDialog}
        open={isUploadErrorDialogOpen}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle>
          {fileRejections[0]?.errors[0]?.code === 'file-too-small'
            ? 'File Not Found'
            : 'File Upload Error'}
          {fileUploadError && 'File Upload Failed'}
        </DialogTitle>
        <DialogContent>
          {fileRejections[0]?.errors[0]?.code === 'file-too-small' && (
            <Typography>
              Something went wrong while attempting to load this file. <br />
              <br />
              Please try again with a different file.
            </Typography>
          )}
          {fileRejections[0]?.errors[0]?.code === 'file-invalid-type' && (
            <Typography>
              File type not supported. Please try again with a different file.
            </Typography>
          )}
          {fileRejections[0]?.errors[0]?.code === 'file-too-large' && (
            <Typography>File too large. Please try again a different file.</Typography>
          )}
          {fileRejections[0]?.errors[0]?.code === 'too-many-files' && (
            <Typography>
              We're sorry, we currently only support one file upload at a time.
            </Typography>
          )}
          {fileUploadError && (
            <Typography>
              We're sorry, it seems your upload failed due to a server error. <br />
              <br />
              Error Message: {fileUploadError}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseUploadErrorDialog} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileUploadButton;
