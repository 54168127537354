import React, { useEffect, useState } from 'react';
import { ExpandCircleDownOutlined } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, Grid, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Draggable from 'react-draggable';
import { DefaultCircularProgress } from '../../index';

const MAX_UNTRUNCATED_FILENAME_LENGTH = 26;
const truncatedFileName = (fileName: string, maxLength: number) => {
  if (fileName.length < maxLength) {
    return fileName;
  }
  const truncatedName = fileName.slice(0, maxLength - 3);
  return `${truncatedName}...mp4`;
};

function VideoUploadPopup(
  props: LinearProgressProps & {
    value: number;
    filename?: string;
    launchcount: number;
    setVideoFileIsCanceled: React.Dispatch<React.SetStateAction<boolean>>;
  },
) {
  const [drawerOpen, setDrawerOpen] = useState(props.launchcount === 1);
  const [hoveredOn, setHoveredOn] = useState(false);
  const popupRef = React.useRef<HTMLDivElement>(null);
  const displayFileName = truncatedFileName(props.filename || '', MAX_UNTRUNCATED_FILENAME_LENGTH);
  const [box2Top, setBox2Top] = useState('-30px');
  const [drawerTextIsVisible, setDrawerTextVisible] = useState(false);
  const theme = useTheme();

  const hiddenTextStyle = {
    opacity: 0,
    transition: 'opacity .5s ease-in-out',
  };

  const visibleTextStyle = {
    opacity: 1,
    transition: 'opacity 1s ease-in-out',
  };

  const handleDrawerToggle = () => {
    if (drawerOpen) {
      setBox2Top('-30px');
    } else {
      setBox2Top('-180px');
      setDrawerTextVisible(false);
    }
    setDrawerOpen((old) => !old);
    showDrawerText();
  };

  const handleHover = () => {
    setHoveredOn((hoveredOn) => !hoveredOn);
  };

  const handleCancel = () => {
    props.setVideoFileIsCanceled(true);
  };

  const showDrawerText = () => {
    setDrawerTextVisible((drawerTextIsVisible) => !drawerTextIsVisible);
  };

  // Define timer for the 20-second drawer display on the first upload
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (props.launchcount === 1) {
      setDrawerTextVisible(true);
      setBox2Top('-180px');
      timer = setTimeout(() => {
        setBox2Top('-30px');
        setDrawerOpen(false);
        setDrawerTextVisible(false);
      }, 20000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [props.launchcount, setDrawerOpen]);

  const renderIcon = () => {
    if (props.value === 100) {
      return <CheckCircleOutlineIcon sx={{ fontSize: '25px' }} color='primary' />;
    } else if (hoveredOn && props.value < 99) {
      return <CancelIcon color='primary' onClick={handleCancel} />;
    } else {
      return (
        <>
          {props.value < 100 && (
            <DefaultCircularProgress
              props={{ size: 20, thickness: 5 }}
              primaryCircleColor={`${theme.palette.primary}`}
              value={props.value}
            />
          )}
        </>
      );
    }
  };

  return (
    <Box
      id='parent-container'
      ref={popupRef}
      sx={{
        position: 'fixed',
        bottom: '100px',
        right: '20px',
        width: '320px',
        height: '104px',
        zIndex: 3,
      }}
    >
      <Draggable defaultPosition={{ x: 0, y: 0 }} offsetParent={document.body}>
        <Box
          id='parent-box'
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'white',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <Box
            id='primary-popup'
            sx={{
              position: 'relative',
              zIndex: 3,
              border: '1px solid #cecece',
              borderRadius: '0 0 10px 10px',
              width: '100%',
              height: '100%',
              backgroundColor: 'white',
              padding: '10px',
              boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.25)',
            }}
          >
            <Grid
              container
              rowSpacing={0}
              sx={{
                width: '100%',
                '&:hover': { backgroundColor: '#F0F0F0', borderRadius: '10px' },
              }}
              onMouseOver={handleHover}
              onMouseOut={handleHover}
            >
              <Grid container item mt='10px'>
                <Grid item xs={'auto'} ml={1.5} mr={0} mb={-0.35} mt={0.25}>
                  <UploadFileIcon sx={{ fontSize: '20px' }}></UploadFileIcon>
                </Grid>
                <Grid item xs={10} mb={0} sx={{ justifyContent: 'center' }}>
                  {props.value > 99 ? (
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        p: '3px 0 0 0',
                        fontSize: 13,
                        fontFamily: 'Prompt, sans-serif',
                      }}
                    >
                      Upload Complete
                    </Typography>
                  ) : (
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        p: '3px 0 0 0',
                        fontSize: 13,
                        fontFamily: 'Prompt, sans-serif',
                      }}
                    >
                      Loading...
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container item>
                <Grid item xs='auto' ml={2}>
                  <Typography>{displayFileName}</Typography>
                </Grid>
                <Grid
                  item
                  xs={'auto'}
                  mt={-1}
                  ml={1}
                  sx={{
                    height: '25px',
                    position: 'fixed',
                    right: 23,
                    '& .MuiCircularProgress-root.background': {
                      position: 'fixed',
                      '& .MuiCircularProgress-circle': {
                        stroke: '#D9D9D9',
                      },
                    },
                  }}
                >
                  {renderIcon()}
                </Grid>
              </Grid>
              <Grid item xs={12} fontSize={9} p='0 10px 0 10px' mb='10px' mt='10px'>
                <LinearProgress
                  variant='determinate'
                  value={props.value}
                  sx={{ backgroundColor: '#D9D9D9' }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            id='drawer-box'
            sx={{
              zIndex: 0,
              position: 'absolute',
              width: '100%',
              height: drawerOpen ? '190px' : '127px',
              top: box2Top,
              transition: 'all .75s ease-in-out',
              border: '1px 1px 0 1px solid #cecece',
              borderRadius: drawerOpen ? '10px 10px 0 0' : '10px',
              boxShadow: '0 -4px 8px rgba(0, 0, 0, 0.25)',
              backgroundColor: 'white',
            }}
          >
            <Box
              id='drawer-header'
              sx={{
                height: '30px',
                backgroundColor: '#DADADA',
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '10px 10px 0 0',
              }}
            >
              <DragIndicatorIcon
                fontSize={'small'}
                sx={{
                  transform: 'rotate(90deg)',
                  position: 'absolute',
                  top: '5px',
                  cursor: 'move',
                  color: '#595959',
                }}
              >
                <MenuIcon />
              </DragIndicatorIcon>
            </Box>
            <Box
              id='drawer-content'
              sx={{
                position: 'absolute',
                top: '-16px',
                right: '16px !important',
              }}
            >
              <Box
                id='drawer-chip'
                sx={{
                  width: '40px',
                  height: drawerOpen ? '205px' : '107px',
                  flexDirection: 'column',
                  backgroundColor: drawerOpen ? '#c7169c' : '#EEEEEE',
                  // backgroundColor: drawerOpen ? "primary" : "#EEEEEE",
                  display: 'flex',
                  justifyContent: drawerOpen ? 'center' : 'flex-start',
                  alignItems: 'center',
                  borderRadius: '20px 20px 0 0',
                  pt: '2px',
                  pb: '2px',
                  transition: 'height .75s ease-in-out',
                }}
              >
                {drawerOpen ? (
                  <ExpandCircleDownOutlined
                    onClick={handleDrawerToggle}
                    sx={{ mb: '6px', fontSize: '20px', color: 'white', cursor: 'pointer' }}
                  />
                ) : (
                  <InfoIcon
                    onClick={handleDrawerToggle}
                    sx={{
                      mb: '10px',
                      cursor: 'pointer',
                      mt: '15px',
                      fontSize: 20,
                      fontWeight: 'bold',
                    }}
                  />
                )}
                <Typography
                  sx={{
                    writingMode: 'vertical-rl',
                    textOrientation: 'upright',
                    fontSize: '11px',
                    color: 'white',
                  }}
                >
                  INFORMATION
                </Typography>
              </Box>
            </Box>
            <Box
              id='drawer-text'
              sx={{
                mr: '80px',
                ml: '24px',
                mt: '20px',
                mb: '20px',
              }}
            >
              <Typography
                sx={{ fontWeight: 'bold', fontFamily: 'Prompt, sans-serif', mb: 1 }}
                fontSize={'small'}
              >
                Feel free to continue editing the course overview or lesson.
              </Typography>
              <Typography
                fontSize={'x-small'}
                sx={{ fontFamily: 'Prompt, sans-serif' }}
                style={drawerTextIsVisible ? visibleTextStyle : hiddenTextStyle}
              >
                *Please stay on the current section until your upload is complete. If you close or
                navigate away, your upload will be canceled.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Draggable>
    </Box>
  );
}

export default VideoUploadPopup;
