import React, { useState } from 'react';

type CoursePanelContextProps = {
  isVideoUploading: boolean;
  setIsVideoUploading: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultCoursePanelContext: CoursePanelContextProps = {
  isVideoUploading: false,
  setIsVideoUploading: () => {},
};

export const CoursePanelContext =
  React.createContext<CoursePanelContextProps>(defaultCoursePanelContext);
