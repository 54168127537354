import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Site as SiteModel } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import { useBreakpoint } from '@juno/utils/hooks';
import CreateEditSubscriptionGroupDialog from '../Dialogs/CreateEditSubscriptionGroupDialog';
import SubscriptionGroupList from './SubscriptionGroupList';

const SubscriptionSettings: React.FC = () => {
  // Variables
  const { site } = useSettings();
  const { md } = useBreakpoint();
  const [createDialogOpen, setCreateDialogOpen] = useState<boolean>(false);
  const [groupCreated, setGroupCreated] = useState<boolean>(false);

  return (
    <Box
      sx={{
        p: md ? '44px 10px' : '44px 32px 32px',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: '12px',
        position: 'relative',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, mr: 2 }}>
        <Box sx={{ display: 'flex', flex: '1', justifyContent: 'flex-start', mb: 3 }}>
          <Typography color='primary.main' variant='h5'>
            {site?.slug || ''} subscription groups
          </Typography>
        </Box>
        {/* We don't currently support custom groups so no need to create */}
        {/*<Box sx={{ display: 'flex', flex: '1', justifyContent: 'flex-end', mb: 3 }}>*/}
        {/*  <Button variant='contained' onClick={() => setCreateDialogOpen(true)}>*/}
        {/*    + Create Subscription Group*/}
        {/*  </Button>*/}
        {/*</Box>*/}
      </Box>
      <SubscriptionGroupList idx={0} groupCreated={groupCreated} />
      {createDialogOpen && (
        <CreateEditSubscriptionGroupDialog
          handleClose={() => {
            setCreateDialogOpen(false);
          }}
          handleGroupCreated={setGroupCreated}
        />
      )}
    </Box>
  );
};

export default SubscriptionSettings;
