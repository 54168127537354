import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions } from '@mui/material';
import { Course, Lesson, Question, Tag } from '@juno/client-api/model';
import ImportDialogContent from './wizard-parts/DialogContent/index';
import DialogFooter from './wizard-parts/DialogFooter';
import DialogHeader from './wizard-parts/DialogHeader';

interface ImportValidationDialogProps {
  bucket: string;
  open: boolean;
  totalErrors: number;
  handleDelete: (row: number) => void;
  handleDeleteAll: (remainingValues: any) => void;
  handleClose: (value: React.SetStateAction<boolean>) => void;
  originalErrorRowRef: React.MutableRefObject<never[]>;
  values: any;
  errors: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  courseList: Course[];
  siteTags: Tag[];
  lessonList: Lesson[];
  questionList: Question[];
}
const ImportValidationDialog: React.FC<ImportValidationDialogProps> = ({ ...props }) => {
  const {
    bucket,
    totalErrors,
    originalErrorRowRef,
    handleDelete,
    handleDeleteAll,
    open,
    values,
    errors,
    handleClose,
    courseList: _courseList, // these are passed in ...props
    siteTags: _siteTags, // these are passed in ...props
    lessonList: _lessonList, // these are passed in ...props
    questionList: _questionList, // these are passed in ...props
  } = props;
  const [currentRow, setCurrentRow] = useState<number>(0);
  const [currentErrorRow, setCurrentErrorRow] = useState<number>(0);
  const [numErrorRows, setNumErrorRows] = useState<number>(0);
  const [errorsOnThisRow, setErrorsOnThisRow] = useState<number>(
    (errors.rows && errors.rows[currentRow]?.length) ?? 0,
  );

  let rowTitlePrefix = `${String(bucket).slice(0, bucket.length - 1)}`;
  let rowTitle = '';

  const rows = values.rows || [];
  if (currentRow < rows.length) {
    const title = rows[currentRow].title?.dom_value;
    const slug = rows[currentRow].slug?.dom_value;

    if (title) {
      rowTitlePrefix += ' Title:';
      rowTitle = title;
    } else if (slug) {
      rowTitlePrefix += ' Slug:';
      rowTitle = slug;
    }
  }

  if (totalErrors == 0 && currentRow == values.rows.length) {
    rowTitlePrefix = 'Validation Complete';
    rowTitle = '';
  }

  useEffect(() => {
    if (!originalErrorRowRef.current || originalErrorRowRef.current?.length == 0) {
      originalErrorRowRef.current = errors.rows;
    }
  }, [errors.rows, values.rows]);

  useEffect(() => {
    setErrorsOnThisRow((errors.rows && errors.rows[currentRow]?.length) ?? 0);
    const size = (errors.rows && Object.keys(errors.rows).length) ?? 0;
    setNumErrorRows(size);
  }, [errors, currentRow]);

  const handleSelectRowChosen = (row: number) => {
    setCurrentRow(row);
    if (row < currentErrorRow) {
      if (originalErrorRowRef.current[row]) {
        setCurrentErrorRow(row);
      }
    }
  };

  const handleStepForwardError = () => {
    for (let i = currentRow + 1; i < values.rows?.length; i++) {
      if (originalErrorRowRef.current[i]) {
        setCurrentErrorRow((old) => old + 1);
        setCurrentRow(i);
        break;
      }
    }
  };

  const handleStepBackwardError = () => {
    for (let i = currentRow - 1; i >= 0; i--) {
      if (originalErrorRowRef.current[i]) {
        setCurrentErrorRow((old) => old - 1);
        setCurrentRow(i);
        break;
      }
    }
  };

  const handleDeleteItem = () => {
    handleDelete(currentRow);
    if (currentRow == 0) {
      handleStepForwardError();
    } else {
      handleStepBackwardError();
    }
    originalErrorRowRef.current = [];
  };

  const deleteAll = () => {
    const errorRows: number[] = [];
    for (let i = 0; i < values.rows?.length; i++) {
      if (errors.rows[i]) {
        errorRows.push(i);
      }
    }

    const newValues = values.rows.filter((value: any, index: number) => {
      return errorRows.filter((id) => id === index).length === 0;
    });

    originalErrorRowRef.current = [];
    setCurrentRow(0);
    setCurrentErrorRow(0);
    handleDeleteAll(newValues);
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogHeader
        title={rowTitle}
        titlePrefix={rowTitlePrefix}
        rowCount={currentRow}
        totalRows={values.rows?.length}
        currentErrorRow={currentErrorRow}
        totalErrorRows={numErrorRows}
        errorsOnThisRow={errorsOnThisRow}
        totalErrors={totalErrors}
        handleStepForwardError={handleStepForwardError}
        handleStepBackwardError={handleStepBackwardError}
        originalErrorRowRef={originalErrorRowRef}
        handleClose={() => {
          handleClose(false);
          setCurrentRow(0);
          setCurrentErrorRow(0);
        }}
      />
      <ImportDialogContent rowCount={currentRow} {...props} />
      <DialogFooter
        allRows={values.rows}
        rowCount={currentRow}
        hasErrors={totalErrors > 0}
        currentErrorRow={currentErrorRow}
        totalErrorRows={numErrorRows}
        handleSelectRowChange={handleSelectRowChosen}
        handleStepForward={() => setCurrentRow((old) => old + 1)}
        handleStepForwardError={handleStepForwardError}
        handleStepBackwardError={handleStepBackwardError}
        handleDeleteItem={handleDeleteItem}
        handleDeleteAll={deleteAll}
        handleClose={() => {
          handleClose(false);
          setCurrentRow(0);
          setCurrentErrorRow(0);
        }}
      />
      <DialogActions sx={{ justifyContent: 'space-between' }}></DialogActions>
    </Dialog>
  );
};
export default ImportValidationDialog;
