import React, { ReactNode, createContext, useContext, useState } from 'react';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { AXIOS_INSTANCE } from '@juno/client-api';
import { useSettings } from '@juno/utils';

// Define the shape of the context's value
interface S3UploadContextValue {
  /**
   * Upload a file to S3
   * @param file The file to upload
   * @param progressCallback A callback function to track the upload progress
   * @param randomizeFileName Whether to randomize the file name
   * @param folder The folder to upload the file to. This can contain slashes to create subfolders (e.g. 'folder/subfolder')
   * @returns The URL of the uploaded file
   */
  uploadFileToS3: (
    file: File,
    progressCallback?: (percentage: number) => void,
    randomizeFileName?: boolean,
    folder?: string,
  ) => Promise<string>;
  uploadVideoFileToS3: (
    file: File,
    onProgress?: (percentage: number) => void,
    randomizeFileName?: boolean,
    folder?: string,
  ) => { promise: Promise<string>; getUrl: () => Promise<string> };
  uploadProgress: number;
}

// Provide a default value for the context
const defaultContextValue: S3UploadContextValue = {
  uploadFileToS3: async () => '',
  uploadVideoFileToS3: () => ({ promise: Promise.resolve(''), getUrl: () => Promise.resolve('') }),
  uploadProgress: 0,
};

const S3UploadContext = createContext<S3UploadContextValue>(defaultContextValue);

// Define props for the S3UploadProvider component
interface S3UploadProviderProps {
  children: ReactNode;
}

export const useS3Upload = () => useContext(S3UploadContext);

export const S3UploadProvider: React.FC<S3UploadProviderProps> = ({ children }) => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const { site } = useSettings();

  const uploadFileToS3 = async (
    file: File,
    progressCallback?: (percentage: number) => void,
    randomizeFileName?: boolean,
    folder?: string,
  ): Promise<string> => {
    try {
      if (!site) {
        throw new Error('Site is not defined');
      }
      const fileName = randomizeFileName ? `${nanoid()}-${file.name}` : file.name;
      // TODO we need to refactor this to use autogen files instead of AXIOS - see TODO in importers/views.py
      const response = await AXIOS_INSTANCE.get<{ url: string; fields: any }>('/s3/presigned_url', {
        params: {
          file_name: folder ? `${site.id}/${folder}/${fileName}` : `${site.id}/${fileName}`,
          content_type: file.type,
        },
      });

      if (!(file instanceof File)) {
        console.error('The provided file is not a valid file.');
        // return;
      }

      const formData = new FormData();
      Object.entries(response.data.fields).forEach(([key, value]) => {
        formData.append(key, value as string); // Assuming all values are strings; adjust if necessary.
      });

      // Here, TypeScript knows `file` is a File/Blob, resolving the error.
      formData.append('file', file);

      const uploadUrl = response.data.url; // Make sure this is typed as a string

      await axios.post(uploadUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentage);
          progressCallback?.(percentage);
        },
      });

      const fileUrl = `${uploadUrl}${response.data.fields.key}`.split('?')[0];
      return fileUrl;
    } catch (err) {
      console.error('Upload failed:', err);
      throw err;
    }
  };

  const uploadVideoFileToS3 = (
    file: File,
    onProgress?: (percentage: number) => void,
    randomizeFileName?: boolean,
    folder?: string,
  ) => {
    // Initially define resolveUpload and rejectUpload as no-ops to ensure they are always initialized.
    let resolveUpload: (url: string) => void = () => {};
    let rejectUpload: (error: Error) => void = () => {};

    const promise = new Promise<string>((resolve, reject) => {
      // Now reassign with the actual implementation
      resolveUpload = resolve;
      rejectUpload = reject;
    });

    uploadFileToS3(file, onProgress, randomizeFileName, folder)
      .then(resolveUpload)
      .catch(rejectUpload);

    const getUrl = () => promise;

    return { promise, getUrl };
  };

  return (
    <S3UploadContext.Provider value={{ uploadFileToS3, uploadVideoFileToS3, uploadProgress }}>
      {children}
    </S3UploadContext.Provider>
  );
};
