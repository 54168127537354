import React from 'react';
import createCache from '@emotion/cache';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { Theme, ThemeProvider } from '@mui/material/styles';
import root from 'react-shadow';
import { FeatureConfig as FeatureConfigModel } from '@juno/client-api/model';
import { FeatureConfig as FeatureConfigUtil } from '@juno/client-api/utils';
import { getTheme } from '@juno/utils';
import './styles.css';

interface ShadowWrapperProps {
  configs?: FeatureConfigModel[];
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const ShadowWrapper: React.FC<ShadowWrapperProps> = ({ children, configs, style }) => {
  const [cache, setCache] = React.useState<EmotionCache | undefined>();
  const [theme, setTheme] = React.useState<Theme | undefined>();
  const [container, setContainer] = React.useState<HTMLElement | undefined>();
  const shadowEl = React.useRef() as React.MutableRefObject<HTMLDivElement>;

  React.useEffect(() => {
    // create theme to use palette prop
    if (!container) {
      return;
    }
    // speedy: false is crucial to work in production https://github.com/emotion-js/emotion/issues/2053
    setCache(createCache({ key: 'shadow-css', container, speedy: false, prepend: true }));
    const themeConfig = FeatureConfigUtil.getThemeConfigTheme(configs || []);
    setTheme(getTheme(themeConfig, container));
    return () => {
      setCache(undefined);
      setTheme(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configs, container]);

  React.useEffect(() => {
    // build shadow root container for emotion cache and theme provider
    const shadowRoot = shadowEl.current;
    if (!shadowRoot || !!container) {
      return;
    }
    const shadowContainer = document.createElement('span');
    shadowContainer.id = 'mui-shadow-container-root';
    shadowRoot.appendChild(shadowContainer);
    setContainer(shadowContainer);
    return () => {
      setContainer(undefined);
      shadowContainer.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shadowEl]);

  // prevents the top-level elements of a shadow tree inheriting from the host element.
  const shadowStyle = ':host {  all: initial }';

  return (
    <section ref={shadowEl}>
      {cache && theme && (
        <CacheProvider value={cache}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div style={{ ...style }}>{children}</div>
            <style>{shadowStyle}</style>
          </ThemeProvider>
        </CacheProvider>
      )}
    </section>
  );
};

export default ShadowWrapper;
