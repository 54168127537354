import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { DeleteTwoTone } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { Download } from '@juno/client-api/model';
import { useS3Upload } from '@juno/modules';
import { DialogAriaWrapper, FileTypeIcon } from '@juno/ui';
import { truncateFileName } from '@juno/utils';
import { FileTree } from '../GroupFilesTab/FileTreeView';

interface UploadFilesDialogProps {
  setShowFileUpload: (show: boolean) => void;
  uploadFiles: (uploads: Download[]) => void;
  open: boolean;
  folderHierarchy: FileTree;
}

const UploadFilesDialog: React.FC<UploadFilesDialogProps> = ({
  setShowFileUpload,
  uploadFiles,
  open,
  folderHierarchy,
}) => {
  const [newUploads, setNewUploads] = useState<File[]>([]);
  const [newUploadsAsDownload, setNewUploadsAsDownload] = useState<Download[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { uploadFileToS3 } = useS3Upload();
  const [folderName, setFolderName] = useState<string | null>(null);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [optionList, setOptionList] = useState<string[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.filter(
        (file) => !newUploads.find((f) => f.name === file.name),
      );

      const newDownloads: Download[] = newFiles.map((file) => ({
        id: file.name,
        url: '',
        title: file.name,
        description: '',
        filetype: file.type,
      }));

      setNewUploadsAsDownload([...newUploadsAsDownload, ...newDownloads]);
      setNewUploads([...newUploads, ...newFiles]);
    },
    [setNewUploads, newUploads, newUploadsAsDownload],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleInputChange = (index: number, fieldName: string, value: any) => {
    setNewUploadsAsDownload((old) =>
      old.map((d, i) => (i === index ? { ...d, [fieldName]: value } : d)),
    );
  };

  const handleUploadFiles = async () => {
    const promises = [
      ...newUploads.map((file) =>
        uploadFileToS3(file, setUploadProgress, false, folderName ? folderName : ''),
      ),
    ];
    const urls = await Promise.all(promises);
    const files = newUploadsAsDownload.map(
      (file, index) => ({ ...file, url: urls[index] } as Download),
    );

    if (files.length > 0) {
      uploadFiles(files);
    }
    setNewUploads([]);
    setNewUploadsAsDownload([]);
    setFolderName(null);
  };

  const isUploadDisabled =
    newUploadsAsDownload.some((file) => file.title === '') || newUploadsAsDownload.length === 0;

  useEffect(() => {
    if (!folderHierarchy) return;
    const folderOptions = (tree: FileTree, parentPath = ''): string[] => {
      return Object.entries(tree)
        .sort(([nameA], [nameB]) => nameA.localeCompare(nameB))
        .flatMap(([name, content]) => {
          const fullPath = parentPath ? `${parentPath}/${name}` : name;
          if (typeof content === 'object' && !('download' in content)) {
            return [fullPath, ...folderOptions(content as FileTree, fullPath)];
          }
          return [];
        });
    };
    setOptionList(folderOptions(folderHierarchy));
  }, [folderHierarchy]);

  return (
    <DialogAriaWrapper
      id={`file_upload_dialog`}
      open={open}
      maxWidth='sm'
      fullWidth={true}
      onClose={() => {
        setNewUploads([]);
        setShowFileUpload(false);
      }}
    >
      <DialogContent id='file_upload_dialog-dialog-description'>
        <Stack spacing={1}>
          {newUploadsAsDownload.map((file, index) => (
            <Card
              variant='outlined'
              key={file.id}
              sx={{
                padding: 1,
              }}
            >
              <Grid container alignItems={'center'}>
                <Grid item xs={12} p={1}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    required
                    inputProps={{ maxLength: 67 }}
                    placeholder={file.title}
                    value={file.title}
                    label='Edit title'
                    error={file.title === ''}
                    helperText={
                      file.title === '' ? 'Title is required' : 'Max length 67 characters'
                    }
                    onChange={(e) => handleInputChange(index, 'title', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} p={1}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    inputProps={{ maxLength: 78 }}
                    placeholder={'Description'}
                    helperText='Max length 78 characters'
                    multiline={true}
                    value={file.description}
                    onChange={(e) => handleInputChange(index, 'description', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} p={1}>
                  <Tooltip title={file.title} placement='top'>
                    <Button
                      variant='text'
                      color='inherit'
                      sx={{ textTransform: 'none' }}
                      startIcon={<FileTypeIcon fileType={file.filetype} />}
                    >
                      {truncateFileName(file.id)}
                    </Button>
                  </Tooltip>
                  <IconButton
                    aria-label='Remove file'
                    component='span'
                    onClick={() => {
                      setNewUploads(newUploads.filter((f) => f.name !== file.title));
                      setNewUploadsAsDownload(
                        newUploadsAsDownload.filter((f) => f.title !== file.title),
                      );
                    }}
                  >
                    <DeleteTwoTone sx={{ fontSize: '24px' }} color='primary' />
                  </IconButton>
                </Grid>
              </Grid>
              <Box></Box>
            </Card>
          ))}
        </Stack>
        <UploadWrapperBox {...getRootProps()} sx={{ mt: 2 }}>
          <input {...getInputProps()} />
          <AddIcon sx={{ fontSize: 60 }} />
          <Typography sx={{ fontSize: 20, fontWeight: 800 }}>Drag and Drop</Typography>
          <Typography>{'Click to select or drag and drop files to upload'}</Typography>
        </UploadWrapperBox>
      </DialogContent>
      <DialogActions>
        <Box width={'100%'} display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
          {isCreatingFolder ? (
            <>
              <Button
                onClick={() => setIsCreatingFolder(false)}
                aria-label='Cancel creating folder'
                variant='outlined'
                sx={{ textTransform: 'none', mr: 1 }}
              >
                Cancel
              </Button>
              <TextField
                name='folder_name'
                InputLabelProps={{ shrink: true }}
                size='small'
                fullWidth
                value={folderName || ''}
                onChange={(e) => setFolderName(e.target.value)}
                placeholder={'Enter subfolders as folder/subfolder'}
              />
            </>
          ) : (
            <>
              <Button
                onClick={() => setIsCreatingFolder(true)}
                aria-label='Create folder'
                variant='contained'
                sx={{ textTransform: 'none', mr: 1, height: '100%' }}
              >
                Create Folder
              </Button>
              <FormControl sx={{ minWidth: 300 }} size={'small'}>
                <InputLabel id='folder-select-label'>Select a Folder</InputLabel>
                <Select
                  autoWidth
                  label='Select a Folder'
                  labelId='folder-select-label'
                  value={folderName}
                  onChange={(e) => setFolderName(decodeURIComponent(e.target.value as string))}
                >
                  {optionList.map((folder) => (
                    <MenuItem key={folder} value={decodeURIComponent(folder)}>
                      {decodeURIComponent(folder)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </Box>
        <Button
          size='small'
          color='inherit'
          onClick={() => {
            setNewUploads([]);
            setNewUploadsAsDownload([]);
            setShowFileUpload(false);
            setFolderName(null);
          }}
          aria-label='Cancel editing'
          variant='text'
          sx={{ textTransform: 'none' }}
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={isUploadDisabled}
          size='small'
          color='primary'
          onClick={handleUploadFiles}
          aria-label='Save changes'
          variant='outlined'
          sx={{ textTransform: 'none' }}
          loading={uploadProgress > 0 && uploadProgress < 100}
        >
          {'Upload'}
        </LoadingButton>
      </DialogActions>
    </DialogAriaWrapper>
  );
};

export default UploadFilesDialog;

const UploadWrapperBox = styled(Box)(({ theme }) => ({
  flex: 1,
  textAlign: 'center' as const,
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderStyle: 'dashed',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
}));
