import * as React from 'react';
import { ShareOutlined } from '@mui/icons-material';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import { Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { TimeSinceCreatedText } from './styles';

interface ThreadPostActionsProps {
  onReplyButtonClick: () => void;
  handleLikeButtonClick: () => void;
  handleShareClick: () => void;
  isLiked: boolean;
  numLikes: number;
  replyCount: number;
  isAuthor: boolean;
  isReply: boolean;
  userCanInteract?: boolean;
  timeSinceCreatedText: string;
}

const ThreadPostCardActions: React.FC<ThreadPostActionsProps> = ({
  onReplyButtonClick,
  handleLikeButtonClick,
  handleShareClick,
  isLiked,
  numLikes,
  replyCount,
  isReply,
  userCanInteract = true,
  timeSinceCreatedText,
}) => {
  return (
    <Grid container p={isReply ? '0 12px' : '4px 16px'}>
      <Grid item xs={6}>
        <Stack direction='row' spacing={1}>
          <Tooltip title={isLiked ? 'Unlike' : 'Like'} placement='top'>
            <IconButton
              aria-label={isLiked ? 'unlike post' : 'like post'}
              onClick={handleLikeButtonClick}
              disabled={!userCanInteract}
            >
              {isLiked ? (
                <FavoriteRoundedIcon color={'primary'} fontSize='small' />
              ) : (
                <FavoriteBorderOutlinedIcon fontSize='small' />
              )}
              {numLikes > 0 && (
                <Typography pl={1} color={isLiked ? 'primary' : 'default'}>
                  {numLikes}
                </Typography>
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title='Reply' placement='top'>
            <IconButton
              aria-label='reply to post'
              onClick={() => onReplyButtonClick()}
              disabled={!userCanInteract}
            >
              <ChatBubbleOutlineOutlinedIcon
                color={replyCount > 0 ? 'primary' : 'inherit'}
                fontSize='small'
              />
              {replyCount > 0 && (
                <Typography pl={1} color='primary'>
                  {replyCount}
                </Typography>
              )}
            </IconButton>
          </Tooltip>

          <Tooltip title={'Share'} placement='top'>
            <IconButton
              aria-label={'share post - copy to clipboard'}
              onClick={handleShareClick}
              disabled={!userCanInteract}
            >
              <ShareOutlined fontSize='small' />
            </IconButton>
          </Tooltip>
        </Stack>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: 'right' }}>
        <TimeSinceCreatedText variant='caption'>{timeSinceCreatedText}</TimeSinceCreatedText>
      </Grid>
    </Grid>
  );
};

export default ThreadPostCardActions;
