import React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from '@mui/material';
import { JunoImage as JunoImageModel } from '@juno/client-api/fakeModel';

export interface ImageUploadModalProps<T> {
  open: boolean;
  onCancel: () => void;
  progress: number;
}

const ImageUploadModal: React.FC<ImageUploadModalProps<JunoImageModel>> = (props) => {
  const { onCancel, open, progress } = props;
  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{'Uploading Files'}</DialogTitle>
      <DialogContent style={{ width: 200 }} id='alert-dialog-description'>
        <Box display='flex' alignItems='center'>
          <Box width='100%' mr={1}>
            <LinearProgress variant='determinate' value={progress} />
          </Box>
          <Box minWidth={35}>
            <Typography variant='body2' color='textSecondary'>
              {`${Math.round(progress)}%`}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color='primary'>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImageUploadModal;
