import React, { useEffect, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Box, InputAdornment, ListItem, TextField } from '@mui/material';
import { JunoUser, Status0c0Enum as StatusEnum } from '@juno/client-api/model';
import { DefaultCircularProgress, UserTile } from '../../index';

interface OnSubmitProps {
  user: JunoUser;
  status: StatusEnum;
}

interface AutocompleteUsersProps {
  options: JunoUser[];
  selected: JunoUser[];
  onSubmit: (user: JunoUser) => void;
  onInputChange: (value: string) => void;
  isLoading: boolean;
  isLoadingPage: boolean;
  userLoading?: string;
  locked: boolean;
  label: string;
  showClearButton?: boolean;
  paginateRef?: (node?: Element | null | undefined) => void;
  closeOnSelect?: boolean;
  filteredUsers?: string[];
  noOptionsText?: string;
  onClear?: () => void;
  round?: boolean;
  filled?: boolean;
}

const AutocompleteUsers: React.FC<AutocompleteUsersProps> = ({
  options,
  selected,
  onSubmit,
  onInputChange,
  isLoading,
  isLoadingPage,
  userLoading,
  locked,
  label,
  showClearButton,
  paginateRef,
  closeOnSelect = false,
  filteredUsers = [],
  noOptionsText,
  onClear,
  round = false,
  filled = false,
}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const scrollRef = useRef<HTMLUListElement>(null);
  const scrollDistance = useRef(0);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollDistance.current;
    }
  }, [options]);

  const handleChange = (e: React.SyntheticEvent, newValue: JunoUser[], reason: string) => {
    const newLearner = newValue[newValue.length - 1];
    if (reason === 'selectOption') {
      onSubmit(newLearner);
    }
    closeOnSelect && setOpen(false);
  };

  const handleInputChange = (e: React.SyntheticEvent, newInputValue: string, reason: string) => {
    if (['change'].includes(e?.type) || ['clear'].includes(reason)) {
      setInputValue(newInputValue);
      onInputChange(newInputValue);
    }
  };

  const onScroll = (e: React.UIEvent<HTMLUListElement, UIEvent>) => {
    scrollDistance.current = e.currentTarget.scrollTop;
  };

  return (
    <Autocomplete
      fullWidth
      multiple
      disableCloseOnSelect
      ListboxProps={{ onScroll: onScroll }} //  ref: scrollRef }}
      value={selected}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      filterOptions={(x) => x.filter((user) => !filteredUsers.includes(user.id))}
      loading={isLoading || isLoadingPage}
      loadingText={
        <Box style={{ textAlign: 'center' }}>
          <DefaultCircularProgress props={{ size: 20, variant: 'indeterminate' }} />
        </Box>
      }
      getOptionLabel={(option) => `${option.first_name} ${option.last_name} ${option.id}`}
      options={options}
      disableClearable={!showClearButton}
      clearOnBlur={false}
      noOptionsText={noOptionsText || 'No Users Found'}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={label}
          variant={filled ? 'filled' : 'outlined'}
          size='small'
          sx={round ? { borderRadius: 10 } : {}}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position='start'>
                {' '}
                <SearchIcon />{' '}
              </InputAdornment>
            ),
            endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
            onKeyDown: (e) => {
              // Backspace/Delete should not control the selection. This causes confusion. Don't send to inner list items.
              if (e.key === 'Backspace') {
                e.stopPropagation();
              }
            },
          }}
        />
      )}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        scrollDistance.current = 0;
        setOpen(false);
      }}
      disabled={locked}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disablePortal={false}
      renderTags={() => null}
      renderOption={(props, option) => {
        const lastItem = option.id === options[options.length - 1].id;
        const loading = userLoading === option.id;
        return (
          <ListItem
            {...props}
            style={{
              padding: 0,
              flexDirection: lastItem ? 'column' : 'row',
              pointerEvents: userLoading ? 'none' : 'auto',
              cursor: userLoading ? 'pointer' : 'normal',
              opacity: userLoading && !loading ? 0.3 : 1,
              ...props.style,
            }}
          >
            <UserTile
              user={option}
              virtualized={true}
              email={true}
              loading={loading}
              hoverState={true}
            />
            {lastItem && (
              <Box ref={paginateRef}>
                <DefaultCircularProgress
                  props={{
                    size: 18,
                    variant: 'indeterminate',
                    style: { visibility: isLoadingPage ? 'visible' : 'hidden' },
                  }}
                />
                <Box ref={paginateRef} sx={{ pb: 1 }} />
              </Box>
            )}
          </ListItem>
        );
      }}
    />
  );
};

export default AutocompleteUsers;
