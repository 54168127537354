import { styled } from '@mui/material/styles';

export const Container = styled('div', {
  shouldForwardProp: (prop) => prop !== 'imageUrl',
})<{ imageUrl: string }>(({ imageUrl }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundImage: `url(${imageUrl})`,
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center',
  height: '100vh',
  width: '100%',
}));

export const Content = styled('div')(() => ({
  height: 250,
  position: 'absolute',
  top: 'calc(50% - 125px)',
  left: 'calc(50% - 175px)',
  textAlign: 'center',
  color: 'white',
  fontWeight: 400,
  fontSize: 40,
  textShadow: '-1px -1px 3px #000, 1px -1px 3px #000, -1px 1px 3px #000, 1px 0px 3px #000',
  userSelect: 'none',
}));

export const Title = styled('h1')(() => ({
  lineHeight: '160px',
  fontSize: 180,
  margin: 0,
}));
