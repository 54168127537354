import React, { createContext, useContext } from 'react';
import { useCreateActivityRecord } from '@juno/client-api';
import { Activity } from '@juno/client-api/model';
import { MutationAction, onMutation, useSettings } from '@juno/utils';

const getGuid = () => {
  let d = new Date().getTime(); //Timestamp
  let d2 = (performance && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

const defaultAnalyticsContext = {
  firehoseActivity: (
    objectType: string,
    objectId: string,
    locationId: string | null,
    userId: string,
    category: string,
    action: string | null,
    label: string | null,
    metric: string | null | number,
  ) => {},
};

const AnalyticsContext = createContext<AnalyticsContextType>(
  defaultAnalyticsContext as AnalyticsContextType,
);

export const useAnalyticsContext = () => useContext(AnalyticsContext);

type AnalyticsContextType = {
  firehoseActivity: (
    objectType: string,
    objectId: string,
    locationId: string | null,
    userId: string,
    category: string,
    action: string | null,
    label: string | null,
    metric: string | null | number,
  ) => void;
};

type AnalyticsProviderProps = {
  children: React.ReactNode;
};

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({ children }) => {
  const { site, user } = useSettings();

  // Leaving model argument blank so we don't get a toast message when the activity is created
  const createActivity = useCreateActivityRecord(onMutation(MutationAction.CREATE, ''));

  const firehoseActivity = (
    objectType: string,
    objectId: string,
    locationId: string | null,
    userId: string,
    category: string,
    action: string | null,
    label: string | null,
    metric: string | null | number,
  ) => {
    if (!site) {
      return;
    }
    const data = {
      activityId: getGuid(),
      userAgent: navigator.userAgent,
      platformId: `${site.platform_id}`,
      siteId: `${site.id}`,
      dateActivity: new Date().toISOString(),
      objectType: objectType,
      objectId: objectId,
      locationId: locationId,
      userId: userId !== undefined ? `${userId}` : `${user?.id}` || '',
      category: objectType === 'Content' ? `Content - ${category}` : category,
      action: action,
      label: label,
      metric: metric,
    };

    // You're probably wondering why we're not breaking this into the individual fields, and that's a valid wonder.
    // Basically the fact that metric can be null | string | number makes generating and endpoint for this impossible
    // w/o updating the data model for analytics. Something to consider for the future.
    const jsonString = JSON.stringify(data);
    const activity: Activity = {
      data: jsonString,
    };

    createActivity.mutate({ siteId: site.id, data: activity });
  };
  const contextValue = {
    firehoseActivity,
  };
  return <AnalyticsContext.Provider value={contextValue}>{children}</AnalyticsContext.Provider>;
};
