import { MenuProps, Select } from '@mui/material';
import {
  ThemeOptions,
  createTheme,
  darken,
  lighten,
  responsiveFontSizes,
  styled,
} from '@mui/material/styles';
import { BASE_THEME, DEFAULT_THEME_CONFIG_PRESET, ThemeConfigProps } from '@juno/constants';

const insertBaseContainer = (theme: ThemeOptions, container: HTMLElement) => {
  return {
    ...theme,
    components: {
      ...theme.components,
      MuiMenu: {
        defaultProps: {
          container,
        },
      },
      MuiTooltip: {
        defaultProps: {
          PopperProps: {
            container,
          },
        },
      },
      MuiModal: {
        defaultProps: {
          container,
        },
      },
    },
  };
};

export const getTheme = (themeConfig?: ThemeConfigProps | undefined, container?: HTMLElement) => {
  const theme = container ? insertBaseContainer(BASE_THEME, container) : BASE_THEME;
  const palette = themeConfig?.palette || DEFAULT_THEME_CONFIG_PRESET.palette;
  const typography = themeConfig?.typography || DEFAULT_THEME_CONFIG_PRESET.typography;
  const newTheme = createTheme({
    ...theme,
    palette,
    typography: { ...theme.typography, ...typography },
  });
  return responsiveFontSizes(newTheme);
};

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '12px',
  color: theme.palette.secondary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.secondary.light, 0.85)
      : darken(theme.palette.secondary.main, 0.8),
}));

export const GroupItems = styled('ul')({
  padding: 0,
});

export const snackOptions = (type: SnackParamsProps['type']) => {
  return {
    variant: type,
    style: { maxWidth: '100vw' },
    anchorOrigin: {
      vertical: 'bottom' as const,
      horizontal: 'right' as const,
    },
  };
};

export interface SnackParamsProps {
  message: string;
  type: 'success' | 'error';
}

export const StyledSelect = styled(Select)(() => ({
  borderColor: 'transparent',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '&:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.1)',
      backgroundColor: 'rgba(0, 0, 0, 0.06)',
    },
  },
  borderRadius: '100px',
  maxWidth: '160px',
  fontSize: '12px',
}));

export const SelectMenuProps: Partial<MenuProps> = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  sx: {
    '.MuiMenuItem-root': {
      fontSize: '12px',
    },
  },
  elevation: 5,
};
