import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useGetLessonCompletion } from '@juno/client-api';
import { Answer as AnswerModel, Question } from '@juno/client-api/model';
import { Question as QuestionUtils } from '@juno/client-api/utils';
import { Blurb } from '@juno/modules';
import FillInTheBlank from './FillInTheBlank';
import MultipleChoice from './MultipleChoice';
import Poll from './Poll';
import Ranking from './Ranking';
import Rating from './Rating';

interface JunoQuestionProps {
  id?: string;
  question: Question | undefined;
  onAnswerMultipleChoice?: (question: Question | undefined, answer: AnswerModel[]) => void;
  onAnswerRanking?: (question: Question | undefined, answer: string[]) => void;
  onAnswerRating?: (question: Question | undefined, answer: number) => void;
  onAnswerFillInTheBlank?: (question: Question | undefined, answer: string) => void;
  displayOnly?: boolean;
  isIncomplete?: boolean;
  disabled?: boolean;
  lessonId?: string;
  courseId?: string;
  siteId?: string;
  initialAnswers?: string[];
  isRetake?: boolean;
}

const QuestionContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(6),
}));

const QuestionBody = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isIncomplete' && prop !== 'disabled',
})<{ isIncomplete?: boolean; disabled: boolean }>(({ isIncomplete, theme, disabled }) => ({
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  border: `solid 1px ${!isIncomplete ? theme.palette.background.paper : theme.palette.error.light}`,
  opacity: disabled ? 0.7 : 1,
  pointerEvents: disabled ? 'none' : 'auto',
}));

export default function JunoQuestion({
  id,
  question,
  onAnswerMultipleChoice = () => {},
  onAnswerRanking = () => {},
  onAnswerRating = () => {},
  onAnswerFillInTheBlank = () => {},
  displayOnly = false,
  isIncomplete = false,
  disabled = false,
  lessonId,
  courseId,
  siteId,
  initialAnswers,
  isRetake,
}: JunoQuestionProps): React.ReactElement {
  const [answer, setAnswer] = useState<string[]>([]);
  const [hasAnswered, setHasAnswered] = useState(false);

  useEffect(() => {
    if (initialAnswers && !isRetake) {
      setAnswer(initialAnswers);
    }
  }, [initialAnswers]);
  const handleSetAnswer = (newAnswer: string[]) => {
    const answers = question?.answers.filter((a) => newAnswer.includes(a.id));
    switch (question?.type) {
      case QuestionUtils.QUESTION_TYPES.MULTIPLE_CHOICE.value:
      case QuestionUtils.QUESTION_TYPES.POLL.value:
        onAnswerMultipleChoice(question, answers || []);
        setAnswer(newAnswer);
        break;
      case QuestionUtils.QUESTION_TYPES.FILL_IN_THE_BLANK.value:
        onAnswerFillInTheBlank(question, newAnswer[0]);
        setAnswer(newAnswer);
        break;
      case QuestionUtils.QUESTION_TYPES.RANKING.value:
        onAnswerRanking(question, newAnswer);
        setAnswer(newAnswer);
        break;
      case QuestionUtils.QUESTION_TYPES.RATING.value:
        onAnswerRating(question, parseInt(newAnswer[0]));
        setAnswer(newAnswer);
        break;
    }

    setHasAnswered(true);
    // }
  };

  // Factory for question answers
  if (!question) return <></>;
  let QuestionComponent = <></>;
  switch (question.type) {
    case QuestionUtils.QUESTION_TYPES.MULTIPLE_CHOICE.value:
      QuestionComponent = (
        <MultipleChoice question={question} answer={answer} handleSetAnswer={handleSetAnswer} />
      );
      break;
    case QuestionUtils.QUESTION_TYPES.POLL.value:
      QuestionComponent = (
        <Poll
          question={question}
          answer={answer}
          courseId={courseId || ''}
          lessonId={lessonId || ''}
          siteId={siteId || ''}
          handleSetAnswer={handleSetAnswer}
        />
      );
      break;
    case QuestionUtils.QUESTION_TYPES.FILL_IN_THE_BLANK.value:
      QuestionComponent = (
        <FillInTheBlank question={question} answer={answer} handleSetAnswer={handleSetAnswer} />
      );
      break;
    case QuestionUtils.QUESTION_TYPES.RANKING.value:
      QuestionComponent = (
        <Ranking
          question={question}
          answer={answer}
          handleSetAnswer={handleSetAnswer}
          displayOnly={displayOnly}
        />
      );
      break;
    case QuestionUtils.QUESTION_TYPES.RATING.value:
      QuestionComponent = (
        <Rating question={question} answer={answer} handleSetAnswer={handleSetAnswer} />
      );
      break;
  }

  return (
    <QuestionContainer id={id}>
      <Blurb html={question.question} />
      <QuestionBody disabled={disabled} isIncomplete={isIncomplete}>
        {QuestionComponent}
      </QuestionBody>
    </QuestionContainer>
  );
}
