import { Box, Grid, Skeleton } from '@mui/material';

const GroupPageSkeleton = () => {
  return (
    <Box maxWidth={'768px'} m='auto'>
      <Skeleton width='100%' height='30vh' />
      <Grid container sx={{ p: '25px 48px' }} spacing={2}>
        <Grid item xs={12} md={8}>
          <Skeleton width={150} height={24} />
          <Skeleton width={250} height={24} />
          <Skeleton width='100%' height={100} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Skeleton width='100%' height={50} />
        </Grid>
      </Grid>
    </Box>
  );
};
export default GroupPageSkeleton;
