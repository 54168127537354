import React, { useContext } from 'react';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { Formik, FormikProps } from 'formik';
import { MAX_SCORM_UPLOAD_SIZE } from '@juno/constants';
import FileUploadButton from '../../../components/FileUploadButton';
import validationSchema from './ValidationSchema';
import { ScormCoursePanelContext } from './context';

export interface ScormCourseForm {
  uploaded_file: string;
}

interface FormDialogProps {
  open: boolean;
  onSave: (course: ScormCourseForm | undefined) => void;
  onClose: () => void;
  isSaving: boolean;
}

const ScormFormDialog: React.FC<FormDialogProps> = ({ open, onClose, onSave, isSaving }) => {
  const INITIAL_FORM_STATE = {
    uploaded_file: '',
  };
  const coursePanelContext = useContext(ScormCoursePanelContext);

  const onFileUploaded = (formik: FormikProps<ScormCourseForm>) => (fileUrl: string) => {
    coursePanelContext.setIsFileUploading(false);
    formik.setFieldValue('uploaded_file', fileUrl);
  };

  const onFileUploadStarted = () => {
    coursePanelContext.setIsFileUploading(true);
  };

  return (
    <Formik
      initialValues={{ ...INITIAL_FORM_STATE }}
      validationSchema={validationSchema}
      onSubmit={(values, actions) => {
        onSave(values);
        actions.resetForm();
      }}
      enableReinitialize={true}
    >
      {(props) => (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>Convert course to a Learning Package</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to convert this course to a Learning Package? This action cannot
              be undone. All existing lessons and lesson parts will be permanently removed.
            </DialogContentText>
            <DialogContentText>
              Select the SCORM, AICC, cmi5, or xAPI file you want to upload
            </DialogContentText>
            <Box display='flex'>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={4}>
                  <FileUploadButton
                    onFileUploaded={onFileUploaded(props)}
                    onFileUploadStarted={onFileUploadStarted}
                    acceptFiles={{ 'application/zip': ['.zip', '.ZIP'] }}
                    maxSizeBytes={MAX_SCORM_UPLOAD_SIZE}
                    icon={UploadFileIcon}
                    title={'Learning Package'}
                    body={'Browse files (1GB)'}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    id='uploaded_file'
                    name='uploaded_file'
                    label='File URL'
                    placeholder=''
                    value={props.values.uploaded_file || ''}
                    onChange={props.handleChange}
                    error={props.touched.uploaded_file && Boolean(props.errors.uploaded_file)}
                    helperText={
                      props.touched.uploaded_file && props.errors.uploaded_file
                        ? props.errors.uploaded_file
                        : '*Supported file types: ZIP'
                    }
                    onBlur={props.handleBlur}
                    variant='outlined'
                    sx={{}}
                    disabled={isSaving}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <Button disabled={isSaving} onClick={onClose}>
                Cancel
              </Button>
              <LoadingButton
                disabled={!(props.dirty && props.isValid)}
                loading={isSaving}
                variant='contained'
                onClick={() => props.handleSubmit()}
              >
                Save
              </LoadingButton>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
export default ScormFormDialog;
