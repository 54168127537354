import { LEARNING_ROLE_TYPE } from '@juno/constants';
import { useGetMe, useGetSite } from '../api';
import { RoleEnum as ShittyRoleEnum } from '../api/model';
import { JunoUser } from '../api/model/junoUser';

const RoleEnum = {
  USER: ShittyRoleEnum.NUMBER_0,
  JUNO_ADMIN: ShittyRoleEnum.NUMBER_1,
};

interface MeProps extends JunoUser {
  is_admin_group_manager: boolean;
  is_course_instructor: boolean;
}

export const useGetUserRoleMap = (siteId: string) => {
  const { data: siteData, isLoading: siteLoading } = useGetSite(siteId);
  const { data: user, isLoading: userLoading } = useGetMe<MeProps>(siteData?.platform_id || '', {
    include: 'is_admin_group_manager,is_course_instructor',
  });

  const isLoading = siteLoading || userLoading;
  const isInstructor = !!user?.is_course_instructor;
  const isGroupAdmin = !!user?.is_admin_group_manager;
  const isSiteAdmin = user?.role === RoleEnum.JUNO_ADMIN;

  let learningRole = LEARNING_ROLE_TYPE.STUDENT;
  if (isSiteAdmin || (isGroupAdmin && isInstructor)) learningRole = LEARNING_ROLE_TYPE.ADMIN;
  else if (isGroupAdmin) learningRole = LEARNING_ROLE_TYPE.MANAGER;
  else if (isInstructor) learningRole = LEARNING_ROLE_TYPE.INSTRUCTOR;

  return { isLoading, user, isSiteAdmin, isGroupAdmin, isInstructor, learningRole };
};
