import React, { useState } from 'react';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
import { Box, Tab, Tabs } from '@mui/material';
import { Session } from '@juno/client-api/model';
import BreakoutRoomsTab from './BreakoutRoomsTab';
import MainRoomTab from './MainRoomTab';

const getTabs = (sessionData: Session | undefined) => {
  return [
    {
      slug: 'info',
      key: 'info',
      icon: <DashboardOutlinedIcon sx={{ mr: 2 }} />,
      label: 'Main Session',
      panel: <MainRoomTab sessionData={sessionData} />,
    },
    {
      slug: 'breakouts',
      key: 'breakouts',
      icon: <FeaturedPlayListOutlinedIcon sx={{ mr: 2 }} />,
      label: 'Breakout Rooms',
      panel: <BreakoutRoomsTab sessionData={sessionData} />,
    },
  ];
};
interface ArchivesProps {
  sessionData: Session | undefined;
}
const Archives: React.FC<ArchivesProps> = ({ sessionData }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const TABS = getTabs(sessionData);
  return (
    <Box display={'flex'}>
      <Tabs
        orientation='vertical'
        value={currentTab}
        onChange={(e, value) => {
          setCurrentTab(value);
        }}
        sx={{
          width: 'fit-content',
          minWidth: '200px',
          pt: 2,
          '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
          },
        }}
      >
        {TABS.map((tab, idx) => (
          <Tab
            key={tab.key}
            id={`archive-tab-${idx}`}
            aria-controls={`archive-tabpanel-${idx}`}
            sx={{
              textTransform: 'none',
              width: '90%',
              ml: 2,
              mt: 1,
              border: '1px solid #e0e0e0',
              borderRadius: 1,
              '&.Mui-selected': {
                border: '1px solid transparent',
                borderRadius: 1,
                color: (theme) => theme.palette.primary.contrastText,
                bgcolor: (theme) => theme.palette.primary.main,
                '&:hover': {
                  bgcolor: (theme) => theme.palette.primary.dark,
                  boxShadow: 1,
                },
              },
              '&:hover': {
                boxShadow: 1,
                bgcolor: (theme) => theme.palette.action.hover,
              },
            }}
            label={<>{tab.label}</>}
          />
        ))}
      </Tabs>
      {TABS.map((tab, idx) => (
        <Box
          key={tab.key}
          role='tabpanel'
          hidden={currentTab !== idx}
          id={`archive-tabpanel-${idx}`}
          aria-labelledby={`archive-tab-${idx}`}
          sx={{
            mt: 2,
            width: '100%',
            pl: 2,
            pr: 2,
          }}
        >
          {currentTab === idx && <Box pt={1}>{tab.panel}</Box>}
        </Box>
      ))}
    </Box>
  );
};
export default Archives;
