import React, { useEffect, useMemo, useRef, useState } from 'react';
import { QuestionAnswerOutlined, SendOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { RoleEnum, Session } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import Aside from '.';
import { QA_ACTIONS, QA_ASIDE } from '../../constants';
import { useCallState } from '../../contexts/CallProvider';
import { useChat } from '../../contexts/ChatProvider';
import { useParticipants } from '../../contexts/ParticipantsProvider';
import { useUIState } from '../../contexts/UIStateProvider';
import { QAMessage } from '../../types';
import QuestionItem from '../QuestionItem';

enum SortState {
  NEWEST = 'newest',
  OLDEST = 'oldest',
  MOST_VOTES = 'mostVotes',
}

enum FilterState {
  ALL = 'all',
  UNANSWERED = 'unanswered',
  ANSWERED = 'answered',
  MINE = 'mine',
}

const QuestionAnswerAside: React.FC = () => {
  const { showAside, setShowAside } = useUIState();
  const { roomInfo } = useCallState();
  const { sendMessage, qaHistory, hasNewQAMessage, setHasNewQAMessage } = useChat();
  const { localParticipant } = useParticipants();
  const { user } = useSettings();

  // TODO
  const [sortedState, setSortedState] = useState(SortState.NEWEST);
  const [filteredState, setFilteredState] = useState(FilterState.ALL);

  const [showInput, setShowInput] = useState(false);
  const [newMessage, setNewMessage] = useState('');

  const inputRef = useRef(null);
  const scrollRef = useRef<HTMLUListElement | null>(null);

  // TODO - where/when do we send the list of questions to the server for analytics?

  useEffect(() => {
    if (showAside === QA_ASIDE && hasNewQAMessage) {
      setHasNewQAMessage(false);
    }
  }, [showAside, qaHistory.length, hasNewQAMessage]);

  const sessionData = useMemo(() => {
    if (roomInfo) {
      return (roomInfo as Record<string, unknown>)['session'] as Session;
    }
    return {} as Session;
  }, [roomInfo]);

  const isModerator = useMemo(() => {
    if (user?.role === RoleEnum.NUMBER_1) return true;
    if (sessionData?.moderators?.find((moderator) => moderator.id === user?.id)) return true;
    return false;
  }, [user, sessionData]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [qaHistory, showAside]);

  const askButtonClicked = () => {
    const message = {
      question: newMessage,
      userId: user?.id || '',
      timestamp: new Date().getTime(),
      voters: [],
    } as QAMessage;
    sendMessage(message, QA_ACTIONS.ASK_QUESTION);
    setNewMessage('');
    setShowInput(false);
  };

  if (!showAside || showAside !== QA_ASIDE) {
    return null;
  }

  return (
    <Aside onClose={() => {}}>
      <Box
        sx={{
          position: 'relative',
          height: '100%',
        }}
      >
        <Card
          sx={{
            background: '#5e5a63',
            color: 'white',
            p: 1,
            textAlign: 'center',
          }}
        >
          <Typography variant={'subtitle2'}>Q&A</Typography>
        </Card>
        {qaHistory.length === 0 ||
        qaHistory.filter((q: any) => q.message?.isDeleted)?.length === qaHistory.length ? (
          <Typography p={3} variant={'body2'}>
            No questions to show
          </Typography>
        ) : (
          <>
            {/* // TODO sort and filter selects */}
            <List
              sx={{
                maxHeight: 'calc(100% - 110px)',
                overflowY: 'scroll',
              }}
              ref={scrollRef}
            >
              {qaHistory
                .sort((a: any, b: any) => b.message.voters.length - a.message.voters.length)
                .map((item: any) => (
                  <QuestionItem
                    key={item.id}
                    sender={item.sender}
                    message={item.message}
                    id={item.id}
                    createdAt={item.createdAt}
                    localParticipant={localParticipant}
                    sendMessage={sendMessage}
                    isModerator={isModerator}
                  />
                ))}
            </List>
          </>
        )}
        <Box
          position={'absolute'}
          bottom={0}
          width={'100%'}
          display='flex'
          flexDirection={'column'}
          alignItems='center'
          p={1}
        >
          {showInput && (
            <FormControl fullWidth variant='outlined'>
              <OutlinedInput
                inputRef={inputRef}
                value={newMessage}
                onChange={(e) => {
                  // TODO this causes the whole thing to re-render
                  setNewMessage(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    askButtonClicked();
                  }
                }}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton aria-label='Post message' onClick={askButtonClicked} edge='end'>
                      <SendOutlined />
                    </IconButton>
                  </InputAdornment>
                }
                multiline
                sx={{
                  background: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  minHeight: '60px',
                }}
              />
            </FormControl>
          )}
          <Button
            variant='contained'
            color='primary'
            startIcon={<QuestionAnswerOutlined />}
            onClick={() => {
              setShowInput(!showInput);
            }}
            sx={{ marginLeft: 'auto' }}
          >
            Ask a Question
          </Button>
        </Box>
      </Box>
    </Aside>
  );
};
export default QuestionAnswerAside;
