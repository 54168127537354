import React, { useEffect } from 'react';
import { DailyEventObjectTranscriptionMessage } from '@daily-co/daily-js';
import { Box, Typography } from '@mui/material';
import { useCallState } from '../../contexts/CallProvider';

const TranscriptionContainer: React.FC = () => {
  const { transcriptionState, setTranscriptionState } = useCallState();

  // timeout for 8 seconds and if no new transcription message, clear the transcription
  useEffect(() => {
    if (!transcriptionState) return;
    const timeout = setTimeout(() => {
      setTranscriptionState(null);
    }, 8000);
    return () => clearTimeout(timeout);
  }, [transcriptionState, setTranscriptionState]);

  if (!transcriptionState) return null;
  return (
    <Box
      sx={{
        zIndex: 1000,
        position: 'absolute',
        bottom: 80,
        margin: '0 15%',
        width: '70%',
        textAlign: 'center',
      }}
    >
      <Typography
        fontSize={28}
        fontWeight={'bold'}
        color='white'
        sx={{
          textShadow: '0px 1px 2px rgba(0,0,0,0.5)',
        }}
      >
        {(transcriptionState as unknown as DailyEventObjectTranscriptionMessage)?.text}
      </Typography>
    </Box>
  );
};
export default TranscriptionContainer;
