import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Card, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Notification } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';

interface PushNotificationProps {
  onClose: () => void;
  onClick: () => void;
  notification: Notification;
}
const PushNotification: React.FC<PushNotificationProps> = ({ onClose, onClick, notification }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { sitePath } = useSettings();

  return (
    <Card
      sx={{
        borderRadius: 2,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: 1,
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        transition: 'all 0.3s ease-in-out',
        cursor: 'pointer',
        width: '300px',
      }}
      aria-label='Push notification, click to view content'
      onClick={() => {
        navigate(`/${sitePath}${notification.content_slug}`);
        onClick();
      }}
    >
      <Typography
        sx={{
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        variant='body1'
      >
        {notification.content_name}
      </Typography>
      <IconButton
        aria-label='Clear notification'
        color='inherit'
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
      >
        <CloseIcon />
      </IconButton>
    </Card>
  );
};
export default PushNotification;
