import React from 'react';
import { Arrow, Curve, Point } from './styles';

const JunoSubDirectoryArrow = () => {
  const arrowRef = React.useRef<HTMLDivElement>(null);

  return (
    <Arrow ref={arrowRef}>
      <Curve />
      <Point />
    </Arrow>
  );
};

export default JunoSubDirectoryArrow;
