import React, { useEffect, useState } from 'react';
import { DailyCall, DailyInputSettings } from '@daily-co/daily-js';
import { DeleteForeverOutlined } from '@mui/icons-material';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import BlurOffOutlinedIcon from '@mui/icons-material/BlurOffOutlined';
import BlurOnOutlinedIcon from '@mui/icons-material/BlurOnOutlined';
import { Box, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {
  useCreateSessionBackground,
  useDeleteSessionBackground,
  useGetSessionBackgrounds,
} from '@juno/client-api';
import { SessionBackground } from '@juno/client-api/model';
import {
  MutationAction,
  onMutation,
  uploadImagesToCloudinary,
  useBreakpoint,
  useSettings,
} from '@juno/utils';
import DialogAriaWrapper from '../DialogAriaWrapper';
import { VisuallyHiddenInput } from '../GenericInputComponents';

interface VisualEffectsDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  videoElementEffects: React.MutableRefObject<HTMLVideoElement | null>;
  callObject: DailyCall;
  uploadFileToS3: (
    file: File,
    progressCallback?: ((percentage: number) => void) | undefined,
    randomizeFileName?: boolean | undefined,
    folder?: string | undefined,
  ) => Promise<string>;
}
/**
 * @param uploadFileToS3 is required to be passed in to avoid a circular dependency between @juno/modules and @juno/ui
 */
const VisualEffectsDialog: React.FC<VisualEffectsDialogProps> = ({
  open,
  setOpen,
  videoElementEffects,
  callObject,
  uploadFileToS3,
}) => {
  const { user, site } = useSettings();
  const { xs, sm, md } = useBreakpoint();
  const iconFontSize = xs ? '0.8rem' : sm ? '1rem' : md ? '1.5rem' : 'inherit';
  const [uploadProgress, setUploadProgress] = useState(0);
  const [backgroundChoice, setBackgroundChoice] = useState('none');
  const [backgroundChoiceId, setBackgroundChoiceId] = useState<string | null>(null);

  const { data: backgrounds } = useGetSessionBackgrounds(
    site?.id || '',
    { filter: { site_level_bg: true } },
    { query: { enabled: !!site?.id && open } },
  );
  const { data: myBackgrounds, refetch: refetchMyBgs } = useGetSessionBackgrounds(
    site?.id || '',
    {
      filter: {
        user_id: user?.id,
        site_level_bg: false,
      },
    },
    { query: { enabled: !!site?.id && !!user?.id && open } },
  );
  const createSessionBackground = useCreateSessionBackground(
    onMutation(MutationAction.CREATE, '', refetchMyBgs),
  );
  const deleteSessionBackground = useDeleteSessionBackground(
    onMutation(MutationAction.DELETE, '', refetchMyBgs),
  );

  useEffect(() => {
    if (!callObject) return;
    const localStorageMeetingBackground = localStorage.getItem('meeting-background');
    if (localStorageMeetingBackground) {
      const inputSettings = JSON.parse(localStorageMeetingBackground) as DailyInputSettings;
      callObject.updateInputSettings(inputSettings);
      setBackgroundChoice(inputSettings?.video?.processor?.type || 'none');
    }

    const localStorageMeetingBackgroundId = localStorage.getItem('meeting-background-id');
    if (localStorageMeetingBackgroundId) {
      setBackgroundChoiceId(localStorageMeetingBackgroundId);
    }
  }, [callObject]);

  return (
    <DialogAriaWrapper
      maxWidth='md'
      fullWidth
      open={true}
      // ? instead of using open to open and close the dialog, we need to keep the dialog in DOM state so that the video ref works properly, so we set display to none when not open instead of hiding the entire dialog
      sx={{ display: open ? 'block' : 'none' }}
      onClose={() => setOpen(false)}
    >
      <Box p={2}>
        <Typography variant='h5'>Visual Effects</Typography>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={8}>
            <Box
              position='relative'
              bgcolor={'black'}
              borderRadius={(theme) => theme.spacing(1)}
              overflow={'hidden'}
            >
              <Box
                position={'relative'}
                pt={'56.25%'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <video
                  autoPlay
                  muted
                  playsInline
                  ref={videoElementEffects}
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    position: 'absolute',
                    height: '100%',
                    top: 0,
                    left: 0,
                    transform: 'scaleX(-1)',
                  }}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='caption'>
              *Choosing a visual effect will turn on your camera.
            </Typography>
            {/* <Tabs
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
              variant='scrollable'
              scrollButtons='auto'
            >
              <Tab key={'backgrounds'} label={'Backgrounds'} /> */}
            {/* // TODO - upcoming */}
            {/* <Tab
                        key={'appearance'}
                        label={'Appearance'}
                      /> */}
            {/* </Tabs> */}
            {/* <TabPanel value={currentTab} index={0}> */}
            <Typography variant='subtitle2' pt={2}>
              Blurring
            </Typography>
            <Box display='flex' justifyContent='flex-start' pt={1}>
              <Tooltip placement='top' title={'No Background Blur'}>
                <IconButton
                  sx={{
                    color: backgroundChoice === 'none' ? 'primary.main' : 'inherit',
                    border: '1px solid',
                    borderColor: backgroundChoice === 'none' ? 'primary.main' : 'inherit',
                    borderRadius: 2,
                    mr: 1,
                    height: 52,
                    width: 60,
                  }}
                  onClick={() => {
                    changeBackground(callObject, 'none', () => {
                      localStorage.removeItem('meeting-background-id');
                      setBackgroundChoice('none');
                    });
                  }}
                >
                  <BlurOffOutlinedIcon sx={{ fontSize: iconFontSize }} />
                </IconButton>
              </Tooltip>
              <Tooltip placement='top' title={'Blur My Background'}>
                <IconButton
                  sx={{
                    color: backgroundChoice === 'background-blur' ? 'primary.main' : 'inherit',
                    border: '1px solid',
                    borderColor:
                      backgroundChoice === 'background-blur' ? 'primary.main' : 'inherit',
                    borderRadius: 2,
                    height: 52,
                    width: 60,
                  }}
                  onClick={() => {
                    changeBackground(callObject, 'blur', () => {
                      localStorage.removeItem('meeting-background-id');
                      setBackgroundChoice('background-blur');
                    });
                  }}
                >
                  <BlurOnOutlinedIcon sx={{ fontSize: iconFontSize }} />
                </IconButton>
              </Tooltip>
            </Box>
            <Box mt={2}>
              <Typography variant='subtitle2'>Personal Backgrounds</Typography>
              <ImageList sx={{ width: '100%', maxHeight: 110 }} cols={5} rowHeight={'auto'}>
                {uploadProgress === 0 ||
                (uploadProgress === 0 && myBackgrounds && myBackgrounds?.length === 0) ? (
                  <Tooltip placement='top' title={'Add a Personal Background'}>
                    <IconButton
                      component='label'
                      sx={{
                        border: '1px solid black',
                        borderRadius: 2,
                        minHeight: 52,
                      }}
                    >
                      <AddPhotoAlternateOutlinedIcon sx={{ fontSize: iconFontSize }} />
                      <VisuallyHiddenInput
                        multiple={false}
                        type='file'
                        accept='.jpg'
                        onChange={(e) => {
                          const file = e.target.files?.[0];
                          if (file === undefined) return;

                          try {
                            setUploadProgress(0.01); // set to 1% to show progress bar
                            const awaitUpload = async (file: File) => {
                              const uploadResult = await uploadImagesToCloudinary(
                                [file],
                                setUploadProgress,
                              );
                              const background = {
                                user_id: user?.id,
                                image: uploadResult.url,
                              } as SessionBackground;
                              const newBg = await createSessionBackground.mutateAsync({
                                siteId: site?.id || '',
                                data: background,
                              });

                              changeBackground(
                                callObject,
                                'image',
                                () => {
                                  setBackgroundChoice('background-image');
                                  localStorage.setItem('meeting-background-id', newBg.id);
                                  setBackgroundChoiceId(newBg.id);
                                  setUploadProgress(0);
                                },
                                uploadResult.url,
                              );
                            };

                            awaitUpload(file);
                          } catch (e: any) {
                            console.error("Couldn't upload file", e);
                          }
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Box sx={{ position: 'relative', display: 'inline-flex', width: 50, height: 50 }}>
                    <CircularProgress variant='determinate' value={uploadProgress} />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography
                        variant='caption'
                        component='div'
                        color='text.secondary'
                      >{`${Math.round(uploadProgress)}%`}</Typography>
                    </Box>
                  </Box>
                )}
                {myBackgrounds &&
                  myBackgrounds?.length > 0 &&
                  myBackgrounds?.map((background) => (
                    <ImageListItem key={background.id} sx={{ p: 0 }}>
                      <IconButton
                        sx={{
                          color:
                            backgroundChoice === 'background-image' &&
                            backgroundChoiceId === background.id
                              ? 'primary.main'
                              : 'inherit',
                          border: '1px solid',
                          borderColor:
                            backgroundChoice === 'background-image' &&
                            backgroundChoiceId === background.id
                              ? 'primary.main'
                              : 'transparent',
                          borderRadius: 2,
                          padding: 0,
                        }}
                        onClick={() => {
                          callObject?.setLocalVideo(false);
                          const updateDaily = async () => {
                            const inputSettings = {
                              video: {
                                processor: {
                                  type: 'background-image',
                                  config: {
                                    source: background.image,
                                  },
                                },
                              },
                            } as DailyInputSettings;
                            await callObject.updateInputSettings(inputSettings);

                            localStorage.setItem(
                              'meeting-background',
                              JSON.stringify(inputSettings),
                            );
                            setBackgroundChoice('background-image');
                            localStorage.setItem('meeting-background-id', background.id);
                            setBackgroundChoiceId(background.id);
                            callObject?.setLocalVideo(true);
                          };
                          updateDaily();
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            height: 50,
                            backgroundImage: `url(${background.image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            borderRadius: 2,
                          }}
                        >
                          <IconButton
                            sx={{
                              position: 'absolute',
                              right: -15,
                              top: -10,
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              // if myBackgrounds is empty, then the user has no personal backgrounds, set to non-blur
                              if (myBackgrounds?.length === 1) {
                                changeBackground(callObject, 'none', () => {
                                  localStorage.removeItem('meeting-background-id');
                                  setBackgroundChoice('none');
                                });
                              }
                              deleteSessionBackground.mutateAsync({
                                siteId: site?.id || '',
                                backgroundId: background.id,
                              });
                            }}
                          >
                            <DeleteForeverOutlined color='error' />
                          </IconButton>
                        </Box>
                      </IconButton>
                    </ImageListItem>
                  ))}
              </ImageList>
            </Box>
            <Box mt={2}>
              <Typography variant='subtitle2'>Installed Backgrounds</Typography>
              {!backgrounds ||
                (backgrounds?.length == 0 && (
                  <Typography variant='body2'>No backgrounds available</Typography>
                ))}
              {backgrounds && backgrounds?.length > 0 && (
                <ImageList sx={{ width: '100%', maxHeight: 110 }} cols={5} rowHeight={'auto'}>
                  {backgrounds.map((background) => (
                    <ImageListItem key={background.id} sx={{ p: 0 }}>
                      <IconButton
                        sx={{
                          color:
                            backgroundChoice === 'background-image' &&
                            backgroundChoiceId === background.id
                              ? 'primary.main'
                              : 'inherit',
                          border: '1px solid',
                          borderColor:
                            backgroundChoice === 'background-image' &&
                            backgroundChoiceId === background.id
                              ? 'primary.main'
                              : 'transparent',
                          borderRadius: 2,
                          padding: 0,
                        }}
                        onClick={() => {
                          changeBackground(
                            callObject,
                            'image',
                            () => {
                              localStorage.setItem('meeting-background-id', background.id);
                              setBackgroundChoiceId(background.id);
                            },
                            background.image,
                          );
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            height: 50,
                            backgroundImage: `url(${background.image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            borderRadius: 2,
                          }}
                        />
                      </IconButton>
                    </ImageListItem>
                  ))}
                </ImageList>
              )}
            </Box>
            {/* </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <Typography variant='subtitle2'>Appearance Settings</Typography>
              <Box>
                <Typography variant='body2'>Coming soon</Typography>
              </Box>
            </TabPanel> */}
          </Grid>
        </Grid>
      </Box>
    </DialogAriaWrapper>
  );
};
export default VisualEffectsDialog;

const changeBackground = async (
  callObject: DailyCall,
  type: 'blur' | 'none' | 'image',
  callback: () => void,
  imageUrl?: string,
) => {
  callObject?.setLocalVideo(false);

  let inputSettings = {
    video: {
      processor: {
        type: 'none',
      },
    },
  } as DailyInputSettings;

  switch (type) {
    case 'blur':
      inputSettings = {
        video: {
          processor: {
            type: 'background-blur',
            config: {
              strength: 0.9,
            },
          },
        },
      };
      break;
    case 'image':
      inputSettings = {
        video: {
          processor: {
            type: 'background-image',
            config: {
              source: imageUrl,
            },
          },
        },
      };
      break;
    default:
      break;
  }

  await callObject.updateInputSettings(inputSettings);

  localStorage.setItem('meeting-background', JSON.stringify(inputSettings));

  callObject?.setLocalVideo(true);

  callback();
};
