/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type UserEducationCreditStatusEnum = typeof UserEducationCreditStatusEnum[keyof typeof UserEducationCreditStatusEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserEducationCreditStatusEnum = {
  in_progress: 'in_progress',
  ready_to_claim: 'ready_to_claim',
  claimed: 'claimed',
} as const;
