import { Box, Skeleton, Stack } from '@mui/material';

export const ThreadLoadingSkeleton = () => (
  <Stack sx={{ pt: 2 }} spacing={2}>
    <Box display={'flex'}>
      <Skeleton variant='circular' height={50} width={50} />
      <Skeleton variant='rectangular' height={50} width={'50%'} />
    </Box>
    <Skeleton variant='rectangular' height={50} width={'50%'} />
    <Skeleton variant='rectangular' height={50} width={'50%'} />
  </Stack>
);
