import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getGetPagesQueryKey, useCreatePage, useGetPages } from '@juno/client-api';
import { PageTypeEnum, SearchContentTypeEnum, Site } from '@juno/client-api/model';
import { GridV2 } from '@juno/modules';
import { Container, DefaultSearchSortFilter, DialogAriaWrapper } from '@juno/ui';
import { slugify, usePagesSearchFilter, useSettings } from '@juno/utils';

interface LibraryAdminProps {
  site?: Site;
}
const LibraryAdmin: React.FC<LibraryAdminProps> = ({ site }) => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newPageName, setNewPageName] = useState('');
  const [newPageSlug, setNewPageSlug] = useState('');
  const [, , searchFilter, setSearch] = usePagesSearchFilter();
  const [sort, setSort] = useState('title');
  const {
    data: pages,
    isFetching: isLoading,
    refetch,
  } = useGetPages(site?.id || '', {
    filter: { type: PageTypeEnum.library, ...searchFilter },
    order: sort,
  });
  const { configs } = useSettings();
  const navigate = useNavigate();
  const createPage = useCreatePage();
  const queryClient = useQueryClient();

  const handleNameChange = (name: string) => {
    setNewPageName(name);
    setNewPageSlug(slugify(name));
  };

  const handleSlugChange = (slug: string) => {
    setNewPageSlug(slugify(slug));
  };

  const handleOpenCreatePage = (e: any) => {
    e.stopPropagation();
    setCreateDialogOpen(true);
  };

  const handleCreatePage = async () => {
    setIsSaving(true);
    try {
      await createPage.mutateAsync({
        siteId: site?.id || '',
        data: {
          id: '',
          site: site?.id || '',
          title: newPageName,
          slug: newPageSlug,
          type: PageTypeEnum.library,
          components: [],
        },
      });
      await queryClient.invalidateQueries(
        getGetPagesQueryKey(site?.id || '', {
          filter: { type: PageTypeEnum.library },
        }),
      );
      setIsSaving(false);
      setNewPageName('');
      setNewPageSlug('');
      setCreateDialogOpen(false);
      navigate(`/${site?.slug}/admin/pages/${newPageSlug}`);
    } catch (e) {
      console.error(e);
      setIsSaving(false);
    }
  };

  const cancelCreatePage = () => {
    setNewPageName('');
    setNewPageSlug('');
    setCreateDialogOpen(false);
  };

  const items = pages?.map((page) => ({
    title: page.title,
    slug: page.slug,
    abstract: `/library/${page.slug}`,
    components: page.components,
    id: page.id,
    icon: '',
  }));

  return (
    <Container>
      <Card sx={{ p: 2, mb: 4 }}>
        <Typography variant='h5' sx={{ mb: 0 }}>
          Library
        </Typography>
        <Divider sx={{ my: 2, mx: -0, mb: 4 }} />
        <Typography variant='body2' sx={{ mb: 5, mt: 1, maxWidth: 900 }}>
          These are pages around your site that can be edited and linked to. Clicking on one will
          take you to the page builder where you can add, edit, and rearrange sections to create
          engaging landing pages.
        </Typography>
        <DefaultSearchSortFilter
          buttonDisabled={false}
          buttonText={'New Page'}
          onClickButton={() => setCreateDialogOpen(true)}
          setSearch={(value: string) => setSearch(value)}
          setSort={setSort}
          sort={sort}
          showFilter={false}
          sortOptions={[
            { value: 'title', label: 'Alphabetical' },
            { value: 'slug', label: 'Page Slug' },
          ]}
        />
        <GridV2
          items={items || []}
          type={SearchContentTypeEnum.page}
          isLoading={isLoading}
          isAdmin={true}
        />

        <DialogAriaWrapper
          open={createDialogOpen}
          onClose={() => cancelCreatePage()}
          id='create_new_page'
        >
          <Box>
            <Typography variant='h6' sx={{ mt: 3, ml: 3, mb: 2 }}>
              Create a new library item
            </Typography>
          </Box>
          <DialogContent>
            <Box>
              <TextField
                name='name'
                variant='filled'
                label='name'
                value={newPageName}
                onChange={(e) => handleNameChange(e.target.value)}
                InputProps={{ disableUnderline: true }}
                sx={{ mr: 1 }}
              />
              <TextField
                name='slug'
                variant='filled'
                label='slug'
                value={newPageSlug}
                onChange={(e) => handleSlugChange(e.target.value)}
                InputProps={{ disableUnderline: true }}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => cancelCreatePage()}>Cancel</Button>
            <LoadingButton
              loading={isSaving}
              onClick={() => handleCreatePage()}
              variant='contained'
            >
              Create
            </LoadingButton>
          </DialogActions>
        </DialogAriaWrapper>
      </Card>
    </Container>
  );
};

export default LibraryAdmin;
