import React from 'react';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { NavigationItem as NavigationItemModel } from '@juno/client-api/model';
import { NavigationMap } from '@juno/client-api/utils';
import { JUNO_LOGO_URL } from '@juno/constants';
import {
  BaseLinkText,
  DrawerWrapper,
  JunoBranding,
  JunoBrandingLink,
  JunoVersion,
  LinkIcon,
  Logo,
  ProfileAvatar,
  ProfileButtonText,
  ProfileContainer,
  ProfileName,
  ProfileTextBlock,
  StyledAccordion,
  StyledAccordionDetailText,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from './styles';

enum LinkType {
  PAGE = 1,
  LINK = 2,
  INTEGRATION = 3,
}

const getLink = (url: string | undefined) => {
  if (!url) return '';
  return url.startsWith('http') ? url : `http://${url}`;
};

const getLocalPath = (url: string | undefined) => {
  return `/${url?.replace(/^\/+/g, '')}`;
};

const ProfileButton: React.FC<{ name: string }> = ({ name }) => (
  <ProfileContainer>
    <ProfileAvatar alt='Anonymous' />
    <ProfileTextBlock>
      <ProfileName>{name}</ProfileName>
      <ProfileButtonText>Edit Profile</ProfileButtonText>
    </ProfileTextBlock>
  </ProfileContainer>
);

interface AccordionItemProps {
  navigationMap: NavigationMap;
  item: NavigationItemModel;
  onSelect: (item: NavigationItemModel) => void;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ item, onSelect, navigationMap }) => {
  const hasItems = (navigationMap.parentIdChildNavigationMap?.[item.id]?.length || 0) > 0;
  const hasIcon = !!item.icon;
  const iconDisplay = hasItems ? 'inline-block' : 'none';
  return (
    <StyledAccordion disableGutters>
      <StyledAccordionSummary expandIcon={<ExpandMoreIcon sx={{ display: iconDisplay }} />}>
        {hasIcon && <LinkIcon className={item.icon} />}
        <BaseLinkText
          className='main-nav-title'
          onClick={(event) => {
            event.stopPropagation();
            onSelect(item);
          }}
        >
          {item.text}
        </BaseLinkText>
      </StyledAccordionSummary>
      {hasItems && (
        <StyledAccordionDetails>
          {navigationMap.parentIdChildNavigationMap?.[item.id]?.map((subItem) => (
            <StyledAccordionDetailText
              key={subItem.id}
              onClick={(event) => {
                event.stopPropagation();
                onSelect(subItem);
              }}
            >
              {subItem.text}
            </StyledAccordionDetailText>
          ))}
        </StyledAccordionDetails>
      )}
    </StyledAccordion>
  );
};

const Branding: React.FC<{ version: string }> = ({ version }) => (
  <JunoBranding>
    <Logo src={JUNO_LOGO_URL} alt='logo' />
    <JunoBrandingLink href='https://www.junolive.com/' target='_blank'>
      Powered by Juno
    </JunoBrandingLink>
    <JunoVersion>[{version}]</JunoVersion>
  </JunoBranding>
);

interface DrawerNavProps {
  open: boolean;
  onClose: () => void;
  navigationMap: NavigationMap;
}

const DrawerNav: React.FC<DrawerNavProps> = ({ open, onClose, navigationMap }) => {
  const navigate = useNavigate();
  const handleSelectLink = (selectedItem: NavigationItemModel) => {
    // if ([LinkType.PAGE, LinkType.INTEGRATION].includes(selectedItem.type)) {
    //   setTimeout(() => {
    //     navigate(getLocalPath(selectedItem.link));
    //   }, 300);
    // } else {
    //   window.open(getLink(selectedItem.link), '_blank');
    // }
    onClose();
  };
  return (
    <DrawerWrapper
      role='navigation'
      variant='temporary'
      open={open}
      onClose={onClose}
      SlideProps={{ easing: 'ease-in-out' }}
      transitionDuration={300}
      width={280}
    >
      <ProfileButton name='Test User' />
      {navigationMap.sideNavItems.map((item) => (
        <AccordionItem
          key={item.id}
          item={item}
          onSelect={handleSelectLink}
          navigationMap={navigationMap}
        />
      ))}
      <Branding version='0.0.0' />
    </DrawerWrapper>
  );
};

export default DrawerNav;
