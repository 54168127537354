import { Dispatch, SetStateAction, forwardRef, useEffect, useState } from 'react';
import { Avatar, Box, ClickAwayListener, Popover, Popper, Skeleton } from '@mui/material';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { getUser } from '@juno/client-api';
import { JunoUser } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import { customShadow } from '../../index';

interface UserQuickViewProps {
  anchorEl: HTMLElement | null;
  setAnchorEl?: Dispatch<SetStateAction<HTMLElement | null>>;
  userId: string;
  open: boolean;
  platformId?: string;
  onViewProfile: (userId: string) => void;
  onMessageUser: (userId: string) => void;
  dialogRef?: React.RefObject<HTMLDivElement>;
}

const UserQuickView = forwardRef<any, UserQuickViewProps>((props, ref) => {
  const { anchorEl, userId, open, dialogRef, onViewProfile, onMessageUser, setAnchorEl } = props;
  const { platform } = useSettings();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<JunoUser>({} as JunoUser);

  const fetchUserDetails = async () => {
    if (!platform) return;
    setLoading(true);
    const userFetch = await getUser(platform.id, userId);
    setUser(userFetch);
    setLoading(false);
  };

  useEffect(() => {
    if (anchorEl) {
      fetchUserDetails();
    }
  }, [anchorEl, dialogRef]);

  return (
    //

    <Popover
      id='user-quick-view'
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={() => {
        setAnchorEl && setAnchorEl(null);
      }}
      disableScrollLock
      sx={{ boxShadow: 0, '.MuiPopover-paper': { boxShadow: 5, borderRadius: 2 } }}
    >
      <Paper
        sx={{
          padding: '16px',
        }}
      >
        {loading ? (
          <>
            <Skeleton variant='circular' width={40} height={40} />
            <Skeleton variant='text' width={140} height={40} />
            <Skeleton variant='text' width={180} height={20} />
            <Skeleton variant='text' width={180} height={20} />
            <Skeleton variant='text' width={180} height={20} />
          </>
        ) : (
          <Box width={180}>
            <Avatar
              src={user.avatar || user.icon || ''}
              alt='User Avatar'
              sx={{ width: '40px', height: '40px', marginBottom: '8px' }}
            />
            <Typography variant='subtitle1'>{`${user.first_name} ${user.last_name}`}</Typography>
            <Typography variant='body2'>{user.company ?? 'Company'}</Typography>
            <Typography variant='caption' display='block'>
              {user.email}
            </Typography>
            <Button
              variant='contained'
              color='primary'
              size='small'
              sx={{ width: '150px', textTransform: 'none', mt: 2 }}
              onClick={(e) => {
                e.stopPropagation();
                onViewProfile(user.id);
              }}
            >
              View Profile
            </Button>
            {/* // TODO - message user */}
            {/* <Button variant='text' color='primary' size='small' sx={{ textTransform: 'none' }} onClick={()=>onMessageUser(userId)}>
                Message User
                </Button> */}
          </Box>
        )}
      </Paper>
    </Popover>
  );
});

export default UserQuickView;
