import React, { Dispatch, SetStateAction } from 'react';
import { MenuItem, TextField } from '@mui/material';
import { useGetCourses } from '@juno/client-api';
import { TabItemObject } from '../ContentGrid';

interface CourseSortProps {
  setSortTemporary: Dispatch<SetStateAction<string>>;
  currentTab: TabItemObject | undefined;
  values: any;
  settings: any;
}

export type SortOption = {
  label: string;
  value: string;
  order: string;
};

type SortOptions = {
  [key: string]: SortOption;
};

export const SortOptions: SortOptions = {
  course_name: {
    label: 'Alphabetical',
    value: 'Alphabetical',
    order: 'title',
  },
  newest: {
    label: 'Newest',
    value: 'Newest',
    order: '-date_released',
  },
  start_date: {
    label: 'Start Date',
    value: 'Start Date',
    order: '-date_start',
  },
  most_popular: {
    label: 'Most Popular',
    value: 'most_popular',
    order: '',
  },
};

export const CourseSort: React.FC<CourseSortProps> = ({
  settings,
  values,
  currentTab,
  setSortTemporary,
}) => (
  <TextField
    select
    sx={{ width: 300 }}
    SelectProps={{ sx: { p: '4px' } }}
    size='small'
    value={values || ''}
    onChange={(e) => {
      if (currentTab) {
        currentTab.temporarySort = e.target.value as string;
        setSortTemporary(e.target.value as string);
      }
    }}
  >
    {settings.showSortCourseName && (
      <MenuItem value={SortOptions.course_name.value}>{SortOptions.course_name.label}</MenuItem>
    )}
    {settings.showSortNewest && (
      <MenuItem value={SortOptions.newest.value}>{SortOptions.newest.label}</MenuItem>
    )}
    {settings.showSortStartDate && (
      <MenuItem value={SortOptions.start_date.value}>{SortOptions.start_date.label}</MenuItem>
    )}
    {settings.showSortMostPopular && (
      <MenuItem value={SortOptions.most_popular.value}>{SortOptions.most_popular.label}</MenuItem>
    )}
  </TextField>
);

export const useCoursesHook = (
  siteId: string,
  settings: any,
  dataModel: string,
  filter: any,
  filterStr: string,
  debouncedSearch = '',
  sortTemporaryOrder: any = null,
  courseSort: any = null,
  courseFilter: any = {},
) => {
  const { data: courses, isLoading: coursesLoading } = useGetCourses(
    siteId,
    {
      order: sortTemporaryOrder
        ? sortTemporaryOrder
        : settings?.order
        ? settings.order
        : courseSort?.order,
      filter: { ...filter, ...courseFilter, title__icontains: debouncedSearch },
      include: 'course_complete_percentage,products,restrict_access',
      // TODO: backend is not ready for popular (will convene with Steveo when JL-9711 gets reprioritized)
      // popular: courseSort?.value === 'most_popular' ? 'most_popular' : undefined,
      exclude:
        settings?.excludeTags && settings.excludeTags.length > 0
          ? { tags__id__in: settings.excludeTags }
          : undefined,
      filter_or:
        settings?.optionalTags && settings.optionalTags.length > 0
          ? { tags__id__in: settings.optionalTags }
          : undefined,
      filter_and:
        settings?.requireTags && settings.requireTags.length > 0
          ? { tags__id__in: settings.requireTags }
          : undefined,
      filter_and_or: settings?.filter_and_or ? settings.filter_and_or : undefined,
    },
    { query: { enabled: dataModel === 'Course' && !filterStr.includes('{{USER_ID}}') } },
  );

  return {
    coursesLoading,
    courses,
  };
};

export const setDefaultCourseFilters = (existingFilter: { [key: string]: string | boolean }) => {
  const now = new Date().toISOString();
  if (!('date_released__lte' in existingFilter)) {
    existingFilter['date_released__lte'] = now;
  }
  if (!('date_end__gte' in existingFilter)) {
    existingFilter['date_end__gte'] = now;
  }
};
