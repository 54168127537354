import { Settings as SettingsIcon } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FETIconButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  display: 'block',
  width: '90px',
  height: '90px',
  marginLeft: '20px',
  transition: '200ms',
  svg: {
    transition: '200ms',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    boxShadow: '2px 2px 4px rgba(86,86,86,.35)',
    transform: 'scale(1.05)',
    svg: {
      transform: 'rotate(180deg)',
    },
  },
}));

export const FETIcon = styled(SettingsIcon)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  lineHeight: '20px',
}));

export const FETLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));
