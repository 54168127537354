import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const SaveBarWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDirty',
})<{
  isDirty: boolean;
}>(({ isDirty, theme }) => ({
  position: 'fixed',
  top: theme.mixins.toolbar.minHeight,
  marginTop: 25,
  right: 70,
  padding: '15px 10px',
  gap: 10,
  borderRadius: 8,
  height: 50,
  zIndex: 5,
  transition: '100ms all',
  display: isDirty ? 'flex' : 'none',
  justifyContent: 'space-between',
  alignItems: 'center',
  minWidth: 250,
  backgroundColor: theme.palette.background.paper,
}));
