import React, { useEffect, useState } from 'react';
import {
  EditNoteOutlined,
  LocationOnOutlined,
  MailOutlined,
  PhoneOutlined,
} from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SaveIcon from '@mui/icons-material/Save';
import XIcon from '@mui/icons-material/X';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDebounce } from 'usehooks-ts';
import { useGetUser, useMapboxApi } from '@juno/client-api';
import { JunoUser } from '@juno/client-api/model';
import { FeatureConfig as FeatureConfigUtils } from '@juno/client-api/utils';
import { FEATURE_FLAG_KEYS_ENUM } from '@juno/constants';
import { CreatePrivateMeetingDialog } from '@juno/ui';
import { useBreakpoint, useMessengerContext, useSettings } from '@juno/utils';
import { AnimatedIconButton } from './AnimatedIconButton';
import { useUserProfile, useUserProfileUpdate } from './UserProfileContext';

const CHARACTER_LIMITS = {
  firstName: 30,
  lastName: 30,
  title: 60,
  email: 128,
  company: 128,
  phone: 32,
  headline: 256,
  linkedIn: 256,
  twitter: 256,
  facebook: 256,
  instagram: 256,
  location_name: 256,
  geo_latitude: 24,
  geo_longitude: 24,
};

type ProfileField =
  | 'firstName'
  | 'lastName'
  | 'title'
  | 'email'
  | 'company'
  | 'phone'
  | 'headline'
  | 'linkedIn'
  | 'twitter'
  | 'facebook'
  | 'instagram';

type ErrorMessages = {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  company: string;
  phone: string;
  headline: string;
  linkedIn: string;
  twitter: string;
  facebook: string;
  instagram: string;
};

type initialBasicInfo = {
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  company: string;
  phone: string;
  headline: string;
  linkedIn: string;
  twitter: string;
  facebook: string;
  instagram: string;
  location_name: string;
  geo_latitude: string;
  geo_longitude: string;
};

export const BasicInfo = () => {
  const userProfile = useUserProfile();
  const updateUserProfile = useUserProfileUpdate();
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();
  const {
    isSmallScreen,
    configs,
    setShowMessenger,
    showMessenger,
    user: currentUser,
    site,
    currentUserSettings,
  } = useSettings();
  const { createChannelThenSubscribe, setMessengerTab, setIsConversation, setCurrentChannel } =
    useMessengerContext();
  const { xs } = useBreakpoint();
  const [editedProfile, setEditedProfile] = useState({
    firstName: userProfile?.firstName || '',
    lastName: userProfile?.lastName || '',
    title: userProfile?.title || '',
    email: userProfile?.email || '',
    company: userProfile?.company || '',
    phone: userProfile?.phone || '',
    headline: userProfile?.headline || '',
    linkedIn: userProfile?.linkedIn || '',
    twitter: userProfile?.twitter || '',
    facebook: userProfile?.facebook || '',
    instagram: userProfile?.instagram || '',
    location_name: userProfile?.location_name || '',
    geo_latitude: userProfile?.geo_latitude || '',
    geo_longitude: userProfile?.geo_longitude || '',
  });

  useEffect(() => {
    setIsEditing(false);
  }, [userProfile?.id]);

  const handleIconClick = () => {
    if (isEditing) {
      updateUserProfile({
        ...userProfile,
        ...editedProfile,
      });
    }
    setIsEditing(!isEditing);
  };

  const handleChange =
    (field: keyof initialBasicInfo) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      // Enforce the character limit
      if (newValue.length <= CHARACTER_LIMITS[field]) {
        setEditedProfile((prev: initialBasicInfo) => {
          // Ensure 'prev' is recognized as the previous state
          const newProfile = {
            ...prev,
            [field]: newValue,
          };
          setIsEdited(
            Object.keys(newProfile).some(
              (key) =>
                newProfile[key as keyof initialBasicInfo] !==
                initialInfo[key as keyof initialBasicInfo],
            ),
          );
          return newProfile;
        });
        // Clear any error message for this field
        setErrorMessages((prev: ErrorMessages) => ({
          // Ensure 'prev' is recognized as the previous state
          ...prev,
          [field]: '',
        }));
      } else {
        // Set the error message for this field
        setErrorMessages((prev: ErrorMessages) => ({
          // Ensure 'prev' is recognized as the previous state
          ...prev,
          [field]: `Must be ${CHARACTER_LIMITS[field]} characters or less`,
        }));
      }
      // If the field is linkedIn, twitter, facebook, or instagram, ensure the value starts with 'https://'
      if (['linkedIn', 'twitter', 'facebook', 'instagram'].includes(field)) {
        if (newValue && !newValue.startsWith('https://')) {
          setErrorMessages((prev: ErrorMessages) => ({
            // Ensure 'prev' is recognized as the previous state
            ...prev,
            [field]: 'Please enter a valid URL starting with https://',
          }));
        }
      }
    };

  const handleCancelEdit = () => {
    // Reset editedProfile to initialInfo
    setEditedProfile(initialInfo);
    // Exit edit mode
    setIsEditing(false);
    setIsEdited(false); // Reset isEdited state
  };

  // State for error messages
  const [errorMessages, setErrorMessages] = useState({
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    company: '',
    phone: '',
    headline: '',
    linkedIn: '',
    twitter: '',
    facebook: '',
    instagram: '',
  });

  // State to track focus of each field
  const [isFieldFocused, setIsFieldFocused] = useState({
    firstName: false,
    lastName: false,
    title: false,
    email: false,
    company: false,
    phone: false,
    headline: false,
    linkedIn: false,
    twitter: false,
    facebook: false,
    instagram: false,
  });

  const handleFocus = (field: ProfileField) => {
    setIsFieldFocused((prev) => ({
      ...prev,
      [field]: true,
    }));
  };

  const handleBlur = (field: ProfileField) => {
    setIsFieldFocused((prev) => ({
      ...prev,
      [field]: false,
    }));
  };

  const [initialInfo, setInitialInfo] = useState<initialBasicInfo>({
    firstName: userProfile?.firstName || '',
    lastName: userProfile?.lastName || '',
    title: userProfile?.title || '',
    email: userProfile?.email || '',
    company: userProfile?.company || '',
    phone: userProfile?.phone || '',
    headline: userProfile?.headline || '',
    linkedIn: userProfile?.linkedIn || '',
    twitter: userProfile?.twitter || '',
    facebook: userProfile?.facebook || '',
    instagram: userProfile?.instagram || '',
    location_name: userProfile?.location_name || '',
    geo_latitude: userProfile?.geo_latitude || '',
    geo_longitude: userProfile?.geo_longitude || '',
  });

  const [isEdited, setIsEdited] = useState(false);

  useEffect(() => {
    // Update initialInfo only if userProfile data changes
    setInitialInfo({
      firstName: userProfile?.firstName || '',
      lastName: userProfile?.lastName || '',
      title: userProfile?.title || '',
      email: userProfile?.email || '',
      company: userProfile?.company || '',
      phone: userProfile?.phone || '',
      headline: userProfile?.headline || '',
      linkedIn: userProfile?.linkedIn || '',
      twitter: userProfile?.twitter || '',
      facebook: userProfile?.facebook || '',
      instagram: userProfile?.instagram || '',
      location_name: userProfile?.location_name || '',
      geo_latitude: userProfile?.geo_latitude || '',
      geo_longitude: userProfile?.geo_longitude || '',
    });
  }, [userProfile]);

  const [locationSearchInput, setLocationSearchInput] = React.useState<string>('');
  const debouncedSearchValue = useDebounce(locationSearchInput, 200);

  const messengerDisabled = FeatureConfigUtils.getThemeConfig(configs)?.config?.hide_messenger;
  const editableProfileFieldsEnabled = FeatureConfigUtils.getFeatureFlag(
    configs,
    FEATURE_FLAG_KEYS_ENUM.UserEditableFields,
  );

  const [newMeetingOpen, setNewMeetingOpen] = useState(false);

  useEffect(() => {
    setLocationSearchInput(editedProfile.location_name);
  }, [editedProfile.location_name]);

  const { data: locationOptions, error: locationError } = useMapboxApi(debouncedSearchValue, {});

  const startChat = (otherUserId: string) => {
    if (!currentUser) return;
    setShowMessenger && setShowMessenger(true);
    createChannelThenSubscribe([otherUserId, currentUser?.id]).then((channelId) => {
      if (channelId) {
        setMessengerTab(0);
        setCurrentChannel(channelId);
        setIsConversation(true);
      }
    });
  };

  const { data: junoUser, refetch: refetchUser } = useGetUser(
    site?.platform_id || '',
    userProfile.id,
  );

  const DISABLED_FIELD_MSG =
    'This field is disabled because the values come from an external identity source';

  const saveDisabled = !Object.values(errorMessages).every((value) => value === '');

  return (
    <Box>
      {userProfile.isMe && (
        <Alert severity='warning' sx={{ mt: 7 }}>
          All profile information entered on this platform is considered public. Please exercise
          caution and discretion when entering personal details.
        </Alert>
      )}
      <Stack direction='row' justifyContent='space-between' alignItems='flex-start'>
        <Stack
          direction='column'
          sx={{
            paddingLeft: isSmallScreen || isEditing ? 0 : 23, // Apply paddingLeft 5 on small screens
            paddingTop: isEditing ? 8 : 0,
          }}
        >
          {/* Edit Icon at the top right (only when not editing) */}
          {userProfile.isMe && !isEditing && (
            <Box sx={{ position: 'absolute', top: 0, right: 0, display: 'flex' }}>
              <AnimatedIconButton onClick={handleIconClick} size='large' animate={isEdited}>
                <EditNoteOutlined color='disabled' />
              </AnimatedIconButton>
            </Box>
          )}

          {userProfile.isMe && isEditing && (
            <Box sx={{ position: 'absolute', top: 0, right: 0, display: 'flex' }}>
              <IconButton onClick={handleCancelEdit} size='large'>
                <CancelIcon sx={{ color: theme.palette.error.main }} />
              </IconButton>
            </Box>
          )}

          {isEditing ? (
            <Box sx={{ width: '90%', mt: 2 }}>
              {/* Grid container for First Name and Last Name */}
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Tooltip
                    title={editableProfileFieldsEnabled ? '' : DISABLED_FIELD_MSG}
                    arrow
                    placement='top-start'
                    enterDelay={300}
                  >
                    <TextField
                      label='First Name'
                      value={editedProfile.firstName}
                      onChange={handleChange('firstName')}
                      onFocus={() => handleFocus('firstName')}
                      onBlur={() => handleBlur('firstName')}
                      size='small'
                      fullWidth
                      helperText={
                        isFieldFocused.firstName
                          ? `${editedProfile.firstName.length}/${CHARACTER_LIMITS.firstName} ${errorMessages.firstName}`
                          : ' '
                      }
                      FormHelperTextProps={{ error: !!errorMessages.firstName }}
                      disabled={!editableProfileFieldsEnabled}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip
                    title={editableProfileFieldsEnabled ? '' : DISABLED_FIELD_MSG}
                    arrow
                    placement='top-start'
                    enterDelay={300}
                  >
                    <TextField
                      label='Last Name'
                      value={editedProfile.lastName}
                      onChange={handleChange('lastName')}
                      onFocus={() => handleFocus('lastName')}
                      onBlur={() => handleBlur('lastName')}
                      size='small'
                      fullWidth
                      helperText={
                        isFieldFocused.lastName
                          ? `${editedProfile.lastName.length}/${CHARACTER_LIMITS.lastName} ${errorMessages.lastName}`
                          : ' '
                      }
                      FormHelperTextProps={{ error: !!errorMessages.lastName }}
                      disabled={!editableProfileFieldsEnabled}
                    />
                  </Tooltip>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Title'
                    value={editedProfile.title}
                    onChange={handleChange('title')}
                    onFocus={() => handleFocus('title')}
                    onBlur={() => handleBlur('title')}
                    size='small'
                    fullWidth
                    helperText={
                      isFieldFocused.title
                        ? `${editedProfile.title.length}/${CHARACTER_LIMITS.title} ${errorMessages.title}`
                        : ' '
                    }
                    FormHelperTextProps={{ error: !!errorMessages.title }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Tooltip
                    title={editableProfileFieldsEnabled ? '' : DISABLED_FIELD_MSG}
                    arrow
                    placement='top-start'
                    enterDelay={300}
                  >
                    <TextField
                      label='Company/School'
                      value={editedProfile.company}
                      onChange={handleChange('company')}
                      onFocus={() => handleFocus('company')}
                      onBlur={() => handleBlur('company')}
                      size='small'
                      fullWidth
                      helperText={
                        isFieldFocused.company
                          ? `${editedProfile.company.length}/${CHARACTER_LIMITS.company} ${errorMessages.company}`
                          : ' '
                      }
                      FormHelperTextProps={{ error: !!errorMessages.company }}
                      disabled={!editableProfileFieldsEnabled}
                    />
                  </Tooltip>
                </Grid>
              </Grid>

              <TextField
                label='Headline'
                value={editedProfile.headline}
                onChange={handleChange('headline')}
                onFocus={() => handleFocus('headline')}
                onBlur={() => handleBlur('headline')}
                size='small'
                fullWidth
                multiline
                rows={3}
                helperText={
                  isFieldFocused.headline
                    ? `${editedProfile.headline.length}/${CHARACTER_LIMITS.headline} ${errorMessages.headline}`
                    : ' '
                }
                FormHelperTextProps={{ error: !!errorMessages.headline }}
              />

              {/* Social Links */}
              <TextField
                label='LinkedIn'
                value={editedProfile.linkedIn}
                onChange={handleChange('linkedIn')}
                onFocus={() => handleFocus('linkedIn')}
                onBlur={() => handleBlur('linkedIn')}
                size='small'
                fullWidth
                helperText={
                  isFieldFocused.linkedIn
                    ? `${editedProfile.linkedIn.length}/${CHARACTER_LIMITS.linkedIn} ${errorMessages.linkedIn}`
                    : ' '
                }
                FormHelperTextProps={{ error: !!errorMessages.linkedIn }}
              />

              <TextField
                label='X / Twitter'
                value={editedProfile.twitter}
                onChange={handleChange('twitter')}
                onFocus={() => handleFocus('twitter')}
                onBlur={() => handleBlur('twitter')}
                size='small'
                fullWidth
                helperText={
                  isFieldFocused.twitter
                    ? `${editedProfile.twitter.length}/${CHARACTER_LIMITS.twitter} ${errorMessages.twitter}`
                    : ' '
                }
                FormHelperTextProps={{ error: !!errorMessages.twitter }}
              />

              <TextField
                label='Facebook'
                value={editedProfile.facebook}
                onChange={handleChange('facebook')}
                onFocus={() => handleFocus('facebook')}
                onBlur={() => handleBlur('facebook')}
                size='small'
                fullWidth
                helperText={
                  isFieldFocused.facebook
                    ? `${editedProfile.facebook.length}/${CHARACTER_LIMITS.facebook} ${errorMessages.facebook}`
                    : ' '
                }
                FormHelperTextProps={{ error: !!errorMessages.facebook }}
              />

              <TextField
                label='Instagram'
                value={editedProfile.instagram}
                onChange={handleChange('instagram')}
                onFocus={() => handleFocus('instagram')}
                onBlur={() => handleBlur('instagram')}
                size='small'
                fullWidth
                helperText={
                  isFieldFocused.instagram
                    ? `${editedProfile.instagram.length}/${CHARACTER_LIMITS.instagram} ${errorMessages.instagram}`
                    : ' '
                }
                FormHelperTextProps={{ error: !!errorMessages.instagram }}
              />

              <TextField
                label='Phone Number'
                value={editedProfile.phone}
                onChange={handleChange('phone')}
                onFocus={() => handleFocus('phone')}
                onBlur={() => handleBlur('phone')}
                size='small'
                fullWidth
                helperText={
                  isFieldFocused.phone
                    ? `${editedProfile.phone.length}/${CHARACTER_LIMITS.phone} ${errorMessages.phone}`
                    : ' '
                }
                FormHelperTextProps={{ error: !!errorMessages.phone }}
              />
              <Autocomplete
                freeSolo
                options={locationOptions ? locationOptions.map((l: any) => l.place_name) : []}
                onInputChange={(event, newInputValue) => {
                  setEditedProfile({
                    ...editedProfile,
                    location_name: newInputValue,
                    geo_latitude: '0.0',
                    geo_longitude: '0.0',
                  });
                }}
                onChange={(event, value) => {
                  // Find the selected option in locationOptions
                  const selectedOption = locationOptions?.find(
                    (option: any) => option.place_name === value,
                  );
                  if (selectedOption) {
                    // Update the editedProfile with the new location_name, geo_latitude, and geo_longitude
                    setEditedProfile({
                      ...editedProfile,
                      location_name: selectedOption.place_name,
                      geo_latitude: selectedOption.center[1].toString().slice(0, 15),
                      geo_longitude: selectedOption.center[0].toString().slice(0, 15),
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label='Location' margin='normal' variant='outlined' />
                )}
                value={editedProfile.location_name}
              />
            </Box>
          ) : (
            <>
              <Stack alignItems='center' direction='row' pt={0}>
                <Typography variant='h5' sx={{ fontWeight: '600', mr: 1 }}>
                  {userProfile.firstName} {userProfile.lastName}
                </Typography>
                {userProfile.settings.private_profile && <LockOutlinedIcon />}
              </Stack>
              <Typography variant='subtitle2'>
                {userProfile.title}
                {userProfile.title && userProfile.company ? ' at ' : ''}
                {userProfile.company}
              </Typography>
              {(!userProfile.settings.private_profile || userProfile.isMe) && (
                <Typography variant='subtitle2' sx={{ mt: 1, mb: 5, mr: 2 }}>
                  {userProfile.headline}
                </Typography>
              )}
              {!userProfile.settings.private_messaging && (
                <Stack direction={xs ? 'column' : 'row'} spacing={1}>
                  {!messengerDisabled && !currentUserSettings?.private_messaging && (
                    <Button
                      variant='outlined'
                      sx={{ mr: 2, background: 'rgba(0,0,0,.04)' }}
                      disabled={userProfile.isMe}
                      onClick={() => {
                        startChat(userProfile.id);
                      }}
                    >
                      Message
                    </Button>
                  )}

                  <Button
                    variant='outlined'
                    sx={{ mr: 2, background: 'rgba(0,0,0,.04)' }}
                    disabled={userProfile.isMe || userProfile.settings.private_messaging}
                    onClick={() => {
                      setNewMeetingOpen(true);
                    }}
                  >
                    Schedule
                  </Button>
                </Stack>
              )}
            </>
          )}
        </Stack>
        {(!userProfile.settings.private_profile || userProfile.isMe) && (
          <Stack direction='column' sx={{ alignSelf: 'end', mb: 0 }}>
            {!isEditing && userProfile.email && (
              <Stack alignItems='center' direction='row' pt={0}>
                <MailOutlined fontSize='small' sx={{ mr: 1 }} />
                <Typography
                  variant='subtitle2'
                  sx={{ opacity: 1, color: theme.palette.primary.main }}
                >
                  {userProfile.email}
                </Typography>
              </Stack>
            )}
            {!isEditing && userProfile.phone && (
              <Stack alignItems='center' direction='row' pt={0}>
                <PhoneOutlined fontSize='small' sx={{ mr: 1 }} />
                <Typography variant='subtitle2' sx={{ opacity: 1 }}>
                  {userProfile.phone}
                </Typography>
              </Stack>
            )}
            <Stack alignItems='center' direction='row' pt={0}>
              <LocationOnOutlined fontSize='small' sx={{ mr: 1 }} />
              <Typography variant='subtitle2'>{editedProfile.location_name}</Typography>
            </Stack>

            {!isEditing && (
              <Box>
                <Stack direction={'row'} spacing={1} mt={1}>
                  {editedProfile.instagram && (
                    <Link href={editedProfile.instagram} target='_blank' rel='noopener noreferrer'>
                      <InstagramIcon fontSize={'medium'} />
                    </Link>
                  )}
                  {editedProfile.facebook && (
                    <Link href={editedProfile.facebook} target='_blank' rel='noopener noreferrer'>
                      <FacebookIcon fontSize='medium' />
                    </Link>
                  )}
                  {editedProfile.twitter && (
                    <Link href={editedProfile.twitter} target='_blank' rel='noopener noreferrer'>
                      <XIcon fontSize={'medium'} />
                    </Link>
                  )}
                  {editedProfile.linkedIn && (
                    <Link href={editedProfile.linkedIn} target='_blank' rel='noopener noreferrer'>
                      <LinkedInIcon fontSize={'medium'} />
                    </Link>
                  )}
                </Stack>
              </Box>
            )}
          </Stack>
        )}
      </Stack>
      {/* Save and Cancel Edit Buttons at the bottom middle (only when editing) */}
      {isEditing && userProfile.isMe && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <AnimatedIconButton
            disabled={saveDisabled}
            onClick={handleIconClick}
            size='large'
            animate={isEdited && !saveDisabled}
          >
            <SaveIcon color={isEdited && !saveDisabled ? 'primary' : 'inherit'} />
          </AnimatedIconButton>
        </Box>
      )}
      <CreatePrivateMeetingDialog
        open={newMeetingOpen}
        handleClose={() => setNewMeetingOpen(false)}
        selectedUser={junoUser as JunoUser | null}
      />
    </Box>
  );
};
