import { useContext, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { useQueryClient } from 'react-query';
import { getGetCertificateTemplatesQueryKey, useCreateCertificateTemplate } from '@juno/client-api';
import { MutationAction, onMutation } from '@juno/utils';
import { SiteIdContext } from '..';
import { CertificateTypeMap } from '../utils/enums';
import { validationSchema, valueMaker } from '../utils/validation';

interface NewCertificateDialogProps {
  open: boolean;
  onClose: () => void;
  onNav: (id: string) => void;
}

const NewCertificateDialog: React.FC<NewCertificateDialogProps> = ({ open, onClose, onNav }) => {
  //TODO do something with loading states
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();
  const siteId = useContext(SiteIdContext);

  // Mutations
  const refetchCertificates = () =>
    queryClient.invalidateQueries(getGetCertificateTemplatesQueryKey(siteId));
  const createCertificate = useCreateCertificateTemplate(
    onMutation(MutationAction.CREATE, 'certificateTemplate', refetchCertificates),
  );

  const formik = useFormik({
    initialValues: valueMaker(siteId),
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      createCertificate
        .mutateAsync(
          { siteId, data: values },
          {
            onSuccess: (certificate) => {
              formik.resetForm({ values: valueMaker(siteId) });
              setIsLoading(false);
              onClose();
              onNav(certificate.id || '');
            },
          },
        )
        .catch((e) => {
          console.error(e);
        });
    },
  });

  const handleClick = () => {
    formik.handleSubmit();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>New Certificate</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          id='name'
          name='name'
          label='Certificate Title'
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          onBlur={formik.handleBlur}
          variant='outlined'
          sx={{ mb: 2, mt: 1 }}
        />
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>Certificate Type</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={formik.values.metadata?.type}
            label='Certificate Type'
            onChange={formik.handleChange}
            name='metadata.type'
            defaultValue={formik.values.metadata?.type}
          >
            {Object.entries(CertificateTypeMap).map(([key, value], index) => (
              <MenuItem key={key} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick} color='primary' variant='contained' fullWidth>
          Create Certificate
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewCertificateDialog;
