export interface AnalyticsCategory {
  id: string;
  title: string;
}

export const ANALYTICS_DASHBOARD_CATEGORIES = {
  // Examine Your Data
  USER_OVERVIEW: { id: 'user_overview', title: 'User Overview' },
  EVENT_ACTIVITY: { id: 'event_activity', title: 'Event Activity' },
  PARTNER_INTERACTION: { id: 'partner_interaction', title: 'Partner Interaction' },
  COMMUNITY_PARTICIPATION: { id: 'community_participation', title: 'Community Participation' },
  LEARNING_ENGAGEMENT: { id: 'learning_engagement', title: 'Learning Engagement' },
  NETWORKING_ENGAGEMENT: { id: 'networking_engagement', title: 'Networking Activity' },
  ECOMMERCE_ACTIVITY: { id: 'ecommerce_activity', title: 'E-Commerce' },

  // Happening Now
  USERS_IN_LIVE_SESSIONS: { id: 'users_in_live_sessions_widget', title: 'Users in Live Sessions' },
  USERS_ON_LIBRARY_PAGE: { id: 'users_on_library_page_widget', title: 'Users on a Library Page' },
  USERS_ON_COURSE_PAGE: { id: 'users_on_course_page_widget', title: 'Users on a Course Page' },
  USERS_ON_COMMUNITY_PAGE: {
    id: 'users_on_community_page_widget',
    title: 'Users on a Community Page',
  },
};
