import React, { useState } from 'react';
import {
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { FormikProps } from 'formik';
import { DayOfWeekEnum as DOW, FrequencyEnum as frequencyOptions } from '@juno/client-api/model';
import { GenericFormikInput } from '@juno/ui';
import { ordinalDatePartValues } from '@juno/utils';
import TimePick from '../../utils/TimePick';
import { CampaignScheduleSectionFormikValues } from '../../utils/validationSchema';

interface ScheduleLogicProps {
  formik: FormikProps<CampaignScheduleSectionFormikValues>;
  updateCampaignScheduleFormikValues: React.Dispatch<
    React.SetStateAction<CampaignScheduleSectionFormikValues>
  >;
  is_active: boolean;
}

const ScheduleLogic: React.FC<ScheduleLogicProps> = ({
  formik,
  updateCampaignScheduleFormikValues,
  is_active,
}) => {
  const switchFrequency = () => {
    switch (formik.values.schedule.frequency) {
      case frequencyOptions.DAILY:
        return (
          <DailySchedulePicker
            formik={formik}
            updateCampaignScheduleFormikValues={updateCampaignScheduleFormikValues}
            is_active={is_active}
          />
        );
      case frequencyOptions.WEEKLY:
        return (
          <WeeklySchedulePicker
            formik={formik}
            updateCampaignScheduleFormikValues={updateCampaignScheduleFormikValues}
            is_active={is_active}
          />
        );
      case frequencyOptions.MONTHLY:
        return (
          <MonthlySchedulePicker
            formik={formik}
            updateCampaignScheduleFormikValues={updateCampaignScheduleFormikValues}
            is_active={is_active}
          />
        );
      default:
        return <></>;
    }
  };

  const showDividers =
    formik.values.schedule.frequency !== frequencyOptions.AUTOMATIC &&
    formik.values.schedule.frequency !== frequencyOptions.ONE_TIME;
  return (
    <>
      {showDividers && <Divider sx={{ mt: 4 }} />}
      {switchFrequency()}
      {showDividers && <Divider sx={{ mt: 4 }} />}
    </>
  );
};

export default ScheduleLogic;

interface SchedulePickerProps {
  formik: FormikProps<CampaignScheduleSectionFormikValues>;
  updateCampaignScheduleFormikValues: React.Dispatch<
    React.SetStateAction<CampaignScheduleSectionFormikValues>
  >;
  is_active: boolean;
}

const DailySchedulePicker: React.FC<SchedulePickerProps> = ({
  formik,
  updateCampaignScheduleFormikValues,
  is_active,
}) => {
  return (
    <Stack direction='row' justifyContent='center' alignItems='center' sx={{ mt: 4 }}>
      <Typography>Send every</Typography>
      <GenericFormikInput
        label='Num'
        type='text'
        name='schedule.day_offset'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          formik.setFieldTouched('schedule.day_offset', true);
          formik.handleChange(event);
          updateCampaignScheduleFormikValues({
            ...formik.values,
            schedule: {
              ...formik.values.schedule,
              day_offset: +event.target.value,
            },
          });
        }}
        fullWidth={false}
        sx={{
          maxWidth:
            formik.touched.schedule?.day_offset && formik.errors.schedule?.day_offset
              ? '175px'
              : '60px',
          mt:
            formik.touched.schedule?.day_offset && formik.errors.schedule?.day_offset
              ? 4
              : undefined,
          ml: 2,
          mr: 2,
        }}
        error={formik.touched.schedule?.day_offset && formik.errors.schedule?.day_offset}
        disabled={is_active}
      />
      <Typography sx={{ mr: 2 }}>days at&nbsp;</Typography>
      <TimePick
        formik={formik}
        updateCampaignScheduleFormikValues={updateCampaignScheduleFormikValues}
        disabled={is_active}
        id='schedule.time'
        initialValue={formik.values.schedule.time || ''}
      />
    </Stack>
  );
};

const WeeklySchedulePicker: React.FC<SchedulePickerProps> = ({
  formik,
  updateCampaignScheduleFormikValues,
  is_active,
}) => {
  return (
    <Stack direction='row' justifyContent='center' alignItems='center' sx={{ mt: 4 }}>
      <Typography sx={{ mr: 2 }}>Send every</Typography>
      <FormControl variant='filled' sx={{ width: '130px', ml: 1, mr: 1 }}>
        <InputLabel id='day-of-week-field-label'>Day</InputLabel>
        <Select
          id='schedule.day_of_week'
          {...formik.getFieldProps('schedule.day_of_week')}
          error={
            formik.touched.schedule?.day_of_week && Boolean(formik.errors.schedule?.day_of_week)
          }
          onChange={(event: SelectChangeEvent) => {
            updateCampaignScheduleFormikValues({
              ...formik.values,
              schedule: {
                ...formik.values.schedule,
                day_of_week: event.target.value as DOW,
              },
            });
            formik.handleChange(event);
          }}
          disabled={is_active}
        >
          {Object.entries(DOW).map(([key, value], index) => {
            if (key === DOW.Null) {
              return null;
            } else {
              return (
                <MenuItem key={index} value={key}>
                  {value}
                </MenuItem>
              );
            }
          })}
        </Select>
        <FormHelperText error>
          {formik.touched.schedule?.day_of_week && Boolean(formik.errors.schedule?.day_of_week)}
        </FormHelperText>
      </FormControl>
      <Typography sx={{ mr: 2, ml: 2 }}>at&nbsp;</Typography>
      <TimePick
        formik={formik}
        updateCampaignScheduleFormikValues={updateCampaignScheduleFormikValues}
        disabled={is_active}
        id='schedule.time'
        initialValue={formik.values.schedule.time || ''}
      />
    </Stack>
  );
};

const MonthlySchedulePicker: React.FC<SchedulePickerProps> = ({
  formik,
  updateCampaignScheduleFormikValues,
  is_active,
}) => {
  // Variables
  const [isSendDatePart, setIsSendDatePart] = useState<boolean>(
    formik.values.schedule?.date_part === null,
  );

  // Functions
  const handleToggle = () => {
    // Toggle 1
    if (!isSendDatePart) {
      formik.setFieldValue('schedule.date_part', null);
      updateCampaignScheduleFormikValues({
        ...formik.values,
        schedule: {
          ...formik.values.schedule,
          date_part: null,
        },
      });
      // Toggle 2
    } else {
      formik.setFieldValue('schedule.week_offset', null);
      formik.setFieldValue('schedule.day_of_week', DOW.Null);
      updateCampaignScheduleFormikValues({
        ...formik.values,
        schedule: {
          ...formik.values.schedule,
          week_offset: null,
          day_of_week: DOW.Null,
        },
      });
    }

    formik.setFieldValue('schedule.time', '');
    updateCampaignScheduleFormikValues({
      ...formik.values,
      schedule: {
        ...formik.values.schedule,
        time: null,
      },
    });
  };

  return (
    <>
      <Stack direction='row' alignItems='center' justifyContent='center' mb={3} mt={4}>
        <Radio
          checked={isSendDatePart}
          onChange={() => {
            handleToggle();
            setIsSendDatePart((prev) => !prev);
          }}
        />
        <Typography sx={{ mr: 2 }}>Send on the</Typography>
        <FormControl variant='filled' sx={{ width: 100 }}>
          <InputLabel id='week-offset-field-label'>Week</InputLabel>
          <Select
            name='schedule.week_offset'
            value={
              (formik.values.schedule.week_offset && String(formik.values.schedule.week_offset)) ||
              ''
            }
            onChange={(event: SelectChangeEvent) => {
              formik.setFieldTouched('schedule.week_offset', true);
              formik.handleChange(event);
              updateCampaignScheduleFormikValues({
                ...formik.values,
                schedule: {
                  ...formik.values.schedule,
                  week_offset: +event.target.value,
                },
              });
            }}
            disabled={!isSendDatePart || is_active}
            error={Boolean(
              formik.touched.schedule?.week_offset && formik.errors.schedule?.week_offset,
            )}
            onBlur={formik.handleBlur}
          >
            <MenuItem value={1}>1st</MenuItem>
            <MenuItem value={2}>2nd</MenuItem>
            <MenuItem value={3}>3rd</MenuItem>
            <MenuItem value={4}>4th</MenuItem>
          </Select>
          <FormHelperText error>
            {formik.touched.schedule?.week_offset && formik.errors.schedule?.week_offset}
          </FormHelperText>
        </FormControl>
        <FormControl variant='filled' sx={{ width: '130px', ml: 3, mr: 1 }}>
          <InputLabel id='day-of-week-field-label'>Day</InputLabel>
          <Select
            name='schedule.day_of_week'
            value={formik.values.schedule.day_of_week}
            onChange={(event: SelectChangeEvent) => {
              formik.setFieldTouched('schedule.day_of_week', true);
              formik.handleChange(event);
              updateCampaignScheduleFormikValues({
                ...formik.values,
                schedule: {
                  ...formik.values.schedule,
                  day_of_week: event.target.value as DOW,
                },
              });
            }}
            disabled={!isSendDatePart || is_active}
            error={
              formik.touched.schedule?.day_of_week && Boolean(formik.errors.schedule?.day_of_week)
            }
            onBlur={formik.handleBlur}
          >
            {Object.entries(DOW).map(([key, value], index) => {
              if (key === DOW.Null) {
                return null;
              } else {
                return (
                  <MenuItem key={index} value={key}>
                    {value}
                  </MenuItem>
                );
              }
            })}
          </Select>
          <FormHelperText error>
            {formik.touched.schedule?.day_of_week && formik.errors.schedule?.day_of_week}
          </FormHelperText>
        </FormControl>
        <Typography sx={{ ml: 2, mr: 2 }}>of the month at&nbsp;</Typography>
        {isSendDatePart && (
          <TimePick
            formik={formik}
            updateCampaignScheduleFormikValues={updateCampaignScheduleFormikValues}
            disabled={is_active}
            id='schedule.time'
            initialValue={formik.values.schedule.time || ''}
          />
        )}
        {!isSendDatePart && (
          <TimePick
            formik={formik}
            updateCampaignScheduleFormikValues={updateCampaignScheduleFormikValues}
            disabled={true}
            id='schedule.time'
            initialValue={''}
          />
        )}
      </Stack>
      <Stack direction='row' alignItems='center' justifyContent='center' mb={3} mt={4}>
        <Radio
          checked={!isSendDatePart}
          onChange={() => {
            handleToggle();
            setIsSendDatePart((prev) => !prev);
          }}
        />
        <Typography sx={{ ml: 2, mr: 2 }}>Send on the</Typography>
        <FormControl
          variant='filled'
          sx={{ ml: 1, mr: 1, width: 110 }}
          disabled={is_active || isSendDatePart}
        >
          <InputLabel id='date-part-field-label'>Date</InputLabel>
          <Select
            name='schedule.date_part'
            value={String(formik.values.schedule.date_part) || '-1'}
            onChange={(event: SelectChangeEvent) => {
              formik.setFieldTouched('schedule.date_part', true);
              formik.handleChange(event);
              updateCampaignScheduleFormikValues({
                ...formik.values,
                schedule: {
                  ...formik.values.schedule,
                  date_part: +event.target.value,
                },
              });
            }}
            disabled={isSendDatePart || is_active}
            error={
              formik.values.schedule.date_part === null &&
              formik.touched.schedule?.date_part &&
              Boolean(formik.errors.schedule?.date_part)
            }
            onBlur={formik.handleBlur}
          >
            {/* hard-coding only 28 days until Product demands "Last Day of Month" functionality */}
            {ordinalDatePartValues().map((option, i) => {
              if (i < 28) {
                return (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              }
            })}
          </Select>
          <FormHelperText error>
            {formik.values.schedule.date_part === null &&
              formik.touched.schedule?.date_part &&
              formik.errors.schedule?.date_part}
          </FormHelperText>
        </FormControl>
        <Typography sx={{ ml: 2, mr: 2 }}>of the month at&nbsp;</Typography>
        {!isSendDatePart && (
          <TimePick
            formik={formik}
            updateCampaignScheduleFormikValues={updateCampaignScheduleFormikValues}
            disabled={is_active}
            id='schedule.time'
            initialValue={formik.values.schedule.time || ''}
          />
        )}
        {isSendDatePart && (
          <TimePick
            formik={formik}
            updateCampaignScheduleFormikValues={updateCampaignScheduleFormikValues}
            disabled={true}
            id='schedule.time'
            initialValue={''}
          />
        )}
      </Stack>
    </>
  );
};
