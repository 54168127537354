import React, { useMemo, useRef, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import GifIcon from '@mui/icons-material/Gif';
import SendIcon from '@mui/icons-material/Send';
import { Box, Button, IconButton, InputBase, Paper, Typography } from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import GifPicker from 'gif-picker-react';
import { useNavigate } from 'react-router-dom';
import { JunoUser } from '@juno/client-api/model';
import { useMessengerContext, useSettings } from '@juno/utils';
import { usePubnubContext } from '../../Contexts/PubnubContext';
import { SendMessageParams, sendMessage } from '../Utils';
import { messengerTabMap } from '../index';

interface MessageInputProps {
  newUsers?: JunoUser[];
}

const MessageInput: React.FC<MessageInputProps> = ({ newUsers }) => {
  const [message, setMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showGifPicker, setShowGifPicker] = useState(false);
  const [iconsCollapsed, setIconsCollapsed] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { pubnub } = usePubnubContext();
  const {
    currentChannel,
    createChannelThenSubscribe,
    setMessengerTab,
    setCurrentChannel,
    setIsConversation,
    messengerMap,
    isConversation,
    setIsCreateNewConversation,
    setSelectedNewConversationUsers,
  } = useMessengerContext();

  const { user, currentUserSettings, site } = useSettings();
  const navigate = useNavigate();

  const handleSendMessage = (m: string, e?: React.KeyboardEvent) => {
    // Only proceed if there's a message to send
    if (!m.trim()) return;

    // If the Enter key is pressed, prevent the default form submission behavior
    if (e && e.key === 'Enter') e.preventDefault();

    const sendPubNubMessage = (channel?: string) => {
      // Common logic to send message
      const pubnubMessagePayload: SendMessageParams = {
        pubnub,
        channel: channel || currentChannel,
        message: m,
      };
      sendMessage(pubnubMessagePayload).then(() => setMessage(''));
    };

    if (newUsers && newUsers.length > 0 && user?.id) {
      // Create a new channel and then send the message
      createChannelThenSubscribe([user?.id, ...newUsers.map((junoUser) => junoUser.id)])
        .then((channelId) => {
          if (channelId) {
            setMessengerTab(messengerTabMap.Messenger);
            setCurrentChannel(channelId);
            setIsConversation(true);
            return channelId;
          }
        })
        .then((channelId) => {
          // Give things time to propagate before sending the message
          setTimeout(() => sendPubNubMessage(channelId), 2000);
        });
    } else {
      // Send message in the current channel
      sendPubNubMessage();
    }
    setSelectedNewConversationUsers([]);
  };

  const allUsersMessagingEnabled = useMemo(() => {
    const channel = messengerMap.channels.find((channel) => channel.id === currentChannel);

    if (!channel) {
      return false;
    }

    return channel.junoUsers.every((junoUser) => junoUser?.private_messaging === false);
  }, [messengerMap, currentChannel]);

  const numUsersInChannel = useMemo(() => {
    const channel = messengerMap.channels.find((channel) => channel.id === currentChannel);

    if (!channel) {
      return 0;
    }

    return channel.junoUsers.length;
  }, [messengerMap, currentChannel]);

  const usersWithoutPrivateMessaging = useMemo(() => {
    const channel = messengerMap.channels.find((channel) => channel.id === currentChannel);

    if (!channel) {
      return [];
    }

    return channel.junoUsers.filter(
      (junoUser) => junoUser?.private_messaging === false && junoUser.id !== user?.id,
    );
  }, [messengerMap, currentChannel]);

  if (currentUserSettings?.private_messaging === true) {
    return (
      <Box
        sx={{
          backgroundColor: '#e0e0e0',
          mx: 2,
          mb: 2,
          py: 2,
          px: 2,
          borderRadius: 2,
          position: 'relative',
        }}
      >
        <Typography variant={'body2'} fontWeight={'bold'}>
          Attention: Privacy Enabled
        </Typography>
        <Typography variant={'body2'}>You have disabled messages.</Typography>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 2,
          }}
        >
          <Button
            variant={'contained'}
            size={'small'}
            onClick={() => navigate(`/${site?.slug}/user/${user?.id || ''}/settings`)}
          >
            Open Settings
          </Button>
        </Box>
      </Box>
    );
  } else if (!isConversation || (allUsersMessagingEnabled && isConversation)) {
    return (
      <Box sx={{ mt: 2, position: 'relative' }}>
        {showEmojiPicker && (
          <Box sx={{ position: 'absolute', bottom: '100%', width: '100%', zIndex: 1000 }}>
            <EmojiPicker
              width={'100%'}
              onEmojiClick={(emoji, event) => {
                setMessage(message + emoji.emoji);
                setShowEmojiPicker(false);
                setIconsCollapsed(true);
              }}
            />
          </Box>
        )}
        {showGifPicker && (
          <Box sx={{ position: 'absolute', bottom: '100%', width: '100%', zIndex: 1000 }}>
            <GifPicker
              width={'100%'}
              tenorApiKey={'AIzaSyCRqoNgPXNknd3_CJOYvYo81Z-pMdFB-KY'}
              onGifClick={(gifUrl: any) => {
                handleSendMessage(gifUrl.url);
                setShowGifPicker(false);
                setIconsCollapsed(true);
              }}
            />
          </Box>
        )}

        <Paper component='form' sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}>
          {iconsCollapsed ? (
            <IconButton
              sx={{ p: '10px' }}
              aria-label='expand'
              onClick={() => setIconsCollapsed(false)}
            >
              <ArrowForwardIosIcon fontSize={'small'} />
            </IconButton>
          ) : (
            <>
              <IconButton
                sx={{
                  pl: '10px',
                  py: '10px',
                  pr: '10px',
                  '&:hover': {
                    color: 'primary.main',
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                  },
                }}
                aria-label='emoji'
                onClick={() => {
                  setShowGifPicker(false);
                  setShowEmojiPicker((old) => !old);
                }}
              >
                <EmojiEmotionsIcon />
              </IconButton>
              <IconButton
                sx={{
                  p: '10px',
                  '&:hover': {
                    color: 'primary.main',
                    boxShadow: 'none',
                    backgroundColor: 'transparent',
                  },
                }}
                aria-label='gif'
                onClick={() => {
                  setShowEmojiPicker(false);
                  setShowGifPicker(!showGifPicker);
                  setIconsCollapsed(true);
                }}
              >
                <GifIcon fontSize={'large'} />
              </IconButton>
            </>
          )}
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder='Type message'
            multiline={true}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onFocus={() => {
              setIconsCollapsed(true);
              setShowGifPicker(false);
              setShowEmojiPicker(false);
            }}
            onBlur={() => message.length === 0 && setIconsCollapsed(false)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage(message, e);
                setIconsCollapsed(false);
              }
            }}
            inputRef={inputRef}
          />

          <IconButton
            sx={{ p: '10px' }}
            aria-label='send'
            onClick={(e) => {
              handleSendMessage(message);
            }}
            disabled={message.trim() === ''}
          >
            <SendIcon />
          </IconButton>
        </Paper>
      </Box>
    );
  } else if (numUsersInChannel > 2) {
    return (
      <Box
        sx={{
          backgroundColor: '#e0e0e0',
          mx: 2,
          mb: 2,
          py: 2,
          px: 2,
          borderRadius: 2,
          position: 'relative',
        }}
      >
        <Typography variant={'body2'} fontWeight={'bold'}>
          Attention: Privacy Enabled
        </Typography>
        <Typography variant={'body2'}>
          A user in this chat has disabled messages. Click the button below to start a new chat with
          the remaining users.
        </Typography>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 2,
          }}
        >
          <Button
            variant={'contained'}
            size={'small'}
            onClick={() => {
              setIsCreateNewConversation(true);
              setIsConversation(false);
              setSelectedNewConversationUsers(usersWithoutPrivateMessaging);
            }}
          >
            New Chat
          </Button>
        </Box>
      </Box>
    );
  } else {
    // This is a 2 person chat
    return (
      <Box
        sx={{
          backgroundColor: '#e0e0e0',
          mx: 2,
          mb: 2,
          py: 2,
          px: 2,
          borderRadius: 2,
          position: 'relative',
        }}
      >
        <Typography variant={'body2'} fontWeight={'bold'}>
          Attention: Privacy Enabled
        </Typography>
        <Typography variant={'body2'}>
          This member is no longer accepting direct messages.
        </Typography>
      </Box>
    );
  }
};

export default MessageInput;
