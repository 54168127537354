import React from 'react';
import { Box } from '@mui/material';
import notification from '../notification';

export enum MutationAction {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

interface ErrorMessage {
  message: string;
  error: any;
}

const ErrorMessage: React.FC<ErrorMessage> = ({ message, error }) => {
  const defaultMessage = error?.message || message;
  const errorList = error?.errors || [];
  const tmpErrors = Object.keys(errorList).map((key) => {
    if (key === '__all__') return errorList[key][0];
    return `${key.charAt(0).toUpperCase() + key.slice(1)}: ${errorList[key][0]}`;
  });
  const errors = tmpErrors.length > 0 ? tmpErrors : [defaultMessage];

  return (
    <Box>
      {errors.map((err) => (
        <Box key={err}>{err}</Box>
      ))}
    </Box>
  );
};

/**
 * leave model blank if you don't want to show a notification
 */
export const onMutation = (
  action: MutationAction,
  model: string,
  invalidate: () => void = () => {},
  onErrorOnly?: boolean,
) => {
  const messageMap = {
    [MutationAction.CREATE]: { present: 'create', past: 'created' },
    [MutationAction.UPDATE]: { present: 'update', past: 'updated' },
    [MutationAction.DELETE]: { present: 'delete', past: 'deleted' },
  };
  return {
    mutation: {
      onSuccess: () => {
        invalidate();
        !onErrorOnly &&
          model !== '' &&
          notification.success(`${model} was successfully ${messageMap[action].past}`);
      },
      onError: (e: any) => {
        const genericMsg = `${model} failed to ${messageMap[action].present}.`;
        model !== '' &&
          notification.error(<ErrorMessage message={genericMsg} error={e.response?.data} />);
      },
    },
  };
};

export const slugify = (str: string, allowUpperCase = false): string => {
  // current slugify rules are only lower alphanumeric, dashes, underscores, and optional uppercase
  const slug = str
    .trim()
    .replace(/[^\w\s-_]/g, '') //regex to remove all non-alphanumeric, non-space, non-dash, non-underscore characters
    .replace(/[\s-]+/g, '_') //regex to replace spaces and dashes with underscores
    .replace(/[_]+/g, '_'); //regex to replace multiple underscores with single underscore
  if (!allowUpperCase) {
    return slug.toLowerCase();
  }
  return slug;
};

// accepts a width and cloudinary url of an image and returns the transformed cloudinary url
export const optimizeImage = (imageWidth: number, url: string | null | undefined) => {
  // TODO: maybe base this on mui breakpoints and add this to an image utils file
  const breakpoints = [300, 600, 960, 1280, 1920];
  const bpWidth = breakpoints.find((bp) => bp > imageWidth) || 1920;
  if (!url || typeof url !== 'string' || !url?.includes('https://res.cloudinary.com')) {
    return url || '';
  }
  // TODO: HACK ALERT, maybe find a better way to alter the string to get the optimized image
  const urlParts = url.split('/');
  const newStr = urlParts.filter((part) => !part.includes('c_scale')).join('/');
  return newStr?.replace('image/upload/', `image/upload/c_scale,w_${bpWidth}/`) || '';
};

export const readImageFile = (file: File, callback: (result: string) => void) => {
  if (!file || !FileReader) return;
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => callback(reader.result as string);
  reader.onerror = (error) => console.error('Error: ', error);
};

export const truncateString = (str: string | undefined, length: number) => {
  if (!str) return '';
  if (str.length <= length) return str;
  return `${str.substring(0, length)}...`;
};

export const prettyFilesize = (bytes: number, si = true, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};
