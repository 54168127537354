import React from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { DialogAriaWrapper } from '@juno/ui';

interface DeleteCampaignDialogProps {
  handleClose: () => void;
  handleDeleteCampaign: () => void;
}

const DeleteCampaignDialog: React.FC<DeleteCampaignDialogProps> = ({
  handleClose,
  handleDeleteCampaign,
}) => {
  return (
    <DialogAriaWrapper
      id={'deleteEmailCampaignDialog'}
      open={true}
      onClose={handleClose}
      maxWidth='xs'
    >
      <DialogTitle
        id={'deleteEmailCampaignDialog-dialog-title'}
        sx={{ pt: 4, pb: 4, alignSelf: 'left' }}
      >
        Delete Campaign?
      </DialogTitle>
      <DialogContent id={'deleteEmailCampaignDialog-dialog-description'}>
        <DialogContentText align='left'>
          <Typography sx={{ mb: 3 }}>
            Are you sure you want to permanently delete this campaign?
          </Typography>
          <Typography>
            All of the campaign details will be lost. This action cannot be undone.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between', p: 3 }}>
        <Button
          aria-label='Cancel'
          onClick={handleClose}
          sx={{ letterSpacing: '2px', color: 'black' }}
        >
          Cancel
        </Button>
        <Button aria-label='Delete' onClick={handleDeleteCampaign} sx={{ letterSpacing: '2px' }}>
          Delete Campaign
        </Button>
      </DialogActions>
    </DialogAriaWrapper>
  );
};

export default DeleteCampaignDialog;
