import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Divider, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { SimplePaletteColorOptions } from '@mui/material/styles';
import { useQueryClient } from 'react-query';
import { useDebounce, useEventListener } from 'usehooks-ts';
import {
  getGetFeatureConfigsPublicQueryKey,
  getGetFeatureConfigsQueryKey,
  useCreateFeatureConfig,
  useUpdateSiteFeatureConfig,
} from '@juno/client-api';
import { FeatureConfig as FeatureConfigModel, Site as SiteModel } from '@juno/client-api/model';
import { FeatureConfig } from '@juno/client-api/utils';
import { THEME_CONFIG_PRESETS, THEME_FONT_FAMILIES, ThemeConfigProps } from '@juno/constants';
import { MutationAction, onMutation } from '@juno/utils';
import ColorPicker from './ColorPicker';
import { SiteDesignContent, SiteDesignContentPanel } from './styles';

interface SiteDesignPanelProps {
  site: SiteModel;
  configs: FeatureConfigModel[] | undefined;
  setThemeConfig: (themeConfig: ThemeConfigProps) => void;
  themeConfig?: ThemeConfigProps;
}

const SiteDesignPanel: React.FC<SiteDesignPanelProps> = ({
  setThemeConfig,
  themeConfig,
  configs,
  site,
}) => {
  useEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      resetTheme();
    }
  });
  const queryClient = useQueryClient();
  const [payload, setPayload] = useState<ThemeConfigProps | undefined>(themeConfig);
  const [isSaving, setIsSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const debouncedPayload = useDebounce(payload, 300);
  const { CREATE, UPDATE } = MutationAction;
  const refetchConfigs = () => {
    const queryKey = getGetFeatureConfigsQueryKey(site.id);
    const publicQueryKey = getGetFeatureConfigsPublicQueryKey(site.id);
    queryClient.invalidateQueries(queryKey);
    queryClient.invalidateQueries(publicQueryKey);
  };

  const initialLoad = useRef(true);

  const configCreate = useCreateFeatureConfig(onMutation(CREATE, 'FeatureConfig', refetchConfigs));
  const configUpdate = useUpdateSiteFeatureConfig(
    onMutation(UPDATE, 'FeatureConfig', refetchConfigs),
  );

  // We need a default otherwise the theme will be undefined moving forward and new sites are unable to adjust themes.
  // Setting this in one place to avoid conditional logic scattered thru the theme code.
  const defaultTheme: ThemeConfigProps = {
    name: 'White',
    slug: 'white',
    typography: { fontFamily: 'Prompt, sans-serif' },
    palette: {
      primary: { main: '#11B6D1', contrastText: '#F1F4FB' },
      secondary: { main: '#0B326D', contrastText: '#F1F4FB' },
      background: { default: '#ffffff', paper: '#ffffff' },
      text: { primary: '#020813', secondary: '#162540' },
    },
  };

  useEffect(() => {
    if (!themeConfig) {
      setThemeConfig(defaultTheme);
    }
  }, [themeConfig, setThemeConfig]);

  useEffect(() => {
    if (!debouncedPayload) return;
    setThemeConfig(debouncedPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPayload]);

  useEffect(() => {
    setIsSaving(false);
    setIsDirty(false);
  }, [configs]);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
    } else {
      setIsDirty(true);
    }
  }, [themeConfig]);

  const handleSaveConfig = async () => {
    setIsSaving(true);
    const data = FeatureConfig.buildThemePayload(themeConfig, configs);
    try {
      if (data.id) {
        await configUpdate.mutateAsync({ siteId: site.id, configType: data.type, data });
      } else {
        await configCreate.mutateAsync({ siteId: site.id, data });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const resetTheme = () => {
    const restoredTheme = FeatureConfig.getThemeConfigTheme(configs);
    if (!restoredTheme) return;
    setThemeConfig(restoredTheme);
    setIsDirty(false);
  };

  const primary = payload?.palette?.primary as SimplePaletteColorOptions;
  const secondary = payload?.palette?.secondary as SimplePaletteColorOptions;
  const info = payload?.palette?.info as SimplePaletteColorOptions;

  const handleFontFamilyChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const fontFamily = target.value;
    if (!themeConfig) return;
    setPayload({ ...themeConfig, typography: { fontFamily } });
  };

  const handleChangeColor = (color: string, colorName: string) => {
    if (!themeConfig) return;
    const palette = themeConfig.palette;
    const [type, key] = colorName.split('.');
    const newConfig = {
      ...themeConfig,
      palette: {
        ...palette,
        [type]: { ...(palette[type as keyof typeof palette] as any), [key]: color },
      },
    };
    setPayload(newConfig);
  };

  const handleSiteTitleChange = (title: string) => {
    if (!themeConfig) return;
    setPayload({ ...themeConfig, siteTitle: title });
  };

  const siteTitleIsInvalid = (payload?.siteTitle || '').length > 60;

  return (
    <SiteDesignContent>
      <SiteDesignContentPanel>
        {/* <TextField
          select
          label='Theme Preset'
          value={themeConfig?.slug || ''}
          onChange={handlePresetChange}
          InputProps={{ disableUnderline: true }}
          variant='filled'
          size='small'
          disabled={isSaving}
        >
          {THEME_CONFIG_PRESETS.map((o) => (
            <MenuItem key={o.slug} value={o.slug}>
              {o.name}
            </MenuItem>
          ))}
        </TextField> */}

        <Grid container>
          <Grid item xs={6} pb={2}>
            <Typography variant='subtitle1'>Site Title</Typography>
            <Typography variant='body2'>
              The document title that appears in browser tabs for your site
            </Typography>
          </Grid>
          <Grid item xs={6} pb={2} justifyContent={'flex-end'} display={'flex'}>
            <TextField
              label='Site Title'
              value={payload?.siteTitle || ''}
              onChange={(e) => handleSiteTitleChange(e.target.value)}
              size='small'
              disabled={isSaving}
              fullWidth
              error={!!siteTitleIsInvalid}
              helperText={!!siteTitleIsInvalid && 'Site title must be 60 or less characters'}
              sx={{ width: 170 }}
            />
          </Grid>
          <Grid item xs={6} py={2}>
            <Typography variant='subtitle1'>Font</Typography>
            <Typography variant='body2'>
              Select the font for text that appears on the site
            </Typography>
          </Grid>
          <Grid item xs={6} py={2} justifyContent={'flex-end'} display={'flex'}>
            <TextField
              select
              label='Theme Font'
              value={themeConfig?.typography?.fontFamily || ''}
              onChange={handleFontFamilyChange}
              size='small'
              disabled={isSaving}
              fullWidth
              sx={{ width: 170 }}
            >
              {THEME_FONT_FAMILIES.map((o) => (
                <MenuItem key={o.id} value={o.value}>
                  {o.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={6} py={2}>
            <Typography variant='subtitle1'>Primary color</Typography>
            <Typography variant='body2'>
              Affects buttons, highlighted menu items, loading spinners, other accents and is
              available to use while building pages or library content
            </Typography>
          </Grid>
          <Grid item xs={6} py={2} justifyContent={'flex-end'} display={'flex'}>
            <ColorPicker
              label='Primary'
              color={primary?.main}
              colorName='primary.main'
              handleChange={handleChangeColor}
              disabled={isSaving}
            />
          </Grid>
          <Divider />
          <Grid item xs={6} py={2}>
            <Typography variant='subtitle1'>Secondary color</Typography>
            <Typography variant='body2'>
              Available to use while building pages or library content
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} py={2} justifyContent={'flex-end'} display={'flex'}>
            <ColorPicker
              label='Secondary'
              color={secondary?.main}
              colorName='secondary.main'
              handleChange={handleChangeColor}
              disabled={isSaving}
            />
          </Grid>
          <Grid item xs={6} py={2}>
            <Typography variant='subtitle1'>Accent color</Typography>
            <Typography variant='body2'>
              Available to use while building pages or library content
            </Typography>
          </Grid>
          <Grid item xs={6} py={2} justifyContent={'flex-end'} display={'flex'}>
            <ColorPicker
              label='Accent'
              color={info?.main}
              colorName='info.main'
              handleChange={handleChangeColor}
              disabled={isSaving}
            />
          </Grid>
        </Grid>
        <LoadingButton
          variant='contained'
          onClick={handleSaveConfig}
          loading={isSaving}
          disabled={!isDirty || !!siteTitleIsInvalid}
          sx={{ marginLeft: 'auto' }}
        >
          Save
        </LoadingButton>
      </SiteDesignContentPanel>
    </SiteDesignContent>
  );
};

export default SiteDesignPanel;
