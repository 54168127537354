import React, { forwardRef, useState } from 'react';
import {
  ArrowCircleDown as ArrowCircleDownIcon,
  Download as DownloadIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import {
  Course,
  CreateGeneratedReportParams,
  GeneratedReport,
  GeneratedReportTypeEnum,
} from '@juno/client-api/model';
import { EMPTY_COURSE_URL, JUNO_ROUTE_MAP } from '@juno/constants';
import { optimizeImage, useRouteNavigate } from '@juno/utils';
import { ROLE_MAP } from '../constants';
import {
  EllipsisTypography,
  StyledFormImage,
  TileCard,
  TileGridBanner,
  TileGridContainer,
  TileGridTextWrapper,
  TileWrapper,
} from '../styles';
import AsyncDownload from './AsyncDownload';

interface AddModulePopperProps {
  course: Course;
  siteId: string;
  isManager: boolean;
  userId?: string;
}

const NavPopper: React.FC<AddModulePopperProps> = ({ course, siteId, isManager, userId }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isDownloadingCourse, setIsDownloadingCourse] = useState(false);
  const learningNavigate = useRouteNavigate();

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const courseDetailsReportData: GeneratedReport = {
    type: GeneratedReportTypeEnum.learningcourse_details,
    site_id: siteId,
    user_id: userId || '',
    admin_id: null,
  };
  const courseDetailsReportParams: CreateGeneratedReportParams = {
    filter: {
      course_id__in: [course.id],
    },
    context: {
      admin_user_id: isManager ? userId : null,
    },
  };

  const handleSelectCourse = () => {
    learningNavigate(JUNO_ROUTE_MAP.CLIENT_COURSE, { courseSlug: course.slug });
    setOpen(false);
  };

  const handleDownloadCourseDetails = () => {
    if (!course) return;
    setOpen(false);
    setIsDownloadingCourse(true);
  };

  return (
    <>
      <IconButton onClick={handleToggle} disabled={isDownloadingCourse}>
        {!isDownloadingCourse && <MoreVertIcon />}
        {isDownloadingCourse && (
          <AsyncDownload
            siteId={siteId}
            data={courseDetailsReportData}
            params={courseDetailsReportParams}
            setIsDownloading={setIsDownloadingCourse}
          />
        )}
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='bottom-end'
        transition
        disablePortal
        sx={{ zIndex: 5 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-end' ? 'right top' : 'left bottom',
            }}
          >
            <Paper sx={{ border: '1px solid', borderColor: 'primary.main', borderRadius: '8px' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                  <Typography variant='body2' sx={{ fontWeight: 700, ml: 2 }}>
                    COURSE OPTIONS
                  </Typography>
                  <MenuItem sx={{ fontSize: '14px' }} onClick={handleSelectCourse}>
                    <ArrowCircleDownIcon sx={{ color: 'primary.main', mr: 1 }} />
                    Go to Course
                  </MenuItem>
                  <MenuItem sx={{ fontSize: '14px' }} onClick={handleDownloadCourseDetails}>
                    <DownloadIcon sx={{ color: 'primary.main', mr: 1 }} />
                    Download Course Details
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export interface CourseModel extends Course {
  manager_enrollments_count: string;
  manager_waitlist_count: string;
}

interface CourseTileProps {
  course: CourseModel;
  roleType: string;
  userId?: string;
  imageUrl?: string;
  idx: number;
  siteId: string;
}

const CourseTile = forwardRef<HTMLDivElement, CourseTileProps>(
  ({ course, roleType, imageUrl, idx, siteId, userId }, ref) => {
    const formatDateTime = (date: string | null | undefined) => {
      if (!date) return '';
      const dt = new Date(date);
      return format(dt, 'M/dd/yy h:mm a');
    };

    return (
      <TileWrapper ref={ref}>
        <TileGridContainer>
          <TileCard idx={idx}>
            <TileGridBanner m={1}>
              <StyledFormImage
                variant='rounded'
                src={optimizeImage(450, imageUrl || EMPTY_COURSE_URL)}
                alt='Course Banner'
              />
            </TileGridBanner>
            <TileGridTextWrapper>
              <EllipsisTypography variant='body1'>{course.title}</EllipsisTypography>
              <EllipsisTypography variant='body2'>
                {`(${formatDateTime(course.date_start)} - ${formatDateTime(course.date_end)})`}
              </EllipsisTypography>
            </TileGridTextWrapper>
            <Box sx={{ display: 'flex', flex: 1 }}></Box>
            <TileGridTextWrapper m={1}>
              <Box sx={{ display: 'flex' }}>
                <Box>
                  <EllipsisTypography variant='body2' color='primary'>
                    {ROLE_MAP.MANAGER.key === roleType
                      ? course.manager_enrollments_count
                      : course.enrollments_count}{' '}
                    Enrolled
                  </EllipsisTypography>
                  <EllipsisTypography variant='body2'>
                    {ROLE_MAP.MANAGER.key === roleType
                      ? course.manager_waitlist_count
                      : course.waitlist_count}{' '}
                    Waitlisted
                  </EllipsisTypography>
                </Box>
                <NavPopper
                  course={course}
                  siteId={siteId}
                  isManager={ROLE_MAP.MANAGER.key === roleType}
                  userId={userId}
                />
              </Box>
            </TileGridTextWrapper>
          </TileCard>
        </TileGridContainer>
      </TileWrapper>
    );
  },
);

export default CourseTile;
