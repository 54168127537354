import React, { useEffect, useState } from 'react';
import { useFeatureFlag } from 'configcat-react';
import {
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { JunoAnalytics } from '@juno/analytics';
import { AutomationTool, EmailCampaignBuilder, Emailer } from '@juno/automation';
import { useUpdateUser } from '@juno/client-api';
import { FeatureConfigTypeEnum, JunoUser, TabsOrderEnum } from '@juno/client-api/model';
import { CommunityGroupPanel, GroupPage } from '@juno/community';
import { ImportExport } from '@juno/import-export';
import { AdminCoursePanel, CoursePage, LessonPage } from '@juno/learning';
import { PageBuilder, ReportPreview, TagsPanel } from '@juno/modules';
import { SessionPage, SessionPanel } from '@juno/sessions';
import { FeatureNotAvailablePage, JunoSpin } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import AccessAdmin from './components/Admin/Access';
import CoursesAdmin from './components/Admin/Courses';
import DeveloperAdmin from './components/Admin/Dev';
import General from './components/Admin/General';
import GroupsAdmin from './components/Admin/Groups';
import LibraryAdmin from './components/Admin/Library';
import ModerationAdmin from './components/Admin/Moderation';
import NavigationAdmin from './components/Admin/Navigation';
import PagesAdmin from './components/Admin/Pages';
import SessionsAdmin from './components/Admin/Sessions';
import SpeakersAdmin from './components/Admin/Speakers';
import SpeakerAdmin from './components/Admin/Speakers/Speaker';
import UsersAdmin from './components/Admin/Users';
import Callback from './components/Callback';
import DynamicPage from './components/DynamicPage';
import Layout from './components/Layout';
import SpeakerPage from './components/SpeakerPage';
import TermsOfServiceDialog from './components/TermsOfServiceDialog';
import UserProfile from './components/UserProfile';
import { UserProfileProvider } from './components/UserProfile/UserProfileContext';
import UserSettings from './components/UserProfile/UserSettings';
import YourCourses from './components/YourCourses';
import YourSchedule from './components/YourSchedule';

const PrivateRoutes: React.FC = () => {
  const { configs, isAdmin, site, platform, user, enableLearning, enableLibrary } = useSettings();
  const { value: termsOfServiceValue, loading: termsOfServiceLoading } = useFeatureFlag(
    'termsOfService',
    false,
  );

  const [showTermsOfService, setShowTermsOfService] = useState(false);
  const termsOfServiceConfig = configs?.find(
    (c) => c.type === FeatureConfigTypeEnum.terms_of_service,
  );
  const updateUser = useUpdateUser(onMutation(MutationAction.UPDATE, ''));
  const onAccept = async () => {
    try {
      // force the user to accept the terms of service even if we error
      await updateUser.mutateAsync({
        platformId: platform?.id || '',
        userId: user?.id || '',
        data: {
          ...user,
          terms_accepted_timestamp: new Date().toISOString(),
        } as JunoUser,
      });
      setShowTermsOfService(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!user?.terms_accepted_timestamp && termsOfServiceConfig) {
      setShowTermsOfService(true);
    }
  }, [user, termsOfServiceConfig]);

  if (!configs || !site || !user || termsOfServiceLoading) return <JunoSpin />;

  // only show if configcat has it on
  if (termsOfServiceValue && user && showTermsOfService)
    return <TermsOfServiceDialog onAccept={onAccept} />;

  return (
    <Layout siteId={site.id}>
      <Routes>
        <Route path={'/:siteSlug/*'} element={<DynamicPage site={site} configs={configs} />} />
        <Route
          path={'/:siteSlug/library/*'}
          element={
            enableLibrary ? (
              <DynamicPage site={site} configs={configs} />
            ) : (
              <FeatureNotAvailablePage />
            )
          }
        />
        <Route path={'/:siteSlug/sessions/:sessionSlug'} element={<SessionPage />} />
        <Route path='/:siteSlug/sessions/:sessionSlug/edit' element={<SessionPanel />} />
        <Route
          path={'/:siteSlug/speakers/:slug'}
          element={<SpeakerPage site={site} configs={configs} />}
        />

        <Route
          path={'/:siteSlug/learning/courses/:courseSlug'}
          element={
            enableLearning ? (
              <CoursePage siteId={site.id} settings={{ user: user }} />
            ) : (
              <FeatureNotAvailablePage />
            )
          }
        />
        <Route
          path={'/:siteSlug/learning/courses/:courseSlug/:lessonSlug'}
          element={enableLearning ? <LessonPage siteId={site.id} /> : <FeatureNotAvailablePage />}
        />
        <Route
          path={'/:siteSlug/you/courses'}
          element={enableLearning ? <YourCourses /> : <FeatureNotAvailablePage />}
        />
        <Route
          path='/:siteSlug/community/edit_group/:groupSlug'
          element={<CommunityGroupPanel site={site} />}
        />
        {/* Routes for single group and tabs on group page */}
        <Route
          path='/:siteSlug/community/groups/:groupSlug/*'
          element={<GroupPage siteId={site.id} platformId={site.platform_id} />}
        >
          <Route
            path={TabsOrderEnum.about}
            element={<GroupPage siteId={site.id} platformId={site.platform_id} />}
          />
          <Route
            path={TabsOrderEnum.discussions}
            element={<GroupPage siteId={site.id} platformId={site.platform_id} />}
          />
          <Route
            path={TabsOrderEnum.feed}
            element={<GroupPage siteId={site.id} platformId={site.platform_id} />}
          />
          <Route
            path={TabsOrderEnum.resources}
            element={<GroupPage siteId={site.id} platformId={site.platform_id} />}
          />
          <Route
            path={TabsOrderEnum.meetings}
            element={<GroupPage siteId={site.id} platformId={site.platform_id} />}
          />
          <Route
            path={TabsOrderEnum.moderation}
            element={<GroupPage siteId={site.id} platformId={site.platform_id} />}
          />
        </Route>
        {/* --- */}
        <Route path={'/:siteSlug/user/:userId/settings'} element={<UserSettings />} />
        <Route
          path={'/:siteSlug/user/:userId'}
          element={
            <UserProfileProvider>
              <UserProfile />
            </UserProfileProvider>
          }
        />

        <Route path={'/:siteSlug/you/groups'} element={'your groups'} />
        <Route path={'/:siteSlug/you/schedule'} element={<YourSchedule />} />
        <Route path={'/:siteSlug/you/saved_content'} element={'your saved content'} />
        <Route path={'/:siteSlug/admin/sessions/:sessionSlug'} element={<SessionPanel />} />
        <Route path={'/:siteSlug/admin/sessions'} element={<SessionsAdmin />} />
        {isAdmin && (
          <>
            <Route
              path={'/:siteSlug/admin/learning/:courseSlug/:tabSlug'}
              element={
                enableLearning ? <AdminCoursePanel site={site} /> : <FeatureNotAvailablePage />
              }
            />
            <Route
              path={'/:siteSlug/admin/learning/:courseSlug/lessons/:lessonSlug'}
              element={
                enableLearning ? <AdminCoursePanel site={site} /> : <FeatureNotAvailablePage />
              }
            />
            <Route
              path={'/:siteSlug/admin/learning/:courseSlug'}
              element={
                enableLearning ? <AdminCoursePanel site={site} /> : <FeatureNotAvailablePage />
              }
            />
            <Route
              path={'/:siteSlug/admin/learning'}
              element={enableLearning ? <CoursesAdmin site={site} /> : <FeatureNotAvailablePage />}
            />

            <Route
              path={'/:siteSlug/admin/community/groups'}
              element={<GroupsAdmin site={site} />}
            />
            <Route
              path='/:siteSlug/admin/community/groups/:groupSlug'
              element={<CommunityGroupPanel site={site} />}
            />
            <Route path={'/:siteSlug/admin/speakers'} element={<SpeakersAdmin />} />
            <Route path={'/:siteSlug/admin/speakers/:slug'} element={<SpeakerAdmin />} />

            <Route path={'/:siteSlug/admin'} element={<General site={site} />} />
            {/* // TODO this is a duplicate but its not styled the same? However this one loads prerequisites... */}
            {/* <Route path={'/:siteSlug/admin/learning'} element={<AdminCoursesPanel site={site} />} /> */}
            <Route path={'/:siteSlug/admin/community'} element={<GroupsAdmin site={site} />} />
            <Route
              path={'/:siteSlug/admin/pages/:pageSlug'}
              element={<PageBuilder siteId={site.id} />}
            />
            <Route path={'/:siteSlug/admin/pages'} element={<PagesAdmin site={site} />} />
            <Route path={'/:siteSlug/admin/navigation'} element={<NavigationAdmin />} />
            <Route
              path={'/:siteSlug/admin/library'}
              element={enableLibrary ? <LibraryAdmin site={site} /> : <FeatureNotAvailablePage />}
            />
            <Route path={'/:siteSlug/admin/moderation'} element={<ModerationAdmin />} />
            <Route path={'/:siteSlug/admin/users'} element={<UsersAdmin />} />
            <Route path={'/:siteSlug/admin/tags'} element={<TagsPanel site={site} />} />
            <Route path={'/:siteSlug/admin/access/:id'} element={<AccessAdmin />} />
            <Route path={'/:siteSlug/admin/access'} element={<AccessAdmin />} />
            <Route
              path={'/:siteSlug/admin/analytics'}
              element={<JunoAnalytics isSuperAdmin={false} />}
            />
            <Route path={'/:siteSlug/admin/super_admin'} element={<DeveloperAdmin />} />
            <Route path={'/:siteSlug/report_preview'} element={<ReportPreview />} />
            <Route path={'/:siteSlug/admin/automation_tool'} element={<AutomationTool />} />
            <Route path={'/:siteSlug/admin/automation_tool/emailer'} element={<Emailer />} />
            <Route
              path={'/:siteSlug/admin/automation_tool/emailer/:id'}
              element={<EmailCampaignBuilder />}
            />
            <Route
              path={'/:siteSlug/admin/import-export/*'}
              element={<ImportExport site={site} />}
            />
          </>
        )}
      </Routes>
    </Layout>
  );
};

const JunoRoutesClient: React.FC = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={'/:siteSlug/callback'} element={<Callback />} />
        <Route path={'/*'} element={<PrivateRoutes />} />
      </>,
    ),
  );

  return <RouterProvider router={router} />;
};

export default JunoRoutesClient;
