import { useEffect, useMemo, useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useDebounce } from 'usehooks-ts';
import { useGetAllTagTypes } from '@juno/client-api';
import { TagType } from '@juno/client-api/model';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
interface AutocompleteInstructorsProps {
  label: string;
  instructions?: string;
  siteId: string;
  disabled?: boolean;
  onSelect?: (value: string[]) => void;
  values?: string[];
}

const AutocompleteTagTypes: React.FC<AutocompleteInstructorsProps> = ({
  label,
  instructions,
  siteId,
  disabled,
  onSelect,
  values,
}) => {
  const [selected, setSelected] = useState<TagType[] | undefined>();
  const [input, setInput] = useState<string>('');
  const debouncedSearchValue = useDebounce(input, 200);

  const searchFilter = useMemo(() => {
    if (!debouncedSearchValue) return undefined;
    return {
      value__icontains: debouncedSearchValue,
    };
  }, [debouncedSearchValue]);

  const { data: tagTypeData, isLoading } = useGetAllTagTypes(siteId);
  //   filter: searchFilter,
  //   order: 'value',
  //   limit: 5,
  //   offset: 0,
  // });

  useEffect(() => {
    if (!values || !tagTypeData) return;
    setSelected(tagTypeData?.filter((t) => values.includes(t.id)));
  }, [values, tagTypeData]);

  const handleSelect = (reason: string, value: TagType[] | undefined) => {
    setSelected(value);
    onSelect?.(value?.map((v) => v.id) || []);
  };

  const handleInputChange = (e: React.SyntheticEvent, newInputValue: string) => {
    if (!e) return;
    setInput(newInputValue);
  };

  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: TagType[] | undefined,
    reason: string,
  ) => {
    handleSelect(reason, value);
  };

  return (
    <>
      <Typography variant='body2'>{instructions}</Typography>
      <Autocomplete
        fullWidth
        disabled={disabled}
        disableCloseOnSelect
        multiple
        disablePortal
        limitTags={1}
        size='small'
        loading={isLoading}
        loadingText={<CircularProgress size={20} />}
        onChange={handleChange}
        getOptionLabel={(option) => option.value}
        filterOptions={(x) => x}
        options={tagTypeData || []}
        value={selected || []}
        onInputChange={handleInputChange}
        inputValue={input}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            variant='filled'
          />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{ right: 0 }}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.value}
          </li>
        )}
      />
    </>
  );
};

export default AutocompleteTagTypes;
