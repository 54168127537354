export interface AspectRatioProps {
  width: number;
  height: number;
  label: string;
  value: string;
  ratio: number;
}

export type AspectRatioType = '1_1' | '4_3' | '5_4' | '16_9' | '3_4' | '4_5' | '9_16' | '25_8';

export const ASPECT_RATIOS: { [id: string]: AspectRatioType } = {
  ONE_ONE: '1_1',
  FOUR_THREE: '4_3',
  FIVE_FOUR: '5_4',
  SIXTEEN_NINE: '16_9',
  THREE_FOUR: '3_4',
  FOUR_FIVE: '4_5',
  NINE_SIXTEEN: '9_16',
  TWENTY_FIVE_EIGHT: '25_8',
};

export const ASPECT_RATIO_MAP: { [id: string]: AspectRatioProps } = {
  [ASPECT_RATIOS.ONE_ONE]: { value: '1_1', label: '1:1', width: 1392, height: 1392, ratio: 1 },
  [ASPECT_RATIOS.FOUR_THREE]: {
    value: '4_3',
    label: '4:3',
    width: 1392,
    height: 1044,
    ratio: 4 / 3,
  },
  [ASPECT_RATIOS.FIVE_FOUR]: {
    value: '5_4',
    label: '5:4',
    width: 1392,
    height: 1114,
    ratio: 5 / 4,
  },
  [ASPECT_RATIOS.SIXTEEN_NINE]: {
    value: '16_9',
    label: '16:9',
    width: 1392,
    height: 783,
    ratio: 16 / 9,
  },
  [ASPECT_RATIOS.THREE_FOUR]: {
    value: '3_4',
    label: '3:4',
    width: 1145,
    height: 1527,
    ratio: 3 / 4,
  },
  [ASPECT_RATIOS.FOUR_FIVE]: {
    value: '4_5',
    label: '4:5',
    width: 1222,
    height: 1527,
    ratio: 4 / 5,
  },
  [ASPECT_RATIOS.NINE_SIXTEEN]: {
    value: '9_16',
    label: '9:16',
    width: 859,
    height: 1527,
    ratio: 9 / 16,
  },
  [ASPECT_RATIOS.TWENTY_FIVE_EIGHT]: {
    value: '25_8',
    label: '25:8',
    width: 1440,
    height: 461,
    ratio: 25 / 8,
  },
};
