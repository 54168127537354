import { Card, FormControl, List } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FormControlRow = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  gap: theme.spacing(1),
  flexDirection: 'row',
  justifyContent: 'space-evenly',
}));

export const TabsListWrapper = styled(List)(() => ({
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  padding: 0,
}));

export const TabsListItem = styled(Card)(() => ({
  display: 'flex',
  marginBottom: 8,
  padding: 8,
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '.module-icon': {
    display: 'inline-block',
  },
  '.drag-icon': {
    display: 'none',
  },
  '&:hover': {
    '.module-icon': {
      display: 'none',
    },
    '.drag-icon': {
      display: 'inline-block',
    },
  },
}));
