import * as React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Chip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { ImportArrayModel } from '../../../utils';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
export interface ImportAutocompleteModel {
  [k: string]: any;
  value: string;
  id: string;
  is_valid: boolean;
}

interface ImportAutocompleteProps {
  label: string;
  fieldValue: string;
  options: any[];
  value: ImportArrayModel[];
  onChangeAutoComplete: any;
}
const ImportAutocomplete: React.FC<ImportAutocompleteProps> = ({
  label,
  fieldValue,
  options,
  value,
  onChangeAutoComplete,
}) => {
  const badValue = value.filter((item) => item.is_valid === false);
  return (
    <>
      <Autocomplete
        sx={{ mt: 1, mb: 2 }}
        id={fieldValue}
        disableCloseOnSelect
        multiple
        freeSolo
        getOptionLabel={(option) => {
          return typeof option === 'string' ? option : (option.dom_value as string) || option.value;
        }}
        value={value}
        options={options}
        onChange={onChangeAutoComplete}
        renderOption={(
          props: JSX.IntrinsicAttributes &
            React.ClassAttributes<HTMLLIElement> &
            React.LiHTMLAttributes<HTMLLIElement>,
          option,
          { selected }: any,
        ) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {`${option.value}`}
          </li>
        )}
        renderTags={(tagList: ImportArrayModel[], getTagProps: any) => {
          return tagList.map((option: ImportArrayModel, index) => {
            const haveTag = options.filter((item) => {
              // TODO does item.value cover all models
              if (option.dom_value === item.value) {
                return true;
              }
              return false;
            });
            if (!option.dom_value) {
              return null;
            }
            return (
              <Chip
                key={`${option.id}_${Date.now()}`}
                {...getTagProps({ index })}
                color={haveTag.length > 0 ? 'default' : 'error'}
                label={<span>{option.dom_value as string}</span>}
              ></Chip>
            );
          });
        }}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            label={label}
            error={badValue.length > 0}
            helperText={
              badValue.length > 0 &&
              (badValue[0].errorMessage
                ? badValue[0].errorMessage
                : 'Highlighted items cannot be found in the system')
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: <>{params.InputProps?.endAdornment}</>,
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => {
          return option.value === value.value || option.value === value.dom_value;
        }}
      />
    </>
  );
};

export default ImportAutocomplete;
