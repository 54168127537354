import { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useCallState } from '../../../contexts/CallProvider';
import { useParticipants } from '../../../contexts/ParticipantsProvider';
import { useTracks } from '../../../contexts/TracksProvider';
import { useUIState } from '../../../contexts/UIStateProvider';
import { isScreenId } from '../../../contexts/participantsState';
import ParticipantBar from '../../ParticipantBar/ParticipantBar';
import Tile from '../../Tile';
import { ScreensAndPins } from './ScreensAndPins';

export const SpeakerView = () => {
  const { currentSpeaker, localParticipant, participants, screens } = useParticipants();
  const { updateCamSubscriptions } = useTracks();
  const { showLocalVideo } = useCallState();
  const { pinnedId } = useUIState();

  const screensAndPinned = useMemo(
    () => [...screens, ...participants.filter(({ id }) => id === pinnedId)],
    [participants, pinnedId, screens],
  );

  // TODO: This was removing the local user from the list in speaker view. Commenting out to make sure no downstream effects
  // const otherParticipants = useMemo(
  //   () => participants.filter(({ isLocal }) => !isLocal),
  //   [participants],
  // );
  const otherParticipants = participants;

  const showSidebar = useMemo(() => {
    const hasScreenshares = screens.length > 0;

    if (isScreenId(pinnedId)) {
      return false;
    }

    return participants.length > 1 || hasScreenshares;
  }, [participants, pinnedId, screens]);

  const screenShareTiles = useMemo(
    () => <ScreensAndPins items={screensAndPinned} />,
    [screensAndPinned],
  );

  const hasScreenshares = useMemo(() => screens.length > 0, [screens]);

  const fixedItems = useMemo(() => {
    const items = [];
    if (showLocalVideo) {
      items.push(localParticipant);
    }
    if (hasScreenshares && otherParticipants.length > 0) {
      items.push(otherParticipants[0]);
    }
    return items;
  }, [hasScreenshares, localParticipant, otherParticipants, showLocalVideo]);

  const otherItems = useMemo(() => {
    if (otherParticipants.length > 1) {
      return otherParticipants.slice(hasScreenshares ? 1 : 0);
    }
    return [];
  }, [hasScreenshares, otherParticipants]);

  /**
   * Update cam subscriptions, in case ParticipantBar is not shown.
   */
  useEffect(() => {
    // Sidebar takes care of cam subscriptions for all displayed participants.
    if (showSidebar) return;
    updateCamSubscriptions([currentSpeaker?.id, ...screensAndPinned.map((p) => p.id)]);
  }, [currentSpeaker, screensAndPinned, showSidebar, updateCamSubscriptions]);

  return (
    <>
      <Box
        sx={{
          height: 'calc(100% - 260px)',
        }}
      >
        {screensAndPinned.length > 0 ? (
          screenShareTiles
        ) : (
          <Tile participant={currentSpeaker} showActiveSpeaker={false} />
        )}
      </Box>
      {showSidebar && <ParticipantBar fixed={otherParticipants} others={otherItems} />}
    </>
  );
};

export default SpeakerView;
