import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { RecipientProps } from '../../utils/utils';

interface MailingListGridProps {
  mailingList: RecipientProps[];
  hasAll: boolean;
  fileName: string;
}

const MailingListGrid: React.FC<MailingListGridProps> = ({ mailingList, hasAll, fileName }) => {
  // Columns should not change between renders per mui datagrid docs
  const columns = useMemo(() => {
    const colDef: GridColDef[] = [
      {
        field: 'first_name',
        headerName: 'Recipient First Name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        flex: 1,
      },
      {
        field: 'last_name',
        headerName: 'Recipient Last Name',
        description: 'This column has a value getter and is not sortable.',
        sortable: false,
        flex: 1,
      },
      {
        field: 'email',
        headerName: 'Email Address',
        flex: 1,
      },
    ];
    return colDef;
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          csvOptions={{
            fileName: fileName,
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={mailingList}
          columns={columns}
          rowsPerPageOptions={[5, 20, 50, 100]}
          disableSelectionOnClick
          components={{ Toolbar: CustomToolbar }}
          getRowId={(e) => {
            return `${e.email}_${Date.now()}`;
          }}
        />
      </div>
    </Box>
  );
};

export default MailingListGrid;
