import { PreferenceTypeEnum } from '@juno/client-api/model';

export const notificationPreferences = [
  {
    type: PreferenceTypeEnum.NUMBER_1,
    label: '',
  },
  {
    type: PreferenceTypeEnum.NUMBER_2,
    label: '',
  },
  {
    type: PreferenceTypeEnum.NUMBER_3,
    label: 'New Discussion Topics',
    description:
      'Receive notifications when a new discussion topic is created in a group you are a member of.',
  },
  {
    type: PreferenceTypeEnum.NUMBER_4,
    label: 'Discussion Activity',
    description:
      'Receive notifications when someone adds a new post on a discussion topic you are following.',
  },
  {
    type: PreferenceTypeEnum.NUMBER_5,
    label: 'Feed Activity',
    description: 'Receive notifications when someone posts on a feed that you are a part of.',
  },
  {
    type: PreferenceTypeEnum.NUMBER_6,
    label: 'Mentions',
    description: 'Receive notifications when someone mentions you in a post or comment.',
  },
  {
    type: PreferenceTypeEnum.NUMBER_7,
    label: 'Responses',
    description:
      'Receive notifications when someone comments on your post or replies to your comments.',
  },
  {
    type: PreferenceTypeEnum.NUMBER_8,
    label: 'Likes',
    description: 'Receive notifications when someone likes your posts or comments.',
  },
  {
    type: PreferenceTypeEnum.NUMBER_9,
    label: 'Group Activity',
    description: 'Receive notifications when you are added/removed from a group.',
  },
];
