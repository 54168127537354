import { Avatar, Box, Drawer, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Canvas = styled('div', {
  shouldForwardProp: (prop) => prop !== 'backgroundColor' && prop !== 'editDrawerWidth',
})<{
  backgroundColor?: string;
  editDrawerWidth?: number;
}>(({ theme, backgroundColor, editDrawerWidth = 0 }) => ({
  position: 'relative',
  backgroundColor,
  marginLeft: editDrawerWidth,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  minHeight: `calc(100vh - ${theme.spacing(7)} - ${theme.spacing(3)} - ${theme.spacing(3)})`,
  ul: {
    padding: 0,
    listStyle: 'none',
  },
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  gridTemplateColumns: 'minmax(0, 1fr)',
}));

export const DrawerWrapper = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'width' })<{
  width: number;
}>(({ width }) => ({
  width,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width,
    boxSizing: 'border-box',
    overflow: 'auto',
    padding: '5px',
    height: '100%',
  },
}));

export const FormControlRow = styled(FormControl)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  gap: theme.spacing(1),
  flexDirection: 'row',
  justifyContent: 'space-evenly',
}));

export const PartRowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  position: 'relative',
  alignItems: 'center',
  marginTop: 25,
  paddingLeft: 0,
}));

export const StyledFormImage = styled(Avatar, { shouldForwardProp: (prop) => prop !== 'width' })<{
  width: number;
}>(({ width }) => ({
  width: '100%',
  height: (9 / 16) * width,
  objectFit: 'cover',
  overflow: 'hidden',
  margin: '30px 0px',
}));
