import GroupsIcon from '@mui/icons-material/Groups';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import SchoolIcon from '@mui/icons-material/School';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

export const FEATURE_CONFIG_THEME_TYPE = 'theme';
export const FEATURE_CONFIG_ECOMMERCE_TYPE = 'ecommerce';
export const FEATURE_CONFIG_AUTOMATION_TYPE = 'automation';
export const FEATURE_CONFIG_THEME_KEY = 'theme';
export enum FEATURE_FLAG_KEYS_ENUM {
  RecurringSessions = 'recurring_sessions',
  UserEditableFields = 'user_editable_fields',
  Learning = 'learning',
  Library = 'library',
  GZMVPFeatures = 'restrict_gz_mvp',
  AIAssist = 'ai_assist',
}
export const EMPTY_PROFILE_URL =
  'https://res.cloudinary.com/dxx7aeiow/image/upload/v1653243450/spmcord4iqrwfzmjr2qo.jpg';
export const EMPTY_COURSE_URL =
  'https://res.cloudinary.com/dxx7aeiow/image/upload/v1664651223/ftwzkyselxzxrhwtjxgj.jpg';
export const EMPTY_GROUP_URL =
  'https://res.cloudinary.com/dxx7aeiow/image/upload/v1703875355/ytfjl7zywugncl5qg1dq.jpg';
export const JUNO_LOGO_URL =
  'https://res.cloudinary.com/dxx7aeiow/image/upload/w_150/v1658369461/das-admin/2021_Icon_Nav_Logo_owheed.png';
export const JUNO_PLATFORM_URL =
  'https://res.cloudinary.com/dxx7aeiow/image/upload/v1658369461/das-admin/2021_Icon_Nav_Logo_owheed.png';
export const JUNO_FAVICON =
  'https://res.cloudinary.com/dxx7aeiow/image/upload/v1658535534/das-admin/favicon_kirtqf.ico';
export const JUNO_ADMIN_BG_URL =
  'https://res.cloudinary.com/dxx7aeiow/image/upload/e_blur:1200/v1657911827/das-admin/image_10_zyduef.jpg';
export const STATES = [
  { id: 'AL' },
  { id: 'AK' },
  { id: 'AZ' },
  { id: 'AR' },
  { id: 'CA' },
  { id: 'CO' },
  { id: 'CT' },
  { id: 'DE' },
  { id: 'DC' },
  { id: 'FL' },
  { id: 'GA' },
  { id: 'HI' },
  { id: 'ID' },
  { id: 'IL' },
  { id: 'IN' },
  { id: 'IA' },
  { id: 'KS' },
  { id: 'KY' },
  { id: 'LA' },
  { id: 'ME' },
  { id: 'MD' },
  { id: 'MA' },
  { id: 'MI' },
  { id: 'MN' },
  { id: 'MS' },
  { id: 'MO' },
  { id: 'MT' },
  { id: 'NE' },
  { id: 'NV' },
  { id: 'NH' },
  { id: 'NJ' },
  { id: 'NM' },
  { id: 'NY' },
  { id: 'NC' },
  { id: 'ND' },
  { id: 'OH' },
  { id: 'OK' },
  { id: 'OR' },
  { id: 'PA' },
  { id: 'RI' },
  { id: 'SC' },
  { id: 'SD' },
  { id: 'TN' },
  { id: 'TX' },
  { id: 'UT' },
  { id: 'VT' },
  { id: 'VA' },
  { id: 'WA' },
  { id: 'WV' },
  { id: 'WI' },
  { id: 'WY' },
];
export const COURSE_TAB_LIST = ['info', 'lessons', 'enrollments', 'resources', 'prerequisites'];

export const FET_ALLOWED_ACTIONS = {
  COURSES: 'COURSES',
  COURSEINFO: 'COURSEINFO',
  LESSONPLAN: 'LESSONPLAN',
  ENROLLMENT: 'ENROLLMENT',
  RESOURCES: 'RESOURCES',
  PREREQUISITES: 'PREREQUISITES',
};

export type LearningRoleType = 'ADMIN' | 'MANAGER' | 'INSTRUCTOR' | 'STUDENT';

export const LEARNING_ROLE_TYPE: { [key: string]: LearningRoleType } = {
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  INSTRUCTOR: 'INSTRUCTOR',
  STUDENT: 'STUDENT',
};

export const ROLE_ALLOWED_FET_ACTIONS = {
  [LEARNING_ROLE_TYPE.ADMIN]: [
    FET_ALLOWED_ACTIONS.COURSES,
    FET_ALLOWED_ACTIONS.COURSEINFO,
    FET_ALLOWED_ACTIONS.LESSONPLAN,
    FET_ALLOWED_ACTIONS.ENROLLMENT,
    FET_ALLOWED_ACTIONS.RESOURCES,
  ],
  [LEARNING_ROLE_TYPE.MANAGER]: [FET_ALLOWED_ACTIONS.ENROLLMENT],
  [LEARNING_ROLE_TYPE.INSTRUCTOR]: [
    FET_ALLOWED_ACTIONS.COURSES,
    FET_ALLOWED_ACTIONS.COURSEINFO,
    FET_ALLOWED_ACTIONS.LESSONPLAN,
    FET_ALLOWED_ACTIONS.RESOURCES,
  ],
  [LEARNING_ROLE_TYPE.STUDENT]: [],
};

export const TINY_MCE_DEFAULT_CONFIG = {
  // height: 500,
  // menubar: true,
  plugins: [
    'template',
    'preview',
    'media',
    'image',
    'importcss',
    'searchreplace',
    'autolink',
    'autosave',
    'save',
    'directionality',
    'code',
    'visualblocks',
    'visualchars',
    'fullscreen',
    'image',
    'link',
    'codesample',
    'table',
    'charmap',
    'pagebreak',
    'nonbreaking',
    'anchor',
    'insertdatetime',
    'advlist',
    'lists',
    'wordcount',
    'help',
    'charmap',
    'emoticons',
  ],
  toolbar:
    'bold italic underline strikethrough| alignleft aligncenter alignright alignjustify | formatselect | fontselect fontsizeselect | headings | forecolor backcolor | preview template | cut copy paste | searchreplace | bullist numlist | outdent indent blockquote | undo redo | link unlink image media code',
  // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
};
export const CAMPAIGN_EMAIL_FROM_SUFFIX = '@junolive.com';

// Max image upload size is 10MB
export const MAX_IMAGE_UPLOAD_SIZE = 1024 * 1024 * 10;

// Max SCORM upload size is 1GB
export const MAX_SCORM_UPLOAD_SIZE = 1024 * 1024 * 1024;

// Max video upload size is 10MB
export const MAX_MP4_VIDEO_UPLOAD_SIZE = 1024 * 1024 * 1024 * 10 * 1000;

export const FILE_UPLOAD_TYPES = [
  'application/pdf',
  '.pdf',
  'application/msword',
  '.doc',
  '.docx',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  '.xml',
  '.pages',
  '.psd',
  '.odt',
  '.odp',
  '.txt',
  '.rtf',
  '.csv',
  '.zip',
  '.key',
  '.ppsm',
  '.ppsx',
  '.pptx',
  '.xlsb',
  '.pptm',
  '.xlsm',
  '.xlsx',
  '.xltm',
  '.xltx',
];

export const LEARNING_ROUTE_MAP: Record<string, any> = {
  CLIENT_COURSES: {
    key: 'navigate.learning.page.courses',
    route: '/courses',
    params: [],
  },
  CLIENT_COURSE: {
    key: 'navigate.learning.course.slug',
    route: '/courses/:courseSlug',
    params: ['courseSlug', 'coursesSlug'],
  },
  CLIENT_LESSON: {
    key: 'navigate.learning.lesson.slug',
    route: '/courses/:courseSlug/:lessonSlug',
    params: ['courseSlug', 'lessonSlug'],
  },
  ADMIN_COURSES: {
    key: 'navigate.learning.admin.courses',
    route: '/sites/:siteSlug/courses',
    params: ['siteSlug'],
  },
  ADMIN_COURSE: {
    key: 'navigate.learning.admin.courses.slug',
    route: '/sites/:siteSlug/courses/:courseSlug/info',
    params: ['siteSlug', 'courseSlug'],
  },
  ADMIN_COURSE_TAB: {
    key: 'navigate.learning.admin.courses.slug.tab',
    route: '/sites/:siteSlug/courses/:courseSlug/:tabSlug',
    params: ['siteSlug', 'courseSlug', 'tabSlug'],
  },
  ADMIN_LESSON: {
    key: 'navigate.learning.admin.courses.slug.lessons.slug',
    route: '/sites/:siteSlug/courses/:courseSlug/lessons/:lessonSlug',
    params: ['siteSlug', 'courseSlug', 'lessonSlug'],
  },
};

export const JUNO_ROUTE_MAP = {
  CLIENT_GROUPS: {
    key: 'navigate.community.page.groups',
    route: '/groups',
    params: [],
  },
  CLIENT_GROUP: {
    key: 'navigate.community.group.slug',
    route: '/groups/:groupSlug',
    params: ['groupSlug', 'groupId'],
  },
  ADMIN_GROUPS: {
    key: 'navigate.community.admin.groups',
    route: '/sites/:siteSlug/groups',
    params: ['siteSlug'],
  },
  ADMIN_GROUP: {
    key: 'navigate.community.admin.group.slug',
    route: '/sites/:siteSlug/group/:groupSlug',
    params: ['siteSlug', 'groupSlug'],
  },
  CLIENT_ADMIN: {
    key: 'navigate.vnextadmin',
    route: '/vnext-admin',
    params: [],
  },
  CLIENT_ADMIN_ECS: {
    key: 'navigate.vnextadmin.education_credits',
    route: '/vnext-admin/education_credits',
    params: [],
  },
  CLIENT_ADMIN_EC: {
    key: 'navigate.vnextadmin.education_credits.slug',
    route: '/vnext-admin/education_credits/:ec_slug',
    params: ['ecSlug'],
  },
  CLIENT_ADMIN_CERTIFICATES: {
    key: 'navigate.vnextadmin.certificates',
    route: '/vnext-admin/certificates',
    params: [],
  },
  CLIENT_SESSIONS: {
    key: 'navigate.page.sessions',
    route: '/sessions',
    params: [],
  },
  CLIENT_SESSION: {
    key: 'navigate.page.sessions.slug',
    route: '/sessions/:sessionSlug',
    params: ['sessionSlug', 'sessionId'],
  },
  ADMIN_SESSIONS: {
    key: 'navigate.vnextadmin.sessions',
    route: '/vnext-admin/sessions',
    params: [],
  },
  ADMIN_SESSION: {
    key: 'navigate.vnextadmin.sessions.slug',
    route: '/sites/:siteSubdomain/sessions/:sessionSlug',
    // route: '/vnext-admin/sessions/:sessionSlug',
    params: ['sessionSlug'],
  },
  CLIENT_COURSES: {
    key: 'navigate.learning.page.courses',
    route: '/courses',
    params: [],
  },
  CLIENT_COURSE: {
    key: 'navigate.learning.course.slug',
    route: '/courses/:courseSlug',
    params: ['courseSlug', 'coursesSlug'],
  },
  CLIENT_LESSON: {
    key: 'navigate.learning.lesson.slug',
    route: '/courses/:courseSlug/:lessonSlug',
    params: ['courseSlug', 'lessonSlug'],
  },
  ADMIN_COURSES: {
    key: 'navigate.learning.admin.courses',
    route: '/sites/:siteSlug/courses',
    params: ['siteSlug'],
  },
  ADMIN_COURSE: {
    key: 'navigate.learning.admin.courses.slug',
    route: '/sites/:siteSlug/courses/:courseSlug/info',
    params: ['siteSlug', 'courseSlug'],
  },
  ADMIN_COURSE_TAB: {
    key: 'navigate.learning.admin.courses.slug.tab',
    route: '/sites/:siteSlug/courses/:courseSlug/:tabSlug',
    params: ['siteSlug', 'courseSlug', 'tabSlug'],
  },
  ADMIN_LESSON: {
    key: 'navigate.learning.admin.courses.slug.lessons.slug',
    route: '/sites/:siteSlug/courses/:courseSlug/lessons/:lessonSlug',
    params: ['siteSlug', 'courseSlug', 'lessonSlug'],
  },
  ADMIN_EMAIL_BRANDING: {
    key: 'navigate.vnextadmin.site_emailer_configs',
    route: '/vnext-admin/site_emailer_configs',
    params: [],
  },
  ADMIN_AUTOMATION_TOOL: {
    key: 'navigate.vnextadmin.automation_tool',
    route: '/vnext-admin/automation-tool',
    params: [],
  },
  ADMIN_EMAILS: {
    key: 'navigate.vnextadmin.emailer',
    route: '/vnext-admin/automation-tool/emailer',
    params: [],
  },
  ADMIN_EMAIL: {
    key: 'navigate.vnextadmin.emailer.slug',
    route: '/vnext-admin/automation-tool/emailer/:id',
    params: ['id'],
  },
};

export const ANALYTICS_CONFIGURATION = {
  FIREHOSE_ACTIONS: {
    ACTION_ANALYTICS: {
      title: 'Analytics',
      value: 'Analytics',
    },
    /** Event Activity*/
    ACTION_JOIN: {
      title: 'Joined',
      value: 'Join',
    },
    ACTION_LEAVE: {
      title: 'Leave',
      value: 'Leave',
    },
    ACTION_ATTEND: {
      title: 'Attend',
      value: 'Attend',
    },
    ACTION_UNATTEND: {
      title: 'Unattend',
      value: 'Unattend',
    },
    ACTION_RAISEHAND: {
      title: 'Raise Hand',
      value: 'Raise Hand',
    },
    ACTION_SCHEDULE: {
      title: 'Scheduled',
      value: 'Schedule',
    },
    ACTION_UNSCHEDULE: {
      title: 'Unschedule',
      value: 'Unschedule',
    },
    ACTION_MEETING_SCHEDULED: {
      title: 'Meeting Scheduled',
      value: 'Meeting Scheduled',
    },
    ACTION_EMOJI: {
      title: 'Emoji',
      value: 'Emoji',
    },
    /** Messaging Activity*/
    ACTION_CHAT: {
      title: 'Chat Message',
      value: 'Chat',
    },
    ACTION_DECLINE_CHAT: {
      title: 'Declined Chat',
      value: 'Chat Decline',
    },
    ACTION_MESSAGE: {
      title: 'Message',
      value: 'Message',
    },
    ACTION_SEND_INVITE: {
      title: 'Send Invite',
      value: 'Send Invite',
    },
    ACTION_RECEIVE_INVITE: {
      title: 'Receive Invite',
      value: 'Recieve Invite',
    },
    ACTION_CHAT_ACCEPT: {
      title: 'Chat Accepted',
      value: 'Chat Accepted',
    },
    ACTION_CHAT_STARTED: {
      title: 'Chat Started',
      value: 'Chat Started',
    },
    ACTION_PARTNER_CHAT_STARTED: {
      title: 'Partner Chat Started',
      value: 'Partner Chat Started',
    },
    /** Moderation Activity*/
    ACTION_KICKED: {
      title: 'Kicked',
      value: 'Kicked',
    },
    ACTION_BANNED: {
      title: 'Banned',
      value: 'Banned',
    },
    ACTION_MOD: {
      title: 'Chat Message Moderation',
      value: 'Mod',
    },
    ACTION_EDIT: {
      title: 'Edit',
      value: 'Edit',
    },
    ACTION_DELETE: {
      title: 'Delete',
      value: 'Delete',
    },
    ACTION_REPORT: {
      title: 'Report',
      value: 'Report',
    },
    ACTION_CREATE: {
      title: 'Create',
      value: 'Create',
    },
    ACTION_UPDATE: {
      title: 'Update',
      value: 'Update',
    },
    /** User Activity*/
    ACTION_QA: {
      title: 'Asked Q&A Question',
      value: 'QA',
    },
    ACTION_CLICK: {
      title: 'Clicked',
      value: 'Click',
    },
    ACTION_DOWNLOAD: {
      title: 'Downloaded',
      value: 'Download',
    },
    ACTION_VIEW: {
      title: 'Viewed',
      value: 'View',
    },
    ACTION_SAVE: {
      title: 'Saved',
      value: 'Save',
    },
    ACTION_FOLLOW: {
      title: 'Followed (forum topic)',
      value: 'Follow',
    },
    ACTION_UNFOLLOW: {
      title: 'Unfollowed (forum topic)',
      value: 'Unfollow',
    },
    ACTION_LIKE: {
      title: 'Like',
      value: 'Like',
    },
    ACTION_UNLIKE: {
      title: 'Unlike',
      value: 'Unlike',
    },
    ACTION_BLOCK: {
      title: 'Block',
      value: 'Block',
    },
    ACTION_LOGIN: {
      title: 'User Logged-in',
      value: 'Log In',
    },
    ACTION_LOGOUT: {
      title: 'User Logged-Out',
      value: 'Log Out',
    },
    ACTION_REVIEW: {
      title: 'User Submitted a Review',
      value: 'Review',
    },
    ACTION_PLAY: {
      title: 'Played a Video',
      value: 'Play',
    },
    ACTION_WATCH: {
      title: 'Watched',
      value: 'Watch',
    },
    ACTION_UPLOAD: {
      title: 'Upload',
      value: 'Upload',
    },
    ACTION_OPEN: {
      title: 'Open',
      value: 'Open',
    },
    ACTION_CLOSE: {
      title: 'Close',
      value: 'Close',
    },
    ACTION_SEARCH: {
      title: 'Search',
      value: 'Search',
    },
    /** Learning Activity*/
    ACTION_QUESTION: {
      title: 'Question',
      value: 'Question',
    },
    ACTION_ANSWER: {
      title: 'Answer',
      value: 'Answer',
    },
    ACTION_START: {
      title: 'Start',
      value: 'Start',
    },
    ACTION_COMPLETE: {
      title: 'Complete',
      value: 'Complete',
    },
    ACTION_ENROLL: {
      title: 'Enroll',
      value: 'Enroll',
    },
    ACTION_UNENROLL: {
      title: 'Unenroll',
      value: 'Unenroll',
    },
    ACTION_WAITLIST_ADD: {
      title: 'Waitlist Add',
      value: 'Waitlist Add',
    },
    ACTION_WAITLIST_REMOVE: {
      title: 'Waitlist remove',
      value: 'Waitlist remove',
    },
    ACTION_FAIL: {
      title: 'Failed',
      value: 'Fail',
    },
    /** Education Credits/Earnings Activity*/
    ACTION_CLAIM: {
      title: 'Credits Claimed',
      value: 'Claimed',
    },
    ACTION_EARN: {
      title: 'Earn',
      value: 'Earn',
    },
    ACTION_EARN_CREDIT: {
      title: 'Earned Credit',
      value: 'Earn Credit',
    },
    ACTION_EARN_GRADE: {
      title: 'Earned Grade',
      value: 'Earn Grade',
    },
    /** Community Activity*/
    ACTION_POST: {
      title: 'Posted',
      value: 'Post',
    },
    ACTION_COMMENT: {
      title: 'Commented',
      value: 'Comment',
    },
    ACTION_REPLY: {
      title: 'Reply',
      value: 'Reply',
    },
    ACTION_TAG: {
      title: 'Tag',
      value: 'Tag',
    },
    /** Ecommerce Activity*/
    ACTION_PAYMENT_SUCCESS: {
      title: 'Payment Success',
      value: 'Payment Success',
    },
  },
  FIREHOSE_CATEGORIES: {
    CATEGORY_ANALYTICS: 'Analytics',
    CATEGORY_USER: 'User',
    CATEGORY_CONTINUING_EDUCATION: 'Continuing Education',
    CATEGORY_DOWNLOAD: 'Download',
    CATEGORY_MESSAGE: 'Message',
    CATEGORY_ADMIN: 'Admin',
    CATEGORY_SUPPORT_CHAT: 'Support Chat',
    CATEGORY_NOTIFICATION: 'Notification',
    CATEGORY_PAGE: 'Page',
    CATEGORY_QUESTION_FILL_IN_THE_BLANK: 'Question - Fill in the Blank',
    CATEGORY_QUESTION_MULTIPLE_CHOICE: 'Question - Multiple Choice',
    CATEGORY_QUESTION_ORDER: 'Question - Order',
    CATEGORY_QUESTION_POLL: 'Question - Poll',
    CATEGORY_QUESTION_RATING: 'Question - Rating',
    CATEGORY_QUESTION_SELECT_MANY: 'Question - Select Many',
    CATEGORY_QUESTION_RANKING: 'Question - Ranking',
    CATEGORY_COHORT: 'Cohort',
    CATEGORY_FORUM: 'Forum',
    CATEGORY_FEED: 'Feed',
    CATEGORY_COURSE: 'Course',
    CATEGORY_LESSON: 'Lesson',
    CATEGORY_META: 'Meta',
    CATEGORY_PARTNER: 'Partner',
    CATEGORY_ONE_TO_ONE_MEETING: '1-1 Meeting',
    CATEGORY_REVIEW: 'Review',
    CATEGORY_SLIDE: 'Slide',
    CATEGORY_ANSWER: 'Answer',
    CATEGORY_LIVE_SESSION: 'Live Session',
    CATEGORY_COMMUNITY: 'Community',
    CATEGORY_ECOMMERCE: 'Ecommerce',
    CATEGORY_LIBRARY: 'Library',
    CATEGORY_FORUM_TOPIC: 'Forum Topic',
    CATEGORY_SEARCH: 'Search',
  },
  FIREHOSE_OBJECTS: {
    OBJECT_QUESTION: 'Question',
    OBJECT_USER: 'User',
    OBJECT_CONTENT: 'Content',
    OBJECT_DOWNLOAD: 'Download',
    OBJECT_COMMENT: 'Comment',
    OBJECT_UPLOAD: 'Upload',
    OBJECT_CME: 'CME',
    OBJECT_COMMUNITY: 'Community',
    OBJECT_LESSON: 'Lesson',
  },
};

export enum ActiveLinkEnum {
  HOME = 'home',
  FEED = 'feed',
  LEARNING = 'learning',
  COMMUNITY = 'community',
  SESSIONS = 'sessions',
  ADMIN = 'admin',
  LIBRARY = 'library',
}

export const NAV_ITEMS = [
  {
    title: 'Home',
    icon: <HomeOutlinedIcon />,
    selectedIcon: <HomeIcon />,
    link: '/home',
    selectedMatch: ActiveLinkEnum.HOME,
  },
  // {
  //   title: 'Feed',
  //   icon: <ChatOutlinedIcon />,
  //   selectedIcon: <ChatIcon />,
  //   link: '/feed',
  //   selectedMatch: ActiveLinkEnum.FEED,
  // },
  {
    title: 'Learning',
    icon: <SchoolOutlinedIcon />,
    selectedIcon: <SchoolIcon />,
    link: '/learning',
    selectedMatch: ActiveLinkEnum.LEARNING,
  },
  {
    title: 'Community',
    icon: <GroupsOutlinedIcon />,
    selectedIcon: <GroupsIcon />,
    link: '/community',
    selectedMatch: ActiveLinkEnum.COMMUNITY,
  },
  {
    title: 'Library',
    icon: <LocalLibraryOutlinedIcon />,
    selectedIcon: <LocalLibraryIcon />,
    link: '/library',
    selectedMatch: ActiveLinkEnum.LIBRARY,
  },
  {
    title: 'Sessions',
    icon: <VideocamOutlinedIcon />,
    selectedIcon: <VideocamIcon />,
    link: '/sessions',
    selectedMatch: ActiveLinkEnum.SESSIONS,
  },
];
