import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

export const PaperContainer = styled(Paper)({
  position: 'fixed',
  left: '50%',
  transform: 'translateX(-50%)',
  top: 'calc(100% - 160px)',
  cursor: 'move',
  '&:active': {
    cursor: 'grabbing',
  },
  overflow: 'hidden',
  zIndex: 1000,
});

export const HandleBar = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[500],
  height: theme.spacing(3),
  width: '100%',
  cursor: 'move',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const ContentContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
}));
