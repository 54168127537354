import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Settings as SettingsIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useQueryClient } from 'react-query';
import { useBlocker, useParams } from 'react-router-dom';
import { useEventListener, useMediaQuery } from 'usehooks-ts';
import {
  useCreateComponent,
  useGetPage,
  useUpdateComponent,
  useUpdatePage,
} from '@juno/client-api';
import {
  Component as ComponentModel,
  NavigationItem as NavigationItemModel,
  Page as PageModel,
} from '@juno/client-api/model';
import { FeatureConfig } from '@juno/client-api/utils';
import { ThemeConfigProps } from '@juno/constants';
import { DialogAriaWrapper, JunoSpin } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import { JunoFixtureForm, JunoModuleForm } from '../JunoModule/form';
import EditPageForm from './EditPageForm';
import ModulePanel from './ModulePanel';
import { Canvas, DrawerWrapper } from './styles';
import { componentModelFromKey, getSortKeyObj } from './utils';

const DRAWER_WIDTH = 320;

interface PageBuilderProps {
  siteId: string;
  formik: FormikProps<PageModel>;
  page?: PageModel;
  handleSavePage: () => void;
  isSaving: boolean;
  setIsSaving: Dispatch<SetStateAction<boolean>>;
  isEditingPage: boolean;
  setIsEditingPage: Dispatch<SetStateAction<boolean>>;
  pageLoading: boolean;
}

const PageBuilder: React.FC<PageBuilderProps> = ({
  siteId,
  formik,
  page,
  isEditingPage,
  setIsEditingPage,
  handleSavePage,
  isSaving,
  setIsSaving,
  pageLoading,
}) => {
  const queryClient = useQueryClient();
  //const { siteId } = useContext(StoreContext);

  const { configs, site, showMessenger } = useSettings();
  const { handleSubmit, isSubmitting, dirty, handleReset, setValues, values } = formik;
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      dirty && currentLocation.pathname !== nextLocation.pathname,
  );

  //const { handleToggleNavDrawer, isNavDrawerOpen, navDrawerWidth } = useContext(AppContext);
  const [navDrawerWidth, setNavDrawerWidth] = useState(0);
  const [isNavDrawerOpen, setIsNavDrawerOpen] = useState(false);
  const handleToggleNavDrawer = () => {
    setIsNavDrawerOpen(!isNavDrawerOpen);
  };

  const [isEditingModule, setIsEditingModule] = useState<boolean>(false);
  const [isPageEditDrawerOpen, setIsPageEditDrawerOpen] = useState<boolean>(true);
  const [moduleEditing, setModuleEditing] = useState<ComponentModel | undefined>();
  const [fixtureEditing, setFixtureEditing] = useState<string | undefined>();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [themeConfig, setThemeConfig] = useState<ThemeConfigProps | undefined>();

  const isLoading = pageLoading;
  useEventListener('keydown', (e) => {
    if (isEditingModule && e.key === 'Escape') {
      handleCancelEditModule();
    }
    if (fixtureEditing && e.key === 'Escape') {
      handleCancelEditFixture();
    }
  });

  useEffect(() => {
    if (page) {
      setIsSaving(false);
    }
  }, [page]);

  // mutations
  const { CREATE, UPDATE } = MutationAction;
  const refetchPage = () =>
    queryClient.invalidateQueries(`/sites/${siteId}/pages/${page?.slug || ''}`);

  const createComponent = useCreateComponent(onMutation(CREATE, 'Component', refetchPage));
  const updateComponent = useUpdateComponent(onMutation(UPDATE, 'Component', refetchPage));

  const handleResetPage = () => {
    if (page) {
      setIsEditingPage(false);
      setIsSaving(false);
    }
  };

  useEffect(() => {
    setIsPageEditDrawerOpen(true);
    if (isNavDrawerOpen) {
      handleToggleNavDrawer();
    }
  }, []);

  useEffect(() => {
    if (!configs) return;
    const configTheme = FeatureConfig.getThemeConfigTheme(configs);
    if (configTheme) {
      setThemeConfig(configTheme);
    }
  }, [configs, page]);

  useEffect(() => {
    // trigger resize of the rotator on drawer open/close
    setTimeout(() => window.dispatchEvent(new Event('resize')), 210);
  }, [isNavDrawerOpen, isEditingModule, isPageEditDrawerOpen]);

  const handleEditFixture = (fixture: string) => {
    setFixtureEditing(fixture);
    const moduleEl = document.getElementById(`pagebuilder-${fixture}`) as HTMLElement;
    if (moduleEl) {
      setTimeout(() => window.scrollTo({ behavior: 'smooth', top: moduleEl.offsetTop }), 100);
    }
  };

  const handleUpdateEditFixture = (payload: NavigationItemModel) => {
    console.log('handleUpdateEditFixture', payload);
    // setModuleEditing(component);
  };

  const handleCancelEditFixture = () => {
    setFixtureEditing(undefined);
  };

  const handleEditModule = (key: string) => {
    const isFixture = ['header', 'footer'].includes(key);
    if (isFixture) {
      handleEditFixture(key);
      return;
    }
    const component = componentModelFromKey(key, values);
    setIsEditingModule(true);
    setModuleEditing(component);
    // scroll to element
    const moduleEl = document.getElementById(component.id) as HTMLElement;
    if (moduleEl) {
      setTimeout(() => window.scrollTo({ behavior: 'smooth', top: moduleEl.offsetTop }), 100);
    }
  };

  // TODO: refactor these JunoModel functions to be more generic
  const handleCancelEditModule = () => {
    setIsEditingModule(false);
    setModuleEditing(undefined);
    handleResetPage();
  };

  const handleSaveEditModule = async (component: ComponentModel) => {
    if (component.id.slice(-6)?.[0] === '#') {
      createComponent.mutateAsync({ siteId, data: component });
    } else {
      updateComponent.mutateAsync({ siteId, componentId: component.id, data: component });
    }

    setModuleEditing(undefined);
    setIsEditingModule(false);
    setIsEditingPage(false);
  };

  const handleUpdateEditModule = (component: ComponentModel) => {
    setModuleEditing(component);
    setValues({
      ...values,
      components: values.components?.map((c) => (c.id === component.id ? component : c)) || [],
    });
  };

  if (isLoading) {
    return (
      <Canvas editDrawerWidth={0} backgroundColor='#fff'>
        <JunoSpin />
        <DrawerWrapper
          anchor='left'
          open={isPageEditDrawerOpen}
          hideBackdrop
          variant={'persistent'}
          transitionDuration={200}
          width={DRAWER_WIDTH}
          sx={{ zIndex: (theme) => theme.zIndex.drawer - 2 }}
        ></DrawerWrapper>
      </Canvas>
    );
  }

  return (
    <Canvas editDrawerWidth={DRAWER_WIDTH}>
      {!isPageEditDrawerOpen && (
        <Box
          sx={{
            position: 'fixed',
            top: 170,
            left: 20,
            zIndex: 100,
          }}
        >
          <Tooltip title='Pagebuilder settings' placement='right'>
            <IconButton
              onClick={() => setIsPageEditDrawerOpen(!isPageEditDrawerOpen)}
              size='large'
              sx={{ background: (theme) => theme.palette.background.paper }}
            >
              <SettingsIcon />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      {themeConfig && (
        <ModulePanel
          siteId={siteId}
          isEditing={isEditingPage && !isEditingModule && !fixtureEditing}
          module={moduleEditing}
          page={values}
          pages={[]}
          navigationItems={[]}
          onReorder={() => {}}
          onRemove={() => {}}
          onEdit={handleEditModule}
          themeConfig={themeConfig}
          configs={configs || []}
        />
      )}
      <DrawerWrapper
        anchor='left'
        open={isPageEditDrawerOpen}
        hideBackdrop
        variant={'persistent'}
        transitionDuration={200}
        width={DRAWER_WIDTH}
        sx={{ zIndex: (theme) => theme.zIndex.drawer - 2 }}
      >
        {site && page && (
          <EditPageForm
            onToggleDrawer={() => setIsPageEditDrawerOpen(!isPageEditDrawerOpen)}
            site={site}
            page={values}
            onPageSave={handleSavePage}
            isConfiguring={isEditingPage}
            onConfigure={setIsEditingPage}
            onModuleEdit={handleEditModule}
            isHomepage={false}
            isSaving={isSaving}
            dirty={dirty}
            setValues={setValues}
            handleSubmit={handleSubmit}
          />
        )}
      </DrawerWrapper>
      <DrawerWrapper
        anchor='left'
        open={isEditingModule}
        hideBackdrop
        variant={'persistent'}
        transitionDuration={200}
        width={DRAWER_WIDTH}
        sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
      >
        <JunoModuleForm
          siteId={siteId}
          component={moduleEditing}
          onCancel={handleCancelEditModule}
          onSave={handleSaveEditModule}
          onUpdate={handleUpdateEditModule}
        />
      </DrawerWrapper>
      <DrawerWrapper
        anchor='left'
        open={!!fixtureEditing}
        hideBackdrop
        variant={'persistent'}
        transitionDuration={200}
        width={DRAWER_WIDTH}
        sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
      >
        {site && (
          <JunoFixtureForm
            site={site}
            configs={configs || []}
            fixtureType={fixtureEditing}
            navigationItems={[]}
            pages={[]}
            onCancel={handleCancelEditFixture}
            onSave={() => {}}
            onUpdate={handleUpdateEditFixture}
            onDelete={() => {}}
          />
        )}
      </DrawerWrapper>
      <DialogAriaWrapper open={blocker.state === 'blocked'}>
        <Box ml={2} mb={2} mt={2}>
          <Typography variant='h6'>Are you sure?</Typography>
        </Box>
        <DialogContent>
          <Typography>You have unsaved changes that will be lost if you proceed.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => blocker.reset && blocker.reset()}>Cancel</Button>
          <Button onClick={() => blocker.proceed && blocker.proceed()} variant='contained'>
            Proceed
          </Button>
        </DialogActions>
      </DialogAriaWrapper>
    </Canvas>
  );
};

interface PageBuilderWrapperProps {
  siteId: string;
}

const PageBuilderWrapper: React.FC<PageBuilderWrapperProps> = ({ siteId }) => {
  const queryClient = useQueryClient();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isEditingPage, setIsEditingPage] = useState<boolean>(false);
  const params = useParams();
  const { pageSlug } = params;

  // mutations
  const { CREATE, UPDATE } = MutationAction;
  const refetchPage = () =>
    queryClient.invalidateQueries(`/sites/${siteId}/pages/${pageSlug || ''}`);

  // queries
  const { data: page, isLoading: pageLoading } = useGetPage(siteId, pageSlug || '');
  const pageUpdate = useUpdatePage(onMutation(UPDATE, 'Page', refetchPage));

  const handleSavePage = async (page: PageModel | undefined = undefined) => {
    setIsSaving(true);
    if (!page) {
      throw Error('Invalid page');
    }
    setIsEditingPage(false);
    const pageId = page.id;
    const components = page.components.map((c) => ({
      ...c,
      id: !getSortKeyObj(c.id) ? c.id : '',
    }));
    try {
      await pageUpdate.mutateAsync({
        siteId,
        pageId,
        data: { ...page, components },
      });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Formik
      initialValues={page || ({} as PageModel)}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        handleSavePage(values);
      }}
      enableReinitialize
    >
      {(formik) => {
        return (
          <Form>
            <PageBuilder
              isEditingPage={isEditingPage}
              setIsEditingPage={setIsEditingPage}
              pageLoading={pageLoading}
              isSaving={isSaving}
              setIsSaving={setIsSaving}
              siteId={siteId}
              formik={formik}
              page={page}
              handleSavePage={handleSavePage}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default PageBuilderWrapper;
