/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type TabsShownEnum = typeof TabsShownEnum[keyof typeof TabsShownEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TabsShownEnum = {
  discussions: 'discussions',
  moderation: 'moderation',
  resources: 'resources',
  meetings: 'meetings',
  about: 'about',
  feed: 'feed',
} as const;
