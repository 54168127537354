import React, { useEffect, useState } from 'react';
import { AnimatePresence, MotionStyle, motion } from 'framer-motion';

export interface NotificationCircleProps {
  count: number;
  style?: MotionStyle;
}

export const NotificationCircle = ({ count, style }: NotificationCircleProps) => {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    if (count > 0) {
      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 1000); // Reset after 1 second
    }
  }, [count]);

  const variants = {
    initial: { scale: 0 },
    animate: { scale: isAnimating ? [0, 1.2, 1] : 1 },
    exit: { scale: 0 },
  };

  // Default style for the notification circle
  const defaultStyle: MotionStyle = {
    background: '#fe493f',
    borderRadius: '50%',
    width: 17,
    height: 17,
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '12px',
    fontWeight: 'bold',
  };

  return (
    <AnimatePresence>
      {count > 0 && (
        <motion.div
          style={{ ...defaultStyle, ...style }}
          variants={variants}
          initial='initial'
          animate='animate'
          exit='exit'
        >
          {count}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
