import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';

interface SearchProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Search: React.FC<SearchProps> = ({ handleChange }) => {
  return (
    <TextField
      fullWidth
      onChange={handleChange}
      placeholder='Search'
      sx={{ mb: 3 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export { Search };
