import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import PubNub from 'pubnub';
import { useQueryClient } from 'react-query';
import {
  getGetAllNotificationsQueryKey,
  useDeleteNotifications,
  useGetAllNotifications,
  useGetNotificationPreferences,
  useMarkNotificationsAsRead,
} from '@juno/client-api';
import {
  DeleteNotifications200,
  MarkNotificationsAsRead200,
  Notification,
  NotificationIds,
  NotificationPreferences,
} from '@juno/client-api/model';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import { usePubnubContext } from '../Contexts/PubnubContext';

const defaultContext = {
  notifications: [],
  unreadNotifications: [],
  newNotifications: [],
  userPreferences: undefined,
  isLoadingUserPreferences: false,
  refetchPreferences: () => {},
  setNewNotifications: () => {},
  deleteNotification: (id: string[]) => Promise.resolve({} as DeleteNotifications200),
  readNotification: (ids: string[]) => Promise.resolve({} as MarkNotificationsAsRead200),
};
type ContextType = {
  notifications: Notification[];
  unreadNotifications: Notification[];
  newNotifications: Notification[];
  userPreferences: NotificationPreferences[] | undefined;
  isLoadingUserPreferences: boolean;
  refetchPreferences: () => void;
  setNewNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
  deleteNotification: (ids: string[]) => Promise<DeleteNotifications200>;
  readNotification: (ids: string[]) => Promise<MarkNotificationsAsRead200>;
};
const NotificationContext = createContext<ContextType>(defaultContext);

interface NotificationProviderProps {
  children: React.ReactNode;
}
export const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const { pubnub } = usePubnubContext();
  const { user, site } = useSettings();
  const queryClient = useQueryClient();
  const [newNotifications, setNewNotifications] = useState<Notification[]>([]);
  // TODO paginate notifications
  const { data: notifications, refetch: refetchNotifications } = useGetAllNotifications(
    site?.id || '',
    {
      order: '-created_at',
    },
    {
      query: { enabled: !!site?.id },
    },
  );
  const {
    data: userPreferences,
    isLoading: isLoadingUserPreferences,
    refetch: refetchPreferences,
  } = useGetNotificationPreferences(site?.id || '', {
    query: { enabled: !!site?.id && !!user?.id },
  });
  const unreadNotifications = useMemo(
    () => notifications?.filter((n) => !n.is_read),
    [notifications],
  );

  const markAsRead = useMarkNotificationsAsRead(
    onMutation(MutationAction.UPDATE, '', () => {
      queryClient.invalidateQueries(getGetAllNotificationsQueryKey(site?.id || ''));
    }),
  );
  const deleteNotis = useDeleteNotifications(
    onMutation(MutationAction.DELETE, '', refetchNotifications),
  );

  const messageListener = {
    message: (messageEvent: PubNub.MessageEvent) => {
      // check the channel first and match to our id
      if (messageEvent.channel !== user?.id) return;
      console.log('NotificationContext messageEvent', messageEvent);

      const message = messageEvent.message as Notification;
      // make sure the message is not already in the list
      if (newNotifications.some((n) => n.id === message.id)) return;

      const determinePrefs = async () => {
        const awaitedPrefs = await refetchPreferences();
        // check user preferences to see if the user wants to see this notification
        const preference = awaitedPrefs.data?.find(
          (p) => p.preference_type === message.preference_type && p.type === message.type,
        );
        if (!preference || !preference.is_active) {
          // we still want to update the notifications list
          refetchNotifications();
          return;
        }

        setNewNotifications((prev) => [message, ...prev]);
        refetchNotifications();
      };
      determinePrefs();
    },
  };
  const hasListener = useRef(false);
  useEffect(() => {
    if (hasListener.current) return;
    if (pubnub && user && userPreferences) {
      pubnub.addListener(messageListener);
      pubnub.subscribe({ channels: [user.id], withPresence: true });
      hasListener.current = true;
    }
  }, [pubnub, user, userPreferences]);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  // useEffect(() => {
  //   if (!notifications) return;
  //   if (timeoutRef.current) clearTimeout(timeoutRef.current);
  //   // set a 30 second timeout to clear the new notifications
  //   timeoutRef.current = setTimeout(() => {
  //     setNewNotifications([]);
  //     refetchNotifications();
  //   }, 30000); // TODO may need to change this to a setting, also on hover needs added in

  //   // Clear timeout on component unmount
  //   return () => {
  //     if (timeoutRef.current) {
  //       clearTimeout(timeoutRef.current);
  //     }
  //   };
  // }, [newNotifications, notifications, refetchNotifications, setNewNotifications]);

  const contextValue = {
    notifications: notifications || [],
    unreadNotifications: unreadNotifications || [],
    newNotifications,
    userPreferences,
    isLoadingUserPreferences,
    refetchPreferences,
    setNewNotifications,
    deleteNotification: (ids: string[]) => {
      const notification_ids = { notification_ids: ids } as NotificationIds;
      return deleteNotis.mutateAsync({ siteId: site?.id || '', data: notification_ids });
    },
    readNotification: async (ids: string[]) => {
      const notification_ids = { notification_ids: ids } as NotificationIds;
      return markAsRead.mutateAsync({ siteId: site?.id || '', data: notification_ids });
    },
  };

  return (
    <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);
