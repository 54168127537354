/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type ReasonEnum = typeof ReasonEnum[keyof typeof ReasonEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ReasonEnum = {
  Illegal__Copywrited: 'Illegal / Copywrited',
  Inappropriate: 'Inappropriate',
  Harassment: 'Harassment',
  False_Information: 'False Information',
  Other: 'Other',
} as const;
