import React, { SetStateAction, useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useQueryClient } from 'react-query';
import {
  createGeneratedReport,
  getGetGeneratedReportQueryKey,
  useGetGeneratedReport,
} from '@juno/client-api';
import {
  CreateGeneratedReportParams,
  GeneratedReport,
  GeneratedReportStatusEnum,
} from '@juno/client-api/model';
import { useSettings } from '@juno/utils';

interface AsyncDownloadProps {
  siteId: string;
  data: GeneratedReport;
  params: CreateGeneratedReportParams;
  setIsDownloading: (arg0: boolean) => void;
}
const AsyncDownload: React.FC<AsyncDownloadProps> = ({
  siteId,
  data,
  params,
  setIsDownloading,
}) => {
  const [reportId, setReportId] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const { downloadUrl } = useSettings();
  const create = async () => {
    try {
      const report = await createGeneratedReport(siteId, data, params);
      setReportId(report.id || null);
    } catch {
      setIsDownloading(false);
    }
  };
  const refetch = () =>
    queryClient.invalidateQueries(getGetGeneratedReportQueryKey(siteId, reportId || ''));
  const { data: reportData, isLoading } = useGetGeneratedReport(siteId, reportId || '', {
    query: {
      enabled: reportId !== null,
    },
  });

  useEffect(() => {
    create();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 2000);
    if (reportData?.status === GeneratedReportStatusEnum.NUMBER_2) {
      clearInterval(interval);

      if (reportData?.report_url && reportData.report_url.length > 0) {
        const url: string = reportData.report_url;

        //extract last bit of filename (before query params)
        const filename: string = url.split('/').pop()?.split('?').shift() || 'Download.csv';

        downloadUrl(url, filename, true);
      }

      setIsDownloading(false);
    } else if (reportData?.status === GeneratedReportStatusEnum.NUMBER_MINUS_1) {
      clearInterval(interval);
      console.error(`Error creating ${data.type} report`);
      setIsDownloading(false);
    }
    return () => {
      clearInterval(interval);
    };
  }, [reportData]);

  return <CircularProgress size={16} sx={{ m: 0.5 }} />;
};

export default AsyncDownload;
