import React, { useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Button,
  FormControl,
  FormGroup,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { DefaultDeleteDialog } from '@juno/ui';

interface DialogFooterProps {
  allRows: [];
  rowCount: number;
  hasErrors: boolean;
  currentErrorRow: number;
  totalErrorRows: number;
  handleSelectRowChange: (chosenRow: number) => void;
  handleStepForward: () => void;
  handleStepForwardError: () => void;
  handleStepBackwardError: () => void;
  handleDeleteItem: () => void;
  handleDeleteAll: () => void;
  handleClose: () => void;
}
const DialogFooter: React.FC<DialogFooterProps> = ({ ...props }) => {
  const {
    allRows,
    rowCount,
    hasErrors,
    currentErrorRow,
    totalErrorRows,
    handleSelectRowChange,
    handleStepForwardError,
    handleStepForward,
    handleStepBackwardError,
    handleDeleteItem,
    handleDeleteAll,
    handleClose,
  } = props;
  const [open, setOpen] = useState(false);
  const [all, setAll] = useState(false);
  const handleChange = (event: SelectChangeEvent) => {
    handleSelectRowChange(event.target.value as unknown as number);
  };
  return (
    <Box sx={{ pt: 2, pl: 2, pr: 2, borderTop: '1px solid black' }}>
      <Stack width='100%'>
        <Box justifyContent='space-between' display='flex' width='65%' margin='auto' mb={3}>
          <Button
            disabled={rowCount == 0}
            startIcon={<ChevronLeftIcon />}
            onClick={handleStepBackwardError}
          >
            PREV
          </Button>
          <Box justifyContent='space-between' display='flex' alignItems='center' ml={2} mr={2}>
            <Typography fontSize='14px'>Item</Typography>
            <FormControl>
              <FormGroup>
                <Select value={String(rowCount)} sx={{ ml: 2, mr: 2 }} onChange={handleChange}>
                  {allRows.map((row: any, index: number) => {
                    return (
                      <MenuItem key={`menu_item_row_${row.rowId}`} value={index}>
                        {index + 1}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormGroup>
            </FormControl>
            <Typography>of {allRows.length}</Typography>
          </Box>
          {currentErrorRow != totalErrorRows || hasErrors ? (
            <Button
              disabled={rowCount + 1 == allRows.length}
              endIcon={<ChevronRightIcon />}
              onClick={handleStepForwardError}
            >
              NEXT
            </Button>
          ) : (
            <Button
              disabled={rowCount == allRows.length}
              endIcon={<ChevronRightIcon />}
              onClick={handleStepForward}
            >
              Done
            </Button>
          )}
        </Box>
        <Box justifyContent='space-between' display='flex'>
          {hasErrors ? (
            <>
              <Button
                variant='outlined'
                sx={{ pr: '50px', pl: '50px' }}
                disabled={!hasErrors}
                onClick={() => {
                  setAll(true);
                  setOpen(true);
                }}
              >
                Delete All Items With Errors
              </Button>
              <Button
                variant='contained'
                sx={{ pr: '50px', pl: '50px' }}
                onClick={(e: any) => {
                  setAll(false);
                  setOpen(true);
                }}
              >
                Delete Item
              </Button>
            </>
          ) : (
            <Button fullWidth variant='contained' onClick={handleClose}>
              Complete
            </Button>
          )}
        </Box>
      </Stack>
      {open && (
        <DefaultDeleteDialog
          deleteTitle={`${all ? 'these items' : 'this item'} from your
                csv?`}
          deleteSubtitle='To recover this data, you will need to start over and re-upload your csv.'
          buttonText={all ? 'Delete All' : 'Delete Item'}
          handleClose={() => {
            setOpen(false);
          }}
          handleDelete={() => {
            all ? handleDeleteAll() : handleDeleteItem();
            setOpen(false);
          }}
        />
      )}
    </Box>
  );
};
export default DialogFooter;
