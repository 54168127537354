import React, { SetStateAction, SyntheticEvent } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { PrivacyEnum, Tag } from '@juno/client-api/model';
import { DateFilter, RotatorSettings } from '@juno/ui';
import { TabItemObject } from '../ContentGrid';
import {
  DateTypeToFilterString,
  EnrollmentStatus,
  SortByOptions,
  SortOrderOptions,
  UserActions,
  checkJsonWorthiness,
} from '../ContentGrid/form/utils';
import { defaultDateFilter } from './FilterDialog';

export const onFilterByTags = (
  { target }: React.ChangeEvent<HTMLInputElement>,
  payload: RotatorSettings,
  setFilterByTags: React.Dispatch<SetStateAction<boolean>>,
  setPayload: React.Dispatch<SetStateAction<RotatorSettings>>,
) => {
  const newPayload = {
    ...payload,
    preFilterTags: false,
    requireTags: [],
    optionalTags: [],
    excludeTags: [],
  } as RotatorSettings;

  if (!payload?.preFilterTags || !payload.preFilterTags) {
    newPayload.preFilterTags = true;
    setFilterByTags(true);
  } else {
    newPayload.preFilterTags = false;
    setFilterByTags(false);
  }
  setPayload(newPayload);
};

export const onTagChange = (
  event: SyntheticEvent,
  payload: RotatorSettings,
  setPayload: React.Dispatch<SetStateAction<RotatorSettings>>,
  newValue: Tag[],
  tagFilterType: string,
) => {
  const newPayload = { ...payload } as RotatorSettings;

  // Did a tag get added or removed?
  const tagAdded = newValue.length > (payload as any)[tagFilterType]?.length;
  // A tag was added
  if (tagAdded) {
    const addedTag = newValue[newValue.length - 1];
    // Make sure the tag isn't already in use
    if (
      !payload?.requireTags?.includes(addedTag.id) &&
      !payload?.optionalTags?.includes(addedTag.id) &&
      !payload?.excludeTags?.includes(addedTag.id)
    ) {
      (newPayload as any)[tagFilterType] = newValue.map((tag) => tag.id);
      setPayload(newPayload);
    }
    // A tag was removed
  } else {
    (newPayload as any)[tagFilterType] = newValue.map((tag) => tag.id);
    setPayload(newPayload);
  }
};

export const onFilterOnlyMyGroups = (
  { target }: React.ChangeEvent<HTMLInputElement>,
  setPayload: React.Dispatch<SetStateAction<RotatorSettings>>,
) => {
  setPayload((payload) => ({ ...payload, filterOnlyMyGroups: target.checked } as TabItemObject));
};
export const onFilterByPrivacyLevel = (
  { target }: React.ChangeEvent<HTMLInputElement>,
  setPayload: React.Dispatch<SetStateAction<RotatorSettings>>,
) => {
  setPayload(
    (payload) =>
      ({
        ...payload,
        filterByPrivacyLevel: target.checked ? PrivacyEnum.public : '',
      } as TabItemObject),
  );
};
export const handlePrivacyLevelChange = (
  { target }: SelectChangeEvent,
  setPayload: React.Dispatch<SetStateAction<RotatorSettings>>,
) => {
  setPayload(
    (payload) => ({ ...payload, filterByPrivacyLevel: target.value as string } as TabItemObject),
  );
};
export const onFilterByUserActions = (
  { target }: React.ChangeEvent<HTMLInputElement>,
  setFilterByUserActions: React.Dispatch<SetStateAction<boolean>>,
  setPayload: React.Dispatch<SetStateAction<RotatorSettings>>,
) => {
  setPayload((payload) => {
    const jsonifiedFilterString = checkJsonWorthiness(payload?.filter);
    // Clear out previously saved User Action filters while preserving Date filters
    if (jsonifiedFilterString) {
      delete jsonifiedFilterString['wait_list__user_id'];
      delete jsonifiedFilterString['enrollments__user_id'];
      delete jsonifiedFilterString['enrollments__status__in'];
    }

    const newPayload = {
      ...payload,
      preFilterUserActions: false,
      filter_and_or: undefined,
      filter: jsonifiedFilterString ? JSON.stringify(jsonifiedFilterString) : '',
      selectedUserActions: {
        [UserActions.Enroll]: false,
        [UserActions.Start]: false,
        [UserActions.Complete]: false,
        [UserActions.Waitlist]: false,
      },
    } as RotatorSettings;

    if (!payload?.preFilterUserActions || !payload.preFilterUserActions) {
      newPayload.preFilterUserActions = true;
      setFilterByUserActions(true);
    } else {
      newPayload.preFilterUserActions = false;
      setFilterByUserActions(false);
    }
    return newPayload;
  });
};

export const onUserActionChange = (
  event: SyntheticEvent,
  payload: RotatorSettings,
  setPayload: React.Dispatch<SetStateAction<RotatorSettings>>,
  setSelectedUserActions: React.Dispatch<SetStateAction<string[]>>,
  newValue: string[],
) => {
  const jsonifiedFilterString = checkJsonWorthiness(payload?.filter);
  // Set the Selected User Actions
  const selectedUserActions: Record<string, boolean> = {};
  newValue.forEach((action: string) => {
    selectedUserActions[action] = true;
  });

  // Now distinguish between whether our User Action set necessitates filter or filter_and_or
  let newPayload: RotatorSettings;
  // Waitlist is the only User Action selected
  if (newValue.includes(UserActions.Waitlist) && newValue.length === 1) {
    // Append filter string with a Waitlist specific filter param
    jsonifiedFilterString['wait_list__user_id'] = '{{USER_ID}}';
    newPayload = {
      ...payload,
      selectedUserActions: selectedUserActions,
      filter: JSON.stringify(jsonifiedFilterString),
      filter_and_or: undefined,
    } as RotatorSettings;
    // There's a combination of Waitlist and other Enrollment User Actions
  } else if (newValue.includes(UserActions.Waitlist)) {
    newPayload = {
      ...payload,
      selectedUserActions: selectedUserActions,
      filter: JSON.stringify(jsonifiedFilterString),
      filter_and_or: {
        and: {
          enrollments__user_id: '{{USER_ID}}',
          enrollments__status__in: newValue
            .filter((action: string) => action != UserActions.Waitlist)
            .map((action: string) => EnrollmentStatus[action as keyof typeof EnrollmentStatus]),
        },
        or: {
          wait_list__user_id: '{{USER_ID}}',
        },
      },
    } as RotatorSettings;
    // Waitlist is not a selected User Action
  } else {
    // Append filter string with enrollments filter params
    jsonifiedFilterString['enrollments__user_id'] = '{{USER_ID}}';
    jsonifiedFilterString['enrollments__status__in'] = newValue.map(
      (action: string) => EnrollmentStatus[action as keyof typeof EnrollmentStatus],
    );
    newPayload = {
      ...payload,
      selectedUserActions: selectedUserActions,
      filter: JSON.stringify(jsonifiedFilterString),
      filter_and_or: undefined,
    } as RotatorSettings;
  }
  // Save changes
  setPayload(newPayload);
  setSelectedUserActions(newValue);
};

export const onFilterByDates = (
  { target }: React.ChangeEvent<HTMLInputElement>,
  payload: RotatorSettings,
  setPayload: React.Dispatch<SetStateAction<RotatorSettings>>,
  filterByDates: boolean,
  setFilterByDates: React.Dispatch<SetStateAction<boolean>>,
  setDateFilters: React.Dispatch<SetStateAction<DateFilter[]>>,
  setDateFilterStringIsChanged: React.Dispatch<SetStateAction<boolean>>,
) => {
  setFilterByDates((prevState) => !prevState);
  if (!filterByDates) {
    setDateFilters([defaultDateFilter]);
    setDateFilterStringIsChanged(false);
    const jsonifiedFilterString = checkJsonWorthiness(payload?.filter);
    for (const key in DateTypeToFilterString.keys()) {
      delete jsonifiedFilterString[DateTypeToFilterString.get(key) + '__lte'];
      delete jsonifiedFilterString[DateTypeToFilterString.get(key) + '__gte'];
    }
    const newPayload = {
      ...payload,
      filter: JSON.stringify(jsonifiedFilterString),
      dateFilterString: '',
    } as RotatorSettings;
    setPayload(newPayload);
  }
};

/**
 *  PreSorting
 */

export const handleSortByChange = (
  event: SelectChangeEvent<string>,
  name: string,
  setSelectedSortByOption?: React.Dispatch<SetStateAction<string>>,
  setSelectedSortOrder?: React.Dispatch<SetStateAction<string>>,
) => {
  switch (name) {
    case 'sortBy':
      if (setSelectedSortByOption) {
        setSelectedSortByOption(event.target.value);
      }
      break;
    case 'sortOrder':
      if (setSelectedSortOrder) {
        setSelectedSortOrder(event.target.value);
      }
      break;
    default:
      break;
  }
};

// Handle when the first option the user chooses to sort by is the default (Alphabetical)
export const handleOpenSortOptions = (
  selectedSortByOption: string,
  setSelectedSortByOption: React.Dispatch<SetStateAction<string>>,
) => {
  if (selectedSortByOption === SortByOptions.Alphabetical) {
    handleSortByChange(
      { target: { value: SortByOptions.Alphabetical } } as SelectChangeEvent<string>,
      'sortBy',
      setSelectedSortByOption,
      undefined,
    );
  }
};
// Handle when the first option the user chooses to order by is the default (Ascending)
export const handleOpenOrderOptions = (
  selectedSortOrder: string,
  setSelectedSortOrder: React.Dispatch<SetStateAction<string>>,
) => {
  if (selectedSortOrder === SortOrderOptions.Ascending) {
    handleSortByChange(
      { target: { value: SortOrderOptions.Ascending } } as SelectChangeEvent<string>,
      'sortOrder',
      undefined,
      setSelectedSortOrder,
    );
  }
};
