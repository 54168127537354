import React from 'react';
import { DragIndicator as DragIndicatorIcon, Settings as SettingsIcon } from '@mui/icons-material';
import { Box, Card, IconButton } from '@mui/material';
import { useDebounce, useElementSize } from 'usehooks-ts';
import { EMPTY_COURSE_URL } from '@juno/constants';
import { optimizeImage } from '@juno/utils';
import {
  BottomIndicatorsWrapper,
  EllipsisTypography,
  HeaderRight,
  StyledFormImage,
  TileGridBanner,
  TileGridContainer,
  TileGridDragArea,
  TileGridTextWrapper,
  TileWrapper,
} from './styles';

interface RowTileProps {
  title?: React.ReactNode;
  subtitle?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  footerAction?: React.ReactNode;
  imageUrl?: string;
  hideImage?: boolean;
  onSettingsSelect?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  onRowSelect?: (evt: React.MouseEvent<HTMLDivElement>) => void;
  sortable?: boolean;
  disabled?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  backgroundColor?: string;
}
const RowTile: React.FC<RowTileProps> = ({
  title,
  subtitle,
  header,
  footer,
  footerAction,
  imageUrl,
  hideImage,
  onSettingsSelect,
  onRowSelect,
  sortable = false,
  disabled = false,
  startAdornment,
  endAdornment,
  backgroundColor,
}) => {
  const [textBodyRef, { width: textWidth }] = useElementSize();
  const debouncedTextWidth = useDebounce(textWidth, 200);

  return (
    <TileWrapper
      id='tile-wrapper'
      sx={{ cursor: onRowSelect ? 'pointer' : 'auto' }}
      onClick={(e) => onRowSelect && onRowSelect(e)}
    >
      <TileGridContainer container sx={{ mt: 2 }}>
        <Card
          sx={{ display: 'flex', width: '100%', backgroundColor: backgroundColor ?? undefined }}
        >
          {sortable && (
            <TileGridDragArea item>
              <DragIndicatorIcon />
            </TileGridDragArea>
          )}
          {!hideImage && (
            <TileGridBanner sx={{ m: 2, mr: 0 }}>
              <StyledFormImage
                variant='rounded'
                src={optimizeImage(450, imageUrl || EMPTY_COURSE_URL)}
                alt='Lesson Banner'
                onDragStart={(e) => e.preventDefault()}
                sx={{ width: '136px' }}
              />
            </TileGridBanner>
          )}
          {startAdornment && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>{startAdornment}</Box>
          )}
          <TileGridTextWrapper ref={textBodyRef} sx={{ m: 2 }}>
            <Box>
              <EllipsisTypography variant='h6' width={debouncedTextWidth}>
                {title}
              </EllipsisTypography>
              <EllipsisTypography variant='body2' width={debouncedTextWidth}>
                {subtitle}
              </EllipsisTypography>
            </Box>
            <BottomIndicatorsWrapper>{footer}</BottomIndicatorsWrapper>
          </TileGridTextWrapper>
          {endAdornment && <Box sx={{ display: 'flex', alignItems: 'center' }}>{endAdornment}</Box>}
          <Box sx={{ position: 'absolute', right: 0, bottom: 0, m: 1 }}>{footerAction}</Box>
        </Card>
      </TileGridContainer>
      {!endAdornment && (
        <HeaderRight>
          {header && header}
          {!header && (
            <IconButton
              onClick={(e) => onSettingsSelect && onSettingsSelect(e)}
              disabled={disabled}
            >
              <SettingsIcon />
            </IconButton>
          )}
        </HeaderRight>
      )}
      <Box sx={{ position: 'absolute', right: 0, bottom: 0, m: 1 }}>{footerAction}</Box>
    </TileWrapper>
  );
};

export default RowTile;
