/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type PrivacyEnum = typeof PrivacyEnum[keyof typeof PrivacyEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrivacyEnum = {
  public: 'public',
  private: 'private',
  secret: 'secret',
} as const;
