import React, { useEffect, useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { FeatureConfig } from '@juno/client-api/utils';
import { customShadow } from '@juno/ui';
import { getTheme, useSettings } from '@juno/utils';

interface ThemeWrapperProps {
  children: React.ReactNode;
}
const ThemeWrapper: React.FC<ThemeWrapperProps> = ({ children }) => {
  const { configs } = useSettings();
  const theme = useMemo(() => {
    const theme = getTheme(FeatureConfig.getThemeConfigTheme(configs));
    const customTheme = {
      ...theme,
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              textTransform: 'capitalize',
              boxShadow: 'none',
              borderRadius: 7,
              fontWeight: 600,
              letterSpacing: '.21px',
              '&.MuiButton-text:hover': {
                backgroundColor: '#eee',
              },
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            root: {
              boxShadow: 'none',
              borderRadius: 8,
            },
          },
        },
        MuiListItemButton: {
          styleOverrides: {
            root: {
              maxHeight: 40,
              borderLeft: '4px solid transparent',
              '&.Mui-selected': {
                borderLeft: `4px solid ${theme.palette.primary.main}`,
                backgroundColor: 'transparent',
                color: theme.palette.primary.main,
                '.MuiListItemIcon-root': {
                  color: theme.palette.primary.main,
                },
                ':hover': {
                  backgroundColor: '#eee',
                },
              },
              '&.Mui-focusVisible': {
                backgroundColor: '#eee',
              },
              ':hover': {
                backgroundColor: '#eee',
              },
            },
          },
        },
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              '> svg': {
                marginLeft: 3,
              },
            },
          },
        },
        MuiListItemText: {
          styleOverrides: {
            root: {
              '> .MuiTypography-root': {
                fontSize: '14px',
                lineHeight: '21px',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            },
          },
        },
        // Commenting for now, not sure if we're ready to apply this to the whole theme
        // MuiTabs: {
        //   styleOverrides: {
        //     root: { minHeight: 0 },
        //   },
        // },
        // MuiTab: {
        //   styleOverrides: {
        //     root: {
        //       textTransform: 'capitalize',
        //       padding: 0,
        //       '&.MuiButtonBase-root': { padding: '5.5px 24.5px', minHeight: 0 },
        //     },
        //   },
        // },
        MuiSwitch: {
          styleOverrides: {
            root: {
              width: 44,
              height: 24,
              padding: 0,
              marginLeft: 8,
              marginRight: 8,
              display: 'flex',
              '&:active': {
                '& .MuiSwitch-thumb': {
                  width: 12,
                },
                '& .MuiSwitch-switchBase.Mui-checked': {
                  transform: 'translateX(20px)',
                },
              },
              '& .MuiSwitch-switchBase': {
                padding: 6,
                '&.Mui-checked': {
                  transform: 'translateX(20px)',
                  color: '#fff',
                  '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.secondary,
                  },
                },
              },
              '& .MuiSwitch-thumb': {
                boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
                width: 12,
                height: 12,
                borderRadius: 10,
                transition: theme.transitions.create(['width'], {
                  duration: 200,
                }),
              },
              '& .MuiSwitch-track': {
                borderRadius: 14,
                opacity: 1,
                backgroundColor: '#fff',
                boxSizing: 'border-box',
                border: '4px solid #000',
              },
              '.Mui-checked + .MuiSwitch-track': {
                border: '2px solid #fff',
              },
              '&.MuiSwitch-sizeSmall': {
                transform: 'scale(0.5)',
              },
            },
            switchBase: {
              color: '#000',
            },
          },
        },
      },
      typography: {
        ...theme.typography,
        h3: {
          fontSize: 48,
          lineHeight: '73px',
          fontWeight: 600,
          color: '#1f1f1f',
        },
        h4: {
          fontSize: 36,
          lineHeight: '55px',
          fontWeight: 600,
          color: '#1f1f1f',
        },
        h5: {
          fontSize: 24,
          lineHeight: '37px',
          fontWeight: 600,
          color: '#1f1f1f',
        },
        h6: {
          fontSize: 20,
          lineHeight: '31px',
          fontWeight: 600,
          color: '#1f1f1f',
        },
        subtitle1: {
          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '25px',
          color: '#1f1f1f',
        },
        subtitle2: {
          fontSize: '14px',
          fontWeight: 500,
          lineHeight: '22px',
          color: '#1f1f1f',
        },
        body: {
          fontSize: '16px',
          lineHeight: '25px',
          color: '#1f1f1f',
        },
        body2: {
          fontSize: '14px',
          lineHeight: '22px',
          color: '#7f7f7f',
        },
        caption: {
          fontSize: '12px',
          lineHeight: '24px',
          color: '#7f7f7f',
          letterSpacing: '.02px',
        },
      },
    };
    return customTheme;
  }, [configs]);

  useEffect(() => {
    // set branded favicon
    const themeConfig = FeatureConfig.getThemeConfig(configs);
    const link = document.getElementById('favicon') as HTMLLinkElement;
    if (link) {
      link.href = (themeConfig?.config?.favicon || '') as string;
    }
  }, [configs]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
export default ThemeWrapper;
