import React, { useMemo } from 'react';
import { Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Card, IconButton } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Reorder, useMotionValue } from 'framer-motion';
import {
  Component as ComponentModel,
  FeatureConfig as FeatureConfigModel,
  NavigationItem as NavigationItemModel,
  Page as PageModel,
  PageTypeEnum,
} from '@juno/client-api/model';
import { FeatureConfig } from '@juno/client-api/utils';
import { ThemeConfigProps } from '@juno/constants';
import { getTheme, useSettings } from '@juno/utils';
import Footer from '../Footer';
import JunoModule from '../JunoModule';
import { useRaisedShadow } from './hooks';
import { SectionControls, SectionMask, StyledModulePanel } from './styles';

interface ReorderModuleItemProps {
  id: string;
  component: ComponentModel;
  siteId: string;
  onRemove: (key: string) => void;
  onEdit: (key: string) => void;
  configs: FeatureConfigModel[];
  isEditing: boolean;
}

const ReorderModuleItem = ({
  id,
  component,
  siteId,
  onRemove,
  onEdit,
  configs,
  isEditing,
}: ReorderModuleItemProps) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  return (
    <Reorder.Item
      value={component}
      id={id}
      style={{ y, boxShadow, position: 'relative' }}
      dragListener={false}
    >
      {isEditing && (
        <SectionMask>
          <SectionControls className='controls'>
            <IconButton color='secondary' onClick={() => onEdit(id)}>
              <EditIcon />
            </IconButton>
            <IconButton color='secondary' onClick={() => onRemove(id)}>
              <CloseIcon />
            </IconButton>
          </SectionControls>
        </SectionMask>
      )}
      <Box>
        <JunoModule component={component} siteId={siteId} configs={configs} />
      </Box>
    </Reorder.Item>
  );
};

interface ModulesProp {
  isEditing: boolean;
  page: PageModel | undefined;
  pages: PageModel[];
  navigationItems: NavigationItemModel[];
  module: ComponentModel | undefined;
  onReorder: (modules: ComponentModel[]) => void;
  onRemove: (key: string) => void;
  onEdit: (key: string) => void;
  themeConfig: ThemeConfigProps;
  siteId: string;
  configs: FeatureConfigModel[];
}

const ModulePanel = ({
  isEditing,
  page,
  pages,
  module,
  onReorder,
  onRemove,
  onEdit,
  themeConfig,
  siteId,
  navigationItems,
  configs,
}: ModulesProp) => {
  const selectedConfigs = useMemo(
    () => [FeatureConfig.buildThemePayload(themeConfig)],
    [themeConfig],
  );
  const theme = useMemo(
    () => getTheme(FeatureConfig.getThemeConfigTheme(selectedConfigs)),
    [selectedConfigs],
  );

  const hideLayout = page?.metadata?.hideLayout;

  return (
    <StyledModulePanel>
      {/*!hideLayout && (
        <div id='pagebuilder-header'>
          <ThemeProvider theme={theme}>
            <Header
              homepage='/'
              style={{ position: 'relative' }}
              navigationMap={navigationMap}
              configs={configs}
            />
          </ThemeProvider>
        </div>
      )*/}
      <Reorder.Group
        axis='y'
        onReorder={onReorder}
        values={page?.components || []}
        style={{
          margin: 0,
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          gridTemplateColumns: 'minmax(0, 1fr)',
        }}
      >
        {page?.components?.map((comp) => {
          const component = module?.id === comp.id ? module : comp;
          return (
            <ReorderModuleItem
              key={component.id}
              id={component.id}
              component={component}
              siteId={siteId}
              onRemove={onRemove}
              onEdit={onEdit}
              configs={selectedConfigs}
              isEditing={isEditing}
            />
          );
        })}
      </Reorder.Group>
      {!hideLayout && (
        <div id='pagebuilder-footer'>
          <ThemeProvider theme={theme}>
            <Footer siteId={siteId} configs={configs} style={{ position: 'relative' }} />
          </ThemeProvider>
        </div>
      )}
    </StyledModulePanel>
  );
};

export default ModulePanel;
