/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type FrequencyEnum = typeof FrequencyEnum[keyof typeof FrequencyEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FrequencyEnum = {
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  ONE_TIME: 'ONE_TIME',
  AUTOMATIC: 'AUTOMATIC',
} as const;
