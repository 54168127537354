import React, { useEffect } from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { LinearProgress, Paper, Stack, Typography } from '@mui/material';
import { formatHmsLongAbbreviated } from '@juno/utils';
import { useBreakpoint, useTimer } from '@juno/utils/hooks';
import DefaultCircularProgress from '../DefaultCircularProgress/index';
import DraggableContainer from '../DraggableContainer';
import { TimeChip } from './styles';

interface LessonTimerProps {
  time: number;
  timeAllowed: number;
  handleTimesUp?: () => void;
}

const LessonTimer: React.FC<LessonTimerProps> = ({ time, timeAllowed, handleTimesUp }) => {
  const { xs, sm } = useBreakpoint();
  const { remainingTime, progress, startTimer, stopTimer } = useTimer({
    startingTime: time,
    timeAllowed,
    handleTimesUp,
  });
  useEffect(() => {
    startTimer();
    return () => {
      stopTimer();
    };
  }, []);

  return (
    <>
      {xs ?? sm ? (
        <Paper
          sx={{
            position: 'fixed',
            bottom: '65px',
            right: '0',
            zIndex: 1,
            width: '100%',
            padding: '10px',
          }}
          variant='elevation'
          elevation={4}
          square
        >
          <Stack direction='row' justifyContent='center'>
            <TimerUX sm={xs || sm} remainingTime={remainingTime} progress={progress} />
          </Stack>
        </Paper>
      ) : (
        <DraggableContainer>
          <TimerUX sm={sm} remainingTime={remainingTime} progress={progress} />
        </DraggableContainer>
      )}
    </>
  );
};

export default LessonTimer;

interface TimerUXProps {
  sm: boolean;
  remainingTime: number;
  progress: number;
}
const TimerUX = ({ sm, remainingTime, progress }: TimerUXProps) => {
  return (
    <Stack spacing={1} direction={sm ? 'row' : 'column'}>
      <TimeChip
        label={
          <Typography
            variant='subtitle2'
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: 240,
            }}
          >
            {remainingTime > 0 ? (
              <>
                TIME LEFT
                <ScheduleIcon fontSize='small' sx={{ ml: 0.5, mr: 0.5 }} />
                {formatHmsLongAbbreviated(remainingTime)}
              </>
            ) : (
              'TIME IS UP'
            )}
          </Typography>
        }
        variant='filled'
        statusColor={progress >= 75 ? 'red' : 'green'}
      />
      {sm ? (
        <DefaultCircularProgress
          value={progress}
          props={{ size: 25, color: progress >= 75 ? 'error' : 'success' }}
          primaryCircleColor={progress >= 75 ? 'error' : 'success'}
        />
      ) : (
        <LinearProgress
          color={progress >= 75 ? 'error' : 'success'}
          variant='determinate'
          value={progress}
        />
      )}
    </Stack>
  );
};
