import React, { useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDebounce } from 'usehooks-ts';
import { Question } from '@juno/client-api/model';

interface FillInTheBlankProps {
  question: Question;
  answer: string[];
  handleSetAnswer: (answer: string[]) => void;
}

const FillInTheBlankInput = styled(TextField)(({ theme }) => ({
  padding: `${theme.spacing(2)} 0`,
  '.MuiOutlinedInput-root': {
    backgroundColor: theme.palette.common.white,
  },
}));

export default function FillInTheBlank({
  question,
  handleSetAnswer,
  answer,
}: FillInTheBlankProps): React.ReactElement {
  const [currentAnswer, setCurrentAnswer] = useState('');
  const debouncedAnswer = useDebounce(currentAnswer, 500);
  const { answers, name, answer_changeable, metadata } = question;
  const initialLoad = useRef(true);

  useEffect(() => {
    // If there is an existing answer, set that
    if (answer && answer.length) {
      setCurrentAnswer(answer[0]);
    }
  }, [answer]);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
    } else {
      handleSetAnswer([`${debouncedAnswer}`]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAnswer]);

  return (
    <FillInTheBlankInput
      fullWidth
      onChange={(e) => setCurrentAnswer(e.target.value)}
      multiline
      placeholder={`Enter your answer here`}
      minRows={4}
      maxRows={20}
      value={currentAnswer}
    />
  );
}
