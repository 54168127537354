/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type ComponentTypeEnum = typeof ComponentTypeEnum[keyof typeof ComponentTypeEnum];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ComponentTypeEnum = {
  blurb: 'blurb',
  call_to_action: 'call_to_action',
  CardGrid: 'CardGrid',
  CardRotator: 'CardRotator',
  download_list: 'download_list',
  featured_rotator: 'featured_rotator',
  grid: 'grid',
  HeaderNavBar: 'HeaderNavBar',
  JunoImage: 'JunoImage',
  poll: 'poll',
  search_bar: 'search_bar',
  session_rotator: 'session_rotator',
  SlideExample: 'SlideExample',
  speaker_preview: 'speaker_preview',
  VideoPanel: 'VideoPanel',
  global_feed: 'global_feed',
  global_forum: 'global_forum',
  hero: 'hero',
  side_by_side: 'side_by_side',
  schedule: 'schedule',
  directory: 'directory',
  announcements: 'announcements',
} as const;
