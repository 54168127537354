import React, { useEffect, useMemo, useState } from 'react';
import { Download as DownloadIcon } from '@mui/icons-material';
import { Box, MenuItem, Skeleton, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useMediaQuery } from 'usehooks-ts';
import { createGeneratedReport, useGetLearningDashboardSummary } from '@juno/client-api';
import { useGetUserRoleMap } from '@juno/client-api/helpers';
import {
  CreateGeneratedReportParams,
  GeneratedReport,
  GeneratedReportTypeEnum,
  GetLearningDashboardSummaryScope,
  Site as SiteModel,
} from '@juno/client-api/model';
import { ConfirmDialog, JunoSpin } from '@juno/ui';
import { useBreakpoint } from '@juno/utils/hooks';
import CompletedCourseItem from './CompletedCourseTileContent';
import CourseList from './CourseList';
import CurrentCourseItem from './CurrentCourseTileContent';
import LearnersList from './LearnersList';
import WaitlistedCourseItem from './WaitlistedCourseTileContent';
import { EnrollmentModel } from './components/EnrollmentTile';
import {
  ADMIN_COURSE_INCLUDE,
  ADMIN_COURSE_ORDER,
  ADMIN_COURSE_SEARCH_FIELD,
  COMPLETED_COURSE_FILTER,
  COMPLETED_COURSE_SEARCH_FIELD,
  CURRENT_COURSE_FILTER,
  CURRENT_COURSE_INCLUDE,
  CURRENT_COURSE_SEARCH_FIELD,
  ROLE_MAP,
  TileType,
  WAITLISTED_COURSE_INCLUDE,
  WAITLISTED_COURSE_SEARCH_FIELD,
} from './constants';
import { TranscriptDownloadButton } from './styles';

interface CourseResourcesProps {
  site: SiteModel;
}

interface StatsProps {
  value: number | undefined;
  label: string;
}

const Stats: React.FC<StatsProps> = ({ value = '', label }) => (
  <Box
    sx={{
      width: '224px',
      p: 2,
      background: '#F2F2F2',
      borderRadius: '4px',
      textAlign: 'center',
    }}
  >
    <Typography>{value}</Typography>
    <Typography sx={{ color: '#595959' }}>{label}</Typography>
  </Box>
);

const CourseResources: React.FC<CourseResourcesProps> = ({ site }) => {
  const { id: siteId } = site;
  const [tabValue, setTabValue] = useState(0);
  const [roleList, setRoleList] = useState<{ key: string; value: string }[]>([]);
  const [showDialog, setShowDialog] = useState(false);
  const [currentRole, setCurrentRole] = useState<string>(ROLE_MAP.LEARNER.key);
  const { user, isGroupAdmin, isSiteAdmin, isLoading: roleMapLoading } = useGetUserRoleMap(siteId);
  const role = useMemo(() => {
    switch (currentRole) {
      case 'admin':
        return GetLearningDashboardSummaryScope.admin;
      case 'manager':
        return GetLearningDashboardSummaryScope.manager;
      case 'learner':
        return GetLearningDashboardSummaryScope.learner;
      default:
        return GetLearningDashboardSummaryScope.learner;
    }
  }, [currentRole]);
  const {
    data: summaryData,
    isLoading: summaryLoading,
    refetch: refetchSummary,
    isError: summaryError,
  } = useGetLearningDashboardSummary(siteId, { scope: role });
  const isLoading = roleMapLoading;
  const { md } = useBreakpoint();
  const isMobile = useMediaQuery('(max-width: 768px)');

  // Retain filter state per tab

  const isLearner = useMemo(() => {
    return ROLE_MAP.LEARNER.key === currentRole;
  }, [currentRole]);
  const isAdminOrManager = useMemo(() => {
    return [ROLE_MAP.ADMIN.key, ROLE_MAP.MANAGER.key].includes(currentRole);
  }, [currentRole]);
  const [tempFilterAdmin1, setTempFilterAdmin1] = useState<any>({});
  const [tempFilterAdmin2, setTempFilterAdmin2] = useState<any>({});
  const [tempFilterLearner1, setTempFilterLearner1] = useState<any>({});
  const [tempFilterLearner2, setTempFilterLearner2] = useState<any>({});
  const [tempFilterLearner3, setTempFilterLearner3] = useState<any>({});
  const tempFilter = useMemo(() => {
    if (isAdminOrManager) {
      switch (tabValue) {
        case 0:
          return tempFilterAdmin1;
        case 1:
          return tempFilterAdmin2;
      }
    }
    if (isLearner) {
      switch (tabValue) {
        case 0:
          return tempFilterLearner1;
        case 1:
          return tempFilterLearner2;
        case 2:
          return tempFilterLearner3;
      }
    }
    // We shouldn't get here
    return {};
  }, [
    tempFilterAdmin1,
    tempFilterAdmin2,
    tempFilterLearner1,
    tempFilterLearner2,
    tempFilterLearner3,
    isAdminOrManager,
    isLearner,
    tabValue,
  ]);
  const prefixedTempFilter = tempFilter
    ? Object.fromEntries(Object.entries(tempFilter).map(([k, v]) => [`course__${k}`, v]))
    : {};

  useEffect(() => {
    if (roleMapLoading) return;
    const tempRoleList = [];
    if (isSiteAdmin) tempRoleList.push(ROLE_MAP.ADMIN);
    if (isGroupAdmin) tempRoleList.push(ROLE_MAP.MANAGER);
    if (tempRoleList.length > 0) tempRoleList.push(ROLE_MAP.LEARNER);
    setRoleList(tempRoleList);
    setCurrentRole(tempRoleList?.[0]?.key || ROLE_MAP.LEARNER.key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleMapLoading]);

  useEffect(() => {
    const rootEl = document.getElementById('juno-learning-scroll');
    if (rootEl?.style) rootEl.style.overflow = 'visible';
  }, []);

  useEffect(() => {
    refetchSummary();
  }, [role]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleRoleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const target = e.target as HTMLInputElement;
    setCurrentRole(target.value);
    setTabValue(0);
  };

  const transcriptReportData: GeneratedReport = {
    type: GeneratedReportTypeEnum.learningstudent_transcripts,
    site_id: siteId,
    user_id: user?.id || '',
    admin_id: null,
  };
  const transcriptReportParams: CreateGeneratedReportParams = {
    context: {
      scope: ROLE_MAP.ADMIN.key.includes(currentRole)
        ? 'all'
        : ROLE_MAP.MANAGER.key.includes(currentRole)
        ? 'learners'
        : ROLE_MAP.LEARNER.key.includes(currentRole)
        ? 'mine'
        : 'mine',
    },
    filter: prefixedTempFilter,
  };

  const onDownloadClick = () => {
    setShowDialog(true);
    createGeneratedReport(siteId, transcriptReportData, transcriptReportParams);
  };

  const onDialogConfirm = () => {
    setShowDialog(false);
  };

  if (isLoading) return <JunoSpin />;

  return (
    <Box
      sx={{
        p: md ? '44px 10px' : '44px 32px 32px',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
        borderRadius: '12px',
        position: 'relative',
        top: '40px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mb: 2,
          mt: 2,
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
      >
        {summaryError ? (
          <></>
        ) : summaryLoading ? (
          <>
            <Skeleton width={224} height={80} sx={{ transform: 'none' }} />
            <Skeleton width={224} height={80} sx={{ transform: 'none' }} />
            <Skeleton width={224} height={80} sx={{ transform: 'none' }} />
          </>
        ) : (
          <>
            <Stats value={summaryData?.completed_lessons_count} label='Completed Lessons' />
            <Stats value={summaryData?.completed_enrollment_count} label='Completed Courses' />
            <Stats value={summaryData?.credits_earned} label='Credits Earned' />
          </>
        )}
      </Box>
      {roleList.length > 0 && (
        <TextField
          id='outlined-select-currency'
          select
          label='Select'
          sx={{ width: '300px', position: 'absolute', top: '-25px', backgroundColor: '#F2F2F2' }}
          value={currentRole}
          onChange={handleRoleChange}
        >
          {roleList.map((opt) => (
            <MenuItem key={opt.key} value={opt.key}>
              {opt.value}
            </MenuItem>
          ))}
        </TextField>
      )}
      {isAdminOrManager && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isMobile ? 'column' : 'unset',
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{ mb: 2, order: isMobile ? 2 : 'unset' }}
            >
              <Tab label='Learners' sx={{ textTransform: 'none' }} />
              <Tab label='Courses' sx={{ textTransform: 'none' }} />
            </Tabs>
            <TranscriptDownloadButton
              size='small'
              startIcon={<DownloadIcon />}
              onClick={onDownloadClick}
              variant={isMobile ? 'contained' : 'text'}
              isMobile={isMobile}
            >
              Learner Transcripts
            </TranscriptDownloadButton>
          </Box>

          {tabValue === 0 && (
            <LearnersList
              site={site}
              roleType={currentRole}
              tempFilter={tempFilterAdmin1}
              setTempFilter={setTempFilterAdmin1}
            />
          )}
          {tabValue === 1 && (
            <CourseList
              siteId={siteId}
              tileType={TileType.COURSE}
              searchField={ADMIN_COURSE_SEARCH_FIELD}
              tempFilter={tempFilterAdmin2}
              setTempFilter={setTempFilterAdmin2}
              roleType={currentRole}
              userId={user?.id}
              order={ADMIN_COURSE_ORDER}
              include={ADMIN_COURSE_INCLUDE}
              fillTileContent={(item) => <></>}
              markEndOfList={true}
            />
          )}
        </>
      )}
      {isLearner && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isMobile ? 'column' : 'unset',
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              sx={{ mb: 2, order: isMobile ? 2 : 'unset' }}
            >
              <Tab label='Current' sx={{ textTransform: 'none' }} />
              <Tab label='Waitlist' sx={{ textTransform: 'none' }} />
              <Tab label='Completed' sx={{ textTransform: 'none' }} />
            </Tabs>
            <TranscriptDownloadButton
              size='small'
              startIcon={<DownloadIcon />}
              onClick={onDownloadClick}
              variant={isMobile ? 'contained' : 'text'}
              isMobile={isMobile}
            >
              My Transcripts
            </TranscriptDownloadButton>
          </Box>
          {tabValue === 0 && (
            <CourseList
              siteId={siteId}
              tileType={TileType.ENROLLMENT}
              searchField={CURRENT_COURSE_SEARCH_FIELD}
              tempFilter={tempFilterLearner1}
              setTempFilter={setTempFilterLearner1}
              filter={CURRENT_COURSE_FILTER}
              include={CURRENT_COURSE_INCLUDE}
              fillTileContent={(item) => CurrentCourseItem(item as EnrollmentModel)}
            />
          )}
          {tabValue === 1 && (
            <CourseList
              siteId={siteId}
              tileType={TileType.WAITLIST}
              searchField={WAITLISTED_COURSE_SEARCH_FIELD}
              tempFilter={tempFilterLearner2}
              setTempFilter={setTempFilterLearner2}
              include={WAITLISTED_COURSE_INCLUDE}
              fillTileContent={(item) => WaitlistedCourseItem(item as EnrollmentModel)}
            />
          )}
          {tabValue === 2 && (
            <CourseList
              siteId={siteId}
              tileType={TileType.ENROLLMENT}
              searchField={COMPLETED_COURSE_SEARCH_FIELD}
              tempFilter={tempFilterLearner3}
              setTempFilter={setTempFilterLearner3}
              filter={COMPLETED_COURSE_FILTER}
              fillTileContent={(item, breakpoints) =>
                CompletedCourseItem(item as EnrollmentModel, breakpoints)
              }
            />
          )}
        </>
      )}
      {showDialog && (
        <ConfirmDialog
          open={showDialog}
          title={'Large File'}
          children={
            <Typography>
              {
                'This file could take a minute to prepare. We’ll send you a notification as soon as your download is ready!'
              }
            </Typography>
          }
          onSave={onDialogConfirm}
        ></ConfirmDialog>
      )}
    </Box>
  );
};

export default CourseResources;
