import { Box, Card, Paper, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

export const TileWrapper = styled(Box)(() => ({
  position: 'relative',
  userSelect: 'none',
  boxShadow: 'none',
  width: '100%',
}));

export const TileGridContainer = styled(Box)(() => ({
  width: 'auto',
}));

export const TileGridTextWrapper = styled(Box)(() => ({
  display: 'grid',
  alignContent: 'center',
}));

export const EllipsisTypography = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const TileCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'idx',
})<{
  idx: number;
}>(({ idx }) => ({
  display: 'flex',
  width: '100%',
  backgroundColor: idx % 2 === 0 ? '#F2F2F2' : '#FFFFFF',
  border: 'none',
  borderRadius: '10px',
  boxShadow: 'none',
}));

export const StatusIconItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const LittleSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:is_active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.main,
  },
}));
