import React, { useEffect, useMemo, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { useInView } from 'react-intersection-observer';
import { useDebounce } from 'usehooks-ts';
import { useCreateUser, useDeleteUser, useGetAllUsers, useUpdateUser } from '@juno/client-api';
import { JunoUser, RoleEnum } from '@juno/client-api/model';
import { EditUserForm } from '@juno/ui';
import { Container } from '@juno/ui';
import { MutationAction, calculateUserSearchFilters, onMutation, useSettings } from '@juno/utils';

const MODEL = 'User';
const MODELS = 'Users';
const PAGE_SIZE = 100;

const HeaderStack = styled(Stack)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: 40,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  borderRadius: theme.spacing(1),
  marginTop: theme.spacing(2),
}));

interface userPanelProps {
  backupPlatformId?: string;
}

const UsersPanel: React.FC<userPanelProps> = ({ backupPlatformId = '' }) => {
  const { site } = useSettings();
  const platformId = site?.platform_id || '';
  const [users, setUsers] = useState<JunoUser[]>([]);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 200);
  const [searchValue2, setSearchValue2] = useState('');
  const debouncedSearchValue2 = useDebounce(searchValue2, 200);
  const [isSaving, setIsSaving] = useState(false);
  const [currentUser, setCurrentUser] = useState<JunoUser | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  // paginate on ref coming into view
  const { ref, inView } = useInView({
    threshold: 0,
    initialInView: false,
  });

  useEffect(() => {
    if (inView && !isFetching && (data?.length || 0) > 0) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView]);

  // search query
  const searchFilter = useMemo(() => {
    if (!debouncedSearchValue) return {};
    return calculateUserSearchFilters(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const searchFilter2 = useMemo(() => {
    if (!debouncedSearchValue2) return {};
    return calculateUserSearchFilters(debouncedSearchValue2);
  }, [debouncedSearchValue2]);

  useEffect(() => {
    setPage(0);
    setUsers([]);
  }, [debouncedSearchValue]);

  const { data, isFetching, refetch } = useGetAllUsers(platformId || backupPlatformId, {
    ...searchFilter,
    limit: PAGE_SIZE,
    offset: page,
    order: 'last_name',
    filter: {
      role: RoleEnum.NUMBER_0,
    },
  });

  const {
    data: admins,
    isFetching: isFetchingAdmins,
    refetch: refetchAdmins,
  } = useGetAllUsers(platformId || backupPlatformId, {
    ...searchFilter2,
    limit: PAGE_SIZE,
    offset: page,
    order: 'last_name',
    filter: {
      role: RoleEnum.NUMBER_1,
    },
  });

  // when we paginate & get new data, append the new data without dupes
  useEffect(() => {
    if (!data) return;
    setUsers((old) => {
      const joined = _.union(old, data);
      const uniq = _.uniqWith(joined, _.isEqual);
      return uniq;
    });
  }, [data]);

  // form handlers
  const handleClose = () => {
    setDialogOpen(false);
    setCurrentUser(null);
    setIsSaving(false);
  };

  const handleSave = (user: JunoUser) => {
    setIsSaving(true);
    if (currentUser?.id) {
      handleUpdate(user);
    } else {
      handleSaveCreate(user);
    }
  };

  const handleCreate = () => {
    setDialogOpen(true);
    setCurrentUser({
      id: '',
      created: '',
      enrollments_count: 0,
      enrollments_in_progress_count: '',
      access_passes: [],
      declared_tags: [],
      followed_forum_topics: [],
      email: '',
      first_name: '',
      last_name: '',
      community_groups_admin: [],
      community_groups_member: [],
      community_groups_pending_member: [],
      skills: [],
      interests: [],
    });
  };

  const handleUpdate = async (user: JunoUser) => {
    if (currentUser) {
      try {
        await userUpdate.mutateAsync({
          platformId: platformId || backupPlatformId,
          userId: user?.id || '',
          data: user,
        });
        setIsSaving(false);
        if (user.role === RoleEnum.NUMBER_0) {
          setUsers([]);
          setPage(0);
          refetch();
        } else {
          refetchAdmins();
        }
      } catch (e) {
        console.log(e);
        setIsSaving(false);
      }
    }
    handleClose();
  };

  const handleSaveCreate = async (user: JunoUser) => {
    if (currentUser) {
      try {
        await userCreate.mutateAsync({ platformId: platformId || backupPlatformId, data: user });
        setIsSaving(false);
        if (user.role === RoleEnum.NUMBER_0) {
          setUsers([]);
          setPage(0);
          refetch();
        } else {
          refetchAdmins();
        }
      } catch (e) {
        console.log(e);
        setIsSaving(false);
      }
    }
    handleClose();
  };

  const onClickRow = (user: JunoUser) => {
    setCurrentUser(user);
    setDialogOpen(true);
  };

  const userCreate = useCreateUser(onMutation(MutationAction.CREATE, MODEL, refetch));
  const userUpdate = useUpdateUser(onMutation(MutationAction.UPDATE, MODEL, refetch));
  // not using this for now but we will
  const userDelete = useDeleteUser(onMutation(MutationAction.DELETE, MODEL, refetch));

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 2 }}>
            <Typography variant='h5'>Users</Typography>
            <Divider sx={{ mt: 2, mb: 4 }} />
            <Stack direction='row' justifyContent='space-between'>
              <TextField
                value={searchValue}
                fullWidth
                size='small'
                hiddenLabel
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: 2,
                    fontSize: '0.8rem',
                    '&:hover': {
                      borderColor: 'rgba(0,0,0,.1)',
                      backgroundColor: 'rgba(0,0,0,.01)',
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0,0,0,.2)',
                        backgroundColor: 'rgba(0,0,0,.01)',
                      },
                    },
                  },
                }}
                placeholder='Search...'
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
                sx={{ maxWidth: '50%' }}
              />
              <Tooltip title='Create user' placement='right'>
                <Button
                  onClick={handleCreate}
                  sx={{ ml: 2, minWidth: 48 }}
                  size='small'
                  variant='contained'
                >
                  Create User
                </Button>
              </Tooltip>
            </Stack>

            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              spacing={2}
              sx={{ pt: 3, pb: 2 }}
            >
              {/* <Button
              variant='contained'
              size='medium'
              sx={{ minWidth: 120, height: 56 }}
              onClick={handleCreate}
            >
              Create User
            </Button> */}
            </Stack>
            {/* <HeaderStack direction='row' alignItems='center' spacing={4}>
          <Typography fontWeight={'bold'} sx={{ width: '30%' }}>
            User
          </Typography>{' '}
          <Box sx={{ width: 44 }} />
          <Typography fontWeight={'bold'} sx={{ width: '30%' }}>
            Tags
          </Typography>
          <Typography fontWeight={'bold'} sx={{ width: '30%' }}>
            Access Passes
          </Typography>
        </HeaderStack> */}
            <Stack maxWidth={400}>
              {users.map((user, i) => (
                <UserListItem
                  key={user.id}
                  user={user}
                  onClickRow={onClickRow}
                  isSelected={user.id === currentUser?.id}
                  index={i}
                />
              ))}
            </Stack>
            {(users.length % PAGE_SIZE === 0 || isFetching) && (
              <Box height={30} textAlign={'center'}>
                <CircularProgress variant='indeterminate' size={24} sx={{ mt: 2 }} />
                <Box ref={ref} sx={{ height: '10px' }} />
              </Box>
            )}
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ p: 2 }}>
            <Typography variant='h5'>Admins</Typography>
            <Divider sx={{ mt: 2, mb: 4 }} />
            <TextField
              value={searchValue2}
              fullWidth
              hiddenLabel
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: 2,
                  fontSize: '0.8rem',
                  '&:hover': {
                    borderColor: 'rgba(0,0,0,.1)',
                    backgroundColor: 'rgba(0,0,0,.01)',
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(0,0,0,.2)',
                      backgroundColor: 'rgba(0,0,0,.01)',
                    },
                  },
                },
              }}
              placeholder='Search...' // The placeholder is not a param so that all search fields are consistent
              size='small'
              onChange={(e) => {
                setSearchValue2(e.target.value);
              }}
              sx={{ maxWidth: '50%' }}
            />

            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              spacing={2}
              sx={{ pt: 3, pb: 2 }}
            ></Stack>
            {/* <HeaderStack direction='row' alignItems='center' spacing={4}>
          <Typography fontWeight={'bold'} sx={{ width: '30%' }}>
            User
          </Typography>{' '}
          <Box sx={{ width: 44 }} />
          <Typography fontWeight={'bold'} sx={{ width: '30%' }}>
            Tags
          </Typography>
          <Typography fontWeight={'bold'} sx={{ width: '30%' }}>
            Access Passes
          </Typography>
        </HeaderStack> */}
            <Stack maxWidth={400}>
              {admins?.map((user, i) => (
                <UserListItem
                  key={user.id}
                  user={user}
                  onClickRow={onClickRow}
                  isSelected={user.id === currentUser?.id}
                  index={i}
                />
              ))}
            </Stack>
            {isFetchingAdmins && (
              <Box height={30} textAlign={'center'}>
                <CircularProgress variant='indeterminate' size={24} sx={{ mt: 2 }} />
              </Box>
            )}
          </Card>
        </Grid>
        <EditUserForm
          currentUser={currentUser}
          dialogOpen={dialogOpen}
          handleClose={handleClose}
          handleSave={handleSave}
          isSaving={isSaving}
        />
      </Grid>
    </Container>
  );
};

const Item = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1),
  cursor: 'pointer',
  overflow: 'hidden',
  transition: 'all 0.25s ease-in-out',
  borderRadius: theme.spacing(1),
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,.03)',
    maxHeight: 300,
  },
}));

const Email = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const ChipSection = styled(Box)(({ theme }) => ({
  width: '30%',
  overflow: 'hidden',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(0.5),
}));

const UserChip = styled(Chip)(({ theme }) => ({
  padding: 0,
  height: 26,
}));

interface UserListItemProps {
  user: JunoUser;
  onClickRow: (user: JunoUser) => void;
  isSelected: boolean;
  index: number;
}

export const UserListItem: React.FC<UserListItemProps> = ({
  user,
  onClickRow,
  isSelected,
  index,
}) => {
  return (
    <Item
      direction='row'
      spacing={4}
      alignItems='center'
      onClick={() => onClickRow(user)}
      sx={{
        maxHeight: isSelected ? 300 : 80,
        background: index % 2 !== 0 ? '#f5f5f5' : 'transparent',
      }}
    >
      <Avatar src={user.avatar || user.icon || ''} sx={{ width: 36, height: 36 }} />
      <Stack sx={{ width: '30%' }}>
        <Typography lineHeight={1.1}>
          {user.first_name} {user.last_name}
        </Typography>
        <Email variant='body2' sx={{ opacity: 0.6 }}>
          {user.email}
        </Email>
      </Stack>
    </Item>
  );
};

export default UsersPanel;
