import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useDeleteCourseRequirement,
  useGetAllCourseRequirements,
  useGetCourses,
  useUpdateCourse,
  useUpdateCourseRequirement,
} from '@juno/client-api';
import { CourseRequirement, Site } from '@juno/client-api/model';
import { AdminEditPanelHeader, JunoSpin, LoseChangesDialog } from '@juno/ui';
import { MutationAction, onMutation } from '@juno/utils';
import EditForm from './helpers/EditForm';

interface PrereqEditPageProps {
  site: Site;
}
const PrereqEditPage: React.FC<PrereqEditPageProps> = ({ site }) => {
  const { prereqId } = useParams();
  const navigate = useNavigate();
  const {
    data: prereqs,
    isLoading: isLoadingPrereqs,
    refetch: refetchPrereqs,
  } = useGetAllCourseRequirements(site.id);
  const [chosenReq, setChosenReq] = useState<CourseRequirement>(
    prereqs?.filter((r) => r.id === prereqId)[0] ?? ({} as CourseRequirement),
  );
  const [openLoseChanges, setOpenLoseChanges] = useState(false);
  const [changed, setChanged] = useState(false);
  const reqFormRef = useRef<any>(null);

  const updatePrereq = useUpdateCourseRequirement(
    onMutation(MutationAction.UPDATE, 'CourseRequirements', refetchPrereqs),
  );

  const handleUpdate = (values: any) => {
    updatePrereq
      .mutateAsync({ siteId: site.id, requirementId: chosenReq.id, data: values })
      .then((_resp: any) => {
        setChanged(false);
      });
  };

  const deletePrereq = useDeleteCourseRequirement(
    onMutation(MutationAction.DELETE, 'CourseRequirements', refetchPrereqs),
  );

  const handleDelete = () => {
    deletePrereq
      .mutateAsync({ siteId: site.id, requirementId: chosenReq.id })
      .then((_resp: any) => {
        navigate('../');
      });
  };

  useEffect(() => {
    setChosenReq(prereqs?.filter((r) => r.id === prereqId)[0] ?? ({} as CourseRequirement));
  }, [prereqs]);

  const siteId = site.id;
  const { data: allCourses, isLoading: isLoadingAllCourses } = useGetCourses(siteId);
  const {
    data: grantsRequirementsCourses,
    isLoading: isLoadingGrantsRequirementsCourses,
    refetch: refetchGrantsRequirementsCourses,
  } = useGetCourses(siteId, { filter: { grants_requirements: chosenReq.id } });
  const updateCourse = useUpdateCourse(
    onMutation(MutationAction.UPDATE, 'Course', refetchGrantsRequirementsCourses),
  );

  const handleUpdateCourse = (values: any) => {
    const removed = grantsRequirementsCourses?.filter((c) => c.id !== values.id);
    const added = allCourses?.filter((item) => {
      return values.grants_requirements?.find((element: any) => {
        return element.id === item.id;
      });
    });
    removed?.forEach((c) => {
      updateCourse
        .mutateAsync({ siteId: site.id, courseId: c.id, data: { ...c, grants_requirements: [] } })
        .then((_resp: any) => {
          setChanged(false);
        });
    });
    if (added && added?.length > 0) {
      added?.forEach((c) => {
        c.grants_requirements = [values.id];
        updateCourse
          .mutateAsync({ siteId: site.id, courseId: c.id, data: c })
          .then((_resp: any) => {
            setChanged(false);
          });
      });
    }
  };

  if (
    !chosenReq.id ||
    isLoadingPrereqs ||
    isLoadingAllCourses ||
    isLoadingGrantsRequirementsCourses
  ) {
    return <JunoSpin />;
  }
  return (
    <Box sx={{ width: '100%', maxWidth: '760px' }}>
      <AdminEditPanelHeader
        shouldShow={changed ?? false}
        title={chosenReq?.name ?? ''}
        onClickDiscard={() => {
          reqFormRef.current?.resetForm();
        }}
        onClickSave={() => reqFormRef.current.handleSubmit()}
        onClickGoBack={() => {
          if (changed) {
            setOpenLoseChanges(true);
          } else {
            navigate(-1);
          }
        }}
      />
      <Box sx={{ pt: 3, pb: 3 }}>
        <Formik
          key={'info-form'}
          enableReinitialize={true}
          innerRef={reqFormRef}
          initialValues={{
            ...chosenReq,
            grants_requirements: grantsRequirementsCourses,
          }}
          onSubmit={(values) => {
            handleUpdate(values);
            handleUpdateCourse(values);
          }}
        >
          {({ values, setFieldValue, handleChange, setFieldTouched, handleBlur, dirty }) => {
            return (
              <EditForm
                allCourses={allCourses}
                req={values}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                handleChange={handleChange}
                handleDirty={() => {
                  setChanged(dirty);
                }}
                handleDelete={handleDelete}
                prereqs={prereqs}
              />
            );
          }}
        </Formik>
      </Box>
      {openLoseChanges && (
        <LoseChangesDialog
          handleCancel={() => {
            setOpenLoseChanges(false);
          }}
          handleDiscard={() => {
            reqFormRef.current?.resetForm();
            setOpenLoseChanges(false);
          }}
          handleSave={() => {
            setOpenLoseChanges(false);
            reqFormRef.current?.handleSubmit();
          }}
        />
      )}
    </Box>
  );
};
export default PrereqEditPage;
