import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { useLocation } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import { useGetPages, useGetSite } from '@juno/client-api';
import { Site } from '@juno/client-api/model';
import {
  FeatureConfig as FeatureConfigUtils,
  NavigationMap,
  Page as PageUtils,
  Site as SiteUtils,
} from '@juno/client-api/utils';
import { Header, Messenger } from '@juno/modules';
import { ReportContentDialog } from '@juno/ui';
import { useBreakpoint, useSettings } from '@juno/utils';
import DangerousElement from '../DangerousElement';
import SideBar from '../SideBar';
import { LayoutContainer, LayoutDrawer, LayoutMainBox } from './styles';

interface LayoutProps {
  siteId: string;
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ siteId, children }) => {
  const { showMessenger, setShowMessenger, showSideMenu, setShowSideMenu, isSmallScreen, configs } =
    useSettings();

  const location = useLocation();
  const auth = useAuth();
  const { pathname } = location;
  const { xs, sm, md } = useBreakpoint();
  const slug = pathname.replace(/^\/+/, '');
  const { data: pages, isLoading: pagesLoading } = useGetPages(siteId);
  const { data: site, isLoading: siteLoading } = useGetSite(siteId);
  const isLoading = pagesLoading || siteLoading;

  useEffect(() => {
    if (!auth.isAuthenticated) return;
    Userpilot.reload();
  }, [location]);

  const page = useMemo(() => PageUtils.getPageBySlug(pages, slug), [slug, pages]);
  const homepage = useMemo(() => SiteUtils.getHomepageUrl(site), [site]);

  const messengerDisabled = FeatureConfigUtils.getThemeConfig(configs)?.config?.hide_messenger;

  const handleToggleDrawer = () => setShowSideMenu && setShowSideMenu(!showSideMenu);

  const isAdmin = pathname.includes('/admin');
  const isPageBuilder = pathname.includes('/admin/pages/');

  const drawerWidth = 290;
  const mainWidth = `calc(100% - ${
    (showSideMenu ? drawerWidth : 0) + (showMessenger ? drawerWidth : 0)
  }px)`;

  useEffect(() => {
    if (isPageBuilder || pathname.includes('/edit')) {
      setShowSideMenu && setShowSideMenu(false);
      setShowMessenger && setShowMessenger(false);
    }
    if (isAdmin && !isPageBuilder && !xs && !sm && !md) {
      setShowSideMenu && setShowSideMenu(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (xs || sm || md) {
      setShowSideMenu && setShowSideMenu(false);
    }
  }, [xs, sm, md]);

  const embedValue = configs?.find((config) => config.type === 'embed')?.config?.embed as
    | string
    | undefined;

  if (page?.metadata?.hideLayout) return <div>{children}</div>;

  return (
    <LayoutContainer showSideMenu={showSideMenu}>
      <Header
        onMoreButtonClick={handleToggleDrawer}
        showSideMenu={false}
        navigationMap={{} as NavigationMap}
        homepage={homepage}
      />
      <LayoutDrawer
        open={showSideMenu}
        variant={isSmallScreen ? 'temporary' : 'persistent'}
        onClose={() => setShowSideMenu && setShowSideMenu(false)}
        ModalProps={{
          keepMounted: true,
          slotProps: { backdrop: { sx: { backgroundColor: 'rgba(0, 0, 0, .3)' } } },
        }}
        drawerWidth={drawerWidth}
        isSmallScreen={isSmallScreen}
      >
        <SideBar site={site || ({} as Site)} />
      </LayoutDrawer>
      <LayoutMainBox
        drawerWidth={drawerWidth}
        mainWidth={mainWidth}
        isSmallScreen={isSmallScreen}
        showSideMenu={showSideMenu}
        showMessenger={showMessenger}
        isAdmin={isAdmin}
        isPageBuilder={isPageBuilder}
      >
        <Box
          maxWidth={'100%'}
          sx={{
            borderRadius:
              isSmallScreen || isPageBuilder ? 0 : showSideMenu || showMessenger ? 2 : 0,
            overflow: 'hidden',
            p: isSmallScreen ? 1 : 'default',
            paddingBottom: isSmallScreen ? '60px' : 0,
            mb: 1,
          }}
        >
          <Box>{children}</Box>
        </Box>
      </LayoutMainBox>
      {!messengerDisabled && (
        <LayoutDrawer
          anchor={'right'}
          open={showMessenger}
          variant={isSmallScreen ? 'temporary' : 'persistent'}
          drawerWidth={drawerWidth}
          isSmallScreen={isSmallScreen}
          onClose={() => setShowMessenger && setShowMessenger(false)}
          ModalProps={{
            keepMounted: true,
            slotProps: { backdrop: { sx: { backgroundColor: 'rgba(0, 0, 0, .3)' } } },
          }}
        >
          <Messenger />
        </LayoutDrawer>
      )}
      {embedValue && <DangerousElement markup={embedValue} />}
      <ReportContentDialog />
    </LayoutContainer>
  );
};

export default Layout;
