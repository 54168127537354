import React from 'react';
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';
import { Button, Card, Typography } from '@mui/material';

interface RowCreateTileProps {
  onClick?: () => void;
  text?: string;
  disabled?: boolean;
}

/**
 * Use for all vnext "create new" tiles to maintain consistency
 * @param onClick () => void
 * @param text string
 * @param disabled boolean
 * @example
 * <RowCreateTile onClick={onClick} text={title} disabled={disabled} />
 *
 */
const RowCreateTile: React.FC<RowCreateTileProps> = ({ onClick, text, disabled }) => {
  return (
    <Button
      onClick={() => {
        if (disabled) return;
        onClick && onClick();
      }}
      sx={{
        opacity: disabled ? 0.3 : 0.7,
        cursor: 'pointer',
        justifyContent: 'flex-start',
        boxShadow: 1,
        mb: 1,
        mt: 4,
      }}
      variant='contained'
      component='div'
      size='large'
    >
      <AddCircleOutlineIcon sx={{ mr: 3 }} />
      <Typography>{text}</Typography>
    </Button>
  );
};

export default RowCreateTile;
