import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const baseStyle = {
  transition: 'border .24s ease-in-out',
};

export const focusedStyle = {
  borderColor: '#2196f3',
};

export const acceptStyle = {
  borderColor: '#00e676',
};

export const rejectStyle = {
  borderColor: '#ff1744',
};

export const FileUploadWrapper = styled(Box)(({ theme }) => ({
  height: 82,
  position: 'relative',
  padding: '16px 12px 18px',
  '& .label': {
    position: 'absolute',
    left: 20,
    top: -10,
  },
  '& fieldset': {
    position: 'absolute',
    inset: '-12px 0 6px',
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.spacing(0.5),
    '& legend': {
      '& .legend-label': {
        opacity: 0,
        fontSize: 12,
        padding: `0 ${theme.spacing(0.5)}`,
      },
    },
  },
  '& .MuiCircularProgress-root.background': {
    position: 'absolute',
    '& .MuiCircularProgress-circle': {
      stroke: 'white',
    },
  },
  '& .uploadBtn': {
    height: '100%',
    width: '100%',
    border: `1px dashed #999999`,
    color: '#999',
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    gap: 4,

    '& span': {
      lineHeight: 1,
    },
  },
}));

export const UploadStatusWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(),
  justifyContent: 'space-between',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.1)',
  border: '1px solid rgba(0, 0, 0, 0.25)',
  padding: '12px 12px 8px',
  borderRadius: theme.spacing(0.5),
}));
