import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

interface AdminDefaultViewHeaderProps {
  title: string;
  children?: React.ReactNode;
}

/**
 * For best visual results wrap with a Box component that has sx={{ width: '100%', p: 3, m: 0 }}
 * @param title The title of the view
 * @param Icon The icon to display
 * @example
 * <Box sx={{ width: '100%', p: 3, m: 0 }}>
 *  <AdminDefaultViewHeader title='Dashboard' Icon={DashboardIcon} />
 * </Box>
 */
const AdminDefaultViewHeader: React.FC<AdminDefaultViewHeaderProps> = ({ title, children }) => {
  return (
    <Stack
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      p={'12px 0 16px 0'}
      sx={{ width: '100%' }}
    >
      <Typography variant='h4' sx={{ mt: 3, mb: 3, fontWeight: 600 }}>
        {title}
      </Typography>
      {children && <Box>{children}</Box>}
    </Stack>
  );
};
export default AdminDefaultViewHeader;
