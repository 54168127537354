import React from 'react';
import { Box, Card, Grid, Skeleton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useGetCourses } from '@juno/client-api';
import { Course } from '@juno/client-api/model';
import { SkeletonUserTile } from '@juno/ui';
import { useSettings } from '@juno/utils';

const dummyArr = [1, 2, 3];

const YourCourses: React.FC = () => {
  const { site, user } = useSettings();
  const { data: inProgress, isLoading: isLoadingInProgress } = useGetCourses(site?.id || '', {
    filter: { enrollments__user__id: user?.id || '', enrollments__date_completed__isnull: true },
  });
  const { data: completed, isLoading: isLoadingCompleted } = useGetCourses(site?.id || '', {
    filter: { enrollments__user__id: user?.id || '', enrollments__date_completed__isnull: false },
  });

  return (
    <>
      <Card sx={{ mt: 2, p: 3 }}>
        <Typography variant='h6'>In progress</Typography>
        <Grid container spacing={2} mt={1}>
          {isLoadingInProgress && dummyArr.map((i) => <CourseTileSkeleton />)}
          {!isLoadingInProgress &&
            inProgress?.map((course) => (
              <Grid item xs={12} md={4}>
                <CourseTile course={course} siteSlug={site?.slug || ''} />
              </Grid>
            ))}
        </Grid>
      </Card>
      <Card sx={{ mt: 2, p: 3 }}>
        <Typography variant='h6'>Completed</Typography>
        <Grid container spacing={2} mt={1}>
          {isLoadingCompleted && dummyArr.map((i) => <CourseTileSkeleton />)}
          {!isLoadingCompleted &&
            completed?.map((course) => (
              <Grid item xs={12} md={4}>
                <CourseTile course={course} siteSlug={site?.slug || ''} />
              </Grid>
            ))}
        </Grid>
      </Card>
    </>
  );
};

export default YourCourses;

interface CourseTileProps {
  course: Course;
  siteSlug: string;
}
const CourseTile: React.FC<CourseTileProps> = ({ course, siteSlug }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(`/${siteSlug}/learning/courses/${course.slug}`)}
    >
      <Box
        sx={{
          background: `url(${course.icon})`,
          backgroundSize: 'cover',
          p: 3,
          borderRadius: 3,
          height: 150,
        }}
      ></Box>
      <Typography sx={{ fontWeight: 'bold', fontSize: '.9rem', mt: 0.5, ml: 1 }}>
        {course.title}
      </Typography>
      <Typography sx={{ ml: 1 }} variant='body2'>
        {course.abstract}
      </Typography>
    </Box>
  );
};

const CourseTileSkeleton: React.FC = () => {
  return (
    <Grid item xs={12} md={4}>
      <Skeleton variant='rectangular' sx={{ height: 150, borderRadius: 3 }} />
      <Skeleton variant='text' sx={{ width: '50%', mt: 0.5 }} />
      <Skeleton variant='text' sx={{ width: '75%', mt: -0.5 }} />
    </Grid>
  );
};
