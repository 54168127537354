import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingButton } from '@mui/lab';
import { Box, Card, IconButton, Stack, TextField, Typography } from '@mui/material';
import { Form, FormikProps } from 'formik';
import { Reorder, motion } from 'framer-motion';
import {
  FeatureConfigTypeEnum,
  SidebarWidget,
  SidebarWidgetTypeEnum,
} from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import { UserSidebar } from '../../SideBar';

interface CustomizeSidebarProps {
  formik: FormikProps<SidebarWidget[]>;
  setPageDirty3: Dispatch<SetStateAction<boolean>>;
}

const CustomizeSidebar: React.FC<CustomizeSidebarProps> = ({ formik, setPageDirty3 }) => {
  const { enableLearning } = useSettings();
  const { isSubmitting, dirty, setValues, values: unfilteredValues } = formik;
  const [editing, setEditing] = useState('');
  const [error, setError] = useState<boolean>(false);

  const handleReorder = (newOrder: SidebarWidget[]) => {
    setValues(newOrder.map((item, i) => ({ ...item, order: i })));
  };

  useEffect(() => {
    setPageDirty3(dirty);
  }, [dirty]);

  const values = useMemo(
    () =>
      unfilteredValues.filter((widget) => {
        if (widget.type === SidebarWidgetTypeEnum.courses && !enableLearning) return false;
        return true;
      }),
    [unfilteredValues, enableLearning],
  );

  return (
    <Form>
      <Card sx={{ p: 2, mt: 2, mb: 4, boxShadow: 1 }}>
        <Typography variant='overline'>Customize Sidebar</Typography>
        <Typography variant='body2' sx={{ mt: 2, mb: 4 }}>
          Reorder, show/hide, or rename the cards in the left sidebar.
        </Typography>
        <Stack direction='row' mt={4} mb={4}>
          <Box minWidth='50%'>
            <motion.div>
              <Reorder.Group
                axis='y'
                values={values}
                onReorder={handleReorder}
                as='div'
                style={{ listStyleType: 'none' }}
              >
                {values
                  .sort((a, b) => (a.order || 0) - (b.order || 0))
                  .map((item, index) => (
                    <Reorder.Item value={item} key={`${item.id}`}>
                      <Stack
                        direction='row'
                        alignItems='center'
                        sx={{
                          border: '1px solid rgba(0,0,0,.1)',
                          mt: 1.25,
                          mb: 1.25,
                          paddingX: 2,
                          paddingY: 1,
                          background: 'rgba(0,0,0,.02)',
                          borderRadius: 2,
                          cursor: 'grab',
                          minHeight: 62,
                          '&:hover': {
                            background: 'rgba(0,0,0,.04)',
                          },
                        }}
                        justifyContent='space-between'
                      >
                        <Stack direction='row' alignItems='center'>
                          <Stack>
                            {editing === item.id ? (
                              <TextField
                                label='Name'
                                value={item.name}
                                size='small'
                                name='name'
                                onChange={(e) => {
                                  setValues((old) =>
                                    old.map((i) => {
                                      if (i.id === item.id) {
                                        return { ...i, name: e.target.value };
                                      }
                                      return i;
                                    }),
                                  );

                                  if (e.target.value.length > 30) setError(true);
                                  else setError(false);
                                }}
                                error={error}
                                helperText={error && 'Text must not exceed 30 characters'}
                              />
                            ) : (
                              <Typography variant='subtitle1' sx={{ ml: 1 }}>
                                {item.name}
                              </Typography>
                            )}

                            <Typography variant='body2' sx={{ ml: 1 }}></Typography>
                          </Stack>
                        </Stack>

                        <Stack direction='row'>
                          {editing === item.id ? (
                            <IconButton
                              onClick={() => {
                                setEditing('');
                              }}
                              size='small'
                            >
                              <CheckIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                setEditing(item.id);
                              }}
                              size='small'
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={() => {
                              setValues((old) =>
                                old.map((i) => {
                                  if (i.id === item.id) {
                                    return { ...i, hidden: !item.hidden };
                                  }
                                  return i;
                                }),
                              );
                            }}
                            size='small'
                            sx={{ mr: 1 }}
                          >
                            {item.hidden ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </Stack>
                      </Stack>
                    </Reorder.Item>
                  ))}
              </Reorder.Group>
            </motion.div>
          </Box>
          <Box
            sx={{
              width: '100%',
              maxWidth: 300,
              ml: 'auto',
              boxShadow: 'none',
              '.MuiAvatar-root': {
                width: 30,
                height: 30,
              },
            }}
          >
            <UserSidebar hideEmptyWidgets={false} sidebarWidgets={values} />
          </Box>
        </Stack>
        <Box textAlign='right'>
          <LoadingButton
            loading={isSubmitting}
            variant='contained'
            type='submit'
            disabled={!dirty || error}
            sx={{ mt: 3 }}
          >
            Save
          </LoadingButton>
        </Box>
      </Card>
    </Form>
  );
};

export default CustomizeSidebar;
