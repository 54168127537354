import React, { useEffect, useMemo, useState } from 'react';
import { DailyCall } from '@daily-co/daily-js';
import { useNavigate } from 'react-router-dom';
import { getRoomsPresence, useGetAllSessions } from '@juno/client-api';
import { JunoUser, Session, SessionSubBreakout } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import { useCallState } from '../../../contexts/CallProvider';
import RoomsLayoutComponent, { RoomItem } from './RoomsLayoutComponent';

interface LiveBreakoutRoomsProps {
  subBreakouts: SessionSubBreakout[] | undefined;
  setHasStartedBreakouts: React.Dispatch<React.SetStateAction<boolean>>;
}
const LiveBreakoutRooms: React.FC<LiveBreakoutRoomsProps> = ({
  subBreakouts,
  setHasStartedBreakouts,
}) => {
  const { site } = useSettings();
  const { roomInfo, callObject } = useCallState();
  const navigate = useNavigate();
  const sessionData = useMemo(() => {
    if (roomInfo) {
      return (roomInfo as Record<string, unknown>)['session'] as Session;
    }
    return {} as Session;
  }, [roomInfo]);
  const [roomsWithPresence, setRoomsWithPresence] = useState<RoomItem[]>([]);

  const { data: allRoomSessions } = useGetAllSessions(
    site?.id || '',
    {
      order: 'date_start',
      filter: {
        id__in: subBreakouts
          ?.filter((breakout) => breakout.actual_length_in_ms === 0)
          ?.map((subBreakout) => subBreakout.session),
        subbreakout: true,
      },
    },
    { query: { enabled: !!site?.id && !!subBreakouts } },
  );
  const fetchParticipants = async () => {
    const roomPresence: any = await getRoomsPresence(site?.id || '', sessionData?.id || '');

    const rooms = roomPresence?.presence?.map((room: any) => {
      return {
        id: room.id,
        name: room.name,
        users: room.users.map((user: any) => {
          return {
            id: user.id,
            first_name: user.userName,
          } as JunoUser;
        }),
      } as RoomItem;
    });

    setRoomsWithPresence(rooms);
  };
  const getSessionForRoom = (roomId: string) => {
    if (roomId === sessionData.id) {
      return sessionData;
    } else if (roomId === subBreakouts?.[0].parent_session) {
      return { slug: subBreakouts?.[0].parent_session } as Session;
    } else {
      return allRoomSessions?.find((session) => session.id === roomId);
    }
  };
  const onJoinRoom = async (roomId: string | number) => {
    if (sessionData?.subbreakout !== true) {
      // we are in the main room moving to a subbreakout, set localstorage to know where to go back to
      localStorage.setItem('parentSession', sessionData?.slug || '');
    }
    const session = getSessionForRoom(roomId as string);
    if (session) {
      const daily = callObject as unknown as DailyCall;
      await daily?.leave();
      navigate(`/${site?.slug}/sessions/${session.slug}`);
    }
  };
  let interval: NodeJS.Timeout | null = null;
  useEffect(() => {
    if (interval) clearInterval(interval);
    fetchParticipants();
    // a timer to refetch the room presence every 10 seconds
    interval = setInterval(() => {
      fetchParticipants();
    }, 10000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [allRoomSessions, subBreakouts]);
  return (
    <>
      <RoomsLayoutComponent
        roomsActive={true}
        rooms={roomsWithPresence}
        setRooms={() => {
          // TODO
        }}
        setHasMovedUser={() => {
          //TODO
        }}
        onJoinRoom={(roomId: string | number) => onJoinRoom(roomId)}
      />
    </>
  );
};
export default LiveBreakoutRooms;

type DailyPresenceData = {
  room: string;
  joinTime: string;
  duration: number;
  id: string;
  userId: string | null;
  userName: string;
};
