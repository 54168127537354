import React, { useMemo } from 'react';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import { Button, IconButton, Typography } from '@mui/material';
import { useLikeUnlikeSession } from '@juno/client-api';
import { Session } from '@juno/client-api/model';
import { MutationAction, onMutation, useSettings } from '@juno/utils';

interface SessionLikeButtonProps {
  sessionData: Session;
  refetchSession: () => void;
}
const SessionLikeButton: React.FC<SessionLikeButtonProps> = ({ sessionData, refetchSession }) => {
  const { site, user: thisUser } = useSettings();
  const { liked_users } = sessionData;
  const likeUnlikeSession = useLikeUnlikeSession(
    onMutation(MutationAction.UPDATE, '', refetchSession),
  );
  const handleLikeButtonClick = async () => {
    await likeUnlikeSession.mutateAsync({ siteId: site?.id || '', sessionId: sessionData.id });
    refetchSession();
  };

  const iHaveLiked = useMemo(() => {
    return liked_users?.filter((user) => user.id === thisUser?.id).length > 0;
  }, [thisUser, sessionData]);

  return (
    <IconButton
      color={iHaveLiked ? 'primary' : 'default'}
      onClick={handleLikeButtonClick}
      size='small'
    >
      <ThumbUpAltRoundedIcon />
      <Typography variant='caption' sx={{ ml: '4px' }}>
        {liked_users?.length > 0 ? liked_users?.length : ''}{' '}
        {liked_users?.length <= 1 ? 'Like' : 'Likes'}
      </Typography>
    </IconButton>
  );
};
export default SessionLikeButton;
