import React, { useEffect, useRef } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { JunoUser } from '@juno/client-api/model';
import { Channel, Message, useMessengerContext, useSettings } from '@juno/utils';
import { ConversationRow, ConversationRowProps } from './ConversationRow';
import MessageInput from './MessageInput';

export const Conversation = () => {
  const { user } = useSettings();

  const {
    setIsConversation,
    currentChannel,
    messengerMap,
    setCurrentChannel,
    currentConversationLastReadMessageId,
  } = useMessengerContext();

  const [conversationRowProps, setConversationRowProps] = React.useState<ConversationRowProps[]>(
    [],
  );
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const messageVariants = {
    initial: {
      opacity: 0,
      y: 10,
      transition: { duration: 2 }, // Adjust the duration as needed
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: { duration: 1.25 }, // Adjust the duration as needed
    },
    exit: {
      opacity: 0,
      y: -50,
      transition: { duration: 1.25 }, // Adjust the duration as needed
    },
  };

  useEffect(() => {
    const conversationsRowPropsNew: ConversationRowProps[] = [];
    if (currentChannel && !currentChannel.includes('system-') && messengerMap.channels) {
      const channel = messengerMap.channels.find(
        (channel: Channel) => channel.id === currentChannel,
      );

      if (channel) {
        channel.messages.forEach((message: Message) => {
          const currentUser = channel.junoUsers.find(
            (junoUser: JunoUser) => junoUser.id === message.userId,
          );
          if (currentUser) {
            conversationsRowPropsNew.push({
              junoUser: currentUser,
              messageText: message.text,
              messageCreatedAt: message.createdAt,
              isNewMessage: message.isNewMessage || false,
              isCurrentUser: message.userId === user?.id,
            });
          }
        });
      }
    }
    setConversationRowProps(conversationsRowPropsNew);
  }, [messengerMap, currentChannel]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const scrollContainer = scrollContainerRef.current;
      const scrollHeight = scrollContainer.scrollHeight;
      const clientHeight = scrollContainer.clientHeight;

      // Directly setting the scrollTop property to scroll to the bottom
      scrollContainer.scrollTop = scrollHeight - clientHeight;
    }
  }, [conversationRowProps]); // Dependency on ConversationRowProps to trigger scroll on update

  const [names, setNames] = React.useState<string>('');
  useEffect(() => {
    setNames(
      messengerMap.channels
        .find((channel) => channel.id === currentChannel)
        ?.junoUsers.map((junoUser) => junoUser.first_name)
        .join(', ') || '',
    );
  }, [currentChannel, messengerMap.channels]);

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <IconButton
            sx={{ mt: 1, mb: 0 }}
            aria-label='back'
            onClick={() => {
              setIsConversation(false);
              setCurrentChannel('NONE');
            }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant='subtitle2'
            component='h5'
            sx={{
              pt: 2,
              pb: 0,
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '.95rem',
            }}
          >
            Conversation
          </Typography>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={12}>
          <Typography
            variant='subtitle2'
            component='h5'
            sx={{
              pl: 2,
              pb: 1,
              pt: 0,
              textAlign: 'left',
              fontSize: '.90rem',
            }}
          >
            {names}
          </Typography>
        </Grid>
      </Grid>
      <Box
        ref={scrollContainerRef}
        sx={{
          overflowY: 'scroll',
          height: 'calc(100vh - 500px)',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          minHeight: 200,
        }}
      >
        {conversationRowProps.map((props, index) => (
          <motion.div
            key={index}
            variants={messageVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            layout
          >
            {props.messageCreatedAt &&
              props.messageCreatedAt === currentConversationLastReadMessageId && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Divider sx={{ flexGrow: 1, my: 2 }} />
                  <Typography variant='body1' component='span' sx={{ whiteSpace: 'nowrap' }}>
                    New
                  </Typography>
                  <ArrowDownwardIcon fontSize={'small'} />
                  <Divider sx={{ flexGrow: 1, my: 2 }} />
                </Box>
              )}
            <ConversationRow {...props} />
          </motion.div>
        ))}
      </Box>
      <MessageInput />
    </Box>
  );
};
