import {
  addDays,
  addHours,
  addMilliseconds,
  addMonths,
  addYears,
  differenceInDays,
  differenceInHours,
  differenceInMilliseconds,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDate,
  getDay,
  getHours,
  getMilliseconds,
  getMinutes,
  getMonth,
  getSeconds,
  isAfter,
  isBefore,
  isToday,
  set,
  setMonth,
  startOfDay,
  startOfMonth,
} from 'date-fns';
import { enUS } from 'date-fns/locale';
import { ReoccurrenceEnum, Session } from '@juno/client-api/model';

/**
 * ? method getRelativeStartsIn
 * * DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 *
 * @param date_start - The start date of the event
 * @param date_end - The end date of the event
 * @param reoccurrence - The reoccurrence pattern of the event
 * @param time_end - The end time of the event
 * @returns {string} The relative start time of the event
 *
 * ? Note: The function should return the relative start time of the event based on the current date and time such as:
 * ? - "Live" if the event is currently ongoing
 * ? - "Today at {start_time} - {end_time}" if the event is today
 * ? - "Tomorrow at {start_time} - {end_time}" if the event is tomorrow
 * ? - "{day} at {start_time} - {end_time}" if the event is later this week
 * ? - "Ended" if the event has already ended
 *
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */

export const getRelativeStartsIn = (
  date_start: string,
  date_end: string,
  reoccurrence?: ReoccurrenceEnum | null,
  time_end?: string | null,
): string => {
  const now = new Date();
  const startDate = new Date(date_start);
  const startTime = new Date(date_start);
  const endTime = time_end ? new Date(time_end) : new Date(date_end);
  const endDate = new Date(date_end);

  // Check if the event has already ended
  if (isBefore(endDate, now)) {
    return 'Ended';
  }

  // Calculate the next occurrence based on reoccurrence pattern
  if (reoccurrence) {
    switch (reoccurrence) {
      case ReoccurrenceEnum.once:
        if (isBefore(startDate, now) && isAfter(endDate, now)) {
          return 'Live';
        } else if (startDate.toDateString() === now.toDateString()) {
          return `Today at ${format(startTime, 'p')} - ${format(endTime, 'p')}`;
        } else if (startDate.getDate() === now.getDate() + 1) {
          return `Tomorrow at ${format(startTime, 'p')} - ${format(endTime, 'p')}`;
        } else {
          return `${format(startDate, 'EEEE')} at ${format(startTime, 'p')} - ${format(
            endTime,
            'p',
          )}`;
        }

      case ReoccurrenceEnum.daily: {
        if (endDate <= now) {
          return 'Ended';
        }

        const todayStartTime = set(now, {
          hours: getHours(startDate),
          minutes: getMinutes(startDate),
          seconds: getSeconds(startDate),
          milliseconds: getMilliseconds(startDate),
        });

        // Handle case when event is starting and ending in the future
        if (startDate > now) {
          const formattedDay = format(startDate, 'EEEE');
          const formattedStartTime = format(startDate, 'p');
          const formattedEndTime = format(endTime, 'p');
          return `${formattedDay} at ${formattedStartTime} - ${formattedEndTime}`;
        }

        const nowTime = getTotalMilliseconds(now);
        const endTimeTime = getTotalMilliseconds(endTime);

        // Handle case when event is currently ongoing
        if (now >= todayStartTime && nowTime < endTimeTime) {
          return 'Live';
        }

        // Handle case when event already started today but is not live
        if (todayStartTime <= now) {
          // Set the start date to tomorrow
          const nextStartDate = addDays(now, 1);
          const nextStartTime = format(startDate, 'p');
          const nextEndTime = format(endTime, 'p');
          return `${format(nextStartDate, 'EEEE')} at ${nextStartTime} - ${nextEndTime}`;
        }

        const nextStartTime = format(startDate, 'p');
        const nextEndTime = format(endTime, 'p');
        return `Today at ${nextStartTime} - ${nextEndTime}`;
      }

      case ReoccurrenceEnum.weekly:
        {
          if (endDate > now) {
            const todayStartTime = set(now, {
              hours: getHours(startDate),
              minutes: getMinutes(startDate),
              seconds: getSeconds(startDate),
              milliseconds: getMilliseconds(startDate),
            });

            // Handle case when event is starting and ending in the future
            if (startDate > now && isToday(startDate)) {
              const formattedDay = 'Today';
              const formattedStartTime = format(startDate, 'p');
              const formattedEndTime = format(endTime, 'p');
              return `${formattedDay} at ${formattedStartTime} - ${formattedEndTime}`;
            } else if (startDate > now) {
              const formattedDay = format(startDate, 'EEEE');
              const formattedStartTime = format(startDate, 'p');
              const formattedEndTime = format(endTime, 'p');
              return `${formattedDay} at ${formattedStartTime} - ${formattedEndTime}`;
            }

            // Handle case when event is currently ongoing
            if (now >= todayStartTime && now < endTime) {
              return 'Live';
            }

            const daysUntilNextStart = getNextWeekday(getDay(startDate)); // Calculate days until next occurrence
            let nextStartDate = addDays(now, daysUntilNextStart);
            nextStartDate = set(nextStartDate, {
              hours: getHours(startDate),
              minutes: getMinutes(startDate),
              seconds: getSeconds(startDate),
              milliseconds: getMilliseconds(startDate),
            });

            // Calculate the end date based on the start date and end time
            const nextEndDate = addMilliseconds(
              nextStartDate,
              differenceInMilliseconds(endTime, startDate),
            );

            // Handle case when event starts later this week
            if (nextStartDate > now) {
              const nextStartTime = format(nextStartDate, 'p', { locale: enUS });
              const nextEndTime = format(nextEndDate, 'p', { locale: enUS });
              const formattedDay = format(nextStartDate, 'EEEE', { locale: enUS });
              return `${formattedDay} at ${nextStartTime} - ${nextEndTime}`;
            }

            // Handle case when event starts and ends within the same week
            const expectedStartTime = format(startDate, 'p', { locale: enUS });
            const expectedEndTime = format(endDate, 'p', { locale: enUS });
            const formattedDay = format(nextStartDate, 'EEEE', { locale: enUS });
            return `${formattedDay} at ${expectedStartTime} - ${expectedEndTime}`;
          }
        }
        break;

      case ReoccurrenceEnum.monthly: {
        // Check if the session is live
        if (isBefore(startDate, now) && isAfter(endTime, now)) {
          return 'Live';
        }

        const startWeekday = getDay(startDate);
        const startWeekIndex = Math.ceil(getDate(startDate) / 7) - 1; // Corrected to 1-based index and 0-based array index

        // Find all occurrences of the start weekday in the current month
        const occurrencesThisMonth = eachDayOfInterval({
          start: startOfMonth(now),
          end: endOfMonth(now),
        }).filter((day) => getDay(day) === startWeekday);

        let nextOccurrenceStartDate;

        // Check if we have enough occurrences in the current month
        if (startWeekIndex < occurrencesThisMonth.length) {
          nextOccurrenceStartDate = occurrencesThisMonth[startWeekIndex];
        } else {
          // If not enough occurrences, take the last one in the current month
          nextOccurrenceStartDate = occurrencesThisMonth[occurrencesThisMonth.length - 1];
        }

        // If the next occurrence in this month has already passed, calculate for the next month
        if (!nextOccurrenceStartDate || nextOccurrenceStartDate < now) {
          const nextMonth = addMonths(now, 1);
          const occurrencesNextMonth = eachDayOfInterval({
            start: startOfMonth(nextMonth),
            end: endOfMonth(nextMonth),
          }).filter((day) => getDay(day) === startWeekday);

          if (startWeekIndex < occurrencesNextMonth.length) {
            nextOccurrenceStartDate = occurrencesNextMonth[startWeekIndex];
          } else {
            nextOccurrenceStartDate = occurrencesNextMonth[occurrencesNextMonth.length - 1];
          }

          // Ensure nextOccurrenceStartDate is defined
          if (!nextOccurrenceStartDate) {
            nextOccurrenceStartDate = occurrencesNextMonth
              .reverse()
              .find((day) => getDay(day) === startWeekday);
          }
        }

        // If there is no valid occurrence in the next month or if the calculated next occurrence is in the past, move back to the previous week
        if (!nextOccurrenceStartDate || nextOccurrenceStartDate < now) {
          const occurrencesThisMonthReversed = occurrencesThisMonth.reverse();
          for (let i = startWeekIndex; i >= 0; i--) {
            if (occurrencesThisMonthReversed[i] && occurrencesThisMonthReversed[i] > now) {
              nextOccurrenceStartDate = occurrencesThisMonthReversed[i];
              break;
            }
          }
        }

        // Ensure the next occurrence start date has the same time as the original start date
        nextOccurrenceStartDate = set(nextOccurrenceStartDate as Date, {
          hours: startDate.getHours(),
          minutes: startDate.getMinutes(),
          seconds: startDate.getSeconds(),
          milliseconds: startDate.getMilliseconds(),
        });

        // Ensure the next occurrence end date spans into the next day if necessary
        const duration = differenceInHours(endTime, startDate);
        const nextOccurrenceEndTime = addHours(nextOccurrenceStartDate, duration);

        // Convert to local dates for comparison
        const nextOccurrenceStartDateLocal = startOfDay(nextOccurrenceStartDate);
        const nextOccurrenceEndTimeLocal = startOfDay(nextOccurrenceEndTime);
        const formattedDay = format(nextOccurrenceStartDate, 'EEEE');
        const formattedStartTime = format(nextOccurrenceStartDate, 'p');
        const formattedEndTime = format(nextOccurrenceEndTime, 'p');
        const formattedStartDate = format(nextOccurrenceStartDate, 'MMM d');
        const formattedEndDate = format(nextOccurrenceEndTime, 'EEEE, MMM d');

        // Determine if the end time is on the next day
        const isNextDay =
          differenceInDays(nextOccurrenceEndTimeLocal, nextOccurrenceStartDateLocal) > 0;

        if (isNextDay) {
          return `${formattedDay}, ${formattedStartDate} at ${formattedStartTime} - ${formattedEndDate} at ${formattedEndTime}`;
        } else {
          return `${formattedDay}, ${formattedStartDate} at ${formattedStartTime} - ${formattedEndTime}`;
        }
      }

      case ReoccurrenceEnum.yearly: {
        // Check if the session is live
        if (isBefore(startDate, now) && isAfter(endTime, now)) {
          return 'Live';
        }

        const startWeekday = getDay(startDate);
        const startWeekIndex = Math.ceil(getDate(startDate) / 7) - 1; // Corrected to 1-based index and 0-based array index

        let nextOccurrenceStartDate;

        // Calculate the next occurrence for the same month this year
        let nextOccurrenceMonth = setMonth(now, getMonth(startDate));
        const occurrencesThisYear = eachDayOfInterval({
          start: startOfMonth(nextOccurrenceMonth),
          end: endOfMonth(nextOccurrenceMonth),
        }).filter((day) => getDay(day) === startWeekday);

        // Check if we have enough occurrences in this year's month
        if (startWeekIndex < occurrencesThisYear.length) {
          nextOccurrenceStartDate = occurrencesThisYear[startWeekIndex];
        } else {
          // If not enough occurrences, take the last one in this year's month
          nextOccurrenceStartDate = occurrencesThisYear[occurrencesThisYear.length - 1];
        }

        // If the next occurrence in this year has already passed, calculate for the next year
        if (!nextOccurrenceStartDate || nextOccurrenceStartDate < now) {
          const nextYear = addYears(now, 1);
          nextOccurrenceMonth = setMonth(nextYear, getMonth(startDate));
          const occurrencesNextYear = eachDayOfInterval({
            start: startOfMonth(nextOccurrenceMonth),
            end: endOfMonth(nextOccurrenceMonth),
          }).filter((day) => getDay(day) === startWeekday);

          if (startWeekIndex < occurrencesNextYear.length) {
            nextOccurrenceStartDate = occurrencesNextYear[startWeekIndex];
          } else {
            nextOccurrenceStartDate = occurrencesNextYear[occurrencesNextYear.length - 1];
          }

          // Ensure nextOccurrenceStartDate is defined
          if (!nextOccurrenceStartDate) {
            nextOccurrenceStartDate = occurrencesNextYear
              .reverse()
              .find((day) => getDay(day) === startWeekday);
          }
        }

        // Ensure the next occurrence start date has the same time as the original start date
        nextOccurrenceStartDate = set(nextOccurrenceStartDate as Date, {
          hours: startDate.getHours(),
          minutes: startDate.getMinutes(),
          seconds: startDate.getSeconds(),
          milliseconds: startDate.getMilliseconds(),
        });

        // Ensure the next occurrence end date spans into the next day if necessary
        const duration = differenceInHours(endTime, startDate);
        const nextOccurrenceEndTime = addHours(nextOccurrenceStartDate, duration);

        // Convert to local dates for comparison
        const nextOccurrenceStartDateLocal = startOfDay(nextOccurrenceStartDate);
        const nextOccurrenceEndTimeLocal = startOfDay(nextOccurrenceEndTime);
        const formattedDay = format(nextOccurrenceStartDate, 'EEEE');
        const formattedStartTime = format(nextOccurrenceStartDate, 'p');
        const formattedEndTime = format(nextOccurrenceEndTime, 'p');
        const formattedStartDate = format(nextOccurrenceStartDate, 'MMM d');
        const formattedEndDate = format(nextOccurrenceEndTime, 'EEEE, MMM d');

        // Determine if the end time is on the next day
        const isNextDay =
          differenceInDays(nextOccurrenceEndTimeLocal, nextOccurrenceStartDateLocal) > 0;

        if (isNextDay) {
          return `${formattedDay}, ${formattedStartDate} at ${formattedStartTime} - ${formattedEndDate} at ${formattedEndTime}`;
        } else {
          return `${formattedDay}, ${formattedStartDate} at ${formattedStartTime} - ${formattedEndTime}`;
        }
      }
    }
  } else {
    // Handle non-recurring sessions
    if (startDate < now) {
      return 'Ended';
    }
  }

  // Default formatting for one-time events
  const formattedDay = format(startDate, 'EEEE');
  const formattedStartTime = format(startDate, 'p');
  const formattedEndTime = format(endTime, 'p');

  return `${formattedDay} at ${formattedStartTime} - ${formattedEndTime}`;
};

const getNextWeekday = (dayOfWeek: number) => {
  const today = new Date().getDay();
  const daysUntilNext = (dayOfWeek + (7 - today)) % 7;
  return daysUntilNext === 0 ? 7 : daysUntilNext;
};

const getTotalMilliseconds = (date: Date) => {
  return (
    getHours(date) * 60 * 60 * 1000 +
    getMinutes(date) * 60 * 1000 +
    getSeconds(date) * 1000 +
    getMilliseconds(date)
  );
};
