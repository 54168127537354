import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import { DailyEventObjectTranscriptionMessage } from '@daily-co/daily-js';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useFeatureFlag } from 'configcat-react';
import { useGetSessionActiveParticipants } from '@juno/client-api';
import { Session } from '@juno/client-api/model';
import { ANALYTICS_CONFIGURATION } from '@juno/constants';
import { useAnalyticsContext } from '@juno/modules';
import { DialogAriaWrapper } from '@juno/ui';
import { useSettings } from '@juno/utils';
import { useCallState } from '../../contexts/CallProvider';
import { useChat } from '../../contexts/ChatProvider';
import { useParticipants } from '../../contexts/ParticipantsProvider';
import { Audio } from '../Audio';
import { Header } from '../Call/Header';
import VideoView from '../Call/VideoView';
import TranscriptionContainer from '../Transcription';
import Tray from '../Tray';

interface RoomProps {
  children?: ReactNode;
}

export const Room: React.FC<RoomProps> = ({ children }) => {
  const { firehoseActivity } = useAnalyticsContext();
  const { user, site } = useSettings();
  const {
    roomInfo,
    recordingState,
    setRecordingState,
    startRecording,
    stopRecording,
    transcriptionState,
    startTranscription,
    stopTranscription,
  } = useCallState();
  const { participants } = useParticipants();
  const { value: enableSessionTranscriptionValue } = useFeatureFlag('sessionTranscription', false);

  const {
    showRoomDialog,
    setShowRoomDialog,
    roomDialogTitle,
    roomDialogMessage,
    navigateUrl,
    switchRooms,
  } = useChat();

  const sessionData = useMemo(() => {
    if (roomInfo) {
      return (roomInfo as Record<string, unknown>)['session'] as Session;
    }
    return {} as Session;
  }, [roomInfo]);
  const hasReportedJoin = useRef(false);
  const hasReportedLeave = useRef(false);

  const { data: activeParticipants, isLoading: isLoadingParticipants } =
    useGetSessionActiveParticipants(
      site?.id || '',
      sessionData.id,
      {},
      {
        query: {
          enabled: !!sessionData.id && !!site?.id,
        },
      },
    );

  const participantCount = useMemo(() => {
    if (!activeParticipants) return 0;
    const { participants } = activeParticipants as {
      participants: {
        data: any[];
        total_count: number;
      };
    };
    return participants.total_count || 0;
  }, [activeParticipants]);

  useEffect(() => {
    if (!sessionData.id || !recordingState || isLoadingParticipants) return;
    if (
      enableSessionTranscriptionValue &&
      sessionData?.enable_cc &&
      !(transcriptionState as unknown as DailyEventObjectTranscriptionMessage)?.text
    ) {
      startTranscription();
    }
    if (!sessionData.enable_auto_record) return;
    if (participantCount > 0 && sessionData.enable_auto_record && recordingState === 'idle') {
      startRecording();
      setRecordingState('started');
    }
  }, [sessionData, participantCount]);

  useEffect(() => {
    return () => {
      if (participants.length === 0 && recordingState === 'started') {
        stopRecording();
        setRecordingState('stopped');
      }
    };
  }, []);

  useEffect(() => {
    // Record session join/leave analytics
    if (sessionData && user && !hasReportedJoin.current) {
      hasReportedJoin.current = true;
      firehoseActivity(
        ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_CONTENT,
        sessionData?.id || '',
        null,
        `${user?.id || ''}`,
        ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_LIVE_SESSION,
        ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_JOIN.value,
        sessionData?.title || '',
        null,
      );
    }
    return () => {
      // Record leave analytics
      if (sessionData && user && !hasReportedLeave.current) {
        hasReportedLeave.current = true;
        firehoseActivity(
          ANALYTICS_CONFIGURATION.FIREHOSE_OBJECTS.OBJECT_CONTENT,
          sessionData?.id || '',
          null,
          `${user?.id || ''}`,
          ANALYTICS_CONFIGURATION.FIREHOSE_CATEGORIES.CATEGORY_LIVE_SESSION,
          ANALYTICS_CONFIGURATION.FIREHOSE_ACTIONS.ACTION_LEAVE.value,
          sessionData?.title || '',
          null,
        );
      }
    };
  }, [firehoseActivity, sessionData, user]);

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'100%'}>
      <Header />
      <VideoView />
      <Tray />
      <Audio />
      {localStorage.getItem('enableCC') === 'true' && <TranscriptionContainer />}
      {/* <RoomMessage /> */}
      <DialogAriaWrapper id='confirm-navigate-dialog' open={showRoomDialog}>
        <DialogTitle>{roomDialogTitle}</DialogTitle>
        <DialogContent>
          <Typography variant='body2'>{roomDialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => setShowRoomDialog(false)}>
            Stay in Main Room
          </Button>
          <Button
            variant='contained'
            onClick={() => {
              setShowRoomDialog(false);
              switchRooms(navigateUrl);
            }}
          >
            Join Breakout Room
          </Button>
        </DialogActions>
      </DialogAriaWrapper>
    </Box>
  );
};

export default Room;
