import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChromePicker } from 'react-color';

export const SwatchCircle = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'disabled',
})<{
  color?: string;
  disabled?: boolean;
}>(({ color, disabled }) => ({
  width: '40px',
  height: '40px',
  backgroundColor: color,
  borderRadius: '50px',
  border: '2px #e5e5e5 solid',
  cursor: disabled ? 'auto' : 'pointer',
}));

interface ColorPickerProps {
  label: string;
  color?: string;
  colorName: string;
  handleChange?: (color: string, colorName: string) => void;
  disabled?: boolean;
  error?: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({
  label,
  color,
  colorName,
  handleChange,
  disabled,
  error,
}) => {
  const handleColorChange = (newColor: string) => {
    // Validate hex color string
    const reg = /^#([0-9a-f]{3}){1,2}$/i;
    if (handleChange && reg.test(newColor)) {
      handleChange(newColor, colorName);
    }
  };

  return (
    <Box>
      <Tooltip
        disableHoverListener={disabled}
        title={<ChromePicker color={color} onChange={(res) => handleColorChange(res.hex)} />}
      >
        <SwatchCircle color={color} disabled={disabled} />
      </Tooltip>
    </Box>
  );
};

export default ColorPicker;
