import { Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const UploadWrapperBox = styled(Box)(({ theme }) => ({
  flex: 1,
  textAlign: 'center' as const,
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderStyle: 'dashed',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
}));

export const UploadWrapperSimpleBox = styled(Box)(({ theme }) => ({
  flex: 1,
  textAlign: 'center' as const,
  padding: '20px',
  outline: 'none',
  cursor: 'pointer',
}));

export const PreviewMediaBox = styled(Box)(({ theme }) => ({
  width: 'auto',
  height: 128,
  display: 'flex',
  backgroundColor: 'rgb(155,155,155,0.15)',
  borderRadius: 1,
  alignItems: 'center',
  justifyContent: 'center',
}));

export const PreviewImage = styled('img')(({ theme }) => ({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
}));

export const PreviewVideo = styled('video')(({ theme }) => ({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
}));

export const EditButton = styled(Button)(({ theme }) => ({ position: 'absolute', bottom: 0 }));
