import { TabsOrderEnum } from '@juno/client-api/model';

interface TabProps {
  slug: string;
  label: string;
}

const TABS = [
  {
    slug: TabsOrderEnum.feed,
    label: 'Feed',
  },
  {
    slug: TabsOrderEnum.about,
    label: 'About',
  },
  {
    slug: TabsOrderEnum.discussions,
    label: 'Discussions',
  },
  {
    slug: TabsOrderEnum.resources,
    label: 'Resources',
  },
  {
    slug: TabsOrderEnum.meetings,
    label: 'Meetings',
  },
  {
    slug: TabsOrderEnum.moderation,
    label: 'Moderation',
  },
];

export { TABS, TabProps };
