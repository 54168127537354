import React from 'react';
import { Button, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import {
  getGetSiteUnsubscribeGroupsQueryKey,
  useDeleteSiteUnsubscribeGroup,
} from '@juno/client-api';
import { UnsubscribeGroup } from '@juno/client-api/model';
import { SimpleDialog } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';

interface DeleteSubscriptionGroupDialogProps {
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  unsubcribeGroup: UnsubscribeGroup;
  handleDeleteGroup: () => void;
}

const DeleteSubscriptionGroupDialog: React.FC<DeleteSubscriptionGroupDialogProps> = ({
  handleClose,
  unsubcribeGroup,
  handleDeleteGroup,
}) => {
  // Variables
  const { site } = useSettings();
  const queryClient = useQueryClient();
  const { DELETE } = MutationAction;
  const refetchGroups = () => {
    queryClient.invalidateQueries(getGetSiteUnsubscribeGroupsQueryKey(site?.platform_id || ''));
  };

  // Queries and mutations
  const unsubscribeGroupDelete = useDeleteSiteUnsubscribeGroup(
    onMutation(DELETE, 'UnsubscribeGroup', refetchGroups),
  );

  // Functions
  const deleteGroup = () => {
    unsubscribeGroupDelete
      .mutateAsync({ siteId: unsubcribeGroup.site_id, unsubscribeGroupId: unsubcribeGroup.id })
      .then(() => {
        refetchGroups();
        handleDeleteGroup();
        handleClose(true);
      })
      .catch((err) => {
        handleClose(true);
      });
  };

  return (
    <SimpleDialog
      open={true}
      onClose={() => {
        handleClose(true);
      }}
      buttonTitle={"Yes, I'm sure"}
      buttonOverrideProps={{ onClick: deleteGroup, sx: { ml: 2, mr: 2, mb: 3 } }}
    >
      <Typography>
        Are you sure want to permanently delete the {unsubcribeGroup.name} subscription group? This
        action cannot be undone
      </Typography>
      <Button
        aria-label='Cancel'
        sx={{ mt: 4 }}
        fullWidth
        variant='outlined'
        onClick={() => {
          handleClose(true);
        }}
      >
        Cancel
      </Button>
    </SimpleDialog>
  );
};

export default DeleteSubscriptionGroupDialog;
