import React from 'react';

type ScormCoursePanelContextProps = {
  isFileUploading: boolean;
  setIsFileUploading: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultScormCoursePanelContext: ScormCoursePanelContextProps = {
  isFileUploading: false,
  setIsFileUploading: () => {},
};

export const ScormCoursePanelContext = React.createContext<ScormCoursePanelContextProps>(
  defaultScormCoursePanelContext,
);
