import React, { useEffect, useMemo, useRef, useState } from 'react';
import { RefObject } from 'react';
import EastIcon from '@mui/icons-material/East';
import { Box, List, Tooltip as MUITooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEventListener } from 'usehooks-ts';
import {
  useGetAllSessions,
  useGetCommunityGroups,
  useGetCourses,
  useGetPages,
} from '@juno/client-api';
import { CommunityGroup, Course, Page, PageTypeEnum, Session } from '@juno/client-api/model';
import { usePubnubContext } from '@juno/modules';
import { useSettings } from '@juno/utils';
import { WidgetContentType } from '../data';
import { BreakdownModel, HappeningNowWidgetProps } from './constants';
import {
  BreakdownCount,
  BreakdownListItem,
  BreakdownTitle,
  ChartCloseIcon,
  ErrorText,
  IconBox,
  OpenButton,
  WidgetBox,
  WidgetCount,
  WidgetListIcon,
  WidgetSubtitle,
  WidgetTitle,
  WidgetTitleBox,
} from './styles';

const NOW = new Date().toISOString();

export const HappeningNowWidget: React.FC<HappeningNowWidgetProps> = ({
  widget,
  currentDate,
  isSelected,
  setSelectedWidget,
  addPieData,
}) => {
  const { site } = useSettings();
  const { pubnub } = usePubnubContext();
  const navigate = useNavigate();
  const [currentCount, setCurrentCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const outsideRef = useRef(null);
  const [breakdownData, setBreakdownData] = useState<BreakdownModel[]>([]);
  const { data: courses, isLoading: isLoadingCourses } = useGetCourses(
    site?.id || '',
    {
      exclude_or: {
        date_released__isnull: true,
        date_end__isnull: true,
      },
      filter: {
        date_released__lte: NOW,
        date_end__gte: NOW,
      },
    },
    { query: { enabled: widget.content_type === WidgetContentType.Course } },
  );
  const { data: groups, isLoading: isLoadingGroups } = useGetCommunityGroups(
    site?.id || '',
    {
      filter_or: {
        date_end__isnull: true,
        date_end__gte: NOW,
      },
    },
    { query: { enabled: widget.content_type === WidgetContentType.Community } },
  );
  const { data: sessions, isLoading: isLoadingSessions } = useGetAllSessions(
    site?.id || '',
    {
      order: 'date_start',
      exclude_or: {
        date_released__isnull: true,
        date_end__isnull: true,
      },
      filter: {
        date_released__lte: NOW,
        date_end__gte: NOW,
      },
    },
    { query: { enabled: widget.content_type === WidgetContentType.Session } },
  );
  const { data: libraries, isLoading: isLoadingLibraries } = useGetPages(
    site?.id || '',
    {
      filter: {
        type__iexact: PageTypeEnum.library,
      },
    },
    { query: { enabled: widget.content_type === WidgetContentType.Library } },
  );

  const filteredContents: Course[] | CommunityGroup[] | Session[] | Page[] = useMemo(() => {
    if (widget.content_type === WidgetContentType.Course) {
      return courses || [];
    }
    if (widget.content_type === WidgetContentType.Community) {
      return groups || [];
    }
    if (widget.content_type === WidgetContentType.Session) {
      return sessions || [];
    }
    if (widget.content_type === WidgetContentType.Library) {
      return libraries || [];
    }
    return [];
  }, [widget, courses, groups, sessions, libraries]);

  const filteredRoomIds: string[] = useMemo(() => {
    return filteredContents.map((c) => c.id);
  }, [filteredContents]);

  useEffect(() => {
    handleLoadData();
  }, [widget, currentDate, filteredContents]);

  const getOccupancy = (ids: string[]) => {
    if (ids.length > 0) {
      pubnub.hereNow(
        {
          channels: ids,
          includeUUIDs: false,
          includeState: false,
        },
        (status, response) => {
          if (response?.totalOccupancy >= 0) {
            const total = widget?.count_content ? filteredContents.length : response.totalOccupancy;
            let currentInterval = 0;
            const interval = setInterval(() => {
              currentInterval = currentInterval + 1;
              const currentValue = Math.ceil((total * currentInterval) / 10);
              if (currentValue >= total) {
                setCurrentCount(total);
                clearInterval(interval);
              } else {
                setCurrentCount(currentValue);
              }
            }, 100);

            if (widget.show_pie) {
              addPieData(widget, total);
            }
            setBreakdownData(
              filteredContents.map((content) => {
                return {
                  id: content.id,
                  name: content.title,
                  count: response.channels[content.id]?.occupancy || 0,
                  slug: content.slug,
                };
              }),
            );
            setIsError(false);
            setIsLoading(false);
          }
        },
      );
    }
  };
  const handleOpenPath = (slug: string) => {
    if (!site) {
      return;
    }
    if (widget.content_type === WidgetContentType.Course) {
      navigate(`/${site.slug}/learning/courses/${slug}`);
    } else if (widget.content_type === WidgetContentType.Community) {
      navigate(`/${site.slug}/community/groups/${slug}`);
    } else if (widget.content_type === WidgetContentType.Session) {
      navigate(`/${site.slug}/sessions/${slug}`);
    } else if (widget.content_type === WidgetContentType.Library) {
      navigate(`/${site.slug}/${slug}`);
    }
  };

  const handleLoadData = async () => {
    setIsLoading(true);
    try {
      getOccupancy(filteredRoomIds);
    } catch {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const handleSelectWidget = () => {
    if (!isSelected) {
      setSelectedWidget(widget.id);
    } else {
      setSelectedWidget(null);
    }
  };

  useOnClickOutside(outsideRef, isSelected ? handleSelectWidget : () => {});

  return (
    <div
      style={
        isSelected
          ? {
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              width: 880,
              maxWidth: '100%',
              maxHeight: '60%',
            }
          : { width: '100%' }
      }
    >
      <ChartCloseIcon />
      <div
        style={{ zIndex: isSelected ? 5 : 1, background: '#fff', borderRadius: 8, height: '100%' }}
        ref={outsideRef}
      >
        <WidgetBox isSelected={isSelected} onClick={isSelected ? () => {} : handleSelectWidget}>
          {!isSelected && (
            <IconBox bgcolor={widget.color} color={'#fff'} sx={{ pl: 2, pr: 2 }}>
              <widget.icon sx={{ transform: 'scale(.8)' }} />
            </IconBox>
          )}

          <WidgetTitleBox isSelected={isSelected}>
            <WidgetCount isSelected={isSelected}>{currentCount}</WidgetCount>
            <WidgetTitle isSelected={isSelected}>{widget.title}</WidgetTitle>
          </WidgetTitleBox>
          {!isSelected && <WidgetListIcon />}

          <WidgetSubtitle variant='body2' isSelected={isSelected}>
            {widget.description}
          </WidgetSubtitle>
          <List
            sx={{
              maxHeight: '450px',
              overflow: 'auto',
            }}
          >
            {isSelected &&
              breakdownData
                .filter((breakdownElem) => breakdownElem.count > 0)
                .sort((a, b) => a.count - b.count)
                .map((breakdownElem) => {
                  return (
                    <BreakdownListItem key={breakdownElem.name}>
                      <BreakdownTitle noWrap={true}>{`${breakdownElem.name}`}</BreakdownTitle>
                      <Box
                        sx={{
                          flex: 1,
                        }}
                      ></Box>
                      <BreakdownCount>{`${breakdownElem.count}`}</BreakdownCount>
                      <MUITooltip
                        title={'Open'}
                        placement={'top'}
                        PopperProps={{
                          disablePortal: true,
                        }}
                      >
                        <OpenButton
                          color={'inherit'}
                          onClick={() => {
                            handleOpenPath(breakdownElem.slug);
                          }}
                          size={'small'}
                          variant={'text'}
                          style={{ pointerEvents: 'auto' }}
                          aria-label={'open'}
                        >
                          <EastIcon sx={{ opacity: 0.5 }} />
                        </OpenButton>
                      </MUITooltip>
                    </BreakdownListItem>
                  );
                })}
          </List>

          {isError && <ErrorText>Error fetching data</ErrorText>}
        </WidgetBox>
      </div>
    </div>
  );
};

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent) => void,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown',
): void {
  useEventListener(mouseEvent, (event) => {
    const el = ref?.current;

    // Do nothing if clicking ref's element or descendent elements
    // had to use composedPath() instead of .contains because the shadow dom moves the click to the parent of the shadow dom
    if (!el || event.composedPath().includes(el)) {
      return;
    }

    handler(event);
  });
}
