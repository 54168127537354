import React from 'react';
import { FETIcon, FETIconButton, FETLabel } from './styles';

interface FETButtonProps {
  onClick: () => void;
  label?: string;
}

const FETButton: React.FC<FETButtonProps> = ({ onClick, label = 'Edit' }) => (
  <FETIconButton size='large' onClick={onClick}>
    <FETIcon fontSize='large' />
    <FETLabel>{label}</FETLabel>
  </FETIconButton>
);

export default FETButton;
