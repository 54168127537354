import React, { CSSProperties } from 'react';
import { Box } from '@mui/material';
import CircularProgress, {
  CircularProgressProps,
  circularProgressClasses,
} from '@mui/material/CircularProgress';

interface DefaultCircularProgressProps {
  value?: number;
  props?: CircularProgressProps;
  primaryCircleColor?: string;
}

const DefaultCircularProgress: React.FC<DefaultCircularProgressProps> = ({
  props,
  value = 0,
  primaryCircleColor,
}) => {
  return (
    <Box
      sx={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <CircularProgress
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        thickness={4}
        {...props}
        variant='determinate'
        value={100}
      />
      <CircularProgress
        variant='determinate'
        sx={{
          color: primaryCircleColor ? primaryCircleColor : (theme) => theme.palette.primary.main,
          animationDuration: '850ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        value={value}
        thickness={4}
        {...props}
      />
    </Box>
  );
};
export default DefaultCircularProgress;
