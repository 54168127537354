import * as React from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';

interface SimpleDialogProps {
  open?: boolean;
  saving?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  title?: React.ReactNode;
  buttonTitle?: string;
  cancelButtonTitle?: string;
  children?: React.ReactNode;
}

const SimpleDialog: React.FC<SimpleDialogProps> = ({
  open = true,
  saving,
  onSave,
  onCancel,
  title,
  children,
  buttonTitle = 'OK',
  cancelButtonTitle = 'Cancel',
}) => {
  const handleCancel = () => {
    onCancel && onCancel();
  };
  const handleSave = () => {
    onSave && onSave();
  };
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth='xs'
      sx={{ textAlign: 'center' }}
    >
      <DialogTitle id='alert-dialog-title'>
        <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
      </DialogTitle>
      <DialogContent sx={{ width: '320px' }}>{children}</DialogContent>
      <DialogActions>
        <Stack sx={{ width: '100%' }}>
          <LoadingButton
            fullWidth
            variant='contained'
            loading={!!saving}
            onClick={handleSave}
            sx={{ mb: 1, height: '45px' }}
          >
            {buttonTitle}
          </LoadingButton>
          {onCancel && (
            <Button fullWidth onClick={handleCancel} sx={{ mb: 1, height: '45px' }}>
              {cancelButtonTitle}
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleDialog;
