import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFormImage = styled(Avatar)(() => ({
  width: '100%',
  height: '80px',
  objectFit: 'cover',
  overflow: 'hidden',
  boxShadow: '0px 0px 3px rgb(0 0 0 / 40%)',
}));

export const TileWrapper = styled(Box)(() => ({
  position: 'relative',
  userSelect: 'none',
  boxShadow: 'none',
  width: '100%',
}));

export const TileGridContainer = styled(Grid)(() => ({
  height: '100%',
  width: 'auto',
  borderRadius: '4px',
}));

export const TileGridDragArea = styled(Grid)(() => ({
  width: '25px',
  height: '100%',
  alignSelf: 'center',
  boxShadow: '0px 0px 4px rgb(0 0 0 / 40%)',
  backgroundOpacity: 0.5,
  display: 'flex',
  alignItems: 'center',
  cursor: 'move',
}));

export const TileGridBanner = styled(Box)(() => ({
  alignSelf: 'center',
}));

export const TileGridTextWrapper = styled(Box)(() => ({
  display: 'grid',
  alignContent: 'space-between',
  width: '100%',
}));

export const BottomIndicatorsWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}));

export const EllipsisTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{
  width: number;
}>(({ width }) => ({
  width,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const HeaderRight = styled(Box)(() => ({
  position: 'absolute',
  top: '0px',
  right: '0px',
}));
