import React from 'react';
import { Skeleton } from '@mui/material';

const LoadingTile = () => (
  <>
    {[...Array(5)].map((_, idx) => (
      <Skeleton key={`skel-${idx}`} variant='rectangular' width={'auto'} height={71} />
    ))}
  </>
);

export default LoadingTile;
