import { useEffect, useMemo, useRef, useState } from 'react';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import GifIcon from '@mui/icons-material/Gif';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import {
  Avatar,
  Box,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import EmojiPicker from 'emoji-picker-react';
import emojiRegex from 'emoji-regex';
import GifPicker from 'gif-picker-react';
import Linkify from 'linkify-react';
import { useNavigate } from 'react-router-dom';
import { SearchContentTypeEnum } from '@juno/client-api/model';
import { UserQuickView } from '@juno/ui';
import { getContentRoute, useSettings } from '@juno/utils';
import { Aside } from '.';
import { CHAT_ACTIONS, CHAT_ASIDE } from '../../constants';
import { useCallState } from '../../contexts/CallProvider';
import { useChat } from '../../contexts/ChatProvider';
import { useParticipants } from '../../contexts/ParticipantsProvider';
import { useUIState } from '../../contexts/UIStateProvider';
import { useMessageSound } from '../../hooks/useMessageSound';

export const ChatAside = () => {
  const { showAside, setShowAside } = useUIState();
  const { sendMessage, chatHistory, hasNewChatMessage, setHasNewChatMessage } = useChat();
  const { localParticipant } = useParticipants();
  const [newMessage, setNewMessage] = useState('');
  const playMessageSound = useMessageSound();
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const inputRef = useRef(null);
  const chatWindowRef = useRef();
  const scrollRef = useRef(null);
  const { user: currentUser, site } = useSettings();
  const navigateRoute = useNavigate();
  const [reactions, setReactions] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMessageId, setSelectedMessageId] = useState(null);
  const [userQuickViewAnchorEl, setUserQuickViewAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleOpenEmojiPicker = (event, messageId) => {
    setAnchorEl(event.currentTarget);
    setSelectedMessageId(messageId);
  };

  const handleCloseEmojiPicker = () => {
    setAnchorEl(null);
    setSelectedMessageId(null);
  };

  const handleEmojiSelect = (emoji) => {
    setReactions((prevReactions) => ({
      ...prevReactions,
      [selectedMessageId]: emoji.native,
    }));
    handleCloseEmojiPicker();
  };

  const open = Boolean(anchorEl);

  const { roomInfo } = useCallState();
  const sessionData = useMemo(() => {
    if (roomInfo) {
      return roomInfo.session;
    }
    return {};
  }, [roomInfo]);

  useEffect(() => {
    // Clear out any new message notifications if we're showing the chat screen
    if (showAside === CHAT_ASIDE && hasNewChatMessage) {
      setHasNewChatMessage(false);
    }
  }, [showAside, chatHistory.length, hasNewChatMessage]);

  useEffect(() => {
    if (hasNewChatMessage && showAside !== CHAT_ASIDE) {
      // TODO ?
      // playMessageSound();
    }
  }, [playMessageSound, showAside, hasNewChatMessage]);

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [chatHistory?.length]);

  const isLocalUser = (user) => user.id === currentUser.id;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return; // don't allow people to submit empty strings
    sendMessage(newMessage, CHAT_ACTIONS.SEND_MESSAGE);
    setNewMessage('');
  };

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    } else {
      const listRef = document.querySelector('.message-scroll-ref');
      if (listRef) {
        scrollRef.current = listRef;
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    }
  }, [chatHistory, showAside]);

  const allowChat = useMemo(() => {
    // TODO if user is a moderator, allow chat
    if (sessionData?.allow_chat) {
      return true;
    }
    return false;
  }, [sessionData]);

  const containsSingleEmoji = (message) => {
    if (!message) return false;
    const emojiPattern = emojiRegex();
    const matches = message.match(emojiPattern);

    // Check if the message contains a single emoji and nothing else
    return matches !== null && matches.length === 1 && matches[0] === message;
  };

  if (!showAside || showAside !== CHAT_ASIDE) {
    return null;
  }

  return (
    <>
      <Aside onClose={() => setShowAside(false)}>
        <Box position={'relative'} height={'100%'}>
          <Card sx={{ background: '#5e5a63', color: 'white', textAlign: 'center' }}>
            <Typography variant='subtitle2' sx={{ fontSize: '10px', p: 1 }}>
              Messages can only be seen by people in the call and are deleted when the session ends.
            </Typography>
          </Card>
          <List
            className='message-scroll-ref'
            ref={scrollRef}
            sx={{
              maxHeight: 'calc(100% - 110px)',
              overflowY: 'auto',
            }}
          >
            {chatHistory.map((chatItem) => (
              <ListItem
                key={chatItem.id}
                disableGutters={true}
                sx={{
                  display: 'flex',
                  flexDirection: isLocalUser(chatItem.sender) ? 'row-reverse' : 'row',
                  justifyContent: isLocalUser(chatItem.sender) ? 'flex-end' : 'flex-start',
                  alignItems: 'flex-start',
                  padding: '5px',
                  marginTop: isLocalUser(chatItem.sender) ? '0' : 'inherit',
                }}
              >
                {!isLocalUser(chatItem.sender) && (
                  <ListItemAvatar sx={{ justifyContent: 'center', display: 'flex' }}>
                    <Avatar
                      alt={chatItem.sender.senderName}
                      src={chatItem.sender.avatar}
                      onClick={async (e) => {
                        await setSelectedUser(chatItem.sender.id);
                        setUserQuickViewAnchorEl(e.target);
                      }}
                      sx={{ cursor: 'pointer' }}
                    />
                  </ListItemAvatar>
                )}
                <ListItemText
                  primary={
                    <Typography
                      fontSize={'16px'}
                      fontWeight={'bold'}
                      onClick={async (e) => {
                        await setSelectedUser(chatItem.sender.id);
                        setUserQuickViewAnchorEl(e.target);
                      }}
                      sx={{ cursor: 'pointer' }}
                    >
                      {isLocalUser(chatItem.sender) ? '' : chatItem.sender?.senderName}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography
                        sx={{
                          whiteSpace: 'pre-wrap !important',
                          backgroundColor: containsSingleEmoji(chatItem?.message)
                            ? 'none'
                            : isLocalUser(chatItem.sender)
                            ? '#c7169c'
                            : '#ffffff7a',
                          color: isLocalUser(chatItem.sender) ? 'white' : 'black',
                          fontSize: containsSingleEmoji(chatItem?.message)
                            ? '28px !important'
                            : '14px',
                          alignSelf: isLocalUser(chatItem.sender) ? 'flex-end' : 'flex-start',
                          borderRadius: 2,
                          height: containsSingleEmoji(chatItem?.message) ? '28px' : 'auto',
                          maxWidth: 'fit-content',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '2px 10px',
                        }}
                      >
                        <Linkify
                          as='p'
                          options={{
                            target: {
                              url: '_blank',
                            },
                          }}
                          sx={{ textAlign: 'right' }}
                        >
                          {chatItem.message}
                        </Linkify>
                      </Typography>
                      {/* // TODO add reactions to messages */}
                      {/* <div>
                        <IconButton
                          onClick={(event) => handleOpenEmojiPicker(event, chatItem.id)}
                          size='small'
                        >
                          <EmojiEmotionsIcon />
                        </IconButton>
                        {reactions[chatItem.id] && (
                          <Typography variant='caption' sx={{ marginLeft: '5px' }}>
                            {reactions[chatItem.id]}
                          </Typography>
                        )}
                      </div> */}
                    </>
                  }
                  sx={{
                    display: isLocalUser(chatItem.sender) ? 'flex' : 'block',
                    justifyContent: isLocalUser(chatItem.sender) ? 'flex-end' : 'flex-start',
                    wordBreak: 'break-word',
                    margin: isLocalUser(chatItem.sender) ? '2px 0 0 5px' : 'inherit',
                  }}
                />
              </ListItem>
            ))}
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseEmojiPicker}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <EmojiPicker onEmojiClick={handleEmojiSelect} />
            </Popover>
          </List>
          <Box display={'flex'} position={'absolute'} bottom={'5px'} height={'60px'}>
            {showEmojiPicker && (
              <Box sx={{ position: 'absolute', bottom: '100%', zIndex: 10 }} width={'100%'}>
                <EmojiPicker
                  onEmojiClick={(emoji, event) => {
                    setNewMessage(newMessage + emoji.emoji);
                    setShowEmojiPicker(false);
                    inputRef.current.focus();
                  }}
                  theme='dark'
                  className='emoji-picker'
                  lazyLoadEmojis={true}
                />
              </Box>
            )}
            <Tooltip
              placement='top'
              title={`${allowChat ? '' : 'Chat has been disabled for this session'}`}
            >
              <FormControl fullWidth variant='outlined'>
                <OutlinedInput
                  disabled={!allowChat}
                  inputRef={inputRef}
                  value={newMessage}
                  onChange={(e) => {
                    setShowEmojiPicker(false);
                    setNewMessage(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSubmit(e);
                    }
                  }}
                  // startAdornment for an emoji icon
                  startAdornment={
                    <InputAdornment position='start'>
                      <IconButton
                        disabled={!allowChat}
                        aria-label='Emoji picker'
                        edge='start'
                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                      >
                        <EmojiEmotionsOutlinedIcon color='black' />
                      </IconButton>
                      {/* <IconButton aria-label='Gif picker' edge='start'
                  >

<GifPicker
            tenorApiKey={'AIzaSyCRqoNgPXNknd3_CJOYvYo81Z-pMdFB-KY'}
            onGifClick={(gifUrl) => {
              handleSendMessage(gifUrl.url);
              setShowGifPicker(false);
            }}
          />
                  </IconButton> */}
                    </InputAdornment>
                  }
                  endAdornment={
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='Post message'
                        onClick={handleSubmit}
                        edge='end'
                        disabled={!allowChat}
                      >
                        <SendOutlinedIcon color='black' />
                      </IconButton>
                    </InputAdornment>
                  }
                  placeholder='Send a Message'
                  sx={{
                    background: 'white',
                    border: 'none',
                    borderRadius: '5px',
                  }}
                />
              </FormControl>
            </Tooltip>
          </Box>
        </Box>
        <UserQuickView
          anchorEl={userQuickViewAnchorEl}
          userId={selectedUser || ''}
          open={Boolean(userQuickViewAnchorEl)}
          setAnchorEl={setUserQuickViewAnchorEl}
          onViewProfile={(userEmail) => {
            navigateRoute(
              getContentRoute(site?.slug || '', selectedUser || '', SearchContentTypeEnum.user),
            );
          }}
          onMessageUser={function (userId) {
            throw new Error('Function not implemented.');
          }}
        />
      </Aside>
    </>
  );
};

export default ChatAside;
