import React, { useMemo, useRef, useState } from 'react';
import { ChatBubbleOutline, EditCalendarOutlined } from '@mui/icons-material';
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { useGetSavedUsers, useSaveUser } from '@juno/client-api';
import { JunoUser, SearchContentTypeEnum, UserSettings } from '@juno/client-api/model';
import { FeatureConfig } from '@juno/client-api/utils';
import {
  MutationAction,
  getContentRoute,
  onMutation,
  useMessengerContext,
  useSettings,
} from '@juno/utils';
import { BasicKebobMenu, DefaultCircularProgress, UserQuickView } from '../../index';
import CreatePrivateMeetingDialog from '../CreatePrivateMeetingDialog';

interface ExtendedUser extends JunoUser {
  settings: UserSettings;
}
interface UserTileProps {
  user: JunoUser;
  icon?: React.ReactNode;
  rightAdornment?: React.ReactNode;
  handleIconClick?: (user: JunoUser) => Promise<void> | void;
  loading?: boolean;
  email?: boolean;
  company?: boolean;
  avatar?: boolean;
  virtualized?: boolean;
  hoverState?: boolean;
  greyBg?: boolean;
}

const UserTile: React.FC<UserTileProps> = ({
  user,
  icon,
  handleIconClick,
  loading,
  avatar = true,
  email,
  company,
  virtualized = false,
  hoverState = false,
  rightAdornment,
  greyBg = false,
}) => {
  const [ref, inView] = useInView({ threshold: 0, initialInView: true, skip: !virtualized });
  const handleClick = () => {
    handleIconClick && handleIconClick(user);
  };
  const quickViewRef = useRef(null);
  const { site, configs, user: currentUser, setShowMessenger } = useSettings();
  const navigateRoute = useNavigate();
  const [userQuickViewAnchorEl, setUserQuickViewAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<JunoUser | null>(null);
  const [newMeetingOpen, setNewMeetingOpen] = useState(false);
  const { createChannelThenSubscribe, setMessengerTab, setIsConversation, setCurrentChannel } =
    useMessengerContext();

  const {
    data: savedUsers,
    isLoading,
    isFetching,
    refetch,
  } = useGetSavedUsers(
    site?.id || '',
    {
      order: '-first_name',
    },
    { query: { enabled: !!site?.id && !!currentUser?.id } },
  );

  const isSaved = useMemo(() => {
    return savedUsers?.some((u) => u.id === user.id);
  }, [savedUsers]);

  const saveUser = useSaveUser(onMutation(MutationAction.CREATE, '', refetch));
  const handleSaveUser = (user: JunoUser) => {
    if (isLoading || isFetching) return;
    try {
      saveUser.mutate({ siteId: site?.id || '', userId: user.id });
    } catch (e) {
      console.error(e);
    }
  };

  let messengerDisabled =
    (FeatureConfig.getThemeConfig(configs)?.config?.hide_messenger as boolean) || false;
  const userSettings = (user as ExtendedUser).settings;
  if (!messengerDisabled && userSettings?.private_messaging) {
    messengerDisabled = userSettings.private_messaging;
  }

  const isCurrentUser = user.id === currentUser?.id;
  const userName = isCurrentUser
    ? `${user.first_name} ${user.last_name} (You)`
    : `${user.first_name} ${user.last_name}`;

  const startChat = (user: JunoUser) => {
    if (!currentUser) return;
    setShowMessenger && setShowMessenger(true);
    createChannelThenSubscribe([user.id, currentUser?.id]).then((channelId) => {
      if (channelId) {
        setMessengerTab(0);
        setCurrentChannel(channelId);
        setIsConversation(true);
      }
    });
  };

  return (
    <Stack
      direction={'row'}
      spacing={2}
      pr={1}
      pl={1}
      pt={0.5}
      pb={0.5}
      ref={ref}
      sx={{
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        '&:hover': {
          backgroundColor: hoverState ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
        },
        backgroundColor: greyBg ? 'rgba(0, 0, 0, 0.04)' : 'transparent',
      }}
    >
      {(!virtualized || (virtualized && inView)) && (
        <>
          <Stack
            onClick={() => setUserQuickViewAnchorEl(quickViewRef.current)}
            direction='row'
            alignItems='center'
            justifyContent={'space-between'}
            sx={{ cursor: 'pointer' }}
            spacing={2}
          >
            {avatar && (
              <Avatar
                aria-label='View user details'
                src={user.avatar ?? ''}
                style={virtualized && !inView ? { visibility: 'hidden' } : {}}
                ref={quickViewRef}
              />
            )}
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  {userName}
                </Typography>
                {email && <Typography variant={'caption'}>{user.email}</Typography>}
                {company && <Typography variant={'caption'}>{user.company}</Typography>}
              </Box>
            </Box>
          </Stack>
          {!isCurrentUser && (
            <>
              {/* // TODO hiding this per prod request until the fast follow for saved contacts */}
              {/* <Tooltip title={isSaved ? 'Remove contact' : 'Save contact'}>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSaveUser(user);
                      }}
                    >
                      {isSaved ? (
                        <BookmarkOutlined color='primary' sx={{ fontSize: '24px' }} />
                      ) : (
                        <BookmarkBorder sx={{ fontSize: '24px' }} />
                      )}
                    </IconButton>
                  </Tooltip> */}
              <BasicKebobMenu>
                {!messengerDisabled && (
                  <MenuItem
                    onClick={() => startChat(user)}
                    aria-label='Start a Chat'
                    disabled={isCurrentUser}
                  >
                    <ListItemIcon>
                      <ChatBubbleOutline sx={{ fontSize: '24px' }} />
                    </ListItemIcon>
                    <Typography>Message Member</Typography>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={(e) => {
                    setSelectedUser(user);
                    setNewMeetingOpen(true);
                  }}
                  aria-label='Start a Meeting'
                  disabled={isCurrentUser}
                >
                  <ListItemIcon>
                    <EditCalendarOutlined sx={{ fontSize: '24px' }} />
                  </ListItemIcon>
                  <Typography>Create Meeting</Typography>
                </MenuItem>
              </BasicKebobMenu>
            </>
          )}

          {icon && handleIconClick && (
            <IconButton onClick={handleClick} size={'small'} sx={{ justifySelf: 'flex-end' }}>
              {loading ? (
                <DefaultCircularProgress props={{ size: 18, variant: 'indeterminate' }} />
              ) : (
                icon
              )}
            </IconButton>
          )}
          {!icon && loading && (
            <DefaultCircularProgress props={{ size: 18, variant: 'indeterminate' }} />
          )}

          {rightAdornment}
        </>
      )}

      <CreatePrivateMeetingDialog
        open={newMeetingOpen}
        handleClose={() => setNewMeetingOpen(false)}
        selectedUser={selectedUser as JunoUser}
      />
      <UserQuickView
        anchorEl={userQuickViewAnchorEl}
        userId={user.id}
        open={Boolean(userQuickViewAnchorEl)}
        setAnchorEl={setUserQuickViewAnchorEl}
        onViewProfile={(userEmail: string): void => {
          navigateRoute(
            getContentRoute(site?.slug || '', user?.id || '', SearchContentTypeEnum.user),
          );
        }}
        onMessageUser={function (userId: string): void {
          throw new Error('Function not implemented.');
        }}
      />
    </Stack>
  );
};

export const SkeletonUserTile = () => {
  return (
    <Stack direction={'row'} spacing={2} pt={0.5} pb={0.5} sx={{ pl: 1, pr: 1 }}>
      <Skeleton variant={'circular'} height={40} width={40} />
      <Box>
        <Skeleton variant={'text'} height={28} width={140} />
        <Skeleton variant={'text'} height={16} width={200} />
      </Box>
    </Stack>
  );
};

export default UserTile;
