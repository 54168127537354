import React, { ChangeEvent, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import {
  Product,
  ProductMetadata,
  ProductPrice,
  TypeD1bEnum as ProductTypeEnum,
} from '@juno/client-api/model';
import { DialogAriaWrapper, JunoSpin } from '@juno/ui';

interface FormDialogProps {
  open: boolean;
  onSave: (data: Product) => void;
  onClose: () => void;
  onDelete: () => void;
  isLoading: boolean;
  isSaving: boolean;
  item?: any;
  isNew?: boolean;
}

export interface PayloadProps {
  id?: string;
  name?: string;
  type?: ProductTypeEnum;
  description?: string;
  image?: string;
  product_prices?: ProductPrice[];
  metadata?: ProductMetadata;
  active?: boolean;
}

const FormDialog: React.FC<FormDialogProps> = ({
  open,
  onClose,
  onSave,
  onDelete,
  isLoading,
  isSaving,
  item,
  isNew,
}) => {
  const [payload, setPayload] = useState<PayloadProps | undefined>(item);
  const isValid = !!payload?.name;

  const handleUpdate = (key: string, val: unknown) => {
    setPayload({
      ...payload,
      type: ProductTypeEnum.NUMBER_1,
      product_prices: [],
      metadata: { foo: 'bar' },
      active: true,
      [key]: val,
    });
  };

  const changeHandler = {
    text: ({ target }: ChangeEvent<HTMLInputElement>) => handleUpdate(target.name, target.value),
  };

  useEffect(() => {
    if (!open) {
      setPayload(undefined);
    }
  }, [open]);

  if (isLoading) return <JunoSpin />;
  return (
    <DialogAriaWrapper open={open} onClose={onClose} id='create_product_dialog'>
      <DialogTitle>Create Product</DialogTitle>
      <DialogContent>
        <TextField
          disabled={isSaving}
          id='name'
          name='name'
          label='Name'
          fullWidth
          margin='dense'
          value={payload?.name || ''}
          onChange={changeHandler.text}
        />
        <TextField
          disabled={isSaving}
          id='description'
          name='description'
          label='Description'
          fullWidth
          margin='dense'
          value={payload?.description || ''}
          onChange={changeHandler.text}
        />
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button disabled={isSaving} onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            disabled={!isValid}
            loading={isSaving}
            variant='contained'
            onClick={() => onSave(payload as Product)}
          >
            Save
          </LoadingButton>
        </Box>
      </DialogActions>
    </DialogAriaWrapper>
  );
};

export default FormDialog;
