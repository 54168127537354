import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

interface ConfirmDeleteDialogProps {
  handleClose: () => void;
  handleDelete: () => void;
  title: string;
  message: JSX.Element;
  deleteButtonText?: string;
}

const ConfirmDeleteDialog: React.FC<ConfirmDeleteDialogProps> = ({
  handleClose,
  handleDelete,
  title,
  message,
  deleteButtonText = 'Delete',
}) => {
  return (
    <Dialog open={true} onClose={handleClose} maxWidth='xs'>
      <DialogTitle sx={{ pt: 4, pb: 4, alignSelf: 'left' }}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText align='left' component='div'>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button onClick={handleClose} sx={{ color: 'black' }}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleDelete}>
          {deleteButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
