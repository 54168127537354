import * as React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';

interface BasicKebobMenuProps {
  children: React.ReactNode;
}
const BasicKebobMenu = React.forwardRef<HTMLButtonElement, BasicKebobMenuProps>(
  ({ children }, ref) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    return (
      <Box>
        <IconButton
          aria-label='more-kebob'
          id='more-kebob'
          aria-controls={open ? 'more-kebob-toggle' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
          ref={ref}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='more-kebob-toggle'
          MenuListProps={{
            'aria-labelledby': 'more-kebob',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          anchorEl={anchorEl}
          open={open}
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
          onClose={handleClose}
          PaperProps={{
            style: {
              minWidth: '20ch',
            },
          }}
        >
          {children}
        </Menu>
      </Box>
    );
  },
);
export default BasicKebobMenu;
