import { IconButton } from '@mui/material';
import { IconButtonProps } from '@mui/material/IconButton';
import { keyframes, styled } from '@mui/system';

const pulse = (color: string) => keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 ${color};
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 10px 10px ${color}00; // Add 00 to the end for transparency
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 ${color};
  }
`;

interface AnimatedIconButtonProps extends IconButtonProps {
  animate: boolean;
  disabled?: boolean;
}

export const AnimatedIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'animate',
})<AnimatedIconButtonProps>(({ theme, animate, disabled }) => ({
  animation: animate ? `${pulse(theme.palette.primary.main)} 2s 2` : 'none',
  '&.Mui-disabled': {
    color: theme.palette.action.disabled,
    boxShadow: 'none',
    animation: 'none',
  },
}));
