import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import { format } from 'date-fns';
import { Course as CourseModel, Enrollment, WaitListedEnrollment } from '@juno/client-api/model';
import { EMPTY_COURSE_URL } from '@juno/constants';
import { optimizeImage } from '@juno/utils';
import {
  EllipsisTypography,
  StyledFormImage,
  TileCard,
  TileGridBanner,
  TileGridContainer,
  TileGridTextWrapper,
  TileWrapper,
} from '../styles';

interface WaitListedEnrollmentModel extends WaitListedEnrollment {
  course: CourseModel;
}
export interface EnrollmentModel extends Enrollment {
  lessons_complete_percent: string;
}

interface EnrollmentTileProps {
  enrollment: EnrollmentModel | WaitListedEnrollmentModel;
  idx: number;
  children?: React.ReactNode;
  onClick?: (course: CourseModel) => void;
}
const EnrollmentTile = forwardRef<HTMLDivElement, EnrollmentTileProps>(
  ({ enrollment, idx, children, onClick }, ref) => {
    const formatDateTime = (date: string | null | undefined) => {
      if (!date) return '';
      const dt = new Date(date);
      return format(dt, 'M/dd/yy h:mm a');
    };

    const course = enrollment.course;
    if (!course) return null;
    const icon = course.icon;
    const title = course.title;
    const subtitle = `(${formatDateTime(course.date_start)} - ${formatDateTime(course.date_end)})`;

    const handleCourseClick = (evt: React.MouseEvent<HTMLDivElement>) => {
      onClick && onClick(course);
    };

    return (
      <TileWrapper
        ref={ref}
        onClick={handleCourseClick}
        sx={{ cursor: onClick ? 'pointer' : 'auto' }}
      >
        <TileGridContainer>
          <TileCard idx={idx}>
            <TileGridBanner m={1}>
              <StyledFormImage
                variant='rounded'
                src={optimizeImage(100, icon || EMPTY_COURSE_URL)}
                alt='Course Banner'
              />
            </TileGridBanner>
            <TileGridTextWrapper>
              <EllipsisTypography variant='body1'>{title}</EllipsisTypography>
              <EllipsisTypography variant='body2'>{subtitle}</EllipsisTypography>
            </TileGridTextWrapper>
            <Box sx={{ display: 'flex', flex: 1 }}></Box>
            <TileGridTextWrapper m={1}>{children}</TileGridTextWrapper>
          </TileCard>
        </TileGridContainer>
      </TileWrapper>
    );
  },
);

export default EnrollmentTile;
