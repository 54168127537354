import CloseIcon from '@mui/icons-material/Close';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, Button, CircularProgress, List, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const WidgetBox = styled(List, { shouldForwardProp: (prop) => prop !== 'isSelected' })<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.divider}`,
  padding: isSelected ? theme.spacing(4) : theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  position: 'relative',
  boxShadow: isSelected ? theme.shadows[6] : 'none',
  maxWidth: '100%',
  transition: '300ms box-shadow',
  overflow: 'hidden',
  height: '100%',
  '&:hover': {
    boxShadow: theme.shadows[5],
  },
}));

export const WidgetTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  fontSize: isSelected ? '1.3rem' : '1rem',
  marginBottom: theme.spacing(1),
}));

export const WidgetSubtitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  display: isSelected ? 'flex' : 'none',
  marginBottom: theme.spacing(2),
  opacity: 0.5,
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  color: theme.palette.error.main,
  alignItems: 'center',
  justifyContent: 'center',
  height: 140,
}));

export const Percentage = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'percent',
})<{
  percent: number;
}>(({ percent, theme }) => ({
  color:
    percent === 0 ? '#fdd835' : percent > 0 ? theme.palette.success.main : theme.palette.error.main,
}));

export const DownloadButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  position: 'absolute',
  top: isSelected ? theme.spacing(4) : theme.spacing(1.5),
  right: isSelected ? theme.spacing(10) : theme.spacing(8),
  width: 40,
  height: 32,
  maxWidth: 50,
  minWidth: 0,
  svg: {},
}));

export const PreviewButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{
  isSelected?: boolean;
}>(({ isSelected, theme }) => ({
  position: 'absolute',
  top: isSelected ? theme.spacing(4) : theme.spacing(1.5),
  right: isSelected ? theme.spacing(4) : theme.spacing(2),
  width: 40,
  height: 32,
  maxWidth: 50,
  minWidth: 0,
  svg: {},
}));

export const ChartBox = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  height: 140,
  backgroundColor: theme.palette.divider,
  position: 'relative',
  overflow: 'hidden',
  ':hover .MuiSvgIcon-root': {
    opacity: 1,
  },
}));

export const ChartZoomIcon = styled(ZoomInIcon)(({ theme }) => ({
  position: 'absolute',
  top: 9,
  left: 9,
  opacity: 0.4,
  transition: '0ms opacity',
}));

export const DownloadCircularProgress = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  top: 6,
  left: 9,
}));

export const ChartCloseIcon = styled(CloseIcon)(({ theme }) => ({
  position: 'absolute',
  top: -45,
  right: -45,
  transition: '0ms opacity',
  color: '#fff',
  fontSize: '2.5rem',
  cursor: 'pointer',
  padding: 5,
}));

export const AggregateListItem = styled(Box)({
  display: 'flex',
  backgroundColor: '#00000010',
  alignItems: 'center',
  padding: '20px',
  cursor: 'pointer',
  justifyContent: 'space-between',
  marginBottom: '16px',
});

export const AggregateDownloadButton = styled(Button)({
  position: 'relative',
  width: 40,
  height: 32,
  maxWidth: 50,
  minWidth: 0,
  svg: {},
});

export const AggregateDownloadCircularProgress = styled(CircularProgress)(({ theme }) => ({
  position: 'relative',
}));
