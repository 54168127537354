import * as React from 'react';
import { useMemo } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useDebounce } from 'usehooks-ts';
import { useGetAllUsers } from '@juno/client-api';
import { JunoUser } from '@juno/client-api/model';
import { calculateUserSearchFilters } from '@juno/utils';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
interface AutocompleteInstructorsProps {
  formik: any;
  name: string;
  label: string;
  siteId: string;
  platformId: string;
  disabled?: boolean;
}

const AutocompleteInstructors: React.FC<AutocompleteInstructorsProps> = ({
  formik,
  name,
  label,
  siteId,
  platformId,
  disabled,
}) => {
  const [selected, setSelected] = React.useState<JunoUser[] | undefined>(formik.values[name]);
  const [input, setInput] = React.useState<string>(formik.values[name]?.title || '');
  const [userSearchInput, setUserSearchInput] = React.useState<string>('');
  const debouncedSearchValue = useDebounce(userSearchInput, 200);

  const searchFilter = useMemo(() => {
    if (!debouncedSearchValue) return {};
    return calculateUserSearchFilters(debouncedSearchValue);
  }, [debouncedSearchValue]);

  const { data: usersData, isLoading } = useGetAllUsers(platformId, {
    ...searchFilter,
    order: 'last_name',
    limit: 10,
    offset: 0,
  });

  const handleSelect = (reason: string, value: JunoUser[] | undefined) => {
    formik.setFieldValue(name, value);
    setSelected(value);
  };

  const handleInputChange = (e: React.SyntheticEvent, newInputValue: string) => {
    setInput(newInputValue);
    setUserSearchInput(newInputValue);
  };

  const handleChange = (
    e: React.SyntheticEvent<Element, Event>,
    value: JunoUser[] | undefined,
    reason: string,
  ) => {
    handleSelect(reason, value);
  };

  return (
    <Autocomplete
      disabled={formik.values.is_active || disabled}
      disableCloseOnSelect
      multiple
      disablePortal
      limitTags={2}
      loading={isLoading}
      loadingText={<CircularProgress size={20} />}
      onChange={handleChange}
      getOptionLabel={(option) => `${option.first_name} ${option.last_name}`}
      filterOptions={(x) => x}
      options={usersData || []}
      value={selected || []}
      onInputChange={handleInputChange}
      inputValue={input}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
          label={label}
          size={'small'}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option.first_name} ${option.last_name}`}
        </li>
      )}
    />
  );
};

export default AutocompleteInstructors;
