import { CircleOutlined as CircleOutlinedIcon, TaskAlt as TaskAltIcon } from '@mui/icons-material';
import { Avatar, Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSmallScreen',
})<{
  isSmallScreen: boolean;
}>(({ isSmallScreen }) => ({
  width: 'calc(100% - 24px)',
  maxWidth: '760px',
  alignSelf: 'center',
  // margin: isSmallScreen ? '15px auto 50px auto' : '30px auto 100px auto',
}));

export const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const DetailImage = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'imageWidth',
})<{
  imageWidth: number;
}>(({ imageWidth }) => ({
  width: '100%',
  height: `${imageWidth * (9 / 16)}px`,
  objectFit: 'cover',
}));

export const LessonBannerContainer = styled(Box)({
  backgroundColor: '#00000010',
  alignItems: 'center',
  padding: '20px',
  cursor: 'pointer',
  justifyContent: 'space-between',
  marginBottom: '16px',
});

export const BigButton = styled(Button)({
  height: '50px',
});

export const RetakeTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginRight: theme.spacing(2),
}));

export const LessonDoneIcon = styled(TaskAltIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const LessonUnfinishedIcon = styled(CircleOutlinedIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));
