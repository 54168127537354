import { Box, Pagination, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ReportsBadge = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  height: 18,
  minWidth: 18,
  justifyContent: 'center',
  borderRadius: 4,
  display: 'inline-flex',
  color: '#fff',
  padding: `0px ${theme.spacing(0.5)}`,
  marginLeft: theme.spacing(1),
  fontSize: 14,
  alignItems: 'center',
}));

export const ReportsPagination = styled(Pagination)(({ theme }) => ({
  opacity: 1,
  '.Mui-selected, .Mui-selected:hover, .Mui-selected:focus': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  '.MuiPagination-ul li .MuiPaginationItem-root': {
    fontSize: 12,
  },
  '.MuiPagination-ul li:first-of-type': {
    marginRight: theme.spacing(8),
    button: {
      color: theme.palette.primary.main,
      svg: {
        fontSize: 28,
      },
    },
  },
  '.MuiPagination-ul li:last-of-type': {
    marginLeft: theme.spacing(8),
    button: {
      color: theme.palette.primary.main,
      svg: {
        fontSize: 28,
      },
    },
  },
}));

export const ModerationTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: '1px solid #cac4d0',
  paddingLeft: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '.MuiTab-root': {
    textTransform: 'capitalize',
    fontSize: 16,
    '&.MuiButtonBase-root': { px: '20px' },
  },
}));
