import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { DialogAriaWrapper, SimpleDialog } from '@juno/ui';
import { formatHmsLong } from '@juno/utils';
import PointsButton from '../../components/PointsButton';

export const TimesUpDialog = ({ open, onClose }: any) => {
  return (
    <SimpleDialog open={open} onClose={onClose} title="Time's Up!">
      <Stack spacing={2}>
        <Typography variant='body1' color='text.primary'>
          Sorry, time is up for this lesson.
        </Typography>
        <Typography variant='body1' color='text.primary'>
          Don’t worry, your progress was saved! You will receive credit for the parts of the lesson
          that were completed before time ran out.
        </Typography>
      </Stack>
    </SimpleDialog>
  );
};

export const UnwatchedVideoDialog = ({ open, onClose }: any) => {
  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      title='Unwatched Videos'
      buttonTitle='Go to first unfinished video'
      buttonOverrideProps={{ variant: 'outlined' }}
    >
      <Stack spacing={2}>
        <Typography sx={{ ml: 0, pl: 2, pr: 2 }}>
          You must watch all of the videos for the minimum duration specified in order to move on to
          the next lesson.
        </Typography>
      </Stack>
    </SimpleDialog>
  );
};

export const IncompleteDialog = ({ open, onClose, handleNext, isSaving }: any) => {
  return (
    <DialogAriaWrapper id='incompleteDialog' onClose={onClose} open={open}>
      <DialogTitle id='incompleteDialog-dialog-title'>Skipped Questions</DialogTitle>
      <DialogContent id='incompleteDialog-dialog-description'>
        <Typography sx={{ ml: 0, pl: 2, pr: 2 }}>
          Would you like to go back and review your answers or continue to the next lesson without
          finishing the quiz?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={onClose}>
          Review my answers
        </Button>
        <PointsButton title='Complete Lesson' points={10} onClick={handleNext} loading={isSaving} />
      </DialogActions>
    </DialogAriaWrapper>
  );
};

export const RetakeDialog = ({ open, onClose, onRetake }: any) => {
  return (
    <DialogAriaWrapper id='incompleteDialog' onClose={onClose} open={open}>
      <DialogTitle id='incompleteDialog-dialog-title'></DialogTitle>
      <DialogContent id='incompleteDialog-dialog-description' sx={{ width: 350, maxWidth: '100%' }}>
        <Typography sx={{ textAlign: 'center', fontWeight: 'bold' }}>Ready to Begin?</Typography>
        <Typography sx={{ mt: 2, mb: 3, textAlign: 'center' }}>
          Once you begin a retake, all previous answers will be erased and you will no longer have
          access to past attempts.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column' }}>
        <Button variant='contained' fullWidth onClick={onRetake} size='large'>
          Retake
        </Button>
        <Button onClick={onClose} fullWidth sx={{ mt: 1 }} size='large'>
          Cancel
        </Button>
      </DialogActions>
    </DialogAriaWrapper>
  );
};

export const RetakeErrorDialog = ({ open, onClose }: any) => {
  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      title=''
      buttonTitle='Ok'
      buttonOverrideProps={{ variant: 'text' }}
    >
      <Stack spacing={2}>
        <Typography>
          Sorry, there was an error initializing the retake. Please try again.
        </Typography>
      </Stack>
    </SimpleDialog>
  );
};

export const TimedLessonPromptDialog = ({ open, onClose, onBegin, timeAllowed }: any) => {
  return (
    <DialogAriaWrapper id='incompleteDialog' onClose={onClose} open={open}>
      <DialogTitle id='incompleteDialog-dialog-title' sx={{ textAlign: 'center' }}>
        Ready to begin?
      </DialogTitle>
      <DialogContent id='incompleteDialog-dialog-description'>
        <Typography variant='subtitle1' color='text.primary' sx={{ textAlign: 'center' }}>
          This is a timed lesson.
        </Typography>
        <Typography variant='body1' color='text.primary' sx={{ textAlign: 'center' }}>
          {`Once you start, you will have ${formatHmsLong(timeAllowed)} to complete this lesson.`}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column' }}>
        <Button variant='contained' fullWidth onClick={onBegin} size='large'>
          BEGIN
        </Button>
        <Button onClick={onClose} fullWidth sx={{ mt: 1 }} size='large'>
          Cancel
        </Button>
      </DialogActions>
    </DialogAriaWrapper>
  );
};
