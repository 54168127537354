import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, Chip, Grid, Paper, TextField, Typography } from '@mui/material';
import { useSettings } from '@juno/utils';
import { DynamicVariableMap } from '../../admin/utils/enums';
import LivePreview from '../LivePreview';

interface CustomHTMLTemplateProps {
  formik: any;
  subtitleStyles: any;
  onDelete?: () => void;
}

const CustomHTMLTemplate: React.FC<CustomHTMLTemplateProps> = ({
  formik,
  subtitleStyles,
  onDelete,
}) => {
  const { isWidget } = useSettings();
  const handleCopy = (dynamicVariable: string) => {
    navigator.clipboard.writeText(dynamicVariable);
  };

  return (
    <>
      <Grid item xs={12} sm={3}>
        <Typography sx={{ ...subtitleStyles, mb: 1 }}>Information</Typography>
        <TextField
          fullWidth
          id='name'
          name='name'
          label='Certificate Title'
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          onBlur={formik.handleBlur}
          variant='outlined'
          sx={{ mb: 2, mt: 2 }}
        />
        <TextField
          fullWidth
          id='description'
          name='metadata.description'
          label='Certificate Description'
          value={formik.values.metadata?.description}
          onChange={formik.handleChange}
          error={
            formik.touched.metadata?.description && Boolean(formik.errors.metadata?.description)
          }
          helperText={formik.touched.metadata?.description && formik.errors.metadata?.description}
          onBlur={formik.handleBlur}
          variant='outlined'
          sx={{ mb: 3 }}
        />
        <Typography sx={{ ...subtitleStyles, mt: 1, mb: 2 }}>Advanced Features</Typography>
        <TextField
          fullWidth
          name='html_content'
          label='HTML Content'
          value={formik.values.html_content}
          onChange={formik.handleChange}
          error={formik.touched.html_content && Boolean(formik.errors.html_content)}
          helperText={formik.touched.html_content && formik.errors.html_content}
          onBlur={formik.handleBlur}
          variant='outlined'
          multiline
          rows={20}
          sx={{ mb: 2 }}
        />
        <Typography sx={{ ...subtitleStyles, mt: 1, mb: 1 }}>Dynamic Variables</Typography>
        <Typography sx={{ fontStyle: 'italics', fontWeight: 200, mb: 1 }}>
          Click on the variable to copy it to your clipboard
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 1,
            p: 1,
            mb: 2,
            bgcolor: 'background.paper',
          }}
        >
          {Object.entries(DynamicVariableMap).map(([key, value]) => (
            <Chip key={key} label={value} onClick={() => handleCopy(key)} sx={{}} />
          ))}
        </Box>
        <Button
          variant='contained'
          color='error'
          startIcon={<DeleteIcon />}
          sx={{ mt: 2 }}
          onClick={onDelete}
        >
          Delete Certificate
        </Button>
      </Grid>
      <Grid item xs={12} sm={9}>
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={12} sx={{ pl: 4, height: '100%' }}>
            <Paper
              elevation={3}
              sx={{
                position: 'sticky',
                top: '145px',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                margin: 'auto',
                width: '1225px',
                minWidth: '1225px',
                maxWidth: '1225px',
                height: '925px',
                minHeight: '925px',
                maxHeight: '925px',
                transform: isWidget ? 'scale(0.5)' : 'scale(0.75)',
                transformOrigin: 'top left',
                border: 'none',
              }}
            >
              <LivePreview html={formik.values.html_content} />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomHTMLTemplate;
