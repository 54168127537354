import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ArrowBackIosOutlined, ArrowForwardIosOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import classnames from 'classnames';
import debounce from 'debounce';
import PropTypes from 'prop-types';
import { DEFAULT_ASPECT_RATIO } from '../../constants';
import { useCallState } from '../../contexts/CallProvider';
import { useParticipants } from '../../contexts/ParticipantsProvider';
import { useTracks } from '../../contexts/TracksProvider';
import { useUIState } from '../../contexts/UIStateProvider';
import { isLocalId } from '../../contexts/participantsState';
import { useCamSubscriptions } from '../../hooks/useCamSubscriptions';
import { useResize } from '../../hooks/useResize';
import { useScrollbarWidth } from '../../hooks/useScrollbarWidth';
import Tile from '../Tile';
import { useBlockScrolling } from './useBlockScrolling';

const MAX_SCROLL_BUFFER = 10;

export const ParticipantBar = ({
  aspectRatio = DEFAULT_ASPECT_RATIO,
  fixed = [],
  others = [],
  width,
}) => {
  const { networkState } = useCallState();
  const { currentSpeaker, screens, swapParticipantPosition } = useParticipants();
  const { maxCamSubscriptions } = useTracks();
  const { pinnedId, showParticipantsBar } = useUIState();

  const spaceBefore = useRef(null);
  const spaceAfter = useRef(null);
  const scrollRef = useRef(null);
  const othersRef = useRef(null);

  const [range, setRange] = useState([0, 20]);
  const [isSidebarScrollable, setIsSidebarScrollable] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  const blockScrolling = useBlockScrolling(scrollRef);
  const scrollbarWidth = useScrollbarWidth();

  const othersCount = useMemo(() => others.length, [others]);
  const visibleOthers = useMemo(() => others.slice(range[0], range[1]), [others, range]);
  const currentSpeakerId = useMemo(() => currentSpeaker?.id, [currentSpeaker]);
  const hasScreenshares = useMemo(() => screens.length > 0, [screens]);

  const otherIds = useMemo(() => others.map((o) => o?.id), [others]);

  const [camSubscriptions, setCamSubscriptions] = useState({
    subscribedIds: [],
    pausedIds: [],
  });

  useCamSubscriptions(camSubscriptions?.subscribedIds, camSubscriptions?.pausedIds);

  const updateCamSubscriptions = useCallback(
    (r) => {
      const scrollEl = scrollRef.current;
      const fixedRemote = fixed.filter((p) => !isLocalId(p.id));

      if (!showParticipantsBar) {
        setCamSubscriptions({
          subscribedIds: [currentSpeakerId, pinnedId, ...fixedRemote.map((p) => p.id)],
          pausedIds: [],
        });
        return;
      }
      if (!scrollEl) return;

      const buffer = Math.max(0, maxCamSubscriptions - (r[1] - r[0]) - fixedRemote.length) / 2;
      const min = Math.max(0, r[0] - buffer);
      const max = Math.min(otherIds.length, r[1] + buffer);
      const ids = otherIds.slice(min, max);

      if (!ids.includes(currentSpeakerId) && !isLocalId(currentSpeakerId)) {
        ids.push(currentSpeakerId);
      }

      const subscribedIds = [...fixedRemote.map((p) => p.id), ...ids];
      const pausedIds = otherIds.filter((id, i) => {
        if (!ids.includes(id)) return false;
        if (id === currentSpeakerId) return false;

        const left = i * width;
        const fixedWidth = fixed.length * width;
        const visibleScrollWidth = scrollEl.clientWidth - fixedWidth;
        const paused =
          left + width < scrollEl.scrollLeft || left > scrollEl.scrollLeft + visibleScrollWidth;

        return paused;
      });

      setCamSubscriptions({
        subscribedIds,
        pausedIds,
      });
    },
    [currentSpeakerId, fixed, width, maxCamSubscriptions, otherIds, pinnedId, showParticipantsBar],
  );

  /** this method assists with how many cams are streaming to the user 
    if the user tile is not visible, it will pause the video stream to save bandwidth */
  const updateVisibleRange = useCallback(
    (sl) => {
      const visibleWidth = scrollRef.current.clientWidth;
      const scrollBuffer = Math.min(MAX_SCROLL_BUFFER, (2 * visibleWidth) / width);
      const visibleItemCount = Math.ceil(visibleWidth / width + scrollBuffer);
      let start = Math.floor(Math.max(0, sl - (scrollBuffer / 2) * width) / width);
      const end = Math.min(start + visibleItemCount, othersCount);
      if (end - visibleItemCount < start) {
        start = Math.max(0, end - visibleItemCount);
      }

      setRange([start, end]);
      if (spaceBefore.current) spaceBefore.current.style.width = `${start * width}px`;
      if (spaceAfter.current) spaceAfter.current.style.width = `${(othersCount - end) * width}px`;
      return [start, end];
    },
    [width, othersCount],
  );

  useResize(() => {
    const scrollEl = scrollRef.current;
    if (!scrollEl) return;

    setIsSidebarScrollable(scrollEl.scrollWidth > scrollEl.clientWidth);
    const r = updateVisibleRange(scrollEl.scrollLeft);
    updateCamSubscriptions(r);
  }, [scrollRef, showParticipantsBar, updateCamSubscriptions, updateVisibleRange]);

  useEffect(() => {
    const scrollEl = scrollRef.current;
    if (!scrollEl) return;

    const handleScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = scrollEl;

      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 5);

      const r = updateVisibleRange(scrollEl.scrollLeft);
      updateCamSubscriptions(r);
    };

    handleScroll();
    scrollEl.addEventListener('scroll', handleScroll);
    return () => {
      scrollEl.removeEventListener('scroll', handleScroll);
    };
  }, [scrollRef, updateCamSubscriptions, updateVisibleRange]);

  const scroll = (direction) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: direction * 200, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const scrollEl = scrollRef.current;
    if (!hasScreenshares || !scrollEl) return;

    const maybePromoteActiveSpeaker = () => {
      const fixedOther = fixed.find((f) => !f.isLocal);
      if (!fixedOther || fixedOther?.id === currentSpeakerId || !scrollEl) return;

      if (visibleOthers.every((p) => p.id !== currentSpeakerId) && !isLocalId(currentSpeakerId)) {
        swapParticipantPosition(fixedOther.id, currentSpeakerId);
        return;
      }

      const activeTile = othersRef.current?.querySelector(`[id="${currentSpeakerId}"]`);
      if (!activeTile || currentSpeakerId === pinnedId) return;

      const { height: tileHeight } = activeTile.getBoundingClientRect();
      const othersVisibleHeight = scrollEl.clientHeight - othersRef.current.offsetTop;
      const scrolledOffsetTop = activeTile.offsetTop - scrollEl.scrollTop;

      if (
        scrolledOffsetTop + tileHeight / 2 < othersVisibleHeight &&
        scrolledOffsetTop > -tileHeight / 2
      ) {
        return;
      }

      swapParticipantPosition(fixedOther.id, currentSpeakerId);
    };

    maybePromoteActiveSpeaker();
    const throttledHandler = debounce(maybePromoteActiveSpeaker, 100);
    scrollEl.addEventListener('scroll', throttledHandler);

    return () => {
      scrollEl.removeEventListener('scroll', throttledHandler);
    };
  }, [currentSpeakerId, fixed, hasScreenshares, pinnedId, swapParticipantPosition, visibleOthers]);

  const otherTiles = useMemo(
    () =>
      visibleOthers.map((callItem) => (
        <Tile aspectRatio={aspectRatio} key={callItem.id} participant={callItem} />
      )),
    [aspectRatio, visibleOthers],
  );

  return (
    <div className='scroll-container'>
      {showLeftArrow && <ArrowBackIosOutlined className='left-arrow' onClick={() => scroll(-1)} />}
      <Box
        ref={scrollRef}
        className={classnames('sidebar', {
          blockScrolling,
          scrollable: isSidebarScrollable,
          scrollbarOutside: scrollbarWidth > 0,
        })}
      >
        {fixed.map((item, i) => (
          <Tile key={i} aspectRatio={aspectRatio} participant={item} network={networkState} />
        ))}
        {showParticipantsBar && (
          <div ref={othersRef} className='participants'>
            <div ref={spaceBefore} style={{ width }} />
            {otherTiles}
            <div ref={spaceAfter} style={{ width }} />
          </div>
        )}
      </Box>
      {showRightArrow && (
        <ArrowForwardIosOutlined className='right-arrow' onClick={() => scroll(1)} />
      )}
    </div>
  );
};

ParticipantBar.propTypes = {
  aspectRatio: PropTypes.number,
  fixed: PropTypes.array.isRequired,
  others: PropTypes.array.isRequired,
  width: PropTypes.number,
};

export default ParticipantBar;
