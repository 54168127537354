import * as Yup from 'yup';
import { string } from 'yup';

const validationSchema = Yup.object().shape({
  uploaded_file: string()
    .url()
    .test('url', 'Must point to a .zip file', function (value) {
      if (value) {
        return value?.substr(value.length - 4).includes('.zip');
      }
      return false;
    }),
});

export default validationSchema;
