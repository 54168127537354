import React, { useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Button, FormControl, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { updateAccessPass } from '@juno/client-api';
import { AccessPass, JunoUser } from '@juno/client-api/model';
import {
  AdminEditPanelHeader,
  AutoCompleteUsers,
  ConfirmDeleteDialog,
  DefaultCircularProgress,
  GenericFormikInput,
  LoseChangesDialog,
  SearchableUserList,
} from '@juno/ui';
import { UserListActionEnum, snackOptions, useDynamicUserList } from '@juno/utils';
import { accessPassValidationSchema, snackMessage } from '../utils/utils';

const PAGE_SIZE = 50;

interface AccessPassItemProps {
  handleDelete: (creditId: string) => void;
  allAccessPasses: AccessPass[] | undefined;
  siteId: string;
  refreshPasses: () => void;
  platformId: string;
}
const AccessPassItem: React.FC<AccessPassItemProps> = ({
  handleDelete,
  allAccessPasses,
  siteId,
  refreshPasses,
  platformId,
}) => {
  const pass_id = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openDelete, setOpenDelete] = useState(false);
  const [openLoseChanges, setOpenLoseChanges] = useState(false);
  const [accessPass, setAccessPass] = useState<AccessPass>(
    allAccessPasses?.filter((pass) => pass.id == pass_id.id)[0] ?? ({} as AccessPass),
  );
  const [isFocusedUrlInput, setHelperText] = useState(false);
  const [userSearchValue, setUserSearchValue] = useState<string>('');
  const [userSearchValue2, setUserSearchValue2] = useState<string>('');
  const [userLoading, setUserLoading] = useState<string | undefined>(undefined);
  const [listUserLoading, setListUserLoading] = useState<string | undefined>(undefined);

  const handleAddUser = async (user: JunoUser) => {
    setUserLoading(user.id);
    const newUser = { ...user, access_passes: [...user.access_passes, accessPass] };
    setTimeout(() => {
      handleUpdateUser(newUser, UserListActionEnum.ADD);
      setUserLoading(undefined);
    }, 500);
  };

  const handleDeleteUser = async (user: JunoUser) => {
    setListUserLoading(user.id);
    const newUser = {
      ...user,
      access_passes: user.access_passes.filter((pass) => pass.id !== accessPass.id),
    };
    setTimeout(() => {
      handleUpdateUser(newUser, UserListActionEnum.REMOVE);
      setListUserLoading(undefined);
    }, 500);
  };

  const {
    list: { list: optimisticValue, isLoadingPage, isInitialLoad, ref },
    secondaryList: {
      list: allUsersData,
      isLoadingPage: isLoadingPageSecondary,
      isInitialLoad: isInitialLoadSecondary,
      ref: secondaryRef,
    },
    handleUpdateUser,
  } = useDynamicUserList(
    {
      filter: {
        access_passes: accessPass?.id || '',
      },
      order: 'last_name',
    },
    PAGE_SIZE,
    userSearchValue,
    userSearchValue2,
    platformId,
    {
      exclude: {
        access_passes: accessPass?.id || '',
      },
      order: 'last_name',
    },
    (a, b) => a.last_name.localeCompare(b.last_name),
    true,
    true,
  );

  return (
    <Formik
      key={'access-pass-form'}
      enableReinitialize={true}
      initialValues={{
        name: accessPass.name,
        upgrade_enrollment_url: accessPass.upgrade_enrollment_url,
      }}
      validationSchema={accessPassValidationSchema(
        allAccessPasses?.filter((pass) => pass.id !== accessPass.id) ?? [],
      )}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const resp = await updateAccessPass(siteId, accessPass.id, values as AccessPass);
          setAccessPass(resp);
          enqueueSnackbar(snackMessage.update, snackOptions('success'));
          setSubmitting(false);
          refreshPasses();
        } catch (e) {
          enqueueSnackbar(snackMessage.error, snackOptions('error'));
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        handleReset,
        setFieldValue,
        handleChange,
        setFieldTouched,
        handleSubmit,
        handleBlur,
        isSubmitting,
        dirty,
      }) => {
        return (
          <Form>
            <AdminEditPanelHeader
              shouldShow={dirty}
              title={accessPass?.name || ''}
              onClickDiscard={handleReset}
              onClickSave={handleSubmit}
              onClickGoBack={() => {
                navigate(-1);
              }}
              isSubmitting={isSubmitting}
            />
            <Box p='10px 12px'>
              <FormControl fullWidth>
                <Box>
                  <Typography mb={2} fontWeight='bold'>
                    Information
                  </Typography>
                  <GenericFormikInput
                    label='Access Pass Title'
                    name='name'
                    type='text'
                    placeholder='Access Pass Title'
                    value={values.name}
                  />
                </Box>
                <Box mt={2}>
                  <GenericFormikInput
                    label='Upgrade URL'
                    name='upgrade_enrollment_url'
                    type='text'
                    placeholder='Upgrade URL'
                    value={values.upgrade_enrollment_url}
                    onFocus={() => setHelperText(true)}
                    onBlur={() => setHelperText(false)}
                    helperText={isFocusedUrlInput && ' https:// ...'}
                  />
                </Box>
                <Grid container mt={1} spacing={2} style={{ maxWidth: 800 }}>
                  <Grid item xs={12}>
                    <Typography fontWeight='bold'>Users with this Access Pass</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <AutoCompleteUsers
                      options={allUsersData || []}
                      selected={[]}
                      onSubmit={handleAddUser}
                      onInputChange={setUserSearchValue2}
                      isLoading={isInitialLoadSecondary}
                      isLoadingPage={isLoadingPageSecondary}
                      userLoading={userLoading}
                      locked={false}
                      label={'Grant Access Pass to Users'}
                      showClearButton={false}
                      paginateRef={secondaryRef}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box mb={2}>
                      <SearchableUserList
                        label={''}
                        users={optimisticValue || []}
                        locked={false}
                        userLoading={listUserLoading}
                        onDelete={handleDeleteUser}
                        searchValue={userSearchValue}
                        setSearchValue={setUserSearchValue}
                        loading={isInitialLoad}
                        showSubheader={false}
                      >
                        <Box
                          style={{
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                          }}
                          mt={1 / 2}
                        >
                          <DefaultCircularProgress
                            props={{
                              size: 18,
                              variant: 'indeterminate',
                              style: { visibility: isLoadingPage ? 'visible' : 'hidden' },
                            }}
                          />
                          <Box ref={ref} sx={{ pb: 1 }} />
                        </Box>
                      </SearchableUserList>
                    </Box>
                  </Grid>
                </Grid>
              </FormControl>
            </Box>
            <Box>
              <Button
                variant='contained'
                color='error'
                sx={{ mt: 3, mb: 3, letterSpacing: '3px', p: '12px 16px' }}
                startIcon={<DeleteForeverIcon />}
                onClick={() => {
                  setOpenDelete((old) => !old);
                }}
              >
                Delete Access Pass
              </Button>
            </Box>
            {openDelete && (
              <ConfirmDeleteDialog
                handleClose={() => {
                  setOpenDelete(false);
                }}
                handleDelete={() => {
                  setOpenDelete(false);
                  handleDelete(accessPass.id);
                }}
                title={'Delete Access Pass'}
                message={
                  <>
                    <Typography sx={{ mb: 3 }}>
                      Are you sure you want to permanently delete this Access Pass?
                    </Typography>
                    <Typography>
                      All of the information will be lost. This action cannot be undone.
                    </Typography>
                  </>
                }
              />
            )}
            {openLoseChanges && (
              <LoseChangesDialog
                handleCancel={() => {
                  setOpenLoseChanges(false);
                }}
                handleDiscard={() => {
                  setOpenLoseChanges(false);
                  handleReset();
                }}
                handleSave={() => {
                  setOpenLoseChanges(false);
                  handleSubmit();
                }}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
export default AccessPassItem;
