import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TrayContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'flex',
  marginTop: 'auto',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: '5px',
}));

export const TrayIcon = styled(IconButton, {
  shouldForwardProp: (prop) => prop != 'enabled' && prop != 'usePrimaryColor',
})<{
  enabled: boolean;
  usePrimaryColor?: boolean;
}>(({ enabled, usePrimaryColor, theme }) => ({
  margin: 'auto 5px',
  background: enabled
    ? usePrimaryColor
      ? theme.palette.primary.main
      : 'rgba(255,255,255,0.85)'
    : 'rgba(255,255,255,0.15)', //TODO theme?
  color: enabled && !usePrimaryColor ? 'black' : 'white', //TODO theme?
  padding: '12px',
  '.MuiSvgIcon-root': {
    fontSize: '1.4rem',
  },
  '&:hover': {
    background: enabled ? 'rgba(255,255,255,0.65)' : 'rgba(255,255,255,0.25)',
  },
  '@media screen and (max-width: 450px)': {
    '& > svg': {
      fontSize: '1.1rem',
    },
  },
}));
