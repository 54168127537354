import React from 'react';
import { Cancel as CancelIcon, Check as CheckIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { Breakpoints } from '@juno/utils/hooks';
import { EnrollmentModel } from './components/EnrollmentTile';
import { CompletedBox, EllipsisTypography } from './styles';

interface GradeProps {
  grade: string | null | undefined;
  didPass: boolean;
}

const Grade: React.FC<GradeProps> = ({ grade, didPass }) => {
  const finalGrade = parseFloat(grade || '0');
  const gradeColor = didPass ? '#6FBB33' : '#BC4848';
  const Icon = didPass ? CheckIcon : CancelIcon;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Icon sx={{ color: gradeColor, fontSize: '16px', mr: 0.5 }} />
      <Typography color={gradeColor}>{finalGrade}%</Typography>
    </Box>
  );
};

const CompletedCourseTileContent = (enrollment: EnrollmentModel, breakpoints: Breakpoints) => {
  const { md } = breakpoints;
  return (
    <>
      <CompletedBox isSmallScreen={md}>
        <Grade grade={enrollment.final_grade} didPass={enrollment.did_pass} />
        <EllipsisTypography variant='body2'>{enrollment.credits_earned} Credits</EllipsisTypography>
      </CompletedBox>
    </>
  );
};

export default CompletedCourseTileContent;
