import React, { Dispatch, SetStateAction } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  IconButton,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import { JunoUser } from '@juno/client-api/model';
import { SkeletonUserTile, UserTile } from '../../index';

interface ListLearnersProps {
  label: string;
  users: JunoUser[];
  locked?: boolean;
  userLoading?: string;
  onDelete: (learner: any) => void;
  loading?: boolean;
  showSubheader?: boolean;
  children?: React.ReactNode;
  searchValue?: string;
  setSearchValue?: Dispatch<SetStateAction<string>>;
}

const SearchableUserList: React.FC<ListLearnersProps> = ({
  label,
  users,
  locked = false,
  userLoading,
  onDelete,
  loading = false,
  showSubheader = true,
  children,
  searchValue,
  setSearchValue,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue && setSearchValue(event.target.value);
  };

  const handleDelete = (user: JunoUser) => {
    onDelete(user);
  };

  const handleClearInput = () => {
    setSearchValue && setSearchValue('');
  };

  const skeletonUsers = [{}, {}, {}, {}, {}, {}, {}, {}];

  return (
    <Box pl={1} pr={1}>
      <CardHeader
        sx={{ p: 0, mb: 2 }}
        title={
          <TextField
            fullWidth
            value={searchValue}
            onChange={handleChange}
            placeholder='Search'
            size='small'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: searchValue !== '' && (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='clear input'
                    onClick={handleClearInput}
                    edge='end'
                    size='small'
                  >
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        }
      />

      <List
        sx={{
          width: '100%',
          height: '510px',
          overflow: 'auto',
        }}
        className='dragon'
      >
        {showSubheader && (
          <>
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{ pr: 1, pl: 1, width: '100%' }}
            >
              <Typography>{label}</Typography>
              <Typography>{`showing: ${users.length}/${users.length}`}</Typography>
            </Stack>
            <Divider sx={{ mb: 1 }} />
          </>
        )}

        {users?.map((item, index) => (
          <Box
            key={item.id}
            style={{
              pointerEvents: userLoading ? 'none' : 'auto',
              cursor: userLoading ? 'pointer' : 'auto',
              opacity: userLoading && userLoading !== item.id ? 0.3 : 1,
            }}
          >
            <UserTile
              user={item}
              icon={<CloseIcon sx={{ fontSize: 16 }} />}
              handleIconClick={handleDelete}
              email={true}
              virtualized={true}
              loading={userLoading === item.id}
            />
          </Box>
        ))}
        {loading && skeletonUsers.map((user, index) => <SkeletonUserTile key={`${index}`} />)}
        {children}
      </List>
    </Box>
  );
};

export default SearchableUserList;
