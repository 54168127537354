import React, { useEffect, useRef, useState } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Stack } from '@mui/material';
import { ContentContainer, HandleBar, PaperContainer } from './styles';

interface DraggableContainerProps {
  children: React.ReactNode;
}

const DraggableContainer = ({ children }: DraggableContainerProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
    if (containerRef.current) {
      setDragOffset({
        x: event.pageX - containerRef.current.offsetLeft,
        y: event.pageY - containerRef.current.offsetTop,
      });
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    if (isDragging && containerRef.current) {
      containerRef.current.style.left = `${event.pageX - dragOffset.x}px`;
      containerRef.current.style.top = `${event.pageY - dragOffset.y}px`;
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging]);

  return (
    <PaperContainer
      id='draggable-container'
      onMouseDown={handleMouseDown}
      elevation={isDragging ? 4 : 2}
      ref={containerRef}
    >
      <HandleBar>
        <DragIndicatorIcon
          sx={{
            color: 'primary.contrastText',
            transform: 'rotate(90deg)',
          }}
        />
      </HandleBar>
      <ContentContainer>{children}</ContentContainer>
    </PaperContainer>
  );
};

export default DraggableContainer;
