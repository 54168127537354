import { Dayjs } from 'dayjs';
import { WidgetModel } from '../constants';

export const lineColors = [
  '#6A1BBC',
  '#4886D3',
  '#52AC9F',
  '#6A1BBC',
  '#4886D3',
  '#52AC9F',
  '#6A1BBC',
  '#4886D3',
  '#52AC9F',
];

export interface LineChartWidgetProps {
  widget: WidgetModel;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  index: number;
  isSelected: boolean;
  setSelectedWidget: (setter: string | null) => void;
}

export interface AnalyticsIntervalModel {
  DAY: number;
  MONTH: number;
  YEAR: number;
  HOUR?: number;
  MINUTE?: number;
  SECOND?: number;
  numOccurred: number;
  numUsers: number;
  numContent: number;
  sumMetric: number;
}

export interface AnalyticsAggregateModel {
  content_id: string;
  content_slug: string;
  content_title: string;
  aggregate_value: number;
}
