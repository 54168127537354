import React, { useMemo } from 'react';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { useParams } from 'react-router-dom';
import { useGetSpeaker } from '@juno/client-api';
import { FeatureConfig as FeatureConfigModel, Site as SiteModel } from '@juno/client-api/model';
import { JunoSpin } from '@juno/ui';

interface LearningPageProps {
  site: SiteModel;
  configs: FeatureConfigModel[];
}

const SpeakerPage: React.FC<LearningPageProps> = ({ site, configs }) => {
  const { slug } = useParams();
  const { data, isLoading } = useGetSpeaker(site.id, slug || '');
  const htmlValue = useMemo(() => {
    const sanitizedString = DOMPurify.sanitize(data?.description || ('' as string), {
      ADD_TAGS: ['iframe'],
      ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'],
    });
    // uncomment to debug what's getting removed
    // console.log('Dirty values', DOMPurify.removed);
    return sanitizedString;
  }, [data?.description]);
  if (isLoading) return <JunoSpin />;
  return (
    <Box sx={{ width: 760, margin: '50px auto' }}>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={12} md={4}>
          <Avatar src={data?.image || ''} sx={{ width: 240, height: 240 }} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
              {data?.name}
            </Typography>
            <Typography variant='subtitle2' sx={{ marginTop: '50px' }}>
              {data?.preview_text}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography
            dangerouslySetInnerHTML={{
              __html: htmlValue as unknown as string,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SpeakerPage;
