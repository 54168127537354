import React, { useState } from 'react';
import { Card, Divider, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  getGetAllSessionsQueryKey,
  useCreateNewSession,
  useGetAllSessions,
} from '@juno/client-api';
import { Session, TypeA36Enum } from '@juno/client-api/model';
import { GridV2 } from '@juno/modules';
import { Container, CreateNewSessionDialog, DefaultSearchSortFilter } from '@juno/ui';
import { useSessionsSearchFilter, useSettings } from '@juno/utils';

const SessionsAdmin: React.FC = () => {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { site } = useSettings();
  const [, , searchFilter, setSearch] = useSessionsSearchFilter();
  const [sort, setSort] = useState('-date_created');
  const navigate = useNavigate();
  const { data: sessions, isLoading } = useGetAllSessions(
    site?.id || '',
    {
      // TODO - add subbreakout filter to admin options?
      filter: {
        subbreakout: false,
      },
      filter_or: searchFilter,
      order: sort,
    },
    {
      query: {
        enabled: !!site?.id,
      },
    },
  );
  const createSession = useCreateNewSession();
  const queryClient = useQueryClient();

  const handleOpenCreateGroup = (e: any) => {
    e.stopPropagation();
    setCreateDialogOpen(true);
  };

  const handleSaveNewSession = async (session: Session) => {
    setIsSaving(true);
    try {
      if (session) {
        await createSession.mutateAsync({ siteId: site?.id || '', data: session });
        setIsSaving(false);
        setCreateDialogOpen(false);
        await queryClient.invalidateQueries(getGetAllSessionsQueryKey(site?.id || ''));
        navigate(`/${site?.slug}/admin/sessions/${session.slug}`);
      }
    } catch (e) {
      console.error(e);
      setIsSaving(false);
      setCreateDialogOpen(false);
    }
  };

  const gridData = sessions?.map((session) => ({
    id: session.id,
    title: session.title || '',
    abstract: session.preview_text || '',
    icon: session.icon || '',
    slug: session.slug || '',
  }));

  return (
    <Container>
      <Card sx={{ p: 2, mb: 4 }}>
        <Typography variant='h5'>Sessions</Typography>
        <Divider sx={{ mt: 2, mb: 4 }} />
        <DefaultSearchSortFilter
          buttonDisabled={false}
          buttonText={'New Session'}
          onClickButton={() => setCreateDialogOpen(true)}
          setSearch={(value: string) => setSearch(value)}
          setSort={setSort}
          sort={sort}
          showFilter={false}
          sortOptions={[
            { value: 'title', label: 'Alphabetical' },
            { value: '-date_created', label: 'Date Created' },
          ]}
        />
        <Stack direction='row' flexWrap={'wrap'} gap={2}>
          <GridV2 isLoading={isLoading} items={gridData || []} type='session' isAdmin={true} />
        </Stack>
      </Card>
      <CreateNewSessionDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onSave={handleSaveNewSession}
        isSaving={isSaving}
        allSessions={sessions || []}
        eventType={TypeA36Enum.NUMBER_3}
      />
    </Container>
  );
};

export default SessionsAdmin;

export const SkeletonPagesTile: React.FC = () => {
  return (
    <Grid item xs={12} md={3} lg={2}>
      <Skeleton variant='rectangular' height={160} width={235} sx={{ borderRadius: 2 }} />
      <Skeleton variant='text' height={20} sx={{ mt: 1, width: '50%' }} />
      <Skeleton variant='text' height={16} sx={{ width: '40%' }} />
    </Grid>
  );
};
