import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Card, IconButton, Popover, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AnimatePresence, motion } from 'framer-motion';
import { useNotificationContext } from './NotificationContext';
import NotificationsCard from './NotificationsCard';
import PushNotification from './PushNotification';

interface NotificationsPopoverProps {
  open: boolean;
  anchorRef: React.RefObject<HTMLButtonElement> | undefined;
  handleClose: () => void;
}
const NotificationsPopover: React.FC<NotificationsPopoverProps> = ({
  open,
  anchorRef,
  handleClose,
}) => {
  const theme = useTheme();
  const { newNotifications, setNewNotifications, readNotification } = useNotificationContext();
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleExpandToggle = () => {
    setExpanded(!expanded);
  };
  const handlePopoverClose = () => {
    handleClose();
    setExpanded(false);
  };

  const displayedNotifications = expanded ? newNotifications : newNotifications.slice(0, 2);
  return (
    <>
      {displayedNotifications.length > 0 ? (
        <AnimatePresence>
          <Box
            sx={{
              position: 'fixed',
              top: theme.spacing(6),
              right: theme.spacing(2),
              zIndex: 1500,
              width: 300,
              height: 80,
            }}
          >
            <Box position={'relative'}>
              {displayedNotifications.map((notification) => (
                <motion.div
                  key={notification.id}
                  initial={{ opacity: 0, x: 300 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 300 }}
                  transition={{ duration: 0.5 }}
                  style={{
                    marginTop: '5px',
                  }}
                >
                  <PushNotification
                    onClick={() => {
                      const updatedNotifications = newNotifications.filter(
                        (notif) => notif.id !== notification.id,
                      );
                      setNewNotifications(updatedNotifications);
                      readNotification([notification.id]);
                    }}
                    onClose={() => {
                      const updatedNotifications = newNotifications.filter(
                        (notif) => notif.id !== notification.id,
                      );
                      setNewNotifications(updatedNotifications);
                    }}
                    notification={notification}
                  />
                </motion.div>
              ))}
              {newNotifications.length > 2 && !expanded && (
                <motion.div
                  initial={{ opacity: 0, x: 300 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 300 }}
                  transition={{ duration: 0.5 }}
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    width: '100%',
                    textAlign: 'right',
                    backgroundColor: 'rgba(255,255,255,1)',
                    borderRadius: theme.spacing(1),
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: theme.spacing(1),
                    cursor: 'pointer',
                  }}
                  onClick={handleExpandToggle}
                >
                  <Typography
                    aria-label='See more'
                    variant='subtitle2'
                    sx={{ color: theme.palette.primary.main }}
                  >
                    See {newNotifications.length - 2} More
                  </Typography>
                  <IconButton aria-label='expand' color='primary' onClick={handleExpandToggle}>
                    <ExpandMoreIcon />
                  </IconButton>
                </motion.div>
              )}
            </Box>
          </Box>
        </AnimatePresence>
      ) : (
        <Popover
          open={open}
          anchorEl={anchorRef?.current}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transitionDuration={300}
          sx={{
            bgcolor: 'transparent',
          }}
        >
          <Card sx={{ width: '300px', p: 1, pt: 2, pb: 1 }}>
            <NotificationsCard handleClose={handlePopoverClose} />
          </Card>
        </Popover>
      )}
    </>
  );
};
export default NotificationsPopover;
