import React from 'react';
import {
  AddCircleOutline,
  AddTaskOutlined,
  CheckCircle,
  CheckCircleOutlined,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { JunoUser } from '@juno/client-api/model';

interface ExtendedUser extends JunoUser {
  session_scheduled_attendees?: string[];
}

interface AttendIconProps {
  sessionId: string;
  dateEnd: string;
  scheduled_attendees: JunoUser[];
  currentUser: ExtendedUser;
  handleClick: () => void;
  isLoading: boolean;
}

const AttendIcon: React.FC<AttendIconProps> = ({
  sessionId,
  dateEnd,
  scheduled_attendees,
  currentUser,
  handleClick,
  isLoading,
}) => {
  const now = new Date();
  const end = new Date(dateEnd);
  const isAttending =
    scheduled_attendees?.find((user) => user.id === currentUser.id) !== undefined ||
    currentUser?.session_scheduled_attendees?.includes(sessionId);

  if (now > end) {
    if (isAttending) {
      return (
        <IconButton disabled sx={{ p: 0 }} aria-label='Already attended past session'>
          <CheckCircleOutlined />
        </IconButton>
      );
    } else {
      return (
        <IconButton disabled sx={{ p: 0 }} aria-label='Did not attend past session'>
          <AddCircleOutline />
        </IconButton>
      );
    }
  } else {
    if (isAttending) {
      return (
        <Tooltip title='Attending' placement='top'>
          <IconButton
            color='success'
            sx={{ p: 0, cursor: isLoading ? 'wait' : 'pointer' }}
            onClick={() => {
              if (!isLoading) {
                handleClick();
              }
            }}
          >
            <CheckCircle />
          </IconButton>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip title='Attend' placement='top'>
          <IconButton
            color='primary'
            sx={{ p: 0, cursor: isLoading ? 'wait' : 'pointer' }}
            onClick={() => {
              if (!isLoading) {
                handleClick();
              }
            }}
          >
            <AddTaskOutlined />
          </IconButton>
        </Tooltip>
      );
    }
  }
};

export default AttendIcon;
