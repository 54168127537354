import React, { useEffect } from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { useDebounce } from 'usehooks-ts';
import { AlignmentEnum, JunoImage, SizeEnum } from '@juno/client-api/fakeModel';
import { ComponentData, Component as ComponentModel } from '@juno/client-api/model';
import { JunoImageUpload } from '@juno/ui';
import { optimizeImage } from '@juno/utils';
import ModuleButtonForm from '../ModuleButton/form';
import type { ModuleButtonModel } from '../ModuleButton/form';

interface HeroFormProps {
  component?: ComponentModel;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
  formik: FormikProps<HeroComponentModel>;
}

interface HeroFormWrapperProps {
  component?: ComponentModel;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
}

export interface HeroDataModel extends ComponentData {
  image: string;
  title: string;
  subtitle: string;
  alignment: AlignmentEnum;
  size: SizeEnum;
  parallax: boolean;
  button: ModuleButtonModel;
  fullWidth: boolean;
}

export interface HeroComponentModel extends ComponentModel {
  data: HeroDataModel;
}

const HeroForm: React.FC<HeroFormProps> = ({ component, onUpdate, formik }) => {
  const { handleSubmit, setValues, values } = formik;
  const debouncedValues = useDebounce(values, 700);
  useEffect(() => {
    if (component) {
      onUpdate({ ...component, ...debouncedValues });
    }
  }, [debouncedValues]);
  return (
    <Box pb={5}>
      <Typography variant='body2' sx={{ opacity: 0.6 }}>
        Editing Section
      </Typography>
      <Typography variant='h5' sx={{ mb: 4 }}>
        {values.name || 'Hero'}
      </Typography>
      <TextField
        value={values.name}
        onChange={formik.handleChange}
        name='name'
        label='name'
        variant='filled'
        fullWidth
        InputProps={{ disableUnderline: true }}
        sx={{ mb: 2 }}
      />
      <JunoImageUpload
        style={{
          aspectRatio: '16/9',
          height: 150,
          width: 250,
        }}
        src={optimizeImage(270, values?.data?.image || '')}
        srcUrl={values?.data?.image || ''}
        onFileUploaded={(selected: JunoImage | null) =>
          formik.setFieldValue('data.image', selected?.url || '')
        }
      />
      <TextField
        value={values.data.title}
        onChange={formik.handleChange}
        name='data.title'
        label='title'
        variant='filled'
        rows={2}
        multiline
        fullWidth
        InputProps={{ disableUnderline: true }}
        sx={{ mt: 2 }}
      />
      <TextField
        value={values?.data?.subtitle}
        onChange={formik.handleChange}
        name='data.subtitle'
        label='subtitle'
        variant='filled'
        fullWidth
        rows={3}
        multiline
        InputProps={{ disableUnderline: true }}
        sx={{ mt: 2 }}
      />
      <Box display='flex'>
        <FormControl sx={{ mt: 3 }}>
          <FormLabel id='alignment_label' sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>
            Alignment
          </FormLabel>
          <RadioGroup
            aria-labelledby='alignment_label'
            defaultValue={AlignmentEnum.left}
            name='data.alignment'
            value={values?.data?.alignment}
            onChange={(e) => {
              setValues({
                ...values,
                data: { ...values.data, alignment: e.target.value as AlignmentEnum },
              });
            }}
          >
            <FormControlLabel value={AlignmentEnum.left} control={<Radio />} label='Left' />
            <FormControlLabel value={AlignmentEnum.center} control={<Radio />} label='Center' />
            <FormControlLabel value={AlignmentEnum.right} control={<Radio />} label='Right' />
          </RadioGroup>
        </FormControl>
        <FormControl sx={{ mt: 3 }}>
          <FormLabel id='size_label' sx={{ fontSize: '.9rem', fontWeight: 'bold' }}>
            Size
          </FormLabel>
          <RadioGroup
            aria-labelledby='size_label'
            defaultValue={SizeEnum.medium}
            name='data.size'
            value={values?.data?.size}
            onChange={(e) => {
              setValues({ ...values, data: { ...values.data, size: e.target.value as SizeEnum } });
            }}
          >
            <FormControlLabel value={SizeEnum.small} control={<Radio />} label='Small' />
            <FormControlLabel value={SizeEnum.medium} control={<Radio />} label='Medium' />
            <FormControlLabel value={SizeEnum.large} control={<Radio />} label='Large' />
          </RadioGroup>
        </FormControl>
      </Box>
      <ModuleButtonForm component={values} formik={formik} onUpdate={onUpdate} />
    </Box>
  );
};

const HeroFormWrapper: React.FC<HeroFormWrapperProps> = ({ component, onSave, onUpdate }) => {
  return (
    <Formik
      initialValues={(component as HeroComponentModel) || ({} as HeroComponentModel)}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        if (component) {
          onSave({ ...component, ...values });
        }
      }}
      enableReinitialize
    >
      {(formik) => {
        return (
          <Form>
            <HeroForm component={component} onSave={onSave} onUpdate={onUpdate} formik={formik} />
          </Form>
        );
      }}
    </Formik>
  );
};

export default HeroFormWrapper;
