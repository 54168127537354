export const a11yProps = (index: number | string, id?: string) => {
  if (id) {
    return {
      id: `${id}-tab-${index}`,
      'aria-controls': `${id}-tabpanel-${index}`,
    };
  }

  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};
