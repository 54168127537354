import React, { forwardRef, useEffect, useState } from 'react';
import { Search as SearchIcon } from '@mui/icons-material';
import { Grid, InputAdornment, Skeleton, TextField, Typography } from '@mui/material';
import { useDebounce } from 'usehooks-ts';
import { useGetSiteUnsubscribeGroups } from '@juno/client-api';
import { Site as SiteModel } from '@juno/client-api/model';
import { useSettings } from '@juno/utils';
import SubscriptionGroupTile from './SubscriptionGroupTile';

interface SubscriptionGroupListProps {
  idx: number;
  groupCreated: boolean;
}

const SubscriptionGroupList = forwardRef<HTMLDivElement, SubscriptionGroupListProps>(
  ({ idx, groupCreated }, ref) => {
    // Variables
    const { site } = useSettings();
    const [search, setSearch] = useState<string>('');
    const debouncedSearch = useDebounce(search, 300);
    const subscriptionGroupFilter = { name__icontains: debouncedSearch };

    // Queries and mutations
    const {
      data: unsubscribeGroups,
      refetch,
      isLoading: groupsLoading,
    } = useGetSiteUnsubscribeGroups(site?.id || '', {
      filter: subscriptionGroupFilter,
    });

    // Functions
    useEffect(() => {
      if (groupCreated) {
        refetch();
      }
    }, [groupCreated]);
    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    };
    const handleGroupsUpdate = () => {
      refetch();
    };

    if (!groupsLoading && (!unsubscribeGroups || unsubscribeGroups.length < 1)) {
      return (
        <Typography variant='body1' sx={{ textAlign: 'center', mt: 2 }}>
          - No site unsubscribe groups found -
        </Typography>
      );
    }

    return (
      <>
        <TextField
          onChange={handleSearch}
          value={search}
          placeholder='Search...'
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{ mb: 4 }}
        />
        {groupsLoading && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Skeleton
                variant='rectangular'
                height={80}
                width={'100%'}
                sx={{ borderRadius: '10px' }}
              ></Skeleton>
            </Grid>
            <Grid item xs={12}>
              <Skeleton
                variant='rectangular'
                height={80}
                width={'100%'}
                sx={{ borderRadius: '10px' }}
              ></Skeleton>
            </Grid>
            <Grid item xs={12}>
              <Skeleton
                variant='rectangular'
                height={80}
                width={'100%'}
                sx={{ borderRadius: '10px' }}
              ></Skeleton>
            </Grid>
          </Grid>
        )}
        {!groupsLoading &&
          unsubscribeGroups.map((group, idx) => (
            <SubscriptionGroupTile
              idx={idx}
              subscriptionGroup={group}
              handleGroupsUpdate={handleGroupsUpdate}
            />
          ))}
      </>
    );
  },
);

export default SubscriptionGroupList;
