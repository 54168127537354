import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUser, useGetUserSettings, useUpdateUser } from '@juno/client-api';
import { JunoUser, Tag, UserSettings } from '@juno/client-api/model';
import { Container } from '@juno/ui';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import { ProfilePageSkeleton } from './ProfilePageSkeleton';

interface UserProfile {
  id: string;
  about: string;
  banner: string;
  company: string;
  email: string;
  facebook: string;
  firstName: string;
  geo_latitude: string;
  geo_longitude: string;
  headline: string;
  interests: Array<Tag>;
  instagram: string;
  lastName: string;
  linkedIn: string;
  location_name: string;
  phone: string;
  photoURL: string;
  skills: Array<Tag>;
  title: string;
  twitter: string;
  isMe: boolean;
  settings: UserSettings;
}

const UserProfileContext = createContext<UserProfile | undefined>(undefined);
const UserProfileUpdateContext = createContext<(profile: UserProfile) => void>(() => {});

interface UserProfileProviderProps {
  children: ReactNode;
}

export const UserProfileProvider = ({ children }: UserProfileProviderProps) => {
  const { userId = '' } = useParams<{ userId: string }>();
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>(undefined);
  const { site, user } = useSettings();
  const platformId = site?.platform_id || '';

  const { data: junoUser, refetch: refetchUser } = useGetUser(platformId, userId);
  const updateUser = useUpdateUser(onMutation(MutationAction.UPDATE, 'User', refetchUser));
  const handleUpdateUser = (user: JunoUser) => {
    updateUser.mutate({ platformId, userId: user?.id || '', data: user });
  };

  const { data: userSettings } = useGetUserSettings(
    site?.id || '',
    {
      filter: { user__id: junoUser?.id },
    },
    { query: { enabled: !!site?.id && !!userProfile?.id } },
  );

  useEffect(() => {
    if (junoUser) {
      const profile: UserProfile = {
        id: junoUser.id,
        about: junoUser?.bio || '',
        banner: junoUser?.banner || '',
        company: junoUser?.company || '',
        email: junoUser.email,
        facebook: junoUser?.facebook || '',
        firstName: junoUser.first_name,
        geo_latitude: junoUser?.geo_latitude || '',
        geo_longitude: junoUser?.geo_longitude || '',
        headline: junoUser?.headline || '',
        interests: junoUser.interests || [],
        instagram: junoUser?.instagram || '',
        lastName: junoUser.last_name,
        location_name: junoUser?.location_name || '',
        phone: junoUser?.phone || '',
        photoURL: junoUser?.avatar || '',
        skills: junoUser.skills || [],
        title: junoUser?.title || '',
        twitter: junoUser?.twitter || '',
        linkedIn: junoUser?.linkedin || '',
        isMe: junoUser.id === user?.id,
        settings: userSettings?.find((us) => us.user == junoUser.id) || ({} as UserSettings),
      };
      setUserProfile(profile);
    }
  }, [junoUser, user?.id, userSettings]);

  const updateUserProfile = (profile: UserProfile) => {
    setUserProfile(profile);
    const user: JunoUser = {
      ...junoUser,

      // Gotta love TypeScript
      id: junoUser?.id || '',
      access_passes: junoUser?.access_passes || [],
      community_groups_admin: junoUser?.community_groups_admin || [],
      community_groups_member: junoUser?.community_groups_member || [],
      community_groups_pending_member: junoUser?.community_groups_pending_member || [],
      created: junoUser?.created || '',
      declared_tags: junoUser?.declared_tags || [],
      email: junoUser?.email || '',
      enrollments_count: junoUser?.enrollments_count || 0,
      enrollments_in_progress_count: junoUser?.enrollments_in_progress_count || '',
      followed_forum_topics: junoUser?.followed_forum_topics || [],

      // Set the new values
      avatar: profile.photoURL,
      banner: profile.banner,
      company: profile.company,
      bio: profile.about,
      facebook: profile.facebook,
      first_name: profile.firstName,
      geo_latitude: profile.geo_latitude,
      geo_longitude: profile.geo_longitude,
      headline: profile.headline,
      interests: profile.interests,
      instagram: profile.instagram,
      last_name: profile.lastName,
      linkedin: profile.linkedIn,
      location_name: profile.location_name,
      phone: profile.phone,
      title: profile.title,
      twitter: profile.twitter,
      skills: profile.skills,
    };
    handleUpdateUser(user);
  };

  if (!userProfile || !userSettings) {
    return <ProfilePageSkeleton />;
  }

  return (
    <UserProfileContext.Provider value={userProfile}>
      <UserProfileUpdateContext.Provider value={updateUserProfile}>
        <Container>{children}</Container>
      </UserProfileUpdateContext.Provider>
    </UserProfileContext.Provider>
  );
};

// Hook to use the user profile state in a component
export const useUserProfile = () => {
  const context = useContext(UserProfileContext);
  if (context === undefined) {
    throw new Error('useUserProfile must be used within a UserProfileProvider');
  }
  return context;
};

// Hook to use the function to update the user profile state in a component
export const useUserProfileUpdate = () => {
  const context = useContext(UserProfileUpdateContext);
  if (context === undefined) {
    throw new Error('useUserProfileUpdate must be used within a UserProfileProvider');
  }
  return context;
};
