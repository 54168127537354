import React, { useState } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { Alert, Box, Button, Card, CardActionArea, Skeleton, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useGetPlatformFeatureConfigExists, useGetSiteFeatureConfig } from '@juno/client-api';
import { FeatureConfigConfig, FeatureConfigTypeEnum } from '@juno/client-api/model';
import { AdminDefaultViewHeader } from '@juno/ui';
import { useSettings } from '@juno/utils';
import EmailSettingsNotFiguredDialog from './tools/Emailer/Dialogs/EmailSettingsNotFiguredDialog';

const AutomationTool: React.FC = () => {
  // Variables
  const { site } = useSettings();
  const navigate = useNavigate();
  const [settingsWarningIsOpen, setSettingsWarningIsOpen] = useState(false);

  // Queries
  const { data: platformFeatureConfigExists, isLoading: platformConfigLoading } =
    useGetPlatformFeatureConfigExists(site?.platform_id || '', FeatureConfigTypeEnum.automation);
  const { data: siteFeatureConfig, isLoading: siteConfigLoading } = useGetSiteFeatureConfig(
    site?.id || '',
    FeatureConfigTypeEnum.automation,
    { query: { enabled: !!site?.id } },
  );
  const emailConfigExists = platformFeatureConfigExists?.exists;
  const siteBrandingConfig: FeatureConfigConfig | undefined =
    siteFeatureConfig?.config || undefined;

  // Functions
  const handleEmailerTileClick = (event: React.MouseEvent) => {
    if (!emailConfigExists || !siteBrandingConfig) {
      event.preventDefault();
      setSettingsWarningIsOpen(true);
    }
  };
  const handleClickPlatformEmailSettings = () => {
    navigate(`/${site?.slug}/admin/automation_settings`);
  };

  const handleClickSiteBrandingButton = () => {
    navigate(`/${site?.slug}/admin`);
  };

  const platformEmailSettingsText =
    'Your platform email settings must be configured in order to create and administer email campaigns. Reach out to your Customer Success representative to get started.';

  return (
    <Box sx={{ width: '100%' }}>
      <AdminDefaultViewHeader title={'Automation Toolbox'}></AdminDefaultViewHeader>
      {platformConfigLoading && (
        <Skeleton variant='rectangular' width={'100%'} height={75} sx={{ mb: 4 }}></Skeleton>
      )}
      {!platformConfigLoading && !emailConfigExists && (
        <Alert severity='warning' sx={{ justifyContent: 'center', mb: 4 }}>
          <Box sx={{ mr: 3, display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>{platformEmailSettingsText}</Typography>
          </Box>
        </Alert>
      )}
      {siteConfigLoading && (
        <Skeleton variant='rectangular' width={'100%'} height={75} sx={{ mb: 4 }}></Skeleton>
      )}
      {!siteConfigLoading && !siteBrandingConfig && (
        <Alert severity='warning' sx={{ justifyContent: 'center', mb: 4 }}>
          <Box sx={{ mr: 3, display: 'flex', alignItems: 'center' }}>
            <Typography variant='body2'>
              You must configure your site branding configs in order to create and administer email
              campaigns.
            </Typography>
            <Button
              aria-label='Submit'
              sx={{ ml: 3 }}
              variant={'contained'}
              onClick={handleClickSiteBrandingButton}
            >
              Configure Branding Settings
            </Button>
          </Box>
        </Alert>
      )}
      {settingsWarningIsOpen && (
        <EmailSettingsNotFiguredDialog
          handleClose={() => {
            setSettingsWarningIsOpen(false);
          }}
          platformSettingsConfigured={emailConfigExists !== undefined}
          siteSettingsConfigured={siteBrandingConfig !== undefined}
        />
      )}
      <Link to={'emailer'} onClick={handleEmailerTileClick}>
        <Card sx={{ mt: 2, maxHeight: '150px', maxWidth: '100%' }}>
          <CardActionArea
            sx={{
              p: 1,
              minHeight: '100px',
              maxHeight: '100%',
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'left',
              }}
              ml={6}
            >
              <EmailOutlinedIcon fontSize={'large'} />
              <Typography variant='h5' ml={3}>
                Emailer Tool
              </Typography>
              <Typography ml={6} variant='h6'>
                A suite for setting up automated email campaigns
              </Typography>
            </Box>
          </CardActionArea>
        </Card>
      </Link>
    </Box>
  );
};

export default AutomationTool;
