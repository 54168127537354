import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { AlignmentEnum } from '@juno/client-api/fakeModel';
import { useBreakpoint } from '@juno/utils';
import { Container } from '../JunoModule/styles';
import ModuleButton from '../ModuleButton';
import { HeroDataModel } from './form';

interface HeroProps {
  component?: HeroDataModel;
}

const Hero: React.FC<HeroProps> = ({ component }) => {
  const alignItems =
    component?.alignment === AlignmentEnum.left
      ? 'flex-start'
      : component?.alignment === AlignmentEnum.center
      ? 'center'
      : 'flex-end';
  const { xs, sm } = useBreakpoint();
  const height = useMemo(() => {
    if (component?.size === 'small' || xs) {
      return 300;
    } else {
      if (sm) {
        return 350;
      } else {
        return component?.size === 'medium' ? 500 : 700;
      }
    }
  }, [xs, sm, component?.size]);

  return (
    <Box
      sx={{
        overflow: 'hidden',
        height: height,
        position: 'relative',
        width: '100%',
        display: 'flex',
      }}
    >
      <motion.div
        style={{
          height: component?.parallax ? height * 1.1 : height,
          width: '100%',
          position: 'absolute',
          top: component?.parallax ? '-10%' : 0,
          left: 0,
          zIndex: 1,
          backgroundImage: `url('${component?.image}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      ></motion.div>
      <Container fullWidth={component?.fullWidth || false}>
        <Box
          zIndex={2}
          sx={{
            display: 'flex',
            height: '100%',
            width: '100%',
            flexDirection: 'column',
            alignItems: alignItems,
            justifyContent: 'center',
            pl: 3,
            pr: 3,
          }}
        >
          <Typography
            variant='h1'
            color={'#fff'}
            textAlign={component?.alignment}
            fontWeight='bold'
          >
            {component?.title}
          </Typography>
          <Typography color={'#fff'} sx={{ mt: 1 }} textAlign={component?.alignment}>
            {component?.subtitle}
          </Typography>
          {component?.button && (
            <Box sx={{ mt: 2 }} textAlign={component?.alignment}>
              <ModuleButton
                to={component?.button?.to || ''}
                text={component?.button?.text || ''}
                color={component?.button?.color || 'primary'}
                type={component?.button?.type || 'external'}
              />
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Hero;
