import { parseISO } from 'date-fns';
import { ImportArrayModel } from '.';
import { headerKeys } from './headerKeys';

/**
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 * ? validateValue and validateValues are only used after running a raw value through shapeIncomingImportColumn
 * This function is used to validate the value of the column cell or "shapedValue"
 * @param columnKey string
 * @param shapedValue ImportArrayModel
 * @param bucket string
 * @returns ImportArrayModel
 * @example
 * const shapedValue = shapeIncomingImportColumn(columnKey, rawValue, bucket);
 * const validatedValue = validateValue(columnKey, shapedValue, bucket);
 *
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */
const validateValue = <T>(
  columnKey: string,
  shapedValue: ImportArrayModel,
  bucket: string,
  rowContext?: T,
): ImportArrayModel => {
  const schema = headerKeys[bucket];
  if (!schema || schema.length === 0) {
    // There is no schema for this bucket, return
    return { ...shapedValue, is_valid: false, errorMessage: 'No schema found for this bucket' };
  }
  const header = schema.filter((headerKey) => headerKey.name === columnKey);
  if (!header || header.length === 0) {
    // If there is no header for this column, return
    return {
      ...shapedValue,
      is_valid: true,
      columnError: true,
      errorMessage: `No headerKeys found for this bucket with key '${columnKey}'`,
    };
  }

  // if shapedValue is undefined at this point, set it to a new ImportArrayModel with dom_value set to null, the expected key set to null and is_valid set to true
  if (!shapedValue) {
    shapedValue = { dom_value: null, [columnKey]: '', is_valid: true };
  }

  // if the shaped value is already invalid, return
  if (!shapedValue.is_valid) {
    return { ...shapedValue, is_valid: false };
  }

  const hasDefault = Object.prototype.hasOwnProperty.call(header[0], 'default');
  const hasValidation = Object.prototype.hasOwnProperty.call(header[0], 'validation');

  /**
   * If shapedValues[columnKey] is undefined, null or blank at this point,
   * check the headerKeys for a default value and return a new ImportArrayModel with dom_value set to that default value,
   * the expected key set to that default value and is_valid set to true
   */
  if (
    shapedValue[columnKey] === null ||
    shapedValue[columnKey] === undefined ||
    shapedValue[columnKey] === ''
  ) {
    // check if I have a default and no value
    if (hasDefault && !shapedValue.dom_value) {
      shapedValue = {
        dom_value: header[0].default,
        [columnKey]: header[0].default,
        is_valid: true,
      };
    }
  }

  if (!hasValidation) {
    // If there is no validation for this column, return
    return { [columnKey]: shapedValue.dom_value, ...shapedValue };
  }

  try {
    // Validate the value using the validation object
    header[0].validation?.validateSync(shapedValue.dom_value, { context: rowContext });
    return shapedValue;
  } catch (err: any) {
    // If the validation fails, return the error
    // we add the [columnKey]: '', first so that if the shapeValue already has it, it will be overwritten
    return {
      [columnKey]: shapedValue.dom_value,
      ...shapedValue,
      is_valid: false,
      errorField: columnKey,
      errorMessage: err.errors ? (err.errors[0] as string) : 'Validation Error Not Found',
    };
  }
};

/**
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 * ? validateValue and validateValues are only used after running a raw value through shapeIncomingImportColumn
 * this method will go thru the entire bucket schema and validate each value provided in shapedValues
 * if a value is not provided in shapedValues, it will be added to the returnValues with a value of null, unless there is a default value in the schema, in which case the default value will be used
 * if a value is provided in shapedValues that is not in the schema,
 * it will be added to the returnValues with a value of null and an error message stating that the column is not in the schema
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */
const validateValues = (shapedValues: ImportArrayModel[], bucket: string): ImportArrayModel[] => {
  // Create a schema from the headerKeys object
  const schema = headerKeys[bucket];
  const returnValues: ImportArrayModel[] = [];

  // Loop through each header in the schema
  schema.map((header) => {
    // Filter the shapedValues to only include the current header
    const shapedValue = shapedValues.filter((value) => value[header.name] !== undefined);
    // If the shapedValue is empty, push a default value to the returnValues array
    if (!shapedValue || shapedValue.length === 0) {
      returnValues.push(
        validateValue(
          header.name,
          { dom_value: null, [header.name]: null, is_valid: true },
          bucket,
        ),
      );
    } else {
      // If the shapedValue is not empty, loop through the array and push each value to the returnValues array
      const pushValue = shapedValue.map((value) => {
        return validateValue(header.name, { ...value, dom_value: value[header.name] }, bucket);
      });
      returnValues.push(...pushValue);
    }
  });
  return returnValues;
};

/**
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 * this is a specialized date format validation specific to dates in the importer
 * it is not a general purpose date validation
 * Most date formats are accepted
 * @param {string} value
 * @returns {boolean}
 ** DO NOT EDIT THIS METHOD WITHOUT A TEST CASE
 */
const validateImportDateString = (value: string) => {
  const parsedISODate = parseISO(value);
  if (parsedISODate && parsedISODate.toString() !== 'Invalid Date') {
    return true;
  }
  /**
   * We wanted to only accept certain formats
   * but this caused alot of issues with the datetimepicker, the backend and the validator
   * commented out for now
   * 
   
   enum ImportDateFormats {
     YYYYMMDD = 'yyyy-MM-dd',
     YYYYMMDDHHMMSS = 'yyyy-MM-dd HH:mm:ss',
     // 2023-06-16T11:30 because the datetimepickers format the date this way on change
     YYYYMMDDTHHMM = "yyyy-MM-dd'T'HH:mm",
   }
   
   for (const format of Object.values(ImportDateFormats)) {
     const parsedDate = parse(value, format, new Date());
     
     if (parsedDate && parsedDate.toString() !== 'Invalid Date') {
       return true;
      }
    }

    */
  return false;
};

export { validateValue, validateValues, validateImportDateString };
