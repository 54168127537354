import React from 'react';
import { Box, Card, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Component, SearchContentTypeEnum } from '@juno/client-api/model';
import { getContentRoute, useSettings } from '@juno/utils';
import JunoModule from '../JunoModule';

const dummySkeletonArr = [1, 2, 3, 4, 5, 6, 7, 8];
export interface GridV2Item {
  id: string;
  title: string;
  abstract: string;
  icon: string;
  slug: string;
  components?: Component[];
}
interface GridV2Props {
  items: GridV2Item[];
  type: SearchContentTypeEnum;
  isLoading: boolean;
  isAdmin?: boolean;
}

const GridV2: React.FC<GridV2Props> = ({ items, type, isLoading, isAdmin = false }) => {
  const navigate = useNavigate();
  const { site, configs } = useSettings();

  return (
    <Grid container spacing={2} mt={1}>
      {isLoading && dummySkeletonArr.map((_, i) => <SkeletonPagesTile key={i} />)}
      {!isLoading &&
        items?.map((item) => (
          <Grid
            item
            xs={12}
            md={4}
            lg={3}
            key={item.id}
            mb={1}
            sx={{
              cursor: 'pointer',
              transition: '200ms',
              '&:hover .MuiCard-root': {
                transform: 'translateY(-4px) scale(1.04)',
                boxShadow: type === 'speaker' ? 0 : (theme) => theme.shadows[5],
              },
            }}
            onClick={() => navigate(getContentRoute(site?.slug || '', item.slug, type, isAdmin))}
          >
            <Card
              sx={{
                height: 160,
                transition: '200ms all',
                boxShadow: type === 'speaker' ? 0 : (theme) => theme.shadows[1],
              }}
            >
              {type === SearchContentTypeEnum.page || type === SearchContentTypeEnum.library ? (
                <Stack direction='column' p={2} pt={0} alignItems={'center'}>
                  <Box
                    sx={{
                      transform: 'scale(.38)',
                      transformOrigin: 'top',
                      width: '300%',
                      pointerEvents: 'none',
                    }}
                  >
                    {item?.components
                      ?.filter((_, i) => i < 2)
                      .map((component) => {
                        return (
                          <JunoModule
                            key={component.id}
                            component={component}
                            siteId={site?.id || ''}
                            configs={configs || []}
                          />
                        );
                      })}
                  </Box>
                </Stack>
              ) : (
                <Box
                  sx={{
                    pointerEvents: 'none',
                    backgroundImage: `url('${item.icon}')`,
                    backgroundSize: 'cover',
                    height: '100%',
                    width: type === SearchContentTypeEnum.speaker ? 160 : 'auto',
                    borderRadius: type === SearchContentTypeEnum.speaker ? '50%' : 2,
                    backgroundPosition: 'center',
                  }}
                />
              )}
            </Card>
            <Typography
              variant='subtitle1'
              sx={{
                mt: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {item.title}
            </Typography>
            <Typography
              variant='body2'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {item.abstract}
            </Typography>
          </Grid>
        ))}
    </Grid>
  );
};

export default GridV2;

export const SkeletonPagesTile: React.FC = () => {
  return (
    <Grid item xs={12} md={4} lg={3}>
      <Skeleton variant='rectangular' height={160} width={235} sx={{ borderRadius: 2 }} />
      <Skeleton variant='text' height={20} sx={{ mt: 1, width: '50%' }} />
      <Skeleton variant='text' height={16} sx={{ width: '40%' }} />
    </Grid>
  );
};
