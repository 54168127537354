import { ChangeEvent, useEffect, useState } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { useDebounce } from 'usehooks-ts';
import { Component as ComponentModel, ComponentTypeEnum } from '@juno/client-api/model';
import { DialogAriaWrapper } from '@juno/ui';

interface ModuleEditFormProps {
  component?: ComponentModel;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
}

const DEFAULT_BLURB = {
  id: '',
  name: 'Blurb',
  type: ComponentTypeEnum.blurb,
  data: { html: '<h1 style="text-align: center;">Blurb Placeholder</h1>' },
};

const BlurbEditForm = ({ component, onSave, onUpdate }: ModuleEditFormProps) => {
  const [payload, setPayload] = useState<ComponentModel | undefined>();
  const [blurbModalOpen, setBlurbModalOpen] = useState(false);
  const debouncedPayload = useDebounce(payload, 700);
  useEffect(() => component && setPayload(component), [component]);
  useEffect(() => {
    debouncedPayload && onUpdate({ ...debouncedPayload });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPayload]);

  const handleSave = () => onSave({ ...(payload || DEFAULT_BLURB) });

  const changeHandler = {
    checkbox: ({ target: { name, checked } }: ChangeEvent<HTMLInputElement>) => {
      payload && setPayload({ ...payload, data: { ...payload.data, [name]: checked } });
    },
    textarea: ({ target: { name, value } }: ChangeEvent<HTMLTextAreaElement>) => {
      payload && setPayload({ ...payload, data: { ...payload.data, [name]: value } });
    },
    text: ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      payload && setPayload({ ...payload, data: { ...payload.data, [name]: value } });
    },
    componentText: ({ target: { name, value } }: ChangeEvent<HTMLInputElement>) => {
      payload && setPayload({ ...payload, [name]: value });
    },
  };

  return (
    <>
      <Typography variant='body2' sx={{ opacity: 0.6 }}>
        Editing Section
      </Typography>
      <Typography variant='h5' sx={{ mb: 4 }}>
        Blurb
      </Typography>
      <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
        <TextField
          fullWidth
          id='name'
          name='name'
          label='Name'
          value={payload?.name || ''}
          onChange={changeHandler.componentText}
          InputProps={{ disableUnderline: true }}
          variant='filled'
          sx={{ mb: 1 }}
        />
      </FormControl>
      <Button onClick={() => setBlurbModalOpen(true)}>
        <LaunchIcon sx={{ mr: 2 }} /> Large Editor
      </Button>
      <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
        <Editor
          apiKey={process.env.NX_TINY_MCE_API_KEY}
          value={payload?.data?.html as string}
          onDirty={() => {}}
          init={{
            contextmenu: false,
            auto_focus: true,
            highlight_on_focus: true,
            placeholder: 'Add content here',
            height: 380,
            width: '100%',
            setup: (editor) => {
              editor.setProgressState(true);
            },
          }}
          plugins={'autolink emoticons link mentions media'}
          onEditorChange={(string) =>
            changeHandler.textarea({
              target: { name: 'html', value: string },
            } as ChangeEvent<HTMLTextAreaElement>)
          }
        />
      </FormControl>
      <FormControl>
        <FormControlLabel
          label='Use Full Width'
          control={
            <Checkbox
              name='isFullWidth'
              checked={!!payload?.data?.isFullWidth || false}
              onChange={changeHandler.checkbox}
            />
          }
        />
      </FormControl>
      <DialogAriaWrapper
        open={blurbModalOpen}
        fullWidth
        onClose={() => setBlurbModalOpen(false)}
        sx={{ p: 2 }}
        disableEnforceFocus={true}
      >
        <Editor
          apiKey={process.env.NX_TINY_MCE_API_KEY}
          value={payload?.data?.html as string}
          onDirty={() => {}}
          init={{
            contextmenu: false,
            auto_focus: true,
            highlight_on_focus: true,
            placeholder: 'Add content here',
            height: 380,
            width: '100%',
            setup: (editor) => {
              editor.setProgressState(true);
            },
          }}
          plugins={'autolink emoticons link mentions media'}
          onEditorChange={(string) =>
            changeHandler.textarea({
              target: { name: 'html', value: string },
            } as ChangeEvent<HTMLTextAreaElement>)
          }
        />
      </DialogAriaWrapper>
    </>
  );
};

export default BlurbEditForm;
