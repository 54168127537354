import { createContext, useContext, useState } from 'react';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { Box, Button } from '@mui/material';
import { useQueryClient } from 'react-query';
import {
  getGetCertificateTemplatesQueryKey,
  useGetCertificateTemplate,
  useGetCertificateTemplates,
} from '@juno/client-api';
import { CertificateTemplate } from '@juno/client-api/model';
import { AdminDefaultViewHeader } from '@juno/ui';
import CertificateTabPanel from './CertificatePanel';
import ListCertificates from './ListCertificates';
import NewCertificateDialog from './dialogs/NewCertificateDialog';

export const SiteIdContext = createContext<string>('');
export const SettingsContext = createContext<any>({});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CertificatesProps {}

const Certificates: React.FC<CertificatesProps> = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isSingleView, setisSingleView] = useState(false);
  const [certificateId, setCertificateId] = useState<string>('');
  const queryClient = useQueryClient();

  // Get data
  const siteId = useContext(SiteIdContext);
  const { data } = useGetCertificateTemplates<CertificateTemplate[]>(siteId || '');
  const refetch = () => queryClient.invalidateQueries(getGetCertificateTemplatesQueryKey(siteId));

  const { data: certificate, isLoading: certificateLoading } = useGetCertificateTemplate(
    siteId,
    certificateId,
  );

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleRouteToSingle = (id: string) => {
    setCertificateId(id);
    setisSingleView(true);
  };

  const handleRouteToList = () => {
    setisSingleView(false);
    refetch();
  };

  if (isSingleView && certificateLoading) {
    return <div>Loading...</div>;
  }

  if (isSingleView && certificate) {
    return (
      <CertificateTabPanel siteId={siteId} certificate={certificate} onBack={handleRouteToList} />
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <AdminDefaultViewHeader title='Certificates'>
        <Button
          sx={{ textTransform: 'none' }}
          startIcon={<BookmarkAddIcon />}
          variant='contained'
          onClick={handleOpen}
        >
          Create a Certificate
        </Button>
      </AdminDefaultViewHeader>
      <NewCertificateDialog open={openDialog} onClose={handleClose} onNav={handleRouteToSingle} />
      <ListCertificates certificates={data} onNav={handleRouteToSingle} />
    </Box>
  );
};

interface CertificateContextWrapperProps {
  siteId: string;
  settings?: any;
}

const CertificateContextWrapper: React.FC<CertificateContextWrapperProps> = ({ siteId }) => {
  return (
    <SiteIdContext.Provider value={siteId}>
      <Certificates />
    </SiteIdContext.Provider>
  );
};

export default CertificateContextWrapper;
