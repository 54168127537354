import React, { useMemo, useState } from 'react';
import { CalendarMonthOutlined } from '@mui/icons-material';
import { Button, IconButton, Popover, Stack, Tooltip } from '@mui/material';
import { google, ics, office365, outlook, yahoo } from 'calendar-link';
import { ReoccurrenceEnum } from '@juno/client-api/model';
// => https://www.npmjs.com/package/calendar-link
import { ReactComponent as GoogleCalendarIcon } from './icons/google.svg';
import { ReactComponent as ICalIcon } from './icons/ical.svg';
import { ReactComponent as Office365Icon } from './icons/office365.svg';
import { ReactComponent as OutlookIcon } from './icons/outlook.svg';
import { ReactComponent as YahooIcon } from './icons/yahoo.svg';

interface AddToCalendarButtonProps {
  title: string;
  description: string;
  dateStart: string;
  dateEnd: string;
  location: string;
  url?: string;
  reoccurrence?: ReoccurrenceEnum;
  btnProps?: {
    btnStyle?: React.CSSProperties;
    iconStyle?: React.CSSProperties;
    optionsStyle?: React.CSSProperties;
    iconOnly?: boolean;
    buttonText?: string;
    iconColor?:
      | 'inherit'
      | 'default'
      | 'primary'
      | 'error'
      | 'secondary'
      | 'info'
      | 'success'
      | 'warning';
  };
}
const AddToCalendarButton: React.FC<AddToCalendarButtonProps> = ({
  title,
  description,
  location,
  url,
  dateStart,
  dateEnd,
  btnProps,
  reoccurrence = ReoccurrenceEnum.once,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // disabled if the event is in the past
  const isDisabled = new Date(dateEnd).getTime() < new Date().getTime();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'add-to-calendar-popover' : undefined;

  // TODO add reoccurrence

  // for yahoo and iCal, the description is stripped of html tags
  const strippedDescription = description.replace(/(<([^>]+)>)/gi, '');
  const event = (stripHtml = false) => {
    if (stripHtml) {
      return {
        title,
        description: strippedDescription,
        location,
        start: dateStart,
        end: dateEnd,
      };
    }
    return {
      title,
      description,
      location,
      start: dateStart,
      end: dateEnd,
    };
  };

  // TODO check legality of using these icons
  const CalendarButtons = [
    {
      name: 'Google',
      icon: <GoogleCalendarIcon style={{ width: '22px', height: '22px' }} />,
      link: google(event()),
    },
    {
      name: 'Apple / iCal',
      icon: <ICalIcon style={{ width: '22px', height: '22px' }} />,
      link: ics(event(true)),
    },
    {
      name: 'Office 365',
      icon: <Office365Icon style={{ width: '22px', height: '22px' }} />,
      link: office365(event()),
    },
    {
      name: 'Outlook',
      icon: <OutlookIcon style={{ width: '22px', height: '22px' }} />,
      link: outlook(event()),
    },
    {
      name: 'Yahoo',
      icon: <YahooIcon style={{ width: '22px', height: '22px' }} />,
      link: yahoo(event(true)),
    },
  ];

  return (
    <>
      {btnProps?.iconOnly ? (
        <Tooltip title='Add to Calendar' placement='top'>
          <IconButton
            color={btnProps.iconColor}
            disabled={isDisabled}
            sx={{ ...btnProps.iconStyle }}
            onClick={handleClick}
          >
            <CalendarMonthOutlined />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          aria-label='Add to Calendar'
          variant='text'
          startIcon={<CalendarMonthOutlined />}
          onClick={handleClick}
          disabled={isDisabled}
          sx={{ textTransform: 'none', ...btnProps?.btnStyle }}
        >
          {btnProps?.buttonText || 'Add to Calendar'}
        </Button>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack spacing={0} sx={{ minWidth: '120px' }}>
          {CalendarButtons.map((button) => (
            <Button
              aria-label={button.name}
              fullWidth
              key={button.name}
              component='a'
              variant='text'
              startIcon={button.icon}
              href={button.link}
              target='_blank'
              rel='noopener noreferrer'
              sx={{
                textTransform: 'none',
                justifyContent: 'flex-start',
                color: 'text.primary',
                padding: '4px 12px',
                ...btnProps?.optionsStyle,
              }}
            >
              {button.name}
            </Button>
          ))}
        </Stack>
      </Popover>
    </>
  );
};
export default AddToCalendarButton;
