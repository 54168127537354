import React, { useMemo, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FormikProps, useField } from 'formik';
import { UTCtoLocalString } from '@juno/utils';
import { useBreakpoint } from '@juno/utils/hooks';

const VisuallyHiddenInput = styled('input')(() => ({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: '1px',
}));

const GenericDateInput = ({ label, ...props }: any) => {
  return (
    <TextField
      label={label}
      fullWidth
      InputLabelProps={{ shrink: true }}
      sx={{ mb: 2 }}
      type='datetime-local'
      {...props}
    />
  );
};
const GenericFormikInput = ({ label, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <TextField
      label={label}
      fullWidth
      sx={{ mb: 2 }}
      {...field}
      {...props}
      error={Boolean(meta.error)}
      helperText={meta.error ? meta.error : props?.helperText}
    />
  );
};

const GenericFormikCheckbox = ({ label, display, ...props }: any) => {
  const [field, meta] = useField(props);
  return (
    <>
      <FormControlLabel
        control={<Checkbox {...field} {...props} />}
        label={label}
        sx={{ mb: 2, display: display ?? 'block' }}
      />
      {Boolean(meta.error) && (
        <FormHelperText error={true}>
          <Typography component={'span'} sx={{ fontSize: '12px' }}>
            {meta.error ? meta.error : props?.helperText}
          </Typography>
        </FormHelperText>
      )}
    </>
  );
};
interface GenericFormikDateSectionProps {
  dateFields: string[];
  props: FormikProps<any>;
}
const GenericFormikDateSection: React.FC<GenericFormikDateSectionProps> = ({
  dateFields,
  props,
}) => {
  const { values, touched, errors, setFieldValue, setFieldTouched, handleBlur } = props;
  const touchedFields = touched;

  const [copyData, setCopyData] = useState<string | undefined>();

  // This is to get the pickers to left align their text
  const { xs, sm, md, lg } = useBreakpoint();
  const gridSize = useMemo(() => {
    if (xs || sm || md) return 12;
    return 6;
  }, [xs, sm, md, lg]);
  return (
    <>
      <Grid item xs={12}>
        <Typography variant='subtitle1' mb={1}>
          Dates
        </Typography>
      </Grid>
      {/* Map over the dateFields array and return a GenericDateInput for each dateField matching the name of the dateField to the name of the formikValue */}
      {dateFields.map((dateField) => (
        <Grid item xs={12} key={dateField} mt={1} mb={1} width='100%'>
          <GenericDateInput
            fullWidth
            // split each label on '_' and map over the array to capitalize each word, then join the array back into a string with spaces
            label={dateField
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ')}
            name={dateField}
            value={values[dateField] ? UTCtoLocalString(new Date(values[dateField])) : ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                dateField,
                !event.target.value ? null : new Date(event.target.value)?.toISOString(),
              );
              setFieldTouched(dateField, true);
            }}
            onBlur={handleBlur}
            error={touchedFields[dateField] && Boolean(errors[dateField])}
            helperText={touchedFields[dateField] && errors[dateField]}
            sx={{ minWidth: '225px' }}
            onCopy={(_event: React.ClipboardEvent<HTMLInputElement>) => {
              const textValue = values[dateField];
              setCopyData(textValue);
            }}
            onPaste={(_event: React.ClipboardEvent<HTMLInputElement>) => {
              const textValue = copyData;
              setFieldValue(dateField, !textValue ? null : new Date(textValue)?.toISOString());
              setFieldTouched(dateField, true);
            }}
          />
        </Grid>
      ))}
    </>
  );
};

export {
  GenericDateInput,
  GenericFormikInput,
  GenericFormikCheckbox,
  GenericFormikDateSection,
  VisuallyHiddenInput,
};
