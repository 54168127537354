import React from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import {
  Component as ComponentModel,
  ComponentTypeEnum,
  FeatureConfig as FeatureConfigModel,
  NavigationItem as NavigationItemModel,
  Page as PageModel,
  Site as SiteModel,
} from '@juno/client-api/model';
import AnnouncementsForm from '../Announcements/form';
import BlurbForm from '../Blurb/form';
import SpeakersPreviewForm from '../ContentGrid/form';
import SpeakersCarouselForm from '../ContentRotator/form';
import DirectoryForm from '../Directory/form';
import FeedForumForm from '../Feed/form';
import FooterForm from '../Footer/form';
import HeaderForm from '../Header/form';
import HeroForm from '../Hero/form';
import ScheduleForm from '../Schedule/form';
import SideBySideForm from '../SideBySide/form';

interface JunoModuleProps {
  component: ComponentModel | undefined;
  onCancel: () => void;
  onSave: (component: ComponentModel) => void;
  onUpdate: (component: ComponentModel) => void;
  siteId: string;
}

const JunoModuleFormBase: React.FC<JunoModuleProps> = ({ component, onSave, onUpdate, siteId }) => {
  switch (component?.type) {
    case ComponentTypeEnum.featured_rotator:
      return (
        <SpeakersCarouselForm
          siteId={siteId}
          component={component}
          onSave={onSave}
          onUpdate={onUpdate}
        />
      );
    case ComponentTypeEnum.speaker_preview:
    case ComponentTypeEnum.grid:
      return (
        <SpeakersPreviewForm
          component={component}
          onSave={onSave}
          onUpdate={onUpdate}
          siteId={siteId}
        />
      );
    case ComponentTypeEnum.blurb:
      return <BlurbForm component={component} onSave={onSave} onUpdate={onUpdate} />;
    case ComponentTypeEnum.global_feed:
      return (
        <FeedForumForm
          component={component}
          onSave={onSave}
          onUpdate={onUpdate}
          title={'Global Feed'}
        />
      );
    case ComponentTypeEnum.global_forum:
      return (
        <FeedForumForm
          component={component}
          onSave={onSave}
          onUpdate={onUpdate}
          title={'Global Discussions'}
        />
      );
    case ComponentTypeEnum.hero:
      return <HeroForm component={component} onSave={onSave} onUpdate={onUpdate} />;
    case ComponentTypeEnum.side_by_side:
      return <SideBySideForm component={component} onSave={onSave} onUpdate={onUpdate} />;
    case ComponentTypeEnum.schedule:
      return <ScheduleForm component={component} onSave={onSave} onUpdate={onUpdate} />;
    case ComponentTypeEnum.directory:
      return <DirectoryForm component={component} onSave={onSave} onUpdate={onUpdate} />;
    case ComponentTypeEnum.announcements:
      return <AnnouncementsForm component={component} onSave={onSave} onUpdate={onUpdate} />;
    default:
      return <span>Not yet implemented</span>;
  }
};

export const JunoModuleForm: React.FC<JunoModuleProps> = (props) => {
  const { onCancel } = props;
  return (
    <Box sx={{ marginTop: (theme) => `calc(${theme.mixins.toolbar.minHeight}px + 12px)` }}>
      <IconButton
        onClick={onCancel}
        size='small'
        sx={{ width: 34, position: 'absolute', marginTop: 2, right: 15, zIndex: 20 }}
      >
        <CloseIcon />
      </IconButton>
      <Box sx={{ m: 1 }}>
        <JunoModuleFormBase {...props} />
      </Box>
    </Box>
  );
};
interface JunoFixtureProps {
  site: SiteModel;
  configs: FeatureConfigModel[];
  navigationItems: NavigationItemModel[];
  pages: PageModel[];
  fixtureType: string | undefined;
  onCancel: () => void;
  onSave: (payload: NavigationItemModel) => void;
  onUpdate: (payload: NavigationItemModel) => void;
  onDelete: (linkId: string) => void;
}

const JunoFixtureFormBase: React.FC<JunoFixtureProps> = ({
  site,
  configs,
  fixtureType,
  onSave,
  onUpdate,
  onDelete,
  navigationItems,
  pages,
}) => {
  switch (fixtureType) {
    case 'header':
      return (
        <HeaderForm
          siteId={site.id}
          configs={configs}
          onSave={onSave}
          onUpdate={onUpdate}
          onDelete={onDelete}
          navigationItems={navigationItems}
          pages={pages}
        />
      );
    case 'footer':
      return (
        <FooterForm
          siteId={site.id}
          configs={configs}
          onSave={onSave}
          onUpdate={onUpdate}
          onDelete={onDelete}
          navigationItems={navigationItems}
          pages={pages}
        />
      );
    default:
      return <span>Not yet implemented</span>;
  }
};

export const JunoFixtureForm: React.FC<JunoFixtureProps> = (props) => {
  const { onCancel, fixtureType } = props;
  // const capitalize = ([first, ...rest]: string) => first?.toUpperCase() + rest.join('');
  return (
    <Box sx={{ marginTop: (theme) => `calc(${theme.mixins.toolbar.minHeight}px + 10px)` }}>
      <IconButton
        onClick={onCancel}
        size='small'
        sx={{ width: 34, position: 'absolute', right: 15 }}
      >
        <CloseIcon />
      </IconButton>

      <div style={{ margin: 5 }}>
        {/* <h2 style={{ margin: 0 }}>{capitalize(fixtureType || '')}</h2> */}
        <JunoFixtureFormBase {...props} />
      </div>
    </Box>
  );
};
