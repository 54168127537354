import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DailyCall } from '@daily-co/daily-js';
import {
  StatefulDevice,
  useAudioTrack,
  useDevices,
  useLocalParticipant,
  useScreenShare,
  useVideoTrack,
} from '@daily-co/daily-react-hooks';
import {
  AddReactionOutlined,
  BackHand,
  BackHandOutlined,
  ChatBubble,
  ChatBubbleOutline,
  ClosedCaptionDisabledOutlined,
  ClosedCaptionOutlined,
  PeopleAlt,
  PlaylistAddCircleOutlined,
  SpeakerOutlined,
  StopCircleOutlined,
  StopScreenShare,
  Widgets,
  WidgetsOutlined,
} from '@mui/icons-material';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import MicIcon from '@mui/icons-material/Mic';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import ScreenShareOutlinedIcon from '@mui/icons-material/ScreenShareOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import {
  Badge,
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useFeatureFlag } from 'configcat-react';
import { motion } from 'framer-motion';
import { RoleEnum, Session } from '@juno/client-api/model';
import { useS3Upload } from '@juno/modules';
import { DialogAriaWrapper, VisualEffectsDialog } from '@juno/ui';
import { useBreakpoint, useSettings } from '@juno/utils';
import { CHAT_ASIDE, EMOJI_ACTIONS, PEOPLE_ASIDE, QA_ASIDE } from '../../constants';
import { useCallState } from '../../contexts/CallProvider';
import { useChat } from '../../contexts/ChatProvider';
import { useMediaDevices } from '../../contexts/MediaDeviceProvider';
import { useUIState } from '../../contexts/UIStateProvider';
import { TrayContainer, TrayIcon } from '../../styles/tray';
import FlyingEmoji, {
  EggEmojis,
  FlyEmojis,
  checkSequence,
  emojiSequences,
} from '../Room/FlyingEmoji';
import CustomLeaveButton from './CustomLeaveButton';

const Tray: React.FC = () => {
  // TODO iamAdmin for recording
  const theme = useTheme();
  const { uploadFileToS3 } = useS3Upload();
  const { xs } = useBreakpoint();
  const { isMicMuted, isCamMuted } = useMediaDevices();
  const {
    callObject,
    roomInfo,
    leave,
    recordingState,
    setRecordingState,
    startRecording,
    stopRecording,
  } = useCallState();
  const {
    hasNewChatMessage,
    hasNewQAMessage,
    flyingEmojis,
    setFlyingEmojis,
    sendMessage,
    setUserEmojiClicked,
  } = useChat();
  const { showAside, toggleAside } = useUIState();
  const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare();
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const { user: currentUser } = useSettings();
  const [moreAnchorEl, setMoreAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [emojisAnchorEl, setEmojisAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const videoElementEffects = useRef<HTMLVideoElement | null>(null);
  const [visualEffectsOpen, setVisualEffectsOpen] = useState(false);
  const { value: enableSessionTranscriptionValue } = useFeatureFlag('sessionTranscription', false);
  const [userClosedCaptionOn, setUserClosedCaptionOn] = useState(
    localStorage.getItem('enableCC') === 'true',
  );

  const handleClickReaction = (event: React.MouseEvent<HTMLButtonElement>) => {
    setEmojisAnchorEl(event.currentTarget);
  };

  const handleCloseReaction = () => {
    setEmojisAnchorEl(null);
  };

  const handleClickMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleCloseMore = () => {
    setMoreAnchorEl(null);
  };

  const handleClickSettings = () => {
    handleCloseMore();
    setDeviceSettingsOpen(true);
  };

  const handleClickCC = () => {
    setUserClosedCaptionOn(!userClosedCaptionOn);
    localStorage.setItem('enableCC', JSON.stringify(!userClosedCaptionOn));
  };

  const sessionData = useMemo(() => {
    if (roomInfo) {
      return (roomInfo as Record<string, unknown>)['session'] as Session;
    }
    return {} as Session;
  }, [roomInfo]);

  const iAmAdmin = useMemo(() => {
    if (currentUser?.role === RoleEnum.NUMBER_1) return true;
    if (sessionData?.moderators) {
      return sessionData?.moderators?.some((mod) => {
        if (mod.id === currentUser?.id) {
          return true;
        }
        return false;
      });
    }
    return false;
  }, [currentUser, sessionData]);

  const moreOpen = Boolean(moreAnchorEl);
  const emojisOpen = Boolean(emojisAnchorEl);

  // const [showMeetingInformation, setShowMeetingInformation] = useState(false);

  const localParticipant = useLocalParticipant();
  const localVideo = useVideoTrack(localParticipant?.session_id || '');
  const localAudio = useAudioTrack(localParticipant?.session_id || '');
  const mutedVideo = localVideo.isOff;
  const mutedAudio = localAudio.isOff;

  const [micSelectState, setMicSelectState] = useState('');
  const [cameraSelectState, setCameraSelectState] = useState('');
  const [speakerSelectState, setSpeakerSelectState] = useState('');
  const [handRaised, setHandRaised] = useState(false);

  const toggleCamera = (newState: boolean) => {
    if (!callObject) return false;
    // TODO fix this when we get contexts rewritten in typescript
    (callObject as DailyCall).setLocalVideo(newState);
  };

  const toggleMic = (newState: boolean) => {
    if (!callObject) return false;
    setHandRaised(false);
    // set onlyWatching to false when unmuting mic
    // TODO fix these when we get contexts rewritten in typescript
    (callObject as DailyCall)?.setUserData({
      ...(localParticipant?.userData as Record<string, unknown>),
      onlyWatching: false,
    });
    (callObject as DailyCall).setLocalAudio(newState);
  };

  useEffect(() => {
    return () => {
      if (!handRaised) return;
      setHandRaised(false);
      (callObject as unknown as DailyCall)?.setUserData({
        ...(localParticipant?.userData as Record<string, unknown>),
        handRaised: false,
      });
    };
  }, []);

  const toggleScreenShare = () => (isSharingScreen ? stopScreenShare() : startScreenShare());
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [emojiSequence, setEmojiSequence] = useState<string[]>([]);
  const [emojis, setEmojis] = useState<(FlyEmojis | string)[]>(Object.values(FlyEmojis));

  const handleEmojiClick = (emoji: any) => {
    if (flyingEmojis.length > 10) return;

    const message = {
      emoji: emoji,
      userId: currentUser?.id,
      timestamp: new Date().getTime(),
    };
    sendMessage(message, EMOJI_ACTIONS.SEND_EMOJI);

    const newFlyingEmoji = (
      <FlyingEmoji
        key={Date.now()}
        emoji={emoji}
        onExited={() => removeFlyingEmoji(newFlyingEmoji)}
      />
    );

    setFlyingEmojis((prevEmojis: any) => [...prevEmojis, newFlyingEmoji]);
    setUserEmojiClicked({
      userId: currentUser?.id,
      emoji: emoji,
    });

    setTimeout(() => {
      setUserEmojiClicked(null);
    }, 5000);

    // Track emoji sequence
    setEmojiSequence((prevSequence) => {
      const newSequence = [...prevSequence, emoji];
      const maxLength = Math.max(...Object.values(emojiSequences).map((seq) => seq.length));
      if (newSequence.length > maxLength) {
        newSequence.shift(); // Keep the sequence length within the maximum combination length
      }

      // Check for each Easter Egg eoji combo
      let matchedEmoji: EggEmojis | null = null;
      for (const [eggEmoji, sequence] of Object.entries(emojiSequences)) {
        if (checkSequence(newSequence, sequence)) {
          matchedEmoji = eggEmoji as EggEmojis;
          setEmojis((prevEmojis) => {
            if (!prevEmojis.includes(eggEmoji)) {
              return [...prevEmojis, eggEmoji];
            }
            return prevEmojis;
          });
          break;
        }
      }

      if (matchedEmoji && emoji === matchedEmoji) {
        // Remove the Easter Egg emoji if it was clicked
        setEmojis((prevEmojis) => prevEmojis.filter((e) => e !== matchedEmoji));
      } else if (!matchedEmoji) {
        // Reset if the sequence doesn't match and the egg emoji was not clicked
        setEmojis((prevEmojis) =>
          prevEmojis.filter((e: any) => !Object.values(EggEmojis).includes(e)),
        );
      }

      return newSequence;
    });
  };

  const removeFlyingEmoji = (emojiComponent: any) => {
    setFlyingEmojis((prevEmojis: any) =>
      prevEmojis.filter((emoji: any) => emoji !== emojiComponent),
    );
  };

  const { microphones, speakers, cameras, setMicrophone, setCamera, setSpeaker } = useDevices();
  // use effect on microphones to set the initial microphone
  useEffect(() => {
    if (micSelectState !== '') return;
    if (microphones.length > 0) {
      // set the selected mic to the mic that has selected set to true
      const selectedMic = microphones.find((mic) => mic.selected === true);
      if (selectedMic) {
        setMicSelectState(selectedMic.device.deviceId);
      } else {
        setMicSelectState(microphones[0].device.deviceId);
      }
    }
  }, [microphones]);

  // use effect on cameras to set the initial camera
  useEffect(() => {
    if (cameraSelectState !== '') return;
    if (cameras.length > 0) {
      // set the selected camera to the camera that has selected set to true
      const selectedCamera = cameras.find((camera) => camera.selected === true);
      if (selectedCamera) {
        setCameraSelectState(selectedCamera.device.deviceId);
      } else {
        setCameraSelectState(cameras[0].device.deviceId);
      }
    }
  }, [cameras]);

  // use effect on speakers to set the initial speaker
  useEffect(() => {
    if (speakerSelectState !== '') return;
    if (speakers.length > 0) {
      // set the selected speaker to the speaker that has selected set to true
      const selectedSpeaker = speakers.find((speaker) => speaker.selected === true);
      if (selectedSpeaker) {
        setSpeakerSelectState(selectedSpeaker.device.deviceId);
      } else {
        setSpeakerSelectState(speakers[0].device.deviceId);
      }
    }
  }, [speakers]);

  const updateMicrophone = (e: any) => {
    setMicrophone(e.target.value);
    setMicSelectState(e.target.value);
  };

  const updateSpeakers = (e: any) => {
    setSpeaker(e.target.value);
    setSpeakerSelectState(e.target.value);
  };

  const updateCamera = (e: any) => {
    setCamera(e.target.value);
    setCameraSelectState(e.target.value);
  };

  useEffect(() => {
    if (!localVideo.persistentTrack) return;
    if (videoElementEffects?.current) {
      videoElementEffects.current.srcObject = new MediaStream([localVideo.persistentTrack]);
    }
  }, [localVideo.persistentTrack, visualEffectsOpen]);
  return (
    <TrayContainer>
      <Box className='left-controls'>
        <CustomLeaveButton onClick={leave} />
      </Box>
      <Box className='center-controls'>
        <Tooltip placement='top' title={mutedAudio ? 'Unmute Mic' : 'Mute Mic'}>
          <TrayIcon enabled={!mutedAudio} onClick={() => toggleMic(isMicMuted)}>
            {mutedAudio ? <MicOffOutlinedIcon /> : <MicIcon />}
          </TrayIcon>
        </Tooltip>
        <Tooltip placement='top' title={mutedVideo ? 'Turn On Camera' : 'Turn Off Camera'}>
          <TrayIcon enabled={!mutedVideo} onClick={() => toggleCamera(isCamMuted)}>
            {mutedVideo ? <VideocamOffOutlinedIcon /> : <VideocamIcon />}
          </TrayIcon>
        </Tooltip>
        {!xs && (
          <>
            <Tooltip
              placement='top'
              title={isSharingScreen ? 'Stop Sharing Screen' : 'Share Screen'}
            >
              <TrayIcon enabled={isSharingScreen} onClick={toggleScreenShare}>
                {isSharingScreen ? <StopScreenShare /> : <ScreenShareOutlinedIcon />}
              </TrayIcon>
            </Tooltip>
            {/* raise hand icon */}
            <Tooltip placement='top' title={handRaised ? 'Lower Hand' : 'Raise Hand'}>
              <TrayIcon
                usePrimaryColor={true}
                enabled={handRaised}
                onClick={() => {
                  if (callObject) {
                    setHandRaised(!handRaised);
                    (callObject as DailyCall)?.setUserData({
                      ...(localParticipant?.userData as Record<string, unknown>),
                      handRaised: !handRaised,
                    });
                  } else {
                    console.warn('callObject is not defined');
                  }
                }}
              >
                {handRaised ? <BackHand /> : <BackHandOutlined />}
              </TrayIcon>
            </Tooltip>
          </>
        )}
        <Box position={'relative'} display={'inline-block'}>
          <Tooltip placement='top' title='Add reaction'>
            <TrayIcon
              onClick={(e) => {
                if (emojisOpen) {
                  handleCloseReaction();
                } else {
                  handleClickReaction(e);
                }
              }}
              enabled={emojisOpen}
            >
              <AddReactionOutlined />
            </TrayIcon>
          </Tooltip>
          <Popover
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={emojisOpen}
            anchorEl={emojisAnchorEl}
            onClose={handleCloseReaction}
          >
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.25 }}
            >
              <Box sx={{ display: 'flex' }}>
                <Tabs
                  variant='scrollable'
                  scrollButtons='auto'
                  allowScrollButtonsMobile
                  sx={{
                    '.MuiTabs-scrollButtons.Mui-disabled': {
                      display: 'none',
                      overflowY: 'hidden',
                    },
                  }}
                >
                  {emojis.map((emoji) => (
                    <Tab
                      sx={{
                        borderRadius: '50%',
                        minWidth: 0,
                        [theme.breakpoints.up('sm')]: {
                          minWidth: 0,
                        },
                        fontWeight: theme.typography.fontWeightRegular,
                        margin: 0,
                        padding: '4px',
                      }}
                      key={emoji}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      label={
                        <IconButton
                          sx={{ color: 'black', padding: 0, margin: 0, width: '40px' }}
                          size='large'
                          key={emoji}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleEmojiClick(emoji);
                          }}
                        >
                          {emoji}
                        </IconButton>
                      }
                    />
                  ))}
                </Tabs>
              </Box>
            </motion.div>
          </Popover>
        </Box>
        <Tooltip placement='top' title={'Chat'}>
          <TrayIcon enabled={showAside === CHAT_ASIDE} onClick={() => toggleAside(CHAT_ASIDE)}>
            {showAside === CHAT_ASIDE ? (
              <ChatBubble />
            ) : (
              <Badge color='primary' variant='dot' invisible={!hasNewChatMessage}>
                <ChatBubbleOutline />
              </Badge>
            )}
          </TrayIcon>
        </Tooltip>
        <Tooltip placement='top' title='More'>
          <TrayIcon enabled={moreOpen} onClick={handleClickMore}>
            <MoreVertOutlinedIcon />
          </TrayIcon>
        </Tooltip>
        <Popover
          open={moreOpen}
          anchorEl={moreAnchorEl}
          onClose={handleCloseMore}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box minWidth={100} p={1} display={'flex'} flexDirection={'column'}>
            {xs && (
              <>
                <IconButton
                  aria-label='attendees'
                  onClick={() => {
                    handleCloseMore();
                    toggleAside(PEOPLE_ASIDE);
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  <PeopleOutlineOutlinedIcon />
                  <Typography pl={1} variant='subtitle2'>
                    Attendees
                  </Typography>
                </IconButton>
                <IconButton
                  aria-label='activities'
                  onClick={() => {
                    handleCloseMore();
                    toggleAside(QA_ASIDE);
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  <PeopleOutlineOutlinedIcon />
                  <Typography pl={1} variant='subtitle2'>
                    Activities
                  </Typography>
                </IconButton>
                <IconButton
                  aria-label='raise-hand'
                  onClick={() => {
                    handleCloseMore();
                    if (callObject) {
                      setHandRaised(!handRaised);
                      (callObject as DailyCall)?.setUserData({
                        ...(localParticipant?.userData as Record<string, unknown>),
                        handRaised: !handRaised,
                      });
                    } else {
                      console.warn('callObject is not defined');
                    }
                  }}
                  sx={{
                    justifyContent: 'flex-start',
                    color: handRaised ? theme.palette.primary.main : '',
                  }}
                >
                  {handRaised ? <BackHand /> : <BackHandOutlined />}
                  <Typography pl={1} variant='subtitle2'>
                    {handRaised ? 'Lower hand' : 'Raise hand'}
                  </Typography>
                </IconButton>
              </>
            )}
            {iAmAdmin && (
              <Button
                startIcon={
                  recordingState === 'started' ? (
                    <StopCircleOutlined />
                  ) : (
                    <PlaylistAddCircleOutlined />
                  )
                }
                aria-label='recording-toggle'
                onClick={() => {
                  if (recordingState && recordingState === 'started') {
                    stopRecording();
                    setRecordingState('stopped');
                  } else {
                    startRecording();
                    setRecordingState('started');
                  }
                }}
                sx={{ justifyContent: 'flex-start' }}
                color='inherit'
              >
                <Typography pl={1} variant='subtitle2'>
                  {recordingState === 'stopped' || recordingState === 'idle'
                    ? 'Start Recording'
                    : 'Stop Recording'}
                </Typography>
              </Button>
            )}
            <Button
              startIcon={<SpeakerOutlined />}
              aria-label='visual-effects'
              onClick={() => {
                handleCloseMore();
                setVisualEffectsOpen(true);
              }}
              sx={{ justifyContent: 'flex-start' }}
              color='inherit'
            >
              <Typography pl={1} variant='subtitle2'>
                Visual Effects
              </Typography>
            </Button>
            {enableSessionTranscriptionValue && sessionData?.enable_cc && (
              <Button
                startIcon={
                  userClosedCaptionOn ? (
                    <ClosedCaptionDisabledOutlined />
                  ) : (
                    <ClosedCaptionOutlined />
                  )
                }
                aria-label='toggle closed captioning'
                onClick={handleClickCC}
                color={'inherit'}
                sx={{ justifyContent: 'flex-start' }}
              >
                <Typography pl={1} variant='subtitle2'>
                  {userClosedCaptionOn ? 'Turn Off CC' : 'Turn On CC'}
                </Typography>
              </Button>
            )}
            <Button
              startIcon={<SettingsOutlinedIcon />}
              aria-label='settings'
              onClick={handleClickSettings}
              sx={{ justifyContent: 'flex-start' }}
              color='inherit'
            >
              <Typography pl={1} variant='subtitle2'>
                Settings
              </Typography>
            </Button>
          </Box>
        </Popover>
      </Box>
      <Box className='right-controls'>
        {!xs && (
          <>
            <Tooltip placement='top' title='View Attendees'>
              <TrayIcon
                enabled={showAside === PEOPLE_ASIDE}
                onClick={() => toggleAside(PEOPLE_ASIDE)}
              >
                {showAside === PEOPLE_ASIDE ? <PeopleAlt /> : <PeopleOutlineOutlinedIcon />}
              </TrayIcon>
            </Tooltip>
            <Tooltip placement='top' title='Activities'>
              <TrayIcon
                enabled={showAside && showAside !== PEOPLE_ASIDE && showAside !== CHAT_ASIDE}
                onClick={() => toggleAside(QA_ASIDE)}
              >
                {showAside && showAside !== PEOPLE_ASIDE && showAside !== CHAT_ASIDE ? (
                  <Widgets />
                ) : (
                  <Badge color='primary' variant='dot' invisible={!hasNewQAMessage}>
                    <WidgetsOutlined />
                  </Badge>
                )}
              </TrayIcon>
            </Tooltip>
          </>
        )}
        {/* // TODO */}
        {/* <Tooltip placement='top' title='Moderation'>
          <TrayIcon enabled={false} onClick={() => toggleAside(QA_ASIDE)}>
            <AdminPanelSettingsOutlinedIcon />
          </TrayIcon>
        </Tooltip> */}
      </Box>
      <SettingsModal
        deviceSettingsOpen={deviceSettingsOpen}
        setDeviceSettingsOpen={setDeviceSettingsOpen}
        value={value}
        handleChange={handleChange}
        micSelectState={micSelectState}
        cameraSelectState={cameraSelectState}
        speakerSelectState={speakerSelectState}
        microphones={microphones}
        cameras={cameras}
        speakers={speakers}
        updateMicrophone={updateMicrophone}
        updateCamera={updateCamera}
        updateSpeakers={updateSpeakers}
      />
      {callObject && (
        <VisualEffectsDialog
          uploadFileToS3={uploadFileToS3}
          open={visualEffectsOpen}
          setOpen={setVisualEffectsOpen}
          videoElementEffects={videoElementEffects}
          callObject={callObject}
        />
      )}
      {flyingEmojis}
    </TrayContainer>
  );
};
export default Tray;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      width={'100%'}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

interface SettingsModalProps {
  deviceSettingsOpen: boolean;
  setDeviceSettingsOpen: (state: boolean) => void;
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
  micSelectState: string;
  cameraSelectState: string;
  speakerSelectState: string;
  microphones: any;
  cameras: any;
  speakers: any;
  updateMicrophone: (e: any) => void;
  updateCamera: (e: any) => void;
  updateSpeakers: (e: any) => void;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
  deviceSettingsOpen,
  setDeviceSettingsOpen,
  value,
  handleChange,
  micSelectState,
  cameraSelectState,
  speakerSelectState,
  microphones,
  cameras,
  speakers,
  updateMicrophone,
  updateCamera,
  updateSpeakers,
}) => {
  const { xs } = useBreakpoint();

  return (
    <DialogAriaWrapper
      id='settings-dialog'
      maxWidth={'sm'}
      fullWidth={true}
      open={deviceSettingsOpen}
    >
      <DialogTitle id='settings-dialog-title'>
        <Typography variant='h6'>Settings</Typography>
      </DialogTitle>
      <IconButton
        aria-label='close'
        onClick={() => setDeviceSettingsOpen(false)}
        sx={{ position: 'absolute', top: 0, right: 0 }}
      >
        <CloseOutlinedIcon />
      </IconButton>
      <DialogContent>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: xs ? 'block' : 'flex',
          }}
        >
          <Tabs
            orientation='vertical'
            variant='scrollable'
            value={value}
            onChange={handleChange}
            aria-label='Settings modal tabs'
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: xs ? 'transparent' : 'default',
              },
            }}
          >
            <Tab label='Device settings' {...a11yProps(0)} />
            {/* <Tab label='tab2' {...a11yProps(1)} />
            <Tab label='tab3' {...a11yProps(2)} /> */}
          </Tabs>
          <TabPanel value={value} index={0}>
            <Stack spacing={2}>
              {/* Microphone choice */}
              <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                <MicIcon fontSize={xs ? 'small' : 'large'} />
                <Select
                  fullWidth
                  sx={{ fontSize: '12px', ml: 2, maxWidth: xs ? '90%' : 'unset' }}
                  name='micOptions'
                  id='micSelect'
                  onChange={updateMicrophone}
                  value={micSelectState}
                >
                  {microphones?.map((mic: StatefulDevice) => (
                    <MenuItem key={`mic-${mic.device.deviceId}`} value={mic.device.deviceId}>
                      {mic.device.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              {/* Camera choice */}
              <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                <VideocamIcon fontSize={xs ? 'small' : 'large'} />
                <Select
                  fullWidth
                  sx={{ fontSize: '12px', ml: 2, maxWidth: xs ? '90%' : 'unset' }}
                  name='cameraOptions'
                  id='cameraSelect'
                  onChange={updateCamera}
                  value={cameraSelectState}
                >
                  {cameras?.map((camera: StatefulDevice) => (
                    <MenuItem key={`cam-${camera.device.deviceId}`} value={camera.device.deviceId}>
                      {camera.device.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              {/* Speakers choice */}
              <Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'}>
                <SpeakerOutlined fontSize={xs ? 'small' : 'large'} />
                <Select
                  fullWidth
                  sx={{ fontSize: '12px', ml: 2, maxWidth: xs ? '90%' : 'unset' }}
                  name='speakersOptions'
                  id='speakersSelect'
                  onChange={updateSpeakers}
                  value={speakerSelectState}
                >
                  {speakers?.map((speaker: StatefulDevice) => (
                    <MenuItem
                      key={`speaker-${speaker.device.deviceId}`}
                      value={speaker.device.deviceId}
                    >
                      {speaker.device.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Stack>
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            nothing
          </TabPanel>
          <TabPanel value={value} index={2}>
          nothing
          </TabPanel> */}
        </Box>
      </DialogContent>
    </DialogAriaWrapper>
  );
};
