import React, { useState } from 'react';
import { AdminPanelSettingsRounded, CoPresentRounded, PeopleAltRounded } from '@mui/icons-material';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
  Accordion,
  AccordionDetails,
  Avatar,
  Badge,
  Box,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFeatureFlag } from 'configcat-react';
import { AccessPass, JunoUser } from '@juno/client-api/model';
import { AutocompleteJunoUsers } from '@juno/ui';
import { useSettings } from '@juno/utils';
import { CustomAccordionSummary } from './helpers';

interface ModerationSectionProps {
  allow_raise_hand?: boolean;
  allow_watch_only?: boolean;
  treat_speakers_as_mods?: boolean;
  allow_speaker_share_screen?: boolean;
  allow_force_stop?: boolean;
  enable_auto_record?: boolean;
  record_chat?: boolean;
  moderators?: JunoUser[];
  presenters?: JunoUser[];
  scheduled_attendees?: JunoUser[];
  restrict_schedule_access_passes?: AccessPass[];
  handleChange: any;
  setFieldValue: any;
  getImageSrc: (url: string) => string;
  metadata?: any;
  enable_cc?: boolean;
}
const ModerationSection: React.FC<ModerationSectionProps> = ({
  allow_raise_hand,
  allow_watch_only,
  treat_speakers_as_mods,
  allow_speaker_share_screen,
  allow_force_stop,
  enable_auto_record,
  record_chat,
  moderators,
  presenters,
  scheduled_attendees,
  restrict_schedule_access_passes,
  handleChange,
  setFieldValue,
  getImageSrc,
  metadata,
  enable_cc,
}) => {
  const [expanded, setExpanded] = useState(true);
  const { site } = useSettings();
  const { value: enableSessionTranscriptionValue } = useFeatureFlag('sessionTranscription', false);

  return (
    <Accordion
      sx={{ background: 'none', boxShadow: 1 }}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <CustomAccordionSummary
        aria-controls='moderation-content'
        id='moderation-header'
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      >
        <Typography variant='overline' sx={{ ml: 1 }}>
          Moderation
        </Typography>
      </CustomAccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Stack>
              <FormControlLabel
                control={
                  <Switch
                    checked={allow_raise_hand}
                    onChange={handleChange}
                    name='allow_raise_hand'
                  />
                }
                label='Allow raising hands'
              />
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={treat_speakers_as_mods}
                    onChange={handleChange}
                    name='treat_speakers_as_mods'
                  />
                }
                label='Treat all Speakers as Moderators'
              /> */}
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={allow_speaker_share_screen}
                    onChange={handleChange}
                    name='allow_speaker_share_screen'
                  />
                }
                label='Allow Speakers to screen share'
              /> */}
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={allow_force_stop}
                    onChange={handleChange}
                    name='allow_force_stop'
                  />
                }
                label='Allow force stop'
              /> */}
              <FormControlLabel
                control={
                  <Switch
                    checked={allow_watch_only}
                    onChange={handleChange}
                    name='allow_watch_only'
                  />
                }
                label='Allow watch only'
              />
              {enableSessionTranscriptionValue && (
                <FormControlLabel
                  control={<Switch checked={enable_cc} onChange={handleChange} name='enable_cc' />}
                  label='Enable Closed Captioning'
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack>
              <Tooltip
                placement='top-start'
                title={`Sessions are auto recorded. ${
                  enable_auto_record
                    ? 'Toggling this will DISABLE auto recording, however sessions can still be manually recorded by moderators during the live session.'
                    : 'Toggling this will ENABLE auto recording. Session recordings can be manually started and stopped by moderators during the live session.'
                } `}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={enable_auto_record}
                      onChange={handleChange}
                      name='enable_auto_record'
                    />
                  }
                  label={enable_auto_record ? 'Auto Recording Enabled' : 'Auto Recording Disabled'}
                />
              </Tooltip>
              {/* <FormControlLabel
            control={<Switch checked={record_chat} onChange={handleChange} name='record_chat' />}
            label='Record Chat (this will disable anonymous chat)'
          /> */}
            </Stack>
            <Stack></Stack>
          </Grid>
          <Grid item xs={12}>
            {/* INVITE */}
            <Typography variant='subtitle1' mt={2}>
              Specify User Roles
            </Typography>
            <Typography variant='caption' mb={2}>
              Attendees specified with a badge{' '}
              <Badge
                badgeContent={''}
                color='primary'
                variant='dot'
                overlap='circular'
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Avatar
                  src=''
                  alt={''}
                  sx={{
                    // mr: 1,
                    width: 18,
                    height: 18,
                    fontSize: 10,
                  }}
                />
              </Badge>{' '}
              have already obtained the specified access passes
            </Typography>
            <Grid container spacing={2}>
              {/* <Grid item xs={3}>
                <Box>
                  <AutocompleteJunoUsers
                    currentUsers={selectedSpeakers.map((speaker)=>{
                      return {
                        first_name: speaker.name,
                        last_name: '',
                        email: speaker.description,
                        avatar: speaker.image,
                        icon: speaker.image,
                      } as JunoUser
                    })}
                    getImageSrc={getImageSrc}
                    label={
                      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <MicExternalOnRounded sx={{ mr: 1 }} />
                        <Typography variant='subtitle2'>Speakers</Typography>
                        <Badge
                          badgeContent={selectedSpeakers.length}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          color='primary'
                          sx={{ ml: 3 }}
                        ></Badge>
                      </Box>
                    }
                    title={'Choose users to assign the Speaker role'}
                    placeholder={'Search for a user'}
                    platformId={site.platform_id}
                    handleChange={(value) => {
                      const speakers = value.map((user) => {
                        return {
                          name: user.first_name,
                          description: user.email,
                          image: user.avatar,
                        } as Speaker;
                      }
                      );
                      setSelectedSpeakers(speakers);
                      setFieldValue('speakers', speakers);
                    }}
                  />
                </Box>
              </Grid> */}
              <Grid item xs={4}>
                <Box>
                  <AutocompleteJunoUsers
                    currentUsers={presenters || []}
                    getImageSrc={getImageSrc}
                    label={
                      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <CoPresentRounded sx={{ mr: 1 }} />
                        <Typography variant='subtitle2'>Presenters</Typography>
                        <Badge
                          badgeContent={presenters?.length}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          color='primary'
                          sx={{ ml: 3 }}
                        ></Badge>
                      </Box>
                    }
                    title={'Choose users to assign the Presenter role'}
                    placeholder={'Search for a user'}
                    platformId={site?.platform_id || ''}
                    handleChange={(value) => {
                      setFieldValue('presenters', value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <AutocompleteJunoUsers
                    currentUsers={moderators || []}
                    getImageSrc={getImageSrc}
                    label={
                      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <AdminPanelSettingsRounded sx={{ mr: 1 }} />
                        <Typography variant='subtitle2'>Moderators</Typography>
                        <Badge
                          badgeContent={moderators?.length}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          color='primary'
                          sx={{ ml: 3 }}
                        ></Badge>
                      </Box>
                    }
                    title={'Choose users to assign the Moderator role'}
                    placeholder={'Search for a user'}
                    platformId={site?.platform_id || ''}
                    handleChange={(value) => {
                      setFieldValue('moderators', value);
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <AutocompleteJunoUsers
                    accessPassIdsForUserBadge={restrict_schedule_access_passes?.map(
                      (accessPass) => accessPass.id,
                    )}
                    currentUsers={scheduled_attendees || []}
                    getImageSrc={getImageSrc}
                    label={
                      <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <PeopleAltRounded sx={{ mr: 1 }} />
                        <Typography variant='subtitle2'>Attendees</Typography>
                        <Badge
                          badgeContent={scheduled_attendees?.length}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          color='primary'
                          sx={{ ml: 3 }}
                        ></Badge>
                      </Box>
                    }
                    title={'Choose users to assign the Attendee role'}
                    placeholder={'Search for a user'}
                    platformId={site?.platform_id || ''}
                    handleChange={(value) => {
                      setFieldValue('scheduled_attendees', value);
                    }}
                  />
                </Box>
                {metadata.extra_users && metadata.extra_users.length > 0 && (
                  <Box>
                    <Typography>Extra attendees through purchase:</Typography>
                    {metadata.extra_users.map((u: any) => (
                      <Stack key={u.email}>
                        <Typography>{u.name}</Typography>
                        <Typography sx={{ ml: 2 }}>{u.email}</Typography>
                      </Stack>
                    ))}
                  </Box>
                )}
                <Box></Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
export default ModerationSection;
