import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  EmailRecord as EmailRecordModel,
  TypeBd0Enum as campaignType,
} from '@juno/client-api/model';
import { EmailCampaignTypeMap, formattedAnalyticsTableDate } from '../utils/utils';

interface SingleTypeAnalyticsTableProps {
  emailCampaignType?: campaignType;
  tableRows: EmailRecordModel[];
}

const AnalyticsTable: React.FC<SingleTypeAnalyticsTableProps> = ({
  emailCampaignType,
  tableRows,
}) => {
  // Variables
  const theme = useTheme();

  return (
    <>
      {tableRows.length === 0 && (
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                No {emailCampaignType ? EmailCampaignTypeMap[emailCampaignType] : 'site'} email
                records found
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {tableRows.length > 0 && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: 100, paddingRight: 0, verticalAlign: 'top' }}>
                User Email
              </TableCell>
              {!emailCampaignType && (
                <TableCell sx={{ width: 100, paddingRight: 0, verticalAlign: 'top' }}>
                  Campaign Type
                </TableCell>
              )}
              <TableCell sx={{ width: 60, paddingRight: 0, verticalAlign: 'top' }}>Name</TableCell>
              {(emailCampaignType === campaignType.COURSE_WAITLIST ||
                emailCampaignType === campaignType.COURSE_ENROLLMENT) && (
                <TableCell sx={{ width: 100, paddingRight: 0, verticalAlign: 'top' }}>
                  Course Title
                </TableCell>
              )}
              <TableCell sx={{ width: 100, paddingRight: 0, verticalAlign: 'top' }}>
                Date Sent
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableRows.map((row: EmailRecordModel, i) => (
              <TableRow key={i} sx={{ border: `1px solid ${theme.palette.divider}` }}>
                <TableCell sx={{ verticalAlign: 'middle', fontSize: '12px' }}>
                  {row.email}
                </TableCell>
                {!emailCampaignType && (
                  <TableCell sx={{ fontSize: '12px' }}>
                    {EmailCampaignTypeMap[row.email_type]}
                  </TableCell>
                )}
                <TableCell
                  sx={{ fontSize: '12px' }}
                >{`${row.first_name} ${row.last_name}`}</TableCell>
                {(emailCampaignType === campaignType.COURSE_WAITLIST ||
                  emailCampaignType === campaignType.COURSE_ENROLLMENT) && (
                  <TableCell sx={{ fontSize: '12px' }}>{row.course_title}</TableCell>
                )}
                <TableCell sx={{ fontSize: '12px' }}>
                  {formattedAnalyticsTableDate(row.date_sent)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default AnalyticsTable;
