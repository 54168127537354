import React from 'react';
import { Typography } from '@mui/material';
import { SimpleDialog } from '@juno/ui';

interface EmailSettingsNotFiguredDialogProps {
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  platformSettingsConfigured: boolean;
  siteSettingsConfigured: boolean;
}

const EmailSettingsNotFiguredDialog: React.FC<EmailSettingsNotFiguredDialogProps> = ({
  handleClose,
  platformSettingsConfigured,
  siteSettingsConfigured,
}) => {
  let contentText = '';

  if (!platformSettingsConfigured && !siteSettingsConfigured) {
    contentText =
      'The Emailer Tool cannot be accessed until both Platform Email Settings and Site Branding Settings have been fully configured';
  } else if (!platformSettingsConfigured) {
    contentText =
      'The Emailer Tool cannot be accessed until the Platform Email Settings have been fully configured';
  } else {
    contentText =
      'The Emailer Tool cannot be accessed until the Site Branding Settings have been fully configured';
  }

  return (
    <SimpleDialog
      open={true}
      onClose={() => {
        handleClose(true);
      }}
    >
      <Typography>{contentText}</Typography>
    </SimpleDialog>
  );
};

export default EmailSettingsNotFiguredDialog;
