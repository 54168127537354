import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { useNavigate } from 'react-router-dom';
import { useGetCommunityGroupsHierarchy } from '@juno/client-api';
import { CommunityGroup, SearchContentTypeEnum } from '@juno/client-api/model';
import { getContentRoute, useSettings } from '@juno/utils';

interface GroupTreeProps {
  groupId: string;
}

interface CommunityGroupWithChildren extends CommunityGroup {
  children: CommunityGroupWithChildren[];
}

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? theme.palette.grey[800] : theme.palette.grey[200],

  [`& .${treeItemClasses.content}`]: {
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    [`& .${treeItemClasses?.label || 'treeitemlabel'}`]: {
      fontSize: '.9rem',
      fontWeight: 500,
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    borderRadius: '50%',
    backgroundColor:
      theme.palette.mode === 'light'
        ? alpha(theme.palette.primary.main, 0.25)
        : theme.palette.primary.dark,
    color: theme.palette.mode === 'dark' && theme.palette.primary.contrastText,
    padding: theme.spacing(0, 1.2),
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.3)}`,
  },
}));

export const recursiveArraySort = (
  list: CommunityGroupWithChildren[],
  parent: CommunityGroupWithChildren,
) => {
  const result = parent;

  /**
   * Get every element whose parent_id attribute matches the parent's id.
   */
  const children = list.filter((item) => item.parent === parent.id);

  if (children.length > 0) {
    result.children = children;
  }

  /**
   * Set the level based on the parent level for each element identified,
   * add them to the result array, then recursively sort the children.
   */

  result.children?.map((child) => {
    recursiveArraySort(list, child);
  });

  return result;
};

const GroupTree: React.FC<GroupTreeProps> = ({ groupId }) => {
  const navigate = useNavigate();
  const { site } = useSettings();
  const {
    data: groupsHierarchy,
    isLoading,
    isFetching,
  } = useGetCommunityGroupsHierarchy(site?.id || '', groupId, 'all');
  const parentGroup = groupsHierarchy?.find((i) => !i.parent);
  const hierarchy = groupsHierarchy
    ? [
        recursiveArraySort(
          groupsHierarchy as CommunityGroupWithChildren[],
          parentGroup as CommunityGroupWithChildren,
        ),
      ]
    : [{ id: 'hey', title: 'ho' } as CommunityGroupWithChildren];

  if (isLoading || isFetching) {
    return (
      <Box textAlign='center' pt={2}>
        <CircularProgress size={22} sx={{ fontSize: 18 }} />
      </Box>
    );
  }

  if (!hierarchy || hierarchy.length === 0) {
    return <></>;
  }

  function getItemId(item: CommunityGroupWithChildren) {
    return item.id;
  }
  function getItemLabel(item: CommunityGroupWithChildren) {
    return item.title;
  }

  return (
    <RichTreeView
      items={hierarchy}
      expandedItems={groupsHierarchy?.map((g) => g.id)}
      slots={{ item: StyledTreeItem }}
      getItemId={getItemId}
      getItemLabel={getItemLabel}
      onItemFocus={(event: SyntheticEvent<Element, Event> | null, itemId: string) => {
        navigate(getContentRoute(site?.slug || '', itemId || '', SearchContentTypeEnum.group));
      }}
    />
  );
};

export default GroupTree;
