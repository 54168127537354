import React from 'react';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { SimpleDialog } from '../../index';

interface ConfirmLeaveDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmLeaveDialog: React.FC<ConfirmLeaveDialogProps> = ({ onCancel, open, onConfirm }) => {
  return (
    <SimpleDialog
      open={open}
      onClose={() => onCancel()}
      buttonTitle={'Confirm'}
      buttonOverrideProps={{ onClick: onConfirm, sx: { ml: 2, mr: 2, mb: 3 } }}
    >
      <Typography sx={{ mb: 4 }}>Unsaved changes will be lost</Typography>
      <Button variant='outlined' fullWidth onClick={() => onCancel()} autoFocus>
        Cancel
      </Button>
    </SimpleDialog>
  );
};
export default ConfirmLeaveDialog;
