import { PaletteOptions, TypographyVariantsOptions } from '@mui/material/styles';

export interface ThemeConfigProps {
  name: string;
  slug: string;
  siteTitle?: string;
  palette: PaletteOptions;
  typography: TypographyVariantsOptions;
}

export interface EmailThemeConfigProps {
  logo: string;
  banner_image: string;
  primary_color: string;
  site_nickname: string;
}

export const BASE_THEME = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1180,
      xl: 1600,
    },
  },
  typography: {
    h1: {
      fontSize: '2rem',
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none !important',
            outlineOffset: 'none',
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            color: '#212121',
            width: '10px',
            height: '10px',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            backgroundColor: '#A9A9A9',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
            border: 'solid rgba(128,128,128,0.2) 1px',
            borderRadius: 5,
          },
        },
      },
    },
  },
};

export const THEME_FONT_FAMILIES = [
  {
    id: 'prompt',
    name: 'Prompt',
    value: 'Prompt, sans-serif',
  },
  {
    id: 'arial',
    name: 'Arial',
    value: 'arial, helvetica, sans-serif',
  },
  {
    id: 'verdana',
    name: 'Verdana',
    value: 'Verdana, sans-serif',
  },
  {
    id: 'tahoma',
    name: 'Tahoma',
    value: 'Tahoma, Verdana, sans-serif',
  },
  {
    id: 'trebuchet-ms',
    name: 'Trebuchet MS',
    value: 'Trebuchet MS, sans-serif',
  },
  {
    id: 'times-new-roman',
    name: 'Times New Roman',
    value: 'Times New Roman, serif',
  },
  {
    id: 'georgia',
    name: 'Georgia',
    value: 'Georgia, serif',
  },
  {
    id: 'garamond',
    name: 'Garamond',
    value: 'Garamond, serif',
  },
  {
    id: 'courier-new',
    name: 'Courier New',
    value: 'Courier New, Courier, monospace',
  },
  {
    id: 'brush-script-mt',
    name: 'Brush Script MT',
    value: 'Brush Script MT, sans-serif',
  },
];

export const THEME_CONFIG_PRESETS: ThemeConfigProps[] = [
  {
    name: 'White',
    slug: 'white',
    typography: {
      fontFamily: 'Prompt, sans-serif',
    },
    palette: {
      primary: {
        main: '#c7169c',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#2D0523',
        contrastText: '#ffffff',
      },
      background: {
        default: '#ffffff',
        paper: '#ffffff',
      },
      text: {
        primary: '#000000',
        secondary: '#000000',
      },
    },
  },
  {
    name: 'Grey',
    slug: 'grey',
    typography: {
      fontFamily: 'Prompt, sans-serif',
    },
    palette: {
      primary: {
        main: '#c7169c',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#2D0523',
        contrastText: '#ffffff',
      },
      background: {
        default: '#212121',
        paper: '#212121',
      },
      text: {
        primary: '#ffffff',
        secondary: '#ffffff',
      },
    },
  },
  {
    name: 'Yikes',
    slug: 'yikes',
    typography: {
      fontFamily: 'Brush Script MT, sans-serif',
    },
    palette: {
      primary: {
        main: '#0000ff',
        contrastText: '#ffff00',
      },
      secondary: {
        main: '#00ffff',
        contrastText: '#ffffff',
      },
      background: {
        default: '#00ff00',
        paper: '#00ff00',
      },
      text: {
        primary: '#ff0000',
        secondary: '#ff0000',
      },
    },
  },
  {
    name: 'Gilead',
    slug: 'gilead',
    typography: {
      fontFamily: 'arial, helvetica, sans-serif',
    },
    palette: {
      primary: {
        main: '#00BF6F',
        contrastText: '#f2f2f2',
      },
      secondary: {
        main: '#003594',
        contrastText: '#f2f2f2',
      },
      background: {
        default: '#f2f2f2',
        paper: '#f2f2f2',
      },
      text: {
        primary: '#595959',
        secondary: '#595959',
      },
    },
  },
  {
    name: 'East',
    slug: 'east',
    typography: {
      fontFamily: 'Prompt, sans-serif',
    },
    palette: {
      primary: {
        main: '#0093B2',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#53565A',
        contrastText: '#ffffff',
      },
      background: {
        default: 'rgba(255, 255, 255, 0.3)',
        paper: '#f3f3f3',
      },
      text: {
        primary: '#000000',
        secondary: '#000000',
      },
    },
  },
];

export const DEFAULT_THEME_CONFIG_PRESET = THEME_CONFIG_PRESETS[0];
