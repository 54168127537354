import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import DialogAriaWrapper from '../DialogAriaWrapper';

interface AlertDialogProps {
  open: boolean;
  title?: string;
  description: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const AlertDialog = (props: AlertDialogProps) => {
  const { open, title = 'Are you sure?', description, onCancel, onConfirm } = props;
  return (
    <DialogAriaWrapper open={open} onClose={onCancel} id='alert_dialog'>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onConfirm} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </DialogAriaWrapper>
  );
};

export default AlertDialog;
