import React, { ChangeEvent, useState } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { DialogActions, DialogContent, DialogTitle, Skeleton } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useCloneCourse, useGetCourses } from '@juno/client-api';
import { Course } from '@juno/client-api/model';
import { JUNO_ROUTE_MAP } from '@juno/constants';
import { DialogAriaWrapper, GenericFormikInput } from '@juno/ui';
import { MutationAction, onMutation, slugify, useRouteNavigate, useSettings } from '@juno/utils';

interface CloneCourseDialogProps {
  siteId: string;
  onClose: () => void;
  cloneTitle: string;
  courseId: string;
}
const CloneCourseDialog: React.FC<CloneCourseDialogProps> = ({
  siteId,
  onClose,
  cloneTitle,
  courseId,
}) => {
  const courseClone = useCloneCourse(onMutation(MutationAction.CREATE, 'Course'));
  const title = `CLONE - ${cloneTitle}`;
  const { data: allCourses, isLoading: isLoadingAllCourses } = useGetCourses(siteId);
  const [isCloning, setIsCloning] = useState(false);
  const navigate = useNavigate();
  const learningNavigate = useRouteNavigate();
  const { isWidget } = useSettings();

  const saveCloneCourse = async (values: Course) => {
    if (!values) return;
    setIsCloning(true);
    const data = {
      title: values.title,
      slug: values.slug,
    };
    try {
      const newCourse = await courseClone.mutateAsync({ siteId, courseId, data });
      setIsCloning(false);
      if (isWidget) {
        learningNavigate(JUNO_ROUTE_MAP.CLIENT_COURSE, { courseSlug: newCourse.slug });
      } else {
        navigate(`../../${newCourse.slug}`);
      }
    } catch (e) {
      console.error(e);
      setIsCloning(false);
    }
    onClose();
  };

  return (
    <DialogAriaWrapper
      onClose={onClose}
      open={true}
      maxWidth='xs'
      fullWidth
      id={`clone_course_dialog`}
    >
      <DialogTitle variant='h5' sx={{ pt: 2, pb: 2 }}>
        Clone Course
      </DialogTitle>
      {!allCourses || isLoadingAllCourses ? (
        <DialogContent>
          <Skeleton variant='rectangular' width='100%' height={45} sx={{ mb: 2 }} />
          <Skeleton variant='rectangular' width='100%' height={45} sx={{ mb: 4 }} />
          <Skeleton variant='rectangular' width='100%' height={35} sx={{ mt: 6 }} />
        </DialogContent>
      ) : (
        <Formik
          enableReinitialize={true}
          validateOnMount
          initialValues={
            {
              title,
              slug: slugify(title),
            } as Course
          }
          validationSchema={Yup.object({
            title: Yup.string()
              .max(90, 'Title must not exceed 90 characters')
              .required('Title is required'),
            slug: Yup.string()
              .max(90, 'Slug must not exceed 90 characters')
              .required('Slug is required')
              .test('unique-slug', 'Slug must be unique', function (slug) {
                return allCourses ? allCourses?.filter((c) => c.slug === slug).length === 0 : true;
              }),
          })}
          onSubmit={saveCloneCourse}
        >
          {({ values, handleSubmit, setFieldValue, errors }) => {
            return (
              <>
                <DialogContent>
                  <Form>
                    <GenericFormikInput
                      id='title'
                      label='Course Title'
                      name='title'
                      value={values.title}
                      sx={{ mt: 1, mb: 2 }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('title', e.target.value);
                        setFieldValue('slug', slugify(e.target.value));
                      }}
                    />
                    <GenericFormikInput
                      id='slug'
                      label='Course Slug'
                      name='slug'
                      value={values.slug}
                      sx={{ mt: 1, mb: 2 }}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue('slug', slugify(e.target.value));
                      }}
                    />
                  </Form>
                </DialogContent>
                <DialogActions sx={{ p: 2, pb: 3 }}>
                  <LoadingButton
                    loading={isCloning}
                    fullWidth
                    variant='contained'
                    onClick={() => handleSubmit()}
                  >
                    CLONE COURSE
                  </LoadingButton>
                </DialogActions>
              </>
            );
          }}
        </Formik>
      )}
    </DialogAriaWrapper>
  );
};
export default CloneCourseDialog;
