import { nanoid } from 'nanoid';
import {
  Component as ComponentModel,
  ComponentTypeEnum,
  Page as PageModel,
  Platform,
  Site as SiteModel,
} from '@juno/client-api/model';

//import { Platform } from '../../utils/model';

export const getSortKeyObj = (key: string, delimiter = '#') => {
  const compArray = key?.split(delimiter);
  if (compArray?.length === 1) {
    return null;
  }
  return { type: compArray?.[0] || '', id: compArray?.[1] || '' };
};

export const createSortKey = (compType: string, compId = nanoid(5), delimiter = '#') => {
  return `${compType}${delimiter}${compId}`;
};

export const componentModelFromKey = (key: string, page: PageModel | undefined = undefined) => {
  const sortKeyObj = getSortKeyObj(key);
  const compId = sortKeyObj?.id || key;
  const compType = sortKeyObj?.type;
  const componentModel = page?.components.find((c) => c.id === compId);
  if (componentModel) {
    return componentModel as ComponentModel;
  }
  const newComponentModel = page?.components.find((c) => c.id === `${compType}#${compId}`);
  if (newComponentModel) {
    return newComponentModel as ComponentModel;
  }
  if (!compType) {
    throw Error('Invalid component key');
  }
  return {
    id: '',
    name: compType
      .split('_')
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(' '),
    type: compType,
    data: {},
  } as ComponentModel;
};

export const getPreviewUrl = (site: SiteModel | undefined, page: PageModel | undefined) => {
  throw 'This is unimplemented!';
  // return `http://${site?.subdomain}.${process.env.NX_CLIENT_DOMAIN || ''}/${page?.slug}`;
};

export const newComponentDummyData = (compType: string) => {
  switch (compType) {
    case ComponentTypeEnum.hero:
      return {
        size: 'medium',
        image:
          'https://res.cloudinary.com/dxx7aeiow/image/upload/v1706588211/oefqqmn3dasgudgwtmm5.jpg',
        title: 'New Hero Banner',
        button: {
          enabled: true,
          to: null,
          text: 'Read it',
          color: 'primary',
          type: 'internal',
        },
        subtitle: 'Edit this section in Page Builder',
        alignment: 'left',
      };
    case ComponentTypeEnum.side_by_side:
      return {
        image:
          'https://res.cloudinary.com/dxx7aeiow/image/upload/v1706591482/mnkmlc6udx9yv5nps36r.png',
        title: '',
        subtitle: '<h2>Side by Side</h2><p>Edit this section in Page Builder</p>',
        alignment: 'right',
        button: {
          enabled: true,
          to: null,
          text: 'Read it',
          color: 'primary',
          type: 'internal',
        },
      };
    default:
      return {};
  }
};
