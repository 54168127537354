import React from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, Chip, TextField, TextFieldProps } from '@mui/material';
import { AccessPass } from '@juno/client-api/model';

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;
interface AutocompleteAccessPassesProps {
  label: string;
  fieldValue: string;
  passArray: AccessPass[];
  accessPasses: AccessPass[] | undefined;
  handleChange: any;
  multiple: boolean;
}
const AutocompleteAccessPasses: React.FC<AutocompleteAccessPassesProps> = ({
  label,
  fieldValue,
  passArray,
  accessPasses,
  handleChange,
  multiple,
}) => {
  const passes = accessPasses && [...accessPasses];
  passes?.sort((a, b) => -b?.name?.localeCompare(a?.name));

  return (
    <Autocomplete
      id={fieldValue}
      multiple={multiple}
      getOptionLabel={(option) => {
        return option.name ?? '';
      }}
      value={passArray}
      options={passes ?? []}
      onChange={handleChange}
      renderOption={(
        props: JSX.IntrinsicAttributes &
          React.ClassAttributes<HTMLLIElement> &
          React.LiHTMLAttributes<HTMLLIElement>,
        option,
        { selected },
      ) => (
        <li {...props} data-aria-id={option.id}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option.name}`}
        </li>
      )}
      renderTags={(tagList: AccessPass[], getTagProps: any) => {
        return tagList.map((option: AccessPass, index) => {
          return (
            <Chip
              {...getTagProps({ index })}
              color='default'
              label={<span>{option.name}</span>}
            ></Chip>
          );
        });
      }}
      renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps?.endAdornment}</>,
          }}
        />
      )}
    />
  );
};
export default AutocompleteAccessPasses;
