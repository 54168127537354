import React, { useState } from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  Accordion,
  AccordionDetails,
  Box,
  Button,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { Download } from '@juno/client-api/model';
import { useS3Upload } from '@juno/modules';
import {
  GenericFormikInput,
  LinearProgressWithLabel,
  VisuallyHiddenInput,
  customShadow,
} from '@juno/ui';
import { MediaPreview } from './MediaSection';
import { CustomAccordionSummary } from './helpers';

interface GreenRoomSectionProps {
  greenroom_open_minutes?: number;
  show_waiting_room?: boolean;
  waiting_room_url?: string;
  waiting_room_text?: string;
  handleChange: any;
  setFieldValue: any;
}
const GreenRoomSection: React.FC<GreenRoomSectionProps> = ({
  greenroom_open_minutes,
  show_waiting_room,
  waiting_room_url,
  waiting_room_text,
  handleChange,
  setFieldValue,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [media, setMedia] = useState<Download | null>(null);
  const { uploadFileToS3 } = useS3Upload();

  return (
    <Accordion
      sx={{ background: 'none', boxShadow: customShadow }}
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
    >
      <CustomAccordionSummary
        aria-controls='greenroom-content'
        id='greenroom-header'
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      >
        <Typography sx={{ ml: 1 }}>Green Room</Typography>
      </CustomAccordionSummary>
      <AccordionDetails>
        <Typography variant='caption'>Greenroom opens before session (in minutes)</Typography>
        <GenericFormikInput
          placeholder='Enter number of minutes'
          value={greenroom_open_minutes}
          name='greenroom_open_minutes'
          type='number'
        />
        {/* // TODO finish waiting room */}
        {/* <Typography>Waiting Room Settings</Typography>
        <Stack spacing={1} padding={2}>
          <FormControlLabel
            control={
              <Switch
                checked={show_waiting_room}
                onChange={handleChange}
                name='show_waiting_room'
              />
            }
            label='Show waiting room'
          />
          {show_waiting_room && (
            <Box>
              <Typography variant='caption'>Waiting Room Media</Typography>
              <Box display='flex' mb={2}>
                {uploadProgress > 0 && uploadProgress < 1 ? (
                  <Box width={'100%'} height={60}>
                    <Typography align='center' mt={1} variant='body2'>
                      Uploading {media?.title}, please wait...
                    </Typography>
                    <Box>
                      <LinearProgressWithLabel value={uploadProgress * 100} />
                    </Box>
                  </Box>
                ) : (
                  <GenericFormikInput
                    placeholder='Image/Video URL'
                    value={waiting_room_url}
                    name='waiting_room_url'
                    type='url'
                    sx={{
                      display: 'inline',
                    }}
                  />
                )}
                <Button size='small' component='label' variant='text'>
                  <CloudUploadIcon />
                  <VisuallyHiddenInput
                    accept='image/*, video/*'
                    multiple={false}
                    type='file'
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file === undefined) return;
                      const newMedia = {
                        title: file.name,
                        description: file.name,
                        url: '',
                        filetype: file.type,
                      } as Download;
                      setMedia(newMedia);
                      try {
                        setUploadProgress(0.01); // set to 1% to show progress bar
                        const awaitUpload = async (file: File) => {
                          const uploadResult = await uploadFileToS3(file, setUploadProgress);

                          setFieldValue('waiting_room_url', uploadResult);
                          newMedia.url = uploadResult;

                          setMedia(newMedia);
                        };

                        awaitUpload(file);
                      } catch (e: any) {
                        // TODO better error handling
                        alert(e.message);
                      }
                    }}
                  />
                </Button>
              </Box>
              <Box mb={2}>
                {uploadProgress === 1 && media && (
                  <MediaPreview
                    media={media}
                    handleRemove={() => {
                      setMedia(null);
                      setFieldValue('waiting_room_url', '');
                    }}
                  />
                )}
              </Box>
              <Typography variant='caption'>Waiting Room Text Overlay</Typography>
              <GenericFormikInput
                placeholder='Enter text...'
                value={waiting_room_text}
                name='waiting_room_text'
                type='textarea'
                multiline
              />
            </Box>
          )}
        </Stack> */}
      </AccordionDetails>
    </Accordion>
  );
};
export default GreenRoomSection;
