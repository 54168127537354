import React, { useContext, useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Button, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Editor } from '@tinymce/tinymce-react';
import { getAllUsers, useCreateDownloads } from '@juno/client-api';
import { PreviewFile } from '@juno/client-api/fakeModel';
import { CommunityUser, Download, Tag, Thread } from '@juno/client-api/model';
import {
  MutationAction,
  calculateUserSearchFilters,
  onMutation,
  uploadImagesToCloudinary,
  uploadTinyMceImageCloudinary,
  useSettings,
} from '@juno/utils';
import { useS3Upload } from '../../Contexts/S3Context';
import { TagsContext } from '../../Feed';
import EditorActionMenu from './EditorActionMenu';
import { convertTinyMceUsers, createTinyMceMentionSpan } from './helpers';
import './styles.css';

interface ThreadEditorProps {
  threadToEdit: Thread;
  handleUpdate: (updatedThread: Thread) => void;
  handleCancel?: () => void;
  saveBtnText?: string;
  showActionMenu?: boolean;
  showToolbar?: boolean;
  siteId: string;
  platformId: string;
}

const ThreadEditor: React.FC<ThreadEditorProps> = ({
  threadToEdit,
  handleUpdate,
  handleCancel,
  saveBtnText,
  showActionMenu = true,
  showToolbar = true,
  siteId,
  platformId,
}) => {
  const { body, image, downloads, tagged_users } = threadToEdit;

  const { getImageSrc, isApp } = useSettings();
  const theme = useTheme();

  const tags = useContext(TagsContext);

  // ! Can I check if the url has 'cloudinary' in it to determine if this is an image vs a video? Images get uploaded to cloudinary, videos go to S3...
  const isImage = image ? image.search('cloudinary') >= 0 : false;
  const [previewMedia, setPreviewMedia] = useState<PreviewFile[]>([
    { type: isImage ? 'image' : 'video', preview: image || '' } as PreviewFile,
  ]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [newFiles, setNewFiles] = useState<File[]>([]);
  const [removedFiles, setRemovedFiles] = useState<Download[]>([]);
  const [dirty, setDirty] = useState(false);
  const [editorHasContent, setEditorHasContent] = useState(!!body);
  const [tagsState, setTagsState] = useState<Tag[]>(threadToEdit.tags ?? []);

  const editorRef = useRef<any>(null);
  const userMentions = useRef<CommunityUser[]>(tagged_users ?? []);

  const createDownloads = useCreateDownloads(onMutation(MutationAction.CREATE, ''));
  const { uploadFileToS3 } = useS3Upload();

  useEffect(() => setDirty(false), [body]);

  const handleEditorChange = (content: string) => {
    setDirty(true);
    setEditorHasContent(content.trim().length > 0);
  };

  const handleSubmitChanges = async () => {
    if (!editorHasContent) {
      alert('Please enter some text before submitting');
      return;
    }

    const values = {
      body: editorRef.current?.getContent(),
    } as Thread;
    try {
      const promises = [
        ...newFiles.map((file) => uploadFileToS3(file, setUploadProgress, true, `post_files`)),
      ];
      const urls = await Promise.all(promises);
      const files = newFiles.map(
        (file, index) => ({ title: file.name, url: urls[index], filetype: file.type } as Download),
      );
      if (files.length > 0) {
        const newFileDownloads = await createDownloads.mutateAsync({ siteId, data: files });
        values.downloads = [...(downloads ?? []), ...newFileDownloads];
      }

      if (previewMedia?.[0]?.lastModified || previewMedia?.[0]?.forceAppUpload) {
        const file = previewMedia[0];
        try {
          if (file.type.search('image') >= 0) {
            const newImage = await uploadImagesToCloudinary(previewMedia, setUploadProgress);
            values.image = newImage.url;
            values.metadata = {
              image_metadata: newImage,
            };
          } else if (file.type.search('video') >= 0) {
            const newVideo = await uploadFileToS3(file, setUploadProgress, true, `post_files`);
            values.image = newVideo;
            values.metadata = {
              video_metadata: newVideo,
            };
          } else {
            alert('Unsupported file type');
            return;
          }
        } catch (e) {
          console.error(e);
          return;
        }
      } else if (previewMedia.length === 0) {
        values.image = '';
        values.metadata = {
          image_metadata: null,
        };
      }

      if (removedFiles.length > 0) {
        values.downloads = downloads?.filter((d) => !removedFiles.some((r) => r.id === d.id));
      }

      // double check that the user mentions are still in the body
      values.tagged_users = userMentions.current?.filter((m) =>
        editorRef.current?.getContent().includes(m.id),
      );

      values.tags = tagsState;

      handleUpdate(values);
    } catch (e) {
      console.error(e);
    }
  };

  const mentions_fetch = async (query: any, success: any) => {
    // TODO - do we want this to only search group members
    const filter = calculateUserSearchFilters(query.term.toLowerCase());
    const allUsers = await getAllUsers(platformId, {
      ...filter,
      order: 'first_name',
      limit: 10,
      offset: 0,
    });
    const tinyUsers = convertTinyMceUsers(allUsers, getImageSrc ?? (() => ''));
    success(tinyUsers);
  };

  return (
    <>
      <Editor
        apiKey={process.env.NX_TINY_MCE_API_KEY}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={body as unknown as string}
        onDirty={() => setDirty(true)}
        onEditorChange={handleEditorChange}
        init={{
          contextmenu: false,
          auto_focus: true,
          content_style: `.user_mention{ color: ${theme.palette.primary.main};, cursor: pointer; }`,
          mentions_selector: 'span.user_mention',
          mentions_menu_complete: (editor: any, userInfo: any) => {
            userMentions.current = [...userMentions.current, userInfo];
            const span = createTinyMceMentionSpan(editor, userInfo);
            return span;
          },
          mentions_fetch: mentions_fetch,
          mentions_item_type: 'profile',
          height: 250,
          images_upload_handler: uploadTinyMceImageCloudinary,
          setup: (editor) => {
            editor.setProgressState(true);
          },
          toolbar: showToolbar
            ? 'emoticons bold italic underline strikethrough link | alignleft aligncenter alignright alignjustify outdent indent | bullist numlist blockquote'
            : false,
          toolbar_location: 'bottom',
          menubar: false,
          statusbar: false,
        }}
        plugins={'autolink emoticons lists link mentions'}
      />
      {tags && tags?.length > 0 && (
        <Autocomplete
          sx={{ mt: 2 }}
          disablePortal={false}
          id='thread_tags'
          options={tags || []}
          value={tagsState}
          fullWidth
          getOptionLabel={(tag) => tag.value}
          onChange={(e, newValue) => {
            setTagsState(newValue);
            setDirty(true);
          }}
          multiple
          renderInput={(params) => <TextField {...params} label='Tags' />}
        />
      )}

      {showActionMenu && (
        <EditorActionMenu
          previewMedia={previewMedia}
          setPreviewMedia={setPreviewMedia}
          newFiles={newFiles}
          setNewFiles={setNewFiles}
          existingFiles={downloads}
          setRemovedFiles={(file: Download) => {
            const removed = downloads?.filter((f) => f.id === file.id || f.title === file.title);
            if (!removed) return;
            setRemovedFiles([...removedFiles, ...removed]);
          }}
        />
      )}
      <Box display={'flex'} justifyContent={'flex-end'} p={1}>
        {handleCancel && (
          <>
            <Button
              size='small'
              color='inherit'
              onClick={handleCancel}
              aria-label='Cancel editing'
              variant='text'
              sx={{ textTransform: 'none' }}
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={!dirty || !editorHasContent}
              size='small'
              color='primary'
              onClick={() => {
                setUploadProgress(1);
                handleSubmitChanges();
              }}
              aria-label='Save changes'
              variant='contained'
              sx={{ textTransform: 'none' }}
              loading={uploadProgress > 0}
            >
              {`${saveBtnText ? saveBtnText : 'Update'}`}
            </LoadingButton>
          </>
        )}
      </Box>
    </>
  );
};
export default ThreadEditor;
