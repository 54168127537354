export enum UserActions {
  Complete = 'Complete',
  Enroll = 'Enroll',
  Start = 'Start',
  Waitlist = 'Waitlist Add',
}

export enum EnrollmentStatus {
  Enroll = 2,
  Start = 4,
  Complete = 5,
}

export enum DateTypeOptions {
  CloseEnrollmentDate = 'Close Enrollment Date',
  EndDate = 'End Date',
  OpenEnrollmentDate = 'Open Enrollment Date',
  ReleaseDate = 'Release Date',
  StartDate = 'Start Date',
}

export const DateTypeToFilterString = new Map<string, string>([
  ['Open Enrollment Date', 'date_open_enrollment'],
  ['Close Enrollment Date', 'date_lock_enrollment'],
  ['Release Date', 'date_released'],
  ['Start Date', 'date_start'],
  ['End Date', 'date_end'],
]);

export enum TimeRange {
  Specific = 'Specific',
  Previous = 'Previous',
  Upcoming = 'Upcoming',
  Relative = 'Relative',
}

export enum BeforeAfterBetweenOptions {
  Before = 'Before',
  After = 'After',
  Between = 'Between',
}

export const checkJsonWorthiness = (jsonString: string | undefined) => {
  try {
    return JSON.parse(jsonString || '{}');
  } catch (error) {
    return JSON.parse('{}');
  }
};

export enum SortByOptions {
  Alphabetical = 'Alphabetical',
  Newest = 'Newest',
  StartDate = 'Start Date',
}

export enum SortOrderOptions {
  Ascending = 'Ascending',
  Descending = 'Descending',
}

export const SortOrderOptionToAscendingParam = new Map<string, string>([
  ['Alphabetical', 'title'],
  ['Newest', 'date_created'],
  ['Start Date', 'date_start'],
]);

export const SortOrderOptionToDescendingParam = new Map<string, string>([
  ['Alphabetical', '-title'],
  ['Newest', '-date_created'],
  ['Start Date', '-date_start'],
]);
