import * as React from 'react';
import { useState } from 'react';
import { AddAlertOutlined } from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import FlagIcon from '@mui/icons-material/Flag';
import ReportIcon from '@mui/icons-material/Report';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { Box, ListItemIcon, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useFeatureFlag } from 'configcat-react';
import { threadId } from 'worker_threads';
import { CommunityUser, JunoUser } from '@juno/client-api/model';
import { BasicKebobMenu, ConfirmDeleteDialog } from '@juno/ui';
import { useSettings } from '@juno/utils';

interface ThreadPostOptionsProps {
  isAuthor: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  onDeleteConfirm: () => void;
  onBlockUserConfirm: () => void;
  onReportUserConfirm: () => void;
  onReportContentConfirm: () => void;
  onMakeAnnouncment?: () => void;
  iAmAdmin?: boolean;
  announced?: boolean;
  threadId: string;
  author: CommunityUser | undefined;
  isReply: boolean;
}

const ThreadPostOptions: React.FC<ThreadPostOptionsProps> = ({
  isAuthor,
  setIsEditing,
  onDeleteConfirm,
  onBlockUserConfirm,
  onReportUserConfirm,
  onReportContentConfirm,
  onMakeAnnouncment,
  iAmAdmin = false,
  announced = false,
  threadId,
  author,
  isReply,
}) => {
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { value: moderationValue } = useFeatureFlag('moderation', false);

  const { setReportContent, reportContent, user, myContentReports } = useSettings();
  // TODO future build out
  const handleBlockMemberClick = () => {
    // setDialogState({
    //   open: true,
    //   title: 'Block this user?',
    //   body: 'They won’t be able to contact you and your conversation will be hidden. They can still see your profile, but it will say you’re offline. They won’t be notified you blocked them. \n\n You can manage your blocked user list from your profile page.',
    //   onSave: () => {
    //     if (onBlockUserConfirm) {
    //       onBlockUserConfirm();
    //     }
    //     setDialogState(defaultDialogState);
    //   }, //to implement when backend ready
    //   onCancel: onDialogCancel,
    //   saveButtonTitle: 'Block User',
    // });
  };
  // TODO future build out
  const handleReportMemberClick = () => {
    // setDialogState({
    //   open: true,
    //   title: 'Report this user?',
    //   body: '',
    //   onSave: () => {
    //     if (onReportUserConfirm) {
    //       onReportUserConfirm();
    //     }
    //     // enqueueSnackbar(
    //     //   'User has been reported. Thank you for helping us keep the community safe.',
    //     //   snackOptions('success'),
    //     // );
    //   }, //to implement when backend ready
    //   onCancel: onDialogCancel,
    //   saveButtonTitle: 'Report User',
    // });
  };
  // TODO future build out
  const handleReportContentClick = () => {
    // setDialogState({
    //   open: true,
    //   title: 'Report Content?',
    //   body: 'Are you sure you want to flag this post as inappropriate?',
    //   onSave: () => {
    //     if (onReportContentConfirm) {
    //       onReportContentConfirm();
    //     }
    //     // enqueueSnackbar(
    //     //   'Content has been reported. Thank you for helping us keep the community safe.',
    //     //   snackOptions('success'),
    //     // );
    //   }, //to implement when backend ready
    //   onCancel: onDialogCancel,
    //   saveButtonTitle: 'Yes',
    //   cancelButtonTitle: 'No',
    // });
  };

  return (
    <>
      <BasicKebobMenu>
        {isAuthor && (
          <Box>
            <MenuItem
              onClick={() => {
                setIsEditing(true);
              }}
              aria-label='Edit post'
            >
              <ListItemIcon>
                <EditRoundedIcon color='primary' fontSize='small' />
              </ListItemIcon>
              <Typography>Edit</Typography>
            </MenuItem>
            {iAmAdmin && (
              <>
                {announced ? (
                  <MenuItem
                    onClick={() => {
                      onMakeAnnouncment && onMakeAnnouncment();
                    }}
                    aria-label='Make announcement'
                  >
                    <ListItemIcon>
                      <AddAlertOutlined color='primary' fontSize='small' />
                    </ListItemIcon>
                    <Typography>Remove Announcement</Typography>
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      onMakeAnnouncment && onMakeAnnouncment();
                    }}
                    aria-label='remove announcement'
                  >
                    <ListItemIcon>
                      <AddAlertOutlined color='primary' fontSize='small' />
                    </ListItemIcon>
                    <Typography>Make Announcement</Typography>
                  </MenuItem>
                )}
              </>
            )}

            <MenuItem
              onClick={() => {
                setShowDeleteConfirm(true);
              }}
              aria-label='Delete post'
            >
              <ListItemIcon>
                <DeleteForeverRoundedIcon color='primary' fontSize='small' />
              </ListItemIcon>
              <Typography>Delete</Typography>
            </MenuItem>
          </Box>
        )}
        {!isAuthor && iAmAdmin && (
          <Box>
            {iAmAdmin && (
              <>
                {announced ? (
                  <MenuItem
                    onClick={() => {
                      onMakeAnnouncment && onMakeAnnouncment();
                    }}
                    aria-label='Make announcement'
                  >
                    <ListItemIcon>
                      <AddAlertOutlined color='primary' fontSize='small' />
                    </ListItemIcon>
                    <Typography>Remove Announcement</Typography>
                  </MenuItem>
                ) : (
                  <MenuItem
                    onClick={() => {
                      onMakeAnnouncment && onMakeAnnouncment();
                    }}
                    aria-label='remove announcement'
                  >
                    <ListItemIcon>
                      <AddAlertOutlined color='primary' fontSize='small' />
                    </ListItemIcon>
                    <Typography>Make Announcement</Typography>
                  </MenuItem>
                )}
              </>
            )}
            <MenuItem
              onClick={() => {
                setShowDeleteConfirm(true);
              }}
              aria-label='Delete post as admin'
            >
              <ListItemIcon>
                <DeleteForeverRoundedIcon color='primary' fontSize='small' />
              </ListItemIcon>
              <Typography>Delete Post</Typography>
            </MenuItem>
          </Box>
        )}

        {!isAuthor && !iAmAdmin && moderationValue && (
          <MenuItem
            onClick={(e) => {
              setReportContent({
                contentBeingReported: {
                  ...reportContent.contentBeingReported,
                  id: threadId,
                  type: isReply ? 'Comment' : 'Post',
                  reporter: user?.id || '',
                  author: author?.id || '',
                },
                reportContentDialogOpen: true,
              });
            }}
            aria-label='Report Post'
            disabled={!!myContentReports.find((r) => r.object_id === threadId)}
          >
            <ListItemIcon>
              <ReportGmailerrorredIcon color='primary' fontSize='small' />
            </ListItemIcon>
            <Typography>
              {myContentReports.find((r) => r.object_id === threadId) ? 'Reported' : 'Report'}
            </Typography>
          </MenuItem>
        )}
        {/* // TODO - future build out */}
        {/* {!isAuthor && !iAmAdmin && (
          <Box>
            <MenuItem onClick={handleBlockMemberClick} aria-label='Block member'>
              <ListItemIcon>
                <BlockIcon color='primary' fontSize='small' />
              </ListItemIcon>
              <Typography>Block Member</Typography>
            </MenuItem>
            <MenuItem onClick={handleReportMemberClick} aria-label='Report member'>
              <ListItemIcon>
                <ReportIcon color='primary' fontSize='small' />
              </ListItemIcon>
              <Typography>Report Member</Typography>
            </MenuItem>
            <MenuItem onClick={handleReportContentClick} aria-label='Report post'>
              <ListItemIcon>
                <FlagIcon color='primary' fontSize='small' />
              </ListItemIcon>
              <Typography>Report Post</Typography>
            </MenuItem>
          </Box>
        )} */}
      </BasicKebobMenu>
      {showDeleteConfirm && (
        <ConfirmDeleteDialog
          handleClose={() => setShowDeleteConfirm(false)}
          handleDelete={() => {
            onDeleteConfirm();
            setShowDeleteConfirm(false);
          }}
          title={'Delete Comment?'}
          message={
            <Typography>
              Are you sure you want to delete this comment? This action cannot be undone.
            </Typography>
          }
        />
      )}
    </>
  );
  // TODO future build out
  //     <ConfirmDialog
  //       open={dialogState.open}
  //       title={dialogState.title}
  //       children={
  //         dialogState.body && (
  //           <Typography sx={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
  //             {dialogState.body}
  //           </Typography>
  //         )
  //       }
  //       onSave={dialogState.onSave}
  //       onCancel={dialogState.onCancel}
  //       buttonTitle={dialogState.saveButtonTitle}
  //       cancelButtonTitle={dialogState.cancelButtonTitle || 'Cancel'}
  //     ></ConfirmDialog>
};

export default ThreadPostOptions;
