import React, { ChangeEvent, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { slugify } from '@juno/utils';

interface FormDialogProps {
  open: boolean;
  onSave: (resource: PayloadProps | undefined) => void;
  onClose: () => void;
  isSaving: boolean;
}

export interface PayloadProps {
  title?: string;
  slug?: string;
}

const FormDialog: React.FC<FormDialogProps> = ({ open, onClose, onSave, isSaving }) => {
  const [payload, setPayload] = useState<PayloadProps | undefined>();
  const [error, setError] = useState<string>('');
  const isValid = !!(payload?.title && payload?.slug && !error);
  useEffect(() => {
    if (!open) {
      setPayload(undefined);
    }
  }, [open]);

  useEffect(() => {
    if (payload?.title && payload?.title?.length > 90) {
      setError('Title character limit: 90');
    } else {
      setError('');
    }
  }, [payload?.title]);

  const handleUpdate = (key: string, val: unknown) => {
    setPayload({ ...payload, [key]: val });
  };
  const changeHandler = {
    text: ({ target }: ChangeEvent<HTMLInputElement>) => {
      if (target.name === 'title') {
        // Update slug value based on title update for new pages
        setPayload({ ...payload, title: target.value, slug: slugify(target.value) });
        return;
      }
      if (target.name === 'slug') {
        // Require slug to follow slugify rules (alphanumeric, dashes, underscores, and uppercase here)
        handleUpdate(target.name, slugify(target.value, true));
        return;
      }
      handleUpdate(target.name, target.value);
    },
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create lesson</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          disabled={isSaving}
          id='title'
          name='title'
          label='Title'
          fullWidth
          margin='dense'
          value={payload?.title || ''}
          onChange={changeHandler.text}
          error={!!error}
          helperText={error}
        />
        <TextField
          disabled={isSaving}
          id='slug'
          name='slug'
          label='Slug'
          fullWidth
          margin='dense'
          value={payload?.slug || ''}
          onChange={changeHandler.text}
        />
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button disabled={isSaving} onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            disabled={!isValid}
            loading={isSaving}
            variant='contained'
            onClick={() => onSave(payload)}
          >
            Save
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default FormDialog;
