import React, { ChangeEvent, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { PriceLevel, ProductPrice } from '@juno/client-api/model';
import { DialogAriaWrapper, JunoSpin } from '@juno/ui';

interface FormDialogProps {
  open: boolean;
  onSave: (data: ProductPrice) => void;
  onClose: () => void;
  onDelete: () => void;
  isLoading: boolean;
  isSaving: boolean;
  item?: ProductPrice;
  priceLevels: PriceLevel[];
  unusedPriceLevels: PriceLevel[];
}

export interface PayloadProps {
  id?: string;
  price_level_id?: string;
  price?: string;
  currency?: string;
}

const FormDialog: React.FC<FormDialogProps> = ({
  open,
  onClose,
  onSave,
  onDelete,
  isLoading,
  isSaving,
  item,
  priceLevels,
  unusedPriceLevels,
}) => {
  const [payload, setPayload] = useState<PayloadProps | undefined>();
  const isValid =
    !!payload?.price_level_id && !!payload?.price && parseInt(payload?.price || '0') > 0;

  const handleUpdate = (key: string, val: unknown) => setPayload({ ...payload, [key]: val });

  const changeHandler = {
    text: ({ target }: ChangeEvent<HTMLInputElement>) => handleUpdate(target.name, target.value),
    select: ({ target }: SelectChangeEvent<string>) => handleUpdate(target.name, target.value),
  };

  useEffect(() => {
    if (!open) setPayload(undefined);
  }, [open]);

  useEffect(() => {
    if (item)
      setPayload({
        ...item,
        price_level_id: item.price_level?.id,
      });
  }, [item]);

  if (isLoading) return <JunoSpin />;
  return (
    <DialogAriaWrapper open={open} onClose={onClose} maxWidth='sm' id='pricing_item_dialog'>
      <DialogTitle>{!payload?.id ? 'New Pricing Item' : 'Edit Pricing Item'}</DialogTitle>
      <DialogContent>
        <FormControl margin='dense' fullWidth>
          <InputLabel id='select-content-label'>Pricing Level</InputLabel>
          <Select
            labelId='select-content-label'
            id='select-content-label'
            name='price_level_id'
            value={item?.price_level?.id || payload?.price_level_id || ''}
            onChange={changeHandler.select}
            input={<OutlinedInput id='select-multiple-chip' label='Pricing Level' size='small' />}
            disabled={!!item}
          >
            {priceLevels.map((level) => (
              <MenuItem
                key={level.id}
                value={level.id}
                disabled={!unusedPriceLevels.find((item) => item.id === level.id)}
              >
                {level.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack direction='row' gap={2}>
          <TextField
            disabled={isSaving}
            id='price'
            name='price'
            label='Price'
            fullWidth
            margin='normal'
            type='number'
            value={payload?.price || ''}
            onChange={changeHandler.text}
            size='small'
            InputProps={{
              startAdornment: <InputAdornment position='start'>$</InputAdornment>,
            }}
          />
          <FormControl margin='normal' fullWidth>
            <InputLabel id='select-content-label'>Currency</InputLabel>
            <Select
              labelId='select-content-label'
              id='select-content-label'
              name='currency'
              value={item?.currency || payload?.currency || 'usd'}
              onChange={changeHandler.select}
              input={<OutlinedInput id='select-multiple-chip' label='Currency' size='small' />}
            >
              <MenuItem value={'usd'}>USD</MenuItem>
              <MenuItem value={'cad'}>CAD</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button
          sx={{ visibility: !item ? 'hidden' : 'visible' }}
          disabled={isSaving}
          variant='contained'
          color='error'
          onClick={onDelete}
        >
          Delete
        </Button>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <Button disabled={isSaving} onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            disabled={!isValid}
            loading={isSaving}
            variant='contained'
            onClick={() => onSave(payload as ProductPrice)}
          >
            Save
          </LoadingButton>
        </Box>
      </DialogActions>
    </DialogAriaWrapper>
  );
};

export default FormDialog;
