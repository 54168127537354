import React, { useEffect, useState } from 'react';
import {
  DesktopMacOutlined,
  MarkEmailUnreadOutlined,
  PhoneIphoneOutlined,
} from '@mui/icons-material';
import { Box, Checkbox, Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import { useUpdateNotificationPreferences } from '@juno/client-api';
import { NotificationPreferences, TypeA36Enum } from '@juno/client-api/model';
import { useNotificationContext } from '@juno/modules';
import { MutationAction, onMutation, useSettings } from '@juno/utils';
import { notificationPreferences } from './utils/consts';

interface PreferencesCardProps {
  handleGoBack: () => void;
}
const PreferencesCard: React.FC<PreferencesCardProps> = ({ handleGoBack }) => {
  const { site, user } = useSettings();
  const [sortedAndGroupedPreferences, setSortedAndGroupedPreferences] = useState<
    { type: string; preferences: NotificationPreferences[] }[]
  >([]);
  const {
    userPreferences: preferences,
    isLoadingUserPreferences,
    refetchPreferences,
  } = useNotificationContext();
  const updatePreference = useUpdateNotificationPreferences(
    onMutation(MutationAction.UPDATE, '', refetchPreferences),
  );
  useEffect(() => {
    if (!preferences) return;
    const groupedPreferences = preferences.reduce((acc, preference) => {
      const type = preference.preference_type;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(preference);
      return acc;
    }, {} as Record<string, NotificationPreferences[]>);
    const finalPrefs = Object.entries(groupedPreferences)
      .sort(([typeA], [typeB]) => Number(typeA) - Number(typeB))
      .filter(
        ([pref]) => Number(pref) !== TypeA36Enum.NUMBER_1 && Number(pref) !== TypeA36Enum.NUMBER_2,
      )
      .map(([type, preferences]) => ({
        type,
        preferences: preferences.sort((a, b) => a.type - b.type),
      }));
    setSortedAndGroupedPreferences(finalPrefs);
  }, [preferences]);

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: number,
    preference: NotificationPreferences,
    checked: boolean,
  ) => {
    // find the appropriate preference in sortedAndGroupedPreferences and update it with the checked value
    const updatedPreferences = sortedAndGroupedPreferences.map((pref) => {
      if (Number(pref.type) === type) {
        return {
          ...pref,
          preferences: pref.preferences.map((p) => {
            if (p.id === preference.id) {
              return { ...p, is_active: checked };
            }
            return p;
          }),
        };
      }
      return pref;
    });

    setSortedAndGroupedPreferences(updatedPreferences);
    const data = {
      id: preference.id,
      user: user?.id,
      type: preference.type,
      preference_type: preference.preference_type,
      is_active: checked,
    } as NotificationPreferences;
    updatePreference.mutate({ siteId: site?.id || '', data: data });
  };
  return (
    <>
      <Typography variant='h6' fontWeight='600'>
        Notification Preferences
      </Typography>
      <Typography variant='subtitle2' fontSize={14} sx={{ opacity: 1 }}>
        Note: We may still send you important information through notifications not included in
        these settings.
      </Typography>
      <Box pt={3}>
        <Grid container spacing={1} pl={1.5} width={328}>
          <Grid item xs={2}>
            <Tooltip title='Mobile Push' placement='top'>
              <PhoneIphoneOutlined />
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title='Browser Push' placement='top'>
              <DesktopMacOutlined />
            </Tooltip>
          </Grid>
          <Grid item xs={2}>
            <Tooltip title='Email Push' placement='top'>
              <MarkEmailUnreadOutlined />
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
      {isLoadingUserPreferences && <PreferencesSkeleton />}
      {!isLoadingUserPreferences && preferences && (
        <>
          {sortedAndGroupedPreferences.map((pref, index) => (
            <Grid key={`${pref.type}-${index}`} container spacing={0} width={328}>
              <React.Fragment key={index}>
                {pref.preferences.map((preference, preferenceIndex) => {
                  return (
                    <Grid item xs={2} key={preferenceIndex}>
                      <Checkbox
                        checked={preference.is_active}
                        onChange={(e, checked) => {
                          handleCheckboxChange(e, Number(pref.type), preference, checked);
                        }}
                      />
                    </Grid>
                  );
                })}
                <Tooltip
                  placement='bottom'
                  title={
                    <Typography variant='caption' fontSize={'0.7rem'}>
                      {notificationPreferences.filter(
                        (nPref) => nPref.type === Number(pref.type),
                      )?.[0]?.description || ''}
                    </Typography>
                  }
                >
                  <Grid item xs={6} alignItems={'center'} display={'flex'} key={index}>
                    <Typography fontSize={'0.875rem'}>
                      {notificationPreferences.filter(
                        (nPref) => nPref.type === Number(pref.type),
                      )?.[0]?.label || ''}
                    </Typography>
                  </Grid>
                </Tooltip>
              </React.Fragment>
            </Grid>
          ))}
        </>
      )}
    </>
  );
};
export default PreferencesCard;

const PreferencesSkeleton = () => {
  return (
    <Box>
      <Box display={'flex'} alignItems={'center'}>
        <Skeleton variant={'rectangular'} height={30} width={30} sx={{ m: 1 }} />
        <Skeleton variant={'rectangular'} height={30} width={30} sx={{ m: 1 }} />
        <Skeleton variant={'rectangular'} height={30} width={30} sx={{ m: 1 }} />
        <Skeleton variant={'text'} height={30} width={100} sx={{ ml: 3 }} />
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <Skeleton variant={'rectangular'} height={30} width={30} sx={{ m: 1 }} />
        <Skeleton variant={'rectangular'} height={30} width={30} sx={{ m: 1 }} />
        <Skeleton variant={'rectangular'} height={30} width={30} sx={{ m: 1 }} />
        <Skeleton variant={'text'} height={30} width={100} sx={{ ml: 3 }} />
      </Box>
      <Box display={'flex'} alignItems={'center'}>
        <Skeleton variant={'rectangular'} height={30} width={30} sx={{ m: 1 }} />
        <Skeleton variant={'rectangular'} height={30} width={30} sx={{ m: 1 }} />
        <Skeleton variant={'rectangular'} height={30} width={30} sx={{ m: 1 }} />
        <Skeleton variant={'text'} height={30} width={100} sx={{ ml: 3 }} />
      </Box>
    </Box>
  );
};
