import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Chip } from '@mui/material';

interface FilterChipProps {
  label: string;
  onDelete: () => void;
}

const FilterChip: React.FC<FilterChipProps> = ({ label, onDelete }) => {
  return (
    <Chip
      sx={{ borderRadius: '4px 4px 4px 4px', fontSize: '12px', height: '20px' }}
      variant={'outlined'}
      onDelete={onDelete}
      deleteIcon={<CloseIcon style={{ fontSize: '12px' }} />}
      label={label}
      color='primary'
    />
  );
};

export default FilterChip;
