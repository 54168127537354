import React from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useGetCertificateTemplates } from '@juno/client-api';
import { CertificateTemplate, EducationCredit } from '@juno/client-api/model';
import { DefaultSingleBasicAutocomplete, GenericDateInput, GenericFormikInput } from '@juno/ui';
import { UTCtoLocalString } from '@juno/utils';

interface NewEducationCreditDialogProps {
  siteId: string;
  open: boolean;
  setCreateNewDialogShowing: React.Dispatch<React.SetStateAction<boolean>>;
  allEducationCredits: EducationCredit[];
  title: string;
  date_released: string;
  isLoading: boolean;
  onCreate: (values: {
    title: string;
    date_released: string;
    date_claimable: string;
    certificate: CertificateTemplate;
  }) => void;
}
const NewEducationCreditDialog: React.FC<NewEducationCreditDialogProps> = ({
  siteId,
  open,
  setCreateNewDialogShowing,
  allEducationCredits,
  title,
  date_released,
  onCreate,
  isLoading,
}) => {
  const { data: certificates, isLoading: isLoadingCertificates } =
    useGetCertificateTemplates<CertificateTemplate[]>(siteId);
  if (isLoadingCertificates) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={() => {
        setCreateNewDialogShowing(false);
      }}
      fullWidth
      sx={{ textAlign: 'center' }}
    >
      <DialogTitle>New Education Credit</DialogTitle>
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        validationSchema={newEducationCreditValidation(allEducationCredits ?? [])}
        initialValues={{
          title,
          date_released,
          date_claimable: date_released,
          certificate: {} as CertificateTemplate,
        }}
        onSubmit={onCreate}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          return (
            <Form>
              <FormControl>
                <DialogContent>
                  <GenericFormikInput
                    label='Title'
                    name='title'
                    type='text'
                    placeholder='Title'
                    value={values.title}
                    required
                  />
                  <GenericDateInput
                    label='Release Date'
                    name='date_released'
                    value={
                      values.date_released ? UTCtoLocalString(new Date(values.date_released)) : ''
                    }
                    onChange={(event: any) => {
                      setFieldValue(
                        'date_released',
                        event.target.value === ''
                          ? null
                          : new Date(event.target.value)?.toISOString(),
                      );
                      setFieldTouched('date_released', true);
                    }}
                    onBlur={handleBlur}
                    error={touched.date_released && Boolean(errors.date_released)}
                    helperText={touched.date_released && errors.date_released}
                  />
                  <GenericDateInput
                    label='Claimable Date'
                    name='date_claimable'
                    value={
                      values.date_claimable ? UTCtoLocalString(new Date(values.date_claimable)) : ''
                    }
                    onChange={(event: any) => {
                      setFieldValue(
                        'date_claimable',
                        event.target.value === ''
                          ? null
                          : new Date(event.target.value)?.toISOString(),
                      );
                      setFieldTouched('date_claimable', true);
                    }}
                    onBlur={handleBlur}
                    error={touched.date_claimable && Boolean(errors.date_claimable)}
                    helperText={touched.date_claimable && errors.date_claimable}
                  />
                  <DefaultSingleBasicAutocomplete
                    chosenOption={values.certificate}
                    options={certificates ?? []}
                    valueKey={'name'}
                    labelKey={'name'}
                    sortKey='name'
                    inputLabel='Certificate'
                    handleChange={(event, value) => {
                      setFieldValue('certificate', value);
                      setFieldTouched('certificate', true);
                    }}
                  />
                </DialogContent>
                <DialogActions
                  sx={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
                >
                  <Stack sx={{ width: '100%', pr: 2, pl: 2, pb: 2 }}>
                    <LoadingButton
                      onClick={() => handleSubmit()}
                      variant='contained'
                      sx={{ mb: 2, letterSpacing: '1px' }}
                      disabled={
                        errors.title !== undefined ||
                        errors.date_released !== undefined ||
                        errors.certificate !== undefined
                      }
                      loading={isLoading}
                    >
                      Save
                    </LoadingButton>
                    <Button
                      onClick={() => setCreateNewDialogShowing((old) => !old)}
                      variant='outlined'
                    >
                      Cancel
                    </Button>
                  </Stack>
                </DialogActions>
              </FormControl>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};
export default NewEducationCreditDialog;

const newEducationCreditValidation = (allEducationCredits: EducationCredit[]) => {
  return Yup.object({
    title: Yup.string()
      .required('Credit Title is required')
      .max(90, 'Credit Title must not exceed 90 characters')
      .test('title-unique-validation', 'This field must be unique', function test(value) {
        if (value && value.length > 0) {
          if (
            allEducationCredits &&
            allEducationCredits.filter((ec) => ec.name === value).length > 0
          ) {
            return false;
          }
          return true;
        } else {
          return true;
        }
      }),
  });
};
