import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Chat } from '@pubnub/chat';
import PubNub from 'pubnub';
import { useGetAllUsers } from '@juno/client-api';
import { Channel, Message, MessengerContext, MessengerMap, useSettings } from '@juno/utils';
import { PlatformContext } from '../Contexts/PlatformContext';
import { usePubnubContext } from '../Contexts/PubnubContext';
import {
  ChannelToUnreadMessageCountMap,
  SystemChannelMessage,
  fetchAllMemberships,
  filterOutNonUUIDs,
  getMessagesFromChannel,
  getPubNubChannel,
  getPubNubChannelMembersForMultipleChannels,
  markAllMessagesAsRead,
  processSystemMessage,
  setChannelVisible,
} from './Utils';

const SUBSCRIBE_KEY = process.env.NX_PUBNUB_SUBSCRIBE_KEY || 'UNINITIALIZED';
const PUBLISH_KEY = process.env.NX_PUBNUB_PUBLISH_KEY || 'UNINITIALIZED';

type MessengerProviderProps = {
  children: React.ReactNode;
};

export const MessengerProvider: React.FC<MessengerProviderProps> = ({ children }) => {
  const platformContext = useContext(PlatformContext);
  const { user } = useSettings();

  const [messengerMap, setMessengerMap] = useState<MessengerMap>({ channels: [] });
  // Our PubNub instance
  const { pubnub, systemChannel } = usePubnubContext();
  const [chat, setChat] = useState<any>(undefined);
  const maxChannelUsers = 10; // Const for the max number of users in a channel... might need tuning but used in more than one place so I put it here as const

  // Used for Messenger state: Conversation or Conversations list etc.
  const [isConversation, setIsConversation] = useState(false);
  const [messengerTab, setMessengerTab] = React.useState(0);

  // Used for Messenger state: What conversation is currently selected, and we're viewing
  const [currentChannel, setCurrentChannel] = React.useState('');

  // Used to manage messenger notification unread message count
  const [unreadMessageCounts, setUnreadMessageCounts] = useState<ChannelToUnreadMessageCountMap>(
    {},
  );

  const messengerMapUserIds = filterOutNonUUIDs(
    messengerMap.channels.map((channel) => channel.userIds).flat(),
  );

  const [isCreateNewConversation, setIsCreateNewConversation] = useState(false);

  const currentChannelRef = useRef(currentChannel);
  const listenersAddedRef = useRef(false);
  const [currentConversationLastReadMessageId, setCurrentConversationLastReadMessageId] =
    useState('');

  useEffect(() => {
    currentChannelRef.current = currentChannel;
  }, [currentChannel]);

  const { data: usersData, refetch } = useGetAllUsers(
    platformContext?.platform?.id || '',
    {
      filter_or: { id__in: messengerMapUserIds },
      limit: 50,
      offset: 0,
      include: 'settings',
    },
    { query: { enabled: messengerMap.channels.length > 0 && messengerMapUserIds.length > 0 } },
  );

  const refreshMessengerMap = useCallback(async () => {
    if (pubnub && user?.id && chat && systemChannel) {
      const fetchMembershipOptions = {
        pubnub: pubnub,
        uuid: user?.id || '',
        limit: 100,
      };

      try {
        const channelsMeta = await fetchAllMemberships(fetchMembershipOptions);
        const newMessengerMap: MessengerMap = {
          channels: channelsMeta.map((channelMeta) => ({
            id: channelMeta.channelId,
            name: channelMeta.channelName,
            userIds: [],
            junoUsers: [],
            messages: [],
            isHidden: channelMeta.isHidden || false,
          })),
        };

        for (const channel of newMessengerMap.channels) {
          const channelMembersMap = await getPubNubChannelMembersForMultipleChannels(
            [channel.id],
            pubnub,
          );
          if (channelMembersMap && channelMembersMap[channel.id]) {
            channel.userIds = channelMembersMap[channel.id].map((uuid) => uuid.uuid);
          }

          const fetchedMessages = await getMessagesFromChannel(
            channel.id,
            pubnub,
            25,
            user?.id,
            chat,
          );
          channel.messages = fetchedMessages || [];
        }

        return newMessengerMap;
      } catch (error) {
        console.error('Error refreshing messenger map:', error);
        // Handle the error appropriately
      }
    }
  }, [pubnub, user?.id, chat, systemChannel]);

  const hasSubscribed = useRef(false);
  // Subscription management
  const subscribeToChannels = useCallback(
    (channelIds: string[]) => {
      if (pubnub && channelIds.length > 0 && !hasSubscribed.current) {
        // subscribe to the channels
        channelIds = [...channelIds, systemChannel];
        pubnub.subscribe({ channels: channelIds, withPresence: true });
        hasSubscribed.current = true;
      }
    },
    [pubnub],
  );

  // Use an effect to handle component unmount and potentially changing channel IDs
  useEffect(() => {
    // Subscribe to channels when `channelIds` or `pubnub` changes
    const channelIds = messengerMap.channels.map((channel) => channel.id);
    subscribeToChannels(channelIds);
  }, [subscribeToChannels, pubnub, messengerMap.channels]);

  const unsubscribeToChannels = useCallback(
    (channelIds: string[]) => {
      if (pubnub && channelIds.length > 0) {
        pubnub.unsubscribe({ channels: channelIds });

        channelIds.forEach((channelId) => {
          removeChannelFromMessengerMap(channelId);
        });
      }
    },
    [pubnub],
  );

  const addChannelToMessengerMap = async (newChannelId: string) => {
    if (!pubnub || !user?.id || !chat) return;

    const newChannel: Channel = {
      id: newChannelId,
      name: newChannelId,
      userIds: [],
      junoUsers: [],
      messages: [],
      isHidden: false,
    };

    const channelMembersMap = await getPubNubChannelMembersForMultipleChannels(
      [newChannel.id],
      pubnub,
    );
    if (channelMembersMap && channelMembersMap[newChannel.id]) {
      newChannel.userIds = channelMembersMap[newChannel.id].map((uuid) => uuid.uuid);
    }
    const fetchedMessages = await getMessagesFromChannel(newChannel.id, pubnub, 25, user?.id, chat);
    newChannel.messages = fetchedMessages || [];

    setMessengerMap((prevMap) => {
      // If the channel already exists, don't add it again
      if (prevMap.channels.find((channel) => channel.id === newChannel.id)) {
        return prevMap;
      }

      // If the channel doesn't exist, add it
      return { ...prevMap, channels: [...prevMap.channels, newChannel] };
    });
  };

  const removeChannelFromMessengerMap = async (newChannelId: string) => {
    if (!pubnub) return;

    setMessengerMap((prevMap: MessengerMap) => {
      const newChannels = prevMap.channels.filter((channel) => channel.id !== newChannelId);
      return { ...prevMap, channels: newChannels };
    });
  };

  const hideChannelInMessengerMap = async (newChannelId: string) => {
    if (!pubnub || !user?.id) return;

    // Set the isHidden flag to true so it doesn't show in the UI also mark all messages as read
    setMessengerMap((prevMap) => {
      const newChannels = prevMap.channels.map((channel) => {
        if (channel.id === newChannelId) {
          const updatedMessages = channel.messages.map((message) => {
            return { ...message, isNewMessage: false };
          });
          return { ...channel, isHidden: true, messages: updatedMessages };
        } else {
          return channel;
        }
      });
      return { ...prevMap, channels: newChannels };
    });

    await setChannelVisible({ pubnub, channelId: newChannelId, userId: user?.id, hide: true });
  };

  const showChannelInMessengerMap = useCallback(
    (channelId: string) => {
      if (!pubnub || !user?.id) return;

      // Logic to determine if the channel is already visible
      const channel = messengerMap.channels.find((c) => c.id === channelId);
      if (!channel?.isHidden) {
        // console.log(`Channel ${channelId} is already visible or does not exist.`);
        return;
      }

      try {
        setChannelVisible({ pubnub, channelId, userId: user.id, hide: false });
        // Update the messengerMap to show the channel
        setMessengerMap((prevMap) => {
          const newChannels = prevMap.channels.map((channel) => {
            if (channel.id === channelId) {
              return { ...channel, isHidden: false };
            } else {
              return channel;
            }
          });
          const newMessengerMap = { ...prevMap, channels: newChannels };
          return newMessengerMap;
        });
        // console.log(`Channel ${channelId} visibility updated.`);
      } catch (error) {
        console.error(`Failed to update visibility for channel ${channelId}:`, error);
      }
    },
    [pubnub, user?.id, messengerMap],
  );

  const addMessageToChannel = useCallback(
    (newMessage: Message, channelId: string) => {
      setMessengerMap((prevMap) => {
        const newChannels = prevMap.channels.map((channel) => {
          if (channel.id === channelId) {
            // Check if the new message already exists in the channel's messages
            const messageExists = channel.messages.some(
              (message) =>
                message.text === newMessage.text &&
                message.createdAt === newMessage.createdAt &&
                message.userId === newMessage.userId,
            );

            if (!messageExists) {
              // For the channel being updated, create a new object with updated messages
              channel.isHidden = false;
              return { ...channel, messages: [...channel.messages, newMessage] };
            } else {
              return channel; // If the message exists, return the channel as is
            }
          } else {
            // For other channels, return the original object
            return channel;
          }
        });

        // Return a new messengerMap object with the updated channels array
        return { ...prevMap, channels: newChannels };
      });
    },
    [setMessengerMap],
  );

  useEffect(() => {
    if (pubnub) {
      const messageListener = {
        message: (messageEvent: PubNub.MessageEvent) => {
          if (messageEvent.channel === user?.id) {
            // Received a message designated for notifications, just return as NotificationContext will handle it
            return;
          }

          if (messageEvent.channel === currentChannelRef.current && user?.id) {
            markRead(messageEvent.channel, messageEvent.timetoken);
          }

          if (messageEvent.channel === systemChannel && user?.id) {
            console.log('Received a message on the system channel:', messageEvent);
            const action = processSystemMessage(
              messageEvent.message as SystemChannelMessage,
              user?.id,
            );
            if (action) {
              switch (action.actionType) {
                case 'SUBSCRIBE_TO_CHANNEL':
                  pubnub.subscribe({ channels: [action.channelId] });
                  addChannelToMessengerMap(action.channelId);
                  break;
                case 'UNSUBSCRIBE_FROM_CHANNEL':
                  pubnub.unsubscribe({ channels: [action.channelId] });
                  // Update application state as needed
                  break;
              }
            }
          } else {
            if (user?.id) {
              setChannelVisible({
                pubnub,
                channelId: messageEvent.channel,
                userId: user.id,
                hide: false,
              });
            } else {
              console.error('User ID not set');
            }
          }

          const currentChannelValue = currentChannelRef.current;

          addMessageToChannel(
            {
              text: messageEvent.message.text,
              createdAt: messageEvent.timetoken,
              userId: messageEvent.publisher,
              isNewMessage: messageEvent.channel !== currentChannelValue,
            } as Message,
            messageEvent.channel,
          );

          showChannelInMessengerMap(messageEvent.channel);
        },
      };

      if (pubnub && !listenersAddedRef.current) {
        pubnub.addListener(messageListener);
        listenersAddedRef.current = true;
      }

      return () => {
        if (pubnub && listenersAddedRef.current) {
          pubnub.removeListener(messageListener);
          listenersAddedRef.current = false;
        }
      };
    }
  }, [pubnub, chat]);

  // Initialize chat instance
  useEffect(() => {
    // Configure the PubNub instance
    if (user?.id) {
      Chat.init({
        publishKey: PUBLISH_KEY,
        subscribeKey: SUBSCRIBE_KEY,
        userId: user?.id,
      }).then((chat: any) => {
        setChat(chat);
      });
    }
  }, [user?.id]);

  // Populate the messengerMap
  useEffect(() => {
    refreshMessengerMap().then((newMessengerMap) => {
      if (newMessengerMap) {
        setMessengerMap(newMessengerMap);
      }
    });
  }, [pubnub, refreshMessengerMap]);

  // Populate the JunoUser data in the messengerMap
  useEffect(() => {
    if (usersData) {
      // Create a new array for channels to ensure updates are detected
      const updatedChannels = messengerMap.channels.map((channel) => {
        // For each channel, create a new object with updated junoUsers
        return {
          ...channel,
          junoUsers: usersData.filter((user) => channel.userIds.includes(user.id)),
        };
      });

      // Update the messengerMap with the new channels array
      const newMessengerMap = {
        ...messengerMap,
        channels: updatedChannels,
      };

      setMessengerMap(newMessengerMap);
    }
  }, [usersData]);

  const createChannelThenSubscribe = async (userIds: string[]): Promise<string | null> => {
    if (pubnub && user?.id && platformContext?.platform?.id) {
      try {
        const newChannel = await getPubNubChannel(userIds, pubnub, platformContext?.platform?.id);
        if (newChannel) {
          subscribeToChannels([newChannel]);
          await addChannelToMessengerMap(newChannel);
          // Send a system message to the new channel to add all users to this channel

          // console.log('Sending system message to add user to channel:', userIds);
          pubnub.publish(
            {
              channel: systemChannel,
              message: {
                type: 'ADD_TO_CHANNEL',
                channelId: newChannel,
                userIds: userIds,
              },
            },
            (status: PubNub.PubnubStatus) => {
              if (status.error) {
                console.error('Error sending system message:', status);
              }
            },
          );
          return newChannel;
        }
      } catch (error) {
        console.error('Error creating channel and subscribing:', error);
      }
    }
    return null;
  };

  // It's critical everyone subscribe to the system channel
  // The system channel is used to send messages to people and control their messenger. i.e. add them to a channel etc.
  // It needs its own listener because it's not a regular channel, and must make decisions based on the message's action type
  useEffect(() => {
    if (pubnub && systemChannel && user?.id) {
      // If the system channel doesn't exist, create it
      // Probably move this to the server to reduce PubNub API calls
      pubnub.channelGroups.listChannels(
        {
          channelGroup: 'system',
        },
        (status, response) => {
          if (status.error) {
            console.error('Error getting system channel: ', status);
          } else {
            if (!response.channels.includes(systemChannel)) {
              pubnub.channelGroups.addChannels(
                {
                  channels: [systemChannel],
                  channelGroup: 'system',
                },
                (status: PubNub.PubnubStatus) => {
                  if (status.error) {
                    console.error('Error adding system channel: ', status);
                  } else {
                    console.log('Added system channel: ', response);
                  }
                },
              );
            }
          }
        },
      );
    }
    return () => {};
  }, [user?.id, chat, pubnub, systemChannel]);

  const markRead = async (channelId: string, timetoken?: string) => {
    if (pubnub && chat) {
      try {
        const markReadParams = {
          pubnub,
          channel: channelId || currentChannel,
          userId: pubnub.getUUID(),
          chat,
          ...(timetoken && { timetoken }),
        };

        if (channelId) {
          // console.log('Marking all messages as read in channel: ', channelId || currentChannel);
          await markAllMessagesAsRead(markReadParams);
          // Find the channel in the messengerMap and mark all messages as read
          setMessengerMap((prevMap) => {
            // console.log('prevMap:', prevMap);
            const newChannels = prevMap.channels.map((channel) => {
              if (channel.id === channelId) {
                const updatedMessages = channel.messages.map((message) => {
                  return { ...message, isNewMessage: false };
                });
                return { ...channel, messages: updatedMessages };
              } else {
                return channel;
              }
            });
            const markedRead = { ...prevMap, channels: newChannels };
            // console.log('markedRead:', markedRead);
            return markedRead;
          });
        } else {
          console.error('markRead called but currentChannel is not set');
        }
      } catch (error) {
        console.error('markRead error', error);
      }
    }
  };

  const removeUserFromChannel = async (channelId: string, userId: string): Promise<boolean> => {
    if (!pubnub) {
      console.error('PubNub not initialized');
      return false;
    }
    unsubscribeToChannels([channelId]);
    await removeChannelFromMessengerMap(channelId);
    return true;
  };

  const setChannelVisibility = async (
    channelId: string,
    userId: string,
    hide = true,
  ): Promise<boolean> => {
    if (!pubnub) {
      console.error('PubNub not initialized');
      return false;
    }
    if (hide) {
      await hideChannelInMessengerMap(channelId);
    } else {
      await showChannelInMessengerMap(channelId);
    }
    return setChannelVisible({
      pubnub,
      channelId,
      userId,
    });
  };

  if (pubnub === undefined || chat === undefined) {
    // Can't use JunoSpin because we haven't loaded the theme
    return <></>;
  }

  const contextValue = {
    isConversation,
    setIsConversation,
    messengerTab,
    setMessengerTab,
    currentChannel,
    setCurrentChannel: (channelId: any) => {
      // console.log('setCurrentChannel called with:', channelId);
      setCurrentChannel(channelId); // You can also use a functional update if needed
    },
    maxChannelUsers,
    unreadMessageCounts,
    setUnreadMessageCounts,
    systemChannel,
    markRead,
    removeUserFromChannel,
    setChannelVisibility,
    messengerMap,
    createChannelThenSubscribe,
    subscribeToChannels,
    isCreateNewConversation,
    setIsCreateNewConversation,
    currentConversationLastReadMessageId,
    setCurrentConversationLastReadMessageId,
  };

  return <MessengerContext.Provider value={contextValue}>{children}</MessengerContext.Provider>;
};

export default MessengerProvider;
