import React, { ChangeEvent, MouseEvent, useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { JunoImage } from '@juno/client-api/fakeModel';
import JunoSpin from '../JunoSpin';

const InputBaseWrapper = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1,
}));

const PaperWrapper = styled(Paper)(() => ({
  flexGrow: 1,
  padding: '2px 4px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  marginBottom: 20,
}));

export interface ImageLibraryProps {
  onSelected: (selectedFile: JunoImage) => void;
}

const ImageLibrary: React.FC<ImageLibraryProps> = ({ onSelected }) => {
  const [searchValue, setSearchValue] = useState('');
  const useGetImageAllImages = { data: [], isLoading: false }; //useGetAllImages(clientId);
  const imageData = useGetImageAllImages.data as JunoImage[];

  const filteredImages = useMemo(() => {
    const strContains = (a: string, b: string) => a.toLowerCase().includes(b.toLowerCase());
    return imageData?.filter((image) => strContains(image.url, searchValue)) || [];
  }, [imageData, searchValue]);

  // const handleRowClick = (rowData: GridRowModel) => {
  //   onSelected(rowData.row as JunoImage);
  // };
  const handleRowClick = (e: MouseEvent<unknown>, name: string) => console.log('name', name);
  const onChange = (event: ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);

  if (useGetImageAllImages.isLoading) return <JunoSpin />;

  return (
    <div>
      <PaperWrapper>
        <InputBaseWrapper
          onChange={onChange}
          value={searchValue || ''}
          placeholder='Search Image Library'
          inputProps={{ 'aria-label': 'search image library' }}
        />
        <IconButton type='submit' style={{ padding: 10 }} aria-label='search' disabled>
          <SearchIcon />
        </IconButton>
      </PaperWrapper>
      <TableContainer style={{ maxHeight: 400, minHeight: 200 }}>
        <Table aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Thumb</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Size</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredImages.map((row) => (
              <TableRow hover key={row.name} onClick={(event) => handleRowClick(event, row.name)}>
                <TableCell sx={{ padding: 1 }}>
                  <img width={50} height={50} src={`${row.thumb_url}`} alt={''} />
                </TableCell>
                <TableCell sx={{ padding: 1 }}>{row.name}</TableCell>
                <TableCell sx={{ padding: 1 }}>{row.size}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ImageLibrary;
