import * as Yup from 'yup';

export const EXPERIENCE_CHARACTER_LIMITS = {
  title: 128,
  company: 128,
  startDate: 32,
  endDate: 32,
  description: 1024,
};

export const experienceValidationSchema = Yup.object({
  experiences: Yup.array().of(
    Yup.object({
      title: Yup.string()
        .required('Title is required')
        .max(
          EXPERIENCE_CHARACTER_LIMITS.title,
          `Title cannot exceed ${EXPERIENCE_CHARACTER_LIMITS.title} characters`,
        ),
      company: Yup.string()
        .required('Company is required')
        .max(
          EXPERIENCE_CHARACTER_LIMITS.company,
          `Company cannot exceed ${EXPERIENCE_CHARACTER_LIMITS.company} characters`,
        ),
      startDate: Yup.string().max(
        EXPERIENCE_CHARACTER_LIMITS.startDate,
        `Start Date cannot exceed ${EXPERIENCE_CHARACTER_LIMITS.startDate} characters`,
      ),
      endDate: Yup.string().max(
        EXPERIENCE_CHARACTER_LIMITS.endDate,
        `End Date cannot exceed ${EXPERIENCE_CHARACTER_LIMITS.endDate} characters`,
      ),
      description: Yup.string().max(
        EXPERIENCE_CHARACTER_LIMITS.description,
        `Description cannot exceed ${EXPERIENCE_CHARACTER_LIMITS.description} characters`,
      ),
    }),
  ),
});

export const EDUCATION_CHARACTER_LIMITS = {
  schoolName: 256,
  degree: 128,
  fieldOfStudy: 128,
  startDate: 32,
  endDate: 32,
};

export const educationValidationSchema = Yup.object({
  education: Yup.array().of(
    Yup.object({
      school_name: Yup.string()
        .required('School Name is required')
        .max(
          EDUCATION_CHARACTER_LIMITS.schoolName,
          `School Name cannot exceed ${EDUCATION_CHARACTER_LIMITS.schoolName} characters`,
        ),
      degree: Yup.string()
        .required('Degree is required')
        .max(
          EDUCATION_CHARACTER_LIMITS.degree,
          `Degree cannot exceed ${EDUCATION_CHARACTER_LIMITS.degree} characters`,
        ),
      field_of_study: Yup.string()
        .required('Field of Study is required')
        .max(
          EDUCATION_CHARACTER_LIMITS.fieldOfStudy,
          `Field of Study cannot exceed ${EDUCATION_CHARACTER_LIMITS.fieldOfStudy} characters`,
        ),
      start_date: Yup.string().max(
        EDUCATION_CHARACTER_LIMITS.startDate,
        `Start Date cannot exceed ${EDUCATION_CHARACTER_LIMITS.startDate} characters`,
      ),
      end_date: Yup.string().max(
        EDUCATION_CHARACTER_LIMITS.endDate,
        `End Date cannot exceed ${EDUCATION_CHARACTER_LIMITS.endDate} characters`,
      ),
    }),
  ),
});
