import React from 'react';
import { Box } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { useGetPlatform } from '@juno/client-api';
import { FeatureConfig as FeatureConfigModel } from '@juno/client-api/model';
import { GlobalFeed } from '@juno/modules';
import { GlobalForum } from '@juno/modules';
import { Juno404 } from '@juno/ui';
import { useSettings } from '@juno/utils';
import CommunityGroupPanel from '../admin/CommunityGroupPanel';
import CommunityGroupsPanel from '../admin/CommunityGroupsPanel';
import GroupPage from './GroupPage';
import GroupsPage from './GroupsPage';

export interface JunoGroupsProps {
  siteId: string;
  configs: FeatureConfigModel[];
}

export interface OnNavigateProps {
  key: string;
  params: { [key: string]: string };
  query?: string;
}

export interface PreferredTag {
  tag_id: string;
  tag_name: string;
  tag_type: string;
}
declare global {
  interface Window {
    App: any;
  }
}

const JunoGroups: React.FC<JunoGroupsProps> = ({ siteId, configs }) => {
  const settings = useSettings();
  const { site: siteData, user: userData } = settings;
  const { data: platformData } = useGetPlatform(siteData?.platform_id || '');

  if (!userData || !siteData || !platformData) return <></>;

  const pathPrefix =
    (settings.isApp ? '/app.html' : '') +
    (settings.sitePath && settings.sitePath.length > 0 ? `/${settings.sitePath}` : '');

  return (
    <Routes>
      <Route
        path={`${pathPrefix}/community-admin/groups`}
        element={
          <Box sx={{ width: 1020, maxWidth: '90%', ml: 'auto', mr: 'auto' }}>
            <CommunityGroupsPanel site={siteData} />
          </Box>
        }
      />
      <Route
        path={`${pathPrefix}/community-admin/:groupSlug`}
        element={
          <Box sx={{ width: 1020, maxWidth: '90%', ml: 'auto', mr: 'auto' }}>
            <CommunityGroupPanel site={siteData} />
          </Box>
        }
      />
      <Route path={'*'} element={<Juno404 />} />
      <Route
        path={`${pathPrefix}/community/:groupsSlug`}
        element={
          <GroupsPage
            site={siteData}
            settings={settings}
            platform={platformData}
            user={userData}
            configs={configs}
          />
        }
      />
      <Route
        path={`${pathPrefix}/groups/:groupSlug`}
        element={<GroupPage siteId={siteId} platformId={platformData.id} />}
      />
      <Route
        path={`${pathPrefix}/:groupsSlug`}
        element={
          <GroupsPage
            site={siteData}
            settings={settings}
            platform={platformData}
            user={userData}
            configs={configs}
          />
        }
      />
      <Route
        path={`${pathPrefix}/global_feed`}
        element={<GlobalFeed siteId={siteData.id} title={''} />}
      />
      <Route
        path={`${pathPrefix}/global_forum`}
        element={<GlobalForum siteId={siteData.id} title={''} />}
      />
    </Routes>
  );
};

const WrappedJunoGroups: React.FC<JunoGroupsProps> = (props) => {
  const { siteId, configs } = props;

  return <JunoGroups siteId={siteId} configs={configs} />;
};

export default WrappedJunoGroups;
