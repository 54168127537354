import { ReactElement, ReactNode } from 'react';
import dayjs from 'dayjs';
import { CourseFilterContextProps } from '@juno/ui';
import { CHIP_DATE_FORMAT } from './constants';

export const getHandleDeleteFilter = (
  courseFilterContext: CourseFilterContextProps,
  setDateStartAfterChipLabel: (label: string) => void,
  setDateStartBeforeChipLabel: (label: string) => void,
  onFilter?: (filter: string) => void,
) => {
  return (filter: string) => {
    switch (filter) {
      case 'date_start__gte':
        onFilter && onFilter('filter');
        courseFilterContext.handleDateUpdate(null, 'date_start__gte');
        setDateStartAfterChipLabel('');
        break;
      case 'date_start__lte':
        onFilter && onFilter('filter');
        courseFilterContext.handleDateUpdate(null, 'date_start__lte');
        setDateStartBeforeChipLabel('');
        break;
      default:
        return;
    }
  };
};

export const getHandleUpdateDateChip = (
  courseFilterContext: CourseFilterContextProps,
  filterComponent: ReactNode,
  onFilter?: (filter: string) => void,
) => {
  return (filterParam: string, setChipLabel: (value: string) => void, isAfter: boolean) => {
    const courseFilters = (filterComponent as ReactElement).props.values;
    if (Object.keys(courseFilters).includes(filterParam)) {
      const formattedDate = dayjs(courseFilterContext.tempFilter[filterParam]).format(
        CHIP_DATE_FORMAT,
      );
      const specialCharacter = isAfter ? '\u2265' : '\u2264';
      setChipLabel(`Start ${specialCharacter} ` + formattedDate);
      onFilter && onFilter('filter');
    } else {
      setChipLabel('');
      onFilter && onFilter('filter');
    }
  };
};
