import React, { useContext, useEffect, useState } from 'react';
import { Box, Skeleton, Typography } from '@mui/material';
import { useGetAllUsers } from '@juno/client-api';
import { Channel, useMessengerContext, useSettings } from '@juno/utils';
import { PlatformContext } from '../../Contexts/PlatformContext';
import { ConversationsRowProps } from '../Conversations/ConversationsRow';
import { SearchResult } from './SearchResult';

interface SearchResultsProps {
  searchValue: string;
}

export const SearchResults: React.FC<SearchResultsProps> = ({ searchValue }) => {
  const platformContext = useContext(PlatformContext);
  const { user } = useSettings();
  const [filteredChannels, setFilteredChannels] = useState<ConversationsRowProps[]>([]);
  const { messengerMap } = useMessengerContext();

  const searchParams = searchValue
    ? {
        first_name__icontains: searchValue,
        last_name__icontains: searchValue,
        email__icontains: searchValue,
      }
    : {};

  const { data: usersData, isLoading } = useGetAllUsers(
    platformContext?.platform?.id || '',
    {
      filter_or: searchParams,
      exclude_or: {
        settings__private_messaging: true,
      },
      limit: 10,
      offset: 0,
      include: 'settings',
    },
    {
      query: {
        enabled: !!platformContext?.platform?.id && !!searchValue && searchValue.trim().length > 0,
      },
    },
  );

  useEffect(() => {
    // Convert channelToChannelMetadataMap to array and sort by channelUpdated
    const sortedChannels = Object.values(messengerMap.channels).filter(
      (channel) =>
        !channel.id.includes('system') &&
        channel.messages.length > 0 &&
        channel.junoUsers.length > 1,
    );

    let filteredChannels: Channel[];

    if (searchValue.trim().length === 0) {
      // If no search value, use all sortedChannels
      filteredChannels = [...sortedChannels];
    } else {
      // If there's a search value, filter channels where any junoUser's first_name, last_name, or email includes the search value
      const searchValueLower = searchValue.trim().toLowerCase();
      filteredChannels = sortedChannels.filter((channel) =>
        channel.junoUsers.some((junoUser) =>
          [junoUser.first_name, junoUser.last_name, junoUser.email].some((value) =>
            value.toLowerCase().includes(searchValueLower),
          ),
        ),
      );
    }

    const filteredChannelsProps = filteredChannels.map((channel) => {
      const memberNames = channel.junoUsers
        ?.map((junoUser) => `${junoUser?.first_name} ${junoUser?.last_name}` || 'Unknown')
        .join(', ');
      const lastMessage = channel.messages[0]; // Assuming this is correctly fetching the last message
      const text = lastMessage ? lastMessage.text : '';
      const createdAt = lastMessage?.createdAt || 'UNKNOWN_TIME'; // Assuming UNKNOWN_TIME is defined elsewhere

      return {
        channelId: channel.id,
        channelName: channel.name,
        channelMembers: memberNames,
        lastChannelMessage: text,
        createdAt: createdAt,
        avatarSrc: '',
        unreadCount: 0, // Assuming this is calculated or set elsewhere
      };
    });

    setFilteredChannels(filteredChannelsProps);
  }, [searchValue, messengerMap.channels]);

  if (isLoading) return <Skeleton variant='rectangular' animation='wave' height={100} />;

  return (
    <Box sx={{ maxHeight: 500, overflow: 'auto' }}>
      {filteredChannels && filteredChannels.length > 0 && (
        <Box>
          <Typography variant='subtitle1' sx={{ ml: 2, textAlign: 'center' }}>
            Recents
          </Typography>
          {filteredChannels.map((channel) => (
            <SearchResult
              key={channel.channelId}
              channelId={channel.channelId}
              channelName={channel.channelMembers}
            />
          ))}
        </Box>
      )}

      {/* Only show the People section if there are results in usersData */}
      {usersData && usersData.length > 0 && !!searchValue && searchValue.trim().length > 0 && (
        <>
          <Typography variant='subtitle1' sx={{ ml: 2, textAlign: 'center' }}>
            People
          </Typography>
          {usersData
            .filter((u) => u && u.id !== user?.id)
            .map((u) => (
              <SearchResult key={u.id} searchResultUser={u} />
            ))}
        </>
      )}

      {/* If there are no results in usersData or filteredChannels, show a message */}
      {usersData && usersData.length === 0 && filteredChannels && filteredChannels.length === 0 && (
        <Typography variant='subtitle1' sx={{ textAlign: 'center' }}>
          No results found
        </Typography>
      )}
    </Box>
  );
};
