import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledFormImage = styled(Avatar)(() => ({
  width: '100%',
  height: '132px',
  objectFit: 'cover',
  overflow: 'hidden',
  boxShadow: '0px 0px 3px rgb(0 0 0 / 40%)',
}));

export const TileWrapper = styled(Box)(() => ({
  position: 'relative',
  userSelect: 'none',
  boxShadow: 'none',
  width: '100%',
}));

export const TileGridContainer = styled(Grid)(() => ({
  height: '100%',
  width: 'auto',
  borderRadius: '4px',
  cursor: 'grab',
}));

export const TypeIcon = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  fontSize: 40,
  opacity: 0.3,
  background: theme.palette.primary.main,
  borderRadius: 8,
  height: 130,
  width: 130,
  paddingBottom: 10,
  paddingRight: 10,
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  bottom: -15,
  right: -15,
}));

export const TileGridDragArea = styled(Grid)(() => ({
  width: '25px',
  height: '100%',
  alignSelf: 'center',
  boxShadow: '0px 0px 4px rgb(0 0 0 / 40%)',
  backgroundOpacity: 0.5,
  display: 'flex',
  alignItems: 'center',
  cursor: 'move',
}));

export const TileGridBanner = styled(Grid)(() => ({
  alignSelf: 'center',
}));

export const TileGridTextWrapper = styled(Grid)(() => ({
  display: 'grid',
  alignContent: 'space-between',
}));

export const BottomIndicatorsWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
}));

export const EllipsisTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'width',
})<{
  width: number;
}>(({ width }) => ({
  width,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

export const SettingsButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 7,
  right: 120,
}));

export const DeleteButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 7,
  right: 160,
  opacity: 0.5,
}));
