/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  Admin,
  GetAdminsParams,
  Client,
  GetClientsParams,
  GetClientParams,
  DeleteResponse,
  Platform,
  GetClientPlatformsParams,
  GrowthZoneSubdomainAvailable,
  GrowthZoneSubdomain,
  GrowthZonePlatform,
  MapBoxLocation,
  NotAuthenticated,
  GetPlatformParams,
  FeatureConfig,
  DeletePlatformFeatureConfigParams,
  FeatureConfigExists,
  JunoUser,
  GetMeParams,
  GetLearnerUsersParams,
  UserRecommendation,
  GetMyRecommendedUsersParams,
  Site,
  GetPlatformSitesParams,
  GetSiteBySlugParams,
  GetAllUsersParams,
  GetUserParams,
  GetSiteBySlug2Params,
  GetPlatformBySubdomainParams,
  GetScormCompleteLanding200,
  AdminSessionMonitoring,
  GetAdminSessionMonitoringParams,
  GetSiteParams,
  AccessPass,
  GetAccessPassesParams,
  GetAccessPassParams,
  AdminGroup,
  GetAdminGroupsParams,
  GetAdminGroupParams,
  AISuggestionRecord,
  GetAISuggestionRecordParams,
  AllClientUnsubscribes,
  AllUserUnsubscribeGroups,
  Activity,
  Aggregate,
  GetAnalyticsActivitiesAggregateParams,
  GetAnalyticsActivitiesGraph200,
  GetAnalyticsActivitiesGraphParams,
  Report,
  GetAnalyticsActivitiesReportParams,
  CertificateTemplate,
  Cohort,
  GetAllCohortsParams,
  GetCohortParams,
  ForumTopic,
  GetAllForumTopicsParams,
  Thread,
  GetGlobalFeedAnnouncementsParams,
  GetFeedThreadsParams,
  GetGlobalForumAnnouncementsParams,
  GetForumTopicsParams,
  FullThread,
  GetForumTopicThreadsParams,
  CommunityGroup,
  GetCommunityGroupsHierarchyParams,
  GetCommunityGroupsParams,
  CommunityUser,
  GetCommunityGroupAdminsParams,
  GetGroupAnnouncementsParams,
  Feed,
  GetCommunityGroupFeedParams,
  Forum,
  GetCommunityGroupForumParams,
  GetCommunityGroupMembersParams,
  GetCommunityGroupPendingMembersParams,
  GroupResource,
  GetGroupResourcesParams,
  GetGroupResourceParams,
  GetAllThreadsParams,
  GetChildThreadsParams,
  Component,
  GetComponentsParams,
  GetComponentParams,
  ContentReportWithUsers,
  GetContentReportParams,
  ContentReport,
  GetContentReportsParams,
  CreateContentReportParams,
  BatchUpdateResponse,
  BatchUpdateContentReportsParams,
  ContentReportsCount,
  GetContentReportsCountParams,
  ContentUrl,
  GetContentUrlParams,
  CourseRequirement,
  GetAllCourseRequirementsParams,
  GetCourseRequirementParams,
  CourseResource,
  GetCourseResourceParams,
  Course,
  GetCoursesParams,
  GetCourseParams,
  UserCourseRequirementSummary,
  CloneCourse,
  CompleteCourse,
  CompleteCourseAdminRequest,
  GetCourseResourcesParams,
  EducationCredit,
  GetCourseEducationCreditsParams,
  Enrollment,
  GetCourseEnrollmentsParams,
  GetEnrollmentParams,
  GetCourseFeedParams,
  Lesson,
  GetCourseLessonsParams,
  LessonCompletion,
  UserAnswer,
  GetLessonCompletionsParams,
  GetLessonCompletionParams,
  LessonPart,
  GetLessonPartsParams,
  GetLessonPartParams,
  GetLessonPartResourcesParams,
  Question,
  GetLessonQuestionsParams,
  GetLessonQuestionParams,
  AnswerSummary,
  Answer,
  GetQuestionAnswersParams,
  AnswerImporter,
  GetQuestionAnswerParams,
  GetUserLessonCompletionsParams,
  ScormRegistrationLinkResponse,
  NotAllowed,
  ScormRegistrationLinkRequest,
  ScormCourseUpload,
  GetUserCourseLessonCompletionsParams,
  WaitListedEnrollment,
  GetWaitListedEnrollmentsParams,
  GetWaitListedEnrollmentParams,
  UpdateWaitListOrderBodyOne,
  UpdateWaitListOrderBodyTwo,
  UpdateWaitListOrderBodyThree,
  Download,
  UserEducationCredit,
  GetDownloadsParams,
  GetDownloadParams,
  GetAllEducationCreditsParams,
  EducationCreditRequirement,
  GetAllEducationCreditRequirementsParams,
  EmailRecord,
  GetAllEmailAnalyticsRecordsParams,
  EmailCampaign,
  Template,
  TemplateHTML,
  GetEnrollmentsParams,
  EventPass,
  GetAllEventPassesParams,
  GetEventPassParams,
  GetFeatureConfigsParams,
  DeleteSiteFeatureConfigParams,
  GetFeatureConfigsPublicParams,
  GetSiteFeedParams,
  GetSiteForumParams,
  GeneratedReport,
  GetAllGeneratedReportsParams,
  CreateGeneratedReportParams,
  GetGroupContentReportsParams,
  ImportRecord,
  LanguageConfig,
  LearningSummary,
  GetLearningDashboardSummaryParams,
  LibraryEntry,
  GetAllLibraryEntriesParams,
  GetLibraryEntryParams,
  UserPreview,
  GetMyContentReportsParams,
  GetMyEnrollmentsParams,
  SavedReport,
  GetMySavedReportsParams,
  CreateMySavedReportParams,
  GetSavedUsersParams,
  NavigationItem,
  GetNavigationItemsParams,
  GetNavigationItemParams,
  NotificationPreferences,
  Notification,
  GetAllNotificationsParams,
  DeleteNotifications200,
  NotificationIds,
  MarkNotificationsAsRead200,
  OpenAIRequest,
  Page,
  GetPagesParams,
  GetPageParams,
  AddPageComponent200,
  PageComponent,
  Partner,
  GetAllPartnersParams,
  GetPartnerParams,
  GetPartnerUsersParams,
  CreatePartnerUser,
  PresignedUrl,
  GetPresignedUrlForS3UrlParams,
  PriceLevel,
  GetPriceLevelsParams,
  Product,
  GetProductsParams,
  ProductPrice,
  GetProductPricesParams,
  UserPurchase,
  GetAllUserPurchasesParams,
  QuickLink,
  GetQuickLinksParams,
  GetQuickLinkParams,
  SearchContent,
  SearchContentParams,
  SendingEmail,
  Session,
  GetAllSessionsParams,
  GetSessionParams,
  GetSessionActiveParticipants200,
  GetSessionActiveParticipantsParams,
  GetSessionArchives200,
  GetSessionArchivesParams,
  SessionAttendee,
  BatchAttendUnattendSession200,
  JoinSession,
  SessionParticipant,
  GetSessionParticipantsParams,
  GetRoomPresence200,
  GetRoomsPresence200,
  SessionMedia,
  GetSessionMediaParams,
  SessionParent,
  SessionSubBreakout,
  GetSessionSubBreakoutsParams,
  UpdateSessionSubBreakout200,
  EndBreakoutMessage,
  SessionBackground,
  GetSessionBackgroundsParams,
  SidebarWidget,
  GetSidebarWidgetsParams,
  Speaker,
  GetAllSpeakersParams,
  GetSpeakerParams,
  GetSpeakerSessionsParams,
  TagType,
  GetAllTagTypesParams,
  GetTagTypeParams,
  Tag,
  GetAllTagsParams,
  GetTagParams,
  UnsubscribeGroup,
  GetSiteUnsubscribeGroupsParams,
  UnsubscribesSearch,
  GroupUnsubscribes,
  AddUnsubscribeGroupUnsubscribes,
  RemoveUnsubscribe,
  BlockUserResponse,
  UserEducation,
  GetUserEducationParams,
  GetAllUserEnrollmentsParams,
  UserExperience,
  GetUserExperienceParams,
  GetPurchaseLinkParams,
  GetUserPurchasesParams,
  UserCount,
  GetUserCountParams,
  UserSettings,
  GetUserSettingsParams,
  GetMyWaitlistedEnrollmentsParams
} from './model'
import { localInstance } from '../mutator/index'

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


/**
 * View for getting a list of ALL admin users
 */
export const getAdmins = (
    params?: GetAdminsParams,
 signal?: AbortSignal
) => {
      return localInstance<Admin[]>(
      {url: `/admins`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAdminsQueryKey = (params?: GetAdminsParams,) => [`/admins`, ...(params ? [params]: [])];

    
export type GetAdminsQueryResult = NonNullable<Awaited<ReturnType<typeof getAdmins>>>
export type GetAdminsQueryError = unknown

export const useGetAdmins = <TData = Awaited<ReturnType<typeof getAdmins>>, TError = unknown>(
 params?: GetAdminsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAdmins>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAdminsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdmins>>> = ({ signal }) => getAdmins(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAdmins>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns all Clients
 */
export const getClients = (
    params?: GetClientsParams,
 signal?: AbortSignal
) => {
      return localInstance<Client[]>(
      {url: `/clients`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetClientsQueryKey = (params?: GetClientsParams,) => [`/clients`, ...(params ? [params]: [])];

    
export type GetClientsQueryResult = NonNullable<Awaited<ReturnType<typeof getClients>>>
export type GetClientsQueryError = unknown

export const useGetClients = <TData = Awaited<ReturnType<typeof getClients>>, TError = unknown>(
 params?: GetClientsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClients>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetClientsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClients>>> = ({ signal }) => getClients(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getClients>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Client
 */
export const createClient = (
    client: Client,
 
) => {
      return localInstance<Client>(
      {url: `/clients`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: client
    },
      );
    }
  


    export type CreateClientMutationResult = NonNullable<Awaited<ReturnType<typeof createClient>>>
    export type CreateClientMutationBody = Client
    export type CreateClientMutationError = unknown

    export const useCreateClient = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createClient>>, TError,{data: Client}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createClient>>, {data: Client}> = (props) => {
          const {data} = props ?? {};

          return  createClient(data,)
        }

      return useMutation<Awaited<ReturnType<typeof createClient>>, TError, {data: Client}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Client
 */
export const getClient = (
    clientId: string,
    params?: GetClientParams,
 signal?: AbortSignal
) => {
      return localInstance<Client>(
      {url: `/clients/${clientId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetClientQueryKey = (clientId: string,
    params?: GetClientParams,) => [`/clients/${clientId}`, ...(params ? [params]: [])];

    
export type GetClientQueryResult = NonNullable<Awaited<ReturnType<typeof getClient>>>
export type GetClientQueryError = unknown

export const useGetClient = <TData = Awaited<ReturnType<typeof getClient>>, TError = unknown>(
 clientId: string,
    params?: GetClientParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClient>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetClientQueryKey(clientId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClient>>> = ({ signal }) => getClient(clientId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getClient>>, TError, TData>(queryKey, queryFn, {enabled: !!(clientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single Client
 */
export const updateClient = (
    clientId: string,
    client: Client,
 
) => {
      return localInstance<Client>(
      {url: `/clients/${clientId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: client
    },
      );
    }
  


    export type UpdateClientMutationResult = NonNullable<Awaited<ReturnType<typeof updateClient>>>
    export type UpdateClientMutationBody = Client
    export type UpdateClientMutationError = unknown

    export const useUpdateClient = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateClient>>, TError,{clientId: string;data: Client}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateClient>>, {clientId: string;data: Client}> = (props) => {
          const {clientId,data} = props ?? {};

          return  updateClient(clientId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateClient>>, TError, {clientId: string;data: Client}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Client
 */
export const deleteClient = (
    clientId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/clients/${clientId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteClientMutationResult = NonNullable<Awaited<ReturnType<typeof deleteClient>>>
    
    export type DeleteClientMutationError = unknown

    export const useDeleteClient = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteClient>>, TError,{clientId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteClient>>, {clientId: string}> = (props) => {
          const {clientId} = props ?? {};

          return  deleteClient(clientId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteClient>>, TError, {clientId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all the Platforms for the given Client
 */
export const getClientPlatforms = (
    clientId: string,
    params?: GetClientPlatformsParams,
 signal?: AbortSignal
) => {
      return localInstance<Platform[]>(
      {url: `/clients/${clientId}/platforms`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetClientPlatformsQueryKey = (clientId: string,
    params?: GetClientPlatformsParams,) => [`/clients/${clientId}/platforms`, ...(params ? [params]: [])];

    
export type GetClientPlatformsQueryResult = NonNullable<Awaited<ReturnType<typeof getClientPlatforms>>>
export type GetClientPlatformsQueryError = unknown

export const useGetClientPlatforms = <TData = Awaited<ReturnType<typeof getClientPlatforms>>, TError = unknown>(
 clientId: string,
    params?: GetClientPlatformsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getClientPlatforms>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetClientPlatformsQueryKey(clientId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getClientPlatforms>>> = ({ signal }) => getClientPlatforms(clientId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getClientPlatforms>>, TError, TData>(queryKey, queryFn, {enabled: !!(clientId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Platform for the given Client
 */
export const createPlatform = (
    clientId: string,
    platform: Platform,
 
) => {
      return localInstance<Platform>(
      {url: `/clients/${clientId}/platforms`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: platform
    },
      );
    }
  


    export type CreatePlatformMutationResult = NonNullable<Awaited<ReturnType<typeof createPlatform>>>
    export type CreatePlatformMutationBody = Platform
    export type CreatePlatformMutationError = unknown

    export const useCreatePlatform = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPlatform>>, TError,{clientId: string;data: Platform}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPlatform>>, {clientId: string;data: Platform}> = (props) => {
          const {clientId,data} = props ?? {};

          return  createPlatform(clientId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createPlatform>>, TError, {clientId: string;data: Platform}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Check and return availability of subdomain
 */
export const growthZoneCheckSubdomainAvailable = (
    growthZoneSubdomain: GrowthZoneSubdomain,
 
) => {
      return localInstance<GrowthZoneSubdomainAvailable>(
      {url: `/growthzone_integration/check_subdomain_availability`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: growthZoneSubdomain
    },
      );
    }
  


    export type GrowthZoneCheckSubdomainAvailableMutationResult = NonNullable<Awaited<ReturnType<typeof growthZoneCheckSubdomainAvailable>>>
    export type GrowthZoneCheckSubdomainAvailableMutationBody = GrowthZoneSubdomain
    export type GrowthZoneCheckSubdomainAvailableMutationError = unknown

    export const useGrowthZoneCheckSubdomainAvailable = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof growthZoneCheckSubdomainAvailable>>, TError,{data: GrowthZoneSubdomain}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof growthZoneCheckSubdomainAvailable>>, {data: GrowthZoneSubdomain}> = (props) => {
          const {data} = props ?? {};

          return  growthZoneCheckSubdomainAvailable(data,)
        }

      return useMutation<Awaited<ReturnType<typeof growthZoneCheckSubdomainAvailable>>, TError, {data: GrowthZoneSubdomain}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Creates a new Client, Platform, and Site
 */
export const createGrowthZonePlatform = (
    growthZonePlatform: GrowthZonePlatform,
 
) => {
      return localInstance<Platform>(
      {url: `/growthzone_integration/create_platform`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: growthZonePlatform
    },
      );
    }
  


    export type CreateGrowthZonePlatformMutationResult = NonNullable<Awaited<ReturnType<typeof createGrowthZonePlatform>>>
    export type CreateGrowthZonePlatformMutationBody = GrowthZonePlatform
    export type CreateGrowthZonePlatformMutationError = unknown

    export const useCreateGrowthZonePlatform = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGrowthZonePlatform>>, TError,{data: GrowthZonePlatform}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGrowthZonePlatform>>, {data: GrowthZonePlatform}> = (props) => {
          const {data} = props ?? {};

          return  createGrowthZonePlatform(data,)
        }

      return useMutation<Awaited<ReturnType<typeof createGrowthZonePlatform>>, TError, {data: GrowthZonePlatform}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of locations from the MapBox API
 */
export const mapboxApi = (
    searchText: string,
 signal?: AbortSignal
) => {
      return localInstance<MapBoxLocation[]>(
      {url: `/mapbox/${searchText}`, method: 'get', signal
    },
      );
    }
  

export const getMapboxApiQueryKey = (searchText: string,) => [`/mapbox/${searchText}`];

    
export type MapboxApiQueryResult = NonNullable<Awaited<ReturnType<typeof mapboxApi>>>
export type MapboxApiQueryError = unknown

export const useMapboxApi = <TData = Awaited<ReturnType<typeof mapboxApi>>, TError = unknown>(
 searchText: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof mapboxApi>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getMapboxApiQueryKey(searchText);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof mapboxApi>>> = ({ signal }) => mapboxApi(searchText, signal);

  const query = useQuery<Awaited<ReturnType<typeof mapboxApi>>, TError, TData>(queryKey, queryFn, {enabled: !!(searchText), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Simple ass view for getting info about the current user
 */
export const getAdminMe = (
    
 signal?: AbortSignal
) => {
      return localInstance<Admin>(
      {url: `/me`, method: 'get', signal
    },
      );
    }
  

export const getGetAdminMeQueryKey = () => [`/me`];

    
export type GetAdminMeQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminMe>>>
export type GetAdminMeQueryError = NotAuthenticated

export const useGetAdminMe = <TData = Awaited<ReturnType<typeof getAdminMe>>, TError = NotAuthenticated>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAdminMe>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAdminMeQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminMe>>> = ({ signal }) => getAdminMe(signal);

  const query = useQuery<Awaited<ReturnType<typeof getAdminMe>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * Get the public API docs open api specification
 */
export const openapiJsonRetrieve = (
    
 signal?: AbortSignal
) => {
      return localInstance<void>(
      {url: `/openapi.json`, method: 'get', signal
    },
      );
    }
  

export const getOpenapiJsonRetrieveQueryKey = () => [`/openapi.json`];

    
export type OpenapiJsonRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof openapiJsonRetrieve>>>
export type OpenapiJsonRetrieveQueryError = unknown

export const useOpenapiJsonRetrieve = <TData = Awaited<ReturnType<typeof openapiJsonRetrieve>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof openapiJsonRetrieve>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getOpenapiJsonRetrieveQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof openapiJsonRetrieve>>> = ({ signal }) => openapiJsonRetrieve(signal);

  const query = useQuery<Awaited<ReturnType<typeof openapiJsonRetrieve>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single Platform
 */
export const getPlatform = (
    platformId: string,
    params?: GetPlatformParams,
 signal?: AbortSignal
) => {
      return localInstance<Platform>(
      {url: `/platforms/${platformId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetPlatformQueryKey = (platformId: string,
    params?: GetPlatformParams,) => [`/platforms/${platformId}`, ...(params ? [params]: [])];

    
export type GetPlatformQueryResult = NonNullable<Awaited<ReturnType<typeof getPlatform>>>
export type GetPlatformQueryError = unknown

export const useGetPlatform = <TData = Awaited<ReturnType<typeof getPlatform>>, TError = unknown>(
 platformId: string,
    params?: GetPlatformParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPlatform>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPlatformQueryKey(platformId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlatform>>> = ({ signal }) => getPlatform(platformId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPlatform>>, TError, TData>(queryKey, queryFn, {enabled: !!(platformId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single Platform
 */
export const updatePlatform = (
    platformId: string,
    platform: Platform,
 
) => {
      return localInstance<Platform>(
      {url: `/platforms/${platformId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: platform
    },
      );
    }
  


    export type UpdatePlatformMutationResult = NonNullable<Awaited<ReturnType<typeof updatePlatform>>>
    export type UpdatePlatformMutationBody = Platform
    export type UpdatePlatformMutationError = unknown

    export const useUpdatePlatform = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePlatform>>, TError,{platformId: string;data: Platform}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePlatform>>, {platformId: string;data: Platform}> = (props) => {
          const {platformId,data} = props ?? {};

          return  updatePlatform(platformId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updatePlatform>>, TError, {platformId: string;data: Platform}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Platform
 */
export const deletePlatform = (
    platformId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/platforms/${platformId}`, method: 'delete'
    },
      );
    }
  


    export type DeletePlatformMutationResult = NonNullable<Awaited<ReturnType<typeof deletePlatform>>>
    
    export type DeletePlatformMutationError = unknown

    export const useDeletePlatform = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePlatform>>, TError,{platformId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePlatform>>, {platformId: string}> = (props) => {
          const {platformId} = props ?? {};

          return  deletePlatform(platformId,)
        }

      return useMutation<Awaited<ReturnType<typeof deletePlatform>>, TError, {platformId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Creates a new SendGrid subuser account for the new Juno client
 */
export const addSendGridSubuser = (
    platformId: string,
    subuserName: string,
 
) => {
      return localInstance<void>(
      {url: `/platforms/${platformId}/add_subuser/${subuserName}`, method: 'post'
    },
      );
    }
  


    export type AddSendGridSubuserMutationResult = NonNullable<Awaited<ReturnType<typeof addSendGridSubuser>>>
    
    export type AddSendGridSubuserMutationError = unknown

    export const useAddSendGridSubuser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addSendGridSubuser>>, TError,{platformId: string;subuserName: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addSendGridSubuser>>, {platformId: string;subuserName: string}> = (props) => {
          const {platformId,subuserName} = props ?? {};

          return  addSendGridSubuser(platformId,subuserName,)
        }

      return useMutation<Awaited<ReturnType<typeof addSendGridSubuser>>, TError, {platformId: string;subuserName: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Platform-level FeatureConfig object for a Client
 */
export const getPlatformFeatureConfig = (
    platformId: string,
    configType: string,
 signal?: AbortSignal
) => {
      return localInstance<FeatureConfig>(
      {url: `/platforms/${platformId}/feature_configs/${configType}`, method: 'get', signal
    },
      );
    }
  

export const getGetPlatformFeatureConfigQueryKey = (platformId: string,
    configType: string,) => [`/platforms/${platformId}/feature_configs/${configType}`];

    
export type GetPlatformFeatureConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getPlatformFeatureConfig>>>
export type GetPlatformFeatureConfigQueryError = unknown

export const useGetPlatformFeatureConfig = <TData = Awaited<ReturnType<typeof getPlatformFeatureConfig>>, TError = unknown>(
 platformId: string,
    configType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPlatformFeatureConfig>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPlatformFeatureConfigQueryKey(platformId,configType);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlatformFeatureConfig>>> = ({ signal }) => getPlatformFeatureConfig(platformId,configType, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPlatformFeatureConfig>>, TError, TData>(queryKey, queryFn, {enabled: !!(platformId && configType), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single Platform-level FeatureConfig object for a Client
 */
export const updatePlatformFeatureConfig = (
    platformId: string,
    configType: string,
    featureConfig: FeatureConfig,
 
) => {
      return localInstance<FeatureConfig>(
      {url: `/platforms/${platformId}/feature_configs/${configType}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: featureConfig
    },
      );
    }
  


    export type UpdatePlatformFeatureConfigMutationResult = NonNullable<Awaited<ReturnType<typeof updatePlatformFeatureConfig>>>
    export type UpdatePlatformFeatureConfigMutationBody = FeatureConfig
    export type UpdatePlatformFeatureConfigMutationError = unknown

    export const useUpdatePlatformFeatureConfig = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePlatformFeatureConfig>>, TError,{platformId: string;configType: string;data: FeatureConfig}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePlatformFeatureConfig>>, {platformId: string;configType: string;data: FeatureConfig}> = (props) => {
          const {platformId,configType,data} = props ?? {};

          return  updatePlatformFeatureConfig(platformId,configType,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updatePlatformFeatureConfig>>, TError, {platformId: string;configType: string;data: FeatureConfig}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Platform-level FeatureConfig object for a Client
 */
export const deletePlatformFeatureConfig = (
    platformId: string,
    configType: string,
    params?: DeletePlatformFeatureConfigParams,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/platforms/${platformId}/feature_configs/${configType}`, method: 'delete',
        params,
    },
      );
    }
  


    export type DeletePlatformFeatureConfigMutationResult = NonNullable<Awaited<ReturnType<typeof deletePlatformFeatureConfig>>>
    
    export type DeletePlatformFeatureConfigMutationError = unknown

    export const useDeletePlatformFeatureConfig = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePlatformFeatureConfig>>, TError,{platformId: string;configType: string;params?: DeletePlatformFeatureConfigParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePlatformFeatureConfig>>, {platformId: string;configType: string;params?: DeletePlatformFeatureConfigParams}> = (props) => {
          const {platformId,configType,params} = props ?? {};

          return  deletePlatformFeatureConfig(platformId,configType,params,)
        }

      return useMutation<Awaited<ReturnType<typeof deletePlatformFeatureConfig>>, TError, {platformId: string;configType: string;params?: DeletePlatformFeatureConfigParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for checking if a single Platform-level FeatureConfig object exists for a Client
Allows users to check if a feature is enabled for a platform
 */
export const getPlatformFeatureConfigExists = (
    platformId: string,
    configType: string,
 signal?: AbortSignal
) => {
      return localInstance<FeatureConfigExists>(
      {url: `/platforms/${platformId}/feature_configs/${configType}/exists`, method: 'get', signal
    },
      );
    }
  

export const getGetPlatformFeatureConfigExistsQueryKey = (platformId: string,
    configType: string,) => [`/platforms/${platformId}/feature_configs/${configType}/exists`];

    
export type GetPlatformFeatureConfigExistsQueryResult = NonNullable<Awaited<ReturnType<typeof getPlatformFeatureConfigExists>>>
export type GetPlatformFeatureConfigExistsQueryError = unknown

export const useGetPlatformFeatureConfigExists = <TData = Awaited<ReturnType<typeof getPlatformFeatureConfigExists>>, TError = unknown>(
 platformId: string,
    configType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPlatformFeatureConfigExists>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPlatformFeatureConfigExistsQueryKey(platformId,configType);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlatformFeatureConfigExists>>> = ({ signal }) => getPlatformFeatureConfigExists(platformId,configType, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPlatformFeatureConfigExists>>, TError, TData>(queryKey, queryFn, {enabled: !!(platformId && configType), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns the JunoUser or Admin that is making the current request
 */
export const getMe = (
    platformId: string,
    params?: GetMeParams,
 signal?: AbortSignal
) => {
      return localInstance<JunoUser>(
      {url: `/platforms/${platformId}/me`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetMeQueryKey = (platformId: string,
    params?: GetMeParams,) => [`/platforms/${platformId}/me`, ...(params ? [params]: [])];

    
export type GetMeQueryResult = NonNullable<Awaited<ReturnType<typeof getMe>>>
export type GetMeQueryError = unknown

export const useGetMe = <TData = Awaited<ReturnType<typeof getMe>>, TError = unknown>(
 platformId: string,
    params?: GetMeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMeQueryKey(platformId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMe>>> = ({ signal }) => getMe(platformId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMe>>, TError, TData>(queryKey, queryFn, {enabled: !!(platformId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns a list of all JunoUser objects for the Platform
 */
export const getLearnerUsers = (
    platformId: string,
    params?: GetLearnerUsersParams,
 signal?: AbortSignal
) => {
      return localInstance<JunoUser[]>(
      {url: `/platforms/${platformId}/myLearners`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetLearnerUsersQueryKey = (platformId: string,
    params?: GetLearnerUsersParams,) => [`/platforms/${platformId}/myLearners`, ...(params ? [params]: [])];

    
export type GetLearnerUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getLearnerUsers>>>
export type GetLearnerUsersQueryError = unknown

export const useGetLearnerUsers = <TData = Awaited<ReturnType<typeof getLearnerUsers>>, TError = unknown>(
 platformId: string,
    params?: GetLearnerUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLearnerUsers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLearnerUsersQueryKey(platformId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLearnerUsers>>> = ({ signal }) => getLearnerUsers(platformId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLearnerUsers>>, TError, TData>(queryKey, queryFn, {enabled: !!(platformId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns a list of all JunoUser objects for the Platform
 */
export const getMyRecommendedUsers = (
    platformId: string,
    params?: GetMyRecommendedUsersParams,
 signal?: AbortSignal
) => {
      return localInstance<UserRecommendation[]>(
      {url: `/platforms/${platformId}/my_recommended_users`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetMyRecommendedUsersQueryKey = (platformId: string,
    params?: GetMyRecommendedUsersParams,) => [`/platforms/${platformId}/my_recommended_users`, ...(params ? [params]: [])];

    
export type GetMyRecommendedUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getMyRecommendedUsers>>>
export type GetMyRecommendedUsersQueryError = unknown

export const useGetMyRecommendedUsers = <TData = Awaited<ReturnType<typeof getMyRecommendedUsers>>, TError = unknown>(
 platformId: string,
    params?: GetMyRecommendedUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMyRecommendedUsers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMyRecommendedUsersQueryKey(platformId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyRecommendedUsers>>> = ({ signal }) => getMyRecommendedUsers(platformId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMyRecommendedUsers>>, TError, TData>(queryKey, queryFn, {enabled: !!(platformId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Get all Sites for the given Platform
 */
export const getPlatformSites = (
    platformId: string,
    params?: GetPlatformSitesParams,
 signal?: AbortSignal
) => {
      return localInstance<Site[]>(
      {url: `/platforms/${platformId}/sites`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetPlatformSitesQueryKey = (platformId: string,
    params?: GetPlatformSitesParams,) => [`/platforms/${platformId}/sites`, ...(params ? [params]: [])];

    
export type GetPlatformSitesQueryResult = NonNullable<Awaited<ReturnType<typeof getPlatformSites>>>
export type GetPlatformSitesQueryError = unknown

export const useGetPlatformSites = <TData = Awaited<ReturnType<typeof getPlatformSites>>, TError = unknown>(
 platformId: string,
    params?: GetPlatformSitesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPlatformSites>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPlatformSitesQueryKey(platformId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlatformSites>>> = ({ signal }) => getPlatformSites(platformId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPlatformSites>>, TError, TData>(queryKey, queryFn, {enabled: !!(platformId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Create a Site on a Platform
 */
export const createSite = (
    platformId: string,
    site: Site,
 
) => {
      return localInstance<Site>(
      {url: `/platforms/${platformId}/sites`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: site
    },
      );
    }
  


    export type CreateSiteMutationResult = NonNullable<Awaited<ReturnType<typeof createSite>>>
    export type CreateSiteMutationBody = Site
    export type CreateSiteMutationError = unknown

    export const useCreateSite = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSite>>, TError,{platformId: string;data: Site}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSite>>, {platformId: string;data: Site}> = (props) => {
          const {platformId,data} = props ?? {};

          return  createSite(platformId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createSite>>, TError, {platformId: string;data: Site}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Get single Site by subdomain or platform_id
 */
export const getSiteBySlug = (
    platformId: string,
    siteSlug: string,
    params?: GetSiteBySlugParams,
 signal?: AbortSignal
) => {
      return localInstance<Site>(
      {url: `/platforms/${platformId}/sites/${siteSlug}/`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSiteBySlugQueryKey = (platformId: string,
    siteSlug: string,
    params?: GetSiteBySlugParams,) => [`/platforms/${platformId}/sites/${siteSlug}/`, ...(params ? [params]: [])];

    
export type GetSiteBySlugQueryResult = NonNullable<Awaited<ReturnType<typeof getSiteBySlug>>>
export type GetSiteBySlugQueryError = unknown

export const useGetSiteBySlug = <TData = Awaited<ReturnType<typeof getSiteBySlug>>, TError = unknown>(
 platformId: string,
    siteSlug: string,
    params?: GetSiteBySlugParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSiteBySlug>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSiteBySlugQueryKey(platformId,siteSlug,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSiteBySlug>>> = ({ signal }) => getSiteBySlug(platformId,siteSlug,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSiteBySlug>>, TError, TData>(queryKey, queryFn, {enabled: !!(platformId && siteSlug), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns a list of all JunoUser objects for the Platform
 */
export const getAllUsers = (
    platformId: string,
    params?: GetAllUsersParams,
 signal?: AbortSignal
) => {
      return localInstance<JunoUser[]>(
      {url: `/platforms/${platformId}/users`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllUsersQueryKey = (platformId: string,
    params?: GetAllUsersParams,) => [`/platforms/${platformId}/users`, ...(params ? [params]: [])];

    
export type GetAllUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getAllUsers>>>
export type GetAllUsersQueryError = unknown

export const useGetAllUsers = <TData = Awaited<ReturnType<typeof getAllUsers>>, TError = unknown>(
 platformId: string,
    params?: GetAllUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllUsersQueryKey(platformId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllUsers>>> = ({ signal }) => getAllUsers(platformId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllUsers>>, TError, TData>(queryKey, queryFn, {enabled: !!(platformId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new JunoUser for the Platform
 */
export const createUser = (
    platformId: string,
    junoUser: JunoUser,
 
) => {
      return localInstance<JunoUser>(
      {url: `/platforms/${platformId}/users`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: junoUser
    },
      );
    }
  


    export type CreateUserMutationResult = NonNullable<Awaited<ReturnType<typeof createUser>>>
    export type CreateUserMutationBody = JunoUser
    export type CreateUserMutationError = unknown

    export const useCreateUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUser>>, TError,{platformId: string;data: JunoUser}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUser>>, {platformId: string;data: JunoUser}> = (props) => {
          const {platformId,data} = props ?? {};

          return  createUser(platformId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createUser>>, TError, {platformId: string;data: JunoUser}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single JunoUser on the given Platform
 */
export const getUser = (
    platformId: string,
    userId: string,
    params?: GetUserParams,
 signal?: AbortSignal
) => {
      return localInstance<JunoUser>(
      {url: `/platforms/${platformId}/users/${userId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetUserQueryKey = (platformId: string,
    userId: string,
    params?: GetUserParams,) => [`/platforms/${platformId}/users/${userId}`, ...(params ? [params]: [])];

    
export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type GetUserQueryError = unknown

export const useGetUser = <TData = Awaited<ReturnType<typeof getUser>>, TError = unknown>(
 platformId: string,
    userId: string,
    params?: GetUserParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey(platformId,userId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) => getUser(platformId,userId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUser>>, TError, TData>(queryKey, queryFn, {enabled: !!(platformId && userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates the JunoUser on the given Platform
 */
export const updateUser = (
    platformId: string,
    userId: string,
    junoUser: JunoUser,
 
) => {
      return localInstance<JunoUser>(
      {url: `/platforms/${platformId}/users/${userId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: junoUser
    },
      );
    }
  


    export type UpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>
    export type UpdateUserMutationBody = JunoUser
    export type UpdateUserMutationError = unknown

    export const useUpdateUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUser>>, TError,{platformId: string;userId: string;data: JunoUser}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUser>>, {platformId: string;userId: string;data: JunoUser}> = (props) => {
          const {platformId,userId,data} = props ?? {};

          return  updateUser(platformId,userId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateUser>>, TError, {platformId: string;userId: string;data: JunoUser}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a JunoUser from the platform
 */
export const deleteUser = (
    platformId: string,
    userId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/platforms/${platformId}/users/${userId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUser>>>
    
    export type DeleteUserMutationError = unknown

    export const useDeleteUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUser>>, TError,{platformId: string;userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUser>>, {platformId: string;userId: string}> = (props) => {
          const {platformId,userId} = props ?? {};

          return  deleteUser(platformId,userId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteUser>>, TError, {platformId: string;userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Get single Site by subdomain or platform_id
 */
export const getSiteBySlug2 = (
    subdomain: string,
    siteSlug: string,
    params?: GetSiteBySlug2Params,
 signal?: AbortSignal
) => {
      return localInstance<Site>(
      {url: `/platforms/${subdomain}/sites/${siteSlug}/`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSiteBySlug2QueryKey = (subdomain: string,
    siteSlug: string,
    params?: GetSiteBySlug2Params,) => [`/platforms/${subdomain}/sites/${siteSlug}/`, ...(params ? [params]: [])];

    
export type GetSiteBySlug2QueryResult = NonNullable<Awaited<ReturnType<typeof getSiteBySlug2>>>
export type GetSiteBySlug2QueryError = unknown

export const useGetSiteBySlug2 = <TData = Awaited<ReturnType<typeof getSiteBySlug2>>, TError = unknown>(
 subdomain: string,
    siteSlug: string,
    params?: GetSiteBySlug2Params, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSiteBySlug2>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSiteBySlug2QueryKey(subdomain,siteSlug,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSiteBySlug2>>> = ({ signal }) => getSiteBySlug2(subdomain,siteSlug,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSiteBySlug2>>, TError, TData>(queryKey, queryFn, {enabled: !!(subdomain && siteSlug), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for fetching a Platform by its subdomain
 */
export const getPlatformBySubdomain = (
    subdomain: string,
    params?: GetPlatformBySubdomainParams,
 signal?: AbortSignal
) => {
      return localInstance<Platform>(
      {url: `/platforms/subdomain/${subdomain}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetPlatformBySubdomainQueryKey = (subdomain: string,
    params?: GetPlatformBySubdomainParams,) => [`/platforms/subdomain/${subdomain}`, ...(params ? [params]: [])];

    
export type GetPlatformBySubdomainQueryResult = NonNullable<Awaited<ReturnType<typeof getPlatformBySubdomain>>>
export type GetPlatformBySubdomainQueryError = unknown

export const useGetPlatformBySubdomain = <TData = Awaited<ReturnType<typeof getPlatformBySubdomain>>, TError = unknown>(
 subdomain: string,
    params?: GetPlatformBySubdomainParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPlatformBySubdomain>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPlatformBySubdomainQueryKey(subdomain,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPlatformBySubdomain>>> = ({ signal }) => getPlatformBySubdomain(subdomain,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPlatformBySubdomain>>, TError, TData>(queryKey, queryFn, {enabled: !!(subdomain), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const s3PresignedUrlRetrieve = (
    
 signal?: AbortSignal
) => {
      return localInstance<void>(
      {url: `/s3/presigned_url`, method: 'get', signal
    },
      );
    }
  

export const getS3PresignedUrlRetrieveQueryKey = () => [`/s3/presigned_url`];

    
export type S3PresignedUrlRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof s3PresignedUrlRetrieve>>>
export type S3PresignedUrlRetrieveQueryError = unknown

export const useS3PresignedUrlRetrieve = <TData = Awaited<ReturnType<typeof s3PresignedUrlRetrieve>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof s3PresignedUrlRetrieve>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getS3PresignedUrlRetrieveQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof s3PresignedUrlRetrieve>>> = ({ signal }) => s3PresignedUrlRetrieve(signal);

  const query = useQuery<Awaited<ReturnType<typeof s3PresignedUrlRetrieve>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * Display a simple message directing users to press the complete lesson button
 */
export const getScormCompleteLanding = (
    
 signal?: AbortSignal
) => {
      return localInstance<GetScormCompleteLanding200>(
      {url: `/scorm_course_complete`, method: 'get', signal
    },
      );
    }
  

export const getGetScormCompleteLandingQueryKey = () => [`/scorm_course_complete`];

    
export type GetScormCompleteLandingQueryResult = NonNullable<Awaited<ReturnType<typeof getScormCompleteLanding>>>
export type GetScormCompleteLandingQueryError = unknown

export const useGetScormCompleteLanding = <TData = Awaited<ReturnType<typeof getScormCompleteLanding>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getScormCompleteLanding>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetScormCompleteLandingQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getScormCompleteLanding>>> = ({ signal }) => getScormCompleteLanding(signal);

  const query = useQuery<Awaited<ReturnType<typeof getScormCompleteLanding>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * Get all Sessions for all clients for all sites for monitoring
 */
export const getAdminSessionMonitoring = (
    params?: GetAdminSessionMonitoringParams,
 signal?: AbortSignal
) => {
      return localInstance<AdminSessionMonitoring[]>(
      {url: `/session_monitoring`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAdminSessionMonitoringQueryKey = (params?: GetAdminSessionMonitoringParams,) => [`/session_monitoring`, ...(params ? [params]: [])];

    
export type GetAdminSessionMonitoringQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminSessionMonitoring>>>
export type GetAdminSessionMonitoringQueryError = unknown

export const useGetAdminSessionMonitoring = <TData = Awaited<ReturnType<typeof getAdminSessionMonitoring>>, TError = unknown>(
 params?: GetAdminSessionMonitoringParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAdminSessionMonitoring>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAdminSessionMonitoringQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminSessionMonitoring>>> = ({ signal }) => getAdminSessionMonitoring(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAdminSessionMonitoring>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


/**
 * Get single Site
 */
export const getSite = (
    siteId: string,
    params?: GetSiteParams,
 signal?: AbortSignal
) => {
      return localInstance<Site>(
      {url: `/sites/${siteId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSiteQueryKey = (siteId: string,
    params?: GetSiteParams,) => [`/sites/${siteId}`, ...(params ? [params]: [])];

    
export type GetSiteQueryResult = NonNullable<Awaited<ReturnType<typeof getSite>>>
export type GetSiteQueryError = unknown

export const useGetSite = <TData = Awaited<ReturnType<typeof getSite>>, TError = unknown>(
 siteId: string,
    params?: GetSiteParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSite>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSiteQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSite>>> = ({ signal }) => getSite(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSite>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Update single Site
 */
export const updateSite = (
    siteId: string,
    site: Site,
 
) => {
      return localInstance<Site>(
      {url: `/sites/${siteId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: site
    },
      );
    }
  


    export type UpdateSiteMutationResult = NonNullable<Awaited<ReturnType<typeof updateSite>>>
    export type UpdateSiteMutationBody = Site
    export type UpdateSiteMutationError = unknown

    export const useUpdateSite = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSite>>, TError,{siteId: string;data: Site}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSite>>, {siteId: string;data: Site}> = (props) => {
          const {siteId,data} = props ?? {};

          return  updateSite(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateSite>>, TError, {siteId: string;data: Site}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Delete single Site
 */
export const deleteSite = (
    siteId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteSiteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSite>>>
    
    export type DeleteSiteMutationError = unknown

    export const useDeleteSite = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSite>>, TError,{siteId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSite>>, {siteId: string}> = (props) => {
          const {siteId} = props ?? {};

          return  deleteSite(siteId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteSite>>, TError, {siteId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * A view for retrieving all of a Juno site's AccessPass objects

Args:
    request: HTTP GET request object
    site_id: indicates which Juno site's AccessPass objects to retrieve

Returns:
    A list of AccessPass objects
 */
export const getAccessPasses = (
    siteId: string,
    params?: GetAccessPassesParams,
 signal?: AbortSignal
) => {
      return localInstance<AccessPass[]>(
      {url: `/sites/${siteId}/access_passes`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAccessPassesQueryKey = (siteId: string,
    params?: GetAccessPassesParams,) => [`/sites/${siteId}/access_passes`, ...(params ? [params]: [])];

    
export type GetAccessPassesQueryResult = NonNullable<Awaited<ReturnType<typeof getAccessPasses>>>
export type GetAccessPassesQueryError = unknown

export const useGetAccessPasses = <TData = Awaited<ReturnType<typeof getAccessPasses>>, TError = unknown>(
 siteId: string,
    params?: GetAccessPassesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccessPasses>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccessPassesQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccessPasses>>> = ({ signal }) => getAccessPasses(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccessPasses>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * A view for creating a site-level AccessPass object

Args:
    request: HTTP POST request object
    site_id: indicates for which Juno site to create the AccessPass object

Returns:
    dict containing the newly created AccessPass object
 */
export const createAccessPass = (
    siteId: string,
    accessPass: AccessPass,
 
) => {
      return localInstance<AccessPass>(
      {url: `/sites/${siteId}/access_passes`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: accessPass
    },
      );
    }
  


    export type CreateAccessPassMutationResult = NonNullable<Awaited<ReturnType<typeof createAccessPass>>>
    export type CreateAccessPassMutationBody = AccessPass
    export type CreateAccessPassMutationError = unknown

    export const useCreateAccessPass = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAccessPass>>, TError,{siteId: string;data: AccessPass}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAccessPass>>, {siteId: string;data: AccessPass}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createAccessPass(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createAccessPass>>, TError, {siteId: string;data: AccessPass}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * A view for retrieving a specific AccessPass object

Args:
    request: HTTP GET request object
    site_id: indicates what Juno site's AccessPass to retrieve
    access_pass_id: id of the AccessPass object to retrieve

Returns:
    dict containing the AccessPass object
 */
export const getAccessPass = (
    siteId: string,
    accessPassId: string,
    params?: GetAccessPassParams,
 signal?: AbortSignal
) => {
      return localInstance<AccessPass>(
      {url: `/sites/${siteId}/access_passes/${accessPassId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAccessPassQueryKey = (siteId: string,
    accessPassId: string,
    params?: GetAccessPassParams,) => [`/sites/${siteId}/access_passes/${accessPassId}`, ...(params ? [params]: [])];

    
export type GetAccessPassQueryResult = NonNullable<Awaited<ReturnType<typeof getAccessPass>>>
export type GetAccessPassQueryError = unknown

export const useGetAccessPass = <TData = Awaited<ReturnType<typeof getAccessPass>>, TError = unknown>(
 siteId: string,
    accessPassId: string,
    params?: GetAccessPassParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAccessPass>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAccessPassQueryKey(siteId,accessPassId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccessPass>>> = ({ signal }) => getAccessPass(siteId,accessPassId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAccessPass>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && accessPassId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * A view for updating a specific AccessPass object

Args:
    request: HTTP PUT request object
    site_id: indicates what Juno site's AccessPass object to update
    access_pass_id: id of the AccessPass object to update

Returns:
    dict containing the updated AccessPass object
 */
export const updateAccessPass = (
    siteId: string,
    accessPassId: string,
    accessPass: AccessPass,
 
) => {
      return localInstance<AccessPass>(
      {url: `/sites/${siteId}/access_passes/${accessPassId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: accessPass
    },
      );
    }
  


    export type UpdateAccessPassMutationResult = NonNullable<Awaited<ReturnType<typeof updateAccessPass>>>
    export type UpdateAccessPassMutationBody = AccessPass
    export type UpdateAccessPassMutationError = unknown

    export const useUpdateAccessPass = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAccessPass>>, TError,{siteId: string;accessPassId: string;data: AccessPass}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAccessPass>>, {siteId: string;accessPassId: string;data: AccessPass}> = (props) => {
          const {siteId,accessPassId,data} = props ?? {};

          return  updateAccessPass(siteId,accessPassId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateAccessPass>>, TError, {siteId: string;accessPassId: string;data: AccessPass}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * A view for deleting a single AccessPass object

Args:
    request: HTTP DELETE request object
    site_id: indicates what Juno site to remove the AccessPass from
    access_pass_id: id of the AccessPass object to deleted

Returns:
    Success message and the number of AccessPass objects removed from the DB
 */
export const deleteContentKey = (
    siteId: string,
    accessPassId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/access_passes/${accessPassId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteContentKeyMutationResult = NonNullable<Awaited<ReturnType<typeof deleteContentKey>>>
    
    export type DeleteContentKeyMutationError = unknown

    export const useDeleteContentKey = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteContentKey>>, TError,{siteId: string;accessPassId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteContentKey>>, {siteId: string;accessPassId: string}> = (props) => {
          const {siteId,accessPassId} = props ?? {};

          return  deleteContentKey(siteId,accessPassId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteContentKey>>, TError, {siteId: string;accessPassId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all AdminGroups for a given Site
 */
export const getAdminGroups = (
    siteId: string,
    params?: GetAdminGroupsParams,
 signal?: AbortSignal
) => {
      return localInstance<AdminGroup[]>(
      {url: `/sites/${siteId}/admin_groups`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAdminGroupsQueryKey = (siteId: string,
    params?: GetAdminGroupsParams,) => [`/sites/${siteId}/admin_groups`, ...(params ? [params]: [])];

    
export type GetAdminGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminGroups>>>
export type GetAdminGroupsQueryError = unknown

export const useGetAdminGroups = <TData = Awaited<ReturnType<typeof getAdminGroups>>, TError = unknown>(
 siteId: string,
    params?: GetAdminGroupsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAdminGroups>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAdminGroupsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminGroups>>> = ({ signal }) => getAdminGroups(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAdminGroups>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new AdminGroup for the given Site
 */
export const createAdminGroup = (
    siteId: string,
    adminGroup: AdminGroup,
 
) => {
      return localInstance<AdminGroup>(
      {url: `/sites/${siteId}/admin_groups`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: adminGroup
    },
      );
    }
  


    export type CreateAdminGroupMutationResult = NonNullable<Awaited<ReturnType<typeof createAdminGroup>>>
    export type CreateAdminGroupMutationBody = AdminGroup
    export type CreateAdminGroupMutationError = unknown

    export const useCreateAdminGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAdminGroup>>, TError,{siteId: string;data: AdminGroup}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAdminGroup>>, {siteId: string;data: AdminGroup}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createAdminGroup(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createAdminGroup>>, TError, {siteId: string;data: AdminGroup}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single AdminGroup
 */
export const getAdminGroup = (
    siteId: string,
    groupId: string,
    params?: GetAdminGroupParams,
 signal?: AbortSignal
) => {
      return localInstance<AdminGroup>(
      {url: `/sites/${siteId}/admin_groups/${groupId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAdminGroupQueryKey = (siteId: string,
    groupId: string,
    params?: GetAdminGroupParams,) => [`/sites/${siteId}/admin_groups/${groupId}`, ...(params ? [params]: [])];

    
export type GetAdminGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getAdminGroup>>>
export type GetAdminGroupQueryError = unknown

export const useGetAdminGroup = <TData = Awaited<ReturnType<typeof getAdminGroup>>, TError = unknown>(
 siteId: string,
    groupId: string,
    params?: GetAdminGroupParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAdminGroup>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAdminGroupQueryKey(siteId,groupId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAdminGroup>>> = ({ signal }) => getAdminGroup(siteId,groupId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAdminGroup>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && groupId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates a single AdminGroup
 */
export const updateAdminGroup = (
    siteId: string,
    groupId: string,
    adminGroup: AdminGroup,
 
) => {
      return localInstance<AdminGroup>(
      {url: `/sites/${siteId}/admin_groups/${groupId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: adminGroup
    },
      );
    }
  


    export type UpdateAdminGroupMutationResult = NonNullable<Awaited<ReturnType<typeof updateAdminGroup>>>
    export type UpdateAdminGroupMutationBody = AdminGroup
    export type UpdateAdminGroupMutationError = unknown

    export const useUpdateAdminGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAdminGroup>>, TError,{siteId: string;groupId: string;data: AdminGroup}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAdminGroup>>, {siteId: string;groupId: string;data: AdminGroup}> = (props) => {
          const {siteId,groupId,data} = props ?? {};

          return  updateAdminGroup(siteId,groupId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateAdminGroup>>, TError, {siteId: string;groupId: string;data: AdminGroup}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a single AdminGroup
 */
export const deleteAdminGroup = (
    siteId: string,
    groupId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/admin_groups/${groupId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteAdminGroupMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAdminGroup>>>
    
    export type DeleteAdminGroupMutationError = unknown

    export const useDeleteAdminGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAdminGroup>>, TError,{siteId: string;groupId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAdminGroup>>, {siteId: string;groupId: string}> = (props) => {
          const {siteId,groupId} = props ?? {};

          return  deleteAdminGroup(siteId,groupId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteAdminGroup>>, TError, {siteId: string;groupId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single AISuggestionRecord
 */
export const getAISuggestionRecord = (
    siteId: string,
    params?: GetAISuggestionRecordParams,
 signal?: AbortSignal
) => {
      return localInstance<AISuggestionRecord[]>(
      {url: `/sites/${siteId}/ai/suggestions`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAISuggestionRecordQueryKey = (siteId: string,
    params?: GetAISuggestionRecordParams,) => [`/sites/${siteId}/ai/suggestions`, ...(params ? [params]: [])];

    
export type GetAISuggestionRecordQueryResult = NonNullable<Awaited<ReturnType<typeof getAISuggestionRecord>>>
export type GetAISuggestionRecordQueryError = unknown

export const useGetAISuggestionRecord = <TData = Awaited<ReturnType<typeof getAISuggestionRecord>>, TError = unknown>(
 siteId: string,
    params?: GetAISuggestionRecordParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAISuggestionRecord>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAISuggestionRecordQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAISuggestionRecord>>> = ({ signal }) => getAISuggestionRecord(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAISuggestionRecord>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single AISuggestionRecord
 */
export const createAISuggestionRecord = (
    siteId: string,
    aISuggestionRecord: AISuggestionRecord,
 
) => {
      return localInstance<AISuggestionRecord>(
      {url: `/sites/${siteId}/ai/suggestions`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: aISuggestionRecord
    },
      );
    }
  


    export type CreateAISuggestionRecordMutationResult = NonNullable<Awaited<ReturnType<typeof createAISuggestionRecord>>>
    export type CreateAISuggestionRecordMutationBody = AISuggestionRecord
    export type CreateAISuggestionRecordMutationError = unknown

    export const useCreateAISuggestionRecord = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAISuggestionRecord>>, TError,{siteId: string;data: AISuggestionRecord}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAISuggestionRecord>>, {siteId: string;data: AISuggestionRecord}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createAISuggestionRecord(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createAISuggestionRecord>>, TError, {siteId: string;data: AISuggestionRecord}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Get all unsubscribes of a given Site
 */
export const getAllSiteUnsubscribes = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<AllClientUnsubscribes>(
      {url: `/sites/${siteId}/all_site_unsubscribes`, method: 'get', signal
    },
      );
    }
  

export const getGetAllSiteUnsubscribesQueryKey = (siteId: string,) => [`/sites/${siteId}/all_site_unsubscribes`];

    
export type GetAllSiteUnsubscribesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllSiteUnsubscribes>>>
export type GetAllSiteUnsubscribesQueryError = unknown

export const useGetAllSiteUnsubscribes = <TData = Awaited<ReturnType<typeof getAllSiteUnsubscribes>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllSiteUnsubscribes>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllSiteUnsubscribesQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllSiteUnsubscribes>>> = ({ signal }) => getAllSiteUnsubscribes(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllSiteUnsubscribes>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Get all unsubscribe groups that a user has ever been unsubscribed from
 */
export const getUserUnsubscribeGroups = (
    siteId: string,
    email: string,
 signal?: AbortSignal
) => {
      return localInstance<AllUserUnsubscribeGroups[]>(
      {url: `/sites/${siteId}/all_user_unsubscribe_groups/${email}`, method: 'get', signal
    },
      );
    }
  

export const getGetUserUnsubscribeGroupsQueryKey = (siteId: string,
    email: string,) => [`/sites/${siteId}/all_user_unsubscribe_groups/${email}`];

    
export type GetUserUnsubscribeGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserUnsubscribeGroups>>>
export type GetUserUnsubscribeGroupsQueryError = unknown

export const useGetUserUnsubscribeGroups = <TData = Awaited<ReturnType<typeof getUserUnsubscribeGroups>>, TError = unknown>(
 siteId: string,
    email: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserUnsubscribeGroups>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserUnsubscribeGroupsQueryKey(siteId,email);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserUnsubscribeGroups>>> = ({ signal }) => getUserUnsubscribeGroups(siteId,email, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserUnsubscribeGroups>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && email), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const createActivityRecord = (
    siteId: string,
    activity: Activity,
 
) => {
      return localInstance<Activity>(
      {url: `/sites/${siteId}/analytics/activity_record`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: activity
    },
      );
    }
  


    export type CreateActivityRecordMutationResult = NonNullable<Awaited<ReturnType<typeof createActivityRecord>>>
    export type CreateActivityRecordMutationBody = Activity
    export type CreateActivityRecordMutationError = unknown

    export const useCreateActivityRecord = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createActivityRecord>>, TError,{siteId: string;data: Activity}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createActivityRecord>>, {siteId: string;data: Activity}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createActivityRecord(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createActivityRecord>>, TError, {siteId: string;data: Activity}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for analytics activities aggregate
 */
export const getAnalyticsActivitiesAggregate = (
    siteId: string,
    params?: GetAnalyticsActivitiesAggregateParams,
 signal?: AbortSignal
) => {
      return localInstance<Aggregate[]>(
      {url: `/sites/${siteId}/analytics/aggregates`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAnalyticsActivitiesAggregateQueryKey = (siteId: string,
    params?: GetAnalyticsActivitiesAggregateParams,) => [`/sites/${siteId}/analytics/aggregates`, ...(params ? [params]: [])];

    
export type GetAnalyticsActivitiesAggregateQueryResult = NonNullable<Awaited<ReturnType<typeof getAnalyticsActivitiesAggregate>>>
export type GetAnalyticsActivitiesAggregateQueryError = unknown

export const useGetAnalyticsActivitiesAggregate = <TData = Awaited<ReturnType<typeof getAnalyticsActivitiesAggregate>>, TError = unknown>(
 siteId: string,
    params?: GetAnalyticsActivitiesAggregateParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAnalyticsActivitiesAggregate>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAnalyticsActivitiesAggregateQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAnalyticsActivitiesAggregate>>> = ({ signal }) => getAnalyticsActivitiesAggregate(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAnalyticsActivitiesAggregate>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for analytics activities graph
 */
export const getAnalyticsActivitiesGraph = (
    siteId: string,
    params?: GetAnalyticsActivitiesGraphParams,
 signal?: AbortSignal
) => {
      return localInstance<GetAnalyticsActivitiesGraph200>(
      {url: `/sites/${siteId}/analytics/graph`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAnalyticsActivitiesGraphQueryKey = (siteId: string,
    params?: GetAnalyticsActivitiesGraphParams,) => [`/sites/${siteId}/analytics/graph`, ...(params ? [params]: [])];

    
export type GetAnalyticsActivitiesGraphQueryResult = NonNullable<Awaited<ReturnType<typeof getAnalyticsActivitiesGraph>>>
export type GetAnalyticsActivitiesGraphQueryError = unknown

export const useGetAnalyticsActivitiesGraph = <TData = Awaited<ReturnType<typeof getAnalyticsActivitiesGraph>>, TError = unknown>(
 siteId: string,
    params?: GetAnalyticsActivitiesGraphParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAnalyticsActivitiesGraph>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAnalyticsActivitiesGraphQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAnalyticsActivitiesGraph>>> = ({ signal }) => getAnalyticsActivitiesGraph(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAnalyticsActivitiesGraph>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for analytics activities report
 */
export const getAnalyticsActivitiesReport = (
    siteId: string,
    params?: GetAnalyticsActivitiesReportParams,
 signal?: AbortSignal
) => {
      return localInstance<Report>(
      {url: `/sites/${siteId}/analytics/report`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAnalyticsActivitiesReportQueryKey = (siteId: string,
    params?: GetAnalyticsActivitiesReportParams,) => [`/sites/${siteId}/analytics/report`, ...(params ? [params]: [])];

    
export type GetAnalyticsActivitiesReportQueryResult = NonNullable<Awaited<ReturnType<typeof getAnalyticsActivitiesReport>>>
export type GetAnalyticsActivitiesReportQueryError = unknown

export const useGetAnalyticsActivitiesReport = <TData = Awaited<ReturnType<typeof getAnalyticsActivitiesReport>>, TError = unknown>(
 siteId: string,
    params?: GetAnalyticsActivitiesReportParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAnalyticsActivitiesReport>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAnalyticsActivitiesReportQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAnalyticsActivitiesReport>>> = ({ signal }) => getAnalyticsActivitiesReport(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAnalyticsActivitiesReport>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getCertificateTemplates = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<CertificateTemplate>(
      {url: `/sites/${siteId}/certificate_templates`, method: 'get', signal
    },
      );
    }
  

export const getGetCertificateTemplatesQueryKey = (siteId: string,) => [`/sites/${siteId}/certificate_templates`];

    
export type GetCertificateTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getCertificateTemplates>>>
export type GetCertificateTemplatesQueryError = unknown

export const useGetCertificateTemplates = <TData = Awaited<ReturnType<typeof getCertificateTemplates>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCertificateTemplates>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCertificateTemplatesQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCertificateTemplates>>> = ({ signal }) => getCertificateTemplates(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCertificateTemplates>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const createCertificateTemplate = (
    siteId: string,
    certificateTemplate: CertificateTemplate,
 
) => {
      return localInstance<CertificateTemplate>(
      {url: `/sites/${siteId}/certificate_templates`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: certificateTemplate
    },
      );
    }
  


    export type CreateCertificateTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof createCertificateTemplate>>>
    export type CreateCertificateTemplateMutationBody = CertificateTemplate
    export type CreateCertificateTemplateMutationError = unknown

    export const useCreateCertificateTemplate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCertificateTemplate>>, TError,{siteId: string;data: CertificateTemplate}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCertificateTemplate>>, {siteId: string;data: CertificateTemplate}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createCertificateTemplate(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createCertificateTemplate>>, TError, {siteId: string;data: CertificateTemplate}, TContext>(mutationFn, mutationOptions)
    }
    
export const getCertificateTemplate = (
    siteId: string,
    templateId: string,
 signal?: AbortSignal
) => {
      return localInstance<CertificateTemplate>(
      {url: `/sites/${siteId}/certificate_templates/${templateId}`, method: 'get', signal
    },
      );
    }
  

export const getGetCertificateTemplateQueryKey = (siteId: string,
    templateId: string,) => [`/sites/${siteId}/certificate_templates/${templateId}`];

    
export type GetCertificateTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof getCertificateTemplate>>>
export type GetCertificateTemplateQueryError = unknown

export const useGetCertificateTemplate = <TData = Awaited<ReturnType<typeof getCertificateTemplate>>, TError = unknown>(
 siteId: string,
    templateId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCertificateTemplate>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCertificateTemplateQueryKey(siteId,templateId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCertificateTemplate>>> = ({ signal }) => getCertificateTemplate(siteId,templateId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCertificateTemplate>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && templateId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const updateCertificateTemplate = (
    siteId: string,
    templateId: string,
    certificateTemplate: CertificateTemplate,
 
) => {
      return localInstance<CertificateTemplate>(
      {url: `/sites/${siteId}/certificate_templates/${templateId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: certificateTemplate
    },
      );
    }
  


    export type UpdateCertificateTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof updateCertificateTemplate>>>
    export type UpdateCertificateTemplateMutationBody = CertificateTemplate
    export type UpdateCertificateTemplateMutationError = unknown

    export const useUpdateCertificateTemplate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCertificateTemplate>>, TError,{siteId: string;templateId: string;data: CertificateTemplate}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCertificateTemplate>>, {siteId: string;templateId: string;data: CertificateTemplate}> = (props) => {
          const {siteId,templateId,data} = props ?? {};

          return  updateCertificateTemplate(siteId,templateId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateCertificateTemplate>>, TError, {siteId: string;templateId: string;data: CertificateTemplate}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteCertificateTemplate = (
    siteId: string,
    templateId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/certificate_templates/${templateId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteCertificateTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCertificateTemplate>>>
    
    export type DeleteCertificateTemplateMutationError = unknown

    export const useDeleteCertificateTemplate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCertificateTemplate>>, TError,{siteId: string;templateId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCertificateTemplate>>, {siteId: string;templateId: string}> = (props) => {
          const {siteId,templateId} = props ?? {};

          return  deleteCertificateTemplate(siteId,templateId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCertificateTemplate>>, TError, {siteId: string;templateId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with all Cohorts for a given Client
 */
export const getAllCohorts = (
    siteId: string,
    params?: GetAllCohortsParams,
 signal?: AbortSignal
) => {
      return localInstance<Cohort[]>(
      {url: `/sites/${siteId}/cohorts`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllCohortsQueryKey = (siteId: string,
    params?: GetAllCohortsParams,) => [`/sites/${siteId}/cohorts`, ...(params ? [params]: [])];

    
export type GetAllCohortsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllCohorts>>>
export type GetAllCohortsQueryError = unknown

export const useGetAllCohorts = <TData = Awaited<ReturnType<typeof getAllCohorts>>, TError = unknown>(
 siteId: string,
    params?: GetAllCohortsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllCohorts>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllCohortsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllCohorts>>> = ({ signal }) => getAllCohorts(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllCohorts>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for dealing with all Cohorts for a given Client
 */
export const createCohort = (
    siteId: string,
    cohort: Cohort,
 
) => {
      return localInstance<Cohort>(
      {url: `/sites/${siteId}/cohorts`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: cohort
    },
      );
    }
  


    export type CreateCohortMutationResult = NonNullable<Awaited<ReturnType<typeof createCohort>>>
    export type CreateCohortMutationBody = Cohort
    export type CreateCohortMutationError = unknown

    export const useCreateCohort = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCohort>>, TError,{siteId: string;data: Cohort}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCohort>>, {siteId: string;data: Cohort}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createCohort(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createCohort>>, TError, {siteId: string;data: Cohort}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with a single Cohort
 */
export const getCohort = (
    siteId: string,
    cohortId: string,
    params?: GetCohortParams,
 signal?: AbortSignal
) => {
      return localInstance<Cohort>(
      {url: `/sites/${siteId}/cohorts/${cohortId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCohortQueryKey = (siteId: string,
    cohortId: string,
    params?: GetCohortParams,) => [`/sites/${siteId}/cohorts/${cohortId}`, ...(params ? [params]: [])];

    
export type GetCohortQueryResult = NonNullable<Awaited<ReturnType<typeof getCohort>>>
export type GetCohortQueryError = unknown

export const useGetCohort = <TData = Awaited<ReturnType<typeof getCohort>>, TError = unknown>(
 siteId: string,
    cohortId: string,
    params?: GetCohortParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCohort>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCohortQueryKey(siteId,cohortId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCohort>>> = ({ signal }) => getCohort(siteId,cohortId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCohort>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && cohortId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for dealing with a single Cohort
 */
export const updateCohort = (
    siteId: string,
    cohortId: string,
    cohort: Cohort,
 
) => {
      return localInstance<Cohort>(
      {url: `/sites/${siteId}/cohorts/${cohortId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: cohort
    },
      );
    }
  


    export type UpdateCohortMutationResult = NonNullable<Awaited<ReturnType<typeof updateCohort>>>
    export type UpdateCohortMutationBody = Cohort
    export type UpdateCohortMutationError = unknown

    export const useUpdateCohort = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCohort>>, TError,{siteId: string;cohortId: string;data: Cohort}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCohort>>, {siteId: string;cohortId: string;data: Cohort}> = (props) => {
          const {siteId,cohortId,data} = props ?? {};

          return  updateCohort(siteId,cohortId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateCohort>>, TError, {siteId: string;cohortId: string;data: Cohort}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with a single Cohort
 */
export const deleteCohort = (
    siteId: string,
    cohortId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/cohorts/${cohortId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteCohortMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCohort>>>
    
    export type DeleteCohortMutationError = unknown

    export const useDeleteCohort = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCohort>>, TError,{siteId: string;cohortId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCohort>>, {siteId: string;cohortId: string}> = (props) => {
          const {siteId,cohortId} = props ?? {};

          return  deleteCohort(siteId,cohortId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCohort>>, TError, {siteId: string;cohortId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of all `ForumTopic`s
 */
export const getAllForumTopics = (
    siteId: string,
    params?: GetAllForumTopicsParams,
 signal?: AbortSignal
) => {
      return localInstance<ForumTopic[]>(
      {url: `/sites/${siteId}/community/discussions`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllForumTopicsQueryKey = (siteId: string,
    params?: GetAllForumTopicsParams,) => [`/sites/${siteId}/community/discussions`, ...(params ? [params]: [])];

    
export type GetAllForumTopicsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllForumTopics>>>
export type GetAllForumTopicsQueryError = unknown

export const useGetAllForumTopics = <TData = Awaited<ReturnType<typeof getAllForumTopics>>, TError = unknown>(
 siteId: string,
    params?: GetAllForumTopicsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllForumTopics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllForumTopicsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllForumTopics>>> = ({ signal }) => getAllForumTopics(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllForumTopics>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns a list of all global feed announcements
 */
export const getGlobalFeedAnnouncements = (
    siteId: string,
    params?: GetGlobalFeedAnnouncementsParams,
 signal?: AbortSignal
) => {
      return localInstance<Thread[]>(
      {url: `/sites/${siteId}/community/feed_announcements`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetGlobalFeedAnnouncementsQueryKey = (siteId: string,
    params?: GetGlobalFeedAnnouncementsParams,) => [`/sites/${siteId}/community/feed_announcements`, ...(params ? [params]: [])];

    
export type GetGlobalFeedAnnouncementsQueryResult = NonNullable<Awaited<ReturnType<typeof getGlobalFeedAnnouncements>>>
export type GetGlobalFeedAnnouncementsQueryError = unknown

export const useGetGlobalFeedAnnouncements = <TData = Awaited<ReturnType<typeof getGlobalFeedAnnouncements>>, TError = unknown>(
 siteId: string,
    params?: GetGlobalFeedAnnouncementsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getGlobalFeedAnnouncements>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetGlobalFeedAnnouncementsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGlobalFeedAnnouncements>>> = ({ signal }) => getGlobalFeedAnnouncements(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getGlobalFeedAnnouncements>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns a list of all Threads in the given Feed
 */
export const getFeedThreads = (
    siteId: string,
    feedId: string,
    params?: GetFeedThreadsParams,
 signal?: AbortSignal
) => {
      return localInstance<Thread[]>(
      {url: `/sites/${siteId}/community/feeds/${feedId}/threads`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetFeedThreadsQueryKey = (siteId: string,
    feedId: string,
    params?: GetFeedThreadsParams,) => [`/sites/${siteId}/community/feeds/${feedId}/threads`, ...(params ? [params]: [])];

    
export type GetFeedThreadsQueryResult = NonNullable<Awaited<ReturnType<typeof getFeedThreads>>>
export type GetFeedThreadsQueryError = unknown

export const useGetFeedThreads = <TData = Awaited<ReturnType<typeof getFeedThreads>>, TError = unknown>(
 siteId: string,
    feedId: string,
    params?: GetFeedThreadsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFeedThreads>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFeedThreadsQueryKey(siteId,feedId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeedThreads>>> = ({ signal }) => getFeedThreads(siteId,feedId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getFeedThreads>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && feedId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Thread in the given Feed
 */
export const createFeedThread = (
    siteId: string,
    feedId: string,
    thread: Thread,
 
) => {
      return localInstance<Thread>(
      {url: `/sites/${siteId}/community/feeds/${feedId}/threads`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: thread
    },
      );
    }
  


    export type CreateFeedThreadMutationResult = NonNullable<Awaited<ReturnType<typeof createFeedThread>>>
    export type CreateFeedThreadMutationBody = Thread
    export type CreateFeedThreadMutationError = unknown

    export const useCreateFeedThread = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFeedThread>>, TError,{siteId: string;feedId: string;data: Thread}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFeedThread>>, {siteId: string;feedId: string;data: Thread}> = (props) => {
          const {siteId,feedId,data} = props ?? {};

          return  createFeedThread(siteId,feedId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createFeedThread>>, TError, {siteId: string;feedId: string;data: Thread}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of all global forum announcements
 */
export const getGlobalForumAnnouncements = (
    siteId: string,
    params?: GetGlobalForumAnnouncementsParams,
 signal?: AbortSignal
) => {
      return localInstance<Thread[]>(
      {url: `/sites/${siteId}/community/forum_announcements`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetGlobalForumAnnouncementsQueryKey = (siteId: string,
    params?: GetGlobalForumAnnouncementsParams,) => [`/sites/${siteId}/community/forum_announcements`, ...(params ? [params]: [])];

    
export type GetGlobalForumAnnouncementsQueryResult = NonNullable<Awaited<ReturnType<typeof getGlobalForumAnnouncements>>>
export type GetGlobalForumAnnouncementsQueryError = unknown

export const useGetGlobalForumAnnouncements = <TData = Awaited<ReturnType<typeof getGlobalForumAnnouncements>>, TError = unknown>(
 siteId: string,
    params?: GetGlobalForumAnnouncementsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getGlobalForumAnnouncements>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetGlobalForumAnnouncementsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGlobalForumAnnouncements>>> = ({ signal }) => getGlobalForumAnnouncements(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getGlobalForumAnnouncements>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns a list of all `ForumTopic`s for the given Forum
 */
export const getForumTopics = (
    siteId: string,
    forumId: string,
    params?: GetForumTopicsParams,
 signal?: AbortSignal
) => {
      return localInstance<ForumTopic[]>(
      {url: `/sites/${siteId}/community/forums/${forumId}/topics`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetForumTopicsQueryKey = (siteId: string,
    forumId: string,
    params?: GetForumTopicsParams,) => [`/sites/${siteId}/community/forums/${forumId}/topics`, ...(params ? [params]: [])];

    
export type GetForumTopicsQueryResult = NonNullable<Awaited<ReturnType<typeof getForumTopics>>>
export type GetForumTopicsQueryError = unknown

export const useGetForumTopics = <TData = Awaited<ReturnType<typeof getForumTopics>>, TError = unknown>(
 siteId: string,
    forumId: string,
    params?: GetForumTopicsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getForumTopics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetForumTopicsQueryKey(siteId,forumId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getForumTopics>>> = ({ signal }) => getForumTopics(siteId,forumId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getForumTopics>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && forumId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new ForumTopic on the given Forum
 */
export const createForumTopic = (
    siteId: string,
    forumId: string,
    forumTopic: ForumTopic,
 
) => {
      return localInstance<ForumTopic>(
      {url: `/sites/${siteId}/community/forums/${forumId}/topics`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: forumTopic
    },
      );
    }
  


    export type CreateForumTopicMutationResult = NonNullable<Awaited<ReturnType<typeof createForumTopic>>>
    export type CreateForumTopicMutationBody = ForumTopic
    export type CreateForumTopicMutationError = unknown

    export const useCreateForumTopic = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createForumTopic>>, TError,{siteId: string;forumId: string;data: ForumTopic}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createForumTopic>>, {siteId: string;forumId: string;data: ForumTopic}> = (props) => {
          const {siteId,forumId,data} = props ?? {};

          return  createForumTopic(siteId,forumId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createForumTopic>>, TError, {siteId: string;forumId: string;data: ForumTopic}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single ForumTopic
 */
export const getForumTopic = (
    siteId: string,
    forumId: string,
    topicId: string,
 signal?: AbortSignal
) => {
      return localInstance<ForumTopic>(
      {url: `/sites/${siteId}/community/forums/${forumId}/topics/${topicId}`, method: 'get', signal
    },
      );
    }
  

export const getGetForumTopicQueryKey = (siteId: string,
    forumId: string,
    topicId: string,) => [`/sites/${siteId}/community/forums/${forumId}/topics/${topicId}`];

    
export type GetForumTopicQueryResult = NonNullable<Awaited<ReturnType<typeof getForumTopic>>>
export type GetForumTopicQueryError = unknown

export const useGetForumTopic = <TData = Awaited<ReturnType<typeof getForumTopic>>, TError = unknown>(
 siteId: string,
    forumId: string,
    topicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getForumTopic>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetForumTopicQueryKey(siteId,forumId,topicId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getForumTopic>>> = ({ signal }) => getForumTopic(siteId,forumId,topicId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getForumTopic>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && forumId && topicId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single ForumTopic
 */
export const updateForumTopic = (
    siteId: string,
    forumId: string,
    topicId: string,
    forumTopic: ForumTopic,
 
) => {
      return localInstance<ForumTopic>(
      {url: `/sites/${siteId}/community/forums/${forumId}/topics/${topicId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: forumTopic
    },
      );
    }
  


    export type UpdateForumTopicMutationResult = NonNullable<Awaited<ReturnType<typeof updateForumTopic>>>
    export type UpdateForumTopicMutationBody = ForumTopic
    export type UpdateForumTopicMutationError = unknown

    export const useUpdateForumTopic = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateForumTopic>>, TError,{siteId: string;forumId: string;topicId: string;data: ForumTopic}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateForumTopic>>, {siteId: string;forumId: string;topicId: string;data: ForumTopic}> = (props) => {
          const {siteId,forumId,topicId,data} = props ?? {};

          return  updateForumTopic(siteId,forumId,topicId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateForumTopic>>, TError, {siteId: string;forumId: string;topicId: string;data: ForumTopic}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single ForumTopic
 */
export const deleteForumTopic = (
    siteId: string,
    forumId: string,
    topicId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/community/forums/${forumId}/topics/${topicId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteForumTopicMutationResult = NonNullable<Awaited<ReturnType<typeof deleteForumTopic>>>
    
    export type DeleteForumTopicMutationError = unknown

    export const useDeleteForumTopic = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteForumTopic>>, TError,{siteId: string;forumId: string;topicId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteForumTopic>>, {siteId: string;forumId: string;topicId: string}> = (props) => {
          const {siteId,forumId,topicId} = props ?? {};

          return  deleteForumTopic(siteId,forumId,topicId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteForumTopic>>, TError, {siteId: string;forumId: string;topicId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Follows or unfollows a forum topic
 */
export const followUnfollowForumTopic = (
    siteId: string,
    forumId: string,
    topicId: string,
    userId: string,
 
) => {
      return localInstance<ForumTopic>(
      {url: `/sites/${siteId}/community/forums/${forumId}/topics/${topicId}/followUnfollow/${userId}`, method: 'post'
    },
      );
    }
  


    export type FollowUnfollowForumTopicMutationResult = NonNullable<Awaited<ReturnType<typeof followUnfollowForumTopic>>>
    
    export type FollowUnfollowForumTopicMutationError = unknown

    export const useFollowUnfollowForumTopic = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof followUnfollowForumTopic>>, TError,{siteId: string;forumId: string;topicId: string;userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof followUnfollowForumTopic>>, {siteId: string;forumId: string;topicId: string;userId: string}> = (props) => {
          const {siteId,forumId,topicId,userId} = props ?? {};

          return  followUnfollowForumTopic(siteId,forumId,topicId,userId,)
        }

      return useMutation<Awaited<ReturnType<typeof followUnfollowForumTopic>>, TError, {siteId: string;forumId: string;topicId: string;userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Gets all the threads within a ForumTopic
 */
export const getForumTopicThreads = (
    siteId: string,
    forumId: string,
    topicId: string,
    params?: GetForumTopicThreadsParams,
 signal?: AbortSignal
) => {
      return localInstance<FullThread[]>(
      {url: `/sites/${siteId}/community/forums/${forumId}/topics/${topicId}/threads`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetForumTopicThreadsQueryKey = (siteId: string,
    forumId: string,
    topicId: string,
    params?: GetForumTopicThreadsParams,) => [`/sites/${siteId}/community/forums/${forumId}/topics/${topicId}/threads`, ...(params ? [params]: [])];

    
export type GetForumTopicThreadsQueryResult = NonNullable<Awaited<ReturnType<typeof getForumTopicThreads>>>
export type GetForumTopicThreadsQueryError = unknown

export const useGetForumTopicThreads = <TData = Awaited<ReturnType<typeof getForumTopicThreads>>, TError = unknown>(
 siteId: string,
    forumId: string,
    topicId: string,
    params?: GetForumTopicThreadsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getForumTopicThreads>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetForumTopicThreadsQueryKey(siteId,forumId,topicId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getForumTopicThreads>>> = ({ signal }) => getForumTopicThreads(siteId,forumId,topicId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getForumTopicThreads>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && forumId && topicId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Thread within the given ForumTopic
 */
export const createForumTopicThread = (
    siteId: string,
    forumId: string,
    topicId: string,
    thread: Thread,
 
) => {
      return localInstance<Thread>(
      {url: `/sites/${siteId}/community/forums/${forumId}/topics/${topicId}/threads`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: thread
    },
      );
    }
  


    export type CreateForumTopicThreadMutationResult = NonNullable<Awaited<ReturnType<typeof createForumTopicThread>>>
    export type CreateForumTopicThreadMutationBody = Thread
    export type CreateForumTopicThreadMutationError = unknown

    export const useCreateForumTopicThread = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createForumTopicThread>>, TError,{siteId: string;forumId: string;topicId: string;data: Thread}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createForumTopicThread>>, {siteId: string;forumId: string;topicId: string;data: Thread}> = (props) => {
          const {siteId,forumId,topicId,data} = props ?? {};

          return  createForumTopicThread(siteId,forumId,topicId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createForumTopicThread>>, TError, {siteId: string;forumId: string;topicId: string;data: Thread}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all CommunityGroups in the chain of parent/child relationship
 */
export const getCommunityGroupsHierarchy = (
    siteId: string,
    groupId: string,
    scope: string,
    params?: GetCommunityGroupsHierarchyParams,
 signal?: AbortSignal
) => {
      return localInstance<CommunityGroup[]>(
      {url: `/sites/${siteId}/community/group/${groupId}/${scope}/hierarchy`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCommunityGroupsHierarchyQueryKey = (siteId: string,
    groupId: string,
    scope: string,
    params?: GetCommunityGroupsHierarchyParams,) => [`/sites/${siteId}/community/group/${groupId}/${scope}/hierarchy`, ...(params ? [params]: [])];

    
export type GetCommunityGroupsHierarchyQueryResult = NonNullable<Awaited<ReturnType<typeof getCommunityGroupsHierarchy>>>
export type GetCommunityGroupsHierarchyQueryError = unknown

export const useGetCommunityGroupsHierarchy = <TData = Awaited<ReturnType<typeof getCommunityGroupsHierarchy>>, TError = unknown>(
 siteId: string,
    groupId: string,
    scope: string,
    params?: GetCommunityGroupsHierarchyParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCommunityGroupsHierarchy>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommunityGroupsHierarchyQueryKey(siteId,groupId,scope,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommunityGroupsHierarchy>>> = ({ signal }) => getCommunityGroupsHierarchy(siteId,groupId,scope,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCommunityGroupsHierarchy>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && groupId && scope), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns all CommunityGroups for the given Site
 */
export const getCommunityGroups = (
    siteId: string,
    params?: GetCommunityGroupsParams,
 signal?: AbortSignal
) => {
      return localInstance<CommunityGroup[]>(
      {url: `/sites/${siteId}/community/groups`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCommunityGroupsQueryKey = (siteId: string,
    params?: GetCommunityGroupsParams,) => [`/sites/${siteId}/community/groups`, ...(params ? [params]: [])];

    
export type GetCommunityGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getCommunityGroups>>>
export type GetCommunityGroupsQueryError = unknown

export const useGetCommunityGroups = <TData = Awaited<ReturnType<typeof getCommunityGroups>>, TError = unknown>(
 siteId: string,
    params?: GetCommunityGroupsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCommunityGroups>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommunityGroupsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommunityGroups>>> = ({ signal }) => getCommunityGroups(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCommunityGroups>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new CommunityGroup
 */
export const createCommunityGroup = (
    siteId: string,
    communityGroup: CommunityGroup,
 
) => {
      return localInstance<CommunityGroup>(
      {url: `/sites/${siteId}/community/groups`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: communityGroup
    },
      );
    }
  


    export type CreateCommunityGroupMutationResult = NonNullable<Awaited<ReturnType<typeof createCommunityGroup>>>
    export type CreateCommunityGroupMutationBody = CommunityGroup
    export type CreateCommunityGroupMutationError = unknown

    export const useCreateCommunityGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCommunityGroup>>, TError,{siteId: string;data: CommunityGroup}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCommunityGroup>>, {siteId: string;data: CommunityGroup}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createCommunityGroup(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createCommunityGroup>>, TError, {siteId: string;data: CommunityGroup}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single CommunityGroup
 */
export const getCommunityGroup = (
    siteId: string,
    groupId: string,
 signal?: AbortSignal
) => {
      return localInstance<CommunityGroup>(
      {url: `/sites/${siteId}/community/groups/${groupId}`, method: 'get', signal
    },
      );
    }
  

export const getGetCommunityGroupQueryKey = (siteId: string,
    groupId: string,) => [`/sites/${siteId}/community/groups/${groupId}`];

    
export type GetCommunityGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getCommunityGroup>>>
export type GetCommunityGroupQueryError = unknown

export const useGetCommunityGroup = <TData = Awaited<ReturnType<typeof getCommunityGroup>>, TError = unknown>(
 siteId: string,
    groupId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCommunityGroup>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommunityGroupQueryKey(siteId,groupId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommunityGroup>>> = ({ signal }) => getCommunityGroup(siteId,groupId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCommunityGroup>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && groupId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates the given CommunityGroup
 */
export const updateCommunityGroup = (
    siteId: string,
    groupId: string,
    communityGroup: CommunityGroup,
 
) => {
      return localInstance<CommunityGroup>(
      {url: `/sites/${siteId}/community/groups/${groupId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: communityGroup
    },
      );
    }
  


    export type UpdateCommunityGroupMutationResult = NonNullable<Awaited<ReturnType<typeof updateCommunityGroup>>>
    export type UpdateCommunityGroupMutationBody = CommunityGroup
    export type UpdateCommunityGroupMutationError = unknown

    export const useUpdateCommunityGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCommunityGroup>>, TError,{siteId: string;groupId: string;data: CommunityGroup}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCommunityGroup>>, {siteId: string;groupId: string;data: CommunityGroup}> = (props) => {
          const {siteId,groupId,data} = props ?? {};

          return  updateCommunityGroup(siteId,groupId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateCommunityGroup>>, TError, {siteId: string;groupId: string;data: CommunityGroup}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the CommunityGroup
 */
export const deleteCommunityGroup = (
    siteId: string,
    groupId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/community/groups/${groupId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteCommunityGroupMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCommunityGroup>>>
    
    export type DeleteCommunityGroupMutationError = unknown

    export const useDeleteCommunityGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCommunityGroup>>, TError,{siteId: string;groupId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCommunityGroup>>, {siteId: string;groupId: string}> = (props) => {
          const {siteId,groupId} = props ?? {};

          return  deleteCommunityGroup(siteId,groupId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCommunityGroup>>, TError, {siteId: string;groupId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of all admins for the given CommunityGroup
 */
export const getCommunityGroupAdmins = (
    siteId: string,
    groupId: string,
    params?: GetCommunityGroupAdminsParams,
 signal?: AbortSignal
) => {
      return localInstance<CommunityUser>(
      {url: `/sites/${siteId}/community/groups/${groupId}/admins`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCommunityGroupAdminsQueryKey = (siteId: string,
    groupId: string,
    params?: GetCommunityGroupAdminsParams,) => [`/sites/${siteId}/community/groups/${groupId}/admins`, ...(params ? [params]: [])];

    
export type GetCommunityGroupAdminsQueryResult = NonNullable<Awaited<ReturnType<typeof getCommunityGroupAdmins>>>
export type GetCommunityGroupAdminsQueryError = unknown

export const useGetCommunityGroupAdmins = <TData = Awaited<ReturnType<typeof getCommunityGroupAdmins>>, TError = unknown>(
 siteId: string,
    groupId: string,
    params?: GetCommunityGroupAdminsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCommunityGroupAdmins>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommunityGroupAdminsQueryKey(siteId,groupId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommunityGroupAdmins>>> = ({ signal }) => getCommunityGroupAdmins(siteId,groupId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCommunityGroupAdmins>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && groupId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Adds a new admin to the given CommunityGroup
 */
export const addCommunityGroupAdmin = (
    siteId: string,
    groupId: string,
    communityUser: CommunityUser,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/community/groups/${groupId}/admins`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: communityUser
    },
      );
    }
  


    export type AddCommunityGroupAdminMutationResult = NonNullable<Awaited<ReturnType<typeof addCommunityGroupAdmin>>>
    export type AddCommunityGroupAdminMutationBody = CommunityUser
    export type AddCommunityGroupAdminMutationError = unknown

    export const useAddCommunityGroupAdmin = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCommunityGroupAdmin>>, TError,{siteId: string;groupId: string;data: CommunityUser}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addCommunityGroupAdmin>>, {siteId: string;groupId: string;data: CommunityUser}> = (props) => {
          const {siteId,groupId,data} = props ?? {};

          return  addCommunityGroupAdmin(siteId,groupId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof addCommunityGroupAdmin>>, TError, {siteId: string;groupId: string;data: CommunityUser}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Removes an admin from the given CommunityGroup
 */
export const removeCommunityGroupAdmin = (
    siteId: string,
    groupId: string,
    userId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/community/groups/${groupId}/admins/${userId}`, method: 'delete'
    },
      );
    }
  


    export type RemoveCommunityGroupAdminMutationResult = NonNullable<Awaited<ReturnType<typeof removeCommunityGroupAdmin>>>
    
    export type RemoveCommunityGroupAdminMutationError = unknown

    export const useRemoveCommunityGroupAdmin = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeCommunityGroupAdmin>>, TError,{siteId: string;groupId: string;userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeCommunityGroupAdmin>>, {siteId: string;groupId: string;userId: string}> = (props) => {
          const {siteId,groupId,userId} = props ?? {};

          return  removeCommunityGroupAdmin(siteId,groupId,userId,)
        }

      return useMutation<Awaited<ReturnType<typeof removeCommunityGroupAdmin>>, TError, {siteId: string;groupId: string;userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of all group forum and feed announcements
 */
export const getGroupAnnouncements = (
    siteId: string,
    groupId: string,
    params?: GetGroupAnnouncementsParams,
 signal?: AbortSignal
) => {
      return localInstance<Thread[]>(
      {url: `/sites/${siteId}/community/groups/${groupId}/announcements`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetGroupAnnouncementsQueryKey = (siteId: string,
    groupId: string,
    params?: GetGroupAnnouncementsParams,) => [`/sites/${siteId}/community/groups/${groupId}/announcements`, ...(params ? [params]: [])];

    
export type GetGroupAnnouncementsQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupAnnouncements>>>
export type GetGroupAnnouncementsQueryError = unknown

export const useGetGroupAnnouncements = <TData = Awaited<ReturnType<typeof getGroupAnnouncements>>, TError = unknown>(
 siteId: string,
    groupId: string,
    params?: GetGroupAnnouncementsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getGroupAnnouncements>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetGroupAnnouncementsQueryKey(siteId,groupId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupAnnouncements>>> = ({ signal }) => getGroupAnnouncements(siteId,groupId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getGroupAnnouncements>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && groupId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Approves the given pending member from the given CommunityGroup and moves to member
 */
export const approveCommunityGroupPendingMember = (
    siteId: string,
    groupId: string,
    userId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/community/groups/${groupId}/approve_member/${userId}`, method: 'post'
    },
      );
    }
  


    export type ApproveCommunityGroupPendingMemberMutationResult = NonNullable<Awaited<ReturnType<typeof approveCommunityGroupPendingMember>>>
    
    export type ApproveCommunityGroupPendingMemberMutationError = unknown

    export const useApproveCommunityGroupPendingMember = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof approveCommunityGroupPendingMember>>, TError,{siteId: string;groupId: string;userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof approveCommunityGroupPendingMember>>, {siteId: string;groupId: string;userId: string}> = (props) => {
          const {siteId,groupId,userId} = props ?? {};

          return  approveCommunityGroupPendingMember(siteId,groupId,userId,)
        }

      return useMutation<Awaited<ReturnType<typeof approveCommunityGroupPendingMember>>, TError, {siteId: string;groupId: string;userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns the `Feed` associated with the `CommunityGroup` if any
 */
export const getCommunityGroupFeed = (
    siteId: string,
    groupId: string,
    params?: GetCommunityGroupFeedParams,
 signal?: AbortSignal
) => {
      return localInstance<Feed>(
      {url: `/sites/${siteId}/community/groups/${groupId}/feed`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCommunityGroupFeedQueryKey = (siteId: string,
    groupId: string,
    params?: GetCommunityGroupFeedParams,) => [`/sites/${siteId}/community/groups/${groupId}/feed`, ...(params ? [params]: [])];

    
export type GetCommunityGroupFeedQueryResult = NonNullable<Awaited<ReturnType<typeof getCommunityGroupFeed>>>
export type GetCommunityGroupFeedQueryError = unknown

export const useGetCommunityGroupFeed = <TData = Awaited<ReturnType<typeof getCommunityGroupFeed>>, TError = unknown>(
 siteId: string,
    groupId: string,
    params?: GetCommunityGroupFeedParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCommunityGroupFeed>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommunityGroupFeedQueryKey(siteId,groupId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommunityGroupFeed>>> = ({ signal }) => getCommunityGroupFeed(siteId,groupId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCommunityGroupFeed>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && groupId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new feed on the given `CommunityGroup`
 */
export const createCommunityGroupFeed = (
    siteId: string,
    groupId: string,
    feed: Feed,
 
) => {
      return localInstance<Feed>(
      {url: `/sites/${siteId}/community/groups/${groupId}/feed`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: feed
    },
      );
    }
  


    export type CreateCommunityGroupFeedMutationResult = NonNullable<Awaited<ReturnType<typeof createCommunityGroupFeed>>>
    export type CreateCommunityGroupFeedMutationBody = Feed
    export type CreateCommunityGroupFeedMutationError = unknown

    export const useCreateCommunityGroupFeed = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCommunityGroupFeed>>, TError,{siteId: string;groupId: string;data: Feed}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCommunityGroupFeed>>, {siteId: string;groupId: string;data: Feed}> = (props) => {
          const {siteId,groupId,data} = props ?? {};

          return  createCommunityGroupFeed(siteId,groupId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createCommunityGroupFeed>>, TError, {siteId: string;groupId: string;data: Feed}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates an existing feed on the given `CommunityGroup`
 */
export const updateCommunityGroupFeed = (
    siteId: string,
    groupId: string,
    feed: Feed,
 
) => {
      return localInstance<Feed>(
      {url: `/sites/${siteId}/community/groups/${groupId}/feed`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: feed
    },
      );
    }
  


    export type UpdateCommunityGroupFeedMutationResult = NonNullable<Awaited<ReturnType<typeof updateCommunityGroupFeed>>>
    export type UpdateCommunityGroupFeedMutationBody = Feed
    export type UpdateCommunityGroupFeedMutationError = unknown

    export const useUpdateCommunityGroupFeed = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCommunityGroupFeed>>, TError,{siteId: string;groupId: string;data: Feed}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCommunityGroupFeed>>, {siteId: string;groupId: string;data: Feed}> = (props) => {
          const {siteId,groupId,data} = props ?? {};

          return  updateCommunityGroupFeed(siteId,groupId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateCommunityGroupFeed>>, TError, {siteId: string;groupId: string;data: Feed}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the `Feed` associated with the `CommunityGroup`
 */
export const deleteCommunityGroupFeed = (
    siteId: string,
    groupId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/community/groups/${groupId}/feed`, method: 'delete'
    },
      );
    }
  


    export type DeleteCommunityGroupFeedMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCommunityGroupFeed>>>
    
    export type DeleteCommunityGroupFeedMutationError = unknown

    export const useDeleteCommunityGroupFeed = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCommunityGroupFeed>>, TError,{siteId: string;groupId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCommunityGroupFeed>>, {siteId: string;groupId: string}> = (props) => {
          const {siteId,groupId} = props ?? {};

          return  deleteCommunityGroupFeed(siteId,groupId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCommunityGroupFeed>>, TError, {siteId: string;groupId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns the `Forum` associated with the `CommunityGroup` if any
 */
export const getCommunityGroupForum = (
    siteId: string,
    groupId: string,
    params?: GetCommunityGroupForumParams,
 signal?: AbortSignal
) => {
      return localInstance<Forum>(
      {url: `/sites/${siteId}/community/groups/${groupId}/forum`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCommunityGroupForumQueryKey = (siteId: string,
    groupId: string,
    params?: GetCommunityGroupForumParams,) => [`/sites/${siteId}/community/groups/${groupId}/forum`, ...(params ? [params]: [])];

    
export type GetCommunityGroupForumQueryResult = NonNullable<Awaited<ReturnType<typeof getCommunityGroupForum>>>
export type GetCommunityGroupForumQueryError = unknown

export const useGetCommunityGroupForum = <TData = Awaited<ReturnType<typeof getCommunityGroupForum>>, TError = unknown>(
 siteId: string,
    groupId: string,
    params?: GetCommunityGroupForumParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCommunityGroupForum>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommunityGroupForumQueryKey(siteId,groupId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommunityGroupForum>>> = ({ signal }) => getCommunityGroupForum(siteId,groupId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCommunityGroupForum>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && groupId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new `Forum` on the given `CommunityGroup`
 */
export const createCommunityGroupForum = (
    siteId: string,
    groupId: string,
    forum: Forum,
 
) => {
      return localInstance<Forum>(
      {url: `/sites/${siteId}/community/groups/${groupId}/forum`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: forum
    },
      );
    }
  


    export type CreateCommunityGroupForumMutationResult = NonNullable<Awaited<ReturnType<typeof createCommunityGroupForum>>>
    export type CreateCommunityGroupForumMutationBody = Forum
    export type CreateCommunityGroupForumMutationError = unknown

    export const useCreateCommunityGroupForum = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCommunityGroupForum>>, TError,{siteId: string;groupId: string;data: Forum}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCommunityGroupForum>>, {siteId: string;groupId: string;data: Forum}> = (props) => {
          const {siteId,groupId,data} = props ?? {};

          return  createCommunityGroupForum(siteId,groupId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createCommunityGroupForum>>, TError, {siteId: string;groupId: string;data: Forum}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates an existing `Forum` on the given `CommunityGroup`
 */
export const updateCommunityGroupForum = (
    siteId: string,
    groupId: string,
    forum: Forum,
 
) => {
      return localInstance<Forum>(
      {url: `/sites/${siteId}/community/groups/${groupId}/forum`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: forum
    },
      );
    }
  


    export type UpdateCommunityGroupForumMutationResult = NonNullable<Awaited<ReturnType<typeof updateCommunityGroupForum>>>
    export type UpdateCommunityGroupForumMutationBody = Forum
    export type UpdateCommunityGroupForumMutationError = unknown

    export const useUpdateCommunityGroupForum = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCommunityGroupForum>>, TError,{siteId: string;groupId: string;data: Forum}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCommunityGroupForum>>, {siteId: string;groupId: string;data: Forum}> = (props) => {
          const {siteId,groupId,data} = props ?? {};

          return  updateCommunityGroupForum(siteId,groupId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateCommunityGroupForum>>, TError, {siteId: string;groupId: string;data: Forum}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the `Forum` associated with the `CommunityGroup`
 */
export const deleteCommunityGroupForum = (
    siteId: string,
    groupId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/community/groups/${groupId}/forum`, method: 'delete'
    },
      );
    }
  


    export type DeleteCommunityGroupForumMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCommunityGroupForum>>>
    
    export type DeleteCommunityGroupForumMutationError = unknown

    export const useDeleteCommunityGroupForum = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCommunityGroupForum>>, TError,{siteId: string;groupId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCommunityGroupForum>>, {siteId: string;groupId: string}> = (props) => {
          const {siteId,groupId} = props ?? {};

          return  deleteCommunityGroupForum(siteId,groupId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCommunityGroupForum>>, TError, {siteId: string;groupId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all members for the given CommunityGroup
 */
export const getCommunityGroupMembers = (
    siteId: string,
    groupId: string,
    params?: GetCommunityGroupMembersParams,
 signal?: AbortSignal
) => {
      return localInstance<CommunityUser[]>(
      {url: `/sites/${siteId}/community/groups/${groupId}/members`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCommunityGroupMembersQueryKey = (siteId: string,
    groupId: string,
    params?: GetCommunityGroupMembersParams,) => [`/sites/${siteId}/community/groups/${groupId}/members`, ...(params ? [params]: [])];

    
export type GetCommunityGroupMembersQueryResult = NonNullable<Awaited<ReturnType<typeof getCommunityGroupMembers>>>
export type GetCommunityGroupMembersQueryError = unknown

export const useGetCommunityGroupMembers = <TData = Awaited<ReturnType<typeof getCommunityGroupMembers>>, TError = unknown>(
 siteId: string,
    groupId: string,
    params?: GetCommunityGroupMembersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCommunityGroupMembers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommunityGroupMembersQueryKey(siteId,groupId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommunityGroupMembers>>> = ({ signal }) => getCommunityGroupMembers(siteId,groupId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCommunityGroupMembers>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && groupId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Adds a new member to the given CommunityGroup
 */
export const addCommunityGroupMember = (
    siteId: string,
    groupId: string,
    communityUser: CommunityUser,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/community/groups/${groupId}/members`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: communityUser
    },
      );
    }
  


    export type AddCommunityGroupMemberMutationResult = NonNullable<Awaited<ReturnType<typeof addCommunityGroupMember>>>
    export type AddCommunityGroupMemberMutationBody = CommunityUser
    export type AddCommunityGroupMemberMutationError = unknown

    export const useAddCommunityGroupMember = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCommunityGroupMember>>, TError,{siteId: string;groupId: string;data: CommunityUser}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addCommunityGroupMember>>, {siteId: string;groupId: string;data: CommunityUser}> = (props) => {
          const {siteId,groupId,data} = props ?? {};

          return  addCommunityGroupMember(siteId,groupId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof addCommunityGroupMember>>, TError, {siteId: string;groupId: string;data: CommunityUser}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the given member from the given CommunityGroup
 */
export const removeCommunityGroupMember = (
    siteId: string,
    groupId: string,
    userId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/community/groups/${groupId}/members/${userId}`, method: 'delete'
    },
      );
    }
  


    export type RemoveCommunityGroupMemberMutationResult = NonNullable<Awaited<ReturnType<typeof removeCommunityGroupMember>>>
    
    export type RemoveCommunityGroupMemberMutationError = unknown

    export const useRemoveCommunityGroupMember = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeCommunityGroupMember>>, TError,{siteId: string;groupId: string;userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeCommunityGroupMember>>, {siteId: string;groupId: string;userId: string}> = (props) => {
          const {siteId,groupId,userId} = props ?? {};

          return  removeCommunityGroupMember(siteId,groupId,userId,)
        }

      return useMutation<Awaited<ReturnType<typeof removeCommunityGroupMember>>, TError, {siteId: string;groupId: string;userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all members for the given CommunityGroup
 */
export const getCommunityGroupPendingMembers = (
    siteId: string,
    groupId: string,
    params?: GetCommunityGroupPendingMembersParams,
 signal?: AbortSignal
) => {
      return localInstance<CommunityUser[]>(
      {url: `/sites/${siteId}/community/groups/${groupId}/pending_members`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCommunityGroupPendingMembersQueryKey = (siteId: string,
    groupId: string,
    params?: GetCommunityGroupPendingMembersParams,) => [`/sites/${siteId}/community/groups/${groupId}/pending_members`, ...(params ? [params]: [])];

    
export type GetCommunityGroupPendingMembersQueryResult = NonNullable<Awaited<ReturnType<typeof getCommunityGroupPendingMembers>>>
export type GetCommunityGroupPendingMembersQueryError = unknown

export const useGetCommunityGroupPendingMembers = <TData = Awaited<ReturnType<typeof getCommunityGroupPendingMembers>>, TError = unknown>(
 siteId: string,
    groupId: string,
    params?: GetCommunityGroupPendingMembersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCommunityGroupPendingMembers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommunityGroupPendingMembersQueryKey(siteId,groupId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommunityGroupPendingMembers>>> = ({ signal }) => getCommunityGroupPendingMembers(siteId,groupId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCommunityGroupPendingMembers>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && groupId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Adds a new pending member to the given CommunityGroup
 */
export const addCommunityGroupPendingMember = (
    siteId: string,
    groupId: string,
    communityUser: CommunityUser,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/community/groups/${groupId}/pending_members`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: communityUser
    },
      );
    }
  


    export type AddCommunityGroupPendingMemberMutationResult = NonNullable<Awaited<ReturnType<typeof addCommunityGroupPendingMember>>>
    export type AddCommunityGroupPendingMemberMutationBody = CommunityUser
    export type AddCommunityGroupPendingMemberMutationError = unknown

    export const useAddCommunityGroupPendingMember = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCommunityGroupPendingMember>>, TError,{siteId: string;groupId: string;data: CommunityUser}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addCommunityGroupPendingMember>>, {siteId: string;groupId: string;data: CommunityUser}> = (props) => {
          const {siteId,groupId,data} = props ?? {};

          return  addCommunityGroupPendingMember(siteId,groupId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof addCommunityGroupPendingMember>>, TError, {siteId: string;groupId: string;data: CommunityUser}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the given pending member from the given CommunityGroup
 */
export const removeCommunityGroupPendingMember = (
    siteId: string,
    groupId: string,
    userId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/community/groups/${groupId}/pending_members/${userId}`, method: 'delete'
    },
      );
    }
  


    export type RemoveCommunityGroupPendingMemberMutationResult = NonNullable<Awaited<ReturnType<typeof removeCommunityGroupPendingMember>>>
    
    export type RemoveCommunityGroupPendingMemberMutationError = unknown

    export const useRemoveCommunityGroupPendingMember = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeCommunityGroupPendingMember>>, TError,{siteId: string;groupId: string;userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeCommunityGroupPendingMember>>, {siteId: string;groupId: string;userId: string}> = (props) => {
          const {siteId,groupId,userId} = props ?? {};

          return  removeCommunityGroupPendingMember(siteId,groupId,userId,)
        }

      return useMutation<Awaited<ReturnType<typeof removeCommunityGroupPendingMember>>, TError, {siteId: string;groupId: string;userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all GroupResources for the given CommunityGroup
 */
export const getGroupResources = (
    siteId: string,
    groupId: string,
    params?: GetGroupResourcesParams,
 signal?: AbortSignal
) => {
      return localInstance<GroupResource[]>(
      {url: `/sites/${siteId}/community/groups/${groupId}/resources`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetGroupResourcesQueryKey = (siteId: string,
    groupId: string,
    params?: GetGroupResourcesParams,) => [`/sites/${siteId}/community/groups/${groupId}/resources`, ...(params ? [params]: [])];

    
export type GetGroupResourcesQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupResources>>>
export type GetGroupResourcesQueryError = unknown

export const useGetGroupResources = <TData = Awaited<ReturnType<typeof getGroupResources>>, TError = unknown>(
 siteId: string,
    groupId: string,
    params?: GetGroupResourcesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getGroupResources>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetGroupResourcesQueryKey(siteId,groupId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupResources>>> = ({ signal }) => getGroupResources(siteId,groupId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getGroupResources>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && groupId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates new GroupResources
 */
export const createGroupResources = (
    siteId: string,
    groupId: string,
    groupResource: GroupResource[],
 
) => {
      return localInstance<GroupResource[]>(
      {url: `/sites/${siteId}/community/groups/${groupId}/resources`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: groupResource
    },
      );
    }
  


    export type CreateGroupResourcesMutationResult = NonNullable<Awaited<ReturnType<typeof createGroupResources>>>
    export type CreateGroupResourcesMutationBody = GroupResource[]
    export type CreateGroupResourcesMutationError = unknown

    export const useCreateGroupResources = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGroupResources>>, TError,{siteId: string;groupId: string;data: GroupResource[]}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGroupResources>>, {siteId: string;groupId: string;data: GroupResource[]}> = (props) => {
          const {siteId,groupId,data} = props ?? {};

          return  createGroupResources(siteId,groupId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createGroupResources>>, TError, {siteId: string;groupId: string;data: GroupResource[]}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single GroupResource
 */
export const getGroupResource = (
    siteId: string,
    groupId: string,
    resourceId: string,
    params?: GetGroupResourceParams,
 signal?: AbortSignal
) => {
      return localInstance<GroupResource>(
      {url: `/sites/${siteId}/community/groups/${groupId}/resources/${resourceId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetGroupResourceQueryKey = (siteId: string,
    groupId: string,
    resourceId: string,
    params?: GetGroupResourceParams,) => [`/sites/${siteId}/community/groups/${groupId}/resources/${resourceId}`, ...(params ? [params]: [])];

    
export type GetGroupResourceQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupResource>>>
export type GetGroupResourceQueryError = unknown

export const useGetGroupResource = <TData = Awaited<ReturnType<typeof getGroupResource>>, TError = unknown>(
 siteId: string,
    groupId: string,
    resourceId: string,
    params?: GetGroupResourceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getGroupResource>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetGroupResourceQueryKey(siteId,groupId,resourceId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupResource>>> = ({ signal }) => getGroupResource(siteId,groupId,resourceId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getGroupResource>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && groupId && resourceId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new GroupResource
 */
export const createGroupResource = (
    siteId: string,
    groupId: string,
    resourceId: string,
    groupResource: GroupResource,
 
) => {
      return localInstance<GroupResource>(
      {url: `/sites/${siteId}/community/groups/${groupId}/resources/${resourceId}`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: groupResource
    },
      );
    }
  


    export type CreateGroupResourceMutationResult = NonNullable<Awaited<ReturnType<typeof createGroupResource>>>
    export type CreateGroupResourceMutationBody = GroupResource
    export type CreateGroupResourceMutationError = unknown

    export const useCreateGroupResource = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGroupResource>>, TError,{siteId: string;groupId: string;resourceId: string;data: GroupResource}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGroupResource>>, {siteId: string;groupId: string;resourceId: string;data: GroupResource}> = (props) => {
          const {siteId,groupId,resourceId,data} = props ?? {};

          return  createGroupResource(siteId,groupId,resourceId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createGroupResource>>, TError, {siteId: string;groupId: string;resourceId: string;data: GroupResource}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates the given Download attached to the given GroupResource
 */
export const updateGroupResource = (
    siteId: string,
    groupId: string,
    resourceId: string,
    groupResource: GroupResource,
 
) => {
      return localInstance<GroupResource>(
      {url: `/sites/${siteId}/community/groups/${groupId}/resources/${resourceId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: groupResource
    },
      );
    }
  


    export type UpdateGroupResourceMutationResult = NonNullable<Awaited<ReturnType<typeof updateGroupResource>>>
    export type UpdateGroupResourceMutationBody = GroupResource
    export type UpdateGroupResourceMutationError = unknown

    export const useUpdateGroupResource = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGroupResource>>, TError,{siteId: string;groupId: string;resourceId: string;data: GroupResource}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateGroupResource>>, {siteId: string;groupId: string;resourceId: string;data: GroupResource}> = (props) => {
          const {siteId,groupId,resourceId,data} = props ?? {};

          return  updateGroupResource(siteId,groupId,resourceId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateGroupResource>>, TError, {siteId: string;groupId: string;resourceId: string;data: GroupResource}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a single GroupResource
 */
export const deleteGroupResource = (
    siteId: string,
    groupId: string,
    resourceId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/community/groups/${groupId}/resources/${resourceId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteGroupResourceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteGroupResource>>>
    
    export type DeleteGroupResourceMutationError = unknown

    export const useDeleteGroupResource = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGroupResource>>, TError,{siteId: string;groupId: string;resourceId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteGroupResource>>, {siteId: string;groupId: string;resourceId: string}> = (props) => {
          const {siteId,groupId,resourceId} = props ?? {};

          return  deleteGroupResource(siteId,groupId,resourceId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteGroupResource>>, TError, {siteId: string;groupId: string;resourceId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for all CommunityGroups for a Site
 */
export const getCommunityGroupsHierarchyAdmin = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<string[]>(
      {url: `/sites/${siteId}/community/groups/admin_hierarchy`, method: 'get', signal
    },
      );
    }
  

export const getGetCommunityGroupsHierarchyAdminQueryKey = (siteId: string,) => [`/sites/${siteId}/community/groups/admin_hierarchy`];

    
export type GetCommunityGroupsHierarchyAdminQueryResult = NonNullable<Awaited<ReturnType<typeof getCommunityGroupsHierarchyAdmin>>>
export type GetCommunityGroupsHierarchyAdminQueryError = unknown

export const useGetCommunityGroupsHierarchyAdmin = <TData = Awaited<ReturnType<typeof getCommunityGroupsHierarchyAdmin>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCommunityGroupsHierarchyAdmin>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommunityGroupsHierarchyAdminQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommunityGroupsHierarchyAdmin>>> = ({ signal }) => getCommunityGroupsHierarchyAdmin(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCommunityGroupsHierarchyAdmin>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for all CommunityGroups for a Site
 */
export const getCommunityGroupsHierarchyMember = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<string[]>(
      {url: `/sites/${siteId}/community/groups/member_hierarchy`, method: 'get', signal
    },
      );
    }
  

export const getGetCommunityGroupsHierarchyMemberQueryKey = (siteId: string,) => [`/sites/${siteId}/community/groups/member_hierarchy`];

    
export type GetCommunityGroupsHierarchyMemberQueryResult = NonNullable<Awaited<ReturnType<typeof getCommunityGroupsHierarchyMember>>>
export type GetCommunityGroupsHierarchyMemberQueryError = unknown

export const useGetCommunityGroupsHierarchyMember = <TData = Awaited<ReturnType<typeof getCommunityGroupsHierarchyMember>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCommunityGroupsHierarchyMember>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCommunityGroupsHierarchyMemberQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCommunityGroupsHierarchyMember>>> = ({ signal }) => getCommunityGroupsHierarchyMember(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCommunityGroupsHierarchyMember>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns a list of all Threads for the given parameters
 */
export const getAllThreads = (
    siteId: string,
    params?: GetAllThreadsParams,
 signal?: AbortSignal
) => {
      return localInstance<Thread[]>(
      {url: `/sites/${siteId}/community/threads`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllThreadsQueryKey = (siteId: string,
    params?: GetAllThreadsParams,) => [`/sites/${siteId}/community/threads`, ...(params ? [params]: [])];

    
export type GetAllThreadsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllThreads>>>
export type GetAllThreadsQueryError = unknown

export const useGetAllThreads = <TData = Awaited<ReturnType<typeof getAllThreads>>, TError = unknown>(
 siteId: string,
    params?: GetAllThreadsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllThreads>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllThreadsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllThreads>>> = ({ signal }) => getAllThreads(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllThreads>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns the given `Thread`
 */
export const getThread = (
    siteId: string,
    threadId: string,
 signal?: AbortSignal
) => {
      return localInstance<Thread>(
      {url: `/sites/${siteId}/community/threads/${threadId}`, method: 'get', signal
    },
      );
    }
  

export const getGetThreadQueryKey = (siteId: string,
    threadId: string,) => [`/sites/${siteId}/community/threads/${threadId}`];

    
export type GetThreadQueryResult = NonNullable<Awaited<ReturnType<typeof getThread>>>
export type GetThreadQueryError = unknown

export const useGetThread = <TData = Awaited<ReturnType<typeof getThread>>, TError = unknown>(
 siteId: string,
    threadId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getThread>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetThreadQueryKey(siteId,threadId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getThread>>> = ({ signal }) => getThread(siteId,threadId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getThread>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && threadId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates the given `Thread`
 */
export const updateThread = (
    siteId: string,
    threadId: string,
    thread: Thread,
 
) => {
      return localInstance<Thread>(
      {url: `/sites/${siteId}/community/threads/${threadId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: thread
    },
      );
    }
  


    export type UpdateThreadMutationResult = NonNullable<Awaited<ReturnType<typeof updateThread>>>
    export type UpdateThreadMutationBody = Thread
    export type UpdateThreadMutationError = unknown

    export const useUpdateThread = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateThread>>, TError,{siteId: string;threadId: string;data: Thread}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateThread>>, {siteId: string;threadId: string;data: Thread}> = (props) => {
          const {siteId,threadId,data} = props ?? {};

          return  updateThread(siteId,threadId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateThread>>, TError, {siteId: string;threadId: string;data: Thread}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the given `Thread`
 */
export const deleteThread = (
    siteId: string,
    threadId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/community/threads/${threadId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteThreadMutationResult = NonNullable<Awaited<ReturnType<typeof deleteThread>>>
    
    export type DeleteThreadMutationError = unknown

    export const useDeleteThread = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteThread>>, TError,{siteId: string;threadId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteThread>>, {siteId: string;threadId: string}> = (props) => {
          const {siteId,threadId} = props ?? {};

          return  deleteThread(siteId,threadId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteThread>>, TError, {siteId: string;threadId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of all child Threads in the given Thread
 */
export const getChildThreads = (
    siteId: string,
    threadId: string,
    params?: GetChildThreadsParams,
 signal?: AbortSignal
) => {
      return localInstance<Thread[]>(
      {url: `/sites/${siteId}/community/threads/${threadId}/child_threads`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetChildThreadsQueryKey = (siteId: string,
    threadId: string,
    params?: GetChildThreadsParams,) => [`/sites/${siteId}/community/threads/${threadId}/child_threads`, ...(params ? [params]: [])];

    
export type GetChildThreadsQueryResult = NonNullable<Awaited<ReturnType<typeof getChildThreads>>>
export type GetChildThreadsQueryError = unknown

export const useGetChildThreads = <TData = Awaited<ReturnType<typeof getChildThreads>>, TError = unknown>(
 siteId: string,
    threadId: string,
    params?: GetChildThreadsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getChildThreads>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetChildThreadsQueryKey(siteId,threadId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getChildThreads>>> = ({ signal }) => getChildThreads(siteId,threadId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getChildThreads>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && threadId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for liking and unliking a thread
 */
export const likeUnlikeThread = (
    siteId: string,
    threadId: string,
    thread: Thread,
 
) => {
      return localInstance<Thread>(
      {url: `/sites/${siteId}/community/threads/${threadId}/like_unlike`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: thread
    },
      );
    }
  


    export type LikeUnlikeThreadMutationResult = NonNullable<Awaited<ReturnType<typeof likeUnlikeThread>>>
    export type LikeUnlikeThreadMutationBody = Thread
    export type LikeUnlikeThreadMutationError = unknown

    export const useLikeUnlikeThread = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof likeUnlikeThread>>, TError,{siteId: string;threadId: string;data: Thread}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof likeUnlikeThread>>, {siteId: string;threadId: string;data: Thread}> = (props) => {
          const {siteId,threadId,data} = props ?? {};

          return  likeUnlikeThread(siteId,threadId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof likeUnlikeThread>>, TError, {siteId: string;threadId: string;data: Thread}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for all Components for a Client
 */
export const getComponents = (
    siteId: string,
    params?: GetComponentsParams,
 signal?: AbortSignal
) => {
      return localInstance<Component[]>(
      {url: `/sites/${siteId}/components`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetComponentsQueryKey = (siteId: string,
    params?: GetComponentsParams,) => [`/sites/${siteId}/components`, ...(params ? [params]: [])];

    
export type GetComponentsQueryResult = NonNullable<Awaited<ReturnType<typeof getComponents>>>
export type GetComponentsQueryError = unknown

export const useGetComponents = <TData = Awaited<ReturnType<typeof getComponents>>, TError = unknown>(
 siteId: string,
    params?: GetComponentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getComponents>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetComponentsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getComponents>>> = ({ signal }) => getComponents(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getComponents>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for all Components for a Client
 */
export const createComponent = (
    siteId: string,
    component: Component,
 
) => {
      return localInstance<Component>(
      {url: `/sites/${siteId}/components`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: component
    },
      );
    }
  


    export type CreateComponentMutationResult = NonNullable<Awaited<ReturnType<typeof createComponent>>>
    export type CreateComponentMutationBody = Component
    export type CreateComponentMutationError = unknown

    export const useCreateComponent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createComponent>>, TError,{siteId: string;data: Component}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createComponent>>, {siteId: string;data: Component}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createComponent(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createComponent>>, TError, {siteId: string;data: Component}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Component for a Client
 */
export const getComponent = (
    siteId: string,
    componentId: string,
    params?: GetComponentParams,
 signal?: AbortSignal
) => {
      return localInstance<Component>(
      {url: `/sites/${siteId}/components/${componentId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetComponentQueryKey = (siteId: string,
    componentId: string,
    params?: GetComponentParams,) => [`/sites/${siteId}/components/${componentId}`, ...(params ? [params]: [])];

    
export type GetComponentQueryResult = NonNullable<Awaited<ReturnType<typeof getComponent>>>
export type GetComponentQueryError = unknown

export const useGetComponent = <TData = Awaited<ReturnType<typeof getComponent>>, TError = unknown>(
 siteId: string,
    componentId: string,
    params?: GetComponentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getComponent>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetComponentQueryKey(siteId,componentId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getComponent>>> = ({ signal }) => getComponent(siteId,componentId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getComponent>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && componentId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single Component for a Client
 */
export const updateComponent = (
    siteId: string,
    componentId: string,
    component: Component,
 
) => {
      return localInstance<Component>(
      {url: `/sites/${siteId}/components/${componentId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: component
    },
      );
    }
  


    export type UpdateComponentMutationResult = NonNullable<Awaited<ReturnType<typeof updateComponent>>>
    export type UpdateComponentMutationBody = Component
    export type UpdateComponentMutationError = unknown

    export const useUpdateComponent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateComponent>>, TError,{siteId: string;componentId: string;data: Component}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateComponent>>, {siteId: string;componentId: string;data: Component}> = (props) => {
          const {siteId,componentId,data} = props ?? {};

          return  updateComponent(siteId,componentId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateComponent>>, TError, {siteId: string;componentId: string;data: Component}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Component for a Client
 */
export const deleteComponent = (
    siteId: string,
    componentId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/components/${componentId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteComponentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteComponent>>>
    
    export type DeleteComponentMutationError = unknown

    export const useDeleteComponent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteComponent>>, TError,{siteId: string;componentId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteComponent>>, {siteId: string;componentId: string}> = (props) => {
          const {siteId,componentId} = props ?? {};

          return  deleteComponent(siteId,componentId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteComponent>>, TError, {siteId: string;componentId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Content Report
 */
export const getContentReport = (
    siteId: string,
    contentReportId: string,
    params?: GetContentReportParams,
 signal?: AbortSignal
) => {
      return localInstance<ContentReportWithUsers>(
      {url: `/sites/${siteId}/content_report/${contentReportId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetContentReportQueryKey = (siteId: string,
    contentReportId: string,
    params?: GetContentReportParams,) => [`/sites/${siteId}/content_report/${contentReportId}`, ...(params ? [params]: [])];

    
export type GetContentReportQueryResult = NonNullable<Awaited<ReturnType<typeof getContentReport>>>
export type GetContentReportQueryError = unknown

export const useGetContentReport = <TData = Awaited<ReturnType<typeof getContentReport>>, TError = unknown>(
 siteId: string,
    contentReportId: string,
    params?: GetContentReportParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getContentReport>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetContentReportQueryKey(siteId,contentReportId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContentReport>>> = ({ signal }) => getContentReport(siteId,contentReportId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getContentReport>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && contentReportId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single Content Report
 */
export const updateContentReport = (
    siteId: string,
    contentReportId: string,
    contentReport: ContentReport,
 
) => {
      return localInstance<ContentReportWithUsers>(
      {url: `/sites/${siteId}/content_report/${contentReportId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: contentReport
    },
      );
    }
  


    export type UpdateContentReportMutationResult = NonNullable<Awaited<ReturnType<typeof updateContentReport>>>
    export type UpdateContentReportMutationBody = ContentReport
    export type UpdateContentReportMutationError = unknown

    export const useUpdateContentReport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateContentReport>>, TError,{siteId: string;contentReportId: string;data: ContentReport}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateContentReport>>, {siteId: string;contentReportId: string;data: ContentReport}> = (props) => {
          const {siteId,contentReportId,data} = props ?? {};

          return  updateContentReport(siteId,contentReportId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateContentReport>>, TError, {siteId: string;contentReportId: string;data: ContentReport}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Content Report
 */
export const deleteContentReport = (
    siteId: string,
    contentReportId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/content_report/${contentReportId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteContentReportMutationResult = NonNullable<Awaited<ReturnType<typeof deleteContentReport>>>
    
    export type DeleteContentReportMutationError = unknown

    export const useDeleteContentReport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteContentReport>>, TError,{siteId: string;contentReportId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteContentReport>>, {siteId: string;contentReportId: string}> = (props) => {
          const {siteId,contentReportId} = props ?? {};

          return  deleteContentReport(siteId,contentReportId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteContentReport>>, TError, {siteId: string;contentReportId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views on the Client collection
 */
export const getContentReports = (
    siteId: string,
    parentType: string,
    params?: GetContentReportsParams,
 signal?: AbortSignal
) => {
      return localInstance<ContentReportWithUsers[]>(
      {url: `/sites/${siteId}/content_reports/${parentType}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetContentReportsQueryKey = (siteId: string,
    parentType: string,
    params?: GetContentReportsParams,) => [`/sites/${siteId}/content_reports/${parentType}`, ...(params ? [params]: [])];

    
export type GetContentReportsQueryResult = NonNullable<Awaited<ReturnType<typeof getContentReports>>>
export type GetContentReportsQueryError = unknown

export const useGetContentReports = <TData = Awaited<ReturnType<typeof getContentReports>>, TError = unknown>(
 siteId: string,
    parentType: string,
    params?: GetContentReportsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getContentReports>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetContentReportsQueryKey(siteId,parentType,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContentReports>>> = ({ signal }) => getContentReports(siteId,parentType,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getContentReports>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && parentType), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Content Report
 */
export const createContentReport = (
    siteId: string,
    parentType: string,
    contentReport: ContentReport,
    params?: CreateContentReportParams,
 
) => {
      return localInstance<ContentReport>(
      {url: `/sites/${siteId}/content_reports/${parentType}`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: contentReport,
        params,
    },
      );
    }
  


    export type CreateContentReportMutationResult = NonNullable<Awaited<ReturnType<typeof createContentReport>>>
    export type CreateContentReportMutationBody = ContentReport
    export type CreateContentReportMutationError = unknown

    export const useCreateContentReport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContentReport>>, TError,{siteId: string;parentType: string;data: ContentReport;params?: CreateContentReportParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createContentReport>>, {siteId: string;parentType: string;data: ContentReport;params?: CreateContentReportParams}> = (props) => {
          const {siteId,parentType,data,params} = props ?? {};

          return  createContentReport(siteId,parentType,data,params,)
        }

      return useMutation<Awaited<ReturnType<typeof createContentReport>>, TError, {siteId: string;parentType: string;data: ContentReport;params?: CreateContentReportParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Content Report
 */
export const batchUpdateContentReports = (
    siteId: string,
    params?: BatchUpdateContentReportsParams,
 
) => {
      return localInstance<BatchUpdateResponse>(
      {url: `/sites/${siteId}/content_reports_batch`, method: 'put',
        params,
    },
      );
    }
  


    export type BatchUpdateContentReportsMutationResult = NonNullable<Awaited<ReturnType<typeof batchUpdateContentReports>>>
    
    export type BatchUpdateContentReportsMutationError = unknown

    export const useBatchUpdateContentReports = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchUpdateContentReports>>, TError,{siteId: string;params?: BatchUpdateContentReportsParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof batchUpdateContentReports>>, {siteId: string;params?: BatchUpdateContentReportsParams}> = (props) => {
          const {siteId,params} = props ?? {};

          return  batchUpdateContentReports(siteId,params,)
        }

      return useMutation<Awaited<ReturnType<typeof batchUpdateContentReports>>, TError, {siteId: string;params?: BatchUpdateContentReportsParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views to return a count of content reports that need review
 */
export const getContentReportsCount = (
    siteId: string,
    params?: GetContentReportsCountParams,
 signal?: AbortSignal
) => {
      return localInstance<ContentReportsCount>(
      {url: `/sites/${siteId}/content_reports_count`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetContentReportsCountQueryKey = (siteId: string,
    params?: GetContentReportsCountParams,) => [`/sites/${siteId}/content_reports_count`, ...(params ? [params]: [])];

    
export type GetContentReportsCountQueryResult = NonNullable<Awaited<ReturnType<typeof getContentReportsCount>>>
export type GetContentReportsCountQueryError = unknown

export const useGetContentReportsCount = <TData = Awaited<ReturnType<typeof getContentReportsCount>>, TError = unknown>(
 siteId: string,
    params?: GetContentReportsCountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getContentReportsCount>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetContentReportsCountQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContentReportsCount>>> = ({ signal }) => getContentReportsCount(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getContentReportsCount>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Gets the shareable url for a content object
 */
export const getContentUrl = (
    siteId: string,
    contentId: string,
    contentSlug: string,
    params?: GetContentUrlParams,
 signal?: AbortSignal
) => {
      return localInstance<ContentUrl>(
      {url: `/sites/${siteId}/content_url/${contentId}/${contentSlug}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetContentUrlQueryKey = (siteId: string,
    contentId: string,
    contentSlug: string,
    params?: GetContentUrlParams,) => [`/sites/${siteId}/content_url/${contentId}/${contentSlug}`, ...(params ? [params]: [])];

    
export type GetContentUrlQueryResult = NonNullable<Awaited<ReturnType<typeof getContentUrl>>>
export type GetContentUrlQueryError = unknown

export const useGetContentUrl = <TData = Awaited<ReturnType<typeof getContentUrl>>, TError = unknown>(
 siteId: string,
    contentId: string,
    contentSlug: string,
    params?: GetContentUrlParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getContentUrl>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetContentUrlQueryKey(siteId,contentId,contentSlug,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getContentUrl>>> = ({ signal }) => getContentUrl(siteId,contentId,contentSlug,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getContentUrl>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && contentId && contentSlug), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns all CourseRequirements
 */
export const getAllCourseRequirements = (
    siteId: string,
    params?: GetAllCourseRequirementsParams,
 signal?: AbortSignal
) => {
      return localInstance<CourseRequirement[]>(
      {url: `/sites/${siteId}/course_requirements`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllCourseRequirementsQueryKey = (siteId: string,
    params?: GetAllCourseRequirementsParams,) => [`/sites/${siteId}/course_requirements`, ...(params ? [params]: [])];

    
export type GetAllCourseRequirementsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllCourseRequirements>>>
export type GetAllCourseRequirementsQueryError = unknown

export const useGetAllCourseRequirements = <TData = Awaited<ReturnType<typeof getAllCourseRequirements>>, TError = unknown>(
 siteId: string,
    params?: GetAllCourseRequirementsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllCourseRequirements>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllCourseRequirementsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllCourseRequirements>>> = ({ signal }) => getAllCourseRequirements(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllCourseRequirements>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new CourseRequirement
 */
export const createCourseRequirement = (
    siteId: string,
    courseRequirement: CourseRequirement,
 
) => {
      return localInstance<CourseRequirement>(
      {url: `/sites/${siteId}/course_requirements`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: courseRequirement
    },
      );
    }
  


    export type CreateCourseRequirementMutationResult = NonNullable<Awaited<ReturnType<typeof createCourseRequirement>>>
    export type CreateCourseRequirementMutationBody = CourseRequirement
    export type CreateCourseRequirementMutationError = unknown

    export const useCreateCourseRequirement = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCourseRequirement>>, TError,{siteId: string;data: CourseRequirement}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCourseRequirement>>, {siteId: string;data: CourseRequirement}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createCourseRequirement(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createCourseRequirement>>, TError, {siteId: string;data: CourseRequirement}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single CourseRequirement
 */
export const getCourseRequirement = (
    siteId: string,
    requirementId: string,
    params?: GetCourseRequirementParams,
 signal?: AbortSignal
) => {
      return localInstance<CourseRequirement>(
      {url: `/sites/${siteId}/course_requirements/${requirementId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCourseRequirementQueryKey = (siteId: string,
    requirementId: string,
    params?: GetCourseRequirementParams,) => [`/sites/${siteId}/course_requirements/${requirementId}`, ...(params ? [params]: [])];

    
export type GetCourseRequirementQueryResult = NonNullable<Awaited<ReturnType<typeof getCourseRequirement>>>
export type GetCourseRequirementQueryError = unknown

export const useGetCourseRequirement = <TData = Awaited<ReturnType<typeof getCourseRequirement>>, TError = unknown>(
 siteId: string,
    requirementId: string,
    params?: GetCourseRequirementParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCourseRequirement>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCourseRequirementQueryKey(siteId,requirementId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCourseRequirement>>> = ({ signal }) => getCourseRequirement(siteId,requirementId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCourseRequirement>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && requirementId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates a CourseRequirement
 */
export const updateCourseRequirement = (
    siteId: string,
    requirementId: string,
    courseRequirement: CourseRequirement,
 
) => {
      return localInstance<CourseRequirement>(
      {url: `/sites/${siteId}/course_requirements/${requirementId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: courseRequirement
    },
      );
    }
  


    export type UpdateCourseRequirementMutationResult = NonNullable<Awaited<ReturnType<typeof updateCourseRequirement>>>
    export type UpdateCourseRequirementMutationBody = CourseRequirement
    export type UpdateCourseRequirementMutationError = unknown

    export const useUpdateCourseRequirement = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCourseRequirement>>, TError,{siteId: string;requirementId: string;data: CourseRequirement}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCourseRequirement>>, {siteId: string;requirementId: string;data: CourseRequirement}> = (props) => {
          const {siteId,requirementId,data} = props ?? {};

          return  updateCourseRequirement(siteId,requirementId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateCourseRequirement>>, TError, {siteId: string;requirementId: string;data: CourseRequirement}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a CourseRequirement
 */
export const deleteCourseRequirement = (
    siteId: string,
    requirementId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/course_requirements/${requirementId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteCourseRequirementMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCourseRequirement>>>
    
    export type DeleteCourseRequirementMutationError = unknown

    export const useDeleteCourseRequirement = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCourseRequirement>>, TError,{siteId: string;requirementId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCourseRequirement>>, {siteId: string;requirementId: string}> = (props) => {
          const {siteId,requirementId} = props ?? {};

          return  deleteCourseRequirement(siteId,requirementId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCourseRequirement>>, TError, {siteId: string;requirementId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single Course Resource
 */
export const getCourseResource = (
    siteId: string,
    resourceId: string,
    params?: GetCourseResourceParams,
 signal?: AbortSignal
) => {
      return localInstance<CourseResource>(
      {url: `/sites/${siteId}/course_resources/${resourceId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCourseResourceQueryKey = (siteId: string,
    resourceId: string,
    params?: GetCourseResourceParams,) => [`/sites/${siteId}/course_resources/${resourceId}`, ...(params ? [params]: [])];

    
export type GetCourseResourceQueryResult = NonNullable<Awaited<ReturnType<typeof getCourseResource>>>
export type GetCourseResourceQueryError = unknown

export const useGetCourseResource = <TData = Awaited<ReturnType<typeof getCourseResource>>, TError = unknown>(
 siteId: string,
    resourceId: string,
    params?: GetCourseResourceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCourseResource>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCourseResourceQueryKey(siteId,resourceId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCourseResource>>> = ({ signal }) => getCourseResource(siteId,resourceId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCourseResource>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && resourceId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates the given Course Resource
 */
export const updateCourseResource = (
    siteId: string,
    resourceId: string,
    courseResource: CourseResource,
 
) => {
      return localInstance<CourseResource>(
      {url: `/sites/${siteId}/course_resources/${resourceId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: courseResource
    },
      );
    }
  


    export type UpdateCourseResourceMutationResult = NonNullable<Awaited<ReturnType<typeof updateCourseResource>>>
    export type UpdateCourseResourceMutationBody = CourseResource
    export type UpdateCourseResourceMutationError = unknown

    export const useUpdateCourseResource = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCourseResource>>, TError,{siteId: string;resourceId: string;data: CourseResource}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCourseResource>>, {siteId: string;resourceId: string;data: CourseResource}> = (props) => {
          const {siteId,resourceId,data} = props ?? {};

          return  updateCourseResource(siteId,resourceId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateCourseResource>>, TError, {siteId: string;resourceId: string;data: CourseResource}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a single Course Resource
 */
export const deleteCourseResource = (
    siteId: string,
    resourceId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/course_resources/${resourceId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteCourseResourceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCourseResource>>>
    
    export type DeleteCourseResourceMutationError = unknown

    export const useDeleteCourseResource = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCourseResource>>, TError,{siteId: string;resourceId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCourseResource>>, {siteId: string;resourceId: string}> = (props) => {
          const {siteId,resourceId} = props ?? {};

          return  deleteCourseResource(siteId,resourceId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCourseResource>>, TError, {siteId: string;resourceId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of all Courses for the given Site
 */
export const getCourses = (
    siteId: string,
    params?: GetCoursesParams,
 signal?: AbortSignal
) => {
      return localInstance<Course[]>(
      {url: `/sites/${siteId}/courses`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCoursesQueryKey = (siteId: string,
    params?: GetCoursesParams,) => [`/sites/${siteId}/courses`, ...(params ? [params]: [])];

    
export type GetCoursesQueryResult = NonNullable<Awaited<ReturnType<typeof getCourses>>>
export type GetCoursesQueryError = unknown

export const useGetCourses = <TData = Awaited<ReturnType<typeof getCourses>>, TError = unknown>(
 siteId: string,
    params?: GetCoursesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCourses>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCoursesQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCourses>>> = ({ signal }) => getCourses(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCourses>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Course for the given Site
 */
export const createCourse = (
    siteId: string,
    course: Course,
 
) => {
      return localInstance<Course>(
      {url: `/sites/${siteId}/courses`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: course
    },
      );
    }
  


    export type CreateCourseMutationResult = NonNullable<Awaited<ReturnType<typeof createCourse>>>
    export type CreateCourseMutationBody = Course
    export type CreateCourseMutationError = unknown

    export const useCreateCourse = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCourse>>, TError,{siteId: string;data: Course}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCourse>>, {siteId: string;data: Course}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createCourse(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createCourse>>, TError, {siteId: string;data: Course}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single Course
 */
export const getCourse = (
    siteId: string,
    courseId: string,
    params?: GetCourseParams,
 signal?: AbortSignal
) => {
      return localInstance<Course>(
      {url: `/sites/${siteId}/courses/${courseId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCourseQueryKey = (siteId: string,
    courseId: string,
    params?: GetCourseParams,) => [`/sites/${siteId}/courses/${courseId}`, ...(params ? [params]: [])];

    
export type GetCourseQueryResult = NonNullable<Awaited<ReturnType<typeof getCourse>>>
export type GetCourseQueryError = unknown

export const useGetCourse = <TData = Awaited<ReturnType<typeof getCourse>>, TError = unknown>(
 siteId: string,
    courseId: string,
    params?: GetCourseParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCourse>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCourseQueryKey(siteId,courseId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCourse>>> = ({ signal }) => getCourse(siteId,courseId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCourse>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates a Course
 */
export const updateCourse = (
    siteId: string,
    courseId: string,
    course: Course,
 
) => {
      return localInstance<Course>(
      {url: `/sites/${siteId}/courses/${courseId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: course
    },
      );
    }
  


    export type UpdateCourseMutationResult = NonNullable<Awaited<ReturnType<typeof updateCourse>>>
    export type UpdateCourseMutationBody = Course
    export type UpdateCourseMutationError = unknown

    export const useUpdateCourse = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCourse>>, TError,{siteId: string;courseId: string;data: Course}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCourse>>, {siteId: string;courseId: string;data: Course}> = (props) => {
          const {siteId,courseId,data} = props ?? {};

          return  updateCourse(siteId,courseId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateCourse>>, TError, {siteId: string;courseId: string;data: Course}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a single Course
 */
export const deleteCourse = (
    siteId: string,
    courseId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/courses/${courseId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteCourseMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCourse>>>
    
    export type DeleteCourseMutationError = unknown

    export const useDeleteCourse = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCourse>>, TError,{siteId: string;courseId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCourse>>, {siteId: string;courseId: string}> = (props) => {
          const {siteId,courseId} = props ?? {};

          return  deleteCourse(siteId,courseId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCourse>>, TError, {siteId: string;courseId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a summary of CourseRequirements needed to take the course, broken down into two categories: requirements_met, requirements_missing
 */
export const checkCourseRequirements = (
    siteId: string,
    courseId: string,
 signal?: AbortSignal
) => {
      return localInstance<UserCourseRequirementSummary>(
      {url: `/sites/${siteId}/courses/${courseId}/check_requirements`, method: 'get', signal
    },
      );
    }
  

export const getCheckCourseRequirementsQueryKey = (siteId: string,
    courseId: string,) => [`/sites/${siteId}/courses/${courseId}/check_requirements`];

    
export type CheckCourseRequirementsQueryResult = NonNullable<Awaited<ReturnType<typeof checkCourseRequirements>>>
export type CheckCourseRequirementsQueryError = unknown

export const useCheckCourseRequirements = <TData = Awaited<ReturnType<typeof checkCourseRequirements>>, TError = unknown>(
 siteId: string,
    courseId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkCourseRequirements>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCheckCourseRequirementsQueryKey(siteId,courseId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkCourseRequirements>>> = ({ signal }) => checkCourseRequirements(siteId,courseId, signal);

  const query = useQuery<Awaited<ReturnType<typeof checkCourseRequirements>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Clones a selected Course, using the user provided title and slug. Optionally, the user can choose to clone access passes, lessons and resources
 */
export const cloneCourse = (
    siteId: string,
    courseId: string,
    cloneCourse: CloneCourse,
 
) => {
      return localInstance<Course>(
      {url: `/sites/${siteId}/courses/${courseId}/clone`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: cloneCourse
    },
      );
    }
  


    export type CloneCourseMutationResult = NonNullable<Awaited<ReturnType<typeof cloneCourse>>>
    export type CloneCourseMutationBody = CloneCourse
    export type CloneCourseMutationError = unknown

    export const useCloneCourse = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cloneCourse>>, TError,{siteId: string;courseId: string;data: CloneCourse}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cloneCourse>>, {siteId: string;courseId: string;data: CloneCourse}> = (props) => {
          const {siteId,courseId,data} = props ?? {};

          return  cloneCourse(siteId,courseId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof cloneCourse>>, TError, {siteId: string;courseId: string;data: CloneCourse}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Marks an in-progress Enrollment as `COMPLETED` for the requesting user
 */
export const completeCourse = (
    siteId: string,
    courseId: string,
 
) => {
      return localInstance<CompleteCourse>(
      {url: `/sites/${siteId}/courses/${courseId}/complete`, method: 'post'
    },
      );
    }
  


    export type CompleteCourseMutationResult = NonNullable<Awaited<ReturnType<typeof completeCourse>>>
    
    export type CompleteCourseMutationError = unknown

    export const useCompleteCourse = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof completeCourse>>, TError,{siteId: string;courseId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof completeCourse>>, {siteId: string;courseId: string}> = (props) => {
          const {siteId,courseId} = props ?? {};

          return  completeCourse(siteId,courseId,)
        }

      return useMutation<Awaited<ReturnType<typeof completeCourse>>, TError, {siteId: string;courseId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Marks an in-progress Enrollment as `COMPLETED` for the requested user
 */
export const completeCourseAdmin = (
    siteId: string,
    courseId: string,
    completeCourseAdminRequest: CompleteCourseAdminRequest,
 
) => {
      return localInstance<CompleteCourse>(
      {url: `/sites/${siteId}/courses/${courseId}/complete_admin`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: completeCourseAdminRequest
    },
      );
    }
  


    export type CompleteCourseAdminMutationResult = NonNullable<Awaited<ReturnType<typeof completeCourseAdmin>>>
    export type CompleteCourseAdminMutationBody = CompleteCourseAdminRequest
    export type CompleteCourseAdminMutationError = unknown

    export const useCompleteCourseAdmin = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof completeCourseAdmin>>, TError,{siteId: string;courseId: string;data: CompleteCourseAdminRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof completeCourseAdmin>>, {siteId: string;courseId: string;data: CompleteCourseAdminRequest}> = (props) => {
          const {siteId,courseId,data} = props ?? {};

          return  completeCourseAdmin(siteId,courseId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof completeCourseAdmin>>, TError, {siteId: string;courseId: string;data: CompleteCourseAdminRequest}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of all Course Resources for the given Course
 */
export const getCourseResources = (
    siteId: string,
    courseId: string,
    params?: GetCourseResourcesParams,
 signal?: AbortSignal
) => {
      return localInstance<CourseResource[]>(
      {url: `/sites/${siteId}/courses/${courseId}/course_resources`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCourseResourcesQueryKey = (siteId: string,
    courseId: string,
    params?: GetCourseResourcesParams,) => [`/sites/${siteId}/courses/${courseId}/course_resources`, ...(params ? [params]: [])];

    
export type GetCourseResourcesQueryResult = NonNullable<Awaited<ReturnType<typeof getCourseResources>>>
export type GetCourseResourcesQueryError = unknown

export const useGetCourseResources = <TData = Awaited<ReturnType<typeof getCourseResources>>, TError = unknown>(
 siteId: string,
    courseId: string,
    params?: GetCourseResourcesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCourseResources>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCourseResourcesQueryKey(siteId,courseId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCourseResources>>> = ({ signal }) => getCourseResources(siteId,courseId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCourseResources>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Course Resource for the given Course
 */
export const createCourseResource = (
    siteId: string,
    courseId: string,
    courseResource: CourseResource,
 
) => {
      return localInstance<CourseResource>(
      {url: `/sites/${siteId}/courses/${courseId}/course_resources`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: courseResource
    },
      );
    }
  


    export type CreateCourseResourceMutationResult = NonNullable<Awaited<ReturnType<typeof createCourseResource>>>
    export type CreateCourseResourceMutationBody = CourseResource
    export type CreateCourseResourceMutationError = unknown

    export const useCreateCourseResource = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCourseResource>>, TError,{siteId: string;courseId: string;data: CourseResource}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCourseResource>>, {siteId: string;courseId: string;data: CourseResource}> = (props) => {
          const {siteId,courseId,data} = props ?? {};

          return  createCourseResource(siteId,courseId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createCourseResource>>, TError, {siteId: string;courseId: string;data: CourseResource}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all Education Credits for a specific Course
 */
export const getCourseEducationCredits = (
    siteId: string,
    courseId: string,
    params?: GetCourseEducationCreditsParams,
 signal?: AbortSignal
) => {
      return localInstance<EducationCredit[]>(
      {url: `/sites/${siteId}/courses/${courseId}/education_credits`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCourseEducationCreditsQueryKey = (siteId: string,
    courseId: string,
    params?: GetCourseEducationCreditsParams,) => [`/sites/${siteId}/courses/${courseId}/education_credits`, ...(params ? [params]: [])];

    
export type GetCourseEducationCreditsQueryResult = NonNullable<Awaited<ReturnType<typeof getCourseEducationCredits>>>
export type GetCourseEducationCreditsQueryError = unknown

export const useGetCourseEducationCredits = <TData = Awaited<ReturnType<typeof getCourseEducationCredits>>, TError = unknown>(
 siteId: string,
    courseId: string,
    params?: GetCourseEducationCreditsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCourseEducationCredits>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCourseEducationCreditsQueryKey(siteId,courseId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCourseEducationCredits>>> = ({ signal }) => getCourseEducationCredits(siteId,courseId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCourseEducationCredits>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns all Enrollments for the given Course
 */
export const getCourseEnrollments = (
    siteId: string,
    courseId: string,
    params?: GetCourseEnrollmentsParams,
 signal?: AbortSignal
) => {
      return localInstance<Enrollment[]>(
      {url: `/sites/${siteId}/courses/${courseId}/enrollments`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCourseEnrollmentsQueryKey = (siteId: string,
    courseId: string,
    params?: GetCourseEnrollmentsParams,) => [`/sites/${siteId}/courses/${courseId}/enrollments`, ...(params ? [params]: [])];

    
export type GetCourseEnrollmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getCourseEnrollments>>>
export type GetCourseEnrollmentsQueryError = unknown

export const useGetCourseEnrollments = <TData = Awaited<ReturnType<typeof getCourseEnrollments>>, TError = unknown>(
 siteId: string,
    courseId: string,
    params?: GetCourseEnrollmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCourseEnrollments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCourseEnrollmentsQueryKey(siteId,courseId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCourseEnrollments>>> = ({ signal }) => getCourseEnrollments(siteId,courseId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCourseEnrollments>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Enrollment in the given Course
 */
export const createCourseEnrollment = (
    siteId: string,
    courseId: string,
    enrollment: Enrollment,
 
) => {
      return localInstance<Enrollment>(
      {url: `/sites/${siteId}/courses/${courseId}/enrollments`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: enrollment
    },
      );
    }
  


    export type CreateCourseEnrollmentMutationResult = NonNullable<Awaited<ReturnType<typeof createCourseEnrollment>>>
    export type CreateCourseEnrollmentMutationBody = Enrollment
    export type CreateCourseEnrollmentMutationError = unknown

    export const useCreateCourseEnrollment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCourseEnrollment>>, TError,{siteId: string;courseId: string;data: Enrollment}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCourseEnrollment>>, {siteId: string;courseId: string;data: Enrollment}> = (props) => {
          const {siteId,courseId,data} = props ?? {};

          return  createCourseEnrollment(siteId,courseId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createCourseEnrollment>>, TError, {siteId: string;courseId: string;data: Enrollment}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single Enrollment
 */
export const getEnrollment = (
    siteId: string,
    courseId: string,
    enrollmentId: string,
    params?: GetEnrollmentParams,
 signal?: AbortSignal
) => {
      return localInstance<Enrollment>(
      {url: `/sites/${siteId}/courses/${courseId}/enrollments/${enrollmentId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetEnrollmentQueryKey = (siteId: string,
    courseId: string,
    enrollmentId: string,
    params?: GetEnrollmentParams,) => [`/sites/${siteId}/courses/${courseId}/enrollments/${enrollmentId}`, ...(params ? [params]: [])];

    
export type GetEnrollmentQueryResult = NonNullable<Awaited<ReturnType<typeof getEnrollment>>>
export type GetEnrollmentQueryError = unknown

export const useGetEnrollment = <TData = Awaited<ReturnType<typeof getEnrollment>>, TError = unknown>(
 siteId: string,
    courseId: string,
    enrollmentId: string,
    params?: GetEnrollmentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEnrollment>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEnrollmentQueryKey(siteId,courseId,enrollmentId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEnrollment>>> = ({ signal }) => getEnrollment(siteId,courseId,enrollmentId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getEnrollment>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && enrollmentId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates the given Enrollment
 */
export const updateEnrollment = (
    siteId: string,
    courseId: string,
    enrollmentId: string,
    enrollment: Enrollment,
 
) => {
      return localInstance<Enrollment>(
      {url: `/sites/${siteId}/courses/${courseId}/enrollments/${enrollmentId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: enrollment
    },
      );
    }
  


    export type UpdateEnrollmentMutationResult = NonNullable<Awaited<ReturnType<typeof updateEnrollment>>>
    export type UpdateEnrollmentMutationBody = Enrollment
    export type UpdateEnrollmentMutationError = unknown

    export const useUpdateEnrollment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEnrollment>>, TError,{siteId: string;courseId: string;enrollmentId: string;data: Enrollment}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEnrollment>>, {siteId: string;courseId: string;enrollmentId: string;data: Enrollment}> = (props) => {
          const {siteId,courseId,enrollmentId,data} = props ?? {};

          return  updateEnrollment(siteId,courseId,enrollmentId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateEnrollment>>, TError, {siteId: string;courseId: string;enrollmentId: string;data: Enrollment}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the given Enrollment
 */
export const deleteEnrollment = (
    siteId: string,
    courseId: string,
    enrollmentId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/courses/${courseId}/enrollments/${enrollmentId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteEnrollmentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEnrollment>>>
    
    export type DeleteEnrollmentMutationError = unknown

    export const useDeleteEnrollment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEnrollment>>, TError,{siteId: string;courseId: string;enrollmentId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEnrollment>>, {siteId: string;courseId: string;enrollmentId: string}> = (props) => {
          const {siteId,courseId,enrollmentId} = props ?? {};

          return  deleteEnrollment(siteId,courseId,enrollmentId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteEnrollment>>, TError, {siteId: string;courseId: string;enrollmentId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns the Feed associated with the Course
 */
export const getCourseFeed = (
    siteId: string,
    courseId: string,
    params?: GetCourseFeedParams,
 signal?: AbortSignal
) => {
      return localInstance<Feed>(
      {url: `/sites/${siteId}/courses/${courseId}/feed`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCourseFeedQueryKey = (siteId: string,
    courseId: string,
    params?: GetCourseFeedParams,) => [`/sites/${siteId}/courses/${courseId}/feed`, ...(params ? [params]: [])];

    
export type GetCourseFeedQueryResult = NonNullable<Awaited<ReturnType<typeof getCourseFeed>>>
export type GetCourseFeedQueryError = unknown

export const useGetCourseFeed = <TData = Awaited<ReturnType<typeof getCourseFeed>>, TError = unknown>(
 siteId: string,
    courseId: string,
    params?: GetCourseFeedParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCourseFeed>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCourseFeedQueryKey(siteId,courseId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCourseFeed>>> = ({ signal }) => getCourseFeed(siteId,courseId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCourseFeed>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Feed on the given Course
 */
export const createCourseFeed = (
    siteId: string,
    courseId: string,
    feed: Feed,
 
) => {
      return localInstance<Feed>(
      {url: `/sites/${siteId}/courses/${courseId}/feed`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: feed
    },
      );
    }
  


    export type CreateCourseFeedMutationResult = NonNullable<Awaited<ReturnType<typeof createCourseFeed>>>
    export type CreateCourseFeedMutationBody = Feed
    export type CreateCourseFeedMutationError = unknown

    export const useCreateCourseFeed = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCourseFeed>>, TError,{siteId: string;courseId: string;data: Feed}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCourseFeed>>, {siteId: string;courseId: string;data: Feed}> = (props) => {
          const {siteId,courseId,data} = props ?? {};

          return  createCourseFeed(siteId,courseId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createCourseFeed>>, TError, {siteId: string;courseId: string;data: Feed}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates the existing Feed on the given Course
 */
export const updateCourseFeed = (
    siteId: string,
    courseId: string,
    feed: Feed,
 
) => {
      return localInstance<Feed>(
      {url: `/sites/${siteId}/courses/${courseId}/feed`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: feed
    },
      );
    }
  


    export type UpdateCourseFeedMutationResult = NonNullable<Awaited<ReturnType<typeof updateCourseFeed>>>
    export type UpdateCourseFeedMutationBody = Feed
    export type UpdateCourseFeedMutationError = unknown

    export const useUpdateCourseFeed = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCourseFeed>>, TError,{siteId: string;courseId: string;data: Feed}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCourseFeed>>, {siteId: string;courseId: string;data: Feed}> = (props) => {
          const {siteId,courseId,data} = props ?? {};

          return  updateCourseFeed(siteId,courseId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateCourseFeed>>, TError, {siteId: string;courseId: string;data: Feed}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the Feed associated with the Course
 */
export const deleteCourseFeed = (
    siteId: string,
    courseId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/courses/${courseId}/feed`, method: 'delete'
    },
      );
    }
  


    export type DeleteCourseFeedMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCourseFeed>>>
    
    export type DeleteCourseFeedMutationError = unknown

    export const useDeleteCourseFeed = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCourseFeed>>, TError,{siteId: string;courseId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCourseFeed>>, {siteId: string;courseId: string}> = (props) => {
          const {siteId,courseId} = props ?? {};

          return  deleteCourseFeed(siteId,courseId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCourseFeed>>, TError, {siteId: string;courseId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for grading all Enrollment objects for a given Course
 */
export const gradeAllCourseEnrollments = (
    siteId: string,
    courseId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/courses/${courseId}/grade_all_enrollments`, method: 'put'
    },
      );
    }
  


    export type GradeAllCourseEnrollmentsMutationResult = NonNullable<Awaited<ReturnType<typeof gradeAllCourseEnrollments>>>
    
    export type GradeAllCourseEnrollmentsMutationError = unknown

    export const useGradeAllCourseEnrollments = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof gradeAllCourseEnrollments>>, TError,{siteId: string;courseId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof gradeAllCourseEnrollments>>, {siteId: string;courseId: string}> = (props) => {
          const {siteId,courseId} = props ?? {};

          return  gradeAllCourseEnrollments(siteId,courseId,)
        }

      return useMutation<Awaited<ReturnType<typeof gradeAllCourseEnrollments>>, TError, {siteId: string;courseId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all Lessons for the Course
 */
export const getCourseLessons = (
    siteId: string,
    courseId: string,
    params?: GetCourseLessonsParams,
 signal?: AbortSignal
) => {
      return localInstance<Lesson[]>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetCourseLessonsQueryKey = (siteId: string,
    courseId: string,
    params?: GetCourseLessonsParams,) => [`/sites/${siteId}/courses/${courseId}/lessons`, ...(params ? [params]: [])];

    
export type GetCourseLessonsQueryResult = NonNullable<Awaited<ReturnType<typeof getCourseLessons>>>
export type GetCourseLessonsQueryError = unknown

export const useGetCourseLessons = <TData = Awaited<ReturnType<typeof getCourseLessons>>, TError = unknown>(
 siteId: string,
    courseId: string,
    params?: GetCourseLessonsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCourseLessons>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetCourseLessonsQueryKey(siteId,courseId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCourseLessons>>> = ({ signal }) => getCourseLessons(siteId,courseId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCourseLessons>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Lesson associated with the Course
 */
export const createLesson = (
    siteId: string,
    courseId: string,
    lesson: Lesson,
 
) => {
      return localInstance<Lesson>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: lesson
    },
      );
    }
  


    export type CreateLessonMutationResult = NonNullable<Awaited<ReturnType<typeof createLesson>>>
    export type CreateLessonMutationBody = Lesson
    export type CreateLessonMutationError = unknown

    export const useCreateLesson = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLesson>>, TError,{siteId: string;courseId: string;data: Lesson}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createLesson>>, {siteId: string;courseId: string;data: Lesson}> = (props) => {
          const {siteId,courseId,data} = props ?? {};

          return  createLesson(siteId,courseId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createLesson>>, TError, {siteId: string;courseId: string;data: Lesson}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single Lesson
 */
export const getLesson = (
    siteId: string,
    courseId: string,
    lessonId: string,
 signal?: AbortSignal
) => {
      return localInstance<Lesson>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}`, method: 'get', signal
    },
      );
    }
  

export const getGetLessonQueryKey = (siteId: string,
    courseId: string,
    lessonId: string,) => [`/sites/${siteId}/courses/${courseId}/lessons/${lessonId}`];

    
export type GetLessonQueryResult = NonNullable<Awaited<ReturnType<typeof getLesson>>>
export type GetLessonQueryError = unknown

export const useGetLesson = <TData = Awaited<ReturnType<typeof getLesson>>, TError = unknown>(
 siteId: string,
    courseId: string,
    lessonId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLesson>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLessonQueryKey(siteId,courseId,lessonId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLesson>>> = ({ signal }) => getLesson(siteId,courseId,lessonId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLesson>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && lessonId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates the given Lesson
 */
export const updateLesson = (
    siteId: string,
    courseId: string,
    lessonId: string,
    lesson: Lesson,
 
) => {
      return localInstance<Lesson>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: lesson
    },
      );
    }
  


    export type UpdateLessonMutationResult = NonNullable<Awaited<ReturnType<typeof updateLesson>>>
    export type UpdateLessonMutationBody = Lesson
    export type UpdateLessonMutationError = unknown

    export const useUpdateLesson = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLesson>>, TError,{siteId: string;courseId: string;lessonId: string;data: Lesson}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateLesson>>, {siteId: string;courseId: string;lessonId: string;data: Lesson}> = (props) => {
          const {siteId,courseId,lessonId,data} = props ?? {};

          return  updateLesson(siteId,courseId,lessonId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateLesson>>, TError, {siteId: string;courseId: string;lessonId: string;data: Lesson}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the given Lesson
 */
export const deleteLesson = (
    siteId: string,
    courseId: string,
    lessonId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteLessonMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLesson>>>
    
    export type DeleteLessonMutationError = unknown

    export const useDeleteLesson = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLesson>>, TError,{siteId: string;courseId: string;lessonId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLesson>>, {siteId: string;courseId: string;lessonId: string}> = (props) => {
          const {siteId,courseId,lessonId} = props ?? {};

          return  deleteLesson(siteId,courseId,lessonId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteLesson>>, TError, {siteId: string;courseId: string;lessonId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Marks an existing LessonCompletion object as complete for the current JunoUser
 */
export const completeLesson = (
    siteId: string,
    courseId: string,
    lessonId: string,
    userAnswer: UserAnswer[],
 
) => {
      return localInstance<LessonCompletion>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/complete`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userAnswer
    },
      );
    }
  


    export type CompleteLessonMutationResult = NonNullable<Awaited<ReturnType<typeof completeLesson>>>
    export type CompleteLessonMutationBody = UserAnswer[]
    export type CompleteLessonMutationError = unknown

    export const useCompleteLesson = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof completeLesson>>, TError,{siteId: string;courseId: string;lessonId: string;data: UserAnswer[]}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof completeLesson>>, {siteId: string;courseId: string;lessonId: string;data: UserAnswer[]}> = (props) => {
          const {siteId,courseId,lessonId,data} = props ?? {};

          return  completeLesson(siteId,courseId,lessonId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof completeLesson>>, TError, {siteId: string;courseId: string;lessonId: string;data: UserAnswer[]}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all LessonCompletions for the given Lesson
 */
export const getLessonCompletions = (
    siteId: string,
    courseId: string,
    lessonId: string,
    params?: GetLessonCompletionsParams,
 signal?: AbortSignal
) => {
      return localInstance<LessonCompletion[]>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/completions`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetLessonCompletionsQueryKey = (siteId: string,
    courseId: string,
    lessonId: string,
    params?: GetLessonCompletionsParams,) => [`/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/completions`, ...(params ? [params]: [])];

    
export type GetLessonCompletionsQueryResult = NonNullable<Awaited<ReturnType<typeof getLessonCompletions>>>
export type GetLessonCompletionsQueryError = unknown

export const useGetLessonCompletions = <TData = Awaited<ReturnType<typeof getLessonCompletions>>, TError = unknown>(
 siteId: string,
    courseId: string,
    lessonId: string,
    params?: GetLessonCompletionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLessonCompletions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLessonCompletionsQueryKey(siteId,courseId,lessonId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLessonCompletions>>> = ({ signal }) => getLessonCompletions(siteId,courseId,lessonId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLessonCompletions>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && lessonId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new LessonCompletion for the given Lesson
 */
export const createLessonCompletion = (
    siteId: string,
    courseId: string,
    lessonId: string,
    lessonCompletion: LessonCompletion,
 
) => {
      return localInstance<LessonCompletion>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/completions`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: lessonCompletion
    },
      );
    }
  


    export type CreateLessonCompletionMutationResult = NonNullable<Awaited<ReturnType<typeof createLessonCompletion>>>
    export type CreateLessonCompletionMutationBody = LessonCompletion
    export type CreateLessonCompletionMutationError = unknown

    export const useCreateLessonCompletion = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLessonCompletion>>, TError,{siteId: string;courseId: string;lessonId: string;data: LessonCompletion}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createLessonCompletion>>, {siteId: string;courseId: string;lessonId: string;data: LessonCompletion}> = (props) => {
          const {siteId,courseId,lessonId,data} = props ?? {};

          return  createLessonCompletion(siteId,courseId,lessonId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createLessonCompletion>>, TError, {siteId: string;courseId: string;lessonId: string;data: LessonCompletion}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single LessonCompletion
 */
export const getLessonCompletion = (
    siteId: string,
    courseId: string,
    lessonId: string,
    completionId: string,
    params?: GetLessonCompletionParams,
 signal?: AbortSignal
) => {
      return localInstance<LessonCompletion>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/completions/${completionId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetLessonCompletionQueryKey = (siteId: string,
    courseId: string,
    lessonId: string,
    completionId: string,
    params?: GetLessonCompletionParams,) => [`/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/completions/${completionId}`, ...(params ? [params]: [])];

    
export type GetLessonCompletionQueryResult = NonNullable<Awaited<ReturnType<typeof getLessonCompletion>>>
export type GetLessonCompletionQueryError = unknown

export const useGetLessonCompletion = <TData = Awaited<ReturnType<typeof getLessonCompletion>>, TError = unknown>(
 siteId: string,
    courseId: string,
    lessonId: string,
    completionId: string,
    params?: GetLessonCompletionParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLessonCompletion>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLessonCompletionQueryKey(siteId,courseId,lessonId,completionId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLessonCompletion>>> = ({ signal }) => getLessonCompletion(siteId,courseId,lessonId,completionId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLessonCompletion>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && lessonId && completionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates a LessonCompletion
 */
export const updateLessonCompletion = (
    siteId: string,
    courseId: string,
    lessonId: string,
    completionId: string,
    lessonCompletion: LessonCompletion,
 
) => {
      return localInstance<LessonCompletion>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/completions/${completionId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: lessonCompletion
    },
      );
    }
  


    export type UpdateLessonCompletionMutationResult = NonNullable<Awaited<ReturnType<typeof updateLessonCompletion>>>
    export type UpdateLessonCompletionMutationBody = LessonCompletion
    export type UpdateLessonCompletionMutationError = unknown

    export const useUpdateLessonCompletion = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLessonCompletion>>, TError,{siteId: string;courseId: string;lessonId: string;completionId: string;data: LessonCompletion}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateLessonCompletion>>, {siteId: string;courseId: string;lessonId: string;completionId: string;data: LessonCompletion}> = (props) => {
          const {siteId,courseId,lessonId,completionId,data} = props ?? {};

          return  updateLessonCompletion(siteId,courseId,lessonId,completionId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateLessonCompletion>>, TError, {siteId: string;courseId: string;lessonId: string;completionId: string;data: LessonCompletion}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a LessonCompletion
 */
export const deleteLessonCompletion = (
    siteId: string,
    courseId: string,
    lessonId: string,
    completionId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/completions/${completionId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteLessonCompletionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLessonCompletion>>>
    
    export type DeleteLessonCompletionMutationError = unknown

    export const useDeleteLessonCompletion = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLessonCompletion>>, TError,{siteId: string;courseId: string;lessonId: string;completionId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLessonCompletion>>, {siteId: string;courseId: string;lessonId: string;completionId: string}> = (props) => {
          const {siteId,courseId,lessonId,completionId} = props ?? {};

          return  deleteLessonCompletion(siteId,courseId,lessonId,completionId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteLessonCompletion>>, TError, {siteId: string;courseId: string;lessonId: string;completionId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all LessonParts for a given Lesson
 */
export const getLessonParts = (
    siteId: string,
    courseId: string,
    lessonId: string,
    params?: GetLessonPartsParams,
 signal?: AbortSignal
) => {
      return localInstance<LessonPart[]>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/parts`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetLessonPartsQueryKey = (siteId: string,
    courseId: string,
    lessonId: string,
    params?: GetLessonPartsParams,) => [`/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/parts`, ...(params ? [params]: [])];

    
export type GetLessonPartsQueryResult = NonNullable<Awaited<ReturnType<typeof getLessonParts>>>
export type GetLessonPartsQueryError = unknown

export const useGetLessonParts = <TData = Awaited<ReturnType<typeof getLessonParts>>, TError = unknown>(
 siteId: string,
    courseId: string,
    lessonId: string,
    params?: GetLessonPartsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLessonParts>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLessonPartsQueryKey(siteId,courseId,lessonId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLessonParts>>> = ({ signal }) => getLessonParts(siteId,courseId,lessonId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLessonParts>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && lessonId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new LessonPart
 */
export const createLessonPart = (
    siteId: string,
    courseId: string,
    lessonId: string,
    lessonPart: LessonPart,
 
) => {
      return localInstance<LessonPart>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/parts`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: lessonPart
    },
      );
    }
  


    export type CreateLessonPartMutationResult = NonNullable<Awaited<ReturnType<typeof createLessonPart>>>
    export type CreateLessonPartMutationBody = LessonPart
    export type CreateLessonPartMutationError = unknown

    export const useCreateLessonPart = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLessonPart>>, TError,{siteId: string;courseId: string;lessonId: string;data: LessonPart}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createLessonPart>>, {siteId: string;courseId: string;lessonId: string;data: LessonPart}> = (props) => {
          const {siteId,courseId,lessonId,data} = props ?? {};

          return  createLessonPart(siteId,courseId,lessonId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createLessonPart>>, TError, {siteId: string;courseId: string;lessonId: string;data: LessonPart}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single LessonPart for a given Lesson
 */
export const getLessonPart = (
    siteId: string,
    courseId: string,
    lessonId: string,
    partId: string,
    params?: GetLessonPartParams,
 signal?: AbortSignal
) => {
      return localInstance<LessonPart>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/parts/${partId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetLessonPartQueryKey = (siteId: string,
    courseId: string,
    lessonId: string,
    partId: string,
    params?: GetLessonPartParams,) => [`/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/parts/${partId}`, ...(params ? [params]: [])];

    
export type GetLessonPartQueryResult = NonNullable<Awaited<ReturnType<typeof getLessonPart>>>
export type GetLessonPartQueryError = unknown

export const useGetLessonPart = <TData = Awaited<ReturnType<typeof getLessonPart>>, TError = unknown>(
 siteId: string,
    courseId: string,
    lessonId: string,
    partId: string,
    params?: GetLessonPartParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLessonPart>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLessonPartQueryKey(siteId,courseId,lessonId,partId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLessonPart>>> = ({ signal }) => getLessonPart(siteId,courseId,lessonId,partId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLessonPart>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && lessonId && partId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates a single LessonPart
 */
export const updateLessonPart = (
    siteId: string,
    courseId: string,
    lessonId: string,
    partId: string,
    lessonPart: LessonPart,
 
) => {
      return localInstance<LessonPart>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/parts/${partId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: lessonPart
    },
      );
    }
  


    export type UpdateLessonPartMutationResult = NonNullable<Awaited<ReturnType<typeof updateLessonPart>>>
    export type UpdateLessonPartMutationBody = LessonPart
    export type UpdateLessonPartMutationError = unknown

    export const useUpdateLessonPart = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLessonPart>>, TError,{siteId: string;courseId: string;lessonId: string;partId: string;data: LessonPart}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateLessonPart>>, {siteId: string;courseId: string;lessonId: string;partId: string;data: LessonPart}> = (props) => {
          const {siteId,courseId,lessonId,partId,data} = props ?? {};

          return  updateLessonPart(siteId,courseId,lessonId,partId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateLessonPart>>, TError, {siteId: string;courseId: string;lessonId: string;partId: string;data: LessonPart}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a LessonPart
 */
export const deleteLessonPart = (
    siteId: string,
    courseId: string,
    lessonId: string,
    partId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/parts/${partId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteLessonPartMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLessonPart>>>
    
    export type DeleteLessonPartMutationError = unknown

    export const useDeleteLessonPart = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLessonPart>>, TError,{siteId: string;courseId: string;lessonId: string;partId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLessonPart>>, {siteId: string;courseId: string;lessonId: string;partId: string}> = (props) => {
          const {siteId,courseId,lessonId,partId} = props ?? {};

          return  deleteLessonPart(siteId,courseId,lessonId,partId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteLessonPart>>, TError, {siteId: string;courseId: string;lessonId: string;partId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of all Resources for the given LessonPart
 */
export const getLessonPartResources = (
    siteId: string,
    courseId: string,
    lessonId: string,
    partId: string,
    params?: GetLessonPartResourcesParams,
 signal?: AbortSignal
) => {
      return localInstance<CourseResource[]>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/parts/${partId}/resources`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetLessonPartResourcesQueryKey = (siteId: string,
    courseId: string,
    lessonId: string,
    partId: string,
    params?: GetLessonPartResourcesParams,) => [`/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/parts/${partId}/resources`, ...(params ? [params]: [])];

    
export type GetLessonPartResourcesQueryResult = NonNullable<Awaited<ReturnType<typeof getLessonPartResources>>>
export type GetLessonPartResourcesQueryError = unknown

export const useGetLessonPartResources = <TData = Awaited<ReturnType<typeof getLessonPartResources>>, TError = unknown>(
 siteId: string,
    courseId: string,
    lessonId: string,
    partId: string,
    params?: GetLessonPartResourcesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLessonPartResources>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLessonPartResourcesQueryKey(siteId,courseId,lessonId,partId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLessonPartResources>>> = ({ signal }) => getLessonPartResources(siteId,courseId,lessonId,partId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLessonPartResources>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && lessonId && partId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Resource for the given LessonPart
 */
export const createLessonPartResource = (
    siteId: string,
    courseId: string,
    lessonId: string,
    partId: string,
    courseResource: CourseResource,
 
) => {
      return localInstance<CourseResource>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/parts/${partId}/resources`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: courseResource
    },
      );
    }
  


    export type CreateLessonPartResourceMutationResult = NonNullable<Awaited<ReturnType<typeof createLessonPartResource>>>
    export type CreateLessonPartResourceMutationBody = CourseResource
    export type CreateLessonPartResourceMutationError = unknown

    export const useCreateLessonPartResource = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLessonPartResource>>, TError,{siteId: string;courseId: string;lessonId: string;partId: string;data: CourseResource}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createLessonPartResource>>, {siteId: string;courseId: string;lessonId: string;partId: string;data: CourseResource}> = (props) => {
          const {siteId,courseId,lessonId,partId,data} = props ?? {};

          return  createLessonPartResource(siteId,courseId,lessonId,partId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createLessonPartResource>>, TError, {siteId: string;courseId: string;lessonId: string;partId: string;data: CourseResource}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all Questions on the given Lesson
 */
export const getLessonQuestions = (
    siteId: string,
    courseId: string,
    lessonId: string,
    params?: GetLessonQuestionsParams,
 signal?: AbortSignal
) => {
      return localInstance<Question[]>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetLessonQuestionsQueryKey = (siteId: string,
    courseId: string,
    lessonId: string,
    params?: GetLessonQuestionsParams,) => [`/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions`, ...(params ? [params]: [])];

    
export type GetLessonQuestionsQueryResult = NonNullable<Awaited<ReturnType<typeof getLessonQuestions>>>
export type GetLessonQuestionsQueryError = unknown

export const useGetLessonQuestions = <TData = Awaited<ReturnType<typeof getLessonQuestions>>, TError = unknown>(
 siteId: string,
    courseId: string,
    lessonId: string,
    params?: GetLessonQuestionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLessonQuestions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLessonQuestionsQueryKey(siteId,courseId,lessonId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLessonQuestions>>> = ({ signal }) => getLessonQuestions(siteId,courseId,lessonId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLessonQuestions>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && lessonId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Question on the given Lesson
 */
export const createLessonQuestion = (
    siteId: string,
    courseId: string,
    lessonId: string,
    question: Question,
 
) => {
      return localInstance<Question>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: question
    },
      );
    }
  


    export type CreateLessonQuestionMutationResult = NonNullable<Awaited<ReturnType<typeof createLessonQuestion>>>
    export type CreateLessonQuestionMutationBody = Question
    export type CreateLessonQuestionMutationError = unknown

    export const useCreateLessonQuestion = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLessonQuestion>>, TError,{siteId: string;courseId: string;lessonId: string;data: Question}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createLessonQuestion>>, {siteId: string;courseId: string;lessonId: string;data: Question}> = (props) => {
          const {siteId,courseId,lessonId,data} = props ?? {};

          return  createLessonQuestion(siteId,courseId,lessonId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createLessonQuestion>>, TError, {siteId: string;courseId: string;lessonId: string;data: Question}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single Question
 */
export const getLessonQuestion = (
    siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
    params?: GetLessonQuestionParams,
 signal?: AbortSignal
) => {
      return localInstance<Question>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions/${questionId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetLessonQuestionQueryKey = (siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
    params?: GetLessonQuestionParams,) => [`/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions/${questionId}`, ...(params ? [params]: [])];

    
export type GetLessonQuestionQueryResult = NonNullable<Awaited<ReturnType<typeof getLessonQuestion>>>
export type GetLessonQuestionQueryError = unknown

export const useGetLessonQuestion = <TData = Awaited<ReturnType<typeof getLessonQuestion>>, TError = unknown>(
 siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
    params?: GetLessonQuestionParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLessonQuestion>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLessonQuestionQueryKey(siteId,courseId,lessonId,questionId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLessonQuestion>>> = ({ signal }) => getLessonQuestion(siteId,courseId,lessonId,questionId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLessonQuestion>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && lessonId && questionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates a Question
 */
export const updateLessonQuestion = (
    siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
    question: Question,
 
) => {
      return localInstance<Question>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions/${questionId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: question
    },
      );
    }
  


    export type UpdateLessonQuestionMutationResult = NonNullable<Awaited<ReturnType<typeof updateLessonQuestion>>>
    export type UpdateLessonQuestionMutationBody = Question
    export type UpdateLessonQuestionMutationError = unknown

    export const useUpdateLessonQuestion = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLessonQuestion>>, TError,{siteId: string;courseId: string;lessonId: string;questionId: string;data: Question}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateLessonQuestion>>, {siteId: string;courseId: string;lessonId: string;questionId: string;data: Question}> = (props) => {
          const {siteId,courseId,lessonId,questionId,data} = props ?? {};

          return  updateLessonQuestion(siteId,courseId,lessonId,questionId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateLessonQuestion>>, TError, {siteId: string;courseId: string;lessonId: string;questionId: string;data: Question}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a Question
 */
export const deleteLessonQuestion = (
    siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions/${questionId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteLessonQuestionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLessonQuestion>>>
    
    export type DeleteLessonQuestionMutationError = unknown

    export const useDeleteLessonQuestion = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLessonQuestion>>, TError,{siteId: string;courseId: string;lessonId: string;questionId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLessonQuestion>>, {siteId: string;courseId: string;lessonId: string;questionId: string}> = (props) => {
          const {siteId,courseId,lessonId,questionId} = props ?? {};

          return  deleteLessonQuestion(siteId,courseId,lessonId,questionId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteLessonQuestion>>, TError, {siteId: string;courseId: string;lessonId: string;questionId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of answers for the given question, along with the number of learners that chose that answer
 */
export const getAnswerSummary = (
    siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
 signal?: AbortSignal
) => {
      return localInstance<AnswerSummary[]>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions/${questionId}/answer_summary`, method: 'get', signal
    },
      );
    }
  

export const getGetAnswerSummaryQueryKey = (siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,) => [`/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions/${questionId}/answer_summary`];

    
export type GetAnswerSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof getAnswerSummary>>>
export type GetAnswerSummaryQueryError = unknown

export const useGetAnswerSummary = <TData = Awaited<ReturnType<typeof getAnswerSummary>>, TError = unknown>(
 siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAnswerSummary>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAnswerSummaryQueryKey(siteId,courseId,lessonId,questionId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAnswerSummary>>> = ({ signal }) => getAnswerSummary(siteId,courseId,lessonId,questionId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAnswerSummary>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && lessonId && questionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns all Answers for the given Question
 */
export const getQuestionAnswers = (
    siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
    params?: GetQuestionAnswersParams,
 signal?: AbortSignal
) => {
      return localInstance<Answer[]>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions/${questionId}/answers`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetQuestionAnswersQueryKey = (siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
    params?: GetQuestionAnswersParams,) => [`/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions/${questionId}/answers`, ...(params ? [params]: [])];

    
export type GetQuestionAnswersQueryResult = NonNullable<Awaited<ReturnType<typeof getQuestionAnswers>>>
export type GetQuestionAnswersQueryError = unknown

export const useGetQuestionAnswers = <TData = Awaited<ReturnType<typeof getQuestionAnswers>>, TError = unknown>(
 siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
    params?: GetQuestionAnswersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuestionAnswers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetQuestionAnswersQueryKey(siteId,courseId,lessonId,questionId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuestionAnswers>>> = ({ signal }) => getQuestionAnswers(siteId,courseId,lessonId,questionId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getQuestionAnswers>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && lessonId && questionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Answer on the given Question
 */
export const createQuestionAnswer = (
    siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
    answerImporter: AnswerImporter,
 
) => {
      return localInstance<AnswerImporter>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions/${questionId}/answers`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: answerImporter
    },
      );
    }
  


    export type CreateQuestionAnswerMutationResult = NonNullable<Awaited<ReturnType<typeof createQuestionAnswer>>>
    export type CreateQuestionAnswerMutationBody = AnswerImporter
    export type CreateQuestionAnswerMutationError = unknown

    export const useCreateQuestionAnswer = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createQuestionAnswer>>, TError,{siteId: string;courseId: string;lessonId: string;questionId: string;data: AnswerImporter}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createQuestionAnswer>>, {siteId: string;courseId: string;lessonId: string;questionId: string;data: AnswerImporter}> = (props) => {
          const {siteId,courseId,lessonId,questionId,data} = props ?? {};

          return  createQuestionAnswer(siteId,courseId,lessonId,questionId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createQuestionAnswer>>, TError, {siteId: string;courseId: string;lessonId: string;questionId: string;data: AnswerImporter}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single Answer
 */
export const getQuestionAnswer = (
    siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
    answerId: string,
    params?: GetQuestionAnswerParams,
 signal?: AbortSignal
) => {
      return localInstance<Answer>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions/${questionId}/answers/${answerId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetQuestionAnswerQueryKey = (siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
    answerId: string,
    params?: GetQuestionAnswerParams,) => [`/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions/${questionId}/answers/${answerId}`, ...(params ? [params]: [])];

    
export type GetQuestionAnswerQueryResult = NonNullable<Awaited<ReturnType<typeof getQuestionAnswer>>>
export type GetQuestionAnswerQueryError = unknown

export const useGetQuestionAnswer = <TData = Awaited<ReturnType<typeof getQuestionAnswer>>, TError = unknown>(
 siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
    answerId: string,
    params?: GetQuestionAnswerParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuestionAnswer>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetQuestionAnswerQueryKey(siteId,courseId,lessonId,questionId,answerId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuestionAnswer>>> = ({ signal }) => getQuestionAnswer(siteId,courseId,lessonId,questionId,answerId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getQuestionAnswer>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && lessonId && questionId && answerId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates a single Answer
 */
export const updateQuestionAnswer = (
    siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
    answerId: string,
    answer: Answer,
 
) => {
      return localInstance<Answer>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions/${questionId}/answers/${answerId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: answer
    },
      );
    }
  


    export type UpdateQuestionAnswerMutationResult = NonNullable<Awaited<ReturnType<typeof updateQuestionAnswer>>>
    export type UpdateQuestionAnswerMutationBody = Answer
    export type UpdateQuestionAnswerMutationError = unknown

    export const useUpdateQuestionAnswer = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateQuestionAnswer>>, TError,{siteId: string;courseId: string;lessonId: string;questionId: string;answerId: string;data: Answer}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateQuestionAnswer>>, {siteId: string;courseId: string;lessonId: string;questionId: string;answerId: string;data: Answer}> = (props) => {
          const {siteId,courseId,lessonId,questionId,answerId,data} = props ?? {};

          return  updateQuestionAnswer(siteId,courseId,lessonId,questionId,answerId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateQuestionAnswer>>, TError, {siteId: string;courseId: string;lessonId: string;questionId: string;answerId: string;data: Answer}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes an Answer
 */
export const deleteQuestionAnswer = (
    siteId: string,
    courseId: string,
    lessonId: string,
    questionId: string,
    answerId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/questions/${questionId}/answers/${answerId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteQuestionAnswerMutationResult = NonNullable<Awaited<ReturnType<typeof deleteQuestionAnswer>>>
    
    export type DeleteQuestionAnswerMutationError = unknown

    export const useDeleteQuestionAnswer = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteQuestionAnswer>>, TError,{siteId: string;courseId: string;lessonId: string;questionId: string;answerId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteQuestionAnswer>>, {siteId: string;courseId: string;lessonId: string;questionId: string;answerId: string}> = (props) => {
          const {siteId,courseId,lessonId,questionId,answerId} = props ?? {};

          return  deleteQuestionAnswer(siteId,courseId,lessonId,questionId,answerId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteQuestionAnswer>>, TError, {siteId: string;courseId: string;lessonId: string;questionId: string;answerId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Creates or returns an existing incomplete LessonCompletion object for the current JunoUser
 */
export const startLesson = (
    siteId: string,
    courseId: string,
    lessonId: string,
 
) => {
      return localInstance<LessonCompletion>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/start`, method: 'post'
    },
      );
    }
  


    export type StartLessonMutationResult = NonNullable<Awaited<ReturnType<typeof startLesson>>>
    
    export type StartLessonMutationError = unknown

    export const useStartLesson = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startLesson>>, TError,{siteId: string;courseId: string;lessonId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof startLesson>>, {siteId: string;courseId: string;lessonId: string}> = (props) => {
          const {siteId,courseId,lessonId} = props ?? {};

          return  startLesson(siteId,courseId,lessonId,)
        }

      return useMutation<Awaited<ReturnType<typeof startLesson>>, TError, {siteId: string;courseId: string;lessonId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all LessonCompletions for a given User and Lesson
 */
export const getUserLessonCompletions = (
    siteId: string,
    courseId: string,
    lessonId: string,
    userId: string,
    params?: GetUserLessonCompletionsParams,
 signal?: AbortSignal
) => {
      return localInstance<LessonCompletion[]>(
      {url: `/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/users/${userId}/completions`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetUserLessonCompletionsQueryKey = (siteId: string,
    courseId: string,
    lessonId: string,
    userId: string,
    params?: GetUserLessonCompletionsParams,) => [`/sites/${siteId}/courses/${courseId}/lessons/${lessonId}/users/${userId}/completions`, ...(params ? [params]: [])];

    
export type GetUserLessonCompletionsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserLessonCompletions>>>
export type GetUserLessonCompletionsQueryError = unknown

export const useGetUserLessonCompletions = <TData = Awaited<ReturnType<typeof getUserLessonCompletions>>, TError = unknown>(
 siteId: string,
    courseId: string,
    lessonId: string,
    userId: string,
    params?: GetUserLessonCompletionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserLessonCompletions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserLessonCompletionsQueryKey(siteId,courseId,lessonId,userId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserLessonCompletions>>> = ({ signal }) => getUserLessonCompletions(siteId,courseId,lessonId,userId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserLessonCompletions>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && lessonId && userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Create Course Registration link to Scorm cloud
 */
export const getScormRegistrationLink = (
    siteId: string,
    courseId: string,
    scormRegistrationLinkRequest: ScormRegistrationLinkRequest,
 
) => {
      return localInstance<ScormRegistrationLinkResponse>(
      {url: `/sites/${siteId}/courses/${courseId}/scorm_link`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: scormRegistrationLinkRequest
    },
      );
    }
  


    export type GetScormRegistrationLinkMutationResult = NonNullable<Awaited<ReturnType<typeof getScormRegistrationLink>>>
    export type GetScormRegistrationLinkMutationBody = ScormRegistrationLinkRequest
    export type GetScormRegistrationLinkMutationError = NotAllowed

    export const useGetScormRegistrationLink = <TError = NotAllowed,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getScormRegistrationLink>>, TError,{siteId: string;courseId: string;data: ScormRegistrationLinkRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getScormRegistrationLink>>, {siteId: string;courseId: string;data: ScormRegistrationLinkRequest}> = (props) => {
          const {siteId,courseId,data} = props ?? {};

          return  getScormRegistrationLink(siteId,courseId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof getScormRegistrationLink>>, TError, {siteId: string;courseId: string;data: ScormRegistrationLinkRequest}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Marks an existing Enrollment object as `STARTED` for the requesting user
 */
export const startCourse = (
    siteId: string,
    courseId: string,
 
) => {
      return localInstance<Enrollment>(
      {url: `/sites/${siteId}/courses/${courseId}/start`, method: 'post'
    },
      );
    }
  


    export type StartCourseMutationResult = NonNullable<Awaited<ReturnType<typeof startCourse>>>
    
    export type StartCourseMutationError = NotAllowed

    export const useStartCourse = <TError = NotAllowed,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof startCourse>>, TError,{siteId: string;courseId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof startCourse>>, {siteId: string;courseId: string}> = (props) => {
          const {siteId,courseId} = props ?? {};

          return  startCourse(siteId,courseId,)
        }

      return useMutation<Awaited<ReturnType<typeof startCourse>>, TError, {siteId: string;courseId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Handle Course upload to Scorm cloud
 */
export const uploadScormCourse = (
    siteId: string,
    courseId: string,
    scormCourseUpload: ScormCourseUpload,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/courses/${courseId}/upload_scorm_course`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: scormCourseUpload
    },
      );
    }
  


    export type UploadScormCourseMutationResult = NonNullable<Awaited<ReturnType<typeof uploadScormCourse>>>
    export type UploadScormCourseMutationBody = ScormCourseUpload
    export type UploadScormCourseMutationError = NotAllowed

    export const useUploadScormCourse = <TError = NotAllowed,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadScormCourse>>, TError,{siteId: string;courseId: string;data: ScormCourseUpload}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadScormCourse>>, {siteId: string;courseId: string;data: ScormCourseUpload}> = (props) => {
          const {siteId,courseId,data} = props ?? {};

          return  uploadScormCourse(siteId,courseId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof uploadScormCourse>>, TError, {siteId: string;courseId: string;data: ScormCourseUpload}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all LessonCompletions for a given Course and User
 */
export const getUserCourseLessonCompletions = (
    siteId: string,
    courseId: string,
    userId: string,
    params?: GetUserCourseLessonCompletionsParams,
 signal?: AbortSignal
) => {
      return localInstance<LessonCompletion[]>(
      {url: `/sites/${siteId}/courses/${courseId}/users/${userId}/completions`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetUserCourseLessonCompletionsQueryKey = (siteId: string,
    courseId: string,
    userId: string,
    params?: GetUserCourseLessonCompletionsParams,) => [`/sites/${siteId}/courses/${courseId}/users/${userId}/completions`, ...(params ? [params]: [])];

    
export type GetUserCourseLessonCompletionsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserCourseLessonCompletions>>>
export type GetUserCourseLessonCompletionsQueryError = unknown

export const useGetUserCourseLessonCompletions = <TData = Awaited<ReturnType<typeof getUserCourseLessonCompletions>>, TError = unknown>(
 siteId: string,
    courseId: string,
    userId: string,
    params?: GetUserCourseLessonCompletionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserCourseLessonCompletions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserCourseLessonCompletionsQueryKey(siteId,courseId,userId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCourseLessonCompletions>>> = ({ signal }) => getUserCourseLessonCompletions(siteId,courseId,userId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserCourseLessonCompletions>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns all Enrollments for the given Course
 */
export const getWaitListedEnrollments = (
    siteId: string,
    courseId: string,
    params?: GetWaitListedEnrollmentsParams,
 signal?: AbortSignal
) => {
      return localInstance<WaitListedEnrollment[]>(
      {url: `/sites/${siteId}/courses/${courseId}/wait_listed_enrollments`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetWaitListedEnrollmentsQueryKey = (siteId: string,
    courseId: string,
    params?: GetWaitListedEnrollmentsParams,) => [`/sites/${siteId}/courses/${courseId}/wait_listed_enrollments`, ...(params ? [params]: [])];

    
export type GetWaitListedEnrollmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getWaitListedEnrollments>>>
export type GetWaitListedEnrollmentsQueryError = unknown

export const useGetWaitListedEnrollments = <TData = Awaited<ReturnType<typeof getWaitListedEnrollments>>, TError = unknown>(
 siteId: string,
    courseId: string,
    params?: GetWaitListedEnrollmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWaitListedEnrollments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetWaitListedEnrollmentsQueryKey(siteId,courseId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWaitListedEnrollments>>> = ({ signal }) => getWaitListedEnrollments(siteId,courseId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getWaitListedEnrollments>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Enrollment in the given Course
 */
export const createWaitListedEnrollment = (
    siteId: string,
    courseId: string,
    waitListedEnrollment: WaitListedEnrollment,
 
) => {
      return localInstance<WaitListedEnrollment>(
      {url: `/sites/${siteId}/courses/${courseId}/wait_listed_enrollments`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: waitListedEnrollment
    },
      );
    }
  


    export type CreateWaitListedEnrollmentMutationResult = NonNullable<Awaited<ReturnType<typeof createWaitListedEnrollment>>>
    export type CreateWaitListedEnrollmentMutationBody = WaitListedEnrollment
    export type CreateWaitListedEnrollmentMutationError = unknown

    export const useCreateWaitListedEnrollment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWaitListedEnrollment>>, TError,{siteId: string;courseId: string;data: WaitListedEnrollment}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createWaitListedEnrollment>>, {siteId: string;courseId: string;data: WaitListedEnrollment}> = (props) => {
          const {siteId,courseId,data} = props ?? {};

          return  createWaitListedEnrollment(siteId,courseId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createWaitListedEnrollment>>, TError, {siteId: string;courseId: string;data: WaitListedEnrollment}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single Enrollment
 */
export const getWaitListedEnrollment = (
    siteId: string,
    courseId: string,
    waitListedEnrollmentId: string,
    params?: GetWaitListedEnrollmentParams,
 signal?: AbortSignal
) => {
      return localInstance<WaitListedEnrollment>(
      {url: `/sites/${siteId}/courses/${courseId}/wait_listed_enrollments/${waitListedEnrollmentId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetWaitListedEnrollmentQueryKey = (siteId: string,
    courseId: string,
    waitListedEnrollmentId: string,
    params?: GetWaitListedEnrollmentParams,) => [`/sites/${siteId}/courses/${courseId}/wait_listed_enrollments/${waitListedEnrollmentId}`, ...(params ? [params]: [])];

    
export type GetWaitListedEnrollmentQueryResult = NonNullable<Awaited<ReturnType<typeof getWaitListedEnrollment>>>
export type GetWaitListedEnrollmentQueryError = unknown

export const useGetWaitListedEnrollment = <TData = Awaited<ReturnType<typeof getWaitListedEnrollment>>, TError = unknown>(
 siteId: string,
    courseId: string,
    waitListedEnrollmentId: string,
    params?: GetWaitListedEnrollmentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWaitListedEnrollment>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetWaitListedEnrollmentQueryKey(siteId,courseId,waitListedEnrollmentId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWaitListedEnrollment>>> = ({ signal }) => getWaitListedEnrollment(siteId,courseId,waitListedEnrollmentId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getWaitListedEnrollment>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && courseId && waitListedEnrollmentId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates the given Enrollment
 */
export const updateWaitListedEnrollment = (
    siteId: string,
    courseId: string,
    waitListedEnrollmentId: string,
    waitListedEnrollment: WaitListedEnrollment,
 
) => {
      return localInstance<WaitListedEnrollment>(
      {url: `/sites/${siteId}/courses/${courseId}/wait_listed_enrollments/${waitListedEnrollmentId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: waitListedEnrollment
    },
      );
    }
  


    export type UpdateWaitListedEnrollmentMutationResult = NonNullable<Awaited<ReturnType<typeof updateWaitListedEnrollment>>>
    export type UpdateWaitListedEnrollmentMutationBody = WaitListedEnrollment
    export type UpdateWaitListedEnrollmentMutationError = unknown

    export const useUpdateWaitListedEnrollment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWaitListedEnrollment>>, TError,{siteId: string;courseId: string;waitListedEnrollmentId: string;data: WaitListedEnrollment}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWaitListedEnrollment>>, {siteId: string;courseId: string;waitListedEnrollmentId: string;data: WaitListedEnrollment}> = (props) => {
          const {siteId,courseId,waitListedEnrollmentId,data} = props ?? {};

          return  updateWaitListedEnrollment(siteId,courseId,waitListedEnrollmentId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateWaitListedEnrollment>>, TError, {siteId: string;courseId: string;waitListedEnrollmentId: string;data: WaitListedEnrollment}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the given Enrollment
 */
export const deleteWaitListedEnrollment = (
    siteId: string,
    courseId: string,
    waitListedEnrollmentId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/courses/${courseId}/wait_listed_enrollments/${waitListedEnrollmentId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteWaitListedEnrollmentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWaitListedEnrollment>>>
    
    export type DeleteWaitListedEnrollmentMutationError = unknown

    export const useDeleteWaitListedEnrollment = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWaitListedEnrollment>>, TError,{siteId: string;courseId: string;waitListedEnrollmentId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWaitListedEnrollment>>, {siteId: string;courseId: string;waitListedEnrollmentId: string}> = (props) => {
          const {siteId,courseId,waitListedEnrollmentId} = props ?? {};

          return  deleteWaitListedEnrollment(siteId,courseId,waitListedEnrollmentId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteWaitListedEnrollment>>, TError, {siteId: string;courseId: string;waitListedEnrollmentId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Reorders the given Enrollments
 */
export const updateWaitListOrder = (
    siteId: string,
    courseId: string,
    updateWaitListOrderBody: UpdateWaitListOrderBodyOne | UpdateWaitListOrderBodyTwo | UpdateWaitListOrderBodyThree,
 
) => {
      return localInstance<WaitListedEnrollment[]>(
      {url: `/sites/${siteId}/courses/${courseId}/wait_listed_enrollments_reorder`, method: 'put',
      data: updateWaitListOrderBody
    },
      );
    }
  


    export type UpdateWaitListOrderMutationResult = NonNullable<Awaited<ReturnType<typeof updateWaitListOrder>>>
    export type UpdateWaitListOrderMutationBody = UpdateWaitListOrderBodyOne | UpdateWaitListOrderBodyTwo | UpdateWaitListOrderBodyThree
    export type UpdateWaitListOrderMutationError = unknown

    export const useUpdateWaitListOrder = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWaitListOrder>>, TError,{siteId: string;courseId: string;data: UpdateWaitListOrderBodyOne | UpdateWaitListOrderBodyTwo | UpdateWaitListOrderBodyThree}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWaitListOrder>>, {siteId: string;courseId: string;data: UpdateWaitListOrderBodyOne | UpdateWaitListOrderBodyTwo | UpdateWaitListOrderBodyThree}> = (props) => {
          const {siteId,courseId,data} = props ?? {};

          return  updateWaitListOrder(siteId,courseId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateWaitListOrder>>, TError, {siteId: string;courseId: string;data: UpdateWaitListOrderBodyOne | UpdateWaitListOrderBodyTwo | UpdateWaitListOrderBodyThree}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with multiple Downloads at once
 */
export const createDownloads = (
    siteId: string,
    download: Download[],
 
) => {
      return localInstance<Download[]>(
      {url: `/sites/${siteId}/create_downloads`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: download
    },
      );
    }
  


    export type CreateDownloadsMutationResult = NonNullable<Awaited<ReturnType<typeof createDownloads>>>
    export type CreateDownloadsMutationBody = Download[]
    export type CreateDownloadsMutationError = unknown

    export const useCreateDownloads = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDownloads>>, TError,{siteId: string;data: Download[]}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDownloads>>, {siteId: string;data: Download[]}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createDownloads(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createDownloads>>, TError, {siteId: string;data: Download[]}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns the current User's `EducationCreditProgress` object for the given `EducationCredit`
 */
export const getMyEducationCreditProgress = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<UserEducationCredit[]>(
      {url: `/sites/${siteId}/credits/my_progress`, method: 'get', signal
    },
      );
    }
  

export const getGetMyEducationCreditProgressQueryKey = (siteId: string,) => [`/sites/${siteId}/credits/my_progress`];

    
export type GetMyEducationCreditProgressQueryResult = NonNullable<Awaited<ReturnType<typeof getMyEducationCreditProgress>>>
export type GetMyEducationCreditProgressQueryError = unknown

export const useGetMyEducationCreditProgress = <TData = Awaited<ReturnType<typeof getMyEducationCreditProgress>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMyEducationCreditProgress>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMyEducationCreditProgressQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyEducationCreditProgress>>> = ({ signal }) => getMyEducationCreditProgress(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMyEducationCreditProgress>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for a User to manage their `UserEducationCreditRequirement` objects. Typically this is for a user to alter the number of hours they are
claiming on a given req
 */
export const sitesCreditsRequirementsRetrieve = (
    siteId: string,
    requirementId: string,
 signal?: AbortSignal
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/credits/requirements/${requirementId}`, method: 'get', signal
    },
      );
    }
  

export const getSitesCreditsRequirementsRetrieveQueryKey = (siteId: string,
    requirementId: string,) => [`/sites/${siteId}/credits/requirements/${requirementId}`];

    
export type SitesCreditsRequirementsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof sitesCreditsRequirementsRetrieve>>>
export type SitesCreditsRequirementsRetrieveQueryError = unknown

export const useSitesCreditsRequirementsRetrieve = <TData = Awaited<ReturnType<typeof sitesCreditsRequirementsRetrieve>>, TError = unknown>(
 siteId: string,
    requirementId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sitesCreditsRequirementsRetrieve>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesCreditsRequirementsRetrieveQueryKey(siteId,requirementId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesCreditsRequirementsRetrieve>>> = ({ signal }) => sitesCreditsRequirementsRetrieve(siteId,requirementId, signal);

  const query = useQuery<Awaited<ReturnType<typeof sitesCreditsRequirementsRetrieve>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && requirementId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for dealing with all Download objects for a given Client
 */
export const getDownloads = (
    siteId: string,
    params?: GetDownloadsParams,
 signal?: AbortSignal
) => {
      return localInstance<Download[]>(
      {url: `/sites/${siteId}/downloads`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetDownloadsQueryKey = (siteId: string,
    params?: GetDownloadsParams,) => [`/sites/${siteId}/downloads`, ...(params ? [params]: [])];

    
export type GetDownloadsQueryResult = NonNullable<Awaited<ReturnType<typeof getDownloads>>>
export type GetDownloadsQueryError = unknown

export const useGetDownloads = <TData = Awaited<ReturnType<typeof getDownloads>>, TError = unknown>(
 siteId: string,
    params?: GetDownloadsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDownloads>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetDownloadsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDownloads>>> = ({ signal }) => getDownloads(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getDownloads>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for dealing with all Download objects for a given Client
 */
export const createDownload = (
    siteId: string,
    download: Download,
 
) => {
      return localInstance<Download>(
      {url: `/sites/${siteId}/downloads`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: download
    },
      );
    }
  


    export type CreateDownloadMutationResult = NonNullable<Awaited<ReturnType<typeof createDownload>>>
    export type CreateDownloadMutationBody = Download
    export type CreateDownloadMutationError = unknown

    export const useCreateDownload = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDownload>>, TError,{siteId: string;data: Download}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDownload>>, {siteId: string;data: Download}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createDownload(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createDownload>>, TError, {siteId: string;data: Download}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with a single Download
 */
export const getDownload = (
    siteId: string,
    downloadId: string,
    params?: GetDownloadParams,
 signal?: AbortSignal
) => {
      return localInstance<Download>(
      {url: `/sites/${siteId}/downloads/${downloadId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetDownloadQueryKey = (siteId: string,
    downloadId: string,
    params?: GetDownloadParams,) => [`/sites/${siteId}/downloads/${downloadId}`, ...(params ? [params]: [])];

    
export type GetDownloadQueryResult = NonNullable<Awaited<ReturnType<typeof getDownload>>>
export type GetDownloadQueryError = unknown

export const useGetDownload = <TData = Awaited<ReturnType<typeof getDownload>>, TError = unknown>(
 siteId: string,
    downloadId: string,
    params?: GetDownloadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getDownload>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetDownloadQueryKey(siteId,downloadId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDownload>>> = ({ signal }) => getDownload(siteId,downloadId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getDownload>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && downloadId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for dealing with a single Download
 */
export const updateDownload = (
    siteId: string,
    downloadId: string,
    download: Download,
 
) => {
      return localInstance<Download>(
      {url: `/sites/${siteId}/downloads/${downloadId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: download
    },
      );
    }
  


    export type UpdateDownloadMutationResult = NonNullable<Awaited<ReturnType<typeof updateDownload>>>
    export type UpdateDownloadMutationBody = Download
    export type UpdateDownloadMutationError = unknown

    export const useUpdateDownload = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDownload>>, TError,{siteId: string;downloadId: string;data: Download}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDownload>>, {siteId: string;downloadId: string;data: Download}> = (props) => {
          const {siteId,downloadId,data} = props ?? {};

          return  updateDownload(siteId,downloadId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateDownload>>, TError, {siteId: string;downloadId: string;data: Download}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with a single Download
 */
export const deleteDownload = (
    siteId: string,
    downloadId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/downloads/${downloadId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteDownloadMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDownload>>>
    
    export type DeleteDownloadMutationError = unknown

    export const useDeleteDownload = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDownload>>, TError,{siteId: string;downloadId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDownload>>, {siteId: string;downloadId: string}> = (props) => {
          const {siteId,downloadId} = props ?? {};

          return  deleteDownload(siteId,downloadId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteDownload>>, TError, {siteId: string;downloadId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for getting all `EducationCredit` objects for a given `Site`
 */
export const getAllEducationCredits = (
    siteId: string,
    params?: GetAllEducationCreditsParams,
 signal?: AbortSignal
) => {
      return localInstance<EducationCredit[]>(
      {url: `/sites/${siteId}/education_credits`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllEducationCreditsQueryKey = (siteId: string,
    params?: GetAllEducationCreditsParams,) => [`/sites/${siteId}/education_credits`, ...(params ? [params]: [])];

    
export type GetAllEducationCreditsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllEducationCredits>>>
export type GetAllEducationCreditsQueryError = unknown

export const useGetAllEducationCredits = <TData = Awaited<ReturnType<typeof getAllEducationCredits>>, TError = unknown>(
 siteId: string,
    params?: GetAllEducationCreditsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllEducationCredits>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllEducationCreditsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllEducationCredits>>> = ({ signal }) => getAllEducationCredits(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllEducationCredits>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for creating a new `EducationCredit` object for a given `Site`
 */
export const createEducationCredit = (
    siteId: string,
    educationCredit: EducationCredit,
 
) => {
      return localInstance<EducationCredit>(
      {url: `/sites/${siteId}/education_credits`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: educationCredit
    },
      );
    }
  


    export type CreateEducationCreditMutationResult = NonNullable<Awaited<ReturnType<typeof createEducationCredit>>>
    export type CreateEducationCreditMutationBody = EducationCredit
    export type CreateEducationCreditMutationError = unknown

    export const useCreateEducationCredit = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEducationCredit>>, TError,{siteId: string;data: EducationCredit}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEducationCredit>>, {siteId: string;data: EducationCredit}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createEducationCredit(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createEducationCredit>>, TError, {siteId: string;data: EducationCredit}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Contains views for a single `EducationCredit` object
 */
export const getEducationCredit = (
    siteId: string,
    creditId: string,
 signal?: AbortSignal
) => {
      return localInstance<EducationCredit>(
      {url: `/sites/${siteId}/education_credits/${creditId}`, method: 'get', signal
    },
      );
    }
  

export const getGetEducationCreditQueryKey = (siteId: string,
    creditId: string,) => [`/sites/${siteId}/education_credits/${creditId}`];

    
export type GetEducationCreditQueryResult = NonNullable<Awaited<ReturnType<typeof getEducationCredit>>>
export type GetEducationCreditQueryError = unknown

export const useGetEducationCredit = <TData = Awaited<ReturnType<typeof getEducationCredit>>, TError = unknown>(
 siteId: string,
    creditId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEducationCredit>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEducationCreditQueryKey(siteId,creditId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEducationCredit>>> = ({ signal }) => getEducationCredit(siteId,creditId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getEducationCredit>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && creditId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Contains views for a single `EducationCredit` object
 */
export const updateEducationCredit = (
    siteId: string,
    creditId: string,
    educationCredit: EducationCredit,
 
) => {
      return localInstance<EducationCredit>(
      {url: `/sites/${siteId}/education_credits/${creditId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: educationCredit
    },
      );
    }
  


    export type UpdateEducationCreditMutationResult = NonNullable<Awaited<ReturnType<typeof updateEducationCredit>>>
    export type UpdateEducationCreditMutationBody = EducationCredit
    export type UpdateEducationCreditMutationError = unknown

    export const useUpdateEducationCredit = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEducationCredit>>, TError,{siteId: string;creditId: string;data: EducationCredit}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEducationCredit>>, {siteId: string;creditId: string;data: EducationCredit}> = (props) => {
          const {siteId,creditId,data} = props ?? {};

          return  updateEducationCredit(siteId,creditId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateEducationCredit>>, TError, {siteId: string;creditId: string;data: EducationCredit}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Contains views for a single `EducationCredit` object
 */
export const deleteEducationCredit = (
    siteId: string,
    creditId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/education_credits/${creditId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteEducationCreditMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEducationCredit>>>
    
    export type DeleteEducationCreditMutationError = unknown

    export const useDeleteEducationCredit = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEducationCredit>>, TError,{siteId: string;creditId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEducationCredit>>, {siteId: string;creditId: string}> = (props) => {
          const {siteId,creditId} = props ?? {};

          return  deleteEducationCredit(siteId,creditId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteEducationCredit>>, TError, {siteId: string;creditId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for getting all `EducationCreditRequirement` objects for a given `EducationCredit`
 */
export const getAllEducationCreditRequirements = (
    siteId: string,
    creditId: string,
    params?: GetAllEducationCreditRequirementsParams,
 signal?: AbortSignal
) => {
      return localInstance<EducationCreditRequirement[]>(
      {url: `/sites/${siteId}/education_credits/${creditId}/requirements`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllEducationCreditRequirementsQueryKey = (siteId: string,
    creditId: string,
    params?: GetAllEducationCreditRequirementsParams,) => [`/sites/${siteId}/education_credits/${creditId}/requirements`, ...(params ? [params]: [])];

    
export type GetAllEducationCreditRequirementsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllEducationCreditRequirements>>>
export type GetAllEducationCreditRequirementsQueryError = unknown

export const useGetAllEducationCreditRequirements = <TData = Awaited<ReturnType<typeof getAllEducationCreditRequirements>>, TError = unknown>(
 siteId: string,
    creditId: string,
    params?: GetAllEducationCreditRequirementsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllEducationCreditRequirements>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllEducationCreditRequirementsQueryKey(siteId,creditId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllEducationCreditRequirements>>> = ({ signal }) => getAllEducationCreditRequirements(siteId,creditId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllEducationCreditRequirements>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && creditId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for creating a new `EducationCreditRequirement` object for a given `EducationCredit`
 */
export const createEducationCreditRequirement = (
    siteId: string,
    creditId: string,
    educationCreditRequirement: EducationCreditRequirement,
 
) => {
      return localInstance<EducationCreditRequirement>(
      {url: `/sites/${siteId}/education_credits/${creditId}/requirements`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: educationCreditRequirement
    },
      );
    }
  


    export type CreateEducationCreditRequirementMutationResult = NonNullable<Awaited<ReturnType<typeof createEducationCreditRequirement>>>
    export type CreateEducationCreditRequirementMutationBody = EducationCreditRequirement
    export type CreateEducationCreditRequirementMutationError = unknown

    export const useCreateEducationCreditRequirement = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEducationCreditRequirement>>, TError,{siteId: string;creditId: string;data: EducationCreditRequirement}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEducationCreditRequirement>>, {siteId: string;creditId: string;data: EducationCreditRequirement}> = (props) => {
          const {siteId,creditId,data} = props ?? {};

          return  createEducationCreditRequirement(siteId,creditId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createEducationCreditRequirement>>, TError, {siteId: string;creditId: string;data: EducationCreditRequirement}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Contains views for a single `EducationCreditRequirement` object
 */
export const getEducationCreditRequirement = (
    siteId: string,
    creditId: string,
    requirementId: string,
 signal?: AbortSignal
) => {
      return localInstance<EducationCreditRequirement>(
      {url: `/sites/${siteId}/education_credits/${creditId}/requirements/${requirementId}`, method: 'get', signal
    },
      );
    }
  

export const getGetEducationCreditRequirementQueryKey = (siteId: string,
    creditId: string,
    requirementId: string,) => [`/sites/${siteId}/education_credits/${creditId}/requirements/${requirementId}`];

    
export type GetEducationCreditRequirementQueryResult = NonNullable<Awaited<ReturnType<typeof getEducationCreditRequirement>>>
export type GetEducationCreditRequirementQueryError = unknown

export const useGetEducationCreditRequirement = <TData = Awaited<ReturnType<typeof getEducationCreditRequirement>>, TError = unknown>(
 siteId: string,
    creditId: string,
    requirementId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEducationCreditRequirement>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEducationCreditRequirementQueryKey(siteId,creditId,requirementId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEducationCreditRequirement>>> = ({ signal }) => getEducationCreditRequirement(siteId,creditId,requirementId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getEducationCreditRequirement>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && creditId && requirementId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Contains views for a single `EducationCreditRequirement` object
 */
export const updateEducationCreditRequirement = (
    siteId: string,
    creditId: string,
    requirementId: string,
    educationCreditRequirement: EducationCreditRequirement,
 
) => {
      return localInstance<EducationCreditRequirement>(
      {url: `/sites/${siteId}/education_credits/${creditId}/requirements/${requirementId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: educationCreditRequirement
    },
      );
    }
  


    export type UpdateEducationCreditRequirementMutationResult = NonNullable<Awaited<ReturnType<typeof updateEducationCreditRequirement>>>
    export type UpdateEducationCreditRequirementMutationBody = EducationCreditRequirement
    export type UpdateEducationCreditRequirementMutationError = unknown

    export const useUpdateEducationCreditRequirement = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEducationCreditRequirement>>, TError,{siteId: string;creditId: string;requirementId: string;data: EducationCreditRequirement}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEducationCreditRequirement>>, {siteId: string;creditId: string;requirementId: string;data: EducationCreditRequirement}> = (props) => {
          const {siteId,creditId,requirementId,data} = props ?? {};

          return  updateEducationCreditRequirement(siteId,creditId,requirementId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateEducationCreditRequirement>>, TError, {siteId: string;creditId: string;requirementId: string;data: EducationCreditRequirement}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Contains views for a single `EducationCreditRequirement` object
 */
export const deleteEducationCreditRequirement = (
    siteId: string,
    creditId: string,
    requirementId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/education_credits/${creditId}/requirements/${requirementId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteEducationCreditRequirementMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEducationCreditRequirement>>>
    
    export type DeleteEducationCreditRequirementMutationError = unknown

    export const useDeleteEducationCreditRequirement = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEducationCreditRequirement>>, TError,{siteId: string;creditId: string;requirementId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEducationCreditRequirement>>, {siteId: string;creditId: string;requirementId: string}> = (props) => {
          const {siteId,creditId,requirementId} = props ?? {};

          return  deleteEducationCreditRequirement(siteId,creditId,requirementId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteEducationCreditRequirement>>, TError, {siteId: string;creditId: string;requirementId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const generateCertificatePDF = (
    siteId: string,
    educationCreditId: string,
    timezoneOffset: number,
 signal?: AbortSignal
) => {
      return localInstance<string>(
      {url: `/sites/${siteId}/education_credits/${educationCreditId}/pdf/${timezoneOffset}`, method: 'get', signal
    },
      );
    }
  

export const getGenerateCertificatePDFQueryKey = (siteId: string,
    educationCreditId: string,
    timezoneOffset: number,) => [`/sites/${siteId}/education_credits/${educationCreditId}/pdf/${timezoneOffset}`];

    
export type GenerateCertificatePDFQueryResult = NonNullable<Awaited<ReturnType<typeof generateCertificatePDF>>>
export type GenerateCertificatePDFQueryError = unknown

export const useGenerateCertificatePDF = <TData = Awaited<ReturnType<typeof generateCertificatePDF>>, TError = unknown>(
 siteId: string,
    educationCreditId: string,
    timezoneOffset: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof generateCertificatePDF>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGenerateCertificatePDFQueryKey(siteId,educationCreditId,timezoneOffset);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof generateCertificatePDF>>> = ({ signal }) => generateCertificatePDF(siteId,educationCreditId,timezoneOffset, signal);

  const query = useQuery<Awaited<ReturnType<typeof generateCertificatePDF>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && educationCreditId && timezoneOffset), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Retrieve all email records for a given Juno site
 */
export const getAllEmailAnalyticsRecords = (
    siteId: string,
    params?: GetAllEmailAnalyticsRecordsParams,
 signal?: AbortSignal
) => {
      return localInstance<EmailRecord[]>(
      {url: `/sites/${siteId}/email_analytics`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllEmailAnalyticsRecordsQueryKey = (siteId: string,
    params?: GetAllEmailAnalyticsRecordsParams,) => [`/sites/${siteId}/email_analytics`, ...(params ? [params]: [])];

    
export type GetAllEmailAnalyticsRecordsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllEmailAnalyticsRecords>>>
export type GetAllEmailAnalyticsRecordsQueryError = unknown

export const useGetAllEmailAnalyticsRecords = <TData = Awaited<ReturnType<typeof getAllEmailAnalyticsRecords>>, TError = unknown>(
 siteId: string,
    params?: GetAllEmailAnalyticsRecordsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllEmailAnalyticsRecords>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllEmailAnalyticsRecordsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllEmailAnalyticsRecords>>> = ({ signal }) => getAllEmailAnalyticsRecords(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllEmailAnalyticsRecords>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Retrieve all email campaigns for a given Juno Site
 */
export const getEmailCampaigns = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<EmailCampaign[]>(
      {url: `/sites/${siteId}/email_campaigns`, method: 'get', signal
    },
      );
    }
  

export const getGetEmailCampaignsQueryKey = (siteId: string,) => [`/sites/${siteId}/email_campaigns`];

    
export type GetEmailCampaignsQueryResult = NonNullable<Awaited<ReturnType<typeof getEmailCampaigns>>>
export type GetEmailCampaignsQueryError = unknown

export const useGetEmailCampaigns = <TData = Awaited<ReturnType<typeof getEmailCampaigns>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEmailCampaigns>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEmailCampaignsQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmailCampaigns>>> = ({ signal }) => getEmailCampaigns(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getEmailCampaigns>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new email campaign for a given Juno site
 */
export const createEmailCampaign = (
    siteId: string,
    emailCampaign: EmailCampaign,
 
) => {
      return localInstance<EmailCampaign>(
      {url: `/sites/${siteId}/email_campaigns`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: emailCampaign
    },
      );
    }
  


    export type CreateEmailCampaignMutationResult = NonNullable<Awaited<ReturnType<typeof createEmailCampaign>>>
    export type CreateEmailCampaignMutationBody = EmailCampaign
    export type CreateEmailCampaignMutationError = unknown

    export const useCreateEmailCampaign = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEmailCampaign>>, TError,{siteId: string;data: EmailCampaign}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEmailCampaign>>, {siteId: string;data: EmailCampaign}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createEmailCampaign(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createEmailCampaign>>, TError, {siteId: string;data: EmailCampaign}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Retrieves a single Site email campaign
 */
export const getEmailCampaign = (
    siteId: string,
    emailCampaignId: string,
 signal?: AbortSignal
) => {
      return localInstance<EmailCampaign>(
      {url: `/sites/${siteId}/email_campaigns/${emailCampaignId}`, method: 'get', signal
    },
      );
    }
  

export const getGetEmailCampaignQueryKey = (siteId: string,
    emailCampaignId: string,) => [`/sites/${siteId}/email_campaigns/${emailCampaignId}`];

    
export type GetEmailCampaignQueryResult = NonNullable<Awaited<ReturnType<typeof getEmailCampaign>>>
export type GetEmailCampaignQueryError = unknown

export const useGetEmailCampaign = <TData = Awaited<ReturnType<typeof getEmailCampaign>>, TError = unknown>(
 siteId: string,
    emailCampaignId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEmailCampaign>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEmailCampaignQueryKey(siteId,emailCampaignId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEmailCampaign>>> = ({ signal }) => getEmailCampaign(siteId,emailCampaignId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getEmailCampaign>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && emailCampaignId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates a Site email campaign
 */
export const updateEmailCampaign = (
    siteId: string,
    emailCampaignId: string,
    emailCampaign: EmailCampaign,
 
) => {
      return localInstance<EmailCampaign>(
      {url: `/sites/${siteId}/email_campaigns/${emailCampaignId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: emailCampaign
    },
      );
    }
  


    export type UpdateEmailCampaignMutationResult = NonNullable<Awaited<ReturnType<typeof updateEmailCampaign>>>
    export type UpdateEmailCampaignMutationBody = EmailCampaign
    export type UpdateEmailCampaignMutationError = unknown

    export const useUpdateEmailCampaign = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEmailCampaign>>, TError,{siteId: string;emailCampaignId: string;data: EmailCampaign}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEmailCampaign>>, {siteId: string;emailCampaignId: string;data: EmailCampaign}> = (props) => {
          const {siteId,emailCampaignId,data} = props ?? {};

          return  updateEmailCampaign(siteId,emailCampaignId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateEmailCampaign>>, TError, {siteId: string;emailCampaignId: string;data: EmailCampaign}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a single Site email campaign
 */
export const deleteEmailCampaign = (
    siteId: string,
    emailCampaignId: string,
 
) => {
      return localInstance<EmailCampaign>(
      {url: `/sites/${siteId}/email_campaigns/${emailCampaignId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteEmailCampaignMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEmailCampaign>>>
    
    export type DeleteEmailCampaignMutationError = unknown

    export const useDeleteEmailCampaign = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEmailCampaign>>, TError,{siteId: string;emailCampaignId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEmailCampaign>>, {siteId: string;emailCampaignId: string}> = (props) => {
          const {siteId,emailCampaignId} = props ?? {};

          return  deleteEmailCampaign(siteId,emailCampaignId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteEmailCampaign>>, TError, {siteId: string;emailCampaignId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Retrieves all SendGrid email templates for a given Juno Site
 */
export const getSendGridTemplates = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<Template[]>(
      {url: `/sites/${siteId}/email_templates`, method: 'get', signal
    },
      );
    }
  

export const getGetSendGridTemplatesQueryKey = (siteId: string,) => [`/sites/${siteId}/email_templates`];

    
export type GetSendGridTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getSendGridTemplates>>>
export type GetSendGridTemplatesQueryError = unknown

export const useGetSendGridTemplates = <TData = Awaited<ReturnType<typeof getSendGridTemplates>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSendGridTemplates>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSendGridTemplatesQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSendGridTemplates>>> = ({ signal }) => getSendGridTemplates(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSendGridTemplates>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Retrieves the HTML content of a SendGrid email template
 */
export const getSendGridTemplate = (
    siteId: string,
    baseId: string,
    versionId: string,
 signal?: AbortSignal
) => {
      return localInstance<TemplateHTML>(
      {url: `/sites/${siteId}/email_templates/${baseId}/${versionId}`, method: 'get', signal
    },
      );
    }
  

export const getGetSendGridTemplateQueryKey = (siteId: string,
    baseId: string,
    versionId: string,) => [`/sites/${siteId}/email_templates/${baseId}/${versionId}`];

    
export type GetSendGridTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof getSendGridTemplate>>>
export type GetSendGridTemplateQueryError = unknown

export const useGetSendGridTemplate = <TData = Awaited<ReturnType<typeof getSendGridTemplate>>, TError = unknown>(
 siteId: string,
    baseId: string,
    versionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSendGridTemplate>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSendGridTemplateQueryKey(siteId,baseId,versionId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSendGridTemplate>>> = ({ signal }) => getSendGridTemplate(siteId,baseId,versionId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSendGridTemplate>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && baseId && versionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns all Enrollments for the given Site
 */
export const getEnrollments = (
    siteId: string,
    params?: GetEnrollmentsParams,
 signal?: AbortSignal
) => {
      return localInstance<Enrollment[]>(
      {url: `/sites/${siteId}/enrollments`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetEnrollmentsQueryKey = (siteId: string,
    params?: GetEnrollmentsParams,) => [`/sites/${siteId}/enrollments`, ...(params ? [params]: [])];

    
export type GetEnrollmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getEnrollments>>>
export type GetEnrollmentsQueryError = unknown

export const useGetEnrollments = <TData = Awaited<ReturnType<typeof getEnrollments>>, TError = unknown>(
 siteId: string,
    params?: GetEnrollmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEnrollments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEnrollmentsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEnrollments>>> = ({ signal }) => getEnrollments(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getEnrollments>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for all EventPass objects for a given Client
 */
export const getAllEventPasses = (
    siteId: string,
    params?: GetAllEventPassesParams,
 signal?: AbortSignal
) => {
      return localInstance<EventPass[]>(
      {url: `/sites/${siteId}/event_passes`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllEventPassesQueryKey = (siteId: string,
    params?: GetAllEventPassesParams,) => [`/sites/${siteId}/event_passes`, ...(params ? [params]: [])];

    
export type GetAllEventPassesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllEventPasses>>>
export type GetAllEventPassesQueryError = unknown

export const useGetAllEventPasses = <TData = Awaited<ReturnType<typeof getAllEventPasses>>, TError = unknown>(
 siteId: string,
    params?: GetAllEventPassesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllEventPasses>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllEventPassesQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllEventPasses>>> = ({ signal }) => getAllEventPasses(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllEventPasses>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for all EventPass objects for a given Client
 */
export const createEventPass = (
    siteId: string,
    eventPass: EventPass,
 
) => {
      return localInstance<EventPass>(
      {url: `/sites/${siteId}/event_passes`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: eventPass
    },
      );
    }
  


    export type CreateEventPassMutationResult = NonNullable<Awaited<ReturnType<typeof createEventPass>>>
    export type CreateEventPassMutationBody = EventPass
    export type CreateEventPassMutationError = unknown

    export const useCreateEventPass = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEventPass>>, TError,{siteId: string;data: EventPass}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEventPass>>, {siteId: string;data: EventPass}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createEventPass(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createEventPass>>, TError, {siteId: string;data: EventPass}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single EventPass object for a given Client
 */
export const getEventPass = (
    siteId: string,
    eventPassId: string,
    params?: GetEventPassParams,
 signal?: AbortSignal
) => {
      return localInstance<EventPass>(
      {url: `/sites/${siteId}/event_passes/${eventPassId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetEventPassQueryKey = (siteId: string,
    eventPassId: string,
    params?: GetEventPassParams,) => [`/sites/${siteId}/event_passes/${eventPassId}`, ...(params ? [params]: [])];

    
export type GetEventPassQueryResult = NonNullable<Awaited<ReturnType<typeof getEventPass>>>
export type GetEventPassQueryError = unknown

export const useGetEventPass = <TData = Awaited<ReturnType<typeof getEventPass>>, TError = unknown>(
 siteId: string,
    eventPassId: string,
    params?: GetEventPassParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getEventPass>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetEventPassQueryKey(siteId,eventPassId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventPass>>> = ({ signal }) => getEventPass(siteId,eventPassId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getEventPass>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && eventPassId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single EventPass object for a given Client
 */
export const updateEventPass = (
    siteId: string,
    eventPassId: string,
    eventPass: EventPass,
 
) => {
      return localInstance<EventPass>(
      {url: `/sites/${siteId}/event_passes/${eventPassId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: eventPass
    },
      );
    }
  


    export type UpdateEventPassMutationResult = NonNullable<Awaited<ReturnType<typeof updateEventPass>>>
    export type UpdateEventPassMutationBody = EventPass
    export type UpdateEventPassMutationError = unknown

    export const useUpdateEventPass = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEventPass>>, TError,{siteId: string;eventPassId: string;data: EventPass}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEventPass>>, {siteId: string;eventPassId: string;data: EventPass}> = (props) => {
          const {siteId,eventPassId,data} = props ?? {};

          return  updateEventPass(siteId,eventPassId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateEventPass>>, TError, {siteId: string;eventPassId: string;data: EventPass}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single EventPass object for a given Client
 */
export const deleteEventPass = (
    siteId: string,
    eventPassId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/event_passes/${eventPassId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteEventPassMutationResult = NonNullable<Awaited<ReturnType<typeof deleteEventPass>>>
    
    export type DeleteEventPassMutationError = unknown

    export const useDeleteEventPass = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteEventPass>>, TError,{siteId: string;eventPassId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteEventPass>>, {siteId: string;eventPassId: string}> = (props) => {
          const {siteId,eventPassId} = props ?? {};

          return  deleteEventPass(siteId,eventPassId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteEventPass>>, TError, {siteId: string;eventPassId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Get all feature configs
 */
export const getFeatureConfigs = (
    siteId: string,
    params?: GetFeatureConfigsParams,
 signal?: AbortSignal
) => {
      return localInstance<FeatureConfig[]>(
      {url: `/sites/${siteId}/feature_configs`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetFeatureConfigsQueryKey = (siteId: string,
    params?: GetFeatureConfigsParams,) => [`/sites/${siteId}/feature_configs`, ...(params ? [params]: [])];

    
export type GetFeatureConfigsQueryResult = NonNullable<Awaited<ReturnType<typeof getFeatureConfigs>>>
export type GetFeatureConfigsQueryError = unknown

export const useGetFeatureConfigs = <TData = Awaited<ReturnType<typeof getFeatureConfigs>>, TError = unknown>(
 siteId: string,
    params?: GetFeatureConfigsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFeatureConfigs>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFeatureConfigsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeatureConfigs>>> = ({ signal }) => getFeatureConfigs(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getFeatureConfigs>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Create a feature config
 */
export const createFeatureConfig = (
    siteId: string,
    featureConfig: FeatureConfig,
 
) => {
      return localInstance<FeatureConfig>(
      {url: `/sites/${siteId}/feature_configs`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: featureConfig
    },
      );
    }
  


    export type CreateFeatureConfigMutationResult = NonNullable<Awaited<ReturnType<typeof createFeatureConfig>>>
    export type CreateFeatureConfigMutationBody = FeatureConfig
    export type CreateFeatureConfigMutationError = unknown

    export const useCreateFeatureConfig = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createFeatureConfig>>, TError,{siteId: string;data: FeatureConfig}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createFeatureConfig>>, {siteId: string;data: FeatureConfig}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createFeatureConfig(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createFeatureConfig>>, TError, {siteId: string;data: FeatureConfig}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Site-level FeatureConfig object for a Client
 */
export const getSiteFeatureConfig = (
    siteId: string,
    configType: string,
 signal?: AbortSignal
) => {
      return localInstance<FeatureConfig>(
      {url: `/sites/${siteId}/feature_configs/${configType}`, method: 'get', signal
    },
      );
    }
  

export const getGetSiteFeatureConfigQueryKey = (siteId: string,
    configType: string,) => [`/sites/${siteId}/feature_configs/${configType}`];

    
export type GetSiteFeatureConfigQueryResult = NonNullable<Awaited<ReturnType<typeof getSiteFeatureConfig>>>
export type GetSiteFeatureConfigQueryError = unknown

export const useGetSiteFeatureConfig = <TData = Awaited<ReturnType<typeof getSiteFeatureConfig>>, TError = unknown>(
 siteId: string,
    configType: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSiteFeatureConfig>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSiteFeatureConfigQueryKey(siteId,configType);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSiteFeatureConfig>>> = ({ signal }) => getSiteFeatureConfig(siteId,configType, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSiteFeatureConfig>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && configType), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single Site-level FeatureConfig object for a Client
 */
export const updateSiteFeatureConfig = (
    siteId: string,
    configType: string,
    featureConfig: FeatureConfig,
 
) => {
      return localInstance<FeatureConfig>(
      {url: `/sites/${siteId}/feature_configs/${configType}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: featureConfig
    },
      );
    }
  


    export type UpdateSiteFeatureConfigMutationResult = NonNullable<Awaited<ReturnType<typeof updateSiteFeatureConfig>>>
    export type UpdateSiteFeatureConfigMutationBody = FeatureConfig
    export type UpdateSiteFeatureConfigMutationError = unknown

    export const useUpdateSiteFeatureConfig = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSiteFeatureConfig>>, TError,{siteId: string;configType: string;data: FeatureConfig}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSiteFeatureConfig>>, {siteId: string;configType: string;data: FeatureConfig}> = (props) => {
          const {siteId,configType,data} = props ?? {};

          return  updateSiteFeatureConfig(siteId,configType,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateSiteFeatureConfig>>, TError, {siteId: string;configType: string;data: FeatureConfig}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Site-level FeatureConfig object for a Client
 */
export const deleteSiteFeatureConfig = (
    siteId: string,
    configType: string,
    params?: DeleteSiteFeatureConfigParams,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/feature_configs/${configType}`, method: 'delete',
        params,
    },
      );
    }
  


    export type DeleteSiteFeatureConfigMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSiteFeatureConfig>>>
    
    export type DeleteSiteFeatureConfigMutationError = unknown

    export const useDeleteSiteFeatureConfig = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSiteFeatureConfig>>, TError,{siteId: string;configType: string;params?: DeleteSiteFeatureConfigParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSiteFeatureConfig>>, {siteId: string;configType: string;params?: DeleteSiteFeatureConfigParams}> = (props) => {
          const {siteId,configType,params} = props ?? {};

          return  deleteSiteFeatureConfig(siteId,configType,params,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteSiteFeatureConfig>>, TError, {siteId: string;configType: string;params?: DeleteSiteFeatureConfigParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Get all public feature configs
 */
export const getFeatureConfigsPublic = (
    siteId: string,
    params?: GetFeatureConfigsPublicParams,
 signal?: AbortSignal
) => {
      return localInstance<FeatureConfig[]>(
      {url: `/sites/${siteId}/feature_configs_public`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetFeatureConfigsPublicQueryKey = (siteId: string,
    params?: GetFeatureConfigsPublicParams,) => [`/sites/${siteId}/feature_configs_public`, ...(params ? [params]: [])];

    
export type GetFeatureConfigsPublicQueryResult = NonNullable<Awaited<ReturnType<typeof getFeatureConfigsPublic>>>
export type GetFeatureConfigsPublicQueryError = unknown

export const useGetFeatureConfigsPublic = <TData = Awaited<ReturnType<typeof getFeatureConfigsPublic>>, TError = unknown>(
 siteId: string,
    params?: GetFeatureConfigsPublicParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFeatureConfigsPublic>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetFeatureConfigsPublicQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFeatureConfigsPublic>>> = ({ signal }) => getFeatureConfigsPublic(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getFeatureConfigsPublic>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns the Feed for the given Site
 */
export const getSiteFeed = (
    siteId: string,
    params?: GetSiteFeedParams,
 signal?: AbortSignal
) => {
      return localInstance<Feed>(
      {url: `/sites/${siteId}/feed`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSiteFeedQueryKey = (siteId: string,
    params?: GetSiteFeedParams,) => [`/sites/${siteId}/feed`, ...(params ? [params]: [])];

    
export type GetSiteFeedQueryResult = NonNullable<Awaited<ReturnType<typeof getSiteFeed>>>
export type GetSiteFeedQueryError = unknown

export const useGetSiteFeed = <TData = Awaited<ReturnType<typeof getSiteFeed>>, TError = unknown>(
 siteId: string,
    params?: GetSiteFeedParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSiteFeed>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSiteFeedQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSiteFeed>>> = ({ signal }) => getSiteFeed(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSiteFeed>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Feed on the given Site
 */
export const sitesFeedCreate = (
    siteId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/feed`, method: 'post'
    },
      );
    }
  


    export type SitesFeedCreateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesFeedCreate>>>
    
    export type SitesFeedCreateMutationError = unknown

    export const useSitesFeedCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sitesFeedCreate>>, TError,{siteId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sitesFeedCreate>>, {siteId: string}> = (props) => {
          const {siteId} = props ?? {};

          return  sitesFeedCreate(siteId,)
        }

      return useMutation<Awaited<ReturnType<typeof sitesFeedCreate>>, TError, {siteId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates the existing Feed on the given Site
 */
export const sitesFeedUpdate = (
    siteId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/feed`, method: 'put'
    },
      );
    }
  


    export type SitesFeedUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesFeedUpdate>>>
    
    export type SitesFeedUpdateMutationError = unknown

    export const useSitesFeedUpdate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sitesFeedUpdate>>, TError,{siteId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sitesFeedUpdate>>, {siteId: string}> = (props) => {
          const {siteId} = props ?? {};

          return  sitesFeedUpdate(siteId,)
        }

      return useMutation<Awaited<ReturnType<typeof sitesFeedUpdate>>, TError, {siteId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the existing Feed on the given Site
 */
export const sitesFeedDestroy = (
    siteId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/feed`, method: 'delete'
    },
      );
    }
  


    export type SitesFeedDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof sitesFeedDestroy>>>
    
    export type SitesFeedDestroyMutationError = unknown

    export const useSitesFeedDestroy = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sitesFeedDestroy>>, TError,{siteId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sitesFeedDestroy>>, {siteId: string}> = (props) => {
          const {siteId} = props ?? {};

          return  sitesFeedDestroy(siteId,)
        }

      return useMutation<Awaited<ReturnType<typeof sitesFeedDestroy>>, TError, {siteId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns the `Forum` associated with the `Site` if any
 */
export const getSiteForum = (
    siteId: string,
    params?: GetSiteForumParams,
 signal?: AbortSignal
) => {
      return localInstance<Forum>(
      {url: `/sites/${siteId}/forum`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSiteForumQueryKey = (siteId: string,
    params?: GetSiteForumParams,) => [`/sites/${siteId}/forum`, ...(params ? [params]: [])];

    
export type GetSiteForumQueryResult = NonNullable<Awaited<ReturnType<typeof getSiteForum>>>
export type GetSiteForumQueryError = unknown

export const useGetSiteForum = <TData = Awaited<ReturnType<typeof getSiteForum>>, TError = unknown>(
 siteId: string,
    params?: GetSiteForumParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSiteForum>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSiteForumQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSiteForum>>> = ({ signal }) => getSiteForum(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSiteForum>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns all GeneratedReport objects for the site
 */
export const getAllGeneratedReports = (
    siteId: string,
    params?: GetAllGeneratedReportsParams,
 signal?: AbortSignal
) => {
      return localInstance<GeneratedReport[]>(
      {url: `/sites/${siteId}/generated_reports`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllGeneratedReportsQueryKey = (siteId: string,
    params?: GetAllGeneratedReportsParams,) => [`/sites/${siteId}/generated_reports`, ...(params ? [params]: [])];

    
export type GetAllGeneratedReportsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllGeneratedReports>>>
export type GetAllGeneratedReportsQueryError = unknown

export const useGetAllGeneratedReports = <TData = Awaited<ReturnType<typeof getAllGeneratedReports>>, TError = unknown>(
 siteId: string,
    params?: GetAllGeneratedReportsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllGeneratedReports>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllGeneratedReportsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllGeneratedReports>>> = ({ signal }) => getAllGeneratedReports(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllGeneratedReports>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new GeneratedReport object for the site
 */
export const createGeneratedReport = (
    siteId: string,
    generatedReport: GeneratedReport,
    params?: CreateGeneratedReportParams,
 
) => {
      return localInstance<GeneratedReport>(
      {url: `/sites/${siteId}/generated_reports`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: generatedReport,
        params,
    },
      );
    }
  


    export type CreateGeneratedReportMutationResult = NonNullable<Awaited<ReturnType<typeof createGeneratedReport>>>
    export type CreateGeneratedReportMutationBody = GeneratedReport
    export type CreateGeneratedReportMutationError = unknown

    export const useCreateGeneratedReport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createGeneratedReport>>, TError,{siteId: string;data: GeneratedReport;params?: CreateGeneratedReportParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createGeneratedReport>>, {siteId: string;data: GeneratedReport;params?: CreateGeneratedReportParams}> = (props) => {
          const {siteId,data,params} = props ?? {};

          return  createGeneratedReport(siteId,data,params,)
        }

      return useMutation<Awaited<ReturnType<typeof createGeneratedReport>>, TError, {siteId: string;data: GeneratedReport;params?: CreateGeneratedReportParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single GeneratedReport object based on the site/id
 */
export const getGeneratedReport = (
    siteId: string,
    reportId: string,
 signal?: AbortSignal
) => {
      return localInstance<GeneratedReport>(
      {url: `/sites/${siteId}/generated_reports/${reportId}`, method: 'get', signal
    },
      );
    }
  

export const getGetGeneratedReportQueryKey = (siteId: string,
    reportId: string,) => [`/sites/${siteId}/generated_reports/${reportId}`];

    
export type GetGeneratedReportQueryResult = NonNullable<Awaited<ReturnType<typeof getGeneratedReport>>>
export type GetGeneratedReportQueryError = unknown

export const useGetGeneratedReport = <TData = Awaited<ReturnType<typeof getGeneratedReport>>, TError = unknown>(
 siteId: string,
    reportId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getGeneratedReport>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetGeneratedReportQueryKey(siteId,reportId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGeneratedReport>>> = ({ signal }) => getGeneratedReport(siteId,reportId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getGeneratedReport>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && reportId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates the given GeneratedReport object

TODO: Do we even use this??
 */
export const updateGeneratedReport = (
    siteId: string,
    reportId: string,
    generatedReport: GeneratedReport,
 
) => {
      return localInstance<GeneratedReport>(
      {url: `/sites/${siteId}/generated_reports/${reportId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: generatedReport
    },
      );
    }
  


    export type UpdateGeneratedReportMutationResult = NonNullable<Awaited<ReturnType<typeof updateGeneratedReport>>>
    export type UpdateGeneratedReportMutationBody = GeneratedReport
    export type UpdateGeneratedReportMutationError = unknown

    export const useUpdateGeneratedReport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateGeneratedReport>>, TError,{siteId: string;reportId: string;data: GeneratedReport}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateGeneratedReport>>, {siteId: string;reportId: string;data: GeneratedReport}> = (props) => {
          const {siteId,reportId,data} = props ?? {};

          return  updateGeneratedReport(siteId,reportId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateGeneratedReport>>, TError, {siteId: string;reportId: string;data: GeneratedReport}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the given GeneratedReport object
 */
export const deleteGeneratedReport = (
    siteId: string,
    reportId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/generated_reports/${reportId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteGeneratedReportMutationResult = NonNullable<Awaited<ReturnType<typeof deleteGeneratedReport>>>
    
    export type DeleteGeneratedReportMutationError = unknown

    export const useDeleteGeneratedReport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteGeneratedReport>>, TError,{siteId: string;reportId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteGeneratedReport>>, {siteId: string;reportId: string}> = (props) => {
          const {siteId,reportId} = props ?? {};

          return  deleteGeneratedReport(siteId,reportId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteGeneratedReport>>, TError, {siteId: string;reportId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for forcing all Enrollment objects for all Courses in the Site to be (re)graded
 */
export const gradeAllSiteEnrollments = (
    siteId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/grade_all_enrollments`, method: 'put'
    },
      );
    }
  


    export type GradeAllSiteEnrollmentsMutationResult = NonNullable<Awaited<ReturnType<typeof gradeAllSiteEnrollments>>>
    
    export type GradeAllSiteEnrollmentsMutationError = unknown

    export const useGradeAllSiteEnrollments = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof gradeAllSiteEnrollments>>, TError,{siteId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof gradeAllSiteEnrollments>>, {siteId: string}> = (props) => {
          const {siteId} = props ?? {};

          return  gradeAllSiteEnrollments(siteId,)
        }

      return useMutation<Awaited<ReturnType<typeof gradeAllSiteEnrollments>>, TError, {siteId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views on the Client collection
 */
export const getGroupContentReports = (
    siteId: string,
    groupId: string,
    params?: GetGroupContentReportsParams,
 signal?: AbortSignal
) => {
      return localInstance<ContentReportWithUsers[]>(
      {url: `/sites/${siteId}/groups/${groupId}/content_reports`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetGroupContentReportsQueryKey = (siteId: string,
    groupId: string,
    params?: GetGroupContentReportsParams,) => [`/sites/${siteId}/groups/${groupId}/content_reports`, ...(params ? [params]: [])];

    
export type GetGroupContentReportsQueryResult = NonNullable<Awaited<ReturnType<typeof getGroupContentReports>>>
export type GetGroupContentReportsQueryError = unknown

export const useGetGroupContentReports = <TData = Awaited<ReturnType<typeof getGroupContentReports>>, TError = unknown>(
 siteId: string,
    groupId: string,
    params?: GetGroupContentReportsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getGroupContentReports>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetGroupContentReportsQueryKey(siteId,groupId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getGroupContentReports>>> = ({ signal }) => getGroupContentReports(siteId,groupId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getGroupContentReports>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && groupId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns the echo parameter

GrowthZone Webhook will send a GET request with an echo parameter to confirm that the endpoint exists.
This value needs to be returned as plain text in the body of the response.
 */
export const sitesGrowthzoneWebhookRetrieve = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/growthzone/webhook`, method: 'get', signal
    },
      );
    }
  

export const getSitesGrowthzoneWebhookRetrieveQueryKey = (siteId: string,) => [`/sites/${siteId}/growthzone/webhook`];

    
export type SitesGrowthzoneWebhookRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof sitesGrowthzoneWebhookRetrieve>>>
export type SitesGrowthzoneWebhookRetrieveQueryError = unknown

export const useSitesGrowthzoneWebhookRetrieve = <TData = Awaited<ReturnType<typeof sitesGrowthzoneWebhookRetrieve>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof sitesGrowthzoneWebhookRetrieve>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSitesGrowthzoneWebhookRetrieveQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof sitesGrowthzoneWebhookRetrieve>>> = ({ signal }) => sitesGrowthzoneWebhookRetrieve(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof sitesGrowthzoneWebhookRetrieve>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Handles Webhook events from GrowthZone AMS
 */
export const sitesGrowthzoneWebhookCreate = (
    siteId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/growthzone/webhook`, method: 'post'
    },
      );
    }
  


    export type SitesGrowthzoneWebhookCreateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesGrowthzoneWebhookCreate>>>
    
    export type SitesGrowthzoneWebhookCreateMutationError = unknown

    export const useSitesGrowthzoneWebhookCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sitesGrowthzoneWebhookCreate>>, TError,{siteId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sitesGrowthzoneWebhookCreate>>, {siteId: string}> = (props) => {
          const {siteId} = props ?? {};

          return  sitesGrowthzoneWebhookCreate(siteId,)
        }

      return useMutation<Awaited<ReturnType<typeof sitesGrowthzoneWebhookCreate>>, TError, {siteId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Gets a list of all ImportRecords for the given site
 */
export const getAllImportRecords = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<ImportRecord[]>(
      {url: `/sites/${siteId}/imports`, method: 'get', signal
    },
      );
    }
  

export const getGetAllImportRecordsQueryKey = (siteId: string,) => [`/sites/${siteId}/imports`];

    
export type GetAllImportRecordsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllImportRecords>>>
export type GetAllImportRecordsQueryError = unknown

export const useGetAllImportRecords = <TData = Awaited<ReturnType<typeof getAllImportRecords>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllImportRecords>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllImportRecordsQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllImportRecords>>> = ({ signal }) => getAllImportRecords(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllImportRecords>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new ImportRecord for the site, and triggers the async import task
 */
export const createImportRecord = (
    siteId: string,
    importRecord: ImportRecord,
 
) => {
      return localInstance<ImportRecord>(
      {url: `/sites/${siteId}/imports`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: importRecord
    },
      );
    }
  


    export type CreateImportRecordMutationResult = NonNullable<Awaited<ReturnType<typeof createImportRecord>>>
    export type CreateImportRecordMutationBody = ImportRecord
    export type CreateImportRecordMutationError = unknown

    export const useCreateImportRecord = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createImportRecord>>, TError,{siteId: string;data: ImportRecord}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createImportRecord>>, {siteId: string;data: ImportRecord}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createImportRecord(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createImportRecord>>, TError, {siteId: string;data: ImportRecord}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single ImportRecord
 */
export const getImportRecord = (
    siteId: string,
    importId: string,
 signal?: AbortSignal
) => {
      return localInstance<ImportRecord>(
      {url: `/sites/${siteId}/imports/${importId}`, method: 'get', signal
    },
      );
    }
  

export const getGetImportRecordQueryKey = (siteId: string,
    importId: string,) => [`/sites/${siteId}/imports/${importId}`];

    
export type GetImportRecordQueryResult = NonNullable<Awaited<ReturnType<typeof getImportRecord>>>
export type GetImportRecordQueryError = unknown

export const useGetImportRecord = <TData = Awaited<ReturnType<typeof getImportRecord>>, TError = unknown>(
 siteId: string,
    importId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getImportRecord>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetImportRecordQueryKey(siteId,importId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getImportRecord>>> = ({ signal }) => getImportRecord(siteId,importId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getImportRecord>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && importId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for all LanguageConfig objects for a Client
 */
export const getLanguageConfigs = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<LanguageConfig[]>(
      {url: `/sites/${siteId}/language_configs`, method: 'get', signal
    },
      );
    }
  

export const getGetLanguageConfigsQueryKey = (siteId: string,) => [`/sites/${siteId}/language_configs`];

    
export type GetLanguageConfigsQueryResult = NonNullable<Awaited<ReturnType<typeof getLanguageConfigs>>>
export type GetLanguageConfigsQueryError = unknown

export const useGetLanguageConfigs = <TData = Awaited<ReturnType<typeof getLanguageConfigs>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLanguageConfigs>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLanguageConfigsQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLanguageConfigs>>> = ({ signal }) => getLanguageConfigs(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLanguageConfigs>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for all LanguageConfig objects for a Client
 */
export const createLanguageConfig = (
    siteId: string,
    languageConfig: LanguageConfig,
 
) => {
      return localInstance<LanguageConfig>(
      {url: `/sites/${siteId}/language_configs`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: languageConfig
    },
      );
    }
  


    export type CreateLanguageConfigMutationResult = NonNullable<Awaited<ReturnType<typeof createLanguageConfig>>>
    export type CreateLanguageConfigMutationBody = LanguageConfig
    export type CreateLanguageConfigMutationError = unknown

    export const useCreateLanguageConfig = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLanguageConfig>>, TError,{siteId: string;data: LanguageConfig}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createLanguageConfig>>, {siteId: string;data: LanguageConfig}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createLanguageConfig(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createLanguageConfig>>, TError, {siteId: string;data: LanguageConfig}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single LanguageConfig object
 */
export const updateLanguageConfig = (
    siteId: string,
    configId: string,
    languageConfig: LanguageConfig,
 
) => {
      return localInstance<LanguageConfig>(
      {url: `/sites/${siteId}/language_configs/${configId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: languageConfig
    },
      );
    }
  


    export type UpdateLanguageConfigMutationResult = NonNullable<Awaited<ReturnType<typeof updateLanguageConfig>>>
    export type UpdateLanguageConfigMutationBody = LanguageConfig
    export type UpdateLanguageConfigMutationError = unknown

    export const useUpdateLanguageConfig = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLanguageConfig>>, TError,{siteId: string;configId: string;data: LanguageConfig}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateLanguageConfig>>, {siteId: string;configId: string;data: LanguageConfig}> = (props) => {
          const {siteId,configId,data} = props ?? {};

          return  updateLanguageConfig(siteId,configId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateLanguageConfig>>, TError, {siteId: string;configId: string;data: LanguageConfig}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single LanguageConfig object
 */
export const deleteLanguageConfig = (
    siteId: string,
    configId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/language_configs/${configId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteLanguageConfigMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLanguageConfig>>>
    
    export type DeleteLanguageConfigMutationError = unknown

    export const useDeleteLanguageConfig = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLanguageConfig>>, TError,{siteId: string;configId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLanguageConfig>>, {siteId: string;configId: string}> = (props) => {
          const {siteId,configId} = props ?? {};

          return  deleteLanguageConfig(siteId,configId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteLanguageConfig>>, TError, {siteId: string;configId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns details of user Enrollments such as completions, credits, etc
 */
export const getLearningDashboardSummary = (
    siteId: string,
    params?: GetLearningDashboardSummaryParams,
 signal?: AbortSignal
) => {
      return localInstance<LearningSummary>(
      {url: `/sites/${siteId}/learning/dashboard/learner_summary`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetLearningDashboardSummaryQueryKey = (siteId: string,
    params?: GetLearningDashboardSummaryParams,) => [`/sites/${siteId}/learning/dashboard/learner_summary`, ...(params ? [params]: [])];

    
export type GetLearningDashboardSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof getLearningDashboardSummary>>>
export type GetLearningDashboardSummaryQueryError = unknown

export const useGetLearningDashboardSummary = <TData = Awaited<ReturnType<typeof getLearningDashboardSummary>>, TError = unknown>(
 siteId: string,
    params?: GetLearningDashboardSummaryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLearningDashboardSummary>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLearningDashboardSummaryQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLearningDashboardSummary>>> = ({ signal }) => getLearningDashboardSummary(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLearningDashboardSummary>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns all Lessons for the Site
 */
export const getAllLessons = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<Lesson[]>(
      {url: `/sites/${siteId}/lessons`, method: 'get', signal
    },
      );
    }
  

export const getGetAllLessonsQueryKey = (siteId: string,) => [`/sites/${siteId}/lessons`];

    
export type GetAllLessonsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllLessons>>>
export type GetAllLessonsQueryError = unknown

export const useGetAllLessons = <TData = Awaited<ReturnType<typeof getAllLessons>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllLessons>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllLessonsQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllLessons>>> = ({ signal }) => getAllLessons(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllLessons>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for dealing with all LibraryEntry objects for a given Client
 */
export const getAllLibraryEntries = (
    siteId: string,
    params?: GetAllLibraryEntriesParams,
 signal?: AbortSignal
) => {
      return localInstance<LibraryEntry[]>(
      {url: `/sites/${siteId}/library_entries`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllLibraryEntriesQueryKey = (siteId: string,
    params?: GetAllLibraryEntriesParams,) => [`/sites/${siteId}/library_entries`, ...(params ? [params]: [])];

    
export type GetAllLibraryEntriesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllLibraryEntries>>>
export type GetAllLibraryEntriesQueryError = unknown

export const useGetAllLibraryEntries = <TData = Awaited<ReturnType<typeof getAllLibraryEntries>>, TError = unknown>(
 siteId: string,
    params?: GetAllLibraryEntriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllLibraryEntries>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllLibraryEntriesQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllLibraryEntries>>> = ({ signal }) => getAllLibraryEntries(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllLibraryEntries>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for dealing with all LibraryEntry objects for a given Client
 */
export const createLibraryEntry = (
    siteId: string,
    libraryEntry: LibraryEntry,
 
) => {
      return localInstance<LibraryEntry>(
      {url: `/sites/${siteId}/library_entries`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: libraryEntry
    },
      );
    }
  


    export type CreateLibraryEntryMutationResult = NonNullable<Awaited<ReturnType<typeof createLibraryEntry>>>
    export type CreateLibraryEntryMutationBody = LibraryEntry
    export type CreateLibraryEntryMutationError = unknown

    export const useCreateLibraryEntry = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createLibraryEntry>>, TError,{siteId: string;data: LibraryEntry}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createLibraryEntry>>, {siteId: string;data: LibraryEntry}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createLibraryEntry(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createLibraryEntry>>, TError, {siteId: string;data: LibraryEntry}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with a single LibraryEntry
 */
export const getLibraryEntry = (
    siteId: string,
    libraryEntryId: string,
    params?: GetLibraryEntryParams,
 signal?: AbortSignal
) => {
      return localInstance<LibraryEntry>(
      {url: `/sites/${siteId}/library_entries/${libraryEntryId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetLibraryEntryQueryKey = (siteId: string,
    libraryEntryId: string,
    params?: GetLibraryEntryParams,) => [`/sites/${siteId}/library_entries/${libraryEntryId}`, ...(params ? [params]: [])];

    
export type GetLibraryEntryQueryResult = NonNullable<Awaited<ReturnType<typeof getLibraryEntry>>>
export type GetLibraryEntryQueryError = unknown

export const useGetLibraryEntry = <TData = Awaited<ReturnType<typeof getLibraryEntry>>, TError = unknown>(
 siteId: string,
    libraryEntryId: string,
    params?: GetLibraryEntryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getLibraryEntry>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetLibraryEntryQueryKey(siteId,libraryEntryId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getLibraryEntry>>> = ({ signal }) => getLibraryEntry(siteId,libraryEntryId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getLibraryEntry>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && libraryEntryId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for dealing with a single LibraryEntry
 */
export const updateLibraryEntry = (
    siteId: string,
    libraryEntryId: string,
    libraryEntry: LibraryEntry,
 
) => {
      return localInstance<LibraryEntry>(
      {url: `/sites/${siteId}/library_entries/${libraryEntryId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: libraryEntry
    },
      );
    }
  


    export type UpdateLibraryEntryMutationResult = NonNullable<Awaited<ReturnType<typeof updateLibraryEntry>>>
    export type UpdateLibraryEntryMutationBody = LibraryEntry
    export type UpdateLibraryEntryMutationError = unknown

    export const useUpdateLibraryEntry = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateLibraryEntry>>, TError,{siteId: string;libraryEntryId: string;data: LibraryEntry}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateLibraryEntry>>, {siteId: string;libraryEntryId: string;data: LibraryEntry}> = (props) => {
          const {siteId,libraryEntryId,data} = props ?? {};

          return  updateLibraryEntry(siteId,libraryEntryId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateLibraryEntry>>, TError, {siteId: string;libraryEntryId: string;data: LibraryEntry}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with a single LibraryEntry
 */
export const deleteLibraryEntry = (
    siteId: string,
    libraryEntryId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/library_entries/${libraryEntryId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteLibraryEntryMutationResult = NonNullable<Awaited<ReturnType<typeof deleteLibraryEntry>>>
    
    export type DeleteLibraryEntryMutationError = unknown

    export const useDeleteLibraryEntry = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteLibraryEntry>>, TError,{siteId: string;libraryEntryId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteLibraryEntry>>, {siteId: string;libraryEntryId: string}> = (props) => {
          const {siteId,libraryEntryId} = props ?? {};

          return  deleteLibraryEntry(siteId,libraryEntryId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteLibraryEntry>>, TError, {siteId: string;libraryEntryId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of JunoUsers that the requesting JunoUser has blocked
 */
export const getBlockedUsers = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<UserPreview[]>(
      {url: `/sites/${siteId}/my_blocked_users`, method: 'get', signal
    },
      );
    }
  

export const getGetBlockedUsersQueryKey = (siteId: string,) => [`/sites/${siteId}/my_blocked_users`];

    
export type GetBlockedUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getBlockedUsers>>>
export type GetBlockedUsersQueryError = unknown

export const useGetBlockedUsers = <TData = Awaited<ReturnType<typeof getBlockedUsers>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBlockedUsers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetBlockedUsersQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBlockedUsers>>> = ({ signal }) => getBlockedUsers(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBlockedUsers>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views on the Client collection
 */
export const getMyContentReports = (
    siteId: string,
    params?: GetMyContentReportsParams,
 signal?: AbortSignal
) => {
      return localInstance<ContentReportWithUsers[]>(
      {url: `/sites/${siteId}/my_content_reports`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetMyContentReportsQueryKey = (siteId: string,
    params?: GetMyContentReportsParams,) => [`/sites/${siteId}/my_content_reports`, ...(params ? [params]: [])];

    
export type GetMyContentReportsQueryResult = NonNullable<Awaited<ReturnType<typeof getMyContentReports>>>
export type GetMyContentReportsQueryError = unknown

export const useGetMyContentReports = <TData = Awaited<ReturnType<typeof getMyContentReports>>, TError = unknown>(
 siteId: string,
    params?: GetMyContentReportsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMyContentReports>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMyContentReportsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyContentReports>>> = ({ signal }) => getMyContentReports(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMyContentReports>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for getting all Enrollment objects for the currently logged in User
 */
export const getMyEnrollments = (
    siteId: string,
    params?: GetMyEnrollmentsParams,
 signal?: AbortSignal
) => {
      return localInstance<Enrollment[]>(
      {url: `/sites/${siteId}/my_enrollments`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetMyEnrollmentsQueryKey = (siteId: string,
    params?: GetMyEnrollmentsParams,) => [`/sites/${siteId}/my_enrollments`, ...(params ? [params]: [])];

    
export type GetMyEnrollmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getMyEnrollments>>>
export type GetMyEnrollmentsQueryError = unknown

export const useGetMyEnrollments = <TData = Awaited<ReturnType<typeof getMyEnrollments>>, TError = unknown>(
 siteId: string,
    params?: GetMyEnrollmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMyEnrollments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMyEnrollmentsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyEnrollments>>> = ({ signal }) => getMyEnrollments(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMyEnrollments>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns a list of JunoUser objects that the requesting user can manage based on their AdminGroup membership
 */
export const getMyLearners = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<JunoUser[]>(
      {url: `/sites/${siteId}/my_learners`, method: 'get', signal
    },
      );
    }
  

export const getGetMyLearnersQueryKey = (siteId: string,) => [`/sites/${siteId}/my_learners`];

    
export type GetMyLearnersQueryResult = NonNullable<Awaited<ReturnType<typeof getMyLearners>>>
export type GetMyLearnersQueryError = unknown

export const useGetMyLearners = <TData = Awaited<ReturnType<typeof getMyLearners>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMyLearners>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMyLearnersQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyLearners>>> = ({ signal }) => getMyLearners(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMyLearners>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns a list of Enrollment objects for all the learners in AdminGroups that belong to the requesting user (who is an admin in those groups)
 */
export const getMyLearnerEnrollments = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<Enrollment[]>(
      {url: `/sites/${siteId}/my_learners/enrollments`, method: 'get', signal
    },
      );
    }
  

export const getGetMyLearnerEnrollmentsQueryKey = (siteId: string,) => [`/sites/${siteId}/my_learners/enrollments`];

    
export type GetMyLearnerEnrollmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getMyLearnerEnrollments>>>
export type GetMyLearnerEnrollmentsQueryError = unknown

export const useGetMyLearnerEnrollments = <TData = Awaited<ReturnType<typeof getMyLearnerEnrollments>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMyLearnerEnrollments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMyLearnerEnrollmentsQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyLearnerEnrollments>>> = ({ signal }) => getMyLearnerEnrollments(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMyLearnerEnrollments>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns a list of Waitlist objects for all the learners in AdminGroups that belong to the requesting user (who is an admin in those groups)
 */
export const getMyLearnerWaitlists = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<WaitListedEnrollment[]>(
      {url: `/sites/${siteId}/my_learners/waitlists`, method: 'get', signal
    },
      );
    }
  

export const getGetMyLearnerWaitlistsQueryKey = (siteId: string,) => [`/sites/${siteId}/my_learners/waitlists`];

    
export type GetMyLearnerWaitlistsQueryResult = NonNullable<Awaited<ReturnType<typeof getMyLearnerWaitlists>>>
export type GetMyLearnerWaitlistsQueryError = unknown

export const useGetMyLearnerWaitlists = <TData = Awaited<ReturnType<typeof getMyLearnerWaitlists>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMyLearnerWaitlists>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMyLearnerWaitlistsQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyLearnerWaitlists>>> = ({ signal }) => getMyLearnerWaitlists(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMyLearnerWaitlists>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns all SavedReport objects for the site and user
 */
export const getMySavedReports = (
    siteId: string,
    params?: GetMySavedReportsParams,
 signal?: AbortSignal
) => {
      return localInstance<SavedReport[]>(
      {url: `/sites/${siteId}/my_saved_reports`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetMySavedReportsQueryKey = (siteId: string,
    params?: GetMySavedReportsParams,) => [`/sites/${siteId}/my_saved_reports`, ...(params ? [params]: [])];

    
export type GetMySavedReportsQueryResult = NonNullable<Awaited<ReturnType<typeof getMySavedReports>>>
export type GetMySavedReportsQueryError = unknown

export const useGetMySavedReports = <TData = Awaited<ReturnType<typeof getMySavedReports>>, TError = unknown>(
 siteId: string,
    params?: GetMySavedReportsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMySavedReports>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMySavedReportsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMySavedReports>>> = ({ signal }) => getMySavedReports(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMySavedReports>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new SavedReport object for the site/user
 */
export const createMySavedReport = (
    siteId: string,
    savedReport: SavedReport,
    params?: CreateMySavedReportParams,
 
) => {
      return localInstance<SavedReport>(
      {url: `/sites/${siteId}/my_saved_reports`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: savedReport,
        params,
    },
      );
    }
  


    export type CreateMySavedReportMutationResult = NonNullable<Awaited<ReturnType<typeof createMySavedReport>>>
    export type CreateMySavedReportMutationBody = SavedReport
    export type CreateMySavedReportMutationError = unknown

    export const useCreateMySavedReport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMySavedReport>>, TError,{siteId: string;data: SavedReport;params?: CreateMySavedReportParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMySavedReport>>, {siteId: string;data: SavedReport;params?: CreateMySavedReportParams}> = (props) => {
          const {siteId,data,params} = props ?? {};

          return  createMySavedReport(siteId,data,params,)
        }

      return useMutation<Awaited<ReturnType<typeof createMySavedReport>>, TError, {siteId: string;data: SavedReport;params?: CreateMySavedReportParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single SavedReport object based on the site/id/user
 */
export const getMySavedReport = (
    siteId: string,
    reportId: string,
 signal?: AbortSignal
) => {
      return localInstance<SavedReport>(
      {url: `/sites/${siteId}/my_saved_reports/${reportId}`, method: 'get', signal
    },
      );
    }
  

export const getGetMySavedReportQueryKey = (siteId: string,
    reportId: string,) => [`/sites/${siteId}/my_saved_reports/${reportId}`];

    
export type GetMySavedReportQueryResult = NonNullable<Awaited<ReturnType<typeof getMySavedReport>>>
export type GetMySavedReportQueryError = unknown

export const useGetMySavedReport = <TData = Awaited<ReturnType<typeof getMySavedReport>>, TError = unknown>(
 siteId: string,
    reportId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMySavedReport>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMySavedReportQueryKey(siteId,reportId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMySavedReport>>> = ({ signal }) => getMySavedReport(siteId,reportId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMySavedReport>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && reportId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Deletes the given SavedReport object
 */
export const deleteMySavedReport = (
    siteId: string,
    reportId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/my_saved_reports/${reportId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteMySavedReportMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMySavedReport>>>
    
    export type DeleteMySavedReportMutationError = unknown

    export const useDeleteMySavedReport = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMySavedReport>>, TError,{siteId: string;reportId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMySavedReport>>, {siteId: string;reportId: string}> = (props) => {
          const {siteId,reportId} = props ?? {};

          return  deleteMySavedReport(siteId,reportId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteMySavedReport>>, TError, {siteId: string;reportId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of JunoUsers that the requesting JunoUser has saved
 */
export const getSavedUsers = (
    siteId: string,
    params?: GetSavedUsersParams,
 signal?: AbortSignal
) => {
      return localInstance<JunoUser[]>(
      {url: `/sites/${siteId}/my_saved_users`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSavedUsersQueryKey = (siteId: string,
    params?: GetSavedUsersParams,) => [`/sites/${siteId}/my_saved_users`, ...(params ? [params]: [])];

    
export type GetSavedUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getSavedUsers>>>
export type GetSavedUsersQueryError = unknown

export const useGetSavedUsers = <TData = Awaited<ReturnType<typeof getSavedUsers>>, TError = unknown>(
 siteId: string,
    params?: GetSavedUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSavedUsers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSavedUsersQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSavedUsers>>> = ({ signal }) => getSavedUsers(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSavedUsers>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns a list of Courses that the logged-in user is an instructor of
 */
export const getMyTaughtCourses = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<Course[]>(
      {url: `/sites/${siteId}/my_taught_courses`, method: 'get', signal
    },
      );
    }
  

export const getGetMyTaughtCoursesQueryKey = (siteId: string,) => [`/sites/${siteId}/my_taught_courses`];

    
export type GetMyTaughtCoursesQueryResult = NonNullable<Awaited<ReturnType<typeof getMyTaughtCourses>>>
export type GetMyTaughtCoursesQueryError = unknown

export const useGetMyTaughtCourses = <TData = Awaited<ReturnType<typeof getMyTaughtCourses>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMyTaughtCourses>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMyTaughtCoursesQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyTaughtCourses>>> = ({ signal }) => getMyTaughtCourses(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMyTaughtCourses>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for all NavigationItem object for a Client
 */
export const getNavigationItems = (
    siteId: string,
    params?: GetNavigationItemsParams,
 signal?: AbortSignal
) => {
      return localInstance<NavigationItem[]>(
      {url: `/sites/${siteId}/navigation_items`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetNavigationItemsQueryKey = (siteId: string,
    params?: GetNavigationItemsParams,) => [`/sites/${siteId}/navigation_items`, ...(params ? [params]: [])];

    
export type GetNavigationItemsQueryResult = NonNullable<Awaited<ReturnType<typeof getNavigationItems>>>
export type GetNavigationItemsQueryError = unknown

export const useGetNavigationItems = <TData = Awaited<ReturnType<typeof getNavigationItems>>, TError = unknown>(
 siteId: string,
    params?: GetNavigationItemsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNavigationItems>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetNavigationItemsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNavigationItems>>> = ({ signal }) => getNavigationItems(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getNavigationItems>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for all NavigationItem object for a Client
 */
export const createUpdateNavigationItems = (
    siteId: string,
    navigationItem: NavigationItem[],
 
) => {
      return localInstance<NavigationItem>(
      {url: `/sites/${siteId}/navigation_items`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: navigationItem
    },
      );
    }
  


    export type CreateUpdateNavigationItemsMutationResult = NonNullable<Awaited<ReturnType<typeof createUpdateNavigationItems>>>
    export type CreateUpdateNavigationItemsMutationBody = NavigationItem[]
    export type CreateUpdateNavigationItemsMutationError = unknown

    export const useCreateUpdateNavigationItems = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUpdateNavigationItems>>, TError,{siteId: string;data: NavigationItem[]}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUpdateNavigationItems>>, {siteId: string;data: NavigationItem[]}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createUpdateNavigationItems(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createUpdateNavigationItems>>, TError, {siteId: string;data: NavigationItem[]}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single NavigationItem for a Client
 */
export const getNavigationItem = (
    siteId: string,
    navigationItemId: string,
    params?: GetNavigationItemParams,
 signal?: AbortSignal
) => {
      return localInstance<NavigationItem>(
      {url: `/sites/${siteId}/navigation_items/${navigationItemId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetNavigationItemQueryKey = (siteId: string,
    navigationItemId: string,
    params?: GetNavigationItemParams,) => [`/sites/${siteId}/navigation_items/${navigationItemId}`, ...(params ? [params]: [])];

    
export type GetNavigationItemQueryResult = NonNullable<Awaited<ReturnType<typeof getNavigationItem>>>
export type GetNavigationItemQueryError = unknown

export const useGetNavigationItem = <TData = Awaited<ReturnType<typeof getNavigationItem>>, TError = unknown>(
 siteId: string,
    navigationItemId: string,
    params?: GetNavigationItemParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNavigationItem>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetNavigationItemQueryKey(siteId,navigationItemId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNavigationItem>>> = ({ signal }) => getNavigationItem(siteId,navigationItemId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getNavigationItem>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && navigationItemId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single NavigationItem for a Client
 */
export const updateNavigationItem = (
    siteId: string,
    navigationItemId: string,
    navigationItem: NavigationItem,
 
) => {
      return localInstance<NavigationItem>(
      {url: `/sites/${siteId}/navigation_items/${navigationItemId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: navigationItem
    },
      );
    }
  


    export type UpdateNavigationItemMutationResult = NonNullable<Awaited<ReturnType<typeof updateNavigationItem>>>
    export type UpdateNavigationItemMutationBody = NavigationItem
    export type UpdateNavigationItemMutationError = unknown

    export const useUpdateNavigationItem = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateNavigationItem>>, TError,{siteId: string;navigationItemId: string;data: NavigationItem}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateNavigationItem>>, {siteId: string;navigationItemId: string;data: NavigationItem}> = (props) => {
          const {siteId,navigationItemId,data} = props ?? {};

          return  updateNavigationItem(siteId,navigationItemId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateNavigationItem>>, TError, {siteId: string;navigationItemId: string;data: NavigationItem}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single NavigationItem for a Client
 */
export const deleteNavigationItem = (
    siteId: string,
    navigationItemId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/navigation_items/${navigationItemId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteNavigationItemMutationResult = NonNullable<Awaited<ReturnType<typeof deleteNavigationItem>>>
    
    export type DeleteNavigationItemMutationError = unknown

    export const useDeleteNavigationItem = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNavigationItem>>, TError,{siteId: string;navigationItemId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteNavigationItem>>, {siteId: string;navigationItemId: string}> = (props) => {
          const {siteId,navigationItemId} = props ?? {};

          return  deleteNavigationItem(siteId,navigationItemId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteNavigationItem>>, TError, {siteId: string;navigationItemId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Retrieves a user's notification preferences
 */
export const getNotificationPreferences = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<NotificationPreferences[]>(
      {url: `/sites/${siteId}/notification_preferences`, method: 'get', signal
    },
      );
    }
  

export const getGetNotificationPreferencesQueryKey = (siteId: string,) => [`/sites/${siteId}/notification_preferences`];

    
export type GetNotificationPreferencesQueryResult = NonNullable<Awaited<ReturnType<typeof getNotificationPreferences>>>
export type GetNotificationPreferencesQueryError = unknown

export const useGetNotificationPreferences = <TData = Awaited<ReturnType<typeof getNotificationPreferences>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNotificationPreferences>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetNotificationPreferencesQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotificationPreferences>>> = ({ signal }) => getNotificationPreferences(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getNotificationPreferences>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates a user's notification preferences
 */
export const updateNotificationPreferences = (
    siteId: string,
    notificationPreferences: NotificationPreferences,
 
) => {
      return localInstance<NotificationPreferences>(
      {url: `/sites/${siteId}/notification_preferences`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: notificationPreferences
    },
      );
    }
  


    export type UpdateNotificationPreferencesMutationResult = NonNullable<Awaited<ReturnType<typeof updateNotificationPreferences>>>
    export type UpdateNotificationPreferencesMutationBody = NotificationPreferences
    export type UpdateNotificationPreferencesMutationError = unknown

    export const useUpdateNotificationPreferences = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateNotificationPreferences>>, TError,{siteId: string;data: NotificationPreferences}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateNotificationPreferences>>, {siteId: string;data: NotificationPreferences}> = (props) => {
          const {siteId,data} = props ?? {};

          return  updateNotificationPreferences(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateNotificationPreferences>>, TError, {siteId: string;data: NotificationPreferences}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Retrieve all notifications for a given user
 */
export const getAllNotifications = (
    siteId: string,
    params?: GetAllNotificationsParams,
 signal?: AbortSignal
) => {
      return localInstance<Notification[]>(
      {url: `/sites/${siteId}/notifications`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllNotificationsQueryKey = (siteId: string,
    params?: GetAllNotificationsParams,) => [`/sites/${siteId}/notifications`, ...(params ? [params]: [])];

    
export type GetAllNotificationsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllNotifications>>>
export type GetAllNotificationsQueryError = unknown

export const useGetAllNotifications = <TData = Awaited<ReturnType<typeof getAllNotifications>>, TError = unknown>(
 siteId: string,
    params?: GetAllNotificationsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllNotifications>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllNotificationsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllNotifications>>> = ({ signal }) => getAllNotifications(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllNotifications>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new notification for a given Juno user
 */
export const createNotification = (
    siteId: string,
    notification: Notification,
 
) => {
      return localInstance<Notification>(
      {url: `/sites/${siteId}/notifications`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: notification
    },
      );
    }
  


    export type CreateNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof createNotification>>>
    export type CreateNotificationMutationBody = Notification
    export type CreateNotificationMutationError = unknown

    export const useCreateNotification = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNotification>>, TError,{siteId: string;data: Notification}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createNotification>>, {siteId: string;data: Notification}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createNotification(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createNotification>>, TError, {siteId: string;data: Notification}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Retrieves a single notification
 */
export const getNotification = (
    siteId: string,
    notificationId: string,
 signal?: AbortSignal
) => {
      return localInstance<Notification>(
      {url: `/sites/${siteId}/notifications/${notificationId}`, method: 'get', signal
    },
      );
    }
  

export const getGetNotificationQueryKey = (siteId: string,
    notificationId: string,) => [`/sites/${siteId}/notifications/${notificationId}`];

    
export type GetNotificationQueryResult = NonNullable<Awaited<ReturnType<typeof getNotification>>>
export type GetNotificationQueryError = unknown

export const useGetNotification = <TData = Awaited<ReturnType<typeof getNotification>>, TError = unknown>(
 siteId: string,
    notificationId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNotification>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetNotificationQueryKey(siteId,notificationId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotification>>> = ({ signal }) => getNotification(siteId,notificationId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getNotification>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && notificationId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates a notification
 */
export const updateNotification = (
    siteId: string,
    notificationId: string,
    notification: Notification,
 
) => {
      return localInstance<Notification>(
      {url: `/sites/${siteId}/notifications/${notificationId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: notification
    },
      );
    }
  


    export type UpdateNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof updateNotification>>>
    export type UpdateNotificationMutationBody = Notification
    export type UpdateNotificationMutationError = unknown

    export const useUpdateNotification = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateNotification>>, TError,{siteId: string;notificationId: string;data: Notification}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateNotification>>, {siteId: string;notificationId: string;data: Notification}> = (props) => {
          const {siteId,notificationId,data} = props ?? {};

          return  updateNotification(siteId,notificationId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateNotification>>, TError, {siteId: string;notificationId: string;data: Notification}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a single notification
 */
export const deleteNotification = (
    siteId: string,
    notificationId: string,
 
) => {
      return localInstance<Notification>(
      {url: `/sites/${siteId}/notifications/${notificationId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteNotification>>>
    
    export type DeleteNotificationMutationError = unknown

    export const useDeleteNotification = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNotification>>, TError,{siteId: string;notificationId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteNotification>>, {siteId: string;notificationId: string}> = (props) => {
          const {siteId,notificationId} = props ?? {};

          return  deleteNotification(siteId,notificationId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteNotification>>, TError, {siteId: string;notificationId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes notifications
 */
export const deleteNotifications = (
    siteId: string,
    notificationIds: NotificationIds,
 
) => {
      return localInstance<DeleteNotifications200>(
      {url: `/sites/${siteId}/notifications/delete`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: notificationIds
    },
      );
    }
  


    export type DeleteNotificationsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteNotifications>>>
    export type DeleteNotificationsMutationBody = NotificationIds
    export type DeleteNotificationsMutationError = unknown

    export const useDeleteNotifications = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNotifications>>, TError,{siteId: string;data: NotificationIds}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteNotifications>>, {siteId: string;data: NotificationIds}> = (props) => {
          const {siteId,data} = props ?? {};

          return  deleteNotifications(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteNotifications>>, TError, {siteId: string;data: NotificationIds}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Marks notifications as read
 */
export const markNotificationsAsRead = (
    siteId: string,
    notificationIds: NotificationIds,
 
) => {
      return localInstance<MarkNotificationsAsRead200>(
      {url: `/sites/${siteId}/notifications/mark_as_read`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: notificationIds
    },
      );
    }
  


    export type MarkNotificationsAsReadMutationResult = NonNullable<Awaited<ReturnType<typeof markNotificationsAsRead>>>
    export type MarkNotificationsAsReadMutationBody = NotificationIds
    export type MarkNotificationsAsReadMutationError = unknown

    export const useMarkNotificationsAsRead = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof markNotificationsAsRead>>, TError,{siteId: string;data: NotificationIds}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof markNotificationsAsRead>>, {siteId: string;data: NotificationIds}> = (props) => {
          const {siteId,data} = props ?? {};

          return  markNotificationsAsRead(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof markNotificationsAsRead>>, TError, {siteId: string;data: NotificationIds}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View to handle API requests to OpenAI for generating content based on a prompt.
 */
export const generateContentWithOpenAI = (
    siteId: string,
    openAIRequest: OpenAIRequest,
 
) => {
      return localInstance<OpenAIRequest>(
      {url: `/sites/${siteId}/openai/generate-content/`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: openAIRequest
    },
      );
    }
  


    export type GenerateContentWithOpenAIMutationResult = NonNullable<Awaited<ReturnType<typeof generateContentWithOpenAI>>>
    export type GenerateContentWithOpenAIMutationBody = OpenAIRequest
    export type GenerateContentWithOpenAIMutationError = unknown

    export const useGenerateContentWithOpenAI = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof generateContentWithOpenAI>>, TError,{siteId: string;data: OpenAIRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof generateContentWithOpenAI>>, {siteId: string;data: OpenAIRequest}> = (props) => {
          const {siteId,data} = props ?? {};

          return  generateContentWithOpenAI(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof generateContentWithOpenAI>>, TError, {siteId: string;data: OpenAIRequest}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for all Page objects for a Client
 */
export const getPages = (
    siteId: string,
    params?: GetPagesParams,
 signal?: AbortSignal
) => {
      return localInstance<Page[]>(
      {url: `/sites/${siteId}/pages`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetPagesQueryKey = (siteId: string,
    params?: GetPagesParams,) => [`/sites/${siteId}/pages`, ...(params ? [params]: [])];

    
export type GetPagesQueryResult = NonNullable<Awaited<ReturnType<typeof getPages>>>
export type GetPagesQueryError = unknown

export const useGetPages = <TData = Awaited<ReturnType<typeof getPages>>, TError = unknown>(
 siteId: string,
    params?: GetPagesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPages>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPagesQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPages>>> = ({ signal }) => getPages(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPages>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for all Page objects for a Client
 */
export const createPage = (
    siteId: string,
    page: Page,
 
) => {
      return localInstance<Page>(
      {url: `/sites/${siteId}/pages`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: page
    },
      );
    }
  


    export type CreatePageMutationResult = NonNullable<Awaited<ReturnType<typeof createPage>>>
    export type CreatePageMutationBody = Page
    export type CreatePageMutationError = unknown

    export const useCreatePage = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPage>>, TError,{siteId: string;data: Page}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPage>>, {siteId: string;data: Page}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createPage(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createPage>>, TError, {siteId: string;data: Page}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Page for a Client
 */
export const getPage = (
    siteId: string,
    pageId: string,
    params?: GetPageParams,
 signal?: AbortSignal
) => {
      return localInstance<Page>(
      {url: `/sites/${siteId}/pages/${pageId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetPageQueryKey = (siteId: string,
    pageId: string,
    params?: GetPageParams,) => [`/sites/${siteId}/pages/${pageId}`, ...(params ? [params]: [])];

    
export type GetPageQueryResult = NonNullable<Awaited<ReturnType<typeof getPage>>>
export type GetPageQueryError = unknown

export const useGetPage = <TData = Awaited<ReturnType<typeof getPage>>, TError = unknown>(
 siteId: string,
    pageId: string,
    params?: GetPageParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPage>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPageQueryKey(siteId,pageId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPage>>> = ({ signal }) => getPage(siteId,pageId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPage>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && pageId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single Page for a Client
 */
export const updatePage = (
    siteId: string,
    pageId: string,
    page: Page,
 
) => {
      return localInstance<Page>(
      {url: `/sites/${siteId}/pages/${pageId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: page
    },
      );
    }
  


    export type UpdatePageMutationResult = NonNullable<Awaited<ReturnType<typeof updatePage>>>
    export type UpdatePageMutationBody = Page
    export type UpdatePageMutationError = unknown

    export const useUpdatePage = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePage>>, TError,{siteId: string;pageId: string;data: Page}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePage>>, {siteId: string;pageId: string;data: Page}> = (props) => {
          const {siteId,pageId,data} = props ?? {};

          return  updatePage(siteId,pageId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updatePage>>, TError, {siteId: string;pageId: string;data: Page}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single Page for a Client
 */
export const deletePage = (
    siteId: string,
    pageId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/pages/${pageId}`, method: 'delete'
    },
      );
    }
  


    export type DeletePageMutationResult = NonNullable<Awaited<ReturnType<typeof deletePage>>>
    
    export type DeletePageMutationError = unknown

    export const useDeletePage = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePage>>, TError,{siteId: string;pageId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePage>>, {siteId: string;pageId: string}> = (props) => {
          const {siteId,pageId} = props ?? {};

          return  deletePage(siteId,pageId,)
        }

      return useMutation<Awaited<ReturnType<typeof deletePage>>, TError, {siteId: string;pageId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Adds a Component to a Page
 */
export const addPageComponent = (
    siteId: string,
    pageId: string,
    componentId: string,
    pageComponent: PageComponent,
 
) => {
      return localInstance<AddPageComponent200>(
      {url: `/sites/${siteId}/pages/${pageId}/components/${componentId}`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: pageComponent
    },
      );
    }
  


    export type AddPageComponentMutationResult = NonNullable<Awaited<ReturnType<typeof addPageComponent>>>
    export type AddPageComponentMutationBody = PageComponent
    export type AddPageComponentMutationError = unknown

    export const useAddPageComponent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addPageComponent>>, TError,{siteId: string;pageId: string;componentId: string;data: PageComponent}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addPageComponent>>, {siteId: string;pageId: string;componentId: string;data: PageComponent}> = (props) => {
          const {siteId,pageId,componentId,data} = props ?? {};

          return  addPageComponent(siteId,pageId,componentId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof addPageComponent>>, TError, {siteId: string;pageId: string;componentId: string;data: PageComponent}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates the given Component on the given Page
 */
export const updatePageComponent = (
    siteId: string,
    pageId: string,
    componentId: string,
    pageComponent: PageComponent,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/pages/${pageId}/components/${componentId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: pageComponent
    },
      );
    }
  


    export type UpdatePageComponentMutationResult = NonNullable<Awaited<ReturnType<typeof updatePageComponent>>>
    export type UpdatePageComponentMutationBody = PageComponent
    export type UpdatePageComponentMutationError = unknown

    export const useUpdatePageComponent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePageComponent>>, TError,{siteId: string;pageId: string;componentId: string;data: PageComponent}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePageComponent>>, {siteId: string;pageId: string;componentId: string;data: PageComponent}> = (props) => {
          const {siteId,pageId,componentId,data} = props ?? {};

          return  updatePageComponent(siteId,pageId,componentId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updatePageComponent>>, TError, {siteId: string;pageId: string;componentId: string;data: PageComponent}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Removes the given Component from the given Page
 */
export const removePageComponent = (
    siteId: string,
    pageId: string,
    componentId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/pages/${pageId}/components/${componentId}`, method: 'delete'
    },
      );
    }
  


    export type RemovePageComponentMutationResult = NonNullable<Awaited<ReturnType<typeof removePageComponent>>>
    
    export type RemovePageComponentMutationError = unknown

    export const useRemovePageComponent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removePageComponent>>, TError,{siteId: string;pageId: string;componentId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removePageComponent>>, {siteId: string;pageId: string;componentId: string}> = (props) => {
          const {siteId,pageId,componentId} = props ?? {};

          return  removePageComponent(siteId,pageId,componentId,)
        }

      return useMutation<Awaited<ReturnType<typeof removePageComponent>>, TError, {siteId: string;pageId: string;componentId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with all Partner objects for a Client
 */
export const getAllPartners = (
    siteId: string,
    params?: GetAllPartnersParams,
 signal?: AbortSignal
) => {
      return localInstance<Partner[]>(
      {url: `/sites/${siteId}/partners`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllPartnersQueryKey = (siteId: string,
    params?: GetAllPartnersParams,) => [`/sites/${siteId}/partners`, ...(params ? [params]: [])];

    
export type GetAllPartnersQueryResult = NonNullable<Awaited<ReturnType<typeof getAllPartners>>>
export type GetAllPartnersQueryError = unknown

export const useGetAllPartners = <TData = Awaited<ReturnType<typeof getAllPartners>>, TError = unknown>(
 siteId: string,
    params?: GetAllPartnersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllPartners>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllPartnersQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllPartners>>> = ({ signal }) => getAllPartners(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllPartners>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for dealing with all Partner objects for a Client
 */
export const createPartner = (
    siteId: string,
    partner: Partner,
 
) => {
      return localInstance<Partner>(
      {url: `/sites/${siteId}/partners`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: partner
    },
      );
    }
  


    export type CreatePartnerMutationResult = NonNullable<Awaited<ReturnType<typeof createPartner>>>
    export type CreatePartnerMutationBody = Partner
    export type CreatePartnerMutationError = unknown

    export const useCreatePartner = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPartner>>, TError,{siteId: string;data: Partner}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPartner>>, {siteId: string;data: Partner}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createPartner(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createPartner>>, TError, {siteId: string;data: Partner}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with a single Partner object for a Client
 */
export const getPartner = (
    siteId: string,
    partnerId: string,
    params?: GetPartnerParams,
 signal?: AbortSignal
) => {
      return localInstance<Partner>(
      {url: `/sites/${siteId}/partners/${partnerId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetPartnerQueryKey = (siteId: string,
    partnerId: string,
    params?: GetPartnerParams,) => [`/sites/${siteId}/partners/${partnerId}`, ...(params ? [params]: [])];

    
export type GetPartnerQueryResult = NonNullable<Awaited<ReturnType<typeof getPartner>>>
export type GetPartnerQueryError = unknown

export const useGetPartner = <TData = Awaited<ReturnType<typeof getPartner>>, TError = unknown>(
 siteId: string,
    partnerId: string,
    params?: GetPartnerParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPartner>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPartnerQueryKey(siteId,partnerId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPartner>>> = ({ signal }) => getPartner(siteId,partnerId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPartner>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && partnerId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for dealing with a single Partner object for a Client
 */
export const updatePartner = (
    siteId: string,
    partnerId: string,
    partner: Partner,
 
) => {
      return localInstance<Partner>(
      {url: `/sites/${siteId}/partners/${partnerId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: partner
    },
      );
    }
  


    export type UpdatePartnerMutationResult = NonNullable<Awaited<ReturnType<typeof updatePartner>>>
    export type UpdatePartnerMutationBody = Partner
    export type UpdatePartnerMutationError = unknown

    export const useUpdatePartner = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePartner>>, TError,{siteId: string;partnerId: string;data: Partner}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePartner>>, {siteId: string;partnerId: string;data: Partner}> = (props) => {
          const {siteId,partnerId,data} = props ?? {};

          return  updatePartner(siteId,partnerId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updatePartner>>, TError, {siteId: string;partnerId: string;data: Partner}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with a single Partner object for a Client
 */
export const deletePartner = (
    siteId: string,
    partnerId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/partners/${partnerId}`, method: 'delete'
    },
      );
    }
  


    export type DeletePartnerMutationResult = NonNullable<Awaited<ReturnType<typeof deletePartner>>>
    
    export type DeletePartnerMutationError = unknown

    export const useDeletePartner = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePartner>>, TError,{siteId: string;partnerId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePartner>>, {siteId: string;partnerId: string}> = (props) => {
          const {siteId,partnerId} = props ?? {};

          return  deletePartner(siteId,partnerId,)
        }

      return useMutation<Awaited<ReturnType<typeof deletePartner>>, TError, {siteId: string;partnerId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for interacting with all JunoUsers associated with a given Partner
 */
export const getPartnerUsers = (
    siteId: string,
    partnerId: string,
    params?: GetPartnerUsersParams,
 signal?: AbortSignal
) => {
      return localInstance<JunoUser[]>(
      {url: `/sites/${siteId}/partners/${partnerId}/users`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetPartnerUsersQueryKey = (siteId: string,
    partnerId: string,
    params?: GetPartnerUsersParams,) => [`/sites/${siteId}/partners/${partnerId}/users`, ...(params ? [params]: [])];

    
export type GetPartnerUsersQueryResult = NonNullable<Awaited<ReturnType<typeof getPartnerUsers>>>
export type GetPartnerUsersQueryError = unknown

export const useGetPartnerUsers = <TData = Awaited<ReturnType<typeof getPartnerUsers>>, TError = unknown>(
 siteId: string,
    partnerId: string,
    params?: GetPartnerUsersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPartnerUsers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPartnerUsersQueryKey(siteId,partnerId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPartnerUsers>>> = ({ signal }) => getPartnerUsers(siteId,partnerId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPartnerUsers>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && partnerId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for interacting with all JunoUsers associated with a given Partner
 */
export const addPartnerUser = (
    siteId: string,
    partnerId: string,
    createPartnerUser: CreatePartnerUser,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/partners/${partnerId}/users`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: createPartnerUser
    },
      );
    }
  


    export type AddPartnerUserMutationResult = NonNullable<Awaited<ReturnType<typeof addPartnerUser>>>
    export type AddPartnerUserMutationBody = CreatePartnerUser
    export type AddPartnerUserMutationError = unknown

    export const useAddPartnerUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addPartnerUser>>, TError,{siteId: string;partnerId: string;data: CreatePartnerUser}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addPartnerUser>>, {siteId: string;partnerId: string;data: CreatePartnerUser}> = (props) => {
          const {siteId,partnerId,data} = props ?? {};

          return  addPartnerUser(siteId,partnerId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof addPartnerUser>>, TError, {siteId: string;partnerId: string;data: CreatePartnerUser}, TContext>(mutationFn, mutationOptions)
    }
    
export const sitesPaymentStripeWebhookCreate = (
    siteId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/payment/stripe/webhook`, method: 'post'
    },
      );
    }
  


    export type SitesPaymentStripeWebhookCreateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesPaymentStripeWebhookCreate>>>
    
    export type SitesPaymentStripeWebhookCreateMutationError = unknown

    export const useSitesPaymentStripeWebhookCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sitesPaymentStripeWebhookCreate>>, TError,{siteId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sitesPaymentStripeWebhookCreate>>, {siteId: string}> = (props) => {
          const {siteId} = props ?? {};

          return  sitesPaymentStripeWebhookCreate(siteId,)
        }

      return useMutation<Awaited<ReturnType<typeof sitesPaymentStripeWebhookCreate>>, TError, {siteId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Generate a presigned URL for an S3 object
 */
export const getPresignedUrlForS3Url = (
    siteId: string,
    params?: GetPresignedUrlForS3UrlParams,
 signal?: AbortSignal
) => {
      return localInstance<PresignedUrl>(
      {url: `/sites/${siteId}/presigned`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetPresignedUrlForS3UrlQueryKey = (siteId: string,
    params?: GetPresignedUrlForS3UrlParams,) => [`/sites/${siteId}/presigned`, ...(params ? [params]: [])];

    
export type GetPresignedUrlForS3UrlQueryResult = NonNullable<Awaited<ReturnType<typeof getPresignedUrlForS3Url>>>
export type GetPresignedUrlForS3UrlQueryError = unknown

export const useGetPresignedUrlForS3Url = <TData = Awaited<ReturnType<typeof getPresignedUrlForS3Url>>, TError = unknown>(
 siteId: string,
    params?: GetPresignedUrlForS3UrlParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPresignedUrlForS3Url>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPresignedUrlForS3UrlQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPresignedUrlForS3Url>>> = ({ signal }) => getPresignedUrlForS3Url(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPresignedUrlForS3Url>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns a list of all PriceLevels for the Site
 */
export const getPriceLevels = (
    siteId: string,
    params?: GetPriceLevelsParams,
 signal?: AbortSignal
) => {
      return localInstance<PriceLevel[]>(
      {url: `/sites/${siteId}/price_levels`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetPriceLevelsQueryKey = (siteId: string,
    params?: GetPriceLevelsParams,) => [`/sites/${siteId}/price_levels`, ...(params ? [params]: [])];

    
export type GetPriceLevelsQueryResult = NonNullable<Awaited<ReturnType<typeof getPriceLevels>>>
export type GetPriceLevelsQueryError = unknown

export const useGetPriceLevels = <TData = Awaited<ReturnType<typeof getPriceLevels>>, TError = unknown>(
 siteId: string,
    params?: GetPriceLevelsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPriceLevels>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPriceLevelsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPriceLevels>>> = ({ signal }) => getPriceLevels(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPriceLevels>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new PriceLevel on the Site
 */
export const createPriceLevel = (
    siteId: string,
    priceLevel: PriceLevel,
 
) => {
      return localInstance<PriceLevel>(
      {url: `/sites/${siteId}/price_levels`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: priceLevel
    },
      );
    }
  


    export type CreatePriceLevelMutationResult = NonNullable<Awaited<ReturnType<typeof createPriceLevel>>>
    export type CreatePriceLevelMutationBody = PriceLevel
    export type CreatePriceLevelMutationError = unknown

    export const useCreatePriceLevel = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createPriceLevel>>, TError,{siteId: string;data: PriceLevel}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createPriceLevel>>, {siteId: string;data: PriceLevel}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createPriceLevel(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createPriceLevel>>, TError, {siteId: string;data: PriceLevel}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single PriceLevel
 */
export const getPriceLevel = (
    siteId: string,
    levelId: string,
 signal?: AbortSignal
) => {
      return localInstance<PriceLevel>(
      {url: `/sites/${siteId}/price_levels/${levelId}`, method: 'get', signal
    },
      );
    }
  

export const getGetPriceLevelQueryKey = (siteId: string,
    levelId: string,) => [`/sites/${siteId}/price_levels/${levelId}`];

    
export type GetPriceLevelQueryResult = NonNullable<Awaited<ReturnType<typeof getPriceLevel>>>
export type GetPriceLevelQueryError = unknown

export const useGetPriceLevel = <TData = Awaited<ReturnType<typeof getPriceLevel>>, TError = unknown>(
 siteId: string,
    levelId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPriceLevel>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetPriceLevelQueryKey(siteId,levelId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPriceLevel>>> = ({ signal }) => getPriceLevel(siteId,levelId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPriceLevel>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && levelId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates the given PriceLevel
 */
export const updatePriceLevel = (
    siteId: string,
    levelId: string,
    priceLevel: PriceLevel,
 
) => {
      return localInstance<PriceLevel>(
      {url: `/sites/${siteId}/price_levels/${levelId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: priceLevel
    },
      );
    }
  


    export type UpdatePriceLevelMutationResult = NonNullable<Awaited<ReturnType<typeof updatePriceLevel>>>
    export type UpdatePriceLevelMutationBody = PriceLevel
    export type UpdatePriceLevelMutationError = unknown

    export const useUpdatePriceLevel = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updatePriceLevel>>, TError,{siteId: string;levelId: string;data: PriceLevel}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updatePriceLevel>>, {siteId: string;levelId: string;data: PriceLevel}> = (props) => {
          const {siteId,levelId,data} = props ?? {};

          return  updatePriceLevel(siteId,levelId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updatePriceLevel>>, TError, {siteId: string;levelId: string;data: PriceLevel}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes an existing PriceLevel
 */
export const deletePriceLevel = (
    siteId: string,
    levelId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/price_levels/${levelId}`, method: 'delete'
    },
      );
    }
  


    export type DeletePriceLevelMutationResult = NonNullable<Awaited<ReturnType<typeof deletePriceLevel>>>
    
    export type DeletePriceLevelMutationError = unknown

    export const useDeletePriceLevel = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deletePriceLevel>>, TError,{siteId: string;levelId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePriceLevel>>, {siteId: string;levelId: string}> = (props) => {
          const {siteId,levelId} = props ?? {};

          return  deletePriceLevel(siteId,levelId,)
        }

      return useMutation<Awaited<ReturnType<typeof deletePriceLevel>>, TError, {siteId: string;levelId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of all Products for the Site
 */
export const getProducts = (
    siteId: string,
    params?: GetProductsParams,
 signal?: AbortSignal
) => {
      return localInstance<Product[]>(
      {url: `/sites/${siteId}/products`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetProductsQueryKey = (siteId: string,
    params?: GetProductsParams,) => [`/sites/${siteId}/products`, ...(params ? [params]: [])];

    
export type GetProductsQueryResult = NonNullable<Awaited<ReturnType<typeof getProducts>>>
export type GetProductsQueryError = unknown

export const useGetProducts = <TData = Awaited<ReturnType<typeof getProducts>>, TError = unknown>(
 siteId: string,
    params?: GetProductsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProducts>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProducts>>> = ({ signal }) => getProducts(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getProducts>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a new Product
 */
export const createProduct = (
    siteId: string,
    product: Product,
 
) => {
      return localInstance<Product>(
      {url: `/sites/${siteId}/products`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: product
    },
      );
    }
  


    export type CreateProductMutationResult = NonNullable<Awaited<ReturnType<typeof createProduct>>>
    export type CreateProductMutationBody = Product
    export type CreateProductMutationError = unknown

    export const useCreateProduct = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProduct>>, TError,{siteId: string;data: Product}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProduct>>, {siteId: string;data: Product}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createProduct(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createProduct>>, TError, {siteId: string;data: Product}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a single Product
 */
export const getProduct = (
    siteId: string,
    productId: string,
 signal?: AbortSignal
) => {
      return localInstance<Product>(
      {url: `/sites/${siteId}/products/${productId}`, method: 'get', signal
    },
      );
    }
  

export const getGetProductQueryKey = (siteId: string,
    productId: string,) => [`/sites/${siteId}/products/${productId}`];

    
export type GetProductQueryResult = NonNullable<Awaited<ReturnType<typeof getProduct>>>
export type GetProductQueryError = unknown

export const useGetProduct = <TData = Awaited<ReturnType<typeof getProduct>>, TError = unknown>(
 siteId: string,
    productId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProduct>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductQueryKey(siteId,productId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProduct>>> = ({ signal }) => getProduct(siteId,productId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getProduct>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && productId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates the given Product
 */
export const updateProduct = (
    siteId: string,
    productId: string,
    product: Product,
 
) => {
      return localInstance<Product>(
      {url: `/sites/${siteId}/products/${productId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: product
    },
      );
    }
  


    export type UpdateProductMutationResult = NonNullable<Awaited<ReturnType<typeof updateProduct>>>
    export type UpdateProductMutationBody = Product
    export type UpdateProductMutationError = unknown

    export const useUpdateProduct = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProduct>>, TError,{siteId: string;productId: string;data: Product}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProduct>>, {siteId: string;productId: string;data: Product}> = (props) => {
          const {siteId,productId,data} = props ?? {};

          return  updateProduct(siteId,productId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateProduct>>, TError, {siteId: string;productId: string;data: Product}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Removes the given Product
 */
export const deleteProduct = (
    siteId: string,
    productId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/products/${productId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteProductMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProduct>>>
    
    export type DeleteProductMutationError = unknown

    export const useDeleteProduct = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProduct>>, TError,{siteId: string;productId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProduct>>, {siteId: string;productId: string}> = (props) => {
          const {siteId,productId} = props ?? {};

          return  deleteProduct(siteId,productId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteProduct>>, TError, {siteId: string;productId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const getProductPrices = (
    siteId: string,
    productId: string,
    params?: GetProductPricesParams,
 signal?: AbortSignal
) => {
      return localInstance<ProductPrice[]>(
      {url: `/sites/${siteId}/products/${productId}/prices`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetProductPricesQueryKey = (siteId: string,
    productId: string,
    params?: GetProductPricesParams,) => [`/sites/${siteId}/products/${productId}/prices`, ...(params ? [params]: [])];

    
export type GetProductPricesQueryResult = NonNullable<Awaited<ReturnType<typeof getProductPrices>>>
export type GetProductPricesQueryError = unknown

export const useGetProductPrices = <TData = Awaited<ReturnType<typeof getProductPrices>>, TError = unknown>(
 siteId: string,
    productId: string,
    params?: GetProductPricesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductPrices>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductPricesQueryKey(siteId,productId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductPrices>>> = ({ signal }) => getProductPrices(siteId,productId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getProductPrices>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && productId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const createProductPrice = (
    siteId: string,
    productId: string,
    productPrice: ProductPrice,
 
) => {
      return localInstance<ProductPrice>(
      {url: `/sites/${siteId}/products/${productId}/prices`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: productPrice
    },
      );
    }
  


    export type CreateProductPriceMutationResult = NonNullable<Awaited<ReturnType<typeof createProductPrice>>>
    export type CreateProductPriceMutationBody = ProductPrice
    export type CreateProductPriceMutationError = unknown

    export const useCreateProductPrice = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProductPrice>>, TError,{siteId: string;productId: string;data: ProductPrice}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProductPrice>>, {siteId: string;productId: string;data: ProductPrice}> = (props) => {
          const {siteId,productId,data} = props ?? {};

          return  createProductPrice(siteId,productId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createProductPrice>>, TError, {siteId: string;productId: string;data: ProductPrice}, TContext>(mutationFn, mutationOptions)
    }
    
export const getProductPrice = (
    siteId: string,
    productId: string,
    priceId: string,
 signal?: AbortSignal
) => {
      return localInstance<ProductPrice>(
      {url: `/sites/${siteId}/products/${productId}/prices/${priceId}`, method: 'get', signal
    },
      );
    }
  

export const getGetProductPriceQueryKey = (siteId: string,
    productId: string,
    priceId: string,) => [`/sites/${siteId}/products/${productId}/prices/${priceId}`];

    
export type GetProductPriceQueryResult = NonNullable<Awaited<ReturnType<typeof getProductPrice>>>
export type GetProductPriceQueryError = unknown

export const useGetProductPrice = <TData = Awaited<ReturnType<typeof getProductPrice>>, TError = unknown>(
 siteId: string,
    productId: string,
    priceId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getProductPrice>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetProductPriceQueryKey(siteId,productId,priceId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductPrice>>> = ({ signal }) => getProductPrice(siteId,productId,priceId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getProductPrice>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && productId && priceId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const updateProductPrice = (
    siteId: string,
    productId: string,
    priceId: string,
    productPrice: ProductPrice,
 
) => {
      return localInstance<ProductPrice>(
      {url: `/sites/${siteId}/products/${productId}/prices/${priceId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: productPrice
    },
      );
    }
  


    export type UpdateProductPriceMutationResult = NonNullable<Awaited<ReturnType<typeof updateProductPrice>>>
    export type UpdateProductPriceMutationBody = ProductPrice
    export type UpdateProductPriceMutationError = unknown

    export const useUpdateProductPrice = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProductPrice>>, TError,{siteId: string;productId: string;priceId: string;data: ProductPrice}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProductPrice>>, {siteId: string;productId: string;priceId: string;data: ProductPrice}> = (props) => {
          const {siteId,productId,priceId,data} = props ?? {};

          return  updateProductPrice(siteId,productId,priceId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateProductPrice>>, TError, {siteId: string;productId: string;priceId: string;data: ProductPrice}, TContext>(mutationFn, mutationOptions)
    }
    
export const deleteProductPrice = (
    siteId: string,
    productId: string,
    priceId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/products/${productId}/prices/${priceId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteProductPriceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProductPrice>>>
    
    export type DeleteProductPriceMutationError = unknown

    export const useDeleteProductPrice = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProductPrice>>, TError,{siteId: string;productId: string;priceId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProductPrice>>, {siteId: string;productId: string;priceId: string}> = (props) => {
          const {siteId,productId,priceId} = props ?? {};

          return  deleteProductPrice(siteId,productId,priceId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteProductPrice>>, TError, {siteId: string;productId: string;priceId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const getAllUserPurchases = (
    siteId: string,
    params?: GetAllUserPurchasesParams,
 signal?: AbortSignal
) => {
      return localInstance<UserPurchase[]>(
      {url: `/sites/${siteId}/purchases`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllUserPurchasesQueryKey = (siteId: string,
    params?: GetAllUserPurchasesParams,) => [`/sites/${siteId}/purchases`, ...(params ? [params]: [])];

    
export type GetAllUserPurchasesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllUserPurchases>>>
export type GetAllUserPurchasesQueryError = unknown

export const useGetAllUserPurchases = <TData = Awaited<ReturnType<typeof getAllUserPurchases>>, TError = unknown>(
 siteId: string,
    params?: GetAllUserPurchasesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllUserPurchases>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllUserPurchasesQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllUserPurchases>>> = ({ signal }) => getAllUserPurchases(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllUserPurchases>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns a list of all Questions for the given Site
 */
export const getAllQuestions = (
    siteId: string,
 signal?: AbortSignal
) => {
      return localInstance<Question[]>(
      {url: `/sites/${siteId}/questions`, method: 'get', signal
    },
      );
    }
  

export const getGetAllQuestionsQueryKey = (siteId: string,) => [`/sites/${siteId}/questions`];

    
export type GetAllQuestionsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllQuestions>>>
export type GetAllQuestionsQueryError = unknown

export const useGetAllQuestions = <TData = Awaited<ReturnType<typeof getAllQuestions>>, TError = unknown>(
 siteId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllQuestions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllQuestionsQueryKey(siteId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllQuestions>>> = ({ signal }) => getAllQuestions(siteId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllQuestions>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for all QuickLink object for a Client
 */
export const getQuickLinks = (
    siteId: string,
    params?: GetQuickLinksParams,
 signal?: AbortSignal
) => {
      return localInstance<QuickLink[]>(
      {url: `/sites/${siteId}/quick_links`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetQuickLinksQueryKey = (siteId: string,
    params?: GetQuickLinksParams,) => [`/sites/${siteId}/quick_links`, ...(params ? [params]: [])];

    
export type GetQuickLinksQueryResult = NonNullable<Awaited<ReturnType<typeof getQuickLinks>>>
export type GetQuickLinksQueryError = unknown

export const useGetQuickLinks = <TData = Awaited<ReturnType<typeof getQuickLinks>>, TError = unknown>(
 siteId: string,
    params?: GetQuickLinksParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuickLinks>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetQuickLinksQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuickLinks>>> = ({ signal }) => getQuickLinks(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getQuickLinks>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for all QuickLink object for a Client
 */
export const createUpdateQuickLinks = (
    siteId: string,
    quickLink: QuickLink[],
 
) => {
      return localInstance<QuickLink[]>(
      {url: `/sites/${siteId}/quick_links`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: quickLink
    },
      );
    }
  


    export type CreateUpdateQuickLinksMutationResult = NonNullable<Awaited<ReturnType<typeof createUpdateQuickLinks>>>
    export type CreateUpdateQuickLinksMutationBody = QuickLink[]
    export type CreateUpdateQuickLinksMutationError = unknown

    export const useCreateUpdateQuickLinks = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUpdateQuickLinks>>, TError,{siteId: string;data: QuickLink[]}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUpdateQuickLinks>>, {siteId: string;data: QuickLink[]}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createUpdateQuickLinks(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createUpdateQuickLinks>>, TError, {siteId: string;data: QuickLink[]}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single QuickLink for a Client
 */
export const getQuickLink = (
    siteId: string,
    quickLinkId: string,
    params?: GetQuickLinkParams,
 signal?: AbortSignal
) => {
      return localInstance<QuickLink>(
      {url: `/sites/${siteId}/quick_links/${quickLinkId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetQuickLinkQueryKey = (siteId: string,
    quickLinkId: string,
    params?: GetQuickLinkParams,) => [`/sites/${siteId}/quick_links/${quickLinkId}`, ...(params ? [params]: [])];

    
export type GetQuickLinkQueryResult = NonNullable<Awaited<ReturnType<typeof getQuickLink>>>
export type GetQuickLinkQueryError = unknown

export const useGetQuickLink = <TData = Awaited<ReturnType<typeof getQuickLink>>, TError = unknown>(
 siteId: string,
    quickLinkId: string,
    params?: GetQuickLinkParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getQuickLink>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetQuickLinkQueryKey(siteId,quickLinkId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getQuickLink>>> = ({ signal }) => getQuickLink(siteId,quickLinkId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getQuickLink>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && quickLinkId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single QuickLink for a Client
 */
export const updateNavigationItem2 = (
    siteId: string,
    quickLinkId: string,
    navigationItem: NavigationItem,
 
) => {
      return localInstance<NavigationItem>(
      {url: `/sites/${siteId}/quick_links/${quickLinkId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: navigationItem
    },
      );
    }
  


    export type UpdateNavigationItem2MutationResult = NonNullable<Awaited<ReturnType<typeof updateNavigationItem2>>>
    export type UpdateNavigationItem2MutationBody = NavigationItem
    export type UpdateNavigationItem2MutationError = unknown

    export const useUpdateNavigationItem2 = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateNavigationItem2>>, TError,{siteId: string;quickLinkId: string;data: NavigationItem}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateNavigationItem2>>, {siteId: string;quickLinkId: string;data: NavigationItem}> = (props) => {
          const {siteId,quickLinkId,data} = props ?? {};

          return  updateNavigationItem2(siteId,quickLinkId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateNavigationItem2>>, TError, {siteId: string;quickLinkId: string;data: NavigationItem}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single QuickLink for a Client
 */
export const deleteQuickLink = (
    siteId: string,
    quickLinkId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/quick_links/${quickLinkId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteQuickLinkMutationResult = NonNullable<Awaited<ReturnType<typeof deleteQuickLink>>>
    
    export type DeleteQuickLinkMutationError = unknown

    export const useDeleteQuickLink = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteQuickLink>>, TError,{siteId: string;quickLinkId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteQuickLink>>, {siteId: string;quickLinkId: string}> = (props) => {
          const {siteId,quickLinkId} = props ?? {};

          return  deleteQuickLink(siteId,quickLinkId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteQuickLink>>, TError, {siteId: string;quickLinkId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Processes the webhook request to update the user registration (juno enrollment)
Authenticated with custom webhook_token generated for the scorm app on creation.
Returns HttpResponse.
 */
export const sitesScormRegistrationWebhookCreate = (
    siteId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/scorm/registration_webhook`, method: 'post'
    },
      );
    }
  


    export type SitesScormRegistrationWebhookCreateMutationResult = NonNullable<Awaited<ReturnType<typeof sitesScormRegistrationWebhookCreate>>>
    
    export type SitesScormRegistrationWebhookCreateMutationError = unknown

    export const useSitesScormRegistrationWebhookCreate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sitesScormRegistrationWebhookCreate>>, TError,{siteId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sitesScormRegistrationWebhookCreate>>, {siteId: string}> = (props) => {
          const {siteId} = props ?? {};

          return  sitesScormRegistrationWebhookCreate(siteId,)
        }

      return useMutation<Awaited<ReturnType<typeof sitesScormRegistrationWebhookCreate>>, TError, {siteId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a list of all matching content for the given Site
 */
export const searchContent = (
    siteId: string,
    params?: SearchContentParams,
 signal?: AbortSignal
) => {
      return localInstance<SearchContent[]>(
      {url: `/sites/${siteId}/search/contents`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getSearchContentQueryKey = (siteId: string,
    params?: SearchContentParams,) => [`/sites/${siteId}/search/contents`, ...(params ? [params]: [])];

    
export type SearchContentQueryResult = NonNullable<Awaited<ReturnType<typeof searchContent>>>
export type SearchContentQueryError = unknown

export const useSearchContent = <TData = Awaited<ReturnType<typeof searchContent>>, TError = unknown>(
 siteId: string,
    params?: SearchContentParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof searchContent>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getSearchContentQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof searchContent>>> = ({ signal }) => searchContent(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof searchContent>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Sends out an EmailCampaign
 */
export const sendSiteEmailCampaign = (
    siteId: string,
    sendingEmail: SendingEmail,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/send_email_campaign`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sendingEmail
    },
      );
    }
  


    export type SendSiteEmailCampaignMutationResult = NonNullable<Awaited<ReturnType<typeof sendSiteEmailCampaign>>>
    export type SendSiteEmailCampaignMutationBody = SendingEmail
    export type SendSiteEmailCampaignMutationError = unknown

    export const useSendSiteEmailCampaign = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendSiteEmailCampaign>>, TError,{siteId: string;data: SendingEmail}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendSiteEmailCampaign>>, {siteId: string;data: SendingEmail}> = (props) => {
          const {siteId,data} = props ?? {};

          return  sendSiteEmailCampaign(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sendSiteEmailCampaign>>, TError, {siteId: string;data: SendingEmail}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Get all Sessions
 */
export const getAllSessions = (
    siteId: string,
    params?: GetAllSessionsParams,
 signal?: AbortSignal
) => {
      return localInstance<Session[]>(
      {url: `/sites/${siteId}/sessions`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllSessionsQueryKey = (siteId: string,
    params?: GetAllSessionsParams,) => [`/sites/${siteId}/sessions`, ...(params ? [params]: [])];

    
export type GetAllSessionsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllSessions>>>
export type GetAllSessionsQueryError = unknown

export const useGetAllSessions = <TData = Awaited<ReturnType<typeof getAllSessions>>, TError = unknown>(
 siteId: string,
    params?: GetAllSessionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllSessions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllSessionsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllSessions>>> = ({ signal }) => getAllSessions(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllSessions>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Create a Session
 */
export const createNewSession = (
    siteId: string,
    session: Session,
 
) => {
      return localInstance<Session>(
      {url: `/sites/${siteId}/sessions`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: session
    },
      );
    }
  


    export type CreateNewSessionMutationResult = NonNullable<Awaited<ReturnType<typeof createNewSession>>>
    export type CreateNewSessionMutationBody = Session
    export type CreateNewSessionMutationError = unknown

    export const useCreateNewSession = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNewSession>>, TError,{siteId: string;data: Session}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createNewSession>>, {siteId: string;data: Session}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createNewSession(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createNewSession>>, TError, {siteId: string;data: Session}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Get a single Session
 */
export const getSession = (
    siteId: string,
    sessionId: string,
    params?: GetSessionParams,
 signal?: AbortSignal
) => {
      return localInstance<Session>(
      {url: `/sites/${siteId}/sessions/${sessionId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSessionQueryKey = (siteId: string,
    sessionId: string,
    params?: GetSessionParams,) => [`/sites/${siteId}/sessions/${sessionId}`, ...(params ? [params]: [])];

    
export type GetSessionQueryResult = NonNullable<Awaited<ReturnType<typeof getSession>>>
export type GetSessionQueryError = unknown

export const useGetSession = <TData = Awaited<ReturnType<typeof getSession>>, TError = unknown>(
 siteId: string,
    sessionId: string,
    params?: GetSessionParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSession>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSessionQueryKey(siteId,sessionId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSession>>> = ({ signal }) => getSession(siteId,sessionId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSession>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && sessionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Update a Session
 */
export const updateSession = (
    siteId: string,
    sessionId: string,
    session: Session,
 
) => {
      return localInstance<Session>(
      {url: `/sites/${siteId}/sessions/${sessionId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: session
    },
      );
    }
  


    export type UpdateSessionMutationResult = NonNullable<Awaited<ReturnType<typeof updateSession>>>
    export type UpdateSessionMutationBody = Session
    export type UpdateSessionMutationError = unknown

    export const useUpdateSession = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSession>>, TError,{siteId: string;sessionId: string;data: Session}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSession>>, {siteId: string;sessionId: string;data: Session}> = (props) => {
          const {siteId,sessionId,data} = props ?? {};

          return  updateSession(siteId,sessionId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateSession>>, TError, {siteId: string;sessionId: string;data: Session}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Delete a Session
 */
export const deleteSession = (
    siteId: string,
    sessionId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/sessions/${sessionId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteSessionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSession>>>
    
    export type DeleteSessionMutationError = unknown

    export const useDeleteSession = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSession>>, TError,{siteId: string;sessionId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSession>>, {siteId: string;sessionId: string}> = (props) => {
          const {siteId,sessionId} = props ?? {};

          return  deleteSession(siteId,sessionId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteSession>>, TError, {siteId: string;sessionId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const getSessionActiveParticipants = (
    siteId: string,
    sessionId: string,
    params?: GetSessionActiveParticipantsParams,
 signal?: AbortSignal
) => {
      return localInstance<GetSessionActiveParticipants200>(
      {url: `/sites/${siteId}/sessions/${sessionId}/active_participants`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSessionActiveParticipantsQueryKey = (siteId: string,
    sessionId: string,
    params?: GetSessionActiveParticipantsParams,) => [`/sites/${siteId}/sessions/${sessionId}/active_participants`, ...(params ? [params]: [])];

    
export type GetSessionActiveParticipantsQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionActiveParticipants>>>
export type GetSessionActiveParticipantsQueryError = unknown

export const useGetSessionActiveParticipants = <TData = Awaited<ReturnType<typeof getSessionActiveParticipants>>, TError = unknown>(
 siteId: string,
    sessionId: string,
    params?: GetSessionActiveParticipantsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSessionActiveParticipants>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSessionActiveParticipantsQueryKey(siteId,sessionId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionActiveParticipants>>> = ({ signal }) => getSessionActiveParticipants(siteId,sessionId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSessionActiveParticipants>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && sessionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Get all SessionArchives for a session
 */
export const getSessionArchives = (
    siteId: string,
    sessionId: string,
    params?: GetSessionArchivesParams,
 signal?: AbortSignal
) => {
      return localInstance<GetSessionArchives200>(
      {url: `/sites/${siteId}/sessions/${sessionId}/archives`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSessionArchivesQueryKey = (siteId: string,
    sessionId: string,
    params?: GetSessionArchivesParams,) => [`/sites/${siteId}/sessions/${sessionId}/archives`, ...(params ? [params]: [])];

    
export type GetSessionArchivesQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionArchives>>>
export type GetSessionArchivesQueryError = unknown

export const useGetSessionArchives = <TData = Awaited<ReturnType<typeof getSessionArchives>>, TError = unknown>(
 siteId: string,
    sessionId: string,
    params?: GetSessionArchivesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSessionArchives>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSessionArchivesQueryKey(siteId,sessionId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionArchives>>> = ({ signal }) => getSessionArchives(siteId,sessionId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSessionArchives>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && sessionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Deletes a single SessionArchive
 */
export const deleteSessionArchive = (
    siteId: string,
    sessionId: string,
    archiveId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/sessions/${sessionId}/archives/${archiveId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteSessionArchiveMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSessionArchive>>>
    
    export type DeleteSessionArchiveMutationError = unknown

    export const useDeleteSessionArchive = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSessionArchive>>, TError,{siteId: string;sessionId: string;archiveId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSessionArchive>>, {siteId: string;sessionId: string;archiveId: string}> = (props) => {
          const {siteId,sessionId,archiveId} = props ?? {};

          return  deleteSessionArchive(siteId,sessionId,archiveId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteSessionArchive>>, TError, {siteId: string;sessionId: string;archiveId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for attending and unattending a Session
 */
export const attendUnattendSession = (
    siteId: string,
    sessionId: string,
    sessionAttendee: SessionAttendee,
 
) => {
      return localInstance<Session>(
      {url: `/sites/${siteId}/sessions/${sessionId}/attend_unattend`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sessionAttendee
    },
      );
    }
  


    export type AttendUnattendSessionMutationResult = NonNullable<Awaited<ReturnType<typeof attendUnattendSession>>>
    export type AttendUnattendSessionMutationBody = SessionAttendee
    export type AttendUnattendSessionMutationError = unknown

    export const useAttendUnattendSession = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof attendUnattendSession>>, TError,{siteId: string;sessionId: string;data: SessionAttendee}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof attendUnattendSession>>, {siteId: string;sessionId: string;data: SessionAttendee}> = (props) => {
          const {siteId,sessionId,data} = props ?? {};

          return  attendUnattendSession(siteId,sessionId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof attendUnattendSession>>, TError, {siteId: string;sessionId: string;data: SessionAttendee}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for attending and unattending a list of users to a Session
 */
export const batchAttendUnattendSession = (
    siteId: string,
    sessionId: string,
    sessionAttendee: SessionAttendee[],
 
) => {
      return localInstance<BatchAttendUnattendSession200>(
      {url: `/sites/${siteId}/sessions/${sessionId}/batch_attend_unattend`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sessionAttendee
    },
      );
    }
  


    export type BatchAttendUnattendSessionMutationResult = NonNullable<Awaited<ReturnType<typeof batchAttendUnattendSession>>>
    export type BatchAttendUnattendSessionMutationBody = SessionAttendee[]
    export type BatchAttendUnattendSessionMutationError = unknown

    export const useBatchAttendUnattendSession = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof batchAttendUnattendSession>>, TError,{siteId: string;sessionId: string;data: SessionAttendee[]}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof batchAttendUnattendSession>>, {siteId: string;sessionId: string;data: SessionAttendee[]}> = (props) => {
          const {siteId,sessionId,data} = props ?? {};

          return  batchAttendUnattendSession(siteId,sessionId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof batchAttendUnattendSession>>, TError, {siteId: string;sessionId: string;data: SessionAttendee[]}, TContext>(mutationFn, mutationOptions)
    }
    
export const joinSession = (
    siteId: string,
    sessionId: string,
 signal?: AbortSignal
) => {
      return localInstance<JoinSession>(
      {url: `/sites/${siteId}/sessions/${sessionId}/join`, method: 'get', signal
    },
      );
    }
  

export const getJoinSessionQueryKey = (siteId: string,
    sessionId: string,) => [`/sites/${siteId}/sessions/${sessionId}/join`];

    
export type JoinSessionQueryResult = NonNullable<Awaited<ReturnType<typeof joinSession>>>
export type JoinSessionQueryError = void

export const useJoinSession = <TData = Awaited<ReturnType<typeof joinSession>>, TError = void>(
 siteId: string,
    sessionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof joinSession>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getJoinSessionQueryKey(siteId,sessionId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof joinSession>>> = ({ signal }) => joinSession(siteId,sessionId, signal);

  const query = useQuery<Awaited<ReturnType<typeof joinSession>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && sessionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for liking and unliking a Session
 */
export const likeUnlikeSession = (
    siteId: string,
    sessionId: string,
 
) => {
      return localInstance<Session>(
      {url: `/sites/${siteId}/sessions/${sessionId}/like_unlike`, method: 'post'
    },
      );
    }
  


    export type LikeUnlikeSessionMutationResult = NonNullable<Awaited<ReturnType<typeof likeUnlikeSession>>>
    
    export type LikeUnlikeSessionMutationError = unknown

    export const useLikeUnlikeSession = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof likeUnlikeSession>>, TError,{siteId: string;sessionId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof likeUnlikeSession>>, {siteId: string;sessionId: string}> = (props) => {
          const {siteId,sessionId} = props ?? {};

          return  likeUnlikeSession(siteId,sessionId,)
        }

      return useMutation<Awaited<ReturnType<typeof likeUnlikeSession>>, TError, {siteId: string;sessionId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for adding a single SessionParticipant
 */
export const createSessionParticipant = (
    siteId: string,
    sessionId: string,
 
) => {
      return localInstance<SessionParticipant>(
      {url: `/sites/${siteId}/sessions/${sessionId}/participant`, method: 'post'
    },
      );
    }
  


    export type CreateSessionParticipantMutationResult = NonNullable<Awaited<ReturnType<typeof createSessionParticipant>>>
    
    export type CreateSessionParticipantMutationError = unknown

    export const useCreateSessionParticipant = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSessionParticipant>>, TError,{siteId: string;sessionId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSessionParticipant>>, {siteId: string;sessionId: string}> = (props) => {
          const {siteId,sessionId} = props ?? {};

          return  createSessionParticipant(siteId,sessionId,)
        }

      return useMutation<Awaited<ReturnType<typeof createSessionParticipant>>, TError, {siteId: string;sessionId: string}, TContext>(mutationFn, mutationOptions)
    }
    
export const getSessionParticipants = (
    siteId: string,
    sessionId: string,
    params?: GetSessionParticipantsParams,
 signal?: AbortSignal
) => {
      return localInstance<SessionParticipant[]>(
      {url: `/sites/${siteId}/sessions/${sessionId}/participants`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSessionParticipantsQueryKey = (siteId: string,
    sessionId: string,
    params?: GetSessionParticipantsParams,) => [`/sites/${siteId}/sessions/${sessionId}/participants`, ...(params ? [params]: [])];

    
export type GetSessionParticipantsQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionParticipants>>>
export type GetSessionParticipantsQueryError = unknown

export const useGetSessionParticipants = <TData = Awaited<ReturnType<typeof getSessionParticipants>>, TError = unknown>(
 siteId: string,
    sessionId: string,
    params?: GetSessionParticipantsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSessionParticipants>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSessionParticipantsQueryKey(siteId,sessionId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionParticipants>>> = ({ signal }) => getSessionParticipants(siteId,sessionId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSessionParticipants>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && sessionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getRoomPresence = (
    siteId: string,
    sessionId: string,
 signal?: AbortSignal
) => {
      return localInstance<GetRoomPresence200>(
      {url: `/sites/${siteId}/sessions/${sessionId}/room_presence`, method: 'get', signal
    },
      );
    }
  

export const getGetRoomPresenceQueryKey = (siteId: string,
    sessionId: string,) => [`/sites/${siteId}/sessions/${sessionId}/room_presence`];

    
export type GetRoomPresenceQueryResult = NonNullable<Awaited<ReturnType<typeof getRoomPresence>>>
export type GetRoomPresenceQueryError = unknown

export const useGetRoomPresence = <TData = Awaited<ReturnType<typeof getRoomPresence>>, TError = unknown>(
 siteId: string,
    sessionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRoomPresence>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRoomPresenceQueryKey(siteId,sessionId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRoomPresence>>> = ({ signal }) => getRoomPresence(siteId,sessionId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRoomPresence>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && sessionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getRoomsPresence = (
    siteId: string,
    sessionId: string,
 signal?: AbortSignal
) => {
      return localInstance<GetRoomsPresence200>(
      {url: `/sites/${siteId}/sessions/${sessionId}/rooms_presence`, method: 'get', signal
    },
      );
    }
  

export const getGetRoomsPresenceQueryKey = (siteId: string,
    sessionId: string,) => [`/sites/${siteId}/sessions/${sessionId}/rooms_presence`];

    
export type GetRoomsPresenceQueryResult = NonNullable<Awaited<ReturnType<typeof getRoomsPresence>>>
export type GetRoomsPresenceQueryError = unknown

export const useGetRoomsPresence = <TData = Awaited<ReturnType<typeof getRoomsPresence>>, TError = unknown>(
 siteId: string,
    sessionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRoomsPresence>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetRoomsPresenceQueryKey(siteId,sessionId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRoomsPresence>>> = ({ signal }) => getRoomsPresence(siteId,sessionId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRoomsPresence>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && sessionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for getting the media for a Session
 */
export const getSessionMedia = (
    siteId: string,
    sessionId: string,
    params?: GetSessionMediaParams,
 signal?: AbortSignal
) => {
      return localInstance<SessionMedia[]>(
      {url: `/sites/${siteId}/sessions/${sessionId}/session_media`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSessionMediaQueryKey = (siteId: string,
    sessionId: string,
    params?: GetSessionMediaParams,) => [`/sites/${siteId}/sessions/${sessionId}/session_media`, ...(params ? [params]: [])];

    
export type GetSessionMediaQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionMedia>>>
export type GetSessionMediaQueryError = unknown

export const useGetSessionMedia = <TData = Awaited<ReturnType<typeof getSessionMedia>>, TError = unknown>(
 siteId: string,
    sessionId: string,
    params?: GetSessionMediaParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSessionMedia>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSessionMediaQueryKey(siteId,sessionId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionMedia>>> = ({ signal }) => getSessionMedia(siteId,sessionId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSessionMedia>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && sessionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for getting the media for a Session
 */
export const createSessionMedia = (
    siteId: string,
    sessionId: string,
    sessionMedia: SessionMedia,
 
) => {
      return localInstance<SessionMedia>(
      {url: `/sites/${siteId}/sessions/${sessionId}/session_media`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sessionMedia
    },
      );
    }
  


    export type CreateSessionMediaMutationResult = NonNullable<Awaited<ReturnType<typeof createSessionMedia>>>
    export type CreateSessionMediaMutationBody = SessionMedia
    export type CreateSessionMediaMutationError = unknown

    export const useCreateSessionMedia = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSessionMedia>>, TError,{siteId: string;sessionId: string;data: SessionMedia}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSessionMedia>>, {siteId: string;sessionId: string;data: SessionMedia}> = (props) => {
          const {siteId,sessionId,data} = props ?? {};

          return  createSessionMedia(siteId,sessionId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createSessionMedia>>, TError, {siteId: string;sessionId: string;data: SessionMedia}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates a single SessionMedia
 */
export const updateSessionMedia = (
    siteId: string,
    sessionId: string,
    mediaId: string,
    sessionMedia: SessionMedia,
 
) => {
      return localInstance<SessionMedia>(
      {url: `/sites/${siteId}/sessions/${sessionId}/session_media/${mediaId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: sessionMedia
    },
      );
    }
  


    export type UpdateSessionMediaMutationResult = NonNullable<Awaited<ReturnType<typeof updateSessionMedia>>>
    export type UpdateSessionMediaMutationBody = SessionMedia
    export type UpdateSessionMediaMutationError = unknown

    export const useUpdateSessionMedia = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSessionMedia>>, TError,{siteId: string;sessionId: string;mediaId: string;data: SessionMedia}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSessionMedia>>, {siteId: string;sessionId: string;mediaId: string;data: SessionMedia}> = (props) => {
          const {siteId,sessionId,mediaId,data} = props ?? {};

          return  updateSessionMedia(siteId,sessionId,mediaId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateSessionMedia>>, TError, {siteId: string;sessionId: string;mediaId: string;data: SessionMedia}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a single SessionMedia
 */
export const deleteSessionMedia = (
    siteId: string,
    sessionId: string,
    mediaId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/sessions/${sessionId}/session_media/${mediaId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteSessionMediaMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSessionMedia>>>
    
    export type DeleteSessionMediaMutationError = unknown

    export const useDeleteSessionMedia = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSessionMedia>>, TError,{siteId: string;sessionId: string;mediaId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSessionMedia>>, {siteId: string;sessionId: string;mediaId: string}> = (props) => {
          const {siteId,sessionId,mediaId} = props ?? {};

          return  deleteSessionMedia(siteId,sessionId,mediaId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteSessionMedia>>, TError, {siteId: string;sessionId: string;mediaId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Creates a single SessionParent
 */
export const createSessionParent = (
    siteId: string,
    sessionId: string,
    parentId: string,
    contentType: string,
 
) => {
      return localInstance<SessionParent>(
      {url: `/sites/${siteId}/sessions/${sessionId}/session_parent/${parentId}/${contentType}`, method: 'post'
    },
      );
    }
  


    export type CreateSessionParentMutationResult = NonNullable<Awaited<ReturnType<typeof createSessionParent>>>
    
    export type CreateSessionParentMutationError = unknown

    export const useCreateSessionParent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSessionParent>>, TError,{siteId: string;sessionId: string;parentId: string;contentType: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSessionParent>>, {siteId: string;sessionId: string;parentId: string;contentType: string}> = (props) => {
          const {siteId,sessionId,parentId,contentType} = props ?? {};

          return  createSessionParent(siteId,sessionId,parentId,contentType,)
        }

      return useMutation<Awaited<ReturnType<typeof createSessionParent>>, TError, {siteId: string;sessionId: string;parentId: string;contentType: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a single SessionParent
 */
export const deleteSessionParent = (
    siteId: string,
    sessionId: string,
    parentId: string,
    contentType: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/sessions/${sessionId}/session_parent/${parentId}/${contentType}`, method: 'delete'
    },
      );
    }
  


    export type DeleteSessionParentMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSessionParent>>>
    
    export type DeleteSessionParentMutationError = unknown

    export const useDeleteSessionParent = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSessionParent>>, TError,{siteId: string;sessionId: string;parentId: string;contentType: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSessionParent>>, {siteId: string;sessionId: string;parentId: string;contentType: string}> = (props) => {
          const {siteId,sessionId,parentId,contentType} = props ?? {};

          return  deleteSessionParent(siteId,sessionId,parentId,contentType,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteSessionParent>>, TError, {siteId: string;sessionId: string;parentId: string;contentType: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for getting the sub breakouts of a Session
 */
export const getSessionSubBreakouts = (
    siteId: string,
    sessionId: string,
    params?: GetSessionSubBreakoutsParams,
 signal?: AbortSignal
) => {
      return localInstance<SessionSubBreakout[]>(
      {url: `/sites/${siteId}/sessions/${sessionId}/sub_breakouts`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSessionSubBreakoutsQueryKey = (siteId: string,
    sessionId: string,
    params?: GetSessionSubBreakoutsParams,) => [`/sites/${siteId}/sessions/${sessionId}/sub_breakouts`, ...(params ? [params]: [])];

    
export type GetSessionSubBreakoutsQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionSubBreakouts>>>
export type GetSessionSubBreakoutsQueryError = unknown

export const useGetSessionSubBreakouts = <TData = Awaited<ReturnType<typeof getSessionSubBreakouts>>, TError = unknown>(
 siteId: string,
    sessionId: string,
    params?: GetSessionSubBreakoutsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSessionSubBreakouts>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSessionSubBreakoutsQueryKey(siteId,sessionId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionSubBreakouts>>> = ({ signal }) => getSessionSubBreakouts(siteId,sessionId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSessionSubBreakouts>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && sessionId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for getting the sub breakouts of a Session
 */
export const createSessionSubBreakouts = (
    siteId: string,
    sessionId: string,
    sessionSubBreakout: SessionSubBreakout[],
 
) => {
      return localInstance<SessionSubBreakout[]>(
      {url: `/sites/${siteId}/sessions/${sessionId}/sub_breakouts`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sessionSubBreakout
    },
      );
    }
  


    export type CreateSessionSubBreakoutsMutationResult = NonNullable<Awaited<ReturnType<typeof createSessionSubBreakouts>>>
    export type CreateSessionSubBreakoutsMutationBody = SessionSubBreakout[]
    export type CreateSessionSubBreakoutsMutationError = unknown

    export const useCreateSessionSubBreakouts = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSessionSubBreakouts>>, TError,{siteId: string;sessionId: string;data: SessionSubBreakout[]}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSessionSubBreakouts>>, {siteId: string;sessionId: string;data: SessionSubBreakout[]}> = (props) => {
          const {siteId,sessionId,data} = props ?? {};

          return  createSessionSubBreakouts(siteId,sessionId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createSessionSubBreakouts>>, TError, {siteId: string;sessionId: string;data: SessionSubBreakout[]}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates a single SessionSubBreakout
 */
export const updateSessionSubBreakout = (
    siteId: string,
    sessionId: string,
    endBreakoutMessage: EndBreakoutMessage,
 
) => {
      return localInstance<UpdateSessionSubBreakout200>(
      {url: `/sites/${siteId}/sessions/${sessionId}/sub_breakouts`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: endBreakoutMessage
    },
      );
    }
  


    export type UpdateSessionSubBreakoutMutationResult = NonNullable<Awaited<ReturnType<typeof updateSessionSubBreakout>>>
    export type UpdateSessionSubBreakoutMutationBody = EndBreakoutMessage
    export type UpdateSessionSubBreakoutMutationError = unknown

    export const useUpdateSessionSubBreakout = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSessionSubBreakout>>, TError,{siteId: string;sessionId: string;data: EndBreakoutMessage}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSessionSubBreakout>>, {siteId: string;sessionId: string;data: EndBreakoutMessage}> = (props) => {
          const {siteId,sessionId,data} = props ?? {};

          return  updateSessionSubBreakout(siteId,sessionId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateSessionSubBreakout>>, TError, {siteId: string;sessionId: string;data: EndBreakoutMessage}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Get all SessionBackgrounds
 */
export const getSessionBackgrounds = (
    siteId: string,
    params?: GetSessionBackgroundsParams,
 signal?: AbortSignal
) => {
      return localInstance<SessionBackground[]>(
      {url: `/sites/${siteId}/sessions/backgrounds`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSessionBackgroundsQueryKey = (siteId: string,
    params?: GetSessionBackgroundsParams,) => [`/sites/${siteId}/sessions/backgrounds`, ...(params ? [params]: [])];

    
export type GetSessionBackgroundsQueryResult = NonNullable<Awaited<ReturnType<typeof getSessionBackgrounds>>>
export type GetSessionBackgroundsQueryError = unknown

export const useGetSessionBackgrounds = <TData = Awaited<ReturnType<typeof getSessionBackgrounds>>, TError = unknown>(
 siteId: string,
    params?: GetSessionBackgroundsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSessionBackgrounds>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSessionBackgroundsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSessionBackgrounds>>> = ({ signal }) => getSessionBackgrounds(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSessionBackgrounds>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Create a SessionBackground
 */
export const createSessionBackground = (
    siteId: string,
    sessionBackground: SessionBackground,
 
) => {
      return localInstance<SessionBackground>(
      {url: `/sites/${siteId}/sessions/backgrounds`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: sessionBackground
    },
      );
    }
  


    export type CreateSessionBackgroundMutationResult = NonNullable<Awaited<ReturnType<typeof createSessionBackground>>>
    export type CreateSessionBackgroundMutationBody = SessionBackground
    export type CreateSessionBackgroundMutationError = unknown

    export const useCreateSessionBackground = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSessionBackground>>, TError,{siteId: string;data: SessionBackground}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSessionBackground>>, {siteId: string;data: SessionBackground}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createSessionBackground(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createSessionBackground>>, TError, {siteId: string;data: SessionBackground}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a single SessionBackground
 */
export const deleteSessionBackground = (
    siteId: string,
    backgroundId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/sessions/backgrounds/${backgroundId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteSessionBackgroundMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSessionBackground>>>
    
    export type DeleteSessionBackgroundMutationError = unknown

    export const useDeleteSessionBackground = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSessionBackground>>, TError,{siteId: string;backgroundId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSessionBackground>>, {siteId: string;backgroundId: string}> = (props) => {
          const {siteId,backgroundId} = props ?? {};

          return  deleteSessionBackground(siteId,backgroundId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteSessionBackground>>, TError, {siteId: string;backgroundId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for setting up automation defaults for an existing Juno site
 */
export const sgSetupSite = (
    siteId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/setup_automation`, method: 'post'
    },
      );
    }
  


    export type SgSetupSiteMutationResult = NonNullable<Awaited<ReturnType<typeof sgSetupSite>>>
    
    export type SgSetupSiteMutationError = unknown

    export const useSgSetupSite = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sgSetupSite>>, TError,{siteId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sgSetupSite>>, {siteId: string}> = (props) => {
          const {siteId} = props ?? {};

          return  sgSetupSite(siteId,)
        }

      return useMutation<Awaited<ReturnType<typeof sgSetupSite>>, TError, {siteId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Get all Sidebar widgeets for the given site
 */
export const getSidebarWidgets = (
    siteId: string,
    params?: GetSidebarWidgetsParams,
 signal?: AbortSignal
) => {
      return localInstance<SidebarWidget[]>(
      {url: `/sites/${siteId}/sidebar_widgets`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSidebarWidgetsQueryKey = (siteId: string,
    params?: GetSidebarWidgetsParams,) => [`/sites/${siteId}/sidebar_widgets`, ...(params ? [params]: [])];

    
export type GetSidebarWidgetsQueryResult = NonNullable<Awaited<ReturnType<typeof getSidebarWidgets>>>
export type GetSidebarWidgetsQueryError = unknown

export const useGetSidebarWidgets = <TData = Awaited<ReturnType<typeof getSidebarWidgets>>, TError = unknown>(
 siteId: string,
    params?: GetSidebarWidgetsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSidebarWidgets>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSidebarWidgetsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSidebarWidgets>>> = ({ signal }) => getSidebarWidgets(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSidebarWidgets>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Update the widgets
 */
export const updateSidebarWidgets = (
    siteId: string,
    sidebarWidget: SidebarWidget[],
 
) => {
      return localInstance<SidebarWidget[]>(
      {url: `/sites/${siteId}/sidebar_widgets`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: sidebarWidget
    },
      );
    }
  


    export type UpdateSidebarWidgetsMutationResult = NonNullable<Awaited<ReturnType<typeof updateSidebarWidgets>>>
    export type UpdateSidebarWidgetsMutationBody = SidebarWidget[]
    export type UpdateSidebarWidgetsMutationError = unknown

    export const useUpdateSidebarWidgets = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSidebarWidgets>>, TError,{siteId: string;data: SidebarWidget[]}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSidebarWidgets>>, {siteId: string;data: SidebarWidget[]}> = (props) => {
          const {siteId,data} = props ?? {};

          return  updateSidebarWidgets(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateSidebarWidgets>>, TError, {siteId: string;data: SidebarWidget[]}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for all Speaker objects for a given Client
 */
export const getAllSpeakers = (
    siteId: string,
    params?: GetAllSpeakersParams,
 signal?: AbortSignal
) => {
      return localInstance<Speaker[]>(
      {url: `/sites/${siteId}/speakers`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllSpeakersQueryKey = (siteId: string,
    params?: GetAllSpeakersParams,) => [`/sites/${siteId}/speakers`, ...(params ? [params]: [])];

    
export type GetAllSpeakersQueryResult = NonNullable<Awaited<ReturnType<typeof getAllSpeakers>>>
export type GetAllSpeakersQueryError = unknown

export const useGetAllSpeakers = <TData = Awaited<ReturnType<typeof getAllSpeakers>>, TError = unknown>(
 siteId: string,
    params?: GetAllSpeakersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllSpeakers>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllSpeakersQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllSpeakers>>> = ({ signal }) => getAllSpeakers(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllSpeakers>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for all Speaker objects for a given Client
 */
export const createSpeaker = (
    siteId: string,
    speaker: Speaker,
 
) => {
      return localInstance<Speaker>(
      {url: `/sites/${siteId}/speakers`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: speaker
    },
      );
    }
  


    export type CreateSpeakerMutationResult = NonNullable<Awaited<ReturnType<typeof createSpeaker>>>
    export type CreateSpeakerMutationBody = Speaker
    export type CreateSpeakerMutationError = unknown

    export const useCreateSpeaker = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSpeaker>>, TError,{siteId: string;data: Speaker}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSpeaker>>, {siteId: string;data: Speaker}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createSpeaker(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createSpeaker>>, TError, {siteId: string;data: Speaker}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for a single Speaker for a given Client
 */
export const getSpeaker = (
    siteId: string,
    speakerId: string,
    params?: GetSpeakerParams,
 signal?: AbortSignal
) => {
      return localInstance<Speaker>(
      {url: `/sites/${siteId}/speakers/${speakerId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSpeakerQueryKey = (siteId: string,
    speakerId: string,
    params?: GetSpeakerParams,) => [`/sites/${siteId}/speakers/${speakerId}`, ...(params ? [params]: [])];

    
export type GetSpeakerQueryResult = NonNullable<Awaited<ReturnType<typeof getSpeaker>>>
export type GetSpeakerQueryError = unknown

export const useGetSpeaker = <TData = Awaited<ReturnType<typeof getSpeaker>>, TError = unknown>(
 siteId: string,
    speakerId: string,
    params?: GetSpeakerParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSpeaker>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSpeakerQueryKey(siteId,speakerId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSpeaker>>> = ({ signal }) => getSpeaker(siteId,speakerId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSpeaker>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && speakerId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * View for a single Speaker for a given Client
 */
export const updateSpeaker = (
    siteId: string,
    speakerId: string,
    speaker: Speaker,
 
) => {
      return localInstance<Speaker>(
      {url: `/sites/${siteId}/speakers/${speakerId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: speaker
    },
      );
    }
  


    export type UpdateSpeakerMutationResult = NonNullable<Awaited<ReturnType<typeof updateSpeaker>>>
    export type UpdateSpeakerMutationBody = Speaker
    export type UpdateSpeakerMutationError = unknown

    export const useUpdateSpeaker = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSpeaker>>, TError,{siteId: string;speakerId: string;data: Speaker}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSpeaker>>, {siteId: string;speakerId: string;data: Speaker}> = (props) => {
          const {siteId,speakerId,data} = props ?? {};

          return  updateSpeaker(siteId,speakerId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateSpeaker>>, TError, {siteId: string;speakerId: string;data: Speaker}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for a single Speaker for a given Client
 */
export const deleteSpeaker = (
    siteId: string,
    speakerId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/speakers/${speakerId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteSpeakerMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSpeaker>>>
    
    export type DeleteSpeakerMutationError = unknown

    export const useDeleteSpeaker = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSpeaker>>, TError,{siteId: string;speakerId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSpeaker>>, {siteId: string;speakerId: string}> = (props) => {
          const {siteId,speakerId} = props ?? {};

          return  deleteSpeaker(siteId,speakerId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteSpeaker>>, TError, {siteId: string;speakerId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for a single Speaker's Sessions for a given Client
 */
export const getSpeakerSessions = (
    siteId: string,
    speakerId: string,
    params?: GetSpeakerSessionsParams,
 signal?: AbortSignal
) => {
      return localInstance<Session[]>(
      {url: `/sites/${siteId}/speakers/${speakerId}/sessions`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSpeakerSessionsQueryKey = (siteId: string,
    speakerId: string,
    params?: GetSpeakerSessionsParams,) => [`/sites/${siteId}/speakers/${speakerId}/sessions`, ...(params ? [params]: [])];

    
export type GetSpeakerSessionsQueryResult = NonNullable<Awaited<ReturnType<typeof getSpeakerSessions>>>
export type GetSpeakerSessionsQueryError = unknown

export const useGetSpeakerSessions = <TData = Awaited<ReturnType<typeof getSpeakerSessions>>, TError = unknown>(
 siteId: string,
    speakerId: string,
    params?: GetSpeakerSessionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSpeakerSessions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSpeakerSessionsQueryKey(siteId,speakerId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSpeakerSessions>>> = ({ signal }) => getSpeakerSessions(siteId,speakerId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSpeakerSessions>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && speakerId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for all TagType for a given Site
 */
export const getAllTagTypes = (
    siteId: string,
    params?: GetAllTagTypesParams,
 signal?: AbortSignal
) => {
      return localInstance<TagType[]>(
      {url: `/sites/${siteId}/tag_types`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllTagTypesQueryKey = (siteId: string,
    params?: GetAllTagTypesParams,) => [`/sites/${siteId}/tag_types`, ...(params ? [params]: [])];

    
export type GetAllTagTypesQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTagTypes>>>
export type GetAllTagTypesQueryError = unknown

export const useGetAllTagTypes = <TData = Awaited<ReturnType<typeof getAllTagTypes>>, TError = unknown>(
 siteId: string,
    params?: GetAllTagTypesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllTagTypes>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllTagTypesQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTagTypes>>> = ({ signal }) => getAllTagTypes(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllTagTypes>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for all TagType for a given Site
 */
export const createTagType = (
    siteId: string,
    tagType: TagType,
 
) => {
      return localInstance<TagType>(
      {url: `/sites/${siteId}/tag_types`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: tagType
    },
      );
    }
  


    export type CreateTagTypeMutationResult = NonNullable<Awaited<ReturnType<typeof createTagType>>>
    export type CreateTagTypeMutationBody = TagType
    export type CreateTagTypeMutationError = unknown

    export const useCreateTagType = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTagType>>, TError,{siteId: string;data: TagType}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTagType>>, {siteId: string;data: TagType}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createTagType(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createTagType>>, TError, {siteId: string;data: TagType}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single TagType
 */
export const getTagType = (
    siteId: string,
    tagTypeId: string,
    params?: GetTagTypeParams,
 signal?: AbortSignal
) => {
      return localInstance<TagType>(
      {url: `/sites/${siteId}/tag_types/${tagTypeId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetTagTypeQueryKey = (siteId: string,
    tagTypeId: string,
    params?: GetTagTypeParams,) => [`/sites/${siteId}/tag_types/${tagTypeId}`, ...(params ? [params]: [])];

    
export type GetTagTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getTagType>>>
export type GetTagTypeQueryError = unknown

export const useGetTagType = <TData = Awaited<ReturnType<typeof getTagType>>, TError = unknown>(
 siteId: string,
    tagTypeId: string,
    params?: GetTagTypeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagType>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagTypeQueryKey(siteId,tagTypeId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagType>>> = ({ signal }) => getTagType(siteId,tagTypeId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagType>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && tagTypeId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for a single TagType
 */
export const updateTagType = (
    siteId: string,
    tagTypeId: string,
    tagType: TagType,
 
) => {
      return localInstance<TagType>(
      {url: `/sites/${siteId}/tag_types/${tagTypeId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: tagType
    },
      );
    }
  


    export type UpdateTagTypeMutationResult = NonNullable<Awaited<ReturnType<typeof updateTagType>>>
    export type UpdateTagTypeMutationBody = TagType
    export type UpdateTagTypeMutationError = unknown

    export const useUpdateTagType = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTagType>>, TError,{siteId: string;tagTypeId: string;data: TagType}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTagType>>, {siteId: string;tagTypeId: string;data: TagType}> = (props) => {
          const {siteId,tagTypeId,data} = props ?? {};

          return  updateTagType(siteId,tagTypeId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateTagType>>, TError, {siteId: string;tagTypeId: string;data: TagType}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for a single TagType
 */
export const deleteTagType = (
    siteId: string,
    tagTypeId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/tag_types/${tagTypeId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteTagTypeMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTagType>>>
    
    export type DeleteTagTypeMutationError = unknown

    export const useDeleteTagType = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTagType>>, TError,{siteId: string;tagTypeId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTagType>>, {siteId: string;tagTypeId: string}> = (props) => {
          const {siteId,tagTypeId} = props ?? {};

          return  deleteTagType(siteId,tagTypeId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteTagType>>, TError, {siteId: string;tagTypeId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with all Tags for a given Client
 */
export const getAllTags = (
    siteId: string,
    params?: GetAllTagsParams,
 signal?: AbortSignal
) => {
      return localInstance<Tag[]>(
      {url: `/sites/${siteId}/tags`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllTagsQueryKey = (siteId: string,
    params?: GetAllTagsParams,) => [`/sites/${siteId}/tags`, ...(params ? [params]: [])];

    
export type GetAllTagsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllTags>>>
export type GetAllTagsQueryError = unknown

export const useGetAllTags = <TData = Awaited<ReturnType<typeof getAllTags>>, TError = unknown>(
 siteId: string,
    params?: GetAllTagsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllTags>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllTagsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllTags>>> = ({ signal }) => getAllTags(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllTags>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for dealing with all Tags for a given Client
 */
export const createTag = (
    siteId: string,
    tag: Tag,
 
) => {
      return localInstance<Tag>(
      {url: `/sites/${siteId}/tags`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: tag
    },
      );
    }
  


    export type CreateTagMutationResult = NonNullable<Awaited<ReturnType<typeof createTag>>>
    export type CreateTagMutationBody = Tag
    export type CreateTagMutationError = unknown

    export const useCreateTag = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTag>>, TError,{siteId: string;data: Tag}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTag>>, {siteId: string;data: Tag}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createTag(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createTag>>, TError, {siteId: string;data: Tag}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with a single Tag
 */
export const getTag = (
    siteId: string,
    tagId: string,
    params?: GetTagParams,
 signal?: AbortSignal
) => {
      return localInstance<Tag>(
      {url: `/sites/${siteId}/tags/${tagId}`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetTagQueryKey = (siteId: string,
    tagId: string,
    params?: GetTagParams,) => [`/sites/${siteId}/tags/${tagId}`, ...(params ? [params]: [])];

    
export type GetTagQueryResult = NonNullable<Awaited<ReturnType<typeof getTag>>>
export type GetTagQueryError = unknown

export const useGetTag = <TData = Awaited<ReturnType<typeof getTag>>, TError = unknown>(
 siteId: string,
    tagId: string,
    params?: GetTagParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTag>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetTagQueryKey(siteId,tagId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTag>>> = ({ signal }) => getTag(siteId,tagId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTag>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && tagId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Views for dealing with a single Tag
 */
export const updateTag = (
    siteId: string,
    tagId: string,
    tag: Tag,
 
) => {
      return localInstance<Tag>(
      {url: `/sites/${siteId}/tags/${tagId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: tag
    },
      );
    }
  


    export type UpdateTagMutationResult = NonNullable<Awaited<ReturnType<typeof updateTag>>>
    export type UpdateTagMutationBody = Tag
    export type UpdateTagMutationError = unknown

    export const useUpdateTag = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTag>>, TError,{siteId: string;tagId: string;data: Tag}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTag>>, {siteId: string;tagId: string;data: Tag}> = (props) => {
          const {siteId,tagId,data} = props ?? {};

          return  updateTag(siteId,tagId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateTag>>, TError, {siteId: string;tagId: string;data: Tag}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Views for dealing with a single Tag
 */
export const deleteTag = (
    siteId: string,
    tagId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/tags/${tagId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteTagMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTag>>>
    
    export type DeleteTagMutationError = unknown

    export const useDeleteTag = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTag>>, TError,{siteId: string;tagId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTag>>, {siteId: string;tagId: string}> = (props) => {
          const {siteId,tagId} = props ?? {};

          return  deleteTag(siteId,tagId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteTag>>, TError, {siteId: string;tagId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Retrieve all unsubscribe groups for a given Juno Site
 */
export const getSiteUnsubscribeGroups = (
    siteId: string,
    params?: GetSiteUnsubscribeGroupsParams,
 signal?: AbortSignal
) => {
      return localInstance<UnsubscribeGroup[]>(
      {url: `/sites/${siteId}/unsubscribe_groups`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetSiteUnsubscribeGroupsQueryKey = (siteId: string,
    params?: GetSiteUnsubscribeGroupsParams,) => [`/sites/${siteId}/unsubscribe_groups`, ...(params ? [params]: [])];

    
export type GetSiteUnsubscribeGroupsQueryResult = NonNullable<Awaited<ReturnType<typeof getSiteUnsubscribeGroups>>>
export type GetSiteUnsubscribeGroupsQueryError = unknown

export const useGetSiteUnsubscribeGroups = <TData = Awaited<ReturnType<typeof getSiteUnsubscribeGroups>>, TError = unknown>(
 siteId: string,
    params?: GetSiteUnsubscribeGroupsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSiteUnsubscribeGroups>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSiteUnsubscribeGroupsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSiteUnsubscribeGroups>>> = ({ signal }) => getSiteUnsubscribeGroups(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSiteUnsubscribeGroups>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Add an unsubscribe group for a given Juno client
 */
export const createSiteUnsubscribeGroup = (
    siteId: string,
    unsubscribeGroup: UnsubscribeGroup,
 
) => {
      return localInstance<UnsubscribeGroup>(
      {url: `/sites/${siteId}/unsubscribe_groups`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: unsubscribeGroup
    },
      );
    }
  


    export type CreateSiteUnsubscribeGroupMutationResult = NonNullable<Awaited<ReturnType<typeof createSiteUnsubscribeGroup>>>
    export type CreateSiteUnsubscribeGroupMutationBody = UnsubscribeGroup
    export type CreateSiteUnsubscribeGroupMutationError = unknown

    export const useCreateSiteUnsubscribeGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSiteUnsubscribeGroup>>, TError,{siteId: string;data: UnsubscribeGroup}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSiteUnsubscribeGroup>>, {siteId: string;data: UnsubscribeGroup}> = (props) => {
          const {siteId,data} = props ?? {};

          return  createSiteUnsubscribeGroup(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createSiteUnsubscribeGroup>>, TError, {siteId: string;data: UnsubscribeGroup}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Get information about a specific Site unsubscribe group
 */
export const getSiteUnsubscribeGroup = (
    siteId: string,
    unsubscribeGroupId: string,
 signal?: AbortSignal
) => {
      return localInstance<UnsubscribeGroup>(
      {url: `/sites/${siteId}/unsubscribe_groups/${unsubscribeGroupId}`, method: 'get', signal
    },
      );
    }
  

export const getGetSiteUnsubscribeGroupQueryKey = (siteId: string,
    unsubscribeGroupId: string,) => [`/sites/${siteId}/unsubscribe_groups/${unsubscribeGroupId}`];

    
export type GetSiteUnsubscribeGroupQueryResult = NonNullable<Awaited<ReturnType<typeof getSiteUnsubscribeGroup>>>
export type GetSiteUnsubscribeGroupQueryError = unknown

export const useGetSiteUnsubscribeGroup = <TData = Awaited<ReturnType<typeof getSiteUnsubscribeGroup>>, TError = unknown>(
 siteId: string,
    unsubscribeGroupId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSiteUnsubscribeGroup>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSiteUnsubscribeGroupQueryKey(siteId,unsubscribeGroupId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSiteUnsubscribeGroup>>> = ({ signal }) => getSiteUnsubscribeGroup(siteId,unsubscribeGroupId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSiteUnsubscribeGroup>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && unsubscribeGroupId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Edit an existing Site unsubscribe group
 */
export const updateSiteUnsubscribeGroup = (
    siteId: string,
    unsubscribeGroupId: string,
    unsubscribeGroup: UnsubscribeGroup,
 
) => {
      return localInstance<UnsubscribeGroup>(
      {url: `/sites/${siteId}/unsubscribe_groups/${unsubscribeGroupId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: unsubscribeGroup
    },
      );
    }
  


    export type UpdateSiteUnsubscribeGroupMutationResult = NonNullable<Awaited<ReturnType<typeof updateSiteUnsubscribeGroup>>>
    export type UpdateSiteUnsubscribeGroupMutationBody = UnsubscribeGroup
    export type UpdateSiteUnsubscribeGroupMutationError = unknown

    export const useUpdateSiteUnsubscribeGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateSiteUnsubscribeGroup>>, TError,{siteId: string;unsubscribeGroupId: string;data: UnsubscribeGroup}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateSiteUnsubscribeGroup>>, {siteId: string;unsubscribeGroupId: string;data: UnsubscribeGroup}> = (props) => {
          const {siteId,unsubscribeGroupId,data} = props ?? {};

          return  updateSiteUnsubscribeGroup(siteId,unsubscribeGroupId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateSiteUnsubscribeGroup>>, TError, {siteId: string;unsubscribeGroupId: string;data: UnsubscribeGroup}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Removes an unsubscribe group from a Juno client SendGrid Subuser account and the corresponding DB row
 */
export const deleteSiteUnsubscribeGroup = (
    siteId: string,
    unsubscribeGroupId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/unsubscribe_groups/${unsubscribeGroupId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteSiteUnsubscribeGroupMutationResult = NonNullable<Awaited<ReturnType<typeof deleteSiteUnsubscribeGroup>>>
    
    export type DeleteSiteUnsubscribeGroupMutationError = unknown

    export const useDeleteSiteUnsubscribeGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteSiteUnsubscribeGroup>>, TError,{siteId: string;unsubscribeGroupId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteSiteUnsubscribeGroup>>, {siteId: string;unsubscribeGroupId: string}> = (props) => {
          const {siteId,unsubscribeGroupId} = props ?? {};

          return  deleteSiteUnsubscribeGroup(siteId,unsubscribeGroupId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteSiteUnsubscribeGroup>>, TError, {siteId: string;unsubscribeGroupId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Search for unsubscribes within a specific unsubscribe group
 */
export const searchUnsubscribeGroupUnsubscribes = (
    siteId: string,
    unsubscribeGroupId: string,
    groupUnsubscribes: GroupUnsubscribes,
 
) => {
      return localInstance<UnsubscribesSearch>(
      {url: `/sites/${siteId}/unsubscribe_groups/${unsubscribeGroupId}/search_unsubscribes`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: groupUnsubscribes
    },
      );
    }
  


    export type SearchUnsubscribeGroupUnsubscribesMutationResult = NonNullable<Awaited<ReturnType<typeof searchUnsubscribeGroupUnsubscribes>>>
    export type SearchUnsubscribeGroupUnsubscribesMutationBody = GroupUnsubscribes
    export type SearchUnsubscribeGroupUnsubscribesMutationError = unknown

    export const useSearchUnsubscribeGroupUnsubscribes = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof searchUnsubscribeGroupUnsubscribes>>, TError,{siteId: string;unsubscribeGroupId: string;data: GroupUnsubscribes}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof searchUnsubscribeGroupUnsubscribes>>, {siteId: string;unsubscribeGroupId: string;data: GroupUnsubscribes}> = (props) => {
          const {siteId,unsubscribeGroupId,data} = props ?? {};

          return  searchUnsubscribeGroupUnsubscribes(siteId,unsubscribeGroupId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof searchUnsubscribeGroupUnsubscribes>>, TError, {siteId: string;unsubscribeGroupId: string;data: GroupUnsubscribes}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Get all the unsubscribes from a specific unsubscribe group
 */
export const getSingleUnsubscribeGroupUnsubscribes = (
    siteId: string,
    unsubscribeGroupId: string,
 signal?: AbortSignal
) => {
      return localInstance<GroupUnsubscribes>(
      {url: `/sites/${siteId}/unsubscribe_groups/${unsubscribeGroupId}/unsubscribes`, method: 'get', signal
    },
      );
    }
  

export const getGetSingleUnsubscribeGroupUnsubscribesQueryKey = (siteId: string,
    unsubscribeGroupId: string,) => [`/sites/${siteId}/unsubscribe_groups/${unsubscribeGroupId}/unsubscribes`];

    
export type GetSingleUnsubscribeGroupUnsubscribesQueryResult = NonNullable<Awaited<ReturnType<typeof getSingleUnsubscribeGroupUnsubscribes>>>
export type GetSingleUnsubscribeGroupUnsubscribesQueryError = unknown

export const useGetSingleUnsubscribeGroupUnsubscribes = <TData = Awaited<ReturnType<typeof getSingleUnsubscribeGroupUnsubscribes>>, TError = unknown>(
 siteId: string,
    unsubscribeGroupId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSingleUnsubscribeGroupUnsubscribes>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetSingleUnsubscribeGroupUnsubscribesQueryKey(siteId,unsubscribeGroupId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSingleUnsubscribeGroupUnsubscribes>>> = ({ signal }) => getSingleUnsubscribeGroupUnsubscribes(siteId,unsubscribeGroupId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSingleUnsubscribeGroupUnsubscribes>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && unsubscribeGroupId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Add email addresses to an unsubscribe group
 */
export const createUnsubscribeGroupUnsubscribes = (
    siteId: string,
    unsubscribeGroupId: string,
    groupUnsubscribes: GroupUnsubscribes,
 
) => {
      return localInstance<AddUnsubscribeGroupUnsubscribes>(
      {url: `/sites/${siteId}/unsubscribe_groups/${unsubscribeGroupId}/unsubscribes`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: groupUnsubscribes
    },
      );
    }
  


    export type CreateUnsubscribeGroupUnsubscribesMutationResult = NonNullable<Awaited<ReturnType<typeof createUnsubscribeGroupUnsubscribes>>>
    export type CreateUnsubscribeGroupUnsubscribesMutationBody = GroupUnsubscribes
    export type CreateUnsubscribeGroupUnsubscribesMutationError = unknown

    export const useCreateUnsubscribeGroupUnsubscribes = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUnsubscribeGroupUnsubscribes>>, TError,{siteId: string;unsubscribeGroupId: string;data: GroupUnsubscribes}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUnsubscribeGroupUnsubscribes>>, {siteId: string;unsubscribeGroupId: string;data: GroupUnsubscribes}> = (props) => {
          const {siteId,unsubscribeGroupId,data} = props ?? {};

          return  createUnsubscribeGroupUnsubscribes(siteId,unsubscribeGroupId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createUnsubscribeGroupUnsubscribes>>, TError, {siteId: string;unsubscribeGroupId: string;data: GroupUnsubscribes}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Removes a specific unsubscribe from a Juno Site SendGrid Subuser unsubscribe group
 */
export const deleteUnsubscribeGroupUnsubscribes = (
    siteId: string,
    unsubscribeGroupId: string,
    email: string,
 
) => {
      return localInstance<RemoveUnsubscribe>(
      {url: `/sites/${siteId}/unsubscribe_groups/${unsubscribeGroupId}/unsubscribes/${email}`, method: 'delete'
    },
      );
    }
  


    export type DeleteUnsubscribeGroupUnsubscribesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUnsubscribeGroupUnsubscribes>>>
    
    export type DeleteUnsubscribeGroupUnsubscribesMutationError = unknown

    export const useDeleteUnsubscribeGroupUnsubscribes = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUnsubscribeGroupUnsubscribes>>, TError,{siteId: string;unsubscribeGroupId: string;email: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUnsubscribeGroupUnsubscribes>>, {siteId: string;unsubscribeGroupId: string;email: string}> = (props) => {
          const {siteId,unsubscribeGroupId,email} = props ?? {};

          return  deleteUnsubscribeGroupUnsubscribes(siteId,unsubscribeGroupId,email,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteUnsubscribeGroupUnsubscribes>>, TError, {siteId: string;unsubscribeGroupId: string;email: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for handling a user claiming the credit
 */
export const claimEducationCredit = (
    siteId: string,
    userCreditId: string,
 
) => {
      return localInstance<UserEducationCredit>(
      {url: `/sites/${siteId}/user_education_credits/${userCreditId}/claim`, method: 'post'
    },
      );
    }
  


    export type ClaimEducationCreditMutationResult = NonNullable<Awaited<ReturnType<typeof claimEducationCredit>>>
    
    export type ClaimEducationCreditMutationError = unknown

    export const useClaimEducationCredit = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof claimEducationCredit>>, TError,{siteId: string;userCreditId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof claimEducationCredit>>, {siteId: string;userCreditId: string}> = (props) => {
          const {siteId,userCreditId} = props ?? {};

          return  claimEducationCredit(siteId,userCreditId,)
        }

      return useMutation<Awaited<ReturnType<typeof claimEducationCredit>>, TError, {siteId: string;userCreditId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Pins or unpins a group for the requesting user
 */
export const pinGroup = (
    siteId: string,
    groupId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/users/${groupId}/pin_group`, method: 'post'
    },
      );
    }
  


    export type PinGroupMutationResult = NonNullable<Awaited<ReturnType<typeof pinGroup>>>
    
    export type PinGroupMutationError = unknown

    export const usePinGroup = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof pinGroup>>, TError,{siteId: string;groupId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof pinGroup>>, {siteId: string;groupId: string}> = (props) => {
          const {siteId,groupId} = props ?? {};

          return  pinGroup(siteId,groupId,)
        }

      return useMutation<Awaited<ReturnType<typeof pinGroup>>, TError, {siteId: string;groupId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Blocks the given JunoUser for the requesting JunoUser
 */
export const blockUser = (
    siteId: string,
    userId: string,
 
) => {
      return localInstance<BlockUserResponse>(
      {url: `/sites/${siteId}/users/${userId}/block`, method: 'post'
    },
      );
    }
  


    export type BlockUserMutationResult = NonNullable<Awaited<ReturnType<typeof blockUser>>>
    
    export type BlockUserMutationError = unknown

    export const useBlockUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof blockUser>>, TError,{siteId: string;userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof blockUser>>, {siteId: string;userId: string}> = (props) => {
          const {siteId,userId} = props ?? {};

          return  blockUser(siteId,userId,)
        }

      return useMutation<Awaited<ReturnType<typeof blockUser>>, TError, {siteId: string;userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns the education for the given user
 */
export const getUserEducation = (
    siteId: string,
    userId: string,
    params?: GetUserEducationParams,
 signal?: AbortSignal
) => {
      return localInstance<UserEducation[]>(
      {url: `/sites/${siteId}/users/${userId}/education`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetUserEducationQueryKey = (siteId: string,
    userId: string,
    params?: GetUserEducationParams,) => [`/sites/${siteId}/users/${userId}/education`, ...(params ? [params]: [])];

    
export type GetUserEducationQueryResult = NonNullable<Awaited<ReturnType<typeof getUserEducation>>>
export type GetUserEducationQueryError = unknown

export const useGetUserEducation = <TData = Awaited<ReturnType<typeof getUserEducation>>, TError = unknown>(
 siteId: string,
    userId: string,
    params?: GetUserEducationParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserEducation>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserEducationQueryKey(siteId,userId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserEducation>>> = ({ signal }) => getUserEducation(siteId,userId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserEducation>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a user education for the given user
 */
export const createUserEducation = (
    siteId: string,
    userId: string,
    userEducation: UserEducation,
 
) => {
      return localInstance<UserEducation>(
      {url: `/sites/${siteId}/users/${userId}/education`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userEducation
    },
      );
    }
  


    export type CreateUserEducationMutationResult = NonNullable<Awaited<ReturnType<typeof createUserEducation>>>
    export type CreateUserEducationMutationBody = UserEducation
    export type CreateUserEducationMutationError = unknown

    export const useCreateUserEducation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUserEducation>>, TError,{siteId: string;userId: string;data: UserEducation}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUserEducation>>, {siteId: string;userId: string;data: UserEducation}> = (props) => {
          const {siteId,userId,data} = props ?? {};

          return  createUserEducation(siteId,userId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createUserEducation>>, TError, {siteId: string;userId: string;data: UserEducation}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates the user education for the given user
 */
export const updateUserEducation = (
    siteId: string,
    userId: string,
    educationId: string,
    userEducation: UserEducation,
 
) => {
      return localInstance<UserEducation>(
      {url: `/sites/${siteId}/users/${userId}/education/${educationId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: userEducation
    },
      );
    }
  


    export type UpdateUserEducationMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserEducation>>>
    export type UpdateUserEducationMutationBody = UserEducation
    export type UpdateUserEducationMutationError = unknown

    export const useUpdateUserEducation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserEducation>>, TError,{siteId: string;userId: string;educationId: string;data: UserEducation}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserEducation>>, {siteId: string;userId: string;educationId: string;data: UserEducation}> = (props) => {
          const {siteId,userId,educationId,data} = props ?? {};

          return  updateUserEducation(siteId,userId,educationId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateUserEducation>>, TError, {siteId: string;userId: string;educationId: string;data: UserEducation}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the user education for the given user
 */
export const deleteUserEducation = (
    siteId: string,
    userId: string,
    educationId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/users/${userId}/education/${educationId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteUserEducationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserEducation>>>
    
    export type DeleteUserEducationMutationError = unknown

    export const useDeleteUserEducation = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserEducation>>, TError,{siteId: string;userId: string;educationId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserEducation>>, {siteId: string;userId: string;educationId: string}> = (props) => {
          const {siteId,userId,educationId} = props ?? {};

          return  deleteUserEducation(siteId,userId,educationId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteUserEducation>>, TError, {siteId: string;userId: string;educationId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all Enrollments for a given User
 */
export const getAllUserEnrollments = (
    siteId: string,
    userId: string,
    params?: GetAllUserEnrollmentsParams,
 signal?: AbortSignal
) => {
      return localInstance<Enrollment[]>(
      {url: `/sites/${siteId}/users/${userId}/enrollments`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetAllUserEnrollmentsQueryKey = (siteId: string,
    userId: string,
    params?: GetAllUserEnrollmentsParams,) => [`/sites/${siteId}/users/${userId}/enrollments`, ...(params ? [params]: [])];

    
export type GetAllUserEnrollmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllUserEnrollments>>>
export type GetAllUserEnrollmentsQueryError = unknown

export const useGetAllUserEnrollments = <TData = Awaited<ReturnType<typeof getAllUserEnrollments>>, TError = unknown>(
 siteId: string,
    userId: string,
    params?: GetAllUserEnrollmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllUserEnrollments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllUserEnrollmentsQueryKey(siteId,userId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllUserEnrollments>>> = ({ signal }) => getAllUserEnrollments(siteId,userId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllUserEnrollments>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns the experiences for the given user
 */
export const getUserExperience = (
    siteId: string,
    userId: string,
    params?: GetUserExperienceParams,
 signal?: AbortSignal
) => {
      return localInstance<UserExperience[]>(
      {url: `/sites/${siteId}/users/${userId}/experience`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetUserExperienceQueryKey = (siteId: string,
    userId: string,
    params?: GetUserExperienceParams,) => [`/sites/${siteId}/users/${userId}/experience`, ...(params ? [params]: [])];

    
export type GetUserExperienceQueryResult = NonNullable<Awaited<ReturnType<typeof getUserExperience>>>
export type GetUserExperienceQueryError = unknown

export const useGetUserExperience = <TData = Awaited<ReturnType<typeof getUserExperience>>, TError = unknown>(
 siteId: string,
    userId: string,
    params?: GetUserExperienceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserExperience>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserExperienceQueryKey(siteId,userId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserExperience>>> = ({ signal }) => getUserExperience(siteId,userId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserExperience>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Creates a user experience for the given user
 */
export const createUserExperience = (
    siteId: string,
    userId: string,
    userExperience: UserExperience,
 
) => {
      return localInstance<UserExperience>(
      {url: `/sites/${siteId}/users/${userId}/experience`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userExperience
    },
      );
    }
  


    export type CreateUserExperienceMutationResult = NonNullable<Awaited<ReturnType<typeof createUserExperience>>>
    export type CreateUserExperienceMutationBody = UserExperience
    export type CreateUserExperienceMutationError = unknown

    export const useCreateUserExperience = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUserExperience>>, TError,{siteId: string;userId: string;data: UserExperience}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUserExperience>>, {siteId: string;userId: string;data: UserExperience}> = (props) => {
          const {siteId,userId,data} = props ?? {};

          return  createUserExperience(siteId,userId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createUserExperience>>, TError, {siteId: string;userId: string;data: UserExperience}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates the user experience for the given user
 */
export const updateUserExperience = (
    siteId: string,
    userId: string,
    experienceId: string,
    userExperience: UserExperience,
 
) => {
      return localInstance<UserExperience>(
      {url: `/sites/${siteId}/users/${userId}/experience/${experienceId}`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: userExperience
    },
      );
    }
  


    export type UpdateUserExperienceMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserExperience>>>
    export type UpdateUserExperienceMutationBody = UserExperience
    export type UpdateUserExperienceMutationError = unknown

    export const useUpdateUserExperience = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserExperience>>, TError,{siteId: string;userId: string;experienceId: string;data: UserExperience}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserExperience>>, {siteId: string;userId: string;experienceId: string;data: UserExperience}> = (props) => {
          const {siteId,userId,experienceId,data} = props ?? {};

          return  updateUserExperience(siteId,userId,experienceId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateUserExperience>>, TError, {siteId: string;userId: string;experienceId: string;data: UserExperience}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the user experience for the given user
 */
export const deleteUserExperience = (
    siteId: string,
    userId: string,
    experienceId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/users/${userId}/experience/${experienceId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteUserExperienceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserExperience>>>
    
    export type DeleteUserExperienceMutationError = unknown

    export const useDeleteUserExperience = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserExperience>>, TError,{siteId: string;userId: string;experienceId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserExperience>>, {siteId: string;userId: string;experienceId: string}> = (props) => {
          const {siteId,userId,experienceId} = props ?? {};

          return  deleteUserExperience(siteId,userId,experienceId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteUserExperience>>, TError, {siteId: string;userId: string;experienceId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all LessonCompletion objects for the given User
 */
export const getAllUserLessonCompletions = (
    siteId: string,
    userId: string,
 signal?: AbortSignal
) => {
      return localInstance<LessonCompletion[]>(
      {url: `/sites/${siteId}/users/${userId}/lesson_completions`, method: 'get', signal
    },
      );
    }
  

export const getGetAllUserLessonCompletionsQueryKey = (siteId: string,
    userId: string,) => [`/sites/${siteId}/users/${userId}/lesson_completions`];

    
export type GetAllUserLessonCompletionsQueryResult = NonNullable<Awaited<ReturnType<typeof getAllUserLessonCompletions>>>
export type GetAllUserLessonCompletionsQueryError = unknown

export const useGetAllUserLessonCompletions = <TData = Awaited<ReturnType<typeof getAllUserLessonCompletions>>, TError = unknown>(
 siteId: string,
    userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAllUserLessonCompletions>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetAllUserLessonCompletionsQueryKey(siteId,userId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAllUserLessonCompletions>>> = ({ signal }) => getAllUserLessonCompletions(siteId,userId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAllUserLessonCompletions>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const getPurchaseLink = (
    siteId: string,
    userId: string,
    params?: GetPurchaseLinkParams,
 
) => {
      return localInstance<string>(
      {url: `/sites/${siteId}/users/${userId}/payment`, method: 'post',
        params,
    },
      );
    }
  


    export type GetPurchaseLinkMutationResult = NonNullable<Awaited<ReturnType<typeof getPurchaseLink>>>
    
    export type GetPurchaseLinkMutationError = unknown

    export const useGetPurchaseLink = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getPurchaseLink>>, TError,{siteId: string;userId: string;params?: GetPurchaseLinkParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getPurchaseLink>>, {siteId: string;userId: string;params?: GetPurchaseLinkParams}> = (props) => {
          const {siteId,userId,params} = props ?? {};

          return  getPurchaseLink(siteId,userId,params,)
        }

      return useMutation<Awaited<ReturnType<typeof getPurchaseLink>>, TError, {siteId: string;userId: string;params?: GetPurchaseLinkParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getUserPurchases = (
    siteId: string,
    userId: string,
    params?: GetUserPurchasesParams,
 signal?: AbortSignal
) => {
      return localInstance<UserPurchase[]>(
      {url: `/sites/${siteId}/users/${userId}/purchases`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetUserPurchasesQueryKey = (siteId: string,
    userId: string,
    params?: GetUserPurchasesParams,) => [`/sites/${siteId}/users/${userId}/purchases`, ...(params ? [params]: [])];

    
export type GetUserPurchasesQueryResult = NonNullable<Awaited<ReturnType<typeof getUserPurchases>>>
export type GetUserPurchasesQueryError = unknown

export const useGetUserPurchases = <TData = Awaited<ReturnType<typeof getUserPurchases>>, TError = unknown>(
 siteId: string,
    userId: string,
    params?: GetUserPurchasesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserPurchases>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserPurchasesQueryKey(siteId,userId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserPurchases>>> = ({ signal }) => getUserPurchases(siteId,userId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserPurchases>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && userId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const createUserPurchase = (
    siteId: string,
    userId: string,
    userPurchase: UserPurchase,
 
) => {
      return localInstance<UserPurchase>(
      {url: `/sites/${siteId}/users/${userId}/purchases`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userPurchase
    },
      );
    }
  


    export type CreateUserPurchaseMutationResult = NonNullable<Awaited<ReturnType<typeof createUserPurchase>>>
    export type CreateUserPurchaseMutationBody = UserPurchase
    export type CreateUserPurchaseMutationError = unknown

    export const useCreateUserPurchase = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUserPurchase>>, TError,{siteId: string;userId: string;data: UserPurchase}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUserPurchase>>, {siteId: string;userId: string;data: UserPurchase}> = (props) => {
          const {siteId,userId,data} = props ?? {};

          return  createUserPurchase(siteId,userId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createUserPurchase>>, TError, {siteId: string;userId: string;data: UserPurchase}, TContext>(mutationFn, mutationOptions)
    }
    
export const getUserPurchase = (
    siteId: string,
    userId: string,
    purchaseId: string,
 signal?: AbortSignal
) => {
      return localInstance<UserPurchase>(
      {url: `/sites/${siteId}/users/${userId}/purchases/${purchaseId}`, method: 'get', signal
    },
      );
    }
  

export const getGetUserPurchaseQueryKey = (siteId: string,
    userId: string,
    purchaseId: string,) => [`/sites/${siteId}/users/${userId}/purchases/${purchaseId}`];

    
export type GetUserPurchaseQueryResult = NonNullable<Awaited<ReturnType<typeof getUserPurchase>>>
export type GetUserPurchaseQueryError = unknown

export const useGetUserPurchase = <TData = Awaited<ReturnType<typeof getUserPurchase>>, TError = unknown>(
 siteId: string,
    userId: string,
    purchaseId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserPurchase>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserPurchaseQueryKey(siteId,userId,purchaseId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserPurchase>>> = ({ signal }) => getUserPurchase(siteId,userId,purchaseId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserPurchase>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId && userId && purchaseId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


export const deleteUserPurchase = (
    siteId: string,
    userId: string,
    purchaseId: string,
 
) => {
      return localInstance<DeleteResponse>(
      {url: `/sites/${siteId}/users/${userId}/purchases/${purchaseId}`, method: 'delete'
    },
      );
    }
  


    export type DeleteUserPurchaseMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUserPurchase>>>
    
    export type DeleteUserPurchaseMutationError = unknown

    export const useDeleteUserPurchase = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUserPurchase>>, TError,{siteId: string;userId: string;purchaseId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUserPurchase>>, {siteId: string;userId: string;purchaseId: string}> = (props) => {
          const {siteId,userId,purchaseId} = props ?? {};

          return  deleteUserPurchase(siteId,userId,purchaseId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteUserPurchase>>, TError, {siteId: string;userId: string;purchaseId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Saves the given JunoUser for the requesting JunoUser
 */
export const saveUser = (
    siteId: string,
    userId: string,
 
) => {
      return localInstance<void>(
      {url: `/sites/${siteId}/users/${userId}/save_user`, method: 'post'
    },
      );
    }
  


    export type SaveUserMutationResult = NonNullable<Awaited<ReturnType<typeof saveUser>>>
    
    export type SaveUserMutationError = unknown

    export const useSaveUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof saveUser>>, TError,{siteId: string;userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof saveUser>>, {siteId: string;userId: string}> = (props) => {
          const {siteId,userId} = props ?? {};

          return  saveUser(siteId,userId,)
        }

      return useMutation<Awaited<ReturnType<typeof saveUser>>, TError, {siteId: string;userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Unblocks the given JunoUser for the requesting JunoUser
 */
export const unblockUser = (
    siteId: string,
    userId: string,
 
) => {
      return localInstance<BlockUserResponse>(
      {url: `/sites/${siteId}/users/${userId}/unblock`, method: 'post'
    },
      );
    }
  


    export type UnblockUserMutationResult = NonNullable<Awaited<ReturnType<typeof unblockUser>>>
    
    export type UnblockUserMutationError = unknown

    export const useUnblockUser = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unblockUser>>, TError,{siteId: string;userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unblockUser>>, {siteId: string;userId: string}> = (props) => {
          const {siteId,userId} = props ?? {};

          return  unblockUser(siteId,userId,)
        }

      return useMutation<Awaited<ReturnType<typeof unblockUser>>, TError, {siteId: string;userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns the count of all users for the given platform
 */
export const getUserCount = (
    siteId: string,
    params?: GetUserCountParams,
 signal?: AbortSignal
) => {
      return localInstance<UserCount>(
      {url: `/sites/${siteId}/users/count_all_users`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetUserCountQueryKey = (siteId: string,
    params?: GetUserCountParams,) => [`/sites/${siteId}/users/count_all_users`, ...(params ? [params]: [])];

    
export type GetUserCountQueryResult = NonNullable<Awaited<ReturnType<typeof getUserCount>>>
export type GetUserCountQueryError = unknown

export const useGetUserCount = <TData = Awaited<ReturnType<typeof getUserCount>>, TError = unknown>(
 siteId: string,
    params?: GetUserCountParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserCount>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserCountQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserCount>>> = ({ signal }) => getUserCount(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserCount>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Returns the settings for the given user
 */
export const getUserSettings = (
    siteId: string,
    params?: GetUserSettingsParams,
 signal?: AbortSignal
) => {
      return localInstance<UserSettings[]>(
      {url: `/sites/${siteId}/users/settings`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetUserSettingsQueryKey = (siteId: string,
    params?: GetUserSettingsParams,) => [`/sites/${siteId}/users/settings`, ...(params ? [params]: [])];

    
export type GetUserSettingsQueryResult = NonNullable<Awaited<ReturnType<typeof getUserSettings>>>
export type GetUserSettingsQueryError = unknown

export const useGetUserSettings = <TData = Awaited<ReturnType<typeof getUserSettings>>, TError = unknown>(
 siteId: string,
    params?: GetUserSettingsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserSettings>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetUserSettingsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserSettings>>> = ({ signal }) => getUserSettings(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserSettings>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


/**
 * Updates the user settings for the given user
 */
export const updateUserSettings = (
    siteId: string,
    userSettings: UserSettings,
 
) => {
      return localInstance<UserSettings>(
      {url: `/sites/${siteId}/users/settings`, method: 'put',
      headers: {'Content-Type': 'application/json'},
      data: userSettings
    },
      );
    }
  


    export type UpdateUserSettingsMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserSettings>>>
    export type UpdateUserSettingsMutationBody = UserSettings
    export type UpdateUserSettingsMutationError = unknown

    export const useUpdateUserSettings = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUserSettings>>, TError,{siteId: string;data: UserSettings}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUserSettings>>, {siteId: string;data: UserSettings}> = (props) => {
          const {siteId,data} = props ?? {};

          return  updateUserSettings(siteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateUserSettings>>, TError, {siteId: string;data: UserSettings}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * View for getting all Enrollment objects for the currently logged in User
 */
export const getMyWaitlistedEnrollments = (
    siteId: string,
    params?: GetMyWaitlistedEnrollmentsParams,
 signal?: AbortSignal
) => {
      return localInstance<WaitListedEnrollment[]>(
      {url: `/sites/${siteId}/waitlist`, method: 'get', signal,
        params,
    },
      );
    }
  

export const getGetMyWaitlistedEnrollmentsQueryKey = (siteId: string,
    params?: GetMyWaitlistedEnrollmentsParams,) => [`/sites/${siteId}/waitlist`, ...(params ? [params]: [])];

    
export type GetMyWaitlistedEnrollmentsQueryResult = NonNullable<Awaited<ReturnType<typeof getMyWaitlistedEnrollments>>>
export type GetMyWaitlistedEnrollmentsQueryError = unknown

export const useGetMyWaitlistedEnrollments = <TData = Awaited<ReturnType<typeof getMyWaitlistedEnrollments>>, TError = unknown>(
 siteId: string,
    params?: GetMyWaitlistedEnrollmentsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMyWaitlistedEnrollments>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetMyWaitlistedEnrollmentsQueryKey(siteId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMyWaitlistedEnrollments>>> = ({ signal }) => getMyWaitlistedEnrollments(siteId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMyWaitlistedEnrollments>>, TError, TData>(queryKey, queryFn, {enabled: !!(siteId), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}


