import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const UserActivityExporterSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  borderRadius: theme.spacing(1),
  padding: theme.spacing(4),
  background: '#fff',
  border: `1px solid ${theme.palette.divider}`,
  width: `100%`,
}));

export const SectionTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export const SectionNumber = styled(Typography)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: '#fff',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(2),
  borderRadius: theme.spacing(1),
  height: 34,
  width: 34,
}));

export const PreviewIcon = styled(VisibilityIcon)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const DownloadIcon = styled(SystemUpdateAltIcon)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const CustomIconButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  display: `inline-flex`,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.spacing(1),
  marginLeft: `5px`,
  minWidth: 0,
  height: 34,
  maxWidth: 34,
  '.MuiButton-endIcon': {
    margin: 0,
  },
}));

export const OptionsSelectBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingLeft: '60px',
}));

export const OptionsSelectTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

export const SavedReportBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '.5em 4px .5em 8px',
  fontWeight: 600,
  lineHeight: '15px',
  borderRadius: '6px',
  color: theme.palette.text.primary,
  cursor: 'pointer',
  '&:hover,&:focus': {
    color: theme.palette.primary.light,
  },
}));
