/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * JUNO-API-SCHEMA
 * Auto-Generated JunoLive OpenApi3.0 Schema
 * OpenAPI spec version: 1.0.0
 */

export type GetLearningDashboardSummaryScope = typeof GetLearningDashboardSummaryScope[keyof typeof GetLearningDashboardSummaryScope];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetLearningDashboardSummaryScope = {
  admin: 'admin',
  learner: 'learner',
  manager: 'manager',
} as const;
