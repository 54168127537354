import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

type VideoUploadCancellationModalProps = {
  open: boolean;
  onClose: () => void;
  onCancelConfirm?: () => void;
};

const VideoUploadCancellationModal = ({
  open,
  onClose,
  onCancelConfirm,
}: VideoUploadCancellationModalProps) => {
  const onClickUnderstood = () => {
    if (onCancelConfirm) {
      onCancelConfirm();
    }
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth='xs'>
      <DialogTitle>Confirm Cancellation</DialogTitle>
      <DialogContent>
        <Typography>
          Leaving now will erase the video information and upload that is currently in progress will
          be lost.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button sx={{ color: '#555' }} onClick={onClose} autoFocus>
          Don't Cancel
        </Button>
        <Button onClick={onClickUnderstood} autoFocus>
          Understood
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VideoUploadCancellationModal;
