import React, { useEffect, useState } from 'react';
import MessageIcon from '@mui/icons-material/Message';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, IconButton, InputAdornment, List, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { JunoUser } from '@juno/client-api/model';
import { NotificationCircle } from '@juno/ui';
import { useMessengerContext, useSettings } from '@juno/utils';
import { SearchResults } from '../Search/SearchResults';
import { UNKNOWN_TIME } from '../Utils';
import { ConversationsRow } from './ConversationsRow';
import { CreateNewConversation } from './CreateNewConversation';

type ConversationsRowsMeta = {
  channelId: string;
  channelName?: string;
  channelMembers: string;
  lastChannelMessage: string;
  createdAt: string;
  avatarSrc?: string;
  order: number;
  unreadCount: number;
}[];

export const Conversations = () => {
  const theme = useTheme();
  const { user } = useSettings();
  const { messengerMap, currentChannel, setIsCreateNewConversation, isCreateNewConversation } =
    useMessengerContext();

  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [conversationsRowsMeta, setConversationsRowsMeta] = useState<ConversationsRowsMeta>([]);

  const handleCreateNewConversation = () => {
    setIsCreateNewConversation(true);
  };

  useEffect(() => {
    const conversationsRowsMeta: ConversationsRowsMeta = [];

    messengerMap.channels.forEach((channel) => {
      if (!channel.isHidden) {
        const memberNames =
          channel.junoUsers?.reduce((acc: string, junoUser: JunoUser) => {
            if (junoUser.id !== user?.id) {
              const nameToAdd = `${junoUser?.first_name} ${junoUser?.last_name}` ?? 'Unknown';
              return acc === '' ? nameToAdd : `${acc}, ${nameToAdd}`;
            } else {
              return acc;
            }
          }, '') || '';

        const lastMessage = channel.messages[channel.messages.length - 1];
        const text = lastMessage ? lastMessage.text : '';
        const createdAt = lastMessage?.createdAt || UNKNOWN_TIME;

        conversationsRowsMeta.push({
          channelId: channel.id,
          channelName: channel.name,
          channelMembers: memberNames,
          lastChannelMessage: text ? `${text}` : '...',
          createdAt: createdAt,
          avatarSrc:
            channel.userIds?.length === 2
              ? channel.junoUsers.filter((u) => u.id !== user?.id)[0]?.avatar || ''
              : '',
          order: 1,
          unreadCount: channel.messages.reduce((acc, message) => {
            // Increment acc by 1 if isNewMessage is true and the message is not by the current user, otherwise just return acc
            return message.userId !== user?.id && message.isNewMessage ? acc + 1 : acc;
          }, 0), // Start the accumulator at 0,
        });
      }
    });

    const sortedConversationsRowsMeta = conversationsRowsMeta
      .filter((channel) => channel.createdAt && !channel.channelId.includes('system'))
      .sort((a, b) => {
        return b.createdAt.localeCompare(a.createdAt);
      });

    setConversationsRowsMeta(sortedConversationsRowsMeta);
  }, [user?.id, messengerMap.channels]);

  return isCreateNewConversation ? (
    <CreateNewConversation />
  ) : (
    <Box sx={{ position: 'relative' }}>
      <Typography variant='overline' sx={{ m: 2 }}>
        Messages
      </Typography>
      <Box px={2} py={1}>
        <TextField
          placeholder='Search...'
          fullWidth
          size='small'
          autoComplete={'off'}
          onFocus={() => setIsSearching(true)}
          onBlur={() => {
            setIsSearching(false);
            setSearchValue('');
          }}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            position: 'relative',
            zIndex: 2,
            fontSize: '0.8rem',
            borderRadius: 2,
            '&:hover': {
              borderColor: 'rgba(0,0,0,.1)',
              backgroundColor: 'rgba(0,0,0,.01)',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(0,0,0,.2)',
                backgroundColor: 'rgba(0,0,0,.01)',
              },
            },
          }}
        />
      </Box>

      <List
        sx={{
          width: '100%',
          minHeight: 150,
          maxHeight: 'calc(100vh - 200px)', // Adjust the maxHeight as needed
          overflowY: 'auto', // Enable vertical scrolling
        }}
      >
        {!isSearching &&
          conversationsRowsMeta.map((message, index) => (
            <Box key={message.channelId} sx={{ position: 'relative' }}>
              <ConversationsRow {...message} />
              <NotificationCircle count={message.unreadCount} style={{ right: 5, top: 4 }} />
            </Box>
          ))}
        {isSearching && (
          <motion.div
            initial={{ opacity: 0, y: -20 }} // Adjust y value to be just below the TextField
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            style={{ top: '100%', left: 0, right: 0, zIndex: 1 }} // Position absolutely just below the TextField
          >
            <SearchResults searchValue={searchValue} />
          </motion.div>
        )}
      </List>

      {/* Position AddCircleRoundedIcon at the bottom right */}
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={9}></Grid>
        <Grid item xs={3}>
          <IconButton onClick={handleCreateNewConversation} size='small' sx={{ pl: 1 }}>
            <Box
              sx={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'primary.main',
                borderRadius: '50%', // Makes the Box circular
                width: 36, // Adjust size as needed
                height: 36, // Adjust size as needed
              }}
            >
              <MessageIcon fontSize='medium' sx={{ color: 'white' }} />
            </Box>
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
};
